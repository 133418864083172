import React, {Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";

const MOMENT_CALENDAR_SETTINGS = {
    sameDay: '[oggi alle] HH:mm',
    nextDay: '[domani alle] HH:mm',
    nextWeek: 'dddd DD [alle] HH:mm',
    lastDay: '[ieri alle] HH:mm',
    lastWeek: '[lo scorso] dddd [alle] HH:mm',
    sameElse: 'dddd D MMM [alle] HH:mm'
}


export class ChatListViewItem extends React.Component {

    constructor(props) {
        super(props);
        this.imageStyle = {
            backgroundImage: `url("${this.props.imageUrl}")`,
            width: 48,
            height: 48,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }
    }

    handleClick = _ => {
        this.props.onClick(this.props.chatData);
    }

    render() {
        return (
            <div className={"list-group-item d-flex align-items-center " + this.props.className}
                 onClick={this.handleClick}>
                <div
                    className={"rounded-circle mr-2 border"}
                    style={this.imageStyle}
                />
                <div className={"w-75"}>
                    <div>
                        <div className={"text-truncate font-weight-bold"}>
                            <SafeHtmlContainer html={this.props.label}/>
                        </div>
                    </div>
                    <div className={"small text-muted"}>
                        Ultimo messaggio {moment(this.props.chatData.data_ultimo_messaggio).calendar(null, MOMENT_CALENDAR_SETTINGS)}
                    </div>
                </div>
                <div className={"flex-fill"}>
                    {
                        this.props.newMessageNumber > 0 &&
                        <span className={'badge badge-pill badge-primary'}>
                                {this.props.newMessageNumber}
                            </span>
                    }
                </div>
            </div>
        )
    }

}

ChatListViewItem.propTypes = {
    chatData: PropTypes.object,
    imageUrl: PropTypes.string,
    label: PropTypes.string,
    newMessageNumber: PropTypes.number,
    className: PropTypes.string,
}