import React from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {BookizonAppManager} from "../../index";
import Page from "../../components/BaseComponents/Page/Page";

export class ServizioClientiPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page
                title={"Servizio clienti"}
                active={true}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
            >
                <div className={"container mt-3"}>
                    <div className={"list-group shadow-sm"}>
                        <div className={"list-group-item d-flex justify-content-between align-items-center"}
                             onClick={_ => BookizonAppManager.openInAppBrowser('https://bookizon.it/ticket')}>
                            <span>Contattaci</span><FaSolidIcon name={"chevron-right"}/>
                        </div>
                        <div className={"list-group-item d-flex justify-content-between align-items-center"}
                             onClick={_ => BookizonAppManager.openInAppBrowser('https://bookizon.it/home/delete-account/')}>
                            <span>Cancellazione account</span><FaSolidIcon name={"chevron-right"}/>
                        </div>
                    </div>
                </div>
            </Page>
        )

    }

}
