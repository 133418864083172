import React from "react";
import {shadeColor} from "../../helpers/UIUtility";

export const Decorator1 = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.49019 112.49426">
            <title>decorator-1</title>
            <g id="Livello_2" data-name="Livello 2">
                <g id="Livello_1-2" data-name="Livello 1">
                    <path fill={color}
                          d="M41.44065,112.49426a6.57385,6.57385,0,0,1-5.77435-3.441L.78937,44.56093a6.569,6.569,0,0,1,2.65128-8.89744L67.9304.7917A6.56647,6.56647,0,0,1,76.82783,3.443l34.8718,64.48718a6.568,6.568,0,0,1-2.65128,8.89744L44.56373,111.70452A6.57592,6.57592,0,0,1,41.44065,112.49426ZM15.46117,44.08913,44.09194,97.03272,97.0304,68.402,68.40219,15.46349Z"/>
                </g>
            </g>
        </svg>
    )
}
