import React, {Fragment} from "react";
import APIConfig from "../../../../../api/APIConfig";
import styled from "styled-components";
import AuthAPI from "../../../../../api/AuthAPI";
import moment from "moment";
import {SeatsExtendedBookType} from "../../../../../components/ModuloSeats/PropTypes/ExtendedBookType";
import {SEAT_BOOK_STATUS_CONFIRMED} from "../../../../../components/ModuloSeats/Constants/BookStatuses";
import {SeatBookItem} from "./SeatBookItem";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";

export class SeatBookQRInfoView extends React.Component {

    constructor(props) {
        super(props);
    }

    isSwipeDisabled = _ => {
        if (this.scrollableContainerRef) {
            return this.scrollableContainerRef.scrollTop > 0;
        }
        return false;
    }

    getUserProfilePic = _ => {
        return APIConfig.url(`/utenti/profile-pic/${AuthAPI.getUserAccessToken()}/${this.props.book.user.id_utente}/300`)
    }

    getScrollableContainerRef = node => this.scrollableContainerRef = node;

    render() {
        const firstScanDate = moment(this.props.book ? this.props.book.first_qr_scan_date : null).format('ddd DD MMM [alle] HH:mm');
        const today = moment().format("YYYY-MM-DD");
        const hasLinkedBooks = this.props.book.linked_books && this.props.book.linked_books.length;
        const bookDate = this.props.book.date === today || !hasLinkedBooks ? this.props.book.date : this.props.book.linked_books[0].book.date;

        return (
            <React.Fragment>
                {/* HEADING */}
                <div className={"mb-3 text-center"}>
                    <div className={"small text-muted text-uppercase"}>prenotazione di</div>
                    <div className={"d-flex align-items-center justify-content-center mb-3"}>
                        <ProfilePicThumbnail
                            className={"mr-2 shadow-sm"}
                            src={this.getUserProfilePic()}
                        />
                        <span className={"lead font-weight-bold"}>
                            {this.props.book.user.nome} {this.props.book.user.cognome}
                        </span>
                    </div>
                </div>

                {/* ORARI */}
                <div className={"mb-3"}>
                    <div className={"text-capitalize text-center"}>
                        {moment(bookDate).format("ddd DD MMMM")}
                    </div>
                    <div className={"text-center"}>
                        Dalle <b>{this.props.book.start_time}</b> alle <b>{this.props.book.end_time}</b>
                    </div>
                </div>

                {/* PRENOTAZIONI */}
                <div className={"list-group mb-3"}>
                    {
                        /**
                         * We show the master book only if the */
                        this.props.book.date === today &&
                        <div className={"list-group-item"}>
                            <SeatBookItem
                                key={this.props.book.book_id}
                                book={this.props.book}
                                seat={this.props.book.seat}
                                sector={this.props.book.sector}
                                accessories={this.props.book.accessories}
                                tickets={this.props.book.tickets}
                            />
                        </div>
                    }
                    {
                        !!this.props.book.linked_books &&
                        this.props.book.linked_books.map(lb => {
                            return <div className={"list-group-item"}>
                                <SeatBookItem
                                    key={lb.book.book_id}
                                    book={lb.book}
                                    seat={lb.seat}
                                    sector={lb.sector}
                                    accessories={lb.accessories}
                                    tickets={lb.tickets}
                                />
                            </div>
                        })
                    }
                </div>

                {/* DATI SCANSIONE */}
                {
                    this.props.book.status === SEAT_BOOK_STATUS_CONFIRMED &&
                    <Fragment>
                        {
                            !!this.props.book.first_qr_scan_date &&
                            <div className={"text-danger text-center mb-3"}>
                                <FaSolidIcon name={"info-circle"}/> QR code scansionato {firstScanDate}
                            </div>
                        }
                        {
                            (!this.props.book.first_qr_scan_date && !this.props.book.entrance_date) &&
                            <div className={"text-success text-center mb-3"}>
                                <FaSolidIcon name={"info-circle"}/> Prima scansione di questo QR code
                            </div>
                        }
                    </Fragment>
                }

                {/* PRENOTAZIONE NON CONFERMATA */}
                {
                    this.props.book.status !== SEAT_BOOK_STATUS_CONFIRMED &&
                    <div className={"card bg-danger text-white mb-3"}>
                        <div className={"card-body"}>
                            <b>PRENOTAZIONE NON CONFERMATA</b>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }

}


SeatBookQRInfoView.propTypes = {
    book: SeatsExtendedBookType
}


const ProfilePicThumbnail = styled.div`
    display:block;
    width:45px;
    height:45px;
    background-image: url('${props => props.src}');
    background-size:cover;
    background-position:center center;
    position:relative;
    overflow:hidden;
    border-radius:50%;
`;
