import React, {Fragment} from "react";
import PropTypes from "prop-types";
import DipendenteWelfareGiftCardsCategoriesPage from "./Catalog/Categories/DipendenteWelfareGiftCardsCategoriesPage";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import styled from "styled-components";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {DISMISS_GIFT_CARDS_SECTION} from "../../../const/AppEventsCostants";
import {WelfareAPI} from "../../../api/Welfare/WelfareAPI";
import WalletDipendenteAPI from "../../../api/Welfare/WalletDipendenteAPI";
import {RequestARefoundIcon} from "../../../img/illustrations/RequestARefoundIcon";
import {AllRequestsRefoundIcon} from "../../../img/illustrations/AllRequestsRefoundIcon";
import Page from "../../../components/BaseComponents/Page/Page";
import {DipendenteWelfareGiftCardsHistoryPage} from "./History/DipendenteWelfareGiftCardsHistoryPage";
import DipendenteWelfareGiftCardsBrandsPage from "./Catalog/Brands/DipendenteWelfareGiftCardsBrandsPage";
import DipendenteWelfareGiftCardsCatalogPage from "./Catalog/DipendenteWelfareGiftCardsCatalogPage";

const ViewTypeWrapper = styled.div`
    & .btn.btn-sm:focus{
        box-shadow: none!important;
    }
`;


export class DipendenteWelfareGiftCardsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogPageOpen: false,
            giftCardsHistoryPageOpen: false,
            maxPayable: 0
        }
    }

    componentDidMount() {
        this.fetchMaxPayable()
        FuxEvents.on(DISMISS_GIFT_CARDS_SECTION, this.props.onDismiss)
    }

    componentWillUnmount() {
        FuxEvents.off(DISMISS_GIFT_CARDS_SECTION, this.props.onDismiss)
    }

    fetchMaxPayable = _ =>{
        WalletDipendenteAPI.getMaxPayableInType(this.props.user.walletapikey, "acquisto_giftcard")
            .then(importo => this.setState({maxPayable: importo}))
    }

    toggleGiftCardsCatalogPage =_=> this.setState({catalogPageOpen: !this.state.catalogPageOpen})

    toggleGiftCardsHistoryPage =_=> this.setState({giftCardsHistoryPageOpen: !this.state.giftCardsHistoryPageOpen})

    render() {
        return (
            <Fragment>
                <Page
                    title={"Gift cards"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <div className={"container pt-4"}>
                            <div className={"text-center mb-4"}>
                                <h3 className={"text-primary "}>
                                    Scegli una operazione da fare sulle gift cards
                                </h3>
                                <span className={"text-muted"}>
                                    Ricorda che una volta aqcuistate, le gift card dovranno essere attivate! <br/>
                                    Troverai sempre tutte le gift card acquistate all'interno dell'apposita sezione
                                </span>
                            </div>
                            <div className={"row"}>
                                <div className={"col-12 mb-2"}>
                                    <div className={"card border-0 shadow-sm"} onClick={this.toggleGiftCardsCatalogPage}>
                                        <div className={"card-body"}>
                                            <div className={"d-flex align-items-center justify-content-left"}>
                                                <RequestARefoundIcon className={"img-fluid w-50 mx-auto"}
                                                                     color={"#293069"}/>
                                                <div className={"ml-2"}>
                                                    <h5>Acquista una nuova gift card</h5>
                                                    <p>Acquista una nuova gift card con il credito del tuo welfare!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <div className={"card border-0 shadow-sm"} onClick={this.toggleGiftCardsHistoryPage}>
                                        <div className={"card-body"}>
                                            <div className={"d-flex align-items-center justify-content-left"}>
                                                <AllRequestsRefoundIcon className={"img-fluid w-25 mx-auto"}
                                                                        color={"#293069"}/>
                                                <div className={"ml-2"}>
                                                    <h5>Gift card già acquistate</h5>
                                                    <p>Storico delle gift card che hai già acquistato!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />

                {/*Pagina delle categorie*/}
                {
                    this.state.catalogPageOpen &&
                    <DipendenteWelfareGiftCardsCatalogPage
                        active={true}
                        onDismiss={this.toggleGiftCardsCatalogPage}
                        maxPayable={parseFloat(this.state.maxPayable)}
                    />
                }

                {/*Pagina storico acquisti*/}
                {
                    this.state.giftCardsHistoryPageOpen &&
                    <DipendenteWelfareGiftCardsHistoryPage
                        onDismiss={this.toggleGiftCardsHistoryPage}
                    />
                }

            </Fragment>
        )
    }
}


DipendenteWelfareGiftCardsModal.propTypes = {
    active: PropTypes.bool,
    user: PropTypes.object,
    dipendente: PropTypes.object,
    onDismiss: PropTypes.func
};