import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {
    SUBSCRIPTION_STATUS_ACTIVE,
    SUBSCRIPTION_STATUS_SUSPENDEND, SUBSCRIPTION_STATUS_TERMINATED
} from "../../../const/SubscriptionConstants";
import {MODULE_BY_CATEGORY_MAP} from "../../../const/ModuliConstants";
import moment from "moment";

export class SubscriptionUnactiveModulesView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const hasSuspendedSubscriptions = this.props.subscriptions.some(m => m.status === SUBSCRIPTION_STATUS_SUSPENDEND);

        if (this.props.subscriptions) {
            return (
                <Fragment>
                    {
                        (hasSuspendedSubscriptions) &&
                        <div className={"text-danger font-weight-bold text-center"}>ATTENZIONE</div>
                    }
                    {
                        this.props.subscriptions
                            .filter(m => m.status !== SUBSCRIPTION_STATUS_ACTIVE)
                            .filter(m => !!MODULE_BY_CATEGORY_MAP[this.props.idCategoria][m.module])
                            .map(m => {
                                if (!MODULE_BY_CATEGORY_MAP[this.props.idCategoria]) return '';
                                if (!MODULE_BY_CATEGORY_MAP[this.props.idCategoria][m.module]) return '';

                                const moduleName = MODULE_BY_CATEGORY_MAP[this.props.idCategoria][m.module].name;
                                const subscriptionDueDate = moment(m.due_date);
                                const terminationDate = subscriptionDueDate.add(30, 'days').format('dddd DD MMMM YYYY');

                                if (m.status === SUBSCRIPTION_STATUS_SUSPENDEND) {
                                    return <div key={m.module} className={"container-fluid text-muted mb-3 small"}>
                                        Il modulo <b className={"text-danger"}>{moduleName}</b> è stato <b>sospeso</b> a
                                        causa del mancato pagamento della fattura. Verrà disattivato automaticamente se
                                        non paghi entro il {terminationDate}.
                                    </div>
                                }

                                return '';
                            })
                    }
                    {
                        hasSuspendedSubscriptions &&
                        <div className={"container-fluid text-muted small"}>
                            <b className={"text-danger"}>Nota bene:</b> se un tuo modulo è <b>sospeso</b> il tuoi
                            clienti potranno continuare ad effettuare ordini e prenotazioni sulla tua app ma tu non
                            potrai vederli
                        </div>
                    }
                </Fragment>
            )
        }
        return '';
    }

}

SubscriptionUnactiveModulesView.propTypes = {
    subscriptions: PropTypes.array.isRequired,
    idCategoria: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
