import React from "react";
import {shadeColor} from "../../helpers/UIUtility";

export const Decorator4 = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.11359 35.24207">
            <title>decorator-4</title>
            <g id="Livello_2" data-name="Livello 2">
                <g id="Livello_1-2" data-name="Livello 1">
                    <path fill={color}
                          d="M7.85679,35.24207a3.93866,3.93866,0,0,1-3.21025-6.21539l7.4282-10.48461L1.6491,11.09335A3.939,3.939,0,0,1,6.22859,4.68309l10.4,7.43333L24.03372,1.66258a3.93784,3.93784,0,0,1,6.42564,4.55384L23.03628,16.69591l10.42821,7.45128A3.939,3.939,0,0,1,28.885,30.55745l-10.40257-7.4359-7.41025,10.459A3.92717,3.92717,0,0,1,7.85679,35.24207Z"/>
                </g>
            </g>
        </svg>
    )
}
