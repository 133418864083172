import React, {Fragment} from "react";
import styled from "styled-components";

const CarouselContainer = styled.div`
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    
    &::-webkit-scrollbar {
        display: none;
    }
`;

const CircleIndicator = styled.div`
    display:inline-block;
    width:9px; 
    height:9px; 
    border-radius:50%;
    margin:0 3px;
    opacity:0.5;
    &.active{
        opacity:1;
    }
`;

const ImageWrapper = styled.div`
    padding: 0 !important;
    display:flex;
    scroll-snap-align: start;
    width:100vw;
`;

export class NewsCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollLeft: 0,
            currActive: 0
        }
    }

    handleScroll = e => {
        const dimensioneCard = window.innerWidth - 45; //width - padding container (30px) - 15px di sfasamento ne mostrare i bordi delle altre card
        this.setState({
            scrollLeft: e.target.scrollLeft,
            currActive: Math.round(e.target.scrollLeft / dimensioneCard)
        });
    }

    render() {
        return (
            <Fragment>
                <CarouselContainer className={"items-container d-flex flex-nowrap mb-3"} onScroll={this.handleScroll}>
                    {
                        this.props.items.map((v,i) =>
                            <ImageWrapper className={"d-flex"} key={i}>{v}
                                {/* Circle indicators */}
                                <div className={"indicators-container justify-content-center d-flex"}>
                                    {
                                        this.props.items.map((v, i) => <CircleIndicator
                                            className={i === this.state.currActive ? 'active bg-primary' : 'bg-primary'} key={i}/>)
                                    }
                                </div>
                            </ImageWrapper>)
                    }
                </CarouselContainer>
            </Fragment>
        )
    }

}
