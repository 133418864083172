import React from "react";
import PropTypes from "prop-types";
import {MessageMetadataView} from "./MessageMetadataView";
import moment from "moment";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../../../FontAwesomeIcons";
import {NativeBrowserAnchorHOC} from "../../../../hoc/NativeBrowserAnchorHOC";

export function TextMessage({isMine, isLast, messageData, getMediaContentUrlAPI}) {
    const sendTime = moment(messageData.data_creazione).format('HH:mm');

    function urlify(text) {
        var urlRegex = /\s?(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
    }

    return (
        <React.Fragment>
            <div className={"d-flex " + (isMine ? 'justify-content-end mine' : 'yours')}>
                <div className={"message " + (isLast ? 'last' : '')}>
                    <NativeBrowserAnchorHOC>
                        <SafeHtmlContainer html={urlify(messageData.contenuto)}/>
                    </NativeBrowserAnchorHOC>
                    {
                        !!messageData.metadata &&
                        <MessageMetadataView
                            metadata={messageData.metadata}
                            isMine={isMine}
                            idMessaggio={messageData.id_messaggio}
                        />
                    }
                    <div className={"metadata"}>
                        {sendTime}&nbsp;
                        {
                            isMine &&
                            <FaSolidIcon name={messageData.letto === '1' ? 'check-double' : 'check'}/>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

TextMessage.propTypes = {
    isMine: PropTypes.bool,
    isLast: PropTypes.bool,
    messageData: PropTypes.object.isRequired,
    getMediaContentUrlAPI: PropTypes.func.isRequired,
}