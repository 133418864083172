import React from 'react';
import BookingAPI from "../../api/BookingAPI";
import {swal, swalError, swalLoading, swalSuccess} from '../../helpers/SweetAlertWrapper';
import ItemChooserPage from "./ItemChooserPage";
import DateChooserPage from "./DateChooserPage";
import filtraSedi, {extendSediListWithItemPickerData} from "./helpers/sedi";
import filtraServizi, {extendServiceListWithItemPickerData} from "./helpers/servizi";
import filtraDipendenti, {extendDipendentiListWithItemPickerData} from "./helpers/dipendenti";

import {filterCategoryTree} from "./helpers/categorie";
import ServiziChooserPage from "./ServiziPicker/ServiziChooserPage";
import ListGroupPage from "../BaseComponents/ListGroupPage";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";
import {CategoryPickerPage} from "./CategoryPicker/CategoryPickerPage";
import {PageZLayerSelector} from "../BaseComponents/Page/PageZLayerSelector";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {
    CTA_GROUP_BOOK_SUCCESS,
    CTA_SERVICE_BOOK_SUCCESS,
    SHOW_BANNER_PARTNERSHIP, UPDATE_USER_BOOKS,
    USERS_CONVERSION_PARTNERHIP
} from "../../const/AppEventsCostants";
import {arrayShuffle} from "../../helpers/ArrayUtility";
import {SHOW_TYPE_PARTNERSHIP_PRENOTAZIONI_SERVIZI} from "../PartnershipNegozi/PartnershipConstants";
import {ResumePage} from "./ResumePage";
import {ObjectArrayGroupByKey} from "../../helpers/ObjectArrayGroupByKey";
import {DIPENDENTE_ANYONE_OPTION} from "../../const/ModuloServiziCostants";
import {BookizonAppManager} from "../../index";
import {NM_ANDROID_ON_RESUME, NM_IN_APP_BROWSER_DISMISS} from "../../native/NativeMessageHandler";
import {StripeAPI} from "../../api/StripeAPI";
import {ModuloServiziAPI} from "../../api/ModuloServizi/ModuloServiziAPI";
import PropTypes from "prop-types";

const PAYMENT_LINK_EXPIRE_MINUTES = 15;

export default class BookingManager extends React.Component {
    constructor(props) {
        super(props);
        this.infoNegozio = (this.props.infoNegozio || NegozioOpenStore.infoNegozio);
        this.state = {
            listaServizi: null,
            listaServiziCategoryGrouped: null,
            listaSedi: null,
            listaDipendenti: null,
            listaCategorie: null,
            negozioUserInfo: null,
            filtered: {
                listaServizi: null,
                listaCategorie: null,
                listaSedi: null,
                listaDipendenti: null
            },
            bookingFlow: this.infoNegozio.impostazioni.booking_flow,
            bookingSteps: getBookingFlowSteps(this.infoNegozio.impostazioni.booking_flow),
            currentStep: -1,
            bookInfo: props.bookInfo ? props.bookInfo : {},
            resumeOpen: false,
            serviziMatching: null,
            payment: {
                gateway_payment_id: null,
                gateway_payment_link: null,
                gateway_metadata: null,
                timestamp: 0
            },
            confirmMessage: ''
        };

        this.advancePaymentData = {
            min: parseFloat(this.infoNegozio.impostazioni.servizi_acconto_min_price),
            perc: parseFloat(this.infoNegozio.impostazioni.servizi_acconto_perc)
        }

        //Bindo gli eventi
        this.goBackStep = this.goBackStep.bind(this);
        this.goNextStep = this.goNextStep.bind(this);
    }

    componentDidMount() {
        FuxEvents.on(NM_IN_APP_BROWSER_DISMISS, this.checkPayment);
        FuxEvents.on(NM_ANDROID_ON_RESUME, this.checkPayment);

        swalLoading('Attendere prego...');
        //Recupero la lista delle risorse che mi servono
        console.log("INFO PROMOZIONE", this.props.promotionChosenInfo)

        BookingAPI.getRichData(this.infoNegozio.id_negozio)
            .then(bookingData => {

                const listaServizi = extendServiceListWithItemPickerData(bookingData.servizi, this.infoNegozio.impostazioni);
                let state = {
                    listaServizi: listaServizi,
                    listaServiziCategoryGrouped: ObjectArrayGroupByKey(listaServizi, 'categoria'),
                    listaDipendenti: extendDipendentiListWithItemPickerData(bookingData.dipendenti, this.infoNegozio),
                    categoryTree: bookingData.categorie,
                    listaSedi: extendSediListWithItemPickerData(bookingData.sedi),
                    negozioUserInfo: bookingData.user,
                    serviziMatching: bookingData.servizi_matching
                };

                if (this.state.bookingSteps.indexOf('sede') === -1) {
                    let bookInfo = {...this.state.bookInfo};
                    bookInfo.id_sede = bookingData.sedi[0].id_sede;
                    state.bookInfo = bookInfo;
                }


                this.setState(state);

                return true;
            })
            .then(() => {
                swal.safeClose();
                this.filterLists();

                this.setState({currentStep: 0}, _ => {
                    const currentStepName = this.state.bookingSteps[this.state.currentStep];
                    //Se il primo step da visualizzare è quello dei servizi/categoria e c'è esplicita richiesta di saltarlo allora vado avanti
                    if (this.props.skipServiceSelection){
                        if (currentStepName === 'servizio' || currentStepName === 'categoria') {
                            this.goNextStep('id_servizio', this.state.bookInfo.id_servizio);
                        }
                    }
                });
                return true;
            })
            .catch((e) => {
                console.log(e);
                this.props.onBookDismiss(e);
            });
    }


    componentWillUnmount() {
        FuxEvents.off(NM_IN_APP_BROWSER_DISMISS, this.checkPayment);
        FuxEvents.off(NM_ANDROID_ON_RESUME, this.checkPayment);
    }


    //Filtra le varie liste in base ad uno step
    filterLists(step, cb) {
        const filteredLists = {}; //listaServizi: null, lista Sedi: null, listaDipendenti: null, listaCategorie: null

        const promotion = this.props.promotionChosenInfo || null //Utilizzato per rendere più semplice la lettura delle condizioni
        let categoriesInPromo = [];
        let servicesInPromo = [];
        if (promotion) {
            //Prendo le categorie in promo se ci sono
            categoriesInPromo = !!(promotion.categories && promotion.categories.modulo_servizi) ? promotion.categories.modulo_servizi : []
            //Prendo i servizi in promo se ci sono
            servicesInPromo = !!(promotion.items && promotion.items.modulo_servizi) ? promotion.items.modulo_servizi : []
        }

        const sediSensitiveSteps = ['dipendente', 'servizio'];
        if (!step || sediSensitiveSteps.indexOf(step) > -1) {
            console.log('FILTRATE SEDI');
            filteredLists.listaSedi = filtraSedi(this.state.listaSedi, this.state.listaServizi, this.state.listaDipendenti, this.state.bookInfo);
        }

        const categorySensitiveSteps = ['dipendente'];
        if (!step || categorySensitiveSteps.indexOf(step) > -1 || !!categoriesInPromo || !!servicesInPromo) {
            console.log('FILTRATE CATEGORIE');
            filteredLists.categoryTree = filterCategoryTree({...this.state}, categoriesInPromo, servicesInPromo);
        }

        const serviziSensitiveSteps = ['dipendente', 'categoria', 'sede'];
        if (!step || serviziSensitiveSteps.indexOf(step) > -1 || !!servicesInPromo) {
            console.log('FILTRATI SERVIZI');
            filteredLists.listaServizi = filtraServizi(this.state.listaServizi, this.state.listaServiziCategoryGrouped, this.state.listaSedi, this.state.listaDipendenti, this.state.bookInfo, categoriesInPromo, servicesInPromo);
        }

        const dipendentiSensitiveSteps = ['servizio', 'categoria', 'sede'];
        if (!step || dipendentiSensitiveSteps.indexOf(step) > -1) {
            console.log('FILTRATI DIPENDENTI');
            filteredLists.listaDipendenti = filtraDipendenti(this.state.listaDipendenti, this.state.listaServizi, this.state.listaSedi, this.state.bookInfo);

            const canShowAnyoneOption =
                //L'opzione non è disabilitata dall'admin e ho almeno 2 dipendenti da visualizzare
                (!parseInt(this.infoNegozio.impostazioni.disable_anyone_option) && filteredLists.listaDipendenti.length > 1)
                ||
                //Non ci sono dipendenti da visualizzare per la combinazione di servizi scelti ed il negozio ha la divisione servizi attiva
                (filteredLists.listaDipendenti.length === 0 && parseInt(this.infoNegozio.impostazioni.split_services_dipendenti));

            if (canShowAnyoneOption) {
                filteredLists.listaDipendenti.push({
                    id_dipendente: DIPENDENTE_ANYONE_OPTION,
                    nome: 'Chiunque',
                    itemPickerData: {
                        text: "Chiunque",
                        value: DIPENDENTE_ANYONE_OPTION,
                        image: ""
                    }
                });
            }
        }

        this.setState({filtered: {...this.state.filtered, ...filteredLists}}, cb);
    }

    prenota(bookInfo, attempt) {
        if (!bookInfo.id_dipendente || bookInfo.id_dipendente === DIPENDENTE_ANYONE_OPTION) {
            bookInfo.id_dipendente = bookInfo.dipendenti[attempt]; //Setto il dipendente al PRIMO disponibile
            bookInfo.dipendente_chiunque = 1
        }
        BookingAPI.book(bookInfo)
            .then((response) => {
                const data = response.data;
                if (data.payment) {
                    this.setState({
                        id_prenotazione: data.id_prenotazione,
                        payment: {
                            gateway_payment_id: data.payment.gateway_payment_id,
                            gateway_payment_link: data.payment.gateway_payment_link,
                            gateway_metadata: data.payment.gateway_metadata,
                            timestamp: (new Date()).getTime()
                        },
                        confirmMessage: response.message
                    }, _ => {
                        //Apro la pagina di pagamento
                        BookizonAppManager.openInAppBrowser(this.state.payment.gateway_payment_link);
                    });
                } else {
                    swalSuccess(response.message);
                    this.props.onBookSuccess();
                    if (!this.infoNegozio.wasAdded) {
                        FuxEvents.emit(CTA_SERVICE_BOOK_SUCCESS);
                    } else {
                        FuxEvents.emit(SHOW_BANNER_PARTNERSHIP, SHOW_TYPE_PARTNERSHIP_PRENOTAZIONI_SERVIZI);
                    }
                    FuxEvents.emit(USERS_CONVERSION_PARTNERHIP, {
                        "tipo_operazione": "prenotazione_servizi",
                        "id_negozio": this.infoNegozio.id_negozio
                    });
                }
            })
            .catch((message) => {
                if (bookInfo.dipendenti[attempt + 1] !== undefined) {
                    bookInfo.id_dipendente = bookInfo.dipendenti[attempt + 1]; //Cambio il dipendente con il prossimo disponibile
                    this.prenota(bookInfo, attempt + 1);
                } else {
                    swalError(message);
                }
            });
    }

    async goNextStep(newBookInfoField, newBookInfoValue) {
        console.log(newBookInfoField, newBookInfoValue)
        //Aggiorno lo stato con i dati ricevuti
        var newBookInfoData = {};
        if (newBookInfoField === 'datetime') {
            newBookInfoData.data = newBookInfoValue.data;
            newBookInfoData.orario = newBookInfoValue.orario;
            newBookInfoData.dipendenti = arrayShuffle(newBookInfoValue.dipendenti);
        } else {
            if (newBookInfoValue !== DIPENDENTE_ANYONE_OPTION) {
                newBookInfoData[newBookInfoField] = newBookInfoValue;
            } else {
                newBookInfoData[newBookInfoField] = undefined;
            }
        }
        const stepName = this.state.bookingSteps[this.state.currentStep];
        const nextStepIdx = Math.min(this.state.currentStep + 1, this.state.bookingSteps.length - 1);
        this.setState({
            bookInfo: Object.assign(this.state.bookInfo, newBookInfoData),
            //Aggiorno lo stato per passare allo step successivo
            currentStep: nextStepIdx,
            //Se questo era l'ultimo step (la data) allora apro la pagina di resume
            resumeOpen: newBookInfoField === 'datetime'
        }, _ => {
            this.filterLists(stepName, _ => {
                const currentStepName = this.state.bookingSteps[this.state.currentStep];
                //Se il prossimo step da visualizzare è quello del dipendente
                if (currentStepName === 'dipendente') {
                    //Se dopo l'applicazione dei filtri rimane un solo dipendente
                    if (this.state.filtered.listaDipendenti.length === 1) {
                        //Setto il dipendente selezionato a quello rimanente e vado al prossimo step
                        console.log("DIpendene settato", this.state.filtered.listaDipendenti[0].id_dipendente)
                        this.goNextStep('id_dipendente', this.state.filtered.listaDipendenti[0].id_dipendente);
                    }
                    if (this.state.filtered.listaDipendenti.length === 0) {
                        swalError('Non esiste un unico collaboratore che possa eseguire tutti i servizi che hai scelto, rimuovi alcuni servizi selezionati e riprova.')
                        this.goBackStep('id_dipendente');
                    }
                }

                //Se il prossimo step da visualizzare è quello dei servizi/categoria e c'è esplicita richiesta di saltarlo allora vado avanti
                if (this.props.skipServiceSelection){
                    if (currentStepName === 'servizio' || currentStepName === 'categoria') {
                        this.goNextStep('id_servizio', this.state.bookInfo.id_servizio);
                    }
                }
            });
            if (this.state.currentStep === this.state.bookingSteps.length) {
                this.handleResumePageOpen();
            }
        });
    }


    /**
     * @param fieldToRemove Indica il campo da rimuovere dall'attuale bookInfo, ovvero il parametro dal quale si sta tornando indietro.
     * */
    goBackStep(fieldToRemove) {
        if (this.state.currentStep - 1 >= 0) {
            var state = {...this.state};
            state.currentStep = this.state.currentStep - 1;
            if (fieldToRemove !== 'id_servizio') {
                delete state.bookInfo[fieldToRemove];
            }
            this.setState(state, _ => {
                //Se il prossimo step da visualizzare è quello del dipendente
                if (this.state.bookingSteps[this.state.currentStep] === 'dipendente') {
                    //Se c'è un solo dipendente (significa che in fase di go next è stato autoselezionato)
                    if (this.state.filtered.listaDipendenti.length === 1) {
                        //Forzo un go back
                        this.goBackStep('id_dipendente');
                    }
                }
            });
            this.filterLists();
            console.log(this.state.bookInfo);
        } else {
            this.props.onBookDismiss();
        }
    }

    getCurrentStep() {
        var stepKeys = {
            "servizio": "id_servizio",
            "categoria": "id_categoria",
            "sede": "id_sede",
            "dipendente": "id_dipendente",
            "data": "datetime"
        };
        var step = this.state.bookingSteps[this.state.currentStep];
        return stepKeys [step];
    }

    canShowStep(stepName) {
        if (stepName === 'dipendente' && this.state.currentStep > 0) {
            if (this.state.filtered.listaDipendenti.length === 1) return false; //Se c'è un solo dipendente lo step è gestito internamente dal componente
        }
        return this.state.currentStep >= this.state.bookingSteps.indexOf(stepName) && this.state.bookingSteps.includes(stepName);
    }


    handleRepeatChange = e => {
        this.setState({
            bookInfo: {...this.state.bookInfo, repeat: e.target.value}
        });
    }

    getSedePage() {
        return (
            <ListGroupPage
                key={"Bookstep_sede"}
                style={{"zIndex": this.getCurrentStep() === 'sede' ? 2001 : 2000}}
                title={"Prenota"}
                subtitle={<h6 className={"text-muted font-weight-bold mb-3"}>Scegli la sede</h6>}
                items={this.state.filtered.listaSedi.map(s => s.itemPickerData)}
                promotionDiscount={this.props.promotionChosenInfo ? this.props.promotionChosenInfo.percentuale : null}
                active={this.canShowStep('sede')}
                onDismiss={this.goBackStep.bind(this, 'id_sede')}
                onChoose={this.goNextStep.bind(this, 'id_sede')}
                required={true}
            />
        );
    }

    getCategoriaPage() {
        return (
            this.canShowStep('categoria') &&
            <PageZLayerSelector
                key={"Bookstep_categoria"}
                zIndex={this.getCurrentStep() === 'categoria' ? 2001 : 2000}
            >
                <CategoryPickerPage
                    categoryTree={this.state.filtered.categoryTree}
                    onDismiss={this.goBackStep.bind(this, 'id_categoria')}
                    onLeafChoose={this.goNextStep.bind(this, 'id_categoria')}
                    promotionDiscount={this.props.promotionChosenInfo ? this.props.promotionChosenInfo.percentuale : null}
                />
            </PageZLayerSelector>
        )
    }

    getServizioPage() {
        const maxRepeat = this.state.negozioUserInfo && parseInt(this.state.negozioUserInfo.prenotazioni_parallele_modulo_servizi || 0)
            ? this.state.filtered.listaDipendenti.filter(d => d.value !== DIPENDENTE_ANYONE_OPTION).length
            : 0;
        return (
            <ServiziChooserPage
                title={'Prenota'}
                key={"Bookstep_servizio"}
                style={{"zIndex": this.getCurrentStep() === 'servizio' ? 2001 : 2000}}
                items={this.state.filtered.listaServizi.map(s => s.itemPickerData)}
                allItems={this.state.listaServizi}
                promotionDiscount={this.props.promotionChosenInfo ? this.props.promotionChosenInfo.percentuale : null}
                active={this.canShowStep('servizio')}
                onDismiss={this.goBackStep.bind(this, 'id_servizio')}
                onChoose={this.goNextStep.bind(this, 'id_servizio')}
                selected={this.state.bookInfo.id_servizio || []}
                shoppingCartEnabled={this.state.currentStep < this.state.bookingSteps.length - 1}
                repeat={this.state.bookInfo.repeat || 1}
                maxRepeat={maxRepeat}
                onRepeatChange={this.handleRepeatChange}
                showPrices={parseInt(this.infoNegozio.impostazioni.hide_services_price) !== 1}
                matching={this.state.serviziMatching}
            />
        );
    }

    getDipendentePage() {
        return (
            <ItemChooserPage
                key={"Bookstep_dipendente"}
                style={{"zIndex": this.getCurrentStep() === 'dipendente' ? 2001 : 2000}}
                title={"Prenota"}
                subtitle={`Scegli ${this.infoNegozio.impostazioni.alias_dipendenti || 'collaboratore'}`}
                items={this.state.filtered.listaDipendenti.map(d => d.itemPickerData)}
                promotionDiscount={this.props.promotionChosenInfo ? this.props.promotionChosenInfo.percentuale : null}
                active={this.canShowStep('dipendente')}
                onDismiss={this.goBackStep.bind(this, 'id_dipendente')}
                onChoose={this.goNextStep.bind(this, 'id_dipendente')}
                required={true}
            />
        );
    }

    getStepsPages() {
        let pages = [];
        this.state.bookingSteps.map(step => {
            switch (step) {
                case 'sede':
                    pages.push(this.getSedePage());
                    break;
                case 'dipendente':
                    pages.push(this.getDipendentePage());
                    break;
                case 'categoria':
                    pages.push(this.getCategoriaPage());
                    break;
                case 'servizio':
                    if (!this.props.bookInfo?.id_servizio) pages.push(this.getServizioPage());
                    break;
            }
        });
        return pages;
    }

    handleResumePageOpen = _ => this.setState({resumeOpen: true});
    handleResumePageDismiss = _ => {
        if (this.state.payment.gateway_payment_id) {
            swalLoading('Annullamento prenotazione in corso...');
            ModuloServiziAPI.BookingEngine.cancelBookWithPaymentId(this.state.payment.gateway_payment_id)
                .then(_ => {
                    this.setState({
                        resumeOpen: false,
                        payment: {
                            gateway_payment_id: null,
                            gateway_payment_link: null,
                            gateway_metadata: null,
                            timestamp: 0
                        }
                    }, swal.safeClose);
                }).catch(swalError);
        } else {
            this.setState({resumeOpen: false});
        }
    }

    handleResumeConfirm = ({noteUtente, shippingNotes, coupon, wallets, packages, payFullAmount}) => {
        //Se è già stato creato una prenotazione e ordine di pagamento per questi dati allora mostro subito la pagina di pagamento
        if (this.state.payment.gateway_payment_link) {
            const ts = (new Date()).getTime();
            const minutesDiff = (ts - this.state.payment.timestamp) / 1000 / 60;
            if (minutesDiff < PAYMENT_LINK_EXPIRE_MINUTES) { //Se non è ancora passato il tempo di scadenza del link di pagamento
                BookizonAppManager.openInAppBrowser(this.state.payment.gateway_payment_link);
                return;
            }
        }

        const bookInfo = {
            ...this.state.bookInfo,
            note_utente: noteUtente,
            coupon: coupon,
            wallets: wallets,
            packages: packages,
            payFullAmount: payFullAmount
        };

        console.log(bookInfo, "ECCOLE LE INFO")

        if (shippingNotes) bookInfo.note_utente += '\nDati spedizione: ' + shippingNotes;

        swalLoading();
        this.prenota(bookInfo, 0);
    }

    checkPayment = _ => {
        if (this.state.payment.gateway_payment_id) {
            swalLoading('Recupero dati pagamento in corso...');
            StripeAPI.verifyPaymentIntent(this.state.payment.gateway_payment_id, this.state.payment.gateway_metadata.id_negozio, this.state.payment.gateway_metadata.is_direct)
                .then(_ => {
                    swalSuccess(this.state.confirmMessage);
                    this.props.onBookSuccess();
                    if (!this.infoNegozio.wasAdded) {
                        FuxEvents.emit(CTA_SERVICE_BOOK_SUCCESS);
                    } else {
                        FuxEvents.emit(SHOW_BANNER_PARTNERSHIP, SHOW_TYPE_PARTNERSHIP_PRENOTAZIONI_SERVIZI);
                    }
                    FuxEvents.emit(USERS_CONVERSION_PARTNERHIP, {
                        "tipo_operazione": "prenotazione_servizi",
                        "id_negozio": this.infoNegozio.id_negozio
                    });
                })
                .catch(swalError)
        }
    }

    render() {
        if (this.state.currentStep < 0) {
            return '';
        }
        return (
            <div>
                {this.getStepsPages()}
                <DateChooserPage
                    bookInfo={this.state.bookInfo}
                    active={this.canShowStep('data')}
                    onDismiss={this.goBackStep.bind(this, 'datetime')}
                    onChoose={this.goNextStep.bind(this, 'datetime')}
                    maxVisibleDays={parseInt(this.infoNegozio.impostazioni.servizi_max_visible_days || 0)}
                />
                {
                    this.state.resumeOpen &&
                    <ResumePage
                        onDismiss={this.handleResumePageDismiss}
                        onConfirm={this.handleResumeConfirm}
                        bookInfo={this.state.bookInfo}
                        listaServizi={this.state.listaServizi}
                        showNotes={parseInt(this.infoNegozio.impostazioni.allow_notes) === 1}
                        notesPlaceholder={this.infoNegozio.impostazioni.notes_placeholder || 'Scrivi note aggiuntive per la tua prenotazione'}
                        showShippingNotes={parseInt(this.infoNegozio.impostazioni.allow_shipping_notes) === 1}
                        shippingNotesPlaceholder={this.infoNegozio.impostazioni.shipping_notes_placeholder || "Inserisci l'indirizzo a cui spedire"}
                        showCouponField={true}
                        externalCoupon={this.props.promotionChosenInfo ? this.props.promotionChosenInfo.codice_coupon : null}
                        advancePaymentData={this.infoNegozio.impostazioni.servizi_acconto_enable && parseInt(this.infoNegozio.impostazioni.servizi_acconto_enable) === 1 ? this.advancePaymentData : null}
                        enablePaymentOfFullAmount={this.infoNegozio.impostazioni.servizi_payment_enable && parseInt(this.infoNegozio.impostazioni.servizi_payment_enable) === 1}
                        showPrices={parseInt(this.infoNegozio.impostazioni.hide_services_price) !== 1}
                        paymentData={this.state.payment}
                    />
                }
            </div>
        );

    }
}

BookingManager.propTypes = {
    bookInfo: PropTypes.object,
    infoNegozio: PropTypes.object,
    onBookSuccess: PropTypes.func,
    onBookDismiss: PropTypes.func,
    promotionChosenInfo: PropTypes.object,
    skipServiceSelection: PropTypes.bool,
}

function getBookingFlowSteps(bookingMode) {
    let steps = bookingMode.split("_");
    for (let i = 1; i < steps.length; i++) {
        if (steps[i] === "auto") {
            delete steps[i]
            delete steps[i - 1];
        }
    }
    steps.push("data");
    return steps.filter(v => !!v);
}
