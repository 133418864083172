import React from "react";
import PropTypes from "prop-types";

/**
 * Component that alerts if you click outside of it
 */
export class OutsideClickListener extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.onOutSideClick();
        }
    }

    render() {
        return <div style={this.props.style} className={this.props.className} ref={this.wrapperRef}>{this.props.children}</div>;
    }
}

OutsideClickListener.propTypes = {
    children: PropTypes.element.isRequired,
    onOutSideClick: PropTypes.func.isRequired
};