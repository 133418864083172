import React from "react";
import styled from "styled-components";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import {TabViewItem} from "../../../components/BaseComponents/TabView";
import FeedPreferredContainer from "./FeedPreferredContainer";
import {ReactComponent as BookizonIcon} from '../../svg/logo-icon.svg';
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {
    NM_ANDROID_ON_RESUME,
    NM_GEOLOCATION_PERMISSION,
    NM_GEOLOCATION_UPDATE,
    NM_IOS_ENTER_FOREGROUND
} from "../../../native/NativeMessageHandler";
import PullToRefresh from "pulltorefreshjs";
import Store from "../../../components/Store";
import FeedGeolocatedContainer from "./FeedGeolocatedContainer";
import {BookizonAppManager} from "../../../index";
import mappaTelefono from "../../svg/mappa-telefono.svg";
import cityLocation from "../../svg/city-location.svg";
import {SearchingLocationImage} from "../../SearchAppPage/SearchAppPage";
import ReactDOMServer from "react-dom/server";
import NativeGeolocationProvider from "../../../providers/NativeGeolocationProvider";
import {swal, swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";

const FEED_TAB_NEAREST = 'nearest';
const FEED_TAB_PREFERRED = 'preferred';

export default class FeedTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: FEED_TAB_NEAREST,
            lat: null,
            lng: null,
            hasGeolocationPermission: false,
        }
        this.geoFeedRef = null;
        this.geoFeedComponentRef = null;
        this.feedRef = null;
        this.feedComponentRef = null;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.active !== this.props.active ||
            nextState.activeTab !== this.state.activeTab ||
            nextState.hasFullscreenPost !== this.state.hasFullscreenPost ||
            nextState.lat !== this.state.lat ||
            nextState.lng !== this.state.lng ||
            nextState.hasGeolocationPermission !== this.state.hasGeolocationPermission;
    }

    handleActiveTabChange = activeTab => {
        if (activeTab === this.state.activeTab) {
            //Scroll a 0 della tab attiva
            scrollTo(activeTab === FEED_TAB_PREFERRED ? this.feedRef : this.geoFeedRef, 0, 200);
        }
        this.setState({activeTab: activeTab});
    }

    canPullToRefresh = _ => {
        if (this.state.activeTab === FEED_TAB_NEAREST && this.geoFeedRef) {
            return !this.geoFeedRef.scrollTop
        }
        if (this.state.activeTab === FEED_TAB_PREFERRED && this.feedRef) {
            return !this.feedRef.scrollTop
        }
        return false;
    }

    onRefresh = (resolve, reject) => {
        if (this.state.activeTab === FEED_TAB_NEAREST) {
            return !this.geoFeedComponentRef.onRefresh(resolve, reject);
        }
        if (this.state.activeTab === FEED_TAB_PREFERRED) {
            return !this.feedComponentRef.onRefresh(resolve, reject);
        }
    }

    forceRefresh = _ => {
        if (this.geoFeedComponentRef) {
            this.geoFeedComponentRef.componentDidMount();
        }
        if (this.feedComponentRef) {
            this.feedComponentRef.componentDidMount();
        }
    }

    componentDidMount() {
        //Allo startup del componente verifico se ho già i permessi. Se li ho rimango SUBITO in attesa della posizione
        NativeGeolocationProvider.getPermission()
            .then(permission => {
                this.setState({hasGeolocationPermission: permission});
                if (permission) {
                    this.waitForPosition();
                }
            });

        PullToRefresh.init({
            mainElement: document.getElementById("feed-container"),
            triggerElement: document.getElementById("feed-container"),
            onRefresh: this.onRefresh,
            instructionsPullToRefresh: 'Tira per aggiornare',
            instructionsReleaseToRefresh: 'Rilascia per aggiornare',
            instructionsRefreshing: ' ',
            shouldPullToRefresh: this.canPullToRefresh,
            iconArrow: ReactDOMServer.renderToString(
                <FaSolidIcon name={"chevron-down"}/>
            ),
            iconRefreshing: ReactDOMServer.renderToString(
                <FaSolidIcon name={"spinner fa-pulse"}/>
            ),
        });

        FuxEvents.on(NM_IOS_ENTER_FOREGROUND, this.forceRefresh);
        FuxEvents.on(NM_ANDROID_ON_RESUME, this.forceRefresh);
    }

    componentWillUnmount() {
        PullToRefresh.destroyAll();
        FuxEvents.off(NM_IOS_ENTER_FOREGROUND, this.forceRefresh);
        FuxEvents.off(NM_ANDROID_ON_RESUME, this.forceRefresh);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.active && this.props.active) {
            //Quando il componente diviene attivo
            NativeGeolocationProvider.getPermission()
                .then(permission => {
                    console.log("[NM]", "FEED", permission)
                    if (permission) {
                        this.setState({hasGeolocationPermission: true});
                        this.waitForPosition();
                    } else {
                        NativeGeolocationProvider.askPermission();
                        NativeGeolocationProvider.waitForPermissionGrant().then(_ => {
                            this.setState({hasGeolocationPermission: true});
                            this.waitForPosition();
                        });
                    }
                });
        }
    }

    /**
     * GEOLOCATION MANAGEMENT
     * */
    waitForPosition = _ => {
        console.log("[FEED]", "ASPETTANDO POSIZIONE");
        NativeGeolocationProvider.waitForPosition('FEED')
            .then(location => {
                console.log("[FEED]", "POSIZIONE PRESA");
                if (location) {
                    this.setState({
                        lat: location.lat,
                        lng: location.lng
                    });
                }
            });
    }


    handleGeolocationFeedContainerRef = ref => {
        if (ref) {
            this.geoFeedRef = ref;
            ref.scrollTop = 1; //Simulo lo scroll di 1px così da precaricare la prima immagine del feed
        }
    }
    getGeoFeedComponentRef = ref => this.geoFeedComponentRef = ref;
    handleFeedContainerRef = ref => {
        if (ref) {
            this.feedRef = ref;
            ref.scrollTop = 1; //Simulo lo scroll di 1px così da precaricare la prima immagine del feed
        }
    }
    getFeedComponentRef = ref => this.feedComponentRef = ref;


    render() {
        return (
            <div id="feed-container" className={this.props.active ? '' : 'd-none'}>
                <div className={"container-fluid bg-white sticky-top shadow-sm"}>
                    <div className={"row"}>
                        <TabViewItem
                            onClick={_ => this.handleActiveTabChange(FEED_TAB_NEAREST)}
                            className={"col py-2 text-center text-muted text-uppercase" + (this.state.activeTab === FEED_TAB_NEAREST ? ' active' : '')}
                            activeColor={"#293069"}
                        >
                            <FaSolidIcon name={"location-arrow"}/> Vicino a te
                        </TabViewItem>
                        {
                            !!this.props.user &&
                            <TabViewItem
                                onClick={_ => this.handleActiveTabChange(FEED_TAB_PREFERRED)}
                                className={"col py-2 text-center text-muted text-uppercase" + (this.state.activeTab === FEED_TAB_PREFERRED ? ' active' : '')}
                                activeColor={"#293069"}
                            >
                                <BookizonIconWrapper><BookizonIcon/></BookizonIconWrapper> Dalle tue app
                            </TabViewItem>
                        }
                    </div>
                </div>
                <div className={"container px-0"}>
                    <div className={this.state.activeTab === FEED_TAB_NEAREST ? '' : 'd-none'}>
                        {/* Posizione disponibile mostro il feed */}
                        {
                            !!this.state.lat &&
                            <FeedGeolocatedContainer
                                ref={this.getGeoFeedComponentRef}
                                active={this.state.activeTab === FEED_TAB_NEAREST}
                                onScrollPaneRef={this.handleGeolocationFeedContainerRef}
                                lat={this.state.lat} lng={this.state.lng}
                                onAppAdd={this.props.onAppAdd}
                                onAppOpen={this.props.onAppOpen}
                            />
                        }
                        {/* Nessun permesso di geolocalizzazione e quindi nessuna posizione */}
                        {
                            (!this.state.hasGeolocationPermission && !this.state.lat) &&
                            <div className={"container text-center mt-5"}>
                                <h5 className={"text-center mt-3 text-muted"}>
                                    Scopri cosa dicono le attività<br/>
                                    <span className={"text-primary text-uppercase font-weight-bold"}>
                                        intorno a te!
                                    </span>
                                </h5>
                                <img src={cityLocation} className={"w-75 mt-2"} alt={"City location"}/>
                                <h6 className={"text-center mt-3 text-muted mb-4"}>
                                    Bookizon non può rilevare la tua posizione, usa il pulsante qui
                                    sotto per autorizzare l'app
                                </h6>

                                <button className={"btn btn-soft-primary border-primary mx-auto"}
                                        onClick={BookizonAppManager.openGeolocationSettings}>
                                    <FaSolidIcon name={"location-arrow"}/> Vai alle impostazioni
                                </button>
                            </div>

                        }
                        {/* Permessi di geolocalizzazione dati, in attesa di posizione */}
                        {
                            (this.state.hasGeolocationPermission && !this.state.lat) &&
                            <div className={"container text-center mt-5"}>
                                <SearchingLocationImage src={mappaTelefono} className={"w-75 mt-5"}/>
                                <h5 className={"text-center mt-3 text-muted"}>
                                    Attendi qualche secondo... stiamo cercando di recuperare la tua posizione
                                </h5>
                            </div>
                        }
                    </div>
                    <div className={this.state.activeTab === FEED_TAB_PREFERRED ? '' : 'd-none'}>
                        {
                            !!this.props.user &&
                            <FeedPreferredContainer
                                ref={this.getFeedComponentRef}
                                active={this.state.activeTab === FEED_TAB_PREFERRED}
                                onAppAdd={this.props.onAppAdd}
                                onAppOpen={this.props.onAppOpen}
                                onScrollPaneRef={this.handleFeedContainerRef}
                            />
                        }
                    </div>
                </div>
            </div>

        );
    }

}

const BookizonIconWrapper = styled.span`
    & svg{
        height:21px;
        vertical-align:top;
    }

    & .cls-2{
        fill: #a9bcc9;
    }

    .active & .cls-2{
        fill: #293069;
    }
`;

function scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function () {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};
