import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import Page from "../../components/BaseComponents/Page/Page";
import {UserDisputeListViewItem} from "./UserDisputeListViewItem";
import ModalPortalHOC from "../../components/BaseComponents/ModalPortalHOC";
import {UserWithBusinessDisputePanel} from "./UserWithBusinessDisputePanel";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {USER_DISPUTE_CHAT_READ_EVT} from "../../const/AppEventsCostants";
import {UserChatAPI} from "../../api/Chat/UserChatAPI";

export class UserDisputeListPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disputeOpen: null,
        }
    }

    componentDidMount() {
        FuxEvents.on(USER_DISPUTE_CHAT_READ_EVT, this.silentUpdate)
    }

    componentWillUnmount() {
        FuxEvents.off(USER_DISPUTE_CHAT_READ_EVT, this.silentUpdate)
    }

    handleDisputeOpen = dispute => {
        this.setState({disputeOpen: dispute});
        //imposto come letti i messaggi della chat aperta
        UserChatAPI.setRead(dispute.id_chat_room)
            .then(_ => FuxEvents.emit(USER_DISPUTE_CHAT_READ_EVT));
    }

    handleDisputeClose = silent => {
        this.setState({disputeOpen: null});
    }

    silentUpdate = _ => this.props.onUpdate(true);
    visibleUpdate = _ => this.props.onUpdate(false);

    render() {
        return (
            <Fragment>
                <Page
                    active={true}
                    title={"Richieste di rimborso"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    rightIcon={<FaSolidIcon name={"sync"}/>}
                    rightIconText={"Aggiorna"}
                    onRightIconClick={this.visibleUpdate}
                >
                    <div className={"container mt-3"}>
                        {
                            this.props.disputes.map((d, i) =>
                                <UserDisputeListViewItem
                                    className={i === 0 ? 'border-top-0' : ''}
                                    key={d.id_chat_room}
                                    onClick={this.handleDisputeOpen}
                                    disputeData={d}
                                    newMessageNumber={parseInt(d.nuovi_messaggi)}
                                />
                            )
                        }
                    </div>
                </Page>
                <ModalPortalHOC>
                    {
                        this.state.disputeOpen &&
                        <UserWithBusinessDisputePanel
                            disputeData={this.state.disputeOpen}
                            onClose={this.handleDisputeClose}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

UserDisputeListPage.propTypes = {
    disputes: PropTypes.array,
    onDismiss: PropTypes.func,
    onUpdate: PropTypes.func,
}