import React from 'react';
import ReactDOM from 'react-dom';
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import styled from "styled-components"
import {swal} from "../../helpers/SweetAlertWrapper";
import ShareComponent from "../ShareComponent";

const ListCardItemImage = styled.div`
width:70px;
height:70px;
background-position:center center;
background-repeat:no-repeat;
background-size:cover;
background-image:url(${props => props.image});
border-radius:50%;
`;

export default class RadioListCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            itemsName: "RadioListGroup_" + (Math.random() * 1000).toFixed(0)
        };
    }

    handleChange = (value) => {
        this.setState({selected: value});
        this.props.onChange(value);
    }

    render() {
        return (
            <div>
                {!this.props.items.length ? 'Nessuna opzione selezionabile' : ''}
                {this.props.items.map((item, i) => {
                    return (
                        <ListCardRadioItem
                            key={i}
                            name={this.state.itemsName}
                            checked={this.state.selected === item.value}
                            onChange={this.handleChange}
                            {...item}
                        />
                    )
                })}
            </div>
        )
    }

}


class ListCardRadioItem extends React.Component {

    constructor(props) {
        super(props);
    }

    handleChange = (changeEvent) => {
        this.props.onChange(changeEvent.target.value);
    }

    handleImageClick = e => {
        e.stopPropagation();
        e.preventDefault();
        swal.fire({
            html: <img src={this.props.image} className={"img-fluid"}/>,
            showConfirmButton: false,
            showCloseButton: true,
            focusCancel: false, focusConfirm: false
        });
    }

    render() {
        return (
            <label className={"card shadow-sm border-0 bg-main-z2 my-2"}>
                <div className="card-body">
                    <div className={"row align-items-center"}>
                        <div className={"fux-radiobox " + (this.props.image ? "col-8" : "col-12")}>
                            <input type="radio" name={this.props.name} checked={this.props.checked}
                                   value={this.props.value} onChange={this.handleChange}/>
                            <span className="text-on-bg-main">
                                {this.props.checked ? <FaSolidIcon name={"check-circle"} className={"mr-2 text-primary"}/> :
                                    <FaRegularIcon name={"circle__"}/>}
                            </span>
                            <span className={"text-on-bg-main font-weight-bold"}>{this.props.text}</span>
                        </div>
                        {
                            this.props.image ?
                                <div className={"col p-0"}>
                                    <ListCardItemImage onClick={this.handleImageClick} className={"mx-auto"} image={this.props.image}/>
                                </div>
                                : ''
                        }
                    </div>
                    <small className={"d-block text-on-bg-main"}>{this.props.description}</small>
                </div>
            </label>
        )
    }

}
