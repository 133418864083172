import React, {Fragment} from "react";
import PropTypes from "prop-types";

export class ElementInViewObserver extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.intersectionObserver = null;
    }

    handleRef = el => {
        if (el) {
            this.intersectionObserver = new IntersectionObserver(
                this.intersectionCallback, {
                    root: null, threshold: 0
                }
            );
            this.intersectionObserver.observe(el);
        }
    }

    intersectionCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.props.onVisible();
            }
        });
    };

    render() {
        return (
            <div ref={this.handleRef}>
            </div>
        )
    }

}

ElementInViewObserver.propTypes = {
    onVisible: PropTypes.func.isRequired
}