import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {PacchettiAPI} from "../../../api/Pacchetti/PacchettiAPI";
import {EllipsisLoader} from "../../GenericUI/EllipsisLoader/EllipsisLoader";
import {PacchettiListItem} from "../PacchettiListPage/PacchettiListItem";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import moment from "moment";

export function PacchettoListaMovimentiItem(props) {
    const humanReadableDate = moment(props.movimento.data_creazione).format('D MMM YYYY HH:mm');

    return (
        <Fragment>
            <div className={"bg-main-z3 p-3 shadow-sm rounded-xl mb-2"}>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <div>
                        {
                            props.movimento.punti > 0 ?
                                <FaSolidIcon name={"long-arrow-alt-down"} className={"text-success"}/> :
                                <FaSolidIcon name={"long-arrow-alt-up"} className={"text-danger"}/>
                        }
                        &nbsp;&nbsp;&nbsp;
                        <b>
                            {props.movimento.punti > 0 && "+"} {props.movimento.punti}
                        </b>
                    </div>
                    <div className={"text-muted"}>
                        {humanReadableDate}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

PacchettoListaMovimentiItem.propTypes = {
    movimento: PropTypes.object.isRequired
}