import React from "react";
import {BookizonAppManager} from "../index";
import FuxEvents from "../lib/FuxFramework/FuxEvents";
import {NM_GEOLOCATION_PERMISSION, NM_GEOLOCATION_UPDATE} from "../native/NativeMessageHandler";
import {isLoading} from "sweetalert2";
import {UserAPI} from "../api/User/UserAPI";
import AuthAPI from "../api/AuthAPI";

var NativeGeolocationProvider = {
    getPermission: function () {
        return new Promise((resolve, reject) => {
            //Rimango in attesa di ricezione
            const listener = function (nativeData) {
                resolve(!!nativeData.permission);
                FuxEvents.off(NM_GEOLOCATION_PERMISSION, listener);
            }
            FuxEvents.on(NM_GEOLOCATION_PERMISSION, listener);

            //Richiede stato permessi nativamente
            BookizonAppManager.getGeolocationPermission();

            //Dopo 1 min do per scontato che non abbia permessi
            setTimeout(_ => {
                FuxEvents.off(NM_GEOLOCATION_PERMISSION, listener);
                resolve(false);
            }, 60 * 1000);
        });
    },
    askPermission: function () {
        BookizonAppManager.askGeolocationPermission();
    },
    waitForPermissionGrant: function () {
        return new Promise((resolve) => {
            let isAlreadyWaiting = false;
            const checkPermission = _ => {
                if (!isAlreadyWaiting) {
                    isAlreadyWaiting = true;
                    NativeGeolocationProvider.getPermission()
                        .then(permission => {
                            isAlreadyWaiting = false;
                            if (permission) {
                                clearInterval(interval);
                                resolve(true);
                            }
                        });
                }
            }
            const interval = setInterval(checkPermission, 200);
        });
    },
    waitForPosition: function (initiator) {
        return new Promise(function(resolve, reject) {

            //Rimango in attesa di ricezione
            const listener = (nativeData) => {
                console.log("[NM]","LISTENER RICHIAMATO",initiator,nativeData);
                localStorage.__last_lat = nativeData.lat;
                localStorage.__last_lng = nativeData.lon;
                if (AuthAPI.currentUser) UserAPI.Geolocation.save(nativeData.lat, nativeData.lon);
                FuxEvents.off(NM_GEOLOCATION_UPDATE, listener);
                resolve({lat: nativeData.lat, lng: nativeData.lon});
            }
            FuxEvents.on(NM_GEOLOCATION_UPDATE, listener);

            //Richiede la posizione
            BookizonAppManager.askGeolocationPermission();

            //Dopo 1 sec do per scontato che non abbia permessi
            setTimeout(_ => {
                const lastLat = parseFloat(localStorage.__last_lat);
                const lastLng = parseFloat(localStorage.__last_lng);
                if (lastLat && lastLng) {
                    console.log("[NM]","RESOLVE ANTICIPATO");
                    resolve({lat: lastLat, lng: lastLng});
                }
            }, 1000);
        });
    }
};

export default NativeGeolocationProvider;
