import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FoodSharedCartListener} from "./FoodSharedCartListener/FoodSharedCartListener";
import {ExpoDirectOpenListener} from "./ExpoListener/ExpoDirectOpenListener";
import {PrenotazioneServizioNotificationClickListener} from "./NotificationClickListeners/PrenotazioneServizioNotificationClickListener";
import {PrenotazioneGruppoNotificationClickListener} from "./NotificationClickListeners/PrenotazioneGruppoNotificationClickListener";
import {OrdineNotificationClickListener} from "./NotificationClickListeners/OrdineNotificationClickListener";
import {PrenotazioneGruppoQRScanListener} from "./OperazioniQRListeners/ModuloGruppi/PrenotazioneGruppoQRScanListener";
import {SeatBookQRScanListener} from "./OperazioniQRListeners/ModuloSeats/SeatBookQRScanListener";
import {StaffIdentityQRScanListener} from "./OperazioniQRListeners/ModuloServizi/StaffIdentityQRScanListener";

export class LauncherListenersController extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <FoodSharedCartListener
                    onAppOpen={this.props.onAppOpen}
                    idNegozio={this.props.idNegozioOpen}
                />
                <ExpoDirectOpenListener
                    onAppOpen={this.props.onAppOpen}
                    onAppAdd={this.props.onAppAdd}
                />
                <PrenotazioneServizioNotificationClickListener/>
                <PrenotazioneGruppoNotificationClickListener/>
                <OrdineNotificationClickListener/>
                <PrenotazioneGruppoQRScanListener/>
                <SeatBookQRScanListener/>
                <StaffIdentityQRScanListener/>
            </Fragment>
        )
    }

}

LauncherListenersController.propTypes = {
    idNegozioOpen: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onAppOpen: PropTypes.func,
    onAppAdd: PropTypes.func,
}