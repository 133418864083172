import React, {Fragment} from "react";

export class FuxRating extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.rating !== this.props.rating;
    }

    render() {
        return (
            <Fragment>
                {
                    [...new Array(+(parseFloat(this.props.rating).toFixed(0)))].map(
                        (v, i) => <i key={i} className={"fas fa-star " + this.props.className}
                                     style={this.props.style}/>
                    )
                }
                {
                    this.props.showMissingStars &&
                    [...new Array(+(parseFloat(5 - this.props.rating).toFixed(0)))].map(
                        (v, i) => <i key={i} className={"far fa-star " + this.props.className}
                                     style={this.props.style}/>
                    )
                }
            </Fragment>
        )
    }
}

FuxRating.defaultProps = {
    className: 'text-success',
    style: {},
    showMissingStars: true
}