import React from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {BookizonAppManager} from "../../index";
import Page from "../../components/BaseComponents/Page/Page";

export class AreaLegalePage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Page
                title={"Area legale"}
                active={true}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                onLeftIconClick={this.props.onDismiss}
            >
                <div className={"container mt-3"}>
                    <div className={"list-group shadow-sm"}>
                        <div className={"list-group-item d-flex justify-content-between align-items-center"}
                             onClick={_ => BookizonAppManager.openInAppBrowser('https://bookizon.it/documents/privacy-policy')}>
                            <span>Privacy Policy</span><FaSolidIcon name={"chevron-right"}/>
                        </div>
                        <div className={"list-group-item d-flex justify-content-between align-items-center"}
                             onClick={_ => BookizonAppManager.openInAppBrowser('https://bookizon.it/documents/termini-e-condizioni')}>
                            <span>Termini e Condizioni</span><FaSolidIcon name={"chevron-right"}/>
                        </div>
                        <div className={"list-group-item d-flex justify-content-between align-items-center"}
                             onClick={_ => BookizonAppManager.openInAppBrowser('https://bookizon.it/esercizio-dei-diritti/modulo-diritto-recesso.pdf')}>
                            <span>Diritto di recesso</span><FaSolidIcon name={"chevron-right"}/>
                        </div>
                    </div>
                </div>
            </Page>
        )

    }

}
