import React,{Fragment} from "react";
import takeAwayIcon from "./../img/take-away-icon.png";
import consegnaIcon from "./../img/consegna-icon.png";
import ecommerceIcon from "./../img/ecommerce-icon.png";
import paypalIcon from "./../img/paypal-icon.png";
import menuDigitale from "./../img/menu-digitale.png";
import tavoliIcon from "./../img/tavoli.png";
import styled from "styled-components";
import i18n from "i18next";

const FeatureIcon = styled.span`
    margin-right:2px;
    padding:2px 4px;
    transform: translateY(-3px);
    display:inline-block;
`;

const IconWrapper = styled.div`
    display:inline-block;
    min-width:${props => props.columnAlign ? '30px' :'auto'};
`;

export default class ShopFeaturesIcon extends React.Component{

    constructor(props) {
        super(props);
    }

    getIconFromFeature = _ =>{
        switch(this.props.feature.toLowerCase()){
            case 'consegna': return <img src={consegnaIcon} height={this.getSize()}/>
            case 'asporto': return <img src={takeAwayIcon} height={this.getSize()}/>
            case 'ecommerce': return <img src={ecommerceIcon} height={this.getSize()}/>
            case 'paypal': return <img src={paypalIcon} height={this.getSize()}/>
            case 'menu': return <img src={menuDigitale} height={this.getSize()}/>
            case 'tavoli': return <img src={tavoliIcon} height={this.getSize()}/>
            default: return <span> </span>
        }
    }

    getFeatureText = _ =>{
        return i18n.t(`business-info.features.${this.props.feature.toLowerCase()}`);
    }

    getSize = _ => {
        switch(this.props.size){
            case 'sm': return '18px';
            case 'md': return '20px';
            case 'lg': return '22px';
            default: return '18px';
        }
    }

    render(){

        return (
            <FeatureIcon className={"text-on-bg-main"}>
                <IconWrapper columnAlign={this.props.columnAlign}>{this.getIconFromFeature()}</IconWrapper> {this.props.withText && this.getFeatureText()}
            </FeatureIcon>

        )
    }


}
