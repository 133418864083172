import React from "react";
import PropTypes from "prop-types";
import {SPTopToBottom} from "./SPTopToBottom";

export class SwipeablePanel extends React.Component{
    constructor(props) {
        super(props);
    }

    getPanel = _ => {
        switch(this.props.direction){
            case 'top-to-bottom': return SPTopToBottom
        }
    }

    getTranslationType = _ => {
        switch(this.props.direction){
            case 'top-to-bottom': return 'translateY';
        }
    }

    getAnimationAxisType = _ => {
        switch(this.props.direction){
            case 'top-to-bottom': return 'y';
        }
    }

    getAnimationDisabledAxisType = _ => {
        switch(this.props.direction){
            case 'top-to-bottom': return 'x';
        }
    }

    render() {
        const Panel = this.getPanel();
        return (
            <Panel
                {...this.props}
                animationAxis={this.getAnimationAxisType()}
                animationDisabledAxis={this.getAnimationDisabledAxisType()}
                translationType={this.getTranslationType()}
            >
                {this.props.children}
            </Panel>
        )
    }
}


SwipeablePanel.propTypes = {
    active: PropTypes.bool,
    direction: PropTypes.string,
    disableSwipe: PropTypes.func,
    onSwipeStart: PropTypes.func,
    onSwipeMove: PropTypes.func,
    onSwipeEnd: PropTypes.func,
    swipeTreshold: PropTypes.number,
    onDismiss: PropTypes.func,
    dismissTreshold: PropTypes.number,
    animationSpeed: PropTypes.number,
};

SwipeablePanel.defaultProps = {
    active: false,
    onSwipeStart: _ => undefined,
    onSwipeMove: _ => undefined,
    onSwipeEnd: _ => undefined,
    swipeTreshold: 50,
    disableSwipe: _ => false,
    onDismiss: _ => undefined,
    dismissTreshold: 100,
    onExitEnd: _ => undefined,
    animationSpeed: 300
};
