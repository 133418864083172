import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const _Books = {
    getBookData: id_prenotazione => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/books/get-book-data`), {
                token: AuthAPI.getUserAccessToken(),
                id_prenotazione: id_prenotazione
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    requestRefund: (id_prenotazione, reason) => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/books/request-refund`), {
                token: AuthAPI.getUserAccessToken(),
                id_prenotazione: id_prenotazione,
                reason: reason
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
}