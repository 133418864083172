import React from "react";
import styled, {keyframes} from "styled-components";

const shineAnimation = keyframes`
  0% {
      background-position: 0% 0%;
  }
  100% {
      background-position: -135% 0%;
  }
`;

export const PlaceholderBlock = styled.div`
    transition: 0.3s;
    background: linear-gradient(-90deg, #dedede 0%, #fcfcfc 50%, #dedede 100%);
    background-size: 400% 400%;
    animation: ${shineAnimation} 1s infinite;
    opacity:1;
    width: ${props => props.width ? `${props.width}px` : '100%'};
    height: ${props => props.height ? `${props.height}px` : 'auto'};
`;

