import React from "react";
import styled from "styled-components";
import AppConfig from "../../../config/AppConfig";
import ImmaginePromozione from "../../../img/ImmaginePromozione.png";
import PromotionsBirthday from "./Birthday/PromotionsBirthday";
import PromotionsAttiveOggi from "./AttiveOggi/PromotionsAttiveOggi";
import PromotionsAppCollegate from "./AppCollegate/PromotionsAppCollegate";
import PromotionsGeohash from "./GeoHash/PromotionsGeohash";


export default class PromotionsTab extends React.Component {
    constructor(props) {
        super(props);
        this.pageContainer = {
            marginBottom: '15%'
        }
        this.mediaContainer = {
            position: 'relative',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url('${ImmaginePromozione}')`,
            height: '40vh',
            width: '100%',
            overflow: 'hidden'
        }
        this.gradientForImg = {
            height:'100%',
            width:'100%',
            backgroundImage: 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9))'
        }
        this.blurForImg = {
            position: 'absolute',
            bottom: '0',
            height:'30%',
            width:'100%',
            backdropFilter: 'blur(3px)'
        }
        this.titleContainer = {
            position: 'absolute',
            bottom: '0',
            textAlign: 'center',
            color: 'white',
            width: '100%'
        }
    }


    render() {
        return (
            <div style={this.pageContainer} className={this.props.active ? '' : 'd-none'}>
                <div style={this.mediaContainer} className={"mb-4"}>
                    <div style={this.gradientForImg}>
                        <div style={this.blurForImg}>
                            <div style={this.titleContainer} className={"p-5"}>
                                <h1>🎉</h1>
                                <h2>Scegli le migliori offerte dedicate a te!</h2>
                                <small>In questa sezione troverai le promozioni ✨ che Bookizon ha identificato per te! 🎁</small>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.props.active &&
                        <div>
                            <PromotionsBirthday user = {this.props.user}/>
                            <PromotionsAttiveOggi user = {this.props.user}/>
                            <PromotionsAppCollegate user = {this.props.user}/>
                            <PromotionsGeohash user = {this.props.user}/>
                        </div>
                }
            </div>
        )
    }
}