import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";

export default class DipendenteWelfareGiftCardsCategoryItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <div className={"list-group-item align-items-center d-flex justify-content-between"}
                 onClick={_ => this.props.onCategoryClick(this.props.category)}>
                <div><b>{this.props.category.nome}</b></div>
                <div><FaSolidIcon name={"chevron-right"}/></div>
            </div>
        )
    }
}

DipendenteWelfareGiftCardsCategoryItem.propTypes = {
    category: PropTypes.object,
    onCategoryClick: PropTypes.func
};