import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import ComandaCard from "./ComandaCard";
import Page from "../../../../components/BaseComponents/Page/Page";
import ComandeAPI from "../../../../api/ComandeAPI";
import {swal, swalConfirm, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";

export default class TavoloServizioPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            comande: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.idServizio !== null && this.props.idServizio !== prevProps.idServizio) {
            this.fetchData();
        }
    }

    fetchData = _ => {
        swalLoading('Attendere prego...');
        ComandeAPI.getComande(this.props.idServizio || 0).then(comande => {
            this.setState({comande: comande});
            swal.safeClose();
        }).catch(swalError);
    }

    handleEliminaProdotto = ({id_comanda, id_prodotto, aggiunte_hash}) => {
        swalConfirm('Sei sicuro di voler eliminare questo prodotto dalla comanda?')
            .then(_ => {
                swalLoading('Attendere prego...');
                ComandeAPI.eliminaProdotto(id_comanda, id_prodotto, aggiunte_hash)
                    .then(message => {
                        this.fetchData();
                    })
                    .catch(swalError);
            })
    }

    handleConsegnaProdotto = ({id_comanda, id_prodotto, aggiunte_hash}) => {
        swalConfirm('Sei sicuro di voler segnare come "consegnato" questo prodotto?')
            .then(_ => {
                swalLoading('Attendere prego...');
                ComandeAPI.setProdottoConsegnato(id_comanda, id_prodotto, aggiunte_hash)
                    .then(message => {
                        this.fetchData();
                    }).catch(swalError)
            })
    }

    render() {
        return (
            <Page
                title={"Comande"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
            >
                <div className={"container"}>
                    {
                        this.state.comande.map(c =>
                            <ComandaCard key={c.id_comanda} onEliminaProdotto={this.handleEliminaProdotto}
                                         onConsegnaProdotto={this.handleConsegnaProdotto} {...c} isReadOnly={this.props.isReadOnly}/>
                        )
                    }
                </div>
            </Page>
        )
    }

}

TavoloServizioPage.propTypes = {
    onDismiss: PropTypes.func,
    comande: PropTypes.array
}
