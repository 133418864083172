import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {MapSectorType} from "../../../../components/ModuloSeats/PropTypes/MapSectorType";
import {SeatsBookType} from "../../../../components/ModuloSeats/PropTypes/BookType";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {BookItemView} from "./BookItemView";

export class SectorBookList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.badgeStyle = {
            backgroundColor: this.props.sector.seat_color
        }
    }

    shouldHideBook = b => {
        if (this.props.hideBooksWithEntranceDate && b.entrance_date) return true;
        if (this.props.searchQuery){
            const keywords = this.props.searchQuery.toLowerCase().split(" ");
            const searchSubject = `${b.customer_first_name} ${b.customer_last_name}`.toLowerCase();
            for(let k of keywords){
                if (searchSubject.indexOf(k) === -1) return true;
            }
        }
        return false;
    }

    render() {
        const booksNum = this.props.books.length;
        const booksWithEntranceNum = this.props.books.filter(b => !!b.entrance_date).length;
        const hideSector = this.props.hideEmptySectors && !this.props.books.length;
        return (
            <Fragment>
                <div className={`card border-0 shadow-sm mt-3 ${hideSector ? 'd-none' : ''}`}>
                    <div className={"card-header bg-white"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <span className={"px-2 rounded text-white"} style={this.badgeStyle}>
                                {this.props.sector.name}
                            </span>
                            {
                                booksNum > 0 &&
                                <div className={booksNum === booksWithEntranceNum ? 'text-success' : ''}>
                                    {booksWithEntranceNum}/{booksNum} <FaSolidIcon name={"sign-in-alt"}/>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={"card-body p-0"}>
                        <div className={"list-group list-group-flush"}>
                            {
                                this.props.books.map(b => {
                                    /**
                                     * We simply hide the item in order to allow the detail page to be mounted when
                                     * entrance date is set from detail page and the entrance date filter is active.
                                     */
                                    const className = this.shouldHideBook(b) ? "d-none" : "list-group-item";
                                    return <div key={b.book_id} className={className}>
                                        <BookItemView book={b} onSetEntranceDate={this.props.onSetEntranceDate}/>
                                    </div>
                                })
                            }
                            {
                                booksNum === 0 &&
                                <div className={"text-muted text-center py-3"}>
                                    Non ci sono prenotazioni in questo settore
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

SectorBookList.propTypes = {
    sector: MapSectorType,
    books: PropTypes.arrayOf(SeatsBookType), //with "seat_internal_code" key
    onSetEntranceDate: PropTypes.func,
    hideBooksWithEntranceDate: PropTypes.bool,
    hideEmptySectors: PropTypes.bool,
    searchQuery: PropTypes.string
}