import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";

var SottocategorieNegoziAPI = {
    list: function(){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/sottocategorie-negozi/list`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listaPresenti: function(){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/sottocategorie-negozi/lista-presenti`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default SottocategorieNegoziAPI;
