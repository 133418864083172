import React from "react";

export function AttachmentsImagePreview({attachment}) {
    const url = URL.createObjectURL(attachment);
    const imageStyle = {
        backgroundImage: `url("${url}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: 40,
        height: 40,
        borderRadius: "0.25rem"
    }
    return (
        <React.Fragment>
            <div style={imageStyle}/>
        </React.Fragment>
    )
}

AttachmentsImagePreview.propTypes = {}
