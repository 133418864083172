import React from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import moment from "moment";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import PasswordRecoveryForm from "../Forms/PasswordRecoveryForm";
import passwordRecoveryImage from '../../img/password-recovery.svg';
import i18next from "i18next";

export default class PasswordRecoveryModal extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Page
                title={i18next.t("password-recovery.title")}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
                content={
                    <div className={"container text-on-bg-main mt-2"}>
                        <div>
                            <img src={passwordRecoveryImage} className={"w-75 mx-auto mb-3 d-block"}/>
                            <PasswordRecoveryForm
                                PasswordRecoveryModalDismiss = {this.props.onDismiss}
                            />
                        </div>
                    </div>
                }
            />
        );
    }

}


PasswordRecoveryModal.propTypes = {
    onDismiss: PropTypes.func,
};
