import React from "react";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {DLA_STAFF_IDENTITY} from "../../../../const/DeepLinkActionsCostants";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import FuxHTTP from "../../../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../../../config/AppConfig";
import AuthAPI from "../../../../api/AuthAPI";

export class StaffIdentityQRScanListener extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        FuxEvents.on(DLA_STAFF_IDENTITY, this.handleConfirm)
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_STAFF_IDENTITY);
    }

    handleConfirm = v => {
        swalConfirm('Sei sicuro di voler associare il tuo account?', 'Conferma', 'Annulla')
            .then(_=> {
                swalLoading('Attendere prego...');
                FuxHTTP.post(`${AppConfig.webServerUrl}/staff-identity/set-user`, {token: AuthAPI.currentUser.accessToken, v: v}, FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE)
                    .then(swalSuccess)
                    .catch(swalError)
            })
            .catch(_=>{})
    }

    render() {
        return '';
    }
}