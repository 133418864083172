import React from "react";
import styled from "styled-components";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import AppConfig from "../../../config/AppConfig";
import svgTriangleA from "../../svg/triangolo1.svg";
import svgTriangleB from "../../svg/triangolo2.svg";
import svgSquare from "../../svg/quadrato.svg";
import svgCircle from "../../svg/cerchio.svg";
import svgPenta from "../../svg/pentagono.svg";
import svgEsa from "../../svg/esagono.svg";
import svgRombo from "../../svg/rombo.svg";
import svgStar from "../../svg/stella.svg";
import svgAbstractA from "../../svg/abstract1.svg";
import svgAbstractB from "../../svg/abstract2.svg";
import PropTypes from "prop-types";


export default class FidelityCardItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.shapes = [
            {
                shape: this.getRandomShape(),
                left: (Math.random() * 100) + "%",
                top: 0,
                rotate: Math.random() * 365,
                opacity: Math.min(0.2, Math.random())
            },
            {
                shape: this.getRandomShape(),
                left: (Math.random() * 100) + "%",
                top: 40,
                rotate: Math.random() * 365,
                opacity: Math.min(0.2, Math.random())
            },
            {
                shape: this.getRandomShape(),
                left: (Math.random() * 100) + "%",
                top: 0,
                rotate: Math.random() * 365,
                opacity: Math.min(0.2, Math.random())
            },
            {
                shape: this.getRandomShape(),
                left: (Math.random() * 100) + "%",
                top: 30,
                rotate: Math.random() * 365,
                opacity: Math.min(0.2, Math.random())
            }
        ];
    }

    getRandomShape = _ => {
        let v = [svgTriangleA, svgTriangleB, svgSquare, svgCircle, svgPenta, svgEsa, svgRombo, svgStar, svgAbstractA, svgAbstractB];
        let i = Math.ceil(Math.random() * v.length);
        return v[i];
    }

    handleClick = _ => this.props.onClick(this.props.cardData)

    render() {
        return (
            <FidelityCard
                {...this.props}
                className={"card shadow mb-3"}
                onClick={this.handleClick}
            >
                <div className={"opacizer"}/>

                {
                    this.shapes.map((s, i) =>
                        <FidelityCardShape
                            key={i}
                            top={`${s.top}%`}
                            left={s.left}
                            src={s.shape}
                            rotate={s.rotate}
                            opacity={s.opacity}
                        />
                    )
                }

                <div>
                    <AppIconThumbnail
                        size={"30px"}
                        src={`${AppConfig.webServerUrl}/api/negozio/${this.props.cardData.id_negozio}/logoIcon/100`}
                        className={"mr-2 bg-white"}/>
                </div>
                <div className={"text-truncate mw-100"}>
                    <small>{this.props.cardData.nome}</small>
                </div>
                <div>
                    <span className={"text-uppercase"}>{this.props.cardData.punti || 0} punti</span>
                </div>
            </FidelityCard>
        )
    }
}

export  function FidelityCardItem2(props) {
    const getRandomShape = _ => {
        var v = [svgTriangleA, svgTriangleB, svgSquare, svgCircle, svgPenta, svgEsa, svgRombo, svgStar, svgAbstractA, svgAbstractB];
        var i = Math.ceil(Math.random() * v.length);
        return v[i];
    }

    const handleClick = _ => props.onClick(props.cardData)

    return (
        <FidelityCard
            {...props}
            className={"card shadow mb-3"}
            onClick={handleClick}
        >

            <div className={"opacizer"}/>

            <FidelityCardShape
                top={"0%"}
                left={(Math.random() * 100) + "%"}
                src={getRandomShape()}
                rotate={Math.random() * 365}
                opacity={Math.min(0.2, Math.random())}
            />
            <FidelityCardShape
                top={"40%"}
                left={(Math.random() * 100) + "%"}
                src={getRandomShape()}
                rotate={Math.random() * 365}
                opacity={Math.min(0.2, Math.random())}
            />
            <FidelityCardShape
                bottom={"0%"}
                left={(Math.random() * 100) + "%"}
                src={getRandomShape()}
                rotate={Math.random() * 365}
                opacity={Math.min(0.2, Math.random())}
            />
            <FidelityCardShape
                bottom={"30%"}
                left={(Math.random() * 100) + "%"}
                src={getRandomShape()}
                rotate={Math.random() * 365}
                opacity={Math.min(0.2, Math.random())}
            />

        </FidelityCard>
    )

}


FidelityCardItem.propTypes = {
    onClick: PropTypes.func,
    color: PropTypes.string,
    darkerColor: PropTypes.string,
    lighterColor: PropTypes.string,
    cardData: PropTypes.object,
}

const FidelityCard = styled.div`
    position:relative;
    background-image: linear-gradient(45deg, ${props => props.darkerColor} 60%, ${props => props.lighterColor});
    border-color: ${props => props.lighterColor};
    color: ${props => props.color};
    border-radius:.75em;
    overflow:hidden;
    padding:1rem;
    
    & .opacizer{
        z-index:0;
        position:absolute;
        top:0px; left:0px;
        width:100%;
        height:100%;
        background-color:rgba(255,255,255,0.20);
    }
    
    & *:not(.opacizer){
        position:relative;
        z-index:2;
    }

`;

const FidelityCardShape = styled.div`
    position:absolute!important;
    background-image: url(${props => props.src});
    background-size:contain;
    background-position:center center;
    background-repeat: no-repeat;
    width: ${props => props.width || 60}px;
    height: ${props => props.height || 60}px;
    opacity: ${props => props.opacity};
    top:${props => props.top || "auto"};
    left:${props => props.left || "auto"};
    right:${props => props.right || "auto"};
    bottom:${props => props.bottom || "auto"};
    transform:rotate(${props => props.rotate || 0}deg);
`;
