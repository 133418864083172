import React, {Fragment} from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import Page from "../../components/BaseComponents/Page/Page";
import {swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import PagamentiDipendenteAPI from "../../api/Welfare/PagamentiDipendenteAPI";
import DipendenteWelfareQrcodeModal from "./DipendenteWelfareQrcodeModal";
import {PayOnlineIcon} from "../../img/illustrations/PayOnlineIcon";
import PropTypes from "prop-types";
import {ElementInViewObserver} from "../../components/ElementInViewObserver/ElementInViewObserver";

export default class DipendenteWelfarePagaInNegozioModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            importo: '',
            qrCodeLink: '',
            importoToPass: '',
            descrizione_dipendente: '',
            qrCodePageOpen: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        if(name === "importo"){
            const regExp = /^\d*(\.\d{0,2})?$/g;
            if(!value.match(regExp)){
                value = parseFloat(value).toFixed("2");
            }
        }

        this.setState({
            [name]: value,
        });
    }


    toggleQrCodePageOpen = _ => this.setState({qrCodePageOpen: !this.state.qrCodePageOpen});


    handleSubmit(event) {
        event.preventDefault();
        swalLoading();
        PagamentiDipendenteAPI.generateAuthCode(this.props.user.walletapikey, this.state.importo, this.state.descrizione_dipendente)
            .then((data) => {
                console.log(data)
                swalSuccess("Qr code generato correttamente");
                this.setState({
                    importo: '',
                    descrizione_dipendente: '',
                    qrCodeLink: data.qrCodeLink,
                    importoToPass: data.importo
                });
                this.toggleQrCodePageOpen();
                this.props.onDismiss();
            })
            .catch(swalError)

    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Paga in negozio"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <form onSubmit={this.handleSubmit}>
                            <div className={"container pt-4 form-group"}>
                                <div className={"row text-center"}>
                                    <div className={"col-12"}>
                                        <PayOnlineIcon className={"img-fluid w-50 mx-auto"}
                                                             color={"#293069"}/>
                                    </div>
                                    <div className={"col-12"}>
                                        <h4>Nuovo pagamento</h4>
                                        <small>Dopo aver inserito i dati richiesti, verrà generato un QR code
                                        che il negoziante verso il quale vuoi effettuare il pagamento, dovrà scansionare</small>
                                        <br/>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-12 mt-4"}>
                                        <label>Importo speso in euro</label>
                                        <input className={"form-control"} name="importo" type="number"
                                               placeholder={"Inserisci importo"} value={this.state.importo}
                                               onChange={this.handleInputChange} required/>
                                        <small
                                            className={(this.state.importo >= 0.01 || this.state.importo === '' ? "d-none" : "")}>Importo
                                            inserito non valido</small>
                                    </div>
                                    <div className={"col-12 mt-4"}>
                                        <label>Descrizione</label>
                                        <textarea className={"form-control"} name="descrizione_dipendente"
                                                  placeholder={"Inserisci una descrizione (opzionale)"}
                                                  value={this.state.descrizione_dipendente}
                                                  onChange={this.handleInputChange}/>
                                    </div>
                                    <div className={"col-12 mt-4 text-center"}>
                                        <button className="btn btn-primary" type="submit">Crea QR code</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                />
                <DipendenteWelfareQrcodeModal
                    user={this.props.user}
                    active={this.state.qrCodePageOpen}
                    dipendente={this.state.dipendente}
                    qrCodeLink = {this.state.qrCodeLink}
                    importo = {this.state.importoToPass}
                    onDismiss={this.toggleQrCodePageOpen}
                />
            </Fragment>
        );
    }
}

DipendenteWelfarePagaInNegozioModal.propTypes = {
    user: PropTypes.array,
    dipendente: PropTypes.array,
    onDismiss: PropTypes.func
}