import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import DashboardOrdiniFood from "./DashboardOrdiniFood";

export class DashboardOrdiniFoodPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Ordinazioni"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >
                    <DashboardOrdiniFood {...this.props.userNegozio}/>
                </Page>
            </Fragment>
        )
    }

}

DashboardOrdiniFoodPage.propTypes = {
    userNegozio: PropTypes.object,
    onDismiss: PropTypes.func,
}