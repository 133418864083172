import React from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {swal, swalSuccess, swalError, swalLoading} from '../../helpers/SweetAlertWrapper';
import {DragDropContext} from 'react-beautiful-dnd';
import LauncherWelcomeScreen from "../LauncherWelcomeScreen";
import LauncherAppList from "./LauncherAppList";
import styled from "styled-components";
import i18n from "../../i18n";

export default class LauncherAppListContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            welcomeScreenOpen: false
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.apps !== this.props.apps ||
            nextProps.editMode !== this.props.editMode ||
            nextState.searchQuery !== this.state.searchQuery ||
            nextState.welcomeScreenOpen !== this.state.welcomeScreenOpen;
    }

    handleAppDelete = id_negozio => {
        swal.fire({
            type: 'question',
            text: 'Sei sicuro di voler eliminare questa app?',
            showCancelButton: true,
            cancelButtonText: 'Annulla',
            confirmButtonText: 'Conferma'
        }).then((result) => {
            if (result.value) {
                this.props.onAppDeleteRequest(id_negozio);
            }
        });
    };

    handleSearchChange(event) {
        this.setState({searchQuery: event.target.value});
    }

    handleResetSearchQuery() {
        this.setState({searchQuery: ''});
    }

    onDragEnd = result => {
        /** @NB: draggableId rappresenta l'id_negozio */
        const {destination, source, draggableId} = result;

        //Controllo se non è stato mosso
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        //Riordino le app
        this.props.onAppReorder(draggableId, destination.index, source.index);
    };

    toggleWelcomeScreen = _ => {
        this.setState({welcomeScreenOpen: !this.state.welcomeScreenOpen},_ => {
            if (this.state.welcomeScreenOpen){
                window.scroll({
                    top: 0,
                    behavior: "smooth"
                });
            }
        });
    }

    getWelcomeScreenClass = _ => {
        if (this.props.apps !== null && !this.props.apps.length){
            return '';
        }else if(this.state.welcomeScreenOpen) {
            return 'opened';
        }else{
            return 'closed';
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.apps === null &&
                        <div className={"container text-center lead mt-4"}>
                            <i className={"fas fa-spinner fa-spin"}/> Caricamento in corso...
                        </div>
                }
                <WelcomeScreenAccordion className={this.getWelcomeScreenClass()}>
                    <LauncherWelcomeScreen/>
                    {/* Trigger del welcome screen */}
                    {
                        this.state.welcomeScreenOpen &&
                        <div className={"text-center mb-4"}>
                            <button className={"btn btn-sm btn-link text-muted"} onClick={this.toggleWelcomeScreen}>
                                <FaSolidIcon name={"minus-circle"}/> {i18n.t('generic.reduce-btn')}
                            </button>
                        </div>
                    }
                </WelcomeScreenAccordion>
                {
                    this.props.apps === null || this.props.apps.length === 0 ? '' :
                        <div className={"container mt-3"}>
                            <div className={"text-muted small mb-2"}><FaSolidIcon name={"list"}/> {i18n.t('launcher.homepage.le-mie-app')}</div>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <LauncherAppList
                                    apps={this.props.apps}
                                    searchQuery={this.state.searchQuery}
                                    onAppOpen={this.props.onAppOpenRequest}
                                    onAppDelete={this.handleAppDelete}
                                    onAppReorder={this.props.onAppReorderRequest}
                                    editMode={this.props.editMode}
                                />
                            </DragDropContext>
                        </div>
                }

                {/* Trigger del welcome screen */}
                {
                    (!!this.props.apps && !!this.props.apps.length && !this.state.welcomeScreenOpen) &&
                    <div className={"text-center my-4"}>
                        <button className={"btn btn-sm btn-link text-muted"} onClick={this.toggleWelcomeScreen}>
                            <FaSolidIcon name={"info-circle"}/> {i18n.t("launcher.homepage.informazioni-su-bookizon")}
                        </button>
                    </div>
                }
            </div>
        );
    }

}


const WelcomeScreenAccordion = styled.div`
  transition: all .3s linear;
  overflow: hidden;
  
  &.opened{
    max-height: 500px;
    transition: all .3s linear
  }
  &.closed{
    max-height: 1px;
    transition: all .3s linear
  }
`;
