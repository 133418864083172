import React, {Fragment} from "react";
import styled from "styled-components";
import {FaSolidIcon} from "../FontAwesomeIcons";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import {EllipsisLoader} from "../GenericUI/EllipsisLoader/EllipsisLoader";

const ImagePanelBackdrop = styled.div`
    position:fixed;
    top:0; left:0; bottom:0; right:0;
    background-color:rgba(0,0,0,0.95);
    z-index:3001;
`;

const ImagePanelClose = styled.div`
    position:fixed;
    top:10px;
    right:10px;
    z-index:3001;
    color:white;
    padding:5px;
    font-size:90%;
    text-shadow: 1px 1px 5px black;
`;

export default class ImageZoomPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
        this.image = new Image();
        this.image.src = props.src;
        this.image.onload = _ => this.setState({loaded: true});
        this.imageStyle = {
            maxWidth: '100%',
            maxHeight: '100%',
        }
    }

    render() {
        return (
            <ImagePanelBackdrop className={"__androidDismissable__"} onClick={this.props.onDismiss}>
                <ImagePanelClose onClick={this.props.onDismiss}>
                    Chiudi
                </ImagePanelClose>
                <PinchZoomPan zoomButtons={false} maxScale={2}>
                    <div className={"d-flex align-items-center h-100 justify-content-center"}>
                        {
                            this.state.loaded ?
                                <img className={"d-flex img-fluid"} src={this.props.src} style={this.imageStyle}/>
                                :
                                <EllipsisLoader color={"#cccccc"}/>
                        }
                    </div>
                </PinchZoomPan>
            </ImagePanelBackdrop>
        )
    }

}
