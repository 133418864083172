import React, {Fragment} from "react";
import PropTypes from "prop-types";
import FeedAPI from "../api/FeedAPI";
import {EllipsisLoader} from "../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {getCompressedImageUrl} from "../helpers/ImageUtility";
import AppConfig from "../config/AppConfig";
import SafeHtmlContainer from "../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../components/FontAwesomeIcons";
import styled from "styled-components";
import moment from "moment";
import {replaceHashtag, truncateHtmlString} from "../helpers/StringHelpers";
import {BookizonAppManager} from "../index";
import appStoreLogo from "../img/store-logo/app-store.svg";
import playStoreLogo from "../img/store-logo/google-play-badge.png";
import MetaTags from "react-meta-tags";
import {FeedPostCardActionBar} from "../launcher/Tabs/FeedTab/FeedPostCardActionBar";
import {AppIconThumbnail} from "../components/BaseComponents/AppIconThumbnail";
import {PostImageSize} from "../const/ImageSizeCostants";
import {FeedPostView} from "../launcher/Tabs/FeedTab/FeedPostView";

export class DirectPostContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            postData: '',
        }
    }

    componentDidMount() {
        //Recupero info post
        document.body.className = "";
        document.body.style.background = '#dadde1';
        FeedAPI.getPostData(this.props.id_post).then(post => this.setState({postData: post}));
    }

    getCalendarTimestamp = datetime => {
        return moment(datetime).calendar(null, {
            sameDay: '[oggi alle] HH:mm',
            nextDay: '[domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[ieri alle] HH:mm',
            lastWeek: '[lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });
    }

    render() {
        if (!this.state.postData) {
            return <div className={"h-100 d-flex align-items-center justify-content-center"}>
                <EllipsisLoader/>
            </div>
        }

        const OS = BookizonAppManager.getMobileOperatingSystem();

        const imageUrl = this.state.postData.immagine ? (this.state.postData.immagine.indexOf('http') !== -1 ? this.state.postData.immagine  : `${AppConfig.webServerUrl}/assets/posts/${this.state.postData.id_post}/${this.state.postData.immagine}`) : '';

        return (
            <Fragment>
                {
                    this.props.useMetaTags && <MetaTags>
                        <title>{this.state.postData.titolo}</title>
                        <meta id="meta-description" name="description"
                              content={truncateHtmlString(this.state.postData.testo,128)}/>
                        <meta id="og-title" property="og:title" content={this.state.postData.titolo}/>
                        {
                            imageUrl &&
                            <meta id="og-image" property="og:image"
                                  content={imageUrl}/>
                        }
                    </MetaTags>
                }
                <div className={"direct-post-container shadow-sm"}>
                    {
                        this.props.useDownloadBanner ?
                        <div className={"bg-light py-3 border-bottom"}>
                            <div className={"container text-center"}>
                                <p>
                                    <b><SafeHtmlContainer html={this.state.postData.nome_negozio}/></b> è su Bookizon
                                    scarica
                                    l'app per
                                    conoscere tutte le ultime novità
                                </p>
                                {
                                    OS !== 'unknown' &&
                                    <div>
                                        {
                                            OS === 'iOS' &&
                                            <a href={"https://apps.apple.com/us/app/bookizon/id1506158300?l=it&ls=1"}>
                                                <img src={appStoreLogo} height={45}/>
                                            </a>
                                        }
                                        {
                                            OS === 'Android' &&
                                            <a href="https://play.google.com/store/apps/details?id=com.proinn.bookizon">
                                                <img src={playStoreLogo} height={45}/>
                                            </a>
                                        }
                                    </div>
                                }

                                {
                                    OS === 'unknown' &&
                                    <img
                                        src={`${AppConfig.webServerUrl}/api/shops/qrcode/${this.state.postData.id_negozio}`}
                                        className="mx-auto" height={150}
                                    />
                                }
                            </div>
                            <div className={"bg-white"}>
                                {
                                    imageUrl &&
                                    <CardFullscreenImage
                                        src={getCompressedImageUrl(imageUrl, PostImageSize.preview.w, PostImageSize.preview.h)}
                                        className={"card-img-top border-0"}
                                    />
                                }

                                <PostCardHeader className={"card-header"}>
                                    <h5 className={"card-title"}>
                                        <b><SafeHtmlContainer html={this.state.postData.titolo}/></b>
                                    </h5>
                                    <div className={"d-flex align-items-center"}>
                                        <div className={"mr-3"}>
                                            <AppIconThumbnail
                                                size={'45px'}
                                                onClick={_ => this.state.postData.onAppOpen(this.state.postData.id_negozio)}
                                                src={`${AppConfig.webServerUrl}/api/negozio/${this.state.postData.id_negozio}/logoIcon/100`}
                                            />
                                        </div>
                                        <div>
                                            <b onClick={_ => this.state.postData.onAppOpen(this.state.postData.id_negozio)}>
                                                <SafeHtmlContainer html={this.state.postData.nome_negozio}/>
                                            </b>
                                            <TypeAndTimePost className={"text-muted"}
                                                             onClick={this.state.postData.onInfoClick}>
                                                {this.state.postData.typeIcon} {this.getCalendarTimestamp(this.state.postData.data_creazione)}
                                            </TypeAndTimePost>
                                        </div>
                                    </div>
                                </PostCardHeader>
                                {
                                    this.props.useActionBar &&
                                    <FeedPostCardActionBar
                                        {...this.state.postData}
                                        onAppAdd={this.props.onAppAdd}
                                        onAppOpen={this.props.onAppOpen}
                                    />
                                }

                                {
                                    this.state.postData.commento &&
                                    <div>

                                    </div>
                                }

                                {
                                    this.state.postData.testo || this.state.postData.link ?
                                        <div className={"card-body border-top"}>
                                            <div className={"card-text"}>
                                                <SafeHtmlContainer
                                                    html={replaceHashtag(this.state.postData.testo, '<span class="text-facebook">{{hashtag}}</span>', true)}/>
                                            </div>
                                            {
                                                this.state.postData.link &&
                                                <a className={"btn btn-primary text-on-bg-primary float-right btn-sm mt-2"}
                                                   href={this.state.postData.link}>
                                                    <FaSolidIcon name={"external-link-alt"}/> Vai al sito
                                                </a>
                                            }
                                        </div> : ""
                                }
                                <div id={"app-modal-root"}/>
                            </div>
                        </div>
                            :
                        <div className={"bg-white"}>
                            <FeedPostView {...this.state.postData}/>
                        </div>
                    }
                </div>
            </Fragment>
        )
    }
}


DirectPostContainer.propTypes = {
    id_post: PropTypes.any,
    useMetaTags: PropTypes.bool,
    useDownloadBanner: PropTypes.bool,
    useActionBar: PropTypes.bool,
    onAppAdd: PropTypes.func,
    onAppOpen: PropTypes.func
}

const CardFullscreenImage = styled.img`
    width: 100%;
    border-radius: 0px;
`;


const AppIconThumbnailXsStyled = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #cdcdcd;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:url(${props => props.src});
`;

const PostCardHeader = styled.div`
    border-bottom: 0px;
    background-color: white;
`;

const TypeAndTimePost = styled.div`
    font-size: 13px;
    margin-top: -3px;
`;
