import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import OrdiniAPI from "./OrdiniAPI";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";
import AppConfig from "../config/AppConfig";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";


var CouponAPI = {
    getValiditaCouponPerUtente: function (codice_coupon, type, importo_speso = null, data_utilizzo = null) {

        const body = {
            id_negozio: NegozioOpenStore.id_negozio,
            codice_coupon: codice_coupon,
            type: type,
        };
        if (importo_speso) body.imposto_speso = importo_speso;
        if (data_utilizzo) body.data_utilizzo = data_utilizzo;

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/coupon/verificaCoupon/${AuthAPI.currentUser.accessToken}?` + new URLSearchParams(body)), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
}

export default CouponAPI;
