import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AppConfig from "../config/AppConfig";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";

var OrdiniAPI = {
    getOrderTime: function(prodotti, data, tipo_ordine, id_indirizzo_spedizione){
        let params = {
            id_negozio: NegozioOpenStore.id_negozio,
            token: AuthAPI.currentUser.accessToken,
            prodotti:prodotti,
            data: data,
            tipo_ordine: tipo_ordine,
            id_indirizzo_spedizione: id_indirizzo_spedizione || null
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ordini/getOrderTime`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    placeOrder: function(prodotti, tipo_ordine, data, orario, id_indirizzo_spedizione, id_indirizzo_fatturazione, metodo_pagamento, note_utente, codice_coupon, wallets = null){
        let params = {
            id_negozio: NegozioOpenStore.id_negozio,
            token: AuthAPI.currentUser.accessToken,
            prodotti:prodotti,
            tipo_ordine: tipo_ordine,
            data:data,
            orario: orario,
            id_indirizzo_spedizione: id_indirizzo_spedizione,
            id_indirizzo_fatturazione: id_indirizzo_fatturazione,
            metodo_pagamento: metodo_pagamento,
            note_utente: note_utente,
            codice_coupon: codice_coupon,
            wallets: wallets
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ordini/placeOrder`), params,
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    verifyPaypalPayment: function (id_ordine, id_ordine_paypal) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            id_ordine: id_ordine,
            id_ordine_paypal: id_ordine_paypal
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/verifyPaypalPayment`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listaAttivi: function(){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/ordini/lista-attivi/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    rifiutaProposta: function(id_ordine){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ordini/rifiuta-proposta`), {id_ordine:id_ordine, token:AuthAPI.currentUser.accessToken},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    accettaProposta: function(id_ordine){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ordini/accetta-proposta`), {id_ordine:id_ordine, token:AuthAPI.currentUser.accessToken},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    cancelOrderByUser: function(id_ordine){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ordini/annulla-by-user`), {id_ordine:id_ordine, token:AuthAPI.currentUser.accessToken},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    passedOrder: function(){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/ordini/getPassed/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getData: function(id_ordine){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/ordini/${id_ordine}/${AuthAPI.currentUser.accessToken}`),{} ,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default OrdiniAPI;
