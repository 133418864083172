import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {MacroCategoryChooserPage} from "./MacroCategoryChooser/MacroCategoryChooserPage";
import SearchAppPage from "./SearchAppPage";
import {ExpoGalleryPage} from "./Expo/ExpoGalleryPage";

export class SearchAppController extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categoriaNegozio: null,
            searchQuery: null,
            expoOpen: false
        }
    }

    handleCategoryChoose = categoria => {
        this.setState({categoriaNegozio: categoria});
    }

    handleSearchDismiss = _ => {
        this.setState({categoriaNegozio: null, searchQuery: null});
    }

    handleDirectSearch = (query, categoria) => {
        this.setState({
            searchQuery: query,
            categoriaNegozio: categoria
        });
    }

    toggleExpoPage = _ => this.setState({expoOpen: !this.state.expoOpen});

    render() {
        return (
            <Fragment>
                <MacroCategoryChooserPage
                    onDismiss={this.props.onDismiss}
                    onChoose={this.handleCategoryChoose}
                    onDirectSearch={this.handleDirectSearch}
                    onExpoOpen={this.toggleExpoPage}
                />
                {
                    this.state.categoriaNegozio &&
                    <SearchAppPage
                        active={true}
                        onDismiss={this.handleSearchDismiss}
                        categoriaNegozio={this.state.categoriaNegozio}
                        initialSearchQuery={this.state.searchQuery}
                    />
                }
                {
                    this.state.expoOpen &&
                        <ExpoGalleryPage
                            onDismiss={this.toggleExpoPage}
                        />
                }
            </Fragment>
        );
    }

}


SearchAppController.propTypes = {
    onDismiss: PropTypes.func.isRequired,
}
