import PhoneInput from "react-phone-input-2";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import it from 'react-phone-input-2/lang/it.json'
import i18n from "../../../i18n";
import {getCurrentLanguage} from "../../../langUtils";

export function PhoneNumberInput(props) {

    const handleChange = (phone, data) => {
        if (phone[0] !== "+") {
            phone = "+" + phone;
        }
        if (props.onChange) props.onChange(phone);
    }

    const inputProps = {
        autoComplete: "new-password"
    }

    let initialCountry = getCurrentLanguage();
    if (initialCountry === 'en' && i18n.language) {
        let variants = i18n.language.split('-').pop().toLowerCase();
        initialCountry = variants === 'en' ? 'gb' : variants;
    }
    return (
        <PhoneInputWrapper
            backgorundColor={props.backgroundColor || ''}
            border={props.border || ''}>
            <PhoneInput
                localization={it}
                country={initialCountry}
                countryCodeEditable={false}
                disableCountryGuess={true}
                autoFormat={false}
                enableSearch={true}
                searchPlaceholder={i18n.t('generic.search-country')}
                searchNotFound={"Nessun risultato"}
                searchClass={"form-control-sm"}
                value={props.value}
                name={"user"}
                onChange={handleChange}
                className={"form-control"}
                specialLabel={"ciao"}
                inputProps={inputProps}
            />
        </PhoneInputWrapper>
    )
}

PhoneNumberInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    backgroundColor: PropTypes.string,
    border: PropTypes.string,
}


const PhoneInputWrapper = styled.div`
    & .react-tel-input .form-control{
        background-color: ${props => props.backgorundColor || ''} !important;
        border: ${props => props.border || ''} !important;
        width:100%!important;
        padding: 0px;
        padding-left: 60px;
    }
 
`;