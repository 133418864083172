import {SeatsBookPaymentType} from "./BookPaymentType";
import {SectorSeatType} from "./SectorSeatType";
import {LinkedBookType} from "./LinkedBookType";

import PropTypes from "prop-types";
import {MapSectorType} from "./MapSectorType";

export const SeatsExtendedBookType = PropTypes.shape({
    book_id: PropTypes.any,
    seat_id: PropTypes.any,
    sector_id: PropTypes.any,
    date: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    total_partecipants: PropTypes.string,
    id_utente: PropTypes.any,
    customer_first_name: PropTypes.string,
    customer_last_name: PropTypes.string,
    notes: PropTypes.string,
    user_notes: PropTypes.string,
    status: PropTypes.string,
    price: PropTypes.number,
    global_price: PropTypes.number,
    global_discount_amount: PropTypes.number,
    price_adjustment: PropTypes.number,
    coupon: PropTypes.string,
    id_coupon: PropTypes.any,
    entrance_date: PropTypes.string,
    first_qr_scan_date: PropTypes.string,
    accessories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        accessory_id: PropTypes.any,
        quantity: PropTypes.number,
        unit_price: PropTypes.number
    })),
    tickets: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        ticket_type_id: PropTypes.any,
        quantity: PropTypes.number,
        unit_price: PropTypes.number
    })),
    payments: PropTypes.arrayOf(SeatsBookPaymentType),
    seat: SectorSeatType,
    sector: MapSectorType,
    user: PropTypes.shape({
        id_utente: PropTypes.any,
        nome: PropTypes.string,
        cognome: PropTypes.string,
        etichette: PropTypes.arrayOf(PropTypes.shape({
            id_etichetta: PropTypes.any,
            etichetta: PropTypes.string
        }))
    }),
    linked_books: PropTypes.arrayOf(LinkedBookType)
});