import React from "react";
import "../../dashboard.css";
import {swal, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import OrdineAdminActionCard from "../Ordini/OrdineAdminActionCard";
import OrdiniDashboardAPI from "../../../../api/OrdiniDashboardAPI";
import Pagination from "../../../../components/BaseComponents/Pagination/Pagination";

export default class DashboardOrdiniEcommerce extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ordini: [],
            totalOrdini: 0,
            pageSize: 10,
            currentPage: 1
        }
    }

    componentDidMount() {
        this.fetchData(this.state.pageSize, (this.state.currentPage - 1) * this.state.pageSize);
    }

    fetchData = (limit, offset) => {
        swalLoading();
        OrdiniDashboardAPI.listaEcommerce(limit, offset).then(data => {
            this.setState({ordini: data.ordini, totalOrdini: data.total});
            swal.safeClose();
        });
    }

    handleOrderStateChange = (id_ordine, state) => {
        let ordini = this.state.ordini.slice();
        for (let i in ordini) {
            if (ordini[i].id_ordine === id_ordine) {
                ordini[i] = {...ordini[i], ...state}
            }
        }
        this.setState({ordini: ordini});
    }

    handlePageChange = page => {
        this.setState({currentPage: page});
        this.fetchData(this.state.pageSize, (page - 1) * this.state.pageSize);
    }

    render() {
        return (
            <div className={"container mt-3 pb-3"} id={"DashboardOrdini"}>
                <div className={"container"}>

                    {
                        this.state.ordini.map((o, i) => {
                            return <OrdineAdminActionCard
                                key={o.id_ordine}
                                className={"my-2"}
                                {...o}
                                onStateChange={this.handleOrderStateChange}
                            />
                        })
                    }
                    <div className={"justify-content-center d-flex"}>
                        {
                            this.state.ordini.length ? <Pagination
                                activePage={this.state.currentPage}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalOrdini}
                                pageRangeDisplayed={5}
                                itemClass="page-item"
                                linkClass="page-link"
                                onChange={this.handlePageChange}
                            /> : <span className={"d-block text-muted text-center"}>
                                Non hai ricevuto ancora nessun ordine
                            </span>
                        }
                    </div>
                </div>
            </div>

        );
    }


}
