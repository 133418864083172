import React,{Fragment} from "react";
import {string} from "prop-types";
import moment from "moment";
import i18n from "../i18n";

export default class ShopOpenOrClose extends React.Component{
    constructor(props) {
        super(props);
    }


    handleOpenOrClose =_=> {

        //DECOMMENTA IN CASO DI BUG
        //return this.handleIfNotSetOrarioAttuale();

        if(!this.props.orarioAttuale){
            return <span className={"text-danger small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.closed')}</span>
        }

        //Recupero orario attuale
        var today = moment(new Date()).format("YYYY-MM-DD HH:mm")

        if(!this.props.orarioAttuale.length){
            return <span className={"text-danger small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.today-closed')}</span>
        }

        for (var i = 0; i<this.props.orarioAttuale.length; i++){
            var hoursCouple = this.props.orarioAttuale[i];
            var firstElementMinus30min = this.remove30MinFromDate(hoursCouple[0])
            var secondElementMinus30min = this.remove30MinFromDate(hoursCouple[1])
            if(hoursCouple[0] <= today && secondElementMinus30min > today){
                return  <span className={"text-success small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.now-opened')}</span>
            }
            if(today >= firstElementMinus30min && today < hoursCouple[0]){
                return <span className={"text-warning small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.almost-opened')}</span>
            }
            if(today >= secondElementMinus30min && today < hoursCouple[1]){
                return <span className={"text-warning small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.almost-closed')}</span>
            }
        }

        return <span className={"text-danger small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.now-closed')}</span>

    }

    remove30MinFromDate = dateAndHour =>{
        return moment(dateAndHour).subtract(30, "minutes").format("YYYY-MM-DD HH:mm")
    }


    handleIfNotSetOrarioAttuale =_=>{
        //Recupero orario e data attuale
        var today = new Date();
        var day= (today.getDay())-1;
        if(day == -1) {day = 6}
        var currMin = today.getMinutes();
        var currH = today.getHours();

        /*controllo giorni chiusura*/
        if(this.props.giorniChiusura !== "") {
            var currDate = today.getFullYear() + '-' +(today.getMonth()+1) + '-' + today.getDate();
            var dataStart;
            var dataEnd;
            var data;
            for (let i = 0; i<this.props.giorniChiusura.length; i++){
                //data giorno chiusura = GG-MM-AAAA => splittando esce [GG,MM,AAAA]!!!
                data = this.props.giorniChiusura[i].data_inizio.split('-');
                dataStart = data[2] + '-' +data[1] +'-' +data[0];
                data = this.props.giorniChiusura[i].data_fine.split('-');
                dataEnd = data[2] + '-' +data[1] +'-' +data[0];
                if (dataStart <= currDate && dataEnd >= currDate){
                    var flagChiusura = 1;
                }
            }
        }

        /*converto ore in minuti*/
        var currTime = currH*60+currMin;

        /* se non settato ora inizio e ora fine, setto a chiuso*/
        if (!this.props.orariNegozio[day].orario_inizio && !this.props.orariNegozio[day].orario_fine){
            return <span className={"text-danger small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.now-closed')}</span>
        }

        /*suddivido ore e minuti di inizio giornata*/
        if (this.props.orariNegozio[day].orario_inizio){
            var startH = this.props.orariNegozio[day].orario_inizio.substr(0, 2);
            var startMin = this.props.orariNegozio[day].orario_inizio.substr(3, 2);
            var start = parseInt(startH)*60+parseInt(startMin);
        }

        /*suddivido ore e minuti di inizio giornata*/
        if (this.props.orariNegozio[day].orario_fine){
            var endH = this.props.orariNegozio[day].orario_fine.substr(0, 2);
            var endMin = this.props.orariNegozio[day].orario_fine.substr(3, 2);
            var end = parseInt(endH)*60+parseInt(endMin);
            /*caso in cui la giornata finisce il giorno dopo*/
            if (start && start>end) {
                /*caso in cui siamo al giorno dopo in currTime*/
                if (currTime < end){currTime = (currH+24)*60+currMin}
                /*Se ci dovrebbe essere giorno chiusura ma siamo oltre la mezzanotte*/
                if(flagChiusura == 1){flagChiusura = 0}
                end = (parseInt(endH) + 24) * 60 + parseInt(endMin);
            }
        }

        /*suddivido ore e minuti dell'inizio pausa*/
        if(this.props.orariNegozio[day].array_pausa){
            if (this.props.orariNegozio[day].array_pausa[0]){
                var pauseStartH = this.props.orariNegozio[day].array_pausa[0].substr(0, 2);
                var pauseStartMin = this.props.orariNegozio[day].array_pausa[0].substr(3, 2);
                var startPause = parseInt(pauseStartH)*60+parseInt(pauseStartMin);
            }

            /*suddivido ore e minuti della fine pausa*/
            if (this.props.orariNegozio[day].array_pausa[1]){
                var pauseEndH = this.props.orariNegozio[day].array_pausa[1].substr(0, 2);
                var pauseEndMin = this.props.orariNegozio[day].array_pausa[1].substr(3, 2);
                var endPause = parseInt(pauseEndH)*60+parseInt(pauseEndMin);
                /*controllo il caso in cui la pausa finisce il giorno successivo*/
                if (startPause && startPause>endPause) {
                    /*controllo il caso in cui siamo al giorno successivo con il currTime*/
                    if(currTime<endPause){currTime = (currH+24)*60+currMin;}
                    endPause = parseInt(pauseEndH + 24) * 60 + parseInt(pauseEndMin);
                }

                /*caso in cui la giornata finisce il girono dopo rispetto alla fine pausa*/
                if(endPause>end){
                    /*controllo il caso in cui siamo al giorno successivo con il currTime*/
                    if (currTime < end){currTime = (currH+24)*60+currMin}
                    end = (parseInt(endH)+ 24) * 60 + parseInt(endMin);
                }
            }
        }

        /*controllo la chiusura*/
        if (flagChiusura == 1 ){
            return <span className={"text-danger small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.today-closed')}</span>
        }

        /*situazione base, controllo se siamo all'inetrno dell'orario lavorativo*/
        if (start <= currTime && end >= currTime) {
            /*controllo se sono in pausa*/
            if (startPause <= currTime && endPause >= currTime){
                if (currTime >= (endPause-30) && currTime < endPause) {
                    return <span className={"text-warning small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.almost-opened')}</span>
                }
                return <span className={"text-danger small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.now-closed')}</span>
            }

            if(currTime>=(end-30) && currTime < end){
                return <span className={"text-warning small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.almost-closed')}</span>
            }

            if(startPause && currTime>=(startPause-30) && currTime < startPause){
                return <span className={"text-warning small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.almost-closed')}</span>
            }
            return  <span className={"text-success small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.now-opened')}</span>
        }


        /*controllo se siamo tra inizio e pausa*/
        if (this.props.orariNegozio[day].array_pausa[0] !== ""){
            if (start <= currTime && startPause >= currTime){
                if(currTime>=(startPause-30) && currTime < startPause){
                    return <span className={"text-warning small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.almost-closed')}</span>
                }
                return  <span className={"text-success small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.now-opened')}</span>
            }
        }

        /*controllo se siamo tra pausa e fine*/
        if (this.props.orariNegozio[day].array_pausa[1] !== "") {
            if (endPause <= currTime && end >= currTime) {
                if(currTime >= (end-30) && currTime < end){
                    return <span className={"text-warning small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.almost-closed')}</span>
                }
                return <span className={"text-success small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.now-opened')}</span>
            }
        }


        if(currTime>=(start-30) && currTime < start){
            return <span className={"text-warning small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.almost-opened')}</span>
        }

        return <span className={"text-danger small"}><i className="far fa-clock"></i> {i18n.t('business-info.work-time.now-closed')}</span>
    }


    render() {
        return this.handleOpenOrClose()
    }

}
