import React from "react";
import PropTypes from "prop-types";

export class DropdownSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.multiple ? [] : null,
            open: false,
            query: '',
        }
        this.menuStyle = {
            maxHeight: props.maxHeight ? `${props.maxHeight}px` : 'auto',
            overflow: 'auto'
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOutsideClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick);
    }

    handleInputChange = ({target}) => this.setState({[target.name]: target.value});

    handleOutsideClick = event => {
        if (this.state.open) {
            this.toggleOpen(event);
        }
    }

    handleClick = value => {
        let newSelected;
        if (this.props.multiple) {
            newSelected = this.state.selected.slice();
            if (newSelected.find(v => v === value)) { //Nel caso in cui sia già presente devo deselezionarlo
                newSelected = newSelected.filter(v => v !== value);
            } else {
                newSelected.push(value);
            }
        } else {
            newSelected = value;
        }

        this.setState({
            selected: newSelected,
            open: this.props.multiple ? this.state.open : false
        }, _ => {
            this.props.onChange(this.state.selected);
        });
    }

    isSelected = value => {
        if (this.props.multiple) {
            return !!this.state.selected.find(v => v === value);
        } else {
            return this.state.selected === value;
        }
    }

    toggleOpen = event => {
        event.stopPropagation();
        this.setState({open: !this.state.open});
    }

    getButtonText = _ => {
        if (this.props.multiple) {
            if (!this.state.selected.length) {
                return this.props.nothingSelectedText || 'Nessuna selezione';
            }
            return `${this.state.selected.length} selezionati`;
        } else {
            if (!this.state.selected) {
                return this.props.nothingSelectedText ||  'Nessuna selezione';
            }
            return this.props.items.find(item => item.value === this.state.selected).label;
        }
    }

    matchQuery = (text, query) => {
        const keywords = query.split(' ');
        const lower = text.toLowerCase();
        for(let k of keywords){
            if (lower.indexOf(k.toLowerCase()) === -1) return false;
        }
        return true;
    }

    render() {
        return (
            <div className={`dropdown ${this.props.direction}`}>
                <button className="btn dropdown-toggle border form-control text-left d-flex align-items-center" type="button"
                        onClick={this.toggleOpen}>
                    <div className={"pr-3 text-truncate"}>{this.getButtonText()}</div>
                </button>
                <div className={"dropdown-menu " + (this.state.open ? "show" : "")} style={this.menuStyle}>
                    {
                        this.props.showSearch &&
                        <DropdownSearchItem
                            onChange={this.handleInputChange}
                            placeholder={this.props.searchPlaceholder || "Cerca per nome..."}
                        />

                    }
                    {
                        this.props.items.map(item => {
                            if (this.state.query && !this.matchQuery(item.label, this.state.query)) return '';
                            return (
                                <DropdownSelectItem
                                    key={item.value}
                                    value={item.value}
                                    label={item.label}
                                    header={item.header}
                                    onClick={this.handleClick}
                                    selected={this.isSelected(item.value)}
                                />
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

DropdownSelect.propTypes = {
    multiple: PropTypes.bool,
    items: PropTypes.array,
    onChange: PropTypes.func,
    maxHeight: PropTypes.number,
    showSearch: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    nothingSelectedText: PropTypes.string,
    direction: PropTypes.string
}

function DropdownSelectItem(props) {
    const handleClick = e => {
        e.stopPropagation();
        props.onClick(props.value);
    }

    if (props.header) {
        return <h6 className="dropdown-header">{props.label}</h6>
    }

    return (
        <div className={"dropdown-item cursor-pointer"} onClick={handleClick}>
            {props.selected &&
            <i className="fa fa-check mr-1"/>}
            {props.label}
        </div>
    )
}


function DropdownSearchItem(props) {
    const handleClick = e => {
        e.stopPropagation();
    }

    return (
        <div className={"dropdown-item"} onClick={handleClick}>
            <input
                type={"text"}
                name={"query"}
                className={"form-control"}
                onChange={props.onChange}
                onClick={handleClick}
                placeholder={props.placeholder}
            />
        </div>
    )
}