import styled from "styled-components";

export const TabViewItem = styled.div`
    font-size:0.9em;
    color:inherit;
    border-bottom: 3px solid transparent;
    &.active{
        color:${props => props.activeColor}!important;
        border-bottom: 3px solid ${props => props.activeColor} !important;
    }
`;

