import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";

export class OspiteView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    hasUncompleteInfo = _ => {
        for (let key in OspiteModel) {
            if (!this.props.ospiteData[key]) {
                return true;
            }
        }
        return false;
    }

    handleClick = _ => {
        this.props.onClick(this.props.ospiteData);
    }

    render() {
        return (
            <div className={"card shadow-sm border-0 mb-3"} onClick={this.handleClick}>
                <div className={"card-body"}>
                    <h6 className={"card-title mb-1"}>{this.props.ospiteData.nome} {this.props.ospiteData.cognome}</h6>
                    <div className={"small text-muted"}>Email: {this.props.ospiteData.email}</div>
                    <div className={"small text-muted"}>Cellulare: {this.props.ospiteData.cellulare}</div>
                    {
                        this.hasUncompleteInfo() &&
                        <div className={"text-danger small"}>
                            <FaSolidIcon name={"exclamation-triangle"}/> Ci sono alcuni dati da completare
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export const OspiteModel = {
    id_ospite: PropTypes.string,
    id_prenotazione: PropTypes.string,
    nome: PropTypes.string,
    cognome: PropTypes.string,
    telefono: PropTypes.string,
    cellulare: PropTypes.string,
    email: PropTypes.string,
    nazione: PropTypes.string,
    citta: PropTypes.string,
    cap: PropTypes.string,
    indirizzo: PropTypes.string,
    provincia: PropTypes.string,
    luogo_nascita: PropTypes.string,
    provincia_nascita: PropTypes.string,
    nazione_nascita: PropTypes.string,
    data_nascita: PropTypes.string,
    sesso: PropTypes.string,
    codice_fiscale: PropTypes.string,
    tipo_documento: PropTypes.string,
    numero_documento: PropTypes.string,
    ente_rilascio_documento: PropTypes.string,
    data_rilascio_documento: PropTypes.string,
    data_scadenza_documento: PropTypes.string,
    comune_rilascio_documento: PropTypes.string,
    nazione_rilascio_documento: PropTypes.string,
    immagine_retro_documento: PropTypes.string,
    immagine_fronte_documento: PropTypes.string,
}

OspiteView.propTypes = {
    ospiteData: PropTypes.shape(OspiteModel),
    onClick: PropTypes.func
}