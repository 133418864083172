import React, {Fragment} from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaSolidIcon} from "../FontAwesomeIcons";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import UserProfileForm from "../Forms/UserProfileForm";
import AuthAPI from "../../api/AuthAPI";
import UserStatus from "../../launcher/SideMenu/UserStatus";
import IndirizziAPI from "../../api/IndirizziAPI";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import EditAddressModal from "../Modals/EditAddressModal";
import {ProfiloUtenteIndirizzoItem} from "../../launcher/SideMenu/ProfiloUtente/ProfiloUtenteIndirizzoItem";
import {ADDRESS_BOOK_CHANGE, AUTHENTICATED_USER_DATA_CHANGE} from "../../const/AppEventsCostants";
import AddAddressModal from "../Modals/AddAddressModal";
import CredentialInfoView from "./CredentialInfoView";


export default class UserProfileModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            indirizzi: null,
            editingAddress: {data: {}, active: false},
            creatingAddress: {invoiceData: false, active: false},
            editEmailModeOpen: false
        };
        this.formWrapperRef = null; //Ref all'object react, non al DOM
    }

    componentDidMount() {
        this.fetchIndirizzi();
        FuxEvents.on(ADDRESS_BOOK_CHANGE, this.fetchIndirizzi);
        FuxEvents.on(AUTHENTICATED_USER_DATA_CHANGE, this.handleUserDataSave);
    }

    componentWillUnmount() {
        FuxEvents.off(ADDRESS_BOOK_CHANGE, this.fetchIndirizzi);
        FuxEvents.off(AUTHENTICATED_USER_DATA_CHANGE, this.handleUserDataSave);
    }

    handleUserDataSave = _ => {
        this.setState({editMode: false});
    }

    /**
     * Page navbar buttons magement
     * */

    startEditMode = _ => {
        this.setState({editMode: true});
    }

    endEditMode = submitForm => {
        if (submitForm) {
            if (this.formWrapperRef) {
                this.formWrapperRef.handleSubmit(new Event("FakeEvent"))
            }
        } else {
            this.setState({editMode: false});
        }
    }

    handleRightIconClick = _ => {
        if (this.state.editMode) {
            this.endEditMode(true);
        } else {
            this.startEditMode();
        }
    }

    handleLeftIconClick = fromSwipe => {
        if (fromSwipe === true) {
            this.endEditMode();
            this.props.onDismiss();
        } else {
            if (this.state.editMode) {
                this.endEditMode(false);
            } else {
                this.props.onDismiss();
            }
        }
    }

    getFormWrapperRef = node => this.formWrapperRef = node;


    /**
     * User status
     * */

    handleLogout = _ => {
        swalConfirm('Sei sicuro di voler uscire?', 'Esci', 'Annulla')
            .then(_ => {
                AuthAPI.logout();
                this.props.onDismiss();
            })
    }


    handleDeleteAccount = _ => {
        swalConfirm('Sei sicuro di richiedere la cancellazione del tuo account?', 'Si, avanti', 'Annulla')
            .then(_ => {
                swalLoading('Attendere prego...');
                AuthAPI.requestAccountCancellation().then(swalSuccess).catch(swalError);
            })
    }


    /**
     * Addresses
     * */

    getIndirizziSpedizione = _ => this.state.indirizzi ? this.state.indirizzi.filter(ind => ind.fatturazione == 0) : [];
    getIndirizziFatturazione = _ => this.state.indirizzi ? this.state.indirizzi.filter(ind => ind.fatturazione == 1) : [];
    handleIndirizzoClick = address => _ => this.setState({editingAddress: {data: address, active: true}});
    handleEditAddressDismiss = _ => this.setState({editingAddress: {data: {}, active: false}})
    fetchIndirizzi = _ => IndirizziAPI.listAll().then(indirizzi => this.setState({indirizzi: indirizzi}));

    handleCreateAddress = isInvoiceData => _ => {
        this.setState({
            creatingAddress: {active: true, invoiceData: isInvoiceData}
        })
    }
    handleCreateAddressDismiss = _ => this.setState({creatingAddress: {invoiceData: false, active: false}})

    /**
     * Rendering
     * */

    renderViewMode() {
        const indirizziSpedizione = this.getIndirizziSpedizione();
        const indirizziFatturazione = this.getIndirizziFatturazione();
        return (
            <div className={"container"}>
                <UserStatus
                    user={this.props.user}
                    allowEdit={true}
                    showBirthday={true}
                    showGender={true}
                    showLang={false}
                />

                {/*Gestisce la pagina di modifica email stampa i due campi email e cellulare*/}
                <CredentialInfoView
                    user = {this.props.user}
                />

                {
                    this.state.indirizzi === null ?
                        <div className={"text-center"}>
                            <FaSolidIcon name={"spin fa-spinner"}/>
                        </div>
                        :
                        <Fragment>

                            <div className={"form-group text-on-bg-main"}>
                                <div className={"row align-items-center"}>
                                    <div className={"col"}>
                                        <small>
                                            <FaSolidIcon name={"map-marker-alt"}/> I miei indirizzi
                                        </small>
                                    </div>
                                    <div className={"col-4 text-right"}>
                                        <button className={"btn btn-link btn-sm"}
                                                onClick={this.handleCreateAddress(false)}>
                                            Crea indirizzo
                                        </button>
                                    </div>
                                </div>
                                <div className={"card px-2 bg-main-z2"}>
                                    <div className={"container"}>
                                        {
                                            !!indirizziSpedizione.length && indirizziSpedizione.map(
                                                (indirizzo, i) =>
                                                    <ProfiloUtenteIndirizzoItem
                                                        key={i}
                                                        address={indirizzo}
                                                        onClick={this.handleIndirizzoClick(indirizzo)}
                                                    />
                                            )
                                        }
                                        {
                                            !indirizziSpedizione.length && <div className={"py-2 text-muted"}>
                                                Nessun indirizzo
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className={"form-group text-on-bg-main"}>
                                <div className={"row align-items-center"}>
                                    <div className={"col"}>
                                        <small>
                                            <FaSolidIcon name={"building"}/> I miei indirizzi di fatturazione
                                        </small>
                                    </div>
                                    <div className={"col-4 text-right"}>
                                        <button className={"btn btn-link btn-sm"}
                                                onClick={this.handleCreateAddress(true)}>
                                            Crea indirizzo
                                        </button>
                                    </div>
                                </div>
                                <div className={"card px-2 bg-main-z2"}>
                                    <div className={"container"}>
                                        {
                                            !!indirizziFatturazione.length && indirizziFatturazione.map(
                                                (indirizzo, i) =>
                                                    <ProfiloUtenteIndirizzoItem
                                                        key={i}
                                                        address={indirizzo}
                                                        onClick={this.handleIndirizzoClick(indirizzo)}
                                                    />
                                            )
                                        }
                                        {
                                            !indirizziFatturazione.length && <div className={"py-2 text-muted"}>
                                                Nessun indirizzo
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </Fragment>
                }

                <button className={"btn btn-link btn-block text-danger mt-3"} onClick={this.handleLogout}>
                    Disconnetti account
                </button>

                <button className={"btn btn-link btn-block text-dark mt-3"} onClick={this.handleDeleteAccount}>
                    Richiedi cancellazione account
                </button>
            </div>
        )
    }

    render() {
        if (!this.props.user) return <br/>;
        return (
            <Fragment>
                <Page
                    active={this.props.active}
                    title={"Il mio profilo"}

                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    leftIconText={this.state.editMode ? 'Annulla' : 'Indietro'}
                    onLeftIconClick={this.handleLeftIconClick}

                    rightIcon={this.state.editMode ? <FaSolidIcon name={"check"}/> : <FaSolidIcon name={"edit"}/>}
                    rightIconText={this.state.editMode ? 'Salva' : 'Modifica'}
                    onRightIconClick={this.handleRightIconClick}

                    content={
                        this.state.editMode ?
                            <UserProfileForm ref={this.getFormWrapperRef} userData={this.props.user}/>
                            :
                            this.renderViewMode()
                    }
                />
                {
                    this.state.creatingAddress.active && <AddAddressModal
                        invoiceData={this.state.creatingAddress.invoiceData}
                        onDismiss={this.handleCreateAddressDismiss}
                    />
                }
                <EditAddressModal
                    active={this.state.editingAddress.active}
                    address={this.state.editingAddress.data}
                    onDismiss={this.handleEditAddressDismiss}
                />

            </Fragment>
        );
    }

}


UserProfileModal.propTypes = {
    onDismiss: PropTypes.func,
};
