import React from "react";
import moment, {duration} from "moment";
import ReactConfetti from "react-confetti";
import {PromotionsAPI} from "../../../../api/Promotions/PromotionsAPI";
import PromotionsCarousel from "../PromotionsCarousel";
import PromotionsBirthdayPage from "./PromotionsBirthdayPage";

export default class PromotionsBirthday extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            runConfetti: false,
            promotions: [],
            birthdayPageOpen: false,
            isInBirthdayRange:false
        }
    }


    componentDidMount() {
        if(this.checkIfIsBirthdayRange()){
            this.setState({isInBirthdayRange: true}, _=>{
                PromotionsAPI.PromotionsBirthday.getPromotionsCompleanno(5,0)
                    .then(response => {
                        this.setState({promotions:response.promotions})
                    })
                this.runConfetti()
            })
        }
    }

    //Verifica se siamo nel range dei -5 +5 giorni dal compleanno
    checkIfIsBirthdayRange =_=>{
        if(!this.props.user) return 0
        var todayNumber = this.getDayInYear(moment());
        var birthdayNumber = this.getDayInYear(moment(this.props.user.data_nascita));
        var giorniDifferenza = todayNumber - birthdayNumber;

        if(giorniDifferenza < 0 && giorniDifferenza >-6){
            return true
        }
        if (giorniDifferenza > 0 && giorniDifferenza < 6){
            return true;
        }
        return giorniDifferenza === 0;
    }


    leapYear = year =>{
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

    runConfetti =_=>{
        this.setState({runConfetti:true})
        setTimeout(() => {
            this.setState({runConfetti:false})
        }, 5000);
    }

    getDayInYear = dateTypeMoment =>{
        var standardDate = dateTypeMoment.set('year', 1970);
        var start = moment("01-01-1970", 'DD-MM-YYYY')
        return  standardDate.diff(start, 'days') + 1;
    }

    //Stampa l'etichetta del compleanno
    printDayBeforeBirthday = _ =>{
        if(!this.props.user) return 0
        var todayNumber = this.getDayInYear(moment());
        //var birthdayNumber = this.getDayInYear(moment(this.props.user.data_nascita));
        var birthdayNumber = this.getDayInYear(moment(this.props.user.data_nascita));
        var giorniDifferenza = todayNumber - birthdayNumber;

        if(giorniDifferenza < 0){
            giorniDifferenza = (giorniDifferenza > 300) ? 365 - giorniDifferenza : giorniDifferenza
            return "Mancano "+Math.abs(giorniDifferenza)+" giorni al tuo compleanno 🥳";
        }
        if (giorniDifferenza > 0){
            giorniDifferenza = (giorniDifferenza < -300) ? 365 + giorniDifferenza : giorniDifferenza
            return "Sono passati " +giorniDifferenza+" giorni dal tuo compleanno 🥳";
        }
        return "Buon compleanno dal team di Bookizon! 🥳";
    }

    birthdayPageOpenToggle =_=>{
        this.setState({birthdayPageOpen: !this.state.birthdayPageOpen})
    }

    render() {
        return (
            <div>
                {this.state.isInBirthdayRange &&
                    <div>
                        <div id={"confettiContainer"} className={"p-3 text-center"}>
                            <ReactConfetti
                                numberOfPieces={100}
                                opacity={0.8}
                                recycle={this.state.runConfetti}
                            />
                            <h2>{this.printDayBeforeBirthday()}</h2>
                            <span className={"muted"}>Scegli tra le offerte dedicate a te per il tuo giorno speciale</span>
                        </div>
                        <PromotionsCarousel
                            onVediDiPiuClick={this.birthdayPageOpenToggle}
                            promotions={this.state.promotions}
                        />

                        {
                            this.state.birthdayPageOpen &&
                            <PromotionsBirthdayPage
                                onDismiss={this.birthdayPageOpenToggle}
                            />
                        }
                    </div>
                }
            </div>
        );
    }

}
