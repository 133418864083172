import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../FontAwesomeIcons";
import {AttachmentsPreview} from "./AttachmentsPreview/AttachmentsPreview";


export function AttachmentsListViewItem({attachment, onRemove, index, ...props}) {
    const handleRemove = e => onRemove(index);
    return (
        <div className={"d-flex align-items-center justify-content-between"}>
            <div className={"flex-shrink-0 mr-2"}>
                <AttachmentsPreview attachment={attachment}/>
            </div>
            <small className={"flex-grow-1 text-truncate text-nowrap"}>{attachment.name}</small>
            <button className={"btn btn-sm btn-link text-danger"} onClick={handleRemove} type={"button"} disabled={props.disabled}>
                <FaSolidIcon name={"trash"}/>
            </button>
        </div>
    )

}

AttachmentsListViewItem.propTypes = {
    onRemove: PropTypes.func.isRequired,
    attachment: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    disabled: PropTypes.bool
}