import React, {Fragment} from 'react';
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import DetailPopover from "./DetailPopover";
import ModalPortalHOC from "./ModalPortalHOC";
import {SwipeablePanel} from "./SwipeablePanel/SwipeablePanel";
import styled from "styled-components";
import {SwipeablePanelGripBar} from "./SwipeablePanel/SwipeablePanelGripBar";

export default class CheckboxListCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected || [],
            itemsName: "CheckboxListGroup_" + (Math.random() * 1000).toFixed(0)
        };
    }

    handleChange = (value, checked) => {
        console.log(value, checked);
        let selected = this.props.selected.slice();
        if (!checked) {
            selected = selected.filter(v => v !== value);
        } else {
            selected.push(value);
        }
        //this.setState({selected: selected});
        this.props.onChange(selected, value, checked); //Restituisco la lista dei selezionati, il valore cambiato e lo stato attuale del valore
    }

    render() {
        return (
            <div>
                {!this.props.items.length ? 'Nessuna opzione selezionabile' : ''}
                {this.props.items.map((item, i) => {
                    const checkedOrder = this.props.selected.findIndex(v => v === item.value)
                    return (
                        <ListCardCheckboxItem
                            key={item.value}
                            name={this.state.itemsName}
                            checked={checkedOrder !== -1}
                            checkedOrder={checkedOrder + 1}
                            onChange={this.handleChange}
                            showOrder={this.props.showOrder}
                            {...item}
                        />
                    )
                })}
            </div>
        )
    }

}

CheckboxListCard.defaultProps = {
    selected: []
}


class ListCardCheckboxItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false
        }
        this.imageStyle = {
            width: 40,
            height: 40,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url(${this.props.image})`,
            borderRadius: '50%'
        }
    }

    handleChange = (changeEvent) => {
        this.props.onChange(changeEvent.target.value, changeEvent.target.checked);
    }

    toggleDetailModal = _ => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }

    render() {
        return (
            <Fragment>
                <label className={"card border-0 shadow-sm bg-main-z2 my-2"}>
                    <div className="card-body">
                        <div className={"row align-items-center"}>
                            <div
                                className={"fux-radiobox " + (this.props.image || this.props.description ? "col-8" : "col-12")}>
                                <input type="checkbox" name={this.props.name} checked={this.props.checked}
                                       value={this.props.value} onChange={this.handleChange}/>
                                <span className="text-on-bg-main">
                                {this.props.checked ? <FaSolidIcon name={"check-square"} className={"mr-2 text-primary"}
                                                                   badge={this.props.showOrder && this.props.checked ? this.props.checkedOrder : undefined}
                                                                   badgeSide={"left"}/> :
                                    <FaRegularIcon name={"circle__"}/>}
                            </span>
                                <span className={"text-on-bg-main"}>{this.props.text}</span>
                                <div>{this.props.subtitle}</div>
                            </div>
                            {
                                this.props.image || this.props.description ?
                                    <div className={"col p-0 text-center"}>
                                        {
                                            this.props.image ? <div
                                                onClick={this.toggleDetailModal}
                                                className={"mx-auto"}
                                                style={this.imageStyle}
                                            /> : ''
                                        }
                                        {
                                            this.props.description ?
                                                <button className={"btn btn-link btn-sm text-primary"}
                                                        onClick={this.toggleDetailModal}>
                                                    Dettagli
                                                </button> : ''
                                        }
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </label>
                <ModalPortalHOC>
                    {this.state.popoverOpen && <div style={BackdropStyle} className={"__androidDismissable__"} onClick={this.toggleDetailModal}/>}
                    {
                        this.state.popoverOpen &&
                        <SwipeablePanel
                            swipeTreshold={5}
                            direction={'top-to-bottom'}
                            active={this.state.popoverOpen}
                            onDismiss={this.toggleDetailModal}
                            disableSwipe={_ => false}
                            animationSpeed={150}
                        >
                            <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                            <div className={"bg-main"}>
                                <DetailPopoverImage src={this.props.image}/>
                                <div className={"container mt-3 pb-4"}>
                                    <h5 className={"font-weight-bold"}>{this.props.text}</h5>
                                    <div>{this.props.subtitle}</div>
                                    {this.props.description}
                                </div>
                            </div>
                        </SwipeablePanel>
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}


const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

const DetailPopoverImage = styled.div`
    width:100%;
    height:40vh;
    background-size:cover;
    background-position:center center;
    background-image:url(${props => props.src});
`;