import React from "react";
import PropTypes from "prop-types";
import AppConfig from "../config/AppConfig";
import {AppIconThumbnail} from "../components/BaseComponents/AppIconThumbnail";
import {EllipsisLoader} from "../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {FaSolidIcon} from "../components/FontAwesomeIcons";


export class BookizonAppUnavailableSplashscreen extends React.Component {
    constructor(props) {
        super(props);
        this.containerStyle = {
            height: '100vh',
            backgroundColor: 'rgba(248,249,250,0.8)',
            position: 'fixed',
            top: 0, left: 0, right: 0, bottom: 0, zIndex: 9000
        }
    }

    render() {
        return (
            <div style={this.containerStyle}
                 className={"container d-flex flex-column align-items-center justify-content-center"}>
                <AppIconThumbnail
                    className={"shadow-sm mb-3"}
                    src={`${AppConfig.webServerUrl}/api/negozio/${this.props.id_negozio}/logoIcon/100`}
                    size={'75px'}
                />
                <h4 className={"text-center font-weight-bold"}>App non disponbile</h4>
                <p className={"lead text-muted text-center"}>
                    Quest'app non è più disponibile su Bookizon.
                    Consulta il gestore dell'attività per ulteriori informazioni.
                </p>
                {
                    this.props.onDismiss &&
                    <button className={"btn btn-primary"} onClick={this.props.onDismiss}>
                        <FaSolidIcon name={"chevron-left"}/> Torna indietro
                    </button>
                }
            </div>
        )
    }

}


BookizonAppUnavailableSplashscreen.propTypes = {
    id_negozio: PropTypes.any,
    onDismiss: PropTypes.func
}
