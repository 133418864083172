import React, {Fragment} from "react";
import styled from "styled-components";
import AppConfig from "../../../config/AppConfig";
import OrdiniAPI from "../../../api/OrdiniAPI";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import OrdineResumePage from "./OrdineResumePage";
import moment from "moment";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import {UPDATE_USER_ORDINI} from "../../../const/AppEventsCostants";
import {ECOMMERCE_ORDER_TYPE_RITIRO} from "../../../const/ModuloEcommerceCostants";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import StaticRating from "../../Recensioni/StaticRating";

const ORDINE_STATUS_ATTESA = 'ordine.in_attesa';
const ORDINE_STATUS_ATTESA_PAGAMENTO = 'ordine.in_attesa_pagamento';
const ORDINE_STATUS_CONFERMATO = 'ordine.confermato';
const ORDINE_STATUS_RIFIUTATO_UTENTE = 'ordine.rifiutato_utente';
const ORDINE_STATUS_RIFIUTATO_ESERCENTE = 'ordine.rifiutato_esercente';
const ORDINE_STATUS_PROPOSTA_ESERCENTE = 'ordine.proposta_esercente';
const ORDINE_STATUS_ANNULLATO_UTENTE = 'ordine.annullato_utente';
const ORDINE_STATUS_COMPLETO = 'ordine.completo';
const ORDINE_STATUS_IN_ARRIVO = 'ordine.in_arrivo'; //Solo se tipo = consegna
const ORDINE_STATUS_PRONTO = 'ordine.pronto'; //Solo se tipo = asporto

export default class OrdineCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoPageOpen: false
        }
    }

    getStatusLabel = _ => {
        switch (this.props.order.status) {
            case ORDINE_STATUS_ATTESA:
                return <span className={"badge badge-warning"}>In attesa di conferma</span>;
            case ORDINE_STATUS_ATTESA_PAGAMENTO:
                return <span className={"badge badge-warning"}>In attesa di pagamento</span>;
            case ORDINE_STATUS_COMPLETO:
                return <span className={"badge badge-success"}>Completato</span>;
            case ORDINE_STATUS_CONFERMATO:
                return <span className={"badge badge-primary"}>Confermato, in preparazione</span>;
            case ORDINE_STATUS_IN_ARRIVO:
                return this.isPreventivo() ? <span className={"badge badge-info"}>Inviato, in attesa</span> :
                    <span className={"badge badge-info"}>In arrivo</span>;
            case ORDINE_STATUS_PRONTO:
                return <span className={"badge badge-info"}>Pronto per il ritiro</span>;
            case ORDINE_STATUS_PROPOSTA_ESERCENTE:
                return <span className={"badge badge-warning"}>In attesa di TUA conferma</span>;
            case ORDINE_STATUS_RIFIUTATO_ESERCENTE:
                return <span className={"badge badge-danger"}>Rifiutato</span>;
            case ORDINE_STATUS_RIFIUTATO_UTENTE:
                return <span className={"badge badge-danger"}>Rifiutato da te</span>;
            case ORDINE_STATUS_ANNULLATO_UTENTE:
                return <span className={"badge badge-danger"}>Annullato da te</span>;
        }
    }

    getOrderTypeLabel = _ => {
        switch (this.props.order.tipo_ordine) {
            case 'asporto':
                return 'ritiro';
            case 'consegna':
                return 'consegna';
        }
    }

    handleRifiutaProposta = _ => {
        swalConfirm('Sei sicuro di voler rifiutare questa proposta? Il tuo ordine verrà annullato definitivamente.', 'Rifiuta', 'Annulla')
            .then(_ => {
                swalLoading();
                OrdiniAPI.rifiutaProposta(this.props.order.id_ordine)
                    .then(swalSuccess)
                    .then(_ => FuxEvents.emit(UPDATE_USER_ORDINI))
                    .catch(swalError);
            });
    }

    handleAccettaProposta = _ => {
        swalConfirm('Sei sicuro di voler accettare questa proposta?', 'Accetta', 'Annulla')
            .then(_ => {
                swalLoading();
                OrdiniAPI.accettaProposta(this.props.order.id_ordine)
                    .then(swalSuccess)
                    .then(_ => FuxEvents.emit(UPDATE_USER_ORDINI))
                    .catch(swalError);
            });
    }

    toggleInfoPage = _ => {
        if (this.state.infoPageOpen) {
            document.body.classList.remove('noscroll');
        } else {
            document.body.classList.add('noscroll');
        }
        this.setState({infoPageOpen: !this.state.infoPageOpen});
    }

    isPreventivo = _ => this.props.order.id_ordine && this.props.order.preventivo === '1';


    render() {
        return (
            <div className={this.props.className}>
                <div className={"card shadow-sm " + this.props.className}>
                    <div className={"card-body"}>
                        <div onClick={this.toggleInfoPage}>
                            <div className={"d-flex align-items-center"}>
                                <AppIconThumbnail
                                    size={"25px"}
                                    src={`${AppConfig.webServerUrl}/api/negozio/${this.props.order.id_negozio}/logoIcon/100`}/>
                                &nbsp;
                                <b>{this.isPreventivo() ? 'Preventivo' : 'Ordine'} #{this.props.order.id_ordine}</b>
                            </div>
                            {
                                //Se vengono passate come props stampa le informazioni sulla recensione
                                this.props.feedbackInformations
                            }
                            <small className={"text-muted"}>
                                {
                                    moment(this.props.order.data_creazione).calendar(null, {
                                        sameDay: '[Oggi alle] HH:mm',
                                        nextDay: '[Domani alle] HH:mm',
                                        nextWeek: 'dddd DD [alle] HH:mm',
                                        lastDay: '[Ieri alle] HH:mm',
                                        lastWeek: '[Lo scorso] dddd [alle] HH:mm',
                                        sameElse: 'dddd D MMM [alle] HH:mm'
                                    })
                                }
                                {
                                    this.props.order.orario && <Fragment>
                                        , consegna {
                                        moment(`${this.props.order.data} ${this.props.order.orario.split("-")[0].trim()}`).calendar(null, {
                                            sameDay: '[oggi alle] HH:mm',
                                            nextDay: '[domani alle] HH:mm',
                                            nextWeek: 'dddd DD [alle] HH:mm',
                                            lastDay: '[ieri alle] HH:mm',
                                            lastWeek: '[lo scorso] dddd [alle] HH:mm',
                                            sameElse: 'dddd D MMM [alle] HH:mm'
                                        })
                                    }
                                    </Fragment>
                                }
                            </small>
                        </div>
                        {this.getStatusLabel()}
                        {
                            this.props.order.tipo_ordine === ECOMMERCE_ORDER_TYPE_RITIRO &&
                            <span className={"ml-2 badge badge-success"}>Ritiro in negozio</span>
                        }
                        {
                            this.props.order.status === ORDINE_STATUS_PROPOSTA_ESERCENTE ?
                                <div>
                                <span>
                                    L'esercente ha proposto un nuovo orario di {this.getOrderTypeLabel()},
                                    le <b>{this.props.order.orario_proposto}</b> invece delle <b>{this.props.order.orario}</b>.
                                </span>
                                    <div className={"row mt-2"}>
                                        <div className={"col"}>
                                            <button className={"btn btn-sm btn-block btn-danger"}
                                                    onClick={this.handleRifiutaProposta}>Rifiuta
                                            </button>
                                        </div>
                                        <div className={"col"}>
                                            <button className={"btn btn-sm btn-block btn-primary"}
                                                    onClick={this.handleAccettaProposta}>Accetta
                                            </button>
                                        </div>
                                    </div>
                                </div> : ''
                        }
                        <div className={"row mt-2"}>
                            <div className={"col"}>
                                <button className={"btn btn-soft-secondary btn-block btn-sm"}
                                        onClick={this.toggleInfoPage}>
                                    Dettagli
                                </button>
                            </div>
                            {
                                this.props.showOpenApp &&
                                <div className={"col"}>
                                    <button className={"btn btn-soft-primary btn-block btn-sm"}
                                            onClick={_ => this.props.onAppOpen(this.props.order.id_negozio)}>
                                        Apri l'app
                                    </button>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <ModalPortalHOC>
                    {
                        this.state.infoPageOpen ?
                            <OrdineResumePage
                                id_ordine={this.props.order.id_ordine}
                                onDismiss={this.toggleInfoPage}
                                onCronologiaDismiss={this.props.onCronologiaDismiss}
                            /> : ''
                    }
                </ModalPortalHOC>
            </div>
        );
    }

}
