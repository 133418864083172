import React, {Fragment} from "react";
import PartnersAppItem from "../../../components/PartnershipNegozi/PartnersAppItem";
import PropTypes from "prop-types";
import {NegozioPartnershipAppCarousel} from "../../../components/PartnershipNegozi/NegozioPartnershipAppCarousel";
import PromotionsItem from "./PromotionsItem";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";


export default class PromotionsCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            <Fragment>
                <div className={"container-fluid w-100 __disable-swipe"}>
                    <div className={"row flex-row flex-nowrap p-2"}
                         style={{scrollSnapType: "x mandatory", overflowX: "auto"}}>
                        {
                            this.props.promotions.map((promotion, i) =>
                                <PromotionsItem
                                    key={i}
                                    {...promotion}
                                />
                            )
                        }
                    </div>
                    <div className={"w-100 text-right mt-2"}>
                        <button className={"btn btn-link text-blue p-0"} onClick={this.props.onVediDiPiuClick}>Mostra tutto <FaSolidIcon name={"arrow-circle-right"}/></button>
                    </div>
                </div>
            </Fragment>
        )
    }
}


PromotionsCarousel.propTypes = {
    promotions: PropTypes.array.isRequired,
}