import React, {Fragment} from "react";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import PropTypes from "prop-types";
import {ModuloCassaAPI} from "../../../../api/ModuloCassa/ModuloCassaAPI";
import moment from "moment";
import {Litepicker} from "litepicker";
import 'litepicker/dist/plugins/mobilefriendly';
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {formatCurrency} from "../../../../helpers/CurrencyHelpers";
import {BookizonAppManager} from "../../../../index";
import {ALIQUOTE_IVA} from "./ModuloCassaContants";


export default class StatisticsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            statistics: {},
            fetching: false
        }
        this.startDateInputRef = null;
        this.endDateInputRef = null;
        this.datePickerInstance = null;
    }

    componentDidMount() {
        this.fetchStatistics()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) { //Whenever date range is changed
            this.fetchStatistics();
        }
    }

    fetchStatistics = _ => {
        this.setState({fetching: true})
        ModuloCassaAPI.Dashboard.getStatisticalInformation(this.state.startDate, this.state.endDate)
            .then(data => {
                this.setState({statistics: data, fetching: false})
            })
    }

    /** @MARK: Refs */
    handleCheckinRef = node => {
        if (node) {
            this.startDateInputRef = node;
            if (this.startDateInputRef && this.endDateInputRef) this.handleLightpickerInit();
        }
    }
    handleCheckoutRef = node => {
        if (node) {
            this.endDateInputRef = node;
            if (this.startDateInputRef && this.endDateInputRef) this.handleLightpickerInit();
        }
    }

    handleLightpickerInit = _ => {
        if (this.datePickerInstance) this.datePickerInstance.destroy();
        this.datePickerInstance = new Litepicker({
            element: this.startDateInputRef,
            elementEnd: this.endDateInputRef,
            singleMode: false,
            lang: 'it-IT',
            format: 'DD MMM',
            autoApply: true,
            buttonText: {
                "apply": "Conferma",
                "cancel": "Annulla"
            },
            tooltipText: {
                one: 'giorno',
                other: 'giorni'
            },
            lockDays: (this.props.closingDays || []).map(closingDays => {
                return [closingDays.data_inizio, closingDays.data_fine]
            }),
            disallowLockDaysInRange: true,
            plugins: ['mobilefriendly'],
            tooltipNumber: (totalDays) => {
                return totalDays;
            },
            setup: picker => {
                picker.on('selected', (date1, date2) => {
                    this.setState({
                        startDate: moment(date1.dateInstance).format('YYYY-MM-DD'),
                        endDate: moment((date2 || date1).dateInstance).format('YYYY-MM-DD'),
                    })
                })
            }
        });
    }

    getAliquoteIvaUsed = _ => {
        let aliquoteIvaUsed = []
        if (!this.state.statistics.iva) return aliquoteIvaUsed
        for (const [key, value] of Object.entries(this.state.statistics.iva)) {
            aliquoteIvaUsed.push(key)
        }
        return aliquoteIvaUsed
    }

    getAliquotaDescription = code => {
        let description = ""
        ALIQUOTE_IVA.map(a => {
            if (a.codice === code) description = a.descrizione
        })
        return description
    }

    render() {

        const startDateFormatted = this.state.startDate ? moment(this.state.startDate).format('ddd DD MMMM') : '';
        const endDateFormatted = this.state.endDate ? moment(this.state.endDate).format('ddd DD MMMM') : '';

        return (
            <Fragment>
                <Page
                    title={"Statistiche"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <div>
                            <div className={"bg-primary container pb-4"}>
                                <small className={"text-white"}>Scegli periodo</small>
                                <div className={"card card-body shadow-sm border-0"}>
                                    <div className={"row align-items-center"}>
                                        <div className={"col-6 text-dark"}>
                                            <b>A partire dal</b>
                                            <DatePickerInput onRef={this.handleCheckinRef} defaultText={"Clicca QUI"}
                                                             value={startDateFormatted}/>
                                        </div>
                                        <div className={"col-6 text-dark"}>
                                            <b>Fino al</b>
                                            <DatePickerInput onRef={this.handleCheckoutRef} defaultText={"Clicca QUI"}
                                                             value={endDateFormatted}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.fetchingData &&
                                <div className={"mt-5 w-100 text-center"}>
                                    <EllipsisLoader/>
                                </div>
                            }

                            {
                                !this.state.fetching && this.state.statistics &&
                                <div className={"container"}>
                                    <div className={"row mt-3"}>
                                        <div className={"col-12 mb-3"}>
                                            <div className={"card card-body shadow-sm border-0"}>
                                                <div>
                                                    {
                                                        parseInt(this.state.statistics.salvati_da_sempre) > 0 ?
                                                            <div>Ci sono <span
                                                                className={"text-warning"}>{this.state.statistics.salvati_da_sempre} scontrini</span> NON
                                                                ANCORA inviati</div> :
                                                            <div>Tutti gli scontrini <span className={"text-success"}>sono stati inviati</span>
                                                            </div>
                                                    }
                                                </div>
                                                <div className={"d-flex align-items-center"}>
                                                    <div className={"progress w-100"}>
                                                        <div
                                                            className={"progress-bar progress-bar-striped progress-bar-animated " + (parseInt(this.state.statistics.salvati_da_sempre) > 0 ? "bg-warning" : "bg-success")}
                                                            role="progressbar"
                                                            style={{width: parseInt(this.state.statistics.inviati_da_sempre) * 100 / (parseInt(this.state.statistics.salvati_da_sempre) + parseInt(this.state.statistics.inviati_da_sempre)) + "%"}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"col-6 text-center"}>
                                            <div className={"card card-body shadow-sm border-0"}>
                                                <h3>{this.state.statistics.creati}</h3>
                                                <div className={"small text-muted"}>Scontrini creati</div>
                                            </div>
                                        </div>

                                        <div className={"col-6 text-center"}>
                                            <div className={"card card-body shadow-sm border-0"}>
                                                <h3>{formatCurrency(this.state.statistics.totale || 0, 'EUR', 'it-IT')}</h3>
                                                <div className={"small text-muted"}>Importo scontrinato</div>
                                            </div>
                                        </div>

                                        <div className={"col-12 mt-3"}>
                                            <small className={"text-muted"}>Metodi pagamento</small>
                                            <div className={"list-group shadow-sm border-0"}>
                                                <div
                                                    className={"list-group-item border-0 d-flex justify-content-between align-items-center"}>
                                                    <div>
                                                        <FaSolidIcon name={"coins text-info mr-2"}/> Pagamenti in
                                                        contanti
                                                    </div>
                                                    <span>{formatCurrency(this.state.statistics.totale_contanti || 0, 'EUR', 'it-IT')}</span>
                                                </div>
                                                <div
                                                    className={"list-group-item border-0 d-flex justify-content-between align-items-center"}>
                                                    <div>
                                                        <FaSolidIcon name={"credit-card text-success mr-2"}/> Pagamenti
                                                        elettronici
                                                    </div>
                                                    <span>{formatCurrency(this.state.statistics.totale_pagamento_elettronico || 0, 'EUR', 'it-IT')}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"col-12 mt-3"}>
                                            <small>Riepilogo Iva</small>
                                            <div className={"list-group shadow-sm border-0"}>
                                                {this.getAliquoteIvaUsed().map((iva, key) => {
                                                    if (iva === "stima_iva_da_versare") {
                                                        return (
                                                            <div
                                                                className={"list-group-item border-0 d-flex justify-content-between align-items-center"}
                                                                key={key}>
                                                                <div>
                                                                    <FaSolidIcon
                                                                        name={"comments-dollar text-danger mr-2"}/> {iva.charAt(0).toUpperCase() + iva.replaceAll("_", " ").slice(1)}
                                                                </div>
                                                                <span>{formatCurrency(this.state.statistics.iva[iva], 'EUR', 'it-IT')}</span>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <div className={"list-group-item border-0 d-flex justify-content-between align-items-center"} key={key}>
                                                            <div>
                                                                <FaSolidIcon name={"bag-shopping text-warning mr-2"}/> Venduto come {this.getAliquotaDescription(iva)} IVA
                                                            </div>
                                                            <span>{formatCurrency(this.state.statistics.iva[iva], 'EUR', 'it-IT')}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    }
                />
            </Fragment>
        );
    }
}


StatisticsPage.propTypes = {
    userNegozio: PropTypes.object,
    onDismiss: PropTypes.func
}


const DatePickerInput = ({value, onClick, defaultText, onRef}) => <div
    ref={onRef}
    className={(!value ? 'text-muted' : '') + ' text-capitalize cursor-pointer'} onClick={onClick}>
    {value ? value : defaultText}
</div>
