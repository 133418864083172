import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "./../APIConfig";
import AuthAPI from "./../AuthAPI";

var ShortLinkAPI = {
    generate: function (url) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/short-link/generate`), {token: AuthAPI.currentUser.accessToken, url: url},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default ShortLinkAPI;
