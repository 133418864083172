import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const _User = {
    getUsableWallets:  function (id_negozio = null, module = null, items = null) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/wallets/user/get-usable`), {
                token: AuthAPI.getUserAccessToken(),
                id_negozio: id_negozio,
                module: module,
                items: btoa(JSON.stringify(items))
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getWallets:  function (id_negozio = null) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/wallets/user/get-wallets`), {
                token: AuthAPI.getUserAccessToken(),
                id_negozio: id_negozio
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getWalletWelfare: function (){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/wallets/user/get-wallet-welfare`), {
                token: AuthAPI.getUserAccessToken(),
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getWalletTransactions: function (cursor, id_wallet){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            cursor: cursor,
            id_wallet: id_wallet
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/wallets/user/get-transactions`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    }
}