import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../config/AppConfig";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";

var ProdottiFoodAPI = {
    list: function (lang, id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-food/prodotti/${id_negozio || NegozioOpenStore.id_negozio}/list`), {
                lang: lang,
                token: AuthAPI.getUserAccessToken(),
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listCategorie: function (lang, id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-food/prodotti/categorie/${id_negozio || NegozioOpenStore.id_negozio}/food/list`), {lang: lang},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default ProdottiFoodAPI;
