import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const _BookingEngine = {
    cancelBookWithPaymentId: riferimento_pagamento => {
        return FuxHTTP.post(
            APIConfig.url(`/modulo-servizi/booking-engine/cancel-book-with-payment-id`), {
                token: AuthAPI.getUserAccessToken(),
                riferimento_pagamento: riferimento_pagamento
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    checkDateTimeAvailability: (date, time, id_sede, id_dipendente, id_servizio) => {
        return FuxHTTP.get(
            APIConfig.url(`/modulo-servizi/booking-engine/check-date-time-availability`), {
                token: AuthAPI.getUserAccessToken(),
                date: date,
                time: time,
                id_sede: id_sede,
                id_dipendente: id_dipendente,
                id_servizio: id_servizio
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_RESPONSE
        );
    }
}