import React from "react";
import PropTypes from "prop-types";
import {applyPercentage} from "../../../helpers/NumberUtility";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import styled from "styled-components";

const Modifier = styled.div`
    display:inline;
    & + &:before{
        content:", ";
        display:inline;
    }
`;


export class FoodSharedCartProductItem extends React.Component {

    constructor(props) {
        super(props);
    }

    getCurrentDiscountPercentage = _ => this.props.productData.perc_sconto ? parseFloat(this.props.productData.perc_sconto) : 0.0;

    getDiscountLabel = _ => {
        const discount = this.getCurrentDiscountPercentage();
        if (discount) {
            return <span className={'badge badge-success'}>Sconto del {discount}%</span>
        }
        return '';
    }

    getPrezzo = _ => {
        let prezzoBase = applyPercentage(parseFloat(this.props.productData.prezzo), -this.getCurrentDiscountPercentage());
        let prezzoAggiunte = 0;
        if (this.props.productData.aggiunte) {
            this.props.productData.aggiunte.map(id_aggiunta => {
                const datiAggiunta = this.props.productData.listaAggiunte.find(a => a.id_aggiunta === id_aggiunta);
                prezzoAggiunte += parseFloat(datiAggiunta.prezzo);
            });
        }
        let prezzoOpzione = 0;
        if (this.props.productData.id_opzione) {
            const datiOpzione = this.props.productData.listaOpzioni.find(o => o.id_opzione === this.props.productData.id_opzione);
            console.log(parseFloat(datiOpzione.prezzo));
            prezzoOpzione += parseFloat(datiOpzione.prezzo);
        }
        return (prezzoBase + prezzoAggiunte + prezzoOpzione) * this.props.productData.quantita;
    }

    render() {
        return (
            <div className={"list-group-item bg-main-z2"}>
                <div className={"container-fluid"}>
                    <div className={"row align-items-center"}>
                        <div className={'col-12 px-1'}>
                            <b><SafeHtmlContainer
                                html={this.props.productData.nome}/></b> x {this.props.productData.quantita}
                            <span className={"badge badge-primary ml-2"}>
                                    {`€${this.getPrezzo().toFixed(2)}`}
                                </span>
                            {this.getDiscountLabel()}
                        </div>
                        <div className={"col-12"}>
                            {
                                this.props.productData.id_opzione &&
                                <Modifier>
                                    <small>
                                        <FaSolidIcon name={"info-circle"}
                                                     className={"text-info"}/> {this.props.productData.listaOpzioni.find(o => o.id_opzione === this.props.productData.id_opzione).nome}
                                    </small>
                                </Modifier>
                            }
                            {
                                this.props.productData.attributi.cottura &&
                                <Modifier>
                                    <small>
                                        <FaSolidIcon name={"info-circle"}
                                                     className={"text-info"}/> {this.props.productData.attributi.cottura}
                                    </small>
                                </Modifier>
                            }
                            {
                                !!this.props.productData.aggiunte &&
                                this.props.productData.aggiunte.map(id_aggiunta => {
                                    const datiAggiunta = this.props.productData.listaAggiunte.find(a => a.id_aggiunta === id_aggiunta);
                                    return <Modifier key={id_aggiunta} className={"text-muted"}>
                                        <small>
                                            <FaSolidIcon name={"plus-circle text-success"}/>
                                            {datiAggiunta.nome}
                                        </small>
                                    </Modifier>
                                })
                            }
                            {
                                !!this.props.productData.rimozioni &&
                                this.props.productData.rimozioni.map((rimozione, i) => {
                                    return <Modifier key={i} className={"text-muted"}>
                                        <small>
                                            <FaSolidIcon name={"minus-circle text-danger"}/> {rimozione}
                                        </small>
                                    </Modifier>
                                })
                            }
                        </div>
                        {
                            this.props.productData.note ?
                                <div className={"col-12"}>
                                    <small>
                                        <FaSolidIcon name={"file-alt"}/> {
                                        this.props.productData.note.split('\n').map(
                                            line => <span>{line}<br/></span>
                                        )
                                    }
                                    </small>
                                </div> : ''
                        }
                    </div>
                </div>
            </div>
        );
    }


}

FoodSharedCartProductItem.propTypes = {
    productData: PropTypes.object
}
