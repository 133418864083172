import React from 'react';
import './style.css';
import PropType from 'prop-types';
import styled from "styled-components";

import {CSSTransition} from "react-transition-group";
import {BookizonAppManager} from "../../../index";
import Swipe from "react-easy-swipe";
import {hasParentWithMatchingSelector} from "../../../helpers/DomUtility";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";


const PageSC = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 2000;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    top:0px;
    left:0px;
    transform: translateX(105%);
    transition: transform .3s linear;
    will-change: transform;
    background-color: ${props => props.pageBgColor || ''};
  
    &.page-slide-appear{
        transform:translateX(105%);
    }
    &.page-slide-appear-active, &.page-slide-appear-done{
        transform:translateX(0);
    }
    &.page-slide-enter{
        transform:translateX(105%);
    }
    &.page-slide-enter-active, &.page-slide-enter-done{
        transform:translateX(0);
    }
    &.page-slide-exit{
        transform:translateX(0);
    }
    &.page-slide-exit-active,&.page-slide-exit-done{
        transform:translateX(105%);
    }
    
    & .page-navbar {
        background-color: ${props => props.navbarBgColor || '#293069'};
        color: ${props => props.navbarTextColor || '#ffffff'};
    }
    
    & .page-title{
        
    }
    
    & .page-content{
        height:calc(100vh - 44px - ${props => props.hasFooter ? '80px' : '0px'});
        overflow-y:auto;
        
        /* Nascondo la scrollbar */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar{
          display: none;
        }
    }
    
    
    /* E' in cima alle pagine */
    &.__stackTop__{
        visibility:visible;
    }
    
    /* E' almeno in posizione 2 o più */
    &.__stackBottom__{
        visibility:hidden;
    }
    
    /* E' in posizione 2 e deve essere visibile */
    &.__stackPreviousShowing__.__stackBottom__{
        visibility:visible;
    }
    
`;

const SWIPE_X_TRESHOLD = 50;
const pageTopStackClassName = '__stackTop__';
const pageStackHidden = '__stackBottom__';
const pageStackPreviousShowing = '__stackPreviousShowing__';

const FloatingNavigationBarStyle = {
    backgroundColor: 'transparent',
    position: 'absolute',
    zIndex: 2000
};

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hiding: false,
        }
        this.ref = null;
        this.pageContentRef = null;
        this.pageNavbarRef = null;
        this.hiding = false;
        this.swipeMoveX = 0;
        this.lastMovePosition = null;
        this.hasRemovedScrollToBody = false;
        this.lastDocumentScrollPos = null;

        this.lastActivePage = null;
        this.pageNativeColorHandler = PageNativeColorsHandler();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.hiding = !nextProps.active && this.props.active;
        return true;
    }

    componentDidMount() {
        if (this.props.active) {
            this.handleRemoveBodyScroll();
            this.handlePreviousPageHide();
        }
    }

    componentWillUnmount() {
        this.handleRestoreBodyScroll();
        this.handlePreviousPageShow();
        if (this.pageNativeColorHandler.hasChangedColor()) this.pageNativeColorHandler.restoreOldColor();
    }

    handleNativeBackButton = _ => {
        console.log(this.ref);
        if (this.ref.classList.contains(pageTopStackClassName)) {
            this.props.onLeftIconClick();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.active && this.props.active) { //Mostrando
            this.handleRemoveBodyScroll();
            this.handlePreviousPageHide();
            this.getNavbarBackgorundColor().then(this.pageNativeColorHandler.setNewTopColor);
        }
        if (!this.props.active && prevProps.active) { //Nascondendo
            this.handleRestoreBodyScroll();
            this.handlePreviousPageShow();
            this.pageNativeColorHandler.restoreOldColor();
        }
    }

    handlePreviousPageHide = _ => {
        if (BookizonAppManager.getMobileOperatingSystem() === 'iOS') {
            return false;
        }

        const oldPage = document.querySelector('.' + pageTopStackClassName);
        if (oldPage) {

            //Ritardo l'hiding della pagina precedente di un tempo pari
            //alla durata dell'animazione di entrata di questa pagina
            setTimeout(_ => {
                oldPage.classList.remove(pageTopStackClassName);
                oldPage.classList.add(pageStackHidden);
            }, 300);

            this.lastActivePage = oldPage;
            console.log('{STACK}', this.props.title, 'NEW TOP STACK', this.ref, 'HIDING', oldPage);
        } else {
            console.log('{STACK}', this.props.title, 'PRIMA PAGINA STACK', this.ref);
        }
        this.ref.classList.add(pageTopStackClassName);
    }

    handlePreviousPageShow = _ => {
        if (BookizonAppManager.getMobileOperatingSystem() === 'iOS') {
            return false;
        }

        if (this.lastActivePage) {
            console.log('{STACK}', this.props.title, 'IS GONE', 'SHOWING PREVIOUS', this.lastActivePage);
            this.lastActivePage.classList.remove(pageStackHidden);
            this.lastActivePage.classList.add(pageTopStackClassName);
        } else {
            console.log('{STACK}', this.props.title, 'IS GONE', 'NO PREVIOUS PAGE TO RESTORE');
        }
        this.ref.classList.remove(pageTopStackClassName);
    }

    handleRemoveBodyScroll = _ => {
        this.hasRemovedScrollToBody = document.body.className.indexOf("noscroll") === -1;
        if (this.hasRemovedScrollToBody) {
            this.lastDocumentScrollPos = document.documentElement.scrollTop;
            document.documentElement.scrollTop = 0;
            console.log("Page add noscroll", this.props.title);
        }
        document.body.classList.add('noscroll');
    }

    handleRestoreBodyScroll = _ => {
        //Rimuovo il "noscroll" solo se sono stato io ad aggiungerlo
        if (this.hasRemovedScrollToBody) {
            document.body.classList.remove('noscroll');
            if (this.lastDocumentScrollPos !== null) {
                document.documentElement.scrollTop = this.lastDocumentScrollPos;
                this.lastDocumentScrollPos = null;
            }
            console.log("Page remove noscroll", this.props.title);
        }
    }

    decolorizeStatusBar = _ => {
        if (NegozioOpenStore.id_negozio) {
            const otherOpenedPage = document.querySelectorAll(".page.page-slide-enter-done");
            if (!otherOpenedPage.length || (otherOpenedPage.length === 1 && otherOpenedPage[0] === this.ref)) {
                //BookizonAppManager.setNativeTopUnsafeAreaColor(NegozioOpenStore.infoNegozio.aspetto.bg_main_z2, this.props.title);
            }
        }
    }

    handleTouchMove = e => {
        //e.stopPropagation();
        try {
            let path = e.nativeEvent.path;
            for (let i in path) {
                if (path.hasOwnProperty(i)) {
                    if (path[i] && path[i].className && path[i].className.indexOf('__touchfix__') > -1) {
                        path[i].style.webkitTransform = '';
                        path[i].style.webkitTransform = 'scale(1)';
                    }
                }
            }
        } catch (e) {
            console.warn("handleTouchMove", e);
        }
    }

    getWrapperRef = node => {
        this.ref = node;
    }
    getPageContentRef = node => {
        this.pageContentRef = node;
    }

    getPageNavbarRef = node => {
        if (node) {
            this.pageNavbarRef = node;
        }
        if (node && this.props.active) {
            this.getNavbarBackgorundColor().then(this.pageNativeColorHandler.setNewTopColor)
        }
    }

    getNavbarBackgorundColor = _ => {
        return new Promise(resolve => {
            if (this.pageNavbarRef) {
                const styles = getComputedStyle(this.pageNavbarRef);
                let counter = 50;
                const interval = setInterval(_ => {
                    const backgroundColor = styles.getPropertyValue('background-color');
                    if (backgroundColor) {
                        const rgbComponents = backgroundColor
                            .replace('rgb', '')
                            .replace('(', '')
                            .replace(')', '')
                            .trim()
                            .split(',')
                            .map(v => parseInt(v.trim()));
                        clearInterval(interval);
                        resolve("#" + ((1 << 24) + (rgbComponents[0] << 16) + (rgbComponents[1] << 8) + rgbComponents[2]).toString(16).slice(1));
                    }
                    counter--;
                    if (counter <= 0) {
                        clearInterval(interval);
                        resolve('#ffffff');
                    }
                }, 100);
            }
        });
    }

    handleAnimationEntered = _ => {
        //Nothing
    }

    handleAnimationExit = _ => {
        //Nothing
    }


    //Swipe management
    onSwipeStart = (event) => {
        if (this.lastActivePage) {
            this.lastActivePage.classList.add(pageStackPreviousShowing);
        }
        if (event.target) {
            if (!this.props.onLeftIconClick || event.target.classList.contains('__disable-swipe') || hasParentWithMatchingSelector(event.target, ".__disable-swipe")) {
                return;
            }
        }
    }

    onSwipeMove = (position, event) => {
        if (event.target) {
            if (!this.props.onLeftIconClick || event.target.classList.contains('__disable-swipe') || hasParentWithMatchingSelector(event.target, ".__disable-swipe")) {
                return;
            }
        }
        if (position.x < SWIPE_X_TRESHOLD) {
            return;
        }

        this.lastMovePosition = position;
        const newSwipeMoveX = Math.abs(position.y) <= 50 || this.swipeMoveX ? position.x : 0;
        this.setNewSwipeMoveX(newSwipeMoveX);

        if (this.lastActivePage) {
            if (newSwipeMoveX > 0) {
                this.lastActivePage.classList.add(pageStackPreviousShowing);
            } else {
                this.lastActivePage.classList.remove(pageStackPreviousShowing);
            }
        }

        if (Math.abs(position.y) > 50 && !this.swipeMoveX) {
            this.lastMovePosition = null;
        }
        return true;
    }

    onSwipeEnd = event => {
        if (this.lastMovePosition) {
            if (this.lastMovePosition.x > 100) {
                this.ref.style.transform = "translateX(105%)";
                this.ref.style.transition = null;
                setTimeout(_ => {
                    this.setNewSwipeMoveX(0);
                    if (this.props.onLeftIconClick) this.props.onLeftIconClick(true)
                }, 300);
            } else {
                this.setNewSwipeMoveX(0);
            }
            this.lastMovePosition = null;
        }
        if (this.lastActivePage) {
            //Nascondo la pagina sottostante con un'attesa pari al tempo
            //di animazione per il riposizionamento della pagina corrente
            setTimeout(_ => this.lastActivePage.classList.remove(pageStackPreviousShowing), 300);
        }
    }

    setNewSwipeMoveX = newX => {
        if (newX > 0) {
            this.ref.style.transform = `translateX(${newX - SWIPE_X_TRESHOLD}px)`;
            this.ref.style.transition = "initial";
            this.pageContentRef.style.overflowY = 'hidden';
        } else {
            this.ref.style.transform = null;
            this.ref.style.transition = null;
            this.pageContentRef.style.overflowY = null;
        }
    }

    render() {
        return (
            <Swipe
                onSwipeStart={this.onSwipeStart}
                onSwipeMove={this.onSwipeMove}
                onSwipeEnd={this.onSwipeEnd}>
                <CSSTransition
                    in={this.props.active}
                    appear={true}
                    classNames={"page-slide"}
                    timeout={0}
                    onEntered={this.handleAnimationEntered}
                    onExit={this.handleAnimationExit}
                >
                    <PageSC
                        ref={this.getWrapperRef}
                        className={"page shadow "+ (!this.props.pageBgColor ? ' bg-main ' : '')}
                        hasFooter={!!this.props.footer}
                        floatingNavbar={!!this.props.floatingNavbar}
                        navbarBgColor={this.props.navbarBgColor}
                        navbarTextColor={this.props.navbarTextColor}
                        pageBgColor={this.props.pageBgColor}
                    >
                        <div
                            ref={this.getPageNavbarRef}
                            className={"page-navbar container-fluid p-2 " + (this.props.floatingNavbar ? 'sticky-top' : '')}
                        >
                            <div className="row">
                                <div className="col-3 text-center pr-0 __androidDismissable__"
                                     onClick={this.props.onLeftIconClick}
                                     style={{fontSize: "1em"}}>
                                    {this.props.leftIcon} <small>{this.props.leftIconText}</small>
                                </div>
                                <div className="col text-truncate text-center">
                                    <span className="page-title">{this.props.title}</span>
                                </div>
                                <div className="col-3 text-center pl-0" onClick={this.props.onRightIconClick}
                                     style={{fontSize: "1em"}}>
                                    <small>{this.props.rightIconText}</small> {this.props.rightIcon}
                                </div>

                            </div>
                        </div>
                        <div ref={this.getPageContentRef}
                             className={"page-content __touchfix__ " + (this.props.noPadding ? '' : 'pb-5')}
                             onScroll={this.props.onScroll}
                             onTouchMove={this.handleTouchMove}
                             style={this.props.pageContentStyle}
                        >
                            {this.props.children ? this.props.children : this.props.content}
                        </div>
                        {this.props.footer}
                    </PageSC>
                </CSSTransition>
            </Swipe>
        )
    }

}

Page.propTypes = {
    title: PropType.node,
    leftIcon: PropType.node,
    rightIcon: PropType.node,
    leftIconText: PropType.string,
    rightIconText: PropType.string,
    onLeftIconClick: PropType.func,
    onRightIconClick: PropType.func,
    content: PropType.node,
    active: PropType.bool,
    floatingNavbar: PropType.bool,
    navbarTextColor: PropType.string,
    navbarBgColor: PropType.string,
    pageBgColor: PropType.string,
    footer: PropType.any,
    pageContentRef: PropType.any,
    pageContentStyle: PropType.object,
};

Page.defaultProps = {
    title: '',
    rightIcon: '',
    leftIcon: '',
    rightIconText: '',
    leftIconText: '',
    onLeftIconClick: function () {
    },
    onRightIconClick: function () {
    },
    content: '',
    active: false,
    floatingNavbar: false
};


const PageNativeColorsHandler = _ => {

    let oldColor = '#ffffff';
    let currentColor = null;

    const isFacebookBrowser = _ => {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }

    return {
        setNewTopColor: color => {
            oldColor = BookizonAppManager.getNativeTopUnsafeAreaColor();
            currentColor = color;
            if (!isFacebookBrowser()) {
                BookizonAppManager.setNativeTopUnsafeAreaColor(color, 'page set');
            }
        },
        restoreOldColor: _ => {
            if (!isFacebookBrowser()) {
                BookizonAppManager.setNativeTopUnsafeAreaColor(oldColor, 'page restore')
            }
        },
        hasChangedColor: _ => !!currentColor
    }

}

