import React, {Fragment} from "react";
import PropTypes from "prop-types";
import ModalPortalHOC from "./ModalPortalHOC";
import DatePicker from "react-mobile-datepicker";
import {timepickerConfig} from "../../const/DatePickerConstants";

export class InputTimepicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            datepickerOpen: false,
            selectedDate: this.props.startDate || new Date
        }
        this.datepicker = null;
        this.inputRef = React.createRef();
    }

    handleDatePickerOpen = _ => this.setState({datepickerOpen: true});
    handleDatePickerClose = _ => this.setState({datepickerOpen: false});
    handleDateChoose = date => {
        this.handleDatePickerClose();
        this.setState({selectedDate: date});
        this.props.onChange({target: this.inputRef.current, value: date});
    }


    render() {
        return (
            <Fragment>
                <input
                    value={this.props.value}
                    className={this.props.className}
                    style={this.props.style}
                    name={this.props.name}
                    readOnly={true}
                    onClick={this.handleDatePickerOpen}
                    ref={this.inputRef}
                />
                <ModalPortalHOC>
                    <DatePicker
                        ref={this.datepicker}
                        dateConfig={timepickerConfig}
                        headerFormat={'hh:mm'}
                        showHeader={true}
                        confirmText={"Conferma"}
                        cancelText={"Annulla"}
                        value={this.state.selectedDate}
                        isOpen={this.state.datepickerOpen}
                        onSelect={this.handleDateChoose}
                        onCancel={this.handleDatePickerClose}
                    />
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

InputTimepicker.propTypes = {
    onChange: PropTypes.func,
    startDate: PropTypes.any
}