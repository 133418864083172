import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {swalConfirm, swalSelect} from "../../../../../../helpers/SweetAlertWrapper";
import DropdownMenu from "../../../../../../components/BaseComponents/DropdownMenu";
import ModalPortalHOC from "../../../../../../components/BaseComponents/ModalPortalHOC";
import {EditStartTimePanel} from "../../EditStartTimePanel";

export class ServicesListViewItemMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            editTimeOpen: false
        }
    }

    toggleMenu = _ => this.setState({menuOpen: !this.state.menuOpen});

    toggleEditTimeOpen = _ => this.setState({editTimeOpen: !this.state.editTimeOpen});

    handleEditDurata = _ => {
        const options = {};
        for (let i = 1; i <= (180 / 5); i += 1) {
            options[i] = `${i * 5} minuti`
        }
        swalSelect('Scegli la nuova durata', options, 'Avanti', 'Annulla', '', this.props.subBook.durata)
            .then(durata => {
                this.props.onEditServiceDuration(this.props.subBook.id_prenotazione, durata);
            })
    }

    handleEditTimeChoose = data => this.props.onEditTime({...data, onSuccess: this.toggleEditTimeOpen});

    handleDelete = _ => {
        swalConfirm(`Sei sicuro di voler eliminare questo elemento? Questa azione è irreversibile.`)
            .then(_ => {
                swalSelect(
                    'Vuoi anticipare tutti i servizi successivi? (Tutti i servizi successivi verranno anticipati in base alla durata del servizio eliminato)',
                    new Map([
                        [1, "Si, anticipa i servizi successivi"],
                        [0, "No, non effettuare modifiche"]
                    ]), 'Conferma', 'Annulla')
                    .then(rearrange => {
                        const priceReductionPromise = !this.props.subBook.id_servizio_bundle ? Promise.resolve(0) : swalSelect(
                            'Il servizio che vuoi eliminare fa parte di un servizio composto. Vuoi ridurre il prezzo del servizio composto solo per questa prenotazione?',
                            new Map([
                                [1, "Si, modifica il prezzo"],
                                [0, "No, non effettuare modifiche"]
                            ]), 'Conferma', 'Annulla');
                        priceReductionPromise.then(rearrange_bundle_price => {
                            this.props.onDelete(this.props.subBook.id_prenotazione, rearrange == "1", rearrange_bundle_price == "1")
                        })
                    })
            })
    }


    render() {
        const isBundle = this.props.subBook.subservices && this.props.subBook.subservices.length;
        return (
            <Fragment>
                <div className="text-muted px-2" type="button" data-toggle="dropdown" onClick={this.toggleMenu}>
                    <FaSolidIcon name={"ellipsis-v"}/>
                </div>
                {
                    this.state.menuOpen &&
                    <DropdownMenu onDismiss={this.toggleMenu}>
                        {
                            !isBundle &&
                            <React.Fragment>
                                <div className={"list-group-item text-nowrap text-muted"}
                                     onClick={this.handleEditDurata}>
                                    <FaSolidIcon name={"hourglass-half"}/> Modifica durata
                                </div>
                                <div className={"list-group-item text-nowrap text-muted"} onClick={this.toggleEditTimeOpen}>
                                    <FaSolidIcon name={"clock"}/> Modifica orario
                                </div>
                            </React.Fragment>
                        }
                        <div className={"list-group-item text-nowrap text-danger"} onClick={this.handleDelete}>
                            <FaSolidIcon name={"trash"}/> Elimina
                        </div>
                    </DropdownMenu>
                }
                <ModalPortalHOC>
                    {
                        this.state.editTimeOpen &&
                        <EditStartTimePanel
                            onDismiss={this.toggleEditTimeOpen}
                            onChoose={this.handleEditTimeChoose}
                            orario={this.props.subBook.orario}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>

        )
    }

}

ServicesListViewItemMenu.propTypes = {
    subBook: PropTypes.object.isRequired,
    onEditServiceDuration: PropTypes.func.isRequired,
    onEditTime: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}