import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import {ProductImageSize} from "../../const/ImageSizeCostants";
import EcommerceProductCardGrid from "./EcommerceProductCardGrid";
import EcommerceProductPage, {ProductPagePropsContext} from "./EcommerceProductPage";
import AppModalPortal from "../BaseComponents/AppModalPortal";
import ModalPortal from "../BaseComponents/ModalPortal";

export class EcommerceProductsCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productOpen: null
        }
    }

    handleProductOpen = productData => {
        this.setState({productOpen: productData})
    }

    handleProductDismiss = _ => this.setState({productOpen: null});

    render() {
        const Portal = document.getElementById('app-modal-root') ? AppModalPortal : ModalPortal;
        let pageTreeProps = this.context;
        return (
            <Fragment>
                <div className={"container-fluid w-100 __disable-swipe"}>
                    <div className={"row flex-row flex-nowrap"}
                         style={{scrollSnapType: "x mandatory", overflowX: "auto"}}>
                        {
                            this.props.prodotti.map((p, i) => {
                                    const compressedImage = getCompressedImageUrl(p.immagine, ProductImageSize.thumbnail.w, ProductImageSize.thumbnail.w);
                                    return (
                                        <div key={i}
                                             className={'col-5 pl-0 pr-2 mb-3'}
                                             style={{scrollSnapAlign: "center"}}>
                                            <EcommerceProductCardGrid
                                                product={p}
                                                immagine={compressedImage} //Sostituisce l'immagine della variabile "p"
                                                onProductOpen={this.handleProductOpen}
                                                showOwnerIcon={this.props.showOwnerIcon}
                                                showFavouriteIcon={this.props.showFavouriteIcon}
                                                onAddToCart={this.props.onAddToCart}
                                            />
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>

                <Portal>
                    {
                        !!this.state.productOpen && <EcommerceProductPage
                            productData={this.state.productOpen}
                            onAddToCart={this.props.onAddToCart}
                            onProductOpen={this.handleProductOpen}
                            onDismiss={this.handleProductDismiss}
                            showSameCategoryProducts={true}
                            showSameBrandProducts={true}
                            showExpectedShipping={true}
                            rightIcon={pageTreeProps.rightIcon}
                            rightIconText={pageTreeProps.rightIconText}
                            onRightIconClick={pageTreeProps.onRightIconClick}
                        />
                    }
                </Portal>
            </Fragment>
        )
    }

}

EcommerceProductsCarousel.contextType = ProductPagePropsContext;

EcommerceProductsCarousel.propTypes = {
    prodotti: PropTypes.array.isRequired,
    onAddToCart: PropTypes.func.isRequired,
    showOwnerIcon: PropTypes.bool,
    showFavouriteIcon: PropTypes.bool
}
