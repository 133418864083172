import React from "react";
import PropTypes from "prop-types";
import BannerInAppAPI from "../../api/BannerInAppAPI";
import {FuxCarousel} from "../BaseComponents/FuxCarousel/FuxCarousel";
import {BannerInAppItem} from "./BannerInAppItem";

export const BANNER_POSITION_HOMEPAGE = 'homepage';
export const BANNER_POSITION_ECOMMERCE_HOMEPAGE = 'ecommerce_homepage';
export const BANNER_POSITION_MENU_HOMEPAGE = 'menu_homepage';
export const BANNER_POSITION_HOTEL_BOOKING_INTENT = 'hotel_booking_intent';
export const BANNER_POSITION_SEAT_BOOKING_INTENT = 'seat_booking_intent';
export const BANNER_POSITION_SEAT_BOOKING_MAP_CHOOSER = 'seat_booking_map_chooser';
export const BANNER_POSITION_PRODUCT_CATEGORY = 'ecommerce_category';

export class BannerInAppContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            banners: []
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = _ => {
        let promise;
        if (this.props.idNegozio && this.props.bannerPosition === BANNER_POSITION_HOMEPAGE) {
            promise = BannerInAppAPI.forHomepage(this.props.idNegozio);
        } else if (this.props.idNegozio && this.props.bannerPosition === BANNER_POSITION_ECOMMERCE_HOMEPAGE) {
            promise = BannerInAppAPI.forEcommerceHomepage(this.props.idNegozio);
        } else if (this.props.idNegozio && this.props.bannerPosition === BANNER_POSITION_MENU_HOMEPAGE) {
            promise = BannerInAppAPI.forMenuHomepage(this.props.idNegozio);
        } else if (this.props.idNegozio && this.props.bannerPosition === BANNER_POSITION_HOTEL_BOOKING_INTENT) {
            promise = BannerInAppAPI.forHotelBookingIntent(this.props.idNegozio);
        } else if (this.props.idCategoria && this.props.bannerPosition === BANNER_POSITION_PRODUCT_CATEGORY) {
            promise = BannerInAppAPI.forProductCategory(this.props.idCategoria);
        }
        if(promise){
            promise.then(banners => {
                this.setState({
                    banners: banners
                })
            });
        }
    }

    render() {
        return (
            <div className={"mb-3"}>
                <FuxCarousel
                    className={"__disable-swipe"}
                    overlappingIndicators={true}
                    autoscroll={3000}
                    items={
                        this.state.banners
                            .map((data, i) => <BannerInAppItem bannerData={data}/>)
                    }
                />
            </div>
        )
    }

}

BannerInAppContainer.propTypes = {
    idNegozio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    idCategoria: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bannerPosition: PropTypes.string
}

BannerInAppContainer.defaultProps = {
    bannerPosition: BANNER_POSITION_HOMEPAGE
}
