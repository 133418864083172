import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../config/AppConfig";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";
import AuthAPI from "../AuthAPI";
import APIWelfareConfig from "./APIWelfareConfig";

var WalletDipendentiAPI = {

    getDipendenteInfo: function (walletApiKey){
        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/wallet/dipendente/${walletApiKey}/getDipendenteInfo`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getBalance: function (walletApiKey){
        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/wallet/dipendente/${walletApiKey}/getBalance`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getTransactions: function (walletApiKey, limit, offset){
        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/wallet/dipendente/${walletApiKey}/getTransactions/${limit}/${offset}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    salvaNotePersonali: function (walletApiKey, id_transazione, note){

        let params = {
            walletapikey: walletApiKey,
            id_transazione: id_transazione,
            note: note
        }

        return FuxHTTP.apiPostRequestWithPromise(
            APIWelfareConfig.url(`/wallet/dipendente/transazione/notePersonali/salva`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getMaxPayableInType: function (walletApiKey, type){
        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/wallet/dipendente/${walletApiKey}/get-max-payable-in-type`), {type: type},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default WalletDipendentiAPI;