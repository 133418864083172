import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import '../FoodDelivery/ribbon.css';
import {FaSolidIcon} from "../FontAwesomeIcons";
import Page from "../BaseComponents/Page/Page";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import EcommerceProductView from "./ProductView/EcommerceProductView";


export const ProductPagePropsContext = React.createContext({
    rightIcon: null,
    rightIconText: null,
    onRightIconClick: null
});

ProductPagePropsContext.displayName = 'ProductPagePropsContext';

export default class EcommerceProductPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.body.classList.add('noscroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noscroll')
    }

    getPageProps = _ => {
        return {
            rightIcon: this.props.rightIcon,
            rightIconText: this.props.rightIconText,
            onRightIconClick: this.props.onRightIconClick
        }
    }

    render() {
        return (
            <Page
                title={this.props.productData.nome_categoria || this.props.productData.nome}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                rightIcon={this.props.rightIcon}
                rightIconText={this.props.rightIconText}
                onRightIconClick={this.props.onRightIconClick}
                active={true}
            >
                <ProductPagePropsContext.Provider value={this.getPageProps()}>
                    <EcommerceProductView
                        productData={this.props.productData}
                        onOpenApp={this.props.onOpenApp}
                        onAddToCart={this.props.onAddToCart}
                        showSameCategoryProducts={this.props.showSameCategoryProducts}
                        showSameBrandProducts={this.props.showSameBrandProducts}
                        showExpectedShipping={this.props.showExpectedShipping}
                        rightIcon={this.props.rightIcon}
                        rightIconText={this.props.rightIconText}
                        onRightIconClick={this.props.onRightIconClick}
                    />
                </ProductPagePropsContext.Provider>
            </Page>
        )
    }

}


EcommerceProductPage.propTypes = {
    productData: PropTypes.object,
    onOpenApp: PropTypes.func,
    onAddToCart: PropTypes.func,
    onDismiss: PropTypes.func,
    rightIcon: PropTypes.node,
    rightIconText: PropTypes.string,
    onRightIconClick: PropTypes.func,
    showExpectedShipping: PropTypes.bool,
    showSameBrandProducts: PropTypes.bool,
    showSameCategoryProducts: PropTypes.bool
}

