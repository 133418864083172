import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";

export const _WebMenuAPI = {
    saveEmail: (id_negozio, email, formData, formHTML) => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/menu-digitale/web-menu/save-email'), {
                id_negozio: id_negozio, email: email, formData: formData, formHTML: formHTML
            }, FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE
        );
    }
}