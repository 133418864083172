import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const DashboardAPI = {
    switchAdmin: function (id_negozio) {
        return FuxHTTP.post(
            APIConfig.url(`/dashboard/switch-admin`), {id_negozio: id_negozio, token: AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
}