import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AppConfig from "../config/AppConfig";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";
import {swal} from "../helpers/SweetAlertWrapper";

var OrdiniDashboardAPI = {
    getData: function (id_ordine) {
        return FuxHTTP.apiGetRequestWithPromise(
            `${AppConfig.webServerUrl}/api/dashboard-ordini/ordine/${id_ordine}/${AuthAPI.currentUser.accessToken}`, {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    lista: function (data) {
        return FuxHTTP.apiGetRequestWithPromise(
            `${AppConfig.webServerUrl}/api/dashboard-ordini/ordini/${data}/${AuthAPI.currentUser.accessToken}`, {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    listaEcommerce: function (limit, offset) {
        return FuxHTTP.apiGetRequestWithPromise(
            `${AppConfig.webServerUrl}/api/dashboard-ordini-ecommerce/ordini/${limit}/${offset}/${AuthAPI.currentUser.accessToken}`, {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    setStatus: function (id_ordine, status, metadata) {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/api/dashboard-ordini/set-status/${id_ordine}/${status}`,
            {token: AuthAPI.currentUser.accessToken, metadata: metadata},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    setProductStatus: function (id_ordine, id_prodotto, aggiunte_hash, status) {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/api/dashboard-ordini-ecommerce/ordini/set-product-status`,
            {
                token: AuthAPI.currentUser.accessToken,
                stato_prodotto: status,
                id_ordine: id_ordine,
                id_prodotto:
                id_prodotto,
                aggiunte_hash: aggiunte_hash
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    setTime: function (id_ordine, time) {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/api/dashboard-ordini/set-time/${id_ordine}/${time}`, {token: AuthAPI.currentUser.accessToken},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    getNextOrdersNumber: function () {
        return FuxHTTP.apiGetRequestWithPromise(
            `${AppConfig.webServerUrl}/api/dashboard-ordini/get-number/${AuthAPI.currentUser.accessToken}`, {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    }
};

export default OrdiniDashboardAPI;
