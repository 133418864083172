import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {DISABLED_WALLET_NOT_AVAILABLE_IN_WEEKDAY, DISABLED_WALLET_ORDER_NOT_COMPLETE} from "./Constants";


export default class SmartPackageListView extends React.Component {
    constructor(props) {
        super(props);
    }

    getPackageName = _ => {
        if(this.props.pack.name) return this.props.pack.name.toUpperCase()
        return this.props.pack.type.replace("wallet", "pacchetto").replaceAll("_", " ").toUpperCase()
    }

    getDisabledLabelText = _ => {
        switch (this.props.pack.disabled_motivation) {
            case DISABLED_WALLET_ORDER_NOT_COMPLETE:
                return <small>Per utilizzare questo pacchetto è necessario che il pagamento ad esso associato sia confermato dall'attività</small>
            case DISABLED_WALLET_NOT_AVAILABLE_IN_WEEKDAY:
                return <small>Questo pacchetto non può essere utilizzato per la giornata scelta</small>
        }
        return ""
    }

    render() {

        const pack = this.props.pack
        return (
            <div className={"list-group-item border-0 pb-2"}>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <div>
                        <small className={"text-muted"}>Utilizza</small>
                        <h6 className={"font-weigh-bold mr-2 my-0 "+(pack.disabled < 1 ? "text-primary" : "text-muted")}>
                            {this.getPackageName(pack)}
                        </h6>

                        {pack.disabled ? this.getDisabledLabelText() : ""}
                    </div>
                </div>

                {
                    pack.disabled < 1 &&
                    <div className={"mt-2 mb-0 py-0"}>
                        <small className={"text-muted"}>Scegli dove applicarlo:</small>
                        {
                            pack.allowed_items.map((allowedItem, i_item) => {
                                //recupero i crediti iniziali utilizzabili
                                const initialAvailableCredits = allowedItem.payable_reason.reduce((partialSum, reason) => partialSum + parseInt(reason.max_payments_num), 0)

                                //Recupero l'elemento se è già all'interno della lista dei selezionati
                                let itemAlreadySelected = this.props.selectedItems.find((selectedItem) => selectedItem.id_item === allowedItem.id_item)

                                //Se l'elemento è in lista ma non è selezionato dal pack corrente allora è disabilitato
                                if (itemAlreadySelected && itemAlreadySelected.id_wallet !== pack.id_wallet) {
                                    return (
                                        <div className={"ml-2 mb-2"} key={i_item}>
                                            <span
                                                className="text-muted font-weight-bold ml-1 mb-0">{allowedItem.nome}</span>
                                            <div className={"small text-muted ml-1 mt-0"}>Selezionato in altri pacchetti
                                            </div>
                                        </div>
                                    )
                                }

                                let isChecked = !!(itemAlreadySelected && itemAlreadySelected.id_wallet === pack.id_wallet)

                                return (
                                    <div className={"ml-2 mb-2"} key={i_item}>
                                        <input type={"checkbox"}
                                               id={"item_" + i_item + "_" + pack.id_wallet}
                                               checked={isChecked}
                                               name={"item_" + i_item + "_" + pack.id_wallet}
                                               onChange={_ => this.props.onItemSelectionChange(allowedItem, pack, isChecked)}
                                        />
                                        <label className="font-weight-bold ml-1 mb-0"
                                               htmlFor={"item_" + i_item + "_" + pack.id_wallet}>
                                            {allowedItem.nome}
                                        </label>
                                        <div className={"small text-muted"}>
                                            Hai {this.props.getInitialCreditsForItem(allowedItem.id_item, pack)} crediti e
                                            ti rimarranno {this.props.getCreditsAvailableForItem(allowedItem.id_item, pack)} crediti
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        );
    }
}

SmartPackageListView.propTypes = {
    pack: PropTypes.object,
    module: PropTypes.string,
    onItemSelectionChange: PropTypes.func,
    selectedItems: PropTypes.array,
    getCreditsAvailableForItem: PropTypes.func,
    getInitialCreditsForItem: PropTypes.func,
}