import React from "react";
import Swipe from "react-easy-swipe";
import {hasParentWithMatchingSelector} from "../../../helpers/DomUtility";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CSSTransition} from "react-transition-group";

export class SPTopToBottom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false
        }
        this.ref = null;
        this.swipeMovement = 0;
        this.lastMovePosition = null;
        this.hasDisableSwipeForTouchSession = false; //Mi dice se al momento del touch start, lo swipe era attivo. Viene resettato al touchend.
        this.PanelSC = getPanelSC({...this.props});
    }

    componentDidMount() {
        /**
         * Se il componente viene montato già attivo allora devo fare in modo di forzare il re-rendering del componente
         * in modo da causare l'aggiunta della classe active e far partire la transition. Per fare questo al momento del
         * mount ritardo di 1ms l'update dello state così da far effettuare il primo rendering con active = false e poi
         * impostarlo a true.
        */
        if (this.props.active) {
            setTimeout(_ => this.setState({active: true}), 1);
        }
    }

    /**
     * Per permettere l'uso dell'animazione anche con props.active = true al momento del mount del componente allora
     * devo gestire la props active come una comunicazione dall'esterno di aggiornare lo state.active.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.active !== this.props.active) {
            this.setState({
                active: this.props.active
            })
        }
    }

    getRef = node => {
        this.ref = node;
    }

    //Swipe management
    onSwipeStart = (event) => {
        this.hasDisableSwipeForTouchSession = this.props.disableSwipe();
        if (this.hasDisableSwipeForTouchSession) {
            return;
        }
        if (event.target) {
            if (event.target.classList.contains('__disable-swipe') || hasParentWithMatchingSelector(event.target, ".__disable-swipe")) {
                return;
            }
        }
        this.props.onSwipeStart(event);
    }

    onSwipeMove = (position, event) => {
        if (this.hasDisableSwipeForTouchSession) {
            return;
        }
        if (event.target) {
            if (event.target.classList.contains('__disable-swipe') || hasParentWithMatchingSelector(event.target, ".__disable-swipe")) {
                return;
            }
        }

        //Non muovo il pannello se sono al di sotto della soglia di movimento
        if (position[this.props.animationAxis] < this.props.swipeTreshold && this.lastMovePosition === null) {
            return;
        }

        this.lastMovePosition = position[this.props.animationAxis];
        const newSwipeMove = Math.max(Math.abs(position[this.props.animationDisabledAxis]) <= 50 || this.swipeMovement ? position[this.props.animationAxis] : 0, 0);
        this.setNewSwipeMove(newSwipeMove);
        this.props.onSwipeMove(newSwipeMove);

        if (Math.abs(position[this.props.animationDisabledAxis]) > 50 && !this.swipeMovement) {
            this.lastMovePosition = null;
        }
        return true;
    }

    onSwipeEnd = event => {
        if (this.lastMovePosition) {
            if (this.lastMovePosition > this.props.dismissTreshold) {
                this.ref.classList.remove('active');
                setTimeout(_ => this.props.onDismiss(true), this.props.animationSpeed);
                this.setNewSwipeMove(0);
            } else {
                this.setNewSwipeMove(0);
            }
            this.lastMovePosition = null;
        }
        this.hasDisableSwipeForTouchSession = null;
        this.props.onSwipeEnd(event);
    }

    setNewSwipeMove = newPosition => {
        if (newPosition > 0) {
            this.ref.style.transform = `${this.props.translationType}(${newPosition - this.props.swipeTreshold}px)`;
            this.ref.style.transition = "initial";
        } else {
            this.ref.style.transform = null;
            this.ref.style.transition = null;
        }
    }

    render() {
        const PanelSC = this.PanelSC;
        return (
            <Swipe
                onSwipeStart={this.onSwipeStart}
                onSwipeMove={this.onSwipeMove}
                onSwipeEnd={this.onSwipeEnd}>
                <PanelSC ref={this.getRef} className={this.state.active ? 'active' : ''}>
                    {this.props.children}
                </PanelSC>
            </Swipe>
        )
    }
}

SPTopToBottom.propTypes = {
    active: PropTypes.bool,
    direction: PropTypes.string,
    disableSwipe: PropTypes.func,
    onSwipeStart: PropTypes.func,
    onSwipeMove: PropTypes.func,
    onSwipeEnd: PropTypes.func,
    swipeTreshold: PropTypes.number,
    onDismiss: PropTypes.func,
    dismissTreshold: PropTypes.number,
    onExitEnd: PropTypes.func,
    animationSpeed: PropTypes.number,
    translationType: PropTypes.string,
    animationAxis: PropTypes.string,
    animationDisabledAxis: PropTypes.string
};

const getPanelSC = ({translationType,animationSpeed}) => {
    return styled.div`
          position:fixed;
          left:0;
          right:0;
          bottom:0;
          transform: ${translationType}(100%);
          transition: transform ${animationSpeed}ms linear;
          will-change: transform;
          z-index: 3000;
          
          &.active{
              transform: ${translationType}(0);
          }
    `;
}
