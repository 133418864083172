import React, {Fragment} from "react";
import {SwipeablePanelGripBar} from "../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {SwipeablePanel} from "../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import SearchUserView from "./SearchUserView";
import PropTypes from "prop-types";
import CreateCRMUserView from "./CreateCRMUserView";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

const ContainerStyle = {
    minHeight: "70vh",
    maxHeight: "70vh",
    overflow: 'auto'
}


export default class SearchUserPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            createMode: false,
            createModeDefaultName: ''
        }
        this.containerRef = null;
    }

    handleShowCreateMode = defaultName => this.setState({createMode: true, createModeDefaultName: defaultName});
    handleHideCreateMode = _ => this.setState({createMode: false, createModeDefaultName: ''});
    getContainerRef = node => this.containerRef = node;

    render() {
        return (
            <Fragment>
                <div onClick={this.props.onDismiss} style={BackdropStyle} className={"__androidDismissable__"}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={_ => !this.containerRef || this.containerRef.scrollTop > 0}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div ref={this.getContainerRef} className={"container pt-3 bg-main-z2 pb-5"} style={ContainerStyle}>
                        {
                            this.state.createMode ?
                                <CreateCRMUserView
                                    onUserCreated={this.props.onResultClick}
                                    defaultName={this.state.createModeDefaultName}
                                    onDismiss={this.handleHideCreateMode}
                                />
                                :
                                <SearchUserView
                                    onResultClick={this.props.onResultClick}
                                    onShowCreateMode={this.handleShowCreateMode}
                                />
                        }
                    </div>
                </SwipeablePanel>
            </Fragment>
        )
    }
}


SearchUserPanel.propTypes = {
    onResultClick: PropTypes.func,
    onDismiss: PropTypes.func
}

