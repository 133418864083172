import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../BaseComponents/Page/Page";
import {FaSolidIcon} from "../FontAwesomeIcons";
import CouponAPI from "../../api/CouponAPI";
import {COUPON_TYPE_GRUPPI, COUPON_TYPE_SERVIZI} from "../../const/CouponConstants";
import {PluralString} from "../../helpers/UIUtility";
import {PageFooter, PageWithFooterContainer} from "../BaseComponents/Page/PageFooter";
import moment from "moment";
import CheckoutDiscount from "../CheckoutDiscount/CheckoutDiscount";
import {swal} from "../../helpers/SweetAlertWrapper";

export class ResumePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            noteUtente: '',
            shippingNotes: '',
            dati_coupon: null,
            dati_wallets: null,
            dati_packages: null,
            repeat: 1,
            payFullAmount: false
        }
    }

    handleInputChange = ({target}) => this.setState({[target.name]: target.value});

    handleChangePayFullAmount = _ => this.setState({payFullAmount: !this.state.payFullAmount})

    handleConfirm = _ => {
        if (this.state.dati_coupon instanceof Error) {
            swal.fire({
                icon: 'error',
                title: "Errore nel coupon!",
                text: this.state.dati_coupon.message
            });
            return false;
        }
        if (this.state.dati_wallets instanceof Error) {
            swal.fire({
                icon: 'error',
                title: "Errore nei wallet!",
                text: this.state.dati_wallets.message
            });
            return false;
        }
        this.props.onConfirm({
            noteUtente: this.state.noteUtente,
            shippingNotes: this.state.shippingNotes,
            coupon: this.state.dati_coupon ? this.state.dati_coupon.codice : null,
            wallets: this.state.dati_wallets ? this.state.dati_wallets.wallets_list : null,
            packages: this.state.dati_packages ? this.state.dati_packages.selected_items : null,
            payFullAmount: this.state.payFullAmount,
            repeat: this.state.repeat
        });
    }

    /** @MARK: Advanced payment import*/

        //Recupera la percentuale di acconto da restituire, se l'utente decide di pagare tutto l'importo sarà al 100%
    getAdvancePaymentDataPercentage = _ => {
        //Se ha scelto di pagare l'intero importo allora posso già ritornare 1
        if (this.props.enablePaymentOfFullAmount && this.state.payFullAmount) return 1

        //Faccio il calcolo della percentuale se esiste un advance payment
        if (this.props.advancePaymentData && this.props.advancePaymentData.perc > 0) {
            return (this.props.advancePaymentData.perc / 100).toFixed(2)
        }
        return 0
    }

    //recupera l'acconto da dare dato un determinato importo
    getImportoAdvancePayment = totalToPay => {
        if (this.props.advancePaymentData && this.props.advancePaymentData.min > totalToPay) return 0

        return totalToPay * this.getAdvancePaymentDataPercentage()
    }


    /** @MARK: Coupon management */

        //Gestisce il coupon che viene confermato
    couponValidityHandler = resultData => {
        this.setState({
            dati_coupon: resultData.coupon,
            dati_wallets: resultData.wallets,
            dati_packages: resultData.packages
        })
    }

    getTotaleServizi = _ => {
        return this.props.listaServizi.reduce((acc, s) => {
            if (this.props.bookInfo.id_servizio.find(id_servizio => id_servizio === s.id_servizio)) {
                const prezzoBase = parseFloat(s.prezzo);
                const prezzoScontato = prezzoBase - (prezzoBase * parseFloat(s.perc_sconto) / 100);
                return acc + prezzoScontato;
            }
            return acc;
        }, 0.0);
    }

    getImportoSconto = _ => {
        if (!this.state.dati_coupon || !this.state.dati_coupon.id_coupon) return 0;
        const valoreSconto = parseFloat(this.state.dati_coupon.valore_sconto);
        if (this.state.dati_coupon.tipo_sconto === 'euro') return valoreSconto;
        const totaleServizi = this.getTotaleServizi();
        return totaleServizi * valoreSconto / 100;
    }

    getWalletsItems = _ => {
        return this.props.bookInfo.id_servizio.map(id_servizio => {
            const servizio = this.props.listaServizi.find(s => s.id_servizio === id_servizio);

            const prezzoBase = parseFloat(servizio.prezzo);
            const percSconto = parseFloat(servizio.perc_sconto)
            const prezzoScontato = prezzoBase - (prezzoBase * percSconto / 100);

            return {
                id_item: id_servizio,
                prezzo: prezzoScontato,
                totale: prezzoScontato * this.getAdvancePaymentDataPercentage(),
                nome: servizio.nome,
                bundle: servizio.bundle,
            }
        })
    }


    render() {
        const bookInfo = this.props.bookInfo;
        const listaServizi = this.props.listaServizi
        const dateMoment = moment(bookInfo.data, 'DD-MM-YYYY');

        const importoSconto = this.getImportoSconto();
        const totaleServizi = this.getTotaleServizi();
        const totalePacchetti = this.state.dati_packages ? parseFloat(this.state.dati_packages.importo_usato_totale) : 0
        const totaleWallet = this.state.dati_wallets ? parseFloat(this.state.dati_wallets.importo_usato_totale) : 0
        const totaleScontato = Math.max(0, totaleServizi - importoSconto - totalePacchetti);

        const acconto = this.getImportoAdvancePayment(totaleScontato)

        const paymentReferenceCreated = !!this.props.paymentData.gateway_payment_link; //In questo modo posso sapere se con i dati nel resume è già stata creata una prenotazione
        return (
            <Fragment>
                <Page
                    title={"Riepilogo"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}

                    active={true}
                    footer={
                        <PageFooter className={"bg-main-z2 p-3 border-top"}>
                            <button className={"btn btn-lg btn-block btn-primary text-on-bg-primary"}
                                    onClick={this.handleConfirm}>
                                <FaSolidIcon
                                    name={"check"}/> {paymentReferenceCreated ? 'Completa pagamento' : 'Conferma & prenota'}
                            </button>
                        </PageFooter>
                    }
                >
                    <PageWithFooterContainer>
                        <div className={"container mt-3"}>
                            <h4 className={"font-weight-bold text-on-bg-main"}>
                                Riepilogo prenotazione
                            </h4>

                            <div className={"form-group my-4"}>
                                <div className={"d-flex align-items-start justify-content-center"}>
                                    <div
                                        className={"d-flex flex-column justify-content-center align-items-center mx-3"}>
                                        <span className={"text-muted text-uppercase"}>
                                            {dateMoment.format('ddd')}
                                        </span>
                                        <div className={"bg-primary text-on-bg-primary shadow-sm rounded px-3 py-2"}>
                                            <b>{dateMoment.format('DD')}</b>
                                        </div>
                                        <span className={"text-muted text-uppercase"}>{dateMoment.format('MMM')}</span>
                                    </div>
                                    <div
                                        className={"d-flex flex-column justify-content-center align-items-center mx-3"}>
                                        <span className={"text-muted"}>
                                            Alle ore
                                        </span>
                                        <div className={"bg-primary text-on-bg-primary shadow-sm rounded px-3 py-2"}>
                                            <b>{bookInfo.orario}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Resume */}
                            <div className={"form-group"}>
                                <b className={"text-on-bg-main"}>Servizi scelti</b>
                                {
                                    bookInfo.id_servizio.map(id_servizio => {
                                        const servizio = listaServizi.find(s => s.id_servizio === id_servizio);
                                        return <ResumePageServizioItem
                                            key={id_servizio}
                                            servizio={servizio}
                                            showPrice={this.props.showPrices}
                                        />
                                    })
                                }
                            </div>
                        </div>

                        {/* Notes */}
                        {
                            this.props.showNotes &&
                            <Fragment>
                                <div className={"form-group"}>
                                    <div className={"container"}>
                                        <b className={"text-on-bg-main"}>Note aggiuntive</b>
                                        <div className={"card card-body p-0 shadow-sm border-0"}>
                                            <textarea
                                                name={"noteUtente"}
                                                value={this.state.noteUtente}
                                                className={"form-control border-0"}
                                                onChange={this.handleInputChange}
                                                placeholder={this.props.notesPlaceholder}
                                                disabled={!!paymentReferenceCreated}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }

                        {/* Shipping notes */}
                        {
                            this.props.showShippingNotes &&
                            <Fragment>
                                <div className={"form-group"}>
                                    <b className={"text-on-bg-main"}>Consegna</b>
                                    <textarea
                                        name={"shippingNotes"}
                                        value={this.state.shippingNotes}
                                        className={"form-control"}
                                        onChange={this.handleInputChange}
                                        placeholder={this.props.shippingNotesPlaceholder}
                                        disabled={!!paymentReferenceCreated}
                                    />
                                </div>
                            </Fragment>
                        }

                        <div className={"container"}>
                            {
                                this.props.enablePaymentOfFullAmount && totaleScontato.toFixed(2) > 0 &&
                                <div className={"form-group"}>
                                    <div className={"card shadow-sm border-0 bg-main-z3 text-on-bg-main"}>
                                        <div className={"card-body"}>
                                            <input type={"checkbox"} id={"payFullAmountSwitch"}
                                                   checked={this.state.payFullAmount}
                                                   name={"payFullAmount"}
                                                   onChange={this.handleChangePayFullAmount}
                                            />
                                            <label className="font-weight-bold ml-2" htmlFor="payFullAmountSwitch">
                                                Paga tutto l'importo ORA
                                            </label>
                                            <br/>
                                            <small className={"text-muted"}>
                                                Selezionandolo non dovrai pagare presso l'attività, ma pagherai adesso
                                                tutto l'importo
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        {/* Coupon */}
                        {
                            this.props.showCouponField &&
                            <CheckoutDiscount
                                type={COUPON_TYPE_SERVIZI}
                                discountValidityHandler={this.couponValidityHandler}
                                discountableAmount={totaleServizi}
                                amountPayableWithWallet={this.state.payFullAmount ? totaleServizi : acconto}
                                externalCode={this.props.externalCoupon || ''}
                                disableCoupon={!!paymentReferenceCreated}
                                usageDate={dateMoment.format('YYYY-MM-DD')}
                                operationDate={dateMoment.format('YYYY-MM-DD')}
                                items={this.getWalletsItems()}
                            />
                        }

                        <div className={"container"}>
                            {
                                this.props.showPrices &&
                                <div className={"form-group"}>
                                    <div className={"card shadow-sm border-0 bg-main-z3 text-on-bg-main"}>
                                        <div className={"card-body"}>
                                            {
                                                totaleServizi !== totaleScontato &&
                                                <Fragment>
                                                    <div
                                                        className={"d-flex align-items-center justify-content-between"}>
                                                        <span>Sub totale</span>
                                                        <span>€{totaleServizi.toFixed(2)}</span>
                                                    </div>

                                                    {/* Mostro lo sconto con il package se possibile */}
                                                    {
                                                        totalePacchetti > 0 &&
                                                        <div
                                                            className={"d-flex align-items-center justify-content-between text-success"}>
                                                            <span>Dai pacchetti</span>
                                                            <span>- €{totalePacchetti.toFixed(2)}</span>
                                                        </div>
                                                    }

                                                    {/* Mostro lo sconto */}
                                                    {
                                                        importoSconto > 0 &&
                                                        <div
                                                            className={"d-flex align-items-center justify-content-between text-success"}>
                                                            <span>Sconto</span>
                                                            <span>- €{importoSconto.toFixed(2)}</span>
                                                        </div>
                                                    }

                                                    <hr/>
                                                </Fragment>
                                            }

                                            {/* Mostro lo sconto con il wallet se possibile */}
                                            {
                                                totaleWallet > 0 &&
                                                <div
                                                    className={"d-flex align-items-center justify-content-between text-success"}>
                                                    <span>Dai wallet</span>
                                                    <span>-€{parseFloat(this.state.dati_wallets.importo_usato_totale).toFixed(2)}</span>
                                                </div>
                                            }

                                            <div className={"d-flex align-items-center justify-content-between"}>
                                                <b>Totale {(totalePacchetti > 0 || totaleWallet > 0) ? "da pagare" : ""}</b>
                                                <b>€{totaleScontato.toFixed(2)}</b>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.props.advancePaymentData && acconto > 0 && !this.state.payFullAmount &&
                                        <div
                                            className={"card card-body shadow-sm border-0 bg-main-z3 text-on-bg-main mt-2"}>
                                            <span>Per questa prenotazione dovrai lasciare un acconto del {this.props.advancePaymentData.perc}%</span>
                                            <div className={"d-flex align-items-center justify-content-between"}>
                                                <b>Acconto:</b>
                                                <b>€{acconto.toFixed(2)}</b>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    </PageWithFooterContainer>
                </Page>
            </Fragment>
        )
    }

}

ResumePage.propTypes = {
    onDismiss: PropTypes.func,
    onConfirm: PropTypes.func,
    bookInfo: PropTypes.object,
    listaServizi: PropTypes.array,
    showNotes: PropTypes.bool,
    notesPlaceholder: PropTypes.string,
    showShippingNotes: PropTypes.bool,
    shippingNotesPlaceholder: PropTypes.string,
    showCouponField: PropTypes.bool,
    maxRepeat: PropTypes.number,
    paymentData: PropTypes.shape({
        gateway_payment_id: PropTypes.string,
        gateway_payment_link: PropTypes.string,
        gateway_metadata: PropTypes.any
    }),
    advancePaymentData: PropTypes.shape({
        min: PropTypes.number,
        perc: PropTypes.number
    }), // ca be null
    enablePaymentOfFullAmount: PropTypes.bool
}


function ResumePageServizioItem(props) {
    const servizio = props.servizio;
    const prezzoBase = parseFloat(servizio.prezzo);
    const percSconto = parseFloat(servizio.perc_sconto)
    const prezzoScontato = prezzoBase - (prezzoBase * percSconto / 100);

    return (
        <div className={"card shadow-sm border-0 bg-main-z3 mb-2"}>
            <div className={"card-body"}>
                <b className={"text-on-bg-main"}>{servizio.nome}</b>
                <div>
                    {
                        props.showPrice &&
                        <Fragment>
                            <span className={"badge badge-primary mr-1 text-on-bg-primary"}>
                                 {`€${prezzoScontato.toFixed(2)}`}
                            </span>
                            {
                                !!percSconto &&
                                <Fragment>
                                    <small className={"mr-1 text-muted text-line-through"}>
                                        {`€${prezzoBase.toFixed(2)}`}
                                    </small>
                                    <span className={"badge badge-success mr-1"}>
                                        Sconto {percSconto}%
                                    </span>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}
