import React, {Fragment} from "react";
import styled, {keyframes} from "styled-components";
import {zoomInUp} from "react-animations";

const showAnimation = keyframes`${zoomInUp}`;

const MenuBackdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 300vh;
  top: 0;
  left: 0;
  z-index: 1031;
  background: white;
  opacity: 0;
`;
const MenuWrapper = styled.div`
  width: auto;
  min-width: 150px;
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1032;
  overflow: hidden;
  animation: .25s ${showAnimation};
  animation-fill-mode: forwards;
  text-align: left;
`

export default class DropdownMenu extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = null;
    }

    componentDidMount() {
        setTimeout(_ => document.addEventListener('click', this.handleClickOutside), 100);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleClickOutside = e => {
        console.log(e.target, this.wrapperRef);
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            console.log('CLICK OUTSIDE MENU');
            this.props.onDismiss()
        }
    }

    setWrapperRef = node => {
        this.wrapperRef = node;
    }

    render() {

        return (
            <Fragment>
                <MenuBackdrop className={"__androidDismissable__"}/>
                <MenuWrapper ref={this.setWrapperRef} className={"bg-white shadow rounded"}>
                    <div className={"list-group list-group-flush"}>
                        {this.props.children}
                    </div>
                </MenuWrapper>
            </Fragment>
        )

    }

}
