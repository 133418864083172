import React from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import styled from "styled-components";
import AppConfig from "../../config/AppConfig";
import NotificheAPI from "../../api/NotificheAPI";
import moment from "moment";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import {AppIconThumbnail} from "../../components/BaseComponents/AppIconThumbnail";
import {replaceLinksWithAnchors} from "../../helpers/StringHelpers";

const NotificheWrapper = styled.div`
    position: relative;
    z-index: 1030;
    padding-bottom: 4rem;
`;

const ListGroup = styled.div.attrs(props => ({
    className: 'list-group list-group-flush shadow'
}))`
border-radius:.5rem;
overflow:hidden;
`;

export default class NotificationTab extends React.Component {


    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.active !== this.props.active ||
            nextProps.notifications !== this.props.notifications;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.active && this.props.active) {
            NotificheAPI.setAllRead().then(this.props.onReadedSuccess);
        }
    }

    render() {
        return (
            <NotificheWrapper className={this.props.active ? '' : 'd-none'}>
                <div className={"container pt-2"}>
                    <h4 className={"font-weight-bold text-primary"}>Centro notifiche</h4>
                    {
                        this.props.notifications === null ?
                            <div className={"text-center"}>
                                <FaSolidIcon name={"spin fa-spinner"}/>
                            </div>
                            :
                            <ListGroup>
                                {
                                    this.props.notifications.map(n => {
                                        var imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${n.id_negozio}/logoIcon/100`;
                                        var timestamp = moment(n.data_creazione).calendar(null, {
                                            sameDay: '[Oggi alle] HH:mm',
                                            nextDay: '[Domani alle] HH:mm',
                                            nextWeek: 'dddd DD [alle] HH:mm',
                                            lastDay: '[Ieri alle] HH:mm',
                                            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
                                            sameElse: 'dddd D MMM [alle] HH:mm'
                                        });
                                        return (
                                            <NotificationListViewItem
                                                key={n.id_notifica}
                                                {...n}
                                                icon={imageFullUrl}
                                                timestamp={timestamp}
                                                onAppOpenRequest={this.props.onAppOpenRequest}
                                            />
                                        );
                                    })
                                }
                            </ListGroup>
                    }
                </div>
            </NotificheWrapper>

        );
    }

}


const NotificationUnreadMarker = styled.div`
    width:12px;
    height:12px;
    background-color:#293069;
    display:inline-block;
    border-radius:50%;
`;


export class NotificationListViewItem extends React.Component {

    constructor(props) {
        super(props);
    }

    setOnClickNotification =_=>{
        if(!this.props.metadata.deepLink){
            return "";
        }
        return window.addAppId('@'+this.props.metadata.deepLink.split('@')[1]);
    }

    handleOnClickAppName =event=>{
        if (this.props.onAppOpenRequest) this.props.onAppOpenRequest(this.props.id_negozio)
        event.stopPropagation()
    }

    render() {
        return (
            <div className={"list-group-item"} onClick={this.setOnClickNotification}>
                <div className={"d-flex align-items-center"}
                     onClick={(e) => {this.handleOnClickAppName(e)}}>
                    {this.props.letto === "1" ? '' : <NotificationUnreadMarker/>}
                    <AppIconThumbnail
                        size={'25px'}
                        src={this.props.icon}
                        className={"mx-1"}
                    />
                    {this.props.nome_negozio}
                </div>
                <div>
                    <strong>{this.props.titolo}</strong><br/>{replaceLinksWithAnchors(this.props.testo.replace(/(\r\n|\r|\n)/g, '<br>'))}
                </div>
                <small className={"text-muted"}>{this.props.timestamp}</small>
            </div>
        );
    }

}
