import React from "react";


export default class LoginButtonGroup extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div>
                <a className={"btn btn-primary text-white btn-block w-50 mx-auto my-2"}
                   onClick={this.props.onLoginClick}>Accedi ora</a>
                <span>oppure</span>

            </div>
        )
    }

}
