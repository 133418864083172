import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";


var ReorderFoodAPI = {
    getProductForCart: function(id_ordine){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/riordina/product-for-cart/${id_ordine}/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    }
};

export default ReorderFoodAPI;