import React, {Fragment} from "react";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import "../../dashboard.css";
import {
    swal,
    swalConfirm,
    swalError,
    swalLoading,
    swalPopOver,
    swalSuccess
} from "../../../../helpers/SweetAlertWrapper";
import moment from "moment";
import "./CalendarioGruppi.css";
import {ModuloGruppiAPI} from "../../../../api/ModuloGruppi/ModuloGruppiAPI";
import HorizontalDatePicker from "../../../../components/HorizontalDatePicker/HorizontalDatePicker";
import DashboardDateBooksUnavailableTimePanel from "./DashboardDateBooksUnavailableTimePanel";
import PropTypes from "prop-types";

export default class DashboardDateBooksUnavailable extends React.Component {

    calendarRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dateBooksUnavailable: [],
            fetchingDateBooksUnavailable: false,
            insUnavailableTimePanelOpen: false,
            unavailableAllDay: false
        }

    }

    componentDidMount() {
        this.fetchDateBooksUnavailable()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.IdgruppoSelected !== prevProps.IdgruppoSelected || this.props.selectedDate !== prevProps.selectedDate) {
            this.fetchDateBooksUnavailable()
        }
    }

    fetchDateBooksUnavailable = _ => {
        this.setState({fetchingDateBooksUnavailable: true});
        ModuloGruppiAPI.Dashboard.getDateBooksUnavailable(
            moment(this.props.selectedDate).format('YYYY-MM-DD'),
            moment(this.props.selectedDate).format('YYYY-MM-DD'),
            this.props.IdgruppoSelected)
            .then(dateUnavailable => {
                var unavailableAllDay = false
                for (var i = 0; i < dateUnavailable.length; i++) {
                    console.log(dateUnavailable[i].orario_inizio_indisponibilita)
                    if (dateUnavailable[i].orario_inizio_indisponibilita === "00:00" &&
                        dateUnavailable[i].orario_fine_indisponibilita === "23:59") {
                        unavailableAllDay = true
                    }
                }
                this.setState({
                    dateBooksUnavailable: dateUnavailable,
                    fetchingDateBooksUnavailable: false,
                    unavailableAllDay: unavailableAllDay
                });
            });
    }

    toggleInsUnavailableTimePanel = _ => {
        this.setState({insUnavailableTimePanelOpen: !this.state.insUnavailableTimePanelOpen})
    }


    /**
     *
     * @param dataSelected
     * @param time array[0] => ora in cui ha inizio, array[1] => ora in cui termina
     */
    handleSetGroupUnavailable = (dataSelected, time = null) => {
        console.log(time)
        swalConfirm("Il servizio non sarà più prenotabile in questo giorno, sei sicuro di voler procedere? " +
            "Potrai sempre modificare questa scelta dalla tua dashboard")
            .then(_ => {
                swalLoading();
                //Data selected vale sia come data inizio che come data fine in questo caso
                ModuloGruppiAPI.Dashboard.setGroupUnavailable(
                    this.props.IdgruppoSelected,
                    dataSelected,
                    dataSelected,
                    (time) ? time[0] : null,
                    (time) ? time[1] : null)
                    .then(message => {
                        swalSuccess(message);
                        if (this.state.insUnavailableTimePanelOpen) {
                            this.toggleInsUnavailableTimePanel()
                        }
                        this.fetchDateBooksUnavailable()
                    })
                    .catch(swalError);
            });
    }


    render() {
        return (
            !this.state.fetchingDateBooksUnavailable &&
            <div>
                {
                    this.state.unavailableAllDay ?
                        <div className={"container mt-2 text-warning"}>
                            <span><FaSolidIcon name={"user-slash"}/> Non prenotabile da utenti in questo giorno!</span>
                        </div>
                        :
                        <div className={"container mt-2"}>
                            <div>
                                <button className={"btn btn-sm btn-link p-0"}
                                        onClick={_ => this.handleSetGroupUnavailable(moment(this.props.selectedDate).format('YYYY-MM-DD'))}>
                                    <FaSolidIcon name={"exclamation-triangle"}/> Disabilita per tutto il giorno
                                </button>
                            </div>
                            <div>
                                <button className={"btn btn-sm btn-link p-0 mt-2"}
                                        onClick={_ => this.toggleInsUnavailableTimePanel()}>
                                    <FaSolidIcon name={"clock"}/> Disabilita in alcuni orari
                                </button>
                            </div>
                            {
                                this.state.dateBooksUnavailable.map((d, i) => {
                                    return (
                                        <div key={i} className={"text-warning"}>
                                            <small><FaSolidIcon name={"user-slash"}/> Non prenotabile
                                                dalle {d.orario_inizio_indisponibilita} alle {d.orario_fine_indisponibilita}
                                            </small>
                                        </div>)
                                })
                            }
                        </div>
                }

                {
                    this.state.insUnavailableTimePanelOpen &&
                    <DashboardDateBooksUnavailableTimePanel
                        onDismiss={this.toggleInsUnavailableTimePanel}
                        selectedDate={this.props.selectedDate}
                        saveData={this.handleSetGroupUnavailable}
                    />
                }
            </div>


        );
    }
}

DashboardDateBooksUnavailable.propTypes = {
    IdgruppoSelected: PropTypes.string,
    selectedDate: PropTypes.any,
    dateIntervalStart: PropTypes.string,
    dateIntervalEnd: PropTypes.string,
}