import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../config/AppConfig";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";
import AuthAPI from "../AuthAPI";
import APIWelfareConfig from "./APIWelfareConfig";
import APIConfig from "../APIConfig";

var PagamentiDipendenteAPI = {


    generateAuthCode: function(walletApiKey, importo, descrizione_dipendente){

        let params = {
            walletapikey: walletApiKey,
            importo: importo,
            descrizione_dipendente: descrizione_dipendente
        };

        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/welfare/pagamenti/generate-auth-code`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },


    getPaymentData: function (walletApiKey, authCode){

        let params = {
            walletapikey: walletApiKey,
            authCode: authCode
        };

        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/welfare/pagamenti/getPaymentData`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );

    },


    autorizzaTransazione: function(walletApiKey, authCode, descrizione){

        let params = {
            walletapikey: walletApiKey,
            authCode: authCode,
            descrizione: descrizione
        };

        return FuxHTTP.apiPostRequestWithPromise(
            APIWelfareConfig.url(`/welfare/pagamenti/autorizza`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },

};

export default PagamentiDipendenteAPI;