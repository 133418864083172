import React from "react";
import PropTypes from "prop-types";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {swal} from "../../../helpers/SweetAlertWrapper";

export class ServizioCard extends React.Component {

    constructor(props) {
        super(props);
        this.imageStyle = {
            width: 70,
            height: 70,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url(${this.props.servizio.immagine})`,
            borderRadius: "50%"
        }
        this.descriptionStyle = {opacity: 0.8};
    }


    handleImageClick = e => {
        e.stopPropagation();
        e.preventDefault();
        swal.fire({
            html: <img src={this.props.servizio.immagine} className={"img-fluid"} alt={this.props.servizio.nome}/>,
            showConfirmButton: false,
            showCloseButton: true,
            focusCancel: false, focusConfirm: false
        });
    }

    render() {
        return (
            <div className={"card bg-main-z2 my-2 text-on-bg-main"}>
                <div className={"card-body"}>
                    <div className={"row align-items-center"}>
                        <div className={this.props.showImage && this.props.servizio.immagine ? 'col-8' : 'col-12'}>
                            <b><SafeHtmlContainer html={this.props.servizio.nome}/></b>
                            {
                                !!this.props.showPrice &&
                                <span>
                                    <br/>
                                    <span className={"badge badge-primary"}>
                                        {`€${parseFloat(this.props.servizio.prezzo).toFixed(2)}`}
                                    </span>
                                </span>
                            }
                        </div>
                        {
                            (!!this.props.showImage && !!this.props.servizio.immagine) &&
                            <div className={"col p-0"}>
                                <div onClick={this.handleImageClick} className={"mx-auto"} style={this.imageStyle}/>
                            </div>
                        }
                        {
                            (!!this.props.showDescription && !!this.props.servizio.descrizione) &&
                            <div className={"col-12 border-top mt-2 pt-2"} style={this.descriptionStyle}>
                                <SafeHtmlContainer html={this.props.servizio.descrizione}/>
                            </div>
                        }
                        {
                            !!this.props.showDuration &&
                            <div className='col-12'>Durata: {this.props.servizio.durata * 5}min </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


ServizioCard.propTypes = {
    servizio: PropTypes.object,
    showPrice: PropTypes.bool,
    showDuration: PropTypes.bool,
    showDescription: PropTypes.bool,
    showImage: PropTypes.bool
}
