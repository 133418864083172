import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {OspiteCrudForm} from "./OspiteCrudForm";
import FuxEvents from "../../../../../../lib/FuxFramework/FuxEvents";
import {HOTEL_GUEST_SAVED_EVT} from "../../../../../../const/AppEventsCostants";

export class GuestCRUDModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleSuccess = _ => {
        FuxEvents.emit(HOTEL_GUEST_SAVED_EVT);
        this.props.onDismiss()
    }

    render() {
        return (
            <Page
                title={"Aggiungi ospite"}
                active={true}
                onLeftIconClick={this.props.onDismiss}
                
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
            >
                <OspiteCrudForm
                    idPrenotazione={this.props.idPrenotazione}
                    ospite={this.props.ospiteData}
                    onSuccess={this.handleSuccess}
                />
            </Page>
        )
    }

}

GuestCRUDModal.propTypes = {
    onDismiss: PropTypes.func,
    idPrenotazione: PropTypes.string.isRequired,
    ospiteData: PropTypes.object
}