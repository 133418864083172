import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {DashboardAdminSwitchListViewItem} from "./DashboardAdminSwitchListViewItem";

export function DashboardAdminSwitchListView(props) {
    return (
        <React.Fragment>
            <div className={"p-3 border-top"}>
                <div className={"small"}>Cambia profilo</div>
                <div className={"d-flex flex-nowrap w-100 align-items-start overflow-auto py-2"}>
                    {
                        props.availableShops.map(s => {
                            return <DashboardAdminSwitchListViewItem key={s.id_negozio} shop={s}/>
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

DashboardAdminSwitchListView.propTypes = {
    availableShops: PropTypes.arrayOf(PropTypes.shape({
        id_negozio: PropTypes.any,
        nome: PropTypes.string
    }))
}
