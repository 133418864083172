import React, {Suspense} from "react";
import PropTypes from "prop-types";
import NegozioAPI from "../api/NegozioAPI";
import {BookizonAppManager} from "../index";
import {fetchStyles} from "./BookizonRouter";
import Styled from "styled-components";
import AppConfig from "../config/AppConfig";
import {swal} from "../helpers/SweetAlertWrapper";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";
import {BookizonAppLoaderSplashscreen} from "./BookizonAppLoaderSplashscreen";
import AuthAPI from "../api/AuthAPI";
import WebFont from "webfontloader";
import FuxEvents from "../lib/FuxFramework/FuxEvents";
import {APP_FAVOURITE_ADDED} from "../const/AppEventsCostants";
import {SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_TERMINATED} from "../const/SubscriptionConstants";
import {BookizonAppUnavailableSplashscreen} from "./BookizonAppUnavailableSplashscreen";

const LazyApp = React.lazy(() => import('./../components/App'));

export class BookizonAppContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoNegozio: null
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.infoNegozio !== nextState.infoNegozio ||
            this.props.id_negozio !== nextProps.id_negozio;
    }

    componentDidMount() {
        this.fetchInfoNegozio();
        FuxEvents.on(APP_FAVOURITE_ADDED, this.handleAppAdded);
        AuthAPI.onStateChanged(this.handleAuthStateChanged);
    }

    componentWillUnmount() {
        FuxEvents.off(APP_FAVOURITE_ADDED, this.handleAppAdded);
        AuthAPI.offStateChanged(this.handleAuthStateChanged);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.id_negozio !== prevProps.id_negozio) {
            this.fetchInfoNegozio();
        }
    }

    handleAuthStateChanged = (user, prevUser) => {
        console.log("user", user, "prev", prevUser);
        if (user && !prevUser) {
            this.fetchInfoNegozio();
        }
    }

    handleAppAdded = id_negozio => {
        if (this.state.infoNegozio && this.state.infoNegozio.id_negozio === id_negozio) {
            this.setState({
                infoNegozio: {...this.state.infoNegozio, wasAdded: true}
            })
        }
    }


    fetchInfoNegozio = _ => {
        let promises = [
            NegozioAPI.getRichInfo(this.props.id_negozio),
            fetchStyles(this.props.id_negozio)
        ];
        if (this.props.isStandalone) {
            promises.push(AuthAPI.start(true, 'AppStandalone'));
        }
        Promise.all(promises)
            .then(results => {
                let infoNegozio = results[0];
                if (typeof infoNegozio.social === "string") infoNegozio.social = JSON.parse(infoNegozio.social);
                if (typeof infoNegozio.impostazioni === "string") infoNegozio.impostazioni = JSON.parse(infoNegozio.impostazioni);
                if (typeof infoNegozio.aspetto === "string") infoNegozio.aspetto = JSON.parse(infoNegozio.aspetto);
                BookizonAppManager.setNativeTopUnsafeAreaColor(infoNegozio.aspetto.bg_main_z2, "circuito -> app");
                BookizonAppManager.setNativeBottomUnsafeAreaColor(infoNegozio.aspetto.bg_main_z2);

                const fontImportString = getFontFamilyLoadStringFromFontFamily(infoNegozio.aspetto.font_family);
                if (fontImportString) {
                    console.log(fontImportString);
                    WebFont.load({
                        google: {
                            families: [fontImportString]
                        }
                    });
                }

                const style = results[1];

                NegozioOpenStore.infoNegozio = infoNegozio;
                NegozioOpenStore.id_negozio = infoNegozio.id_negozio;
                this.setState({
                    infoNegozio: infoNegozio,
                    style: style
                }, _ => {
                    swal.safeClose();
                    const modalRoot = document.getElementById("modal-root");
                    if (modalRoot) modalRoot.classList.add("d-none");
                    const bookizonLauncherWrapper = document.getElementById("bookizon-launcher-wrapper");
                    if (bookizonLauncherWrapper) bookizonLauncherWrapper.classList.add("d-none");
                    document.documentElement.scrollTop = 0;
                });
            });
    }


    getWrapper = () => Styled.div`
        ${this.state.style}
    `;

    needLogin = _ => {
        if (this.state.infoNegozio.impostazioni.needLogin !== undefined) {
            return parseInt(this.state.infoNegozio.impostazioni.needLogin) === 1;
        }
        return true;
    }


    render() {
        if (!this.state.infoNegozio) {
            return <BookizonAppLoaderSplashscreen id_negozio={this.props.id_negozio}/>
        }

        if (this.state.infoNegozio.subscriptionStatus === SUBSCRIPTION_STATUS_TERMINATED){
            return <BookizonAppUnavailableSplashscreen id_negozio={this.props.id_negozio} onDismiss={this.props.onAppClose}/>
        }

        const AppThemeWrapper = this.getWrapper();
        return (
            <AppThemeWrapper id={"app"}>
                <div className={"bg-main"} style={{"minHeight": "100vh"}}>
                    <Suspense fallback={<BookizonAppLoaderSplashscreen id_negozio={this.props.id_negozio}/>}>
                        <LazyApp infoNegozio={this.state.infoNegozio} onAppClose={this.props.onAppClose}
                                 isStandalone={this.props.isStandalone} needLogin={this.needLogin()}
                                 promotionChosenInfo={this.props.promotionChosenInfo}
                        />
                    </Suspense>
                    <div id={"app-modal-root"}/>
                </div>
            </AppThemeWrapper>
        )

    }

}


BookizonAppContainer.propTypes = {
    id_negozio: PropTypes.any,
    onAppClose: PropTypes.func,
    isStandalone: PropTypes.bool
}


const getFontFamilyLoadStringFromFontFamily = fontFamily => {
    const fontData = {
        "roboto": {
            "loadString": "Roboto:300,700"
        },
        "merriweather": {
            "loadString": "Merriweather:300,700"
        },
        "lemonada": {
            "loadString": "Lemonada:300,700"
        },
        "poppins": {
            "loadString": "Poppins:300,700"
        },
        "pt-serif": {
            "loadString": "PT Serif:300,700"
        },
        "baloo-2": {
            "loadString": "Baloo 2:300,700"
        },
        "caveat": {
            "loadString": "Caveat:300,700"
        },
        "josefin-sans": {
            "loadString": "Josefin Sans:300,700"
        },
        "lobster-two": {
            "loadString": "Lobster Two:300,700"
        },
        "lora": {
            "loadString": "Lora:300,700"
        },
        "dancing-script": {
            "loadString": "Dancing Script:300,700"
        },
    }
    return fontData[fontFamily] ? fontData[fontFamily].loadString : null;
}
