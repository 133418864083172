import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import i18n from "../../../../i18n";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import FuxHTTP from "../../../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../../../config/AppConfig";
import AuthAPI from "../../../../api/AuthAPI";
import ModalPortal from "../../../../components/BaseComponents/ModalPortal";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import CalendarioGruppi from "../ModuloGruppi/CalendarioGruppi";

export class DashboardModuloGruppiButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageOpen: false,
            infoNegozio: null,
        }
    }

    handlePageOpen = _ => {
        this.setState({pageOpen: true});
        this.fetchInfoNegozio();
    }

    handlePageClose = _ => this.setState({pageOpen: false});

    fetchInfoNegozio = _ => {
        this.setState({infoNegozio: null});
        swalLoading();
        FuxHTTP.apiGetRequestWithPromise(
            `${AppConfig.webServerUrl}/api/dashboard-gruppi/infoNegozio/${AuthAPI.getUserAccessToken()}`, {},
            FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE
        ).then(data => {
            this.setState({infoNegozio: data});
            swal.safeClose();
        }).catch(swalError);
    }

    render() {
        return (
            <Fragment>
                <div className={"d-flex align-items-center justify-content-between p-3 border-top text-info"}
                     onClick={this.handlePageOpen}>
                    <span>
                        <FaSolidIcon name={"users"}/>&nbsp;
                        {i18n.t(`launcher.side-menu.${this.props.labelName}`)}
                    </span>
                    {
                        this.props.prenotazioniSospese &&
                        <span className={"badge badge-pill badge-primary"}>
                            Ancora {this.props.prenotazioniSospese} oggi
                        </span>
                    }
                </div>
                <ModalPortalHOC>
                    {
                        (this.state.infoNegozio && this.state.pageOpen) &&
                        <CalendarioGruppi {...this.state.infoNegozio} onDismiss={this.handlePageClose}/>
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DashboardModuloGruppiButton.propTypes = {
    labelName: PropTypes.string.isRequired,
    prenotazioniSospese: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}