import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {PluralString} from "../../../../helpers/UIUtility";
import {formatNumber} from "../../../../helpers/CurrencyHelpers";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import moment from "moment";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {getCompressedImageUrl} from "../../../../helpers/ImageUtility";
import {ProductImageSize} from "../../../../const/ImageSizeCostants";

export class ServizioView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAvailableDates: false,
        }

        this.imageStyle = {
            height: '20vh',
            width: '100%',
            backgroundImage: `url('${getCompressedImageUrl(this.props.servizioData.immagini?.[0], ProductImageSize.preview.w, ProductImageSize.preview.h)}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
        }
        this.textBackdropStyle = {
            position: 'absolute',
            zIndex: 0,
            width: '100%',
            height: 50,
            background: "linear-gradient(180deg, rgba(30,30,30,0.65) 50%, transparent 100%)"
        }
        this.cardStyle = {
            border: 0,
            borderRadius: '.75rem',
            overflow: 'hidden'
        }
    }

    toggleAvailableDatesVisibility = _ => this.setState({showAvailableDates: !this.state.showAvailableDates});

    render() {
        return (
            <div className={"card mb-3 shadow-sm"} style={this.cardStyle} onClick={this.handleClick}>
                <div style={this.imageStyle} className={"card-img"}/>
                <div style={this.textBackdropStyle}/>
                <div className={"card-img-overlay p-2"}>
                    <h6 className={"card-title text-white mb-0"}>
                        {
                            !!this.props.servizioData.moltiplica_input &&
                            `${this.props.servizioData.quantita}x`
                        } <SafeHtmlContainer html={this.props.servizioData.json.nome}/>
                    </h6>
                </div>
                <div className={"card-footer bg-main-z2 position-relative"}>
                    {/* Prezzo */}
                    <div>
                        <b className={"text-success"}>€{formatNumber(this.props.servizioData.totale, 2)}</b>&nbsp;
                        {
                            (!!this.props.servizioData.moltiplica_adulti && !!this.props.servizioData.nadulti) &&
                            <Fragment>
                                &nbsp;<FaSolidIcon name={"male"}/>&nbsp;
                                {this.props.servizioData.nadulti} <PluralString
                                n={this.props.servizioData.nadulti} more={"adulti"} single={"adulto"}/>
                            </Fragment>
                        }

                        {
                            (!!this.props.servizioData.moltiplica_bambini && !!this.props.servizioData.nbambini) &&
                            <Fragment>
                                &nbsp;<FaSolidIcon name={"child"}/>&nbsp;
                                {this.props.servizioData.nbambini} <PluralString
                                n={this.props.servizioData.nbambini} more={"bambini"} single={"bambino"}/>
                            </Fragment>
                        }
                    </div>


                    {/* Date prenotate */}
                    <div className={"small text-info"} onClick={this.toggleAvailableDatesVisibility}>
                        Vedi giorni prenotati
                    </div>
                    {
                        this.state.showAvailableDates &&
                        <div className={"pl-2 text-muted"}>
                            {
                                Object.keys(this.props.servizioData.datePrenotate).map(dateYmd =>
                                    <div className={"small"} key={dateYmd}>
                                        {moment(dateYmd, 'YYYY-MM-DD').format('dddd DD MMM')}
                                    </div>
                                )
                            }
                        </div>
                    }


                </div>

            </div>
        )
    }

}

ServizioView.propTypes = {
    servizioData: PropTypes.shape({
        id_servizio: PropTypes.string,
        quantita: PropTypes.number,
        nadulti: PropTypes.number,
        nbambini: PropTypes.number,
        totale: PropTypes.number,
        json: PropTypes.shape({
            nome: PropTypes.string,
            descrizione: PropTypes.string,
            sempre_disponibile: PropTypes.bool,
            moltiplica_adulti: PropTypes.number,
            moltiplica_bambini: PropTypes.number,
            moltiplica_input: PropTypes.number,
            moltiplica_giorni: PropTypes.number,
        }),
        datePrenotate: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
}