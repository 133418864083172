import React, {Fragment} from "react";
import {swal, swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import EcommerceAPI from "../../api/EcommerceAPI";
import EcommercePaymentPage from "./EcommercePaymentPage";
import {
    BOOKIZON_PAY_METHODS,
    PAYMENT_METHOD_CONSEGNA,
    PAYMENT_METHOD_PAYPAL
} from "../../const/PaymentMethodoCostants";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";
import {ECOMMERCE_ORDER_TYPE_DEFAULT, ECOMMERCE_ORDER_TYPE_RITIRO} from "../../const/ModuloEcommerceCostants";
import {applyPercentage} from "../../helpers/NumberUtility";
import {EcommerceCheckoutDetailPage} from "./Checkout/EcommerceCheckoutDetailPage";
import PropTypes from "prop-types";


export default class EcommerceDirectCheckoutFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tipo_ordine: '',
            resumeOpen: false,
            orderData: {
                shipping: null,
                billing: null,
                corriere: null,
                metodo_pagamento: null,
                data: null,
                orario: null,
                coupon: null,
                note: null,
                formHTML: null
            },
            paymentPageOpen: false,
            paymentData: null,
            id_ordine: null,
        }
    }


    handleTipoOrdineChoose = tipo_ordine => {
        this.setState({
            tipo_ordine: tipo_ordine,
            resumeOpen: true,
        });
    }

    closeResumePage = _ => this.setState({resumeOpen: false})

    handleResumeConfirm = ({shipping, billing, corriere, metodo_pagamento, data, orario, coupon, note, formHTML}) => {
        this.setState({
            orderData: {
                shipping: shipping,
                billing: billing,
                corriere: corriere,
                metodo_pagamento: metodo_pagamento,
                data: data,
                orario: orario,
                coupon: coupon,
                note: note,
                formHTML: formHTML
            }
        }, _ => {
            if (this.state.id_ordine) { //Se l'ordine è stato già creato riutilizzo lo stesso per il pagamento
                this.setState({paymentPageOpen: true});
            } else {
                this.placeOrder();
            }
        })
    }

    placeOrder = _ => {
        const orderData = {...this.state.orderData};
        orderData.coupon = !orderData.coupon ? null : orderData.coupon;

        swalLoading('Attendere prego...');
        EcommerceAPI.placeOrder(
            this.props.orderType,
            this.props.products,
            orderData.shipping ? orderData.shipping.id_indirizzo : null,
            orderData.billing ? orderData.billing.id_indirizzo : null,
            orderData.metodo_pagamento,
            orderData.note,
            orderData.corriere ? orderData.corriere.id_corriere : null,
            orderData.data,
            orderData.orario,
            orderData.coupon,
            orderData.wallets,
            orderData.formHTML
        ).then(response => {
            const data = response.data;
            if (data.payment) {
                this.setState({
                    id_ordine: response.data.id_ordine,
                    paymentData: data.payment,
                    confirmMessage: response.message,
                    paymentPageOpen: true
                })
                swal.safeClose();
            } else {
                swalSuccess(response.message);
                this.props.onPaymentSuccess();
            }
        }).catch(swalError);
    }

    handlePaymentPageDismiss = _ => this.setState({paymentPageOpen: false})

    handlePaymentSuccess = _ => {
        swalSuccess(this.state.confirmMessage);
        this.props.onPaymentSuccess();
    }

    /* UTILITY */
    getTotaleProdotti = _ => {
        return this.props.products.reduce((acc, p) => {
            const priceTotal = (parseFloat(p.prezzo) * parseFloat(p.quantita));
            const discount = p.perc_sconto ? parseFloat(p.perc_sconto) : 0.0;
            return acc + applyPercentage(priceTotal, -discount);
        }, 0);
    }

    getAvailableOrderTypes = _ => {

        //Verifico il tipo di spedizione disponibile per i prodotti
        let tipoConsegna = {abilita_ritiro: 1, abilita_spedizione: 1};

        this.props.products.map((p, i) => {
            tipoConsegna["abilita_ritiro"] = p.abilita_ritiro === "0" ? 0 : tipoConsegna["abilita_ritiro"];
            tipoConsegna["abilita_spedizione"] = p.abilita_spedizione === "0" ? 0 : tipoConsegna["abilita_spedizione"];
        });

        let orderTypes = [];
        if (NegozioOpenStore.infoNegozio.impostazioni.ordini_abilita_ritiro === "1" && tipoConsegna["abilita_ritiro"]) {
            orderTypes.push(ECOMMERCE_ORDER_TYPE_RITIRO);
        }
        if (NegozioOpenStore.infoNegozio.accetta_spedizione && tipoConsegna["abilita_spedizione"]) {
            orderTypes.push(ECOMMERCE_ORDER_TYPE_DEFAULT);
        }

        return orderTypes;
    }

    render() {
        const orderData = {
            id_ordine: this.state.id_ordine,
            ...this.state.orderData,
            prodotti: [...this.props.products]
        }
        return (
            <Fragment>
                <EcommerceCheckoutDetailPage
                    onDismiss={this.props.onDismiss}
                    onConfirm={this.handleResumeConfirm}
                    getShipping={this.props.getShipping}
                    getBilling={this.props.getBilling}
                    orderType={this.props.orderType}
                    paymentMethods={this.props.paymentMethods}
                    products={this.props.products}
                />

                {
                    this.state.paymentPageOpen ?
                        <EcommercePaymentPage
                            onDismiss={this.handlePaymentPageDismiss}
                            onSuccess={this.handlePaymentSuccess}
                            orderData={orderData}
                            prezzoProdotti={this.getTotaleProdotti()}
                            paymentData={this.state.paymentData}
                        /> : ''
                }
            </Fragment>
        )
    }

}


EcommerceDirectCheckoutFlow.propTypes = {
    onDismiss: PropTypes.func,
    onPaymentSuccess: PropTypes.func,
    orderType: PropTypes.oneOf([ECOMMERCE_ORDER_TYPE_DEFAULT, ECOMMERCE_ORDER_TYPE_RITIRO]).isRequired,
    products: PropTypes.array.isRequired,
    getShipping: PropTypes.bool.isRequired,
    getBilling: PropTypes.bool.isRequired,
    paymentMethods: PropTypes.arrayOf(PropTypes.oneOf([PAYMENT_METHOD_CONSEGNA, PAYMENT_METHOD_PAYPAL, ...BOOKIZON_PAY_METHODS])).isRequired
}
