import PropTypes from "prop-types";

export const CityPropType = PropTypes.shape({
    city_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    normalised_name: PropTypes.string.isRequired,
    logo_url: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
    default_lang: PropTypes.string.isRequired,
    fallback_lang: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
});
