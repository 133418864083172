import {ALIQUOTE_IVA, METODI_PAGAMENTO} from "../../ModuloCassaContants";
import {
    getDescrizioneIva,
    getScontoAPagare,
    getSubtotalOfItem,
    getTotaleItems,
    getTotaleIva,
    getTotaleScontrino
} from "../../ReceiptsUtils";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import React, {Fragment} from "react";
import AggiungiItemPanel from "./AggiungiItemPanel";
import PropTypes from "prop-types";
import {PageFooter} from "../../../../../../components/BaseComponents/Page/PageFooter";
import ReceiptItem from "../ReceiptItem";
import {
    PAYMENT_METHOD_ASSEGNO,
    PAYMENT_METHOD_BANCOMAT, PAYMENT_METHOD_BONIFICO,
    PAYMENT_METHOD_CARTA,
    PAYMENT_METHOD_CONTANTI, PAYMENT_METHOD_PAYPAL
} from "../../../../../../const/PaymentMethodoCostants";


export default class ListItemsInterface extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemInPanel: "",
            addItemPanelOpen: false
        }
        this.baseItem = {
            descrizione: "",
            importo_unitario: 1,
            quantita: 1,
            sconto: "",
            codice_aliquota: ALIQUOTE_IVA[3].codice,
            sconto_documento: ""
        };
    }


    toggleAddItemPanel = (index = false) => {
        let itemInPanel = this.baseItem
        //Se è settato un indice sovrascrivo itemInPanel settando quello desiderato dall'utente
        if (index) itemInPanel = {...this.props.items[index], index: index}

        console.log(itemInPanel)

        this.setState({
            addItemPanelOpen: !this.state.addItemPanelOpen,
            itemInPanel: itemInPanel,
        })
    }


    render() {
        return (
            <Fragment>
                <div>
                    <div className={"p-3"}>
                        {/*GESTIONE ITEMS*/}
                        {
                            this.props.items.length > 0 &&
                            <div className={"card shadow-sm border-0"}>
                                <div className={"card-body p-0 list-group-flush"}>
                                    {
                                        this.props.items.map((item, i) => {
                                            return <ReceiptItem item={item} key={i} deleteItem={this.props.deleteItem}/>
                                        })

                                    }
                                </div>
                            </div>
                        }
                        <div className={"w-100 text-center"}>
                            <button type={"button"} className={"btn btn-link"}
                                    onClick={_ => this.toggleAddItemPanel()}>
                                + Aggiungi riga
                            </button>
                        </div>

                        {/*GESTIONE METODI PAGAMENTO*/}
                        {
                            this.props.items.length > 0 &&
                            <div className={"mt-3"}>
                                Metodi di pagamento
                                <div className={"card shadow-sm border-0"}>
                                    <div className={"card-body container"}>
                                        {
                                            this.props.metodi_pagamento.map((mp, i) => {
                                                return <div key={i} className={"mb-2 row"}>
                                                    <div className={"col-6"}>
                                                        <select className={"form-control"}
                                                                name={"metodo_pagamento"} data-idx={i}
                                                                onChange={this.props.changeMetodoPagamento}
                                                                value={mp.metodo_pagamento}>
                                                            <option value={PAYMENT_METHOD_CONTANTI}>Contanti</option>
                                                            <option value={PAYMENT_METHOD_CARTA}>Carta di credito</option>
                                                            <option value={PAYMENT_METHOD_BANCOMAT}>Bancomat</option>
                                                            <option value={PAYMENT_METHOD_BONIFICO}>Bonifico</option>
                                                            <option value={PAYMENT_METHOD_ASSEGNO}>Assegno</option>
                                                            <option value={PAYMENT_METHOD_PAYPAL}>Pay Pal</option>
                                                        </select>
                                                    </div>
                                                    <div className={"col-4"}>
                                                        <input
                                                            type={"number"}
                                                            name={"importo"}
                                                            placeholder={"importo"}
                                                            value={mp.importo}
                                                            className={"form-control mr-2"}
                                                            step={"0.01"}
                                                            data-idx={i}
                                                            onChange={this.props.changeMetodoPagamento}
                                                        />
                                                    </div>
                                                    <div className={"col-1"}>
                                                        <button
                                                            className={"btn btn-link text-danger " + (i ? "" : "disabled")}
                                                            onClick={_ => this.props.deleteMetodoPagamento(i)}>
                                                            <FaSolidIcon name={"trash"}/>
                                                        </button>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>

                                {this.props.errorsMetodiPagamento()}

                                {
                                    this.props.metodi_pagamento.length < 2 &&
                                    <div className={"w-100 text-center"}>
                                        <button type={"button"} className={"btn btn-link"}
                                                onClick={_ => this.props.addMetodoPagamento()}>
                                            + Aggiungi metodo
                                        </button>
                                    </div>
                                }

                                {/*Riepilogo scontrino*/}
                                <br/>
                                <span className={"mt-2"}>Riepilogo</span>
                                <div className={"card shadow-sm border-0 mb-4"}>
                                    <div className={"card-body"}>
                                        <div className={"mb-3"}>
                                            <div className={"input-group"}>
                                                <div className={"input-group-prepend"}>
                                                        <span className={"input-group-text align-items-center"}>
                                                            <i className="fas fa-euro-sign"/> <small
                                                            className={"ml-1"}>(sconto)</small>
                                                        </span>
                                                </div>
                                                <input
                                                    type={"number"}
                                                    name={"sconto_documento"}
                                                    placeholder={"sconto sul totale"}
                                                    value={this.props.sconto_documento}
                                                    className={"form-control"}
                                                    step={"0.01"}
                                                    onChange={this.props.changeScontoDocumento}
                                                />
                                            </div>
                                            {this.props.errorsScontoDocumento()}
                                        </div>

                                        <div className={"d-flex justify-content-between my-1"}>
                                            <span>Totale imponibile</span>
                                            <span>€{(getTotaleItems(this.props.items) - getTotaleIva(this.props.items)).toFixed(2)}</span>
                                        </div>
                                        <hr className={"m-0"}/>
                                        <div className={"d-flex justify-content-between my-1"}>
                                            <span>IVA versata</span>
                                            <span>€{getTotaleIva(this.props.items).toFixed(2)}</span>
                                        </div>
                                        <hr className={"m-0"}/>
                                        <div className={"d-flex justify-content-between my-1"}>
                                            <span>Subtotale prodotti</span>
                                            <span>€{getTotaleItems(this.props.items, false).toFixed(2)}</span>
                                        </div>
                                        {
                                            this.props.sconto_documento &&
                                            <div>
                                                <hr className={"m-0"}/>
                                                <div className={"d-flex justify-content-between my-1"}>
                                                    <span>Sconto</span>
                                                    <span>-€{parseFloat(this.props.sconto_documento || "0").toFixed(2)}</span>
                                                </div>
                                            </div>
                                        }
                                        {
                                            getScontoAPagare(this.props.metodi_pagamento, this.props.items) !== 0 &&
                                            <div>
                                                <hr className={"m-0"}/>
                                                <div className={"d-flex justify-content-between my-1"}>
                                                    <span>Sconto a pagare</span>
                                                    <span>€{getScontoAPagare(this.props.metodi_pagamento, this.props.items)}</span>
                                                </div>
                                            </div>
                                        }

                                        <hr className={"m-0"}/>
                                        <div className={"d-flex justify-content-between my-1"}>
                                            <b>Totale scontrino</b>
                                            <b>€{(getTotaleScontrino(this.props.metodi_pagamento, this.props.items)).toFixed(2)}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div>
                    {
                        this.state.addItemPanelOpen &&
                        <AggiungiItemPanel
                            useNavbar={true}
                            expanded={true}
                            onToggle={this.toggleAddItemPanel}
                            item={this.state.itemInPanel}
                            onAddItem={this.props.addItem}
                        />
                    }
                </div>

                <PageFooter className={"px-3 pt-2 border-top w-100 bg-white"}>
                    <button className={"btn btn-primary btn-block my-3"} onClick={_ => this.props.submitReceipt()}
                            disabled={!this.props.items.length > 0}>Salva & Trasmetti
                    </button>
                </PageFooter>
            </Fragment>
        )
    }
}

ListItemsInterface.propTypes = {
    //Sconto
    sconto_documento: PropTypes.string,
    errorsScontoDocumento: PropTypes.func,
    changeScontoDocumento: PropTypes.func,
    //Items
    items: PropTypes.array,
    addItem: PropTypes.func,
    deleteItem: PropTypes.func,
    //Metodi Pagamento
    metodi_pagamento: PropTypes.array,
    addMetodoPagamento: PropTypes.func,
    deleteMetodoPagamento: PropTypes.func,
    changeMetodoPagamento: PropTypes.func,
    errorsMetodiPagamento: PropTypes.func,
    //Submit
    submitReceipt: PropTypes.func,
}
