export const ALIQUOTE_IVA = [
    {codice:"4", descrizione:"4%"},
    {codice:"5", descrizione:"5%"},
    {codice:"10", descrizione:"10%"},
    {codice:"22", descrizione:"22%"},
    {codice:"N1", descrizione:"Escluse ex art. 15"},
    {codice:"N2", descrizione:"Non soggette"},
    {codice:"N3", descrizione:"Non imponibili"},
    {codice:"N4", descrizione:"Esenti"},
    {codice:"N5", descrizione:"Regime del margine"},
    {codice:"N6", descrizione:"Altro non IVA"},
    {codice:"2", descrizione:"2% - Perc. compensazione agricoltura"},
    {codice:"6", descrizione:"6% - Perc. compensazione agricoltura"},
    {codice:"7.3", descrizione:"7.3% - Perc. compensazione agricoltura"},
    {codice:"7.5", descrizione:"7.5% - Perc. compensazione agricoltura"},
    {codice:"7.65", descrizione:"7.65% - Perc. compensazione agricoltura"},
    {codice:"7.95", descrizione:"7.95% - Perc. compensazione agricoltura"},
    {codice:"8.3", descrizione:"8.3% - Perc. compensazione agricoltura"},
    {codice:"8.5", descrizione:"8.5% - Perc. compensazione agricoltura"},
    {codice:"8.8", descrizione:"8.8% - Perc. compensazione agricoltura"},
    {codice:"12.3", descrizione:"12.3% - Perc. compensazione agricoltura"}
]

export const METODI_PAGAMENTO = [
    {codice:"1", descrizione:"contanti"},
    {codice:"2", descrizione:"pagamento elettronico"},
    {codice:"3", descrizione:"obsoleto"},
    {codice:"4", descrizione:"corrispettivo non riscosso servizi"},
    {codice:"5", descrizione:"corrispettivo non riscosso beni"},
    {codice:"6", descrizione:"ticket restaurant"},
    {codice:"7", descrizione:"emissione fattura"}
]