import {SeatsBookType} from "./BookType";

import PropTypes from "prop-types";
import {SeatsMapType} from "./MapType";
import {LinkedBookType} from "./LinkedBookType";

export const UserBookInfoType = PropTypes.shape({
    map: SeatsMapType,
    master: SeatsBookType,
    qr_code_url: PropTypes.string,
    dates: PropTypes.objectOf(PropTypes.arrayOf(LinkedBookType)), //each key is a date in Y-m-d format
    online_payments: PropTypes.objectOf(PropTypes.number), //each key is a seat_id
    disputes: PropTypes.arrayOf(PropTypes.shape({
        id_operazione: PropTypes.string,
        tipo_operazione: PropTypes.string,
        id_negozio: PropTypes.string,
        id_utente: PropTypes.string,
        id_chat_room: PropTypes.string,
        status: PropTypes.string,
        data_creazione: PropTypes.string,
        data_modifica: PropTypes.string
    }))
});