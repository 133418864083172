import PropTypes from "prop-types";

export const FilePropType = PropTypes.shape({
    id_file: PropTypes.any,
    filename: PropTypes.string,
    url: PropTypes.string,
    note: PropTypes.string,
    mime_type: PropTypes.string,
    data_creazione: PropTypes.string,
    data_modifica: PropTypes.string
})
