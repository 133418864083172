import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {StoriesLikeSelector} from "../../../../../components/GenericUI/StoriesLikeSelector/StoriesLikeSelector";
import {getCompressedImageUrl} from "../../../../../helpers/ImageUtility";
import {LightCalendarEvent} from "./LightCalendarEvent";
import {EllipsisLoader} from "../../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import moment from "moment";
import {CalendarDayPicker} from "../CalendarDayPicker";
import AppConfig from "../../../../../config/AppConfig";
import AuthAPI from "../../../../../api/AuthAPI";
import {ModuloServiziAPI} from "../../../../../api/ModuloServizi/ModuloServiziAPI";
import FuxEvents from "../../../../../lib/FuxFramework/FuxEvents";
import {EVT_REFETCH_EVENTS} from "../CalendarioServizi";

const DEFAULT_ID_DIPENDENTE_LOCAL_STORAGE_KEY = "dashboard_servizi_id_dipendente_default";

export class LightCalendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            dateIntervalStart: moment().subtract(this.props.daysBeforeToday, 'days').format('YYYY-MM-DD'),
            dateIntervalEnd: moment().add(this.props.daysAfterToday, 'days').format('YYYY-MM-DD'),
            id_sede: this.props.infoNegozio.sedi[0].id_sede,
            id_dipendente: this.props.infoNegozio.sedi[0].dipendenti[0].id_dipendente,
            events: [],
            datesBookCount: null,
            loading: false,
        }

        const savedIdDipendente = localStorage.getItem(DEFAULT_ID_DIPENDENTE_LOCAL_STORAGE_KEY);
        if (savedIdDipendente) {
            if (this.props.infoNegozio.sedi.find(s => !!s.dipendenti.find(d => d.id_dipendente === savedIdDipendente))) {
                this.state.id_dipendente = savedIdDipendente;
            }
        }

    }

    componentDidMount() {
        this.fetchEvents();
        this.fetchDatesBooksCount();
        FuxEvents.on(EVT_REFETCH_EVENTS, this.fetchEvents);
    }

    componentWillUnmount() {
        FuxEvents.off(EVT_REFETCH_EVENTS, this.fetchEvents);
    }

    /** @MARK: Events fetching */
    fetchEvents = _ => {
        this.setState({loading: true});
        const startDate = this.state.selectedDate;
        startDate.setUTCHours(0);
        startDate.setUTCMinutes(0);
        startDate.setUTCSeconds(0);
        const endDate = new Date(this.state.selectedDate);
        endDate.setUTCHours(23);
        endDate.setUTCMinutes(59);
        endDate.setUTCSeconds(59);
        var url = new URL(`${AppConfig.webServerUrl}/api/dashboard/events/${this.state.id_sede}/${this.state.id_dipendente}/${AuthAPI.currentUser.accessToken}`);
        url.searchParams.append('start', startDate.toISOString());
        url.searchParams.append('end', endDate.toISOString());
        fetch(url.toString())
            .then(response => {
                if (!response.ok) {
                    console.error("Fetch request error");
                    return;
                }
                return response.json();
            })
            .then(events => this.setState({events: events}))
            .then(_ => {
                this.setState({loading: false});
            });
    }

    fetchDatesBooksCount = _ => {
        ModuloServiziAPI.Dashboard.getDateListBooksCount(this.state.dateIntervalStart, this.state.dateIntervalEnd, this.state.id_dipendente)
            .then(datesInfo => {
                this.setState({
                    datesBookCount: {...(this.state.datesBookCount ? this.state.datesBookCount : {}), ...datesInfo}
                });
            });
    }


    /** @MARK: Date changes */
    handleDateChange = date => this.setState({selectedDate: date}, _ => {
        this.props.onDateChange(this.state.selectedDate);
        this.fetchEvents();
    });

    /** @MARK: Dipendenti */
    getListaDipendenti = _ => {
        const sede = this.props.infoNegozio.sedi.find(s => s.id_sede = this.state.id_sede);
        if (!sede) return [];
        return sede.dipendenti;
    }

    handleChangeDipendente = id_dipendente => {
        this.setState({id_dipendente: id_dipendente},
            _ => {
                this.fetchEvents();
                this.fetchDatesBooksCount();
            });
        localStorage[DEFAULT_ID_DIPENDENTE_LOCAL_STORAGE_KEY] = id_dipendente;
    }

    handleAddBookWithTime = orario => this.props.onAddBookWithData({orario:orario, id_dipendente: this.state.id_dipendente});

    render() {
        const selectedDipendente = this.getListaDipendenti().find(d => d.id_dipendente === this.state.id_dipendente);
        return (
            <Fragment>
                <div className={"container my-3 __disable-swipe"}>
                    <StoriesLikeSelector
                        items={
                            this.getListaDipendenti().map(d => {
                                return {
                                    value: d.id_dipendente,
                                    label: d.nome,
                                    imageUrl: getCompressedImageUrl(`${d.immagine}`, 150, 150)
                                }
                            })
                        }
                        circleSize={60}
                        onChange={this.handleChangeDipendente}
                        defaultValue={this.state.id_dipendente}
                    />
                </div>
                {
                    !!this.state.datesBookCount &&
                    <CalendarDayPicker
                        datesBookCount={this.state.datesBookCount}
                        daysBeforeToday={this.props.daysBeforeToday}
                        daysAfterToday={this.props.daysAfterToday}
                        onDateChange={this.handleDateChange}
                    />
                }
                <div className={"container py-3"}>
                    {
                        !this.state.loading &&
                        <Fragment>
                            {
                                addEmptyEvents(this.state.events).map(e =>
                                    <LightCalendarEvent
                                        key={e.isFreeTimeEvent ? `ft-${e.start}-${e.end}` : e.book.id_prenotazione}
                                        event={e}
                                        onOpenResume={this.props.onEventResumeOpen}
                                        onAddBookWithTime={this.handleAddBookWithTime}
                                    />
                                )
                            }
                            {
                                !this.state.events.length && selectedDipendente &&
                                <div className={"text-muted"}>
                                    Non ci sono appuntamenti per {selectedDipendente.nome} in questo giorno
                                </div>
                            }
                        </Fragment>
                    }
                    {
                        this.state.loading &&
                        <div className={"text-center"}>
                            <EllipsisLoader/>
                        </div>
                    }
                </div>
            </Fragment>
        )
    }

}

LightCalendar.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    daysBeforeToday: PropTypes.number.isRequired,
    daysAfterToday: PropTypes.number.isRequired,
    infoNegozio: PropTypes.object.isRequired,
    onEventResumeOpen: PropTypes.func.isRequired,
    onAddBookWithData: PropTypes.func.isRequired,
}


/**
 * Effettua un preprocessamento degli eventi, aggiungendo eventi "spazio" che identificano uno spazio
 * vuoto disponibile per una prenotazione
 * */
const addEmptyEvents = events => {
    const myEvents = events.slice();
    const freeSpaceEvents = {}; //Mappatura delle i-esime posizioni di "myEvents" in cui aggiungere uno spazio libero
    for (let i = 0; i < myEvents.length - 1; i++) {
        //Se trovo due eventi con l'orario di fine del primo NON CORRISPONDENTE a quello di inizio del successivo
        //Allora significa che ho un potenziale "buco" libero tra i due appuntamenti ed aggiungo un evento nuovo
        //di tipo "spazio" se verifico che effettivamente non c'è nessun'altra prenotazioen che fa overlapping con
        //il mio spazio libero
        if (myEvents[i].book.orario_fine !== myEvents[i + 1].book.orario_inizio) {
            //A questo punto ho trovato un potenziale gap tra gli eventi, ma devo verificare che non ci siano
            //sovrapposizioni con eventi che iniziano prima quello analizzato (i-esimo) ma finiscono dopo di esso
            //e quindi si trovano in posizione i-1. Per ovviare a questo problema posso porre l'orario di inizio
            //del "free-space" a MAX(orario_fine) per ogni orario_fine in myEvents[0:i]
            const realStartTime = myEvents.filter((_, idx) => idx <= i).map(e => e.book.orario_fine).sort().reverse()[0];
            const start = moment("1970-01-01 " + realStartTime);
            const end = moment("1970-01-01 " + myEvents[i + 1].book.orario_inizio);
            let diffInMinutes = end.diff(start, 'minutes');
            if (diffInMinutes > 0) { //Se è < 0 significa che non c'è davvero uno spazio libero
                freeSpaceEvents[i] = {
                    start: realStartTime,
                    end: myEvents[i + 1].book.orario_inizio,
                    diff: diffInMinutes
                };
            }
        }
    }

    let counter = 1;
    for (let j in freeSpaceEvents) {
        const freeTimeMinutes = freeSpaceEvents[j].diff % 60;

        let freeTimeStr;
        if (freeSpaceEvents[j].diff === 60) {
            freeTimeStr = `${(freeSpaceEvents[j].diff - freeTimeMinutes) / 60}h`;
        } else if (freeSpaceEvents[j].diff > 60) {
            freeTimeStr = `${(freeSpaceEvents[j].diff - freeTimeMinutes) / 60}h ${freeTimeMinutes}min`;
        } else {
            freeTimeStr = `${freeTimeMinutes} minuti`;
        }
        j = parseInt(j);
        myEvents.splice(j + counter, 0, {
            className: 'appuntamento free-time',
            start: freeSpaceEvents[j].start,
            end: freeSpaceEvents[j].end,
            title: `Libero per ${freeTimeStr}`,
            isFreeTimeEvent: true,
        });
        //Incremento il counter che terrà conto dello shift avvenuto nell'array degli eventi a causa dell'aggiunta dei precedenti spazi vuoti
        counter++;
    }

    return myEvents;

}
