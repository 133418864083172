import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {OspitiListView} from "./OspitiListView";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import ModalPortalHOC from "../../../../../components/BaseComponents/ModalPortalHOC";
import {GuestCRUDModal} from "./GuestModal/GuestCRUDModal";

export class OnlineCheckinModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addGuestModalOpen: false
        }
    }

    toggleAddGuestModal = _ => this.setState({addGuestModalOpen: !this.state.addGuestModalOpen})

    render() {
        return (
            <Fragment>
                <Page
                    title={"Checkin online"}
                    active={true}
                    onLeftIconClick={this.props.onDismiss}
                    leftIconText={"Fine"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                >
                    <div className={"container mt-3"}>
                        <OspitiListView
                            ospiti={this.props.ospiti}
                            maxOspiti={this.props.maxOspiti}
                            idPrenotazione={this.props.idPrenotazione}
                        />
                        {
                            (this.props.ospiti.length < this.props.maxOspiti) &&
                            <div className={"text-center"}>
                                <button className={"btn btn-link"} onClick={this.toggleAddGuestModal}>
                                    <FaSolidIcon name={"plus"}/> Aggiungi ospite
                                </button>
                            </div>
                        }
                    </div>
                </Page>
                <ModalPortalHOC>
                    {
                        this.state.addGuestModalOpen &&
                        <GuestCRUDModal
                            onDismiss={this.toggleAddGuestModal}
                            idPrenotazione={this.props.idPrenotazione}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

OnlineCheckinModal.propTypes = {
    ospiti: PropTypes.array.isRequired,
    maxOspiti: PropTypes.number.isRequired,
    onDismiss: PropTypes.func,
    idPrenotazione: PropTypes.string,
}