import React, {Fragment} from "react";
import {SwipeablePanelGripBar} from "../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {SwipeablePanel} from "../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import Select from "react-select";
import {
    PROMOTIONS_SORT_INDICIZZAZIONE_DESC,
    PROMOTIONS_SORT_PERCENTUALE_DESC, PROMOTIONS_SORT_POSIZIONE_ASC,
    PROMOTIONS_SORT_POSIZIONE_DESC
} from "./PromotionsContants";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import NativeGeolocationProvider from "../../../providers/NativeGeolocationProvider";
import {PromotionsOrder} from "../../../img/illustrations/PromotionsOrder";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}


const POSITION_RECOVERY_TIMEOUT = 2000;


export default class PromotionsOrderPanel extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

            lat: null,
            lng: null,
            hasGeolocationPermission:false,
            positionRecoveryTimedout: false,

            sortType: this.props.sortType
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open) {
            this.setState({
                sortType: this.props.sortType
            });
        }
    }

    handleChange = e =>{
        this.setState({[e.target.name]: e.target.value})
        if(e.target.value === PROMOTIONS_SORT_POSIZIONE_ASC){
            this.checkGeolocationPerimission();
        }
    }


    //Verifico se ci sono i permessi di geolocalizzazione
    checkGeolocationPerimission =_=>{
        NativeGeolocationProvider.getPermission()
            .then(permission => {
                this.setState({
                    hasGeolocationPermission: permission,
                    sortType: permission ? PROMOTIONS_SORT_POSIZIONE_ASC : PROMOTIONS_SORT_INDICIZZAZIONE_DESC
                });
                if (permission) {
                    setTimeout(this.handlePositionRecoveryTimedout, POSITION_RECOVERY_TIMEOUT);
                    this.waitForPosition();
                } else {
                    NativeGeolocationProvider.askPermission();
                    NativeGeolocationProvider.waitForPermissionGrant().then(_ => {
                        setTimeout(this.handlePositionRecoveryTimedout, POSITION_RECOVERY_TIMEOUT);
                        this.setState({hasGeolocationPermission: true, sortType: PROMOTIONS_SORT_POSIZIONE_ASC});
                        this.waitForPosition();
                    });
                }
            });
    }


    //Se dopo la richiesta della posizione sono passati X secondi e non ho ancora una posizione, allora imposto il flag a true
    handlePositionRecoveryTimedout = _ => {
        this.setState({
            positionRecoveryTimedout: this.state.hasGeolocationPermission && (!this.state.lat || !this.state.lng)
        });
    }


    //Recupera le informazioni sulla geo localizzazione
    waitForPosition = _ => {
        NativeGeolocationProvider.waitForPosition('SEARCH')
            .then(location => {
                if (location) {
                    this.setState({
                        lat: location.lat,
                        lng: location.lng
                    })
                }
            });
    }

    handleConfirm = _ => this.props.onChange(this.state.sortType, this.state.lat, this.state.lng);

    isSwipeDisabled = _ => false;

    render() {
        return(
            <Fragment>
                {this.props.open && <div style={BackdropStyle}/>}
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={this.props.open}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"bg-main-z2 p-4"}>
                        <div className={"row w-100 text-center p-3 mb-2"}>
                            <PromotionsOrder className={"img-fluid w-50 mx-auto"} color={"#293069"}/>
                        </div>
                        {this.state.positionRecoveryTimedout && <div className={"w-100 text-center"}>STIAMO RECUPERANDO LA POSIZIONE...</div>}
                        <div className={"form-group"}>
                            <label className={"font-weight-bold"}>Ordina per</label>
                            <select name={"sortType"} value={this.state.sortType}
                                    onChange={this.handleChange}
                                    className={"form-control form-control"}>
                                <option value={PROMOTIONS_SORT_INDICIZZAZIONE_DESC}>Consigliati</option>
                                <option value={PROMOTIONS_SORT_PERCENTUALE_DESC}>Percentuale sconto</option>
                                <option value={PROMOTIONS_SORT_POSIZIONE_ASC}>Più vicini</option>
                            </select>
                        </div>
                        <button className={"btn btn-primary btn-block p-2 mt-4"} onClick={this.handleConfirm}>
                            <FaSolidIcon name={"check"}/> Conferma e aggiorna
                        </button>
                    </div>
                </SwipeablePanel>
            </Fragment>
        );
    }
}