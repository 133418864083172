import React from "react";

export default class PatternForPromotion extends React.Component {

    constructor(props) {
        super(props);
        this.style1 = {
            borderTopRightRadius:'10px',
            borderTopLeftRadius:'10px',
            position: 'absolute',
            height: '100%',
            width: '100%',
            opacity: '0.3',
            backgroundImage: 'linear-gradient(0deg, #e5e5f7 50%, #cccccc 50%)',
            backgroundSize: '20px 20px',
        }
        this.style2 = {
            borderTopRightRadius:'10px',
            borderTopLeftRadius:'10px',
            position: 'absolute',
            height: '100%',
            width: '100%',
            opacity:'0.3',
            backgroundImage: 'linear-gradient(45deg, #cccccc 50%, #e5e5f7 50%)',
            backgroundSize: '20px 20px'
        }
        this.style3 = {
            borderTopRightRadius:'10px',
            borderTopLeftRadius:'10px',
            position: 'absolute',
            height: '100%',
            width: '100%',
            opacity: 0.3,
            backgroundImage: 'linear-gradient(135deg, #cccccc 25%, transparent 25%), linear-gradient(225deg, #cccccc 25%, transparent 25%), linear-gradient(45deg, #cccccc 25%, transparent 25%), linear-gradient(315deg, #cccccc 25%, #e5e5f7 25%)',
            backgroundPosition:  '20px 0, 20px 0, 0 0, 0 0',
            backgroundSize: '40px 40px',
            backgroundRepeat: 'repeat'
        }
        this.style4 = {
            borderTopRightRadius:'10px',
            borderTopLeftRadius:'10px',
            position: 'absolute',
            height: '100%',
            width: '100%',
            opacity: '0.3',
            backgroundSize: '40px 40px',
            backgroundImage: 'repeating-linear-gradient(0deg, #cccccc, #cccccc 2px, #e5e5f7 2px, #e5e5f7)'
        }
        this.style5 = {
            borderTopRightRadius:'10px',
            borderTopLeftRadius:'10px',
            position: 'absolute',
            height: '100%',
            width: '100%',
            opacity: '0.3',
            background: 'linear-gradient(135deg, #cccccc55 25%, transparent 25%) -20px 0/ 40px 40px, linear-gradient(225deg, #cccccc 25%, transparent 25%) -20px 0/ 40px 40px, linear-gradient(315deg, #cccccc55 25%, transparent 25%) 0px 0/ 40px 40px, linear-gradient(45deg, #cccccc 25%, #e5e5f7 25%) 0px 0/ 40px 40px'
        }
    }

    render() {
        switch (this.props.pattern){
            case 1:
                return  <div style={this.style1}></div>;
            case 2:
                return  <div style={this.style2}></div>;
            case 3:
                return  <div style={this.style3}></div>;
            case 4:
                return  <div style={this.style4}></div>;
            case 5:
                return  <div style={this.style5}></div>;
            default:
                return  <div style={this.style1}></div>;
        }
    }

}