import React, {Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {swal, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {UPDATE_USER_BOOKS} from "../../../../const/AppEventsCostants";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import BookingManager from "../../../../components/Booking/BookingManager";
import NegozioAPI from "../../../../api/NegozioAPI";
import {NegozioOpenStore} from "../../../../stores/NegozioOpenStore";

export class ServiceBookResumeRebookView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rebookControllerOpen: false,
        }
    }

    handleRebookOpen = _ => {
        this.setState({rebookControllerOpen: true});
    }

    handleRebookDismiss = _ => {
        this.setState({rebookControllerOpen: false});
    }


    getBookInfoForRebook = _ => {
        const bookData = this.props.bookData;
        return {
            id_sede: bookData.id_sede,
            id_negozio: bookData.id_negozio,
            id_servizio: bookData.books.map(b => b.id_servizio)
        }
    }

    handleBookSuccess = _ => {
        FuxEvents.emit(UPDATE_USER_BOOKS);
        this.handleRebookDismiss();
    }

    render() {
        const now =  moment().format('YYYY-MM-DD HH:mm:ss')
        console.log(this.props.bookData.datetime_inizio, now,  this.props.bookData.datetime_inizio <= now);
        return (
            <Fragment>
                {
                    this.props.bookData.datetime_inizio <= now &&
                    <div className={"row mt-2"}>
                        <div className={"col"}>
                            <button className={"btn btn-warning btn-block"} onClick={this.handleRebookOpen}>
                                <FaSolidIcon name={"retweet"}/> Prenota di nuovo
                            </button>
                        </div>
                    </div>
                }

                {
                    (this.state.rebookControllerOpen && !!this.props.infoNegozio) &&
                    <BookingManager
                        bookInfo={this.getBookInfoForRebook()}
                        onBookSuccess={this.handleBookSuccess}
                        onBookDismiss={this.handleRebookDismiss}
                        promotionChosenInfo={null}
                        infoNegozio={this.props.infoNegozio}
                        skipServiceSelection={true}
                    />
                }

            </Fragment>
        )
    }

}

ServiceBookResumeRebookView.propTypes = {
    bookData: PropTypes.object,
}
