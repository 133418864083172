import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {ModuloServiziAPI} from "../../../../api/ModuloServizi/ModuloServiziAPI";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import {AppIconThumbnail} from "../../../../components/BaseComponents/AppIconThumbnail";
import AppConfig from "../../../../config/AppConfig";
import moment from "moment";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import {ServiceBookResumeEditView} from "./ServiceBookResumeEditView";
import {ServiceBookResumeRebookView} from "./ServiceBookResumeRebookView";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {OPEN_APP_REQUEST} from "../../../../const/AppEventsCostants";
import {NegozioOpenStore} from "../../../../stores/NegozioOpenStore";
import RecensioniModal from "../../../Recensioni/RecensioniModal";
import {RECENSIONI_TIPO_PRENOTAZIONE_SERVIZIO} from "../../../../const/RecensioniConstants";
import {showFeedbackAvailableForService} from "../../../Recensioni/RecensioniHelperFunctions";
import {formatCurrency} from "../../../../helpers/CurrencyHelpers";
import {ServiceBookResumePaymentsView} from "./ServiceBookResumePaymentsView";
import {Base64} from "../../../../helpers/Base64";
import {ServiceRecurringBookButton} from "./RecurringBook/ServiceRecurringBookButton";
import NegozioAPI from "../../../../api/NegozioAPI";

const MINUTES_PER_TICK = 5;

export class ServiceBookResumePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookData: null,
            infoNegozio: null,
        }
        this.isInsideShopApp = !!NegozioOpenStore.infoNegozio;
    }

    componentDidMount() {
        this.fetchInfoPrenotazione();
    }

    componentWillUnmount() {
        if (!this.isInsideShopApp) {
            NegozioOpenStore.id_negozio = null;
            NegozioOpenStore.infoNegozio = null;
        }
    }

    fetchInfoPrenotazione = _ => {
        swalLoading('Attendere prego...');
        ModuloServiziAPI.Books.getBookData(this.props.idPrenotazione)
            .then(bookData => {
                const p = NegozioOpenStore.infoNegozio ? Promise.resolve(NegozioOpenStore.infoNegozio) : NegozioAPI.getInfo(bookData.id_negozio);
                p.then(infoNegozio => {
                    this.setState({infoNegozio: infoNegozio, bookData: bookData});
                    NegozioOpenStore.id_negozio = infoNegozio.id_negozio;
                    NegozioOpenStore.infoNegozio = infoNegozio;
                    swal.safeClose();
                })
                    .catch(swalError);
            })
            .catch(swalError)
    }

    getMapLink() {
        var mapLink = 'https://maps.google.com/maps?q=';
        if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
            mapLink = 'http://maps.apple.com/?q=';
        }
        return `${mapLink}${this.state.bookData.indirizzo_sede},${this.state.bookData.nome_negozio}`;
    }

    handleOpenApp = _ => FuxEvents.emit(OPEN_APP_REQUEST, this.state.bookData.id_negozio);


    render() {
        if (!this.state.bookData) return '';

        const logoNegozioUrl = `${AppConfig.webServerUrl}/api/negozio/${this.state.bookData.id_negozio}/logoIcon/100`
        const momentDate = moment(this.state.bookData.data, 'DD-MM-YYYY');
        const monthDay = momentDate.format('DD');
        const monthName = momentDate.format('MMMM');
        const dayFullName = momentDate.format('dddd');
        const coupon = this.state.bookData.coupon;

        const hasPrice = !!this.state.bookData.books[0].prezzo;
        const totaleServizi = this.state.bookData.books.reduce((acc, b) => {
            return acc + (b.prezzo ? parseFloat(b.prezzo) : 0)
        }, 0.0);
        const importoScontoGlobale = parseFloat(this.state.bookData.importo_sconto_globale);

        let orarioMin = null;
        let orarioMax = null;
        this.state.bookData.books.map(book => {
            const updOrari = b => {
                if (!orarioMin || b.orario < orarioMin) orarioMin = b.orario;
                if (!orarioMax || b.orario_fine > orarioMax) orarioMax = b.orario_fine;
            }

            if (book.subservices) {
                book.subservices.map(updOrari);
            } else {
                updOrari(book);
            }

        });

        const isRecurringButtonEnabled = this.state.bookData.date_ymd < moment().format('YYYY-MM-DD') &&
            ['78', '113', '403'].indexOf(this.state.bookData.id_sede) !== -1;

        return (
            <Page
                title={"Dettagli"}
                active={true}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                onLeftIconClick={this.props.onDismiss}
            >
                <div className={"container pt-3"}>

                    {/** @MARK Heading */}
                    <div className={"text-center"}>
                        <AppIconThumbnail src={logoNegozioUrl} size={"50px"} className={"rounded-circle"}/>
                        <h4 className={"text-center text-on-bg-main"}>{this.state.bookData.nome_negozio}</h4>
                    </div>

                    <div className={"justify-content-center d-flex mt-2 mb-2"}>
                        <a href={"tel:" + this.state.bookData.telefono_sede}
                           className={"btn btn-sm btn-outline-primary"}>
                            <FaSolidIcon name={"phone"}/> Chiama ora
                        </a>
                        &nbsp;
                        <a href={this.getMapLink()} className={"btn btn-sm btn-outline-primary"}>
                            <FaSolidIcon name={"map-marker-alt"}/> Portami qui
                        </a>
                        &nbsp;
                        {
                            this.props.showOpenApp && !NegozioOpenStore.id_negozio &&
                            <button className={"btn btn-sm btn-outline-primary"} onClick={this.handleOpenApp}>
                                Apri l'app <FaSolidIcon name={"chevron-right"}/>
                            </button>
                        }
                    </div>

                    <p className={"text-center text-on-bg-main"}>
                        <span className={"text-capitalize"}>{dayFullName} {monthDay} {monthName}</span> <br/>
                        Dalle <b>{orarioMin}</b> alle <b>{orarioMax}</b>
                    </p>
                    <hr/>

                    {
                        showFeedbackAvailableForService(this.state.bookData) &&
                        <div className={"mb-2"}>
                            <RecensioniModal
                                id_operazione={this.state.bookData.id_prenotazione}
                                tipo_operazione={RECENSIONI_TIPO_PRENOTAZIONE_SERVIZIO}
                            />
                        </div>
                    }

                    {/** @MARK Elenco dei servizi */}
                    <div className={"mt-4"}>
                        <b className={"text-on-bg-main"}>Cosa hai prenotato</b>
                        {
                            this.state.bookData.books.map((b, i) => {
                                return (
                                    <div className={"mt-2"} key={i}>
                                        <span className={"text-on-bg-main"}>
                                            {b.nome_servizio || ''}&nbsp;
                                            {b.nome_dipendente ? `con ${b.nome_dipendente}` : ''}&nbsp;
                                            {b.prezzo > 0 ? formatCurrency(b.prezzo, "EUR", "it-IT") : ''}&nbsp;
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/** @MARK Coupon - Promozioni */}
                    {
                        !!coupon &&
                        <div>
                            {coupon.id_promozione ?
                                <div className={"p-3 bg-soft-success shadow-sm"}>
                                    <span>
                                        Hai utilizzato una <b>promozione</b> per questa prenotazione! &#x1F389; <br/>
                                        Il prezzo viene <b>scontato del {this.state.bookData.coupon.valore_sconto}%</b> <br/>
                                        {
                                            this.state.bookData.coupon.min_spesa > 0 &&
                                            <span>
                                               Su una spesa minima di €{this.state.bookData.coupon.min_spesa}
                                            </span>
                                        }
                                    </span>
                                </div>
                                :
                                <div className={"mt-2 alert alert-success"}>
                                    <b>Sconti e coupon</b>
                                    <p>
                                        Per questa prenotazione hai usato il coupon con codice <b
                                        className={"text-uppercase"}>{coupon.codice}</b> che ti da
                                        diritto ad uno
                                        sconto {coupon.tipo_sconto === 'euro' ? `di massimo €${coupon.valore_sconto}` : `del ${coupon.valore_sconto}%`}.
                                        <br/>
                                        {
                                            coupon.min_spesa > 0 &&
                                            <span>Questo codice ha un minimo di spesa di €{coupon.min_spesa}</span>
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                    }

                    {
                        (hasPrice && totaleServizi > 0) &&
                        <div className={"card mt-2 border-0 shadow-sm"}>
                            <div className={"card-body"}>
                                {
                                    importoScontoGlobale > 0 &&
                                    <Fragment>
                                        <div className={"d-flex align-items-center justify-content-between"}>
                                            <b>Subtotale</b>
                                            <span>{formatCurrency(totaleServizi, 'EUR', 'it-IT')}</span>
                                        </div>
                                        <div className={"d-flex align-items-center justify-content-between"}>
                                            <b>Sconto/Promo</b>
                                            <span>{formatCurrency(importoScontoGlobale, 'EUR', 'it-IT')}</span>
                                        </div>
                                        <hr/>
                                    </Fragment>
                                }

                                <div className={"d-flex align-items-center justify-content-between"}>
                                    <b>Totale</b>
                                    <span>{formatCurrency(totaleServizi - importoScontoGlobale, 'EUR', 'it-IT')}</span>
                                </div>

                                {/** @MARK Pagamenti online */}
                                {
                                    !!this.state.bookData.pagamenti?.length &&
                                    <div className={"mt-2"}>
                                        <ServiceBookResumePaymentsView pagamenti={this.state.bookData.pagamenti}/>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {/** @MARK Card pagamenti online in caso di totale/subtotale non mostrati */}
                    {
                        (!hasPrice && !!this.state.bookData.pagamenti?.length) &&
                        <div className={"card border-0 shadow-sm"}>
                            <div className={"card-body"}>
                                <ServiceBookResumePaymentsView pagamenti={this.state.bookData.pagamenti}/>
                            </div>
                        </div>
                    }

                    {/** @MARK Note utente */}
                    {
                        !!this.state.bookData.note_utente &&
                        <div className={"mt-2"}>
                            <span className={"text-muted"}>Le tue note</span>
                            <p>
                                <SafeHtmlContainer html={this.state.bookData.note_utente}/>
                            </p>
                        </div>
                    }

                    {
                        !!this.state.bookData.online_meeting_url &&
                        <a className={'btn btn-block btn-success mt-2'} target={"_blank"}
                           href={`https://proinn.web.app/bookizon/redirect.html?url=${Base64.encode(this.state.bookData.online_meeting_url)}`}>
                            <FaSolidIcon name={"video-camera"}/> Apri video chat
                        </a>
                    }

                    {
                        !!this.state.infoNegozio &&
                        <Fragment>
                            {/** @MARK Modifica e Cancellazione */}
                            <ServiceBookResumeEditView
                                bookData={this.state.bookData}
                                onChange={this.props.onDismiss}
                                onDelete={this.props.onDismiss}
                                onUpdate={this.fetchInfoPrenotazione}
                                infoNegozio={this.state.infoNegozio}
                            />

                            {/** @MARK Ri-prenota */}
                            <ServiceBookResumeRebookView
                                bookData={this.state.bookData}
                                infoNegozio={this.state.infoNegozio}
                            />
                        </Fragment>
                    }


                    {
                        isRecurringButtonEnabled &&
                        <ServiceRecurringBookButton
                            bookData={this.state.bookData}
                            onBookSuccess={this.props.onDismiss}
                        />
                    }
                </div>
            </Page>
        )
    }

}

ServiceBookResumePage.propTypes = {
    idPrenotazione: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    showOpenApp: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired
}
