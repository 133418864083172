import React from "react";
import PropTypes from "prop-types";
import {RecordingDurationLabel} from "./RecordingDurationLabel";
import {FaSolidIcon} from "../../../FontAwesomeIcons";
import AudioRecorder from 'audio-recorder-polyfill'
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder'
import {AudioPlayer} from "../Messages/AudioMessage/AudioPlayer/AudioPlayer";

AudioRecorder.encoder = mpegEncoder
AudioRecorder.prototype.mimeType = 'audio/mpeg'
window.MediaRecorder = AudioRecorder

export class AudioRecordingButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            temporaryUrl: '',
            hasPermissions: null,
            recorder: null,
            paused: false,
        }
        this.chunks = [];
        this.mimeType = 'audio/mpeg';
        this.roundedButtonStyle = {width: 38, height: 38};
    }

    handleStartRecording = () => {
        if (!this.props.isRecording) {
            navigator.mediaDevices.getUserMedia({audio: true})
                .then((stream) => {
                    const mediaRecorder = new MediaRecorder(stream);

                    mediaRecorder.addEventListener('start', _ => {
                        this.chunks = [];
                    })

                    mediaRecorder.addEventListener('dataavailable', (e) => {
                        //this.mimeType = e.data.type
                        this.chunks.push(e.data);
                    });

                    mediaRecorder.addEventListener('stop', _ => {
                        stream.getTracks().forEach(track => {
                            track.stop();
                            track.enabled = false
                        })
                    });

                    this.setState({
                        hasPermissions: true,
                        recorder: mediaRecorder
                    }, _ => {
                        console.log('started');
                        this.state.recorder.start(250);
                        this.props.onRecordingChange(true);
                    })
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        hasPermissions: false,
                        recorder: null
                    })
                })
        }
    }

    handleStopRecording = () => {
        if (this.props.isRecording) {
            this.props.onRecordingChange(false);
            this.setState({paused: false, temporaryUrl: ''}, _ => this.state.recorder.stop());
        }
    }


    handlePauseRecording = _ => {
        if (this.state.recorder && this.props.isRecording) {
            const url = URL.createObjectURL(new Blob(this.chunks, {type: this.mimeType}));
            console.log(url);
            this.setState({paused: true, temporaryUrl: url}, _ => {
                this.state.recorder.pause();
            });
        }
    }

    handleResumeRecording = _ => {
        if (this.state.recorder && this.state.paused) {
            this.setState({paused: false, temporaryUrl: ''}, _ => this.state.recorder.resume());
        }
    }

    handleSendAudio = _ => {
        this.handleStopRecording();
        const blob = new Blob(this.chunks, {type: this.mimeType});
        const audioFile = new File([blob], `vocal-message.${this.mimeType.split('/').pop()}`, {type: this.mimeType});
        this.props.onAudioFile(audioFile);
    }

    render() {
        return (
            <React.Fragment>

                {/* No permessi */}
                {
                    (!this.props.isRecording && this.state.hasPermissions === false) &&
                    <button
                        disabled={true}
                        className={"btn btn-link text-muted rounded-circle d-flex align-items-center justify-content-center ml-2"}>
                        <FaSolidIcon name={"microphone-slash"}/>
                    </button>
                }

                {/* Permessi dati */}
                {
                    !this.props.isRecording &&
                    <button
                        className={"btn btn-link text-primary rounded-circle d-flex align-items-center justify-content-center ml-2"}
                        onClick={this.handleStartRecording}>
                        <FaSolidIcon name={"microphone"}/>
                    </button>
                }

                {
                    this.props.isRecording &&
                    <React.Fragment>
                        <div className={"d-flex align-items-center justify-content-end w-100"}>
                            <button className={"btn btn-link text-muted"} onClick={this.handleStopRecording}>
                                <FaSolidIcon name={"trash"}/>
                            </button>
                            <div className={"px-5"}>
                                <div className={!!this.state.temporaryUrl && 'd-none'}>
                                    <RecordingDurationLabel isPaused={this.state.paused}/>
                                </div>
                                {
                                    !!this.state.temporaryUrl &&
                                    <AudioPlayer src={this.state.temporaryUrl} type={"audio/mpeg"}/>
                                }
                            </div>
                            {
                                this.state.paused ?
                                    <button
                                        onClick={this.handleResumeRecording}
                                        style={this.roundedButtonStyle}
                                        className={"btn btn-danger rounded-circle d-flex align-items-center justify-content-center ml-2"}>
                                        <FaSolidIcon name={"microphone"}/>
                                    </button>
                                    :
                                    <button
                                        onClick={this.handlePauseRecording}
                                        style={this.roundedButtonStyle}
                                        className={"btn btn-danger rounded-circle d-flex align-items-center justify-content-center ml-2"}>
                                        <FaSolidIcon name={"pause"}/>
                                    </button>
                            }

                            <button
                                onClick={this.handleSendAudio}
                                style={this.roundedButtonStyle}
                                className={"btn btn-success rounded-circle d-flex align-items-center justify-content-center ml-2"}>
                                <FaSolidIcon name={"paper-plane"}/>
                            </button>

                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }

}


AudioRecordingButton.propTypes = {
    isRecording: PropTypes.bool,
    recordingStartTime: PropTypes.number,
    onRecordingChange: PropTypes.func,
    onAudioFile: PropTypes.func
}



