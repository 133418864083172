import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {swalError} from "../../../helpers/SweetAlertWrapper";
import {ChatRoomMessage} from "./Messages/ChatRoomMessage";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import moment from "moment";
import {CHAT_UPDATE_MESSAGES} from "../../../const/AppEventsCostants";
import {AttachmentsAddButton} from "./Attachments/AttachmentsAddButton";
import {AudioRecordingButton} from "./AudioRecording/AudioRecordingButton";
import {AttachmentsContainer} from "./Attachments/AttachmentsContainer";

const WrapperStyle = {
    height: 'calc(100vh - 95px)',
    width: "100%",
    backgroundColor: "#dadde1",
    paddingBottom: 65
}

const MESSAGE_FETCH_LIMIT = 20;

export class ChatRoomView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            text: '',
            messages: [],
            attachments: [],
            attachmentIsAudio: false,
            afterCursor: null,
            beforeCursor: null,
            sendingMessage: false,
            isRecording: false,
        }

        this.inputRef = React.createRef();
        this.scrollPaneRef = React.createRef();
    }

    componentDidMount() {
        FuxEvents.on(this.props.fetchMessageEventName, this.handleExternalNewMessage);
        FuxEvents.on(CHAT_UPDATE_MESSAGES, this.handleUpdateMessages);
        this.fetchInitialMessages(MESSAGE_FETCH_LIMIT);
    }

    componentWillUnmount() {
        FuxEvents.off(this.props.fetchMessageEventName, this.handleExternalNewMessage);
        FuxEvents.off(CHAT_UPDATE_MESSAGES, this.handleUpdateMessages);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Riposiziona lo scroll del div alla posizione originale prima di aver preso messaggi più vecchi
        if (this.oldScroll !== null && this.curScrollPos !== null) {
            this.restoreScrollPoint();
        }
    }

    handleUpdateMessages = id_chat_room => {
        if (id_chat_room === this.props.idRoom) {
            this.fetchInitialMessages(this.state.messages.length);
        }
    }

    handleInputChange = ({target}) => {
        if (this.state.sendingMessage) return;
        this.setState({[target.name]: target.value});
    }

    handleAttachmentsChange = attachments => this.setState({attachments: attachments});

    fetchInitialMessages = messagesNum => {
        this.props.getMessageAPI(this.props.idRoom, messagesNum, null)
            .then(data => {
                this.setState({
                    messages: data.messages.reverse(),
                    afterCursor: data.cursors.after,
                    beforeCursor: data.cursors.before,
                }, this.scrollToBottom);
            });
    }

    fetchNewMessages = (silent, onStateUpdate) => {
        return this.props.getMessageAPI(this.props.idRoom, MESSAGE_FETCH_LIMIT, this.state.afterCursor)
            .then(data => {
                this.setState({
                    messages: [...this.state.messages, ...data.messages.reverse()],
                    afterCursor: data.cursors.after, //Aggiorno solo il cursore after
                }, _ => {
                    if (!silent) this.scrollToBottom();
                    if (onStateUpdate) onStateUpdate();
                });
            });
    }

    fetchOldMessages = _ => {
        return this.props.getMessageAPI(this.props.idRoom, MESSAGE_FETCH_LIMIT, this.state.beforeCursor)
            .then(data => {
                this.saveScrollPoint();
                this.setState({
                    messages: [...data.messages.reverse(), ...this.state.messages],
                    beforeCursor: data.cursors.before, //Aggiorno solo il cursore after
                });
            });
    }

    handleInputFormSubmit = e => {
        e.preventDefault();
        this.sendMessage();
    }

    sendMessage = _ => {
        this.setState({
            sendingMessage: true
        });
        this.inputRef.current.focus();
        this.props.sendMessageAPI(this.props.idRoom, this.state.text, this.state.attachments)
            .then(({id_messaggio, otp}) => {
                this.props.sendNotificationAPI(this.props.idRoom, id_messaggio, otp);
                this.fetchNewMessages()
                    .then(_ => {
                        this.setState({
                            sendingMessage: false,
                            text: '',
                            attachments: [],
                            attachmentIsAudio: false
                        });
                    });
            })
            .catch(message => {
                swalError(message);
                this.setState({sendingMessage: false, attachments: [], attachmentIsAudio: false});
            });

    }

    handleExternalNewMessage = id_room => {
        if (id_room === this.props.idRoom) {
            this.fetchNewMessages(false, _ => {
                const messageList = this.state.messages.slice().map(m => {
                    m.letto = "1";
                    return m;
                });
                this.setState({messages: messageList});
            });
            this.props.setReadAPI(this.props.idRoom);
        }
    }

    /** @MARK: Scroll utilities */

    scrollToBottom = _ => {
        if (this.scrollPaneRef.current) {
            this.scrollPaneRef.current.scrollTop = this.scrollPaneRef.current.scrollHeight;
        }
    }

    saveScrollPoint = _ => {
        if (this.scrollPaneRef.current) {
            this.curScrollPos = this.scrollPaneRef.current.scrollTop;
            this.oldScroll = this.scrollPaneRef.current.scrollHeight - this.scrollPaneRef.current.clientHeight;
        }
    }

    restoreScrollPoint = _ => {
        if (this.oldScroll !== null && this.curScrollPos !== null) {
            const newScroll = this.scrollPaneRef.current.scrollHeight - this.scrollPaneRef.current.clientHeight;
            this.scrollPaneRef.current.scrollTop = this.curScrollPos + (newScroll - this.oldScroll);
            this.oldScroll = null;
            this.curScrollPos = null;
        }
    }

    /** @MARK: Recording management */

    handleRecordingChange = isRecording => this.setState({
        isRecording: isRecording,
        recordingStartTime: isRecording ? (new Date()).getTime() : null
    });

    handleRecordingAudioFile = audio => {
        this.setState({attachments: [audio], attachmentIsAudio: true}, _ => this.sendMessage());
    }


    render() {
        return (
            <div ref={this.scrollPaneRef} className={"position-relative pt-4 overflow-auto"} style={WrapperStyle}>
                <div className={"px-3"}>
                    {
                        !this.state.messages &&
                        <div className={"w-75 mx-auto bg-white small p-2 rounded shadow-sm"}>
                            Non ci sono ancora messaggi in questa chat
                        </div>
                    }
                    {
                        !!this.state.beforeCursor &&
                        <div className={"text-center"} onClick={this.fetchOldMessages}>
                            <button className={"btn btn-sm btn-link text-primary"}>
                                Carica messaggi precedenti
                            </button>
                        </div>
                    }
                    {
                        this.state.messages.map((m, i) => {
                            const prevMessage = i === 0 ? null : this.state.messages[i - 1];
                            const nextMessage = i === this.state.messages.length - 1 ? null : this.state.messages[i + 1];
                            const isLast = !nextMessage || nextMessage.id_mittente !== m.id_mittente;

                            const showOwnDate = !prevMessage || moment(prevMessage.data_creazione).format('DD-MM-YYYY') !== moment(m.data_creazione).format('DD-MM-YYYY');

                            return <ChatRoomMessage
                                key={m.id_messaggio}
                                messageData={m}
                                idSelf={this.props.idSelf}
                                isLast={isLast}
                                showOwnDate={showOwnDate}
                                getMediaContentUrlAPI={this.props.getMediaContentUrlAPI}
                            />
                        })
                    }
                </div>
                <div className={"bg-white shadow-sm position-fixed fixed-bottom p-2 border-top"}>
                    {
                        (this.state.attachments.length > 0 && !this.state.attachmentIsAudio) &&
                        <AttachmentsContainer
                            attachments={this.state.attachments}
                            onChange={this.handleAttachmentsChange}
                            disabled={this.state.sendingMessage}
                        />
                    }
                    <div className={"d-flex align-items-center"}>
                        <form onSubmit={this.handleInputFormSubmit}
                              className={this.state.isRecording ? 'd-none' : 'flex-grow-1'}>
                            <div className={"input-group"}>
                                <div className={"input-group-prepend"}>
                                    <AttachmentsAddButton
                                        attachments={this.state.attachments}
                                        onChange={this.handleAttachmentsChange}
                                        disabled={this.state.sendingMessage}
                                    />
                                </div>
                                <input
                                    ref={this.inputRef}
                                    className={"form-control rounded-pill"}
                                    type={"text"}
                                    name={"text"}
                                    value={this.state.text}
                                    onChange={this.handleInputChange}
                                />
                                <div className={"input-group-append"}>
                                    <button style={{width: 38, height: 38}}
                                            className={"btn btn-primary rounded-circle d-flex align-items-center justify-content-center ml-2"}
                                            disabled={(!this.state.text && this.state.attachments.length === 0) || this.state.sendingMessage}
                                            onClick={this.sendMessage}>
                                        {
                                            this.state.sendingMessage ?
                                                <FaSolidIcon name={"spin"} className={"fa-spinner"}/>
                                                :
                                                <FaSolidIcon name={"paper-plane"}/>
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                        {/*<AudioRecordingButton
                            onRecordingChange={this.handleRecordingChange}
                            isRecording={this.state.isRecording}
                            onAudioFile={this.handleRecordingAudioFile}
                        />*/}
                    </div>
                </div>
            </div>
        )
    }

}

ChatRoomView.propTypes = {
    idRoom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    idSelf: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    getMessageAPI: PropTypes.func.isRequired,
    getMediaContentUrlAPI: PropTypes.func.isRequired,
    sendMessageAPI: PropTypes.func.isRequired,
    setReadAPI: PropTypes.func.isRequired,
    sendNotificationAPI: PropTypes.func.isRequired,
    fetchMessageEventName: PropTypes.string.isRequired
}