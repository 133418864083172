import React from "react";
import PropTypes from "prop-types";
import {FileViewItem} from "./FileViewItem";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import {FilePropType} from "./PropTypes/FilePropType";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {UploadPanel} from "./UploadPanel/UploadPanel";
import AuthAPI from "../../api/AuthAPI";
import emptyBox from "../../img/illustrations/empty.svg";

export class FileManagerView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadPanelOpen: false
        }
    }

    handleDeleteFile = file => {
        swalConfirm("Sei sicuro di voler eliminare questo file? L'azione è irreversibile")
            .then(_ => {
                swalLoading('Attendere prego...');
                FuxHTTP.post(this.props.deleteUrl, {id_file: file.id_file, token: AuthAPI.getUserAccessToken()}, FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE)
                    .then(m => {
                        swalSuccess(m);
                        this.props.onFileUpdateRequest();
                    }).catch(swalError);
            });
    }

    toggleUploadPanel = _ => {
        this.setState({uploadPanelOpen: !this.state.uploadPanelOpen});
    }

    render() {
        const hasFiles = this.props.files && this.props.files.length > 0;
        return (
            <React.Fragment>
                <div className={"d-flex align-items-center justify-content-between mb-3"}>
                    <h4 className={"font-weight-bold mb-0"}>{this.props.title || 'File caricati'}</h4>
                    {
                        !!this.props.uploadUrl && <button className={"btn btn-primary"} onClick={this.toggleUploadPanel}>
                            <FaSolidIcon name={"upload"}/> Carica
                        </button>
                    }
                </div>
                {
                    hasFiles ?
                        <div className={"list-group"}>
                            {
                                this.props.files.map(f => {
                                    return <div className={"list-group-item"} key={f.id_file}>
                                        <FileViewItem file={f} onDelete={this.handleDeleteFile}/>
                                    </div>
                                })
                            }
                        </div>
                        :
                        <div>
                            <div className={"text-center my-5 px-3"}>
                                <img src={emptyBox} className={"img-fluid w-75 mx-auto mb-3"}
                                     alt={"Nessuna immagine disponibile"}/>
                                <p className={"text-muted text-center lead"}>
                                    Non hai ancora caricato alcun file, fallo subito!
                                </p>
                            </div>
                        </div>
                }

                <UploadPanel
                    onDismiss={this.toggleUploadPanel}
                    open={this.state.uploadPanelOpen}
                    uploadUrl={this.props.uploadUrl}
                    uploadParams={this.props.uploadParams}
                    onFileUpdateRequest={this.props.onFileUpdateRequest}
                />

            </React.Fragment>
        )
    }

}

FileManagerView.propTypes = {
    files: PropTypes.arrayOf(FilePropType),
    uploadUrl: PropTypes.string,
    uploadParams: PropTypes.object,
    deleteUrl: PropTypes.string,
    onFileUpdateRequest: PropTypes.func,
    title: PropTypes.string,
}
