import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";
import {func} from "prop-types";

var DashboardUtentiAPI = {
    search: function (queryString, cancelToken) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard-utenti/search`), {query: queryString, token: AuthAPI.currentUser.accessToken},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE,
            {cancelToken: cancelToken}
        );
    },
    createGuest: function(nome){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard-utenti/create-guest`), {nome: nome, token: AuthAPI.currentUser.accessToken},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    createCRM: function(userData){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard-utenti/create-crm`), {...userData, token: AuthAPI.currentUser.accessToken},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default DashboardUtentiAPI;
