import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {BookizonAppManager} from "../index";

export class DevModeView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleInvalidateFcmToken = _ => BookizonAppManager.invalidateFcmToken();
    handleRetrieveFcmToken = _ => BookizonAppManager.retrieveFcmToken();


    render() {
        return (
            <div className={"container"}>
                <div className={"card shadow-sm border-0 my-3"}>
                    <div className={"card-body"}>
                        <h4 className={"card-title text-magenta"}>
                            Developer mode CP
                        </h4>
                        <button className={"btn btn-primary"} onClick={this.handleInvalidateFcmToken}>
                            Invalidate FCM Token
                        </button>
                        <button className={"btn btn-primary"} onClick={this.handleRetrieveFcmToken}>
                            Retrieve FCM Token
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

DevModeView.propTypes = {}