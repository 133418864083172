import React from "react";
import PropTypes from "prop-types";
import {ConsentSolutionAPI} from "../../api/ConsentSolution/ConsentSolutionAPI";
import {SwipeablePanel} from "../BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {AppListIcon} from "../../img/illustrations/AppListIcon";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import i18n from "i18next";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {SwipeablePanelBackdrop} from "../BaseComponents/SwipeablePanel/SwipeablePanelBackdrop";
import {CheckboxSwitch} from "../Bootstrap4/CustomControl/CheckboxSwitch";
import {swal, swalError, swalLoading} from "../../helpers/SweetAlertWrapper";
import {Base64} from "../../helpers/Base64";

export class ConsentSolutionController extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            changes: null,
            checkboxes: {}
        }
        this.iconStyle = {
            width: 70,
            height: 70,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.user && !this.state.changes) this.fetchChanges();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.user && this.props.user) this.fetchChanges();
    }

    fetchChanges = _ => {
        if (!this.props.user.consent_solution) return;
        const ids = this.props.user.consent_solution.change_ids;
        if (!ids || !ids.length) return;
        swalLoading('Attendere prego...');
        ConsentSolutionAPI.Changes.getChanges(ids).then(changes => {
            this.setState({
                changes: changes,
                checkboxes: changes.reduce((acc, c) => {
                    acc[c.legal_notice] = false;
                    return acc;
                }, {})
            });
            swal.safeClose();
        });
    }

    handleCheckboxesChange = notice => e => {
        this.setState({
            checkboxes: {...this.state.checkboxes, [e.target.name]: e.target.checked}
        })
    }

    handleAcceptAll = _ => this.setState({
        checkboxes: this.state.changes.reduce((acc, c) => {
            acc[c.legal_notice] = true;
            return acc;
        }, {})
    }, this.handleSubmit)

    isSwipeDisabled = _ => true;

    handleSubmit = _ => {
        const hasUnacceptedChanges = Object.keys(this.state.checkboxes).some(n => !this.state.checkboxes[n]);
        if (hasUnacceptedChanges) return swalError("Devi accettare tutti i cambiamenti per poter proseguire");
        swalLoading("Attendere prego...");
        ConsentSolutionAPI.Changes.submit(this.formRef.current.innerHTML, this.state.changes.map(c => c.change_id))
            .then(_ => {
                window.location.reload();
            }).catch(swalError);
    }

    getBrowserInAppUrl = url => `https://proinn.web.app/bookizon/redirect.html?url=${Base64.encode(url)}`;

    render() {
        if (!this.state.changes || !this.state.changes.length) return '';

        const hasUnacceptedChanges = Object.keys(this.state.checkboxes).some(n => !this.state.checkboxes[n]);
        return (
            <React.Fragment>
                <SwipeablePanelBackdrop/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"bg-main-z2 p-3"} ref={this.formRef}>
                        <div style={this.iconStyle} className="bg-soft-primary text-primary mb-2 mx-auto">
                            <FaSolidIcon name={"lock"} size={2}/>
                        </div>
                        <h5 className="text-center text-primary mb-3">
                            Abbiamo aggiornato le nostre policy
                        </h5>
                        <p>
                            Ti informiamo che abbiamo rafforzato la protezione dei tuoi dati aggiornando la nostra
                            privacy policy e i
                            termini e condizioni d'uso su Bookizon. La tua privacy è fondamentale per noi, e vogliamo
                            rassicurarti che
                            non venderemo i tuoi dati a terzi per scopi pubblicitari.
                        </p>
                        <p>
                            Per saperne di più, leggi la <a
                            href={this.getBrowserInAppUrl("https://bookizon.it/documents/privacy-policy")}>nuova
                            privacy policy</a> e i <a
                            href={this.getBrowserInAppUrl("https://bookizon.it/documents/termini-e-condizioni")}>nuovi
                            termini e condizioni</a>.<br/>
                            Per continuare ad usare l'app è necessario che tu accetti i cambiamenti che abbiamo
                            apportato.
                        </p>
                        <div className={"mb-3"}>
                            {
                                this.state.changes.map(c => {
                                    return <div className={"font-weight-bold text-primary mb-3"}>
                                        <CheckboxSwitch
                                            label={c.public_description}
                                            name={c.legal_notice}
                                            checked={this.state.checkboxes[c.legal_notice]}
                                            onChange={this.handleCheckboxesChange(c.legal_notice)}
                                        />
                                    </div>
                                })
                            }
                        </div>
                        {
                            hasUnacceptedChanges && <div className={"mb-4 text-center"}>
                                <button className={"btn btn-link px-0"} onClick={this.handleAcceptAll}>
                                    <FaSolidIcon name={"check"}/> Accetta tutto e prosegui
                                </button>
                            </div>
                        }
                        <div className={"text-center mt-3"}>
                            <button className={"btn btn-lg btn-primary"} disabled={hasUnacceptedChanges}
                                    onClick={this.handleSubmit}>
                                Invia e prosegui <FaSolidIcon name={"chevron-right"}/>
                            </button>
                        </div>
                    </div>
                </SwipeablePanel>
            </React.Fragment>
        )
    }

}

ConsentSolutionController.propTypes = {
    user: PropTypes.shape({
        id_utente: PropTypes.any,
        consent_solution: PropTypes.shape({
            change_ids: PropTypes.arrayOf(PropTypes.any)
        })
    })
}
