import React, {Fragment} from "react";
import {BookizonAppManager} from "../index";


export class NativeBrowserAnchorHOC extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = null;
    }

    getWrapperRef = node => {
        if (node) {
            this.wrapperRef = node;
            const anchors = this.wrapperRef.querySelectorAll('a');
            [...anchors].forEach(a => {
                a.addEventListener('click', this.handleAnchorClick);
            })
        }
    }

    handleAnchorClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const href = e.target.getAttribute('href');
        if (href) {
            BookizonAppManager.openInAppBrowser(href);
        }
    }

    render() {
        return (
            <div ref={this.getWrapperRef}>
                {this.props.children}
            </div>
        )
    }

}