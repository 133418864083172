import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {PluralString} from "../../../../helpers/UIUtility";
import {formatNumber} from "../../../../helpers/CurrencyHelpers";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import moment from "moment";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {getCompressedImageUrl} from "../../../../helpers/ImageUtility";
import {ProductImageSize} from "../../../../const/ImageSizeCostants";

export class CameraView extends React.Component {
    constructor(props) {
        super(props);
        this.imageStyle = {
            height: '20vh',
            width: '100%',
            backgroundImage: `url('${getCompressedImageUrl(this.props.bookData.camera.immagini?.[0], ProductImageSize.preview.w, ProductImageSize.preview.h)}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
        }
        this.textBackdropStyle = {
            position: 'absolute',
            zIndex: 0,
            width: '100%',
            height: 50,
            background: "linear-gradient(180deg, rgba(30,30,30,0.65) 50%, transparent 100%)"
        }
        this.cardStyle = {
            border: 0,
            borderRadius: '.75rem',
            overflow: 'hidden'
        }
    }

    getPrezzoTotale = _ => {
        return parseFloat(this.props.bookData.prezzo);
    }

    getDurataSoggiorno = _ => moment(this.props.bookData.data_checkout, 'YYYY-MM-DD').diff(moment(this.props.bookData.data_checkin, 'YYYY-MM-DD'), 'days')

    getNumOspiti = _ => this.props.bookData.nadulti + this.props.bookData.nbambini;

    render() {
        return (
            <div className={"card mb-3 shadow-sm"} style={this.cardStyle} onClick={this.handleClick}>
                <div style={this.imageStyle} className={"card-img"}/>
                <div style={this.textBackdropStyle}/>
                <div className={"card-img-overlay p-2"}>
                    <h6 className={"card-title text-white mb-0"}>
                        <SafeHtmlContainer html={this.props.bookData.camera.nome}/>
                    </h6>
                </div>
                <div className={"card-footer bg-main-z2"}>
                    <div>
                        <b className={"text-success"}>€{formatNumber(this.getPrezzoTotale())}</b>&nbsp;
                        <small>
                            <FaSolidIcon name={"bed"}/> {this.getDurataSoggiorno()}
                            &nbsp;<PluralString n={this.getDurataSoggiorno()} more={"notti"} single={"notte"}/>
                            &nbsp;per <FaSolidIcon name={"user-friends"}/> {this.getNumOspiti()}
                            &nbsp;<PluralString n={this.getNumOspiti()} more={"persone"} single={"persona"}/>
                        </small>
                    </div>
                    <div className={"small text-muted"}>
                        Tariffa <b><SafeHtmlContainer html={this.props.bookData.tariffa.nome_pubblico}/></b>
                    </div>
                </div>

            </div>
        )
    }

}

CameraView.propTypes = {
    bookData: PropTypes.object.isRequired
}