import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FuxCarousel} from "../../../../components/BaseComponents/FuxCarousel/FuxCarousel";
import {getCompressedImageUrl} from "../../../../helpers/ImageUtility";
import {ProductImageSize} from "../../../../const/ImageSizeCostants";
import styled from "styled-components";
import ImageZoomPanel from "../../../../components/BaseComponents/ImageZoomPanel";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import "../../../../css/ribbon.css"
import {CollapsibleHTMLTextView} from "../../../../hoc/CollapsibleHTMLTextView";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {swalConfirm, swalError, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import {FidelityCardAPI} from "../../../../api/FidelityCard/FidelityCardAPI";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {FIDELITY_CARD_PRIZE_REDEEM_EVT} from "../../../../const/AppEventsCostants";

export class FidelityCardPremiItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zoomedImage: '',
            fullDescription: false
        }
    }

    getImmagini = _ => {
        let immagini = [];
        if (this.props.premioData.immagine) immagini.push(this.props.premioData.immagine);
        immagini = [...immagini, ...this.props.premioData.immagini]
        return immagini.map(url => getCompressedImageUrl(url, ProductImageSize.preview.w, ProductImageSize.preview.h));
    }

    handleImageZoom = originalUrl => _ => {
        this.setState({zoomedImage: originalUrl});
    }
    toggleZoomedImage = _ => this.setState({zoomedImage: ''})

    toggleFullDescription = _ => this.setState({fullDescription: !this.state.fullDescription});

    handleRedeem = _ => {
        swalConfirm(`Sei sicuro di voler riscattare questo premio? Verranno scaricati ${this.props.premioData.valore} punti dalla tua fidelity card`)
            .then(_ => {
                FidelityCardAPI.User.bookPrize(this.props.premioData.id_premio)
                    .then(swalSuccess)
                    .then(_ => FuxEvents.emit(FIDELITY_CARD_PRIZE_REDEEM_EVT))
                    .catch(swalError)
            });
    }

    render() {
        const premio = this.props.premioData;
        return (
            <Fragment>
                <div className={"card mb-4 shadow-sm border-0 overflow-hidden"}>
                    <div className={"img-card-top"}>
                        <FuxCarousel
                            className={"__disable-swipe border-0"}
                            items={
                                this.getImmagini().map((src, i) =>
                                    <ProductImage
                                        className={`border-bottom border-top ${i === 0 ? "" : "__disable-swipe"}`}
                                        src={src}
                                        onClick={this.handleImageZoom(src)}
                                    />
                                )
                            }
                        />
                        <div className={"corner-ribbon top-right bg-info shadow"}>
                            {premio.valore} punti
                        </div>
                        {
                            premio.disponibilita <= 0 &&
                            <div className={"corner-ribbon top-left bg-danger shadow"}>
                                Esaurito
                            </div>
                        }
                    </div>
                    <div className={"card-body"}>
                        <h4 className={"font-weight-bold card-title"}>
                            {premio.titolo}
                        </h4>
                        <span className={"badge badge-success"}>
                            {premio.disponibilita} pezzi disponibili
                        </span>
                        {
                            !!premio.descrizione &&
                            <CollapsibleHTMLTextView
                                html={premio.descrizione}
                                collapsed={!this.state.fullDescription}
                                limit={150}
                                showLessElement={
                                    <span className={"ml-2 text-muted"} onClick={this.toggleFullDescription}>
                                        Mostra meno
                                    </span>
                                }
                                showMoreElement={
                                    <span className={"ml-2 text-muted"} onClick={this.toggleFullDescription}>
                                        Mostra altro...
                                    </span>
                                }
                            />
                        }
                    </div>
                    <div className={"card-footer text-center bg-white"}>
                        <button className={"btn btn-success"} onClick={this.handleRedeem}>
                            Riscatta questo premio <FaSolidIcon name={"chevron-right"}/>
                        </button>
                    </div>
                </div>

                <ModalPortalHOC>
                    {
                        this.state.zoomedImage &&
                        <ImageZoomPanel onDismiss={this.toggleZoomedImage} src={this.state.zoomedImage}/>
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

FidelityCardPremiItem.propTypes = {
    premioData: PropTypes.object
}


const ProductImage = styled.div`
    width:100%;
    padding-top:100%;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:contain;
    background-image:url("${props => props.src}");
`;
