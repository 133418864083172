import React, {Fragment} from "react";
import PropTypes from "prop-types";

export default class FuxCursorPaginator extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            data: [], //The items of the page
            max_items: 0,
            total: 0, //The number of total items of the pagination
            currentPage: 1,
            curr: null, //The current cursor
            prev: '', //The prev cursor
            next: '', //The next cursor
            fetching: false
        }
        this.state = {...this.initialState}

        if (this.props.refreshTime && this.props.refreshTime > 0) {
            setInterval(() => {
                this.fetch(this.state.curr, true)
            }, this.props.refreshTime)
        }
    }

    componentDidMount() {
        this.fetch(null)
    }

    fetch = (cursor, silent = false) => {
        if (!silent) {
            this.setState({fetching: true})
        }
        this.props.onPageRequest(cursor)
            .then(page => {
                this.setState({
                    data: page.data, max_items: page.max_items,
                    curr: cursor, next: page.next, prev: page.prev,
                    //The field "total" has the correct info only when cursor is null, otherwise it contains wrong informations
                    // caused by conditions injected in the query based on the cursor settings.
                    total: cursor ? this.state.total : page.total,
                    fetching: false
                })
            });
    }

    reset = _ => this.setState(...this.initialState, _ => this.fetch(null))

    handleGoPrev = _ => {
        this.setState({currentPage: this.state.currentPage - 1}, _ => {
            this.state.prev && this.fetch(this.state.prev)
        })
    }

    handleGoNext = _ => {
        this.setState({currentPage: this.state.currentPage + 1}, _ => {
            this.state.next && this.fetch(this.state.next)
        })
    }

    render() {
        if (this.state.fetching) {
            if (!this.props.onItemSkeletonRender || !this.props.skeletonItemsNum) {
                return (
                    <div className={"w-100 text-center"}>
                        <i className="fa fa-spin fa-spinner"></i> Ricerca in corso...
                    </div>
                )
            }
        }

        if (!this.state.data.length) {
            return (this.props.onEmptyPage())
        }

        return (
            <div>
                <div className={this.props.classItemsContainer || ""}>
                    {
                        this.state.fetching ?
                            [...Array(this.props.skeletonItemsNum || 3)].map((_, i) => {
                                return <Fragment key={i}>{this.props.onItemSkeletonRender(i)}</Fragment>
                            })
                            :
                            this.state.data.map((item, k) => {
                                return <Fragment key={k}>{this.props.onItemRender(item)}</Fragment>
                            })
                    }
                </div>

                {
                    !this.props.hideControls &&
                    <div className="d-flex justify-content-center mt-3">
                        <nav className="text-center">
                            <ul className="pagination">
                                <li className="page-item">
                                    <button className="page-link" data-role="prev" disabled={(!this.state.prev)}
                                            onClick={_ => {
                                                this.handleGoPrev()
                                            }}>Precedente
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button className="page-link" data-role="next" disabled={(!this.state.next)}
                                            onClick={_ => {
                                                this.handleGoNext()
                                            }}>Successivo
                                    </button>
                                </li>
                            </ul>
                            <span
                                data-role="label">Pagina {this.state.currentPage} di {Math.ceil(this.state.total / this.state.max_items)}</span>
                        </nav>
                    </div>
                }
            </div>
        )
    }
}

FuxCursorPaginator.propTypes = {
    onItemRender: PropTypes.func,
    onItemSkeletonRender: PropTypes.func,
    skeletonItemsNum: PropTypes.number,
    onPageRequest: PropTypes.func,
    onEmptyPage: PropTypes.func,
    refreshTime: PropTypes.number,
    hideControls: PropTypes.bool,
    classItemsContainer: PropTypes.string
}

FuxCursorPaginator.defaultProps = {
    skeletonItemsNum: 3
}
