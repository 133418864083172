import React, {Fragment} from "react";
import {FuxCarousel} from "../components/BaseComponents/FuxCarousel/FuxCarousel";
import socialLife from "./svg/homepage-welcome/social-life.svg";
import breakfast from "./svg/homepage-welcome/breakfast.svg";
import booking from "./svg/homepage-welcome/booking.svg";
import digitalization from "./svg/homepage-welcome/digitalization.svg";
import sharingArticle from "./svg/homepage-welcome/sharing-articles.svg";
import eatingTable from "./svg/homepage-welcome/eating-table.svg";
import shopping from "./svg/homepage-welcome/shopping.svg";
import arrow from "./svg/homepage-welcome/arrow-ricerca.svg";
import {BookizonAppManager} from "../index";
import {UserAuthContext} from "./BookizonLauncher";
import bookizonLogo from "./svg/bookizon-logo.svg";
import {NeedAuthCallToActionHOC} from "../hoc/NeedAuthCallToActionHOC";
import i18n from "../i18n";

export default class LauncherWelcomeScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchPageOpen: false,
        };
        this.imageStyle = {
            width: 200,
            maxWidth: "80%"
        }
        this.arrowStyle = {
            width: 100
        }

    }

    render() {

        const slides = [
            {
                image: socialLife,
                description: i18n.t("launcher.homepage.slides.slide-1")
            },
            {
                image: breakfast,
                description: i18n.t("launcher.homepage.slides.slide-2")
            },
            {
                image: shopping,
                description: i18n.t("launcher.homepage.slides.slide-3")
            },
            {
                image: booking,
                description: i18n.t("launcher.homepage.slides.slide-4")
            },
            {
                image: eatingTable,
                description: i18n.t("launcher.homepage.slides.slide-5")
            },
            {
                image: sharingArticle,
                description: i18n.t("launcher.homepage.slides.slide-6")
            },
            {
                image: digitalization,
                description: <span>{i18n.t("launcher.homepage.slides.slide-7_1")} <a
                    href={"tel:+390809999080"}>080 9999 080</a> {i18n.t("launcher.homepage.slides.slide-7_2")} <button className={"btn btn-link p-0"}
                                                                                                                       onClick={_ => BookizonAppManager.openInAppBrowser('https://bookizon.it')}>www.bookizon.it</button></span>
            }
        ]

        return (
            <UserAuthContext.Consumer>
                {
                    user => (
                        <div className={"container"}>
                            {
                                !!user &&
                                <div className={"mb-4"}>
                                    <img src={arrow} alt={"Freccia"} className={"d-block mx-auto mb-1 my-3"}
                                         style={this.arrowStyle}/>
                                    <h5 className={"font-weight-bold text-primary text-center"}>
                                        {i18n.t('launcher.homepage.ricerca-app-su-bookizon')}
                                    </h5>
                                </div>
                            }
                            <div className={"d-flex align-items-center bg-white shadow-sm p-3 rounded"}>
                                <div className={"w-100"}>
                                    {
                                        !user &&
                                        <div className={"text-center"}>
                                            <img src={bookizonLogo} style={{width:"60%"}}/>
                                            <h5 className={"font-weight-bold text-primary text-center mt-3"}>
                                                {i18n.t('launcher.homepage.welcome-heading')}
                                            </h5>
                                            <div className={"d-flex align-items-center justify-content-center mt-3"}>
                                                <NeedAuthCallToActionHOC openRegisterModal={true}>
                                                    <button className={"btn btn-sm btn-soft-primary mx-2"}>
                                                        {i18n.t('launcher.homepage.register-now-btn')}
                                                    </button>
                                                </NeedAuthCallToActionHOC>
                                                <NeedAuthCallToActionHOC showRegisterButton={false}>
                                                    <button className={"btn btn-sm btn-link text-blue mx-2"}>
                                                        {i18n.t('launcher.homepage.already-have-account-btn')}
                                                    </button>
                                                </NeedAuthCallToActionHOC>
                                            </div>
                                            <hr/>
                                        </div>
                                    }

                                    <FuxCarousel
                                        className={"__disable-swipe alignt-items-center"}
                                        items={
                                            slides.map(s => {
                                                return (
                                                    <div className={"text-center px-3"}>
                                                        <img src={s.image} style={this.imageStyle}/>
                                                        <p className={"text-center mt-3 mb-1 text-muted"}>
                                                            {s.description}
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </UserAuthContext.Consumer>
        );
    }

}
