import React, {Fragment} from "react";
import LauncherWelcomeScreen from "./LauncherWelcomeScreen";

export class LauncherLoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className={"mt-4 w-100"}>
                <LauncherWelcomeScreen />
            </div>
        )
    }
}

LauncherLoginView.propTypes = {
};
