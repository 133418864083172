import React, {Fragment} from "react";
import ModalPortal from "../../../../components/BaseComponents/ModalPortal";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {RequestARefoundIcon} from "../../../../img/illustrations/RequestARefoundIcon";
import {AllRequestsRefoundIcon} from "../../../../img/illustrations/AllRequestsRefoundIcon";
import CreateReceiptsPage from "./CreateReceipts/CreateReceiptsPage";
import PropTypes from "prop-types";
import {ModuloCassaAPI} from "../../../../api/ModuloCassa/ModuloCassaAPI";
import {NegozioOpenStore} from "../../../../stores/NegozioOpenStore";
import Pagination from "../../../../components/BaseComponents/Pagination/Pagination";
import ReceiptPreview from "../../../../components/ModuloCassa/ReceiptPreview";
import emptyBox from "../../../../img/illustrations/empty.svg";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import FuxCursorPaginator from "../../../../components/BaseComponents/FuxCursorPaginator/FuxCursorPaginator";

export default class ReceiptsHistoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    /**
     * @PAGINATOR
     */

    handleEmptyPage = _ => {
        return <div className={"text-center my-3"}>
            Non ci sono scontrini da visualizzare
            <img src={emptyBox} className={"img-fluid w-75 mx-auto mt-3"}
                 alt={"Nessuna immagine disponibile"}/>
        </div>
    }

    handlePageRequest = cursor => {
        return ModuloCassaAPI.Dashboard.storicoScontrini(cursor);
    }

    handleItemRender = i => {
        return (
            <div className={"mt-2"}>
                <ReceiptPreview
                    idRicevuta={i.id_ricevuta}
                    password={i.password}
                    enableSendOptions={true}
                    numeroDocumento={i.numero_documento}
                    righe={i.righe}
                    pagamenti={i.pagamenti}
                    status={i.status}
                    dataCreazione={i.data_creazione}
                    userNegozio={this.props.userNegozio}
                />
            </div>
        );
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Modulo cassa"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <div className={"container"}>
                            <div className={"container pt-4"}>
                                <div className={"row"}>
                                    {/*SEZIONE STORICO SCONTRINI*/}
                                    <div className={"col-12 mt-2"}>
                                        <FuxCursorPaginator
                                            onItemRender={this.handleItemRender}
                                            onPageRequest={this.handlePageRequest}
                                            onEmptyPage={this.handleEmptyPage}
                                            refreshTime={2000}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

ReceiptsHistoryPage.propTypes = {
    userNegozio: PropTypes.object,
    onDismiss: PropTypes.func
}
