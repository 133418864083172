import React, {Fragment} from "react";
import {FaRegularIcon, FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import ModalPortal from "../../../../components/BaseComponents/ModalPortal";
import Page from "../../../../components/BaseComponents/Page/Page";
import {swal, swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import {timepickerConfig} from "../../../../const/DatePickerConstants";
import moment from "moment";
import ServiziChooserPage from "../../../../components/Booking/ServiziPicker/ServiziChooserPage";
import SearchUserPanel from "../../SearchUserPanel/SearchUserPanel";
import {UserSearchResult} from "../../SearchUserPanel/UserSearchResult";
import DashboardUtentiAPI from "../../../../api/DashboardUtentiAPI";
import {FullscreenInput, FullscreenTextarea} from "../../../../components/Forms/FullscreenInputs";
import {StoriesLikeSelector} from "../../../../components/GenericUI/StoriesLikeSelector/StoriesLikeSelector";
import DashboardDateChooserPage from "./DashboardDateChooserPage";
import {CategoryPickerPage} from "../../../../components/Booking/CategoryPicker/CategoryPickerPage";
import {filterCategoryTree} from "../../../../components/Booking/helpers/categorie";
import BookingAPI from "../../../../api/BookingAPI";
import PropTypes from "prop-types";
import {TabViewItem} from "../../../../components/BaseComponents/TabView";
import {InputDatepicker} from "../../../../components/BaseComponents/InputDatepicker";
import {moment_getDaysBetweenDates} from "../../../../helpers/DateUtility";
import {ModuloServiziAPI} from "../../../../api/ModuloServizi/ModuloServiziAPI";
import AppConfig from "../../../../config/AppConfig";
import {DIPENDENTE_ANYONE_OPTION} from "../../../../const/ModuloServiziCostants";

const ERROR_TYPE_DIPENDENTE = "dipendente";
const BOOK_TYPE_APPUNTAMENTO = 'appuntamento';
const BOOK_TYPE_BLOCCO = 'blocco';

export default class DashboardAddServiceBookModal extends React.Component {

    constructor(props) {
        super(props);

        this.currentHour = moment().hours();
        this.moment_currentTime = moment(this.props.selectedDate || new Date()).hours(this.currentHour).minutes(0).seconds(0);
        this.moment_currentTimeEnd = moment(this.props.selectedDate || new Date()).hours(this.currentHour).minutes(0).seconds(0).add(30, 'minutes');

        this.state = {
            listaSedi: null,
            categoryTree: null,
            listaDipendenti: null,
            listaServizi: null,
            data: this.moment_currentTime.format('DD-MM-YYYY'),
            orario: this.props.startTime || '',
            orario_fine: this.props.startTime || this.moment_currentTimeEnd.format('HH:mm'),
            id_servizio: [],
            useDurata: false,
            wasOrarioUnsetWhenUsingDurata: false,
            id_dipendente: null,
            id_sede: null,
            nome_cliente: '',
            note: '',
            cliente: null,
            temporaneo: false,
            categorieOpen: false,
            categoriaSelected: null,
            searchClienteOpen: false,
            dateChooserOpen: false,
            forceBook: false,
            forceDipendente: false,
            bookType: BOOK_TYPE_APPUNTAMENTO,
            /** @MARK State per book type blocco */
            titolo_blocco: 'Orario bloccato',
            data_inizio_blocco: this.moment_currentTime.format('DD-MM-YYYY'),
            orario_inizio_blocco: this.moment_currentTime.format('HH:mm'),
            data_fine_blocco: this.moment_currentTimeEnd.format('DD-MM-YYYY'),
            orario_fine_blocco: this.moment_currentTimeEnd.format('HH:mm')
        }
    }

    componentDidMount() {
        swalLoading();
        BookingAPI.getRichData(this.props.idNegozio, true)
            .then(bookingData => {
                let state = {
                    listaServizi: bookingData.servizi,
                    listaDipendenti: bookingData.dipendenti,
                    categoryTree: bookingData.categorie,
                    listaSedi: bookingData.sedi,
                };
                state.id_sede = bookingData.sedi[0].id_sede;
                state.id_dipendente = this.props.idDipendenteDefault || bookingData.dipendenti.find(d => d.id_sede === bookingData.sedi[0].id_sede).id_dipendente || null;

                this.setState(state, _ => swal.safeClose());
            })
            .catch(m => {
                swalError(m);
                this.props.onDismiss();
            })
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value,
        });
    }

    //Gestione dipendenti

    getSelectedSedeDipendenti = _ => {
        return this.state.listaDipendenti.filter(d => d.id_sede === this.state.id_sede)
    }

    handleChangeDipendente = id_dipendente => this.setState({id_dipendente: id_dipendente});


    //Gestione della prenotazione

    canUseCategoria = _ => this.props.bookingFlow.indexOf("categoria") !== -1;

    handleSubmit = e => {
        e.preventDefault();
        swalLoading("Attendere prego...");
        if (this.state.bookType === BOOK_TYPE_APPUNTAMENTO) {
            this.handlePrenota(e);
        } else {
            this.handleBlocca(e);
        }
    }

    handleBlocca = e => {
        const fullDayDuration = 24 * 60 / 5;
        const momentInizio = moment(`${this.state.data_inizio_blocco} ${this.state.orario_inizio_blocco}`, 'DD-MM-YYYY HH:mm')
        const momentFine = moment(`${this.state.data_fine_blocco} ${this.state.orario_fine_blocco}`, 'DD-MM-YYYY HH:mm')
        if (momentInizio.isSameOrAfter(momentFine)) {
            swalError("La data di fine del blocco deve essere successivo a quello di inizio");
            return;
        }

        const startDateF = momentInizio.format('DD-MM-YYYY');
        const endDateF = momentFine.format('DD-MM-YYYY')
        const dates = moment_getDaysBetweenDates(momentInizio, momentFine).map(m => {
            const date = m.format('DD-MM-YYYY')
            let durata;
            let orario = '00:00';

            if (date === startDateF) {
                durata = fullDayDuration - momentInizio.diff(moment(`${date}`, 'DD-MM-YYYY'), 'minutes') / 5;
                orario = momentInizio.format('HH:mm');
            } else if (date === endDateF) {
                durata = momentFine.diff(moment(`${date}`, 'DD-MM-YYYY'), 'minutes') / 5
            } else {
                durata = fullDayDuration
            }

            return {date: date, durata: durata, orario: orario}
        });

        if (dates.length === 1) {
            dates[0].durata = momentFine.diff(momentInizio, 'minutes') / 5;
        }

        if (!dates.length) {
            swalError("Il blocco deve durare almeno 5 min")
            return;
        }

        if (dates.length > 10) {
            swalError("Puoi bloccare al massimo 10 giorni insieme");
            return;
        }

        DashboardUtentiAPI.createGuest(this.state.titolo_blocco)
            .then(id_utente => {
                const promises = [];
                dates.map(d => {
                    promises.push(ModuloServiziAPI.Dashboard.fastBook({
                        id_sede: this.state.id_sede,
                        id_dipendente: this.state.id_dipendente,
                        id_utente: id_utente,
                        data: d.date,
                        orario: d.orario,
                        durata: d.durata,
                        id_servizio: null,
                        note: '',
                        is_blocked_time: 1
                    }))
                });

                Promise.all(promises)
                    .then(r => swalSuccess(r[0]))
                    .then(this.props.onDismiss)
                    .then(this.props.onBook)
                    .catch(swalError);
            }).catch(swalError);
    }

    handlePrenota = _ => {
        this.getIdCliente()
            .then(id_utente => {
                if (this.state.useDurata || this.state.forceBook) {
                    this.doForcedScheduling(id_utente);
                } else {
                    const shuffleIdDipendenti = this.getSelectedSedeDipendenti().map(d => d.id_dipendente).sort(_ => (.5 - Math.random()));
                    this.doClassicScheduling(id_utente, shuffleIdDipendenti, 0);
                }
                this.setState({forceDipendente: false}); //In ogni caso dopo aver inviato la richiesta, annullo la forzatura del dipendente
            }).catch(swalError);
    }

    getIdCliente = _ => {
        return new Promise((resolve, reject) => {
            if (this.state.cliente) {
                resolve(this.state.cliente.id_utente);
            } else {
                DashboardUtentiAPI.createGuest(this.state.nome_cliente)
                    .then(id_utente => {
                        resolve(id_utente);
                    }).catch(reject);
            }
        });
    }

    doForcedScheduling = id_utente => {
        const durata = moment(`${this.state.data} ${this.state.orario_fine}`, 'DD-MM-YYYY HH:mm').diff(moment(`${this.state.data} ${this.state.orario}`, 'DD-MM-YYYY HH:mm'), 'minutes') / 5;
        if (this.state.useDurata && durata <= 0) {
            swalError("L'orario di inizio appuntamento deve essere precedente a quello di fine");
            return;
        }
        ModuloServiziAPI.Dashboard.fastBook({
            id_sede: this.state.id_sede,
            id_dipendente: this.state.id_dipendente,
            id_utente: id_utente,
            data: this.state.data,
            orario: this.state.orario,
            durata: this.state.useDurata ? durata : null,
            id_servizio: this.state.forceBook ? this.state.id_servizio : null,
            note: this.state.note,
        }).then(swalSuccess)
            .then(this.props.onDismiss)
            .then(this.props.onBook)
            .catch(swalError);
    }

    doClassicScheduling = (id_utente, shuffledIdDipendenti, idx) => {
        ModuloServiziAPI.Dashboard.book({
            id_sede: this.state.id_sede,
            id_servizio: this.state.id_servizio,
            id_dipendente: this.state.id_dipendente === DIPENDENTE_ANYONE_OPTION ? shuffledIdDipendenti[idx] : this.state.id_dipendente,
            id_utente: id_utente,
            data: this.state.data,
            orario: this.state.orario,
            note: this.state.note,
            forceDipendente: this.state.forceDipendente
        }).then(swalSuccess)
            .then(this.props.onDismiss)
            .then(this.props.onBook)
            .catch(response => {

                if (this.state.id_dipendente === DIPENDENTE_ANYONE_OPTION && idx + 1 < shuffledIdDipendenti.length) {
                    this.doClassicScheduling(id_utente, shuffledIdDipendenti, idx + 1);
                    return;
                }


                if (response.data && response.data.type && response.data.type === ERROR_TYPE_DIPENDENTE) {
                    //In caso di errore nella scelta del dipendente viene chiesto se proseguire lo stesso
                    swalConfirm(response.message, 'Continua', 'Annulla')
                        .then(_ => {
                            //In caso di prosecuzione, imposto la forzatura del dipendente e rieseguo la richiesta
                            this.setState({forceDipendente: true}, _ => {
                                this.handlePrenota();
                            });
                        });
                } else {
                    swalError(response.message);
                }
            });
    }

    /**
     * @MARK Gestione data e orario (e durata) manuali
     * */

    handleDateChange = ({value}) => this.setState({data: moment(value).format('DD-MM-YYYY')});
    handleTimeChange = ({value}) => {
        console.log(value);
        const newOrario = moment(value).format('HH:mm');
        //Alla selezione dell'orario di inizio, devo spostare anche l'orario di fine mantenendo la loro differenza
        const newOrarioMoment = moment(`1970-01-01 ${newOrario}:00`)
        const oldOrarioMoment = moment(`1970-01-01 ${this.state.orario}`);
        const diff = newOrarioMoment.diff(oldOrarioMoment, 'minutes');
        const momentEnd = moment(`1970-01-01 ${this.state.orario_fine}`).add(diff, 'minutes');

        this.setState({
            orario: newOrario,
            orario_fine: momentEnd.format('HH:mm'), //Modifiche all'orario di fine
        });
    }
    handleTimeEndChange = ({value}) => this.setState({orario_fine: moment(value).format('HH:mm')});
    canPickTime = _ => {
        return this.state.id_sede && this.state.id_dipendente && (this.state.id_servizio.length || this.state.useDurata);
    }


    /**
     * @MARK Date chooser (Trova disponibilità)
     * */

    toggleDateChooser = _ => this.setState({dateChooserOpen: !this.state.dateChooserOpen})
    handleDateChooserChoose = datetime => {
        const currentDurata = this.getDurataAppuntamento();
        const newStartDate = moment(`${datetime.data} ${datetime.orario}`, 'DD-MM-YYYY HH:mm');
        const newEndDate = moment(newStartDate).add(currentDurata, 'minutes');
        this.setState({
            dateChooserOpen: false,
            data: datetime.data,
            orario: datetime.orario,
            orario_fine: newEndDate.format('HH:mm'),
            timepickerStartTime: newStartDate.toDate(),
            timepickerStartOpen: false,
            timepickerEndTime: newEndDate.toDate(),
            timepickerEndOpen: false,
            datepickerDate: newStartDate.toDate(),
            datepickerOpen: false
        });
    }


    /**
     * @MARK Gestione categoria
     * */

    getCategorieListItems = _ => {
        return filterCategoryTree({
            categoryTree: this.state.categoryTree,
            listaDipendenti: this.getSelectedSedeDipendenti(),
            listaServizi: this.state.listaServizi,
            bookInfo: {
                id_sede: this.state.id_sede,
                id_dipendente: this.state.id_dipendente
            }
        });
    }

    handleCategoriaDismiss = _ => this.setState({categorieOpen: false, categoriaSelected: null});

    handleCategoriaChoose = id_categoria => this.setState({categoriaSelected: id_categoria});


    /**
     * @MARK Gestione servizi
     * */

    getServiziListItems = _ => {
        let listaServizi = this.state.listaServizi.slice();
        if (this.state.categoriaSelected && this.state.categoriaSelected !== -1) {
            listaServizi = listaServizi.filter(s => s.categoria === this.state.categoriaSelected)
        }
        return listaServizi.map(s => {
            return {text: s.nome, value: s.id_servizio}
        });
    }

    handleServiziDismiss = _ => this.setState({categoriaSelected: null});

    handleServizioChoose = listaIdServizio => this.setState({
        id_servizio: listaIdServizio,
        categoriaSelected: null,
        categorieOpen: false
    });

    handleServizioChange = listaIdServizio => this.setState({
        id_servizio: listaIdServizio,
    });

    handleAddServizio = _ => {
        if (this.canUseCategoria()) {
            this.setState({categorieOpen: true})
        } else {
            this.setState({categorieOpen: false, categoriaSelected: -1});
        }
    }

    handleRemoveServizio = id_servizio => _ => {
        var servizi = this.state.id_servizio.slice();
        servizi = servizi.filter(id_s => id_s !== id_servizio);
        this.setState({id_servizio: servizi});
    }

    toggleUseDurata = _ => {
        if (this.state.useDurata) { //Si sta disattivando la durata
            this.setState({
                useDurata: !this.state.useDurata,
                orario: this.state.wasOrarioUnsetWhenUsingDurata ? '' : this.state.orario,
                timepickerStartTime: this.moment_currentTime.toDate()
            });
        } else { //Si sta attivando la durata
            /*
            Se non impostato, imposto l'orario di partenza all'ora attuale ma alla data passata tramite props con fallback su quella attuale.
            Inoltre imposto "wasOrarioUnsetWhenUsingDurata" per indicare se devo rimuovere nuovamente l'orario se disabilito l'uso della durata.
            */
            this.setState({
                useDurata: !this.state.useDurata,
                orario: this.state.orario === '' ? this.moment_currentTime.format('HH:mm') : this.state.orario,
                timepickerStartTime: this.moment_currentTime.toDate(),
                wasOrarioUnsetWhenUsingDurata: this.state.orario === '',
                forceBook: false
            });
        }
    }

    getDurataAppuntamento = _ => {
        const durata = moment(`${this.state.data} ${this.state.orario_fine}`, 'DD-MM-YYYY HH:mm').diff(moment(`${this.state.data} ${this.state.orario}`, 'DD-MM-YYYY HH:mm'), 'minutes');
        if (durata) {
            return durata;
        }
    }


    /**
     * @MARK Gestione clienti
     * */

    handleResetCliente = _ => {
        this.setState({temporaneo: false, nome_cliente: '', cliente: null});
    }

    toggleSearchCliente = _ => this.setState({searchClienteOpen: !this.state.searchClienteOpen});


    handleClienteChoose = cliente => this.setState({cliente: cliente, searchClienteOpen: false});

    handleUseTemporaneo = searchString => {
        searchString = typeof searchString === 'string' ? searchString : '';
        this.setState({cliente: null, nome_cliente: searchString, temporaneo: true, searchClienteOpen: false});
    }


    /**
     * @MARK Gestione tipo prenotazione (appuntamento o blocco orario)
     * */

    handleBookTypeChange = type => _ => this.setState({bookType: type});


    /**
     * @MARK Gestione data e orario blocco
     * */
    handleDataInizioBloccoChange = ({value}) => this.setState({data_inizio_blocco: moment(value).format('DD-MM-YYYY')});
    handleOrarioInizioBloccoChange = ({value}) => this.setState({orario_inizio_blocco: moment(value).format('HH:mm')});
    handleDataFineBloccoChange = ({value}) => this.setState({data_fine_blocco: moment(value).format('DD-MM-YYYY')});
    handleOrarioFineBloccoChange = ({value}) => this.setState({orario_fine_blocco: moment(value).format('HH:mm')});


    render() {
        if (!this.state.id_sede) return ''; //Attendo il recupero dei dati di prenotazione

        const dipendenti = this.getSelectedSedeDipendenti().map(d => {
            return {
                value: d.id_dipendente,
                label: d.nome,
                imageUrl: `${d.immagine}`
            }
        });
        dipendenti.unshift({
            value: DIPENDENTE_ANYONE_OPTION,
            label: 'Chiunque',
            imageUrl: `${AppConfig.webServerUrl}/public/img/profile-placeholder.png`
        });
        return (
            <Fragment>
                <Page
                    active={true}
                    title={"Aggiungi"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                    onLeftIconClick={this.props.onDismiss}
                    rightIcon={<FaSolidIcon name={"check"}/>}
                    rightIconText={this.state.bookType === BOOK_TYPE_APPUNTAMENTO ? "Prenota" : "Blocca"}
                    onRightIconClick={this.handleSubmit}
                >
                    {
                       !!this.props.enableBlockTime &&
                        <div className={"d-flex bg-white sticky-top shadow-sm"}>
                            <TabViewItem
                                onClick={this.handleBookTypeChange(BOOK_TYPE_APPUNTAMENTO)}
                                className={"py-2 text-muted d-flex align-items-center justify-content-center flex-fill text-uppercase " + (this.state.bookType === BOOK_TYPE_APPUNTAMENTO ? ' active' : '')}
                                activeColor={"#293069"}
                            >
                                Appuntamento
                            </TabViewItem>
                            <TabViewItem
                                onClick={this.handleBookTypeChange(BOOK_TYPE_BLOCCO)}
                                className={"py-2 text-muted d-flex align-items-center justify-content-center flex-fill text-uppercase " + (this.state.bookType === BOOK_TYPE_BLOCCO ? ' active' : '')}
                                activeColor={"#293069"}
                            >
                                Blocco
                            </TabViewItem>
                        </div>
                    }
                    <div className={"container pt-2 px-0"}>
                        <form onSubmit={this.handleSubmit}>
                            {/* Scelta del DIPENDENTE */}
                            <div className={"container my-4 __disable-swipe"}>
                                <StoriesLikeSelector
                                    items={dipendenti}
                                    circleSize={60}
                                    onChange={this.handleChangeDipendente}
                                    defaultValue={this.state.id_dipendente}
                                />
                            </div>

                            {
                                this.state.bookType === BOOK_TYPE_BLOCCO &&
                                <Fragment>
                                    {/* Titolo blocco */}
                                    <div className={"form-group mb-4"}>
                                        <h6 className={"text-muted font-weight-bold pl-3"}>Testo del blocco</h6>
                                        <div className={"container-fluid"}>
                                            <div
                                                className={"row bg-white align-items-center border-top border-bottom"}>
                                                <div className={"col-1 px-0 text-primary text-center"}>
                                                    <FaSolidIcon name={"pencil-alt"}/>
                                                </div>
                                                <div className={"col px-0"}>
                                                    <FullscreenInput
                                                        type={"text"}
                                                        name={"titolo_blocco"}
                                                        placeholder={"Titolo"}
                                                        className={"form-control border-0 rounded-0"}
                                                        value={this.state.titolo_blocco}
                                                        onChange={this.handleInputChange}
                                                        readonly={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Data/Orario inizio blocco */}
                                    <div className={"form-group mb-4"}>
                                        <h6 className={"text-muted font-weight-bold pl-3"}>Inizia</h6>
                                        <div className={"container-fluid clearfix"}>
                                            <div className={"row align-items-center bg-white border-bottom border-top"}>
                                                <div className={"col-1 px-0 text-success text-center"}>
                                                    <FaRegularIcon name={"calendar"}/>
                                                </div>
                                                <div className={"col px-0"}>
                                                    <InputDatepicker
                                                        className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                        value={this.state.data_inizio_blocco}
                                                        onChange={this.handleDataInizioBloccoChange}
                                                    />
                                                </div>
                                                <div className={"col px-0"}>
                                                    <InputDatepicker
                                                        className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                        value={this.state.orario_inizio_blocco}
                                                        onChange={this.handleOrarioInizioBloccoChange}
                                                        format={'hh:mm'}
                                                        config={timepickerConfig}
                                                        startDate={this.moment_currentTime.toDate()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Data/Orario fine blocco */}
                                    <div className={"form-group mb-4"}>
                                        <h6 className={"text-muted font-weight-bold pl-3"}>Finisce</h6>
                                        <div className={"container-fluid clearfix"}>
                                            <div className={"row align-items-center bg-white border-bottom border-top"}>
                                                <div className={"col-1 px-0 text-warning text-center"}>
                                                    <FaRegularIcon name={"calendar"}/>
                                                </div>
                                                <div className={"col px-0"}>
                                                    <InputDatepicker
                                                        className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                        value={this.state.data_fine_blocco}
                                                        onChange={this.handleDataFineBloccoChange}
                                                    />
                                                </div>
                                                <div className={"col px-0"}>
                                                    <InputDatepicker
                                                        className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                        value={this.state.orario_fine_blocco}
                                                        onChange={this.handleOrarioFineBloccoChange}
                                                        format={'hh:mm'}
                                                        config={timepickerConfig}
                                                        startDate={this.moment_currentTimeEnd.toDate()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            <div className={this.state.bookType === BOOK_TYPE_BLOCCO ? 'd-none' : ''}>

                                {/* Scelta dei SERVIZI */}
                                <div
                                    className={"form-group mb-4" + (this.state.useDurata ? ' d-none' : '')}>
                                    <h6 className={"text-muted font-weight-bold pl-3"}>Servizi</h6>
                                    {
                                        this.state.id_servizio.length ?
                                            <Fragment>
                                                <div className={"list-group"}>
                                                    {
                                                        this.state.id_servizio.map(id_servizio => {
                                                            const s = this.state.listaServizi.find(ss => ss.id_servizio === id_servizio);
                                                            return <div
                                                                key={s.id_servizio}
                                                                className={"list-group-item d-flex align-items-center justify-content-between"}>
                                                                {s.nome}
                                                                <button
                                                                    type={"button"}
                                                                    className={"float-right btn btn-sm btn-danger"}
                                                                    onClick={this.handleRemoveServizio(s.id_servizio)}
                                                                >
                                                                    <FaSolidIcon name={"trash"}/>
                                                                </button>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                <div className={"container-fluid clearfix"}>
                                                    <button
                                                        className={"btn btn-link btn-sm float-right"} type={"button"}
                                                        onClick={this.handleAddServizio}
                                                    >
                                                        <FaSolidIcon name={"plus"}/> Aggiungi altri servizi
                                                    </button>
                                                </div>
                                            </Fragment>
                                            : //NOT (this.state.id_servizio.length)
                                            <div className={"container-fluid"}>
                                                <div
                                                    className={"row bg-white align-items-center border-top border-bottom"}>
                                                    <div className={"col-1 px-0 text-center text-danger"}>
                                                        <FaSolidIcon name={"ellipsis-h"}/>
                                                    </div>
                                                    <div className={"col px-0"}>
                                                        <button
                                                            className={"btn btn-link btn-block text-muted text-left"}
                                                            type={"button"}
                                                            onClick={this.handleAddServizio}
                                                            disabled={this.state.useDurata}
                                                        >
                                                            Aggiungi servizio
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    className={"text-right mt-2 small d-flex align-items-center justify-content-end"}>
                                                    oppure
                                                    <button
                                                        className={"btn btn-link btn-sm p-0 ml-2"}
                                                        type={"button"}
                                                        onClick={this.toggleUseDurata}
                                                    >
                                                        <FaSolidIcon name={"clock"}/> Scegli solo la durata
                                                    </button>
                                                </div>
                                            </div>
                                    }

                                </div>


                                {/* Scelta di DATA & ORARIO */}
                                <div className={"form-group mb-4"} style={!this.canPickTime() ? {"opacity": 0.5} : {}}>
                                    <div className={"d-flex align-items-center justify-content-between"}>
                                        <h6 className={"text-muted font-weight-bold pl-3"}>Data e ora</h6>
                                        {
                                            this.state.useDurata &&
                                            <button className={"btn btn-link btn-sm"} type={"button"}
                                                    onClick={this.toggleUseDurata}
                                            >
                                                <FaSolidIcon name={"tags"}/> Scegli un servizio
                                            </button>
                                        }
                                    </div>

                                    <div className={"container-fluid clearfix"}>
                                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                                            <div className={"col-1 px-0 text-success text-center"}>
                                                <FaRegularIcon name={"clock"}/>
                                            </div>
                                            <div className={"col px-0"}>
                                                <InputDatepicker
                                                    className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                    disabled={!this.canPickTime()}
                                                    value={this.state.data}
                                                    onChange={this.handleDateChange}
                                                />
                                            </div>
                                            <div className={"col px-0"}>
                                                <InputDatepicker
                                                    className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                    disabled={!this.canPickTime()}
                                                    value={this.state.orario}
                                                    onChange={this.handleTimeChange}
                                                    format={'hh:mm'}
                                                    config={timepickerConfig}
                                                    startDate={this.moment_currentTime.toDate()}
                                                />
                                            </div>
                                            {
                                                this.state.useDurata &&
                                                <div className={"col px-0"}>
                                                    <InputDatepicker
                                                        className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                        disabled={!this.canPickTime()}
                                                        value={this.state.orario_fine}
                                                        onChange={this.handleTimeEndChange}
                                                        format={'hh:mm'}
                                                        config={timepickerConfig}
                                                        startDate={this.moment_currentTimeEnd.toDate()}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.useDurata ?
                                                <div className={"d-flex align-items-center justify-content-between"}>
                                                    <small className={"text-muted"}>
                                                        L'apputamento durerà {this.getDurataAppuntamento()} min
                                                    </small>
                                                    <button className={"btn btn-link btn-sm"} type={"button"}
                                                            onClick={this.toggleDateChooser}
                                                            disabled={!this.canPickTime()}
                                                    >
                                                        <FaSolidIcon name={"calendar-alt"}/> Trova disponibilità
                                                    </button>
                                                </div>
                                                :
                                                <button className={"btn btn-link btn-sm float-right"} type={"button"}
                                                        onClick={this.toggleDateChooser}
                                                        disabled={!this.canPickTime()}
                                                >
                                                    <FaSolidIcon name={"calendar-alt"}/> Trova disponibilità
                                                </button>

                                        }
                                    </div>
                                </div>

                                {/* Forzatura della prenotazione */}
                                {
                                    !this.state.useDurata &&
                                    <div className={"container-fluid"}>
                                        <div className={"form-group"}>
                                            <label>
                                                <input
                                                    type={"checkbox"}
                                                    name={"forceBook"}
                                                    onChange={this.handleInputChange}
                                                    checked={this.state.forceBook}
                                                /> Ignora disponibilità reale
                                            </label>
                                        </div>
                                    </div>
                                }

                                {/* Scelta del CLIENTE */}
                                <div className={"form-group mb-4"}>
                                    <h6 className={"text-muted font-weight-bold pl-3"}>Cliente</h6>
                                    {
                                        this.state.temporaneo ?
                                            <div className={"container-fluid clearfix"}>
                                                <div
                                                    className={"row bg-white align-items-center border-top border-bottom"}>
                                                    <div className={"col-1 px-0 text-primary text-center"}>
                                                        <FaSolidIcon name={"user"}/>
                                                    </div>
                                                    <div className={"col px-0"}>
                                                        <FullscreenInput
                                                            type={"text"}
                                                            name={"nome_cliente"}
                                                            placeholder={"Nome appuntamento"}
                                                            className={"form-control border-0 rounded-0"}
                                                            value={this.state.nome_cliente}
                                                            onChange={this.handleInputChange}
                                                            readonly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <button className={"btn btn-link btn-sm text-danger float-right"}
                                                        type={"button"}
                                                        onClick={this.handleResetCliente}
                                                >
                                                    <FaSolidIcon name={"times"}/> Rimuovi
                                                </button>
                                            </div>
                                            :
                                            this.state.cliente ?
                                                <div className={"container-fluid"}>
                                                    <UserSearchResult {...this.state.cliente}/>
                                                    <div className={"text-center"}>
                                                        <button className={"btn btn-link btn-sm text-danger"}
                                                                type={"button"}
                                                                onClick={this.handleResetCliente}
                                                        >
                                                            <FaSolidIcon name={"times"}/> Scegli un altro cliente
                                                        </button>
                                                    </div>
                                                </div>
                                                :

                                                <div className={"container-fluid"}>
                                                    <div
                                                        className={"row bg-white align-items-center border-top border-bottom"}>
                                                        <div className={"col-1 px-0 text-center text-danger"}>
                                                            <FaSolidIcon name={"ellipsis-h"}/>
                                                        </div>
                                                        <div className={"col px-0"}>
                                                            <button
                                                                className={"btn btn-link btn-block text-muted text-left"}
                                                                type={"button"}
                                                                onClick={this.toggleSearchCliente}
                                                            >
                                                                Associa cliente
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className={"text-right"}>
                                                        <button className={"btn btn-link btn-sm"} type={"button"}
                                                                onClick={this.handleUseTemporaneo}>
                                                            <FaSolidIcon name={"pencil-alt"}/> Scrivi manualmente
                                                        </button>
                                                    </div>
                                                </div>
                                    }

                                </div>

                                {/* Note aggiuntive interne */}
                                <div className={"form-group mb-4"}>
                                    <FullscreenTextarea
                                        name={"note"}
                                        className={"form-control border-right-0 border-left-0 border-top border-bottom rounded-0"}
                                        value={this.state.note}
                                        onChange={this.handleInputChange}
                                        placeholder={"Note aggiuntive"}
                                    />
                                </div>

                            </div>

                        </form>
                    </div>
                </Page>
                <ModalPortal>

                    {
                        this.state.categorieOpen &&
                        <CategoryPickerPage
                            categoryTree={this.getCategorieListItems()}
                            onDismiss={this.handleCategoriaDismiss}
                            onLeafChoose={this.handleCategoriaChoose}
                        />
                    }

                    {
                        this.state.categoriaSelected && <ServiziChooserPage
                            title={"Servizio"}
                            items={this.getServiziListItems()}
                            allItems={this.state.listaServizi}
                            active={!!this.state.categoriaSelected}
                            onDismiss={this.handleServiziDismiss}
                            onChoose={this.handleServizioChoose}
                            onChange={this.handleServizioChange}
                            selected={this.state.id_servizio || []}
                            shoppingCartEnabled={this.state.categorieOpen || this.state.categoriaSelected === -1}
                        />
                    }

                    {
                        this.state.searchClienteOpen &&
                        <SearchUserPanel
                            active={this.state.searchClienteOpen}
                            onDismiss={this.toggleSearchCliente}
                            onResultClick={this.handleClienteChoose}
                        />
                    }

                    {
                        this.state.dateChooserOpen &&
                        <DashboardDateChooserPage
                            bookInfo={{...this.state}}
                            onChoose={this.handleDateChooserChoose}
                            onDismiss={this.toggleDateChooser}
                            active={true}
                            confirmText={"Scegli"}
                            durata={this.state.useDurata ? this.getDurataAppuntamento() / 5 : null}
                            forceDipendente={true}
                        />
                    }


                </ModalPortal>
            </Fragment>
        )
    }

}


DashboardAddServiceBookModal.propTypes = {
    bookingFlow: PropTypes.string,
    idNegozio: PropTypes.any,
    onDismiss: PropTypes.func,
    onBook: PropTypes.func,
    selectedDate: PropTypes.string,
    startTime: PropTypes.string,
    idDipendenteDefault: PropTypes.any,
    enableBlockTime: PropTypes.bool
}
