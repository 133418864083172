import React from "react";

import DelayUnmountingComponent from "../DelayUnmountingComponent";
import Page from "./Page";

const DelayedPage = DelayUnmountingComponent(Page);

export default class PageUnmountable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cachedProps: {...this.props}
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Sono passato da NON ATTIVO -> ATTIVO, quindi mi faccio il caching delle props
        if (this.props.cacheProps && this.props.active && !prevProps.active){
            this.setState({cachedProps: {...this.props}});
        }
    }

    getCurrentProps = _ => {
        if (!this.props.active && this.props.cacheProps){
            return this.state.cachedProps;
        }else{
            return this.props;
        }
    }

    render() {
        return <DelayedPage delayTime={300} isMounted={this.props.active} {...this.getCurrentProps()} active={this.props.active} />
    }

}
