import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {RequestARefoundIcon} from "../../../../img/illustrations/RequestARefoundIcon";
import {AllRequestsRefoundIcon} from "../../../../img/illustrations/AllRequestsRefoundIcon";
import Page from "../../../../components/BaseComponents/Page/Page";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {DipendenteWelfareGiftCardsModal} from "../DipendenteWelfareGiftCardsModal";
import FuxCursorPaginator from "../../../../components/BaseComponents/FuxCursorPaginator/FuxCursorPaginator";
import DipendenteWelfareGiftCardsCategoryItem from "../Catalog/Categories/DipendenteWelfareGiftCardsCategoryItem";
import {WelfareAPI} from "../../../../api/Welfare/WelfareAPI";
import DipendenteWelfareGiftCardsHistoryItem from "./DipendenteWelfareGiftCardsHistoryItem";
import DipendenteWelfareGiftCardHistoryProductPage from "./DipendenteWelfareGiftCardHistoryProductPage";
import {DipendenteWelfareGiftCardsProductPage} from "../Catalog/Products/DipendenteWelfareGiftCardsProductPage";

export class DipendenteWelfareGiftCardsHistoryPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            giftCardClicked: {}
        }
    }

    /**
     * @PAGINATOR-CATEGORIES
     */
    handleCategoryRender = giftCard => <DipendenteWelfareGiftCardsHistoryItem giftCard={giftCard}
                                                                              onGiftCardClick={this.handleGiftCardClicked}/>

    handleCategoriesPageRequest = cursor => WelfareAPI.GiftCards.getOrders(cursor)

    //Pagina vuota del fux
    handleCategoryEmptyPage = _ => "Non hai ancora acquisto una gift card"

    //Toggle product page
    handleGiftCardClicked = giftCard => this.setState({giftCardClicked: giftCard})
    handleProductPageDismiss = _ => this.setState({giftCardClicked: {}})

    render() {
        return (
            <Fragment>
                <Page
                    title={"Storico Gift Cards"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <div className={"container pt-4"}>
                            {
                                !this.state.searchQuery &&
                                <div>
                                    <b>I tuoi ordini:</b>
                                    <FuxCursorPaginator
                                        onItemRender={this.handleCategoryRender}
                                        onPageRequest={this.handleCategoriesPageRequest}
                                        onEmptyPage={this.handleCategoryEmptyPage}
                                        hideControls={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                />

                {
                    this.state.giftCardClicked.id_ordine &&
                    <DipendenteWelfareGiftCardHistoryProductPage
                        idOrdine={this.state.giftCardClicked.id_ordine}
                        onDismiss={this.handleProductPageDismiss}
                    />
                }
            </Fragment>
        )
    }

}


DipendenteWelfareGiftCardsHistoryPage.propTypes = {
    active: PropTypes.bool,
    onDismiss: PropTypes.func
};