import React, {Fragment} from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import styled from "styled-components";
import AppConfig from "../../config/AppConfig";
import ShopFeaturesIcon from "../../components/ShopFeaturesIcon";
import {AppIconThumbnail} from "../../components/BaseComponents/AppIconThumbnail";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import {FuxRating} from "../../components/FuxRating";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {OPEN_APP_REQUEST} from "../../const/AppEventsCostants";
import i18n from "../../i18n";

const RatingWrapper = styled.div`
    font-size:0.8em;
`;

const ButtonCTA = styled.button`
  background-color:${props => props.bgColor} !important;
  color:white;
`;

export default class NegozioSearchResult extends React.Component {

    constructor(props) {
        super(props);
    }

    getDistanceString = km => {
        km = parseFloat(km);
        if (km < 1) {
            return `${Math.round(km * 1000).toFixed(0)}m`;
        } else {
            return `${km.toFixed(1)} km`
        }
    }

    render() {
        return (
            <div className={"card mb-2 rounded-0"}>
                <div className={"container-fluid py-1"}>
                    <div className={"row align-items-center"}>
                        <div className={"col-2 px-0 py-2 justify-content-center d-flex"}>
                            <AppIconThumbnail
                                size={"50px"}
                                src={`${AppConfig.webServerUrl}/api/negozio/${this.props.id_negozio}/logoIcon/100`}
                            />
                        </div>
                        <div className={"col-7 pr-0 p-2"} onClick={this.props.onClick}>
                            <div className={"text-truncate mb-1"}>
                                <h6 className="mt-0 text-truncate font-weight-bold mb-0">{this.props.nome}</h6>
                            </div>
                            <Subtitle className={"text-muted"}>
                                <SafeHtmlContainer html={`${ucword(this.props.indirizzo_sede)}, ${ucword(this.props.citta_sede)}`}/>
                            </Subtitle>

                            {
                                this.props.features && this.props.features.map(
                                    (f, i) => <ShopFeaturesIcon key={i} feature={f}/>
                                )
                            }
                        </div>
                        <div className={"col-3 pl-1 pr-1 text-right"}>
                            {
                                !!this.props.distance &&
                                <BadgeDistance className={"p-1 mb-1"} color={this.props.accentColor}>
                                    <FaSolidIcon name={"map-marked-alt"}/>&nbsp;
                                    {this.getDistanceString(this.props.distance)}
                                </BadgeDistance>
                            }
                            <ButtonCTA
                                className={"btn btn-xs btn-block"}
                                bgColor={this.props.accentColor}
                                onClick={_ => FuxEvents.emit(OPEN_APP_REQUEST, this.props.id_negozio)}
                            >
                                {i18n.t('search-page.result-card.open-app-btn')}
                            </ButtonCTA>
                        </div>
                        <div className={"col-12 pb-2 mt-n2"}>
                            <div>
                                {
                                    this.props.accetta_consegna === false &&
                                    <small className={"text-warning"}>
                                        <FaSolidIcon name={"exclamation-circle"}/> {i18n.t('search-page.result-card.food.no-delivery-today')}
                                    </small>
                                }
                            </div>
                            <div>
                                {
                                    this.props.accetta_asporto === false &&
                                    <small className={"text-warning"}>
                                        <FaSolidIcon name={"exclamation-circle"}/> {i18n.t('search-page.result-card.food.no-takeaway-today')}
                                    </small>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        {
                            this.props.food_free_shipping_value > 0 &&
                            <div className={"col-12 bg-soft-success py-2"}>
                                <small className={"font-weight-bold text-success"}>
                                    <FaSolidIcon name={"tags"}/> {i18n.t('search-page.result-card.food.free-delivery', {spending: this.props.food_free_shipping_value})}
                                </small>
                            </div>
                        }

                        {
                            (!!this.props.valutazione || !!this.props.fasciaPrezzo) &&
                            <div className={"col-12 p-1 border-top"}>
                                <RatingWrapper className={"align-items-center justify-content-center d-flex"}>
                                    {
                                        !!this.props.valutazione &&
                                        <Fragment>
                                            <FuxRating rating={this.props.valutazione}/>
                                            <span className={"text-success"}>({this.props.nvalutazioni})</span>
                                        </Fragment>
                                    }
                                    {
                                        !!this.props.fasciaPrezzo &&
                                        <Fragment>
                                            &nbsp;&nbsp;&nbsp;
                                            {i18n.t('search-page.result-card.food.spending-range')}: <span
                                            className={"text-success"}>{this.props.fasciaPrezzo}</span>
                                        </Fragment>
                                    }
                                </RatingWrapper>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

}


const BadgeDistance = styled.div`
  display:block;
  border: 1px solid ${props => props.color};
  border-radius: .25rem;
  color:${props => props.color};
  font-size: 0.85em;
  text-align: center;
`;

const Subtitle = styled.div`
  display: block;
  font-size: 0.9em;
  line-height: 1em;
  line-break: normal;
  white-space: normal;
  &::first-letter{
    text-transform: uppercase;
  }
`;

const ucword = str => {
    if (!str) return '';
    const words = str.split(' ');
    for (var i = 0; i < words.length; i++) {
        if (!words[i].length) continue;
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
}

