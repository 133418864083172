import React, {Fragment} from "react";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import Page from "../../../components/BaseComponents/Page/Page";
import {swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import IndirizziAPI from "../../../api/IndirizziAPI";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {ADDRESS_BOOK_CHANGE} from "../../../const/AppEventsCostants";
import RimborsiDipendenteAPI from "../../../api/Welfare/RimborsiDipendenteAPI";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";
import moment from "moment";
import WalletDipendentiAPI from "../../../api/Welfare/WalletDipendenteAPI";
import AppConfig from "../../../config/AppConfig";

export default class DipendenteWelfareDetailsRichiestaRimborso extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            descrizione: this.props.richiestaRimborso.descrizione ?? '',
            id_rimborso: this.props.richiestaRimborso.id_rimborso,
            immagini: null,
            newImages: []
        }

        this.state = {...this.state};

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    fetchData = _ => {
        RimborsiDipendenteAPI.getImmaginiRichiesta(this.props.user.walletapikey, this.state.id_rimborso)
            .then((data) => {
                console.log(data);
                this.setState({immagini: data});
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.active && this.props.active) {
            this.fetchData();
        }
    }


    handleSubmit(event) {
        event.preventDefault();
        swalLoading();
        const formData = new FormData();
        formData.append('descrizione', this.state.descrizione);
        formData.append('id_rimborso', this.state.id_rimborso);
        this.state.newImages.map((item, i) => {
            formData.append('newImages[]', item);
        });

        RimborsiDipendenteAPI.modificaRichiestaRimborso(this.props.user.walletapikey, formData)
            .then((message) => {
                swalSuccess(message);
                this.setState({newImages: []})
                this.props.onDismiss();
            })
            .catch(swalError)

    }


    handleInputChange = async (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        if (name.includes('newImage_')) {
            const file = target.files[0];
            const index = target.name.substring(9);

            let immagini = this.state.newImages
            immagini[index] = (await fetch(URL.createObjectURL(file)).then(r => r.blob()))

            this.setState({newImages: immagini});
            return;
        }

        this.setState({
            [name]: value,
        });
    }


    handleColorStatusRichiesta = status => {
        switch (status) {
            case "in_attesa":
                return <h2 className={"text-muted"}><i className="fas fa-clock"></i><br/>In attesa</h2>;
            case "rifiutato":
                return <h2 className={"text-danger"}><i className="fas fa-times"></i><br/>Rifiutato</h2>;
            case "accettato":
                return <h2 className={"text-warning"}><i className="fas fa-thumbs-up"></i><br/>Accettato</h2>;
            case "rimborsato":
                return <h2 className={"text-success"}><i className="fas fa-check-circle"></i><br/>Rimborsato</h2>;
        }
    }

    addNewFIle =_=>{
        let immagini = this.state.newImages
        immagini.push('')
        this.setState({newImages: immagini})
    }

    removeFile = index =>{
        let immagini = this.state.newImages
        immagini.splice(index, 1)
        console.log(index)
        this.setState({newImages: immagini})
    }

    render() {

        var momentDate = moment(this.props.richiestaRimborso.data_creazione);
        var m = momentDate.calendar(null, {
            sameDay: '[Oggi alle] HH:mm',
            nextDay: '[Domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[Ieri alle] HH:mm',
            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });

        return (
            <ModalPortal>
                <Page
                    title={"Dettagli Richiesta"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div className={"container pt-4"}>
                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    {this.handleColorStatusRichiesta(this.props.richiestaRimborso.status)}
                                    <h4>€ {this.props.richiestaRimborso.importo}</h4>
                                </div>

                                <div className={"col-12 text-center"}>
                                    <div className={"text-muted"}>{m[0].toUpperCase() + m.substr(1)}</div>
                                </div>
                            </div>

                            <div className={"card mt-4"}>
                                <div className={"card-body"}>
                                    <b>{this.props.richiestaRimborso.denominazione_attivita}</b>
                                    <br/>
                                    <form onSubmit={this.handleSubmit} className={"from-group mt-4"}>
                                        <label>Descrizione</label>
                                        <textarea disabled={this.props.richiestaRimborso.status === "in_attesa" ? false : true}
                                                  className={"form-control"} name="descrizione"
                                                  placeholder={"Inserisci una descrizione (opzionale)"}
                                                  value={this.state.descrizione}
                                                  onChange={this.handleInputChange}/>


                                        <label className={"mt-3"}>Allegati</label>
                                            {this.state.immagini && this.state.immagini.map((item, i) => {
                                                return (
                                                    <div className={"w-100 my-2"} key={i}>
                                                        <img className={"mr-3"} width={'100'} src={`${AppConfig.webServerWelfare}/assets/welfare/rimborsi/${this.state.id_rimborso}/${item.immagine}`}/>
                                                    </div>
                                                )
                                            })}

                                        {
                                            this.state.newImages.map((img, i) =>{
                                                return <div className={"d-flex justify-content-between mt-2"} key={i}>
                                                    <input className={"form-control w-75"} name={"newImage_"+i} value={img.value} type={"file"} onChange={this.handleInputChange} required/>
                                                    <button type={"button"} className={"btn btn-danger btn-sm"} onClick={_=>this.removeFile(i)}>Rimuovi</button>
                                                </div>

                                            })
                                        }

                                        <button type={"button"} className={"btn btn-link btn-sm"} onClick={_=>this.addNewFIle()}>+ Aggiungi file</button>

                                        {
                                            this.props.richiestaRimborso.status === "in_attesa" &&
                                            <div className={"col-12 mt-2 text-right"}>
                                                <button className="btn btn-primary" type="submit">Salva</button>
                                            </div>
                                        }

                                    </form>
                                </div>
                            </div>

                        </div>
                    }
                />
            </ModalPortal>
        );
    }
}