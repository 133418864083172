import React from "react";
import PropTypes from "prop-types";
import {FileManagerView} from "../../../../../../components/FileManager/FileManagerView";
import AppConfig from "../../../../../../config/AppConfig";
import APIConfig from "../../../../../../api/APIConfig";
import {EllipsisLoader} from "../../../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import FuxHTTP from "../../../../../../lib/FuxFramework/FuxHTTP";
import {ModuloServiziAPI} from "../../../../../../api/ModuloServizi/ModuloServiziAPI";

export class FilesView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
        this.uploadUrl = APIConfig.url("/modulo-servizi/admin/filemanager/upload-files");
        this.deleteUrl = APIConfig.url("/modulo-servizi/admin/filemanager/delete-file");
        this.uploadParams = {
            id_prenotazione: this.props.idParent
        }
    }

    componentDidMount() {
        this.fetchFiles();
    }


    fetchFiles = _ => {
        this.setState({
            files: null
        }, _ => {
            ModuloServiziAPI.Dashboard.getBookFiles(this.props.idParent)
                .then(f => this.setState({files: f}));
        });
    }


    render() {
        if (!this.state.files) return <EllipsisLoader/>
        return (
            <React.Fragment>
                <FileManagerView
                    title={"Gestione file"}
                    uploadUrl={this.uploadUrl}
                    uploadParams={this.uploadParams}
                    files={this.state.files}
                    onFileUpdateRequest={this.fetchFiles}
                    deleteUrl={this.deleteUrl}
                />
            </React.Fragment>
        )
    }

}

FilesView.propTypes = {
    idParent: PropTypes.any
}
