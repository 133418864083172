import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";

export default class StaticRating extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            iconSize: this.props.size ?? 1
        }
    }

    render() {
        return(
            <span>
                {
                    [...new Array(this.props.actualRating)].map((array, i) =>{
                            return <FaSolidIcon key={i} style={{color: "#f1a545"}} name={"star"} size={this.state.iconSize}/>
                        }
                    )
                }

                {
                    [...new Array(this.props.maxRating - this.props.actualRating)].map((array, i) =>{
                            return <FaSolidIcon key={i} style={{color: "#ccc"}} name={"star"} size={this.state.iconSize}/>
                        }
                    )
                }
            </span>
        )
    }
}

StaticRating.propTypes = {
    maxRating: PropTypes.number,
    actualRating: PropTypes.number,
    size: PropTypes.number //Default 1
}