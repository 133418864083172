import AuthAPI from "./AuthAPI";
import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AppConfig from "../config/AppConfig";

export const StripeAPI = {
    verifyPaymentIntent: function (intentId, id_negozio, is_direct) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            id: intentId,
            id_negozio: id_negozio,
            is_direct: is_direct ? 1 : 0
        };
        return FuxHTTP.apiGetRequestWithPromise(
            `${AppConfig.webServerUrl}/payment-gateway/stripe/verify-payment`, params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};