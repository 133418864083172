import React from "react";
import Swal from "sweetalert2";
import {swal} from "../../../../helpers/SweetAlertWrapper";
import {FOOD_ORDER_REJECT_REASONS} from "../../../../const/ModuloFoodCostants";

async function getFoodDeliveryRejectReasonByOptionList() {
    return new Promise(function (resolve, reject) {
        swal.fire({
            text: "Scegli il motivo del rifiuto",
            input: 'select',
            inputOptions: FOOD_ORDER_REJECT_REASONS,
            showCancelButton: true,
            cancelButtonText: 'Scrivi motivo',
            showCloseButton: true,
            reverseButtons: true
        }).then(function (result) {
            if (result.dismiss === Swal.DismissReason.backdrop || result.dismiss === Swal.DismissReason.close) {
                reject();
            }else if (result.dismiss === Swal.DismissReason.cancel) {
                getRejectCustomReason()
                    .then(function (reason) {
                        resolve(reason);
                    })
                    .catch(reject);
            }else if (result.value) {
                resolve(FOOD_ORDER_REJECT_REASONS[result.value]);
            }
        })
    });
}

async function getRejectCustomReason() {
    return new Promise(function (resolve, reject) {
        swal.fire({
            type: 'question',
            input: 'text',
            text: 'Scrivi il motivo del rifiuto',
            showConfirmButton: true, showCancelButton: true, reverseButtons: true,
            confirmButtonText: 'Procedi',
            cancelButtonText: 'Scegli da elenco'
        })
            .then(function (result) {
                if (result.dismiss === Swal.DismissReason.backdrop || result.dismiss === Swal.DismissReason.close) {
                    reject();
                }else if (result.dismiss === Swal.DismissReason.cancel) {
                    getFoodDeliveryRejectReasonByOptionList()
                        .then(function (reason) {
                            resolve(reason);
                        })
                        .catch(reject);
                }else if (result.value) {
                    resolve(result.value);
                }
            })
            .catch(reject);
    });
}

export {getFoodDeliveryRejectReasonByOptionList,getRejectCustomReason};
