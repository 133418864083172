import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import ModalPortalHOC from "../../components/BaseComponents/ModalPortalHOC";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {ChatRoomView} from "../../components/ChatPanel/ChatRoom/ChatRoomView";
import {DLA_ADMIN_CHAT_MESSAGE, DLA_USER_CHAT_MESSAGE} from "../../const/DeepLinkActionsCostants";
import {TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI} from "../../const/OperazioniConstants";
import {PageZLayerSelector} from "../../components/BaseComponents/Page/PageZLayerSelector";
import {GroupBookResumePage} from "../NextBooks/GroupBooks/GroupBookResume/GroupBookResumePage";
import {UserChatAPI} from "../../api/Chat/UserChatAPI";
import {AppIconThumbnail} from "../../components/BaseComponents/AppIconThumbnail";
import AppConfig from "../../config/AppConfig";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {
    CHAT_UPDATE_MESSAGES,
    DISPUTE_CHAT_ACCEPT_REFUND,
    DISPUTE_CHAT_REJECT_REFUND,
    UPDATE_USER_BOOKS,
    USER_DISPUTE_CHAT_READ_EVT
} from "../../const/AppEventsCostants";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import {formatCurrency} from "../../helpers/CurrencyHelpers";
import {DisputesAPI} from "../../api/Disputes/DisputesAPI";
import {UserAdminChatAPI} from "../../api/Chat/UserAdminChatAPI";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class UserWithBusinessDisputePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
        }
    }

    componentDidMount() {
        FuxEvents.on(DISPUTE_CHAT_ACCEPT_REFUND, this.handleProposalAccept);
        FuxEvents.on(DISPUTE_CHAT_REJECT_REFUND, this.handleProposalReject);
    }

    componentWillUnmount() {
        FuxEvents.off(DISPUTE_CHAT_ACCEPT_REFUND, this.handleProposalAccept);
        FuxEvents.off(DISPUTE_CHAT_REJECT_REFUND, this.handleProposalReject);
    }


    /**
     * @MARK: Panel utility
     * */

    isSwipeDisabled = _ => false;

    handleClose = _ => {
        this.setState({roomData: null});
        this.props.onClose();
    }


    /**
     * @MARK: Operation details
     * */

    toggleDetail = _ => this.setState({detailOpen: !this.state.detailOpen});

    getDetailPage = _ => {
        if (this.props.disputeData.tipo_operazione === TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI) {
            return (
                <GroupBookResumePage
                    onDismiss={this.toggleDetail}
                    idPrenotazione={this.props.disputeData.id_operazione}
                    showOpenApp={true}
                />
            )
        }
        return '';
    }

    /**
     * @MARK: Refund proposals
     * */

    handleProposalAccept = ({id_messaggio, metadata}) => {
        swalConfirm(`Sei sicuro di voler accettare questa proposta di rimborso di ${formatCurrency(metadata.amount, 'EUR', 'it-IT')}?`)
            .then(_ => {
                swalLoading('Attendere prego...');
                DisputesAPI.User.acceptRefund(id_messaggio)
                    .then(m => {
                        FuxEvents.emit(UPDATE_USER_BOOKS);
                        swalSuccess(m).then(_ => {
                            FuxEvents.emit(USER_DISPUTE_CHAT_READ_EVT, false); //Aggiornamento elenco chat
                            this.props.onClose();
                        });
                    }).catch(swalError);
            });
    }

    handleProposalReject = ({id_messaggio, metadata}) => {
        swalConfirm(`Sei sicuro di voler rifiutare questa proposta di rimborso di ${formatCurrency(metadata.amount, 'EUR', 'it-IT')}?`)
            .then(_ => {
                swalLoading('Attendere prego...');
                DisputesAPI.User.rejectRefund(id_messaggio)
                    .then(m => {
                        swalSuccess(m);
                        FuxEvents.emit(USER_DISPUTE_CHAT_READ_EVT, false); //Aggiornamento elenco chat
                        FuxEvents.emit(CHAT_UPDATE_MESSAGES, this.props.disputeData.id_chat_room); //Aggiornamento messaggi in chat
                    }).catch(swalError);
            });
    }

    render() {
        const dispute = this.props.disputeData;
        const imageUrl = `${AppConfig.webServerUrl}/api/negozio/${dispute.id_negozio}/logoIcon/100`;
        return (
            <Fragment>
                <div style={BackdropStyle}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.handleClose}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <div className={"container-fluid rounded-top overflow-hidden bg-white py-2"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <div className={"d-flex align-items-center w-75"}>
                                <AppIconThumbnail
                                    className={"mr-2 shadow-sm"}
                                    size={"32px"}
                                    src={imageUrl}
                                />
                                <div className={"text-truncate w-75"}>
                                    <SafeHtmlContainer html={`${dispute.nome}`}/>
                                </div>
                            </div>
                            <div className={"d-flex align-items-center text-right"}>
                                <button className={"btn btn-link text-primary"} onClick={this.handleClose}>
                                    <FaSolidIcon name={"times"}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"__disable-swipe"}>
                        <ChatRoomView
                            idRoom={this.props.disputeData.id_room}
                            idSelf={dispute.id_utente1}
                            getMessageAPI={UserChatAPI.getMessages}
                            sendMessageAPI={UserChatAPI.sendTextMessage}
                            getMediaContentUrlAPI={UserChatAPI.getMediaContentUrl}
                            setReadAPI={UserChatAPI.setRead}
                            sendNotificationAPI={UserChatAPI.sendMessageNotification}
                            fetchMessageEventName={DLA_USER_CHAT_MESSAGE}
                        />
                    </div>
                </SwipeablePanel>
                <ModalPortalHOC>
                    <PageZLayerSelector zIndex={3000}>
                        {
                            this.state.detailOpen && this.getDetailPage()
                        }
                    </PageZLayerSelector>
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

UserWithBusinessDisputePanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    disputeData: PropTypes.object,
}