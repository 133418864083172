import React, {Fragment} from 'react';

import styled from "styled-components";
import {swal} from "../../helpers/SweetAlertWrapper";
import {MenuDigitaleSearchBar} from "./MenuDigitaleSearchBar";
import MenuDigitaleCardProdotto from "./MenuDigitaleCardProdotto";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import {CategoryImageSize} from "../../const/ImageSizeCostants";

export default class MenuCategoryGalleryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productSearchResults: null
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.categoryList !== this.props.categoryList ||
            nextState.productSearchResults !== this.state.productSearchResults;
    }

    handleCategoriaChoose = categoria => {
        if (categoria.child.length) {
            this.props.onCategoryChoose(categoria);
        } else {
            this.props.onCategoryLeafChoose(categoria);
        }
    }

    handleSearch = (queryString, sortType) => {
        let productSearchResults = this.props.onSearch(queryString, sortType);
        this.setState({
            productSearchResults: productSearchResults
        });
    }

    allItemsMissImage = _ => {
        for (let i in this.props.categoryList) {
            if (this.props.categoryList.hasOwnProperty(i)) {
                if (this.props.categoryList[i].immagine && this.props.categoryList[i].immagine.indexOf('placeholder') === -1) {
                    return false;
                }
            }
        }
        return true;
    }

    render() {
        const showAsList = this.allItemsMissImage();
        const containerClassName = showAsList ? "list-group mt-2" : "row";
        const itemsWrapperClassName = showAsList ? "list-group-item d-flex align-items-center justify-content-between" : "col-6 col-md-4 col-lg-3 my-2";

        return (
            <div className={"container pt-3 pb-5"}>
                {
                    !!this.props.onSearch &&
                    <MenuDigitaleSearchBar
                        onSearch={this.handleSearch}
                    />
                }

                {
                    this.state.productSearchResults ?
                        this.state.productSearchResults.map((p, i) => {
                                return this.props.searchResultItemGenerator(p, i);
                            }
                        )
                        :
                        <div className={containerClassName}>
                            {
                                this.props.categoryList.map((categoria, i) => {
                                    return (
                                        <div key={categoria.id_categoria}
                                             className={itemsWrapperClassName}
                                             onClick={_ => this.handleCategoriaChoose(categoria)}>
                                            <CategoriaListItem showAsList={showAsList} {...categoria}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }

            </div>
        );
    }
}


const CategoryImage = styled.div`
    background-image:url(${props => props.src});
    background-size:cover;
    background-position:center center;
    width:100%;
    height:40vw;
    max-height:200px;
`;

function CategoriaListItem(props) {
    if (props.showAsList) {
        return (
            <Fragment>
                <b>{props.nome}</b>
                {
                    props.showAsList &&
                    <FaSolidIcon name={"chevron-right"}/>
                }
            </Fragment>
        );
    } else {
        return (
            <div className={"card"}>
                <CategoryImage
                    src={getCompressedImageUrl(props.immagine, CategoryImageSize.thumbnail.w, CategoryImageSize.thumbnail.h)}
                    className={"card-img-top"}/>
                <div className={"card-body p-2"}>
                    <div className={'card-text d-flex align-items-center justify-content-between'}>
                        <b>{props.nome}</b>
                    </div>
                </div>
            </div>
        );
    }
}
