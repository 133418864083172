import {
    getDescrizioneIva,
    getScontoAPagare,
    getSubtotalOfItem,
    getTotaleItems,
    getTotaleIva,
    getTotaleScontrino
} from "../../ReceiptsUtils";
import {FaIcon, FaRegularIcon, FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import React from "react";
import PropTypes, {string} from "prop-types";
import KeypadInterfaceConfirmPanel from "./KeypadInterfaceConfirmPanel";
import ReceiptItem from "../ReceiptItem";
import "./keypadInterface.css"
import {ModuloCassaAPI} from "../../../../../../api/ModuloCassa/ModuloCassaAPI";
import FavoriteItems from "../FavoriteItems";


export default class KeypadInterface extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputAreaPrice: "",
            inputAreaQuantita: "",
            quantitaMode: false,
            confirmPanelOpen: false,
            settings: []
        }
        this.initialState = {
            inputAreaPrice: "",
            inputAreaQuantita: "",
            quantitaMode: false
        }
        this.scrollPaneRef = null;
    }


    componentDidMount() {
        ModuloCassaAPI.Dashboard.getKeypadSettings()
            .then(data => this.setState({settings: data}))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.items.length !== prevProps.items.length && this.scrollPaneRef !== null) {
            setTimeout(_ => this.scrollPaneRef.scrollTop = this.scrollPaneRef.scrollHeight, 100)
        }
    }


    keyPadClicked(btn, val = null) {
        switch (btn) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 0:
                this.handleNumberClick(btn)
                break
            case ",":
                //Non permetto l'inserimento della virgola come primo carattere oppure inserimento doppio
                if (this.state.inputAreaPrice === "" || this.state.inputAreaPrice.includes(".")) return
                this.setState({inputAreaPrice: this.state.inputAreaPrice + "."})
                break
            case "canc":
                this.handleCancClick()
                break
            case "quantitaMode":
                this.setQuantitaMode()
                break
            case "iva":
                this.handleIvaClicked(val)
                break
        }
    }

    /**
     * Permette di settare la modalità quantità e impostare tutti i valori nella maniera corretta
     * @param quantitaMode
     */
    setQuantitaMode = (quantitaMode = true) => {
        if (quantitaMode) {
            //Se passo in quantità mode do alla quantità il valore attuale del prezzo
            this.setState({quantitaMode: true, inputAreaQuantita: this.state.inputAreaPrice, inputAreaPrice: ""})
        } else {
            //Se esco da quantità mode do al prezzo quello che c'é in quantità
            this.setState({quantitaMode: false, inputAreaQuantita: "", inputAreaPrice: this.state.inputAreaQuantita})
        }
    }

    /**
     * Gestione del tasto cancella
     */
    handleCancClick = _ => {
        //Nel caso la quantità è vuota e scelgo di cancellare ancora, cambio modalità
        if (this.state.quantitaMode && this.state.inputAreaPrice === "") {
            this.setQuantitaMode(false)
            return
        }

        this.setState({inputAreaPrice: this.state.inputAreaPrice.slice(0, -1)})
    }

    /**
     * Permette di gestire i click sui tasti e indirizzare l'insermento nel campo giusto
     * in base alle diverse modalità
     */
    handleNumberClick = n => {
        //Non permette di inserire più di 2 caratteri dopo la virgola
        if (this.state.inputAreaPrice.includes(".") && this.state.inputAreaPrice.split(".")[1].length === 2) return
        //Non permette di superare le 8 cifre intere
        if (!this.state.inputAreaPrice.includes(".") && this.state.inputAreaPrice.length === 8) return
        this.setState({inputAreaPrice: this.state.inputAreaPrice + n})
    }

    //Restituisce gli errori dovuti agli inserimenti
    errors = _ => {
        if (!this.state.inputAreaPrice) {
            return `<span class="warning">Inserisci un importo</span>`
        }

        if (this.state.inputAreaPrice && parseFloat(this.state.inputAreaPrice) <= 0) {
            return `<span class="danger">Attenzione l'importo non può essere zero</span>`
        }

        if (this.state.inputAreaQuantita && parseFloat(this.state.inputAreaQuantita) <= 0) {
            return `<span class="danger">Attenzione la quantità non può essere zero</span>`
        }
    }

    handleIvaClicked = iva => {
        if (this.errors()) {
            return
        }

        let item = {
            descrizione: (this.state.settings.defaultItemName || "Elemento") + " " + (this.props.items.length + 1),
            importo_unitario: parseFloat(this.state.inputAreaPrice),
            quantita: this.state.inputAreaQuantita || 1,
            sconto: "",
            codice_aliquota: iva,
            sconto_documento: ""
        };

        this.setState(this.initialState)
        this.props.addItem(item)
    }

    //List item methods
    handleScrollPaneRef = el => this.scrollPaneRef = el;

    //Panel
    toggleConfirmPanel = _ => {this.setState({confirmPanelOpen: !this.state.confirmPanelOpen})}

    handleFavoriteItemClick = item =>{
        this.props.addItem(item)
    }


    render() {
        let keypadClass = " m-0 p-4 btn rounded-0 "
        let aliquoteKeypad = this.state.settings.aliquoteKeypad || []

        return (
            <div className={"keypad-interface-container"}>

                {/*Items*/}
                <div className={"p-3"}>
                    <div className={"card shadow-sm border-0 mb-3 receipt-items-container"} ref={this.handleScrollPaneRef}>
                        <div className={"card-body p-0 list-group-flush"}>
                            {
                                this.props.items.length > 0 && this.props.items.map((item, i) => {
                                    return <ReceiptItem item={item} key={i} deleteItem={this.props.deleteItem}/>
                                })
                            }
                        </div>
                    </div>
                </div>

                {/*KEYPAD*/}
                <div className={"keypad-container"}>
                    <div className={"row text-center p-0 m-0"}>
                        <div className={"col-12 p-0 m-0"}>
                            <FavoriteItems
                                onItemClick = {this.handleFavoriteItemClick}
                            />
                        </div>
                        <div className={"col-9 p-3 d-flex justify-content-between align-items-center"}>
                            <h2 className={"font-weight-bold"}>
                                {this.state.inputAreaPrice}
                            </h2>
                            {
                                (this.state.quantitaMode || this.state.inputAreaQuantita !== "") &&
                                <h5 onClick={_ => this.setQuantitaMode(false)}>
                                    x {this.state.inputAreaQuantita}
                                </h5>
                            }
                        </div>
                        <button type={"button"} className={"col-3 btn-primary " + keypadClass}
                                onClick={_ => this.keyPadClicked("canc")}><FaSolidIcon name={"backspace"} size={1}/>
                        </button>

                        <button type={"button"} className={"col-3 " + keypadClass}
                                onClick={_ => this.keyPadClicked(7)}>7
                        </button>
                        <button type={"button"} className={"col-3 " + keypadClass}
                                onClick={_ => this.keyPadClicked(8)}>8
                        </button>
                        <button type={"button"} className={"col-3  " + keypadClass}
                                onClick={_ => this.keyPadClicked(9)}>9
                        </button>
                        <button type={"button"} className={"col-3 btn-success " + keypadClass}
                                onClick={_ => this.keyPadClicked("iva", aliquoteKeypad[0] || 22)}> {aliquoteKeypad[0] || 22}
                        </button>

                        <button type={"button"} className={"col-3  " + keypadClass}
                                onClick={_ => this.keyPadClicked(4)}>4
                        </button>
                        <button type={"button"} className={"col-3  " + keypadClass}
                                onClick={_ => this.keyPadClicked(5)}>5
                        </button>
                        <button type={"button"} className={"col-3  " + keypadClass}
                                onClick={_ => this.keyPadClicked(6)}>6
                        </button>
                        <button type={"button"} className={"col-3 btn-success " + keypadClass}
                                onClick={_ => this.keyPadClicked("iva", aliquoteKeypad[1] || 10)}> {aliquoteKeypad[1] || 10}
                        </button>

                        <button type={"button"} className={"col-3  " + keypadClass}
                                onClick={_ => this.keyPadClicked(1)}>1
                        </button>
                        <button type={"button"} className={"col-3  " + keypadClass}
                                onClick={_ => this.keyPadClicked(2)}>2
                        </button>
                        <button type={"button"} className={"col-3  " + keypadClass}
                                onClick={_ => this.keyPadClicked(3)}>3
                        </button>
                        <button type={"button"} className={"col-3 btn-success " + keypadClass}
                                onClick={_ => this.keyPadClicked("iva", aliquoteKeypad[2] || 4)}> {aliquoteKeypad[2] || 4}
                        </button>

                        <button type={"button"} className={"col-6  " + keypadClass}
                                onClick={_ => this.keyPadClicked(0)}>0
                        </button>
                        <button type={"button"} className={"col-3  " + keypadClass}
                                onClick={_ => this.keyPadClicked(",")}>,
                        </button>
                        <button type={"button"} className={"col-3 btn-warning " + keypadClass}
                                onClick={_ => this.keyPadClicked("quantitaMode")}>&times;
                        </button>

                    </div>
                    <div className={"px-3 pt-2 bg-white"}>
                        <button className={"btn btn-primary btn-block my-3"} onClick={_ => this.toggleConfirmPanel()}
                                disabled={!this.props.items.length > 0}>Procedi (Tot.
                            €{getTotaleItems(this.props.items, false).toFixed(2)})
                        </button>
                    </div>
                </div>

                {
                    this.state.confirmPanelOpen &&
                    <KeypadInterfaceConfirmPanel
                        useNavbar={true}
                        expanded={true}
                        onToggle={this.toggleConfirmPanel}
                        //Items
                        items={this.props.items}
                        //Metodi pagamento
                        metodi_pagamento={this.props.metodi_pagamento}
                        changeMetodoPagamento={this.props.changeMetodoPagamento}
                        addMetodoPagamento={this.props.addMetodoPagamento}
                        deleteMetodoPagamento={this.props.deleteMetodoPagamento}
                        //Sconti
                        errorsScontoDocumento={this.props.errorsScontoDocumento}
                        sconto_documento={this.props.sconto_documento}
                        changeScontoDocumento={this.props.changeScontoDocumento}
                        //Submit
                        submitReceipt={this.props.submitReceipt}
                    />
                }
            </div>
        )
    }
}

KeypadInterface.propTypes = {
    //Sconto
    sconto_documento: PropTypes.string,
    errorsScontoDocumento: PropTypes.func,
    changeScontoDocumento: PropTypes.func,
    //Items
    items: PropTypes.array,
    addItem: PropTypes.func,
    deleteItem: PropTypes.func,
    //Metodi Pagamento
    metodi_pagamento: PropTypes.array,
    addMetodoPagamento: PropTypes.func,
    deleteMetodoPagamento: PropTypes.func,
    changeMetodoPagamento: PropTypes.func,
    errorsMetodiPagamento: PropTypes.func,
    //Submit
    submitReceipt: PropTypes.func,
}

