import React from "react";

function shadeColor(color, percent) {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export const BirthdayCakeIllustration = props => {
    const lightColor = shadeColor(props.color, +80);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={props.className} style={props.style} data-name="Layer 1" width="649.3779"
             height="727.77798" viewBox="0 0 1077.87045 818.13939"><title>Birthday cake</title>
            <path
                d="M724.88913,132.77315C678.637,165.15693,618.64686,163.06077,566.0755,146.21272S465.2185,99.36712,413.9902,78.0458A494.18659,494.18659,0,0,0,280.38075,43.2116c-65.27725-7.46816-138.17815,1.629-183.6634,53.98071C46.06687,155.489,49.9558,260.63994,104.74175,314.16073c27.86523,27.22173,64.3542,40.58007,96.17591,61.83552s61.27768,56.54522,58.56766,97.56983c-2.50884,37.97919-31.67671,67.1203-61.86034,85.4044-23.33,14.13247-52.06519,29.56582-54.877,58.83474-2.72081,28.32129,21.43765,50.51207,44.50822,63.13276,75.26244,41.17208,167.99042,40.7761,242.96352-1.03754,26.75083-14.91935,51.33838-34.75613,79.39114-46.38368,73.6499-30.52707,154.80787.20352,232.79573,11.43993A465.4391,465.4391,0,0,0,940.40967,630.544c38.62329-11.40826,77.38928-28.84831,104.52167-61.3378,19.56546-23.42852,31.75437-53.07661,43.19758-82.35738q18.30444-46.83727,35.18876-94.3336c6.75709-19.00815,13.40626-38.38513,15.15148-58.71982,3.16313-36.85563-10.159-73.28074-28.30108-104.38505C1067.09817,155.56778,994.559,103.64386,916.0413,90.45336s-161.71258,12.571-222.64322,68.9421"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff" opacity="0.1"/>
            <path
                d="M969.20342,578.9804l-2.78768-1.6121c2.606-4.50561-4.388-23.6855-10.16161-33.34366-4.00573-6.70054-6.51846-12.79214-7.68236-18.62319-1.85316-9.28413-.09284-17.33381,5.23238-23.92538,11.04722-13.6744,10.95161-39.778,10.94925-40.03985l3.22-.02885c.01018,1.11657.11332,27.51363-11.66457,42.0925-7.82879,9.69069-6.91723,22.76927,2.709,38.87274C963.55434,549.96083,973.24482,571.99265,969.20342,578.9804Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M1004.47715,433.65992c.73072-26.15292-16.46834-47.85115-38.41521-48.46435s-40.33063,20.09086-41.06134,46.24379c-.70948,25.39283,15.48424,46.58433,36.51445,48.356a13.88746,13.88746,0,0,1-3.25839,7.15025c-3.18843,3.25617,10.02792.28018,10.02792.28018a23.47942,23.47942,0,0,1-1.4247-7.4045C987.25417,478.31163,1003.785,458.43186,1004.47715,433.65992Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M936.57363,451.12029c-3.2502-9.95233-.25316-19.94507,6.68944-22.9455-.32111.073-.64062.15961-.95767.26315-7.59894,2.48164-11.00956,12.91264-7.61784,23.2983,3.29314,10.0838,11.78807,16.41654,19.23453,14.50467C954.0733,467.03117,939.86127,461.18724,936.57363,451.12029Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
            <path
                d="M985.70033,615.21555l3.21738.13587c.2192-5.20037,16.41432-17.63033,26.46937-22.68106,6.97606-3.50395,12.36555-7.29537,16.4771-11.59083,6.54635-6.83918,9.38122-14.57609,8.42553-22.9959-1.9825-17.46712,12.10769-39.44145,12.25024-39.66117l-2.70147-1.75247c-.60785.93668-14.86191,23.15462-12.7483,41.77705,1.40487,12.37844-6.38342,22.92464-23.14841,31.34612C1006.04142,593.76124,986.04053,607.15045,985.70033,615.21555Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M1033.92912,473.66605c13.41945-22.45947,39.57689-31.53743,58.4243-20.27616s23.2476,38.5973,9.82815,61.05677c-13.02944,21.80671-38.06664,30.99666-56.76235,21.20489a13.88742,13.88742,0,0,0-1.08811,7.782c.94278,4.45868-8.61172-5.14548-8.61172-5.14548a23.47928,23.47928,0,0,0,5.17605-5.48314C1024.49739,520.58565,1021.21827,494.93956,1033.92912,473.66605Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M1081.85392,524.84189c8.08377-6.65322,10.91793-16.69337,6.6702-22.9511.2318.23389.45489.47849.66684.736,5.08,6.17223,2.35955,16.80415-6.07619,23.747-8.19056,6.74111-18.75713,7.5254-24.01422,1.91576C1058.54884,528.87527,1073.677,531.57173,1081.85392,524.84189Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
            <path
                d="M413.76212,745.00788l-3.21738.13586c-.21921-5.20037-16.41432-17.63032-26.46938-22.68106-6.97606-3.50394-12.36555-7.29536-16.47709-11.59082-6.54636-6.83918-9.38122-14.57609-8.42554-22.99591,1.9825-17.46712-12.10768-39.44145-12.25023-39.66116l2.70146-1.75248c.60785.93668,14.86191,23.15462,12.74831,41.77705-1.40487,12.37844,6.38342,22.92465,23.14841,31.34613C393.421,723.55357,413.42191,736.94277,413.76212,745.00788Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M365.53333,603.45838c-13.41945-22.45947-39.57689-31.53743-58.4243-20.27617s-23.2476,38.5973-9.82815,61.05677c13.02944,21.80672,38.06663,30.99667,56.76235,21.2049a13.8874,13.8874,0,0,1,1.08811,7.782c-.94278,4.45868,8.61172-5.14547,8.61172-5.14547a23.47942,23.47942,0,0,1-5.17605-5.48315C374.96506,650.378,378.24418,624.73188,365.53333,603.45838Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M317.60853,654.63421c-8.08377-6.65321-10.91793-16.69336-6.67021-22.95109-.23179.23389-.45488.47849-.66683.736-5.08,6.17223-2.35956,16.80415,6.07618,23.74705,8.19057,6.74111,18.75713,7.5254,24.01422,1.91576C340.91361,658.66759,325.78541,661.36406,317.60853,654.63421Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
            <path
                d="M519.76212,407.00788l-3.21738.13586c-.21921-5.20037-16.41432-17.63032-26.46938-22.68106-6.97606-3.50394-12.36555-7.29536-16.47709-11.59082-6.54636-6.83918-9.38122-14.57609-8.42554-22.99591,1.9825-17.46712-12.10768-39.44145-12.25023-39.66116l2.70146-1.75248c.60785.93668,14.86191,23.15462,12.74831,41.777-1.40487,12.37844,6.38342,22.92465,23.14841,31.34613C499.421,385.55357,519.42191,398.94277,519.76212,407.00788Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M471.53333,265.45838c-13.41945-22.45947-39.57689-31.53743-58.4243-20.27617s-23.2476,38.5973-9.82815,61.05677c13.02944,21.80672,38.06663,30.99667,56.76235,21.2049a13.8874,13.8874,0,0,1,1.08811,7.782c-.94278,4.45868,8.61172-5.14547,8.61172-5.14547a23.47942,23.47942,0,0,1-5.17605-5.48315C480.96506,312.378,484.24418,286.73188,471.53333,265.45838Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M423.60853,316.63421c-8.08377-6.65321-10.91793-16.69336-6.67021-22.95109-.23179.23389-.45488.47849-.66683.736-5.08,6.17223-2.35956,16.80415,6.07618,23.74705,8.19057,6.74111,18.75713,7.5254,24.01422,1.91576C446.91361,320.66759,431.78541,323.36406,423.60853,316.63421Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
            <path
                d="M482.76212,550.00788l-3.21738.13586c-.21921-5.20037-16.41432-17.63032-26.46938-22.68106-6.97606-3.50394-12.36555-7.29536-16.47709-11.59082-6.54636-6.83918-9.38122-14.57609-8.42554-22.99591,1.9825-17.46712-12.10768-39.44145-12.25023-39.66116l2.70146-1.75248c.60785.93668,14.86191,23.15462,12.74831,41.777-1.40487,12.37844,6.38342,22.92465,23.14841,31.34613C462.421,528.55357,482.42191,541.94277,482.76212,550.00788Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M434.53333,408.45838c-13.41945-22.45947-39.57689-31.53743-58.4243-20.27617s-23.2476,38.5973-9.82815,61.05677c13.02944,21.80672,38.06663,30.99667,56.76235,21.2049a13.8874,13.8874,0,0,1,1.08811,7.782c-.94278,4.45868,8.61172-5.14547,8.61172-5.14547a23.47942,23.47942,0,0,1-5.17605-5.48315C443.96506,455.378,447.24418,429.73188,434.53333,408.45838Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M386.60853,459.63421c-8.08377-6.65321-10.91793-16.69336-6.67021-22.95109-.23179.23389-.45488.47849-.66683.736-5.08,6.17223-2.35956,16.80415,6.07618,23.74705,8.19057,6.74111,18.75713,7.5254,24.01422,1.91576C409.91361,463.66759,394.78541,466.36406,386.60853,459.63421Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
            <path
                d="M926.69461,739.25489l-2.04355-2.48874c4.0229-3.30276,4.21344-23.7172,2.20127-34.78811-1.39615-7.68075-1.60844-14.26682-.64945-20.135,1.52689-9.34333,6.00319-16.26135,13.30481-20.5617C954.655,652.36,963.7368,627.88707,963.82661,627.641l3.02483,1.10431c-.38277,1.049-9.56062,25.79936-25.70982,35.31071-10.73443,6.32229-14.476,18.88733-11.12136,37.34627C931.6015,710.10065,932.93346,734.13255,926.69461,739.25489Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M1010.77685,615.59223c9.87277-24.22886,1.39373-50.58653-18.93851-58.8715s-44.81821,4.64011-54.691,28.869c-9.58584,23.52469-1.87,49.05471,17.197,58.10228a13.88748,13.88748,0,0,1-5.56285,5.54959c-4.12919,1.92835,9.29017,3.78556,9.29017,3.78556a23.47942,23.47942,0,0,1,1.26765-7.433C978.96383,651.34608,1001.42541,638.5417,1010.77685,615.59223Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M941.06782,608.082c.45369-10.45977,6.77053-18.76245,14.32471-19.13236-.32627-.04452-.65586-.07565-.98908-.0901-7.98639-.3464-14.84443,8.22129-15.31787,19.13648-.45968,10.598,5.26871,19.5116,12.91216,20.33787C951.86166,629.12693,940.60891,618.66227,941.06782,608.082Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
            <ellipse cx="605.48305" cy="706.71134" rx="302.44355" ry="22.4762" fill="#6c63ff" opacity="0.1"/>
            <polygon points="580.006 121.167 580.006 156.496 583.71 156.496 583.71 119.458 580.006 121.167"
                     fill="#53425a"/>
            <polygon points="655.792 130.569 655.792 163.334 660.921 163.334 659.781 126.865 655.792 130.569"
                     fill="#53425a"/>
            <polygon points="509.919 136.837 506.5 140.541 506.5 172.451 509.919 172.451 509.919 136.837"
                     fill="#53425a"/>
            <path
                d="M585.22893,271.785H563.006V207.97087h17.09459a8.56,8.56,0,0,0-.39319,1.51289c-.66666,3.66677-.46154,10.58156.1624,18.50486C581.357,246.98074,585.22893,271.785,585.22893,271.785Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M662.72724,271.785l-27.924,3.41892V192.01309h17.66724c-.11962.94018-.19657,2.00859-.23929,3.19668a140.553,140.553,0,0,0,1.10258,18.77838c.59832,5.419,1.38466,11.23971,2.24794,17.02625.66672,4.48733,1.38466,8.949,2.0941,13.197C660.24,259.45121,662.72724,271.785,662.72724,271.785Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M737.94343,263.23766l-27.35134,2.28216v-66.6689h18.804c-.66666,1.41883-.95729,3.81206-.96585,6.8549a122.57618,122.57618,0,0,0,1.47016,16.41937C732.5672,240.801,737.94343,263.23766,737.94343,263.23766Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M575.542,113.09307s1.70946,6.83783-16.23986,25.64188-10.82658,56.69706,8.26238,55.55742c0,0,17.84548,2.94358,24.26486-9.20527a16.26781,16.26781,0,0,0-1.139-16.66938c-5.89878-8.68687-17.276-26.94276-15.14837-35.66588Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffc13d"/>
            <path
                d="M571.93328,138.93476s1.06773,4.27091-10.14339,16.01589-6.76227,35.4129,5.16067,34.70108c0,0,11.39533,1.87964,15.28678-6.006a10.1816,10.1816,0,0,0-.85506-10.17386c-3.48195-5.12973-9.99506-15.56287-9.59472-21.29267.06257-.8956.14572-1.78964.14572-2.68743Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.5"/>
            <path
                d="M723.67907,104.51706s1.70946,6.83783-16.23986,25.64188-10.82657,56.69706,8.26239,55.55742c0,0,17.84547,2.94358,24.26485-9.20527a16.26779,16.26779,0,0,0-1.139-16.66938c-5.89879-8.68686-17.276-26.94276-15.14838-35.66587Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffc13d"/>
            <path
                d="M721.41408,145.27352s.8,3.2002-7.60048,12.00077-5.067,26.535,3.86691,26.00165c0,0,8.35194,1.37764,11.3563-4.30819a7.61358,7.61358,0,0,0-.53307-7.80151c-2.76072-4.06557-8.0854-12.60959-7.08966-16.69213Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.5"/>
            <path
                d="M648.10044,79.78733s2.07871,8.31482-19.74771,31.18059-13.16514,68.94375,10.04708,67.55795c0,0,21.70014,3.57941,29.50612-11.19363a19.78166,19.78166,0,0,0-1.385-20.27c-7.17294-10.56325-21.00761-32.76246-18.42046-43.36979Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffc13d"/>
            <path
                d="M645.81169,121.0586s1.15975,4.639-11.01769,17.39635-7.34513,38.46526,5.60549,37.69209c0,0,12.107,1.997,16.46212-6.24517a11.03664,11.03664,0,0,0-.77274-11.30909c-4.00194-5.89348-11.72062-18.27891-10.27718-24.197Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.5"/>
            <path
                d="M579.86976,227.98862c-5.22238,5.49591-12.4363,15.33385-16.8638,21.56486V227.00572c4.88053-5.88055,11.87221-12.86367,16.7014-17.522C579.0407,213.15053,579.24582,220.06532,579.86976,227.98862Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.5"/>
            <path
                d="M653.33377,213.98815a199.17332,199.17332,0,0,0-18.53053,16.85529v-21.5563a99.76217,99.76217,0,0,1,17.428-14.07737A140.553,140.553,0,0,0,653.33377,213.98815Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.5"/>
            <path
                d="M657.67581,244.21142c-6.8635,6.01728-16.30825,14.57313-22.87257,20.53913V249.32269c6.855-6.30791,15.23984-13.57311,20.77847-18.30829C656.24843,235.50173,656.96637,239.96339,657.67581,244.21142Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M729.90045,222.12519c-6.59,5.52157-14.23127,13.11158-19.30836,18.334V218.96268c5.39334-5.36767,12.54742-10.11141,17.8382-13.25686A122.57618,122.57618,0,0,0,729.90045,222.12519Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.5"/>
            <rect x="453.79426" y="246.23978" width="278.92389" height="127.64532" fill="#c99398"/>
            <path
                d="M793.78292,287.17009v19.21435c-5.3335,1.03419-12.32518-.56415-18.18011-2.52146a24.1701,24.1701,0,0,0-20.90665,2.63258c-16.06895,10.5046-28.61638,8.28233-37.3688,3.05989a32.191,32.191,0,0,0-29.12919-2.24789,31.62676,31.62676,0,0,1-12.91495,2.36757,27.26651,27.26651,0,0,0-14.65858,4.0429c-13.197,7.9917-26.35133,3.36759-34.61655-1.4616a24.40043,24.40043,0,0,0-17.898-2.5813,25.785,25.785,0,0,1-7.949.6496,29.82535,29.82535,0,0,0-17.55613,3.78645c-21.787,12.72693-28.98385-2.72659-33.27461-5.00015-4.8463-2.56419-5.41045,6.26513-6.83783,6.26513s-6.26519-11.67558-7.41049-7.69257c-.52993,1.87186-5.01726,4.11981-9.63282,5.98311a19.218,19.218,0,0,1-10.59011,1.09408V287.17009Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M525.97262,261.72212s-45.71376,20.40038-21.32,45.891a18.99689,18.99689,0,0,0,20.79823,4.34267c4.61229-1.8591,9.09915-4.10715,9.63391-5.9788,1.13964-3.98874,5.98311,7.69257,7.40766,7.69257s1.99437-8.83221,6.83783-6.268c4.29018,2.27127,11.48632,17.73112,33.27042,4.99755a29.88085,29.88085,0,0,1,17.5643-3.784,25.57433,25.57433,0,0,0,7.94279-.65068,24.42806,24.42806,0,0,1,17.9011,2.57955c8.26488,4.83,21.42127,9.45336,34.61417,1.4682a27.26077,27.26077,0,0,1,14.66319-4.0486,31.46519,31.46519,0,0,0,12.90972-2.36943,32.23663,32.23663,0,0,1,29.13319,2.24885c8.75173,5.2288,21.30162,7.44766,37.36683-3.05448a24.1437,24.1437,0,0,1,20.90269-2.63919c11.68411,3.91417,27.91865,6.40973,26.44662-10.70267C799.766,264.95,768.42595,257.25744,755.3201,256.97253s-34.759-2.56419-59.26125,0-74.36146,2.27928-74.36146,2.27928S537.97688,255.92765,525.97262,261.72212Z"
                transform="translate(-61.06478 -40.93031)" fill="#f8f8fa"/>
            <rect x="396.80946" y="373.8851" width="397.87658" height="156.27021" fill="#c99398"/>
            <path
                d="M793.78292,388.15639v26.659H638.67519q-.39753-.05125-.79494-.077a20.86814,20.86814,0,0,0-3.94029.077H514.859v-23.77c12.97478,1.58123,26.06069,3.58988,38.95858,3.5386,17.12026-.077,34.03534-3.6326,51.18976-2.67531,59.97636,3.33341,121.04679,4.32493,180.78385-2.77787C788.44942,388.81455,791.11617,388.4812,793.78292,388.15639Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M855.75081,414.81541v14.09448c-4.32493-.547-8.48745-2.27356-12.66709-3.64116-3.76934-1.23081-7.84641-2.1881-11.67559-1.171-2.0172.53848-3.85483,1.59834-5.84637,2.21377-8.32506,2.59836-17.00908-2.81209-25.73589-2.84626-8.64986-.03417-16.38515,5.17966-24.82988,7.07718-13.05174,2.93172-26.394-2.21377-38.89875-6.98317-5.06-1.92315-10.92347-3.76078-15.65009-1.12825-2.077,1.15391-3.64116,3.05139-5.66687,4.29931-5.92326,3.65827-13.67567.80345-19.58188-2.889-3.73516-2.33339-8.25666-5.07709-12.197-3.11123-2.03426,1.00858-3.3078,3.05139-4.99164,4.56428-5.33351,4.77791-13.727,3.49582-20.26561.58977-6.53014-2.9061-12.73549-7.19681-19.86395-7.72679-10.05158-.76067-18.85533,6.01733-27.62482,10.9833s-20.93236,7.90625-28.08645.7949c-2.36756-2.35051-4.1198-5.75232-7.3592-6.56432-5.35917-1.33338-9.38492,5.29933-14.78681,6.46175-6.65838,1.43594-12.07734-5.64975-18.75277-7.043-6.90623-1.44449-13.33381,3.35908-19.428,6.90618s-14.94926,5.54723-19.36819.05133c-1.41028-1.75224-2.17955-4.1113-4.11125-5.27372a8.23976,8.23976,0,0,0-4.7523-.76072,48.28961,48.28961,0,0,0-16.76123,4.00868c-6.08567,2.68387-11.97477,6.70966-18.616,6.58143-.11962,0-.2393-.00855-.359-.01706V414.81541Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M553.81434,396.2929c-30.52022.12919-62.04306-11.3139-90.817-1.13741-10.4405,3.6925-21.029,12.42945-19.92809,23.44882a16.08175,16.08175,0,0,0,15.16183,13.99129c6.64583.12838,12.53348-3.90136,18.61606-6.582a48.23892,48.23892,0,0,1,16.76677-4.01339,8.28854,8.28854,0,0,1,4.753.76727c1.928,1.15674,2.70008,3.52168,4.10938,5.27352,4.42088,5.49545,13.26969,3.49469,19.3653-.05322s12.52636-8.35316,19.43022-6.91063c6.67,1.39365,12.08971,8.48267,18.75064,7.04643,5.39973-1.1643,9.42524-7.79193,14.78555-6.45783,3.24447.8075,4.99258,4.20694,7.36357,6.56428,7.14781,7.10667,19.31229,4.16878,28.08228-.79943s17.57221-11.74161,27.62338-10.98672c7.13314.53573,13.33311,4.828,19.87015,7.73241s14.93261,4.18424,20.26041-.58893c1.68926-1.5134,2.96278-3.56046,4.99346-4.57057,3.93911-1.9594,8.46274.78428,12.19394,3.11537,5.90792,3.691,13.66249,6.5482,19.5889,2.887,2.01867-1.24709,3.588-3.14367,5.661-4.29821,4.72765-2.633,10.59585-.79551,15.65211,1.13266,12.50125,4.76725,25.84952,9.9135,38.90289,6.97792,8.43711-1.89742,16.17949-7.106,24.82727-7.07412,8.72291.03211,17.40825,5.44354,25.73608,2.84788,1.98918-.62,3.82748-1.677,5.84081-2.21343,3.83579-1.022,7.90741-.0656,11.68031,1.16845,5.00614,1.63742,9.99053,3.7923,15.25762,3.80752s11.02793-2.86,12.288-7.97416c.9646-3.9149-.91453-7.9726-3.25943-11.2526-8.51489-11.91046-23.27858-18.03985-37.82877-19.6692s-29.21245.64422-43.75131,2.37166c-59.73875,7.09789-120.8111,6.10982-180.78208,2.77458C587.85467,392.66413,570.9374,396.22042,553.81434,396.2929Z"
                transform="translate(-61.06478 -40.93031)" fill="#f8f8fa"/>
            <rect x="369.88548" y="516.05227" width="439.93783" height="181.20265" fill="#c99398"/>
            <path
                d="M872.83685,552.0166a519.32412,519.32412,0,0,1-66.72874,9.91484,45.84426,45.84426,0,0,0-22.51355,1.718c-.13678.04272-.27352.08545-.4103.13673-13.86369,4.90619-25.79573,16.539-40.49709,16.351-11.73542-.15384-21.88105-7.90625-33.15494-11.15421a42.23114,42.23114,0,0,0-9.89778-1.61545,43.4249,43.4249,0,0,0-6.82928.18807c-13.63294.359-27.26584.50426-40.89878.22224-20.00069-.40175-39.95861-1.72657-59.91657-3.04284q-32.001-2.11548-64.0021-4.23092-30.10362-1.98723-60.2157-3.97451-7.38487-.4872-14.78681-.98291c-5.30789-.35047-9.24818.79489-11.39356-3.94885-2.54713-5.67541-.29919-11.32516,2.8206-16.26552,2.68386.05128,5.37623.23935,8.05153.42736q60.97641,4.295,121.92719,8.88921l-6.24807,1.10258A682.06118,682.06118,0,0,1,696.50616,537.811c20.04342.65811,40.1979,2.19666,60.096-.359,13.98336-1.79491,27.6847-5.58991,41.73646-6.778,19.29975-1.63251,38.64228,1.7009,57.737,5.02581a116.104,116.104,0,0,1,11.93205,2.53C869.02479,543.11884,870.16154,547.99936,872.83685,552.0166Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M870.88809,556.98258v17.03475c-5.4874-.812-10.86363-1.61545-14.1971,2.28211-1.56411,1.83769-2.171,4.33349-3.69238,6.2139-3.1369,3.8719-9.26529,3.8634-13.74407,1.66674-4.47027-2.19666-7.88063-6.043-11.73547-9.20546a46.66119,46.66119,0,0,0-44.33481-7.76951c-13.86369,4.90619-25.79573,16.539-40.49709,16.351-11.73542-.15385-21.88105-7.90625-33.15494-11.15421a44.78974,44.78974,0,0,0-37.779,6.419c-7.98314,5.66686-15.52186,14.462-25.24013,13.34231-5.09421-.58976-9.37641-3.90612-13.70134-6.66688a72.89966,72.89966,0,0,0-28.69328-10.63283c-4.45311-.6325-9.17979-.82056-13.28248,1.02568-5.1711,2.3334-8.51312,7.48744-13.3167,10.51316-11.15421,7.01735-25.61621.68378-37.11234-5.75231-11.50467-6.43614-25.73589-13.02607-37.12945-6.41047-2.68386,1.55561-4.98308,3.77789-7.82079,5.02581C479.43049,586.29979,463.69494,559.1963,447.25,565.188c-3.70955,1.35049-6.66688,4.26509-10.3166,5.76943a12.36821,12.36821,0,0,1-5.9831.83761V556.98258Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M574.3952,546.35549q-60.95452-4.58713-121.93034-8.885c-7.05844-.49751-14.29773-.97386-21.08084,1.04075s-13.12151,7.08092-14.57243,14.00653c-2.19351,10.47014,10.23123,20.79764,20.124,16.72713,3.65133-1.50239,6.60662-4.41991,10.31685-5.7703,16.44216-5.98439,32.18,21.11323,48.20052,14.07749,2.84064-1.24753,5.13753-3.46238,7.82058-5.02021,11.39733-6.61752,25.6285-.02378,37.12917,6.41247s25.96162,12.76192,37.118,5.74583c4.80482-3.02169,8.1408-8.17553,13.31563-10.50751,4.10424-1.84954,8.82484-1.663,13.28113-1.02493a72.77513,72.77513,0,0,1,28.69594,10.63272c4.32394,2.75614,8.60687,6.07051,13.70048,6.66035,9.719,1.12546,17.25985-7.676,25.23738-13.34036a44.82021,44.82021,0,0,1,37.78062-6.42169c11.27221,3.25,21.42144,11.00426,33.15182,11.157,14.70313.19145,26.63606-11.44248,40.49649-16.35242a46.685,46.685,0,0,1,44.34037,7.77212c3.85411,3.16471,7.25985,7.00548,11.73593,9.20416s10.60421,2.20775,13.74426-1.66647c1.52069-1.87624,2.12239-4.37738,3.6922-6.21272,6.41716-7.50261,20.39158,2.41224,28.17191-3.66523a10.65272,10.65272,0,0,0,3.5614-5.86348c1.95046-7.77429-2.93317-15.96775-9.622-20.384s-14.83663-5.88933-22.73286-7.26531c-19.087-3.32605-38.429-6.66492-57.7346-5.03172-14.05,1.18858-27.75122,4.98941-41.73679,6.78385-19.89389,2.55253-40.04886,1.01561-60.09508.35892a682.05255,682.05255,0,0,0-128.36461,7.93591"
                transform="translate(-61.06478 -40.93031)" fill="#f8f8fa"/>
            <path
                d="M870.88809,688.85022v42.57407q-25.25727,1.29494-50.5231,2.57274c-27.19745,1.38466-54.42061,2.76932-81.64372,2.23939-23.00936-.453-45.96738-2.265-68.96813-2.983-48.36061-1.50433-96.858,1.87186-145.08177-2-15.43642-1.23937-30.82155-3.21379-46.28363-4.09419a340.601,340.601,0,0,0-47.43748.68377V687.73052a37.31411,37.31411,0,0,1,4.36765-.10256,55.84923,55.84923,0,0,1,22.0264,5.32495c5.39334,2.55568,10.50461,6.02588,16.38515,7.043,15.29968,2.64109,28.59071-11.81236,44.0784-12.96628,12.47053-.92307,23.676,6.81222,34.37725,13.28253,2.05988,1.23937,4.35055,2.48724,6.71816,2.13682,1.983-.29063,3.61549-1.64962,5.23094-2.83771,15.26546-11.26532,38.52267-9.89778,52.35218,3.08557,1.50434,1.40176,3.00011,2.983,4.98309,3.50437,3.92318,1.04279,7.44466-2.359,10.55589-4.97448,16.85524-14.21416,42.83048-9.94051,62.173.6581,3.58132,1.95737,7.54728,4.1882,11.50468,3.16251,2.64964-.68377,4.69246-2.718,6.81217-4.45316,16.47065-13.51327,41.2407-11.00036,60.51484-1.91458,1.86335.88039,3.82922,1.82913,5.872,1.59834,2.45307-.29058,4.33343-2.17955,6.2224-3.76078a31.73577,31.73577,0,0,1,33.37718-4.13692c3.04283,1.46161,5.86348,3.436,9.09433,4.4275,9.20545,2.81209,18.5562-2.90605,27.74454-5.8036A47.66956,47.66956,0,0,1,870.88809,688.85022Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M917.24861,730.77469q-48.43756,2.47451-96.88362,4.9318c-27.19745,1.38466-54.42061,2.76932-81.64372,2.23939-23.00936-.453-45.96738-2.265-68.96813-2.983-48.36061-1.50433-96.858,1.87186-145.08177-2-15.43642-1.23937-30.82155-3.2138-46.28363-4.09419a344.33108,344.33108,0,0,0-52.18978,1.10263c-3.70954.35042-9.41913,2.20517-13.009,1.47867-3.87189-.78634-2.39323-1.23081-1.12819-4.89759a32.43645,32.43645,0,0,0,.95729-3.32491c1.05986-4.48733,1.25643-9.01738,1.69235-13.67567.5641-5.94893,1.43594-12.49614,6.35919-16.539,3.8719-3.18813,9.23107-3.83773,14.24832-3.67533a55.84906,55.84906,0,0,1,22.0264,5.325c5.39334,2.55568,10.50461,6.02588,16.38515,7.043,15.29968,2.64109,28.59071-11.81237,44.0784-12.96628,12.47053-.92307,23.676,6.81222,34.37725,13.28253,2.05988,1.23937,4.35055,2.48724,6.71816,2.13682,1.983-.29063,3.61549-1.64962,5.23094-2.83771,15.26546-11.26533,38.52267-9.89778,52.35218,3.08556,1.50434,1.40177,3.00011,2.983,4.98309,3.50437,3.92318,1.0428,7.44466-2.359,10.55589-4.97448,16.85524-14.21415,42.83048-9.9405,62.173.65811,3.58132,1.95736,7.54728,4.1882,11.50468,3.16251,2.64964-.68377,4.69246-2.718,6.81217-4.45316,16.47065-13.51327,41.2407-11.00036,60.51484-1.91458,1.86335.88039,3.82922,1.82913,5.872,1.59834,2.45307-.29058,4.33343-2.17955,6.2224-3.76078a31.73577,31.73577,0,0,1,33.37718-4.13692c3.04283,1.46161,5.86348,3.436,9.09433,4.4275,9.20545,2.81209,18.5562-2.906,27.74454-5.8036a48.03718,48.03718,0,0,1,60.002,30.51385A45.82008,45.82008,0,0,1,917.24861,730.77469Z"
                transform="translate(-61.06478 -40.93031)" fill="#f8f8fa"/>
            <path
                d="M917.24861,730.77469q-48.43756,2.47451-96.88362,4.9318c-27.19745,1.38466-54.42061,2.76932-81.64372,2.23939-23.00936-.453-45.96738-2.265-68.96813-2.983-48.36061-1.50433-96.858,1.87186-145.08177-2-15.43642-1.23937-30.82155-3.2138-46.28363-4.09419a344.33108,344.33108,0,0,0-52.18978,1.10263c-3.70954.35042-9.41913,2.20517-13.009,1.47867-3.87189-.78634-2.39323-1.23081-1.12819-4.89759a32.43645,32.43645,0,0,0,.95729-3.32491H915.34253A45.82008,45.82008,0,0,1,917.24861,730.77469Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M339.48168,683.15189H861.84321a17.03368,17.03368,0,0,1,17.03368,17.03368v2.19773a0,0,0,0,1,0,0H322.448a0,0,0,0,1,0,0v-2.19773A17.03368,17.03368,0,0,1,339.48168,683.15189Z"
                fill="#d4d5e0"/>
            <rect x="526.10833" y="275.90213" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(41.81662 -180.84306) rotate(16.71337)" fill="#6c63ff"/>
            <rect x="480.54425" y="401.36964" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(75.9745 -162.43941) rotate(16.7134)" fill="#6c63ff"/>
            <rect x="431.74495" y="546.6512" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(115.69372 -142.26811) rotate(16.7134)" fill="#6c63ff"/>
            <rect x="553.76243" y="556.30133" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(123.62343 -176.95072) rotate(16.71339)" fill="#6c63ff"/>
            <rect x="626.20969" y="550.86385" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(125.12019 -198.01511) rotate(16.71339)" fill="#6c63ff"/>
            <rect x="728.33345" y="561.8857" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(132.60376 -226.91845) rotate(16.71336)" fill="#6c63ff"/>
            <rect x="798.17856" y="545.77913" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(130.92294 -247.68567) rotate(16.71341)" fill="#6c63ff"/>
            <rect x="491.42064" y="552.65367" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(119.94079 -159.17627) rotate(16.71339)" fill="#6c63ff"/>
            <rect x="639.50425" y="567.28945" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(130.40522 -201.14431) rotate(16.71336)" fill="#6c63ff"/>
            <rect x="700.60922" y="550.86385" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(128.26288 -219.41104) rotate(16.71337)" fill="#6c63ff"/>
            <rect x="829.66051" y="545.77913" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(132.25251 -256.73908) rotate(16.71338)" fill="#6c63ff"/>
            <rect x="686.29737" y="274.17495" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-5.05549 626.77908) rotate(-53.67407)" fill="#6c63ff"/>
            <rect x="648.24594" y="271.33166" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-18.27512 594.96399) rotate(-53.67411)" fill="#6c63ff"/>
            <rect x="586.46185" y="271.33166" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-43.45988 545.18668) rotate(-53.67408)" fill="#6c63ff"/>
            <rect x="504.15728" y="397.15699" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-178.38161 530.16616) rotate(-53.67407)" fill="#6c63ff"/>
            <rect x="508.84965" y="409.86887" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-186.71029 539.12851) rotate(-53.67409)" fill="#6c63ff"/>
            <rect x="610.4159" y="398.16644" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-135.8815 616.18604) rotate(-53.67407)" fill="#6c63ff"/>
            <rect x="570.12832" y="563.59733" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-285.58466 651.16155) rotate(-53.67409)" fill="#6c63ff"/>
            <rect x="720.41864" y="404.72028" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-96.32206 707.48249) rotate(-53.67408)" fill="#6c63ff"/>
            <rect x="765.66045" y="410.72339" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-82.71689 746.37917) rotate(-53.67409)" fill="#6c63ff"/>
            <rect x="751.97074" y="545.77913" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-197.10654 790.40058) rotate(-53.67402)" fill="#6c63ff"/>
            <rect x="845.20915" y="561.85512" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-172.05216 872.07244) rotate(-53.67405)" fill="#6c63ff"/>
            <rect x="528.97732" y="552.65367" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-293.54189 613.54666) rotate(-53.67407)" fill="#6c63ff"/>
            <rect x="461.08617" y="546.6512" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-316.37991 556.40225) rotate(-53.67404)" fill="#6c63ff"/>
            <rect x="595.81123" y="557.15606" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-269.92634 669.22717) rotate(-53.67406)" fill="#6c63ff"/>
            <rect x="668.50417" y="552.65367" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(-236.66773 725.95757) rotate(-53.67405)" fill="#6c63ff"/>
            <rect x="760.16357" y="278.02903" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(490.62469 -543.32851) rotate(54.67721)" fill="#6c63ff"/>
            <rect x="679.98692" y="289.82435" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(466.42842 -472.93624) rotate(54.67719)" fill="#6c63ff"/>
            <rect x="547.39176" y="405.92621" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(505.22607 -315.77707) rotate(54.67721)" fill="#6c63ff"/>
            <rect x="587.48252" y="409.86887" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(525.35388 -346.82434) rotate(54.6772)" fill="#6c63ff"/>
            <rect x="664.98083" y="409.86887" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(558.04365 -410.05578) rotate(54.67718)" fill="#6c63ff"/>
            <rect x="689.20854" y="401.64767" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(561.5558 -433.29121) rotate(54.67719)" fill="#6c63ff"/>
            <rect x="746.55325" y="404.72028" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(588.2517 -478.7831) rotate(54.67719)" fill="#6c63ff"/>
            <rect x="788.39533" y="398.16644" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(600.5542 -515.6869) rotate(54.67719)" fill="#6c63ff"/>
            <rect x="845.20915" y="401.64767" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(627.3595 -560.57326) rotate(54.67719)" fill="#6c63ff"/>
            <rect x="670.86587" y="269.54135" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(46.10259 -222.74173) rotate(16.71337)" fill="#6c63ff"/>
            <rect x="717.85446" y="267.45604" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(47.48805 -236.34318) rotate(16.71339)" fill="#6c63ff"/>
            <rect x="653.34572" y="286.45432" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(50.2265 -216.98889) rotate(16.71339)" fill="#6c63ff"/>
            <rect x="561.43896" y="289.82435" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(47.31296 -190.41544) rotate(16.71337)" fill="#6c63ff"/>
            <rect x="725.06245" y="282.24167" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(52.04475 -237.79158) rotate(16.7134)" fill="#6c63ff"/>
            <rect x="553.76243" y="269.54135" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(41.15569 -189.06474) rotate(16.71338)" fill="#6c63ff"/>
            <rect x="602.03861" y="278.02903" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(45.63593 -202.58954) rotate(16.71337)" fill="#6c63ff"/>
            <rect x="572.55044" y="401.36964" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(79.86117 -188.89887) rotate(16.71339)" fill="#6c63ff"/>
            <rect x="644.67252" y="401.36964" width="3.13401" height="8.65782" rx="1.38793"
                  transform="matrix(0.95776, 0.28758, -0.28758, 0.95776, 82.90794, -209.64006)" fill="#6c63ff"/>
            <rect x="812.13641" y="404.72028" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(90.94561 -257.65809) rotate(16.71335)" fill="#6c63ff"/>
            <rect x="829.66051" y="404.72028" width="3.13401" height="8.65782" rx="1.38793"
                  transform="matrix(0.95776, 0.28758, -0.28758, 0.95776, 91.68591, -262.69774)" fill="#6c63ff"/>
            <rect x="621.31191" y="289.82435" width="3.13401" height="8.65782" rx="1.38793"
                  transform="translate(49.84231 -207.634) rotate(16.71338)" fill="#6c63ff"/>
            <ellipse cx="437.41895" cy="770.76644" rx="179.00005" ry="16.10035" fill="#6c63ff" opacity="0.1"/>
            <ellipse cx="198.97245" cy="702.9892" rx="28.5981" ry="2.89702" fill="#6c63ff" opacity="0.1"/>
            <ellipse cx="122" cy="775.5068" rx="28.5981" ry="2.89702" fill="#6c63ff" opacity="0.1"/>
            <ellipse cx="907.92661" cy="766.80571" rx="28.5981" ry="2.89702" fill="#6c63ff" opacity="0.1"/>
            <ellipse cx="838.42141" cy="797.49528" rx="28.5981" ry="2.89702" fill="#6c63ff" opacity="0.1"/>
            <ellipse cx="756.2078" cy="747.95854" rx="28.5981" ry="2.89702" fill="#6c63ff" opacity="0.1"/>
            <ellipse cx="626.7996" cy="815.24236" rx="28.5981" ry="2.89702" fill="#6c63ff" opacity="0.1"/>
            <path
                d="M601.666,467.5231l-12.72823,27.06356s-1.87495,5.07843-3.08564,6.02126c-1.2.94284-6.557,17.01384-6.557,17.01384l-13.66035,21.053v.01073l-.93211,1.4357-5.357-1.47854-3.7499-14.03537.22509-4.07131.032-.51425s8.44263-8.82832,10.31758-9.50331c0,0,1.46782.1714,4.2856-6.67486,2.81779-6.85692,5.357-8.32473,5.357-8.32473l1.46781-4.96059,15.81387-25.17789s13.25321-19.00662,16.86382-10.57471C613.58,463.2375,601.666,467.5231,601.666,467.5231Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffd2c6"/>
            <path
                d="M447.69508,495.96874a18.5176,18.5176,0,0,0-1.47841,4.52135c-.08579.39641-.17146.8142-.26785,1.24279-.87862,4.51062-1.47866,10.189-1.77856,13.47823-.15,1.66065-.22508,2.72133-.22508,2.72133s-31.9704-2.1428-26.43135-7.4998a11.8335,11.8335,0,0,0,2.92491-5.31417q.2411-.8517.41786-1.73566a29.66672,29.66672,0,0,0,.50365-4.04985c.02132-.34286.032-.68572.04277-1.02857a42.48622,42.48622,0,0,0-.32134-6.62124s10.40325-.06429,18.23511.83572C444.58813,493.12951,448.70226,494.1688,447.69508,495.96874Z"
                transform="translate(-61.06478 -40.93031)" fill="#d38884"/>
            <path
                d="M548.78175,659.75361a2.94067,2.94067,0,0,1-.42859,1.29642c-1.46781,2.1428-9.77116,45.9309-9.77116,45.9309.66426,2.95707-1.2,8.84977-2.41065,11.52827s-3.88918,16.6067-3.88918,16.6067-.66427,29.99919-2.39993,34.6812c-1.74639,4.682.55715,14.73175.55715,14.73175-.17146,4.61773,2.60356,9.50331,4.25343,11.989.11783.16067.22508.32141.32134.4607.5357.77137.88934,1.21068.88934,1.21068a7.125,7.125,0,0,0,1.875,2.3142c.11784.09645.2464.19284.375.2893a21.46342,21.46342,0,0,0,7.51052,3.17136c.08566.02139.17146.03211.25712.05356,1.0714.23568,1.76784.33213,1.76784.33213l.66426,3.62134-7.09266,1.46781-13.52106.93211s-15.94243,1.21069-12.32109-5.8927a12.43416,12.43416,0,0,0,.65353-1.5h.01073c.12856-.34286.25712-.69637.36437-1.06068,2.17484-6.82481.707-15.11743.707-15.11743l-3.08564-24.23506c-5.07842-22.49939,4.007-47.54872,4.007-47.54872s-.79283-2.00351.95356-7.09266,0-16.19956,0-16.19956-.93212-14.33533-2.1428-21.428a43.18793,43.18793,0,0,1-.48208-9.02123,45.53057,45.53057,0,0,1,.48208-5.282l30.53489-4.04986S549.146,656.9251,548.78175,659.75361Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffd2c6"/>
            <path
                d="M548.35316,807.97108l-7.09266,1.46781-13.52106.93211s-15.94243,1.21069-12.32109-5.8927a12.43416,12.43416,0,0,0,.65353-1.5h.01073c.12856-.34286.25712-.69637.36437-1.06068a24.75125,24.75125,0,0,1,8.07826,1.62855c4.54273,1.74639,7.96056-2.81779,7.96056-2.81779l2.207-4.21059.32134-.6107c.5357.77137.88934,1.21068.88934,1.21068a7.22486,7.22486,0,0,0,2.24992,2.6035l-.375.7821-2.1428,4.51061s2.74285.27858,6.49275.87856a2.91179,2.91179,0,0,0,3.53554-1.92851c.08566.02139.17146.03211.25712.05356,1.0714.23568,1.76784.33213,1.76784.33213Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M554.92085,811.31384c-.5357,2.41065-15.471,3.14992-15.471,3.14992s-8.30335-.80355-10.63906,1.13568c-2.34643,1.93923-9.18184,1.47853-9.44969,1.47853s-3.87859-.13928-5.29278-6.62124c-1.37129-6.32128,1.84291-7.42486,2.00351-7.47841h.01073a24.27885,24.27885,0,0,1,8.44263,1.63927c4.54273,1.74639,7.96056-2.81779,7.96056-2.81779l2.52836-4.82129c.5357.77137.88934,1.21068.88934,1.21068a7.22494,7.22494,0,0,0,2.24992,2.6035l-2.51777,5.29271s2.74285.27858,6.49275.87856c2.99984.48214,3.65338-1.96068,3.79266-2.94635a3.48278,3.48278,0,0,0,.03218-.40714s2.33571.675,5.21771,1.47854A5.38461,5.38461,0,0,1,554.92085,811.31384Z"
                transform="translate(-61.06478 -40.93031)" fill="#626696"/>
            <path
                d="M583.04509,691.67059c-.35365,2.1428-.71775,7.682-.71775,7.682,1.25345,4.63911-1.96075,15.66386-1.96075,15.66386l-1.6071,6.29983c.71775,8.0355-4.41416,27.67421-4.41416,27.67421s-3.43928,18.2138-5.22844,26.0779a21.6541,21.6541,0,0,0,.93212,13.13537c.08566.21429.13928.32141.13928.32141.03218.15.06435.28929.10712.43931a6.16671,6.16671,0,0,0,.19291.63209,9.52561,9.52561,0,0,0,1.757,2.98918,8.39671,8.39671,0,0,0,.96441.98573c.02145.02139.03205.04283.05349.05356a19.27042,19.27042,0,0,0,5.68907,3.5035h.01072l.01073.01066q.46613.19293.90007.35358a25.415,25.415,0,0,0,2.8391.88928h.01073c.20363.05355.32147.075.32147.075l-.71775,4.21059-9.46055-2.42137-12.67474-4.1142-6.23549-3.56772s.06422-.34285.19291-.99639c.01073-.03217.01073-.075.02145-.10718.05349-.27857.10711-.61071.18205-.98567.975-5.52839,3.39639-20.98875,2.1857-32.37773-1.51071-14.09959,4.36067-33.56691,5.79618-38.20609a23.1077,23.1077,0,0,0,1.0714-8.21761c-2.32485-3.39639-2.86055-16.96028-2.86055-16.96028s-4.2856-8.75332-7.832-18.0424a47.316,47.316,0,0,1-2.5498-9.38541,32.69413,32.69413,0,0,1-.51426-6.857s28.75621-26.24929,31.91691-.83566c.10712.85711.20364,1.682.3,2.48559C584.65219,685.31719,583.388,689.60279,583.04509,691.67059Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffd2c6"/>
            <path
                d="M583.04509,798.457l-.71775,4.21059-9.46055-2.42137-12.67474-4.1142-6.23549-3.56772s.06422-.34285.19291-.99639c.01073-.03217.01073-.075.02145-.10718.05349-.27857.10711-.61071.18205-.98567l.88935-.08573.07494-.01072a11.779,11.779,0,0,1,5.30351.71788c2.06773.77138,4.76767,1.66066,5.32482,1.28563a6.70118,6.70118,0,0,1,3.032-.5357l1.3178-2.882.19291-.43931a9.52561,9.52561,0,0,0,1.757,2.98918,9.34745,9.34745,0,0,0,1.0179,1.03929l-.05349,1.01784-.07507,1.44637a18.64911,18.64911,0,0,0,4.55345,2.1,2.64025,2.64025,0,0,0,1.26418.01073h.01072l.01073.01066q.46613.19293.90007.35358a25.415,25.415,0,0,0,2.8391.88928h.01073C582.92725,798.43559,583.04509,798.457,583.04509,798.457Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M578.30959,807.43538c-2.63574-.76072-9.01064-1.97141-10.03914-1.02857s-9.86755,0-9.86755,0a5.375,5.375,0,0,1-4.55345-2.6785c-1.83205-2.86062-.35365-12.09608-.35365-12.09608l.65367-.06428,1.09285-.10718.07494-.01072a11.779,11.779,0,0,1,5.30351.71788c2.06773.77138,4.76767,1.66066,5.32482,1.28563a6.70118,6.70118,0,0,1,3.032-.5357l1.51071-3.32131a9.52561,9.52561,0,0,0,1.757,2.98918,9.34745,9.34745,0,0,0,1.0179,1.03929l-.12856,2.4642a18.649,18.649,0,0,0,4.55345,2.1,1.8394,1.8394,0,0,0,2.1857-.69643,1.66388,1.66388,0,0,0,.1606-.36424l.01073.01066c.08579.15.95369.45,3.73917,1.24286h.01073c3.02142-.17146,5.94632,1.1464,7.28557,5.24982C592.41984,807.74606,580.94519,808.1853,578.30959,807.43538Z"
                transform="translate(-61.06478 -40.93031)" fill="#626696"/>
            <path
                d="M548.78175,659.75361c-.58932.17146-1.125.33213-1.62855.49287a23.73619,23.73619,0,0,1-3.94268.83566c-8.45349,1.1893-22.38168.47142-26.80644.19284a45.53057,45.53057,0,0,1,.48208-5.282l30.53489-4.04986S549.146,656.9251,548.78175,659.75361Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M575.41673,652.33954s-11.20693,1.73567-20.32455,3.72845c-3.04261.66427-5.86053,1.3607-7.939,2.03569a23.73416,23.73416,0,0,1-3.94268.83566c-10.15685,1.425-28.19931.10718-28.19931.10718-4.94986-16.071,6.02126-36.299,6.02126-36.299-.25713-1.6071,2.28209-6.557,2.28209-6.557l.04289-.16074,1.56421-5.732H566.567l.51425,2.46421,1.0608,5.01414,6.53538,31.04914Z"
                transform="translate(-61.06478 -40.93031)" fill="#626696"/>
            <path
                d="M581.86658,662.07853l-.24654.10718-4.87479,2.16418c-5.27133,2.4321-21.06375,2.8928-26.58135,2.93569a32.69413,32.69413,0,0,1-.51426-6.857s28.75621-26.24929,31.91691-.83566C581.67367,660.45005,581.77019,661.275,581.86658,662.07853Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M582.638,659.59294l-1.01791.45-4.87479,2.16418c-5.36772,2.475-21.66367,2.91425-26.88138,2.93569-.63222,0-1.10357,0-1.37142-.01072-2.41065-.075-4.8213-2.33564-4.8213-2.925,0-.3214-.2464-1.90705-.46063-3.26775-.18218-1.09279-.34292-2.03562-.34292-2.03562s-10.714-4.63918-16.19956-8.38908c-4.09269-2.78561-3.06419-22.5744-2.27136-32.52768.27857-3.42849.525-5.68913.525-5.68913l42.15955,2.46421,5.91414.34285,3.38566.75.89994.19284s2.42138,17.67809,3.62134,19.14591.40714,6.4284.26785,9.24618a17.46816,17.46816,0,0,1-.39641,3.61061s1.73566,4.96059,1.32852,7.73548A15.42008,15.42008,0,0,0,582.638,659.59294Z"
                transform="translate(-61.06478 -40.93031)" fill="#626696"/>
            <path
                d="M392.33594,648.1075c-10.89605-.3643-2.86055-23.03509-1.6071-26.09928s-1.86422-10.51043-1.86422-10.51043-3.67483-21.61018-3.67483-23.03509a57.37555,57.37555,0,0,0-1.243-8.75338c-.5357-1.96062.17159-6.5998,1.425-8.5712,1.25346-1.96062,1.0714-9.814,1.0714-9.814v-10.1783l2.42138-3.2142,6.21417,5.87125,1.7248,1.62855,3.03215,11.37826-1.0714,16.296-.5357,15.88881,3.7499,21.9637S403.232,648.46108,392.33594,648.1075Z"
                transform="translate(-61.06478 -40.93031)" fill="#d38884"/>
            <polygon
                points="334.667 515.573 325.378 511.726 325.378 510.216 327.8 507.001 334.014 512.873 334.667 515.573"
                opacity="0.1"/>
            <path
                d="M391.44311,533.28561a43.16072,43.16072,0,0,0-2.57646,9.1069,40.75016,40.75016,0,0,1-2.42341,8.74977l10.35687,4.2856Z"
                transform="translate(-61.06478 -40.93031)" fill="#4c4981"/>
            <path
                d="M391.44311,533.28561a43.16072,43.16072,0,0,0-2.57646,9.1069,40.75016,40.75016,0,0,1-2.42341,8.74977l10.35687,4.2856Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.05"/>
            <path
                d="M473.63376,758.78308l-.857,1.82139s-.93225,6.0641-.93225,6.20338c0,.12857-2.6785,4.18921-2.6785,4.18921l-6.73914,1.43564-2.86056-.34279a38.22844,38.22844,0,0,0-.76065-4.94993c-.10724-.46069-.21423-.93211-.34292-1.41419a37.37149,37.37149,0,0,0-4.70333-10.66044s-4.99289-12.32109-6.782-13.92819-8.5712-17.86028-7.85345-21.78158c.38582-2.16425.07507-9.37474-.27857-15.396-.28917-4.907-.61051-9.03189-.61051-9.03189l16.96008-10.039,1.97147,3.11775a.0394.0394,0,0,0,.01072.03217l1.17852,1.8535s-.01073.35358-.06422.94284c0,.06428-.01072.13929-.01072.21429a30.73717,30.73717,0,0,1-1.51071,7.41407s-1.275,9.1069,0,10.714c1.24286,1.55354,5.3035,23.08865,6.20331,33.02054.03217.31069.05362.61071.07507.89994.35365,4.56418,3.182,8.63555,6.02126,11.614l.03218.03218c.18218.19284.375.38569.56774.57853A39.88393,39.88393,0,0,0,473.63376,758.78308Z"
                transform="translate(-61.06478 -40.93031)" fill="#d38884"/>
            <path
                d="M459.56627,772.0899l2.86064.34284,6.7391-1.43567s2.6785-4.06061,2.6785-4.18918c0-.13928.93212-6.2034.93212-6.2034l.85712-1.82138.10714-.225a37.37591,37.37591,0,0,1-4.63916-3.81419l-.03214-.03214c-.16071-.15-.31071-.3-.47142-.4607,0,0,.96426,5.90341-4.16774,8.53906-2.48565,1.275-4.532,2.26065-5.9677,2.93563Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M474.81227,759.62953l-.96415,2.04634s-.93225,6.0641-.93225,6.20338c0,.12857-2.6785,4.18921-2.6785,4.18921l-6.73914,1.43564-3.86761-.46069c-.0429-.7821-.12869-1.88568-.3322-3.22493-.11783-.81421-.27857-1.71421-.4928-2.6785,1.43564-.66426,3.7499-1.76777,6.69625-3.27848,5.132-2.6356,4.16763-8.539,4.16763-8.539a39.64115,39.64115,0,0,0,3.52495,3.12847C473.90161,758.99737,474.46935,759.39379,474.81227,759.62953Z"
                transform="translate(-61.06478 -40.93031)" fill="#ceddf9"/>
            <path
                d="M474.81227,759.62953l-.96415,2.04634s-.93225,6.0641-.93225,6.20338c0,.12857-2.6785,4.18921-2.6785,4.18921l-6.73914,1.43564-3.86761-.46069c-.0429-.7821-.12869-1.88568-.3322-3.22493a12.91909,12.91909,0,0,1,2.45355.33213s9.20329,1.97141,9.6426-5.3141c.25712-4.13558,1.1143-5.75341,1.79988-6.38556C473.90161,758.99737,474.46935,759.39379,474.81227,759.62953Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M474.13613,759.14957s-2.2934-.51288-2.73982,6.76371-9.6426,5.31235-9.6426,5.31235-4.5088-1.116-4.55344.75891a17.5298,17.5298,0,0,0,1.20532,5.04451s.26785,4.33024,1.116,5.357.31249,4.86594.31249,4.86594.35714,2.85706,7.45516,0c0,0,6.34628-1.30536,11.60683-9.19618.17857-.26785-.625-1.0714,3.97311-2.72315s17.76738-12.23181,16.74062-17.41024a4.38253,4.38253,0,0,0-3.115-3.70939,5.3666,5.3666,0,0,0-4.11728.55177,34.4597,34.4597,0,0,1-3.83884,1.77373,18.74549,18.74549,0,0,1-5.48657,1.30775,20.6061,20.6061,0,0,0-4.25693.81828C477.249,759.12922,475.27474,759.55443,474.13613,759.14957Z"
                transform="translate(-61.06478 -40.93031)" fill="#cc8294"/>
            <path
                d="M422.06728,709.12373a12.56269,12.56269,0,0,0-2.582,2.97846c-2.60369,3.96419-4.15716,9.50331-5.92487,11.89257-2.27149,3.08564-2.88213,9.1069-2.88213,9.1069-.59992,3.95347-8.96761,25.04933-11.2497,28.79923-2.27136,3.7499-5.42121,13.9282-5.42121,13.9282-1.92857,2.42137-1.81074,6.01053-1.61783,7.72475.0429.3.07494.5357.09639.69643.032.18212.0535.27858.0535.27858l-.0535.09639-3.09636,5.2606-9.8461-1.36069-5.08915-3.4606a16.50825,16.50825,0,0,0,1.10358-2.1c2.72126-6.07482,4.66043-16.91738,4.66043-16.91738s2.74284-12.38538,2.53934-15.26745c-.19291-2.88206.80355-11.78539.80355-11.78539s7.7033-23.69936,10.12468-27.58854a11.055,11.055,0,0,0,1.86422-5.08915,4.02764,4.02764,0,0,0-.05362-1.10358l3.33217-3.31058S426.35288,705.71669,422.06728,709.12373Z"
                transform="translate(-61.06478 -40.93031)" fill="#d38884"/>
            <path
                d="M392.53945,783.45745s-.03218.29995-.0535.79282v.375l-3.09636,5.2606-9.8461-1.36069-5.08915-3.4606a16.50825,16.50825,0,0,0,1.10358-2.1c2.47486-.7071,8.14248,1.29642,8.14248,1.29642s6.11778,1.16778,8.68916-.70716A.589.589,0,0,0,392.53945,783.45745Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M390.1288,797.5142l-9.98539,2.42138-9.17112-6.77126.47136-3.81418a6.98544,6.98544,0,0,0,1.68217-1.53209,6.08245,6.08245,0,0,0,1.32852-2.7535c.5357-2.80707,9.24606.26785,9.24606.26785s6.36418,1.21068,8.839-.80355a15.59316,15.59316,0,0,0,.47148,5.507,4.13708,4.13708,0,0,0,1.46781,2.19635C397.02868,793.96787,390.1288,797.5142,390.1288,797.5142Z"
                transform="translate(-61.06478 -40.93031)" fill="#ceddf9"/>
            <path
                d="M390.1288,797.5142l-9.98539,2.42138-9.17112-6.77126.47136-3.81418a6.98544,6.98544,0,0,0,1.68217-1.53209c.66413-.01072,1.39274,0,2.00338.05356,1.54288.13928,5.1535,6.707,5.1535,6.707,9.064.6,11.76394-2.35709,12.72823-4.54272a4.13708,4.13708,0,0,0,1.46781,2.19635C397.02868,793.96787,390.1288,797.5142,390.1288,797.5142Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M380.2827,795.64462s-3.616-6.56233-5.15612-6.69625a34.25136,34.25136,0,0,0-3.88382,0s-4.01775,9.50867-5.49092,10.37918-3.616,5.357-3.616,5.357,10.608,10.24526,20.87275,8.30335a21.1967,21.1967,0,0,1,9.39429,2.34369c4.75434,2.47761,24.57524.60266,26.98588-3.7499,1.808-3.26442,2.52365-5.63914.93225-7.37986a4.27055,4.27055,0,0,0-3.7271-1.26408c-2.495.34171-8.09013.26924-13.00829-5.753,0,0-9.03994-8.28846-9.77652-8.2959S394.41178,796.58209,380.2827,795.64462Z"
                transform="translate(-61.06478 -40.93031)" fill="#cc8294"/>
            <path
                d="M458.35559,690.96349a.64908.64908,0,0,1-.07494.08573c-.61078.675-4.982,5.46411-6.3,5.807a62.26167,62.26167,0,0,0-6.4284,2.84989s-2.6785,3.57851-4.8213,3.7499a8.324,8.324,0,0,0-1.88567.50359c-.28917-4.907-.61051-9.03189-.61051-9.03189l16.96008-10.039,1.97147,3.11775a.0394.0394,0,0,0,.01072.03217c.44991,1.26424.95343,2.42137,1.1143,2.79634Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M396.62154,622.004s-6.607,8.60044,0,20.0287,13.57106,17.1424,13.57106,17.1424L432.692,705.781s5.8927-3.2142,8.0355-3.39277,4.82129-3.7499,4.82129-3.7499a63.483,63.483,0,0,1,6.4284-2.85706c1.42854-.35714,6.37811-5.8927,6.37811-5.8927s-2.44964-5.357-1.91394-6.78553-1.6071-4.99987-1.6071-4.99987a29.58654,29.58654,0,0,1-1.42853-5.53556c0-1.42854-2.32137-4.8213-2.32137-4.8213s-2.32137-3.57133-2.32137-5.53557-3.39276-9.64259-3.39276-9.64259l-.89284-33.92766Z"
                transform="translate(-61.06478 -40.93031)" fill="#ceddf9"/>
            <path
                d="M458.35559,689.89209s-4.94986,5.5284-6.37491,5.8927a62.26167,62.26167,0,0,0-6.4284,2.84989s-2.6785,3.57851-4.8213,3.7499a17.25847,17.25847,0,0,0-4.39258,1.575c-1.91785.87856-3.64291,1.8214-3.64291,1.8214l-22.4994-46.60589a49.08943,49.08943,0,0,1-5.464-5.60346,76.9191,76.9191,0,0,1-8.11057-11.53894,19.31789,19.31789,0,0,1-2.56066-13.89608,16.082,16.082,0,0,1,2.56066-6.12838l16.00665-1.125,31.85269-2.23926.26785,10.02829.6215,23.8922s.525,1.2,1.16792,2.775c.9856,2.41065,2.22833,5.67841,2.22833,6.86764,0,1.97141,2.31439,5.53919,2.31439,5.53919s2.32486,3.39632,2.32486,4.8213a29.77919,29.77919,0,0,0,1.425,5.53911s2.1428,3.56778,1.6071,4.9927C455.90217,684.53509,458.35559,689.89209,458.35559,689.89209Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.05"/>
            <path
                d="M422.06728,709.12373a12.56269,12.56269,0,0,0-2.582,2.97846c-9.71766.69643-21.01026-4.382-23.93516-5.78552a4.02764,4.02764,0,0,0-.05362-1.10358l3.33217-3.31058S426.35288,705.71669,422.06728,709.12373Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M450.72723,632.57221s-1.425,4.99269-1.96075,7.13549-1.6071,12.6854-1.6071,12.6854l-.62123,2.94635L436.3384,703.95957l-2.21787,10.57471c-3.93195,2.1428-13.74614-3.5785-13.74614-3.5785-11.43175,1.25359-25.542-6.06409-25.542-6.06409s2.32485-7.4998,1.78915-11.2497,3.2142-6.7927,3.2142-6.7927c-.88935-3.3856,2.31413-18.2138,2.31413-18.2138s.718-5.52839,3.39651-6.9641c2.6785-1.42491-.35364-3.56771-1.0714-4.82129-.35364-.61064-.09652-2.01418.25713-3.27848a25.519,25.519,0,0,1,.81427-2.432c-6.80349-6.80343-7.85344-17.45314-7.86416-23.3565a38.67546,38.67546,0,0,1,.19277-4.31777l14.7532-2.582,32.20634-5.63558,3.27854,10.114v.02138l.825,2.53921Z"
                transform="translate(-61.06478 -40.93031)" fill="#ceddf9"/>
            <path
                d="M447.69508,495.96874a18.5176,18.5176,0,0,0-1.47841,4.52135c-.08579.39641-.17146.8142-.26785,1.24279-3.10708,2.01424-5.02852,2.03087-9.00337,2.03087-1.37142,0-2.13123-2.62013-3.42758-2.888,0,0-1.94511-7.00989-2.50226-1.10111-3.73917-.77144-6.96626,2.15106-9.61259-.39887l-.04277-.04283c.02132-.34286.032-.68572.04277-1.02857a42.48622,42.48622,0,0,0-.32134-6.62124s10.40325-.06429,18.23511.83572C444.58813,493.12951,448.70226,494.1688,447.69508,495.96874Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M455.06645,483.94767a19.89862,19.89862,0,0,1-23.903,19.48876s-.01073-.01072-.01073,0a19.89362,19.89362,0,1,1,23.91372-19.48876Z"
                transform="translate(-61.06478 -40.93031)" fill="#d38884"/>
            <path
                d="M444.17026,515.21111c-.15,1.66065-.22508,2.72133-.22508,2.72133s-31.9704-2.1428-26.43135-7.4998a11.8335,11.8335,0,0,0,2.92491-5.31417l13.86384,2.27137s3.92149,3.0428,6.9641,5.8927A12.23045,12.23045,0,0,0,444.17026,515.21111Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M448.93807,628.9937a10.34823,10.34823,0,0,1-4.18934-.3214,7.64682,7.64682,0,0,1-1.70336-.75c-2.6785-1.6071-11.60334.36431-11.60334.36431s-24.28855,1.6071-25.35995,0c-.61077-.92139-4.88564-.7821-8.39986-.50353-1.425.11784-2.73213.25713-3.62134.35352a16.082,16.082,0,0,1,2.56066-6.12838l16.00665-1.125,32.20634-5.63558,3.27854,10.114v.02138C448.48791,627.451,448.93807,628.9937,448.93807,628.9937Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M434.2991,508.46485l-17.42939-2.85706a5.17236,5.17236,0,0,1-3.28434,2.85706c-2.32137.5357-12.67823,5.8927-17.321,12.3211l-4.64273,6.4284s-3.7499,4.64273-2.49994,8.39263,2.6785,16.42813,2.6785,16.42813l1.78567,14.10676s3.39276,10.1783,2.32136,14.64247.5357,23.21366.5357,23.21366l-3.57133,23.21366s12.14253-1.6071,13.21393,0,25.35646,0,25.35646,0,8.92833-1.96424,11.60683-.35714a9.66572,9.66572,0,0,0,5.8927,1.0714s-2.1428-7.32123-.89283-11.42826.89283-11.60683.89283-11.60683,1.78567-10.89257.89283-12.8568,1.96424-9.1069,1.96424-9.1069l9.464-49.82008s-1.14569-8.92833-6.1977-11.964-10.76613-3.92846-13.80176-6.78553S434.2991,508.46485,434.2991,508.46485Z"
                transform="translate(-61.06478 -40.93031)" fill="#4c4981"/>
            <path
                d="M442.33459,557.57067v5.8927l5.50929,14.821,1.99051,13.74963s3.57134,9.82117,3.57134,13.21393,8.74976,19.8209,8.74976,19.8209,2.385,5.53556,1.1925,6.607-11.54936,24.64219,1.486,23.92793,8.92833-25.35646,8.92833-25.35646l-1.78567-10.714s-2.49993-26.785-2.49993-28.74922c0,0-3.2142-11.42827-3.57133-13.9282s-2.6785-15.17816-2.6785-15.17816l-1.96423-8.21407Z"
                transform="translate(-61.06478 -40.93031)" fill="#d38884"/>
            <polygon points="375.065 535.211 355.646 546.327 349.619 555.434 357.654 547.265 375.065 535.211"
                     opacity="0.1"/>
            <path
                d="M430.23671,597.43567s-8.83905,5.75878-12.99072,5.75878-13.79428,9.91044-13.79428,9.91044,13.66035-8.82314,14.73175-8.56324S430.23671,597.43567,430.23671,597.43567Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path d="M407.60339,600.516s-8.30335,7.49979-6.16055,8.30334,13.25857-8.30334,13.25857-8.30334Z"
                  transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path d="M444.29883,573.731s-6.4284,8.70513-4.68738,8.5712,6.69625-5.08915,6.69625-5.08915Z"
                  transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path d="M445.2363,582.30368s-6.9641,7.9-6.69625,9.90892S445.2363,582.30368,445.2363,582.30368Z"
                  transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path d="M446.97733,586.25294s-4.68738,8.37031-2.6785,10.11133S446.97733,586.25294,446.97733,586.25294Z"
                  transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path d="M440.9507,564.758s-3.482,5.8927-.93747,6.29447,2.79681,0,2.79681,0Z"
                  transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M441.88818,543.19606a61.72641,61.72641,0,0,1-3.56785,10.51043,48.28488,48.28488,0,0,1-1.39274-5.27127A36.596,36.596,0,0,1,441.88818,543.19606Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M452.51638,474.20862a8.67246,8.67246,0,0,1-1.35,2.07852,15.62675,15.62675,0,0,0-2.42125,2.77495c-.75006,1.42492-.61064,3.13919-.86776,4.73557-.25726,1.58565-1.26444,3.35349-2.87154,3.407a6.30342,6.30342,0,0,1-2.357-.70716,2.37014,2.37014,0,0,0-2.3463.075,2.59542,2.59542,0,0,0-.74993,1.76783,23.7215,23.7215,0,0,0-.23581,4.17849c.01073.55708.0429,1.10351.09639,1.66066.17146,1.78921.525,3.71772-.32134,5.3141A6.3888,6.3888,0,0,1,435.792,502.14a19.459,19.459,0,0,1-4.62852,1.29641s-.01073-.01072-.01073,0a50.81906,50.81906,0,0,1-9.12834.3,2.31687,2.31687,0,0,1-.81428-.12856,1.23755,1.23755,0,0,1-.35351-.225,30.12248,30.12248,0,0,0,.54642-5.07842,19.89257,19.89257,0,1,1,31.11336-24.09577Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M444.193,457.3174c-1.82607-.18673-3.547.78915-5.33944,1.185-3.70645.81857-7.4983-.86973-11.294-.85609a20.85024,20.85024,0,0,0-9.2806,2.76921,22.31645,22.31645,0,0,0-6.19757,4.33743,10.08326,10.08326,0,0,0-2.94505,6.80894c.01273,1.47505.43191,3.0197-.13349,4.38215a14.11869,14.11869,0,0,1-1.35183,2.06468,6.05931,6.05931,0,0,0-.63248,5.11015c.58353,1.744,1.92233,3.107,3.04877,4.56063,2.4411,3.15023,3.97743,6.901,6.16915,10.22952a30.55239,30.55239,0,0,0,3.21718,4.08167,2.1442,2.1442,0,0,0,.6874.55006,2.22019,2.22019,0,0,0,.81283.12518c4.65655.13141,9.48354.23224,13.767-1.59872a6.38879,6.38879,0,0,0,3.30116-2.6443c.84035-1.59706.49522-3.52283.3233-5.31927a24.83635,24.83635,0,0,1,.13183-5.83868,2.66224,2.66224,0,0,1,.75117-1.76495,2.402,2.402,0,0,1,2.35425-.07181,6.12268,6.12268,0,0,0,2.34787.7028c1.61333-.05579,2.62181-1.814,2.87923-3.40768s.11263-3.30617.86837-4.73263a15.2823,15.2823,0,0,1,2.421-2.77115,11.36807,11.36807,0,0,0,2.01227-10.06905C451.11811,461.83309,447.87811,457.69423,444.193,457.3174Z"
                transform="translate(-61.06478 -40.93031)" fill="#1e1637"/>
            <circle cx="476.44583" cy="454.58847" r="17.27632" fill="#ffd2c6"/>
            <path
                d="M529.07333,502.72906s1.20532,16.36051-2.1428,20.11041-12.05325,10.84792-12.05325,10.84792l2.6785,11.11577,15.13352,1.741,17.6781-18.88342-4.01775-4.95523s-3.08027-10.44614-.5357-15.66922S529.07333,502.72906,529.07333,502.72906Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffd2c6"/>
            <path
                d="M565.63484,538.67472v.01073l-.93211,1.4357-5.357-1.47854-3.7499-14.03537.22509-4.07131,1.10344-1.58565s5.8927.13929,5.63557,5.49629C562.31353,529.16069,564.992,536.8855,565.63484,538.67472Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M577.281,616.19053s-4.26415.9535-9.13894,1.58565c-4.95.64281-10.52121.96429-12.82475-.38569-3.87846-2.29281-24.88859-1.63927-30.92058-1.40353-.42859.01073-.77138.03211-1.03923.04283l1.56421-5.732H566.567l.51425,2.46421,5.91414.34285,3.38566.75C576.93816,615.24769,577.281,616.19053,577.281,616.19053Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M555.85832,520.027s5.8927.13393,5.62485,5.49093,3.2142,14.59782,3.2142,14.59782l-1.33925,3.08027s-1.875,7.4998-.40178,9.37475,6.83018,1.87495,3.7499,17.27632c0,0-.66607,11.51755.93926,15.26745s1.60532,12.72287,4.016,16.47277,5.62485,12.455,5.62485,12.455-17.41025,3.88382-21.9637,1.20532-32.54376-1.33925-32.54376-1.33925.13392-4.68737,0-5.22307a4.4444,4.4444,0,0,1,.66962-3.41509c.93748-1.27228,2.41065-3.41508,1.6071-5.29s2.00887-4.63284.5357-7.5395-3.26967-3.48052-2.90712-5.06853-.57493-3.72776-1.64633-5.06854-8.30335-15.494-8.30335-15.494l-1.20532-6.60518s-9.32086-6.83017-9.14691-10.04437,3.52206-7.667,3.52206-7.667l8.973-8.80576s4.95523,5.357,9.77653,3.482,15.17927-10.84792,15.17927-10.84792,3.70415-2.00888,3.972-3.482,2.54457-.13393,2.54457-.13393,3.34812-1.83665,4.01775-1.18617,3.616,1.18617,3.616,1.18617Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffcde5"/>
            <path
                d="M505.23469,547.92808l-2.85244,2.23208s-2.90634,5.22307-3.576,14.59782-3.616,22.61424-3.616,22.61424l-5.62484,10.06345S480.32464,616.841,479.11932,622.004s-6.69625,13.86816-6.69625,13.86816-18.61557,16.72779-5.62485,19.74752,13.3925-13.31913,13.3925-13.31913,3.02792-3.34812,3.52283-5.62484,16.96769-31.40541,16.96769-31.40541,5.75877-7.83461,4.55345-11.98628c0,0,7.49979-17.63167,7.49979-22.053a77.9327,77.9327,0,0,0-1.20532-11.02649l1.20532-9.24082Z"
                transform="translate(-61.06478 -40.93031)" fill="#ffd2c6"/>
            <path d="M543.80508,584.84673s-13.12465,6.86986-15.66923,7.23194,11.11578-1.47317,11.11578-1.47317Z"
                  transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M554.51907,590.20372s-17.41024,5.8927-25.044,4.8213C529.4751,595.025,548.49245,598.641,554.51907,590.20372Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M555.59047,592.126s-15.67155,9.86314-24.91121,8.256c0,0,21.56309,4.2856,24.91121,2.00888l-9.6426-2.6785S556.66187,594.04824,555.59047,592.126Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M537.53428,465.12105c-4.52426-.9133-9.55312-.35633-13.18123,2.49673-2.68364,2.11036-4.46736,5.33664-7.49482,6.91462a43.96154,43.96154,0,0,1-4.89448,1.72161,14.13187,14.13187,0,0,0-8.6261,15.20572c.47335,2.65067,1.716,5.10512,2.32932,7.72693,1.09332,4.67345.13114,9.5657-1.05492,14.21647A149.78547,149.78547,0,0,1,489.73033,550.322a3.84946,3.84946,0,0,0-.73613,2.47692,3.48385,3.48385,0,0,0,.91048,1.58451c6.19065,7.15222,16.26283,9.60794,25.71659,9.9318,3.48441.11937,7.1263-.03376,10.194-1.69044a15.44057,15.44057,0,0,0,4.96964-4.56468,24.207,24.207,0,0,0,4.609-15.55911c-.25679-4.48644-1.76409-9.03508-.62985-13.38337a3.57422,3.57422,0,0,1,.80271-1.63939c1.019-1.04009,2.71164-.76659,4.15805-.93439,3.93055-.456,6.46825-4.60345,6.93415-8.53284s-.511-7.88208-.53522-11.83892a24.80188,24.80188,0,0,1,4.67039-14.57374c1.30643-1.80557,2.97668-3.571,3.41831-5.83185.55136-2.82259-1.0823-4.89443-2.49589-7.13082-2.19393-3.471-3.26958-7.5294-6.61431-10.178A17.84046,17.84046,0,0,0,537.53428,465.12105Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M536.46288,464.04965c-4.52426-.9133-9.55312-.35633-13.18123,2.49673-2.68364,2.11036-4.46736,5.33664-7.49482,6.91462a43.96154,43.96154,0,0,1-4.89448,1.72161,14.13187,14.13187,0,0,0-8.6261,15.20572c.47335,2.65067,1.716,5.10512,2.32932,7.72693,1.09332,4.67345.13114,9.5657-1.05492,14.21647a149.78547,149.78547,0,0,1-14.88172,36.91886,3.84946,3.84946,0,0,0-.73613,2.47692,3.48385,3.48385,0,0,0,.91048,1.58451c6.19065,7.15222,16.26283,9.60794,25.71659,9.93181,3.48441.11936,7.1263-.03377,10.194-1.69045a15.44057,15.44057,0,0,0,4.96964-4.56468,24.207,24.207,0,0,0,4.609-15.55911c-.25679-4.48644-1.76409-9.03508-.62985-13.38337a3.57422,3.57422,0,0,1,.80271-1.63939c1.019-1.04009,2.71164-.76659,4.15805-.93439,3.93055-.456,6.46825-4.60345,6.93415-8.53284s-.511-7.88208-.53522-11.83892a24.80188,24.80188,0,0,1,4.67039-14.57374c1.30643-1.80557,2.97668-3.571,3.41831-5.83185.55136-2.82259-1.0823-4.89443-2.49589-7.13082-2.19393-3.471-3.26958-7.5294-6.61431-10.178A17.84046,17.84046,0,0,0,536.46288,464.04965Z"
                transform="translate(-61.06478 -40.93031)" fill="#985c7e"/>
            <path
                d="M463.31966,542.02758c-.71775,2.8499,0,7.31768,0,7.31768-.18205,1.96061,0,12.8568,0,12.8568-5.8927-1.78929-20.89229,1.78922-20.89229,1.78922l-1.78916-5.357a13.20409,13.20409,0,0,1-2.22859-4.40344,48.28261,48.28261,0,0,1-1.39274-5.27127,109.63485,109.63485,0,0,1-1.75712-11.21759c-.7392-5.6784,7.70331-11.78539,14.08894-15.18171s11.99975,11.06751,11.99975,11.06751S464.027,539.167,463.31966,542.02758Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.1"/>
            <path
                d="M463.31966,540.95618c-.71775,2.8499,0,7.31768,0,7.31768-.18205,1.96061,0,12.8568,0,12.8568-5.8927-1.78929-20.89229,1.78922-20.89229,1.78922l-1.78916-5.357a13.20409,13.20409,0,0,1-2.22859-4.40344,48.28261,48.28261,0,0,1-1.39274-5.27127,109.63485,109.63485,0,0,1-1.75712-11.21759c-.7392-5.6784,7.70331-11.78539,14.08894-15.18171s11.99975,11.06751,11.99975,11.06751S464.027,538.09556,463.31966,540.95618Z"
                transform="translate(-61.06478 -40.93031)" fill="#4c4981"/>
            <path d="M530.67926,631.49725s3.4408,16.071,9.15382,20.44588Z" transform="translate(-61.06478 -40.93031)"
                  opacity="0.1"/>
            <path
                d="M451.98489,465.15048a12.33065,12.33065,0,0,0-1.32212-2.86775c.09365.24457.17944.48718.25072.72495a11.368,11.368,0,0,1-2.01227,10.06909,15.28046,15.28046,0,0,0-2.421,2.7711c-.75581,1.42648-.611,3.139-.86842,4.73269s-1.26587,3.35185-2.87912,3.40763a6.12091,6.12091,0,0,1-2.34787-.70278,2.40245,2.40245,0,0,0-2.35441.0718,2.6627,2.6627,0,0,0-.75111,1.765,24.83908,24.83908,0,0,0-.13183,5.83869c.172,1.79641.517,3.72223-.3233,5.31926a6.389,6.389,0,0,1-3.30118,2.6443c-4.2835,1.83094-9.11056,1.73011-13.76706,1.59873a2.21962,2.21962,0,0,1-.81271-.12516,2.14288,2.14288,0,0,1-.68741-.55009,30.56146,30.56146,0,0,1-3.21734-4.0817c-2.19171-3.32851-3.72793-7.07926-6.16905-10.2295-.66845-.86273-1.40883-1.695-2.0247-2.59251.01727.05781.02825.11738.04735.17467.58356,1.744,1.92229,3.107,3.04875,4.56064,2.44112,3.15024,3.97734,6.901,6.16905,10.2295a30.56146,30.56146,0,0,0,3.21734,4.0817,2.14288,2.14288,0,0,0,.68741.55009,2.21962,2.21962,0,0,0,.81271.12516c4.6565.13138,9.48356.23221,13.76706-1.59873a6.389,6.389,0,0,0,3.30118-2.6443c.8403-1.597.49528-3.52285.3233-5.31926a24.83908,24.83908,0,0,1,.13183-5.83869,2.6627,2.6627,0,0,1,.75111-1.765,2.40245,2.40245,0,0,1,2.35441-.0718,6.12091,6.12091,0,0,0,2.34787.70278c1.61325-.05578,2.62174-1.814,2.87912-3.40763s.11261-3.30621.86842-4.73269a15.28012,15.28012,0,0,1,2.421-2.7711A11.368,11.368,0,0,0,451.98489,465.15048Z"
                transform="translate(-61.06478 -40.93031)" opacity="0.2"/>
            <g opacity="0.1">
                <path
                    d="M503.28392,493.988c-.18048-.63033-.38791-1.25326-.6037-1.874C502.86175,492.74365,503.06787,493.367,503.28392,493.988Z"
                    transform="translate(-61.06478 -40.93031)"/>
                <path
                    d="M552.144,479.96193a5.63558,5.63558,0,0,1-.07429,1.519c-.44179,2.26083-2.11193,4.02631-3.41835,5.83188a24.80127,24.80127,0,0,0-4.6705,14.57376c.02432,3.9568,1.00129,7.90948.5353,11.83888s-3.00363,8.07683-6.93415,8.53282c-1.44649.16786-3.139-.10568-4.15808.9344a3.57408,3.57408,0,0,0-.80263,1.6394c-1.13431,4.34831.373,8.89692.62986,13.38341a24.20794,24.20794,0,0,1-4.609,15.5591,15.44207,15.44207,0,0,1-4.96974,4.56469c-3.06759,1.65667-6.70959,1.80976-10.194,1.69041-9.29929-.31859-19.18841-2.71034-25.40154-9.59558a2.4689,2.4689,0,0,0-.15406,1.29348,3.48341,3.48341,0,0,0,.91053,1.58447c6.19063,7.15224,16.26273,9.60794,25.71647,9.93183,3.4844.11934,7.1264-.03374,10.194-1.69041a15.44219,15.44219,0,0,0,4.96974-4.56469,24.20794,24.20794,0,0,0,4.609-15.5591c-.25686-4.48649-1.76417-9.0351-.62986-13.38341a3.574,3.574,0,0,1,.80263-1.6394c1.01909-1.04008,2.71159-.76654,4.15808-.9344,3.93052-.456,6.46816-4.60341,6.93415-8.53282s-.511-7.88208-.53531-11.83889A24.80134,24.80134,0,0,1,549.7228,490.527c1.30642-1.80557,2.97656-3.571,3.41835-5.83188A6.66683,6.66683,0,0,0,552.144,479.96193Z"
                    transform="translate(-61.06478 -40.93031)"/>
            </g>
            <path
                d="M193.051,768.625s-37.29516,19.26758-18.02758,41.969c0,0,1.81229,4.96,7.05842,4.57844,0,0-1.04923-1.90769,2.9569-3.43383a19.38418,19.38418,0,0,0,4.02376-2.14111,9.47291,9.47291,0,0,0,3.607-4.82192h0s7.63072-10.20609,1.71691-29.09213c0,0,.76307-5.34151.09538-6.2l-4.53074,6.21189s4.68574,3.14767,5.22228,7.76188c0,0-4.88054-7.74859-5.93766-6.86765-.21461.17884-2.25345,3.39805-2.25345,3.39805s8.11957,5.50843,8.72764,12.66224c0,0-4.57843-10.01533-9.51456-11.44609l-2.79,5.04343s8.87072,5.40112,9.87225,13.52069c0,0-5.15074-10.30148-10.40879-12.37608l-2.0746,5.32958s8.5488,5.4369,9.33571,12.19724c0,0-7.1538-11.303-9.72917-10.94532,0,0-1.6096,5.00766-1.395,6.54573,0,0,6.045,4.0419,6.15227,8.01226,0,0-5.58-7.0465-6.43842-6.5815,0,0-.35769,7.08227,1.35922,10.76648,0,0-2.93306-5.25805-2.46806-10.76648a11.82088,11.82088,0,0,0-3.5769,5.75881s-1.60961-3.89882,3.68421-7.47572c0,0,.50076-5.50843,1.10883-6.188,0,0-5.723,2.79-8.15533,6.22381,0,0,.89422-5.115,8.6561-7.65457l1.9673-5.40112s-7.76188,3.29074-10.1584,6.188c0,0,1.72094-5.07707,10.76647-7.5115l2.68268-4.97189s-6.90342,2.325-10.26571,4.97189c0,0,2.61114-4.36382,11.08839-6.36688l2.18191-3.11191s-5.00766,1.78845-6.40265,2.43229-1.68114.32193-1.68114.32193a18.08036,18.08036,0,0,1,8.978-4.07767S193.39677,768.95886,193.051,768.625Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M823.68964,742.69335s-37.29517,19.26758-18.02759,41.969c0,0,1.8123,4.96,7.05842,4.57844,0,0-1.04922-1.90769,2.95691-3.43383a19.38469,19.38469,0,0,0,4.02376-2.14111,9.47291,9.47291,0,0,0,3.607-4.82192h0s7.63072-10.20609,1.71691-29.09213c0,0,.76307-5.34151.09539-6.2l-4.53075,6.21188s4.68575,3.14768,5.22228,7.76188c0,0-4.88054-7.74859-5.93766-6.86765-.21461.17884-2.25344,3.39805-2.25344,3.39805s8.11956,5.50843,8.72764,12.66224c0,0-4.57844-10.01533-9.51456-11.44609l-2.79,5.04343s8.87072,5.40112,9.87225,13.52069c0,0-5.15074-10.30148-10.40878-12.37608l-2.07461,5.32958s8.5488,5.4369,9.33572,12.19724c0,0-7.1538-11.303-9.72917-10.94532,0,0-1.60961,5.00766-1.395,6.54573,0,0,6.045,4.0419,6.15228,8.01226,0,0-5.58-7.0465-6.43843-6.5815,0,0-.35769,7.08227,1.35922,10.76648,0,0-2.933-5.25805-2.46806-10.76648a11.82088,11.82088,0,0,0-3.5769,5.75881s-1.60961-3.89882,3.68421-7.47572c0,0,.50077-5.50843,1.10884-6.188,0,0-5.723,2.79-8.15534,6.22381,0,0,.89423-5.115,8.65611-7.65457l1.96729-5.40112s-7.76188,3.29075-10.1584,6.188c0,0,1.72094-5.07707,10.76647-7.5115l2.68268-4.97189s-6.90342,2.325-10.26571,4.97189c0,0,2.61114-4.36382,11.0884-6.36688l2.18191-3.11191s-5.00766,1.78845-6.40266,2.4323-1.68114.32192-1.68114.32192a18.08042,18.08042,0,0,1,8.978-4.07767S824.0354,743.02719,823.68964,742.69335Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M982.11817,759.52927s-37.29516,19.26757-18.02758,41.969c0,0,1.8123,4.96,7.05842,4.57843,0,0-1.04923-1.90768,2.9569-3.43382a19.38453,19.38453,0,0,0,4.02377-2.14111,9.47289,9.47289,0,0,0,3.607-4.82193h0s7.63072-10.20609,1.71691-29.09213c0,0,.76307-5.34151.09538-6.2l-4.53074,6.21189s4.68574,3.14767,5.22228,7.76188c0,0-4.88054-7.74859-5.93766-6.86766-.21461.17885-2.25345,3.39806-2.25345,3.39806s8.11957,5.50843,8.72764,12.66223c0,0-4.57843-10.01532-9.51455-11.44608l-2.79,5.04343s8.87072,5.40112,9.87225,13.52069c0,0-5.15074-10.30148-10.40878-12.37608l-2.07461,5.32958s8.5488,5.43689,9.33572,12.19724c0,0-7.15381-11.303-9.72918-10.94532,0,0-1.6096,5.00766-1.395,6.54573,0,0,6.045,4.0419,6.15227,8.01226,0,0-5.58-7.0465-6.43842-6.5815,0,0-.35769,7.08226,1.35922,10.76647,0,0-2.93306-5.258-2.46806-10.76647a11.82088,11.82088,0,0,0-3.5769,5.75881s-1.60961-3.89882,3.68421-7.47572c0,0,.50076-5.50843,1.10884-6.188,0,0-5.72305,2.79-8.15534,6.2238,0,0,.89423-5.115,8.6561-7.65457l1.9673-5.40112s-7.76188,3.29075-10.1584,6.188c0,0,1.72094-5.07706,10.76647-7.51149l2.68268-4.97189s-6.90342,2.325-10.26571,4.97189c0,0,2.61114-4.36382,11.0884-6.36689l2.18191-3.1119s-5.00767,1.78845-6.40266,2.43229-1.68114.32192-1.68114.32192a18.0803,18.0803,0,0,1,8.978-4.07766S982.46394,759.86311,982.11817,759.52927Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M710.69461,550.25489l-2.04355-2.48874c4.0229-3.30276,4.21344-23.7172,2.20127-34.78811-1.39615-7.68075-1.60844-14.26682-.64945-20.135,1.52689-9.34333,6.00319-16.26135,13.30481-20.5617C738.655,463.36,747.7368,438.88707,747.82661,438.641l3.02483,1.10431c-.38277,1.049-9.56062,25.79936-25.70982,35.31071-10.73443,6.32229-14.476,18.88733-11.12136,37.34627C715.6015,521.10065,716.93346,545.13255,710.69461,550.25489Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M794.77685,426.59223c9.87277-24.22886,1.39373-50.58653-18.93851-58.8715s-44.81821,4.64011-54.691,28.869c-9.58584,23.52469-1.87,49.05471,17.197,58.10228a13.88748,13.88748,0,0,1-5.56285,5.54959c-4.12919,1.92835,9.29017,3.78556,9.29017,3.78556a23.47942,23.47942,0,0,1,1.26765-7.433C762.96383,462.34608,785.42541,449.5417,794.77685,426.59223Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M725.06782,419.082c.45369-10.45977,6.77053-18.76245,14.32471-19.13236-.32627-.04452-.65586-.07565-.98908-.0901-7.98639-.3464-14.84443,8.22129-15.31787,19.13648-.45968,10.598,5.26871,19.5116,12.91216,20.33787C735.86166,440.12693,724.60891,429.66227,725.06782,419.082Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
            <path
                d="M833.69461,714.25489l-2.04355-2.48874c4.0229-3.30276,4.21344-23.7172,2.20127-34.78811-1.39615-7.68075-1.60844-14.26682-.64945-20.135,1.52689-9.34333,6.00319-16.26135,13.30481-20.5617C861.655,627.36,870.7368,602.88707,870.82661,602.641l3.02483,1.10431c-.38277,1.049-9.56062,25.79936-25.70982,35.31071-10.73443,6.32229-14.476,18.88733-11.12136,37.34627C838.6015,685.10065,839.93346,709.13255,833.69461,714.25489Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M917.77685,590.59223c9.87277-24.22886,1.39373-50.58653-18.93851-58.8715s-44.81821,4.64011-54.691,28.869c-9.58584,23.52469-1.87,49.05471,17.197,58.10228a13.88748,13.88748,0,0,1-5.56285,5.54959c-4.12919,1.92835,9.29017,3.78556,9.29017,3.78556a23.47942,23.47942,0,0,1,1.26765-7.433C885.96383,626.34608,908.42541,613.5417,917.77685,590.59223Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M848.06782,583.082c.45369-10.45977,6.77053-18.76245,14.32471-19.13236-.32627-.04452-.65586-.07565-.98908-.0901-7.98639-.3464-14.84443,8.22129-15.31787,19.13648-.45968,10.598,5.26871,19.5116,12.91216,20.33787C858.86166,604.12693,847.60891,593.66227,848.06782,583.082Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
            <path
                d="M645.69461,397.25489l-2.04355-2.48874c4.0229-3.30276,4.21344-23.7172,2.20127-34.78811-1.39615-7.68075-1.60844-14.26682-.64945-20.13505,1.52689-9.34333,6.00319-16.26135,13.30481-20.5617C673.655,310.36,682.7368,285.88707,682.82661,285.641l3.02483,1.10431c-.38277,1.049-9.56062,25.79936-25.70982,35.31071-10.73443,6.32229-14.476,18.88733-11.12136,37.34627C650.6015,368.10065,651.93346,392.13255,645.69461,397.25489Z"
                transform="translate(-61.06478 -40.93031)" fill="#d4d5e0"/>
            <path
                d="M729.77685,273.59223c9.87277-24.22886,1.39373-50.58653-18.93851-58.8715s-44.81821,4.64011-54.691,28.869c-9.58584,23.52469-1.87,49.05471,17.197,58.10228a13.88748,13.88748,0,0,1-5.56285,5.54959c-4.12919,1.92835,9.29017,3.78556,9.29017,3.78556a23.47942,23.47942,0,0,1,1.26765-7.433C697.96383,309.34608,720.42541,296.5417,729.77685,273.59223Z"
                transform="translate(-61.06478 -40.93031)" fill="#6c63ff"/>
            <path
                d="M660.06782,266.082c.45369-10.45977,6.77053-18.76245,14.32471-19.13236-.32627-.04452-.65586-.07565-.98908-.0901-7.98639-.3464-14.84443,8.22129-15.31787,19.13648-.45968,10.598,5.26871,19.5116,12.91216,20.33787C670.86166,287.12693,659.60891,276.66227,660.06782,266.082Z"
                transform="translate(-61.06478 -40.93031)" fill="#fff" opacity="0.2"/>
        </svg>
    )
}