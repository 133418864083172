import React from "react";
import PropTypes from "prop-types";

export class CheckboxSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.switchId = 'switch-' + Math.random() + '-' + (new Date()).getTime();
    }

    render() {
        const inputProps = {...this.props};
        return (
            <React.Fragment>
                <div className={`custom-control custom-switch`}>
                    <input
                        id={this.switchId}
                        type="checkbox"
                        className="custom-control-input cursor-pointer"
                        {...inputProps}
                    />
                    <label className="custom-control-label cursor-pointer" htmlFor={this.switchId}>
                        {this.props.label || this.props.children}
                    </label>
                </div>
            </React.Fragment>
        )
    }

}

CheckboxSwitch.propTypes = {
    label: PropTypes.string,
}