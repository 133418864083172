import React, {Fragment} from "react";

export default class InfoIndirizzo extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <Fragment>
                <b>{this.props.ragione_sociale || ""}{this.props.nome} {this.props.cognome}</b>
                {
                    parseInt(this.props.fatturazione) === 1 &&
                        <div className={"small"}>
                            {this.props.piva}<br/>
                            {this.props.pec || ""} {this.props.codice_sdi || ""}<br/>
                            {this.props.indirizzo},{this.props.civico}<br/>
                            {this.props.citta},{this.props.cap}
                        </div>
                }
                {
                    parseInt(this.props.fatturazione) === 0 &&
                        <div className={"small"}>
                            {this.props.cellulare}<br/>
                            {this.props.indirizzo},{this.props.civico}<br/>
                            {this.props.citofono ?
                                <span>Citofono: {this.props.citofono}<br/></span> : ''}
                            {this.props.interno_scala ?
                                <span>Interno/Scala: {this.props.interno_scala}<br/></span> : ''}
                            {this.props.citta},{this.props.cap}
                        </div>
                }
            </Fragment>
        )
    }
}
