import APIConfig from "../../APIConfig";
import FuxHTTP from "../../../lib/FuxFramework/FuxHTTP";

export const _Availability = {
    getAvailableSeats: (map_id, start_date, end_date, start_time, end_time) => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/availability/get-available-seats`), {
            map_id: map_id,
            start_date: start_date,
            end_date: end_date,
            start_time: start_time,
            end_time: end_time
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    },
    getAvailableAccessories: (map_id, start_date, end_date, start_time, end_time) => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/availability/get-available-accessories`), {
            map_id: map_id,
            start_date: start_date,
            end_date: end_date,
            start_time: start_time,
            end_time: end_time
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    },
}