import React from "react";
import {shadeColor} from "../../../helpers/UIUtility";

export const ProfessionistiIllustration = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             width="816.3622" height="662.16124" viewBox="0 0 816.3622 662.16124"><title>certification</title>
            <path
                d="M741.83035,727.019A836.66535,836.66535,0,0,1,628.214,719.57443c-1.22925-.15691-2.47557-.33251-3.71377-.51706-9.24377-1.31462-18.49242-2.817-27.48335-4.46661-40.46082-7.39908-78.616-18.20625-113.40534-32.12232C367.84124,636.15933,295.0512,646.01529,254.37494,662.437c-44.02053,17.77292-60.94224,46.85056-61.1081,47.14243l-1.44794-.82194c.16788-.29593,17.30789-29.8069,61.8093-47.814,26.09191-10.55839,56.361-15.17215,89.97314-13.71851,41.95632,1.81705,89.27071,13.15429,140.62893,33.69709,34.68611,13.87461,72.73436,24.65169,113.08623,32.03127,8.96939,1.64469,18.19609,3.14467,27.42361,4.456,1.2325.18374,2.46744.35772,3.69344.51463,102.08232,14.04208,200.56062,5.5739,265.20119-4.00157,53.27974-7.89257,87.56788-17.1209,87.90853-17.21277l.43577,1.60648c-.34065.09268-34.715,9.3454-88.1004,17.25342A1049.77458,1049.77458,0,0,1,741.83035,727.019Z"
                transform="translate(-191.8189 -118.91938)" fill="#3f3d56"/>
            <path
                d="M741.94335,249.18505c-29.34918.0004-57.922-1.37316-85.48905-4.11865-63.34668-6.30885-121.49912-19.92086-172.84272-40.458-115.77034-46.30911-188.56038-36.454-229.23664-20.031-44.02053,17.77211-60.94224,46.85016-61.1081,47.14162l-1.44794-.82194c.16788-.29593,17.30789-29.80609,61.8093-47.81356,26.09232-10.55839,56.361-15.17459,89.97314-13.71851,41.95632,1.81664,89.27071,13.15388,140.62893,33.69709,115.95286,46.38147,244.98074,48.20421,332.80309,41.57137,95.18974-7.19258,163.8278-25.59883,164.50991-25.78378l.43577,1.60648c-.68292.18577-69.44457,18.62779-164.78389,25.8346C791.66786,248.22042,766.53244,249.18505,741.94335,249.18505Z"
                transform="translate(-191.8189 -118.91938)" fill="#3f3d56"/>
            <path
                d="M737.63215,126.96936s-32.73125-11.68973-53.77276,35.0692-53.77277,98.19375-53.77277,98.19375l18.70357,4.67589s4.67589-32.73125,16.36562-37.40714l-4.67589,42.083S800.75671,318.681,863.88126,264.9082l-2.33794-16.36562s9.35178,2.33794,9.35178,16.36562l7.01384-7.01384s-7.01384-14.02768-28.05536-32.73125c-13.81073-12.2762-18.5567-35.63156-20.18762-50.23369a60.78886,60.78886,0,0,0-13.99522-33.10376C801.68756,125.62,776.20827,108.39567,737.63215,126.96936Z"
                transform="translate(-191.8189 -118.91938)" fill="#2f2e41"/>
            <path
                d="M936.28852,391.56025s61.59328,52.53545,70.65112,83.3321-34.41978,34.41977-34.41978,34.41977L878.31837,518.37s-1.81157,43.47761-18.11568,48.91231-3.31-71.89656-3.31-71.89656l92.07677-25.92807L907.30344,425.98Z"
                transform="translate(-191.8189 -118.91938)" fill="#fbbebe"/>
            <path
                d="M648.24932,445.90727l-7.24627,96.01306L601.14858,704.96139s-36.23135,74.27425-5.43471,76.08582S624.699,710.39609,624.699,710.39609L682.6691,556.41287l5.4347-105.0709Z"
                transform="translate(-191.8189 -118.91938)" fill="#fbbebe"/>
            <circle cx="557.87819" cy="98.73042" r="59.78172" fill="#fbbebe"/>
            <path
                d="M722.52358,246.63487s21.73881,77.89739-12.681,97.82463,86.95523,34.41978,119.56344-25.36194c0,0-57.97015-19.92724-41.66605-72.46269Z"
                transform="translate(-191.8189 -118.91938)" fill="#fbbebe"/>
            <path
                d="M680.85753,353.51734l-10.8694-3.62314s-21.73881,9.05784-21.73881,25.36195-3.62313,81.52052-3.62313,81.52052l48.91232,5.4347Z"
                transform="translate(-191.8189 -118.91938)" fill={color}/>
            <path
                d="M893.75664,714.74589c-66.87539,9.90682-164.34553,17.90722-265.42873,4.00437-.00833-.999-.00833-1.97305-.00833-2.92212q0-8.47914.64933-16.76674c5.17823-68.03258,40.22683-124.75141,53.78843-144.407.68261-.999,1.31532-1.89809,1.88142-2.69732,2.18951-3.072,3.46327-4.69536,3.46327-4.69536s105.07089-1.715,159.41709,20.02188a23.3758,23.3758,0,0,1,7.07632,4.62874C874.85873,590.61048,886.76359,651.31705,893.75664,714.74589Z"
                transform="translate(-191.8189 -118.91938)" fill="#2f2e41"/>
            <path
                d="M854.768,328.1554s23.55038-12.681,47.10075,14.49254l47.10075,54.347-36.23135,39.85448-68.83955-41.666Z"
                transform="translate(-191.8189 -118.91938)" fill={color}/>
            <path
                d="M685.09787,218.02885c21.16318-15.31611,38.29434-32.95711,49.62659-53.94194,0,0,38.83822,43.15356,62.57269,45.31124s2.15768-51.78429,2.15768-51.78429l-43.15357-10.78839-40.9959,4.31535-32.36518,21.57679Z"
                transform="translate(-191.8189 -118.91938)" fill="#2f2e41"/>
            <path
                d="M820.34821,315.47443s-25.35167,40.08368-101.44263,20.94762L675.727,348.31369l-5.73885,1.58051s5.4347,108.694,19.92724,128.62128,10.8694,30.79664,7.24627,32.60821-12.681-1.81157-7.24627,7.24627,12.681,3.62313,5.4347,9.05784-10.8694,25.36194-10.8694,25.36194L854.768,574.52854s-5.4347-70.65112,9.05784-106.88246,18.11567-50.72389,18.11567-50.72389l-10.8694-90.57836Z"
                transform="translate(-191.8189 -118.91938)" fill={color}/>
            <rect x="41.5169" y="161.09044" width="233.10245" height="291.37806" fill="#e6e6e6"/>
            <rect x="57.75082" y="185.64945" width="49.95052" height="33.30035" fill="#fff"/>
            <rect x="58.16708" y="250.58513" width="193.14203" height="11.65512" fill="#fff"/>
            <rect x="58.16708" y="281.38795" width="193.14203" height="11.65512" fill="#fff"/>
            <rect x="58.16708" y="312.19078" width="193.14203" height="11.65512" fill="#fff"/>
            <rect x="58.16708" y="342.9936" width="193.14203" height="11.65512" fill="#fff"/>
            <rect x="58.16708" y="373.79642" width="193.14203" height="11.65512" fill="#fff"/>
            <path
                d="M512.22623,570.97162a45.65939,45.65939,0,1,1-7.07633-24.45911A45.78336,45.78336,0,0,1,512.22623,570.97162Z"
                transform="translate(-191.8189 -118.91938)" fill={color}/>
            <path
                d="M505.1499,546.51251l-45.072,45.0637c-2.8328-4.38565-18.61489-24.76713-18.61489-24.76713a63.86592,63.86592,0,0,1,6.46028-5.70272l13.087,17.44941,38.8615-38.86151A45.59883,45.59883,0,0,1,505.1499,546.51251Z"
                transform="translate(-191.8189 -118.91938)" fill="#fff"/>
        </svg>
    )
}
