import React from "react";
import PropTypes from "prop-types";
import {FaRegularIcon, FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {BundleSubServiceItem} from "./BundleSubServiceItem";
import {AddServiceButton} from "./AddServiceButton/AddServiceButton";
import {ServicesListViewItemMenu} from "./ServicesListViewItemMenu";

export class ServicesListViewItem extends React.Component {

    constructor(props) {
        super(props);
    }

    handleEditTime = data => this.props.onEditTime({...data, originalBook: this.props.subBook})

    render() {
        let {
            subBook,
            showCollapseToggle,
            collapsed,
            onCollapseToggle,
            noteServizi,
            optGroupServizi,
            showEditOptions,
            onAppendSubBook,
            showPause,
            showPrice
        } = this.props;

        const isPause = !subBook.id_servizio;
        if (!showPause && isPause) return '';

        const nomeServizio = isPause ? 'Pausa NON prenotabile' : subBook.nome_servizio;

        const isBundle = subBook.subservices && subBook.subservices.length;
        const showSubservices = !showCollapseToggle || !collapsed;

        const mainServiceNote = noteServizi && noteServizi.find(({id_servizio}) => id_servizio == subBook.id_servizio);


        const addServiceDirection = 'after';
        let relativeBook = subBook;
        if (relativeBook.subservices && relativeBook.subservices.length) {
            relativeBook = addServiceDirection === 'after' ? relativeBook.subservices.slice(-1)[0] : relativeBook.subservices[0];
        }
        const addServiceStartTime = addServiceDirection === "after" ? relativeBook.orario_fine : relativeBook.orario;
        const addServiceIdPrenotazione = relativeBook.id_prenotazione;


        const handleCollapseToggle = e => onCollapseToggle(subBook.id_prenotazione, !collapsed);

        return (
            <React.Fragment>
                <div className={"card mb-2 rounded border-0 shadow-sm"}>
                    <div
                        className={`dialog-service-indicator rounded-left appuntamento ${subBook.css_class || 'pausa'}`}/>
                    <div className={"card-body p-2 rounded"}>
                        <div className={"d-flex align-items-center"}>
                            <div className="d-flex align-items-start justify-content-between w-100">
                                {/* Info servizio */}
                                <div>
                                    {/* Data e ora, nome servizio e prezzo */}
                                    {
                                        !isBundle &&
                                        <span className={"mr-2"}>{subBook.orario} - {subBook.orario_fine}</span>
                                    }
                                    <b className={"mr-2"}>{nomeServizio}</b>
                                    {
                                        !!subBook.nome_dipendente &&
                                        <span className={"mr-2"}>con {subBook.nome_dipendente}</span>
                                    }
                                    {
                                        (!isPause && showPrice) &&
                                        <React.Fragment>
                                            €{parseFloat(subBook.prezzo).toFixed(2)}
                                            {
                                                parseFloat(subBook.perc_sconto_servizio) > 0 &&
                                                <span
                                                    className='badge badge-success ml-2'>Scontato del {subBook.perc_sconto_servizio}%</span>
                                            }
                                        </React.Fragment>
                                    }

                                    {
                                        (showEditOptions && !!subBook.categoria) &&
                                        <div className={"text-muted small"}>
                                            Categoria servizio: {subBook.categoria}
                                        </div>
                                    }

                                    {
                                        !!mainServiceNote &&
                                        <div className={"text-info"}>
                                            <b>Note:</b> {mainServiceNote.note_servizio}
                                        </div>
                                    }

                                    {/* Sottoservizi in caso di bundle */}
                                    {
                                        (showSubservices && subBook.subservices) &&
                                        subBook.subservices.map((s, i) => {
                                            const note = noteServizi && noteServizi.find(({id_servizio}) => id_servizio == s.id_servizio);
                                            return (
                                                <BundleSubServiceItem
                                                    key={i}
                                                    subservice={s}
                                                    noteServizio={note}
                                                    showPause={showPause}
                                                />
                                            )
                                        })
                                    }
                                    {
                                        (showCollapseToggle && subBook.subservices) &&
                                        <button className={"btn btn-light btn-sm px-2 py-1 ml-3"}
                                                onClick={handleCollapseToggle}>
                                            {collapsed ?
                                                <FaSolidIcon name={"ellipsis-h"}/> :
                                                <React.Fragment>
                                                    <FaRegularIcon name={"minus-square"}/> Nascondi servizi
                                                </React.Fragment>
                                            }
                                        </button>

                                    }
                                </div>
                            </div>

                            {/* Menù */}
                            <div className="dropdown mr-2">
                                <ServicesListViewItemMenu
                                    subBook={this.props.subBook}
                                    onEditServiceDuration={this.props.onEditServiceDuration}
                                    onEditTime={this.handleEditTime}
                                    onDelete={this.props.onDelete}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <AddServiceButton
                    optGroupServizi={optGroupServizi}
                    direction={addServiceDirection}
                    startTime={addServiceStartTime}
                    idPrenotazione={addServiceIdPrenotazione}
                    nomeServizioPrenotazione={subBook.nome_servizio}
                    onAppendSubBook={onAppendSubBook}
                />
            </React.Fragment>
        )
    }
}

ServicesListViewItem.propTypes = {
    subBook: PropTypes.object,
    showCollapseToggle: PropTypes.bool,
    collapsed: PropTypes.bool,
    onCollapseToggle: PropTypes.func,
    noteServizi: PropTypes.array,
    showPause: PropTypes.bool,
    showPrice: PropTypes.bool,
    optGroupServizi: PropTypes.array, //Struttura per optgroup dei servizi divisi in categoria
    onAppendSubBook: PropTypes.func,
    onEditServiceDuration: PropTypes.func,
    onDelete: PropTypes.func,
    onEditTime: PropTypes.func,
}