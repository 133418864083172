import React from "react";
import PropTypes from "prop-types";
import AppConfig from "../../config/AppConfig";
import {AppIconImageSize} from "../../const/ImageSizeCostants";
import {AppIconThumbnail} from "../../components/BaseComponents/AppIconThumbnail";
import {
    TIPO_OPERAZIONE_ORDINE_ECOMMERCE,
    TIPO_OPERAZIONE_ORDINE_FOOD,
    TIPO_OPERAZIONE_PRENOTAZIONE_CAMERA,
    TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI,
    TIPO_OPERAZIONE_PRENOTAZIONE_POSTI,
    TIPO_OPERAZIONE_PRENOTAZIONE_SERVIZI
} from "../../const/OperazioniConstants";
import moment from "moment";
import {getCurrentLanguage} from "../../langUtils";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";

export function RedeemOperationItem(props) {

    const [date, time] = getOperationDateTime(props.operationType, props.operation);

    const onAdd = _ => props.onImportAdd(props.operation.id_operazione, props.operationType);
    const onRemove = _ => props.onImportRemove(props.operation.id_operazione, props.operationType);

    const shopNameStyle = {
        maxWidth: '200px'
    }

    return (
        <React.Fragment>
            <div className={"d-flex align-items-center justify-content-between w-100"}>

                <div className={"d-flex align-items-center"}>
                    {
                        props.isImported &&
                        <FaSolidIcon name={"check-circle"} className={"text-success mr-1"}/>
                    }
                    <AppIconThumbnail
                        src={`${AppConfig.webServerUrl}/api/negozio/${props.operation.id_negozio}/logoIcon/100`}
                        size={`${AppIconImageSize.productThumbnail.w}px`}
                    />
                    <div className={"ml-2"}  style={shopNameStyle}>
                        <div className={"text-truncate font-weight-bold"}>
                            {props.operation.nome_negozio}
                        </div>
                        <div className={"small text-muted"}>
                            {date}{!!time && `, ${time}`}
                        </div>
                    </div>
                </div>
                {
                    props.isImported ?
                        <button className={"btn btn-sm btn-link text-danger p-0"} onClick={onRemove}>
                            Rimuovi
                        </button>
                        :
                        <button className={"btn btn-sm btn-link p-0"} onClick={onAdd}>
                            Importa
                        </button>
                }
            </div>
        </React.Fragment>
    )
}

RedeemOperationItem.propTypes = {
    operation: PropTypes.shape({
        id_negozio: PropTypes.any,
        nome_negozio: PropTypes.string,
        id_operazione: PropTypes.any,
    }).isRequired,
    operationType: PropTypes.string.isRequired,
    isImported: PropTypes.bool.isRequired,
    onImportAdd: PropTypes.func,
    onImportRemove: PropTypes.func,
}

function getOperationDateTime(operationType, operationData) {
    moment.locale(getCurrentLanguage('it'));
    const formatDate = d => moment(d).format('ddd DD MMM YYYY');

    switch (operationType) {
        case TIPO_OPERAZIONE_PRENOTAZIONE_SERVIZI:
            return [formatDate(operationData.date_ymd), operationData.orario];
        case TIPO_OPERAZIONE_ORDINE_ECOMMERCE:
        case TIPO_OPERAZIONE_ORDINE_FOOD:
            if (operationData.data && operationData.orario) return [operationData.data, operationData.orario];
            return [formatDate(operationData.data_creazione), moment(operationData.data_creazione).format('HH:mm')];
        case TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI:
            return [formatDate(operationData.data), operationData.orario_inizio + ' - ' + operationData.orario_fine];
        case TIPO_OPERAZIONE_PRENOTAZIONE_POSTI:
            return [formatDate(operationData.date), operationData.start_time + ' - ' + operationData.end_time];
        case TIPO_OPERAZIONE_PRENOTAZIONE_CAMERA:
            return [formatDate(operationData.data_checkin) + ' - ' + formatDate(operationData.data_checkout), null];
    }
    return [null, null];
}


