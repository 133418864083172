import {SectorSeatType} from "./SectorSeatType";

import PropTypes from "prop-types";

export const MapSectorType = PropTypes.shape({
    sector_id: PropTypes.any,
    map_id: PropTypes.any,
    name: PropTypes.string,
    description: PropTypes.string,
    seat_capacity: PropTypes.number,
    seat_color: PropTypes.string,
    seat_internal_code_left: PropTypes.string,
    seat_internal_code_right: PropTypes.string,
    seats: PropTypes.arrayOf(SectorSeatType),
    accessories: PropTypes.arrayOf(PropTypes.any)
});
