import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";

var CronologiaUserAPI = {
    getItems: (filters, limit, offset) => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/cronologia/get-items'), {
                ...filters,
                limit: limit,
                offset: offset,
                token: AuthAPI.currentUser.accessToken
            },
            FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default CronologiaUserAPI;