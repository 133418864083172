import React from "react";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import FeedPostCard from "./FeedPostCard";
import {FeedContainer, GetMoreLoadingWrapper} from "./FeedStyledComponents";
import FeedAPI from "../../../api/FeedAPI";
import {FeedPostCardHOC} from "./FeedPostCardHOC";
import emptyBox from "../../../img/illustrations/empty.svg";


export default class FeedPreferredContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: false,
            hasFullscreenPost: false,
            hasProfiloAttivita: false,
            lastScrollTop: 0
        }
        this.lastScrollTop = 0;
        this.feedContainerRef = null;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState.items !== this.state.items ||
            nextState.loading !== this.state.loading ||
            nextState.lastScrollTop !== this.state.lastScrollTop;
    }

    componentDidMount() {
        FeedAPI.getStartFeed(10)
            .then(items => {
                this.setState({items: items});
            })
    }

    handleContainerRef = node => {
        this.feedContainerRef = node;
        this.props.onScrollPaneRef(node);
    }

    handleFeedScroll = e => {
        if (this.feedContainerRef && e.target !== this.feedContainerRef) return;
        if (this.state.loading || this.state.hasFullscreenPost || this.state.hasProfiloAttivita) return;
        const scrollTop = e.target.scrollTop;
        this.setState({lastScrollTop: scrollTop});

        if (scrollTop <= this.lastScrollTop) { //Se vado verso sopra allora non triggero il getMore
            this.lastScrollTop = scrollTop;
            return;
        }
        this.lastScrollTop = scrollTop;

        const height = e.target.scrollHeight;
        const thresholdOffset = 200;
        if (scrollTop + window.innerHeight + thresholdOffset >= height) {
            return new Promise(this.onGetMore);
        }
    }

    onGetMore = (resolve, reject) => {
        this.setState({loading: true})
        const lastItem = this.state.items.slice().pop();
        FeedAPI.getMore(10, lastItem.id, lastItem.tipo)
            .then(newItems => {
                const oldItems = this.state.items.slice();
                this.setState({items: oldItems.concat(newItems), loading: false})
                resolve();
            }).catch(reject);
    }

    onRefresh = (resolve, reject) => {
        console.log("REFRESH");
        this.setState({loading: true})
        FeedAPI.pullToRefresh(10, this.state.items[0].id, this.state.items[0].tipo)
            .then(newItems => {
                const oldItems = this.state.items.slice();
                this.setState({items: newItems.concat(oldItems), loading: false})
                resolve();
            }).catch(reject);
    }


    handlePostFullscreenOpen = _ => this.setState({hasFullscreenPost: true});
    handlePostFullscreenClose = _ => this.setState({hasFullscreenPost: false});

    handleProfiloAttivitaOpen = _ => this.setState({hasProfiloAttivita: true});
    handleProfiloAttivitaClose = _ => this.setState({hasProfiloAttivita: false});

    render() {
        return (
            <FeedContainer ref={this.handleContainerRef} onScroll={this.handleFeedScroll}>
                {
                    this.state.items.length > 0 ?
                        <div>
                            {
                                this.state.items.map((item, i) =>
                                    <FeedPostCardHOC scrollTop={this.state.lastScrollTop} key={item.id_post}>
                                        <FeedPostCard
                                            {...item}
                                            onAppOpen={this.props.onAppOpen}
                                            onAppAdd={this.props.onAppAdd}
                                            onFullscreenOpen={this.handlePostFullscreenOpen}
                                            onFullscreenClose={this.handlePostFullscreenClose}
                                            onProfiloAttivitaOpen={this.handleProfiloAttivitaOpen}
                                            onProfiloAttivitaClose={this.handleProfiloAttivitaClose}
                                            scrollTop={this.state.lastScrollTop}
                                        />
                                    </FeedPostCardHOC>
                                )
                            }
                        </div>
                        :
                        <div className={"container text-center mt-5"}>
                            <h5 className={"text-center mt-3 text-muted"}>
                                Non ci sono post pubblicati!
                            </h5>
                            <img src={emptyBox} className={"img-fluid w-50 mt-2"} alt={"Nessuna immagine disponibile"}/>
                            <h6 className={"text-center mt-3 text-muted mb-4"}>
                                Gli esercenti presenti su Bookizon hanno la possibilità di pubblicare dei post che
                                raggiungono i loro clienti.<br/>
                            </h6>
                            <span className={"text-primary text-uppercase font-weight-bold"}>
                                    Aggiungi nuove app esercente e segui i loro post!
                            </span>
                        </div>
                }
                {
                    this.state.loading &&
                    <GetMoreLoadingWrapper>
                        <FaSolidIcon name={"spinner fa-pulse text-muted"}/>
                    </GetMoreLoadingWrapper>
                }
            </FeedContainer>
        )
    }


}
