import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AppConfig from "../config/AppConfig";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";

var GruppiAPI = {
    list: function (id_negozio) {
        id_negozio = id_negozio ?? NegozioOpenStore.id_negozio
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/gruppi/` + id_negozio + `/list`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listCategory: function (id_negozio) {
        id_negozio = id_negozio ?? NegozioOpenStore.id_negozio
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/gruppi/` + id_negozio + `/categories`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getAvailableDates: function (id_gruppo, from, direction, npartecipanti, max_dates = 6, show_full_group_sessions = 0) {
        var params = {
            id_gruppo: id_gruppo,
            from: from,
            direction: direction,
            partecipanti: npartecipanti,
            max_dates: max_dates,
            show_full_group_sessions: show_full_group_sessions
        }

        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/gruppi/availableDates`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    prenota: function (id_gruppo, data, npartecipanti, sessioni, note_utente, questionsAnswers = null, accessori = null, coupon = null, wallets = null, packages = null, metodoPagamento = null) {
        var params = {
            id_gruppo: id_gruppo,
            data: data,
            partecipanti: npartecipanti,
            sessioni: sessioni,
            note_utente: note_utente,
            questions_answers: questionsAnswers,
            accessori: accessori,
            coupon: coupon,
            wallets: wallets,
            packages: packages,
            metodo_pagamento: metodoPagamento,
            token: AuthAPI.currentUser.accessToken
        }

        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/gruppi/book`), params,
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    remove: function (id_prenotazione) {
        var params = {
            id_prenotazione: id_prenotazione,
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/gruppi/delete-book`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default GruppiAPI;
