import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../BaseComponents/Page/Page";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {FullscreenInput} from "../Forms/FullscreenInputs";
import {swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import AuthAPI from "../../api/AuthAPI";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {AUTHENTICATED_USER_DATA_CHANGE} from "../../const/AppEventsCostants";
import {ModuloGruppiAPI} from "../../api/ModuloGruppi/ModuloGruppiAPI";
import {UserAPI} from "../../api/User/UserAPI";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";
import ValidateOTPPage from "../ValidateOTPPage";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";

export default class EditEmailPage extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            newEmail: this.props.user.email,
            password: '',
            passwordVisible: false,
            validateOtpPageOpen: false
        }

    }

    handleSubmit = event => {
        swalLoading("Stiamo verificando i dati inseriti");
        event.preventDefault();
        UserAPI.Credentials.changeEmail(this.state.newEmail, this.state.password)
            .then(swalSuccess)
            .then(this.toggleValidateOtpPageOpen)
            .catch(swalError)
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    togglePassword = _ => this.setState({passwordVisible: !this.state.passwordVisible});

    toggleValidateOtpPageOpen = _ => {
        this.setState({validateOtpPageOpen: !this.state.validateOtpPageOpen});
    }


    handleValidateOTP = otp => {
        swalLoading("Stiamo verificando i dati inseriti, ci siamo quasi..");
        UserAPI.Credentials.validateOTPForChangeEmail(this.state.newEmail, otp)
            .then(swalSuccess)
            .then(_ => {
                this.toggleValidateOtpPageOpen();
                AuthAPI.setUserData({email: this.state.newEmail})
                    .then(_ => FuxEvents.emit(AUTHENTICATED_USER_DATA_CHANGE))
                this.props.onDismiss()
            })
            .catch(swalError)
    }

    handleResendOTP = _ => {
        swalLoading("Stiamo inviando nuovamente il codice alla e-mail inserita");
        UserAPI.Credentials.resendOTPForChangeEmail(this.state.newEmail)
            .then(swalSuccess)
            .catch(swalError)
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Modifica email"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >

                    <form onSubmit={this.handleSubmit}>

                        <div className="form-group container-fluid mt-3">
                            {
                                this.props.user.negozio &&
                                <span className={"text-muted"}>
                                    Modificando l'email di questo account potresti perdere l'accesso a tutti i servizi da <b>amministratore </b>
                                    accessibili tramite applicazione, per il negozio <b><SafeHtmlContainer
                                    html={this.props.user.negozio.nome}/></b>.
                                    Se vuoi ripristinarli, modifica l'account dell'admin dalla dashboard web.
                                </span>
                            }
                        </div>

                        <div className="form-group container-fluid mt-3">
                            <small className={"text-uppercase"}>Modifica la tua email</small>
                            <div className={"row align-items-center bg-white border-bottom border-top"}>
                                <div className={"col-1 px-0 text-primary text-center"}>
                                    <FaSolidIcon name={"envelope"}/>
                                </div>
                                <div className={"col px-0"}>
                                    <FullscreenInput
                                        className={"form-control border-0 rounded-0"}
                                        type={"email"}
                                        name={"newEmail"}
                                        value={this.state.newEmail}
                                        placeholder="La tua nuova email"
                                        onChange={this.handleInputChange}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group container-fluid mt-3">
                            <small className={"text-uppercase"}>Inserisci la password prima di proseguire</small>
                            <div className={"row align-items-center bg-white border-bottom border-top"}>
                                <div className={"col-1 px-0 text-primary text-center"}>
                                    <FaSolidIcon name={"envelope"}/>
                                </div>
                                <div className={"col px-0"}>
                                    <FullscreenInput
                                        className={"form-control border-0 rounded-0"}
                                        type={this.state.passwordVisible ? 'text' : "password"}
                                        name={"password"}
                                        value={this.state.password}
                                        placeholder="La tua password"
                                        onChange={this.handleInputChange}
                                        required={true}
                                    />
                                </div>
                                <div className={"input-group-append"} onClick={this.togglePassword}>
                                    <span className={"input-group-text"}>
                                        {this.state.passwordVisible ? <FaSolidIcon name={"eye-slash"}/> :
                                            <FaSolidIcon name={"eye"}/>}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={"form-group container mt-4"}>
                            <button className="btn btn-primary btn-block shadow-sm" type="submit">Cambia e-mail</button>
                        </div>
                    </form>
                </Page>

                <ModalPortalHOC>
                    {
                        this.state.validateOtpPageOpen &&
                        <ValidateOTPPage
                            onValidate={this.handleValidateOTP}
                            resendOTP={this.handleResendOTP}
                            onDismiss={this.toggleValidateOtpPageOpen}
                            message={"Quasi, fatto! inserisci il codice che hai ricevuto al nuovo indirizzo e-mail"}
                        />
                    }

                </ModalPortalHOC>

            </Fragment>
        )
    }

}


EditEmailPage.propTypes = {
    user: PropTypes.any,
    onDismiss: PropTypes.func
}