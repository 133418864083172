import React, {Fragment} from "react";
import {FaRegularIcon, FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FidelityCardAPI} from "../../../../api/FidelityCard/FidelityCardAPI";
import {swalError, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import APIConfig from "../../../../api/APIConfig";
import {ProfilePicThumbnail} from "../../../../components/BaseComponents/ProfilePicThumbnail";
import AuthAPI from "../../../../api/AuthAPI";
import ToggleSwitch from "../../../../components/BaseComponents/ToggleSwitch/ToggleSwitch";
import {FullscreenInput} from "../../../../components/Forms/FullscreenInputs";

export default class DashboardFidelityCardTransactionPage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            cardData: null,
            isAddTransactionType: true,
            amount: ''
        }
    }

    componentDidMount() {
        FidelityCardAPI.Admin.getCardData(this.props.cardToken)
            .then(cardData => this.setState({cardData: cardData}))
            .catch(swalError);
    }

    handleInputChange = (event) => {
        console.log(event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        const amount = this.state.amount * (this.state.isAddTransactionType ? 1 : -1);
        FidelityCardAPI.Admin.transaction(this.props.cardToken, amount)
            .then(swalSuccess)
            .then(_ => this.setState({amount: 0}))
            .catch(swalError)
            .finally(this.props.onDismiss);
    }

    render() {
        return (
            <Page
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
            >
                {
                    this.state.cardData ?
                        <Fragment>

                            <div className={"d-flex align-items-center justify-content-center mt-3"}>
                                <ProfilePicThumbnail
                                    className={"mr-2"}
                                    size={"60px"}
                                    src={APIConfig.url(`/utenti/profile-pic/${AuthAPI.currentUser.accessToken}/${this.state.cardData.id_utente}/100`)}
                                />
                                <h3 className={"font-weight-bold"}>{this.state.cardData.nome} {this.state.cardData.cognome}</h3>

                            </div>
                            <h4 className={"text-center"}>{this.state.cardData.punti} punti</h4>

                            <form onSubmit={this.handleSubmit}>
                                <div className={"container my-3"}>
                                    <div className={"row align-items-center bg-white border-bottom border-top"}>
                                        <div className={"col-1 px-0 text-success text-center"}>
                                            <FaRegularIcon name={"credit-card"}/>
                                        </div>
                                        <div className={"col px-0"}>
                                            <FullscreenInput
                                                className={"form-control border-0 rounded-0 text-center"}
                                                placeholder={"Inserisci il numero di punti"}
                                                onChange={this.handleInputChange}
                                                name={"amount"}
                                                value={this.state.amount}
                                            />
                                        </div>
                                    </div>
                                    <div className={"d-flex align-items-center justify-content-between my-2"}>
                                        <ToggleSwitch
                                            id={`fidelitycard-transaction-type`}
                                            currentValue={this.state.isAddTransactionType}
                                            onChange={this.handleInputChange}
                                            Name={"isAddTransactionType"}
                                            Text={["Carica", "Scarica"]}
                                            Colors={["#fff", "#fff"]}
                                            BgColors={["#28a745", "#dc3545"]}
                                            Big={true}
                                        />
                                        <button className={"btn btn-soft-primary"}>
                                            {
                                                this.state.isAddTransactionType ?
                                                    'Carica punti' : 'Scarica punti'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Fragment>
                        :
                        <div className={"text-center mt-5"}>
                            <FaSolidIcon name={"spin fa-spinner"}/> Attendere prego...
                        </div>
                }
            </Page>
        )
    }

}
