import styled from "styled-components";
import React from "react";
import "../../css/placeholder-loading.css";

export const ProfilePicThumbnailSC = styled.div`
    display:block;
    width:${props => props.size};
    height:${props => props.size};
    background-image: url('${props => props.src}');
    background-size:cover;
    background-position:center center;
    position:relative;
    overflow:hidden;
    border-radius:50%;
`;

export class ProfilePicThumbnail extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            src: null
        }
        PreloadImage(this.props.src)
            .then(image => this.setState({src: image}));
    }

    render() {
        return this.state.src ?
            <ProfilePicThumbnailSC {...this.props} src={this.state.src}/>
            :
            <div {...this.props}>
                <div className={"ph-item p-0 rounded-circle m-0"}>
                    <div className={"ph-picture m-0 rounded-circle"}
                         style={{width: this.props.size, height: this.props.size}}/>
                </div>
            </div>
    }
}


export const PreloadImage = urlImg => {
    return new Promise((resolve, reject) => {
        // To bypass errors (“Tainted canvases may not be exported” or “SecurityError: The operation is insecure”)
        // The browser must load the image via non-authenticated request and following CORS headers
        var img = new Image();
        img.crossOrigin = 'Anonymous';

        // The magic begins after the image is successfully loaded
        img.onload = function () {
            var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');

            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            ctx.drawImage(img, 0, 0);

            // Unfortunately, we cannot keep the original image type, so all images will be converted to PNG
            // For this reason, we cannot get the original Base64 string
            var b64 = canvas.toDataURL('image/png');
            //b64 = uri.replace(/^data:image.+;base64,/, '');

            //console.log(b64); //-> "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWP4z8DwHwAFAAH/q842iQAAAABJRU5ErkJggg=="
            resolve(b64);
        };

        img.onerror = function () {
            reject(false);
        }

        // If you are loading images from a remote server, be sure to configure “Access-Control-Allow-Origin”
        // For example, the following image can be loaded from anywhere.
        img.src = urlImg;
    })
};
