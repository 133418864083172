import React, {Fragment} from "react";
import MultipleItemChooserPage from "../../BaseComponents/MultipleItemChooserPage";
import styled from "styled-components";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {PluralString} from "../../../helpers/UIUtility";
import {ServiziRecommenderPanel} from "./ServiziRecommenderPanel";

export default class ServiziChooserPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected || [],
            shoppingCartOpen: false,
            recommenderOpen: false,
            recommendations: []
        }
    }

    handleChoose = _ => {
        //TODO: SISTEMARE LA FUNZIONE, FIXATA PERCHé NON TROVAVA I SERVIZI ALL'INTERNO DI ALL ITEMS E RESTITUVIA UNDEFINED
        const recommendations = getPossibileRecommendations(this.state.selected, this.props.matching, this.props.allItems).map(id => {
            return this.props.allItems.find(s => s.id_servizio === id)
        });
        recommendations.length = Math.min(3, recommendations.length); //Max 3 suggestions
        this.setState({
            shoppingCartOpen: false,
            recommenderOpen: !!recommendations.length,
            recommendations: recommendations
        });
        if (!!recommendations.length) return; //Se ci sono consigli da dare non vado allo step successivo di prenotazione

        this.props.onChoose(this.state.selected);
    }

    /**
     * @description Viene eseguito ogni volta che un servizio viene selezionato/delesezionato. L'obiettivo è tenere traccia
     * di tutti i servizi selezionati
     * */
    handleChange = (newSelectedList, changedValue, changedValueState) => {
        let globalSelected = Array.from(new Set([...this.state.selected, ...newSelectedList]));

        if (!changedValueState) { //Se ho deselezionato allora devo rimuoverlo anche dall'eventuale stato precedente
            console.log("DESELEZIONATO", changedValue)
            globalSelected = globalSelected.filter(v => v !== changedValue)
        }

        this.setState({selected: globalSelected});
        if (this.props.onChange) {
            this.props.onChange(globalSelected);
        }
    }

    handleRemove = id_servizio => {
        let globalSelected = this.state.selected.filter(id => id !== id_servizio);
        this.setState({selected: globalSelected});
        if (this.props.onChange) {
            this.props.onChange(globalSelected);
        }
    }

    toggleShoppingCart = _ => {
        this.setState({shoppingCartOpen: !this.state.shoppingCartOpen});
    }

    getTotale = _ => {
        return this.props.allItems.reduce((acc, s) => {
            if (this.state.selected.find(id_servizio => id_servizio === s.id_servizio)) {
                const prezzoBase = parseFloat(s.prezzo);
                const prezzoScontato = prezzoBase - (prezzoBase * parseFloat(s.perc_sconto) / 100);
                return acc + prezzoScontato;
            }
            return acc;
        }, 0.0);
    }

    handleRecommenderDismiss = _ => {
        this.setState({
            recommenderOpen: false
        }, _ => {
            this.props.onChoose(this.state.selected);
        })
    }

    render() {
        return (
            <Fragment>
                <MultipleItemChooserPage
                    key={"Bookstep_servizio"}
                    style={this.props.style}
                    title={this.props.title}
                    subtitle={<h6 className={"text-muted font-weight-bold mb-3"}>Scegli il servizio</h6>}
                    items={this.props.items}
                    itemsType={"card"}
                    active={this.props.active}
                    onDismiss={this.props.onDismiss}
                    onChange={this.handleChange}
                    onChoose={this.handleChoose}
                    required={true}
                    showOrder={true}
                    selected={this.state.selected}
                    promotionDiscount={this.props.promotionDiscount ? this.props.promotionDiscount : null}
                    cartIcon={
                        this.state.selected.length && this.props.shoppingCartEnabled ?
                            <ShoppingCartIcon onClick={this.toggleShoppingCart}
                                              className={"bg-secondary text-on-bg-secondary rounded-circle d-flex align-items-center justify-content-center"}>
                                {
                                    this.state.shoppingCartOpen ?
                                        <FaSolidIcon name={"times"}/>
                                        :
                                        <FaSolidIcon name={"list"}
                                                     badge={this.state.selected.length}
                                                     badgeColor={"secondary"}/>

                                }
                            </ShoppingCartIcon> : ''
                    }
                />


                {
                    this.state.shoppingCartOpen && this.props.shoppingCartEnabled ?
                        [
                            <ShoppingCartBackdrop className={"__androidDismissable__"} key={"cart-backdrop"}
                                                  onClick={this.toggleShoppingCart}/>,
                            <ShoppingCartContainer key={"cart-container"} className={"bg-main-z2 rounded shadow p-3"}>
                                <h3 className={"text-on-bg-main"}>Servizi scelti</h3>
                                <div className={"list-group list-group-flush"}>
                                    {
                                        this.state.selected.map(id_servizio => {
                                            const servizio = this.props.allItems.find(i => i.id_servizio === id_servizio);
                                            return <ServiziShoppingCartItem
                                                key={id_servizio}
                                                servizio={servizio}
                                                onRemove={this.handleRemove}
                                                showPrice={this.props.showPrices}
                                            />
                                        })
                                    }
                                </div>
                                <ShoppingCartFooter className={"p-3 bg-main-z2 border-top"}>
                                    {
                                        !!this.props.maxRepeat && <div className={"form-group"}>
                                            Prenota contemporaneamente per&nbsp;
                                            <select name={"repeat"} onChange={this.props.onRepeatChange}
                                                    value={this.props.repeat}>
                                                {
                                                    [...new Array(this.props.maxRepeat)].map((_, i) =>
                                                        <option key={i} value={i + 1}>{i + 1}</option>
                                                    )
                                                }
                                            </select> <PluralString more={"persone"} single={"persona"}
                                                                    n={this.props.repeat}/>
                                        </div>
                                    }

                                    <button className={"btn btn-primary btn-block"} onClick={this.handleChoose}>
                                        <FaSolidIcon name={"check"}/> Avanti
                                        {
                                            this.props.showPrices &&
                                            <span>&nbsp;(€{this.getTotale().toFixed(2)})</span>
                                        }
                                    </button>
                                </ShoppingCartFooter>
                            </ShoppingCartContainer>,
                            <FloatingShoppingCartIcon onClick={this.toggleShoppingCart}
                                                      className={"bg-secondary text-on-bg-secondary rounded-circle d-flex align-items-center justify-content-center"}>
                                <FaSolidIcon name={"times"}/>
                            </FloatingShoppingCartIcon>
                        ]
                        : ''
                }

                {
                    this.state.recommenderOpen &&
                    <ServiziRecommenderPanel
                        onDismiss={this.handleRecommenderDismiss}
                        recommendations={this.state.recommendations}
                        showPrice={this.props.showPrices}
                        onChange={this.handleChange}
                        selectedServicesIds={this.state.selected}
                    />
                }
            </Fragment>
        )
    }

}


function ServiziShoppingCartItem(props) {
    const servizio = props.servizio;
    const handleRemove = _ => props.onRemove(servizio.id_servizio);

    const prezzoBase = parseFloat(servizio.prezzo);
    const percSconto = parseFloat(servizio.perc_sconto)
    const prezzoScontato = prezzoBase - (prezzoBase * percSconto / 100);

    return (
        <div className={"list-group-item bg-main-z3 text-on-bg-main"}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <div>
                    {servizio.nome}
                    <div>
                        {
                            props.showPrice &&
                            <Fragment>
                            <span className={"badge badge-primary mr-1"}>
                                 {`€${prezzoScontato.toFixed(2)}`}
                            </span>
                                {
                                    !!percSconto &&
                                    <Fragment>
                                        <small className={"mr-1 text-muted text-line-through"}>
                                            {`€${prezzoBase.toFixed(2)}`}
                                        </small>
                                        <span className={"badge badge-success mr-1"}>
                                        Sconto {percSconto}%
                                    </span>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                </div>
                <button className={"btn btn-secondary btn-sm float-right"}
                        onClick={handleRemove}>
                    <FaSolidIcon name={"trash"}/>
                </button>
            </div>

        </div>
    )
}


/**
 * Verifica se, in base ai servizi in carrello, c'è possibilità di suggerire altri servizi consigliati
 *
 * @param selectedIds[]
 * @param matchingDataset{
 *     "{{id_servizio}}": []
 * }
 * */
function getPossibileRecommendations(selectedIds, matchingDataset, allItems) {
    if (!matchingDataset) return [];
    const recommendations = [];
    selectedIds.map(id => {
        if (matchingDataset[id]) {
            matchingDataset[id].map(s => {
                if(allItems.find(all => all.id_servizio === s.id_servizio2)){
                    return recommendations.push(s.id_servizio2)
                }
            })
        }
    });
    return recommendations.filter(id => !selectedIds.find(sId => sId === id)); //Rimuovo tutti i suggerimenti già nel carrello
}

const ShoppingCartIcon = styled.div`
    width:48px;
    height:48px;
`;

const FloatingShoppingCartIcon = styled.div`
    width:48px;
    height:48px;
    position:fixed;
    z-index:2001;
    bottom:1rem;
    right:1rem;
`;

const ShoppingCartBackdrop = styled.div`
    position:fixed; top:0; left:0; width:100%; height: 100vh; z-index: 2000; background-color: rgba(0,0,0,0.6);
`;

const ShoppingCartContainer = styled.div`
    position:fixed; top: 1rem; left:1rem; right:1rem; bottom: 100px; z-index:2001; overflow-x:hidden; overflow-y:auto;
    padding-bottom:103px!important; /* serve per escludere il footer dallo scroll */
    
    &:after{
        content: ' ';
        background-color:inherit;
        width:20px;
        height:20px;
        position:fixed; bottom:94px; right:42px;
        z-index:2000;
        transform:rotate(45deg);
        -webkit-transform:rotate(45deg);
    }
`;

const ShoppingCartFooter = styled.div`
    position:fixed; bottom:100px; left:20px; right:20px; z-index:2001;
`;
