import React from "react";
import {swalError, swalSuccess, swalConfirm, swalLoading, swal} from "../../../helpers/SweetAlertWrapper";
import AppModalPortal from "../../../components/BaseComponents/AppModalPortal";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";
import NegozioAPI from "../../../api/NegozioAPI";
import {FoodCartUtils} from "../../../components/FoodDelivery/Utils/FoodCartUtils";
import ReorderFoodAPI from "../../../api/ModuloFood/ReorderFoodAPI";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {DLA_APP_OPEN} from "../../../const/DeepLinkActionsCostants";
import {
    APP_HOMEPAGE_ACTION,
    APP_MOUNTED,
    ECOMMERCE_CART_RELOAD_FROM_MEMORY, ECOMMERCE_OPEN_CART,
    FOOD_CART_RELOAD_FROM_MEMORY
} from "../../../const/AppEventsCostants";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import PropTypes from "prop-types";
import {FOOD_ORDER_TYPE_ASPORTO, FOOD_ORDER_TYPE_CONSEGNA} from "../../../const/ModuloFoodCostants";
import {EcommerceCartUtils} from "../../../components/Ecommerce/Utils/EcommerceCartUtils";


export default class ReorderController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoNegozio: null,
        }

    }


    componentDidMount() {
        swalConfirm("<b>Le tue abitudini sono importanti per noi!</b></br>" +
            "Se scegli di continuare potrai riscegliere il tipo di ordine, ed i prodotti di questo ordine saranno all'interno del carrello. " +
            "Il carrello potrà essere modificato.</br>" +
            "<b>Vuoi procedere?</b>", "Si", "No")
            .then(_ => {
                this.onModifyCartChoose();
            })
    }

    isFoodOrder = _ => {
        return this.props.order.type == FOOD_ORDER_TYPE_ASPORTO || this.props.order.type == FOOD_ORDER_TYPE_CONSEGNA;
    }


    onModifyCartChoose = _ => {
        swalLoading()

        const CartUtils = this.isFoodOrder() ? FoodCartUtils : EcommerceCartUtils;
        CartUtils.saveOfflineCartProducts(this.props.id_negozio, []);

        ReorderFoodAPI.getProductForCart(this.props.order.id_ordine)
            .then((data) => {
                CartUtils.saveOfflineCartProducts(this.props.id_negozio, data);
                if (!NegozioOpenStore.id_negozio) {
                    FuxEvents.emit(DLA_APP_OPEN, this.props.id_negozio);
                    this.props.onSuccess();
                    FuxEvents.waitForEvent(APP_MOUNTED)
                        .then(_ => {
                            FuxEvents.emit(APP_HOMEPAGE_ACTION, this.isFoodOrder() ? 'ordini_prodotti' : 'ordini_prodotti_ecommerce'); //Apre la pagina ordini
                        });
                } else {
                    this.props.onDismiss();
                    FuxEvents.emit(this.isFoodOrder() ? FOOD_CART_RELOAD_FROM_MEMORY : ECOMMERCE_CART_RELOAD_FROM_MEMORY);
                    if (this.isFoodOrder()) {
                        FuxEvents.emit(APP_HOMEPAGE_ACTION, 'ordini_prodotti'); //Apre la pagina ordini
                    }else{
                        FuxEvents.emit(ECOMMERCE_OPEN_CART); //Apre il carrello ecommerce
                    }

                }

                swal.safeClose();
            })
    }


    render() {
        return '';
    }
}

ReorderController.propTypes = {
    order: PropTypes.object,
    onDismiss: PropTypes.func,
    onSuccess: PropTypes.func,
}