import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {EllipsisLoader} from "../components/GenericUI/EllipsisLoader/EllipsisLoader";
import AppConfig from "../config/AppConfig";
import {truncateHtmlString} from "../helpers/StringHelpers";
import {BookizonAppManager} from "../index";
import appStoreLogo from "../img/store-logo/app-store.svg";
import playStoreLogo from "../img/store-logo/google-play-badge.png";
import MetaTags from "react-meta-tags";
import EcommerceProductView from "../components/Ecommerce/ProductView/EcommerceProductView";
import {ModuloEcommerceAPI} from "../api/ModuloEcommerce/ModuloEcommerceAPI";
import {getCurrentLanguage} from "../langUtils";

export class DirectProductContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productData: '',
        }
    }

    componentDidMount() {
        //Recupero info post
        document.body.className = "";
        document.body.style.background = '#dadde1';
        ModuloEcommerceAPI.Prodotti.get(this.props.id_prodotto, getCurrentLanguage()).then(product => this.setState({productData: product}));
    }

    render() {
        if (!this.state.productData) {
            return <div className={"h-100 d-flex align-items-center justify-content-center"}>
                <EllipsisLoader/>
            </div>
        }

        const OS = BookizonAppManager.getMobileOperatingSystem();

        return (
            <Fragment>
                {
                    this.props.useMetaTags &&
                    <MetaTags>
                        <title>{this.state.productData.nome}</title>
                        <meta id="meta-description" name="description"
                              content={truncateHtmlString(this.state.productData.descrizione, 128)}/>
                        <meta id="og-title" property="og:title" content={this.state.productData.nome}/>
                        {
                            this.state.productData.immagine &&
                            <meta id="og-image" property="og:image"
                                  content={`${AppConfig.webServerUrl}/assets/prodotti/${this.state.productData.id_prodotto}/${this.state.productData.immagine}`}/>
                        }
                    </MetaTags>
                }
                <div className={"direct-post-container shadow-sm"}>
                    {
                        this.props.useDownloadBanner && <div className={"bg-light py-3 border-bottom"}>
                            <div className={"container text-center"}>
                                <p>
                                    Scarica Bookizon per scoprire articoli come questo e tanti altri ancora!
                                </p>
                                {
                                    OS !== 'unknown' &&
                                    <div>
                                        {
                                            OS === 'iOS' &&
                                            <a href={"https://apps.apple.com/us/app/bookizon/id1506158300?l=it&ls=1"}>
                                                <img src={appStoreLogo} height={45}/>
                                            </a>
                                        }
                                        {
                                            OS === 'Android' &&
                                            <a href="https://play.google.com/store/apps/details?id=com.proinn.bookizon">
                                                <img src={playStoreLogo} height={45}/>
                                            </a>
                                        }
                                    </div>
                                }

                                {
                                    OS === 'unknown' &&
                                    <img
                                        src={`${AppConfig.webServerUrl}/api/shops/qrcode/${this.state.productData.id_negozio}`}
                                        className="mx-auto" height={150}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className={"bg-main py-4"}>
                    <EcommerceProductView
                        productData={this.state.productData}
                        onOpenApp={this.props.onAppOpen}
                        onAddToCart={this.props.onAddToCart}
                    />
                </div>
            </Fragment>
        )
    }
}


DirectProductContainer.propTypes = {
    id_prodotto: PropTypes.any,
    useMetaTags: PropTypes.bool,
    useDownloadBanner: PropTypes.bool,
    useActionBar: PropTypes.bool,
    onAppAdd: PropTypes.func,
    onAppOpen: PropTypes.func,
    onAppToCart: PropTypes.func,
}

