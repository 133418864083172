import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";

export const _PromotionsNoGeolocation = {

    getPromotionsAppCollegate: function (limit, offset, sortType=null, lat=null, lng=null,  maxDistance=null){
        var params = {
            token: AuthAPI.currentUser.accessToken,
            limit: limit,
            offset: offset,
            sort_type: sortType,

            lat: lat,
            lng:lng,
            maxDistance: maxDistance
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/promotions/no-geolocation/app-collegate`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getPromotionsSingleApp: function (id_negozio){
        var params = {
            token: AuthAPI.currentUser ? AuthAPI.currentUser.accessToken : null,
            id_negozio: id_negozio
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/promotions/no-geolocation/single-app`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }

}
