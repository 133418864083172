import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import shareIcon from "../../img/illustrations/shareIcon.svg";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class CronologiaUserFiltersPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ordini_food: this.props.filters.ordini_food,
            ordini_ecommerce: this.props.filters.ordini_ecommerce,
            prenotazioni: this.props.filters.prenotazioni,
            hotel: this.props.filters.hotel,
            future_experiences: this.props.filters.future_experiences,
            minDateType: this.props.filters.minDateType,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open) {
            this.setState({
                ordini_food: this.props.filters.ordini_food,
                ordini_ecommerce: this.props.filters.ordini_ecommerce,
                prenotazioni: this.props.filters.prenotazioni,
                hotel: this.props.filters.hotel,
                future_experiences: this.props.filters.future_experiences,
                minDateType: this.props.filters.minDateType,
            });
        }
    }

    handleCheckboxChange = ({target}) => this.setState({[target.name]: target.checked})

    handleConfirm = _ => this.props.onChange(this.state);

    isSwipeDisabled = _ => false;

    render() {
        return (
            <Fragment>
                {this.props.open && <div style={BackdropStyle}/>}
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={this.props.open}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"bg-main-z2 p-3"}>
                        <div className={"form-group"}>
                            <div className="custom-control custom-switch">
                                <input
                                    type={"checkbox"}
                                    className={"custom-control-input"}
                                    name={"future_experiences"}
                                    onChange={this.handleCheckboxChange}
                                    checked={this.state.future_experiences}
                                    id="switchFutureExperiences"
                                />
                                <label className="custom-control-label" htmlFor="switchFutureExperiences">
                                    Mostra esperienze future
                                </label>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className="custom-control custom-switch">
                                <input
                                    type={"checkbox"}
                                    className={"custom-control-input"}
                                    name={"ordini_food"}
                                    onChange={this.handleCheckboxChange}
                                    checked={this.state.ordini_food}
                                    id="switchCronologiaFood"
                                />
                                <label className="custom-control-label" htmlFor="switchCronologiaFood">
                                    Ordinazioni food
                                </label>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className="custom-control custom-switch">
                                <input
                                    type={"checkbox"}
                                    className={"custom-control-input"}
                                    name={"ordini_ecommerce"}
                                    onChange={this.handleCheckboxChange}
                                    checked={this.state.ordini_ecommerce}
                                    id="switchCronologiaEcommerce"
                                />
                                <label className="custom-control-label" htmlFor="switchCronologiaEcommerce">
                                    Ordini E-commerce
                                </label>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className="custom-control custom-switch">
                                <input
                                    type={"checkbox"}
                                    className={"custom-control-input"}
                                    name={"prenotazioni"}
                                    onChange={this.handleCheckboxChange}
                                    checked={this.state.prenotazioni}
                                    id="switchCronologiaPrenotazioni"
                                />
                                <label className="custom-control-label" htmlFor="switchCronologiaPrenotazioni">
                                    Prenotazioni
                                </label>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className="custom-control custom-switch">
                                <input
                                    type={"checkbox"}
                                    className={"custom-control-input"}
                                    name={"hotel"}
                                    onChange={this.handleCheckboxChange}
                                    checked={this.state.hotel}
                                    id="switchCronologiaHotel"
                                />
                                <label className="custom-control-label" htmlFor="switchCronologiaHotel">
                                    Prenotazioni Hotel e B&B
                                </label>
                            </div>
                        </div>
                        <button className={"btn btn-primary btn-block"} onClick={this.handleConfirm}>
                            <FaSolidIcon name={"check"}/> Conferma e aggiorna
                        </button>
                    </div>
                </SwipeablePanel>
            </Fragment>
        )
    }

}

CronologiaUserFiltersPanel.propTypes = {
    onDismiss: PropTypes.func,
    open: PropTypes.bool,
    onChange: PropTypes.func,
    filters: PropTypes.shape({
        ordini_food: PropTypes.bool,
        ordini_ecommerce: PropTypes.bool,
        prenotazioni: PropTypes.bool,
        hotel: PropTypes.bool,
        minDateType: PropTypes.string,
    })
}