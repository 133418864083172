import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import i18n from "../../../../i18n";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {DashboardOrdiniFoodPage} from "../ModuloFood/DashboardOrdiniFoodPage";

export class DashboardModuloFoodButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageOpen: false,
        }
    }

    handlePageOpen = _ => this.setState({pageOpen: true});
    handlePageClose = _ => this.setState({pageOpen: false});

    render() {
        return (
            <Fragment>
                <div className={"d-flex align-items-center justify-content-between p-3 border-top text-warning"} onClick={this.handlePageOpen}>
                    <span>
                        <FaSolidIcon name={"pizza-slice"}/>&nbsp;
                        {i18n.t(`launcher.side-menu.${this.props.labelName}`)}
                    </span>
                    {
                        this.props.ordiniSospesi &&
                        <span className={"badge badge-pill badge-primary"}>
                            {this.props.ordiniSospesi} in corso
                        </span>
                    }
                </div>
                <ModalPortalHOC>
                    {
                        this.state.pageOpen &&
                        <DashboardOrdiniFoodPage
                            userNegozio={this.props.userNegozio}
                            onDismiss={this.handlePageClose}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DashboardModuloFoodButton.propTypes = {
    labelName: PropTypes.string.isRequired,
    ordiniSospesi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    userNegozio: PropTypes.object
}