import React from "react";
import PropTypes from "prop-types";
import AppConfig from "../../config/AppConfig";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import {BannerInAppImageSize} from "../../const/ImageSizeCostants";
import {LazyImage} from "../../helpers/ImageUtility/LazyImage";
import {BookizonAppManager} from "../../index";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {OPEN_PRODUCT_BY_ID} from "../../const/AppEventsCostants";

const ACTION_OPEN_URL = "open_url";
const ACTION_OPEN_WHATSAPP = "open_whatsapp";
const ACTION_OPEN_PRODUCT = "open_product";
const ACTION_DO_NOTHING = "doNothing";

export class BannerInAppItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
        this.image = new Image();
        this.image.src = props.src;
        this.image.onload = _ => this.setState({loaded: true});
        this.bannerStyle = {
            paddingTop: "56.25%",
            backgroundImage: `url('${this.getImageUrl()}')`,
            backgroundSize: 'contain',
            width: "100%"
        }

        this.placeholderContainerStyle = {
            paddingTop: "56.25%",
            width: "100%",
            animation: "none",
            backgroundSize: 'contain',
            position: 'relative'
        }

        this.placeholderStyle = {
            backgroundImage: `url('${this.getBlurredImageUrl()}')`,
            backgroundRepeat: 'no-repeat',
            animation: "none",
            backgroundSize: 'contain',
            position: 'absolute',
            top: 0, left: 0, bottom: 0, right: 0,
            filter: 'blur(4px)',
        }
    }

    getImageUrl = _ =>
        getCompressedImageUrl(
            this.props.bannerData.immagine,
            BannerInAppImageSize.full.w,
            BannerInAppImageSize.full.h
        );

    getBlurredImageUrl = _ =>
        getCompressedImageUrl(
            this.props.bannerData.immagine,
            BannerInAppImageSize.blur.w,
            BannerInAppImageSize.blur.h
        );

    handleClick = _ => {
        switch (this.props.bannerData.action_type) {
            case ACTION_OPEN_URL:
                BookizonAppManager.openInAppBrowser(this.props.bannerData.action_data);
                break;
            case ACTION_OPEN_PRODUCT:
                FuxEvents.emit(OPEN_PRODUCT_BY_ID, this.props.bannerData.action_data);
                break;
            case ACTION_OPEN_WHATSAPP:
                var link = document.createElement('a');
                link.href = `https://api.whatsapp.com/send?phone=${this.props.bannerData.action_data}`;
                document.body.appendChild(link);
                link.click();
                break;
        }
    }

    render() {
        return (
            <LazyImage
                src={this.getImageUrl()}
                placeholderContainerStyle={this.placeholderContainerStyle}
                placeholderStyle={this.placeholderStyle}
                showLoader={true}
                loaderSize={3}
            >
                <div style={this.bannerStyle} onClick={this.handleClick}/>
            </LazyImage>
        )
    }

}

BannerInAppItem.propTypes = {
    bannerData: PropTypes.object
}
