import React, {Fragment} from "react";
import secureLogin from "../../../launcher/svg/register-form/secure-login.svg"
import {PhoneNumberInput} from "../../BaseComponents/Inputs/PhoneNumberInput";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {Base64} from "../../../helpers/Base64";
import {swalError} from "../../../helpers/SweetAlertWrapper";
import i18next from "i18next";
import {SecureLoginIllustration} from "../../../img/illustrations/login/SecureLoginIllustration";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";

export default class PasswordAndTermsAndConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password2: '',
            passwordVisible: false,
            termini_condizioni: false,
            privacy_policy: false,
            errors: {}
        }
        this.campiObbligatori = ["password"];
        this.formRef = null;
    }

    handleInputChange = ({target}) => this.setState({[target.name]: target.value});
    handleCheckboxChange = ({target}) => this.setState({[target.name]: target.checked});
    togglePassword = _ => this.setState({passwordVisible: !this.state.passwordVisible});

    validateFormAndProceed = values => {
        const errors = {};

        for (var i in this.campiObbligatori) {
            if (!values[this.campiObbligatori[i]]) {
                errors[this.campiObbligatori[i]] = i18next.t('generic.errors.mandatory-field-label');
            }
        }

        if (values.password !== values.password2) {
            errors.password = i18next.t('register.errors.password-not-matching');
            errors.password2 = i18next.t('register.errors.password-not-matching');
        }

        if (!values.privacy_policy) {
            errors.privacy_policy = i18next.t('register.errors.privacy-mandatory');
        }

        if (!values.termini_condizioni) {
            errors.termini_condizioni = i18next.t('register.errors.tos-mandatory');
        }

        if (Object.keys(errors).length) {
            this.setState({
                errors: errors
            });
            swalError(i18next.t('register.errors.missing-fields'));
            return;
        }

        this.props.confirmValue({
            "password": this.state.password,
            "termini_condizioni": this.state.termini_condizioni,
            "privacy_policy": this.state.privacy_policy,
        })
    }

    render() {
        let negozioAspetto = NegozioOpenStore.infoNegozio ? NegozioOpenStore.infoNegozio.aspetto : null

        return (
            <Fragment>

                <div className={"text-center px-3 pb-2"}>
                    <div className={"w-100"}>
                        <SecureLoginIllustration className={"img-fluid my-2"} color={negozioAspetto ? negozioAspetto.secondary_color : "#6861ce"}/>
                    </div>
                    <h2 className={"text-primary text-primary font-weight-bold"}>{i18next.t('register.heading3')}</h2>
                    <p className={"text-muted"}>{i18next.t('register.subheading3')}</p>
                </div>

                <div className="form-group px-4 py-1">
                    <div className={"input-group"}>
                        <input autoComplete={"new-password"} type={this.state.passwordVisible ? 'text' : "password"}
                               className={"form-control border-0"}
                               name="password" placeholder={i18next.t('register.password-placeholder')}
                               value={this.state.password} onChange={this.handleInputChange}/>
                        <div className={"input-group-append"} onClick={this.togglePassword}>
                                <span className={"input-group-text border-0"}>
                                    {this.state.passwordVisible ? <FaSolidIcon name={"eye-slash"}/> :
                                        <FaSolidIcon name={"eye"}/>}
                                </span>
                        </div>
                    </div>
                    <ErrorMessage errors={this.state.errors} name="password"/>
                </div>
                <div className="form-group px-4 py-1">
                    <div autoComplete={"new-password"} className={"input-group"}>
                        <input type={this.state.passwordVisible ? 'text' : "password"}
                               className={"form-control border-0"}
                               name="password2" placeholder={i18next.t('register.password-confirm-placeholder')}
                               value={this.state.password2} onChange={this.handleInputChange}/>
                        <div className={"input-group-append"} onClick={this.togglePassword}>
                                <span className={"input-group-text border-0"}>
                                    {this.state.passwordVisible ? <FaSolidIcon name={"eye-slash"}/> :
                                        <FaSolidIcon name={"eye"}/>}
                                </span>
                        </div>
                    </div>
                    <ErrorMessage errors={this.state.errors} name="password2"/>
                </div>
                <div className="form-group px-4 py-1">
                    <label>
                        <input type="checkbox" name="privacy_policy" required="" checked={this.state.privacy_policy}
                               onChange={this.handleCheckboxChange}/>
                        &nbsp;{i18next.t('register.privacy-policy-label')} <a
                        href={`https://proinn.web.app/bookizon/redirect.html?url=${Base64.encode("https://bookizon.it/documents/privacy-policy")}`}
                        className="iubenda-white no-brand iubenda-embed iub-body-embed"
                        title="Privacy Policy">Privacy Policy</a>
                    </label>
                    <ErrorMessage errors={this.state.errors} name="privacy_policy"/>
                </div>
                <div className="form-group px-4 py-1">
                    <label>
                        <input type="checkbox" name="termini_condizioni" required=""
                               checked={this.state.termini_condizioni} onChange={this.handleCheckboxChange}/>
                        &nbsp; {i18next.t('register.tos-label')} <a
                        href={`https://proinn.web.app/bookizon/redirect.html?url=${Base64.encode("https://bookizon.it/documents/termini-e-condizioni")}`}
                        className="iubenda-white no-brand iubenda-embed iub-body-embed"
                        title="Privacy Policy">{i18next.t('register.tos-label2')}</a>

                    </label>
                    <ErrorMessage errors={this.state.errors} name="termini_condizioni"/>
                </div>

                <div className={"px-4 py-3"}>
                    <button type={"button"} className={"btn btn-primary btn-block shadow"} onClick={_=>this.validateFormAndProceed(this.state)}>
                        {i18next.t('register.submit-btn')}
                    </button>
                </div>
            </Fragment>
        )
    }
}

function ErrorMessage(props) {
    return (!!props.errors[props.name] && <span className={'text-danger'}>{props.errors[props.name]}</span>)
}