import React from 'react';
import StaticRating from "./StaticRating";
import moment from "moment";
import PropTypes from "prop-types";

export default class RecensioniResume extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    render() {
        const momentDate = moment(this.props.infoRecensione.data);
        const monthDay = momentDate.format('DD');
        const monthName = momentDate.format('MMMM');
        const dayFullName = momentDate.format('dddd');

        return (
            <div className="form-group text-align-center mt-2">
                <span>La tua recensione:</span><br/>
                <small className={"text-muted"}>{dayFullName} {monthDay} {monthName}</small>
                <div>
                    <StaticRating actualRating={parseInt(this.props.infoRecensione.valutazione)} maxRating={5}/>
                </div>
                {this.props.infoRecensione.testo &&
                <span className={"text-muted"}>{this.props.infoRecensione.testo}</span>}
            </div>
        )
    }
}

RecensioniResume.propTypes = {
    infoRecensione: PropTypes.object,
}