import styled from "styled-components";

export const ResetInput = styled.div`
    display:none;
    input:not([value=""]) + & {
        display:block;
        position:absolute;
        right:10px;
        top:50%;
        transform: translateY(-50%);
        background-color: rgba(0,0,0,0.5);
        color:white;
        border-radius: 50%;
        width:16px;
        height:16px;
        text-align: center;
        line-height: 15px;
    }
`;