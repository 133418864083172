import React from "react";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {FidelityCardAPI} from "../../../../api/FidelityCard/FidelityCardAPI";
import {swal, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import PropTypes from "prop-types";
import {FidelityCardPremiItem} from "./FidelityCardPremiItem";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {FIDELITY_CARD_PRIZE_REDEEM_EVT} from "../../../../const/AppEventsCostants";
import {FidelityCardPremiRiscattatiItem} from "./FidelityCardPremiRiscattatiItem";

export class FidelityCardPremiRiscattatiPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            premi: null,
        }
    }

    componentDidMount() {
        this.fetchPremi();
    }

    fetchPremi = _ => {
        swalLoading('Recupero premi in corso...')
        FidelityCardAPI.User.bookedPrizes(this.props.idNegozio)
            .then(premi => this.setState({premi: premi}, _ => swal.safeClose()));
    }

    render() {
        if (!this.state.premi) return '';

        return (
            <Page
                title={"I miei premi"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={true}
            >
                <div className={"container-fluid pt-3"}>
                    {
                        this.state.premi.length &&
                        <div className={"list-group"}>
                            {
                                this.state.premi.map(p => <FidelityCardPremiRiscattatiItem key={p.id_movimento} premioData={p}/>)
                            }
                        </div>
                    }
                </div>
            </Page>
        );
    }
}

FidelityCardPremiRiscattatiPage.propTypes = {
    idNegozio: PropTypes.string,
    onDismiss: PropTypes.func,
}