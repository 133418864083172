import React, {Fragment} from "react";
import PropTypes from "prop-types";
import PartnersAppItem from "./PartnersAppItem";


export class NegozioPartnershipAppCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            <Fragment>
                <div className={"container-fluid w-100 __disable-swipe mt-3"}>
                    <h4 className={"font-weight-bold text-on-bg-main"}>{this.props.infoLista ? this.props.infoLista.nome : ''}</h4>
                    <div className={"row flex-row flex-nowrap py-2"}
                         style={{scrollSnapType: "x mandatory", overflowX: "auto"}}>
                        {
                            this.props.apps.map((app, i) =>
                                <PartnersAppItem
                                    key={i}
                                    {...app}
                                    onAppAdd={this.props.onAppAdd}
                                    id_lista={this.props.infoLista ? this.props.infoLista.id_lista : null}
                                />
                            )
                        }
                    </div>
                </div>
            </Fragment>
        )
    }

}


NegozioPartnershipAppCarousel.propTypes = {
    apps: PropTypes.array.isRequired,
    infoLista: PropTypes.object
}
