import PropTypes from "prop-types";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import React, {Fragment} from "react";
import {WelfareAPI} from "../../../../../api/Welfare/WelfareAPI";
import {EllipsisLoader} from "../../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import styled from "styled-components";
import {PageFooter} from "../../../../../components/BaseComponents/Page/PageFooter";
import giftcardPlaceholder from "../../../../../img/giftcard-placeholder.png"
import DipendenteWelfareGiftCardsOrderResumePage from "../../DipendenteWelfareGiftCardsOrderResumePage";

const TaglioButton = styled.div`
    background-color: ${props => props.theme.main};
    color: ${props => props.theme.text};
    border: ${props => props.theme.border};
`;

// We are passing a default theme for Buttons that arent wrapped in the ThemeProvider
TaglioButton.defaultProps = {
    theme: {
        main: "white",
        text: "black",
        border: "0"
    }
}

// Define what props.theme will look like
const selected = {
    main: "#283069",
    text: "#fff",
    border: "0"
};


export class DipendenteWelfareGiftCardsProductPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variants: [],
            variantSelected: {},
            resumePageOpen: false
        }
    }

    componentDidMount() {
        this.fetchVariants()
    }

    fetchVariants = _ => {
        WelfareAPI.GiftCards.getProductVariants(this.props.product.id_prodotto)
            .then(variants => this.setState({variants: variants}))
    }

    getTagliDisponibiliGeneralInfo = _ => {
        //Se c'è solo una variante restituisco subito il massimo del taglio
        if (this.state.variants.length < 2) {
            if (parseFloat(this.state.variants[0].taglio) === 0) return false
            return "€" + parseFloat(this.state.variants[0].taglio).toFixed(2)
        }

        //Recupero min e max price per poter dare un range
        let minPrice = Number.MAX_SAFE_INTEGER; // initialize minPrice to maximum possible value
        let maxPrice = Number.MIN_SAFE_INTEGER; // initialize maxPrice to minimum possible value

        for (let i = 0; i < this.state.variants.length; i++) {
            let price = parseFloat(this.state.variants[i].taglio);
            if (price < minPrice) {
                minPrice = price; // update minPrice if price is less than current minPrice
            }
            if (price > maxPrice) {
                maxPrice = price; // update maxPrice if price is greater than current maxPrice
            }
        }

        return "da €" + parseFloat(minPrice).toFixed(2) + " a €" + parseFloat(maxPrice).toFixed(2)
    }


    handleVariantSelection = id_variante => {
        this.setState({variantSelected: this.state.variants.filter(v => v.id_variante === id_variante)[0]})
    }

    //Toggle resume page
    toggleOrderResumePage = _ => this.setState({resumePageOpen: !this.state.resumePageOpen})


    render() {

        return (
            <Fragment>
                <Page
                    title={this.props.product.name}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div>
                            <div className={"p-3"}>
                                <img src={(this.props.product.downloaded_image_url || giftcardPlaceholder)}
                                     style={{width: '100%'}}/>
                            </div>
                            {
                                this.state.variants.length > 0 ?
                                    <div className={"mb-5"}>
                                        <div className={"card card-body shadow-sm border-0 py-3"}>
                                            <h5 className={"font-weight-bold"}>{this.props.product.name}</h5>
                                            {
                                                this.getTagliDisponibiliGeneralInfo() ?
                                                    <div>
                                                        <span className={"text-muted"}>Tagli disponibili:</span>
                                                        <b className={"ml-2 text-primary"}>{this.getTagliDisponibiliGeneralInfo()}</b>
                                                    </div>
                                                    :
                                                    <span className={"text-warning"}>Non disponibile al momento</span>
                                            }
                                        </div>

                                        {
                                            this.getTagliDisponibiliGeneralInfo() &&
                                            <div className={"container"}>
                                                <div className={"mt-4 font-weight-bold"}>Seleziona un taglio:</div>
                                                <div className={"row px-2"}>
                                                    {
                                                        this.state.variants.map((v, k) => {
                                                            if (v.taglio > 0) return <div
                                                                className={"col-3 p-1 text-center"} key={k}>
                                                                <TaglioButton className={"p-2 shadow-sm"}
                                                                              theme={this.state.variantSelected.id_variante === v.id_variante ? selected : null}
                                                                              onClick={_ => this.handleVariantSelection(v.id_variante)}>
                                                                    €{parseFloat(v.taglio).toFixed(2)}
                                                                </TaglioButton>
                                                            </div>
                                                        })
                                                    }
                                                </div>

                                                {
                                                    this.props.product.short_description &&
                                                    <div>
                                                        <div className={"mt-4 font-weight-bold"}>Descrizione</div>
                                                        <div className={"card card-body shadow-sm border-0 p-2"}>
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: this.props.product.short_description}}/>
                                                        </div>
                                                    </div>
                                                }

                                                {/*INFO AGGIUNTIVE SU TAGLIO SCELTO*/}
                                                {
                                                    this.state.variantSelected.suitable_for &&
                                                    <div>
                                                        <div className={"mt-4 font-weight-bold"}>Informazioni prodotto
                                                        </div>
                                                        <div className={"card card-body shadow-sm border-0 p-2"}>
                                                            {this.state.variantSelected.expiration_activation && this.state.variantSelected.expiration_activation !== "" &&
                                                                <div className={"mt-2"}><b
                                                                    className={"mr-2"}>Validità:</b>{this.state.variantSelected.expiration_activation}
                                                                </div>}
                                                            {this.state.variantSelected.usable_at &&
                                                                <div className={"mt-2"}>
                                                                    <b className={"mr-2"}>Utilizzabile presso:</b>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: this.state.variantSelected.usable_at}}/>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className={"w-100 text-center mt-2"}><EllipsisLoader/></div>
                            }

                            <PageFooter className={"bg-white p-3 border-top shadow-sm"}>
                                {/*
                                    <div>
                                        <b className={"text-danger my-2"}>
                                            Attenzione il sistema per l'acquisto delle Gift Card è in manutenzione.
                                            Ci scusiamo per il disagio, servirà per rendere
                                            il sistema più veloce e preciso!
                                        </b>
                                        <button
                                            className={"btn btn-primary btn-lg btn-block disabled mt-3"}>
                                            Procedi con l'acquisto {this.state.variantSelected.id_variante &&
                                            <span>(€{parseFloat(this.state.variantSelected.taglio).toFixed(2)})</span>}
                                        </button>
                                    </div>
                                */}
                                {
                                    !this.state.variantSelected.id_variante &&
                                    <span className={"text-warning my-2"}>Scegli un taglio per procedere</span>}
                                {
                                    <button
                                        className={"btn btn-primary btn-lg btn-block " + (this.state.variantSelected.id_variante ? "" : "disabled")}
                                        onClick={this.toggleOrderResumePage}>
                                        Procedi con l'acquisto {this.state.variantSelected.id_variante &&
                                        <span>(€{parseFloat(this.state.variantSelected.taglio).toFixed(2)})</span>}
                                    </button>
                                }
                            </PageFooter>
                        </div>
                    }
                />

                {
                    this.state.resumePageOpen &&
                    <DipendenteWelfareGiftCardsOrderResumePage
                        onDismiss={this.toggleOrderResumePage}
                        active={true}
                        product={this.props.product}
                        variant={this.state.variantSelected}
                    />
                }

            </Fragment>
        )
    }
}

DipendenteWelfareGiftCardsProductPage.propTypes = {
    product: PropTypes.object,
    onDismiss: PropTypes.func,
    active: PropTypes.bool
}
