import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {logShadeColor, shadeColor} from "../../../helpers/UIUtility";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import moment from "moment";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";
import {PacchettoDetailPage} from "../PacchettoDetailPage/PacchettoDetailPage";

export class PacchettiListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false
        }
    }

    toggleDetail = _ => this.setState({detailOpen: !this.state.detailOpen});

    render() {
        const pacchetto = this.props.pacchetto;
        const humanReadableDate = moment(pacchetto.data_creazione).format('ddd D MMM YYYY');

        return (
            <Fragment>
                <div className={"card shadow-sm border-0 mt-3 rounded-xl overflow-hidden text-on-bg-primary"}
                     onClick={this.toggleDetail}>
                    <PacchettoCardBackground color={this.props.color || '#293069'}>
                        <h5 className={"font-weight-bold"}>
                            <SafeHtmlContainer html={pacchetto.nome}/>
                        </h5>
                        <div className={"text-center my-3"}>
                            <small className={"text-uppercase"}>saldo</small>
                            <h3 className={"font-weight-bold text-center"}>{pacchetto.punti_rimanenti}/{pacchetto.punti}</h3>
                        </div>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <div className={"small"}>
                                Acquistato {humanReadableDate}
                            </div>
                            <div className={"small text-right"}>
                                Cod. #{pacchetto.id_pacchetto}
                            </div>
                        </div>
                        <div className={"small text-uppercase"}>
                            Tocca per ulteriori dettagli
                        </div>
                    </PacchettoCardBackground>
                </div>
                <ModalPortalHOC>
                    {
                        this.state.detailOpen &&
                        <PacchettoDetailPage
                            pacchetto={pacchetto}
                            onDismiss={this.toggleDetail}
                            onRefresh={this.props.onRefresh}
                            color={this.props.color}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

PacchettiListItem.propTypes = {
    pacchetto: PropTypes.object.isRequired,
    color: PropTypes.string,
    enableClick: PropTypes.bool,
    onRefresh: PropTypes.func
}

PacchettiListItem.defaultProps = {
    enableClick: true
}

export function PacchettoCardBackground(props) {

    const colors = [
        logShadeColor(props.color, +10),
        logShadeColor(props.color, +5),
        logShadeColor(props.color, 0),
        logShadeColor(props.color, -10),
        logShadeColor(props.color, -20),
        logShadeColor(props.color, -30),
    ];

    const style = {
        backgroundColor: `${colors[0]}`,
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='${colors[1].replace('#', '%23')}' points='1600%2C160 0%2C460 0%2C350 1600%2C50'/%3E%3Cpolygon fill='${colors[2].replace('#', '%23')}' points='1600%2C260 0%2C560 0%2C450 1600%2C150'/%3E%3Cpolygon fill='${colors[3].replace('#', '%23')}' points='1600%2C360 0%2C660 0%2C550 1600%2C250'/%3E%3Cpolygon fill='${colors[4].replace('#', '%23')}' points='1600%2C460 0%2C760 0%2C650 1600%2C350'/%3E%3Cpolygon fill='${colors[5].replace('#', '%23')}' points='1600%2C800 0%2C800 0%2C750 1600%2C450'/%3E%3C/g%3E%3C/svg%3E")`,
        backgroundSize: "auto 147%",
        backgroundPosition: "center",
        position: "relative",
        height: "100%",
        width: "100%",
        padding: "20px 20px",
        boxSizing: "border-box",
    }

    return (
        <div style={style} className={"text-on-bg-primary"}>
            {props.children}
        </div>
    )
}