import React from 'react';
import ReactDOM from 'react-dom';
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import styled from "styled-components"
import {swal} from "../../helpers/SweetAlertWrapper";
import {text} from "@fortawesome/fontawesome-svg-core";

const CheckboxListGroupItemImage = styled.div`
width:40px;
height:40px;
background-position:center center;
background-repeat:no-repeat;
background-size:cover;
background-image:url(${props => props.image});
border-radius:50%;
`;

export default class CheckboxListGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected || [],
            itemsName: "CheckboxListGroup_" + (Math.random() * 1000).toFixed(0),
            query: '',
        };
    }

    handleChange = (value, checked) => {
        console.log(value, checked);
        let selected = this.props.selected.slice();
        if (!checked) {
            selected = selected.filter(v => v !== value);
        } else {
            selected.push(value);
        }
        //this.setState({selected: selected});
        this.props.onChange(selected, value, checked); //Restituisco la lista dei selezionati, il valore cambiato e lo stato attuale del valore
    }

    handleQueryChange = e => this.setState({query: e.target.value});
    handleQueryReset = _ => this.setState({query: ''});

    getFilteredItems = _ => {
        if (!this.state.query) {
            return this.props.items;
        }

        const keywords = this.state.query.toLowerCase().split(' ');


        return this.props.items.filter(item => {
            const textParts = textContent(item.text).toLowerCase().split(' ');
            return keywords.every(k => textParts.find(t => t.indexOf(k) !== -1));
        })
    }

    render() {
        return (
            <div>
                {!this.props.items.length && 'Nessuna opzione selezionabile'}
                {
                    !!this.props.items.length &&
                    <div className={"p-2"}>
                        <div className={"input-group"}>
                            <div className={"input-group-prepend"}>
                                <span className={"input-group-text bg-main-z2"}>
                                    <FaSolidIcon name={"search"}/>
                                </span>
                            </div>
                            <input
                                type={"text"}
                                name={"query"}
                                value={this.state.query}
                                className={"form-control form-control-sm border-left-0"}
                                placeholder={this.props.searchPlaceholder || "Ricerca per nome..."}
                                onChange={this.handleQueryChange}
                            />
                            {
                                !!this.state.query &&
                                <div className={"input-group-append"}>
                                    <button className={"btn btn-light btn-sm bg-main-z2 border"}
                                            onClick={this.handleQueryReset}>
                                        &times;
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    this.getFilteredItems().map((item, i) => {
                        const checkedOrder = this.props.selected.findIndex(v => v === item.value)
                        return (
                            <ListGroupCheckboxItem
                                key={item.value}
                                name={this.state.itemsName}
                                checked={checkedOrder !== -1}
                                checkedOrder={checkedOrder + 1}
                                showOrder={this.props.showOrder}
                                onChange={this.handleChange}
                                {...item}
                            />
                        )
                    })
                }
            </div>
        )
    }

}

CheckboxListGroup.defaultProps = {
    selected: []
}


class ListGroupCheckboxItem extends React.Component {

    constructor(props) {
        super(props);
    }

    handleChange = (changeEvent) => {
        this.props.onChange(changeEvent.target.value, changeEvent.target.checked);
    }

    handleImageClick = e => {
        e.stopPropagation();
        e.preventDefault();
        swal.fire({
            html: <img src={this.props.image} className={"img-fluid"}/>,
            showConfirmButton: false,
            showCloseButton: true,
            focusCancel: false, focusConfirm: false
        });
    }

    render() {
        if (this.props.isHeader) {
            return (
                <div className={"list-group-item bg-secondary text-on-bg-secondary px-0 rounded-0"}>
                    <div className={"container-fluid"}>
                        <span className={"text-on-bg-main"}>{this.props.text}</span>
                    </div>
                </div>
            )
        }
        return (
            <label className={"list-group-item bg-main-z2 px-0 rounded-0"}>
                <div className="container-fluid">
                    <div className={"row align-items-center"}>
                        <div className={"fux-radiobox " + (this.props.image ? "col-8" : "col-12")}>
                            <input type="checkbox" name={this.props.name} checked={this.props.checked}
                                   value={this.props.value} onChange={this.handleChange}/>
                            <span className="text-on-bg-main">
                                {this.props.checked ? <FaSolidIcon name={"check-square"} className={"mr-2 text-primary"}
                                                                   badge={this.props.showOrder && this.props.checked ? this.props.checkedOrder : undefined}
                                                                   badgeSide={"left"}/> :
                                    <FaRegularIcon name={"circle__"}/>}
                            </span>
                            <span className={"text-on-bg-main"}>{this.props.text}</span>
                            <small className={"d-block text-on-bg-main"}>{this.props.description}</small>
                        </div>
                        {
                            this.props.image ?
                                <div className={"col"}>
                                    <CheckboxListGroupItemImage onClick={this.handleImageClick} className={"mx-auto"}
                                                                image={this.props.image}/>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </label>
        )
    }

}


/**
 * Traverse any props.children to get their combined text content.
 *
 * This does not add whitespace for readability: `<p>Hello <em>world</em>!</p>`
 * yields `Hello world!` as expected, but `<p>Hello</p><p>world</p>` returns
 * `Helloworld`, just like https://mdn.io/Node/textContent does.
 *
 * NOTE: This may be very dependent on the internals of React.
 *
 * @see https://stackoverflow.com/questions/63141123/get-text-content-from-react-element-stored-in-a-variable
 *
 * @param elem React.ReactElement | string
 */
const textContent = (elem) => {
    if (!elem) {
        return '';
    }
    if (typeof elem === 'string') {
        return elem;
    }
    // Debugging for basic content shows that props.children, if any, is either a
    // ReactElement, or a string, or an Array with any combination. Like for
    // `<p>Hello <em>world</em>!</p>`:
    //
    //   $$typeof: Symbol(react.element)
    //   type: "p"
    //   props:
    //     children:
    //       - "Hello "
    //       - $$typeof: Symbol(react.element)
    //         type: "em"
    //         props:
    //           children: "world"
    //       - "!"
    const children = elem.props && elem.props.children;
    if (children instanceof Array) {
        return children.map(textContent).join('');
    }
    return textContent(children);
}
