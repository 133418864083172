import React from "react";
import styled from "styled-components";
import AppConfig from "../../config/AppConfig";
import bannerSmall from "../../img/coriandoli.png";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import {CategoryImageSize, SubcategoryImageSize} from "../../const/ImageSizeCostants";

export default class SearchPageCarouselSottocategorie extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.sottocategorie !== nextProps.sottocategorie || this.props.selected !== nextProps.selected;
    }

    getChunks() {
        let clone = this.props.sottocategorie.slice();

        clone = clone.map(function (c) {
            let newObj = {...c};
            newObj.immagine = getCompressedImageUrl(`${AppConfig.webServerUrl}/assets/sottocategorieNegozi/${c.id_sottocategoria}/${c.immagine}`, SubcategoryImageSize.thumbnail.w, SubcategoryImageSize.thumbnail.h, 90);
            return newObj;
        });

        return clone;
    }

    render() {
        return (
            <div className={"container-fluid"}>
                <BoxContainer className={"row flex-row flex-nowrap pb-3 __disable-swipe"}>
                    {
                        this.getChunks().map((s, j) => {
                            return <div className={"col-4 p-1"} key={j}>
                                <BoxCategoria
                                    style={{
                                        backgroundImage: `url('${s.immagine}')`
                                    }}
                                    onClick={_ => this.props.onChange(s.id_sottocategoria)}
                                    active={this.props.selected === s.id_sottocategoria}
                                    className={"d-flex flex-column p-2 shadow __disable-swipe"}
                                    src={s.immagine}
                                    color={this.props.accentColor}
                                >
                                    {
                                        this.props.selected === s.id_sottocategoria &&
                                        <BoxCategoriaCheckIcon name={"check-circle"} color={this.props.accentColor}/>
                                    }
                                    <BoxCategoriaName
                                        active={this.props.selected === s.id_sottocategoria}
                                        className={"text-white mt-auto"}
                                        color={this.props.accentColor}
                                    >
                                        {s.nome}
                                    </BoxCategoriaName>
                                </BoxCategoria>
                            </div>
                        })
                    }
                </BoxContainer>
            </div>
        )
    }
}

const BoxContainer = styled.div`
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    padding:0 15px;

    &::-webkit-scrollbar {
        display: none;
    }
`

const BoxCategoria = styled.div`
    position:relative;
    background-size:cover;
    background-position:center center;
    background-repeat:no-repeat;
    border-radius:0.5em;
    overflow:hidden;
    width:100%;
    height:75px;
    color: ${props => props.active ? props.color : "white"};

    &::before{
        content: ' ';
        position:absolute; top:0; left:0; right:0; bottom:0;
        background: ${props => props.active ? "#fff" : "rgba(0,0,0,0.3)"};
        transition: all .1s linear;
    }
`;


const BoxCategoriaName = styled.small`
      position: relative;
      z-index:1;
      color: ${props => props.active ? props.color : "white"}!important;
      width:100%;
      text-align:center;
`;

const BoxCategoriaCheckIcon = styled(FaSolidIcon)`
    position: absolute;
    top:30%; left:50%;
    transform: translateX(-50%) translateY(-50%);
    z-index:1;
    font-size:1.5rem;
    color:${props => props.color};
`;
