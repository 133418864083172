import React, {Fragment} from "react";
import ModalPortal from "../../components/BaseComponents/ModalPortal";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {DLA_WELFARE_CAPTURE_TRANSACTION} from "../../const/DeepLinkActionsCostants";
import AttivitaWelfareTransactionConfirmPage from "./AttivitaWelfareTransactionConfirmPage";


export default class AttivitaWelfareCaptureTransactionListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authCode: null
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_WELFARE_CAPTURE_TRANSACTION, this.handlePagamentoWelfareScan);
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_WELFARE_CAPTURE_TRANSACTION, this.handlePagamentoWelfareScan);
    }

    handlePagamentoWelfareScan = authCode => {
        this.setState({authCode: authCode});
    }

    handlePagamentoDismiss = _ => this.setState({authCode: null});

    render() {
        return (
            <ModalPortal>
                {
                    this.state.authCode ?
                        <AttivitaWelfareTransactionConfirmPage
                            active={!!this.state.authCode}
                            authCode={this.state.authCode}
                            negozio = {this.props.negozio}
                            onDismiss={this.handlePagamentoDismiss}
                        />
                        : ''
                }
            </ModalPortal>
        )
    }


}
