import React, {Component, Fragment} from "react";
import Page from "../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {ModuloGruppiAPI} from "../../api/ModuloGruppi/ModuloGruppiAPI";
import FuxCursorPaginator from "../../components/BaseComponents/FuxCursorPaginator/FuxCursorPaginator";
import PropTypes from "prop-types";
import {WaitingListItem} from "./WaitingListItem";

export class WaitingListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /**
     * @PAGINATOR-GRUPPI
     */
    handleWaitingLIstItemRender = item => <WaitingListItem item={item}/>
    handleWaitingListPageRequest = cursor => ModuloGruppiAPI.WaitingList.pagination(cursor)
    handleWaitingListEmptyPage = _ => <div className={"text-center"}>Non hai prenotazioni in attesa al momento</div>


    render() {
        return (
            <Fragment>
                <Page
                    title={"Lista d'attesa"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >

                    <div className={"container mt-2"}>
                        <FuxCursorPaginator
                            onItemRender={this.handleWaitingLIstItemRender}
                            onPageRequest={this.handleWaitingListPageRequest}
                            onEmptyPage={this.handleWaitingListEmptyPage}
                        />
                    </div>
                </Page>
            </Fragment>
        )
    }
}

WaitingListPage.propTypes = {
    onDismiss: PropTypes.func,
}