import React, {Fragment} from "react";
import i18next from "i18next";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {FingerPrintIllustration} from "../../../img/illustrations/login/FingerPrintIllustration";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";

export const OTP_PROVIDER_SMS = 'sms';
export const OTP_PROVIDER_WHATSAPP = 'whatsapp';
export const OTP_PROVIDERS = [OTP_PROVIDER_WHATSAPP, OTP_PROVIDER_SMS];


export default class OtpProviderChoose extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            provider: OTP_PROVIDERS[0],
        }
        this.imageStyle = {
            width: 150,
            maxWidth: "70%"
        }
    }

    handleProviderChange = p => this.setState({provider: p});

    handleConfirm = values => {
        this.props.confirmValue({
            "otp_provider": this.state.provider,
        })
    }

    render() {
        let negozioAspetto = NegozioOpenStore.infoNegozio ? NegozioOpenStore.infoNegozio.aspetto : null

        return (
            <Fragment>
                <div className={"text-center px-3 pb-2"}>
                    <div className={"w-100"}>
                        <FingerPrintIllustration style={this.imageStyle} color={negozioAspetto ? negozioAspetto.secondary_color : "#6861ce"}/>
                    </div>
                    <h2 className={"text-primary text-primary font-weight-bold"}>{i18next.t('register.heading4')}</h2>
                    <p className={"text-muted"}>{i18next.t('register.subheading4')}</p>
                </div>

                <div className={"py-3"}>
                    {
                        OTP_PROVIDERS.map(provider => {
                            return <OtpItem provider={provider} selected={this.state.provider === provider}
                                            onChange={this.handleProviderChange}/>
                        })
                    }
                </div>

                <div className={"py-3"}>
                    <button type={"button"} className={"btn btn-primary btn-block shadow"}
                            onClick={_ => this.handleConfirm(this.state)}>
                        {i18next.t('generic.proceed-btn')}
                    </button>
                </div>
            </Fragment>
        )
    }
}


function OtpItem({provider, selected, onChange}) {
    const style = {
        cursor: 'pointer',
        border: selected ? '2px solid #293069' : '2px solid transparent'
    }

    const iconClass = {
        [OTP_PROVIDER_SMS]: 'fas fa-comment-sms text-blue mr-2',
        [OTP_PROVIDER_WHATSAPP]: 'fab fa-whatsapp text-success mr-2'
    }[provider];

    const iconStyle = {
        fontSize: '1.5rem'
    }

    const handleClick = _ => onChange(provider);

    return <div className={"bg-main-z3 shadow-sm p-3 mb-3 rounded"} style={style} onClick={handleClick}>
        <div className={"d-flex align-items-center justify-content-between"}>
            <div className={"d-flex align-items-center"}>
                <i className={iconClass} style={iconStyle}/> {i18next.t(`register.receive-${provider}`)}
            </div>
            {
                selected && <FaSolidIcon name={"check-circle"} className={"text-primary"}/>
            }
        </div>
    </div>

}
