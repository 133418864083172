import React from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaSolidIcon} from "../FontAwesomeIcons";
import RadioListGroup from "../BaseComponents/RadioListGroup";
import {swalError} from "../../helpers/SweetAlertWrapper";
import RadioListCard from "../BaseComponents/RadioListCard";
import {PageFooter, PageWithFooterContainer} from "../BaseComponents/Page/PageFooter";
import DiscountBoxToWarnUser from "../DiscountBoxToWarnUser";

export default class ItemChooserPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onDismiss: props.onDismiss,
            onChoose: props.onChoose,
            selected: undefined
        };

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleChoose = this.handleChoose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleDismiss() {
        this.state.onDismiss();
    }

    handleChoose() {
        if (this.props.required) {
            if (this.state.selected !== undefined) {
                this.state.onChoose(this.state.selected);
            } else {
                swalError("Devi scegliere un'opzione per poter proseguire");
            }
            return;
        }
        this.state.onChoose(this.state.selected);
    }

    handleChange(value) {
        this.setState({selected: value});
    }

    render() {
        return (
            <Page
                title={this.props.title}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.handleDismiss}
                active={this.props.active}
                content={
                    <PageWithFooterContainer>
                        <div className={"container pt-2"}>
                            {/*Stampo box per far capire la presenza di uno sconto esterno*/}
                            {this.props.promotionDiscount && <DiscountBoxToWarnUser discount={this.props.promotionDiscount}/>}

                            {
                                this.props.subtitle ?
                                    <div className={"mb-2 px-1"}>
                                        <small
                                            className={"text-uppercase text-on-bg-main"}>{this.props.subtitle}</small>
                                    </div>
                                    : ''
                            }
                            {this.props.itemsType === 'list-group' ?
                                <RadioListGroup items={this.props.items} onChange={this.handleChange}/> : ''}
                            {this.props.itemsType === 'card' ?
                                <RadioListCard items={this.props.items} onChange={this.handleChange}/> : ''}
                        </div>
                    </PageWithFooterContainer>
                }
                footer={
                    <PageFooter className={"bg-main-z2 p-3 border-top"}>
                        <button className={"btn btn-lg btn-block btn-primary text-on-bg-primary"} onClick={this.handleChoose}>
                            Continua <FaSolidIcon name={"chevron-right"}/>
                        </button>
                    </PageFooter>
                }
            />
        );
    }


}

ItemChooserPage.defaultProps = {
    itemsType: 'list-group'
};


ItemChooserPage.propTypes = {
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func,
    active: PropTypes.bool,
    itemsType: PropTypes.string
};
