import React, {Fragment} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import Page from "../../../../components/BaseComponents/Page/Page";
import moment from "moment";
import {swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import DashboardAddServiceBookModal from "./DashboardAddServiceBookModal";
import DashboardDateChooserPage from "./DashboardDateChooserPage";
import {ModuloServiziAPI} from "../../../../api/ModuloServizi/ModuloServiziAPI";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {BookResumePage} from "./BookResumePage/BookResumePage";
import {LightCalendar} from "./LightCalendar/LightCalendar";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {CompleteCalendar} from "./CompleteCalendar/CompleteCalendar";
import FuxHTTP from "../../../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../../../config/AppConfig";


const DAYS_BEFORE_TODAY = 100;
const DAYS_AFTER_TODAY = 100;
export const EVT_REFETCH_EVENTS = 'EVT_REFETCH_EVENTS_DASHBOARD_MOD_SERVIZI';

const VIEW_TYPE_LIGHT = 'light';
const VIEW_TYPE_COMPLETE = 'full';
const DEFAULT_VIEW_TYPE_LOCAL_STORAGE_KEY = 'dashboard_servizi_default_view_type';

const ViewTypeWrapper = styled.div`
    & .btn.btn-sm:focus{
        box-shadow: none!important;
    }
`;

const FloatingButton = styled.button`
    position:fixed;
    bottom: 15px; 
    right: 15px; 
    z-index: 1;
    width:48px;
    height:48px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
`;

const FloatingButtonCenter = styled.button`
    position:fixed;
    bottom: 15px; 
    left: 50%;
    transform: translateX(-50%); 
    z-index: 1;
    padding:0px 15px;
    height:48px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:500px;
`;

export class CalendarioServizi extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            addBookOpen: false,
            reschedulingBookInfo: false,
            editTimeBookInfo: false,
            addBookDefaultData: {
                orario: null,
                id_dipendente: null
            },
            idPrenotazioneOpen: null,
            viewType: VIEW_TYPE_LIGHT,
            editMode: false,
            editedEvents: {} //{"{{id_prenotazione}}": {start: Date(), end: Date(), resource_id: 123}}
        }

        const savedViewType = localStorage.getItem(DEFAULT_VIEW_TYPE_LOCAL_STORAGE_KEY);
        if (savedViewType) this.state.viewType = savedViewType;

        this.todayMoment = moment();
    }

    handleViewTypeChange = t => _ => this.setState({viewType: t}, _ => localStorage.setItem(DEFAULT_VIEW_TYPE_LOCAL_STORAGE_KEY, t));


    handleDateChange = date => this.setState({selectedDate: date});


    toggleAddBook = _ => this.setState({addBookOpen: !this.state.addBookOpen});

    handleAddBookWithData = data => this.setState({
        addBookDefaultData: {
            ...this.state.addBookDefaultData,
            ...data
        }
    }, this.toggleAddBook)

    fetchEvents = _ => FuxEvents.emit(EVT_REFETCH_EVENTS);

    /** @MARK: Deletion */

    handleBookDeleted = _ => {
        this.fetchEvents();
        this.handleCloseResume();
    }

    /** @MARK: TimeChange */

    handleStartTimeBookChanged = _ => this.fetchEvents();


    /** @MARK: Reschedule */

    handleReschedule = async bookInfo => {
        if (bookInfo) {
            if (bookInfo.id_servizio === null) { //Prenotazione senza servizio ma con durata
                //Non modifico nulla nelle bookInfo
            } else {
                //Recupero tutti i servizi della prenotazione e ricostruisco un nuovo book info per lo spostamento COMPLETO di tutto l'apputamento
                await ModuloServiziAPI.Dashboard.getFullBookInfo(bookInfo.id_parent ? bookInfo.id_parent : bookInfo.id_prenotazione)
                    .then(fullBookInfo => {
                        bookInfo = {
                            id_prenotazione: bookInfo.id_parent ? bookInfo.id_parent : bookInfo.id_prenotazione, //Setto l'id della prenotazione da modificare alla prenotazione "root"
                            id_sede: bookInfo.id_sede,
                            id_dipendente: bookInfo.dipendente_chiunque == 1 ? null : bookInfo.id_dipendente_scelto || bookInfo.id_dipendente,
                            id_servizio: fullBookInfo.books
                                .map(b => b.id_servizio_bundle ? b.id_servizio_bundle : b.id_servizio)
                                .filter(v => !!v)
                                .filter((v, idx, self) => self.indexOf(v) === idx) //Se il servizio fa parte di un bundle userò solo il riferimento del bundle, ma ciò comporta avere id del bundle duplicati. Con filter elimino i duplicati.
                        };
                    });
            }
        }
        this.setState({reschedulingBookInfo: bookInfo || false});
    }

    handleRescheduleDismiss = _ => this.setState({reschedulingBookInfo: null});

    handleRescheduleDateChoose = dateTime => {
        swalLoading('Attendere prego...');

        const methodAPI = this.state.reschedulingBookInfo.id_servizio === null ? ModuloServiziAPI.Dashboard.editTime : ModuloServiziAPI.Dashboard.editFullBookTime;

        methodAPI({...this.state.reschedulingBookInfo, data: dateTime.data, orario: dateTime.orario})
            .then(({message, data}) => {
                swalSuccess(message);
                this.setState({reschedulingBookInfo: null});
                this.handleOpenResume(data);
                this.fetchEvents();
            })
            .catch(swalError);
    }

    /** @MARK: Manual rescheduling resizing */

    handleEditModeStart = _ => this.setState({editMode: true});
    handleEditModeEnd = _ => this.setState({editMode: false});
    handleEditedEventsChange = events => this.setState({editedEvents: events});
    handleEditModeSave = _ => {
        if (Object.keys(this.state.editedEvents).length) {
            swalLoading('Salvataggio modifiche...');
            const MINUTES_PER_TICK = 5;
            let promises = Object.keys(this.state.editedEvents).map(id_prenotazione => {
                const e = this.state.editedEvents[id_prenotazione];
                const data = moment(e.start).format('DD-MM-YYYY');
                const orario = moment(e.start).format('HH:mm');
                const durata = (moment(e.end).toDate().getTime() - moment(e.start).toDate().getTime()) / 1000 / 60 / MINUTES_PER_TICK;
                const newBookInfo = {
                    id_prenotazione: id_prenotazione,
                    data: data,
                    orario: orario,
                    durata: durata,
                    id_dipendente: e.resource_id
                }
                return ModuloServiziAPI.Dashboard.editTime(newBookInfo)
            });

            Promise.all(promises)
                .then(_ => {
                    swalSuccess('Prenotazioni salvate correttamente');
                    this.setState({editedEvents: {}, editMode: false}, _ => FuxEvents.emit(EVT_REFETCH_EVENTS));
                })
                .catch(_ => swalError('Qualcosa è andato storto. Non tutte le modifiche sono state salvate.'));
        } else {
            this.setState({editedEvents: {}, editMode: false}, _ => FuxEvents.emit(EVT_REFETCH_EVENTS));
        }
    }

    /** @MARK: Resume page */

    handleOpenResume = id_prenotazione => this.setState({idPrenotazioneOpen: id_prenotazione});

    handleCloseResume = _ => this.setState({idPrenotazioneOpen: null});


    render() {

        let editModeBtn = '';
        let editModeBtnAction = null;
        if (this.state.viewType === VIEW_TYPE_COMPLETE) editModeBtn = this.state.editMode ? 'Annulla' :
            <FaSolidIcon name={"edit"}/>;
        if (this.state.viewType === VIEW_TYPE_COMPLETE) editModeBtnAction = this.state.editMode ? this.handleEditModeEnd : this.handleEditModeStart;

        return (
            <Fragment>
                <Page
                    title={
                        <ViewTypeWrapper className={"btn-group border border-white rounded"}>
                            <button
                                className={`btn btn-sm py-0 btn-${this.state.viewType === VIEW_TYPE_LIGHT ? 'light' : 'primary'}`}
                                onClick={this.handleViewTypeChange(VIEW_TYPE_LIGHT)}>
                                <FaSolidIcon name={"list"}/>
                            </button>
                            <button
                                className={`btn btn-sm py-0 btn-${this.state.viewType === VIEW_TYPE_COMPLETE ? 'light' : 'primary'}`}
                                onClick={this.handleViewTypeChange(VIEW_TYPE_COMPLETE)}>
                                <FaSolidIcon name={"calendar-alt"}/>
                            </button>
                        </ViewTypeWrapper>
                    }

                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    rightIcon={editModeBtn}
                    onRightIconClick={editModeBtnAction}
                    active={true}
                >
                    {
                        this.state.viewType === VIEW_TYPE_LIGHT &&
                        <LightCalendar
                            onDateChange={this.handleDateChange}
                            daysBeforeToday={DAYS_BEFORE_TODAY}
                            daysAfterToday={DAYS_AFTER_TODAY}
                            infoNegozio={this.props.infoNegozio}
                            onEventResumeOpen={this.handleOpenResume}
                            onAddBookWithData={this.handleAddBookWithData}
                        />
                    }
                    {
                        this.state.viewType === VIEW_TYPE_COMPLETE &&
                        <CompleteCalendar
                            onDateChange={this.handleDateChange}
                            daysBeforeToday={DAYS_BEFORE_TODAY}
                            daysAfterToday={DAYS_AFTER_TODAY}
                            infoNegozio={this.props.infoNegozio}
                            onEventResumeOpen={this.handleOpenResume}
                            onAddBookWithData={this.handleAddBookWithData}
                            editMode={this.state.editMode}
                            editedEvents={this.state.editedEvents}
                            onEventEditedChange={this.handleEditedEventsChange}
                        />
                    }
                    {
                        !this.state.editMode &&
                        <FloatingButton className={"btn btn-primary shadow"} onClick={this.toggleAddBook}>
                            <FaSolidIcon name={"plus"}/>
                        </FloatingButton>
                    }
                    {
                        this.state.editMode &&
                        <FloatingButtonCenter className={"btn btn-primary shadow"} onClick={this.handleEditModeSave}>
                            <span><FaSolidIcon name={"save"}/> Salva modifiche</span>
                        </FloatingButtonCenter>
                    }
                </Page>
                <ModalPortalHOC>
                    {
                        this.state.addBookOpen &&
                        <DashboardAddServiceBookModal
                            bookingFlow={this.props.infoNegozio.impostazioni.booking_flow}
                            idNegozio={this.props.infoNegozio.id_negozio}
                            onDismiss={this.toggleAddBook}
                            onBook={this.fetchEvents}
                            selectedDate={this.state.selectedDate}
                            startTime={this.state.addBookDefaultData.orario}
                            idDipendenteDefault={this.state.addBookDefaultData.id_dipendente}
                            enableBlockTime={this.props.enableBlockTime}
                        />
                    }

                    {
                        this.state.idPrenotazioneOpen &&
                        <BookResumePage
                            idPrenotazione={this.state.idPrenotazioneOpen}
                            optGroupServizi={this.props.infoNegozio.servizi_optgroup || []}
                            onDismiss={this.handleCloseResume}
                            onReplaceParentId={this.handleOpenResume}
                            onReschedule={this.handleReschedule}
                            onDelete={this.handleBookDeleted}
                            onEditTime={this.handleStartTimeBookChanged}
                            onRefetchData={this.fetchEvents}
                        />
                    }

                    {
                        this.state.reschedulingBookInfo &&
                        <DashboardDateChooserPage
                            bookInfo={this.state.reschedulingBookInfo}
                            onChoose={this.handleRescheduleDateChoose}
                            onDismiss={this.handleRescheduleDismiss}
                            active={true}
                            confirmText={"Conferma"}
                            durata={this.state.reschedulingBookInfo.id_servizio ? null : this.state.reschedulingBookInfo.durata}
                            forceDipendente={true}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        );
    }

}

CalendarioServizi.propTypes = {
    infoNegozio: PropTypes.object,
    onDismiss: PropTypes.func,
    enableBlockTime: PropTypes.bool
}

