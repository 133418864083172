import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import AppConfig from "../../../config/AppConfig";
import i18n from "../../../i18n";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import styled from "styled-components";
import {DashboardModuloServiziButton} from "./Buttons/DashboardModuloServiziButton";
import {DashboardModuloGruppiButton} from "./Buttons/DashboardModuloGruppiButton";
import {DashboardModuloEcommerceButton} from "./Buttons/DashboardModuloEcommerceButton";
import {DashboardModuloComandeButton} from "./Buttons/DashboardModuloComandeButton";
import {DashboardModuloFoodButton} from "./Buttons/DashboardModuloFoodButton";
import {DashboardPubblicaPostButton} from "./Buttons/DashboardPubblicaPostButton";
import {SubscriptionMessageView} from "./SubscriptionMessageView";
import DashboardFidelityCardListener from "./FidelityCard/DashboardFidelityCardListener";
import DashboardUpaidInvoices from "./DashboardUpaidInvoices";
import {DashboardInvitaUnAmicoButton} from "./Buttons/DashboardInvitaUnAmicoButton";
import {PacchettoAdminQRScanListener} from "../../Listeners/PacchettiListener/PacchettoAdminQRScanListener";
import {
    SUBSCRIPTION_STATUS_ACTIVE,
    SUBSCRIPTION_STATUS_SUSPENDEND, SUBSCRIPTION_STATUS_TERMINATED
} from "../../../const/SubscriptionConstants";
import moment from "moment";
import {DisputeButtonView} from "./Disputes/DisputeButtonView";
import AttivitaWelfareModal from "../../WelfareAttivita/AttivitaWelfareModal";
import {DashboardAttivitaWelfareButton} from "./Buttons/DashboardAttivitaWelfareButton";
import {MODULE_BY_CATEGORY_MAP} from "../../../const/ModuliConstants";
import {SubscriptionUnactiveModulesView} from "./SubscriptionUnactiveModulesView";
import {DashboardModuloCassaButton} from "./Buttons/DashboardModuloCassaButton";
import {DashboardModuloSeatsButton} from "./Buttons/DashboardModuloSeatsButton";
import {DashboardAdminSwitchListView} from "./AdminSwitch/DashboardAdminSwitchListView";
import {BookizonAppManager} from "../../../index";
import {DashboardBanner} from "./DashboardBanner";

const DashboardViewAccordion = styled.div`
  transition: all .3s linear;
  overflow: hidden;
  
  &.opened{
    max-height: 500px;
    opacity:1;
    transition: all .2s ease;
  }
  
  &.closed{
    max-height: 1px;
    opacity:0;
    transition: all .2s ease;
  }
`;


export class AdminDashboardView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewOpen: false
        }
        this.dashboardDisabledStyle = {
            borderLeft: '5px solid #dc3545'
        }
    }

    toggleViewOpen = _ => this.setState({viewOpen: !this.state.viewOpen});


    render() {
        const negozioIconUrl = `${AppConfig.webServerUrl}/api/negozio/${this.props.user.negozio.id_negozio}/logoIcon/100`;

        /**
         * La dashboard in generale non è abilitata se non esiste una subscription oldstyle pagata e se non esiste almeno
         * 1 modulo che sia stato pagato.
         * */
        const dashboardEnabled = this.props.user.isAdmin &&
            (
                this.props.user.negozio.modulesSubscription.filter(m => m.status === SUBSCRIPTION_STATUS_ACTIVE).length > 0 || //Almeno 1 modulo acquistato
                this.props.user.negozio.trialDays > 0 //Ha ancora giorni di prova
            );

        if (!dashboardEnabled) {
            return (
                <Fragment>
                    <div className={"container mt-3"}>
                        <div className={"bg-white shadow-sm rounded py-2"} style={this.dashboardDisabledStyle}>
                            {/* Header */}
                            <div className={"d-flex align-items-center justify-content-center"}
                                 onClick={this.toggleViewOpen}>
                                <AppIconThumbnail size={"25px"} src={negozioIconUrl} className={"mr-2"}/>
                                <div className={"mr-2"}>{i18n.t(`launcher.homepage.la-mia-attivita`)}</div>
                            </div>

                            <SubscriptionUnactiveModulesView
                                subscriptions={this.props.user.negozio.modulesSubscription}
                                idCategoria={this.props.user.negozio.id_categoria}
                            />

                            {/* Fatture */}
                            <DashboardUpaidInvoices invoices={this.props.user.negozio.invoices}/>
                        </div>
                    </div>
                </Fragment>
            )
        }

        return (

            <Fragment>
                <div className={"container mt-3"}>
                    <DashboardBanner negozio={this.props.user.negozio}/>
                    <div className={"bg-white shadow-sm rounded py-2"}>
                        <div className={"d-flex align-items-center justify-content-center"}
                             onClick={this.toggleViewOpen}>
                            <AppIconThumbnail size={"25px"} src={negozioIconUrl} className={"mr-2"}/>
                            <div className={"mr-2"}>{i18n.t(`launcher.homepage.la-mia-attivita`)}</div>
                            <FaSolidIcon name={this.state.viewOpen ? "chevron-up" : "chevron-down"}/>
                        </div>
                        <DisputeButtonView disputes={this.props.user.negozio.disputes}/>
                        <DashboardViewAccordion className={this.state.viewOpen ? "opened overflow-auto" : "closed"}>
                            <div className={"mt-2"}>

                                <SubscriptionUnactiveModulesView
                                    subscriptions={this.props.user.negozio.modulesSubscription}
                                    idCategoria={this.props.user.negozio.id_categoria}
                                />
                                <DashboardUpaidInvoices invoices={this.props.user.negozio.invoices}/>
                                {
                                    this.props.user.negozio.dashboard_servizi &&
                                    <DashboardModuloServiziButton
                                        labelName={this.props.user.negozio.dashboard_servizi}
                                        prenotazioniSospese={this.props.user.negozio.prenotazioniServiziSospese}
                                        enableBlockTime={this.props.user.negozio.enableBlockTimeServizi == 1}
                                    />
                                }
                                {
                                    this.props.user.negozio.dashboard_gruppi &&
                                    <DashboardModuloGruppiButton
                                        labelName={this.props.user.negozio.dashboard_gruppi}
                                        prenotazioniSospese={this.props.user.negozio.prenotazioniGruppiSospese}
                                    />
                                }
                                {
                                    this.props.user.negozio.dashboard_shop &&
                                    <DashboardModuloEcommerceButton
                                        labelName={this.props.user.negozio.dashboard_shop}
                                        ordiniSospesi={this.props.user.negozio.ordiniShopSospesi}
                                        userNegozio={this.props.user.negozio}
                                    />
                                }
                                {
                                    this.props.user.negozio.dashboard_food &&
                                    <DashboardModuloFoodButton
                                        labelName={this.props.user.negozio.dashboard_food}
                                        ordiniSospesi={this.props.user.negozio.ordiniFoodSospesi}
                                        userNegozio={this.props.user.negozio}
                                    />
                                }
                                {
                                    this.props.user.negozio.dashboard_comande &&
                                    <DashboardModuloComandeButton
                                        labelName={this.props.user.negozio.dashboard_comande}
                                        tavoliLiberi={this.props.user.negozio.tavoliLiberi}
                                        userNegozio={this.props.user.negozio}
                                    />
                                }
                                {
                                    this.props.user.negozio.dashboard_seats &&
                                    <DashboardModuloSeatsButton
                                        labelName={this.props.user.negozio.dashboard_seats}
                                        userNegozio={this.props.user.negozio}
                                        waitingCheckinBooks={this.props.user.negozio.waitingCheckinBooks}
                                    />
                                }
                                {
                                    this.props.user.negozio.dashboard_cassa &&
                                    <DashboardModuloCassaButton
                                        labelName={this.props.user.negozio.dashboard_cassa}
                                        userNegozio={this.props.user.negozio}
                                    />
                                }
                                {
                                    this.props.user.negozio.walletapikey &&
                                    <DashboardAttivitaWelfareButton
                                        userNegozio={this.props.user.negozio}
                                    />
                                }
                                <DashboardInvitaUnAmicoButton
                                    userNegozio={this.props.user.negozio}
                                />
                                {
                                    this.props.user.negozio.dashboard_post &&
                                    <DashboardPubblicaPostButton
                                        userNegozio={this.props.user.negozio}
                                    />
                                }
                                {
                                    this.props.user.negozio.available_shops?.length > 0 &&
                                    <DashboardAdminSwitchListView
                                        availableShops={this.props.user.negozio.available_shops}
                                    />
                                }
                            </div>
                        </DashboardViewAccordion>
                    </div>
                </div>

                <DashboardFidelityCardListener/>
                <PacchettoAdminQRScanListener idNegozio={this.props.user.negozio.id_negozio}/>

            </Fragment>
        )
    }

}

AdminDashboardView.propTypes = {
    user: PropTypes.object.isRequired
}
