import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";


const _Books = {
    getNextBooks: function () {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-hotel/books/next-books`), {
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getBookData: function (id_prenotazione) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-hotel/books/get-book-data`), {
                token: AuthAPI.getUserAccessToken(),
                id_prenotazione: id_prenotazione
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    requestRefund: (id_prenotazione, reason) => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-hotel/books/request-refund`), {
                token: AuthAPI.getUserAccessToken(),
                id_prenotazione: id_prenotazione,
                reason: reason
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default _Books;