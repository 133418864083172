import React from 'react'
import AuthAPI from "../../api/AuthAPI";
import {swal, swalError, swalLoading, swalSuccess} from '../../helpers/SweetAlertWrapper';
import ValidateOTPPage from "../ValidateOTPPage";
import {FaSolidIcon} from "../FontAwesomeIcons";
import 'react-phone-input-2/lib/bootstrap.css'
import {PhoneNumberInput} from "../BaseComponents/Inputs/PhoneNumberInput";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";
import {PageZLayerSelector} from "../BaseComponents/Page/PageZLayerSelector";
import i18next from "i18next";


export default class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
            otp_provider: 'sms',
            passwordVisible: '',
            isValidateOtpOpen: false,
            id_utente: null,
            isRequesting: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    togglePassword = _ => this.setState({passwordVisible: !this.state.passwordVisible});
    handlePhoneNumberChange = phone => this.setState({user: phone});

    handleSubmit(event) {
        event.preventDefault();
        this.setState({isRequesting: true});
        AuthAPI.loginWithCredentials(this.state.user, this.state.password)
            .catch((response) => {
                swalError(response.message);
                if (response.data && response.data.reason === 'account-unconfirmed') {
                    this.setState({
                        id_utente: response.data.id_utente,
                        cellulare: response.data.cellulare,
                        isValidateOtpOpen: true,
                    });
                    AuthAPI.resendOTP(response.data.id_utente, this.state.otp_provider);
                }
                this.setState({isRequesting: false});
            });
    }

    handleValidateOTP = otp => {
        AuthAPI.confirmAccountWithOTP(this.state.id_utente, otp)
            .then(token => {
                swal.fire({
                    icon: 'success',
                    html: 'Complimenti! Il tuo account è stato confermato',
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Procedi'
                }).then(_ => {
                    AuthAPI.saveAccessToken(token);
                    AuthAPI.loginWithAccessToken();
                    this.setState({isValidateOtpOpen: false});
                });
            })
            .catch(swalError);
    }


    handleResendOTP = otp_provider => {
        swalLoading();
        this.setState({otp_provider: otp_provider}, _ => {
            AuthAPI.resendOTP(this.state.id_utente, this.state.otp_provider)
                .then(swalSuccess)
                .catch(swalError);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'user') {
            value = value.toLowerCase();
        }
        this.setState({
            [name]: value,
        });
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <form autoComplete={"off"} onSubmit={this.handleSubmit} className={"text-left"}>
                        <input autoComplete="on" style={{display: 'none'}}
                               id="fake-hidden-input-to-stop-google-address-lookup"/>
                        <div className={"form-group"}>
                            <PhoneNumberInput value={this.state.user} onChange={this.handlePhoneNumberChange}/>
                            <div className={"input-group mt-2"}>
                                <input autoComplete={"new-password"}
                                       type={this.state.passwordVisible ? 'text' : "password"}
                                       className={"form-control"}
                                       name="password" value={this.state.password}
                                       onChange={this.handleInputChange}
                                       placeholder={i18next.t("login.password-placeholder")}/>
                                <div className={"input-group-append"} onClick={this.togglePassword}>
                                <span className={"input-group-text"}>
                                    {this.state.passwordVisible ? <FaSolidIcon name={"eye-slash"}/> :
                                        <FaSolidIcon name={"eye"}/>}
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <button className={"btn btn-link pl-0"} type={"button"}
                                    onClick={this.props.onPasswordRecovery}>
                                {i18next.t("login.password-forgot-btn")}
                            </button>
                        </div>
                        <div className={"form-group"}>
                            <button className={"btn btn-primary btn-block"} disabled={this.state.isRequesting}>
                                {this.state.isRequesting ?
                                    <FaSolidIcon name={"spinner"}
                                                 className={"fa-pulse"}/> : i18next.t("login.login-btn")}
                            </button>
                        </div>
                    </form>
                </div>
                <ModalPortalHOC>
                    <PageZLayerSelector zIndex={3001}>
                        {
                            this.state.isValidateOtpOpen ?
                                <ValidateOTPPage
                                    resendOTP={this.handleResendOTP}
                                    onValidate={this.handleValidateOTP}
                                    message={
                                        <span>{i18next.t(`register.confirm-account-otp-headline-${this.state.otp_provider}`)} {this.state.cellulare}</span>
                                    }
                                /> : ''
                        }
                    </PageZLayerSelector>
                </ModalPortalHOC>
            </React.Fragment>
        )
    }

}