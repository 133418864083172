import React from "react";
import {FaRegularIcon, FaSolidIcon} from "../../../components/FontAwesomeIcons";
import {ReactComponent as BookizonIcon} from "../../svg/logo-icon.svg";
import styled from "styled-components";
import PostAPI from "../../../api/PostAPI";
import {POST_LIKE_TYPE_LIKE} from "../../../const/PostCostants";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {APP_FAVOURITE_ADDED, APP_FAVOURITE_REMOVED, POST_LIKE_CHANGE} from "../../../const/AppEventsCostants";
import {BookizonAppManager} from "../../../index";
import AppConfig from "../../../config/AppConfig";
import {PluralString} from "../../../helpers/UIUtility";
import {FeedPostPage} from "./FeedPostPage";
import AuthAPI from "../../../api/AuthAPI";

export class FeedPostCardActionBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userLiked: parseInt(this.props.userLiked) === 1,
            negozioAdded: parseInt(this.props.negozioAdded) === 1,
        }
    }

    componentDidMount() {
        FuxEvents.on(APP_FAVOURITE_ADDED, this.handleExternalAppAdd);
        FuxEvents.on(APP_FAVOURITE_REMOVED, this.handleExternalAppRemove);
        FuxEvents.on(POST_LIKE_CHANGE, this.handleExternalLikeChange);
    }

    componentWillUnmount() {
        FuxEvents.off(APP_FAVOURITE_ADDED, this.handleExternalAppAdd);
        FuxEvents.off(APP_FAVOURITE_REMOVED, this.handleExternalAppRemove);
        FuxEvents.off(POST_LIKE_CHANGE, this.handleExternalLikeChange);
    }

    handleExternalAppAdd = id_negozio => {
        if (id_negozio === this.props.id_negozio) {
            this.setState({negozioAdded: true});
        }
    }

    handleExternalAppRemove = id_negozio => {
        if (id_negozio === this.props.id_negozio) {
            this.setState({negozioAdded: false});
        }
    }

    handleExternalLikeChange = ({id_post, status}) => {
        if (id_post === this.props.id_post){
            this.setState({
                userLiked: status
            })
        }
    }

    toggleLike = _ => {
        this.setState({
            userLiked: !this.state.userLiked
        }, _ => {
            let promise = this.state.userLiked ? PostAPI.like(this.props.id_post) : PostAPI.unlike(this.props.id_post);
            //Se il toggle non è andato a buon fine sul server, allora ripristino lo stato
            promise
                .then(_ => FuxEvents.emit(POST_LIKE_CHANGE, {
                    id_post: this.props.id_post,
                    status: this.state.userLiked
                }))
                .catch(_ => this.setState({userLiked: !this.state.userLiked}))
        });
    }

    handleAppAction = _ => {
        this.props.onAppOpen(this.props.id_negozio);
    }

    handleShare = _ => {
        const message = `Guarda cosa ho trovato su Bookizon! Clicca questo link: ${AppConfig.webServerUrl}/dl/@view-post:${this.props.id_post}`;
        BookizonAppManager.shareData(message);
    }

    printLikeNumber = _ => {
        var likeNumber = parseInt(this.props.likeNumber);
        if(this.state.userLiked && parseInt(this.props.userLiked)){
            if(likeNumber-1 > 0) {
                return <PluralString n={likeNumber - 1} more={"Piace a te ed altre " + (likeNumber - 1) + " persone"} single={"Piace a te ed un'altra persona"}/>
            }
            return "Ti piace"
        }
        if(this.state.userLiked && !parseInt(this.props.userLiked)){
            if(likeNumber > 0){
                return <PluralString n={likeNumber} more={"Piace a te ed altre " + (likeNumber) + " persone"} single={"Piace a te ed un'altra persona"}/>
            }
            return "Ti piace"
        }
        if(parseInt(this.props.userLiked) && likeNumber-1 > 0){
            console.log(this.props.userLiked)
            return  "Piace a "+(likeNumber-1) + " persone";
        }
        if(!parseInt(this.props.userLiked) && likeNumber > 0){
            return  "Piace a " + this.props.likeNumber + " persone";
        }
        return ""
    }


    render() {
        return (
            <div className={"bg-white container"}>
                <span className={"text-muted"}>{this.printLikeNumber()}</span>
                <div className={"row py-2 text-primary border-bottom"}>
                    {
                        AuthAPI.currentUser &&
                        <div className={"col text-center"} onClick={this.toggleLike}>
                            <small>
                                {
                                    this.state.userLiked ?
                                        <span><FaSolidIcon name={"heart"}/> Ti piace</span> :
                                        <span><FaRegularIcon name={"heart"}/> Mi piace</span>
                                }
                            </small>
                        </div>
                    }
                    <div className={"col border-left border-right text-center"} onClick={this.handleShare}>
                        <small><FaSolidIcon name={"share"}/> Condividi</small>
                    </div>

                    {
                        AuthAPI.currentUser && this.props.onCommentButtonClick ?
                            <div className={"col border-left border-right text-center align-items-center"} onClick={this.props.onCommentButtonClick}>
                                <small><FaSolidIcon name={"comment"}/> Commenta</small>
                            </div>
                            :
                            <div className={"col text-center align-items-center"} onClick={this.handleAppAction}>
                                <BookizonIconWrapper><BookizonIcon/></BookizonIconWrapper>&nbsp;
                                <small>
                                    Apri
                                </small>
                            </div>
                    }
                </div>
            </div>
        )
    }

}

const BookizonIconWrapper = styled.span`
    & svg{
        height:16px;
    }

    & .cls-2{
        fill: #293069;
    }
`;
