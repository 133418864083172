import React from 'react';
import PropTypes from 'prop-types'
import '../../../../components/Booking/DateTimeChooserPage.css';
import moment from "moment";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import Page from "../../../../components/BaseComponents/Page/Page";
import {PageFooter, PageWithFooterContainer} from "../../../../components/BaseComponents/Page/PageFooter";
import {FaRegularIcon, FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {ModuloServiziAPI} from "../../../../api/ModuloServizi/ModuloServiziAPI";
import {DIPENDENTE_ANYONE_OPTION} from "../../../../const/ModuloServiziCostants";

export default class DashboardDateChooserPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onDismiss: props.onDismiss,
            onChoose: props.onChoose,
            selectedDate: null,
            selectedTime: null,
            dateDisponibili: {},
            startDate: 'now',
            requestDirection: 'next',
            nextButtonClicks: 0
        };

        this.needRefetch = true;


        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNextDays = this.handleNextDays.bind(this);
        this.handleBackDays = this.handleBackDays.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    componentDidMount() {
    }

    fetchNewData(updateState) {
        var state = {};
        swalLoading("Recupero date e orari disponibili...");
        const methodAPI = this.props.durata ? ModuloServiziAPI.Dashboard.getAvailableDatesForDuration : ModuloServiziAPI.Dashboard.getAvailableDates

        methodAPI(this.state.startDate, this.state.requestDirection, this.props.bookInfo.id_sede, this.props.bookInfo.id_dipendente === DIPENDENTE_ANYONE_OPTION ? null : this.props.bookInfo.id_dipendente, this.props.durata || this.props.bookInfo.id_servizio, !!this.props.forceDipendente)
            .then((dateDisponibili) => {
                state.dateDisponibili = dateDisponibili;
                state.selectedDate = Object.keys(dateDisponibili)[0];
                console.log(dateDisponibili);
                this.setState(state);
                swal.safeClose();
            }).catch(swalError);
    }

    handleDismiss() {
        this.setState({
            selectedDate: null,
            selectedTime: null,
            dateDisponibili: {},
            startDate: 'now',
            requestDirection: 'next',
            nextButtonClicks: 0
        });
        this.state.onDismiss();
    }

    handleConfirm() {
        if (!this.state.selectedDate || !this.state.selectedTime) {
            swalError("Devi scegliere una data e un orario");
            return;
        }

        this.state.onChoose({
            data: this.state.selectedDate,
            orario: this.state.selectedTime,
            dipendenti: this.state.dateDisponibili[this.state.selectedDate].dipendenti
        });
    }

    handleChange(value) {
        this.setState({selected: value});
    }

    handleNextDays() {
        var dateList = Object.keys(this.state.dateDisponibili);
        this.needRefetch = true;
        this.setState({
            startDate: dateList[dateList.length - 1],
            requestDirection: 'next',
            nextButtonClicks: this.state.nextButtonClicks + 1
        });
    }

    handleBackDays() {
        var dateList = Object.keys(this.state.dateDisponibili);
        this.needRefetch = true;
        this.setState({
            startDate: dateList[0],
            requestDirection: 'back',
            nextButtonClicks: this.state.nextButtonClicks - 1
        });
    }

    handleDayClick(dateString) {
        console.log(dateString);
        console.log(this.state.dateDisponibili[dateString].time);
        this.setState({
            selectedDate: dateString
        });
    }

    handleTimeChange(orario) {
        this.setState({
            selectedTime: orario
        })
    }

    render() {
        if (this.props.active && this.needRefetch) {
            this.needRefetch = false;
            this.fetchNewData(true);
        }
        if (!this.props.active) {
            this.needRefetch = true; //Necessiterà di un refresh al prossimo stato active
            return '';
        }
        return (
            <Page
                title={"Data e ora"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.handleDismiss}
                active={this.props.active}
                content={
                    <PageWithFooterContainer>
                        <div className={"container bg-main-z2 mb-4 py-3 border-bottom"}>
                            <div className={"text-center my-2"}>
                                <button className={"btn btn-sm btn-primary mx-1 px-2 text-on-bg-primary"}
                                        onClick={this.handleBackDays}
                                        disabled={this.state.nextButtonClicks === 0}>
                                    <FaSolidIcon name={"angle-left"}/>
                                </button>
                                <span className={"text-on-bg-main"}>Scegli il giorno</span>
                                <button className={"btn btn-sm btn-primary mx-1 px-2 text-on-bg-primary"}
                                        onClick={this.handleNextDays}>
                                    <FaSolidIcon name={"angle-right"}/>
                                </button>
                            </div>
                            <div className={"my-2 row p-1"}>
                                {/* Mostro le attuali date disponibili */}
                                {
                                    Object.keys(this.state.dateDisponibili).map((dateString, i) => {
                                        var giorniSettimana = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];
                                        var mesi = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"];
                                        var m = moment(dateString, "DD-MM-YYYY");
                                        var giorno = m.date();
                                        var mese = mesi[m.month()]; //0 Based
                                        var giornoSettimana = giorniSettimana[m.day()]; //0 Based, 0 = Domenica
                                        return (
                                            <div key={i} className={"col-2 p-0 text-center"}
                                                 onClick={this.handleDayClick.bind(this, dateString)}>
                                                <span
                                                    className={"d-block mb-2 text-uppercase text-on-bg-main"}>{giornoSettimana}</span>
                                                <div style={{minWidth: "40px"}}
                                                     className={"rounded shadow p-2 text-center mx-auto d-inline-block cursor-pointer " + (this.state.selectedDate === dateString ? "bg-secondary text-on-bg-secondary" : "bg-white bg-main-z2 text-on-bg-main")}>
                                                    {giorno}
                                                </div>
                                                <small
                                                    className={"d-block mt-2 text-uppercase text-on-bg-main"}>{mese}</small>
                                            </div>
                                        )
                                    })

                                }
                            </div>
                        </div>

                        <div className={"container"}>
                            <div className={"row my-2 pb-5"}>
                                {
                                    !this.state.selectedDate ? '' : this.state.dateDisponibili[this.state.selectedDate].time.map((orario, i) => {
                                        return (
                                            <div className={"col-4 px-2 text-center"} key={i}>
                                                <label
                                                    className={
                                                        "fux-radiobox badge badge-pill d-block shadow py-2 " +
                                                        (this.state.selectedTime === orario ? "bg-secondary text-on-bg-secondary" : "bg-main-z2 text-on-bg-main")
                                                    }
                                                    style={{fontSize: "1em"}}>
                                                    <input type={"radio"} value={orario} name={"orario_prenotazione"}
                                                           checked={this.state.selectedTime === orario}
                                                           onChange={this.handleTimeChange.bind(this, orario)}/>
                                                    {this.state.selectedTime === orario ?
                                                        <FaSolidIcon name={"check-circle"}/> :
                                                        <FaRegularIcon name={"circle"}/>}&nbsp;
                                                    {orario}
                                                </label>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </PageWithFooterContainer>
                }
                footer={
                    <PageFooter className={"bg-main-z2 p-3 border-top"}>
                        <button className={"btn btn-lg btn-block btn-primary text-on-bg-primary"}
                                onClick={this.handleConfirm}>
                            {this.props.confirmText ? this.props.confirmText : "Prenota"} <FaSolidIcon
                            name={"chevron-right"}/>
                        </button>
                    </PageFooter>
                }
            />
        );
    }

}


DashboardDateChooserPage.propTypes = {
    bookInfo: PropTypes.object,
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func,
    active: PropTypes.bool,
    durata: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    forceDipendente: PropTypes.bool
};
