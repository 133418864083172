import React, {Fragment} from "react";
import {UserAPI} from "../../../api/User/UserAPI";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import AuthAPI from "../../../api/AuthAPI";
import APIWelfareConfig from "../../../api/Welfare/APIWelfareConfig";
import WalletDipendentiAPI from "../../../api/Welfare/WalletDipendenteAPI";
import PropTypes from "prop-types";
import {WalletsAPI} from "../../../api/Wallets/WalletsAPI";
import {data} from "autoprefixer";
import {da} from "date-fns/locale";
import {WalletItemType} from "../Types/WalletItemType";
import {SmartPackageAPI} from "../../../api/SmartPackage/SmartPackageAPI";
import SmartPackageListView from "./SmartPackageListView";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {wait} from "@testing-library/react";
import {COUPON_TYPE_GRUPPI} from "../../../const/CouponConstants";
import {MODULE_GRUPPI} from "../../../const/ModuliConstants";
import {SelectItemsUtils} from "./SelectItemsUtils/SelectItemsUtils";
import moment from "moment";


export default class SmartPackageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packagesList: [],
            packagesError: [],
            selectedItems: []
        }
    }

    componentDidMount() {
        this.fetchPackageInfo()
    }

    fetchPackageInfo = _ => {
        SmartPackageAPI.User.getUsablePackage(NegozioOpenStore.infoNegozio.id_negozio, this.props.module || null, this.props.items || null,  this.props.operationDate ? moment(this.props.operationDate).day() : null)
            .then((data) => {
                this.setState({packagesList: data.packages}, _ => {
                    this.defaultSelectOfItems()
                })
            })
    }


    /**
     * Permette di selezionare gli elementi in autonomia
     * @param _
     * @returns {Promise<void>}
     */
    defaultSelectOfItems = async _ => {
        for (const item of this.props.items) {
            for (let i = 0; i < this.state.packagesList.length; i++) {
                const itemAllowed = this.state.packagesList[i].allowed_items.find((allowed_item) => allowed_item.id_item === item.id_item);
                if (this.state.packagesList[i].disabled < 1 && itemAllowed && this.getCreditsAvailableForItem(itemAllowed.id_item, this.state.packagesList[i]) > 0) {
                    //NB. Leggere documentazione metodo
                    await this.itemSelectionChangeWithDelay(itemAllowed, this.state.packagesList[i]);
                    break;
                }
            }
        }
    }

    /**
     * NB. Questo metodo viene utilizzato perché la selezione se fatta senza timeout non va a buon fine
     * poiché lo stato di "selectedItems" non fa in tempo ad aggiornarsi che viene richiesta immediatamente+
     * la selezione del elemento successivo e così facendo avviene una sovrascrizione
     */
    itemSelectionChangeWithDelay = (itemElement, packageItem) => {
        return new Promise((resolve) => {
            this.itemSelectionChangeHandler(itemElement, packageItem, false);
            setTimeout(() => {
                resolve();
            }, 2);
        });
    };

    /**
     * Centralizza l' onChange e smista in base all' operazione da fare
     * @param item
     * @param smartPackage
     * @param isChecked
     * @returns {number}
     */
    itemSelectionChangeHandler = (item, smartPackage, isChecked) => {
        //Se non checked vuol dire che bisogna aggiungerla
        if (!isChecked) {
            //Cerco l'elemento per il quale è pagabile il corrente item e che abbia crediti disponibili per farlo
            let payableBy = item.payable_reason.find((reason) => parseInt(reason.max_payments_num) > 0)
            if (!payableBy) return 0;

            this.itemSelectionHandler(item, smartPackage, payableBy)
        } else {
            this.itemUnSelectionHandler(item, smartPackage)
        }
    }


    /**
     * Rimuove un item dalla lista
     * @param item
     * @param smartPackage
     */
    itemUnSelectionHandler = (item, smartPackage) => {
        const selectedItems = this.state.selectedItems
        /**
         * Può capitare che in base alle impostazioni del modulo, nonostante ci sia un solo click sull'elemento
         * più items debbano essere eliminati dalla lista, per questo viene restituito un array
         */
        this.setState({selectedItems: SelectItemsUtils.removeItems(item, smartPackage, selectedItems, this.props.module)}, _ => {
            this.props.discountFromPackage(this.state.selectedItems, smartPackage)
        })
    }

    /**
     * Gestisce la selezione di un item e lo aggiunge alla lista degli elementi aggiunti
     * @param item
     * @param smartPackage
     * @param payableBy
     */
    itemSelectionHandler = (item, smartPackage, payableBy) => {
        let selectedItems = JSON.parse(JSON.stringify(this.state.selectedItems));
        let itemToSelect = JSON.parse(JSON.stringify(item));

        /**
         * Può capitare che in base alle impostazioni del modulo, nonostante ci sia un solo click sull'elemento
         * più items debbano essere aggiunti alla lista, per questo viene restituito un array
         */
        let newItems = SelectItemsUtils.getItems(itemToSelect, smartPackage, payableBy, this.props.module)

        this.setState({selectedItems: [...selectedItems, ...newItems]}, _ => {
            this.props.discountFromPackage(this.state.selectedItems, smartPackage)
        })
    }


    /**
     *
     * @param id_item
     * @param pack
     * @returns {number}
     */
    getCreditsAvailableForItem = (id_item, pack) => {
        let allowedItem = pack.allowed_items.find((allowedItem) => allowedItem.id_item === id_item)

        let creditsAvailable = 0
        allowedItem.payable_reason.map((reason) => {
            creditsAvailable += parseInt(reason.max_payments_num)

            this.state.selectedItems.map((selectedItem) => {
                if (selectedItem.id_payable === reason.id_payable) {
                    creditsAvailable -= selectedItem.crediti
                }
            })
        })
        return creditsAvailable
    }

    /**
     *
     * @param id_item
     * @param pack
     */
    getInitialCreditsForItem = (id_item, pack) => {
        const allowedItem = pack.allowed_items.find((allowedItem) => allowedItem.id_item === id_item)
        return allowedItem.payable_reason.reduce((partialSum, reason) => partialSum + parseInt(reason.max_payments_num), 0)
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.packagesList.length > 0 &&
                    <div className={"my-4"}>
                        <div className={"card bg-main-z3 text-on-bg-main border-0 shadow-sm my-1 mx-3 list-group"}>
                            {
                                this.state.packagesList.map((pack, key) => {
                                    return (
                                        <SmartPackageListView
                                            key={key}
                                            pack={pack}
                                            module={this.props.module}
                                            onItemSelectionChange={this.itemSelectionChangeHandler}
                                            getCreditsAvailableForItem={this.getCreditsAvailableForItem}
                                            getInitialCreditsForItem={this.getInitialCreditsForItem}
                                            selectedItems={this.state.selectedItems}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}


SmartPackageList.propTypes = {
    module: PropTypes.string, //Opzionale
    items: PropTypes.arrayOf(WalletItemType),
    discountFromPackage: PropTypes.func,
    operationDate: PropTypes.string,
}