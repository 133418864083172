import React from "react";
import PropTypes from "prop-types";
import {AttachmentsListViewItem} from "./AttachmentsListViewItem";
import {FaSolidIcon} from "../../../FontAwesomeIcons";


export function AttachmentsListView({attachments, onRemove, ...props}) {
    const handleClearAll = _ => onRemove(-1);
    return (
        <React.Fragment>
            <div className={"list-group"}>
                {
                    attachments.map((a, i) => {
                        return <div className={"list-group-item p-2"}>
                            <AttachmentsListViewItem key={i} attachment={a} onRemove={onRemove} index={i} disabled={props.disabled}/>
                        </div>
                    })
                }
            </div>
            <div className={"text-center"}>
                <button className={"btn btn-link btn-sm text-muted"} type={"button"} onClick={handleClearAll}>
                    <FaSolidIcon name={"trash"}/> Rimuovi tutti
                </button>
            </div>
        </React.Fragment>
    )
}

AttachmentsListView.propTypes = {
    onRemove: PropTypes.func.isRequired,
    attachments: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
}