/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styles from "./DatePicker.module.css"
import {
    addDays,
    addMonths,
    differenceInMonths,
    format,
    isSameDay,
    lastDayOfMonth,
    startOfMonth
} from "date-fns";
import {FaSolidIcon} from "../FontAwesomeIcons";
import moment from "moment";


const DEFAULT_SELECTED_CLASS = '__default_selected_class__';
const DATE_BLOCK_WIDTH = 50;

export default class HorizontalDatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date()
        };
        this.buttonColor = {color: '#555', background: "#d6d6d6"};
        this.labelColor = {color: this.props.color};
        this.startDate = new Date();
        this.scrollPanelRef = null;
        this.todayMoment = moment();
        this.todayDateRef = React.createRef();
    }

    handleDateChange = day => {
        this.setState({selectedDate: day});
        if (this.props.getSelectedDay) {
            this.props.getSelectedDay(day);
        }
    }

    nextWeek = () => {
        if (this.scrollPanelRef) {
            const width = this.scrollPanelRef.getBoundingClientRect().width;
            const datesInView = Math.floor(width / DATE_BLOCK_WIDTH);
            this.scrollPanelRef.scroll({
                left: this.scrollPanelRef.scrollLeft + datesInView * DATE_BLOCK_WIDTH,
                behavior: "smooth"
            });
        }
    };

    prevWeek = () => {
        if (this.scrollPanelRef) {
            const width = this.scrollPanelRef.getBoundingClientRect().width;
            const datesInView = Math.floor(width / DATE_BLOCK_WIDTH);
            this.scrollPanelRef.scroll({
                left: this.scrollPanelRef.scrollLeft - datesInView * DATE_BLOCK_WIDTH,
                behavior: "smooth"
            });
        }
    };

    getDateList = _ => {
        let monthList = {};
        let startDate = moment(this.startDate).subtract(this.props.initialDaysBeforeToday, 'days');
        for (let i = 0; i < this.props.initialDaysBeforeToday + this.props.initialDaysAfterToday; i++) {
            let currentDate = startDate.add(1, 'days');
            let month = startDate.format('YYYY-MM');
            if (!monthList[month]) monthList[month] = [];
            monthList[month].push(currentDate.toDate());
        }
        return monthList;
    }


    setDaysContainerRef = node => {
        if (node) {
            setTimeout(() => {
                let selectedDateNode = node.querySelector("." + (this.props.selectedClass ? this.props.selectedClass : DEFAULT_SELECTED_CLASS));
                if (selectedDateNode && this.scrollPanelRef) {
                    this.scrollPanelRef.scrollLeft = selectedDateNode.offsetLeft - 60;
                } else {
                    this.setDaysContainerRef(node);
                }
            }, 100);
        }
    }

    setScrollPanelRef = node => this.scrollPanelRef = node

    handleGoToday = _ => {
        if (this.scrollPanelRef && this.todayDateRef && this.todayDateRef.current) {
            this.scrollPanelRef.scroll({left: this.todayDateRef.current.offsetLeft - DATE_BLOCK_WIDTH, behavior: "smooth"});
            this.handleDateChange(new Date())
        }
    }

    renderDays() {
        const dayFormat = "E";
        const dateFormat = "d";

        const datesList = this.getDateList();
        const elements = [];
        for (let monthYear in datesList) {
            const datesOfMonth = datesList[monthYear];
            elements.push(
                <div className={styles.monthContainer} key={monthYear}>
                    <span className={styles.monthYearLabel} style={this.labelColor}>
                        {moment(monthYear + '-01').format('MMMM YYYY')}
                    </span>
                    <div className={styles.daysContainer} ref={this.setDaysContainerRef}>
                        {
                            datesOfMonth.map(date => {
                                const currentDateYmd = moment(date).format('YYYY-MM-DD');
                                const className = this.props.getDayClass(currentDateYmd);
                                return <div
                                    className={
                                        styles.dateDayItem + ' ' + className + ' ' +
                                        (isSameDay(date, this.state.selectedDate) ? (this.props.selectedClass || DEFAULT_SELECTED_CLASS) : '')
                                    }
                                    key={date}
                                    ref={this.todayMoment.format('YYYY-MM-DD') === currentDateYmd ? this.todayDateRef : null}
                                    onClick={_ => this.handleDateChange(date)}
                                >
                                    <div className={styles.dayLabel}>
                                        {moment(date).format('ddd')}
                                    </div>
                                    <div className={styles.dateLabel}>
                                        {format(date, dateFormat)}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            )
        }
        return <div className={styles.dateListScrollable} ref={this.setScrollPanelRef}>{elements}</div>;
    }

    render() {
        return (
            <div className={'text-center'}>
                <div className={styles.container}>
                    <div className={styles.buttonWrapper}>
                        <button className={styles.button} style={this.buttonColor} onClick={this.prevWeek}>
                            <FaSolidIcon name={"chevron-left"}/>
                        </button>
                    </div>
                    {this.renderDays()}
                    <div className={styles.buttonWrapper}>
                        <button className={styles.button} style={this.buttonColor} onClick={this.nextWeek}>
                            <FaSolidIcon name={"chevron-right"}/>
                        </button>
                    </div>
                </div>
                <button className={"btn btn-link btn-sm"} onClick={this.handleGoToday}><FaSolidIcon
                    name={"calendar-day"}/> Vai ad oggi
                </button>
            </div>
        )
    }
}
