import React from "react";
import PropTypes from "prop-types";
import {DatesAvailabilityListViewItem} from "./DatesAvailabilityListViewItem";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {swalError} from "../../../../../../helpers/SweetAlertWrapper";


export class DatesAvailabilityListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dates: this.props.dates.map(d => ({...d, available: null, selected: true})) //This syntax is needed in order to make a deep clone of dates objects array
        }
        this.dateListViewStyle = {
            maxHeight: '45vh',
            overflowY: 'scroll'
        }
    }

    componentDidMount() {
        //TODO set state con functional setState
        Promise.all(this.state.dates.map(dt => this.props.onCheckDateTimeAvailability(dt.date, dt.time)))
            .then(availabilities => {
                this.setState({
                    dates: this.state.dates.map((d, i) => {
                        return {
                            ...d,
                            available: availabilities[i]
                        }
                    })
                });
            });
    }

    handleSelectedChange = (dateIndex, selected) => {
        const dates = this.state.dates.map(d => ({...d}))
        dates[dateIndex].selected = selected;
        this.setState({dates: dates});
    }

    handleDateChange = (dateIndex, date, time) => {
        const dates = this.state.dates.map(d => ({...d}))
        dates[dateIndex].date = date;
        dates[dateIndex].time = time;
        this.setState({dates: dates}, _ => {
            this.handleRefreshAvailability(dateIndex)
        });
    }

    handleRefreshAvailability = (dateIndex) => {
        this.props.onCheckDateTimeAvailability(this.state.dates[dateIndex].date, this.state.dates[dateIndex].time)
            .then(available => {
                const dates = this.state.dates.map(d => ({...d}))
                dates[dateIndex].available = available;
                this.setState({dates: dates});
            });
    }

    handleDatesConfirm = _ => {
        const hasDuplicates = !!this.state.dates.find((d,i) => {
            return !!this.state.dates.find((d2,i2) => d.date === d2.date && i !== i2 && d.selected && d2.selected);
        });
        if (hasDuplicates){
            swalError("Non puoi proseguire perchè non puoi effettuare più prenotazioni nella stessa data")
            return;
        }
        this.props.onDatesConfirm(this.state.dates.filter(d => d.selected));
    }

    render() {
        return (
            <React.Fragment>
                <h4 className={"font-weight-bold text-center"}>
                    Riepilogo prenotazione
                </h4>
                <p className={"text-muted"}>
                    Qui sono elencate tutte le date che rientrano nella periodicità che hai scelto. Se lo desideri puoi
                    modificare una singola data/orario oppure puoi deselezionarla e non includerla nella tua
                    prenotazione.
                </p>
                <div ref={this.props.onScrollablePanelRef} className={"bg-main rounded p-3"} style={this.dateListViewStyle}>
                    {
                        this.state.dates.map((d, i) => {
                            const isDuplicated = this.state.dates.find((d2,i2) => d.date === d2.date && i !== i2 && d.selected && d2.selected);
                            return <DatesAvailabilityListViewItem
                                date={d}
                                dateIndex={i}
                                key={i}
                                onSelectedChange={this.handleSelectedChange}
                                onRefreshAvailability={this.handleRefreshAvailability}
                                onDateChange={this.handleDateChange}
                                bookInfo={this.props.bookInfo}
                                isDuplicated={isDuplicated}
                            />
                        })
                    }
                </div>
                <div className={"d-flex align-items-center justify-content-between mt-3"}>
                    <button className={"btn btn-link text-on-bg-main"} onClick={this.props.onDismiss}>
                        <FaSolidIcon name={"chevron-left"}/> Indietro
                    </button>
                    <button className={"btn btn-primary"} onClick={this.handleDatesConfirm}>
                        Prenota queste date <FaSolidIcon name={"chevron-right"}/>
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

DatesAvailabilityListView.propTypes = {
    onCheckDateTimeAvailability: PropTypes.func,
    dates: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string, //YYYY-MM-DD
        time: PropTypes.string, //HH:mm
    })),
    bookInfo: PropTypes.shape({
        data: PropTypes.string,
        id_sede: PropTypes.string,
        id_dipendente: PropTypes.string,
        id_servizio: PropTypes.array
    }),
    onScrollablePanelRef: PropTypes.func,
    onDatesConfirm: PropTypes.func
}