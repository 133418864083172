import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import EcommerceAPI from "../../../api/EcommerceAPI";

export class EcommerceProductStockNotifyButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleSubscriptionUserNotifyAvailability = _ => {
        const modello = this.props.productData.modelli.length ? this.props.modello : null
        swalLoading('Attendere prego...');
        EcommerceAPI.subscriptionUserProductAvailability(this.props.productData.id_prodotto, (modello ? modello.id_variante : null))
            .then(swalSuccess)
            .catch(swalError)
    }

    render() {
        return (
            <Fragment>
                <button className={"btn btn-block btn-info"} onClick={this.handleSubscriptionUserNotifyAvailability}>
                    <FaSolidIcon name={"bell"}/> Avvisami quando disponibile
                </button>
            </Fragment>
        )
    }

}

EcommerceProductStockNotifyButton.propTypes = {
    productData: PropTypes.object.isRequired,
    modello: PropTypes.any
}