import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "../AuthAPI";
import APIConfig from "../APIConfig";
import _Dashboard_Fullcalendar from "./_Dashboard.Fullcalendar";


var _Dashboard = {
    Fullcalendar: _Dashboard_Fullcalendar,
    editTime: function (bookData) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/edit-time'),
            {
                token: AuthAPI.currentUser.accessToken,
                ...bookData
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    editFullBookTime: function (bookData) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/edit-full-book-time'),
            {
                token: AuthAPI.currentUser.accessToken,
                ...bookData
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    checkAvailability: function (data, orario, durata, id_dipendente, id_sede) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/check-availability'),
            {
                token: AuthAPI.currentUser.accessToken,
                data: data,
                orario: orario,
                durata: durata,
                id_dipendente: id_dipendente,
                id_sede: id_sede
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    delete: function (id_prenotazione, keep_recurring) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/delete'),
            {
                token: AuthAPI.currentUser.accessToken,
                keep_recurring: keep_recurring,
                id_prenotazione: id_prenotazione
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    setNote: function (id_prenotazione, note) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/set-note'),
            {
                token: AuthAPI.currentUser.accessToken,
                id_prenotazione: id_prenotazione,
                note: note
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getDateListBooksCount: function (startDate, endDate, id_dipendente) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/dashboard/modulo-servizi/${AuthAPI.currentUser.accessToken}/${id_dipendente}/date-list-books-count`),
            {
                start: startDate,
                end: endDate
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    fastBook: function ({id_sede, id_dipendente, id_utente, data, orario, id_servizio, durata, note, is_blocked_time}) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/fast-book'),
            {
                token: AuthAPI.currentUser.accessToken,
                id_sede: id_sede,
                id_dipendente: id_dipendente,
                id_utente: id_utente,
                data: data,
                orario: orario,
                durata: durata,
                id_servizio: id_servizio, //in xor con la durata
                note: note,
                is_blocked_time: is_blocked_time
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getAvailableDates: function (from, direction, id_sede, id_dipendente, id_servizio, forceDipendente) {
        var params = {
            from: from,
            direction: direction,
            id_sede: id_sede,
            id_dipendente: id_dipendente,
            id_servizio: id_servizio,
            token: AuthAPI.currentUser.accessToken,
            forceDipendente: forceDipendente,
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/get-available-dates'),
            params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getAvailableDatesForDuration: function (from, direction, id_sede, id_dipendente, durata) {
        var params = {
            from: from,
            direction: direction,
            id_sede: id_sede,
            id_dipendente: id_dipendente,
            durata: durata,
            token: AuthAPI.currentUser.accessToken,
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/get-available-dates-for-duration'),
            params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    book: function (options) {
        var params = {
            id_sede: options.id_sede,
            data: options.data,
            orario: options.orario,
            id_servizio: options.id_servizio,
            id_dipendente: options.id_dipendente,
            note_utente: options.note_utente,
            token: AuthAPI.currentUser.accessToken,
            id_utente: options.id_utente,
            note: options.note,
            forceDipendente: options.forceDipendente
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/book'),
            params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getFullBookInfo: function (id_parent) {
        var params = {
            id_parent: id_parent,
            token: AuthAPI.getUserAccessToken(),
        };
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/get-full-book-info'),
            params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    addExtraService: function (id_prenotazione, id_servizio, direction, rearrange, force) {
        const body = {
            id_prenotazione: id_prenotazione,
            id_servizio: id_servizio,
            direction: direction,
            rearrange: rearrange,
            force: force,
            token: AuthAPI.getUserAccessToken(),
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/add-extra-service'),
            body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    editServiceDuration: function (id_prenotazione, durata, force) {
        const body = {
            id_prenotazione: id_prenotazione,
            durata: durata,
            force: force,
            token: AuthAPI.getUserAccessToken(),
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/edit-service-duration'),
            body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    deleteSubService: function (id_prenotazione, rearrange, delete_single_service_only, rearrange_bundle_price) {
        const body = {
            id_prenotazione: id_prenotazione,
            rearrange: rearrange,
            delete_single_service_only: delete_single_service_only,
            rearrange_bundle_price: rearrange_bundle_price,
            token: AuthAPI.getUserAccessToken(),
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard/modulo-servizi/delete-sub-service'),
            body,
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getBookFiles: (id_prenotazione) => {
        return FuxHTTP.get(
            APIConfig.url(`/modulo-servizi/admin/filemanager/get-files`), {
                token: AuthAPI.getUserAccessToken(),
                id_prenotazione: id_prenotazione,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};


export default _Dashboard;
