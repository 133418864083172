import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {
    getSubtotalOfItem,
    getTotaleIva,
    getTotaleScontrino
} from "../../launcher/dashboard/DashboardView/ModuloCassa/ReceiptsUtils";
import {swal, swalError, swalInput, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import APIConfig from "../../api/APIConfig";
import ShortLinkAPI from "../../api/ShortLink/ShortLinkAPI";
import {BookizonAppManager} from "../../index";
import {FaBrandIcon, FaSolidIcon} from "../FontAwesomeIcons";
import {ModuloCassaAPI} from "../../api/ModuloCassa/ModuloCassaAPI";

export default class ReceiptPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getStatusLabel = _ => {
        if (!this.props.status) return ""
        switch (this.props.status) {
            case 'inviata_ae':
                return (<span className={"badge badge-success m-0"}>Inviato</span>)

            case 'creata':
                return <span className={"badge badge-warning m-0"}>Salvato</span>

            case 'annullata':
                return <span className={"badge badge-danger m-0"}>Annullato</span>

            case 'attesa_annullamento':
                return <span className={"badge badge-dark m-0"}>Attesa annullamento</span>
        }
    }

    //Gestisce l'invio dello scontrino in whatsapp
    handleSendReceipt = _ => {
        let url = APIConfig.url('/modulo-cassa/scontrini/get-scontrino-data/from-external-link?id_ricevuta=' + this.props.idRicevuta + '&password=' + this.props.password)
        swalLoading("Attendi, stiamo generando lo scontrino")
        ShortLinkAPI.generate(url)
            .then(data => {
                let msg = "Ciao, a questo link troverai il tuo scontrino: " + data.short_link
                BookizonAppManager.shareData(msg)
                swal.close()
            })
            .catch(swalError)
    }

    handleWhatsappShareReceipt = _ => {
        swalInput("Inserisci il numero di cellulare al quale inviare il messaggio", "", "text", "", "", true, "+39")
            .then(({value}) =>{
                if(!value) swalError("Inserisci un numero valido")

                swalLoading("Stiamo provando ad inviare il tuo messaggio")
                ModuloCassaAPI.Dashboard.sendWhatsapp(this.props.idRicevuta, value)
                    .then(msg => swalSuccess(msg))
                    .catch(swalError)
            })
    }


    render() {
        let isModuleWhatsAppActive = false;
        if(this.props.userNegozio){
            isModuleWhatsAppActive = this.props.userNegozio.modulesSubscription.some(module => module.module === "modulo_whatsapp" && module.status === "active");
        }

        let scontoDocumento = this.props.righe.reduce((scontoD, r) => {
            return scontoD + parseFloat(r.sconto_documento)
        }, 0)

        return (
            <div className={"card card-body shadow-sm border-0"}>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <b className={"text-primary"}>{this.props.numeroDocumento}</b>
                    <div>{this.getStatusLabel()}</div>
                </div>
                {/*STAMPA DEGLI ITEMS*/}
                {
                    this.props.showRows && this.props.righe.map((r, key) => {
                        return (
                            <div className={"d-flex"} key={key}>
                                <small className={"mr-2 text-muted"}>{r.descrizione} x {r.quantita - r.reso}</small>
                                <small>€{getSubtotalOfItem(r).toFixed(2)}</small>
                            </div>
                        )
                    })
                }
                {/*STAMPA SCONTO*/}
                {
                    scontoDocumento > 0 &&
                    <small>Sconto €{scontoDocumento.toFixed(2)}</small>
                }

                {/*STAMPA RIEPILOGO IVA-TOTALE*/}
                <span
                    className={"mt-2"}>Importo totale €{getTotaleScontrino(this.props.pagamenti, this.props.righe).toFixed(2)}</span>
                <small className={"mb-2"}>Di cui iva €{getTotaleIva(this.props.righe).toFixed(2)}</small>

                <small className={"text-muted"}>{this.props.dataCreazione}</small>

                {
                    this.props.enableSendOptions &&
                    <div className={"d-flex mt-2"}>

                        {
                            isModuleWhatsAppActive &&
                            <button className={"btn btn-sm btn-success mr-1"}
                                    onClick={this.handleWhatsappShareReceipt}>Invia whatsapp <FaBrandIcon name={"whatsapp"}/>
                            </button>
                        }

                        <button className={"btn btn-sm btn-primary"} onClick={_ => this.handleSendReceipt()}>
                            Condividi <FaSolidIcon name={"share-alt"}/>
                        </button>
                    </div>
                }
            </div>
        );
    }
}

ReceiptPreview.propTypes = {
    idRicevuta: PropTypes.string,
    enableSendOptions: PropTypes.bool,
    password: PropTypes.string,
    numeroDocumento: PropTypes.string,
    righe: PropTypes.array,
    pagamenti: PropTypes.array,
    status: PropTypes.string,
    dataCreazione: PropTypes.string,
    showRows: PropTypes.bool,
    userNegozio: PropTypes.object //optional
}

ReceiptPreview.defaultProps = {
    showRows: true
}