import styled from "styled-components";
import AppConfig from "../../config/AppConfig";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import React from "react";

const Body = styled.div`
    overflow: auto;
    width: 100%;
    max-height: 90vh;
    padding-bottom: 15px;
    padding-top: 20%;
`

const ImgCardNews = styled.img`
    width: 100%;
`

const ImgFullScreen = styled.div`
    width: 100%;
    height: 75vh;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:url(${props => props.src});
`

const Titolo = styled.h5`
    color: ${props => props.coloreTitolo}
`


export default class BodyCardNews extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <Body>
                {
                    this.props.news.img_full_screen == 0
                    ?
                        <div>
                            {
                                this.props.news.testo_up == "0"  ?
                                    <div>
                                        {this.props.news.immagine &&
                                        <ImgCardNews
                                            src={`${AppConfig.webServerUrl}/assets/AppBreakingNews/${this.props.news.id_novita}/${this.props.news.immagine}`}
                                            className={"card-img-top border-0"}/>
                                        }
                                        <div className={"container"}>
                                            <div style={{width: "100%", textAlign: "center"}}>
                                                <Titolo className={"mt-3"} coloreTitolo={this.props.news.colore_titolo}>
                                                    {this.props.news.titolo}
                                                </Titolo>
                                            </div>
                                            <SafeHtmlContainer className={"mt-3"} html={this.props.news.descrizione}/>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className={"container mb-3"}>
                                            <div style={{width: "100%", textAlign: "center"}}>
                                                <Titolo className={"mt-3"} coloreTitolo={this.props.news.colore_titolo}>
                                                    {this.props.news.titolo}
                                                </Titolo>
                                            </div>
                                            <SafeHtmlContainer className={"mt-3"} html={this.props.news.descrizione}/>
                                        </div>
                                        {this.props.news.immagine &&
                                            <ImgCardNews
                                                src={`${AppConfig.webServerUrl}/assets/AppBreakingNews/${this.props.news.id_novita}/${this.props.news.immagine}`}
                                                className={"card-img-top border-0"}/>
                                            }
                                    </div>
                            }
                        </div>
                        :
                        <div>
                            <ImgFullScreen src={`${AppConfig.webServerUrl}/assets/AppBreakingNews/${this.props.news.id_novita}/${this.props.news.immagine}`}/>
                        </div>

                }
            </Body>
        )
    }
}