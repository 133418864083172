import React, {Fragment} from "react";
import PropTypes from "prop-types";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {DLA_APP_OPEN, DLA_SHARED_FOOD_CART} from "../../../const/DeepLinkActionsCostants";
import SharedCartsAPI from "../../../api/SharedCartsAPI";
import {swal, swalConfirm, swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import {Base64} from "../../../helpers/Base64";
import AppModalPortal from "../../../components/BaseComponents/AppModalPortal";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";
import {SwipeablePanel} from "../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import AppConfig from "../../../config/AppConfig";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {FoodSharedCartProductItem} from "./FoodSharedCartProductItem";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import styled from "styled-components";
import APIConfig from "../../../api/APIConfig";
import AuthAPI from "../../../api/AuthAPI";
import {FoodCartUtils} from "../../../components/FoodDelivery/Utils/FoodCartUtils";
import {FuxNotificationSystem} from "../../../components/GenericUI/FuxNotificationSystem/FuxNotificationSystem";
import {
    APP_HOMEPAGE_ACTION,
    APP_MOUNTED,
    FOOD_CART_RELOAD_FROM_MEMORY,
    FOOD_SHARED_CART_LISTENER_MOUNTED
} from "../../../const/AppEventsCostants";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";

export class FoodSharedCartListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            referenceData: null,
            cartData: null
        }
        this.scrollableContainerRef = null;
    }

    componentDidMount() {
        FuxEvents.emit(FOOD_SHARED_CART_LISTENER_MOUNTED);
        FuxEvents.on(DLA_SHARED_FOOD_CART, this.handleEvent)
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_SHARED_FOOD_CART, this.handleEvent);
    }

    handleEvent = reference => {
        swalLoading('Recupero i prodotti nel carrello...')
        try {
            const data = JSON.parse(Base64.decode(reference));
            SharedCartsAPI.get(data.id_cart, data.auth_code)
                .then(cartData => {
                    this.setState({
                        referenceData: data,
                        cartData: cartData
                    });
                    this.handleMenuOpen();
                    swal.safeClose();
                })
                .catch(swalError);
        } catch (e) {
            swalError("Qualcosa è andato storto. Il link potrebbe essere non corretto!");
            console.warning("Cannot decode reference", e);
        }
    }

    handleMenuClose = _ => {
        this.setState({menuOpen: false});
        document.body.classList.remove('noscroll');
    }

    handleMenuOpen = _ => {
        this.setState({menuOpen: true});
        document.body.classList.add('noscroll');
    }

    handleCartAccept = _ => {
        const currentCart = FoodCartUtils.getOfflineCartProducts(this.state.cartData.id_negozio);
        const newCart = FoodCartUtils.mergeCarts(currentCart, this.state.cartData.prodotti);
        FoodCartUtils.saveOfflineCartProducts(this.state.cartData.id_negozio, newCart);
        FuxEvents.emit(FOOD_CART_RELOAD_FROM_MEMORY); //Invia richiesta all'eventuale gestore dell'ordine food
        FuxNotificationSystem.fire({
            title: "Prodotti aggiunti",
            message: `Tutti i prodotti del link sono stati aggiunti al tuo carrello`,
            position: 'tc', //Top center
            level: 'success',
            autoDismiss: 4
        });
        if (NegozioOpenStore.id_negozio !== this.state.cartData.id_negozio) {
            swalConfirm("Vuoi andare alla sezione ordini in cui sono stati aggiunti i prodotti?")
                .then(_ => {
                    FuxEvents.emit(DLA_APP_OPEN, this.state.cartData.id_negozio);
                    FuxEvents.waitForEvent(APP_MOUNTED)
                        .then(_ => {
                            FuxEvents.emit(APP_HOMEPAGE_ACTION,'ordini_prodotti'); //Apre la pagina ordini
                        })
                })

        }
        this.handleMenuClose();
    }

    isSwipeDisabled = _ => {
        if (this.scrollableContainerRef) {
            return this.scrollableContainerRef.scrollTop > 0;
        }
        return false;
    }

    getFriendProfilePic = _ => {
        return APIConfig.url(`/utenti/profile-pic/${AuthAPI.currentUser.accessToken}/${this.state.cartData.id_utente}/300`)
    }

    getScrollableContainerRef = node => this.scrollableContainerRef = node;


    render() {
        if (!this.state.menuOpen) return '';

        const Portal = document.getElementById('app-modal-root') ? AppModalPortal : ModalPortal;
        let imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${this.state.cartData.id_negozio}/logoIcon/100`
        return (
            <Fragment>
                <Portal>
                    <div style={BackdropStyle}/>
                    <SwipeablePanel
                        swipeTreshold={5}
                        direction={'top-to-bottom'}
                        active={this.state.menuOpen}
                        onDismiss={this.handleMenuClose}
                        disableSwipe={this.isSwipeDisabled}
                        animationSpeed={150}
                    >
                        <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                        <div className={"bg-main-z2"}>
                            <div className={"py-4"} ref={this.getScrollableContainerRef}
                                 style={ScrollableContainerStyle}>
                                {/* HEADING */}
                                <div className={"mb-3 text-center"}>
                                    <div className={"d-flex align-items-center justify-content-center mb-3"}>
                                        <ProfilePicThumbnail
                                            className={"mr-2 shadow-sm"}
                                            src={this.getFriendProfilePic()}
                                        />
                                        <span className={"lead font-weight-bold"}>
                                        {this.state.cartData.nome_utente} {this.state.cartData.cognome_utente}
                                    </span>
                                    </div>

                                    <p className={"lead text-muted"}>
                                        ha condiviso questo carrello nella seguente attività con te.
                                    </p>
                                </div>

                                {/* Attività */}
                                <div className={"d-flex align-items-center mb-3 justify-content-center"}>
                                    <AppIconThumbnail size={"45px"} src={imageFullUrl} className={"mr-2"}/>
                                    <h5 className={"mb-0"}><SafeHtmlContainer html={this.state.cartData.nome_negozio}/>
                                    </h5>
                                </div>

                                {/* Stampa dei prodotti */}
                                <div className={"container"}>
                                    <div className={"list-group mb-3"}>
                                        {
                                            this.state.cartData.prodotti.map((p, i) =>
                                                <FoodSharedCartProductItem key={i} productData={p}/>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Call to action */}
                            <div className={"container-fluid py-3 border-top"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <p className={"text-center text-muted"}>
                                            Vuoi aggiungere questi prodotti al tuo carrello?
                                        </p>
                                    </div>
                                    <div className={"col"}>
                                        <button className={"btn btn-link text-danger btn-block btn-lg"}
                                                onClick={this.handleMenuClose}
                                        >
                                            <FaSolidIcon name={"times"}/> Annulla
                                        </button>
                                    </div>
                                    <div className={"col"}>
                                        <button className={"btn btn-primary btn-block btn-lg"}
                                                onClick={this.handleCartAccept}
                                        >
                                            <FaSolidIcon name={"shopping-basket"}/> Aggiungi
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwipeablePanel>
                </Portal>
            </Fragment>
        )
    }

}


FoodSharedCartListener.propTypes = {
    onAppOpen: PropTypes.func,
    idNegozio: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

const ScrollableContainerStyle = {maxHeight: '70vh', overflowY: 'auto'};


const ProfilePicThumbnail = styled.div`
    display:block;
    width:70px;
    height:70px;
    background-image: url('${props => props.src}');
    background-size:cover;
    background-position:center center;
    position:relative;
    overflow:hidden;
    border-radius:50%;
`;
