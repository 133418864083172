import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AppConfig from "../config/AppConfig";
import AuthAPI from "./AuthAPI";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";


var SaleAPI = {
    getSaleShop: function (id_negozio) {
        id_negozio = id_negozio ? id_negozio : NegozioOpenStore.id_negozio;
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/sale/${AuthAPI.currentUser.accessToken}/${id_negozio}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
}

export default SaleAPI
