import React, {Fragment} from "react";
import PropTypes from "prop-types";

export function InputMaxCharCounterHOC(InputComponent) {

    class InputMaxCharCounterClass extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                currLength: 0
            }
        }

        handleChange = e => {
            this.setState({
                currLength: e.target.value.length
            });
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }

        getLabel = _ => {
            const ratio = parseInt(this.state.currLength) / parseInt(this.props.maxLength) * 100;
            const ratioStr = `${this.state.currLength}/${this.props.maxLength}`;
            let newClass = '';
            if (ratio >= 0) {
                newClass = this.props.labelClassList['0'] || newClass;
            }
            if (ratio >= 25) {
                newClass = this.props.labelClassList['25'] || newClass;
            }
            if (ratio >= 50) {
                newClass = this.props.labelClassList['50'] || newClass;
            }
            if (ratio >= 75) {
                newClass = this.props.labelClassList['75'] || newClass;
            }
            return <span className={newClass}>
                {this.props.labelFormat.replace('{ratio}', ratioStr)}
            </span>
        }

        render() {
            return (
                <Fragment>
                    <InputComponent {...this.props} onChange={this.handleChange}/>
                    {this.getLabel()}
                </Fragment>
            )
        }

    }

    InputMaxCharCounterClass.propTypes = {
        labelFormat: PropTypes.string,
        labelClassList: PropTypes.object,
        maxLength: PropTypes.number
    }

    return InputMaxCharCounterClass;
}

