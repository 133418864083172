import React from "react";
import AuthAPI from "./../../api/AuthAPI.js"
import {BookizonAppManager} from "../../index";

export class FirebaseTokenResetIOS extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        AuthAPI.onStateChanged(user => {
            if (user) {
                const url = new URL(window.location.href);
                const platform = url.searchParams?.get('platform');
                const bundleVersion = url.searchParams?.get('bundleVersion');
                if (bundleVersion === '2.1' && platform === 'ios' && !user.bundle_version && user.fcm_token) {
                    BookizonAppManager.invalidateFcmToken();
                    setTimeout(_ => {
                        BookizonAppManager.retrieveFcmToken()
                            .then(fcm_token => {
                                AuthAPI.setFCMToken(fcm_token, platform, bundleVersion, true)
                            });
                    }, 1000);
                }
            }
        })
    }

    render() {
        return '';
    }

}