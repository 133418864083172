import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import React, {Fragment} from "react";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";

/**
 * @description: Filtra i servizi in base alla sede o il dipendente scelto
 * categorieWhitelist = [{id_categoria: 12}, {id_categoria: 13}, ...]
 * serviziWhitelist = [{id_servizio: 12}, {id_servizio: 13}, ...]
 * */
export default function filtraServizi(listaServizi, listaServiziGrouped, listaSedi, listaDipendenti, bookInfo, categorieWhitelist = [], serviziWhitelist = []) {
    var newListaServizi = listaServizi.slice();

    if (bookInfo.id_categoria) {
        newListaServizi = listaServiziGrouped[bookInfo.id_categoria].slice();
    }

    if (bookInfo.id_dipendente) {
        newListaServizi = newListaServizi.filter((s) => {
            //Restituisco true solo se nel dipendente che ho scelto posso eseguire il servizio
            for (var i in listaDipendenti) {
                if (listaDipendenti[i].id_dipendente === bookInfo.id_dipendente) {
                    if (listaDipendenti[i].servizi.includes(s.id_servizio)) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    //Se white list è vuota non la considero
    if (serviziWhitelist.length || categorieWhitelist.length) {
        //trasformo gli id della white list dei servizi in un array in questa forma: [11, 12, 13]
        let idsOfServicesInWhiteList = []
        serviziWhitelist.map(swl => idsOfServicesInWhiteList.push(swl.id_servizio))
        //trasformo gli id della white list delle categorie in un array in questa forma: [11, 12, 13]
        let idsOfCategoriesInWhiteList = []
        categorieWhitelist.map(swl => idsOfCategoriesInWhiteList.push(swl.id_categoria))
        //Filtro i servizi già filtrati
        newListaServizi = newListaServizi.filter((s) => {
            return (idsOfServicesInWhiteList.includes(s.id_servizio) || idsOfCategoriesInWhiteList.includes(s.categoria))
        });
    }

    //Potrei accorpare questa parte con la precedente, ma siccome non ci sono molti cambiamenti in performance
    // meglio rendere tutto più chiaro
    if (bookInfo.id_sede) {
        newListaServizi = newListaServizi.filter((s) => {
            //Restituisco true solo se nella sede scelta c'è un dipendente che svolge quel servizio
            for (var i in listaDipendenti) {
                if (listaDipendenti[i].id_sede === bookInfo.id_sede) {
                    if (listaDipendenti[i].servizi.includes(s.id_servizio)) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    return newListaServizi;
}


/**
 * Aggiunge la chiave "itemPickerData" ad ogni elmento della lista
 * */
export function extendServiceListWithItemPickerData(listaServizi, impostazioni) {
    return listaServizi.map(servizio => {

        var nome = <b><SafeHtmlContainer html={servizio.nome}/></b>

        const prezzoBase = parseFloat(servizio.prezzo);
        const percSconto = parseFloat(servizio.perc_sconto)
        const prezzoScontato = prezzoBase - (prezzoBase * percSconto / 100);

        var subtitle = <Fragment>
            {
                impostazioni.hide_services_price === "1" ? '' :
                    <Fragment>
                                <span className={"badge badge-primary mr-1 text-on-bg-primary"}>
                                     {`€${prezzoScontato.toFixed(2)}`}
                                </span>
                        {
                            !!percSconto &&
                            <Fragment>
                                <small className={"mr-1 text-muted text-line-through"}>
                                    {`€${prezzoBase.toFixed(2)}`}
                                </small>
                                <span className={"badge badge-success mr-1"}>
                                            Sconto {percSconto}%
                                        </span>
                            </Fragment>
                        }
                    </Fragment>
            }
            {
                impostazioni.hide_services_duration === "1" ? '' :
                    <small
                        className={"mr-1 text-on-bg-main text-nowrap"}>Durata: {servizio.durata * 5}min </small>
            }
        </Fragment>

        return {
            ...servizio,
            itemPickerData: {
                text: nome,
                subtitle: subtitle,
                value: servizio.id_servizio,
                description:
                    impostazioni.hide_services_description === "1" || !servizio.descrizione ? '' :
                        <div className={"row"}>
                            <div className={"col-12 border-top mt-2 pt-2  text-on-bg-main"}
                                 style={{opacity: 0.8}}>
                                <SafeHtmlContainer html={servizio.descrizione}/>
                            </div>
                        </div>,
                image: impostazioni.show_services_image === "1" ? servizio.immagine : undefined
            }
        };
    })
}
