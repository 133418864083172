export const PAYMENT_METHOD_CONSEGNA = 'consegna';
export const PAYMENT_METHOD_CONTANTI = 'contanti';
export const PAYMENT_METHOD_BANCOMAT = 'bancomat';
export const PAYMENT_METHOD_ASSEGNO = 'assegno';
export const PAYMENT_METHOD_CARTA = 'carta_credito';
export const PAYMENT_METHOD_STRIPE = 'stripe';
export const PAYMENT_METHOD_VIVA_WALLET = 'viva_wallet';
export const PAYMENT_METHOD_BOOKIZON_PAY = 'bookizon_pay';
export const BOOKIZON_PAY_METHODS = [PAYMENT_METHOD_STRIPE, PAYMENT_METHOD_VIVA_WALLET, PAYMENT_METHOD_BOOKIZON_PAY];
export const PAYMENT_METHOD_PAYPAL = 'paypal';
export const PAYMENT_METHOD_BONIFICO = 'bonifico';
export const PAYMENT_METHOD_BONIFICO_30GG = 'bonifico 30gg';
export const PAYMENT_METHOD_BONIFICO_3060GG = 'bonifico 30-60gg';
export const PAYMENT_METHOD_BONIFICO_306090GG = 'bonifico 30-60-90gg';
export const PAYMENT_METHOD_NAME = {
    [PAYMENT_METHOD_CONSEGNA]: 'Alla consegna',
    [PAYMENT_METHOD_PAYPAL]: 'PayPal',
    [PAYMENT_METHOD_STRIPE]: 'Carta di credito',
    [PAYMENT_METHOD_VIVA_WALLET]: 'Carta di credito',
    [PAYMENT_METHOD_BOOKIZON_PAY]: 'Carta di credito',
    [PAYMENT_METHOD_BONIFICO]: 'Bonifico',
    [PAYMENT_METHOD_BONIFICO_30GG]: 'Bonifico 30 giorni',
    [PAYMENT_METHOD_BONIFICO_3060GG]: 'Bonifico 30-60 giorni',
    [PAYMENT_METHOD_BONIFICO_306090GG]: 'Bonifico 30-60-90 giorni',
};

export const PAYMENT_METHOD_NAME_ASPORTO = {
    [PAYMENT_METHOD_CONSEGNA]: 'Al ritiro',
    [PAYMENT_METHOD_PAYPAL]: 'PayPal',
    [PAYMENT_METHOD_STRIPE]: 'Carta di credito',
    [PAYMENT_METHOD_VIVA_WALLET]: 'Carta di credito',
    [PAYMENT_METHOD_BOOKIZON_PAY]: 'Carta di credito',
    [PAYMENT_METHOD_BONIFICO]: 'Bonifico',
    [PAYMENT_METHOD_BONIFICO_30GG]: 'Bonifico 30 giorni',
    [PAYMENT_METHOD_BONIFICO_3060GG]: 'Bonifico 30-60 giorni',
    [PAYMENT_METHOD_BONIFICO_306090GG]: 'Bonifico 30-60-90 giorni',
};

export const PAYMENT_METHOD_NAME_GRUPPI = {
    [PAYMENT_METHOD_CONTANTI]: 'Contanti',
    [PAYMENT_METHOD_CONSEGNA]: 'Contanti',
    [PAYMENT_METHOD_PAYPAL]: 'PayPal',
    [PAYMENT_METHOD_STRIPE]: 'Carta di credito',
    [PAYMENT_METHOD_VIVA_WALLET]: 'Carta di credito',
    [PAYMENT_METHOD_BOOKIZON_PAY]: 'Carta di credito',
    //[PAYMENT_METHOD_BONIFICO]: 'Bonifico',
};
