import React from "react";
import PropTypes from "prop-types";
import {FaRegularIcon, FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import moment from "moment";
import ModalPortalHOC from "../../../../../../components/BaseComponents/ModalPortalHOC";
import DateChooserPage from "../../../../../../components/Booking/DateChooserPage";
import {PageZLayerSelector} from "../../../../../../components/BaseComponents/Page/PageZLayerSelector";

export class DatesAvailabilityListViewItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDateChooserOpen: false
        }
    }

    handleToggleSelected = _ => this.props.onSelectedChange(this.props.dateIndex, !this.props.date.selected);

    handleToggleDateChooserOpen = _ => this.setState({isDateChooserOpen: !this.state.isDateChooserOpen});

    handleDateTimeChange = ({data, orario}) => {
        this.handleToggleDateChooserOpen();
        this.props.onDateChange(this.props.dateIndex, moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD'), orario);
    }

    renderItemView = _ => {
        if (this.props.date.available === null) {
            return <div className={"text-muted"}>
                <FaSolidIcon name={"spin fa-spinner"}/> Recupero disponibilità in corso
            </div>
        }

        if (this.props.date.available === true) {
            return <div>
                <div className={"text-success"}>
                    <FaSolidIcon name={"thumbs-up"}/> Disponibile
                </div>
                {
                    this.props.date.selected ?
                        <button className={"btn btn-link text-primary px-0"} onClick={this.handleToggleDateChooserOpen}>
                            Modifica data e ora <FaSolidIcon name={"chevron-right"}/>
                        </button>
                        :
                        <div className={"text-muted"}>
                            Non verrà prenotata
                        </div>
                }
            </div>
        }

        return <div>
            <div className={"text-danger"}>
                <FaSolidIcon name={"exclamation-triangle"}/> Occupato/Non disponibile
            </div>
            {
                this.props.date.selected ?
                    <button className={"btn btn-link text-primary  px-0"} onClick={this.handleToggleDateChooserOpen}>
                        Trova altra data e ora <FaSolidIcon name={"chevron-right"}/>
                    </button>
                    :
                    <div className={"text-muted"}>
                        Non verrà prenotata
                    </div>
            }
        </div>
    }

    render() {
        const cardStyle = {
            filter: `grayscale(${this.props.date.selected ? '0' : '1'})`,
            opacity: this.props.date.selected ? '1' : '0.5',
            borderColor: this.props.isDuplicated ? 'var(--danger) !important' : 'inherit',
        }
        return (
            <React.Fragment>
                <div className={"d-flex align-items-center mb-3"}>
                    <div className={"mr-3"} onClick={this.handleToggleSelected}>
                        {
                            this.props.date.selected ?
                                <FaSolidIcon name={"check-circle"} className={"text-primary"}/>
                                :
                                <FaRegularIcon name={"circle"} className={"text-muted"}/>
                        }
                    </div>
                    <div className={'flex-grow-1'}>
                        <div className={`bg-main-z3 border rounded p-2`}
                             style={cardStyle}
                        >
                            <b className={"text-capitalize"}>
                                {moment(this.props.date.date).format('dddd DD MMMM')} {this.props.date.time}
                            </b>
                            {this.renderItemView()}
                        </div>
                        {
                            this.props.isDuplicated &&
                            <div className={"small text-danger"}>
                                Hai già selezionato un altra prenotazione per la stessa data
                            </div>
                        }
                    </div>
                </div>
                <ModalPortalHOC>
                    <PageZLayerSelector zIndex={3000}>
                        {
                            this.state.isDateChooserOpen &&
                            <DateChooserPage
                                bookInfo={this.props.bookInfo}
                                onChoose={this.handleDateTimeChange}
                                onDismiss={this.handleToggleDateChooserOpen}
                                active={true}
                                confirmText={"Conferma"}
                            />
                        }
                    </PageZLayerSelector>
                </ModalPortalHOC>
            </React.Fragment>
        )
    }

}

DatesAvailabilityListViewItem.propTypes = {
    onRefreshAvailability: PropTypes.func,
    onSelectedChange: PropTypes.func,
    onDateChange: PropTypes.func,
    dateIndex: PropTypes.number,
    date: PropTypes.shape({
        date: PropTypes.string, //YYYY-MM-DD
        time: PropTypes.string, //HH:mm
        available: PropTypes.bool, //True, False, NULL
        selected: PropTypes.bool
    }),
    bookInfo: PropTypes.shape({
        data: PropTypes.string,
        id_sede: PropTypes.string,
        id_dipendente: PropTypes.string,
        id_servizio: PropTypes.array
    }),
    isDuplicated: PropTypes.bool,
}