import React from "react";
import PropTypes from "prop-types";
import {LinkedBookType} from "../../../../../components/ModuloSeats/PropTypes/LinkedBookType";
import {SeatView} from "./SeatView";
import moment from "moment";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {LinkedBooksView} from "./LinkedBooksView";

export function DateAccordionItem({date, linkedBooks, onlinePayments, disputeList, onUpdate}) {

    const [isExpanded, setExpanded] = React.useState(false);
    const toggleExpanded = _ => setExpanded(!isExpanded);

    return (
        <React.Fragment>
            <button className={"btn btn-link btn-block text-capitalize"} onClick={toggleExpanded}>
                {moment(date).format('ddd DD MMM')} <FaSolidIcon name={isExpanded ? 'chevron-up' : 'chevron-down'}/>
            </button>
            <div className={isExpanded ? "list-group border rounded" : "d-none"}>
                <LinkedBooksView
                    linkedBooks={linkedBooks}
                    onlinePayments={onlinePayments}
                    disputeList={disputeList}
                    onUpdate={onUpdate}
                />
            </div>
        </React.Fragment>
    )
}

DateAccordionItem.propTypes = {
    date: PropTypes.string,
    linkedBooks: PropTypes.arrayOf(LinkedBookType),
    onlinePayments: PropTypes.object,
    onUpdate: PropTypes.func,
    disputeList: PropTypes.array,
}