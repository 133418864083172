import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {TabViewItem} from "../../../components/BaseComponents/TabView";
import {FaRegularIcon, FaSolidIcon} from "../../../components/FontAwesomeIcons";
import {UserChatListView} from "./UserChat/UserChatListView";
import {UserChatAPI} from "../../../api/Chat/UserChatAPI";
import {EllipsisLoader} from "../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {UserWithBusinessChatPanel} from "./UserChat/UserWithBusinessChatPanel";
import APIConfig from "../../../api/APIConfig";
import AuthAPI from "../../../api/AuthAPI";
import AppConfig from "../../../config/AppConfig";
import {AdminChatListView} from "./AdminChat/AdminChatListView";
import {BusinessWithUserChatPanel} from "./AdminChat/BusinessWithUserChatPanel";
import {UserAdminChatAPI} from "../../../api/Chat/UserAdminChatAPI";
import {BookizonAppManager} from "../../../index";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {ADMIN_CHAT_READ_EVT, CHAT_TAB_MOUNTED, USER_CHAT_READ_EVT} from "../../../const/AppEventsCostants";
import {DLA_ADMIN_CHAT_MESSAGE, DLA_USER_CHAT_MESSAGE} from "../../../const/DeepLinkActionsCostants";
import {FuxSharedState} from "../../../lib/FuxFramework/FuxSharedState";
import commentDotsSVG from "../../svg/comment-dots.svg";
import chatSVG from "../../svg/chat.svg";
import {ChatSettingsView} from "./ChatSettings/ChatSettingsView";

const TAB_USER = 'user';
const TAB_ADMIN = 'admin';
const TAB_SETTINGS = 'settings';


export const ChatTabSharedState = new FuxSharedState({
    totalUnreadChatMessages: 0
});

export class ChatTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userChatList: [],
            userChatListLoading: false,
            userChatOpen: null,

            adminChatList: [],
            adminChatListLoading: false,
            adminChatOpen: null,

            activeTab: 'user',
        }

        this.userProfilePicStyle = {
            width: 30, height: 30, borderRadius: '50%', backgroundImage: `url('${this.getUserProfilePicUrl()}')`,
            backgroundPosition: 'center center', backgroundSize: 'cover'
        }

        this.negozioProfilePicStyle = {
            width: 30, height: 30, borderRadius: '50%', backgroundImage: `url('${this.getNegozioIconUrl()}')`,
            backgroundPosition: 'center center', backgroundSize: 'cover'
        }

        this.silentFetchUserChatList = this.fetchUserChatList.bind(this, true);
        this.silentFetchUserAdminChatList = this.fetchUserAdminChatList.bind(this, true);
    }

    componentDidMount() {
        FuxEvents.emit(CHAT_TAB_MOUNTED);
        this.fetchUserChatList();
        if (this.props.user.isAdmin) {
            this.fetchUserAdminChatList();
        }
        FuxEvents.on(USER_CHAT_READ_EVT, this.silentFetchUserChatList); //Aggiorno l'elenco chat
        FuxEvents.on(ADMIN_CHAT_READ_EVT, this.silentFetchUserAdminChatList); //Aggiorno l'elenco chat
        FuxEvents.on(DLA_USER_CHAT_MESSAGE, this.silentFetchUserChatList); //Nuovo messaggio per l'utente
        FuxEvents.on(DLA_ADMIN_CHAT_MESSAGE, this.silentFetchUserAdminChatList); //Nuovo messaggio per l'admin
    }

    componentWillUnmount() {
        FuxEvents.off(USER_CHAT_READ_EVT, this.silentFetchUserChatList);
        FuxEvents.off(ADMIN_CHAT_READ_EVT, this.silentFetchUserAdminChatList);
        FuxEvents.off(DLA_USER_CHAT_MESSAGE, this.silentFetchUserChatList);
        FuxEvents.off(DLA_ADMIN_CHAT_MESSAGE, this.silentFetchUserAdminChatList);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateUnreadMessage();
    }

    updateUnreadMessage = _ => {
        ChatTabSharedState.setState({
            totalUnreadChatMessages: this.getUnreadUserMessage() + this.getUnreadAdminMessage()
        });
    }

    /** @MARK: Generic UI methods */

    handleTabChange = tab => _ => this.setState({activeTab: tab});

    getUserProfilePicUrl = _ => {
        if (!this.props.user.immagine) return null;
        return APIConfig.url(`/utenti/profile-pic/${AuthAPI.currentUser.accessToken}/400`)
    }

    getNegozioIconUrl = _ => {
        return `${AppConfig.webServerUrl}/api/negozio/${this.props.user.negozio?.id_negozio}/logoIcon/100`
    }

    getUnreadUserMessage = _ => {
        return this.state.userChatList.reduce((acc, chatData) => {
            return acc + parseInt(chatData.nuovi_messaggi || 0);
        }, 0);
    }

    getUnreadAdminMessage = _ => {
        return this.state.adminChatList.reduce((acc, chatData) => {
            return acc + parseInt(chatData.nuovi_messaggi || 0);
        }, 0);
    }


    /** @MARK: User chat */
    fetchUserChatList = silent => {
        if (!silent) this.setState({userChatListLoading: true});
        UserChatAPI.chatList().then(chats => this.setState({
            userChatList: chats,
            userChatListLoading: false
        }, this.updateUnreadMessage));
    }

    handleUserChatChoose = chatData => {
        UserChatAPI.setRead(chatData.id_room).then(_ => FuxEvents.emit(USER_CHAT_READ_EVT));
        this.setState({userChatOpen: chatData});
    }

    handleUserChatClose = _ => this.setState({userChatOpen: null});


    /** @MARK: Admin chat */

    fetchUserAdminChatList = silent => {
        if (!silent) this.setState({adminChatListLoading: true});
        if (!AuthAPI.currentUser.isAdmin) return;
        UserAdminChatAPI.chatList().then(chats => this.setState({
            adminChatList: chats,
            adminChatListLoading: false
        }, this.updateUnreadMessage));
    }

    handleAdminChatChoose = chatData => {
        UserAdminChatAPI.setRead(chatData.id_room).then(_ => FuxEvents.emit(ADMIN_CHAT_READ_EVT));
        this.setState({adminChatOpen: chatData});
    }

    handleAdminChatClose = _ => this.setState({adminChatOpen: null});


    render() {
        const unreadUserMessage = this.getUnreadUserMessage();
        const unreadAdminMessage = this.getUnreadAdminMessage();
        return (
            <Fragment>
                <div className={this.props.active ? '' : 'd-none'}>
                    {
                        this.props.user.isAdmin &&
                        <div className={"d-flex bg-white sticky-top shadow-sm"}>
                            <TabViewItem
                                onClick={this.handleTabChange(TAB_USER)}
                                className={"py-2 text-muted d-flex align-items-center justify-content-center flex-fill " + (this.state.activeTab === TAB_USER ? ' active' : '')}
                                activeColor={"#293069"}
                            >
                                <div className={"border mr-1"} style={this.userProfilePicStyle}/>
                                Tu
                                <FaRegularIcon
                                    name={"ellipsis-v"}
                                    className={"text-white"}
                                    badge={unreadUserMessage > 0 ? unreadUserMessage : undefined}
                                    badgeColor={"red"}
                                />
                            </TabViewItem>
                            <TabViewItem
                                onClick={this.handleTabChange(TAB_ADMIN)}
                                className={"py-2 text-muted d-flex align-items-center justify-content-center flex-fill " + (this.state.activeTab === TAB_ADMIN ? ' active' : '')}
                                activeColor={"#293069"}
                            >
                                <div className={"border mr-1"} style={this.negozioProfilePicStyle}/>
                                Attività

                                <FaRegularIcon
                                    name={"ellipsis-v"}
                                    className={"text-white"}
                                    badge={unreadAdminMessage > 0 ? unreadAdminMessage : undefined}
                                    badgeColor={"red"}
                                />
                            </TabViewItem>
                            <TabViewItem
                                onClick={this.handleTabChange(TAB_SETTINGS)}
                                className={"py-2 px-4 text-muted d-flex align-items-center justify-content-center flex-shrink-1 " + (this.state.activeTab === TAB_SETTINGS ? ' active' : '')}
                                activeColor={"#293069"}
                            >
                                <FaSolidIcon name={"cogs"}/>
                            </TabViewItem>
                        </div>
                    }

                    {/** @MARK: User chat */}
                    {
                        (this.state.activeTab === TAB_USER && !!this.state.userChatList.length && !this.state.userChatListLoading) &&
                        <UserChatListView
                            chatList={this.state.userChatList}
                            onChoose={this.handleUserChatChoose}
                        />
                    }

                    {
                        (this.state.activeTab === TAB_USER && !this.state.userChatList.length && !this.state.userChatListLoading) &&
                        <div className={"mt-5 text-center container"}>
                            <img src={commentDotsSVG} className={"w-50 mx-auto"}/>
                            <h5 className={"text-muted mt-3"}>
                                Non hai ancora avviato una chat!
                                <small>
                                    <br/>
                                    Nelle attività che lo consentono, troverai un pulsante "Chatta con noi" in basso
                                </small>
                            </h5>
                        </div>
                    }

                    {
                        (this.state.activeTab === TAB_USER && this.state.userChatListLoading) &&
                        <div className={"mt-5 text-center"}><EllipsisLoader/></div>
                    }


                    {/** @MARK: Admin chat */}
                    {
                        (this.state.activeTab === TAB_ADMIN && !!this.state.adminChatList.length && !this.state.adminChatListLoading) &&
                        <AdminChatListView
                            chatList={this.state.adminChatList}
                            onChoose={this.handleAdminChatChoose}
                        />
                    }

                    {
                        (this.state.activeTab === TAB_ADMIN && !this.state.adminChatList.length && !this.state.adminChatListLoading) &&
                        <div className={"mt-5 text-center container"}>
                            <img src={chatSVG} className={"w-50 mx-auto"}/>
                            <h5 className={"text-muted mt-3"}>
                                Nessun cliente ha avviato una chat
                                <small>
                                    <br/>
                                    Se non lo hai ancora fatto, abilita la funzione "Chat" dalla dashboard web di
                                    Bookizon.
                                    I tuoi clienti troveranno un tasto "Chatta con noi" direttamente nella tua app!
                                </small>
                            </h5>
                        </div>
                    }

                    {
                        (this.state.activeTab === TAB_ADMIN && this.state.adminChatListLoading) &&
                        <div className={"mt-5 text-center"}><EllipsisLoader/></div>
                    }

                    {/** @MARK: Chat settings */}
                    {
                        this.props.user.isAdmin &&
                        <div className={(this.state.activeTab === TAB_SETTINGS) ? '' : 'd-none'}>
                            <ChatSettingsView
                                chatSettings={this.props.user.negozio.chatSettings}
                            />
                        </div>
                    }

                </div>

                <UserWithBusinessChatPanel
                    open={!!this.state.userChatOpen}
                    label={this.state.userChatOpen?.nome || ''}
                    onClose={this.handleUserChatClose}
                    idNegozio={this.state.userChatOpen?.id_negozio || 0}
                />

                {
                    this.props.user?.negozio &&
                    <BusinessWithUserChatPanel
                        open={!!this.state.adminChatOpen}
                        label={`${this.state.adminChatOpen?.nome} ${this.state.adminChatOpen?.cognome}`}
                        onClose={this.handleAdminChatClose}
                        idUtente={this.state.adminChatOpen?.id_utente || 0}
                        idSelf={this.props.user?.negozio?.id_utente_chat}
                    />
                }
            </Fragment>
        )
    }

}

ChatTab.propTypes = {
    active: PropTypes.bool,
    user: PropTypes.shape({
        isAdmin: PropTypes.bool,
        negozio: PropTypes.object,
    })
}