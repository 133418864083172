import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import ModalPortalHOC from "../../../../../components/BaseComponents/ModalPortalHOC";
import {RecurringDatesChooserPanel} from "./RecurringDatesChooserPanel";
import {ModuloServiziAPI} from "../../../../../api/ModuloServizi/ModuloServiziAPI";
import moment from "moment";
import {swalError, swalLoading, swalSuccess} from "../../../../../helpers/SweetAlertWrapper";
import BookingAPI from "../../../../../api/BookingAPI";
import {BookizonAppManager} from "../../../../../index";
import FuxEvents from "../../../../../lib/FuxFramework/FuxEvents";
import {NM_ANDROID_ON_RESUME, NM_IN_APP_BROWSER_DISMISS} from "../../../../../native/NativeMessageHandler";
import {StripeAPI} from "../../../../../api/StripeAPI";

export class ServiceRecurringBookButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recurringOptionsPanelOpen: false
        }
    }


    componentDidMount() {
        FuxEvents.on(NM_IN_APP_BROWSER_DISMISS, this.checkPayment);
        FuxEvents.on(NM_ANDROID_ON_RESUME, this.checkPayment);
    }

    componentWillUnmount() {
        FuxEvents.off(NM_IN_APP_BROWSER_DISMISS, this.checkPayment);
        FuxEvents.off(NM_ANDROID_ON_RESUME, this.checkPayment);
    }

    checkPayment = _ => {
        if (this.state.payment.gateway_payment_id) {
            swalLoading('Recupero dati pagamento in corso...');
            StripeAPI.verifyPaymentIntent(this.state.payment.gateway_payment_id, this.state.payment.gateway_metadata.id_negozio, this.state.payment.gateway_metadata.is_direct)
                .then(_ => {
                    swalSuccess(this.state.confirmMessage);
                    this.props.onBookSuccess();
                })
                .catch(swalError)
        }
    }

    togglePanel = _ => this.setState({recurringOptionsPanelOpen: !this.state.recurringOptionsPanelOpen});

    /**
     * Check if the given date-time is available. The availability will be checked using the same services reserved in
     * the given book.
     *
     * @param {string} date
     * @param {string} time
     *
     * @return {Promise<true, string>}
     * */
    checkDateTimeAvailability = (date, time) => {
        return new Promise(resolve => {
            ModuloServiziAPI.BookingEngine.checkDateTimeAvailability(
                date, time,
                this.props.bookData.id_sede,
                this.props.bookData.id_dipendente,
                this.props.bookData.books.map(b => b.id_servizio)
            ).then(_ => resolve(true)).catch(_ => resolve(false));
        })
    }

    /**
     * This method receive the list of dates the user prefer in order to make the book
     *
     * @typedef {Object} BookDateType
     * @property {String} date YYYY-MM-DD format
     * @property {String} time HH:MM format
     *
     * @param {BookDateType[]} datetimes
     * */
    handleBookDates = datetimes => {
        const bookInfo = this.getBookInfo();
        bookInfo.data = datetimes.map(d => moment(d.date).format('DD-MM-YYYY'));
        bookInfo.orario = datetimes.map(d => d.time);
        swalLoading('Attendere prego...');
        BookingAPI.book(bookInfo)
            .then((response) => {
                const data = response.data;
                if (data.payment) {
                    this.setState({
                        id_prenotazione: data.id_prenotazione,
                        payment: {
                            gateway_payment_id: data.payment.gateway_payment_id,
                            gateway_payment_link: data.payment.gateway_payment_link,
                            gateway_metadata: data.payment.gateway_metadata,
                            timestamp: (new Date()).getTime()
                        },
                        confirmMessage: response.message
                    }, _ => {
                        //Apro la pagina di pagamento
                        BookizonAppManager.openInAppBrowser(this.state.payment.gateway_payment_link);
                    });
                } else {
                    swalSuccess(response.message);
                    this.props.onBookSuccess();
                }
            })
            .catch((message) => {
                swalError(message);
            });
    }

    getBookInfo = _ => {
        return {
            data: this.props.bookData.data,
            id_sede: this.props.bookData.id_sede,
            id_dipendente: this.props.bookData.id_dipendente,
            id_negozio: this.props.bookData.id_negozio,
            id_servizio: this.props.bookData.books.map(b => b.id_servizio)
        }
    }

    render() {
        return (
            <React.Fragment>
                <button className={"btn btn-block btn-primary mt-2"} onClick={this.togglePanel}>
                    <FaSolidIcon name={"calendar-week"}/> Prenota periodicamente
                </button>

                <ModalPortalHOC>
                    {
                        this.state.recurringOptionsPanelOpen &&
                        <RecurringDatesChooserPanel
                            onBookDates={this.handleBookDates}
                            onDismiss={this.togglePanel}
                            time={this.props.bookData.orario}
                            onCheckDateTimeAvailability={this.checkDateTimeAvailability}
                            bookInfo={this.getBookInfo()}
                        />
                    }
                </ModalPortalHOC>
            </React.Fragment>
        )
    }
}

ServiceRecurringBookButton.propTypes = {
    bookData: PropTypes.shape({
        books: PropTypes.arrayOf(PropTypes.shape({
            id_servizio: PropTypes.any
        }))
    }),
    onBookSuccess: PropTypes.func
}