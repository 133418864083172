import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./AuthAPI";
import APIConfig from "./APIConfig";
import {POST_LIKE_TYPE_LIKE} from "../const/PostCostants";

var PostAPI = {
    like: function(id_post, type){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/post/like`),
            {
                id_post: id_post,
                token: AuthAPI.currentUser.accessToken,
                type: type || POST_LIKE_TYPE_LIKE
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    unlike: function(id_post){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/post/unlike`),
            {
                id_post: id_post,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    addNewComment: function (id_post, commento, id_commento_risposto = null){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/post/comments/add-new`),
            {
                id_post: id_post,
                commento: commento,
                id_commento_risposto: id_commento_risposto,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getPostComments: function (id_post, limit, offset){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/post/comments/get`),
            {
                id_post: id_post,
                limit: limit,
                offset: offset,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getCommentAnswers: function (id_commento, limit, offset){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/post/comments/get-answers`),
            {
                id_commento: id_commento,
                limit: limit,
                offset: offset,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getBestCommentUnderThePost: function (id_post){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/post/comments/get-best`),
            {
                id_post: id_post,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    commentLike: function(id_commento, type){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/post/comments/like`),
            {
                id_commento: id_commento,
                token: AuthAPI.currentUser.accessToken,
                type: type || POST_LIKE_TYPE_LIKE
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    commentUnlike: function(id_commento){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/post/comments/unlike`),
            {
                id_commento: id_commento,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default PostAPI;
