import React, {Fragment} from "react";
import PropTypes from "prop-types";
import logoIcon from "./../../img/logo-icon.png";
import {Base64} from "../../helpers/Base64";
import {BookizonAppManager} from "../../index";

export class MenuDigitaleDownloadBanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getDownloadURL = _ => {
        if (BookizonAppManager.getMobileOperatingSystem() === "Android") {
           return "https://play.google.com/store/apps/details?id=com.proinn.bookizon";
        }
        if (BookizonAppManager.getMobileOperatingSystem() === "iOS") {
            return "https://apps.apple.com/us/app/bookizon/id1506158300?l=it&ls=1";
        }
        return `https://proinn.web.app/bookizon/redirect.html?url=${Base64.encode("https://bookizon.it/download-app")}`;
    }

    render() {
        return (
            <Fragment>
                <div
                    className={"d-flex bg-main-z3 p-2 shadow-sm text-muted align-items-center justify-content-between sticky-top"}>
                    <div className={"mr-3"}>
                        <img src={logoIcon} height={40} width={40} alt={"Icona app Bookizon"} className={"rounded"}/>
                    </div>
                    <div className={"flex-shrink-1"}>
                        Usa l'app Bookizon per un'esperienza completa
                    </div>
                    <div className={"flex-grow-1"}>
                        <div className={"rounded-pill bg-main"}>
                            <a className={"btn btn-sm btn-link text-nowrap"}
                               target={"_blank"}
                               href={this.getDownloadURL()}>
                                Scarica ora
                            </a>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

MenuDigitaleDownloadBanner.propTypes = {}