import React, {Fragment} from 'react';
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {FullscreenInput, FullscreenTextarea} from "../../components/Forms/FullscreenInputs";
import {Rating} from "react-simple-star-rating";
import {swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import {UserAPI} from "../../api/User/UserAPI";
import {RecensioniAPI} from "../../api/Recensioni/RecensioniAPI";
import PropTypes from "prop-types";
import RecensioniForm from "./RecensioniForm";
import RecensioniResume from "./RecensioniResume";


export default class RecensioniModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoRecensione: null,
            downloadingData: true
        };
    }

    componentDidMount() {
       this.loadInfoRecensione()
    }

    loadInfoRecensione =_=>{
        RecensioniAPI.RecensioniCRUD.getRecensione(this.props.tipo_operazione, this.props.id_operazione)
            .then(data => {
                this.setState({downloadingData: false, infoRecensione: data})
            })
            .catch(_=>{
                this.setState({downloadingData: false})
            })
    }

    render(){
        return (
            <div className={"card"}>
                <div className={"card-body pt-1 pb-1"}>
                    {this.state.downloadingData ?
                        <FaSolidIcon name={"spin fa-spinner"} size={2}/>
                        :
                        <div>
                            {
                                !this.state.infoRecensione ?
                                    <RecensioniForm
                                        id_operazione={this.props.id_operazione}
                                        tipo_operazione={this.props.tipo_operazione}
                                        reloadInformation = {this.loadInfoRecensione}
                                    />
                                :
                                   <RecensioniResume
                                        infoRecensione = {this.state.infoRecensione}
                                   />
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}


RecensioniModal.propTypes = {
    id_operazione: PropTypes.string,
    tipo_operazione: PropTypes.string,
};
