import React from "react";
import {shadeColor} from "../../../helpers/UIUtility";

export const BeautyIllustration = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             width="1144" height="714.9" viewBox="0 0 1144 714.9"><title>barber</title>
            <path
                d="M1172,586.79a180,180,0,0,0-76-147.42,184.71,184.71,0,0,0,1-18.77c0-99.14-79.24-179.51-177-179.51a174.28,174.28,0,0,0-85.91,22.54c-69.19-45.52-159.52-73.07-258.34-73.07-160.79,0-299.1,72.95-360.46,177.55-3.41-.2-6.84-.31-10.3-.31-97.74,0-177,80.37-177,179.51A180.53,180.53,0,0,0,57.12,646h0c0,89.2,84.19,161.5,188,161.5H998.24c81.54,0,147.64-56.77,147.64-126.81h0A180.52,180.52,0,0,0,1172,586.79Z"
                transform="translate(-28 -92.59)" fill={color} opacity="0.1"/>
            <path
                d="M856.58,256.72c-7.54,3.18-15.1,6.37-22.12,10.57-3.19,1.9-6.25,4-9.53,5.73-3.53,1.88-7.28,3.3-11,4.82a245.21,245.21,0,0,0-29.84,14.62c.83-10.75-.74-22.43-7.17-30.81a27,27,0,0,0-3.14-3.46c-4.55-4.22-11-5.31-15.47-9,2.72-4.78,8.76-9.12,12.87-12.77q6.66-5.89,13.82-11.17a196.76,196.76,0,0,1,30.35-18.43c6.76-3.31,13.82-6.27,21.28-7.28a4.62,4.62,0,0,1,2.81.25c1.16.64,1.58,2.08,1.86,3.37,1.39,6.45,2.1,13.07,3.26,19.57a83.48,83.48,0,0,0,2.31,9.89,120.66,120.66,0,0,0,4.31,11.31Q853.89,250.32,856.58,256.72Z"
                transform="translate(-28 -92.59)" fill="#fbbebe"/>
            <path d="M642.57,439A63,63,0,0,1,528.8,476.29,62.84,62.84,0,0,1,516.57,439a63,63,0,0,1,126,0Z"
                  transform="translate(-28 -92.59)" fill="#be6f72"/>
            <path
                d="M557,494.52c3.37,5.81,6.77,11.67,8.93,18s3,13.34,1.22,19.81c21.46-11.3,47-10.39,70.62-16.11a2.54,2.54,0,0,0,1.63-.86,2.59,2.59,0,0,0,0-2.08,172.89,172.89,0,0,1-5.63-56.2l-12.33,7.29a157.84,157.84,0,0,1-16.75,9c-8.15,3.57-16.8,5.73-25.31,8.23-6.74,2-20.12,4.59-24.36,10.48A11.9,11.9,0,0,1,557,494.52Z"
                transform="translate(-28 -92.59)" fill="#be6f72"/>
            <path
                d="M522.29,409.8c-4.1,1.65-8.92,3.55-12.69,1.24-2.77-1.71-3.91-5.26-3.74-8.51s1.41-6.33,2.39-9.43c2-6.37,3.08-13,5.31-19.34s5.87-12.45,11.65-15.8c10.64-6.16,24.41-1.07,36.22-4.48,5-1.43,9.35-4.31,14-6.57s9.88-4,14.9-2.79c5.88,1.37,10.22,6.32,15.7,8.84,6,2.76,13.18,2.57,18.64,6.26,3.87,2.62,6.31,6.85,8.48,11a208.81,208.81,0,0,1,16.35,41.9,63.54,63.54,0,0,1,2.67,16c.05,6.94-1.83,13.74-3.69,20.44l-3,10.6c-1.11,4-2.25,8-4.35,11.62s-5.34,6.67-9.35,7.72c-5,1.29-10.82-1.21-13-5.89-1.34-2.9-1.28-6.22-1.35-9.41a104.74,104.74,0,0,0-2-18.6c-.62-3.07-1.45-6.26-3.54-8.58-2.7-3-7-4-11-4.07-4.68-.06-9.81,1.21-12.54,5-2.19,3.06-2.57,7.36-5.48,9.75a9.07,9.07,0,0,1-4.45,1.81,14.87,14.87,0,0,1-15.73-9.45c-.92-2.64-1.37-5.92-3.87-7.16-1.3-.65-2.85-.53-4.24-.95-4.26-1.29-5.38-6.69-5.77-11.12-.45-5.19,1.28-15.44-5.17-15.79C536.69,403.66,528.68,407.67,522.29,409.8Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <path
                d="M537.4,478.09c-2.22,1.07-4.65,2.32-5.52,4.62-.34.9-.4,1.89-.71,2.81-1.41,4.07-6.91,4.87-9.75,8.11a12.77,12.77,0,0,0-2.51,5.61,40.68,40.68,0,0,0,1.76,24.24,19.56,19.56,0,0,0,4,6.6c5.08,5.18,13.84,5.08,20.17,1.55s10.7-9.72,14.41-16a55.68,55.68,0,0,0,4.91-10,61,61,0,0,0,2.43-10.89c2.22-14.41,5.12-30.39,4.12-45-3-.19-8.73-.4-11.38,1.48-3.32,2.36-2.4,7.12-3.6,10.57C552.89,470.09,544.65,474.47,537.4,478.09Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <path
                d="M540,480c-3.75.23-10.47-3.92-14.23-3.71A117.11,117.11,0,0,1,519,462.43c2-2.87,5.22-3.57,10.09,0C533,465.18,538.4,474.69,540,480Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <path
                d="M596.53,322c-4.07,2.15-6.59,2.25-8.6,1.82s-6,1.57-6,1.57c-35.25,12.06-36.64,17.75-36.64,17.75l40.81-14.41c7.85-3.44,8-2.23,8-2.23,6,5.64,13.64,1.82,13.64,1.82s10.57-5,7.46-9.81S600.61,319.89,596.53,322Zm17-.9s-3,8-13.79,6.7c0,0-5.3-.78-3.06-4.73,0,0,11-4.62,13.91-4.29S613.5,321.14,613.5,321.14Z"
                transform="translate(-28 -92.59)" fill="#e8e8f0"/>
            <path
                d="M596.53,322c-4.07,2.15-6.59,2.25-8.6,1.82s-6,1.57-6,1.57c-35.25,12.06-36.64,17.75-36.64,17.75l40.81-14.41c7.85-3.44,8-2.23,8-2.23,6,5.64,13.64,1.82,13.64,1.82s10.57-5,7.46-9.81S600.61,319.89,596.53,322Zm17-.9s-3,8-13.79,6.7c0,0-5.3-.78-3.06-4.73,0,0,11-4.62,13.91-4.29S613.5,321.14,613.5,321.14Z"
                transform="translate(-28 -92.59)" opacity="0.1"/>
            <path
                d="M598.77,299.7c-6.1.2-7.37,4.76-7.37,4.76a8.79,8.79,0,0,0-.57,10.53L580,325.76a10.89,10.89,0,0,0-5.06,4s-23.78,21-23.43,22.91c0,0,3.88,2,28.49-20.43l6.46-5.72s1.43-10.69,11.52-12.72c0,0,1,.44,1.53-1.94a11.34,11.34,0,0,1,2.23-3.07C603.08,307.55,604.87,299.49,598.77,299.7Zm1.71,7.23s-3.27,8.44-7.93,6-.13-8.07-.13-8.07C600.5,297,600.48,306.93,600.48,306.93Z"
                transform="translate(-28 -92.59)" fill="#e8e8f0"/>
            <circle cx="552.21" cy="236.08" r="1.29" opacity="0.1"/>
            <path
                d="M693.06,287.88a85.16,85.16,0,0,0-11.69-5.08A77.82,77.82,0,0,0,665,280q-26.26-2.5-52.68-2.9c-3.11,0-6.29-.07-9.24,1a19.71,19.71,0,0,0-7,4.59,34.85,34.85,0,0,0-8,11.58,5.14,5.14,0,0,0-.5,3.58c.4,1.16,2,1.93,2.91,1.11A14.91,14.91,0,0,0,585.61,315a9.11,9.11,0,0,0-4.87,7.5,47.69,47.69,0,0,0,21.86-1.06c-2.12-.54-4.31,1.45-4.44,3.63a5.94,5.94,0,0,0,3.31,5.25,13.29,13.29,0,0,0,6.36,1.26c8.47,0,16.71-2.87,25.16-3.24,6.46-.29,13.12.88,19.29-1,7.16-2.22,13.55-8.51,20.93-7.2,5.89,1.05,9.73,6.52,13.57,11.1,6.14,7.31,13.73,13.23,21.26,19.1,5.8,4.52,11.86,9.16,19.05,10.69a10.58,10.58,0,0,0,6.52-.31,11.13,11.13,0,0,0,4.24-4c7.75-11,7.76-24.85,7.31-37.74-.23-6.59-1.67-7.48-7.77-10.21Q726.77,304,716.23,299,704.59,293.56,693.06,287.88Z"
                transform="translate(-28 -92.59)" fill="#fbbebe"/>
            <path
                d="M774.11,312.56a21.09,21.09,0,0,1-3.76,1.77c-2.19.52-4.42-.41-6.55-1.13-9-3-19-2.29-27.7-6a63,63,0,0,1-10.39,46.85c-1.8,2.56-4.17,5.18-7.29,5.38,1.39,4.27,5.67,6.85,9.73,8.77a104.46,104.46,0,0,0,20.24,7.14c2,.47,4.44.7,5.6-1,.7-1,1-2.71,2.23-2.65,1.88,2,1.26,6.1,3.86,7a5,5,0,0,0,2.47,0l5.8-1a10.63,10.63,0,0,0,4.88-1.68,9.56,9.56,0,0,0,2.23-2.84c6.5-11.07,9-23.88,10-36.53.49-6.28,1.1-12.94.67-19.24C785.73,312,778.81,310.37,774.11,312.56Z"
                transform="translate(-28 -92.59)" fill="#e8e8f0"/>
            <path
                d="M774.11,312.56a21.09,21.09,0,0,1-3.76,1.77c-2.19.52-4.42-.41-6.55-1.13-9-3-19-2.29-27.7-6a63,63,0,0,1-10.39,46.85c-1.8,2.56-4.17,5.18-7.29,5.38,1.39,4.27,5.67,6.85,9.73,8.77a104.46,104.46,0,0,0,20.24,7.14c2,.47,4.44.7,5.6-1,.7-1,1-2.71,2.23-2.65,1.88,2,1.26,6.1,3.86,7a5,5,0,0,0,2.47,0l5.8-1a10.63,10.63,0,0,0,4.88-1.68,9.56,9.56,0,0,0,2.23-2.84c6.5-11.07,9-23.88,10-36.53.49-6.28,1.1-12.94.67-19.24C785.73,312,778.81,310.37,774.11,312.56Z"
                transform="translate(-28 -92.59)" opacity="0.1"/>
            <path
                d="M438.88,636.93c-2.85-3.66-5.78-7.54-10-9.47a27.38,27.38,0,0,0-7.6-1.86c-14.07-2-28.17-4.08-42.37-4.47-4.64-.13-9.62,0-13.4,2.71-4.33,3.09-6,8.78-6.24,14.09a39.74,39.74,0,0,0,15.32,33c8.5,6.48,19.24,9.19,29.72,11.33,5.51,1.13,11.05,2.14,16.61,3s11.22,1.54,16.59,3.26c11.48,3.68,21.61,12,33.65,12.69a2.65,2.65,0,0,0,1.65-.28,2.8,2.8,0,0,0,1-1.7c2.65-9.35,11.84-31.74,0-38.28-6.19-3.41-13.06-3.56-19.12-7.89S443.48,642.84,438.88,636.93Z"
                transform="translate(-28 -92.59)" fill="#be6f72"/>
            <path
                d="M337.79,699c-7.79-6.78-18.54-10.37-24.2-19-2.49-3.8-3.75-8.26-5-12.64l-3.68-13.22a6.89,6.89,0,0,1-.38-2.53c.28-2.76,3.53-4.07,6.27-4.5,14-2.18,28.58,3.46,39.06,13s17.21,22.7,21.16,36.34a3.41,3.41,0,0,1,.12,2.18,3.36,3.36,0,0,1-1.54,1.46c-5.06,3-11.86,7.37-17.77,8.29S342,702.62,337.79,699Z"
                transform="translate(-28 -92.59)" fill="#be6f72"/>
            <path
                d="M679.57,642l41,16.41a17.47,17.47,0,0,1,10.51,20.26L700.57,807H624.29l13.28-146S666.57,637,679.57,642Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <path
                d="M719.17,626.6c-.76,1.66-1.88,3.3-1.71,5.11.1,1.08.66,2.1.58,3.17-.16,2.1-2.59,3.27-4.69,3.29s-4.21-.66-6.28-.3c-3.4.58-5.7,3.71-8.64,5.52-4.1,2.52-9.22,2.37-14,2.8-10,.9-19.46,4.65-28.51,8.92-6.15,2.9-12.68,6.62-15,13-3.33,9,3.13,18.48,4.56,28a53.73,53.73,0,0,1,.28,9.66c-1.05,33.62-2.86,67.63-4.78,101.21l-366.56.43,18.05-31.63c17.6-30.85,35.67-62.28,62.22-85.87a55.9,55.9,0,0,1,13.14-8.84l21.29,19a21.42,21.42,0,0,0,5.85,4.13c4,1.63,8.48.7,12.77.84,8.25.27,15.79,4.48,22.95,8.59a131.73,131.73,0,0,1,15.12,9.59c2.82,2.16,6.89,3.51,9.83,5.5l-.69-35.9c-.07-3.24-.12-6.56.85-9.65,1.88-6,7.25-10.24,9.83-15.95,1.17-2.57,1.74-5.37,2.72-8a54.5,54.5,0,0,1,5.14-9.65l13.27-21.32c3.28-5.26,6.55-10.52,10.11-15.59,15.57-22.21,36.53-40,56.92-57.85a23.82,23.82,0,0,1,3.35-14.56,22.37,22.37,0,0,0,2.58-4.69,19.56,19.56,0,0,0,.37-6c0-.51,0-1,0-1.54a13.94,13.94,0,0,1,3.86-8.72c2.34-2.43,5.47-3.91,8.55-5.29A279.3,279.3,0,0,1,622,497.23c2.71-.64,5.58-1.24,8.25-.43.81,2.61,3.57,3.53,6.33,4.19l1.77.41a29.64,29.64,0,0,1,12.74,6.22c3.87,3.38,6.53,7.9,9.12,12.35,19.47,33.43,28.44,77.29,58.56,101.58C720.23,622.7,719.93,625,719.17,626.6Z"
                transform="translate(-28 -92.59)" fill={color}/>
            <path
                d="M842,216c-10.54,23.17-31.31,46-58.43,46a66,66,0,0,1-6.63-.33,27,27,0,0,0-3.14-3.46c-4.55-4.22-11-5.31-15.47-9,2.72-4.78,8.76-9.12,12.87-12.77q6.66-5.89,13.82-11.17a196.76,196.76,0,0,1,30.35-18.43c6.76-3.31,13.82-6.27,21.28-7.28a4.62,4.62,0,0,1,2.81.25c1.16.64,1.58,2.08,1.86,3.37C842.71,209.58,840.84,209.5,842,216Z"
                transform="translate(-28 -92.59)" opacity="0.1"/>
            <circle cx="755.57" cy="100.4" r="67" fill="#fbbebe"/>
            <path
                d="M923.1,415.82a121.77,121.77,0,0,1-5.68,23.79c-1,2.81-2.23,5.55-3.5,8.25-7.45,15.79-11.54,32.86-13.49,50.22a27.77,27.77,0,0,1-.75,4.05c-1.47,5.6-4.57,10.6-6.94,15.87a129.24,129.24,0,0,0-6,17.53,9.78,9.78,0,0,0-.56,4.71c.37,1.69,1.59,3,2.45,4.53,4.44,7.65-.69,17-2.42,25.66-.59,3-.85,6.26-2.8,8.6-1.66,2-4.24,2.92-6.69,3.76a757.3,757.3,0,0,1-106.94,27.84c-5.41,1-11,1.93-16.37.93s-10.72-4.32-12.56-9.5c-1.06-3-.88-6.26-.79-9.44.2-6.68,0-13.4-.36-20.13-1.16-22.15-3.79-44.38,2-65.6,1.73-6.26,4.17-12.3,6.13-18.49,3.83-12.07,4.82-24.55,5.78-37.07.79-10.32,1.56-20.68,3.87-30.86,1.72-7.59.58-15.48.48-23.26a87.59,87.59,0,0,1,15-50c1.73-2.55,3.63-5.07,4.54-8,1.28-4.2.4-8.71-.18-13.07a91.76,91.76,0,0,1,1.74-33.31,34,34,0,0,1,4.61-11.49c2.49-3.63,6.06-6.37,9.63-8.95a253,253,0,0,1,67.83-34.75c2.23,5.64,4.37,12.23,6.08,18,.23.78.39,1.77-.25,2.27s-1.3.23-2,.25a3.6,3.6,0,0,0-3.09,2.65,6.54,6.54,0,0,0,.38,4.24c1.36,3.5,4.29,6.11,7.11,8.58L883.54,286a34.71,34.71,0,0,0,5.53,4.22c1.78,1,3.72,1.71,5.55,2.63,7.34,3.71,12.41,10.94,15.24,18.66s1.55,17,5.24,24.33C927.36,360.14,927.26,388.89,923.1,415.82Z"
                transform="translate(-28 -92.59)" fill="#e8e8f0"/>
            <path
                d="M913.78,670.08a107.22,107.22,0,0,1-.31,52.85c-.81,3.09-1.76,6.29-1.12,9.42.37,1.78,1.24,3.45,1.44,5.26a15.4,15.4,0,0,1-.53,4.9c-4.44,21-2.85,42.82-1.06,64.25-.76,0-1,.35-1.8.35H891.13c-.34-5.63-7.34-9.85-10.76-14.34A37.12,37.12,0,0,1,874,761.58c3-12,11.84-21.59,16.37-33.06,5-12.62,4.57-26.91,9.88-39.39C903.3,681.92,909,676.29,913.78,670.08Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <path
                d="M913.78,670.08a107.22,107.22,0,0,1-.31,52.85c-.81,3.09-1.76,6.29-1.12,9.42.37,1.78,1.24,3.45,1.44,5.26a15.4,15.4,0,0,1-.53,4.9c-4.44,21-2.85,42.82-1.06,64.25-.76,0-1,.35-1.8.35H891.13c-.34-5.63-7.34-9.85-10.76-14.34A37.12,37.12,0,0,1,874,761.58c3-12,11.84-21.59,16.37-33.06,5-12.62,4.57-26.91,9.88-39.39C903.3,681.92,909,676.29,913.78,670.08Z"
                transform="translate(-28 -92.59)" opacity="0.1"/>
            <path
                d="M873.34,573.83c-16.38-2.62-32.25,5.63-48.07,10.64-24.17,7.65-50.78,8.12-73.11,20.13a18.67,18.67,0,0,0-5.34,3.86,15.29,15.29,0,0,0-3.29,7.82A27.75,27.75,0,0,0,747,634.63c1.39,2.36,3.14,4.53,4.22,7.06,2.56,6,.85,12.92.41,19.48-1,14.65,4.68,28.91,5.82,43.55.68,8.63-.21,17.45,1.82,25.87,1.27,5.28,3.66,10.26,4.65,15.6s.56,10.56.89,15.84c.93,15.13,7.82,29.83,6.14,44.9l130,.43c-4.82-16.77-8.83-34.11-4.46-51a120.23,120.23,0,0,1,5.64-15.65c7.27-17.57,14.74-35.19,18.75-53.77s4.37-38.42-2.42-56.18c-7.56-19.8-23.63-36.27-27.13-57.17C885.33,572.79,879.35,574.79,873.34,573.83Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <path
                d="M736.91,160.31c-1.26-2.64-4.83-4.38-7.23-2.71S727.59,163,727,166c-1.14,5.9-7,9.92-13,10.86-2.81.45-5.94.27-8.12-1.55a11.3,11.3,0,0,1-3-4.89c-6-16-2.35-34.68,7.47-48.64A34.23,34.23,0,0,1,718,113.6c8.27-6,19.33-6,29.56-5.68a14,14,0,0,0,6.15-.71,17,17,0,0,0,4.08-3c9.69-8.52,23.08-12.1,36-11.53s25.35,5,36.83,10.89c5.49,2.81,10.87,6,15.17,10.44,10.33,10.63,12.85,26.38,14.88,41.06,2.18,15.84,4.35,32.12.68,47.69-2.08,8.82-6,17.44-5.54,26.49a213.89,213.89,0,0,1-25.28,5.59c-4,.63-8.62,1-11.55-1.83a11.44,11.44,0,0,1-2.64-4.68c-3.17-9.37-2.11-20.07-6.9-28.73a24.1,24.1,0,0,0-19.77-12.18c-3.14-.11-6.71.66-8.32,3.35a20.67,20.67,0,0,0-1.42,4.12c-2.22,6.69-11.31,10-17.3,6.27-3-1.88-5.05-5-7.76-7.34-3.44-2.95-7.85-4.47-11.71-6.85C730.3,179,741.89,170.76,736.91,160.31Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <path
                d="M636.62,501c-5.47,1.75-10.45,4.92-15.77,7.15-9.37,3.91-19.67,4.82-29.5,7.32a121.18,121.18,0,0,0-27.11,10.92c-.63.34-2.61,2-4.19,3.14,0-.51,0-1,0-1.54a13.94,13.94,0,0,1,3.86-8.72c2.34-2.43,5.47-3.91,8.55-5.29A279.3,279.3,0,0,1,622,497.23c2.71-.64,5.58-1.24,8.25-.43C631.1,499.41,633.86,500.33,636.62,501Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <path
                d="M494.85,757.45c10.76,12,20.41,25.1,32.93,35.22-14.45-19.19-35.18-32-53.92-46.47-3.8-2.94-11.4-7.11-13.7-11.36-3.63-6.66,4.39-1.93,7.72-.12C478.28,740.37,487,748.71,494.85,757.45Z"
                transform="translate(-28 -92.59)" opacity="0.1"/>
            <path
                d="M882,407.17c-.51,4.32-2.12,8.42-3.75,12.44-4,10-8.29,19.9-14.33,28.77-4.16,6.11-9.11,11.63-13.45,17.6a202,202,0,0,0-12.24,19.73Q828,503.83,818.29,522.25c-3.09,5.88-6.16,11.81-10.15,17.12a118,118,0,0,1-9,10.24c-7,7.38-14.19,14.2-20.45,22.2-3.1,4-8.27,5.54-13.1,6.9a17.82,17.82,0,0,1-5,.9,17.26,17.26,0,0,1-5-1.11,126.72,126.72,0,0,1-15.8-6c-1.16-22.15-3.79-44.38,2-65.6,1.73-6.26,4.17-12.3,6.13-18.49,3.83-12.07,4.82-24.55,5.78-37.07,1.19-2.51,2.46-5,3.69-7.42,8.34-16.48,15.37-34.63,13.2-53a99,99,0,0,1-1.15-10,37.68,37.68,0,0,1,2.05-12.07,66.74,66.74,0,0,1,38-41,21.27,21.27,0,0,1,9-2c4,.21,7.83,2.29,11.73,1.53,13.21,5.81,23.9,15.73,31.89,27.75s8.43,28.08,17.12,39.6C881.8,398.23,882.5,402.86,882,407.17Z"
                transform="translate(-28 -92.59)" opacity="0.1"/>
            <polygon
                points="564.86 260.31 564.44 259.4 548.1 266.94 545.37 268.2 542.65 269.45 565.69 319.4 568.41 318.14 571.14 316.88 587.48 309.34 587.06 308.44 570.72 315.98 570.3 315.07 586.64 307.53 586.22 306.62 569.88 314.16 569.46 313.25 585.8 305.71 585.39 304.8 569.04 312.34 568.62 311.44 584.97 303.9 584.55 302.99 568.2 310.53 567.78 309.62 584.13 302.08 583.71 301.17 567.37 308.71 566.95 307.8 583.29 300.26 582.87 299.36 566.53 306.9 566.11 305.99 582.45 298.45 582.03 297.54 565.69 305.08 565.27 304.17 581.62 296.63 581.2 295.72 564.85 303.26 564.43 302.36 580.78 294.81 580.36 293.91 564.01 301.45 563.6 300.54 579.94 293 579.52 292.09 563.18 299.63 562.76 298.72 579.1 291.18 578.68 290.27 562.34 297.81 561.92 296.91 578.26 289.37 577.85 288.46 561.5 296 561.08 295.09 577.43 287.55 577.01 286.64 560.66 294.18 560.25 293.27 576.59 285.74 576.17 284.83 559.83 292.37 559.41 291.46 575.75 283.92 575.33 283.01 558.99 290.55 558.57 289.64 574.91 282.1 574.5 281.19 558.15 288.74 557.73 287.83 574.08 280.29 573.66 279.38 557.31 286.92 556.89 286.01 573.24 278.47 572.82 277.56 556.48 285.1 556.06 284.19 572.4 276.65 571.98 275.75 555.64 283.29 555.22 282.38 571.56 274.84 571.14 273.93 554.8 281.47 554.38 280.56 570.73 273.02 570.31 272.12 553.96 279.65 553.54 278.75 569.89 271.21 569.47 270.3 553.12 277.84 552.71 276.93 569.05 269.39 568.63 268.48 552.29 276.02 551.87 275.11 568.21 267.57 567.79 266.67 551.45 274.21 551.03 273.3 567.37 265.76 566.96 264.85 550.61 272.39 550.19 271.48 566.54 263.94 566.12 263.03 549.77 270.57 549.35 269.67 565.7 262.13 565.28 261.22 548.93 268.76 548.52 267.85 564.86 260.31"/>
            <path
                d="M644.16,462.76c-2.11,3.57-5.34,6.67-9.35,7.72-5,1.29-10.82-1.21-13-5.89-1.34-2.9-1.28-6.22-1.35-9.41a104.74,104.74,0,0,0-2-18.6c-.62-3.07-1.45-6.26-3.54-8.58-2.7-3-7-4-11-4.07-4.68-.06-9.81,1.21-12.54,5-2.19,3.06-2.57,7.36-5.48,9.75a9.07,9.07,0,0,1-4.45,1.81,14.87,14.87,0,0,1-15.73-9.45c-.92-2.64-1.37-5.92-3.87-7.16-1.3-.65-2.85-.53-4.24-.95-4.26-1.29-5.38-6.69-5.77-11.12-.45-5.19,1.28-15.44-5.17-15.79-6.9-.38-14.91,3.63-21.3,5.76-4.1,1.65-8.92,3.55-12.69,1.24-2.77-1.71-3.91-5.26-3.74-8.51s1.41-6.33,2.39-9.43c1.68-5.24,2.69-10.68,4.23-16a47.48,47.48,0,0,0-1.93,4.62c-2.22,6.31-3.26,13-5.3,19.34-1,3.1-2.22,6.18-2.39,9.43s1,6.8,3.74,8.51c3.77,2.31,8.59.41,12.69-1.24,6.39-2.13,14.4-6.14,21.3-5.76,6.45.35,4.72,10.6,5.17,15.79.39,4.43,1.51,9.83,5.77,11.12,1.39.42,2.94.3,4.24.95,2.5,1.24,3,4.52,3.87,7.16a14.87,14.87,0,0,0,15.73,9.45,9.07,9.07,0,0,0,4.45-1.81c2.91-2.39,3.29-6.69,5.48-9.75,2.73-3.81,7.86-5.08,12.54-5,4,.06,8.35,1.06,11,4.07,2.09,2.32,2.92,5.51,3.54,8.58a104.74,104.74,0,0,1,2,18.6c.07,3.19,0,6.51,1.35,9.41,2.17,4.68,8,7.18,13,5.89,4-1.05,7.24-4.15,9.35-7.72a40,40,0,0,0,3.68-9.24C644.62,461.94,644.4,462.35,644.16,462.76Z"
                transform="translate(-28 -92.59)" opacity="0.1"/>
            <path
                d="M695.6,456.12c-8-7.68-15.23-16.18-22.41-24.66-6.88-8.11-13.78-16.26-19.32-25.33-3.43-5.61-6.49-11.73-11.76-15.66-4-3-8.89-4.4-13.31-6.69-3.18-1.65-6.11-3.74-9.25-5.45a44,44,0,0,0-22.34-5.27c-3.23.11-6.9.86-8.56,3.64-.57.95-.84,2.06-1.42,3-1.79,3-6.1,3.85-7.57,7-2.48,5.3,5,10.17,5.47,16,.22,2.92-1.39,5.64-1.86,8.53-.8,5,1.79,9.77,4.29,14.13,5.35,9.34,11.86,19.57,22.37,21.92,3.72.83,7.65.56,11.28,1.74a26.68,26.68,0,0,1,8,4.77A131,131,0,0,1,655.05,481c3.38,4.77,6.44,9.75,9.85,14.49a117.8,117.8,0,0,0,41.57,35.74l12.27-39c1.89-6,3.38-14.23-2-18.91C710,467.25,702.23,462.46,695.6,456.12Z"
                transform="translate(-28 -92.59)" fill="#fbbebe"/>
            <path
                d="M829.17,322.42c-3.9.75-7.77-1.32-11.73-1.53a21.21,21.21,0,0,0-9,2,66.78,66.78,0,0,0-38,41A38.06,38.06,0,0,0,768.42,376c.06,3.35.76,6.65,1.15,10,2.17,18.34-4.86,36.5-13.19,53-4.61,9.11-9.81,18.72-8.72,28.87.14,1.36.36,2.89-.47,4-1,1.32-3.16,1.39-4.09,2.78s-.08,3.49-.82,5.06c-1,2.22-4.26,1.95-6.59,1.16a53,53,0,0,1-17.43-9.9c-8.31,4.85-17.2,10.63-19.93,19.85A44.05,44.05,0,0,0,697,498l-4.29,35.8c-.57,4.74-.85,10.3,2.73,13.46,6.17,5.46,17.16-1.61,23.76,3.33,1.69,1.26,2.79,3.14,4,4.85,7.21,9.89,19.62,14.24,31.26,18a17.57,17.57,0,0,0,5,1.1,17,17,0,0,0,5-.9c4.84-1.36,10-2.94,13.1-6.89,6.27-8,13.46-14.82,20.46-22.2a121.26,121.26,0,0,0,9-10.24c4-5.32,7.06-11.25,10.15-17.13q9.69-18.42,19.92-36.54A201.75,201.75,0,0,1,849.44,461c4.35-6,9.29-11.5,13.45-17.6,6-8.88,10.31-18.82,14.33-28.77,1.63-4,3.24-8.13,3.76-12.44s-.18-8.94-2.8-12.41c-8.69-11.53-9.12-27.58-17.11-39.6S842.39,328.22,829.17,322.42Z"
                transform="translate(-28 -92.59)" fill="#e8e8f0"/>
            <path
                d="M593.13,357a1.89,1.89,0,0,0-.86,1.35c0,.5.41.89.63,1.34.61,1.28-.37,2.76-1.49,3.64s-2.48,1.56-3.09,2.85a1,1,0,0,0-.12.56,1.2,1.2,0,0,0,.44.66,5.45,5.45,0,0,0,2.47,1.62c1.53.32,3-.69,4.3-1.52a23.28,23.28,0,0,1,4.48-2.2,4.37,4.37,0,0,0,1.35-.67,3,3,0,0,0,.87-1.86c.34-2.51-1-4.92-2.53-7C597.74,353.32,594.7,355.65,593.13,357Z"
                transform="translate(-28 -92.59)" fill="#3f3d56"/>
            <rect x="314.13" y="623.51" width="119.62" height="61.27" rx="1.54"
                  transform="translate(310.2 -191.08) rotate(27.04)" fill="#3f3d56"/>
            <rect x="323.8" y="629.09" width="100.54" height="49.98" transform="translate(310.19 -191.14) rotate(27.04)"
                  fill="#e8e8f0"/>
            <ellipse cx="325.81" cy="629.44" rx="1.09" ry="1.65" transform="translate(-410.95 540.95) rotate(-62.96)"
                     fill="#e6e8ec"/>
            <ellipse cx="422.77" cy="679.06" rx="1.79" ry="2.72" transform="translate(-402.25 654.38) rotate(-62.96)"
                     fill="#e6e8ec"/>
            <g opacity="0.1">
                <path
                    d="M861.33,195.72c-2.08,8.82-6,17.44-5.54,26.49a213.89,213.89,0,0,1-25.28,5.59c-4,.63-8.62,1-11.55-1.83a11.44,11.44,0,0,1-2.64-4.68c-3.17-9.37-2.11-20.07-6.9-28.73a24.1,24.1,0,0,0-19.77-12.18c-3.14-.11-6.71.66-8.32,3.35a20.67,20.67,0,0,0-1.42,4.12c-2.22,6.69-11.31,10-17.3,6.27-3-1.88-5.05-5-7.76-7.34-3.44-2.95-7.85-4.47-11.71-6.85-3.48-2.14-5.15-4.3-5.86-6.53-.88,4.81-1.13,9.23,5.86,13.53,3.86,2.38,8.27,3.9,11.71,6.85,2.71,2.32,4.72,5.46,7.76,7.34,6,3.71,15.08.42,17.3-6.27a20.67,20.67,0,0,1,1.42-4.12c1.61-2.69,5.18-3.46,8.32-3.35a24.1,24.1,0,0,1,19.77,12.18c4.79,8.66,3.73,19.36,6.9,28.73A11.44,11.44,0,0,0,819,233c2.93,2.8,7.56,2.46,11.55,1.83a213.89,213.89,0,0,0,25.28-5.59c-.49-9.05,3.46-17.67,5.54-26.49a84,84,0,0,0,1.91-22A77.18,77.18,0,0,1,861.33,195.72Z"
                    transform="translate(-28 -92.59)"/>
                <path
                    d="M737.88,163.11a15.63,15.63,0,0,0-1-9.8c-1.26-2.64-4.83-4.38-7.23-2.71S727.59,156,727,159c-1.14,5.9-7,9.92-13,10.86-2.81.45-5.94.27-8.12-1.55a11.3,11.3,0,0,1-3-4.89,46.46,46.46,0,0,1-2.77-12.94,47.64,47.64,0,0,0,2.77,19.94,11.3,11.3,0,0,0,3,4.89c2.18,1.82,5.31,2,8.12,1.55,5.93-.94,11.82-5,13-10.86.57-3,.18-6.63,2.66-8.35s6,.07,7.23,2.71A13.52,13.52,0,0,1,737.88,163.11Z"
                    transform="translate(-28 -92.59)"/>
            </g>
        </svg>
    )
}
