import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./AuthAPI";
import APIConfig from "./APIConfig";

var PlaceAPI = {
    getNazioni: function(){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/place/nazioni/${AuthAPI.currentUser.accessToken}`),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getProvince: function(codice_nazione){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/place/province/${codice_nazione}/${AuthAPI.currentUser.accessToken}`),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getCitta: function(id_provincia){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/place/citta/${id_provincia}/${AuthAPI.currentUser.accessToken}`),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default PlaceAPI;
