import React, {Fragment} from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import Page from "../../components/BaseComponents/Page/Page";
import {swal, swalLoading} from "../../helpers/SweetAlertWrapper";
import WalletDipendentiAPI from "../../api/Welfare/WalletDipendenteAPI";
import AttivitaWelfareBalanceContent from "./AttivitaWelfareBalanceContent";
import AttivitaWelfareTransactions from "./AttivitaWelfareTransactions";
import WalletAttivitaAPI from "../../api/Welfare/WalletAttivitaAPI";

export default class AttivitaWelfareModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Welfare attivita"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div>
                            <div style={{
                                height: "40vh",
                                backgroundColor: "#293069",
                                color: "white",
                                paddingTop: "10%"
                            }}>
                                <div className={"container text-center align-middle"}>
                                    <div className={"row"}>
                                        <div className={"col-12 mb-2"}>
                                            <AttivitaWelfareBalanceContent
                                                active={this.props.active}
                                                negozio={this.props.negozio}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AttivitaWelfareTransactions
                                active={this.props.active}
                                negozio={this.props.negozio}
                            />
                        </div>
                    }
                />

            </Fragment>
        );
    }
}