import React from "react";
import Dexie from "dexie";

const BOOKIZON_DB = "bookizon_app_icons";

/** Database declaration */
export const BookizonDexieDB = new Dexie(BOOKIZON_DB);
BookizonDexieDB.version(3)
    //Non inserisco tutti i campi, ma solo quelli usati nelle query
    //# = Primary Key
    .stores({
        appIcons: "url", //Schema: {#url, imageData, timestamp}
        accessToken: "id_negozio" //Schema: {#id_negozio, token}
    });
