import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {CategoriaQuickPickerCard} from "./CategoriaQuickPickerCard";
import Page from "../../BaseComponents/Page/Page";
import DiscountBoxToWarnUser from "../../DiscountBoxToWarnUser";

export class CategoryPickerPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idCategoriaSelected: null
        }
    }

    handleSubcategorySelect = id_categoria => {
        const category = this.props.categoryTree.find(c => c.id_categoria === id_categoria);
        if (!category) return;
        if (!category.child || category.child.length === 0) {
            this.props.onLeafChoose(id_categoria);
        } else {
            this.setState({idCategoriaSelected: id_categoria});
        }
    }

    handleSubcategoryDismiss = _ => this.setState({idCategoriaSelected: null});

    getSelectedCategorySubtree = _ => {
        const category = this.props.categoryTree.find(c => c.id_categoria === this.state.idCategoriaSelected);
        return category.child || [];
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Prenota"}
                    active={true}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    
                    onLeftIconClick={this.props.onDismiss}
                >
                    {/*Stampo box per far capire la presenza di uno sconto esterno*/}
                    {this.props.promotionDiscount && <DiscountBoxToWarnUser discount={this.props.promotionDiscount}/>}

                    <div className={"container mt-3"}>
                        {this.props.categoryTree.map(c =>
                            <CategoriaQuickPickerCard
                                key={c.id_categoria}
                                itemData={c}
                                onChoose={this.handleSubcategorySelect}
                            />
                        )}
                    </div>
                </Page>

                {
                    !!this.state.idCategoriaSelected &&
                    <CategoryPickerPage
                        categoryTree={this.getSelectedCategorySubtree()}
                        onDismiss={this.handleSubcategoryDismiss}
                        onLeafChoose={this.props.onLeafChoose}
                    />
                }
            </Fragment>
        )
    }

}

CategoryPickerPage.propTypes = {
    categoryTree: PropTypes.array,
    onDismiss: PropTypes.func,
    onLeafChoose: PropTypes.func,
    hasPromotion: PropTypes.bool
}