import defaultProfilePicM from "../../../../img/profile-placeholder-m.png";
import React from "react";
import styled from "styled-components";
import APIConfig from "../../../../api/APIConfig";
import moment from "moment";
import PropTypes from "prop-types";
import RecensioniForm from "../../../Recensioni/RecensioniForm";
import {FeedCommentsFetchingAnswers} from "./FeedCommentsFetchingAnswers";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {
    ANSWER_IS_LOADED,
    ANSWERING_A_COMMENT,
    NEED_TO_REPLY_COMMENT,
    POST_LIKE_CHANGE
} from "../../../../const/AppEventsCostants";
import AuthAPI from "../../../../api/AuthAPI";
import {FaRegularIcon, FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import PostAPI from "../../../../api/PostAPI";
import FeedAPI from "../../../../api/FeedAPI";
import {PluralString} from "../../../../helpers/UIUtility";


const ProfilePicThumbnail = styled.div`
    width:40px;
    height:40px;
    background-image: url('${props => props.src}');
    background-size:cover;
    background-position:center center;
    position:relative;
    overflow:hidden;
    border-radius:50%;
`;

export default class FeedCommentItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAnswers: false,
            newAnsweringInPublishing : "",
            userLiked: this.props.userLiked,
        }
        this.commentContainerStyle = {
            backgroundColor: "#f8f8f8",
            width: '70%',
            borderRadius: '10px',
            fontSize: '12px'
        }
    }

    getProfilePicUrl = _ => {
        if (!this.props.immagineUtente) {
            return false;
        }
        return APIConfig.url(`/utenti/profile-pic/0/${this.props.idUtente}/400`)
    }

    getCalendarTimestamp = datetime => {
        return moment(datetime).calendar(null, {
            sameDay: '[alle] HH:mm',
            nextDay: '[domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[ieri alle] HH:mm',
            lastWeek: 'dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });
    }

    toggleAnswers = _=>{
        this.setState({showAnswers: !this.state.showAnswers})
    }

    handleReplay =_=>{
        this.setState({showAnswers: true})
        FuxEvents.emit(NEED_TO_REPLY_COMMENT, {"id_commento": this.props.idCommento, "nome_utente": this.props.nomeUtente})
    }

    toggleLike = _ => {
        this.setState({
            userLiked: !this.state.userLiked
        }, _ => {
            let promise = this.state.userLiked ? PostAPI.commentLike(this.props.idCommento) : PostAPI.commentUnlike(this.props.idCommento);
            //Se il toggle non è andato a buon fine sul server, allora ripristino lo stato
            promise
                .then(_ => this.setState({ userLiked: this.state.userLiked }))
                .catch(_ => this.setState({userLiked: !this.state.userLiked}))
        });
    }

    printLikeNumber = _ => {
        var likeNumber = parseInt(this.props.likeNumber || "0");
        if(this.props.userLiked && !this.state.userLiked){
            return likeNumber - 1 > 0 ? likeNumber : "";
        }
        if(!this.props.userLiked && this.state.userLiked){
            return likeNumber + 1;
        }
        return likeNumber || ""
    }


    render() {
        return (
            <div>
                <div className={"d-flex align-items-start"} onClick={this.toggleCommentSection}>
                    <ProfilePicThumbnail
                        src={
                            this.getProfilePicUrl() || defaultProfilePicM
                        }
                    />
                    <div className={"w-75 ml-3"}>
                        <div className={"d-flex"}>
                            <div style={this.commentContainerStyle} className={"p-2 shadow-sm"}>
                                <b className={(this.props.publishing ? "text-muted" : "")}>{this.props.nomeUtente}</b>
                                <div className={(this.props.publishing ? "text-muted" : "")}>{this.props.commento}</div>
                            </div>

                            {
                                this.props.likeNumber !== undefined  &&
                                <div className={"ml-auto mt-2"} style={{fontSize: "10px", textAlign: "center"}} onClick={this.toggleLike}>
                                    {
                                        this.state.userLiked ?
                                            <span><b>{this.printLikeNumber()}</b> <FaSolidIcon name={"heart"}/><br/>ti piace</span> :
                                            <span><b>{this.printLikeNumber()}</b> <FaRegularIcon name={"heart"}/><br/> mi piace</span>

                                    }
                                </div>
                            }
                        </div>

                        <div style={{fontSize: "10px"}} className={"text-muted ml-2 mt-1"}>
                            {
                                this.props.publishing ?
                                    <span>Pubblicazione...</span>
                                    :
                                    <div className={"d-flex"}>
                                        {this.getCalendarTimestamp(this.props.dataCommento)}
                                        {this.props.disableReplay ? '': <span className={"ml-2"} onClick={this.handleReplay}>Rispondi</span>}
                                    </div>
                            }
                        </div>

                        {
                            this.props.idCommento &&
                            <div className={"m-1"}>
                                <div style={{fontSize: "10px"}} className={"text-muted d-flex align-items-center justify-content-start " + (this.props.nRisposte > 0 ? "" : "invisible")} onClick={this.toggleAnswers}>
                                    <hr className={"m-0"} style={{width: "25px"}}/>
                                    {
                                        this.state.showAnswers ?
                                            <span className={"ml-1"}>Nascondi risposte </span>:
                                            <span className={"ml-1"}>Visualizza <b>{this.props.nRisposte} risposte </b></span>
                                    }
                                </div>

                                {
                                    this.state.showAnswers &&
                                    <FeedCommentsFetchingAnswers
                                        idContext={this.props.idCommento}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

FeedCommentItem.propTypes = {
    idUtente: PropTypes.string || PropTypes.number,
    nomeUtente: PropTypes.string,
    immagineUtente: PropTypes.string, //Opzionale
    commento: PropTypes.string,
    dataCommento: PropTypes.string,
    nRisposte: PropTypes.string || PropTypes.number, //Opzionale
    publishing: PropTypes.bool, //Default false
    disableReplay: PropTypes.bool, //Default false
    likeNumber: PropTypes.string || PropTypes.number,
    userLiked: PropTypes.bool
};
