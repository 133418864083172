import PropTypes from "prop-types";

export const SeatsBookType = PropTypes.shape({
    book_id: PropTypes.any,
    seat_id: PropTypes.any,
    seat_internal_code: PropTypes.string,
    sector_id: PropTypes.any,
    date: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    total_partecipants: PropTypes.string,
    id_utente: PropTypes.any,
    customer_first_name: PropTypes.string,
    customer_last_name: PropTypes.string,
    customer_phone: PropTypes.string,
    customer_email: PropTypes.string,
    notes: PropTypes.string,
    user_notes: PropTypes.string,
    status: PropTypes.string,
    price: PropTypes.number,
    global_price: PropTypes.number,
    global_discount_amount: PropTypes.number,
    coupon: PropTypes.string,
    id_coupon: PropTypes.any,
    entrance_date: PropTypes.string,
});