import React from "react";
import PropTypes from "prop-types";

export class StoriesLikeSelectorItem extends React.Component {

    constructor(props) {
        super(props);
        this.circleStyle = {
            backgroundImage: `url("${this.props.imageUrl}")`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            width: this.props.size,
            height: this.props.size,
            borderRadius: "50%",
        }

        this.labelStyle = {
            maxWidth: this.props.size
        }
    }

    handleSelect = _ => {
        this.props.onSelect(this.props.value);
    }

    render() {
        return (
            <div className={"text-center mx-2 d-inline-block"}>
                <div className={"d-inline-block"}
                     style={this.props.selected ? CircleWrapperSelectedStyle : CircleWrapperStyle}
                     onClick={this.handleSelect}>
                    <div style={this.circleStyle}/>
                </div>
                <div className={"small text-truncate"} style={this.labelStyle}>{this.props.label}</div>
            </div>
        )
    }

}

StoriesLikeSelectorItem.propTypes = {
    imageUrl: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelect: PropTypes.func,
    size: PropTypes.number,
    selected: PropTypes.bool
}


const CircleWrapperStyle = {
    border: "2px solid #ddd",
    borderRadius: "50%",
    padding: 2
}
const CircleWrapperSelectedStyle = {
    border: "2px solid #293069",
    borderRadius: "50%",
    padding: 2
}
