import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./AuthAPI";
import APIConfig from "./APIConfig";

var DashboardUserAdminAPI = {
    getData: function(){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/userAdmin/get/'+AuthAPI.currentUser.accessToken),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default DashboardUserAdminAPI;
