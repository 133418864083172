import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./AuthAPI";
import APIConfig from "./APIConfig";

var SharedCartsAPI = {
    save: function (id_negozio, prodotti) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/shared-carts/save`),
            {
                id_negozio: id_negozio,
                token: AuthAPI.currentUser.accessToken,
                prodotti: prodotti
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    get: function (id_cart, auth_code) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/shared-carts/get`),
            {
                id_cart: id_cart,
                auth_code: auth_code,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default SharedCartsAPI;
