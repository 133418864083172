import React, {Fragment} from "react";
import Page from "../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import Store from "../../../components/Store";
import styled from "styled-components";
import AppConfig from "../../../config/AppConfig";
import ToggleSwitch from "../../../components/BaseComponents/ToggleSwitch/ToggleSwitch";
import UsersShopsAPI from "../../../api/UsersShopsAPI";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";
import {FidelityCardAPI} from "../../../api/FidelityCard/FidelityCardAPI";
import {swal, swalLoading} from "../../../helpers/SweetAlertWrapper";
import APIConfig from "../../../api/APIConfig";
import AuthAPI from "../../../api/AuthAPI";
import moment from "moment";
import {PluralString} from "../../../helpers/UIUtility";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import {FidelityCardRegolamentoPage} from "./FidelityCardRegolamentoPage";
import {FidelityCardPremiPage} from "./FidelityCardPremi/FidelityCardPremiPage";

export default class FidelityCardModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            transactions: null,
            qrCodeActive: false,
            regolamentoOpen: false,
            premiOpen: false
        }
    }

    componentDidMount() {
        FidelityCardAPI.User.transactionList(this.props.cardData.id_negozio)
            .then(transaction => this.setState({transactions: transaction}));
    }


    toggleQrCode = _ => this.setState({qrCodeActive: !this.state.qrCodeActive})
    toggleRegolamento = _ => this.setState({regolamentoOpen: !this.state.regolamentoOpen})
    togglePremi = _ => this.setState({premiOpen: !this.state.premiOpen})

    render() {
        return (
            <Fragment>
                <Page
                    title={"Fidelity Card"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <div className={"container mt-3"}>
                            <div className={"card shadow-sm border-0 mb-4"}>
                                <div className={"card-body"}>
                                    <h5 className={"mb-0"}>
                                        Il tuo saldo: <b className={"text-success"}>{this.props.cardData.punti || 0}
                                        <PluralString n={this.props.cardData.punti} more={" punti"} single={" punto"}/></b>
                                    </h5>
                                </div>
                            </div>
                            <div className={"row my-4"}>
                                <div className={"col-6"}>
                                    <div className={"card bg-magenta border-0 shadow-sm text-white"} onClick={this.toggleRegolamento}>
                                        <div className={"card-body"}>
                                            <h6 className={"card-title text-center mb-0 text-white"}>
                                                <FaSolidIcon name={"file-alt"}/> Regolamento
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-6"}>
                                    <div className={"card bg-blue border-0 shadow-sm text-white"} onClick={this.togglePremi}>
                                        <div className={"card-body"}>
                                            <h6 className={"card-title text-center mb-0"}>
                                                <FaSolidIcon name={"gifts"}/> Premi
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"card shadow-sm border-0 text-center my-4"} onClick={this.toggleQrCode}>
                                <div className={"card-body"}>
                                    <QrCodeContainer
                                        className={this.state.qrCodeActive && 'enlargedQr'}
                                        src={APIConfig.url(`/fidelity-card/user/qr-code?token=${AuthAPI.currentUser.accessToken}&id_negozio=${this.props.cardData.id_negozio}`)}
                                    />
                                    <b>Clicca per {this.state.qrCodeActive ? 'rimpicciolire' : 'ingrandire'} il QR
                                        Code</b>
                                </div>
                            </div>
                            <p className={"my-3 text-on-bg-main"}>
                                Fai scansionare questo QR code ad un addetto dell'attività per farti accreditare o
                                scaricare
                                dei punti.
                            </p>
                            <p className={"text-muted"}>
                                <small>
                                    Per proteggerti da frodi o da altre problematiche il tuo codice QR non è sempre lo
                                    stesso, per cui l'attività avrà per forza bisogno del codice sul tuo telefono per
                                    compiere operazioni sulla tua Fidelity Card!
                                </small>
                            </p>
                            {
                                !this.state.transactions ?
                                    <div className={"text-center"}>
                                        <FaSolidIcon name={"spin fa-spinner"}/> Caricamento ultimi movimenti
                                    </div> :
                                    <div className={"list-group"}>
                                        {
                                            this.state.transactions.map((t, i) => {
                                                const punti = parseInt(t.punti);
                                                const arrow = <FaSolidIcon name={"exchange-alt"}
                                                                           className={punti > 0 ? "text-success" : "text-danger"}/>
                                                const calendar = moment(t.data_creazione).calendar(null, {
                                                    sameDay: '[Oggi alle] HH:mm',
                                                    nextDay: '[Domani alle] HH:mm',
                                                    nextWeek: 'dddd DD [alle] HH:mm',
                                                    lastDay: '[Ieri alle] HH:mm',
                                                    lastWeek: '[Lo scorso] dddd [alle] HH:mm',
                                                    sameElse: 'dddd D MMM [alle] HH:mm'
                                                })
                                                return (
                                                    <div className={"list-group-item"} key={t.id_movimento}>
                                                        {arrow} {t.punti} punti<br/>
                                                        <small className={"text-muted"}>{calendar}</small>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                            }

                        </div>
                    }
                />
                <ModalPortalHOC>
                    {
                        this.state.regolamentoOpen &&
                            <FidelityCardRegolamentoPage
                                idNegozio={this.props.cardData.id_negozio}
                                onDismiss={this.toggleRegolamento}
                            />
                    }
                    {
                        this.state.premiOpen &&
                            <FidelityCardPremiPage
                                idNegozio={this.props.cardData.id_negozio}
                                onDismiss={this.togglePremi}
                                punti={this.props.cardData.punti}
                            />
                    }
                </ModalPortalHOC>
            </Fragment>
        );
    }
}


const QrCodeContainer = styled.div`
    margin: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    background-image:url(${props => props.src});
    transition: 0.3s;
    
    &.enlargedQr {
        width: 260px;
        height: 260px;
    }
    
`;
