import React, {Fragment} from "react";
import {SwipeablePanel} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import styled from "styled-components";
import {FaRegularIcon, FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import ProfiloAttivitaContainer from "../../../../components/ProfiloAttivita/ProfiloAttivitaContainer";
import {InputDatepicker} from "../../../../components/BaseComponents/InputDatepicker";
import {timepickerConfig} from "../../../../const/DatePickerConstants";
import moment from "moment";
import {PageFooter} from "../../../../components/BaseComponents/Page/PageFooter";
import PropTypes from "prop-types";

const InfoBackdrop = styled.div`
        position:fixed;
        top:0; left:0; height:100vh; width:100vh;
        z-index:2000; background-color:rgba(0,0,0,0.7);
`;

const InfoWrapper = styled.div`
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
    min-height:80vh;
    max-height:80vh;
    width:100%;
    scrollbar-width: none;
    overflow-y:auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
        display:none;
    }
`;


export default class DashboardDateBooksUnavailableTimePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orario_inizio_indisponibilita: (new Date()).getHours() + ":" + (new Date()).getMinutes(),
            orario_fine_indisponibilita: (new Date()).getHours() + ":" + (new Date()).getMinutes()
        }
        this.footer = {
            position: "fixed",
            zIndex: "2001",
            bottom: "0px",
            left: "0px",
            right: "0px",
        }
    }

    getWrapperRef = node => {
        this.ref = node;
    }

    handleTimeStartChange = ({value}) => this.setState({orario_inizio_indisponibilita: moment(value).format('HH:mm')});
    handleTimeEndChange = ({value}) => this.setState({orario_fine_indisponibilita: moment(value).format('HH:mm')});

    render() {
        return (
            <Fragment>
                <InfoBackdrop className={"__androidDismissable__"} onClick={this.props.onDismiss}/>

                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={_ => !this.ref || this.ref.scrollTop > 0}
                    animationSpeed={150}>
                    <InfoWrapper
                        ref={this.getWrapperRef}
                        className={"bg-main-z2"}
                    >
                        <div className={"text-center text-on-bg-main small"}>
                            <FaSolidIcon name={"grip-lines"}/><br/>
                            <b>Orari per indisponibilità</b>
                        </div>
                        <hr/>
                        <div className={"text-center"}>
                            <div className={"text-muted my-2 p-2"}>Inserisci gli orari in cui il servizio non dovrà più
                                essere disponibile in data {moment(this.props.selectedDate).format('DD-MM-YYYY')}
                            </div>

                            <div className={"my-2"}>
                                <label>Data inizio indisponibilità</label>
                                <div className={"col px-0"}>
                                    <InputDatepicker
                                        className={"form-control border-0 rounded-0 text-center m-0 shadow-none"}
                                        value={this.state.orario_inizio_indisponibilita}
                                        onChange={this.handleTimeStartChange}
                                        format={'hh:mm'}
                                        config={timepickerConfig}
                                        startDate={moment(`00:00`, 'HH:mm').toDate()}
                                    />
                                </div>
                            </div>

                            <div className={"my-2"}>
                                <label>Data fine indisponibilità</label>
                                <div className={"col px-0"}>
                                    <InputDatepicker
                                        className={"form-control border-0 rounded-0 text-center m-0 shadow-none"}
                                        value={this.state.orario_fine_indisponibilita}
                                        onChange={this.handleTimeEndChange}
                                        format={'hh:mm'}
                                        config={timepickerConfig}
                                        startDate={moment(`00:00`, 'HH:mm').toDate()}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={this.footer} className={"px-2 pb-3"}>
                            <hr/>
                            <button className={"btn btn-block btn-primary"}
                                    onClick={_ => this.props.saveData(
                                        moment(this.props.selectedDate).format('YYYY-MM-DD'),
                                        [this.state.orario_inizio_indisponibilita, this.state.orario_fine_indisponibilita]
                                    )}>
                                Salva indisponibilità
                            </button>
                        </div>
                    </InfoWrapper>
                </SwipeablePanel>
            </Fragment>
        )
    }
}

DashboardDateBooksUnavailableTimePanel.propTypes = {
    onDismiss: PropTypes.func,
    selectedDate: PropTypes.any,
    saveData: PropTypes.func
}