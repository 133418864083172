import React, {Fragment} from "react";
import {EcommerceProductsCarousel} from "../EcommerceProductsCarousel";
import PropTypes from "prop-types";
import {ModuloEcommerceAPI} from "../../../api/ModuloEcommerce/ModuloEcommerceAPI";

export const PRODUCT_SUGGEST_SAME_BRAND = 'brand';
export const PRODUCT_SUGGEST_SAME_CATEGORY = 'category';

export class EcommerceProductSuggestionView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productOpen: null,
            products: null,
        }
    }

    componentDidMount() {
        this.fetchProducts();
    }

    fetchProducts = _ => {
        if (this.props.suggestionType === PRODUCT_SUGGEST_SAME_CATEGORY) {
            ModuloEcommerceAPI.Prodotti.getSameCategoryProducts(this.props.productData.id_prodotto, 10)
                .then(products => this.setState({products: products}));
        } else if (this.props.suggestionType === PRODUCT_SUGGEST_SAME_BRAND && this.props.productData.marca) {
            ModuloEcommerceAPI.Prodotti.getSameBrandProducts(this.props.productData.id_prodotto, 10)
                .then(products => this.setState({products: products}));
        }
    }

    render() {
        return <Fragment>
            {
                (this.state.products !== null && !!this.state.products.length) &&
                <div className={"my-3"}>
                    <h6 className={"font-weight-bold text-on-bg-main"}>{this.props.heading}</h6>
                    <EcommerceProductsCarousel
                        prodotti={this.state.products}
                        onAddToCart={this.props.onAddToCart}
                        showOwnerIcon={this.props.showOwnerIcon}
                        showFavouriteIcon={this.props.showFavouriteIcon}
                    />
                </div>
            }
        </Fragment>;
    }
}

EcommerceProductSuggestionView.propTypes = {
    suggestionType: PropTypes.oneOf([PRODUCT_SUGGEST_SAME_BRAND, PRODUCT_SUGGEST_SAME_CATEGORY]),
    onAddToCart: PropTypes.func,
    productData: PropTypes.object,
    heading: PropTypes.string,
    showOwnerIcon: PropTypes.bool,
    showFavouriteIcon: PropTypes.bool
};
