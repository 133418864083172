import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const _Notes = {
    pagination: (user_id, cursor) => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-servizi/notes/pagination`),
            {
                token: AuthAPI.getUserAccessToken(),
                cursor: cursor,
                user_id: user_id
            }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE,
        )
    },
}
