import React from "react";
import styled from "styled-components";
import HorizontalDatePicker from "../../../../components/HorizontalDatePicker/HorizontalDatePicker";
import PropTypes from "prop-types";
import moment from "moment";


export class CalendarDayPicker extends React.Component {

    constructor(props) {
        super(props);
        this.todayMoment = moment();
    }

    getCalendarDateClass = dateYmd => {
        let baseClass = this.todayMoment.format('YYYY-MM-DD') === dateYmd ? 'today' : '';
        if (this.props.datesBookCount) {
            if (this.props.datesBookCount[dateYmd]) {
                baseClass += ' withBadge';
            }
        }
        return baseClass;
    }

    render() {
        return (
            <CalendarWrapper className={"__disable-swipe py-2"}>
                <div className={"container"}>
                    <HorizontalDatePicker
                        getSelectedDay={this.props.onDateChange}
                        color={"#293069"}
                        selectedClass={"selectedDate"}
                        getDayClass={this.getCalendarDateClass}
                        initialDaysBeforeToday={this.props.daysBeforeToday}
                        initialDaysAfterToday={this.props.daysAfterToday}
                    />
                </div>
            </CalendarWrapper>
        )
    }
}

CalendarDayPicker.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    datesBookCount: PropTypes.object.isRequired,
    daysBeforeToday: PropTypes.number.isRequired,
    daysAfterToday: PropTypes.number.isRequired,
}

const CalendarWrapper = styled.div`
    background-color:#EBEBEB;
    
    & .selectedDate > div:last-of-type{
      background-color: #293069;
      border-radius: 50%;
      color:white;
      width:28px; height: 28px;
      text-align:center;
      line-height: 28px;
    }
    
    & .withBadge::before {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #293069;
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }
    
    & .today::after {
        content: 'OGGI';
        position: absolute;
        top: -8px;
        left: 10%;
        background-color: #e39813;
        width: 80%;
        /* height: 3px; */
        border-radius: 500px;
        color: white;
        font-size: 50%;
    }
`;