import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./AuthAPI";
import APIConfig from "./APIConfig";

var FeedAPI = {
    getStartFeed: function(limit){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/feed/start/${limit}/${AuthAPI.currentUser.accessToken}`),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getMore: function(limit,last_id, last_type){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/feed/more/${last_id}/${last_type}/${limit}/${AuthAPI.currentUser.accessToken}`),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    pullToRefresh: function(limit, last_id, last_type){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/feed/ptr/${last_id}/${last_type}/${limit}/${AuthAPI.currentUser.accessToken}`),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getPostData: function (id_post) {
        let params = {};
        if (AuthAPI.currentUser){
            params.token = AuthAPI.currentUser.accessToken;
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/feed/post/${id_post}`),
            params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default FeedAPI;
