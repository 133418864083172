import styled from "styled-components";

export const FullscreenTextarea = styled.textarea`
    width:100%;
    height:12vh!important;
    margin:0px;
    padding:10px 5px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
`;

export const FullscreenInput = styled.input`
    width:100%;
    margin:0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
`;

export const FullscreenSelectWrapper = styled.div`
    & .select__control{
        border:0px;
    }
`;

export const FullscreenSelect = styled.select`
    width:100%;
    margin:0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
`;
