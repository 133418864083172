import PropTypes from "prop-types";
import React from "react";
import {formatCurrency} from "../../../../helpers/CurrencyHelpers";
import {FaSolidIcon} from "../../../FontAwesomeIcons";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {DISPUTE_CHAT_ACCEPT_REFUND, DISPUTE_CHAT_REJECT_REFUND} from "../../../../const/AppEventsCostants";

const MESSAGE_METADATA_TYPE_REFUND = 'refund_proposal';

export function MessageMetadataView(props) {

    if (props.metadata.type === MESSAGE_METADATA_TYPE_REFUND) {
        const status = parseInt(props.metadata.status);
        if (props.isMine) {
            const containerStyle = {
                background: "rgba(255,255,255,0.5)",
            }

            return (
                <div style={containerStyle} className={"rounded p-2 shadow-sm my-3"}>
                    Hai proposto un rimborso di {formatCurrency(props.metadata.amount, 'EUR', 'it-IT')}
                    {
                        status === -1 &&
                        <div className={"small"}>
                            <FaSolidIcon name={"clock"}/> In attesa di risposta
                        </div>
                    }
                    {
                        status === 1 &&
                        <div className={"text-success small"}>
                            Proposta accettata
                        </div>
                    }
                    {
                        status === 0 &&
                        <div className={"text-danger small"}>
                            Proposta rifiutata
                        </div>
                    }
                </div>
            )
        } else {
            const containerStyle = {
                background: "rgba(255,255,255,0.4)",
            }
            const accept = _ => FuxEvents.emit(DISPUTE_CHAT_ACCEPT_REFUND, {
                id_messaggio: props.idMessaggio,
                metadata: props.metadata
            });
            const reject = _ => FuxEvents.emit(DISPUTE_CHAT_REJECT_REFUND, {
                id_messaggio: props.idMessaggio,
                metadata: props.metadata
            });
            return (
                <div style={containerStyle} className={"rounded p-2 shadow-sm my-3"}>
                    Ti è stato proposto un rimborso di {formatCurrency(props.metadata.amount, 'EUR', 'it-IT')}<br/>
                    {
                        status === -1 &&
                        <div className={"d-flex align-items-center btn-group"}>
                            <button className={"btn btn-success btn-sm btn-block mt-0"} onClick={accept}>
                                Accetta
                            </button>
                            <button className={"btn btn-danger btn-sm btn-block mt-0"} onClick={reject}>
                                Rifiuta
                            </button>
                        </div>
                    }
                    {
                        status === 1 &&
                        <div className={"text-success small"}>
                            Proposta accettata
                        </div>
                    }
                    {
                        status === 0 &&
                        <div className={"text-danger small"}>
                            Proposta rifiutata
                        </div>
                    }
                </div>
            )
        }
    }

    return '';
}

MessageMetadataView.propTypes = {
    metadata: PropTypes.object,
    isMine: PropTypes.bool,
    idMessaggio: PropTypes.number,
}