import React from "react";
import PropTypes from "prop-types";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {SwipeablePanel} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {ModuloSeatsAPI} from "../../../../api/ModuloSeats/ModuloSeatsAPI";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {swalError} from "../../../../helpers/SweetAlertWrapper";
import moment from "moment";
import {SeatBookQRInfoView} from "./SeatBookQRInfoView/SeatBookQRInfoView";

export class SeatBookQRInfoPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            book: null
        }
        this.scrollableContainerRef = null;
    }

    componentDidMount() {
        ModuloSeatsAPI.Admin.Books.getBookInfo(this.props.bookId, moment().format('YYYY-MM-DD'))
            .then(book => {
                this.setState({book});
                ModuloSeatsAPI.Admin.Books.setQRScanDate(book.book_id);
            })
            .catch(m => {
                swalError(m);
                this.props.onDismiss();
            });
    }

    isSwipeDisabled = _ => {
        if (this.scrollableContainerRef) {
            return this.scrollableContainerRef.scrollTop > 0;
        }
        return false;
    }

    getScrollableContainerRef = node => this.scrollableContainerRef = node;

    render() {

        return (
            <ModalPortalHOC>
                <div style={BackdropStyle} onClick={this.props.onDismiss}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"bg-main-z2"}>
                        {
                            !this.state.book &&
                            <div className={"py-5 d-flex align-items-center justify-content-center flex-column"}>
                                <EllipsisLoader/>
                                <p className={"text-muted lead"}>
                                    Recupero informazioni...
                                </p>
                            </div>
                        }
                        {
                            !!this.state.book &&
                            <div className={"container py-4"} ref={this.getScrollableContainerRef}
                                 style={ScrollableContainerStyle}>
                                <SeatBookQRInfoView book={this.state.book}/>
                            </div>
                        }
                    </div>
                </SwipeablePanel>
            </ModalPortalHOC>
        )
    }

}


SeatBookQRInfoPanel.propTypes = {
    bookId: PropTypes.number,
    onDismiss: PropTypes.func,
}

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

const ScrollableContainerStyle = {maxHeight: '70vh', overflowY: 'auto'};