import React, {Fragment} from "react";
import AuthAPI from "../../api/AuthAPI";
import defaultProfilePic from "../svg/user.svg";
import defaultProfilePicM from "../../img/profile-placeholder-m.png"
import defaultProfilePicF from "../../img/profile-placeholder-f.png"
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import styled from "styled-components";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../../api/APIConfig";
import FlagDropdown from "../../components/BaseComponents/FlagDropdown/FlagDropdown";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import i18n from "../../i18n";
import moment from "moment";

const ProfilePicThumbnail = styled.div`
    display:block;
    width:120px;
    height:120px;
    background-image: url('${props => props.src}');
    background-size:cover;
    background-position:center center;
    position:relative;
    overflow:hidden;
    border-radius:50%;
`;

const ProfilePicEditButton = styled.div`
    position:absolute;
    bottom:0px;
    left:0px;
    width:100%;
    height:auto;
    padding:3px;
    padding-bottom:15px;
    color:white;
    background-color:rgba(0,0,0,0.3);
    font-size:0.8em;
    text-align:center;
`;

const ProfilePicUploadSpinner = styled.div`
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    color:white;
    background-color:rgba(0,0,0,0.5);
    text-align:center;
    line-height:135px;
`;

const FileSelectInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    
    & + label{
        cursor:pointer;
    }
    
    & + label * {
        pointer-events: none;
    }
`;


export default class UserStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profilePicPreview: null,
            uploading: false
        }
    }

    handleProfilePicChange = async event => {
        const target = event.target;
        let files = target.files
        let fileObjectUrl = URL.createObjectURL(files[0]);
        let blob = await fetch(fileObjectUrl).then(r => r.blob());
        this.setState({profilePicPreview: fileObjectUrl, uploading: true});

        console.log('FILE PICKER RESULT');
        console.log(`${fileObjectUrl}`);

        const formData = new FormData();
        formData.append('token', AuthAPI.currentUser.accessToken);
        formData.append('immagine', blob);

        FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/utenti/update-profile-pic/${AuthAPI.currentUser.accessToken}`), formData,
            FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE
        )
            .then(swalSuccess)
            .then(_ => this.setState({uploading: false}))
            .catch(swalError)
            .catch(_ => this.setState({profilePicPreview: null, uploading: false}))
    }

    getProfilePicUrl = _ => {
        if (!this.props.user.immagine) {
            return null;
        }
        return APIConfig.url(`/utenti/profile-pic/${AuthAPI.currentUser.accessToken}/400`)
    }

    getGenderIcon = _ => {
        switch (this.props.user.sesso) {
            case "1":
                return <MaleIcon name={"mars"} className={"mr-2"}/>
            case "0":
                return <FemaleIcon name={"venus"} className={"mr-2"}/>
            default:
                return '';
        }
    }

    render() {
        return (
            <Fragment>
                <div className={"py-4"}>
                    <ProfilePicThumbnail
                        className={"mx-auto mb-2 shadow"}
                        src={
                            this.state.profilePicPreview || this.getProfilePicUrl() ||
                            (this.props.user.sesso === "0" ? defaultProfilePicF : defaultProfilePicM)
                        }
                    >
                        {
                            this.props.allowEdit ?
                                this.state.uploading ?
                                    <ProfilePicUploadSpinner>
                                        <FaSolidIcon name={"spin"} className={"fa-spinner"} size={2}/>
                                    </ProfilePicUploadSpinner>
                                    :
                                    <ProfilePicEditButton>
                                        <FileSelectInput
                                            id={"profilePicSelector"}
                                            type={"file"}
                                            name={"profilePic"}
                                            className={"form-control"}
                                            onChange={this.handleProfilePicChange}
                                            accept={"image/*"}
                                        />
                                        <label htmlFor={"profilePicSelector"} className={"mb-0"}>
                                            <FaSolidIcon name={"edit"}/> {i18n.t("launcher.side-menu.edit-pic")}
                                        </label>
                                    </ProfilePicEditButton>
                                : ''
                        }

                    </ProfilePicThumbnail>
                    <h5 className="m-0 text-center text-on-bg-main">{this.props.user.nome} {this.props.user.cognome}</h5>
                    <div className={"text-center text-on-bg-main"}>
                        {
                            this.props.showGender && this.getGenderIcon()
                        }
                        {
                            this.props.showBirthday &&
                            <small>
                                {Math.abs(moment(this.props.user.data_nascita).diff(moment(), 'years'))} anni
                            </small>
                        }
                    </div>
                </div>
            </Fragment>
        );
    }

}


const MaleIcon = styled(FaSolidIcon)`
  color:#246eff;
`;
const FemaleIcon = styled(FaSolidIcon)`
  color:#d484b4;
`;
