import React from "react";
import {shadeColor} from "../../../helpers/UIUtility";

export const ViaggiIllustration = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             width="1063.13" height="672.74" viewBox="0 0 1063.13 672.74"><title>aircraft</title>
            <path
                d="M928.08,510.29c-9.77,3.6-26.93,6.56-46.79,8.94-54,6.48-128,8.78-128,8.78S372.88,559.56,87.77,442.22c0,0-41.47-21.74-3-51a125.29,125.29,0,0,1,18.82-11.48l.47-.47a99.89,99.89,0,0,1,14.13-10.89c14.77-9.55,39.71-20.87,70.07-15.74L937.08,473.75S964.43,496.86,928.08,510.29Z"
                transform="translate(-68.44 -113.63)" fill="#3f3d56"/>
            <path d="M778.88,455.4,935.64,344.18l69.09,11.95L937.08,473.75S780.68,475,778.88,455.4Z"
                  transform="translate(-68.44 -113.63)" fill="#3f3d56"/>
            <polygon points="315.78 274.2 628.03 186.63 678.36 197.15 537.31 260.6 493.23 309.92 315.78 274.2"
                     fill="#3f3d56"/>
            <path
                d="M881.29,519.23c-54,6.48-128,8.78-128,8.78S372.88,559.56,87.77,442.22c0,0-41.47-21.74-3-51l.13.54s61.52,41.58,355.46,76.29Z"
                transform="translate(-68.44 -113.63)" fill={color}/>
            <path d="M831.1,516.83l139.6,57,35.07-3.89-83.5-62.76S821.27,485.63,831.1,516.83Z"
                  transform="translate(-68.44 -113.63)" fill="#3f3d56"/>
            <path
                d="M135.52,397.85c-6.66.11-21.92-11.06-31.41-18.58a99.89,99.89,0,0,1,14.13-10.89c7.65,5.87,21.26,15.37,28.83,15C158,382.93,145.55,397.67,135.52,397.85Z"
                transform="translate(-68.44 -113.63)" fill={color}/>
            <path
                d="M769.73,713.86l-89.63,4.69-252-169.92-46.78-31.55L358.6,501.75c42.63-36,216.56,13.65,216.56,13.65,3.33,2.81,4.39,7.48,4.24,12.64-.29,9.66-4.83,21-6.58,25.08-.42,1-.68,1.53-.68,1.53Z"
                transform="translate(-68.44 -113.63)" fill="#3f3d56"/>
            <path
                d="M474.55,625.76,459,635.6l9.42-17.13a15.35,15.35,0,0,0-9.38-3.35h-.25a17.21,17.21,0,0,1-3.22-.24l-5.26,3.34,2.25-4.1a18.78,18.78,0,0,1-9.2-7l-9.41,6,5.94-10.82c-5.5-6.61-12.92-10.66-21.09-10.66-9.79,0-18.51,5.82-24.14,14.9a18.06,18.06,0,0,1-16,8.6h-.52c-10.81,0-19.57,12.25-19.57,27.37s8.76,27.37,19.57,27.37a14.6,14.6,0,0,0,6.81-1.71,28.28,28.28,0,0,1,23.69-.46,24.71,24.71,0,0,0,20.14,0,28.27,28.27,0,0,1,23.47.45,14.72,14.72,0,0,0,6.75,1.67c10.81,0,19.57-12.25,19.57-27.37A34.77,34.77,0,0,0,474.55,625.76Z"
                transform="translate(-68.44 -113.63)" fill="#f2f2f2"/>
            <path
                d="M445.3,665.81a29,29,0,0,0-16.77,1.82,24.63,24.63,0,0,1-20.13,0,28.21,28.21,0,0,0-23.69.46,14.73,14.73,0,0,1-6.82,1.71c-9.6,0-17.59-9.68-19.25-22.44a18.26,18.26,0,0,0,4.8-5.17c5.63-9.07,14.35-14.89,24.14-14.89S406,633,411.62,642a18.5,18.5,0,0,0,15.95,8.77h.25C435.46,650.75,442.08,656.87,445.3,665.81Z"
                transform="translate(-68.44 -113.63)" opacity="0.03"/>
            <path
                d="M913.55,612.76,898,622.6l9.42-17.13a15.35,15.35,0,0,0-9.38-3.35h-.25a17.21,17.21,0,0,1-3.22-.24l-5.26,3.34,2.25-4.1a18.78,18.78,0,0,1-9.2-7l-9.41,6,5.94-10.82c-5.5-6.61-12.92-10.66-21.09-10.66-9.79,0-18.51,5.82-24.14,14.9a18.06,18.06,0,0,1-16,8.6h-.52c-10.81,0-19.57,12.25-19.57,27.37s8.76,27.37,19.57,27.37a14.6,14.6,0,0,0,6.81-1.71,28.28,28.28,0,0,1,23.69-.46,24.71,24.71,0,0,0,20.14,0,28.27,28.27,0,0,1,23.47.45,14.72,14.72,0,0,0,6.75,1.67c10.81,0,19.57-12.25,19.57-27.37A34.77,34.77,0,0,0,913.55,612.76Z"
                transform="translate(-68.44 -113.63)" fill="#f2f2f2"/>
            <path
                d="M884.3,652.81a29,29,0,0,0-16.77,1.82,24.63,24.63,0,0,1-20.13,0,28.21,28.21,0,0,0-23.69.46,14.73,14.73,0,0,1-6.82,1.71c-9.6,0-17.59-9.68-19.25-22.44a18.26,18.26,0,0,0,4.8-5.17c5.63-9.07,14.35-14.89,24.14-14.89S845,620,850.62,629a18.5,18.5,0,0,0,16,8.77h.25C874.46,637.75,881.08,643.87,884.3,652.81Z"
                transform="translate(-68.44 -113.63)" opacity="0.03"/>
            <path
                d="M913.55,284.76,898,294.6l9.42-17.13a15.35,15.35,0,0,0-9.38-3.35h-.25a17.21,17.21,0,0,1-3.22-.24l-5.26,3.34,2.25-4.1a18.78,18.78,0,0,1-9.2-7l-9.41,6,5.94-10.82c-5.5-6.61-12.92-10.66-21.09-10.66-9.79,0-18.51,5.82-24.14,14.9a18.06,18.06,0,0,1-16,8.6h-.52c-10.81,0-19.57,12.25-19.57,27.37s8.76,27.37,19.57,27.37a14.6,14.6,0,0,0,6.81-1.71,28.28,28.28,0,0,1,23.69-.46,24.71,24.71,0,0,0,20.14.05,28.27,28.27,0,0,1,23.47.45,14.72,14.72,0,0,0,6.75,1.67c10.81,0,19.57-12.25,19.57-27.37A34.77,34.77,0,0,0,913.55,284.76Z"
                transform="translate(-68.44 -113.63)" fill="#f2f2f2"/>
            <path
                d="M884.3,324.81a29,29,0,0,0-16.77,1.82,24.63,24.63,0,0,1-20.13,0,28.21,28.21,0,0,0-23.69.46,14.73,14.73,0,0,1-6.82,1.71c-9.6,0-17.59-9.68-19.25-22.44a18.26,18.26,0,0,0,4.8-5.17c5.63-9.07,14.35-14.89,24.14-14.89S845,292,850.62,301a18.5,18.5,0,0,0,16,8.77h.25C874.46,309.75,881.08,315.87,884.3,324.81Z"
                transform="translate(-68.44 -113.63)" opacity="0.03"/>
            <path
                d="M271.53,249.37l-7.75,4.91,4.7-8.54a7.62,7.62,0,0,0-4.68-1.68h-.12a9.54,9.54,0,0,1-1.61-.12l-2.62,1.66,1.12-2a9.36,9.36,0,0,1-4.59-3.49l-4.69,3,3-5.39a13.71,13.71,0,0,0-10.52-5.32c-4.88,0-9.23,2.9-12,7.43a9,9,0,0,1-8,4.29h-.26c-5.39,0-9.76,6.12-9.76,13.66s4.37,13.65,9.76,13.65a7.32,7.32,0,0,0,3.4-.85,14.07,14.07,0,0,1,11.82-.23,12.31,12.31,0,0,0,10,0,14.08,14.08,0,0,1,11.71.23,7.42,7.42,0,0,0,3.36.83c5.39,0,9.76-6.11,9.76-13.65A17.42,17.42,0,0,0,271.53,249.37Z"
                transform="translate(-68.44 -113.63)" fill="#f2f2f2"/>
            <path
                d="M256.94,269.35a14.36,14.36,0,0,0-8.37.91,12.44,12.44,0,0,1-5,1.06,12.26,12.26,0,0,1-5.05-1.09,14.1,14.1,0,0,0-11.82.24,7.28,7.28,0,0,1-3.39.85c-4.79,0-8.78-4.83-9.61-11.19a9.4,9.4,0,0,0,2.4-2.58c2.8-4.53,7.15-7.43,12-7.43s9.18,2.87,12,7.34a9.22,9.22,0,0,0,8,4.37h.13C252,261.83,255.33,264.89,256.94,269.35Z"
                transform="translate(-68.44 -113.63)" opacity="0.03"/>
            <path
                d="M961.53,738.37l-7.75,4.91,4.7-8.54a7.62,7.62,0,0,0-4.68-1.68h-.12a9.54,9.54,0,0,1-1.61-.12l-2.62,1.66,1.12-2a9.36,9.36,0,0,1-4.59-3.49l-4.69,3,3-5.39a13.71,13.71,0,0,0-10.52-5.32c-4.88,0-9.23,2.9-12,7.43a9,9,0,0,1-8,4.29h-.26c-5.39,0-9.76,6.12-9.76,13.66s4.37,13.65,9.76,13.65a7.32,7.32,0,0,0,3.4-.85,14.07,14.07,0,0,1,11.82-.23,12.31,12.31,0,0,0,10,0,14.08,14.08,0,0,1,11.71.23,7.42,7.42,0,0,0,3.36.83c5.39,0,9.76-6.11,9.76-13.65A17.42,17.42,0,0,0,961.53,738.37Z"
                transform="translate(-68.44 -113.63)" fill="#f2f2f2"/>
            <path
                d="M946.94,758.35a14.36,14.36,0,0,0-8.37.91,12.44,12.44,0,0,1-5,1.06,12.26,12.26,0,0,1-5.05-1.09,14.1,14.1,0,0,0-11.82.24,7.28,7.28,0,0,1-3.39.85c-4.79,0-8.78-4.83-9.61-11.19a9.4,9.4,0,0,0,2.4-2.58c2.8-4.53,7.15-7.43,12-7.43s9.18,2.87,12,7.34a9.22,9.22,0,0,0,8,4.37h.13C942,750.83,945.33,753.89,946.94,758.35Z"
                transform="translate(-68.44 -113.63)" opacity="0.03"/>
            <path
                d="M241.53,764.37l-7.75,4.91,4.7-8.54a7.62,7.62,0,0,0-4.68-1.68h-.12a9.54,9.54,0,0,1-1.61-.12l-2.62,1.66,1.12-2a9.36,9.36,0,0,1-4.59-3.49l-4.69,3,3-5.39a13.71,13.71,0,0,0-10.52-5.32c-4.88,0-9.23,2.9-12,7.43a9,9,0,0,1-8,4.29h-.26c-5.39,0-9.76,6.12-9.76,13.66s4.37,13.65,9.76,13.65a7.32,7.32,0,0,0,3.4-.85,14.07,14.07,0,0,1,11.82-.23,12.31,12.31,0,0,0,10,0,14.08,14.08,0,0,1,11.71.23,7.42,7.42,0,0,0,3.36.83c5.39,0,9.76-6.11,9.76-13.65A17.42,17.42,0,0,0,241.53,764.37Z"
                transform="translate(-68.44 -113.63)" fill="#f2f2f2"/>
            <path
                d="M226.94,784.35a14.36,14.36,0,0,0-8.37.91,12.44,12.44,0,0,1-5,1.06,12.26,12.26,0,0,1-5.05-1.09,14.1,14.1,0,0,0-11.82.24,7.28,7.28,0,0,1-3.39.85c-4.79,0-8.78-4.83-9.61-11.19a9.4,9.4,0,0,0,2.4-2.58c2.8-4.53,7.15-7.43,12-7.43s9.18,2.87,12,7.34a9.22,9.22,0,0,0,8,4.37h.13C222,776.83,225.33,779.89,226.94,784.35Z"
                transform="translate(-68.44 -113.63)" opacity="0.03"/>
            <path
                d="M599.55,147.76,584,157.6l9.42-17.13a15.35,15.35,0,0,0-9.38-3.35h-.25a17.21,17.21,0,0,1-3.22-.24l-5.26,3.34,2.25-4.1a18.78,18.78,0,0,1-9.2-7l-9.41,6,5.94-10.82c-5.5-6.61-12.92-10.66-21.09-10.66-9.79,0-18.51,5.82-24.14,14.9a18.06,18.06,0,0,1-16,8.6h-.52c-10.81,0-19.57,12.25-19.57,27.37s8.76,27.37,19.57,27.37a14.6,14.6,0,0,0,6.81-1.71,28.28,28.28,0,0,1,23.69-.46,24.71,24.71,0,0,0,20.14.05,28.27,28.27,0,0,1,23.47.45,14.72,14.72,0,0,0,6.75,1.67c10.81,0,19.57-12.25,19.57-27.37A34.77,34.77,0,0,0,599.55,147.76Z"
                transform="translate(-68.44 -113.63)" fill="#f2f2f2"/>
            <path
                d="M570.3,187.81a29,29,0,0,0-16.77,1.82,24.63,24.63,0,0,1-20.13,0,28.21,28.21,0,0,0-23.69.46,14.73,14.73,0,0,1-6.82,1.71c-9.6,0-17.59-9.68-19.25-22.44a18.26,18.26,0,0,0,4.8-5.17c5.63-9.07,14.35-14.89,24.14-14.89S531,155,536.62,164a18.5,18.5,0,0,0,16,8.77h.25C560.46,172.75,567.08,178.87,570.3,187.81Z"
                transform="translate(-68.44 -113.63)" opacity="0.03"/>
            <path
                d="M1129.53,319.37l-7.75,4.91,4.7-8.54a7.62,7.62,0,0,0-4.68-1.68h-.12a9.54,9.54,0,0,1-1.61-.12l-2.62,1.66,1.12-2a9.36,9.36,0,0,1-4.59-3.49l-4.69,3,3-5.39a13.71,13.71,0,0,0-10.52-5.32c-4.88,0-9.23,2.9-12,7.43a9,9,0,0,1-8,4.29h-.26c-5.39,0-9.76,6.12-9.76,13.66s4.37,13.65,9.76,13.65a7.32,7.32,0,0,0,3.4-.85,14.07,14.07,0,0,1,11.82-.23,12.31,12.31,0,0,0,10,0,14.08,14.08,0,0,1,11.71.23,7.42,7.42,0,0,0,3.36.83c5.39,0,9.76-6.11,9.76-13.65A17.42,17.42,0,0,0,1129.53,319.37Z"
                transform="translate(-68.44 -113.63)" fill="#f2f2f2"/>
            <path
                d="M1114.94,339.35a14.36,14.36,0,0,0-8.37.91,12.44,12.44,0,0,1-5,1.06,12.26,12.26,0,0,1-5-1.09,14.1,14.1,0,0,0-11.82.24,7.28,7.28,0,0,1-3.39.85c-4.79,0-8.78-4.83-9.61-11.19a9.4,9.4,0,0,0,2.4-2.58c2.8-4.53,7.15-7.43,12-7.43s9.18,2.87,12,7.34a9.22,9.22,0,0,0,7.95,4.37h.13C1110,331.83,1113.33,334.89,1114.94,339.35Z"
                transform="translate(-68.44 -113.63)" opacity="0.03"/>
        </svg>
    )
}
