import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {swal, swalLoading} from "../../../helpers/SweetAlertWrapper";

export class SwalLoadingSplashscreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        swalLoading(this.props.message);
    }

    componentWillUnmount() {
        swal.safeClose();
    }

    render() {
        return '';
    }

}

SwalLoadingSplashscreen.propTypes = {
    message: PropTypes.string
}