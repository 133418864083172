import React, {Fragment} from "react";
import PropTypes from "prop-types";
import FeedPostBaseInfo from "./FeedPostBaseInfo";
import {FeedPostCardActionBar} from "./FeedPostCardActionBar";
import {FeedPostComments} from "./Comments/FeedPostComments";

export class FeedPostView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                <div className={"position-relative mh-100 overflow-auto"}>
                    {/*BASE DEL POST*/}
                    <FeedPostBaseInfo {...this.props}/>

                    {/*BARRA LIKE/COMMENTI/CONDIVISIONE*/}
                    <FeedPostCardActionBar
                        {...this.props}
                        onAppAdd={this.props.onAppAdd}
                        onAppOpen={this.props.onAppOpen}
                        onCommentButtonClick={this.toggleCommentSection}
                    />

                    {/*SEZIONE CONTENTE COMMENTI*/}
                    <FeedPostComments id_post={this.props.id_post}/>
                </div>
            </Fragment>
        )
    }
}

FeedPostView.propTypes = {
    idNegozio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onAppOpen: PropTypes.func,
}