import React from "react";
import {shadeColor} from "../../helpers/UIUtility";

export const Decorator2 = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.39135 117.09824">
            <title>decorator-2</title>
            <g id="Livello_2" data-name="Livello 2">
                <g id="Livello_1-2" data-name="Livello 1">
                    <path fill={color}
                          d="M182.8245,117.09824a6.57007,6.57007,0,0,1-6.18205-4.35641L155.8963,54.69567l-58.86667,22.359A6.56395,6.56395,0,0,1,88.51681,73.129L67.763,15.07516,8.8963,37.42644A6.56366,6.56366,0,1,1,4.23476,25.15465L69.3604.42644a6.56394,6.56394,0,0,1,8.51282,3.92564L98.62707,62.40593l58.86667-22.359a6.56394,6.56394,0,0,1,8.51282,3.92565l23,64.34871a6.5688,6.5688,0,0,1-6.18206,8.77693Z"/>
                </g>
            </g>
        </svg>
    )
}
