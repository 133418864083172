import React from "react";
import AppConfig, {BODY_BACKGROUND} from "../config/AppConfig";
import Styled from "styled-components";
import launcherBsTheme from "../launcher/css/launcher-bs-theme.css";
import BookizonLauncher from "../launcher/BookizonLauncher";
import {BookizonAppContainer} from "./BookizonAppContainer";
import {DirectMenuDigitaleContainer} from "./DirectMenuDigitaleContainer";
import {DirectProfiloAttivitaContainer} from "./DirectProfiloAttivitaContainer";
import {DirectPostContainer} from "./DirectPostContainer";
import {DirectPostPage} from "./DirectPostPage";
import FuxEvents from "../lib/FuxFramework/FuxEvents";
import {
    DLA_APP_OPEN, DLA_INTERACTIVE_MAP,
    DLA_VIEW_POST,
    DLA_VIEW_PRODUCT,
    IMMEDIATE_FIRING_FOREGROUND_NOTIFICATION_ACTIONS
} from "../const/DeepLinkActionsCostants";
import {ADD_APP_REQUEST, CLOSE_APP_REQUEST, OPEN_APP_REQUEST} from "../const/AppEventsCostants";
import ModalPortal from "../components/BaseComponents/ModalPortal";
import {NM_NOTIFICATION_FOREGROUND} from "../native/NativeMessageHandler";
import {FuxNotificationSystem} from "../components/GenericUI/FuxNotificationSystem/FuxNotificationSystem";
import {EcommerceProductView} from "../components/Ecommerce/ProductView/EcommerceProductView";
import {DirectProductContainer} from "./DirectProductContainer";
import {DirectProductPage} from "./DirectProductPage";
import {BookizonAppManager, getActionDataByActionLink} from "../index";
import {DirectComandeOrderContainer} from "./DirectComandeOrderContainer";
import {DisputeModeView} from "../launcher/dashboard/DashboardView/Disputes/DisputeModeView";
import {FirebaseTokenResetIOS} from "../components/Platform/FirebaseTokenResetIOS";
import ModalPortalHOC from "../components/BaseComponents/ModalPortalHOC";


export class BookizonRouter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoNegozio: null,
            id_negozio: null,
            linkedApps: [],
            isBookizonWebView: false,
            devMode: false,
            isTemplateView: false,
            templateData: {},
            templateUrl: null,
            jsInterfaceVersion: 1,
            idServizioComande: null,
            accessToken: null,
            disputeMode: false,
        }

        let currentUrl = new URL(window.location.href);

        //Setto la modalità di esecuzione
        if (currentUrl.searchParams.get('fromWebview')) {
            this.state.isBookizonWebView = true;
            AppConfig.isBookizonWebView = true;
        }

        //Setto la modalità developer
        if (currentUrl.searchParams.get('devMode')) {
            this.state.devMode = true;
        }

        //Verifico se ci sono proprietà di templating
        let template = currentUrl.searchParams.get('template');
        if (template) {
            try {
                this.state.isTemplateView = true;
                this.state.templateData = JSON.parse(atob(template));
            } catch (e) {
                console.log(e);
            }
        }

        let templateUrl = currentUrl.searchParams.get('templateUrl');
        if (templateUrl) {
            this.state.templateUrl = templateUrl;
        }

        //Imposto l'id negozio
        let id_negozio = currentUrl.searchParams.get('id_negozio');
        if (id_negozio) {
            this.state.id_negozio = id_negozio;
        }

        //Controllo se deve essere aperto solo il modulo delle comande
        let id_servizio_comande = currentUrl.searchParams.get('id_servizio_comande');
        if (id_servizio_comande) {
            this.state.idServizioComande = id_servizio_comande;
        }

        //Controllo se deve essere aperto solo il modulo delle comande
        let disputeMode = currentUrl.searchParams.get('disputeMode');
        if (disputeMode) {
            this.state.disputeMode = true;
        }

        //Controllo se deve essere aperto solo il post indicato
        let id_post = currentUrl.searchParams.get('id_post');
        if (id_post) {
            this.state.id_post = id_post;
        }

        //Controllo se deve essere aperto solo il prodotto indicato
        let id_prodotto = currentUrl.searchParams.get('id_prodotto');
        if (id_prodotto) {
            this.state.id_prodotto = id_prodotto;
        }

        //Auto-setto la modalità di esecuzione come launcher se non vi sono altri dati che indicano il contrario
        if (!this.state.id_negozio && !this.state.isTemplateView && !this.state.id_post && !this.state.id_prodotto) {
            this.state.isBookizonWebView = true;
        }

        //Modalità di autologin
        let accessToken = currentUrl.searchParams.get('autologinToken');
        if (accessToken) {
            this.state.accessToken = accessToken;
            localStorage[(this.state.id_negozio ? this.state.id_negozio : "global") + "_accessToken"] = accessToken;
        }

        //Controllo se deve essere aperto solo il menu digitale
        if (currentUrl.searchParams.get('onlyMenuDigitale')) {
            this.state.menuDigitale = true;
        }


        //Controllo se deve essere aperto solo il menu digitale
        if (currentUrl.searchParams.get('profiloAttivita')) {
            this.state.profiloAttivita = true;
        }

        //Controllo se viene passata la versione dell'interfaccia JS
        var jsv = currentUrl.searchParams.get('jsv');
        if (jsv) {
            AppConfig.jsInterfaceVersion = parseInt(jsv);
            this.state.jsInterfaceVersion = parseInt(jsv);
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_VIEW_POST, this.handlePostPreviewOpen);
        FuxEvents.on(DLA_INTERACTIVE_MAP, this.handleInteractiveMapOpen);
        FuxEvents.on(DLA_VIEW_PRODUCT, this.handleProductPreviewOpen);
        FuxEvents.on(DLA_APP_OPEN, this.handleAppOpen)
        FuxEvents.on(NM_NOTIFICATION_FOREGROUND, this.handleNativeNotification);
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_VIEW_POST, this.handlePostPreviewOpen);
        FuxEvents.off(DLA_INTERACTIVE_MAP, this.handleInteractiveMapOpen);
        FuxEvents.off(DLA_VIEW_PRODUCT, this.handleProductPreviewOpen);
        FuxEvents.off(NM_NOTIFICATION_FOREGROUND, this.handleNativeNotification);
    }

    handlePostPreviewOpen = id_post => this.setState({id_post: id_post});
    handleProductPreviewOpen = id_prodotto => this.setState({id_prodotto: id_prodotto});
    handleInteractiveMapOpen = map_id => {
        BookizonAppManager.openInAppBrowser(`${AppConfig.webServerUrl}/interactive-maps/view?map_id=${map_id}`);
    }

    handleNativeNotification = message => {
        FuxNotificationSystem.fire({
            title: message.title,
            message: message.body,
            position: 'tc', //Top center
            level: 'info',
            onRemove: (id, removedBy) => {
                if (removedBy === 'click' && message.deepLink) {
                    window.addAppId(message.deepLink.split("/").pop())
                }
            }
        });
        if (message.deepLink) {
            const actionUrlSegment = message.deepLink.split("/").pop();
            const actionData = getActionDataByActionLink(actionUrlSegment);
            if (IMMEDIATE_FIRING_FOREGROUND_NOTIFICATION_ACTIONS[`DL_${actionData.action}`]) {
                window.addAppId(actionUrlSegment);
            }
        }
    }

    handleAppAdd = id_negozio => FuxEvents.emit(ADD_APP_REQUEST, id_negozio);
    handleAppOpen = id_negozio => {
        FuxEvents.emit(CLOSE_APP_REQUEST); //Chiudo l'eventuale app aperta
        FuxEvents.emit(OPEN_APP_REQUEST, id_negozio); //Apro la nuova
    }

    render() {
        //Ordine comande diretto
        if (this.state.disputeMode && this.state.id_negozio) {
            return <DisputeModeView accessToken={this.state.accessToken}/>
        }

        //Ordine comande diretto
        if ((this.state.idServizioComande || this.state.idServizioComande === 0) && this.state.id_negozio) {
            return <DirectComandeOrderContainer accessToken={this.state.accessToken} idNegozio={this.state.id_negozio} idServizio={this.state.idServizioComande}/>
        }

        //Menu digitale diretto
        if (this.state.menuDigitale && this.state.id_negozio) {
            return <DirectMenuDigitaleContainer id_negozio={this.state.id_negozio}/>
        }

        //Profilo attività diretto
        if (this.state.profiloAttivita && this.state.id_negozio) {
            return <DirectProfiloAttivitaContainer id_negozio={this.state.id_negozio}/>
        }

        //Post attività diretto
        if (this.state.id_post && !this.state.isBookizonWebView && !this.state.id_negozio) {
            return <DirectPostContainer id_post={this.state.id_post} useDownloadBanner={true} useMetaTags={true}/>
        }

        //Dettagli prodotto diretto
        if (this.state.id_prodotto && !this.state.isBookizonWebView) {
            return <DirectProductContainer id_prodotto={this.state.id_prodotto} useDownloadBanner={true}
                                           useMetaTags={true}/>
        }

        //App standalone
        if (!this.state.isBookizonWebView && this.state.id_negozio) {
            return <React.Fragment>
                <BookizonAppContainer id_negozio={this.state.id_negozio} isStandalone={true}/>
                <ModalPortalHOC>
                    {/* Anteprima post all'interno di Bookizon */}
                    {
                        this.state.id_post &&
                        <DirectPostPage
                            onDismiss={_ => this.setState({id_post: null})}
                            id_post={this.state.id_post}
                            onAppOpen={this.handleAppOpen}
                            onAppAdd={this.handleAppAdd}
                        />
                    }
                    {/* Anteprima prodotto all'interno di Bookizon */}
                    {
                        this.state.id_prodotto &&
                        <DirectProductPage
                            onDismiss={_ => this.setState({id_prodotto: null})}
                            id_prodotto={this.state.id_prodotto}
                            onAppOpen={this.handleAppOpen}
                            onAppAdd={this.handleAppAdd}
                        />
                    }
                </ModalPortalHOC>
            </React.Fragment>
        }


        //Launcher di Bookizon
        if (this.state.isBookizonWebView) {
            document.body.className = "";
            document.body.style.background = BODY_BACKGROUND;
            return (
                <BookizonStyleWrapper>
                    <BookizonLauncher devMode={this.state.devMode}/>
                    <ModalPortal>
                        {/* Anteprima post all'interno di Bookizon */}
                        {
                            this.state.id_post &&
                            <DirectPostPage
                                onDismiss={_ => this.setState({id_post: null})}
                                id_post={this.state.id_post}
                                onAppOpen={this.handleAppOpen}
                                onAppAdd={this.handleAppAdd}
                            />
                        }
                        {/* Anteprima prodotto all'interno di Bookizon */}
                        {
                            this.state.id_prodotto &&
                            <DirectProductPage
                                onDismiss={_ => this.setState({id_prodotto: null})}
                                id_prodotto={this.state.id_prodotto}
                                onAppOpen={this.handleAppOpen}
                                onAppAdd={this.handleAppAdd}
                            />
                        }
                    </ModalPortal>
                </BookizonStyleWrapper>
            )
        }

        return '';
    }


}

const BookizonStyleWrapper = Styled.div`
    ${launcherBsTheme}
`;


export const fetchStyles = (id_negozio, templateUrl) =>
    fetch(
        AppConfig.webServerUrl + "/bootstrap-override.css.php?id_negozio=" + id_negozio + (templateUrl ? '&templateUrl=' + templateUrl : '')
    ).then(
        response => response.text()
    ).catch(e => {
        console.log(e);
    });
