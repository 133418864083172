import React, {Fragment} from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import Page from "../../components/BaseComponents/Page/Page";
import {swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import IndirizziAPI from "../../api/IndirizziAPI";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {ADDRESS_BOOK_CHANGE} from "../../const/AppEventsCostants";
import RimborsiDipendenteAPI from "../../api/Welfare/RimborsiDipendenteAPI";
import moment from "moment";
import PagamentiDipendenteAPI from "../../api/Welfare/PagamentiDipendenteAPI";
import APIConfig from "../../api/APIConfig";
import AuthAPI from "../../api/AuthAPI";

export default class DipendenteWelfareQrcodeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //states
        }

    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Qr code pagamento"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div className={"container text-center"} style={{paddingTop: '10%'}}>
                            {
                                this.props.qrCodeLink ?
                                    <img
                                        src={this.props.qrCodeLink}
                                        className={"img-fluid"}/>
                                        :
                                    <div className={"container text-center lead mt-4"}>
                                        <i className={"fas fa-spinner fa-spin"}/>
                                    </div>
                            }
                            <h3 className={"mt-3"}>€ {this.props.importo}</h3>
                            <hr/>
                            <span><b>Fai scansionare</b> questo codice <b>all'esercente</b> al quale vuoi pagare. Potrà utilizzare l'opzione
                            <b>'Scannerizza'</b> in alto a sinistra <b>nell'homepage</b> di Bookizon</span><br/>
                            <span>Il Qr code ha valenza temporale <b>limitata</b>, quindi utilizzalo il più presto possibile</span>
                        </div>
                    }
                />
            </Fragment>
        );
    }
}