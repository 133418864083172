import AppConfig from "../../config/AppConfig";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";


export const _Dashboard = {
    /**
     * Permette di salvare una ricevuta
     * @param items
     * @param metodiPagamento
     * @param scontoDocumento
     * @param id_ricevuta //Se passata modificherà una ricevuta già presente
     * @param infoOperazione {id_operazione: 123, tipo_operazione: op_prenotazione_servizi}
     * @returns {Promise | Promise<unknown>}
     */
    salvaScontrino: function (items, metodiPagamento, scontoDocumento = null, id_ricevuta = null, infoOperazione = null){
        let body = {
            items: items,
            metodiPagamento: metodiPagamento,
            scontoDocumento: scontoDocumento,
            id_ricevuta: id_ricevuta,
            infoOperazione: infoOperazione,
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard-cassa/salva-scontrino`), body,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    ricercaProdotto: function (query, limit = 10){
        let params = {
            query: query,
            limit: limit,
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/dashboard-cassa/ricerca-prodotto`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    storicoScontrini: function (cursor){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            cursor: cursor
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/dashboard-cassa/storico`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    /**
     * Recupera informazioni riepilogative sullo scontrino
     * @param dataInizio //Se null verrà utilizzata data corrente
     * @param dataFine //Se null verrà utilizzata data corrente
     */
    getStatisticalInformation: function (dataInizio=null, dataFine=null){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            dataInizio: dataInizio,
            dataFine: dataFine
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/dashboard-cassa/statistiche`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    /**
     * Recupera le informazioni utili per il keypad
     * @returns {Promise}
     */
    getKeypadSettings: function (){
        let params = {
            token: AuthAPI.getUserAccessToken()
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/dashboard-cassa/settings/keypad`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    /**
     * Permette di inviare uno scontrino sfruttando il modulo whatsapp
     * @returns {Promise}
     */
    sendWhatsapp: function (id_ricevuta, cellulare){
        let body = {
            token: AuthAPI.getUserAccessToken(),
            id_ricevuta: id_ricevuta,
            cellulare: cellulare
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard-cassa/scontrino/invia-whatsapp`), body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getFavoriteItems: function (){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/dashboard-cassa/settings/get-favorite-items`), {
                token: AuthAPI.getUserAccessToken(),
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};