import PropTypes from "prop-types";
import React, {Fragment} from "react";
import CronologiaUserListView from "../../../launcher/CronologiaUser/CronologiaUserListView";
import {
    TIPO_OPERAZIONE_ACQUISTO_GIFTCARD,
    TIPO_OPERAZIONE_RICARICA_NEGOZIO, TIPO_OPERAZIONE_RIMBORSO_NEGOZIO,
    TIPO_OPERAZIONE_SCARICO_NEGOZIO
} from "../../../const/OperazioniConstants";
import OperationSelectView from "./OperationSelectView";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";
import {swal, swalInfo} from "../../../helpers/SweetAlertWrapper";
import moment from "moment";

export default class TransactionView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            operationPageOpen: false
        }
    }

    getIconInOrOut = _ => {
        return [TIPO_OPERAZIONE_RICARICA_NEGOZIO, TIPO_OPERAZIONE_RIMBORSO_NEGOZIO].includes(this.props.transaction.tipo_operazione) ?
            <h4 className={'text-success text-lg'}><i className={'fas fa-arrow-circle-down'}/></h4> :
            <h4 className={'text-danger text-lg'}><i className={'fas fa-arrow-circle-up'}/></h4>
    }

    getImportoWithColor = _ => {
        return [TIPO_OPERAZIONE_RICARICA_NEGOZIO, TIPO_OPERAZIONE_RIMBORSO_NEGOZIO].includes(this.props.transaction.tipo_operazione) ?
            <h6 className={'text-success text-lg'}>€{this.props.transaction.importo}</h6> :
            <h6 className={'text-danger text-lg'}>€{this.props.transaction.importo}</h6>
    }

    toggleOperationPageOpen = _ => {
        //Se voglio provare ad aprire le info faccio un check che possa realmente essere fatto
        if (!this.state.operationPageOpen) {
            if ([TIPO_OPERAZIONE_RICARICA_NEGOZIO, TIPO_OPERAZIONE_SCARICO_NEGOZIO,
                TIPO_OPERAZIONE_ACQUISTO_GIFTCARD, TIPO_OPERAZIONE_RIMBORSO_NEGOZIO]
                .includes(this.props.transaction.tipo_operazione)) {
                swalInfo("Attenzione, non sono disponibili ulteriori informazioni per questa transazione")
                return
            }
        }
        this.setState({operationPageOpen: !this.state.operationPageOpen})
    }

    render() {
        var momentDate = moment(this.props.transaction.created_at).calendar(null, {
            sameDay: '[Oggi alle] HH:mm',
            nextDay: '[Domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[Ieri alle] HH:mm',
            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });

        return (
            <Fragment>
                <li className={"card"} onClick={this.toggleOperationPageOpen}>
                    <div className={"card-body d-flex align-items-center justify-content-around"}>
                        <div>{this.getIconInOrOut()}</div>
                        <div className={"text-center"}>
                            {this.props.transaction.tipo_operazione.toUpperCase()
                                .replace("NEGOZIO", "").replace("OP", "").replaceAll("_", " ")}
                            <div className={"text-muted"}>
                                {momentDate}
                            </div>
                        </div>
                        <div>
                            {this.getImportoWithColor()}
                        </div>
                    </div>
                </li>

                <ModalPortalHOC>
                    {
                        this.state.operationPageOpen &&
                        <OperationSelectView
                            id_operazione={this.props.transaction.id_operazione}
                            type={this.props.transaction.tipo_operazione}
                            onDismiss={this.toggleOperationPageOpen}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }
}

TransactionView.propTypes = {
    transaction: PropTypes.object,
}