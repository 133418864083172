import React, {Fragment} from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaBrandIcon, FaSolidIcon} from "../FontAwesomeIcons";
import {BOOKIZON_PAY_METHODS, PAYMENT_METHOD_PAYPAL} from "../../const/PaymentMethodoCostants";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import EcommerceAPI from "../../api/EcommerceAPI";
import {swalError, swalLoading} from "../../helpers/SweetAlertWrapper";
import {BookizonAppManager} from "../../index";
import {NM_ANDROID_ON_RESUME, NM_IN_APP_BROWSER_DISMISS} from "../../native/NativeMessageHandler";
import {PageWithFooterContainer} from "../BaseComponents/Page/PageFooter";
import CouponAPI from "../../api/CouponAPI";
import {StripeAPI} from "../../api/StripeAPI";

export default class EcommercePaymentPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dati_coupon: []
        };
    }

    componentDidMount() {
        FuxEvents.on(NM_IN_APP_BROWSER_DISMISS, this.checkPayment);
        FuxEvents.on(NM_ANDROID_ON_RESUME, this.checkPayment);
        this.getDataCoupon();
    }

    componentWillUnmount() {
        FuxEvents.off(NM_IN_APP_BROWSER_DISMISS, this.checkPayment)
        FuxEvents.off(NM_ANDROID_ON_RESUME, this.checkPayment)
    }

    checkPayment = _ => {
        swalLoading('Recupero dati pagamento in corso...');
        let promise;
        if (BOOKIZON_PAY_METHODS.indexOf(this.props.orderData.metodo_pagamento) !== -1) {
            promise = StripeAPI.verifyPaymentIntent(
                this.props.paymentData.gateway_payment_id,
                this.props.paymentData.gateway_metadata.id_negozio,
                this.props.paymentData.gateway_metadata.is_direct
            )
        }else if(this.props.orderData.metodo_pagamento === PAYMENT_METHOD_PAYPAL){
            promise = EcommerceAPI.verifyPaypalPayment(
                this.props.orderData.id_ordine,
                this.props.paymentData.gateway_order_id
            );
        }else{
            promise = Promise.resolve(true);
        }

        promise
            .then(_ => {
                FuxEvents.off(NM_IN_APP_BROWSER_DISMISS, this.checkPayment);
                FuxEvents.off(NM_ANDROID_ON_RESUME, this.checkPayment);
                this.props.onSuccess();
            })
            .catch(swalError)
    }

    getDataCoupon = _ => {
        if (this.props.orderData.coupon !== '') {
            CouponAPI.getValiditaCouponPerUtente(this.props.orderData.coupon, "ecommerce")
                .then((data) => {
                    this.setState({dati_coupon: data.datiCoupon});
                });
        }
    }

    handleOpenPaymentBrowser = _ => {
        BookizonAppManager.openInAppBrowser(this.props.paymentData.gateway_payment_link);
    }

    getCostoSpedizione = _ => {
        if (this.props.orderData.corriere) {
            return parseFloat(this.props.orderData.corriere.costo_finale || 0);
        }
        return 0.0;
    }

    //Restituisce il costo della spedizione (se consegna a domicilio) sommato a quello dei prodotti
    getSubtotal = _ => {
        return parseFloat(this.props.prezzoProdotti) + this.getCostoSpedizione()
    }

    //Restituisce il valore in euro del coupon applicato all'ordine
    getCouponRealValue = _ => {
        if (!this.state.dati_coupon.id_coupon) return 0;

        if (this.state.dati_coupon.tipo_sconto === 'euro') {
            return parseFloat(this.state.dati_coupon.valore_sconto);
        } else {
            let factorSconto = (parseFloat(this.state.dati_coupon.valore_sconto) / 100);
            return parseFloat(this.props.prezzoProdotti) * factorSconto;
        }
    }

    getWalletsImportoUsato =_=>{
        if(!this.props.orderData.wallets) return 0
        return this.props.orderData.wallets.reduce((partialSum, w) => partialSum + w.importo_usato, 0)
    }

    //Restituisce il totale REALE dell'ordine ovverto totale prodotti + costi consegna - eventuale sconto
    getFinalTotal = _ => {
        return this.getSubtotal() - this.getCouponRealValue() - this.getWalletsImportoUsato();
    }


    getPayButtonText = method => {
        if (BOOKIZON_PAY_METHODS.indexOf(method) !== -1) {
            return <Fragment>
                <FaSolidIcon name={"credit-card"}/> Paga con Carta
            </Fragment>
        }else if(method === PAYMENT_METHOD_PAYPAL) {
            return <Fragment>
                <FaBrandIcon name={"paypal"}/> Paga con PayPal
            </Fragment>
        }

        return <Fragment>
            <FaBrandIcon name={"credit-card"}/> Paga ora
        </Fragment>
    }

    render() {
        return (
            <div>
                <Page
                    title={"Pagamento"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >
                    <PageWithFooterContainer className={"container"}>
                        <h2 className={"font-weight-bold"}>Riepilogo</h2>
                        {
                            this.props.orderData.prodotti.map((p, i) =>
                                <div key={i} className={"row"}>
                                    <div className={"col-8"}>
                                        <b>{p.quantita}</b>x <b>{p.nome}</b>
                                    </div>
                                    <div className={"col-4 text-right"}>
                                        {`€${(parseFloat(p.prezzo) * parseInt(p.quantita)).toFixed(2)}`}
                                    </div>
                                </div>
                            )
                        }

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <hr/>
                            </div>

                            <div className={"col-8"}>
                                <h6>Spedizione</h6>
                            </div>
                            <div className={"col-4 text-right"}>
                                {`€${this.getCostoSpedizione().toFixed(2)}`}
                            </div>
                        </div>

                        {/* Mostro subtotale se c'è uno sconto*/}
                        {
                            (this.state.dati_coupon.id_coupon || this.props.orderData.wallets) &&
                            <div className={"row"}>
                                <div className={"col-8"}>
                                    <h6>Subtotale</h6>
                                </div>
                                <div className={"col-4 text-right"}>
                                    <h6>€{this.getSubtotal().toFixed(2)}</h6>
                                </div>
                            </div>
                        }

                        {/* Mostro lo sconto con il coupon se possibile */}
                        {
                            this.state.dati_coupon.id_coupon &&
                            <div className={"row"}>
                                <div className={"col-8"}>
                                    <h6>
                                        Sconto coupon
                                        {
                                            this.state.dati_coupon.tipo_sconto !== 'euro' &&
                                            <span>&nbsp;{this.state.dati_coupon.valore_sconto}%</span>
                                        }
                                    </h6>
                                </div>
                                <div className={"col-4 text-right"}>
                                    <h6 className={"text-success"}>€{this.getCouponRealValue().toFixed(2)}</h6>
                                </div>
                            </div>
                        }

                        {
                            this.props.orderData.wallets &&
                            <div className={"row"}>
                                <div className={"col-8"}>
                                    <h6>
                                        Dai wallet
                                    </h6>
                                </div>
                                <div className={"col-4 text-right"}>
                                    <h6 className={"text-success"}>€{this.getWalletsImportoUsato().toFixed(2)}</h6>
                                </div>
                            </div>
                        }

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <hr/>
                            </div>
                        </div>

                        {/*Stampa del totale*/}
                        {
                            <div className={"row"}>
                                <div className={"col-8 mt-3"}>
                                    <b>Totale</b>
                                </div>
                                <div className={"col-4 mt-3 text-right"}>
                                    <h5>€{this.getFinalTotal().toFixed(2)}</h5>
                                </div>
                            </div>
                        }
                        <button className={"btn btn-primary btn-block"} onClick={this.handleOpenPaymentBrowser}>
                            {this.getPayButtonText(this.props.orderData.metodo_pagamento)}
                        </button>
                    </PageWithFooterContainer>
                </Page>
            </div>
        );
    }
}

EcommercePaymentPage.propTypes = {
    onDismiss: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    orderData: PropTypes.shape({
        id_ordine: PropTypes.any.isRequired,
        prodotti: PropTypes.array.isRequired,
        metodo_pagamento: PropTypes.string.isRequired,
        corriere: PropTypes.object.isRequired,
        shipping: PropTypes.object.isRequired,
        billing: PropTypes.object,
        coupon: PropTypes.string
    }),
    prezzoProdotti: PropTypes.number.isRequired,
    paymentData: PropTypes.shape({
        gateway_payment_link: PropTypes.string.isRequired,
        gateway_order_id: PropTypes.any, //For paypal
        gateway_payment_id: PropTypes.string, //For stripe
        gateway_metadata: PropTypes.shape({
            id_negozio: PropTypes.any,
            is_direct: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        })
    })
};
