import React, {Fragment} from "react";
import itFlag from "../../../img/flags/it.svg";
import enFlag from "../../../img/flags/en.svg";
import frFlag from "../../../img/flags/fr.svg";
import deFlag from "../../../img/flags/de.svg";
import esFlag from "../../../img/flags/es.svg";
import nlFlag from "../../../img/flags/nl.svg";
import hrFlag from "../../../img/flags/hr.svg";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import DropdownMenu from "../DropdownMenu";
import i18n from "./../../../i18n";
import {getCurrentLanguage} from "../../../langUtils";

const languages = {
    'it': {name: 'Italiano', image: itFlag},
    'en': {name: 'English', image: enFlag},
    'fr': {name: 'Français', image: frFlag},
    'de': {name: 'Deutsch', image: deFlag},
    'es': {name: 'Español', image: esFlag},
    'nl': {name: 'Nederlands', image: nlFlag},
    'hr': {name: 'Hrvatski', image: hrFlag},
};


export default class FlagDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: getCurrentLanguage(this.props.default),
            open: false
        }
    }

    toggleDropdown = _ => {
        this.setState({open: !this.state.open});
    }

    onLanguageClick = lang => _ => {
        this.setState({selected: lang});
        i18n.changeLanguage(lang);
        if (this.props.onChange){
            this.props.onChange(lang);
        }
    }

    render() {
        return (
            <Fragment>
                <span className={"d-inline-flex align-items-center justify-content-center " + this.props.className} onClick={this.toggleDropdown}>
                    <img src={languages[this.state.selected].image} width={"18px"}/>
                    <FaSolidIcon name={"chevron-down text-muted"} className={"ml-1"}/>
                    {
                        this.state.open &&
                        <DropdownMenu onDismiss={this.toggleDropdown}>
                            {
                                Object.keys(languages).map(lang =>
                                    <div key={lang} className={"list-group-item text-muted d-flex align-items-center"}
                                         onClick={this.onLanguageClick(lang)}
                                    >
                                        <img src={languages[lang].image} width={"18px"} className={"mr-2"}/>
                                        {languages[lang].name}
                                    </div>
                                )
                            }
                        </DropdownMenu>
                    }
                </span>
            </Fragment>
        )
    }

}
