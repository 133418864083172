var AppConfig = {
    webServerUrl: "https://bookizon.it",
    //webServerUrl: "http://localhost:8888/bookizon", //For local server
    //webServerUrl: "https://dev.bookizon.it", //Dev Server
    //webServerWelfare: "http://localhost:8888/bookizonWelfare", //Welfare Server Salvo
    webServerWelfare: "https://welfare.bookizon.it", //Welfare live
    webServerDigitalCity: "https://cittadigitale.proinnsrl.it",
    controllerUrl: "",
    infoNegozio: null,
    id_negozio: null,
    linkedApps: [],
    isBookizonWebView: false,
    isTemplateView: false,
    templateData: {},
    templateUrl: null,
    jsInterfaceVersion: 1
};
AppConfig.controllerUrl = AppConfig.webServerUrl + "/controller.php";
AppConfig.appDefaultIcon = AppConfig.webServerUrl + "/public/img/app-icon-wireframe.png";

export const BODY_BACKGROUND = "#f3f3f3"

export default AppConfig
