import React, {Fragment} from "react";
import PropTypes from "prop-types";
import APIConfig from "../../../../api/APIConfig";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {SwipeablePanel} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {ModuloGruppiAPI} from "../../../../api/ModuloGruppi/ModuloGruppiAPI";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import styled from "styled-components";
import AuthAPI from "../../../../api/AuthAPI";
import {swalError} from "../../../../helpers/SweetAlertWrapper";
import {ObjectArrayGroupByKey} from "../../../../helpers/ObjectArrayGroupByKey";
import moment from "moment";
import {PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA} from "../../../NextBooks/GroupBooks/GroupBookCard";


export class PrenotazioneGruppoQRInfoPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            book: null
        }
        this.scrollableContainerRef = null;
    }

    componentDidMount() {
        ModuloGruppiAPI.Dashboard.getBookInfo(this.props.idPrenotazione, true)
            .then(book => this.setState({book}))
            .catch(m => {
                swalError(m);
                this.props.onDismiss();
            });
    }

    isSwipeDisabled = _ => {
        if (this.scrollableContainerRef) {
            return this.scrollableContainerRef.scrollTop > 0;
        }
        return false;
    }

    getUserProfilePic = _ => {
        return APIConfig.url(`/utenti/profile-pic/${AuthAPI.getUserAccessToken()}/${this.state.book.id_utente}/300`)
    }

    getScrollableContainerRef = node => this.scrollableContainerRef = node;

    render() {

        const groupedBooks = this.state.book ? ObjectArrayGroupByKey(this.state.book.books, "id_gruppo") : null;
        const firstScanDate = moment(this.state.book ? this.state.book.data_prima_scansione_qr : null).format('ddd DD MMM [alle] HH:mm');
        const nowDate = moment().format('YYYY-MM-DD HH:mm');
        return (
            <ModalPortalHOC>
                <div style={BackdropStyle} onClick={this.props.onDismiss}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"bg-main-z2"}>
                        {
                            !this.state.book &&
                            <div className={"py-5 d-flex align-items-center justify-content-center flex-column"}>
                                <EllipsisLoader/>
                                <p className={"text-muted lead"}>
                                    Recupero informazioni...
                                </p>
                            </div>
                        }
                        {
                            !!this.state.book &&
                            <div className={"container py-4"} ref={this.getScrollableContainerRef}
                                 style={ScrollableContainerStyle}>
                                {/* HEADING */}
                                <div className={"mb-3 text-center"}>
                                    <div className={"small text-muted text-uppercase"}>prenotazione di</div>
                                    <div className={"d-flex align-items-center justify-content-center mb-3"}>
                                        <ProfilePicThumbnail
                                            className={"mr-2 shadow-sm"}
                                            src={this.getUserProfilePic()}
                                        />
                                        <span className={"lead font-weight-bold"}>
                                        {this.state.book.nome_utente} {this.state.book.cognome_utente}
                                    </span>
                                    </div>
                                </div>

                                {/* ORARIO */}
                                <div className={"mb-3"}>
                                    {
                                        Object.keys(groupedBooks).map(id_gruppo => {
                                            const books = groupedBooks[id_gruppo];
                                            const nomeGruppo = books[0].nome_gruppo;
                                            const dataFormatted = moment(books[0].data).format('ddd DD MMM')
                                            const dateTimeInizio = `${books[0].data} ${books[0].orario_inizio}`;
                                            const orario = `dalle ${books[0].orario_inizio} alle ${books.pop().orario_fine}`;
                                            return <div className={"text-center"}>
                                                <b>{nomeGruppo}</b><br/> {dataFormatted} {orario}
                                                {
                                                    dateTimeInizio < nowDate &&
                                                    <div className={"text-danger"}>
                                                        <b>ATTENZIONE</b> Orario delle prenotazione già passato
                                                    </div>
                                                }
                                            </div>
                                        })
                                    }
                                </div>

                                {/* PARTECIPANTI */}
                                <div className={"mb-3"}>
                                    {
                                        this.state.book.domande ?
                                            this.state.book.domande.map(d =>
                                                <div>
                                                    <b>{d.testo}:</b> {d.persone}
                                                </div>
                                            )
                                            :
                                            <div>
                                                <b>Partecipanti:</b> {this.state.book.partecipanti}
                                            </div>
                                    }
                                </div>

                                {/* DATI SCANSIONE */}
                                {
                                    this.state.book.status === PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA &&
                                    <Fragment>
                                        {
                                            !!this.state.book.data_prima_scansione_qr &&
                                            <div className={"card bg-danger text-white mb-3"}>
                                                <div className={"card-body"}>
                                                    Già scansionato {firstScanDate}
                                                </div>
                                            </div>
                                        }
                                        {
                                            (this.state.book.data_entrata) &&
                                            <div className={"card bg-warning text-white mb-3"}>
                                                <div className={"card-body"}>
                                                    Questo ingresso è stato confermato manualmente
                                                </div>
                                            </div>
                                        }
                                        {
                                            (!this.state.book.data_prima_scansione_qr && !this.state.book.data_entrata) &&
                                            <div className={"card bg-success text-white mb-3"}>
                                                <div className={"card-body"}>
                                                    Prima scansione di questo QR code
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                }

                                {/* PRENOTAZIONE NON CONFERMATA */}
                                {
                                    this.state.book.status !== PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA &&
                                    <div className={"card bg-danger text-white mb-3"}>
                                        <div className={"card-body"}>
                                            <b>PRENOTAZIONE NON CONFERMATA</b>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </SwipeablePanel>
            </ModalPortalHOC>
        )
    }

}


PrenotazioneGruppoQRInfoPanel.propTypes = {
    idPrenotazione: PropTypes.number,
    onDismiss: PropTypes.func,
}

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

const ScrollableContainerStyle = {maxHeight: '70vh', overflowY: 'auto'};


const ProfilePicThumbnail = styled.div`
    display:block;
    width:45px;
    height:45px;
    background-image: url('${props => props.src}');
    background-size:cover;
    background-position:center center;
    position:relative;
    overflow:hidden;
    border-radius:50%;
`;
