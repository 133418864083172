import {_Map} from "./_Map";
import {_Availability} from "./_Availability";
import {_Booking} from "./_Booking";
import {_Books} from "./_Books";

export const Client = {
    Map: _Map,
    Availability: _Availability,
    Booking: _Booking,
    Books: _Books,
}