import Page from "../../BaseComponents/Page/Page";
import React, {Fragment} from "react";
import i18n from "../../../i18n";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {EllipsisLoader} from "../../GenericUI/EllipsisLoader/EllipsisLoader";
import WalletView from "../WalletView";
import PropTypes from "prop-types";
import "./../WalletsStyle.css";
import DipendenteWelfareBalanceContent from "../../../launcher/WelfareDipendente/DipendenteWelfareBalanceContent";
import {formatCurrency} from "../../../helpers/CurrencyHelpers";
import WalletDescription from "../WalletDescription";
import FuxCursorPaginator from "../../BaseComponents/FuxCursorPaginator/FuxCursorPaginator";
import DipendenteWelfareGiftCardsCategoryItem
    from "../../../launcher/WelfareDipendente/GiftCards/Catalog/Categories/DipendenteWelfareGiftCardsCategoryItem";
import {WelfareAPI} from "../../../api/Welfare/WelfareAPI";
import {WalletsAPI} from "../../../api/Wallets/WalletsAPI";
import TransactionView from "./TransactionView";
import {TYPE_WALLET_WELFARE} from "../../CheckoutDiscount/Wallets/WalletsConstants";

export default class SingleWalletPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            walletDescriptionOpen: false,
            transactions: false
        }
    }

    toggleWalletDescription =_=>{
        this.setState({walletDescriptionOpen: !this.state.walletDescriptionOpen})
    }

    handleTransactionRender = transaction => <TransactionView transaction={transaction}/>

    handleTransactionsPageRequest = cursor => WalletsAPI.User.getWalletTransactions(cursor, this.props.wallet.id_wallet)

    //Pagina vuota del fux
    handleTransactionsEmptyPage = _ => ""

    getWalletTitle = _ => {
        switch (this.props.wallet.type) {
            case  TYPE_WALLET_WELFARE:
                return "Il tuo welfare"
            default:
                if(this.props.wallet.name) return this.props.wallet.name.toUpperCase()
                return this.props.wallet.type.replaceAll("_", " ").toUpperCase()
        }
    }


    render() {
        const w = this.props.wallet

        return (
            <Fragment>
                <Page
                    title={i18n.t("launcher.side-menu.wallets")}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={_=>this.props.onDismiss(false)}
                    active={true}
                    content={
                        <div>
                            <div className={"p-3"}>
                                <div className={"card card-body w-100 border-0 text-white shadow-sm bubble-shadow text-center bg-"+w.color}>
                                    <span>{this.getWalletTitle()}</span>
                                    <div className={"container text-center text-white"}>
                                        <div className={"small"}>Hai ancora</div>
                                        <h1>{formatCurrency(w.saldo_disponibile, "EUR", "it-IT")}</h1>
                                        <div>su {formatCurrency(w.inbound_balance, "EUR", "it-IT")}</div>

                                        <div className={"mt-2"} onClick={this.toggleWalletDescription}>
                                            Cosa posso pagare {this.state.walletDescriptionOpen ? <FaSolidIcon name={"caret-down"}/> : <FaSolidIcon name={"caret-right"}/>}
                                        </div>
                                        {
                                            this.state.walletDescriptionOpen &&
                                            <WalletDescription
                                                payable_categories={w.payable_categories}
                                                payable_items={w.payable_items}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            <FuxCursorPaginator
                                onItemRender={this.handleTransactionRender}
                                onPageRequest={this.handleTransactionsPageRequest}
                                onEmptyPage={this.handleTransactionsEmptyPage}
                            />
                        </div>
                    }
                />
            </Fragment>
        )
    }
}

SingleWalletPage.propTypes = {
    wallet: PropTypes.object,
    onDismiss: PropTypes.func
}