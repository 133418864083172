import {Base64} from "../../../helpers/Base64";



/**
 * Salva i dati di un carrello di un negozio per la lettura offline
 * @param id_negozio
 * @param cartProducts
 *
 * @return void
 * */
const saveOfflineCartProducts = (id_negozio, cartProducts) => {
    localStorage.setItem(`${id_negozio}_shop_cart`, Base64.encode(JSON.stringify(cartProducts)));
}

/**
 * Recupera i dati di un carrello di un negozio per la lettura offline
 * @param id_negozio
 *
 * @return array
 * */
const getOfflineCartProducts = (id_negozio) => {
    try {
        const cart = JSON.parse(atob(localStorage.getItem(`${id_negozio}_shop_cart`)));
        return cart || [];
    } catch (e) {
        console.warn("IMPOSSIBILE RECUPERARE CARRELLO", e);
    }
    return [];
}


const areProductEquals = (p1, p2) => {
    return p1.id_prodotto === p2.id_prodotto && JSON.stringify(p1.variante) === JSON.stringify(p2.variante)
}



export const EcommerceCartUtils = {
    checkProductEquals: areProductEquals,
    saveOfflineCartProducts: saveOfflineCartProducts,
    getOfflineCartProducts: getOfflineCartProducts,
};
