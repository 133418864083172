import React from "react";

function suggestedItem(itemInfo){
    return <div className={"d-flex justify-content-between align-items-center"}>
        <span>{itemInfo.nome.length > 25 ? itemInfo.nome.slice(0, 25) + "..." : itemInfo.nome}</span>
        {selectBadge(itemInfo.label)}
    </div>
}

function selectBadge (label){
    switch (label){
        case 'prodotti':
            return <span className={"badge badge-danger"}>Prodotti</span>
        case 'servizi':
            return <span className={"badge badge-primary"}>Servizi</span>
        case 'gruppi':
            return <span className={"badge badge-warning"}>Gruppi</span>
    }
}

export default suggestedItem