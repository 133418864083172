import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./AuthAPI";
import APIConfig from "./APIConfig";

var UsersShopsAPI = {
    getShops: function(){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/usersShops/get/'+AuthAPI.getUserAccessToken()),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    add: function (id_negozio) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/usersShops/add'),
            {token: AuthAPI.getUserAccessToken(), id_negozio:id_negozio},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    delete: function (id_negozio) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/usersShops/delete'),
            {token: AuthAPI.getUserAccessToken(), id_negozio:id_negozio},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    setOrder: function(negozi){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/usersShops/setOrder'),
            {token: AuthAPI.getUserAccessToken(), negozi:negozi},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    save: function(id_negozio, data){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/usersShops/save'),
            {token: AuthAPI.getUserAccessToken(), id_negozio:id_negozio, ...data},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    }
};

export default UsersShopsAPI;