import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {PlaceholderBlock} from "../Placeholders";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";

export class LazyImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }

        this.image = new Image();

        if (!this.props.loadOnView || typeof IntersectionObserver === "undefined") {
            this.loadImage();
        }

        this.spinnerContainerStyle = {
            position: "absolute",
            top: 0, left: 0, right: 0, bottom: 0,
            zIndex: 1,
            color: "white!important",
            opacity: .5
        }

        this.placeholderRef = null;
        this.isLoadingImage = false;
        this.intersectionObserver = null;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.src !== this.props.src ||
            nextState.loaded !== this.state.loaded ||
            (nextProps.scrollTop !== this.props.scrollTop && !this.isLoadingImage && !this.state.loaded); //Aggiorno in base al nuovo scrollTop solo se l'immagine è ancora da caricare
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.shouldLoadImage()){
            this.loadImage();
        }
    }

    shouldLoadImage = _ => {
        if (this.props.loadOnView && this.props.scrollTop !== undefined && !this.isLoadingImage && !this.state.loaded) {
            const viewportHeight = document.documentElement.clientHeight;
            const boundingClientRect = this.placeholderRef.getBoundingClientRect();
            if (boundingClientRect.top - viewportHeight < this.props.scrollTopThreshold) {
                return true;
            }
        }
        return false;
    }

    getPlaceholderRef = el => {
        if (el) {
            this.placeholderRef = el;
            this.intersectionObserver = new IntersectionObserver(
                this.intersectionCallback, {
                    root: null, threshold: 0
                }
            );
            this.intersectionObserver.observe(el);
        }
    }

    loadImage = _ => {
        //console.log("Loading image",this.props.src);
        this.image.src = this.props.src;
        this.image.onload = _ => {
            this.setState({loaded: true});
            if (this.props.onLoad) {
                this.props.onLoad();
            }
        }
    }

    intersectionCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.loadImage();
            }
        });
    };

    render() {
        const Fallback = this.props.fallback;
        return <Fragment>
            {
                this.state.loaded ?
                    this.props.children ? this.props.children : <img
                        alt={this.props.alt}
                        title={this.props.title}
                        src={this.props.src}
                        width={this.props.width}
                        height={this.props.height}
                        style={this.props.style}
                        className={this.props.className}
                        onClick={this.props.onClick}
                    />
                    :
                    this.props.fallback ?
                        <Fallback ref={this.getPlaceholderRef}/>
                        :
                        <div style={this.props.placeholderContainerStyle}>
                            <PlaceholderBlock style={this.props.placeholderStyle}/>
                            {
                                this.props.showLoader &&
                                <div style={this.spinnerContainerStyle}
                                     className={"d-flex align-items-center justify-content-center"}>
                                    <FaSolidIcon name={"circle-notch"} size={this.props.loaderSize}
                                                 className={"fa-spin"}/>
                                </div>

                            }
                        </div>
            }
        </Fragment>
    }
}


LazyImage.propTypes = {
    src: PropTypes.string,
    fallback: PropTypes.object,
    loadOnView: PropTypes.bool,
    scrollTop: PropTypes.number,
    scrollTopThreshold: PropTypes.number,
    placeholderContainerStyle: PropTypes.object,
    placeholderStyle: PropTypes.object,
    showLoader: PropTypes.bool,
    loaderSize: PropTypes.number,
    onLoad: PropTypes.func,
}

LazyImage.defaultProps = {
    loaderSize: 1,
    scrollTopThreshold: 200
}
