import React from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import AddressForm from "../Forms/AddressForm";
import i18next from "i18next";

export default class AddAddressModal extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page
                title={i18next.t('address-book.add-form.title')}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={true}
                content={
                    <div className={"container text-on-bg-main pt-2"}>
                        <AddressForm invoiceData={this.props.invoiceData} onSuccess={this.props.onDismiss}/>
                    </div>
                }
            />
        );
    }

}


AddAddressModal.propTypes = {
    onDismiss: PropTypes.func,
};
