import React from 'react';
import Page from "../../../../components/BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons"
import styled from "styled-components";
import {swal, swalError, swalInput, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import ProdottiFoodAPI from "../../../../api/ModuloFood/ProdottiFoodAPI";
import FoodDeliveryProductCard from "../../../../components/FoodDelivery/FoodDeliveryProductCard";
import ProdottoDetail from "../../../../components/MenuDigitale/ProdottoDetail";
import ScegliTavoloModal from "./ScegliTavoloModal";
import i18n from "../../../../i18n";
import SaleAPI from "../../../../api/SaleAPI";
import NegozioAPI from "../../../../api/NegozioAPI";
import copertiIcon from "../../../../img/illustrations/coperti.svg";
import ComandeAPI from "../../../../api/ComandeAPI";
import FoodShoppingCart from "../../../../components/FoodDelivery/FoodShoppingCart";
import ProdottiAggiuntePage from "../../../../components/FoodDelivery/ProdottiAggiuntePage";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import AppModalPortal from "../../../../components/BaseComponents/AppModalPortal";
import ModalPortal from "../../../../components/BaseComponents/ModalPortal";
import MenuCategoryGalleryPage from "../../../../components/MenuDigitale/MenuCategoryGalleryPage";
import FlagDropdown from "../../../../components/BaseComponents/FlagDropdown/FlagDropdown";
import {MenuDigitaleSearchBar} from "../../../../components/MenuDigitale/MenuDigitaleSearchBar";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import ComandeShoppingCart from "./ComandeShoppingCart";
import {AdminAPI} from "../../../../api/Admin/AdminAPI";


export default class ComandaModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sale: null,
            prodotti: null,
            cartProducts: [],
            prodotto_open: null,
            editingProduct: null,
            searchQuery: '',
            orderMenuOpen: false,
            id_tavolo: '',
            id_servizio: '',
            note: '',
            negozio: null,
            lang: i18n.language || 'it',

            categoryTree: [],
            categoryOpen: [],
            categoryLeaf: null,

            permissions: null,
        };
    }

    componentDidMount() {
        this.fetchNewData();
    }

    fetchNewData() {
        swalLoading("Attendere prego...");
        var promiseList = [];
        promiseList.push(SaleAPI.getSaleShop(this.props.id_negozio))
        promiseList[0].then(sale => this.setState({sale: sale}));

        promiseList.push(ProdottiFoodAPI.list(i18n.language, this.props.id_negozio));
        promiseList[1].then((prodotti) => this.setState({prodotti: prodotti.filter(p => p.abilita_ordine === "1")}));

        promiseList.push(ProdottiFoodAPI.listCategorie(i18n.language, this.props.id_negozio));
        promiseList[2].then((categorie) => this.setState({categoryTree: categorie}));

        promiseList.push(NegozioAPI.getInfo(this.props.id_negozio));
        promiseList[3].then((negozio) => this.setState({negozio: negozio}));

        promiseList.push(AdminAPI.getPermissions());
        promiseList[4].then(permissions => this.setState({permissions: permissions}));

        Promise.all(promiseList).then(swal.close);
    }

    onLanguageChange = lang => {
        this.setState({lang: lang}, _ => {
            this.fetchNewData();
        });
    }


    /*
     * Category navigation
     * */
    handleCategoriesDismiss = _ => this.setState({id_servizio: ''});

    handleCategoryChoose = categoria => {
        if (!this.state.categoryOpen.find(c => c.id_categoria === categoria.id_categoria)) {
            this.setState({categoryOpen: [...this.state.categoryOpen, categoria]});
        }
    }

    handleCategoryDismiss = categoria => _ => {
        let categories = this.state.categoryOpen.slice();
        categories = categories.filter(c => c.id_categoria !== categoria.id_categoria);
        this.setState({categoryOpen: categories});
    }

    handleCategoryLeafChoose = categoria => this.setState({categoryLeaf: categoria});

    /**
     * Product navigation
     * */
    handleProdottiDismiss = _ => {
        this.setState({categoryLeaf: null, searchQuery: ''});
    }

    openDettaglio = prodotto => _ => {
        this.setState({prodotto_open: prodotto});
    }

    closeDettaglio = _ => {
        this.setState({prodotto_open: null});
    }

    handleProductEdit = product => this.setState({editingProduct: product});

    closeProductEdit = _ => this.setState({editingProduct: null});


    /**
     * SEARCH AND ORDERINGS
     * */

    handleSearch = (queryString, sortType) => {
        this.setState({searchQuery: queryString, orderType: sortType});
    }

    filterProductSearch = p => {
        const query = this.state.searchQuery.toLowerCase();
        if (!query) {
            return true;
        }
        return p.nome.toLowerCase().indexOf(query) > -1;
    }

    filterProductCategory = p => {
        return p.id_categoria === this.state.categoryLeaf.id_categoria;
    }

    sortProducts = (p1, p2) => {
        console.log("SORT CALLED");
        switch (this.state.orderType) {
            case 'alpha_asc':
                return p1.nome < p2.nome ? -1 : 1;
            case 'alpha_desc':
                return p1.nome > p2.nome ? -1 : 1;
            case 'price_asc':
                return parseFloat(p1.prezzo) < parseFloat(p2.prezzo) ? -1 : 1;
            case 'price_desc':
                return parseFloat(p1.prezzo) > parseFloat(p2.prezzo) ? -1 : 1;
            default:
                return 0
        }
    }

    /**
     * @description Effettua una ricerca in tutte le categorie figlie di "id_categoria_parent" in base ai dati di ricerca
     * @return Array | Null */
    handleSearchInCategory = (queryString, sortType, childList) => {
        if (!queryString) {
            return null;
        }

        const idLeaf = this.getLeafChilds(childList);
        let prodotti = this.state.prodotti
            .filter(
                p => idLeaf.find(id_categoria => id_categoria === p.id_categoria)
            )
            .filter(
                p => p.nome.toLowerCase().indexOf(queryString.toLowerCase()) > -1
            )
            .sort((p1, p2) => {
                switch (sortType) {
                    case 'alpha_asc':
                        return p1.nome < p2.nome ? -1 : 1;
                    case 'alpha_desc':
                        return p1.nome > p2.nome ? -1 : 1;
                    case 'price_asc':
                        return parseFloat(p1.prezzo) < parseFloat(p2.prezzo) ? -1 : 1;
                    case 'price_desc':
                        return parseFloat(p1.prezzo) > parseFloat(p2.prezzo) ? -1 : 1;
                    default:
                        return 0
                }
            });
        return prodotti;
    }

    getLeafChilds = childList => {
        let idLeafs = [];
        for (let categoria of childList) {
            if (!categoria.child.length) {
                idLeafs.push(categoria.id_categoria);
            } else {
                idLeafs = [...idLeafs, ...this.getLeafChilds(categoria.child)];
            }
        }
        return idLeafs;
    }

    productCardSearchResultGenerator = (prodotto) =>
        <FoodDeliveryProductCard
            primaryColor={this.state.negozio.aspetto.primaryColor}
            textOnPrimaryColor={this.state.negozio.aspetto.text_on_primary_color}
            secondaryColor={this.state.negozio.aspetto.secondary_color}
            textOnSecondaryColor={this.state.negozio.aspetto.text_on_secondary_color}
            bgMain={this.state.negozio.aspetto.bg_main}
            key={prodotto.id_prodotto}
            {...prodotto}
            onAddToCart={this.handleAddToCart}
            onDetail={this.openDettaglio(prodotto)}
            abilitaUscita={true}
        />


    /**
     * GESTIONE CARRELLO
     * */

    handleAddToCart = productData => {
        var trovato = false;
        var products = this.state.cartProducts.slice();
        products.map((p, i) => {
            if (this.areCartProductEqual(p, productData)) {
                trovato = true;
                //Verifico che ci sia disponibilità
                /* I prodotti possono essere con aggiunte, la disponibilità però è unica */
                let prodotto = this.state.prodotti.find(pp => pp.id_prodotto === p.id_prodotto); //Recupero disponibilità prodotto
                let quantitaInCarrello = this.state.cartProducts.filter(pc => pc.id_prodotto === productData.id_prodotto).reduce((acc, pp) => pp.quantita + acc, 0);
                if (quantitaInCarrello + productData.quantita > prodotto.quantita) {
                    swalError(`Ci sono solamente ${prodotto.quantita} ${prodotto.nome} disponibili`);
                    return;
                }
                products[i].quantita += productData.quantita;
                products[i].uscita = productData.uscita;
                if (productData.note) {
                    products[i].note += `\n${productData.note}`;
                }
            }
        });
        if (!trovato) {
            //Verifico che ci sia disponibilità
            /* I prodotti possono essere con aggiunte, la disponibilità però è unica */
            let prodotto = this.state.prodotti.find(pp => pp.id_prodotto === productData.id_prodotto); //Recupero disponibilità prodotto
            let quantitaInCarrello = this.state.cartProducts.filter(pc => pc.id_prodotto === productData.id_prodotto).reduce((acc, pp) => pp.quantita + acc, 0);
            if (quantitaInCarrello + productData.quantita > prodotto.quantita) {
                swalError(`Ci sono solamente ${prodotto.quantita} ${prodotto.nome} disponibili`);
                return;
            }
            productData.listaAggiunte = prodotto.aggiunte; //Nei dati del prodotto in carrello passo anche la lista di tutte le aggiunte
            productData.listaOpzioni = prodotto.opzioni; //Nei dati del prodotto in carrello passo anche la lista di tutte le opzioni
            products.push(productData);
        }
        this.setState({cartProducts: products});
    }

    handleUpdateCart = cartData => {
        var products = this.state.cartProducts.slice();
        products.map((p, i) => {
            if (this.areCartProductEqual(p, cartData.prodotto)) {
                //Verifico che ci sia disponibilità
                /* I prodotti possono essere con aggiunte, la disponibilità però è unica */
                let prodotto = this.state.prodotti.find(pp => pp.id_prodotto === cartData.prodotto.id_prodotto); //Recupero disponibilità prodotto
                if (cartData.quantita > prodotto.quantita) {
                    swalError(`Ci sono solamente ${prodotto.quantita} ${prodotto.nome} disponibili`);
                    return;
                }
                products[i].quantita = cartData.quantita;
                products[i].note = cartData.note;
                products[i].uscita = cartData.uscita;
            }
        });
        products = products.filter(p => p.quantita > 0);//-1 significa che il prodotto è da eliminare, 0 che non lo vuole più
        this.setState({cartProducts: products});
    }

    handleUpdateAggiunte = (id_opzione, aggiunte, rimozioni, attributi, note, uscita) => {
        var products = this.state.cartProducts.slice();
        products.map((p, i) => {
            if (this.areCartProductEqual(p, this.state.editingProduct)) {
                products[i].id_opzione = id_opzione;
                products[i].aggiunte = aggiunte;
                products[i].rimozioni = rimozioni;
                products[i].attributi = attributi;
                products[i].note = note;
                products[i].uscita = uscita;
            }
        });
        this.setState({cartProducts: products, editingProduct: null});
    }

    areCartProductEqual = (p1, p2) => {
        if (p1.id_prodotto !== p2.id_prodotto) {
            return false;
        }

        if (p1.id_opzione !== p2.id_opzione) {
            return false;
        }

        if ((p1.aggiunte && !p2.aggiunte) || (!p1.aggiunte && p2.aggiunte)) { //XOR
            return false;
        }

        if (p1.aggiunte && p2.aggiunte && p1.aggiunte.length !== p2.aggiunte.length) {
            return false;
        }

        if ((p1.rimozioni && !p2.rimozioni) || (!p1.rimozioni && p2.rimozioni)) { //XOR
            return false;
        }

        if (p1.rimozioni && p2.rimozioni && p1.rimozioni.length !== p2.rimozioni.length) {
            return false;
        }

        if ((p1.attributi && !p2.attributi) || (!p1.attributi && p2.attributi)) {
            return false;
        }

        if (p1.attributi && p2.attributi && Object.keys(p1.attributi).length !== Object.keys(p2.attributi).length) {
            return false;
        }

        for (var i in p1.aggiunte) {
            if (!p2.aggiunte.find(id_aggiunta2 => id_aggiunta2 === p1.aggiunte[i])) {
                return false;
            }
        }

        for (var i in p1.rimozioni) {
            if (p2.rimozioni.find(rimozione => rimozione === p1.rimozioni[i]) === undefined) {
                return false;
            }
        }

        for (var i in p1.attributi) {
            if (!Object.keys(p2.attributi).find(nomeAttributo => p2.attributi[nomeAttributo] === p1.attributi[nomeAttributo])) {
                return false;
            }
        }

        return true;
    }

    handleCheckout = _ => {
        this.handlePlaceOrder(this.state);
    }

    /*
    * GESTIONE DEI TAVOLI/SALE
    * */

    getTavolo = id_tavolo => {
        if (id_tavolo === 0) return {id_servizio: 0};
        for (let sala of this.state.sale.slice()) {
            for (let tavolo of sala.tavoli) {
                if (tavolo.id_tavolo === id_tavolo) {
                    return tavolo;
                }
            }
        }
        return null;
    }

    handleTavoloChoose = async id_tavolo => {
        const tavolo = this.getTavolo(id_tavolo);
        //id_servizio pari a 0 indica un ordine al banco
        if (tavolo.id_servizio === 0 || tavolo.id_servizio) { //Se il tavolo ha già un servizio attivo allora imposto già lo state
            this.setState({id_tavolo: id_tavolo, id_servizio: tavolo.id_servizio});
        } else { //Altrimenti richiedo all'utente il numero di coperti e faccio iniziare il servizio
            await swalInput(
                <div className={"text-center"}>
                    <img src={copertiIcon} className={"mb-2"} height={100} alt={"Persone al tavolo"}/>
                    <br/>Seleziona il numero di coperti
                </div>,
                "coperti", "number", "none", false, true
            )
                .then(result => {
                    if (result.value) {
                        ComandeAPI.iniziaServizio(id_tavolo, result.value)
                            .then(id_servizio => {
                                let sale = this.state.sale.slice();
                                for (let i = 0; i < sale.length; i++) {
                                    for (let j = 0; j < sale[i].tavoli.length; j++) {
                                        if (sale[i].tavoli[j].id_tavolo === id_tavolo) {
                                            sale[i].tavoli[j].id_servizio = id_servizio;
                                            sale[i].tavoli[j].coperti = result.value;
                                            break;
                                        }
                                    }
                                }
                                console.log(sale, this.state.sale);
                                this.setState({id_tavolo: id_tavolo, id_servizio: id_servizio, sale: sale});
                            }).catch(swalError)
                    }
                })
        }
    }


    /**
     * ORDER MANAGEMENT
     * */
    handleSetNote = _ => {
        swalInput('Note comanda', "Inserisci note della comanda", "textarea",'',true,true,this.state.note)
            .then(({value}) => {
                this.setState({note: value});
            });
    }

    handlePlaceOrder = state => {
        swalLoading();
        ComandeAPI.placeOrder(
            state.cartProducts,
            state.id_servizio,
            state.note,
        ).then(response => {
            swalSuccess(response.message);
            this.setState({
                prodotti: null,
                categoryTree: null,
                categoryOpen: [],
                id_tavolo: '',
                id_servizio: '',
                categoryLeaf: null,
                cartProducts: [],
                note: ''
            });
            this.props.onDismiss();
            this.handleProdottiDismiss();
        }).catch(swalError);
    }

    render() {
        const Portal = document.getElementById('app-modal-root') ? AppModalPortal : ModalPortal;
        /* La pagina principale del menù è una categoria creata in modo fittizio */
        const tempMainCategory = {
            nome: "Comande",
            child: this.state.categoryTree
        }
        if (!this.state.negozio) {
            return '';
        }
        return (
            <div>

                <ScegliTavoloModal
                    onDismiss={this.props.onDismiss}
                    active={this.props.active}
                    availableOrderTypes={this.AVAILABLE_ORDER_TYPES}
                    onChoose={this.handleTavoloChoose}
                    sale={this.state.sale}
                    permissions={this.state.permissions}
                />

                {
                    (!!this.state.id_servizio || this.state.id_servizio === 0) && <MenuCategoryGalleryPage
                        category={tempMainCategory}
                        onDismiss={this.handleCategoriesDismiss}
                        onCategoryChoose={this.handleCategoryChoose}
                        onCategoryLeafChoose={this.handleCategoryLeafChoose}
                        onSearch={this.handleSearchInCategory}
                        onProductDetailOpen={this.openDettaglio}
                        hideEmptyCategory={true}
                        productList={this.state.prodotti}
                        searchResultItemGenerator={this.productCardSearchResultGenerator}
                        rightIcon={<FlagDropdown className={"bg-main-z3 px-2 py-1 rounded"} default={"it"}
                                                 onChange={this.onLanguageChange}/>}
                    />
                }

                {/* Stampo tutte le successive pagine di categorie */}
                {
                    this.state.categoryOpen.map((c, i) =>
                        <MenuCategoryGalleryPage
                            key={i}
                            category={c}
                            onDismiss={this.handleCategoryDismiss(c)}
                            onCategoryChoose={this.handleCategoryChoose}
                            onCategoryLeafChoose={this.handleCategoryLeafChoose}
                            onSearch={this.handleSearchInCategory}
                            onProductDetailOpen={this.openDettaglio}
                            hideEmptyCategory={true}
                            productList={this.state.prodotti}
                            searchResultItemGenerator={this.productCardSearchResultGenerator}
                            rightIcon={<FlagDropdown className={"bg-main-z3 px-2 py-1 rounded"} default={"it"}
                                                     onChange={this.onLanguageChange}/>}
                        />
                    )
                }


                <Page
                    title={"Prodotti"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    leftIconText={"Categorie"}
                    onLeftIconClick={this.handleProdottiDismiss}
                    active={!!this.state.categoryLeaf}

                    content={
                        <div>
                            <div className={"container pt-2"}>
                                <MenuDigitaleSearchBar
                                    onSearch={this.handleSearch}
                                />
                                {
                                    this.state.prodotti === null || !this.state.categoryLeaf ?
                                        <div className={"text-center"}>Caricamento prodotti</div>
                                        :
                                        this.state.prodotti
                                            .filter(this.filterProductCategory)
                                            .filter(this.filterProductSearch)
                                            .sort(this.sortProducts)
                                            .map((prodotto, i) => {
                                                return (
                                                    <FoodDeliveryProductCard
                                                        primaryColor={this.state.negozio.aspetto.primaryColor}
                                                        textOnPrimaryColor={this.state.negozio.aspetto.text_on_primary_color}
                                                        secondaryColor={this.state.negozio.aspetto.secondary_color}
                                                        textOnSecondaryColor={this.state.negozio.aspetto.text_on_secondary_color}
                                                        bgMain={this.state.negozio.aspetto.bg_main}
                                                        key={i}
                                                        {...prodotto}
                                                        onAddToCart={this.handleAddToCart}
                                                        onDetail={this.openDettaglio(prodotto)}
                                                        abilitaUscita={true}
                                                    />
                                                )
                                            })
                                }
                            </div>

                        </div>
                    }
                />

                <ComandeShoppingCart
                    floatingCartColor={this.state.negozio.aspetto.primary_color}
                    floatingIconVisible={(!!this.state.id_servizio || this.state.id_servizio === 0) && !this.state.orderDetailOpen}
                    cartVisible={!!this.state.id_servizio || this.state.id_servizio === 0}
                    cartProducts={this.state.cartProducts}
                    onUpdateCart={this.handleUpdateCart}
                    onContinue={this.handleCheckout}
                    onProductEdit={this.handleProductEdit}
                    onContinueText={"Invia comanda"}
                    additionalFooter={
                        <button className={"btn btn-block btn-secondary"} onClick={this.handleSetNote}>
                            Aggiungi note comanda
                        </button>
                    }
                />

                <ProdottoDetail
                    {...this.state.prodotto_open}
                    active={!!this.state.prodotto_open}
                    onDismiss={this.closeDettaglio}
                />

                <ModalPortalHOC>
                    {
                        !!this.state.editingProduct &&
                        <ProdottiAggiuntePage
                            active={!!this.state.editingProduct}
                            onDismiss={this.closeProductEdit}
                            onChoose={this.handleUpdateAggiunte}
                            title={this.state.editingProduct.nome}
                            subtitle={this.state.editingProduct.ingredienti &&
                            <SafeHtmlContainer html={`Ingredienti: ${this.state.editingProduct.ingredienti}`}/>
                            }
                            opzioni={this.state.editingProduct.listaOpzioni}
                            aggiunte={this.state.editingProduct.listaAggiunte}
                            cottura={this.state.editingProduct.attributi.cottura !== undefined ? 1 : 0}
                            abilitaRimozioni={parseInt(this.state.editingProduct.abilitaRimozioni) === 1}
                            abilitaNote={parseInt(this.state.editingProduct.abilitaNote) === 1}
                            startOpzione={this.state.editingProduct.id_opzione}
                            startAggiunte={this.state.editingProduct.aggiunte}
                            startRimozioni={this.state.editingProduct.rimozioni}
                            startAttributi={this.state.editingProduct.attributi}
                            startNote={this.state.editingProduct.note}
                            continueButtonText={"Salva modifiche"}
                        />
                    }
                </ModalPortalHOC>

            </div>
        );
    }
}

ComandaModal.propTypes = {
    onDismiss: PropTypes.func,
};
