import React, {Fragment} from "react";
import IndirizziAPI, {INDIRIZZI_TYPE_NORMAL} from "../../../api/IndirizziAPI";
import {MARKETPLACE_SHIPPING_ADDRESS_SELECTED} from "../../../const/AppEventsCostants";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {EllipsisLoader} from "../../GenericUI/EllipsisLoader/EllipsisLoader";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";
import PropTypes from "prop-types";
import AddAddressModal from "../../Modals/AddAddressModal";
import AddressBook from "../../AddressBook";
import {PageZLayerSelector} from "../../BaseComponents/Page/PageZLayerSelector";
import {Trans} from "react-i18next";
import i18n from "i18next";


export class EcommerceShippingAddressBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addressList: null,
            selected: null,
            addressChooserOpen: false,
            createAddressOpen: false
        }
    }

    componentDidMount() {
        IndirizziAPI.list(INDIRIZZI_TYPE_NORMAL)
            .then(list => {
                const predefined = list.find(i => parseInt(i.predefinito) === 1);
                this.setState({
                    addressList: list,
                    selected: predefined ? predefined.id_indirizzo : null
                });
                if (predefined) {
                    this.handleShippingAddressChoose({id_indirizzo: predefined.id_indirizzo, data: predefined});
                }
            });
    }

    getSelected = _ => {
        return this.state.addressList.find(i => i.id_indirizzo === this.state.selected);
    }

    toggleAddressCreate = _ => this.setState({createAddressOpen: !this.state.createAddressOpen});

    toggleAddressChooser = _ => this.setState({addressChooserOpen: !this.state.addressChooserOpen})

    handleShippingAddressChoose = ({id_indirizzo, data}) => {
        const exists = !!this.state.addressList.find(i => i.id_indirizzo === id_indirizzo);
        const addressList = exists ? [...this.state.addressList] : [...this.state.addressList, data];
        this.setState({
            addressList: addressList,
            selected: id_indirizzo,
            addressChooserOpen: false
        });
        this.props.onChange({id_indirizzo, data})
    }

    render() {

        if (!this.state.addressList) {
            return (
                <div className={"bg-white p-3 shadow-sm text-center"}>
                    <EllipsisLoader/>
                </div>
            )
        }

        const selected = this.getSelected();
        return (
            <Fragment>
                <div className={"bg-white p-3 shadow-sm"}>
                    {
                        !!selected &&
                        <div className={"d-flex align-items-center justify-content-between"}
                             onClick={this.toggleAddressChooser}>
                            <div className={"text-truncate mr-1"}>
                                <FaSolidIcon name={"map-marker-alt"}/>&nbsp;
                                <Trans i18nKey={"ecommerce.cart.checkout.shipping.ship-to-template"} values={{
                                    userName: `${selected.nome} ${selected.cognome}`,
                                    address: selected.indirizzo
                                }}>
                                    Consegna a <b>{selected.nome} {selected.cognome}</b>
                                    <span>{selected.indirizzo}</span>
                                </Trans>
                            </div>
                            <FaSolidIcon name={"chevron-right"}/>
                        </div>
                    }
                    {
                        !selected &&
                        <div className={"text-center"}>
                            <button className={"btn btn-link text-primary"} onClick={this.toggleAddressChooser}>
                                <FaSolidIcon
                                    name={"map-marker-alt"}/> {this.props.placeholder || i18n.t('ecommerce.cart.checkout.shipping.choose-btn')}
                            </button>
                        </div>
                    }
                </div>

                <ModalPortalHOC>
                    <PageZLayerSelector zIndex={2001}>
                        {
                            this.state.addressChooserOpen && <AddressBook
                                active={true}
                                title={i18n.t('ecommerce.cart.checkout.shipping.title')}
                                subtitle={this.props.placeholder || i18n.t('ecommerce.cart.checkout.shipping.choose-btn')}
                                onChoose={this.handleShippingAddressChoose}
                                onDismiss={this.toggleAddressChooser}
                                useFlag={false}
                                onCreateNew={this.toggleAddressCreate}
                            />
                        }
                        {
                            this.state.createAddressOpen &&
                            <AddAddressModal
                                invoiceData={false}
                                onDismiss={this.toggleAddressCreate}
                            />
                        }
                    </PageZLayerSelector>
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

EcommerceShippingAddressBar.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string || null,
}
