import React from "react";
import PropTypes from "prop-types";


/**
 * Questo HOC si occupa di ricevere in input la scroll position da parte del genitore e decidere in base alla propria
 * dimensione cosa se nascondere il proprio contenuto
 * */

const ELEMENT_DISTANCE_THRESHOLD = 300;

export class FeedPostCardHOC extends React.Component {

    constructor(props) {
        super(props);
        this.childHeight = 0;
        this.childPos = 0;
        this.childRef = null;
        this.wrapperRef = null;
        this.isHidden = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.scrollTop !== this.props.scrollTop) {
            if (this.wrapperRef && this.childRef) {
                const child = this.childRef;
                const wrapper = this.wrapperRef;

                //Aggiorno l'altezza del mio post se non è nascosto
                if (!this.isHidden){
                    this.childHeight = child.offsetHeight;
                    this.childPos = child.offsetTop;
                }

                const distanceFromTop = this.props.scrollTop - this.childPos - this.childHeight;

                if (distanceFromTop > ELEMENT_DISTANCE_THRESHOLD){
                    if (!this.isHidden) {
                        wrapper.style.height = `${this.childHeight}px`;
                        child.style.display = "none";
                        this.isHidden = true;
                    }
                }else{
                    child.style.display = null;
                    wrapper.style.height = null;
                    this.isHidden = false;
                }

            }
        }
    }

    getWrapperRef = ref => this.wrapperRef = ref;
    getChildRef = ref => this.childRef = ref;

    render() {
        return (
            <div ref={this.getWrapperRef}>
                <div ref={this.getChildRef}>
                    {this.props.children}
                </div>
            </div>
        )
    }

}

FeedPostCardHOC.propTypes = {
    scrollTop: PropTypes.number
}
