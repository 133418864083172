import React from "react";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import WalletAttivitaAPI from "../../api/Welfare/WalletAttivitaAPI";

export default class AttivitaWelfareTransactions extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            id_wallet: null,
            transactions: [],
            totalTransactions: 0,
            pageSize: 10,
            currentPage: 1
        }
    }

    fetchData = (limit, offset) => {
        WalletAttivitaAPI.getTransactions(this.props.negozio.walletapikey, limit, offset)
            .then((data) => {
                console.log(data);
                this.setState({id_wallet: data.id_wallet, transactions: data.transactions, totalTransactions: parseInt(data.total)});
            })
    }

    componentDidMount() {
        if (this.props.active) {
            this.fetchData(this.state.pageSize,
                (this.state.currentPage - 1) * this.state.pageSize);
        }
    }

    handlePageChange = page => {
        this.setState({currentPage: page});
        this.fetchData(this.state.pageSize,
            (page - 1) * this.state.pageSize);
    }

    render() {
        return (
            <div>
                {
                    this.state.transactions.length > 0 &&
                    <div>
                        {
                            this.state.transactions.map((item, i) => {
                                return <ul key={i} className={"list-group"}>
                                    {item.id_transazione &&
                                    <li className={"list-group-item list-group-item-action"}>
                                        <div className={"row align-items-center"}>
                                            <div className={"col-2"}>
                                                {
                                                    item.id_wallet_from === this.state.id_wallet
                                                        ?
                                                        <h4 className={'text-danger text-lg'}><i className={"fas fa-arrow-circle-up"}></i></h4>
                                                        :
                                                        <h4 className={'text-success text-lg'}><i className={"fas fa-arrow-circle-down"}></i></h4>
                                                }
                                            </div>
                                            <div className={"col-7"}>
                                                <b>{item.data_creazione}</b><br/>
                                                <span>{item.descrizione}</span><br/>
                                                <span className={"text-muted"}>{item.denominazione}</span>
                                            </div>
                                            <div className={"col-3"}>
                                                {
                                                    item.id_wallet_from === this.state.id_wallet
                                                        ?
                                                        <h6 className={'text-danger text-lg'}>€{item.importo}</h6>
                                                        :
                                                        <h6 className={'text-success text-lg'}>€{item.importo}</h6>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    }
                                </ul>
                            })
                        }
                    </div>
                }
                <div className={"justify-content-center d-flex pt-3"}>
                    {
                        this.state.transactions.length ?
                            <Pagination
                                activePage={this.state.currentPage}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalTransactions}
                                pageRangeDisplayed={5}
                                itemClass="page-item"
                                linkClass="page-link"
                                onChange={this.handlePageChange}
                            /> :
                            <div className={"container"}>
                                <div className={"mt-3 text-center text-on-bg-main"}>
                                    Non ci sono transazioni
                                </div>
                            </div>

                    }
                </div>
            </div>
        );
    }
}