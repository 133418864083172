import PropTypes from "prop-types";
import React from "react";

export default class WalletDescription extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                {
                    (this.props.payable_categories.length > 0 || this.props.payable_items.length > 0) &&
                    <div>
                        {/*Categorie pagabili*/}
                        {this.props.payable_categories.map((c, i) => {
                            return <div key={i}>
                                La categoria: <b>{c.nome}</b>
                            </div>
                        })}

                        {/*Items pagabili*/}
                        {this.props.payable_items.map((item, i) => {
                            return <div key={i}>
                                L'elemento: <b>{item.nome}</b>
                            </div>
                        })}
                    </div>
                }
            </div>
        )
    }
}

WalletDescription.propTypes = {
    payable_categories: PropTypes.array,
    payable_items: PropTypes.array,
}