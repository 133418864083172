import React from "react";
import UserStatus from "./UserStatus";
import {FaRegularIcon, FaSolidIcon} from "../../components/FontAwesomeIcons";
import LoginButtonGroup from "../LoginButtonGroup";
import AppConfig from "../../config/AppConfig";
import ImpostazioniPage from "./Impostazioni/ImpostazioniPage";
import UserProfileModal from "../../components/UserInfo/UserProfileModal";
import i18n from "../../i18n";
import FidelityCardsModal from "./FidelityCard/FidelityCardsModal";
import PopUpNews from "../PopUpNews/PopUpNews";
import ModalPortal from "../../components/BaseComponents/ModalPortal";
import NovitaUsersAPI from "../../api/NovitaUsersAPI";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import styled from "styled-components";
import {AreaLegalePage} from "./AreaLegalePage";
import {ServizioClientiPage} from "./ServizioClientiPage";
import {CronologiaUserPage} from "../CronologiaUser/CronologiaUserPage";
import DipendenteWelfareModal from "../WelfareDipendente/DipendenteWelfareModal";
import ModalPortalHOC from "../../components/BaseComponents/ModalPortalHOC";
import FlagDropdown from "../../components/BaseComponents/FlagDropdown/FlagDropdown";
import WalletsPage from "../../components/Wallets/WalletsPage";
import {WaitingListPage} from "../WaitingLIst/WaitingListPage";

const LanguageSelectorWrapper = styled.div`
    position:absolute;
    top:20px;
    right:20px;
`;


export default class LauncherSideMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            settingsOpen: false,
            userProfileOpen: false,
            userCronologiaOpen: false,
            dipendenteWelfareModalOpen: false,
            fidelityOpen: false,
            walletsOpen: false,
            popUpNews: false,
            numberOfNews: null,
            areaLegaleOpen: false,
            servizioClientiOpen: false,
            waitingListPageOpen: false
        }
    }

    componentDidMount() {
        if (this.props.user) {
            NovitaUsersAPI.countNewsVisible()
                .then(newsVisible => this.setState({numberOfNews: newsVisible}));
        }
        FuxEvents.on("OPEN_PROFILE_PAGE", this.toggleUserProfile);
    }

    componentWillUnmount() {
        FuxEvents.off("OPEN_PROFILE_PAGE", this.toggleUserProfile);
    }

    toggleSettings = _ => this.setState({settingsOpen: !this.state.settingsOpen});
    toggleUserProfile = _ => this.setState({userProfileOpen: !this.state.userProfileOpen});
    toggleUserCronologia = _ => this.setState({userCronologiaOpen: !this.state.userCronologiaOpen});
    toggleDipendenteWelfareModal = _ => this.setState({dipendenteWelfareModalOpen: !this.state.dipendenteWelfareModalOpen});
    toggleFidelityCard = _ => this.setState({fidelityOpen: !this.state.fidelityOpen});
    toggleWallets = _ => this.setState({walletsOpen: !this.state.walletsOpen});
    togglePopUpNews = _ => this.setState({popUpNews: !this.state.popUpNews});
    toggleAreaLegale = _ => this.setState({areaLegaleOpen: !this.state.areaLegaleOpen});
    toggleServizioClienti = _ => this.setState({servizioClientiOpen: !this.state.servizioClientiOpen});
    toggleWaitingListPage = _ => this.setState({waitingListPageOpen: !this.state.waitingListPageOpen});


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.active && !prevProps.active) {
            document.body.classList.add('noscroll');
        }
        if (!this.props.active && prevProps.active) {
            document.body.classList.remove('noscroll');
        }
    }

    numberInBadge = _ => {
        if (this.props.nOfRecensioniInSospeso > 9)
            return "9+"
        return this.props.nOfRecensioniInSospeso
    }

    handleLangChange = lang => {
        FuxEvents.emit('changeLang', lang);
    }

    render() {
        return (
            <div>
                <div className={"launcher-menu-backdrop " + (this.props.active ? 'active __androidDismissable__' : '')}
                     onClick={this.props.onMenuToggle}>&nbsp;</div>
                <div className={"launcher-menu pb-4 d-flex flex-column " + (this.props.active ? 'active shadow' : '')}>
                    <LanguageSelectorWrapper>
                        <FlagDropdown
                            className={"px-2 py-1 bg-white shadow rounded"}
                            default={"it"}
                            onChange={this.handleLangChange}
                        />
                    </LanguageSelectorWrapper>
                    {
                        this.props.user &&
                        <React.Fragment>
                            <UserStatusWrapper sesso={this.props.user.sesso}
                                               className={"p-5 d-flex align-items-center justify-content-center"}>
                                <UserStatus
                                    user={this.props.user}
                                    allowEdit={false}
                                    showLang={true}
                                />
                            </UserStatusWrapper>
                            <div className={"container mb-3"}>
                                <ul className={"list-group shadow"}>
                                    <li className={"list-group-item list-group-item-action"}
                                        onClick={this.toggleUserProfile}>
                                        <FaRegularIcon name={"user-circle"}/> {i18n.t("launcher.side-menu.profilo")}
                                    </li>
                                    <li className={"list-group-item list-group-item-action"}
                                        onClick={this.toggleUserCronologia}>
                                        <FaSolidIcon name={"history"}/> {i18n.t("launcher.side-menu.cronologia")}
                                        {
                                            this.props.nOfRecensioniInSospeso > 0 &&
                                            <span className="ml-1 text-white position-absolute top-0 translate-middle
                                            badge rounded-pill bg-success">
                                                {this.numberInBadge()}
                                          </span>
                                        }
                                    </li>
                                    <li className={"list-group-item list-group-item-action"}
                                        onClick={this.toggleWaitingListPage}>
                                        <FaSolidIcon name={"hourglass-half"}/> {i18n.t("launcher.side-menu.lista-attesa")}
                                    </li>
                                    <li className={"list-group-item list-group-item-action"}
                                        onClick={this.toggleSettings}>
                                        <FaSolidIcon name={"cogs"}/> {i18n.t("launcher.side-menu.impostazioni")}
                                    </li>

                                    {/**
                                     OSCURATO AL MOMENTO PERCHÈ QUELLI DEL WELFARE SI CONFONDONO CON LE GIFT INTERNE
                                     <li className={"list-group-item list-group-item-action"} onClick={this.toggleWallets}>
                                     <FaSolidIcon name={"wallet"}/> {i18n.t("launcher.side-menu.wallets")}
                                     </li>
                                     **/}

                                    <li className={(!this.props.user.walletapikey && 'd-none') + " list-group-item list-group-item-action"}
                                        onClick={this.toggleDipendenteWelfareModal}>
                                        <FaSolidIcon
                                            name={"id-card"}/> {i18n.t("launcher.side-menu.dipendenteWelfareModal")}
                                    </li>
                                    <li className={"list-group-item list-group-item-action"}
                                        onClick={this.toggleFidelityCard}>
                                        <FaSolidIcon name={"heart"}/> {i18n.t("launcher.side-menu.fidelity-card")}
                                    </li>
                                    <li className={(!parseInt(this.state.numberOfNews) && 'd-none') + " list-group-item list-group-item-action"}
                                        onClick={this.togglePopUpNews}>
                                        <FaSolidIcon name={"bullhorn"}/> {i18n.t("launcher.side-menu.news")}
                                    </li>
                                </ul>
                            </div>
                        </React.Fragment>
                    }

                    {/* Spaziatura senza utente loggato*/}
                    {
                        !this.props.user && <div className={"mb-5 mt-5"}/>
                    }
                    <div className={"container mb-3"}>
                        <ul className={"list-group shadow"}>
                            <li className={"list-group-item list-group-item-action"} onClick={this.toggleAreaLegale}>
                                <FaSolidIcon name={"gavel"}/> {i18n.t("launcher.side-menu.legal-area")}
                            </li>
                            <li className={"list-group-item list-group-item-action"}
                                onClick={this.toggleServizioClienti}>
                                <FaSolidIcon name={"headphones-alt"}/> {i18n.t("launcher.side-menu.customer-care")}
                            </li>
                        </ul>
                    </div>

                    <div className={"mt-auto py-3"}>
                        <img src={AppConfig.webServerUrl + "/public/img/logo_total_white.svg"}
                             className={"launcher-menu-logo mx-auto"}/>
                    </div>

                </div>

                {
                    this.state.userProfileOpen &&
                    <UserProfileModal user={this.props.user} active={this.state.userProfileOpen}
                                      onDismiss={this.toggleUserProfile}/>
                }

                {this.state.walletsOpen && <WalletsPage onDismiss={this.toggleWallets}/>}

                {this.state.areaLegaleOpen && <AreaLegalePage onDismiss={this.toggleAreaLegale}/>}
                {this.state.servizioClientiOpen && <ServizioClientiPage onDismiss={this.toggleServizioClienti}/>}

                {
                    !!this.props.user &&
                    <ModalPortalHOC>
                        <React.Fragment>
                            <ImpostazioniPage apps={this.props.apps} active={this.state.settingsOpen}
                                              onDismiss={this.toggleSettings} user={this.props.user}/>
                            {
                                this.state.userCronologiaOpen &&
                                <CronologiaUserPage
                                    idNegozio={null}
                                    onDismiss={this.toggleUserCronologia}
                                    nOfRecensioniInSospeso={this.props.nOfRecensioniInSospeso}
                                    onAppOpen={this.props.onAppOpen}
                                />
                            }
                            {
                                this.state.popUpNews &&
                                <PopUpNews fromSideMenu={true} onDismiss={this.togglePopUpNews}/>
                            }
                            {
                                this.state.fidelityOpen &&
                                <FidelityCardsModal onDismiss={this.toggleFidelityCard}/>
                            }

                            {
                                this.state.dipendenteWelfareModalOpen &&
                                <DipendenteWelfareModal
                                    user={this.props.user}
                                    active={true}
                                    onDismiss={this.toggleDipendenteWelfareModal}/>
                            }
                            {
                                this.state.waitingListPageOpen &&
                                <WaitingListPage
                                    onDismiss={this.toggleWaitingListPage}
                                />
                            }
                        </React.Fragment>
                    </ModalPortalHOC>
                }
            </div>
        );
    }

}


const UserStatusWrapper = styled.div`
    background-color: ${props => props.sesso == 0 ? '#d484b4' : '#293069'};
    margin-bottom: -25px;
    color:white;
`;
