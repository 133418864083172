import React from "react";
import {ModuloCassaAPI} from "../../../../../api/ModuloCassa/ModuloCassaAPI";
import PropTypes from "prop-types";
import KeypadInterface from "./KeypadInterface/KeypadInterface";

export default class FavoriteItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            items: []
        }
        this.itemsStyle = {
            backgroundColor: "white",
            minHeight: "60px",
            minWidth: "130px",
            fontSize: "12px",
            cursor: "pointer",
            lineHeight: "15px"
        }
        this.priceStyle = {
            color: "#898d8d"
        }
    }

    componentDidMount() {
        this.fetchFavoriteItems();
    }

    fetchFavoriteItems() {
        this.setState({fetching: true})
        ModuloCassaAPI.Dashboard.getFavoriteItems()
            .then(items => {
                this.setState({items: items, fetching: false})
            })
    }

    handleItemClick = item => {
        this.props.onItemClick({
            descrizione: item.nome,
            importo_unitario: parseFloat(item.prezzo) || 0,
            quantita: 1,
            codice_aliquota: item.codice_aliquota,
            sconto: "",
            sconto_documento: ""
        })
    }


    render() {
        if (this.state.fetching)
            return <i className="fa fa-spin fa-spinner"/>

        if (this.state.items.length === 0) return ""

        return (
            <div className={"d-flex __disable-swipe"} style={{maxWidth: "100vw", overflowX: "scroll"}}>
                {
                    this.state.items.map((item, i) => {
                        const bgStripColor = item.css_class && item.css_class !== "default" ? item.css_class : "#293069"
                        const itemStripStyle = {
                            height: "100%",
                            minWidth: "2px",
                            backgroundColor: bgStripColor
                        }

                        return <div className={"d-flex text-left m-1 shadow-sm"} style={this.itemsStyle} key={i}
                                    onClick={_ => this.handleItemClick(item)}>
                            <div style={itemStripStyle}></div>
                            <div className={"p-1"}>
                                {item.nome}
                                <div className={"pt-1 text-muted"}
                                     style={this.priceStyle}>{parseFloat(item.prezzo || "0").toFixed(2)}€
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        )
    }
}


FavoriteItems.propTypes = {
    onItemClick: PropTypes.func
}