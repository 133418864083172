/* global AndroidInterface, webkit */
import React, {Fragment, Suspense} from 'react';
import PropTypes from 'prop-types'
import NegozioAPI from "../api/NegozioAPI";
import AppConfig, {BODY_BACKGROUND} from "../config/AppConfig";
import {swal, swalError, swalLoading, swalSuccess} from "../helpers/SweetAlertWrapper";
import FuxEvents from "../lib/FuxFramework/FuxEvents";
import AuthAPI from "../api/AuthAPI";
import LoginModal from "../components/Modals/LoginModal";
import RegisterModal from "../components/Modals/RegisterModal";
import PasswordRecoveryModal from "../components/Modals/PasswordRecoveryModal";
import LauncherSideMenu from "./SideMenu/LauncherSideMenu";
import LauncherAppListContainer from "./AppList/LauncherAppListContainer";
import LauncherNextBooksList from "./NextBooks/LauncherNextBooksList";
import LauncherNavbar from "./LauncherNavbar";
import UsersShopsAPI from "../api/UsersShopsAPI";
import LoginButtonGroup from "./LoginButtonGroup";
import Store from "../components/Store";
import BookingAPI from "../api/BookingAPI";
import styled from 'styled-components';
import PopUpNews from "./PopUpNews/PopUpNews";

//CSS
import './css/launcher.css';
import './css/LauncherAppList.css';
import './css/LauncherNextBooksDetails.css';
import './css/LauncherNextBooksList.css';
import 'animate.css/animate.min.css'
import OrdiniAPI from "../api/OrdiniAPI";
import HomepageTab from "./Tabs/HomepageTab";
import NotificationTab from "./Tabs/NotificationTab";
import FeedTab from "./Tabs/FeedTab/FeedTab";
import {BookizonAppManager} from "../index";
import BookizonSplashScreen from "./BookizonSplashScreen";
import {
    ADD_APP_REQUEST,
    APP_FAVOURITE_ADDED,
    APP_FAVOURITE_REMOVED,
    CLOSE_APP_REQUEST,
    LINKED_APPS_CHANGE,
    NEW_FEEDBACK_WRITTEN,
    AUTHENTICATED_USER_DATA_CHANGE,
    PROMOTION_CLICKED,
    OPEN_APP_REQUEST, OPEN_PROMOTIONS_TAB,
    UPDATE_USER_BOOKS, UPDATE_USER_HOTEL_BOOKS,
    UPDATE_USER_NOTIFICATIONS,
    UPDATE_USER_ORDINI,
    USERS_CONVERSION_PARTNERHIP, UPDATE_USER_SEAT_BOOKS
} from "../const/AppEventsCostants";
import {BookizonAppContainer} from "../router/BookizonAppContainer";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";
import NotificheAPI from "../api/NotificheAPI";
import {UserDataStore} from "../stores/UserDataStore";
import {EllipsisLoader} from "../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {FuxNotificationSystem} from "../components/GenericUI/FuxNotificationSystem/FuxNotificationSystem";
import {ChatTab} from "./Tabs/ChatTab/ChatTab";
import {LauncherListenersController} from "./Listeners/LauncherListenersController";
import {RecensioniAPI} from "../api/Recensioni/RecensioniAPI";
import {ButtonToGoBookizonHome} from "../components/ButtonToGoBookizonHome";
import NegoziPartnershipAPI from "../api/NegoziPartnership/NegoziPartnershipAPI";
import {DLA_OPEN_NOTIFICATION_CENTER} from "../const/DeepLinkActionsCostants";
import {ModuloHotelAPI} from "../api/ModuloHotel/ModuloHotelAPI";
import PromotionsTab from "./Tabs/Promotions/PromotionsTab";
import AttivitaWelfareCaptureTransactionListener from "./WelfareAttivita/AttivitaWelfareCaptureTransactionListener";
import {ModuloSeatsAPI} from "../api/ModuloSeats/ModuloSeatsAPI";
import ValidateOTPPage from "../components/ValidateOTPPage";
import {ConsentSolutionController} from "../components/ConsentSolution/ConsentSolutionController";
import LauncherOperationsRedeemController from "./OperationsRedeem/LauncherOperationsRedeemController";

const LazyMarketplaceTab = React.lazy(() => import('./Tabs/MarketplaceTab/MarketplaceTab'));

export const UserAuthContext = React.createContext(null);

UserAuthContext.displayName = 'UserAuthContext';

export default class BookizonLauncher extends React.Component {

    constructor(props) {
        super(props);
        window.launcher = this;
        this.state = {
            menuOpen: false,
            notificheOpen: false,
            user: null,
            editMode: false, //Abilità la visualizzazione dei tasti elimina delle app
            apps: null,
            navbarHeight: 65,
            ordini: null,
            books: null,
            hotelBooks: null,
            seatBooks: null,
            pushNotifications: null,
            unreadNotifications: 0,
            activeTab: 'homepage',
            isAutologging: true, //Flag per indicare l'autologin in corso
            isUserModalOpen: false,
            nOfRecensioniInSospeso: null,
            promotionChosenInfo: null, //IMPORTANTE: Resettato a false ogni volta che una app viene chiusa

            stackNegoziOpen: [],
            idNegozioOpen: null,
        };
        this.updateAppsInterval = null; //Mi aggiorna ogni X secondi le info legate al trascorrere del tempo
        this.feedTabComponent = null;
        this.bodyScrollBeforeAppOpen = null;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState.menuOpen !== this.state.menuOpen ||
            nextState.isLoginModalOpen !== this.state.isLoginModalOpen ||
            nextState.isRegisterModalOpen !== this.state.isRegisterModalOpen ||
            nextState.isPassRecoveryModalOpen !== this.state.isPassRecoveryModalOpen ||
            nextState.isUserModalOpen !== this.state.isUserModalOpen ||
            nextState.notificheOpen !== this.state.notificheOpen ||
            nextState.user !== this.state.user ||
            nextState.editMode !== this.state.editMode ||
            nextState.apps !== this.state.apps ||
            nextState.navbarHeight !== this.state.navbarHeight ||
            nextState.books !== this.state.books ||
            nextState.ordini !== this.state.ordini ||
            nextState.hotelBooks !== this.state.hotelBooks ||
            nextState.seatBooks !== this.state.seatBooks ||
            nextState.pushNotifications !== this.state.pushNotifications ||
            nextState.unreadNotifications !== this.state.unreadNotifications ||
            nextState.activeTab !== this.state.activeTab ||
            nextState.isAutologging !== this.state.isAutologging ||
            nextState.idNegozioOpen !== this.state.idNegozioOpen ||
            nextState.nOfRecensioniInSospeso !== this.state.nOfRecensioniInSospeso;
    }

    componentDidMount() {
        swal.safeClose(); //Chiudo l'eventuale loader
        AuthAPI.onStateChanged(this.handleAuthChanged);
        FuxEvents.on(AUTHENTICATED_USER_DATA_CHANGE, this.handleUserDataChange);
        FuxEvents.on(LINKED_APPS_CHANGE, this.fetchLinkedApps);
        FuxEvents.on('homepageEvent', this.handleHomepageActionClick.bind(this));
        FuxEvents.on(UPDATE_USER_BOOKS, this.fetchUserGlobalBooks);
        FuxEvents.on(UPDATE_USER_ORDINI, this.fetchUserOrdini);
        FuxEvents.on(UPDATE_USER_HOTEL_BOOKS, this.fetchUserHotelBooks);
        FuxEvents.on(UPDATE_USER_SEAT_BOOKS, this.fetchUserSeatBooks);
        FuxEvents.on(UPDATE_USER_NOTIFICATIONS, this.fetchPushNotifications);
        FuxEvents.on(ADD_APP_REQUEST, this.handleDirectAdd);
        FuxEvents.on(OPEN_APP_REQUEST, this.handleAppOpen);
        FuxEvents.on(CLOSE_APP_REQUEST, this.handleNegozioClose);
        FuxEvents.on(NEW_FEEDBACK_WRITTEN, this.decreaseNumberOfRecensioniToDo);
        FuxEvents.on(USERS_CONVERSION_PARTNERHIP, this.usersConversionPartnership);
        FuxEvents.on(OPEN_PROMOTIONS_TAB, this.handleSetActiveTab)
        FuxEvents.on(PROMOTION_CLICKED, this.handleOpenAppFromPromotion)
        FuxEvents.on(DLA_OPEN_NOTIFICATION_CENTER, this.handleOpenNotificationCenter);

        Store.onChange(this.handleStoreChanged);
        AuthAPI.start(true, "launcher")
            .then(withLogin => { //With login mi dice se l'autenticazione è andata bene in seguito ad un login
                console.log("AUTOLOGIN RESOLVE", withLogin);
                this.setState({isAutologging: false});
                swal.safeClose();
            })
            .catch(e => {
                console.log("AUTOLOGIN REJECT", e);
                this.setState({isAutologging: false});
                swal.safeClose();
            });

        FuxEvents.on('changeLang', _ => {
            this.setState({menuOpen: false});
        });
        this.updateAppsInterval = setInterval(_ => {
            this.fetchLinkedApps();
        }, 5 * 60 * 1000);
    }

    componentWillUnmount() {
        //Disiscrivo il componente dagli eventi a cui era iscritto
        Store.off(this.handleStoreChanged);
        AuthAPI.offStateChanged(this.handleAuthChanged);
        FuxEvents.off(AUTHENTICATED_USER_DATA_CHANGE, this.handleUserDataChange);
        FuxEvents.off(UPDATE_USER_ORDINI, this.fetchUserOrdini)
        FuxEvents.off(UPDATE_USER_BOOKS, this.fetchUserGlobalBooks);
        FuxEvents.off(UPDATE_USER_HOTEL_BOOKS, this.fetchUserHotelBooks);
        FuxEvents.off(UPDATE_USER_SEAT_BOOKS, this.fetchUserSeatBooks);
        FuxEvents.off(UPDATE_USER_NOTIFICATIONS, this.fetchPushNotifications);
        FuxEvents.off(LINKED_APPS_CHANGE, this.fetchLinkedApps);
        FuxEvents.off(ADD_APP_REQUEST, this.handleDirectAdd);
        FuxEvents.off(OPEN_APP_REQUEST, this.handleAppOpen);
        FuxEvents.off(CLOSE_APP_REQUEST, this.handleNegozioClose);
        FuxEvents.off(NEW_FEEDBACK_WRITTEN, this.decreaseNumberOfRecensioniToDo);
        FuxEvents.off(USERS_CONVERSION_PARTNERHIP, this.usersConversionPartnership);
        FuxEvents.off(OPEN_PROMOTIONS_TAB, this.handleSetActiveTab)
        FuxEvents.off(PROMOTION_CLICKED, this.handleOpenAppFromPromotion)
        FuxEvents.off(DLA_OPEN_NOTIFICATION_CENTER, this.handleOpenNotificationCenter);

        if (this.updateAppsInterval) {
            clearInterval(this.updateAppsInterval);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var navbar = document.getElementById("launcherNavbar");
        if (navbar) {
            if (navbar.clientHeight !== prevState.navbarHeight) {
                this.setState({
                    navbarHeight: navbar.clientHeight
                });
            }
        }

        if (this.state.activeTab === 'recommended') {
            document.body.style.background = "#ffffff";
        } else if (this.state.activeTab === 'feed') {
            document.body.style.background = "#dadde1";
        } else {
            document.body.style.background = BODY_BACKGROUND;
        }
    }


    /**
     * Gestione app collegate
     * */

    handleAppOpen = id_negozio => {
        if (this.state.stackNegoziOpen.length > 0) {
            this.handleAppOpenFromAnother(id_negozio);
            return
        }
        BookizonAppManager.topUnsafeAreaCheckpoint();
        BookizonAppManager.bottomUnsafeAreaCheckpoint();
        this.setState({idNegozioOpen: id_negozio, stackNegoziOpen: this.state.stackNegoziOpen.concat(id_negozio)});
        this.bodyScrollBeforeAppOpen = document.documentElement.scrollTop; //Lo scroll top verrà settato a 0 da "BookizonAppContainer"
    }

    handleNegozioClose = _ => {
        this.state.stackNegoziOpen.pop();
        NegozioOpenStore.id_negozio = null;
        this.setState({idNegozioOpen: null, promotionChosenInfo: null}, _ => {
            BookizonAppManager.restoreTopUnsafeAreaCheckpoint();
            BookizonAppManager.restoreBottomUnsafeAreaCheckpoint();
            if (this.state.stackNegoziOpen.length > 0) {
                this.handleAppOpenFromAnother(this.state.stackNegoziOpen.pop());
            } else {
                const buttonToCloseAllApp = document.getElementById("buttonToCloseAllApp");
                if (buttonToCloseAllApp) buttonToCloseAllApp.classList.add("d-none");
                const modalRoot = document.getElementById("modal-root");
                if (modalRoot) modalRoot.classList.remove("d-none"); //Mostro di nuovo il modal container
                const launcherWrapper = document.getElementById("bookizon-launcher-wrapper");
                if (launcherWrapper) launcherWrapper.classList.remove("d-none"); //Mostro di nuovo il modal container
            }
        });
        document.documentElement.scrollTop = this.bodyScrollBeforeAppOpen;
        this.bodyScrollBeforeAppOpen = null;
    }

    handleAppOpenFromAnother = id_negozio => {
        NegozioOpenStore.id_negozio = null;
        this.setState({idNegozioOpen: null, promotionChosenInfo: null}, _ => {
            this.setState({idNegozioOpen: id_negozio, stackNegoziOpen: this.state.stackNegoziOpen.concat(id_negozio)});
            document.getElementById("buttonToCloseAllApp").classList.remove("d-none");
        });
    }

    handleCloseAllApp = _ => {
        var firstElement = this.state.stackNegoziOpen[0];
        this.setState({stackNegoziOpen: [firstElement]}, _ => {
            this.handleNegozioClose();
        })
    }


    handleAuthChanged = (user) => {
        //Aggiorno lo stato
        //Chiudo il menu e la pagina di login se mi sono loggato e non si tratta di un semplice aggiornamento dati
        this.setState({
            user: user,
            menuOpen: this.state.user ? this.state.menuOpen : false,
            isLoginModalOpen: this.state.user ? this.state.isLoginModalOpen : false
        });

        //Recupero le app che sono associate con il mio utente
        if (user) {
            if (!this.state.apps) this.fetchLinkedApps(); //Scarico le app solamente se non stanno già memorizzate, se entro ed esco dal launcher non verranno ri-scaricate
            if (!this.state.books) this.fetchUserGlobalBooks();
            if (!this.state.ordini) this.fetchUserOrdini();
            if (!this.state.hotelBooks) this.fetchUserHotelBooks();
            if (!this.state.seatBooks) this.fetchUserSeatBooks();
            if (this.state.nOfRecensioniInSospeso === null) this.fetchNumberOfRecensioniToDO();
            this.fetchPushNotifications();
        } else {
            this.setState({
                books: null,
                apps: null,
                ordini: null,
            });
        }
    }

    handleStoreChanged = (store) => {
        if (NegozioOpenStore.shouldOpenAppId !== null) {
            this.handleAppOpen(NegozioOpenStore.shouldOpenAppId);
            NegozioOpenStore.shouldOpenAppId = null;
        }
    }


    handleUserDataChange = _ => {
        this.setState({user: AuthAPI.currentUser});
    }

    fetchLinkedApps = _ => {
        UsersShopsAPI.getShops()
            .then((linkedApps) => {
                UserDataStore.apps = linkedApps;
                this.setState({apps: linkedApps});
                swal.safeClose();
            });
    }

    handleOpenNotificationCenter = _ => {
        this.handleSetActiveTab('notifiche');
    }

    //Controlla ogni 100ms se sono state caricate tutte le app
    waitForApps = _ => {
        return new Promise(resolve => {
            const interval = setInterval(_ => {
                if (this.state.apps != null) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    }


    /**
     * Push Notifications
     * */

    fetchPushNotifications = _ => {
        NotificheAPI.getLatest(50)
            .then(data => {
                this.setState({
                    pushNotifications: data,
                    unreadNotifications: data.reduce((count, notifica) => {
                        if (notifica.letto === "0") {
                            count++;
                        }
                        return count;
                    }, 0)
                })
            });
    }

    handleSetNotificationReaded = _ => {
        this.setState({
            pushNotifications: this.state.pushNotifications.map(n => {
                n.letto = "1";
                return n;
            }),
            unreadNotifications: 0
        });
    }


    fetchUserGlobalBooks = _ => {
        BookingAPI.getNextBooks() //Non verrà indicato un ID negozio per cui prenderà sempre quelle di default
            .then(nextBooks => {
                this.setState({books: nextBooks});
                Store.setState({nextGlobalBooks: nextBooks});
            });
    }

    fetchUserHotelBooks = _ => {
        ModuloHotelAPI.Books.getNextBooks() //Non verrà indicato un ID negozio per cui prenderà sempre quelle di default
            .then(nextBooks => {
                this.setState({hotelBooks: nextBooks});
                Store.setState({hotelBooks: nextBooks});
            });
    }

    fetchUserSeatBooks = _ => {
        ModuloSeatsAPI.Client.Books.getNextBooks() //Non verrà indicato un ID negozio per cui prenderà sempre quelle di default
            .then(nextBooks => {
                this.setState({seatBooks: nextBooks});
                Store.setState({seatBooks: nextBooks});
            });
    }

    fetchUserOrdini = _ => {
        OrdiniAPI.listaAttivi()
            .then(ordini => {
                this.setState({ordini: ordini});
                Store.setState({ordini: ordini});
            });
    }

    handleMenuToggle = _ => this.setState({menuOpen: !this.state.menuOpen});

    handleHomepageActionClick(actionName) {
        console.log(actionName);
        switch (actionName) {
            case 'userProfileModal':
                this.setState({isUserModalOpen: !this.state.isUserModalOpen});
                break;
        }
    }

    handleAdd = _ => {
        //Tento l'aggiunta automatica live
        swal.fire({
            type: 'input',
            input: 'text',
            text: 'Inserisci codice negozio per poterlo aggiungere su Bookizon',
            inputPlaceholder: 'es. BZN1234',
            confirmButtonText: 'Collega negozio',
            cancelButtonText: 'Annulla'
        }).then(function (result) {
            if (result.value) {
                var id_negozio = result.value.toLowerCase().replace('bzn', '');
                window.addAppId(id_negozio);
            }
        });
    }

    handleDirectAdd = codice => {
        swalLoading();
        NegozioAPI.getInfoByCode(codice)
            .then(data => {
                this.waitForApps()
                    .then(_ => {
                        const alreadyHasApp = !!this.state.apps.find(n => n.id_negozio === data.id_negozio);
                        if (alreadyHasApp) {
                            this.handleAppOpen(data.id_negozio);
                            return;
                        }
                        UsersShopsAPI.add(data.id_negozio)
                            .then(_ => {
                                FuxEvents.emit(LINKED_APPS_CHANGE);
                                FuxEvents.emit(APP_FAVOURITE_ADDED, data.id_negozio);
                                FuxNotificationSystem.fire({
                                    title: "Applicazione aggiunta",
                                    message: `"${data.nome}" è stata aggiunta alla lista delle tue app`,
                                    position: 'tc', //Top center
                                    level: 'success',
                                    autoDismiss: 4
                                });
                            })
                            .catch(swalError);
                    })
            }).catch(swalError);
    }

    handleQRScan = _ => {
        BookizonAppManager.scanQRCode();
    }

    handleDeleteApp = (id_negozio) => {
        swalLoading();
        UsersShopsAPI.delete(id_negozio)
            .then(() => {
                swal.safeClose();
                this.removeIdFromState(id_negozio);
            });
    }

    removeIdFromState(id_negozio) {
        var newApps = this.state.apps.filter((negozio, i) => {
            return negozio.id_negozio !== id_negozio;
        });
        this.setState({apps: newApps, editMode: newApps.length ? this.state.editMode : false});
        FuxEvents.emit(LINKED_APPS_CHANGE);
        FuxEvents.emit(APP_FAVOURITE_REMOVED, id_negozio);
    }

    handleToggleEditMode = () => {
        this.setState({editMode: !this.state.editMode});
    }

    //Effettua il riordinamento delle app in locale ed invia il nuovo ordine al server
    handleAppReorder = (id_negozio, destIndex, sourceIndex) => {
        const apps = Array.from(this.state.apps);
        const movingApp = apps[sourceIndex];
        apps.splice(sourceIndex, 1);
        apps.splice(destIndex, 0, movingApp);
        this.setState({apps: apps});
        const negozi = [];
        apps.map((n, i) => negozi.push({id_negozio: n.id_negozio, ordine: i}));
        UsersShopsAPI.setOrder(negozi);
    };

    handleNotificheToggle = _ => {
        this.setState({notificheOpen: !this.state.notificheOpen});
    }

    handleSetActiveTab = tabName => {
        this.setState({activeTab: tabName});
        if (tabName === 'feed' && this.state.activeTab === 'feed' && this.feedTabComponent) {
            this.feedTabComponent.handleActiveTabChange(this.feedTabComponent.state.activeTab);
        }
    }

    fetchNumberOfRecensioniToDO = _ => {
        RecensioniAPI.RecensioniInfoForLauncher.getRecensioniInSospeso()
            .then((response) => {
                this.setState({nOfRecensioniInSospeso: response.nOfRecensioniInSospeso})
            });

    }

    decreaseNumberOfRecensioniToDo = _ => this.setState({nOfRecensioniInSospeso: this.state.nOfRecensioniInSospeso - 1});


    /*Gestione partnership*/
    usersConversionPartnership = info => {
        //Se c'é solo un negozio in coda non si può continuare
        if (!this.state.stackNegoziOpen[this.state.stackNegoziOpen.length - 2]) {
            return
        }
        NegoziPartnershipAPI.salvaVisite(
            this.state.stackNegoziOpen[this.state.stackNegoziOpen.length - 2],
            info.id_negozio,
            null,
            info.tipo_operazione
        )
    }

    /*Apertura di una app dopo avr cliccato su una promozione*/
    //promotionInfo deve contenere id_negozio, id_promozione, modulo (della promozione), codice_coupon
    handleOpenAppFromPromotion = promotionInfo => {
        console.log("INFORMAZIONI PROMO!!!", promotionInfo)
        if (NegozioOpenStore.id_negozio) {
            return false;
        }
        this.setState({promotionChosenInfo: promotionInfo}, _ => {
            this.handleAppOpen(promotionInfo.id_negozio)
        })
    }

    handleGuestOperationsImported = _ => {
        this.fetchUserGlobalBooks();
        this.fetchUserOrdini();
        this.fetchUserHotelBooks();
        this.fetchUserSeatBooks();
    }


    render() {
        if (this.state.isAutologging) {
            return <BookizonSplashScreen/>
        }

        return (
            <UserAuthContext.Provider value={this.state.user}>
                <div id={"bookizon-launcher-wrapper"} className={"bookizonLauncher"}>
                    {
                        this.state.user &&
                        <Fragment>
                            <div>
                                <PopUpNews/>

                                <ChatTab
                                    active={this.state.activeTab === 'chat'}
                                    user={this.state.user}
                                />

                                <NotificationTab
                                    active={this.state.activeTab === 'notifiche'}
                                    onAppOpenRequest={this.handleAppOpen}
                                    onReadedSuccess={this.handleSetNotificationReaded}
                                    notifications={this.state.pushNotifications}
                                />

                                <LauncherOperationsRedeemController
                                    user={this.state.user}
                                    onSuccess={this.handleGuestOperationsImported}
                                />

                            </div>
                        </Fragment>
                    }
                    <HomepageTab
                        active={this.state.activeTab === 'homepage'}
                        user={this.state.user}
                        books={this.state.books ? this.state.books : []}
                        hotelBooks={this.state.hotelBooks ? this.state.hotelBooks : []}
                        seatBooks={this.state.seatBooks ? this.state.seatBooks : []}
                        ordini={this.state.ordini ? this.state.ordini : []}
                        hasApps={this.state.apps && this.state.apps.length > 0}
                        apps={this.state.apps}
                        editMode={this.state.editMode}
                        nOfRecensioniInSospeso={this.state.nOfRecensioniInSospeso}
                        onAppReorderRequest={this.handleToggleEditMode}
                        onAppOpenRequest={this.handleAppOpen}
                        onAppDeleteRequest={this.handleDeleteApp}
                        onAppAddRequest={this.handleAdd}
                        onQRScanRequest={this.handleQRScan}
                        onAppReorder={this.handleAppReorder}
                        onMenuToggle={this.handleMenuToggle.bind(this)}
                        devMode={this.props.devMode}
                    />
                    <FeedTab
                        ref={ref => this.feedTabComponent = ref}
                        active={this.state.activeTab === 'feed'}
                        user={this.state.user}
                        onAppOpen={this.handleAppOpen}
                        onAppAdd={this.handleDirectAdd}
                    />
                    <PromotionsTab
                        active={this.state.activeTab === 'promotions'}
                        user={this.state.user}
                        onAppOpen={this.handleAppOpen}
                        onAppAdd={this.handleDirectAdd}
                    />
                    {
                        (this.state.activeTab === 'marketplace' || LazyMarketplaceTab._result) &&
                        <Suspense fallback={
                            <div className={"d-flex align-items-center justify-content-center"}
                                 style={{height: "100vh"}}>
                                <EllipsisLoader/>
                            </div>
                        }>
                            <LazyMarketplaceTab
                                active={this.state.activeTab === 'marketplace'}
                            />
                        </Suspense>
                    }
                    <LauncherSideMenu
                        active={this.state.menuOpen}
                        user={this.state.user}
                        apps={this.state.apps}
                        nOfRecensioniInSospeso={this.state.nOfRecensioniInSospeso}
                        onMenuToggle={this.handleMenuToggle.bind(this)}
                        onLoginClick={_ => FuxEvents.emit('homepageEvent', 'loginModal')}
                        onRegisterClick={_ => FuxEvents.emit('homepageEvent', 'registerModal')}
                        unreadNotifications={this.state.unreadNotifications}
                        onAppOpen={this.handleAppOpen}
                    />
                    <LauncherNavbar
                        onNotificheToggle={this.handleNotificheToggle}
                        onEditClick={this.handleToggleEditMode.bind(this)}
                        onTabChange={this.handleSetActiveTab}
                        editMode={this.state.editMode}
                        user={this.state.user}
                        unreadNotifications={this.state.unreadNotifications}
                        activeTab={this.state.activeTab}
                    />
                </div>
                <LauncherListenersController
                    onAppOpen={this.handleAppOpen}
                    onAppAdd={this.handleDirectAdd}
                    idNegozioOpen={this.state.idNegozioOpen}
                />
                <ConsentSolutionController user={this.state.user}/>
                {
                    !!this.state.idNegozioOpen &&
                    <BookizonAppContainer
                        isStandalone={false}
                        onAppClose={this.handleNegozioClose}
                        promotionChosenInfo={this.state.promotionChosenInfo}
                        id_negozio={this.state.idNegozioOpen}
                    />
                }

                {/*Evento scansione qr welfare pagamento*/}
                {
                    this.state.user &&
                    this.state.user.negozio && this.state.user.negozio.walletapikey &&
                    <AttivitaWelfareCaptureTransactionListener
                        negozio={this.state.user.negozio}
                    />
                }

                <ButtonToGoBookizonHome
                    closeApp={this.handleCloseAllApp}
                />

                {
                    /*<ValidateOTPPage
                        onDismiss={_ => {
                        }}
                        onValidate={_ => {
                        }}
                        resendOTP={_ => {
                        }}
                        message={"Attiv tutt cos"}
                    />*/
                }

            </UserAuthContext.Provider>
        );
    }

}

BookizonLauncher.propTypes = {
    links: PropTypes.arrayOf(PropTypes.string),
    devMode: PropTypes.bool
};

BookizonLauncher.defaultProps = {
    appIds: []
};
