import React from "react";
import PropTypes from "prop-types";
import {shadeColor} from "../../../helpers/UIUtility";
import {Decorator1} from "../../../img/decorators/Decorator1";
import {Decorator2} from "../../../img/decorators/Decorator2";
import {Decorator3} from "../../../img/decorators/Decorator3";
import {Decorator4} from "../../../img/decorators/Decorator4";
import {Decorator5} from "../../../img/decorators/Decorator5";

const ImageStyle = {
    width:"35%",
    height:80,
    opacity:0.3,
    transform: "scale(1.6) translateX(30px) translateY(20px)",
    position:"absolute",
    top:0, left:0
};

export class SearchPageHeader extends React.Component{

    constructor(props) {
        super(props);
        this.labelStyle = {
            color:"#ffffff",
            zIndex:1,
        }
        this.backgroundStyle = {
            backgroundColor:this.props.categoria.color,
        }
        this.decoratorStyles = [];
        for(let i = 0; i<5; i++){
            this.decoratorStyles.push({
                position:"absolute",
                top: Math.floor(Math.random()*5) * (100/5),
                right: i * (200/5),
                opacity: 0.3,
                maxWidth:32, maxHeight:32
            });
        }
    }

    getDecorator = i => {
        const color = shadeColor(this.props.categoria.color,+80);
        switch(i){
            case 0: return <Decorator1 key={i} color={color} style={this.decoratorStyles[i]}/>;
            case 1: return <Decorator2 key={i} color={color} style={this.decoratorStyles[i]}/>;
            case 2: return <Decorator3 key={i} color={color} style={this.decoratorStyles[i]}/>;
            case 3: return <Decorator4 key={i} color={color} style={this.decoratorStyles[i]}/>;
            case 4: return <Decorator5 key={i} color={color} style={this.decoratorStyles[i]}/>;
        }
    }

    render(){
        const CategoryImage = this.props.categoria.image;
        return (
            <div className={"position-relative overflow-hidden py-5 d-flex align-items-center shadow-sm mb-2"} style={this.backgroundStyle}>
                <CategoryImage style={ImageStyle} color={shadeColor(this.props.categoria.color,+80)}/>
                {
                    [...(new Array(5)).keys()].map(i => this.getDecorator(i))
                }
                <h4 className={"font-weight-bold text-center w-100"} style={this.labelStyle}>
                    {this.props.categoria.nome}
                </h4>
            </div>
        )
    }

}

SearchPageHeader.propTypes = {
    categoria: PropTypes.object.isRequired
}
