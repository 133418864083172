import React from "react";
import {AdminAPI} from "../../../api/Admin/AdminAPI";
import {swalError} from "../../../helpers/SweetAlertWrapper";
import ToggleSwitch from "../../../components/BaseComponents/ToggleSwitch/ToggleSwitch";

export class PostAdminNotifySection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifica_like_post: this.props.user.negozio?.notifiche?.notifica_like_post,
            notifica_commento_post: this.props.user.negozio?.notifiche?.notifica_commento_post
        }
    }

    onToggle = ({target}) => {
        let settings = {}
        //Inverte lo stato
        settings[target.id] = (this.state[target.id] ? 0 : 1)
        AdminAPI.Settings.notificheSettings(settings)
            .then(_ => {
                this.setState(settings)
            })
            .catch(message => swalError(message))
    };

    render() {
        return (
            <div className={"list-group list-group-flush"}>
                {/*LIKE*/}
                <div className={"list-group-item list-group-item-action"}>
                    <h5 className={"my-0"}>Like ai tuoi post</h5>
                    <div className={"row align-items-center"}>
                        <div className={"col-8"}>
                            Notifiche like ai tuoi post
                        </div>
                        <div className={"col-4 text-right"}>
                            <ToggleSwitch id={`notifica_like_post`}
                                          currentValue={!!parseInt(this.state.notifica_like_post)}
                                          onChange={this.onToggle}/>
                        </div>
                    </div>

                    <small>Ricevi una notifica quando un utente clicca "mi piace" ad un tuo post</small>
                </div>

                {/*COMMENTI*/}
                <div className={"list-group-item list-group-item-action border-bottom"}>
                    <h5 className={"my-0"}>Commenti sotto i tuoi post</h5>
                    <div className={"row align-items-center"}>
                        <div className={"col-8"}>
                            Notifiche commenti sotto i tuoi post
                        </div>
                        <div className={"col-4 text-right"}>
                            <ToggleSwitch id={`notifica_commento_post`}
                                          currentValue={!!parseInt(this.state.notifica_commento_post)}
                                          onChange={this.onToggle}/>
                        </div>
                    </div>

                    <small>Ricevi una notifica quando un utente ha commentato un tuo post</small>
                </div>
            </div>
        )
    }

}
