import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import FuxCursorPaginator from "../../../../components/BaseComponents/FuxCursorPaginator/FuxCursorPaginator";
import Page from "../../../../components/BaseComponents/Page/Page";
import React from "react";
import PropTypes from "prop-types";
import DipendenteWelfareGiftCardsHistoryItem from "./DipendenteWelfareGiftCardsHistoryItem";
import {ORDINE_COMPLETATO, ORDINE_RIMBORSATO, PIN_STATUS_ATTIVO, PIN_STATUS_NON_ATTIVO} from "../GiftCardsConstants";
import moment from "moment";
import {WelfareAPI} from "../../../../api/Welfare/WelfareAPI";
import {BookizonAppManager} from "../../../../index";
import AuthAPI from "../../../../api/AuthAPI";
import AppConfig from "../../../../config/AppConfig";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {NM_ANDROID_ON_RESUME, NM_IN_APP_BROWSER_DISMISS} from "../../../../native/NativeMessageHandler";
import APIConfig from "../../../../api/APIConfig";

export default class DipendenteWelfareGiftCardHistoryProductPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            giftCard: {},
            redeemInstructionsOpen: false,
        }
    }

    componentDidMount() {
        FuxEvents.on(NM_IN_APP_BROWSER_DISMISS, this.fetchOrderInfo);
        FuxEvents.on(NM_ANDROID_ON_RESUME, this.fetchOrderInfo);
        //Pasato a false il refresh data perchè alla prima apertura i dati li ho
        this.fetchOrderInfo()
    }

    componentWillUnmount() {
        FuxEvents.off(NM_IN_APP_BROWSER_DISMISS, this.fetchOrderInfo)
        FuxEvents.off(NM_ANDROID_ON_RESUME, this.fetchOrderInfo)
    }

    fetchOrderInfo = _ => {
        //Resetta gift card in modo che vada in caricamento la pagina
        this.setState({giftCard: {}}, _ => {
            WelfareAPI.GiftCards.getOrder(this.props.idOrdine)
                .then(data => this.setState({giftCard: data}))
                .catch(msg => swalError(msg))
        })
    }

    //Stampa lo stato dell'ordine
    getOrderStatus = _ => {
        if (this.state.giftCard.status === ORDINE_RIMBORSATO)
            return <div className={"badge badge-danger"}>Rimborsato</div>

        switch (this.state.giftCard.pin_status) {
            case PIN_STATUS_NON_ATTIVO:
                return <div className={"badge badge-warning"}>Non ancora attivata</div>
            case PIN_STATUS_ATTIVO:
                return <div className={"badge badge-success"}>Attiva</div>
        }
    }

    getDataItalianFormat = data => {
        return moment(data).calendar(null, {
            sameDay: '[Oggi alle] HH:mm',
            nextDay: '[Domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[Ieri alle] HH:mm',
            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        })

    }

    toggleRedeemInstruction = _ => this.setState({redeemInstructionsOpen: !this.state.redeemInstructionsOpen})

    getPinActivationPage = _ => {
        let qsParams = "?id_ordine=" + this.state.giftCard.id_ordine +
            "&item_external_id=" + this.state.giftCard.item_external_id +
            "&serial_number=" + this.state.giftCard.serial_number +
            "&token=" + AuthAPI.getUserAccessToken()

        let url = AppConfig.webServerUrl + "/epipoli/pin-activation" + qsParams
        BookizonAppManager.openInAppBrowser(url);
    }

    refundGiftCard = _ => {
        swalConfirm("Sei sicuro di voler ricevere il rimborso di questa gift card? Non potrai più utilizzarla.", "Procedi", "Annulla")
            .then(_ => {
                swalLoading("Attendi mentre completiamo tutti i passaggi...")
                WelfareAPI.GiftCards.refundOrder(this.state.giftCard.id_ordine)
                    .then(msg => {
                        swalSuccess(msg).then(_ => this.props.onDismiss())
                    })
                    .catch(msg => swalError(msg))
            })
    }

    /**
     * @FOR-ACTIVATED-CARD
     */
    getGiftCardActiveInformation = _ => {
        this.fetchOrderInfo()
        WelfareAPI.GiftCards.getActivatedCardInfo(this.state.giftCard.id_ordine)
            .then(data => this.setState({giftCardActivatedInfo: data}))
    }

    openPDF = _ => {
        if (!this.state.giftCard.activated_card_info.pdfBase64) {
            swalError("Per questa Gift Card non è presente un pdf")
        }
        BookizonAppManager.openInAppBrowser(
            `${AppConfig.webServerUrl}/api/welfare/gift-cards/show-pdf?token=${AuthAPI.getUserAccessToken()}&id_ordine=${this.props.idOrdine}`
        );
    }

    base64ToBlob = (base64, type = "application/octet-stream") => {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], {type: type});
    }

    render() {

        return (
            <Page
                title={"La tua gift card"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={true}
                content={
                    !this.state.giftCard.name ?
                        <div className={"w-100 text-center mt-5 p-2"}>
                            <EllipsisLoader/><br/>
                            Attendi mentre carichiamo il tuo ordine...
                        </div>
                        :
                        <div className={"container pt-4"}>
                            <div className={"w-100 text-center"}>
                                <h4 className={"font-weight-bold"}>
                                    {this.state.giftCard.name}
                                </h4>
                                <span
                                    className={"text-muted"}>Ordine effettuato il {this.getDataItalianFormat(this.state.giftCard.data_creazione)}</span>
                            </div>

                            <div className={"my-3"}>Stato: {this.getOrderStatus()}</div>

                            {/*INFO TAGLIO*/}
                            <b>Taglio:</b>
                            <div className={"card card-body shadow-sm border-0 mt-1 mb-3"}>
                                Hai scelto la gift card da €{parseFloat(this.state.giftCard.taglio).toFixed(2)}
                            </div>

                            {/*INFO Acquisto*/}
                            <b>Modalità acquisto:</b>
                            <div className={"card card-body shadow-sm border-0 mt-1 mb-3"}>
                                Pagato con credito welfare
                            </div>

                            {/*INFO GIFTCARD ATTIVA*/}
                            {
                                this.state.giftCard.activated_card_info && this.state.giftCard.status === ORDINE_COMPLETATO &&
                                <div>
                                    <b>Dati Gift Card:</b>
                                    <div className={"card card-body shadow-sm border-0 mt-1 mb-3"}>
                                        {
                                            this.state.giftCard.activated_card_info.cardCode &&
                                            <div><b>Codice:</b> {this.state.giftCard.activated_card_info.cardCode}
                                            </div>
                                        }
                                        {
                                            this.state.giftCard.activated_card_info.pdfBase64 &&
                                            <div onClick={this.openPDF}><b>PDF:</b>
                                                <button className={"btn btn-link p-0"}>Clicca quì per
                                                    vederlo
                                                </button>
                                            </div>
                                        }
                                        {
                                            this.state.giftCard.activated_card_info.expiresAt &&
                                            <div><b>Data
                                                scadenza:</b> {moment(this.state.giftCard.activated_card_info.expiresAt).format('DD-MM-YYYY')}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            {/*BUTTON TO ACTIVE*/}
                            {
                                this.state.giftCard.pin_status === PIN_STATUS_NON_ATTIVO && this.state.giftCard.status === ORDINE_COMPLETATO &&
                                <div className={"text-center"}>
                                    <button className={"btn btn-warning btn-block my-2"}
                                            onClick={this.getPinActivationPage}>
                                        Attiva gift card
                                    </button>
                                    <button className={"btn btn-link text-danger"} onClick={this.refundGiftCard}>
                                        Richiedi rimborso
                                    </button>
                                </div>
                            }

                            {/*REEED ME ISTRUCTIONS*/}
                            <div className={"items-center my-3 text-muted"}
                                 onClick={_ => this.toggleRedeemInstruction()}>Istruzioni per l'uso {
                                this.state.redeemInstructionsOpen ?
                                    <i className="fas fa-sort-down"/> :
                                    <i className="fas fa-caret-right"/>}
                            </div>
                            {
                                this.state.redeemInstructionsOpen &&
                                <div className={"card card-body shadow-sm border-0 mt-1"}>
                                    <div dangerouslySetInnerHTML={{__html: this.state.giftCard.redeem_instructions}}/>
                                </div>
                            }
                        </div>
                }
            />
        )
    }
}

DipendenteWelfareGiftCardHistoryProductPage.propTypes = {
    onDismiss: PropTypes.func,
    idOrdine: PropTypes.number
}