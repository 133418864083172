import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {RECURRING_MAX_BOOKS_NUM} from "../RecurringDatesChooserPanel";

export const WEEKDAY_NUMBER_1ST = 'first';
export const WEEKDAY_NUMBER_2ND = 'second';
export const WEEKDAY_NUMBER_3RD = 'third';
export const WEEKDAY_NUMBER_4TH = 'fourth';
export const WEEKDAY_NUMBER_LAST = 'last';

export class MonthlyOptionsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            weekdayNumber: WEEKDAY_NUMBER_1ST,
            weekday: 1,
            amount: 2
        }
    }

    handleInputChange = ({target}) => this.setState({[target.name]: target.value});

    handleChoose = _ => this.props.onChoose(this.state);

    render() {
        return (
            <React.Fragment>
                <h4 className={"font-weight-bold text-center"}>
                    Prenotazione mensile
                </h4>
                <p className={"text-muted"}>
                    Scegli il giorno della settimana in cui vuoi effettuare le prossime prenotazioni con cadenza mensile
                </p>
                <div className={"d-flex align-items-center flex-wrap justify-content-center mb-3"}>
                    Ogni
                    <select className={"form-control w-auto mx-2"} name={'weekdayNumber'}
                            value={this.state.weekdayNumber}
                            onChange={this.handleInputChange}>
                        <option value={WEEKDAY_NUMBER_1ST}>Primo/a</option>
                        <option value={WEEKDAY_NUMBER_2ND}>Secondo/a</option>
                        <option value={WEEKDAY_NUMBER_3RD}>Terzo/a</option>
                        <option value={WEEKDAY_NUMBER_4TH}>Quarto/a</option>
                        <option value={WEEKDAY_NUMBER_LAST}>Ultimo/a</option>
                    </select>
                    <select className={"form-control w-auto"} name={'weekday'} value={this.state.weekday}
                            onChange={this.handleInputChange}>
                        <option value={1}>Lunedì</option>
                        <option value={2}>Martedì</option>
                        <option value={3}>Mercoledì</option>
                        <option value={4}>Giovedì</option>
                        <option value={5}>Venerdì</option>
                        <option value={6}>Sabato</option>
                        <option value={0}>Domenica</option>
                    </select>
                </div>
                <div className={"text-center mb-3"}>del mese</div>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <button className={"btn btn-link text-on-bg-main"} onClick={this.props.onDismiss}>
                        <FaSolidIcon name={"chevron-left"}/> Indietro
                    </button>
                    <button className={"btn btn-primary"} onClick={this.handleChoose}>
                        Conferma <FaSolidIcon name={"chevron-right"}/>
                    </button>
                </div>
            </React.Fragment>
        )
    }

}

MonthlyOptionsView.propTypes = {
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func
}
