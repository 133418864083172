import moment from "moment";
import React from "react";

import AppConfig from "../../../config/AppConfig";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import {GroupBookResumePage} from "./GroupBookResume/GroupBookResumePage";


export const PRENOTAZIONE_GRUPPO_STATUS_ATTESA = 'prenotazione.in_attesa';
export const PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA = 'prenotazione.confermata';
export const PRENOTAZIONE_GRUPPO_STATUS_RIFIUTATA_ESERCENTE = 'prenotazione.rifiutata_esercente';


export default class GroupBookCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            detailsOpen: false
        };

        this.styleOpacity = {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(30, 30, 30, 0.7)',
            fromNegozio: 0
        }
    }


    getStatusLabel = _ => {
        switch (this.props.book.status) {
            case PRENOTAZIONE_GRUPPO_STATUS_ATTESA:
                return <span className={"badge badge-warning"}>In attesa di conferma</span>;
            case PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA:
                return <span className={"badge badge-success"}>Confermato</span>;
            case PRENOTAZIONE_GRUPPO_STATUS_RIFIUTATA_ESERCENTE:
                return <span className={"badge badge-danger"}>Rifiutato</span>;
        }
    }



    handleClick() {
        if (this.state.detailsOpen) {
            if (!this.props.isFullScreen) {
                //Se mi trovo nella view fullscreen degli appuntamenti non devo riabilitare lo scroll del body
                document.body.classList.remove('noscroll');
            }
        } else {
            document.body.classList.add('noscroll');
        }
        this.setState({detailsOpen: !this.state.detailsOpen});
    }

    getColorByDay(weekDay) {
        const colors = ["#ef5350", "#673ab7", "#2196f3", "#009688", "#4caf50", "#ffc107", "#ff5722"];
        return colors[weekDay];
    }

    render() {
        var momentDate = moment(this.props.book.datetime_inizio);
        var m = momentDate.calendar(null, {
            sameDay: '[Oggi alle] HH:mm',
            nextDay: '[Domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[Ieri alle] HH:mm',
            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });

        var dayName = momentDate.format('ddd').toUpperCase();
        var monthDay = momentDate.format('DD');
        var monthName = momentDate.format('MMMM').charAt(0).toUpperCase() + momentDate.format('MMMM').slice(1);
        var dayNameExstend = momentDate.format('dddd').charAt(0).toUpperCase() + momentDate.format('dddd').slice(1);
        var imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${this.props.book.id_negozio}/logoIcon/100`;


        return (
            <div>
                <div className={"card bg-main-z2 shadow-sm " + this.props.className}
                     style={{"borderLeft": `4px solid ${this.getColorByDay(momentDate.day())}`}}>
                    <div className={"card-body"}>
                        <div onClick={this.handleClick.bind(this)}>
                            <div className={"d-flex align-items-center"}>
                                <AppIconThumbnail size={"25px"} src={imageFullUrl} className={"mr-1"}/>
                                <small><SafeHtmlContainer html={this.props.book.nome_negozio}/></small>
                            </div>
                            {
                                //Se vengono passate come props stampa le informazioni sulla recensione
                                this.props.feedbackInformations
                            }
                            <b>
                                {this.props.book.nome_gruppo || ''}
                            </b>
                            <div className={"text-muted"}>{m[0].toUpperCase() + m.substr(1)} {this.getStatusLabel()}</div>
                        </div>
                        <div className={"row mt-2"}>
                            <div className={"col"}>
                                <button className={"btn btn-soft-secondary btn-block btn-sm"}
                                        onClick={this.handleClick.bind(this)}>
                                    Dettagli
                                </button>
                            </div>
                            {
                                this.props.showOpenApp &&
                                <div className={"col"}>
                                    <button className={"btn btn-soft-primary btn-block btn-sm"}
                                            onClick={_ => this.props.onAppOpen(this.props.book.id_negozio)}>
                                        Apri l'app
                                    </button>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <ModalPortalHOC>
                    {
                        !!this.state.detailsOpen &&
                        <GroupBookResumePage
                            idPrenotazione={this.props.book.id_prenotazione}
                            onDismiss={this.handleClick.bind(this)}
                            showOpenApp={true}
                        />
                    }
                </ModalPortalHOC>
            </div>
        );
    }

}
