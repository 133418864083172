import React from "react";
import {shadeColor} from "../../helpers/UIUtility";

export const Decorator3 = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.61304 118.72543">
            <title>decorator-3</title>
            <g id="Livello_2" data-name="Livello 2">
                <g id="Livello_1-2" data-name="Livello 1">
                    <path fill={color}
                          d="M101.73039,118.72543a6.55821,6.55821,0,0,1-4.88975-10.9282c.18462-.20513.37693-.4.57436-.59487A56.72306,56.72306,0,1,0,12.07654,32.45364c-.17179.23077-.32307.42564-.48461.618a6.56307,6.56307,0,0,1-10.05641-8.4359c.25128-.3359.4641-.60769.7-.87179A69.85554,69.85554,0,0,1,107.30731,115.846c-.22564.25641-.44359.47692-.6641.69487A6.59646,6.59646,0,0,1,101.73039,118.72543Z"/>
                </g>
            </g>
        </svg>
    )
}
