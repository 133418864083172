import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import i18n from "../../../../i18n";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import ComandaModal from "../ModuloComande/ComandaModal";
import CreateReceiptsPage from "../ModuloCassa/CreateReceipts/CreateReceiptsPage";
import ModuloCassaDashboard from "../ModuloCassa/ReceiptsHistoryPage";

export class DashboardModuloCassaButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageOpen: false
        }
    }

    handlePageOpen = _ => this.setState({pageOpen: true});
    handlePageClose = _ => this.setState({pageOpen: false});

    render() {
        return (
            <Fragment>
                <div className={"d-flex align-items-center justify-content-between p-3 border-top text-purple"}
                     onClick={this.handlePageOpen}>
                    <span>
                        <FaSolidIcon name={"cash-register"}/>&nbsp;
                        {i18n.t(`launcher.side-menu.${this.props.labelName}`)}
                    </span>
                </div>
                <ModalPortalHOC>
                    {
                        this.state.pageOpen &&
                        <CreateReceiptsPage
                            userNegozio={this.props.userNegozio}
                            onDismiss={this.handlePageClose}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DashboardModuloCassaButton.propTypes = {
    labelName: PropTypes.string.isRequired,
    userNegozio: PropTypes.object
}