import React, {Fragment} from "react";
import Page from "../BaseComponents/Page/Page";
import {FaSolidIcon} from "../FontAwesomeIcons";
import i18n from "../../i18n";
import PropTypes from "prop-types";
import {WalletsAPI} from "../../api/Wallets/WalletsAPI";
import {EllipsisLoader} from "../GenericUI/EllipsisLoader/EllipsisLoader";
import WalletsListView from "./WalletsListView";

export default class WalletsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wallets: {},
            fetching: false,
        }
    }

    componentDidMount() {
        this.fetchWallets()
    }

    fetchWallets = _ => {
        this.setState({fetching: true})
        WalletsAPI.User.getWallets(this.props.idNegozio || null)
            .then(data => {
                this.setState({
                    wallets: data,
                    fetching: false
                })
            })
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={i18n.t("launcher.side-menu.wallets")}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <div className={"container py-3"}>
                            <div>
                                In questa area puoi trovare le tue gift card e crediti prepagati collegati al tuo
                                account, clicca su ognuno per scoprire cosa puoi pagare
                            </div>
                            {
                                this.state.fetching &&
                                <div className={"w-100 text-center"}>
                                    <EllipsisLoader/>
                                </div>
                            }

                            {/*SUDDIVISIONE NEGOZI*/}
                            {
                                Object.keys(this.state.wallets).map(id_negozio => {
                                    return <WalletsListView
                                        key={id_negozio}
                                        showShopName={true}
                                        wallets={this.state.wallets[id_negozio]}
                                    />
                                })
                            }

                        </div>
                    }
                />
            </Fragment>
        )
    }
}


WalletsPage.propTypes = {
    onDismiss: PropTypes.func,
    idNegozio: PropTypes.number || null
}
