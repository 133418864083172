import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import Page from "../../../../components/BaseComponents/Page/Page";
import styled from "styled-components";
import TavoloServizioPage from "./TavoloServizioPage";

const SalaTab = styled.div`
    display:inline-block;
    background-color:${props => props.active ? '#293069' : '#fff'};
    color:${props => props.active ? '#fff' : '#293069'};
`;

const TavoloButton = styled.div`
    height:30vw;
`;
const TavoloInfoButton = styled.div`
    position:absolute;
    right:5px;
    bottom:5px;
`;


export default class ScegliTavoloModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id_sala: this.props.sale && this.props.sale.length ? this.props.sale[0].id_sala : '',
            id_servizio: null, //Indica il servizio di cui voglio vedere le comande
        }
    }

    setSala = id_sala => {
        this.setState({id_sala: id_sala});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sale && this.props.sale.length && this.state.id_sala === null) {
            this.setState({id_sala: this.props.sale[0].id_sala});
        }
    }

    openServizioPage = id_servizio => _ => this.setState({id_servizio: id_servizio});
    closeServizioPage = _ => this.setState({id_servizio: null});

    render() {
        let ordiniBancoPermission = this.props.permissions !== null ? this.props.permissions['ordini_banco'] || false : false;
        return (
            <Fragment>
                <Page
                    title={"Scegli un tavolo"}
                    active={this.props.active}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                >
                    <div className={"container pt-4"}>
                        {
                            !!this.props.sale && this.props.sale.map(s => {
                                return (
                                    <SalaTab
                                        key={s.id_sala}
                                        className={"border p-2"}
                                        active={this.state.id_sala === s.id_sala}
                                        onClick={_ => this.setSala(s.id_sala)}
                                    >
                                        {s.nome}
                                    </SalaTab>
                                )
                            })
                        }

                        {
                            (!!this.state.id_sala && this.state.id_sala !== 0) &&
                            <div className={"row"}>
                                {
                                    this.props.sale.find(s => s.id_sala === this.state.id_sala).tavoli.map(tavolo => {
                                        return <div key={tavolo.id_tavolo} className={"col-4 py-3"}>
                                            <TavoloButton
                                                className={`shadow d-flex align-items-center justify-content-center bg-white ${!!tavolo.id_servizio && 'bg-soft-primary text-primary'}`}>
                                                <div
                                                    onClick={_ => this.props.onChoose(tavolo.id_tavolo)}>
                                                    <h4 className={"text-center p-1"}>{tavolo.nome}</h4>
                                                    <div
                                                        className={"small text-left px-2 mt-auto"}>{!!tavolo.id_servizio ? `Coperti ${tavolo.coperti}` : 'Libero'}</div>
                                                </div>
                                                <TavoloInfoButton className={"py-1 px-2 bg-primary shadow"}
                                                                  onClick={this.openServizioPage(tavolo.id_servizio)}>
                                                    <FaSolidIcon name={"info-circle"}/>
                                                </TavoloInfoButton>
                                            </TavoloButton>
                                        </div>
                                    })
                                }
                            </div>
                        }

                        {
                            ordiniBancoPermission !== false &&
                            <div className={"mt-5"}>
                                <h4>Ordini al Banco</h4>
                                {
                                    `${ordiniBancoPermission}` === 'rw'
                                        ?
                                        <div className={"d-flex align-items-center justify-content-around"}>
                                            <button className={"btn btn-link text-blue"} onClick={_ => this.props.onChoose(0)}>
                                                <i className={"fas fa-plus-circle"}/> Aggiungi Ordine
                                            </button>
                                            <div>
                                                <button className={"btn btn-link text-primary"} onClick={this.openServizioPage(0)}>
                                                    Visualizza Ordini <i className={"fas fa-info-circle"}/>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <button className={"btn btn-link text-primary"} onClick={this.openServizioPage(0)}>
                                                <i className={"fas fa-info-circle"}/> Visualizza Ordini
                                            </button>
                                        </div>
                                }
                            </div>
                        }
                    </div>

                </Page>
                {/*Aggiunta props isReadOnly per capire se devo mostrare le azioni per ogni comanda*/}
                <TavoloServizioPage active={this.state.id_servizio !== null} onDismiss={this.closeServizioPage}
                                    idServizio={this.state.id_servizio} isReadOnly={(ordiniBancoPermission === 'r' && this.state.id_servizio === 0)}/>
            </Fragment>
        )
    }
}


ScegliTavoloModal.propTypes = {
    sale: PropTypes.array,
    onDismiss: PropTypes.func,
    active: PropTypes.bool,
    onChoose: PropTypes.func
};

ScegliTavoloModal.defaultProps = {
    sale: [],
    onDismiss: _ => {
    },
    active: false,
    onChoose: _ => {
    },
    permissions: [],
};

