import React, {Fragment} from "react";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import NegozioAPI from "../../api/NegozioAPI";
import {swal, swalLoading} from "../../helpers/SweetAlertWrapper";
import {AppIconThumbnail} from "../BaseComponents/AppIconThumbnail";
import {FaSolidIcon} from "../FontAwesomeIcons";
import ModalPortal from "../BaseComponents/ModalPortal";
import ProfiloAttivitaContainer from "../ProfiloAttivita/ProfiloAttivitaContainer";
import AppConfig from "../../config/AppConfig";
import styled from "styled-components";
import {ADD_APP_REQUEST, OPEN_APP_REQUEST} from "../../const/AppEventsCostants";
import ProfiloAttivitaPanel from "../ProfiloAttivita/ProfiloAttivitaPanel";
import AppModalPortal from "../BaseComponents/AppModalPortal";
import NegoziPartnershipAPI from "../../api/NegoziPartnership/NegoziPartnershipAPI";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";
import menuDigitaleIcon from "../../img/menu-digitale.svg";
import ShopFeaturesIcon from "../ShopFeaturesIcon";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import {FuxRating} from "../FuxRating";
import StaticRating from "../../launcher/Recensioni/StaticRating";


export default class PartnersAppItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            previewOpen: false,
            infoNegozio: null
        }
    }

    componentDidMount() {
        FuxEvents.on('RecommenderTabAppAdded', _ => this.setState({previewOpen:false}));
    }

    getMapLink(sede) {
        var mapLink = 'https://maps.google.com/maps?q=';
        if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
            mapLink = 'http://maps.apple.com/?q=';
        }
        var queryString = (`${!!this.props.lat ? `${this.props.lat},${this.props.lng},` : ''}${sede.indirizzo},${sede.citta},${sede.nome}`);
        return `${mapLink}${queryString}`;
    }

    handleOnAppClick = id_negozio => {
        NegoziPartnershipAPI.salvaVisite(NegozioOpenStore.id_negozio, id_negozio, this.props.id_lista ?? null);
        FuxEvents.emit(OPEN_APP_REQUEST, id_negozio);
    }

    handleStopPropagation = e =>{
        e.stopPropagation()
    }

    render() {
        return (
            <Fragment>
                <CardOfApp className={"card my-2 " + (this.props.id_lista ? "ml-2" : "")}
                           onClick={_ =>this.handleOnAppClick(this.props.id_negozio)}
                >
                    <div className={"card-body text-center shadow-sm px-2 py-2"}>
                        <AppIconThumbnail
                            className={"my-2 bg-white shadow"}
                            size={"60px"}
                            src={`${AppConfig.webServerUrl}/api/negozio/${this.props.id_negozio}/logoIcon/100`}
                            circle={true}
                        />
                        <AppNameContainer>
                            <span className={"text-truncate w-100"}>{this.props.nome}</span><br/>
                            <small>{this.props.categoria}&nbsp;</small>
                        </AppNameContainer>

                        {/* Rating */}
                        <div className={"container mt-0 py-2"}>
                        {
                            (!!this.props.valutazione) ?
                                <div className={"d-flex align-items-center justify-content-center"}>
                                    {
                                        !!this.props.valutazione &&
                                        <Fragment>
                                            <small>
                                                <StaticRating actualRating={parseInt(this.props.valutazione)} maxRating={5}/>
                                            </small>
                                        </Fragment>
                                    }

                            </div>
                                :
                            <div className={"d-flex align-items-center justify-content-center"}>
                                <small style={{visibility: "hidden"}}>
                                    <StaticRating actualRating={parseInt("5")} maxRating={5}/>
                                </small>
                            </div>
                        }
                        </div>

                        <div className={"my-1"}>
                            <div className={"row justify-content-center"}>

                                {
                                    this.props.sedi[0].telefono &&
                                    <div className={"col-3 text-center px-1"}>
                                        <a href={`tel:${this.props.sedi[0].telefono}`} onClick={this.handleStopPropagation}>
                                            <PulsanteCircle
                                                className={"d-flex align-items-center justify-content-center mx-auto text-primary"}>
                                                <FaSolidIcon name={"phone"}/>
                                            </PulsanteCircle>
                                        </a>
                                    </div>
                                }

                                {
                                    this.props.sedi[0].email &&
                                    <div className={"col-3 text-center px-1"}>
                                        <a href={`mailto:${this.props.sedi[0].email}`} onClick={this.handleStopPropagation}>
                                            <PulsanteCircle
                                                className={"d-flex align-items-center justify-content-center mx-auto text-primary"}>
                                                <FaSolidIcon name={"envelope"}/>
                                            </PulsanteCircle>
                                        </a>
                                    </div>
                                }

                                {
                                    this.props.sedi[0] &&
                                    <div className={"col-3 text-center px-1"}>
                                        <a href={this.getMapLink(this.props.sedi[0])} onClick={this.handleStopPropagation}>
                                            <PulsanteCircle
                                                className={"d-flex align-items-center justify-content-center mx-auto text-primary"}>
                                                <FaSolidIcon name={"map-marker-alt"}/>
                                            </PulsanteCircle>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </CardOfApp>
            </Fragment>
        )
    }

}

const CardOfApp = styled.div`
    min-width: 40vw;
    border: 0;
`;


const PulsanteCircle = styled.div`
    width: 35px;
    height: 35px;
    border:1px solid;
    border-radius:50%;
`;


const AppNameContainer = styled.div`
    text-overflow:ellipsis;
    overflow:hidden;
    
    .d-flex-column &{
        max-width:100%;
    }
    
    & small{
        display:block;
        margin-top:-2px;
        max-width:100%;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
    }
`;