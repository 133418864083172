import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AppConfig from "../config/AppConfig";
import AuthAPI from "./AuthAPI";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";


var ComandeAPI = {
    iniziaServizio: function (id_tavolo, coperti) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard/comande/inizia-servizio`), {
                token: AuthAPI.currentUser.accessToken,
                id_tavolo: id_tavolo,
                coperti
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    placeOrder: function(prodotti, id_servizio, note){
        let params = {
            id_negozio: NegozioOpenStore.id_negozio,
            token: AuthAPI.currentUser.accessToken,
            prodotti:prodotti,
            id_servizio:id_servizio,
            note: note
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard/comande/place-order`), params,
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getComande: function(id_servizio){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/dashboard/comande/${id_servizio}/lista-comande/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    setProdottoConsegnato: function(id_comanda, id_prodotto, aggiunte_hash){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard/comande/set-prodotto-consegnato`), {
                id_comanda: id_comanda,
                id_prodotto: id_prodotto,
                aggiunte_hash: aggiunte_hash,
                token: AuthAPI.currentUser.accessToken
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    eliminaProdotto: function(id_comanda, id_prodotto, aggiunte_hash){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/dashboard/comande/elimina-prodotto`), {
                id_comanda: id_comanda,
                id_prodotto: id_prodotto,
                aggiunte_hash: aggiunte_hash,
                token: AuthAPI.currentUser.accessToken
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    }
}

export default ComandeAPI
