import React, {Fragment} from "react";
import styled, {keyframes} from "styled-components";
import {slideInUp} from "react-animations";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import AllergeniProdottiList from "./AllergeniProdottiList";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import AppConfig from "../../config/AppConfig";
import {SwipeablePanel} from "../BaseComponents/SwipeablePanel/SwipeablePanel";
import {ProductImageSize} from "../../const/ImageSizeCostants";

const slideInUpAnimation = keyframes`${slideInUp}`;

const ProductDetailWrapper = styled.div`
    width:100vw; 
    height:75vh;
    bottom:0px; left:0px; z-index:3000;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    overflow:auto;
`;

const ProductDetailBackdrop = styled.div`
    &.active{
        position:fixed;
        top:0; left:0; height:100vh; width:100vh;
        z-index:3000; background-color:rgba(0,0,0,0.7);
    }
`;

const ProductDetailImage = styled.div`
    width:100%;
    height:40vh;
    background-size:cover;
    background-position:center center;
    background-image:url(${props => props.src});
`;

const Badge = styled.span`
    & + span{
        margin-left:2px;
    }
`;

export default class ProdottoDetail extends React.PureComponent {

    constructor(props) {
        super(props);
        this.ref = null;
    }

    getRef = node => this.ref = node;

    render() {
        return (
            <div>
                <ProductDetailBackdrop
                    onClick={this.props.onDismiss}
                    className={this.props.active ? 'active __androidDismissable__' : ''}
                />
                <SwipeablePanel
                    swipeTreshold={50}
                    direction={'top-to-bottom'}
                    active={!!this.props.active}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={_ => !this.ref || this.ref.scrollTop > 0}
                >
                    <ProductDetailWrapper
                        className={"bg-main-z3 text-on-bg-main " + (this.props.active ? 'active' : '')}
                        ref={this.getRef}
                    >
                        {this.props.immagine && <ProductDetailImage className={"img-card-top"}
                                                                    src={getCompressedImageUrl(this.props.immagine, ProductImageSize.preview.w, ProductImageSize.preview.h)}/>}

                        <div className={"description bg-main-z3 p-3 mt-2"}
                             style={{borderTopLeftRadius: 20, borderTopRightRadius: 20, marginTop: -20}}>
                            <p className={"lead font-weight-bold text-on-bg-main mb-0"}>
                                {this.props.nome} <span
                                className={"text-secondary ml-2"}>{`€${parseFloat(this.props.prezzo).toFixed(2)}`}</span>
                            </p>

                            {
                                !!this.props.ingredienti &&
                                <IngredientiContainer className={"pl-3 my-3"}>
                                    <span className={"bg-secondary mark"}/>
                                    <small>
                                        <i>
                                            <SafeHtmlContainer html={`Ingredienti: ${this.props.ingredienti}`}/>
                                        </i>
                                    </small>
                                </IngredientiContainer>
                            }


                            {
                                this.props.badges ?
                                    this.props.badges.map((b, i) => {
                                        return (
                                            <Badge key={i} className={"badge badge-primary mt-2"}>
                                                {b}
                                            </Badge>
                                        );
                                    })
                                    : ''
                            }

                            <div>
                                {parseInt(this.props.minimo_ordinabile) > 0 ?
                                    <span
                                        className={"badge badge-primary mr-2"}>{`Min. ${this.props.minimo_ordinabile}`}</span> : ''}
                                {parseInt(this.props.massimo_ordinabile) > 0 ?
                                    <span
                                        className={"badge badge-primary mr-2"}>{`Max. ${this.props.massimo_ordinabile}`}</span> : ''}

                            </div>
                            <div className={"my-3"}>
                                <SafeHtmlContainer html={this.props.descrizione}/>
                            </div>
                            {
                                !!this.props.allergeni && !!this.props.allergeni.length &&
                                <div className={"my-3"}>
                                    <b>Allergeni presenti:</b>
                                    <div className={"d-flex flex-wrap"}>
                                        <AllergeniProdottiList
                                            allergeni={this.props.allergeni}
                                            className={"d-block my-2 mr-3"}
                                            icon={true}
                                            text={true}
                                            size={30}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                !!this.props.opzioni && !!this.props.opzioni.length &&
                                <div className={"my-3"}>
                                    <b>Opzioni disponibili</b>
                                    <div className={"list-group shadow"}>
                                        {
                                            this.props.opzioni.map(o => {
                                                const prezzo = parseFloat(o.prezzo);
                                                const labelPrezzo = prezzo > 0 ? `+ €${prezzo.toFixed(2)}` :
                                                    <span className={"text-success"}>Gratis</span>;
                                                return (
                                                    <div className={"list-group-item bg-main-z2"} key={o.id_opzione}>
                                                        <div className={"row"}>
                                                            <div className={"col-8"}>
                                                                {o.nome}
                                                            </div>
                                                            <div className={"col text-right text-secondary"}>
                                                                {labelPrezzo}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !!this.props.aggiunte && !!this.props.aggiunte.length &&
                                <div className={"my-3"}>
                                    <b>Aggiunte disponibili</b>
                                    <div className={"list-group shadow"}>
                                        {
                                            this.props.aggiunte.map(a => {
                                                const prezzo = parseFloat(a.prezzo);
                                                let labelPrezzo = '';
                                                if (prezzo === -1) {
                                                    labelPrezzo = '';
                                                } else {
                                                    labelPrezzo = prezzo > 0 ? `+ €${prezzo.toFixed(2)}` :
                                                        <span className={"text-success"}>Gratis</span>;
                                                }
                                                return (
                                                    <div
                                                        className={"list-group-item bg-main-z2 " + (prezzo === -1 ? 'bg-secondary text-on-bg-secondary' : '')}
                                                        key={a.id_aggiunta}
                                                    >
                                                        <div className={"row"}>
                                                            <div className={"col-8"}>
                                                                {a.nome}
                                                            </div>
                                                            <div className={"col text-right text-secondary"}>
                                                                {labelPrezzo}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>


                    </ProductDetailWrapper>
                </SwipeablePanel>
            </div>
        )
    }

}


const IngredientiContainer = styled.div`
  position:relative;
  & > .mark{
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    width:3px;
  }
`;
