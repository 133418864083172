import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {AppIconThumbnail} from "../../../../components/BaseComponents/AppIconThumbnail";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import AuthAPI from "../../../../api/AuthAPI";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {UserChatAPI} from "../../../../api/Chat/UserChatAPI";
import {ChatRoomView} from "../../../../components/ChatPanel/ChatRoom/ChatRoomView";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {UserAdminChatAPI} from "../../../../api/Chat/UserAdminChatAPI";
import APIConfig from "../../../../api/APIConfig";
import {DLA_ADMIN_CHAT_MESSAGE} from "../../../../const/DeepLinkActionsCostants";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class BusinessWithUserChatPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roomData: null
        }
    }

    isSwipeDisabled = _ => false;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open) {
            document.body.classList.add("noscroll");
        } else {
            document.body.classList.remove("noscroll");
        }

        if (this.props.open && !prevProps.open && !this.state.roomData) {
            this.fetchRoomData();
        }
    }

    fetchRoomData = _ => {
        UserAdminChatAPI.getChatRoom(this.props.idUtente)
            .then(data => {
                this.setState({roomData: data})
            });
    }

    handleClose = _ => {
        this.setState({roomData: null});
        this.props.onClose();
    }

    render() {
        const imageStyle = {
            backgroundImage: `url("${APIConfig.url(`/utenti/profile-pic/${AuthAPI.currentUser.accessToken}/${this.props.idUtente}/400`)}")`,
            width: 48,
            height: 48,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }
        return (
            <Fragment>
                <ModalPortalHOC>
                    {this.props.open && <div style={BackdropStyle}/>}
                    <SwipeablePanel
                        swipeTreshold={5}
                        direction={'top-to-bottom'}
                        active={this.props.open}
                        onDismiss={this.handleClose}
                        disableSwipe={this.isSwipeDisabled}
                        animationSpeed={150}
                    >
                        <div className={"container-fluid rounded-top overflow-hidden bg-white py-2"}>
                            <div className={"d-flex align-items-center justify-content-between"}>
                                <div className={"d-flex align-items-center w-75"}>
                                    <div className={"mr-2 rounded-circle border"}  style={imageStyle}/>
                                    <div className={"text-truncate w-75"}>
                                        <SafeHtmlContainer html={this.props.label}/>
                                    </div>
                                </div>
                                <div className={"d-flex align-items-center text-right"}>
                                    <button className={"btn btn-link text-primary"} onClick={this.handleClose}>
                                        <FaSolidIcon name={"times"}/>
                                    </button>
                                    {
                                        !!this.props.onCollapse &&
                                        <button className={"btn btn-link text-primary"} onClick={this.props.onCollapse}>
                                            <FaSolidIcon name={"compress"}/>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={"__disable-swipe"}>
                            {
                                (this.props.open && !!this.state.roomData) &&
                                <ChatRoomView
                                    idRoom={this.state.roomData.id_room}
                                    idSelf={this.props.idSelf}
                                    getMessageAPI={UserAdminChatAPI.getMessages}
                                    getMediaContentUrlAPI={UserAdminChatAPI.getMediaContentUrl}
                                    sendMessageAPI={UserAdminChatAPI.sendTextMessage}
                                    setReadAPI={UserAdminChatAPI.setRead}
                                    sendNotificationAPI={UserAdminChatAPI.sendMessageNotification}
                                    fetchMessageEventName={DLA_ADMIN_CHAT_MESSAGE}
                                />
                            }
                            {
                                (this.props.open && !this.state.roomData) &&
                                <EllipsisLoader/>
                            }
                        </div>
                    </SwipeablePanel>
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

BusinessWithUserChatPanel.propTypes = {
    idUtente: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    idSelf: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCollapse: PropTypes.func,
}