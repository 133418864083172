import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SeatsBookType} from "../../../../components/ModuloSeats/PropTypes/BookType";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {BookDetailPage} from "./BookDetailPage/BookDetailPage";

export class BookItemView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
        }
    }

    handleSetEntranceDate = e => {
        e.stopPropagation();
        this.props.onSetEntranceDate(this.props.book.book_id);
    }

    handleOpenBookDetail = _ => this.setState({detailOpen: true});
    handleCloseBookDetail = _ => this.setState({detailOpen: false});

    render() {
        const book = this.props.book;
        return (
            <Fragment>
                <div className={"d-flex align-items-center justify-content-between"} onClick={this.handleOpenBookDetail}>
                    <div className={"d-flex align-items-center"}>
                        {
                            !!book.entrance_date &&
                            <FaSolidIcon name={"check-circle"} className={"text-success mr-2"}/>
                        }
                        <div>
                            <b>{book.seat_internal_code}</b> - {book.customer_first_name} {book.customer_last_name}
                            <div className={"small text-muted"}>
                                {book.start_time} - {book.end_time}
                            </div>
                        </div>
                    </div>
                    {
                        !book.entrance_date &&
                        <button className={"btn btn-outline-success"} onClick={this.handleSetEntranceDate}>
                            <FaSolidIcon name={"sign-in-alt"}/>
                        </button>
                    }
                </div>
                <ModalPortalHOC>
                    {
                        this.state.detailOpen &&
                            <BookDetailPage
                                onDismiss={this.handleCloseBookDetail}
                                bookId={this.props.book.book_id}
                                onSetEntranceDate={this.props.onSetEntranceDate}
                            />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

BookItemView.propTypes = {
    book: SeatsBookType,
    onSetEntranceDate: PropTypes.func
}