import PropTypes from "prop-types";

export const SeatsMapType = PropTypes.shape({
    map_id: PropTypes.any,
    id_negozio: PropTypes.any,
    name: PropTypes.string,
    description: PropTypes.string,
    cover_image: PropTypes.string,
    map_image: PropTypes.string,
    pricing_model: PropTypes.string,
    require_partecipants_number: PropTypes.number,
    allow_seat_overbooking: PropTypes.number,
    payment_advance_perc: PropTypes.number,
    payment_advance_min_price: PropTypes.number,
    enable_full_payment_advance: PropTypes.number,
    send_book_confirmation_sms: PropTypes.number,
    disable_ticket_advance_payment: PropTypes.number,
    disable_ticket_receipt: PropTypes.number,
    automatic_receipt: PropTypes.number,
    max_book_seats: PropTypes.number,
    id_tax_code: PropTypes.number,
    address: PropTypes.string,
    city: PropTypes.string,
    postal_code: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    cancel_advance_time: PropTypes.number,
});