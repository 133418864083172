import React, {Fragment} from "react";
import IndirizziAPI, {INDIRIZZI_TYPE_INVOICE, INDIRIZZI_TYPE_NORMAL} from "../../../api/IndirizziAPI";
import {MARKETPLACE_SHIPPING_ADDRESS_SELECTED} from "../../../const/AppEventsCostants";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {EllipsisLoader} from "../../GenericUI/EllipsisLoader/EllipsisLoader";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";
import PropTypes from "prop-types";
import AddAddressModal from "../../Modals/AddAddressModal";
import AddressBook from "../../AddressBook";
import {PageZLayerSelector} from "../../BaseComponents/Page/PageZLayerSelector";
import {Trans} from "react-i18next";
import i18n from "i18next";


export class EcommerceBillingAddressBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addressList: null,
            selected: null,
            addressChooserOpen: false,
            createAddressOpen: false,
            requestBill: false
        }
    }

    componentDidMount() {
        IndirizziAPI.list(INDIRIZZI_TYPE_INVOICE)
            .then(list => this.setState({addressList: list, selected: null}));
    }

    getSelected = _ => {
        return this.state.addressList.find(i => i.id_indirizzo === this.state.selected);
    }

    handleCheckboxChange = ({target}) => this.setState(
        {[target.name]: target.checked, selected: target.checked ? this.state.selected : null},
        _ => {
            if (!target.checked) {
                this.props.onChange(null);
            }
        }
    );

    toggleAddressCreate = _ => this.setState({createAddressOpen: !this.state.createAddressOpen});

    toggleAddressChooser = _ => this.setState({addressChooserOpen: !this.state.addressChooserOpen})

    handleBillingAddressChoose = ({id_indirizzo, data}) => {
        const exists = !!this.state.addressList.find(i => i.id_indirizzo === id_indirizzo);
        const addressList = exists ? [...this.state.addressList] : [...this.state.addressList, data];
        this.setState({
            addressList: addressList,
            selected: id_indirizzo,
            addressChooserOpen: false
        });
        this.props.onChange({id_indirizzo, data})
    }

    render() {

        if (!this.state.addressList) {
            return (
                <div className={"bg-white p-3 shadow-sm text-center"}>
                    <EllipsisLoader/>
                </div>
            )
        }

        const selected = this.getSelected();
        return (
            <Fragment>
                {
                    this.state.requestBill && <div className={"bg-white p-3 shadow-sm"}>
                        {
                            !!selected &&
                            <div className={"d-flex align-items-center justify-content-between"}
                                 onClick={this.toggleAddressChooser}>
                                <div className={"text-truncate mr-1"}>
                                    <FaSolidIcon name={"file-invoice-dollar"}/>&nbsp;
                                    <Trans i18nKey={"ecommerce.cart.checkout.billing.bill-to-template"} values={{
                                        userName: selected.ragione_sociale,
                                        address: selected.indirizzo
                                    }}>
                                        Fattura a <b>{selected.ragione_sociale}</b> <span>{selected.indirizzo}</span>
                                    </Trans>
                                </div>
                                <FaSolidIcon name={"chevron-right"}/>
                            </div>
                        }
                        {
                            !selected &&
                            <div className={"text-center"}>
                                <button className={"btn btn-link text-primary"} onClick={this.toggleAddressChooser}>
                                    <FaSolidIcon
                                        name={"file-invoice-dollar"}/> {i18n.t('ecommerce.cart.checkout.billing.choose-btn')}
                                </button>
                            </div>
                        }
                    </div>
                }

                <div className={"container-fluid"}>
                    <div className={"form-group mt-2"}>
                        <label>
                            <input type={"checkbox"} name={"requestBill"} checked={this.state.requestBill}
                                   onChange={this.handleCheckboxChange}/>
                            {i18n.t('ecommerce.cart.checkout.billing.request-invoice-label')}
                        </label>
                    </div>
                </div>

                <ModalPortalHOC>
                    <PageZLayerSelector zIndex={2001}>
                        {
                            this.state.addressChooserOpen && <AddressBook
                                active={true}
                                invoiceData={true}
                                title={i18n.t('ecommerce.cart.checkout.billing.title')}
                                subtitle={i18n.t('ecommerce.cart.checkout.billing.choose-btn')}
                                onChoose={this.handleBillingAddressChoose}
                                onDismiss={this.toggleAddressChooser}
                                useFlag={false}
                                onCreateNew={this.toggleAddressCreate}
                            />
                        }
                        {
                            this.state.createAddressOpen &&
                            <AddAddressModal
                                invoiceData={false}
                                onDismiss={this.toggleAddressCreate}
                            />
                        }
                    </PageZLayerSelector>
                </ModalPortalHOC>

            </Fragment>
        )
    }

}

EcommerceBillingAddressBar.propTypes = {
    onChange: PropTypes.func
}
