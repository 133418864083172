import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../FontAwesomeIcons";
import './AudioPlayer.css';
import './AudioPlayerMine.css';
import './AudioPlayerYours.css';

export class AudioPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false,
            duration: null,
            currentTime: 0,
            canPlay: false,
        }
        this.seekInterval = null;
        this.audioRef = React.createRef();
        this.audio = null;
    }

    componentDidMount() {
        this.initAudio();
    }

    initAudio = _ => {
        this.audio = new Audio(this.props.src);

        //Check if buffering is enough
        this.audio.addEventListener('canplaythrough', _ => {
            this.setState({
                canPlay: true
            });
        });

        const getDuration = _ => {
            this.audio.currentTime = 0
            this.audio.removeEventListener('timeupdate', getDuration)
            this.setState({
                duration: this.audio.duration
            });
        }

        this.audio.addEventListener('loadedmetadata', () => {
            if (this.audio.duration === Infinity || isNaN(Number(this.audio.duration))) {
                this.audio.currentTime = 1e101
                this.audio.addEventListener('timeupdate', getDuration)
            }else{
                this.setState({
                    duration: this.audio.duration
                });
            }
        });

        this.audio.addEventListener('ended', _ => {
            this.setState({
                currentTime: 0,
                isPlaying: false
            }, _ => {
                this.audio.currentTime = 0;
            })
        });

        this.audio.load();
    }

    isAudioLoaded = _ => this.state.canPlay && this.state.duration !== Infinity && this.state.duration;

    handlePlay = _ => {
        if (this.isAudioLoaded()) {
            this.setState({isPlaying: true}, _ => {
                this.audio.play();
                setInterval(this.updateSeek, 100);
            })
        }
    }

    handlePause = _ => {
        if (this.isAudioLoaded()) {
            this.setState({isPlaying: false}, _ => {
                this.audio.pause();
                if (this.seekInterval) clearInterval(this.seekInterval);
            })
        }
    }

    updateSeek = _ => {
        // Check if the current track duration is a legible number
        if (this.isAudioLoaded()) {
            this.setState({
                currentTime: this.audio.currentTime
            });
        }
    }

    handleSliderChange = ({target}) => {
        if (this.isAudioLoaded()) {
            const selectedTime = this.state.duration * (target.value / 100);
            this.setState({
                currentTime: selectedTime
            }, _ => {
                this.audio.currentTime = selectedTime;
            })
        }
    }

    render() {

        const progressPerc = this.state.duration ? this.state.currentTime * (100 / this.state.duration) : 0;

        return (
            <React.Fragment>
                <div className={"d-flex align-items-center audio-player"}>
                    {
                        this.state.canPlay ?
                            <React.Fragment>
                                {
                                    this.state.isPlaying ?
                                        <div className={'audio-control'} onClick={this.handlePause}>
                                            <FaSolidIcon name={"pause"}/>
                                        </div>
                                        :
                                        <div className={'audio-control'} onClick={this.handlePlay}>
                                            <FaSolidIcon name={"play"}/>
                                        </div>
                                }
                            </React.Fragment>
                            :
                            <FaSolidIcon name={"spin fa-spinner"}/>
                    }
                    <div className={"ml-2"}>
                        <div>
                            <input className={'audio-control'} type={"range"} min={0} max={100} value={progressPerc}
                                   disabled={!this.isAudioLoaded()} onChange={this.handleSliderChange}/>
                        </div>
                        <div className={"small"}>
                            {getDurationString(this.state.currentTime > 0 ? this.state.currentTime : (this.state.duration || 0))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

AudioPlayer.propTypes = {
    src: PropTypes.string,
    type: PropTypes.string
}


function getDurationString(duration) {
    const durationDate = new Date(duration * 1000).toISOString();
    return duration < 3600 ? durationDate.substring(14, 19) : durationDate.substring(11, 16);
}
