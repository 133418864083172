import React, {Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {swalConfirm, swalError, swalInput, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import GruppiAPI from "../../../../api/GruppiAPI";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {UPDATE_USER_BOOKS, USER_DISPUTE_REQUEST_EVT} from "../../../../const/AppEventsCostants";
import {BOOKIZON_PAY_METHODS} from "../../../../const/PaymentMethodoCostants";
import {ModuloGruppiAPI} from "../../../../api/ModuloGruppi/ModuloGruppiAPI";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {UserWithBusinessDisputePanel} from "../../../UserDisputes/UserWithBusinessDisputePanel";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";

export class GroupBookResumeEditView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disputeOpen: false,
        }
    }

    handleDeleteBook = _ => {
        swalConfirm("Sei sicuro di voler annullare questa prenotazione?", "Procedi", "Annulla")
            .then(_ => {
                swalLoading();
                GruppiAPI.remove(this.props.bookData.id_prenotazione)
                    .then(swalSuccess)
                    .then(this.props.onDelete)
                    .then(_ => FuxEvents.emit(UPDATE_USER_BOOKS))
                    .catch(swalError);
            })
    }

    handleRefund = _ => {
        swalInput(
            "Motiva la tua richiesta",
            "Scrivi il motivo per cui stai annullando la prenotazione e richiedendo il rimborso",
            "textarea", null, false, true
        ).then(({value}) => {
            if (!value) return;
            swalLoading('Attendere prego...');
            ModuloGruppiAPI.Books.requestRefund(this.props.bookData.id_prenotazione, value)
                .then(m => {
                    swalSuccess(m)
                        .then(_ => {
                            this.props.onUpdate();
                            FuxEvents.emit(USER_DISPUTE_REQUEST_EVT);
                        });
                }).catch(swalError)
        })
    }

    toggleDisputePanel = _ => this.setState({disputeOpen: !this.state.disputeOpen});

    render() {
        const now = moment().format('YYYY-MM-DD HH:mm:ss')
        if (this.props.bookData.datetime_inizio < now) return '';

        if (this.props.bookData.dispute) {
            return (
                <Fragment>
                    <div className={"alert alert-danger"}>
                        <strong>
                            Cancellazione richiesta
                        </strong>
                        <p>
                            Hai inviato una richiesta di rimborso e cancellazione all'attività.
                        </p>
                        <div className={"text-center"}>
                            <button className={"btn btn-link"} onClick={this.toggleDisputePanel}>
                                Vedi richiesta
                            </button>
                        </div>
                    </div>
                    <ModalPortalHOC>
                        {
                            this.state.disputeOpen &&
                            <UserWithBusinessDisputePanel
                                disputeData={this.props.bookData.dispute}
                                onClose={this.toggleDisputePanel}
                            />
                        }
                    </ModalPortalHOC>
                </Fragment>
            )
        }

        if (
            (this.props.bookData.riferimento_pagamento && BOOKIZON_PAY_METHODS.indexOf(this.props.bookData.metodo_pagamento) !== -1) ||
            this.props.bookData.pagamenti.length
        ) {
            return (
                <button className={"btn btn-block btn-danger mt-3"} onClick={this.handleRefund}>
                    <FaSolidIcon name={"trash"}/> Annulla e richiedi rimborso
                </button>
            )
        }

        return (
            <Fragment>
                <button className={"btn btn-block btn-danger mt-3"} onClick={this.handleDeleteBook}>
                    <FaSolidIcon name={"trash"}/> Annulla prenotazione
                </button>
            </Fragment>
        )
    }

}

GroupBookResumeEditView.propTypes = {
    bookData: PropTypes.object,
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
}
