import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AppConfig from "../config/AppConfig";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";

export const INDIRIZZI_TYPE_NORMAL = 0;
export const INDIRIZZI_TYPE_INVOICE = 1;

var IndirizziAPI = {
    list: function (type) {
        if (!type) {
            type = 0;
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/indirizzi/list/${AuthAPI.currentUser.accessToken}/${type}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listAll: function () {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/indirizzi/list/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    save: function (data) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/indirizzi/save/${AuthAPI.currentUser.accessToken}`), data,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    delete: function (id_indirizzo) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/indirizzi/delete/${AuthAPI.currentUser.accessToken}`), {id_indirizzo: id_indirizzo},
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    setPredefined: function (id_indirizzo) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/indirizzi/set-predefined`), {
                id_indirizzo: id_indirizzo,
                token: AuthAPI.currentUser.accessToken
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default IndirizziAPI;
