import AuthAPI from "../AuthAPI";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";

export const _User = {
    getPacchetti: function (id_negozio) {
        const body = {
            id_negozio: id_negozio,
            token: AuthAPI.getUserAccessToken()
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/pacchetti/user/get-pacchetti`), body,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getMovimenti: function (id_pacchetto, limit, offset) {
        const body = {
            id_pacchetto: id_pacchetto,
            limit: limit,
            offset:offset,
            token: AuthAPI.currentUser.accessToken
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/pacchetti/user/get-movimenti`), body,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
}