import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {FaBrandIcon, FaSolidIcon, FaIcon, FaRegularIcon} from "./FontAwesomeIcons";
import styled from "styled-components";

const Navbar = styled.div`
    height:9vh;
`;

const NavbarLogo = styled.img`
    max-width:100%;
    max-height:7vh;
`;

const NavbarLogoStyle = {maxWidth:"100%",maxHeight:"7vh"};

export default class NavBarTop extends React.Component{

    constructor(props){
        super(props);
    }

    render(){
        switch(this.props.navBarStyle){
            case 'navbar_logo_center':
                var children1 = <div className="col-3 p-0 text-center" onClick={this.props.leftIconClick}>{this.props.leftIcon}</div>;
                var children2 = <div className="col-6 p-0 text-center"><NavbarLogo src={this.props.imageSrc} style={NavbarLogoStyle}/></div>;
                var children3 = <div className="col-3 p-0 text-center" onClick={this.props.rightIconClick}>{this.props.rightIcon}</div>;
                break;
            case 'navbar_logo_right':
                var children1 = <div className="col-3 p-0 text-center" onClick={this.props.leftIconClick}>{this.props.leftIcon}</div>;
                var children2 = <div className="col-3 p-0 text-center" onClick={this.props.rightIconClick}>{this.props.rightIcon}</div>;
                var children3 = <div className="col-6 p-0 text-center"><NavbarLogo src={this.props.imageSrc} style={NavbarLogoStyle}/></div>;
            case 'navbar_logo_left':
            default:
                var children1 = <div className="col-6 p-0 text-center"><NavbarLogo src={this.props.imageSrc} style={NavbarLogoStyle}/></div>;
                var children2 = <div className="col-3 p-0 text-center" onClick={this.props.leftIconClick}>{this.props.leftIcon}</div>;
                var children3 = <div className="col-3 p-0 text-center" onClick={this.props.rightIconClick}>{this.props.rightIcon}</div>;break;
                break;
        }
        return (
            <Navbar className={"row shadow-sm py-2 mx-0 align-items-center bg-main-z2 text-primary fixed-top"}>
                {children1}
                {children2}
                {children3}
            </Navbar>
        );
    }

}

NavBarTop.defaultProps = {
    leftIcon:null,
    rightIcon:null,
    leftIconClick: function(){},
    rightIconClick: function(){},
    imageSrc:'',
    navBarStyle:'navbar_logo_left'
};

NavBarTop.propTypes = {
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    leftIconClick: PropTypes.func,
    rightIconClick: PropTypes.func,
    imageSrc: PropTypes.string,
    navBarStyle: PropTypes.string
};
