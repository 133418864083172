import React from "react";
import Styled from "styled-components";
import {fetchStyles} from "./BookizonRouter";
import {BookizonAppLoaderSplashscreen} from "./BookizonAppLoaderSplashscreen";
import NegozioAPI from "../api/NegozioAPI";
import {BookizonAppManager} from "../index";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";
import {swal} from "../helpers/SweetAlertWrapper";
import ProfiloAttivitaPage from "../components/ProfiloAttivita/ProfiloAttivitaPage";
import {SUBSCRIPTION_STATUS_ACTIVE} from "../const/SubscriptionConstants";
import {BookizonAppUnavailableSplashscreen} from "./BookizonAppUnavailableSplashscreen";

export class DirectProfiloAttivitaContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            style: '',
            infoNegozio: null
        }
    }

    componentDidMount() {
        //Recupera dati e stile del negozio
        let promises = [
            NegozioAPI.getRichInfo(this.props.id_negozio),
            fetchStyles(this.props.id_negozio)
        ];
        Promise.all(promises)
            .then(results => {
                let infoNegozio = results[0];
                if (typeof infoNegozio.social === "string") infoNegozio.social = JSON.parse(infoNegozio.social);
                if (typeof infoNegozio.impostazioni === "string") infoNegozio.impostazioni = JSON.parse(infoNegozio.impostazioni);
                if (typeof infoNegozio.aspetto === "string") infoNegozio.aspetto = JSON.parse(infoNegozio.aspetto);
                BookizonAppManager.setNativeTopUnsafeAreaColor(infoNegozio.aspetto.bg_main_z2, "circuito -> app");
                BookizonAppManager.setNativeBottomUnsafeAreaColor(infoNegozio.aspetto.bg_main_z2);

                const style = results[1];

                NegozioOpenStore.infoNegozio = infoNegozio;
                NegozioOpenStore.id_negozio = infoNegozio.id_negozio;
                this.setState({
                    infoNegozio: infoNegozio,
                    style: style
                }, _ => {
                    swal.safeClose();
                    document.getElementById("modal-root").classList.add("d-none");
                });
            });
    }

    getWrapper = () => Styled.div` ${this.state.style} `;

    render() {
        if (!this.state.style || !this.state.infoNegozio) {
            return <BookizonAppLoaderSplashscreen id_negozio={this.props.id_negozio}/>
        }


        if (this.state.infoNegozio.subscriptionStatus !== SUBSCRIPTION_STATUS_ACTIVE){
            return <BookizonAppUnavailableSplashscreen id_negozio={this.props.id_negozio}/>
        }

        const Wrapper = this.getWrapper();
        return (
            <Wrapper id={"app"}>
                <div className={"bg-main"} style={{"minHeight": "100vh"}}>
                    <ProfiloAttivitaPage negozio={this.state.infoNegozio}/>
                    <div id={"app-modal-root"}/>
                </div>
            </Wrapper>
        )
    }
}
