import React from "react";
import GalleriaAPI from "../../api/GalleriaAPI";
import axios from "axios";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import moment from "moment";
import "../../css/placeholder-loading.css";
import emptyBox from "../../img/illustrations/empty.svg";
import {GalleryGridImage} from "../GalleryGrid/GalleryGridImage";
import i18n from "../../i18n";

const IMAGE_TYPE_GALLERY = "gallery";
const IMAGE_TYPE_OFFERS = "offers";

export default class ProfiloGridGallery extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            imageFetchLimit: 9,
            imageOffset: 0,
            imageTotal: null,
            images: [],
            loadingInternal: false,
            loadingInstagram: false
        }
    }

    componentDidMount() {
        if (this.props.id_negozio) {
            this.setState({loadingInternal: true});
            this.fetchInternalImages(this.state.imageFetchLimit, this.state.imageOffset);
        }
        //this.props.igProfile usato nel successivo if per recuperare instagram img
        if (null) {
            try {
                const url = new URL(this.props.igProfile);
                const username = url.pathname.split('/').filter(v => v).join('');
                this.fetchInstagramImages(username);
                this.setState({loadingInstagram: true});
            } catch (e) {
                console.warn("CATCH:", e);
            }
        }
    }

    fetchInternalImages = (limit, offset) => {
        GalleriaAPI.getImages(this.props.id_negozio, limit, offset)
            .then(data => {
                let images = data.immagini.map(image => {
                    let url = image.immagine;
                    return {
                        srcHD: url,
                        srcLD: getCompressedImageUrl(url, 300, 300),
                        timestamp: moment(image.data_creazione).toDate().getTime(),
                        instagram: false
                    }
                });
                let state = {images: [...this.state.images, ...images], loadingInternal: false};
                state.images = state.images.sort((a, b) => b.timestamp - a.timestamp)
                if (data.total) {
                    state.imageTotal = data.total;
                }
                this.setState(state);
            });
    }

    fetchInstagramImages = username => {
        axios.get("https://www.instagram.com/" + username)
            .then((response) => {
                let data = response.data;
                data = data.split("window._sharedData = ");
                data = data[1].split("<\/script>");
                data = data[0];
                data = data.substr(0, data.length - 1);
                data = JSON.parse(data);
                console.log(data);
                data = data.entry_data.ProfilePage[0].graphql.user;

                let images = [];
                const igImages = data.edge_owner_to_timeline_media.edges;
                igImages.map(image => {
                    const srcLD = image.node.thumbnail_resources[4].src || '';
                    const srcHD = image.node.display_url || '';
                    const postUrl = `https://www.instagram.com/p/${image.node.shortcode}/`;
                    images.push({
                        srcLD: srcLD,
                        srcHD: srcHD,
                        timestamp: image.node.taken_at_timestamp * 1000,
                        instagram: true
                    });
                });

                this.setState({
                    loadingInstagram: false,
                    images: [...this.state.images, ...images].sort((a, b) => b.timestamp - a.timestamp)
                })
            });
    }

    render() {
        return (
            <div className={"row"}>
                {
                    this.state.images.map((image, i) => {
                        return <GalleryGridImage key={i}
                                                 srcLD={image.srcLD}
                                                 srcHD={image.srcHD}
                                                 isInstagram={image.instagram}
                                                 borderColor={this.props.borderColor}/>
                    })
                }
                {
                    (this.state.loadingInternal || this.state.loadingInstagram) &&
                    [...(new Array(9))].map((v, i) =>
                        <div key={i} className={"col-4 ph-item p-0 m-0"}>
                            <div className={"ph-picture m-0"}/>
                        </div>
                    )
                }
                {
                    (!this.state.loadingInternal && !this.state.loadingInstagram) && !this.state.images.length &&
                    <div className={"text-center"}>
                        <h4 className={"text-primary"}>
                            {i18n.t('business-info.no-photos-message')}
                        </h4>
                        <img src={emptyBox} className={"img-fluid w-75 mx-auto"} alt={"Nessuna immagine disponibile"}/>
                    </div>
                }
            </div>
        )
    }
}

