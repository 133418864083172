import React from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaSolidIcon} from "../FontAwesomeIcons";
import AddressForm from "../Forms/AddressForm";
import PageUnmountable from "../BaseComponents/Page/PageUnmountable";

export default class EditAddressModal extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <PageUnmountable
                title={"Modifica indirizzo"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
                content={
                    <div className={"container text-on-bg-main pt-2"}>
                        <AddressForm
                            addressData={this.props.address}
                            invoiceData={parseInt(this.props.address.fatturazione) === 1}
                            onSuccess={this.props.onDismiss}
                        />
                    </div>
                }
            />
        );
    }

}


EditAddressModal.propTypes = {
    onDismiss: PropTypes.func,
};
