import React, {Fragment} from "react";
import OrdineCard from "../../../launcher/NextBooks/Ordini/OrdineCard";
import {
    RECENSIONI_TIPO_ORDINE, RECENSIONI_TIPO_PRENOTAZIONE_CAMERA,
    RECENSIONI_TIPO_PRENOTAZIONE_GRUPPO,
    RECENSIONI_TIPO_PRENOTAZIONE_SERVIZIO
} from "../../../const/RecensioniConstants";
import ServiceBookCard from "../../../launcher/NextBooks/ServiceBook/ServiceBookCard";
import GroupBookCard from "../../../launcher/NextBooks/GroupBooks/GroupBookCard";
import HotelNextBooksItem from "../../../launcher/NextBooks/HotelNextBooks/HotelNextBooksItem";
import {
    TIPO_OPERAZIONE_ORDINE_ECOMMERCE,
    TIPO_OPERAZIONE_ORDINE_FOOD, TIPO_OPERAZIONE_PRENOTAZIONE_CAMERA, TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI,
    TIPO_OPERAZIONE_PRENOTAZIONE_SERVIZI
} from "../../../const/OperazioniConstants";
import PropTypes from "prop-types";
import OrdineResumePage from "../../../launcher/NextBooks/Ordini/OrdineResumePage";
import {ServiceBookResumePage} from "../../../launcher/NextBooks/ServiceBook/ServiceBookResume/ServiceBookResumePage";
import {GroupBookResumePage} from "../../../launcher/NextBooks/GroupBooks/GroupBookResume/GroupBookResumePage";
import {HotelBookDetailModal} from "../../../launcher/NextBooks/HotelNextBooks/DetailModal/HotelBookDetailModal";



export default class OperationSelectView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        switch (this.props.type) {
            case TIPO_OPERAZIONE_ORDINE_ECOMMERCE:
            case TIPO_OPERAZIONE_ORDINE_FOOD:
                return <OrdineResumePage
                    id_ordine={this.props.id_operazione}
                    onDismiss={this.props.onDismiss}
                />


            case TIPO_OPERAZIONE_PRENOTAZIONE_SERVIZI:
                return <ServiceBookResumePage
                    idPrenotazione={this.props.id_operazione}
                    onDismiss={this.props.onDismiss}
                    showOpenApp={false}
                />

            case TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI:
                return <GroupBookResumePage
                    idPrenotazione={this.props.id_operazione}
                    onDismiss={this.props.onDismiss}
                    showOpenApp={false}
                />

            case TIPO_OPERAZIONE_PRENOTAZIONE_CAMERA:
                return <HotelBookDetailModal
                    idPrenotazione={this.props.id_operazione}
                    onDismiss={this.props.onDismiss}
                />
        }
    }
}

OperationSelectView.propTypes = {
    id_operazione: PropTypes.number,
    type: PropTypes.string,
    onDismiss: PropTypes.func
}