import React from "react";
import WalletAttivitaAPI from "../../api/Welfare/WalletAttivitaAPI";


const SaldoContabileStyle = {
    opacity: "0.5"
};


export default class AttivitaWelfareBalanceContent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            balanceContabile: null,
            balanceDisponibile: null
        }
    }

    fetchData = () => {
        WalletAttivitaAPI.getBalance(this.props.negozio.walletapikey)
            .then((data) => {
                this.setState({balanceContabile: data.saldoContabile, balanceDisponibile: data.saldoDisponibile});
            })
    }

    componentDidMount() {
        console.log("WE");
        if (this.props.active) {
            this.fetchData();
        }
    }

    render() {
        return (
            <div className={"container"}>
                {
                    this.state.balanceContabile !== null && this.state.balanceDisponibile !== null
                        ?
                        <div>
                            <span>Saldo disponibile</span>
                            <h1 className={"font-weight-bold"}>€ {this.state.balanceDisponibile}</h1>
                            <span className={"text-sx"} style={SaldoContabileStyle}>Saldo contabile</span>
                            <h4 style={SaldoContabileStyle}>€ {this.state.balanceContabile}</h4>
                        </div>
                        :
                        <div className={"container text-center lead mt-4"}>
                            <i className={"fas fa-spinner fa-spin"}/>
                        </div>
                }
            </div>
        );
    }
}