import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../../components/BaseComponents/Page/Page";
import OrdineCard from "./OrdineCard";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";

export class WaitingEcommerceOrdersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleAppOpen = codice => {
        this.props.onAppOpen(codice);
        this.props.onDismiss();
    }

    render() {
        return (
            <Page
                title={"Ordini e-commerce"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={true}
            >
                <div className={"container pt-3"}>
                    {
                        this.props.ordini.map(o =>
                            <div key={o.id_ordine} className={"mb-3"}>
                                <OrdineCard
                                    order={o}
                                    showOpenApp={true}
                                    onAppOpen={this.handleAppOpen}
                                />
                            </div>
                        )
                    }
                </div>
            </Page>
        )
    }

}

WaitingEcommerceOrdersPage.propTypes = {
    ordini: PropTypes.array,
    onAppOpen: PropTypes.func,
    onDismiss: PropTypes.func,
}