import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./AuthAPI";
import APIConfig from "./APIConfig";

const GalleriaAPI = {
    getImages: function(id_negozio, limit, offset){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/galleria/${id_negozio}/${limit}/${offset}`),
            {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default GalleriaAPI;
