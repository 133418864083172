import React from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelBackdrop} from "../../../../../components/BaseComponents/SwipeablePanel/SwipeablePanelBackdrop";
import {SwipeablePanelGripBar} from "../../../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {RecurringTypeChooserView} from "./RecurringTypeChooserView";
import {WeeklyOptionsView} from "./RecurringOptionsView/WeeklyOptionsView";
import {
    MonthlyOptionsView,
    WEEKDAY_NUMBER_1ST,
    WEEKDAY_NUMBER_2ND,
    WEEKDAY_NUMBER_3RD,
    WEEKDAY_NUMBER_4TH,
    WEEKDAY_NUMBER_LAST
} from "./RecurringOptionsView/MonthlyOptionsView";
import {swalError} from "../../../../../helpers/SweetAlertWrapper";
import {parseExpression} from "cron-parser"
import moment from "moment";
import {DatesAvailabilityListView} from "./DatesAvailabilityView/DatesAvailabilityListView";
import {RecurringCyclesChooserView} from "./RecurringCyclesChooserView";


export const RECURRING_TYPE_MONTHLY = 'monthly';
export const RECURRING_TYPE_WEEKLY = 'weekly';

export const RECURRING_MAX_BOOKS_NUM = 12;


export class RecurringDatesChooserPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recurringType: null,
            recurringOptions: null,
            recurringDates: null //It will be equal to an array of {date: 'Y-m-d', time:'H:i'}
        }
        this.panelContentStyle = {
            maxHeight: '90vh',
            overflow: 'auto'
        }
        this.scrollablePanelsRefs = [];
    }

    isSwipeDisabled = _ => {
        for (let i in this.scrollablePanelsRefs) {
            if (this.scrollablePanelsRefs[i] && this.scrollablePanelsRefs.scrollTop > 0) return true;
        }
        return false;
    }

    handleScrollablePanelRef = node => this.scrollablePanelsRefs.push(node);

    /** @MARK Recurring type/cycle  */
    setRecurringType = t => this.setState({recurringType: t});

    /**
     * Returns the view to show options selection for the given recurring type
     *
     * @param {String} type
     *
     * @return {React.Component}
     * */
    getRecurringTypeOptionsView = type => {
        return {
            [RECURRING_TYPE_WEEKLY]: WeeklyOptionsView,
            [RECURRING_TYPE_MONTHLY]: MonthlyOptionsView,
        }[type] || null;
    }


    /** @MARK Recurring options  */
    handleRecurringOptionsDismiss = _ => this.setState({recurringType: null, recurringOptions: null});

    handleRecurringOptionsChoose = options => {
        this.setState({recurringOptions: options});
    }


    handleRecurringCycleDismiss = _ => this.setState({recurringOptions: null});


    /**
     * @param {Object} cycleOptions
     * @property {String} cycleOptions.startDate
     * @property {Number} cycleOptions.amount
     * */
    handleRecurringCycleChoose = cycleOptions => {
        const options = {...this.state.recurringOptions, ...cycleOptions};
        const timeParts = this.props.time.split(":");
        let cronString = `${timeParts[1]} ${timeParts[0]} * * ${options.weekday}`;
        let interval= parseExpression(cronString, {
            currentDate: options.startDate
        });
        let dates;

        const maxDates = Math.min(RECURRING_MAX_BOOKS_NUM, options.amount) * parseInt(options.step);
        switch (this.state.recurringType) {
            case RECURRING_TYPE_WEEKLY:
                dates = [...Array(maxDates)].map(_ => {
                    const cronDate = interval.next();
                    const dateObj = new Date(cronDate.getFullYear(), cronDate.getMonth(), cronDate.getDate());
                    return {
                        date: moment(dateObj).format('YYYY-MM-DD'),
                        time: this.props.time,
                    }
                });
                break;
            case RECURRING_TYPE_MONTHLY:
                /**
                 * Come specificato in questa risposta https://stackoverflow.com/a/37628629 non è possibile definire
                 * nella cronstring sia il weekday (ultima parte) che il day-month (terza parte).
                 * Per cui per riconoscere l'i-esimo giorno della settimana del mese recuperiamo tutti i giorni della
                 * settimana del mese (es. tutti i lunedì) e si filtrano con un loop i giorni corretti che rientrano
                 * nelle regole dell'oggetto definito di seguito
                 * */

                const dayMonthRange = {
                    [WEEKDAY_NUMBER_1ST]: [1, 7], // First week
                    [WEEKDAY_NUMBER_2ND]: [8, 14], // Second week
                    [WEEKDAY_NUMBER_3RD]: [15, 21], // Third week
                    [WEEKDAY_NUMBER_4TH]: [22, 28], // Fourth week
                    [WEEKDAY_NUMBER_LAST]: [25, 31], // Last week
                }[options.weekdayNumber];

                dates = [...Array(maxDates * 5)].map(_ => { //Moltiplico per 5 perchè ho bisogno di almeno 5 date per mese. Se voglio l'ultimo lunedi del mese, siccome potrebbero esserci 5 lunedì, devo recuperare almeno (5 * Numero_mesi_voluti) giorni
                    const cronDate = interval.next();
                    const dayMonth = cronDate.getDate();
                    if (dayMonthRange[0] <= dayMonth && dayMonth <= dayMonthRange[1]) {
                        const dateObj = new Date(cronDate.getFullYear(), cronDate.getMonth(), cronDate.getDate());
                        return {
                            date: moment(dateObj).format('YYYY-MM-DD'),
                            time: this.props.time,
                        }
                    }
                    return null;
                }).filter((v, i) => !!v).slice(0, maxDates);
                break;
            default:
                return swalError("Tipo di ricorrenza non valido");
        }

        //Filtro le date in base allo step
        dates = dates.filter((d,i) => i % options.step === 0);

        this.setState({recurringDates: dates});

    }

    /** @MARK Dates availability  */

    handleDatesAvailabilityDismiss = _ => this.setState({recurringDates: null});

    render() {
        const RecurringOptionsChooserView = this.getRecurringTypeOptionsView(this.state.recurringType);
        const bookDate = moment(this.props.bookInfo.data, 'DD-MM-YYYY').toDate();
        const todayDate = new Date();
        const startDate = bookDate.getTime() < todayDate.getTime() ? todayDate : bookDate;
        return (
            <React.Fragment>
                <SwipeablePanelBackdrop onClick={this.props.onDismiss}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z3 rounded-top"}/>
                    <div ref={this.handleScrollablePanelRef} style={this.panelContentStyle}
                         className={"bg-main-z3 p-3"}>

                        {/** @MARK Recurring cycle (monthly/weekly) */}
                        {
                            !this.state.recurringType &&
                            <RecurringTypeChooserView
                                onChoose={this.setRecurringType}
                                onDismiss={this.props.onDismiss}
                            />
                        }

                        {/** @MARK Recurring cycle specific options + cycles number (amount) */}
                        {
                            RecurringOptionsChooserView &&
                            <div className={this.state.recurringOptions ? 'd-none' : ''}>
                                <RecurringOptionsChooserView
                                    onDismiss={this.handleRecurringOptionsDismiss}
                                    onChoose={this.handleRecurringOptionsChoose}
                                />
                            </div>
                        }

                        {/** @MARK Cycles number (amount) */}
                        {
                            !!this.state.recurringOptions &&
                            <div className={this.state.recurringDates ? 'd-none' : ''}>
                                <RecurringCyclesChooserView
                                    onDismiss={this.handleRecurringCycleDismiss}
                                    onChoose={this.handleRecurringCycleChoose}
                                    startDate={startDate}
                                />
                            </div>
                        }

                        {/** @MARK Availability for each recurring book date */}
                        {
                            !!this.state.recurringDates &&
                            <DatesAvailabilityListView
                                onDismiss={this.handleDatesAvailabilityDismiss}
                                onCheckDateTimeAvailability={this.props.onCheckDateTimeAvailability}
                                onDatesConfirm={this.props.onBookDates}
                                dates={this.state.recurringDates}
                                bookInfo={this.props.bookInfo}
                                onScrollablePanelRef={this.handleScrollablePanelRef}
                            />
                        }
                    </div>
                </SwipeablePanel>
            </React.Fragment>
        )
    }
}

RecurringDatesChooserPanel.propTypes = {
    onDismiss: PropTypes.func,
    onBookDates: PropTypes.func,
    time: PropTypes.string, //format HH:mm
    onCheckDateTimeAvailability: PropTypes.func,
    bookInfo: PropTypes.shape({
        data: PropTypes.string,
        id_sede: PropTypes.string,
        id_dipendente: PropTypes.string,
        id_servizio: PropTypes.array
    }),
}
