import React from 'react';
import {swalError} from "../../../../../helpers/SweetAlertWrapper";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {FaRegularIcon, FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import moment from "moment/moment";
import {PageFooter} from "../../../../../components/BaseComponents/Page/PageFooter";
import GruppiAPI from "../../../../../api/GruppiAPI";
import PropTypes from "prop-types";


export default class DashboardSessionsChooserPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onDismiss: props.onDismiss,
            onChoose: props.onChoose,
            selectedDate: null,
            selectedInterval: [],
            dateDisponibili: {},
            startDate: 'now',
            requestDirection: 'next',
            nextButtonClicks: 0,
            datesHistory: {}
        };

        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNextDays = this.handleNextDays.bind(this);
        this.handleBackDays = this.handleBackDays.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    componentDidMount() {
        this.fetchNewData(this.state);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState.startDate !== this.state.startDate || nextState.requestDirection !== this.state.requestDirection) {
            this.fetchNewData(nextState);
        }
        return true;
    }

    fetchNewData = state => {
        var newState = {};

        //Per evitare che le date visualizzate siano maggiori di quelle richieste dall'utente
        var maxDates = 6
        if (state.requestDirection === "next" && this.props.maxVisibleDays) {
            maxDates = (this.props.maxVisibleDays - Object.keys(this.state.datesHistory).length > 5) ? 6 : this.props.maxVisibleDays - Object.keys(this.state.datesHistory).length
        }

        //Questo calcolo di startDate permette di non includere la startDate tra le date prossime date richieste
        const startDate = state.startDate === 'now' ?
            //Se non ho una data di partenza uso quella attuale
            moment().format('DD-MM-YYYY') :
            state.requestDirection === 'next' ?
                //Se sto andando avanti allora devo partire dal giorno successivo all'ultima data disponibile (this.state.startDate)
                moment(state.startDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY')
                :
                //Se sto andando indietro allora devo partire dal giorno precedente alla prima data disponibile (this.state.startDate)
                moment(state.startDate, 'DD-MM-YYYY').subtract(1, 'day').format('DD-MM-YYYY');

        GruppiAPI.getAvailableDates(this.props.id_gruppo, startDate, state.requestDirection, this.props.partecipanti, maxDates)
            .then((dateDisponibili) => {
                newState.dateDisponibili = dateDisponibili;
                newState.selectedDate = Object.keys(dateDisponibili)[0];

                /**
                 * In caso di direction "prev" allora rimuovo le date attualmente visualizzate dalla history,
                 * prima di sostituirle con quelle appena fetchate
                 */
                newState.datesHistory = {...this.state.datesHistory};
                console.log(state.requestDirection)
                if (state.requestDirection === "back") {
                    Object.keys(this.state.dateDisponibili).map(d => delete newState.datesHistory[d]);
                }
                Object.keys(dateDisponibili).map(d => newState.datesHistory[d] = true);

                console.log(dateDisponibili);
                this.setState(newState);
            });
    }

    handleConfirm = _ => {
        if(this.checkAdvancedDaysPassed()){
            swalError(`Attenzione! In questa attività puoi prenotare con un massimo di `+this.props.advanceDays+` giorni di anticipo`)
            return;
        }

        if(!this.state.selectedInterval.length){
            swalError(`Scegli un opzione prima di proseguire`)
            return;
        }

        for (let i in this.state.selectedInterval) {
            const session = this.state.selectedInterval[i];
            if (session.posti_disponibili < this.props.partecipanti) {
                swalError(`I posti disponibili dalle ${session.orario_inizio} alle ${session.orario_fine} sono solo ${session.posti_disponibili}`);
                return;
            }
        }

        this.state.onChoose({
            data: this.state.selectedDate,
            sessioni: this.state.selectedInterval,
        });
    }

    handleChange(value) {
        this.setState({selected: value});
    }

    handleNextDays() {
        var dateList = Object.keys(this.state.dateDisponibili);
        this.setState({
            startDate: dateList[dateList.length - 1],
            requestDirection: 'next',
            nextButtonClicks: this.state.nextButtonClicks + 1
        });
    }

    handleBackDays() {
        var dateList = Object.keys(this.state.dateDisponibili);
        this.setState({
            startDate: dateList[0],
            requestDirection: 'back',
            nextButtonClicks: this.state.nextButtonClicks - 1
        });
    }

    handleDayClick(dateString) {
        console.log(dateString);
        console.log(this.state.dateDisponibili[dateString].time);
        this.setState({
            selectedDate: dateString,
            selectedInterval: []
        });
    }

    handleTimeChange(session) {
        var selected = this.state.selectedInterval.slice();
        var index = this.isSessionSelected(session)
        if (index !== false) { //Deseleziono
            selected.splice(index, 1);
            this.setState({selectedInterval: selected});
            return;
        }

        if (selected.length + 1 > this.props.maxSessions) {
            //Elimino l'elemento più remoto e poi aggiungo quello appena selezionato
            selected.splice(0, 1);
        }

        selected.push(session);
        this.setState({
            selectedInterval: selected
        })

    }

    isSessionSelected(session) {
        var str = JSON.stringify(session);
        for (var i in this.state.selectedInterval) {
            if (str === JSON.stringify(this.state.selectedInterval[i])) {
                return i;
            }
        }
        return false;
    }

    getOccupancyLabel = (capienza, disp) => {
        if (this.props.hideAvailability && disp) {
            return <span className={"text-success"}>Posti disponibili</span>
        }

        let prenotazioni = capienza - Math.max(0, disp);
        let occupancyPerc = prenotazioni / capienza * 100;
        if (occupancyPerc === 100) {
            return <span className={"text-danger"}>Esaurito</span>
        } else if (occupancyPerc >= 75) {
            return <span className={"text-danger"}>{disp} posti disponibili</span>
        } else if (occupancyPerc >= 50) {
            return <span className={"text-warning"}>{disp} posti disponibili</span>
        } else {
            return <span className={"text-success"}>{disp} posti disponibili</span>
        }
    }

    //Se true vuol dire che i giorni di anticipo sono stati superati e quindi l'utente non potrà prenotare
    checkAdvancedDaysPassed =_=>{
        return this.props.advanceDays && moment(this.state.selectedDate, "DD-MM-YYYY").diff(moment(), "days") >= this.props.advanceDays
    }

    render() {
        const canGoPrev = this.state.nextButtonClicks > 0;
        const canGoNext = !this.props.maxVisibleDays || this.props.maxVisibleDays > Object.keys(this.state.datesHistory).length;

        return (
            <Page
                title={"Data e ora"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={true}
                content={
                    <div className={"container pt-2"}>
                        <div className={"text-center my-2"}>
                            <button className={"btn btn-sm btn-primary mx-1 px-2 text-on-bg-primary"} onClick={this.handleBackDays}
                                    disabled={!canGoPrev}>
                                <FaSolidIcon name={"angle-left"}/>
                            </button>
                            <span className={"text-on-bg-main"}>Scegli il giorno</span>
                            <button className={"btn btn-sm btn-primary mx-1 px-2 text-on-bg-primary"} onClick={this.handleNextDays}
                                    disabled={!canGoNext}>
                                <FaSolidIcon name={"angle-right"}/>
                            </button>
                        </div>
                        {
                            !canGoNext &&
                            <div className={"my-2 text-muted text-center small"}>
                                <FaSolidIcon name={"exclamation-triangle"} className={"text-warning"}/> Non puoi
                                visualizzare altre date future a causa dei limiti impostati dall'attività
                            </div>
                        }
                        <div className={"my-2 row p-1"}>
                            {/* Mostro le attuali date disponibili */}
                            {
                                Object.keys(this.state.dateDisponibili).map((dateString, i) => {
                                    var giorniSettimana = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];
                                    var mesi = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"];
                                    var m = moment(dateString, "DD-MM-YYYY");
                                    var giorno = m.date();
                                    var mese = mesi[m.month()]; //0 Based
                                    var giornoSettimana = giorniSettimana[m.day()]; //0 Based, 0 = Domenica
                                    return (
                                        <div key={i} className={"col-2 p-0 text-center"}
                                             onClick={this.handleDayClick.bind(this, dateString)}>
                                            <span
                                                className={"d-block mb-2 text-uppercase text-on-bg-main"}>{giornoSettimana}</span>
                                            <div style={{minWidth: "40px"}}
                                                 className={"rounded shadow p-2 text-center mx-auto d-inline-block cursor-pointer " + (this.state.selectedDate === dateString ? "bg-primary text-white" : "bg-white bg-main-z2 text-on-bg-main")}>
                                                {giorno}
                                            </div>
                                            <span
                                                className={"d-block mt-2 text-uppercase text-on-bg-main"}>{mese}</span>
                                        </div>
                                    )
                                })

                            }
                        </div>
                        <div className={"row my-2 pb-5"}>
                            {
                                this.checkAdvancedDaysPassed() ?
                                    <div className={"px-4 my-2 alert alert-primary text-center small"}>
                                        <FaSolidIcon name={"info"}/> In questa attività puoi prenotare con un massimo di {this.props.advanceDays} giorni di anticipo
                                    </div> : ""
                            }
                            {
                                !this.state.selectedDate ? '' : this.state.dateDisponibili[this.state.selectedDate].map((sessione, i) => {
                                    const checked = this.isSessionSelected(sessione);
                                    return (
                                        <div className={"col-12 px-2 text-center"} key={i}>
                                            <label
                                                className={"fux-radiobox bg-main-z2 d-block shadow py-2"}
                                                style={{fontSize: "1em"}}>
                                                <input type={"checkbox"} value={sessione} name={"orario_prenotazione"}
                                                       checked={checked}
                                                       onChange={this.handleTimeChange.bind(this, sessione)}/>
                                                {checked ?
                                                    <FaSolidIcon name={"check-circle"}/> :
                                                    <FaRegularIcon name={"circle"}/>}&nbsp;
                                                Dalle {sessione.orario_inizio} alle {sessione.orario_fine}
                                                <br/>
                                                {
                                                    this.getOccupancyLabel(sessione.max_partecipanti, sessione.posti_disponibili)
                                                }
                                            </label>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
                footer={
                    <PageFooter className={"bg-main-z2 p-3 border-top"}>
                        <button className={"btn btn-lg btn-block btn-primary text-on-bg-primary "+(this.checkAdvancedDaysPassed() && "disabled")+""} onClick={this.handleConfirm}>
                            {this.props.confirmText ? this.props.confirmText : "Prenota"} <FaSolidIcon
                            name={"chevron-right"}/>
                        </button>
                    </PageFooter>
                }
            />
        );
    }

}


DashboardSessionsChooserPage.propTypes = {
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func,
    hideAvailability: PropTypes.bool
};
