export const COUPON_TYPE_FOOD = 'food';
export const COUPON_TYPE_ECOMMERCE = 'ecommerce';
export const COUPON_TYPE_HOTEL = 'hotel';
export const COUPON_TYPE_SERVIZI = 'servizi';
export const COUPON_TYPE_GRUPPI = 'gruppi';

export const MAPPING_MODULE_COUPON_TYPE = {}
MAPPING_MODULE_COUPON_TYPE[COUPON_TYPE_SERVIZI] = 'modulo_servizi'
MAPPING_MODULE_COUPON_TYPE[COUPON_TYPE_GRUPPI] = 'modulo_gruppi'
MAPPING_MODULE_COUPON_TYPE[COUPON_TYPE_ECOMMERCE] = 'modulo_shop'
MAPPING_MODULE_COUPON_TYPE[COUPON_TYPE_FOOD] = 'modulo_food'
MAPPING_MODULE_COUPON_TYPE[COUPON_TYPE_HOTEL] = 'modulo_hotel'