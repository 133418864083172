import React, {Fragment} from "react";
import {swal} from "../../helpers/SweetAlertWrapper";
import styled, {keyframes} from "styled-components";
import '../FoodDelivery/ribbon.css';
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import {stripHtml} from "../../helpers/StringHelpers";
import AllergeniProdottiList from "./AllergeniProdottiList";
import AppConfig from "../../config/AppConfig";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import vietatoMinoriImg from "../../img/vietato-minorenni.png";
import {ProductInfoIcon} from "../../img/illustrations/ProductInfoIcon";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";

const ListGroupItemImage = styled.div`
    width:${props => props.small ? '70px' : '90px'};
    height:${props => props.small ? '70px' : '90px'};
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    background-image:url(${props => props.image || ''});
    border-radius:.50rem;
    position:relative;

    &.greyscale{
        filter:grayscale(1);
    }
    
    &:after{
        position:absolute;
        bottom:-5px;
        right:-5px;
        text-align:center;
        padding:2px 4px;
        font-family: "Font Awesome 5 Free";
        background-color: ${props => props.backgroundColor};
        color: ${props => props.color};
        content: "${props => props.icon}";
        z-index:1;
        border-top-left-radius:.25rem;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
`;

const DescriptionPreviewContainer = styled.div`
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Badge = styled.span`
    & + span{
        margin-left:2px;
    }
`;

export const VietatoMinoriIcon = styled.div`
    background-image:url(${vietatoMinoriImg});
    width:35px;
    height:35px;
    background-size:contain;
    background-repeat:no-repeat;
    position:absolute;
    top:5px;
    right:5px;
    z-index:1;
`;


export default class MenuDigitaleCardProdotto extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }

    handleImageClick = e => {
        e.stopPropagation();
        e.preventDefault();
        swal.fire({
            html: <img src={this.props.immagine} className={"img-fluid"}/>,
            showConfirmButton: false,
            showCloseButton: true,
            focusCancel: false, focusConfirm: false
        });
    }

    getProductDescription = _ => {
        if (this.props.ingredienti) {
            return <div><span className={"font-weight-bold"}>Ingredienti:</span> {this.props.ingredienti}</div>;
        } else if (this.props.descrizione) {
            return (
                <DescriptionPreviewContainer>
                    <SafeHtmlContainer html={stripHtml(this.props.descrizione)}/>
                </DescriptionPreviewContainer>
            );
        } else {
            return '';
        }
    }

    getProductPriceLabel = _ => {
        const prezzo = parseFloat(this.props.prezzo);
        return prezzo > 0 ? `€${prezzo.toFixed(2)}` : '';
    }

    render() {
        this.grayscale = parseInt(this.props.disponibilita) === 0 || parseInt(this.props.quantita) <= 0;
        return (
            <div>
                <div className={"card bg-main-z2 my-2 overflow-hidden text-on-bg-main"}>
                    <div className={"card-body"}>
                        {
                            parseInt(this.props.disponibilita) === 0 ?
                                <div className={"corner-ribbon top-right grey shadow"}>Non disponibile</div>
                                :
                                (parseInt(this.props.quantita) <= 0 ?
                                    <div className={"corner-ribbon top-right grey shadow"}>Esaurito</div> : '')
                        }
                        {parseInt(this.props.vietato_minori) === 1 && <VietatoMinoriIcon/>}
                        <div className={"row align-items-center"}>
                            <div className={'col-8'}>
                                <b>{this.props.nome}</b>
                                <br/>
                                <b className={"text-primary"} style={{verticalAlign: 'middle'}}>
                                    {this.getProductPriceLabel()}
                                </b>
                                &nbsp;
                                {parseInt(this.props.minimo_ordinabile) > 0 ? <span
                                    className={"badge badge-primary"}>{`Min. ${this.props.minimo_ordinabile}`}</span> : ''}&nbsp;
                                {parseInt(this.props.massimo_ordinabile) > 0 ? <span
                                    className={"badge badge-primary"}>{`Max. ${this.props.massimo_ordinabile}`}</span> : ''}&nbsp;
                            </div>

                            {/* Immagine impostata da admin */}
                            {
                                this.props.immagine && <div className={"col p-0 text-center"}>
                                    <ListGroupItemImage
                                        onClick={this.props.onDetail}
                                        className={"mx-auto shadow " + (this.grayscale ? 'greyscale' : '')}
                                        image={getCompressedImageUrl(this.props.immagine, 300, 300)}
                                        icon={"\\f06e"}
                                        backgroundColor={NegozioOpenStore.infoNegozio.aspetto.primary_color}
                                        color={NegozioOpenStore.infoNegozio.aspetto.text_on_primary_color}
                                    />
                                </div>
                            }

                            {/* Immagine di default del sistema */}
                            {
                                !this.props.immagine && <div className={"col p-0 text-center"}>
                                    <ListGroupItemImage
                                        className={"mx-auto"}
                                        onClick={this.props.onDetail}
                                        backgroundColor={NegozioOpenStore.infoNegozio.aspetto.secondary_color}
                                        color={NegozioOpenStore.infoNegozio.aspetto.text_on_secondary_color}
                                        icon={"\\f06e"}
                                        small={true}
                                    >
                                        <ProductInfoIcon
                                            color={NegozioOpenStore.infoNegozio.aspetto.bg_main}
                                            bgColor={NegozioOpenStore.infoNegozio.aspetto.secondary_color}
                                        />
                                    </ListGroupItemImage>
                                </div>
                            }
                        </div>
                        {
                            !this.props.ingredienti && !stripHtml(this.props.descrizione) && (!this.props.badges || !this.props.badges.length) ? '' :
                                <div className={"row align-items-center border-top mt-3 pt-3"}>
                                    <div className={"col-12"}>
                                        {
                                            this.props.badges ?
                                                this.props.badges.map((b, i) => {
                                                    return (
                                                        <Badge key={i} className={"badge badge-primary mb-2"}>
                                                            {b}
                                                        </Badge>
                                                    );
                                                })
                                                : ''
                                        }
                                    </div>
                                    <div className={"col-12"}>
                                        <small>{this.getProductDescription()}</small>
                                    </div>
                                </div>
                        }

                        {
                            !!this.props.allergeni && !!this.props.allergeni.length &&
                            <div className={"d-flex align-items-center"}>
                                <small className={"font-weight-bold small mr-2"}>Allergeni presenti:</small>
                                <AllergeniProdottiList
                                    detailPage={true}
                                    allergeni={this.props.allergeni}
                                    icon={true}
                                    text={false}
                                    size={30}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}


