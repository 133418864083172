import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import i18n from "../../../../i18n";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import ComandaModal from "../ModuloComande/ComandaModal";

export class DashboardModuloComandeButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageOpen: false
        }
    }

    handlePageOpen = _ => this.setState({pageOpen: true});
    handlePageClose = _ => this.setState({pageOpen: false});

    render() {
        return (
            <Fragment>
                <div className={"d-flex align-items-center justify-content-between p-3 border-top text-blue"}
                     onClick={this.handlePageOpen}>
                    <span>
                        <FaSolidIcon name={"utensils"}/>&nbsp;
                        {i18n.t(`launcher.side-menu.${this.props.labelName}`)}
                    </span>
                    {
                        this.props.tavoliLiberi &&
                        <span className={"badge badge-pill badge-primary"}>
                            {this.props.tavoliLiberi} tavoli liberi
                        </span>
                    }
                </div>
                <ModalPortalHOC>
                    {
                        this.state.pageOpen &&
                        <ComandaModal
                            {...this.props.userNegozio}
                            active={true}
                            onDismiss={this.handlePageClose}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DashboardModuloComandeButton.propTypes = {
    labelName: PropTypes.string.isRequired,
    tavoliLiberi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    userNegozio: PropTypes.object
}