import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../FontAwesomeIcons";
import {FileListViewItemPreview} from "./FileListViewItemPreview";

export function FilesListViewItem({file, onRemove, index, ...props}) {
    const handleRemove = e => onRemove(index);
    return (
        <div className={"d-flex align-items-center justify-content-between"}>
            <div className={"flex-shrink-0 mr-2"}>
                <FileListViewItemPreview file={file}/>
            </div>
            <small className={"flex-grow-1 text-truncate text-nowrap"}>{file.name}</small>
            <button className={"btn btn-sm btn-link text-danger"} onClick={handleRemove} type={"button"} disabled={props.disabled}>
                <FaSolidIcon name={"trash"}/>
            </button>
        </div>
    )
}

FilesListViewItem.propTypes = {
    onRemove: PropTypes.func.isRequired,
    file: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    disabled: PropTypes.bool
}
