import React from "react";
import PropTypes from "prop-types";
import {FilePropType} from "./PropTypes/FilePropType";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {UploadedFilePreview} from "./UploadedFilePreview";
import {BookizonAppManager} from "../../index";

export function FileViewItem({file, onDelete}) {
    const handleDelete = _ => onDelete(file);
    const handleOpen = _ => BookizonAppManager.openInAppBrowser(file.url);
    
    return (
        <React.Fragment>
            <div className={"d-flex align-items-center justify-content-between"}>
                <div className={"flex-grow-1 d-flex align-items-center"} onClick={handleOpen}>
                    <UploadedFilePreview file={file}/> {file.filename}
                </div>
                <button className={"btn btn-link text-danger btn-sm"} onClick={handleDelete}>
                    <FaSolidIcon name={'trash'}/>
                </button>
            </div>
        </React.Fragment>
    )
}

FileViewItem.propTypes = {
    file: FilePropType,
    onDelete: PropTypes.func
}
