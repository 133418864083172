import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const _User = {

    list: function () {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/fidelity-card/user/list`),
            {
                token: AuthAPI.currentUser.accessToken
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    transactionList: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/fidelity-card/user/transaction-list`),
            {
                token: AuthAPI.currentUser.accessToken,
                id_negozio: id_negozio
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getQRCode: function (token, id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/fidelity-card/user/qr-code'),
            {
                token: AuthAPI.currentUser.accessToken,
                id_negozio: id_negozio
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    bookPrize: function (id_premio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/fidelity-card/user/book-prize'),
            {
                id_premio: id_premio,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    bookedPrizes: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/fidelity-card/user/booked-prizes'),
            {
                id_negozio: id_negozio,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getRegulation: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/fidelity-card/user/regulation'),
            {
                id_negozio: id_negozio,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getPrizeList: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/fidelity-card/user/prize-list'),
            {
                id_negozio: id_negozio,
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

}