import React, {Fragment} from "react";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import axios from "axios";
import makeSearchImage from "../../svg/make-search.svg";
import sadFace from "../../svg/sad-face.svg";
import DashboardUtentiAPI from "../../../api/DashboardUtentiAPI";
import {UserSearchResult} from "./UserSearchResult";
import styled from "styled-components";
import {EllipsisLoader} from "../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import PropTypes from "prop-types";

const CancelToken = axios.CancelToken;

const SearchInputWrapper = styled.div`
    position:relative;
    
    & > input{
        padding-left:30px;
    }
    
    & i{
        position:absolute;
        left:8px;
        top:50%;
        transform: translateY(-50%);
        bottom:0px;
        display:block;
        color:#717a80;
    }
    
    & span{
        display: none;
        position: absolute;
        top:50%;
        right:8px;
        border-radius: 50%;
        padding:2px;
        background:#ddd;
        color:white;
        width: 18px;
        height: 18px;
        line-height: 13px;
        text-align: center;
        transform: translateY(-50%);
    }

    & input:not([value=""]) + span{
        display: block;
    }  
`;

export default class SearchUserView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            searchResults: []
        };

        this.typingTimer = null;
        this.doneTypingInterval = 300;
        this.cancelTokenSource = undefined;
    }

    handleChange = ({target}) => {
        this.setState({[target.name]: target.value});
    }

    handleSearchInputKeyUp = ({target}) => {
        clearTimeout(this.typingTimer);
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('cancellato');
            this.cancelTokenSource = undefined;
        }
        if (target.value) {
            this.typingTimer = setTimeout(this.handleSearch.bind(this, target.value), this.doneTypingInterval);
            this.setState({searching: true});
        } else {
            this.setState({searching: false});
        }
    }

    handleSearch = queryString => {
        this.cancelTokenSource = CancelToken.source();
        this.setState({searching: true});
        DashboardUtentiAPI.search(queryString, this.cancelTokenSource.token)
            .then(searchResults => {
                this.setState({searching: false, searchResults: searchResults});
            })
            .catch(e => {
                console.error(e);
            });
    }

    handleReset = _ => {
        this.setState({searchQuery: '', searchResults: []});
        this.cancelTokenSource.cancel('cancellato');
    }

    handleCreateUser = _ => this.props.onShowCreateMode(this.state.searchQuery);

    render() {
        return (
            <Fragment>
                <h5 className={"font-weight-bold text-center"}>Ricerca cliente</h5>
                <div className={"mb-3"}>
                    <SearchInputWrapper>
                        <input
                            className={"form-control"}
                            type={"text"}
                            name={"searchQuery"}
                            value={this.state.searchQuery}
                            placeholder={"Scrivi il nome del cliente"}
                            onChange={this.handleChange}
                            onKeyUp={this.handleSearchInputKeyUp}
                        />
                        <span className={"appListSearchDelete"} onClick={this.handleReset}>&times;</span>
                        <FaSolidIcon name={"search"}/>
                    </SearchInputWrapper>
                </div>

                {
                    this.state.searching &&
                    <div className={"text-center my-5"}>
                        <EllipsisLoader/>
                    </div>
                }

                {
                    (!this.state.searching && !!this.state.searchResults?.length) &&
                    <Fragment>
                        <div className={"list-group"}>
                            {
                                this.state.searchResults.map((c, i) => {
                                    return <div key={i} className={"mb-3"}>
                                        <UserSearchResult
                                            onClick={_ => this.props.onResultClick(c)}
                                            {...c}
                                        />
                                    </div>
                                })
                            }
                        </div>
                        <h6 className={"text-center mt-4"}>
                            Non hai trovato il cliente che cercavi?
                            <button className={"btn btn-sm btn-soft-primary mt-2"} onClick={this.handleCreateUser}>
                                <FaSolidIcon name={"plus"}/> Crea scheda cliente
                            </button>
                        </h6>
                    </Fragment>
                }

                {/** @MARK Nessuna ricerca */}
                {
                    (!this.state.searching && !this.state.searchResults?.length && !this.state.searchQuery) &&
                    <div className={"container mt-5"}>
                        <img src={makeSearchImage}
                             className={"w-50 d-block mx-auto mb-2"}
                             alt={"Fai la tua ricerca"}
                             title={"Fai la tua ricerca"}
                        />
                        <h6 className={"text-center mb-3"}>
                            Digita il nome o cognome del cliente che stai cercando
                        </h6>
                        <p className={"small text-center text-muted"}>oppure</p>
                        <div className={"text-center"}>
                            <button className={"btn btn-sm btn-soft-primary mt-2"} onClick={this.handleCreateUser}>
                                <FaSolidIcon name={"plus"}/> Crea scheda cliente
                            </button>
                        </div>
                    </div>
                }

                {/** @MARK Nessun risultato */}
                {
                    (!this.state.searching && !this.state.searchResults?.length && !!this.state.searchQuery) &&
                    <div className={"container mt-5"}>
                        <img src={sadFace}
                             className={"w-50 d-block mx-auto mb-2"}
                             alt={"Fai la tua ricerca"}
                             title={"Fai la tua ricerca"}
                        />
                        <h6 className={"text-center"}>
                            Non abbiamo trovato nulla con
                            <div className={"font-weight-bold"}>{this.state.searchQuery}</div>
                            <button className={"btn btn-soft-primary mt-2"} onClick={this.handleCreateUser}>
                                <FaSolidIcon name={"plus"}/> Crea scheda cliente
                            </button>
                        </h6>

                    </div>
                }
            </Fragment>
        )
    }
}


SearchUserView.propTypes = {
    onResultClick: PropTypes.func,
    onShowCreateMode: PropTypes.func
}

