import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import {CalendarDayPicker} from "../CalendarDayPicker";
import {ModuloServiziAPI} from "../../../../../api/ModuloServizi/ModuloServiziAPI";
import FuxEvents from "../../../../../lib/FuxFramework/FuxEvents";
import {EVT_REFETCH_EVENTS} from "../CalendarioServizi";
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import itLocale from '@fullcalendar/core/locales/it';
import resourceTimeGrid from '@fullcalendar/resource-timegrid'
import FuxHTTP from "../../../../../lib/FuxFramework/FuxHTTP";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";

export class CompleteCalendar extends React.Component {

    calendarRef = React.createRef();
    stickyHeaderRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            dateIntervalStart: moment().subtract(this.props.daysBeforeToday, 'days').format('YYYY-MM-DD'),
            dateIntervalEnd: moment().add(this.props.daysAfterToday, 'days').format('YYYY-MM-DD'),
            id_sede: this.props.infoNegozio.sedi[0].id_sede,
            id_dipendente: this.props.infoNegozio.sedi[0].dipendenti[0].id_dipendente,
            resources: [],
            events: [],
            bgEvents: [],
            datesBookCount: null,
            loading: false,
            minTime: '07:00',
            maxTime: '22:00',
        }

    }

    componentDidMount() {
        this.fetchResources();
        this.fetchEvents();
        this.fetchBackgroundEvents();
        this.fetchDatesBooksCount();
        this.fetchSedeBusinessHour();
        FuxEvents.on(EVT_REFETCH_EVENTS, this.fetchEvents);
    }

    componentWillUnmount() {
        FuxEvents.off(EVT_REFETCH_EVENTS, this.fetchEvents);
    }

    /** @MARK: Events fetching */
    fetchEvents = _ => {
        const startDate = this.state.selectedDate;
        startDate.setUTCHours(0);
        startDate.setUTCMinutes(0);
        startDate.setUTCSeconds(0);
        const endDate = new Date(this.state.selectedDate);
        endDate.setUTCHours(0);
        endDate.setUTCMinutes(0);
        endDate.setUTCSeconds(0);
        endDate.setUTCDate(endDate.getUTCDate() + 1);
        ModuloServiziAPI.Dashboard.Fullcalendar.getEvents(this.state.id_sede, startDate.toISOString(), endDate.toISOString())
            .then(events => this.setState({events: events}))
    }

    fetchBackgroundEvents = _ => {
        const startDate = this.state.selectedDate;
        startDate.setUTCHours(0);
        startDate.setUTCMinutes(0);
        startDate.setUTCSeconds(0);
        const endDate = new Date(this.state.selectedDate);
        endDate.setUTCHours(0);
        endDate.setUTCMinutes(0);
        endDate.setUTCSeconds(0);
        endDate.setUTCDate(endDate.getUTCDate() + 1);
        ModuloServiziAPI.Dashboard.Fullcalendar.getBackgroundEvents(this.state.id_sede, startDate.toISOString(), endDate.toISOString())
            .then(events => this.setState({bgEvents: events}))
    }


    fetchResources = _ => {
        ModuloServiziAPI.Dashboard.Fullcalendar.getResources(this.state.id_sede)
            .then(resources => this.setState({resources: resources}))
    }

    fetchDatesBooksCount = _ => {
        ModuloServiziAPI.Dashboard.getDateListBooksCount(this.state.dateIntervalStart, this.state.dateIntervalEnd, this.state.id_dipendente)
            .then(datesInfo => {
                this.setState({
                    datesBookCount: {...(this.state.datesBookCount ? this.state.datesBookCount : {}), ...datesInfo}
                });
            });
    }

    fetchSedeBusinessHour = _ => {
        ModuloServiziAPI.Dashboard.Fullcalendar.getTimeInterval(this.state.id_sede, this.state.selectedDate.toISOString()).then(data => {
            this.setState({
                minTime: data.minTime,
                maxTime: data.maxTime
            }, _ => {
                if (this.calendarRef.current) {
                    this.calendarRef.current.getApi().setOption('minTime', this.state.minTime);
                    this.calendarRef.current.getApi().setOption('maxTime', this.state.maxTime);
                }
            });
        });
    }


    /** @MARK: Date changes */
    handleDateChange = date => this.setState({selectedDate: date}, _ => {
        this.calendarRef.current.getApi().gotoDate(this.state.selectedDate);
        this.props.onDateChange(this.state.selectedDate);
        this.fetchEvents();
        this.fetchBackgroundEvents();
        this.fetchSedeBusinessHour();
    });

    handleResourceRender = data => {
        console.log(data);
    }

    handleEventClick = data => {
        if (this.props.editMode) return;
        var bookInfo = data.event.extendedProps.book;
        if (bookInfo) {
            this.props.onEventResumeOpen(bookInfo.id_parent ? bookInfo.id_parent : bookInfo.id_prenotazione);
        }
    }

    handleDatesRender = info => {
        const calendar = info.el;
        if (this.calendarRef.current) {
            this.calendarRef.current.calendar.el.addEventListener('scroll', e => {
                if (this.stickyHeaderRef.current) {
                    this.stickyHeaderRef.current.scrollLeft = e.target.scrollLeft;
                }
            }, true);
        }
        calendar.closest(".page-content").addEventListener('scroll', e => {
            if (this.stickyHeaderRef.current) {
                //Uso l'opacity per nascondere perchè su safari non modifica lo scroll se l'elemento è nascosto
                this.stickyHeaderRef.current.parentElement.style.opacity = e.target.scrollTop > 200 ? 1 : 0;
            }
        });
    }

    handleDateSelected = selectionInfo => {
        if (this.props.editMode) return;
        this.props.onAddBookWithData({
            orario: moment(selectionInfo.date).format('HH:mm'),
            id_dipendente: selectionInfo.resource.id
        })
    }

    /**
     * @MARK Event move/resizing
     * */

    handleEventDrop = info => {
        const editedEvents = {...this.props.editedEvents};
        const bookInfo = info.oldEvent.extendedProps.book;
        const resource_id = info.newResource ? info.newResource.id : bookInfo.id_dipendente;
        if (checkFullcalendarEventChange(bookInfo, info.event, resource_id)) {
            editedEvents[bookInfo.id_prenotazione] = {
                start: info.event.start,
                end: info.event.end,
                resource_id: resource_id
            }
        } else {
            delete editedEvents[bookInfo.id_prenotazione];
        }
        this.props.onEventEditedChange(editedEvents);
    }

    handleEventResize = info => {
        const editedEvents = {...this.props.editedEvents};
        const bookInfo = info.prevEvent.extendedProps.book;
        if (checkFullcalendarEventChange(bookInfo, info.event, null)) {
            editedEvents[bookInfo.id_prenotazione] = {
                ...(editedEvents[bookInfo.id_prenotazione] || {}),
                start: info.event.start,
                end: info.event.end,
            }
        } else {
            delete editedEvents[bookInfo.id_prenotazione];
        }
        this.props.onEventEditedChange(editedEvents);
    }

    handleEventRender = info => {
        const bookInfo = info.event.extendedProps.book;
        if (bookInfo) {
            const fcTimeEl = info.el.querySelector('.fc-time');
            const fcTitleEl = info.el.querySelector('.fc-title');

            let bookGroup = bookInfo.id_parent ? bookInfo.id_parent : bookInfo.id_prenotazione
            info.el.className += ' book_group_' + bookGroup;
            info.el.dataset.bookgroup = bookGroup;

            let header = "";

            if (parseInt(bookInfo.is_guest)) {
                header += "<i class='fas fa-mask'></i> ";
            }

            if (parseInt(bookInfo.ordiniCollegati)) {
                header += "<i class='fas fa-cash-register'></i> ";
            }

            if (bookInfo.note || bookInfo.note_utente) {
                header += "<small class='badge badge-warning ml-2'><i class='fas fa-edit'></i> Note</small>";
            }

            if (bookInfo.nome_postazione) {
                fcTimeEl.innerHTML += `<small class='badge p-1 ml-2 my-1' style='background-color:rgba(0,0,0,0.2)'>${bookInfo.nome_postazione}</span>`;
            }

            fcTimeEl.innerHTML += "&nbsp;&nbsp;" + header;

            if (!bookInfo.id_servizio) {
                fcTitleEl.innerHTML = `<i class='fas fa-user-clock'></i> ${fcTitleEl.innerHTML}, Pausa NON prenotabile`;
            }

            if (parseInt(bookInfo.dipendente_chiunque)) {
                fcTitleEl.innerHTML = "<i class='fas fa-people-arrows'></i> " + fcTitleEl.innerHTML;
            }

            if (parseInt(bookInfo.prenotazioneChiusa)) {
                info.el.className += ' soft';
                info.el.innerHTML = `
                            <div class="position-relative h-100">
                                <div class="appuntamento ${bookInfo.className} mr-2 position-absolute p-1" style="top:0; left:0; bottom:0;">
                                    <i class="fas fa-check"></i>
                                </div>
                                <div class="py-1 pl-4">
                                    ${info.el.innerHTML}
                                </div>
                            </div>
                        `;
            }

        }
    }

    render() {
        const slotInterval = this.props.infoNegozio.impostazioni.slot_interval || 15;
        return (
            <Fragment>
                {
                    !!this.state.datesBookCount &&
                    <CalendarDayPicker
                        datesBookCount={this.state.datesBookCount}
                        daysBeforeToday={this.props.daysBeforeToday}
                        daysAfterToday={this.props.daysAfterToday}
                        onDateChange={this.handleDateChange}
                    />
                }
                <CalendarWrapper className={"__disable-swipe"} resources={this.state.resources || [0]}>
                    <div className={"fc fc-unthemed fc-ltr fc-sticky-header"}>
                        <div className={"fc-view"} ref={this.stickyHeaderRef}>
                            <table>
                                <thead className="fc-head">
                                <tr>
                                    <td className="fc-head-container fc-widget-header">
                                        <div className="fc-row fc-widget-header">
                                            <table className={"w-100"}>
                                                <thead>
                                                <tr>
                                                    <th className={"fc-axis fc-widget-header"} style={{width: 44.75}}/>
                                                    {
                                                        this.state.resources.map(r =>
                                                            <th key={r.id} className="fc-resource-cell"
                                                                data-resource-id={r.id}>
                                                                {r.title}
                                                            </th>
                                                        )
                                                    }
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    <FullCalendar
                        locale={itLocale}
                        className={"bg-white"}
                        defaultView="resourceTimeGridDay"
                        plugins={[resourceTimeGrid, interactionPlugin]}
                        ref={this.calendarRef}
                        header={{
                            left: "",
                            right: ""
                        }}
                        height={"auto"}
                        eventClick={this.handleEventClick}
                        datesRender={this.handleDatesRender}
                        allDaySlot={false}
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            omitZeroMinute: false
                        }}
                        slotDuration={`00:${('0' + slotInterval).slice(-2)}:00`}
                        slotLabelInterval={`00:${('0' + slotInterval).slice(-2)}:00`}
                        minTime={this.state.minTime}
                        maxTime={this.state.maxTime}
                        nowIndicator={true}
                        timeGridEventMinHeight={70}
                        events={[...this.state.events, ...this.state.bgEvents]}
                        resources={this.state.resources}
                        resourceOrder={"ordine"}
                        schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
                        dateClick={this.handleDateSelected}
                        eventRender={this.handleEventRender}
                        /* Books edit and resizing */
                        editable={this.props.editMode}
                        eventDrop={this.handleEventDrop}
                        eventResize={this.handleEventResize}
                    />
                </CalendarWrapper>
            </Fragment>
        )
    }

}

CompleteCalendar.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    daysBeforeToday: PropTypes.number.isRequired,
    daysAfterToday: PropTypes.number.isRequired,
    infoNegozio: PropTypes.object.isRequired,
    onEventResumeOpen: PropTypes.func.isRequired,
    onAddBookWithData: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    onEventEditedChange: PropTypes.func.isRequired,
    editedEvents: PropTypes.object.isRequired
}

function checkFullcalendarEventChange(bookInfo, newEvent, newResourceId) {
    const MINUTES_PER_TICK = 5;
    const newStart = newEvent.start.getTime();
    const newEnd = newEvent.end.getTime();
    const originalStart = moment(bookInfo.data + ' ' + bookInfo.orario, 'DD-MM-YYYY HH:mm').toDate().getTime();
    const originalEnd = moment(bookInfo.data + ' ' + bookInfo.orario, 'DD-MM-YYYY HH:mm')
        .add(parseInt(bookInfo.durata) * MINUTES_PER_TICK, 'minutes')
        .toDate()
        .getTime();
    return newStart !== originalStart || newEnd !== originalEnd || (newResourceId && bookInfo.id_dipendente !== newResourceId);
}

const CalendarWrapper = styled.div`
    & .fc-unthemed td{
        border-color: rgba(0,0,0,0.15);
    }
    
    & .fc-toolbar{
        display:none;
    }
    
    & .fc-view {
        overflow-x: auto;
    }
    
    & .fc-view > table {
        min-width: 100%;
        width: ${props => props.resources.length * Math.max(150, window.innerWidth / 2 - 10)}px;
    }
    
    & .fc-time-grid .fc-slats {
        z-index: 4;
        pointer-events: none;
    }
    
    & .fc-scroller.fc-time-grid-container {
        overflow: initial !important;
    }
    
    & .fc-axis {
        position: sticky;
        left: 0;
        background: white;
    }  
    
    & .fc-sticky-header *{
        box-sizing:content-box;
    }
    
    & .fc-sticky-header{
        position:fixed;
        top:40px;
        background:white;
        z-index:1000;
        opacity:0;
    }
    
    & .fc-sticky-header .fc-view{
        width:100vw;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    
    & .fc-sticky-header .fc-view::-webkit-scrollbar {
        display: none;
    }
    
    & .fc-time-grid .fc-slats td {
        height: 70px;
    }
    
    & .fc-event{
        font-size:0.85rem!important;
    }
`;
