import React from "react";
import PropTypes from "prop-types";
import {DropdownSelect} from "../../../../../../../components/BaseComponents/DropdownSelect/DropdownSelect";
import {swalConfirm} from "../../../../../../../helpers/SweetAlertWrapper";
import {FaSolidIcon} from "../../../../../../../components/FontAwesomeIcons";

export class AddServiceCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idServizio: null
        }
    }

    /** @MARK Aggiunta servizio alla fine */

    handleServizioChange = idServizio => this.setState({idServizio: idServizio});

    getDropdownItems = _ => {
        const items = [];
        this.props.optGroupServizi.map(g => {
            if (g.options.length) items.push({label: g.label, value: null, header: true});
            g.options.filter(s => s.bundle == 0).map(s => {
                items.push({label: `${s.nome} - ${parseInt(s.durata) * 5}min`, value: s.id_servizio})
            })
        });
        return items;
    }


    handleAddService = _ => {
        const servizio = this.props.optGroupServizi.reduce((ss, g) => ss.concat(g.options), []).find(s => s.id_servizio == this.state.idServizio);
        const verboseDirection = this.props.direction === 'after' ? 'dopo' : 'prima di';
        swalConfirm(`Sei sicuro di voler aggiungere il servizio <b>${servizio.nome}</b> alle <b>${this.props.startTime}</b> (${verboseDirection} ${this.props.nomeServizioPrenotazione || "Pausa NON prenotabile"})?<br/>Tutti i servizi successivi verrano spostati in base alla durata del nuovo servizio`)
            .then(_ => {
                this.props.onAppendSubBook(this.state.idServizio, this.props.direction, this.props.idPrenotazione, this.handleCollapse, this.props.rearrange);
            });
    }

    render() {
        return <div className={"card border mx-auto mb-0"}>
            <div className={"text-right mb-n3"}>
                <button className={"btn btn-link text-muted lead text-decoration-none"} onClick={this.props.onDismiss}>
                    &times;
                </button>
            </div>
            <div className={"card-body pt-0"}>
                <p className={"text-muted text-center mb-2"}>
                    Il servizio scelto inizierà alle {this.props.startTime}
                </p>
                <div className={"row"}>
                    <div className={"col-8 px-1"}>
                        <DropdownSelect
                            items={this.getDropdownItems()}
                            maxHeight={200}
                            onChange={this.handleServizioChange}
                            showSearch={true}
                            searchPlaceholder={"Cerca servizio"}
                            nothingSelectedText={"Seleziona un servizio"}
                            direction={this.props.dropdownDirection || 'dropup'}
                        />
                    </div>
                    <div className={"col px-1"}>
                        <button className="btn btn-link btn-sm flex-grow-1" onClick={this.handleAddService}>
                            <FaSolidIcon name={"plus"}/> Aggiungi
                        </button>
                    </div>
                </div>
            </div>
        </div>;
    }
}

AddServiceCard.propTypes = {
    onDismiss: PropTypes.func,
    idPrenotazione: PropTypes.any, //La prenotazione da usare come orario di inizio per il nuovo servizio aggiunto
    nomeServizioPrenotazione: PropTypes.string,
    direction: PropTypes.oneOf(['after', 'before']), //La posizione in cui aggiungere il servizio scelto relativamente alla prenotazione indicata
    rearrange: PropTypes.bool, //Decide se inviare al server in fase di aggiunta l'opzione di aggiustare l'orario di inizio dei blocchi successivi in seguito all'aggiunta
    startTime: PropTypes.string, //L'orario di inizio del nuovo servizio in formato HH:mm
    optGroupServizi: PropTypes.array, //Struttura per optgroup dei servizi divisi in categoria
    onAppendSubBook: PropTypes.func,
    dropdownDirection: PropTypes.string
}

