import React, {Fragment} from "react";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import Page from "../../../../components/BaseComponents/Page/Page";
import {ModuloGruppiAPI} from "../../../../api/ModuloGruppi/ModuloGruppiAPI";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import {bool} from "prop-types";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import moment from "moment";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import {PluralString} from "../../../../helpers/UIUtility";
import {
    BOOKIZON_PAY_METHODS,
    PAYMENT_METHOD_NAME,
    PAYMENT_METHOD_NAME_GRUPPI
} from "../../../../const/PaymentMethodoCostants";

export class DashboardGruppiBookDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchingData: false,
            bookData: [],
            usePrezzoServizioInQuestion: false,
            showQrCode: false
        }
    }

    componentDidMount() {
        this.fetchBookInfo()
    }

    fetchBookInfo = _ => {
        this.setState({fetchingData: true});
        ModuloGruppiAPI.Dashboard.getBookInfo(this.props.id_prenotazione)
            .then(book => {
                this.setState({bookData: book, fetchingData: false}, _ => {
                    //Se non ci sono prezzi settati nelle domande utilizzerò il prezzo del servizio
                    if (this.state.bookData.domande) {
                        const prezziNonZero = this.state.bookData.domande.map(d => d.prezzo).filter(p => p > 0)
                        if (!prezziNonZero.length) {
                            this.setState({usePrezzoServizioInQuestion: true})
                        }
                    }
                })
            })
    }

    //Calcola il subtotale per i partecipanti
    getSubtotaleDeiPartecipanti = (domandaInfo = null) => {
        if (this.state.usePrezzoServizioInQuestion) {
            //Calcolo prezzo base
            var basePrice = (this.state.bookData.prezzo / this.state.bookData.partecipanti).toFixed(2)
            return (basePrice * domandaInfo.persone * this.state.bookData.books.length).toFixed(2)
        }
        if (domandaInfo.prezzo) {
            var prezzo = parseFloat(domandaInfo.prezzo).toFixed(2)
            if (this.state.bookData.perc_sconto > 0) {
                prezzo = this.calcolaPrezzoScontato(prezzo, this.state.bookData.perc_sconto)
            }
            return (prezzo * domandaInfo.persone * this.state.bookData.books.length).toFixed(2)
        }
        return 0.00
    }

    //Calcola il subtotale
    getSubtotale = _ => {
        if (!this.state.bookData.prezzo) return "0.00"
        //Calcolo totale accessori
        let accessories = 0;
        if (this.state.bookData.accessories) {
            accessories = this.state.bookData.accessories.reduce((totale, acc) => {
                return totale + (acc.prezzo * acc.numero_prenotati)
            }, 0)
        }

        return (this.state.bookData.prezzo * this.state.bookData.books.length + accessories).toFixed(2)
    }

    //Sottrae al subtotale un eventuale sconto globale presente
    getTotale = _ => {
        return (this.getSubtotale() - this.state.bookData.importo_sconto_globale).toFixed(2)
    }

    //Calcola il prezzo passato scontato per una percentuale
    calcolaPrezzoScontato = (price, perc_sconto) => {
        var prezzo = parseFloat(price).toFixed(2)
        let sconto = parseFloat(perc_sconto).toFixed(2) / 100
        return prezzo - prezzo * sconto
    }

    //Calcola il valore reale del coupon inserito
    getCouponRealValue = _ => {
        if (!this.state.bookData.coupon.id_coupon) return 0;

        if (this.state.bookData.coupon.tipo_sconto === 'euro') {
            return parseFloat(this.state.bookData.coupon.valore_sconto);
        } else {
            let factorSconto = (parseFloat(this.state.bookData.coupon.valore_sconto) / 100).toFixed(2);
            return ((this.getSubtotale()) * factorSconto).toFixed(2);
        }
    }

    getTotaleWithCoupon = _ => {
        var tot = (this.getSubtotale() - this.getCouponRealValue()).toFixed(2)
        return tot < 0 ? 0 : tot
    }

    handleRegistraEntrata = _ => {
        swalConfirm("Sei sicuro di voler registrare l'entrata? In questo modo il qr code " +
            "non risulterà più valido per l'ingresso", 'Continua', 'Annulla').then(_ => {
            swalLoading('Attendere prego...');
            ModuloGruppiAPI.Dashboard.registerEntryByAdmin(this.state.bookData.id_prenotazione)
                .then((response) => {
                    swalSuccess(response).then(this.fetchBookInfo)
                })
                .catch(swalError);
        });
    }

    toggleShowQrCode = _ => this.setState({showQrCode: !this.state.showQrCode})


    onDeleteBookClick = _ => {
        if (BOOKIZON_PAY_METHODS.indexOf(this.state.bookData.metodo_pagamento) !== -1 || this.state.bookData.smart_packages_used.length > 0) {
            swalConfirm('Questa prenotazione è già stata pagata, vuoi emettere un rimborso?', 'Si, emetti rimborso', 'No')
                .then(_ => this.handleDeleteBook(true))
                .catch(_ => this.handleDeleteBook())
            return 0;
        }
        this.handleDeleteBook()
    }


    handleDeleteBook = (withRefuse = null) => {
        swalConfirm("L'azione sarà irreversibile, vuoi continuare?", 'Continua', 'Annulla')
            .then(_ => {
                swalLoading('Attendere prego...');
                ModuloGruppiAPI.Dashboard.delete(this.state.bookData.id_prenotazione, withRefuse)
                    .then((response) => {
                        swalSuccess(response).then(_ => {
                            this.props.onUpdate()
                            this.props.onDismiss()
                        })
                    })
                    .catch(response => swalError(response));
            });
    }

    render() {

        const momentDate = moment(this.state.bookData.datetime_inizio);
        const monthDay = momentDate.format('DD');
        const monthName = momentDate.format('MMMM');
        const dayFullName = momentDate.format('dddd');

        const smartPackagesUsed = this.state.bookData.smart_packages_used || []

        console.log(this.state.bookData.payment_report)

        return (
            <Page
                title={"Dettagli prenotazione"}

                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={true}
            >
                <div>
                    {/*Caricamento dati*/}
                    {
                        this.state.fetchingData &&
                        <div className={"mt-5 w-100 text-center"}>
                            <EllipsisLoader/>
                        </div>
                    }

                    {
                        !this.state.fetchingData && this.state.bookData &&

                        <div className={"p-3"}>
                            <div className={"d-flex"}>N. prenotazione: <b> #{this.state.bookData.id_prenotazione}</b>
                            </div>
                            <div className={"mb-4 mt-2"}>
                                <div>
                                    {
                                        this.state.bookData.data_entrata ?
                                            <div>
                                                <b className={"text-success"}>Entrata registrata manualmente:</b><br/>
                                                {moment(this.state.bookData.data_entrata).format('dddd DD MMMM YYYY') + " alle " + moment(this.state.bookData.data_entrata).format('HH:mm')}
                                            </div>
                                            :
                                            <div>
                                                {
                                                    this.state.bookData.data_prima_scansione_qr ?
                                                        <div>
                                                            <b className={"text-success"}>Entrata registrata con QR:</b><br/>
                                                            {moment(this.state.bookData.data_prima_scansione_qr).format('dddd DD MMMM YYYY') + " alle " + moment(this.state.bookData.data_prima_scansione_qr).format('HH:mm')}
                                                        </div>
                                                        :
                                                        <button className={"btn btn-link align-items-center p-0"}
                                                                onClick={this.handleRegistraEntrata}>
                                                            <i className={"fa fa-plus"}> </i> Registra entrata <i
                                                            className={"ml-1 fas fa-person-booth"}> </i>
                                                        </button>
                                                }
                                            </div>
                                    }
                                </div>
                            </div>

                            <h5 className={"font-weight-bold"}>{this.state.bookData.nome_gruppo}</h5>

                            {/** @MARK Elenco delle fasce orarie prenotate */}
                            <div className={"mb-3 card shadow-sm border-0 p-2"}>
                                <b className={"text-capitalize"}>{dayFullName} {monthDay} {monthName}</b>
                                {
                                    this.state.bookData.books &&
                                    <div>
                                        {
                                            this.state.bookData.books.map(b =>
                                                <div>
                                                    Dalle <b>{b.orario_inizio}</b> alle <b>{b.orario_fine}</b>
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                            </div>

                            {/* Accessori */}
                            {
                                this.state.bookData.accessories && this.state.bookData.accessories.length > 0 &&
                                <div className={"mt-3"}>
                                    <b>Accessori:</b>
                                    <div className={"card shadow-sm border-0 p-3"}>
                                        {
                                            this.state.bookData.accessories.map((a, i) => {
                                                return <div className={"row align-items-center"} key={i}>
                                                    <div className={"col-8"}>Ha
                                                        aggiunto <b>{a.nome_accessorio} x {a.numero_prenotati}</b></div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            }

                            {/*Visualizzazione delle info sul cliente*/}
                            <h5 className={"font-weight-bold mt-3"}>Cliente</h5>
                            <div className={"card shadow-sm border-0 p-2"}>
                                <span>
                                    <b>Nome: </b>
                                    {this.state.bookData.nome_utente + " " + this.state.bookData.cognome_utente}
                                    {
                                        this.state.bookData.partecipanti > 1 &&
                                        <span
                                            className={"text-muted"}> + altre {this.state.bookData.partecipanti - 1} persone</span>
                                    }
                                </span>
                                <span><b>Numero:</b> {this.state.bookData.cellulare_utente}</span>
                            </div>

                            {/*NOTE CLIENTE + INFO PAGAMENTO*/}
                            <div className={"card shadow-sm border-0 mt-3 p-2"}>
                                <div>
                                    <b>Note cliente:</b> <SafeHtmlContainer html={this.state.bookData.note_utente}/>
                                </div>
                                {
                                    smartPackagesUsed.length > 0 &&
                                    <div className={"my-2"}>
                                        <b>Pacchetti smart</b>
                                        {
                                            smartPackagesUsed.map(s => {
                                                return <div className={"d-flex align-items-center"}>
                                                    <div>
                                                        <PluralString n={s.credits} single={"Utilizzato"}
                                                                      more={"Utilizzati"} zero={"Utilizzati"}/>&nbsp;
                                                        <b>{s.credits}</b>&nbsp;
                                                        <PluralString n={s.credits} single={"credito"} more={"crediti"}
                                                                      zero={"crediti"}/> da &nbsp;
                                                        <b>{s.name}</b>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>

                            {/*STAMPA DEI METODI DI PAGAMENTO*/}
                            <div className={"card shadow-sm border-0 mt-3 p-2"}>
                                <b>Metodi pagamento</b>
                                {
                                    this.state.bookData.payment_report && this.state.bookData.payment_report.payments_type.map((p, i) => {
                                        return <div key={i}>
                                            {PAYMENT_METHOD_NAME_GRUPPI[p.type] || p.type.replaceAll("_", " ").capitalize()}: €{p.importo}
                                        </div>
                                    })
                                }
                            </div>

                            {
                                this.state.bookData.note &&
                                <div className={"card shadow-sm border-0 my-2 p-2"}>
                                    <b>Note interne:</b> <SafeHtmlContainer html={this.state.bookData.note}/>
                                </div>
                            }

                            {
                                this.state.bookData.coupon &&
                                <div>
                                    {this.state.bookData.coupon.id_promozione ?
                                        <div>
                                            <h5 className={"font-weight-bold mt-3"}>Promozione utilizzata &#x1F389;</h5>
                                            <div className={"card shadow-sm border-0 p-2"}>
                                                <span>Il prezzo viene <b>scontato del {this.state.bookData.coupon.valore_sconto}%</b></span>
                                                {this.state.bookData.coupon.min_spesa > 0 &&
                                                    <span>
                                                   Su una spesa minima di €{this.state.bookData.coupon.min_spesa}
                                                </span>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h5 className={"font-weight-bold mt-3"}>Info Coupon Inserito</h5>
                                            <div className={"card shadow-sm border-0 p-2"}>
                                                <span><b>Codice:</b> {this.state.bookData.coupon.codice}</span>
                                                <span><b>Sconto:</b> {
                                                    this.state.bookData.coupon.tipo_sconto === 'percentuale' ?
                                                        <span>-{this.state.bookData.coupon.valore_sconto}%</span> :
                                                        <span>-€{this.state.bookData.coupon.valore_sconto}</span>
                                                }
                                                </span>
                                                {
                                                    this.state.bookData.coupon.min_spesa > 0 &&
                                                    <span><b>Spesa minima:</b> €{this.state.bookData.coupon.min_spesa}</span>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }


                            {/*Visualizzazione delle info sui prezzi*/}
                            <h5 className={"font-weight-bold mt-4"}>Prezzi & Dettagli</h5>
                            <div className="card shadow-sm border-0 p-2">
                                {
                                    this.state.bookData.domande ?
                                        <div>
                                            {
                                                this.state.bookData.domande.map((d, i) => {
                                                    return (
                                                        <div className={"row align-items-center"} key={i}>
                                                            <div className={"col-8"}>{d.testo} ({d.persone})</div>
                                                            <div className={"col-4"}>
                                                                <b>€{this.getSubtotaleDeiPartecipanti(d)}</b></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className={"row align-items-center"}>
                                            <div className={"col-8"}>Prenotazione per
                                                ({this.state.bookData.partecipanti})
                                            </div>
                                            <div className={"col-4"}>
                                                <b>€{this.getSubtotale()}</b>
                                            </div>
                                        </div>
                                }

                                {
                                    this.state.bookData.accessories && this.state.bookData.accessories.length > 0 &&
                                    <div className={"mt-2"}>
                                        {
                                            this.state.bookData.accessories.map((a, i) => {
                                                return <div className={"row align-items-center"} key={i}>
                                                    <div
                                                        className={"col-8"}>{a.nome_accessorio} ({a.numero_prenotati})
                                                    </div>
                                                    <div className={"col-4"}>
                                                        <b>€{(a.prezzo * a.numero_prenotati).toFixed(2)}</b></div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }

                                <hr/>

                                {(!!this.state.bookData.coupon) ?
                                    <div className={"my-1"}>
                                        <div className={"row align-items-center"}>
                                            <div className={"col-8"}>Sub Totale:</div>
                                            <div className={"col-4"}><b>€{this.getSubtotale()}</b></div>
                                        </div>

                                        <div className={"row align-items-center"}>
                                            <div className={"col-8"}>Sconto:</div>
                                            <div className={"col-4"}><b>€{this.getCouponRealValue()}</b></div>
                                        </div>

                                        <hr/>

                                        <div className={"row align-items-center"}>
                                            <div className={"col-8"}>Totale:</div>
                                            <div className={"col-4"}><b>€{this.getTotaleWithCoupon()}</b></div>
                                        </div>
                                    </div>
                                    :
                                    <div className={"my-1"}>
                                        {
                                            this.state.bookData.importo_sconto_globale > 0 &&
                                            <div className={"row align-items-center"}>
                                                <div className={"col-8"}>Importo sconto:</div>
                                                <div className={"col-4"}>
                                                    <b>€{this.state.bookData.importo_sconto_globale}</b></div>
                                            </div>
                                        }
                                        <div className={"row align-items-center"}>
                                            <div className={"col-8"}>Totale:</div>
                                            <div className={"col-4"}><b>€{this.getTotale()}</b></div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/*Qr code*/}
                            <div className={"my-3"}>
                                {
                                    !this.state.showQrCode ?
                                        <button className={"btn btn-sm btn-link p-0"}
                                                onClick={_ => this.toggleShowQrCode()}>Mostra qr code <i
                                            className="fas fa-caret-right"/></button> :
                                        <button className={"btn btn-sm btn-link p-0"}
                                                onClick={_ => this.toggleShowQrCode()}> Nascondi qr code <i
                                            className="fas fa-caret-down"/></button>
                                }
                                {
                                    this.state.bookData.qrcode_url && this.state.showQrCode &&
                                    <div className={"card border-0 shadow-sm text-center p-3 mt-2"}
                                         onClick={this.toggleClassQrCode}>
                                        <img className={this.state.qrCodeActive && 'enlargedQr'}
                                             src={this.state.bookData.qrcode_url}/>
                                    </div>
                                }
                            </div>

                            <div className={"my-3"}>
                                <button className={"btn btn-block btn-danger"} onClick={this.onDeleteBookClick}>
                                    Elimina prenotazione <FaSolidIcon name={"trash"}/>
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </Page>
        )
    }

}