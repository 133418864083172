import React, {Fragment} from "react";
import {FaRegularIcon, FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {InputDatepicker} from "../../../../../components/BaseComponents/InputDatepicker";
import {timepickerConfig} from "../../../../../const/DatePickerConstants";


export default class QuestionsInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            questions: this.props.questions,
            totale: 0
        }

        for (var i in props.questions) {
            this.state.errors[i] = ''; //Lista degli errori
        }
    }

    //Verifica se l'input inserito è corretto secondo le impostazioni
    isInputValid = (value, min, max) => {
        //console.log(value, min, max);
        if (value < 0) {
            return 'Il numero inserito non è valido';
        } else if ((value > max && max > 0) || value < min) {
            if (max > 0) {
                return `Da impostazioni: il numero deve essere compreso tra ${min} e ${max} (potrai proseguire comunque)`;
            } else {
                return `Da impostazioni: il numero deve essere maggiore di ${min} (potrai proseguire comunque)`;
            }
        }
        return '';
    }

    //Prezzo modificato manualmente
    handleChangeRisposta = ({target}) => {
        const i = target.name.substr(target.name.indexOf('_') + 1, target.name.length - 1)
        const question = this.state.questions[i];
        let state = {...this.state};
        //Setto il nuovo valore di risposta
        question.risposta = (!target.value) ? 0 : target.value;
        //Calcolo il prezzo per la domanda, se lo sconto è a zero, ritornerà il prezzo senza sconto
        question.subtotal = (question.risposta * question.prezzo).toFixed(2)
        //Controllo se il nuovo valore rientra nel range scelto
        state.errors[i] = this.isInputValid(question.risposta, parseInt(question.min), parseInt(question.max))

        this.setState(state, _ => {
            this.props.onChange(this.state.questions)
        });
    }

    handleChangePriceAdPersonam = ({target}) => {
        const i = target.name.substr(target.name.indexOf('_') + 1, target.name.length - 1)
        const question = this.state.questions[i];
        let state = {...this.state};
        //Setto new Value sfruttando la risposta alla domanda in input
        question.prezzo = (!target.value) || target.value < 0 ? 0 : target.value;

        //Calcolo il subtotale per la domanda
        //Se non è settata una risposta allora la metto a zero di default
        question.subtotal = (((!!question.risposta) ? question.risposta : 0) * question.prezzo).toFixed(2)

        this.setState(state, _ => {
            this.props.onChange(this.state.questions)
        });
    }

    //Calcola il prezzo passato in input scontandolo per perc_sconto
    calcolaScontoApplicato = indexOfQuestion => {
        let question = this.props.questions[indexOfQuestion]
        //Se inizialmente il prezzo è zero ed il nuovo prezzo è zero allora avremo uno sconto dello 0%
        if(question.prezzoIniziale == 0 && question.prezzo == 0)return 0
        //Se inizialmente il prezzo è zero, qualsiasi supplemento sarà del 100
        if(question.prezzoIniziale == 0 && question.prezzo > 0)return 100
        //(Iniziale-nuovo)/iniziale*100
        //Negativo perché in questo modo posso simboleggiare anche il supplemento
        return -(((question.prezzoIniziale - question.prezzo) / question.prezzoIniziale * 100).toFixed(2))
    }


    render() {
        return (
            <Fragment>
                {
                    this.props.questions.map((q, i) => {
                        return (
                            <div key={i} className={"mb-4"}>
                                <h6 className={"text-muted font-weight-bold pl-3"}>{q.testo}</h6>
                                <div className={"container-fluid"}>
                                    <div className={"row bg-white align-items-center border-top"}>
                                        <div className={"col-1 px-0 text-success text-center"}>
                                            <FaSolidIcon name={"users"}/>
                                        </div>
                                        <div className={"col px-1 py-2"}>
                                            <span>Numero</span>
                                            <input type={"number"}
                                                   className={"form-control"}
                                                   name={`risposta_${i}`}
                                                   onChange={this.handleChangeRisposta}
                                                   value={this.state.questions[i].risposta}/>
                                        </div>
                                        {
                                            this.props.hasPrice &&
                                            <div className={"col px-1 py-2"}>
                                                <span>Prezzo</span>
                                                <input type={"number"}
                                                       className={"form-control"}
                                                       name={`prezzo_${i}`}
                                                       value={this.state.questions[i].prezzo}
                                                       onChange={this.handleChangePriceAdPersonam}
                                                />
                                            </div>
                                        }
                                    </div>

                                    <div className={"row bg-white align-items-center border-bottom"}>
                                        <div className={"col-1 px-0"}> </div>
                                        {
                                            this.props.showSconto &&
                                            <div className={"col px-1 py-2"}>
                                                {this.calcolaScontoApplicato(i) > 0 ?
                                                <span className={"text-danger"}>Supplemento del:</span> :
                                                <span
                                                    className={"text-success"}>Sconto del:</span>}<br/><b>€{this.calcolaScontoApplicato(i)}%</b>
                                            </div>
                                        }
                                        {
                                            q.subtotal &&
                                            <div className={"col px-1 py-2"}>
                                                Tot. <br/><b>€{this.state.questions[i].subtotal}</b>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <span className={"text-warning"}>{this.state.errors[i]}</span>
                            </div>
                        );
                    })
                }
            </Fragment>
        );
    }
}

QuestionsInput.propTypes = {}
