import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";
import {Base64} from "../../helpers/Base64";

export const _User = {
    getUsablePackage: function (id_negozio = null, module = null, items = null, weekday = null) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/smart-package/user/get-usable`), {
                token: AuthAPI.getUserAccessToken(),
                id_negozio: id_negozio,
                module: module,
                items: Base64.encode(JSON.stringify(items)),
                weekday: weekday,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getPackage: function (id_negozio = null) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/smart-package/user/get-packages`), {
                token: AuthAPI.getUserAccessToken(),
                id_negozio: id_negozio
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getPackagesTransactions: function (cursor, id_wallet){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            cursor: cursor,
            id_wallet: id_wallet
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/smart-package/user/get-transactions`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    }
}