import React from "react";
import DipendenteWelfareDetailsTransaction from "./DipendenteWelfareDetailsTransaction";
import moment from "moment";


export default class DipendenteWelfareSingleTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionDetailOpen: false
        }
    }


    handleIconInOrOut =_=>{
        if(this.props.transazione.id_wallet_from === this.props.dipendente.id_wallet){
            return <h4 className={'text-danger text-lg'}><i className={'fas fa-arrow-circle-up'}></i></h4>;
        }else{
            return <h4 className={'text-success text-lg'}><i className={'fas fa-arrow-circle-down'}></i></h4>;
        }
    }

    handleImportoColor =_=>{
        if(this.props.transazione.id_wallet_from === this.props.dipendente.id_wallet){
            return <h6 className={'text-danger text-lg'}>€{this.props.transazione.importo}</h6>
        }else{
            return <h6 className={'text-success text-lg'}>€{this.props.transazione.importo}</h6>
        }
    }


    toggleTransactionDetail = _ => {
        this.setState({transactionDetailOpen: !this.state.transactionDetailOpen});
    }


    render() {

        var momentDate = moment(this.props.transazione.data_creazione);
        var m = momentDate.calendar(null, {
            sameDay: '[Oggi alle] HH:mm',
            nextDay: '[Domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[Ieri alle] HH:mm',
            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });

        return (
            <div>
                <ul className={"list-group"} onClick={this.toggleTransactionDetail}>
                    {this.props.transazione.id_transazione &&
                    <li className={"list-group-item list-group-item-action"}>
                        <div className={"row align-items-center"}>
                            <div className={"col-2"}>
                                {this.handleIconInOrOut()}
                            </div>
                            <div className={"col-7"}>
                                <b>{m[0].toUpperCase() + m.substr(1)}</b><br/>
                                <span>{this.props.transazione.descrizione}</span><br/>
                                <span className={"text-muted"}>{this.props.transazione.denominazione}</span>
                            </div>
                            <div className={"col-3"}>
                                {this.handleImportoColor()}
                            </div>
                        </div>
                    </li>
                    }
                </ul>


                <DipendenteWelfareDetailsTransaction
                    active = {this.state.transactionDetailOpen}
                    onDismiss = {this.toggleTransactionDetail}
                    user={this.props.user}
                    dipendente={this.props.dipendente}
                    transazione = {this.props.transazione}
                />
            </div>
        );
    }
}