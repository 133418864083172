import React from "react";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {DLA_PRENOTAZIONE_GRUPPO_QR} from "../../../../const/DeepLinkActionsCostants";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {PrenotazioneGruppoQRInfoPanel} from "./PrenotazioneGruppoQRInfoPanel";

export class PrenotazioneGruppoQRScanListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idPrenotazione: null
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_PRENOTAZIONE_GRUPPO_QR, this.handleOpenPanel)
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_PRENOTAZIONE_GRUPPO_QR, this.handleOpenPanel);
    }

    handleOpenPanel = idPrenotazione => this.setState({idPrenotazione: idPrenotazione});

    handleDismissResume = _ => this.setState({idPrenotazione: null});


    render() {
        if (!this.state.idPrenotazione) return '';
        return (
            <ModalPortalHOC>
                <PrenotazioneGruppoQRInfoPanel
                    idPrenotazione={this.state.idPrenotazione}
                    onDismiss={this.handleDismissResume}
                />
            </ModalPortalHOC>
        )
    }

}

PrenotazioneGruppoQRScanListener.propTypes = {

}