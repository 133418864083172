import React, {Fragment} from "react";
import loadImage from "blueimp-load-image";
import PropTypes from "prop-types";

export class InputMobileImagePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blob: null,
            preview: null,
        }
        this.previewRef = React.createRef();
    }

    handleImagePicked = async ({target}) => {
        if (target.type === 'file') {
            const value = target.files;
            if (!value) { //Immagine non selezionata dal filechooser
                return;
            }
            let blob = await fetch(URL.createObjectURL(value[0])).then(r => r.blob());
            this.setState({blob: blob});
            this.props.onChange(blob);
            loadImage(
                value[0],
                (img) => {
                    img.className = this.props.previewClassName
                    this.previewRef.current.appendChild(img);
                    this.setState({preview: img});
                },
                {
                    orientation: true,
                    maxWidth: window.innerWidth,
                    imageSmoothingQuality: 'high'
                }
            )
            target.value = null; //Elimino il file selezionato nella memoria dell'input file
        }
    }

    render() {
        return (
            <Fragment>
                <input
                    type={"file"}
                    accept={"image/*"}
                    capture={"camera"}
                    onChange={this.handleImagePicked}
                    className={this.props.className}
                    style={this.props.style}
                />
                <div ref={this.previewRef}>
                    {
                        !!this.props.previewInitialSrc && !this.state.preview &&
                        <img src={this.props.previewInitialSrc} className={this.props.previewClassName}
                             alt={"preview"}/>
                    }
                </div>
            </Fragment>
        )
    }

}

InputMobileImagePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    previewClassName: PropTypes.string,
    previewInitialSrc: PropTypes.string
}