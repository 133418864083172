import React from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import moment from "moment";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import LoginForm from "../Forms/LoginForm";

export default class LoginModal extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <Page
                title={"Login"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
                content={
                    <div className={"container text-on-bg-main pt-2"}>
                        <LoginForm onPasswordRecovery={this.props.onPasswordRecovery}/>
                    </div>
                }
            />
        );
    }

}


LoginModal.propTypes = {
    onDismiss: PropTypes.func,
};
