import styled from "styled-components";
import React, {Fragment} from "react";
import {NavbarBottomRounded} from "../../components/BaseComponents/NavbarBottomRounded";
import {FaRegularIcon, FaSolidIcon} from "../../components/FontAwesomeIcons";
import moment from "moment";
import AppConfig from "../../config/AppConfig";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";

const InfoBackdrop = styled.div`
        position:fixed;
        top:0; left:0; height:100vh; width:100vh;
        z-index:2000; 
        background-color:rgba(0,0,0,0.2);
`;

const InfoWrapper = styled.div`
    max-height: 0px;
    transition: max-height .3s ease;
    overflow:hidden;
    
    &.active{
        overflow-y: visible;
        max-height:85vh;
        transition: max-height .3s ease;
        padding-bottom:1rem;
    }
`;

const InfoToggleIcon = styled.div`
    text-align:center;
    font-size:70%;
`

const PreviewAppDiv = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
`;

const NavTop = styled.div`
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
    overflow:hidden;
    z-index:1030;
`;

const TypeAndTimePost = styled.div`
    font-size: 13px;
    margin-top: -3px;
`;

export default class PublicationInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        }
    }

    getCalendarTimestamp = datetime => {
        return moment(datetime).calendar(null, {
            sameDay: '[oggi alle] HH:mm',
            nextDay: '[domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[ieri alle] HH:mm',
            lastWeek: '[lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });
    }


    render() {

        return (
            <Fragment>
                {this.props.active && <InfoBackdrop className={"__androidDismissable__"} onClick={this.props.onClose}/>}
                <PreviewAppDiv>
                    {
                        (this.props.active) &&
                        <NavTop className={"bg-main-z2 text-main w-100 pb-2"} onClick={this.props.onClose}>
                            <InfoToggleIcon>
                                <FaSolidIcon name={"chevron-up"}/>
                            </InfoToggleIcon>
                        </NavTop>
                    }

                    {
                        <InfoWrapper className={"bg-main-z2 px-3 " + (this.props.active ? 'active' : '')}>
                            <div className={"text-center"}>
                                Postato da <b><SafeHtmlContainer html={this.props.infoPost.nome_negozio}/></b> tra
                                {
                                    this.props.infoPost.tipo.toLowerCase() === "eventi" ?
                                        " i suoi eventi"
                                        :
                                        " le sue offerte"
                                }
                                <br/>
                                <TypeAndTimePost className={"text-muted"}>
                                     {this.getCalendarTimestamp(this.props.infoPost.data_creazione)}
                                </TypeAndTimePost>
                            </div>
                        </InfoWrapper>
                    }
                </PreviewAppDiv>
            </Fragment>
        )


    }

}
