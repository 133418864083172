import React from "react";
import styled from "styled-components";
import {FaSolidIcon} from "../components/FontAwesomeIcons";
import i18n from "../i18n";
import {NeedAuthCallToActionHOC} from "../hoc/NeedAuthCallToActionHOC";

const ActionHeader = styled.div`
  z-index: 1031 !important;

  & .small {
    font-size: .65rem;
    line-height: 10px;
  }

  & .fas, & .far, & .fab {
    font-size: 1.2rem;
  }
  
  input[readonly]{
    box-shadow: none!important;
  }
`;

export default class LauncherActionHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    numberInBadge = _ => {
        if (this.props.nOfRecensioniInSospeso > 9)
            return "9+"
        if (this.props.nOfRecensioniInSospeso > 0)
            return this.props.nOfRecensioniInSospeso
        return undefined;
    }

    render() {
        return (
            <ActionHeader className={"bg-white shadow-sm sticky-top"}>
                <div className={"container py-3"}>
                    <div className={"row text-center align-items-center"}>

                        <div className={"col px-1 text-primary"} onClick={this.props.onMenuToggle}>
                            <div>
                                <FaSolidIcon name={"bars"}
                                             badge={
                                                 this.numberInBadge()
                                             }
                                             badgeColor={"success"}/>
                                <div className={"small"}>{i18n.t("launcher.homepage.menu")}</div>
                            </div>
                        </div>
                        <div className={"col px-1 text-success"} onClick={this.props.onQRScan}>
                            <FaSolidIcon name={"qrcode"}/>
                            <div className={"small"}>{i18n.t("launcher.homepage.scannerizza")}</div>
                        </div>
                        <NeedAuthCallToActionHOC className={"col px-1 text-magenta"}>
                            <div onClick={this.props.onAppAdd}>
                                <FaSolidIcon name={"plus-circle"}/>
                                <div className={"small"}>{i18n.t("launcher.homepage.aggiungi")}</div>
                            </div>
                        </NeedAuthCallToActionHOC>
                        <div className={"col-6 px-1 text-blue"} onClick={this.props.onSearch}>
                            <div className={"input-group border"} style={{borderRadius: 500, overflow: 'hidden'}}>
                                <div className={"input-group-prepend"}>
                                    <div className={"input-group-text bg-white px-2 text-blue border-0"}>
                                        <FaSolidIcon name={"search"}/>
                                    </div>
                                </div>
                                <input readOnly={true} className={"form-control border-0 px-0 bg-white "}
                                       placeholder={i18n.t("launcher.homepage.cerca-in-bookizon-placeholder")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </ActionHeader>
        )
    }

}
