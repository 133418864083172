import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import i18n from "../../../../i18n";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import FuxHTTP from "../../../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../../../config/AppConfig";
import AuthAPI from "../../../../api/AuthAPI";
import {CalendarioServizi} from "../ModuloServizi/CalendarioServizi";
import {ModuloSeatsAPI} from "../../../../api/ModuloSeats/ModuloSeatsAPI";
import {CalendarioSeats} from "../ModuloSeats/CalendarioSeats";

export class DashboardModuloSeatsButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageOpen: false,
            maps: null,
        }
    }

    handlePageOpen = _ => {
        this.setState({pageOpen: true});
        this.fetchInfoNegozio();
    }
    handlePageClose = _ => this.setState({pageOpen: false});

    fetchInfoNegozio = _ => {
        swalLoading();
        ModuloSeatsAPI.Admin.Maps.getList()
            .then(maps => this.setState({maps: maps}, _ => swal.safeClose()))
            .catch(swalError);
    }

    render() {
        return (
            <Fragment>
                <div className={"d-flex align-items-center justify-content-between p-3 border-top text-instagram"}
                     onClick={this.handlePageOpen}
                >
                    <span>
                        <FaSolidIcon name={"map"}/>&nbsp;
                        {i18n.t(`launcher.side-menu.${this.props.labelName}`)}
                    </span>
                    {
                        this.props.waitingCheckinBooks &&
                        <span className={"badge badge-pill badge-primary"}>
                            {this.props.waitingCheckinBooks} in attesa check-in
                        </span>
                    }
                </div>
                <ModalPortalHOC>
                    {
                        (this.state.maps && this.state.pageOpen) &&
                        <CalendarioSeats maps={this.state.maps} onDismiss={this.handlePageClose}/>
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DashboardModuloSeatsButton.propTypes = {
    labelName: PropTypes.string.isRequired,
    userNegozio: PropTypes.object,
    waitingCheckinBooks: PropTypes.number,
}