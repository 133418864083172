import React, {Fragment} from "react";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {FaRegularIcon, FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import PropTypes from "prop-types";
import {StoriesLikeSelector} from "../../../../../components/GenericUI/StoriesLikeSelector/StoriesLikeSelector";
import {getCompressedImageUrl} from "../../../../../helpers/ImageUtility";
import {FullscreenInput} from "../../../../../components/Forms/FullscreenInputs";
import ToggleSwitch from "../../../../../components/BaseComponents/ToggleSwitch/ToggleSwitch";
import {InputDatepicker} from "../../../../../components/BaseComponents/InputDatepicker";
import {timepickerConfig} from "../../../../../const/DatePickerConstants";
import moment from "moment";
import QuestionsInput from "./QuestionsInput";
import DashboardDateChooserPage from "../../ModuloServizi/DashboardDateChooserPage";
import DashboardSessionsChooserPage from "./DashboardSessionsChooserPage";
import {NegozioOpenStore} from "../../../../../stores/NegozioOpenStore";
import {PageFooter} from "../../../../../components/BaseComponents/Page/PageFooter";
import DashboardUserInfoPage from "./DashboardUserInfoPage";
import DashboardResumeAndCostsPage from "./DashboardResumeAndCostsPage";
import {swal, swalError, swalLoading, swalSuccess} from "../../../../../helpers/SweetAlertWrapper";
import DashboardUtentiAPI from "../../../../../api/DashboardUtentiAPI";
import {ModuloGruppiAPI} from "../../../../../api/ModuloGruppi/ModuloGruppiAPI";


export default class DashboardAddBookableGroupsBook extends React.Component {
    constructor(props) {
        super(props);
        this.startingState = {
            //Prendere le prenotazioni manualmente
            prenotazioneManuale: false,
            //Riguardo il gruppo scelto
            groupSelected: '',
            questions: [],
            partecipanti: 0,
            subtotalePartecipanti: 0,
            perc_sconto: 0,
            //Riguardo le date
            data: moment().format('DD-MM-YYYY'),
            orario_inizio: '09:00',
            orario_fine: '11:00',
            forceBook: false,
            sessionChooserOpen: false,
            sessionChooseData: false,
            totalePrenotazione: 0,
            importoScontoGlobale: 0,
            //User info step
            userInfoPageOpen: false,
            metodoPagamento: 'contanti',
            note: '',
            utente: [],
            //Resume and Costs step
            resumeAndCostsPageOpen: false
        }
        this.state = this.startingState

        this.currentHour = moment().hours();
        this.moment_currentTime = moment(this.props.selectedDate || new Date()).hours(this.currentHour).minutes(0).seconds(0);
        this.moment_currentTimeEnd = moment(this.props.selectedDate || new Date()).hours(this.currentHour).minutes(0).seconds(0).add(30, 'minutes');
    }

    componentDidMount() {
        //Se il gruppo non è passato di default allora mi setto il primo in ordine
        this.handleChangeGruppo(this.props.id_group_selected ?
            this.props.id_group_selected :
            (this.props.groups.length > 0 ? this.props.groups[0].id_gruppo : null)
        )
    }


    /*---------- UTILITY STEPS -------------*/

    /**
     *Controllo se posso andare allo step numero 2 (se la scelta è manuale verrà saltato lo step delle sessioni)
     * @MARK Step. n.2
     */
    canGoToSecondStep =_=>{
        if(this.state.prenotazioneManuale){
            return !!this.state.partecipanti && !!this.state.groupSelected && !!this.state.data
        }
        return !!this.state.partecipanti && !!this.state.groupSelected;
    }

    toggleSessionChooser = _ => {
        this.setState({sessionChooserOpen: !this.state.sessionChooserOpen});
    }

    //Setto i dati una volta scelte le sessioni
    handleSessionChoose = sessionChooseData => {
        this.setState({sessionChooseData: sessionChooseData.sessioni, data: sessionChooseData.data}, _=>{
            this.calcolaTotalePrenotazione()
        });
        this.userInfoStep()
    }


    /**
     * Se la prenotazione è manuale sarà il 2° step, altrimenti sarà il terzo
     * @MARK Step user info
     */
    userInfoStep =_=>{
        this.userInfoPageToggle()
    }

    userInfoPageToggle = _ =>{
        this.setState({userInfoPageOpen: !this.state.userInfoPageOpen})
    }

    handleUserInfoChoose = userInfo =>{
        this.setState({
            utente: userInfo.cliente,
            note: userInfo.note,
            metodoPagamento: userInfo.metodoPagamento
        }, _=>{
            this.resumeAndCostsStep()
        })
    }


    /**
     *@MARK resume page step
     */
    resumeAndCostsStep =_=>{
        this.resumeAndCostsPageToggle()
    }

    resumeAndCostsPageToggle = _ =>{
        this.setState({resumeAndCostsPageOpen: !this.state.resumeAndCostsPageOpen})
    }

    handleResumeAndCostsPageConfirm = importoScontoGlobale =>{
        this.setState({importoScontoGlobale: importoScontoGlobale}, _=>{
            this.handleSubmit()
        })
    }


    /*---------- UTILITY DOMANDE -----------*/

    //Setto le domande sui partecipanti in base al gruppo scelto
    getQuestionsOfGroups = _ => {
        const gruppo = this.state.groupSelected;
        //Recupero solo le domande numeriche del gruppo scelto
        let numberQuestions = gruppo.domande_partecipanti.filter(d => d.min !== undefined && d.max !== undefined);
        //Se non ci sono domande inietto quella di default
        if (!numberQuestions.length) {
            numberQuestions.push({testo: 'Per quante persone vuoi prenotare?', min: 1, max: 0, prezzo: 0});
        }
        //Se le domande hanno tutte prezzo 0, inietto il prezzo del servizio
        const prezziNonZero = numberQuestions.map(d => d.prezzo).filter(p => p > 0)
        if (!prezziNonZero.length) {
            numberQuestions.map(d => {
                //Se il gruppo ha prezzo "null" inietto 0
                d.prezzo = gruppo.prezzo ? gruppo.prezzo : 0;
                return d;
            })
        }

        //Setto prezzo inizale all'interno del quale verrà salvato il prezzo al momento dell'inizializzazione per mantenre uno storico
        numberQuestions.map(d => {
            d.prezzoIniziale = d.prezzo;
            return d;
        })

        //Setto le sconto all'interno delle domande
        numberQuestions.map(d =>{
            d.prezzo = (d.prezzo-(d.prezzo*this.state.groupSelected.perc_sconto*0.01)).toFixed(2);
        })

        console.log(numberQuestions)
        //Salvo le domande all'interno dello stato
        this.setState({questions: numberQuestions});
    }


    onChangeQuestionsInfo = newQuestionsInfo => {
        let partecipanti = 0
        let subtotalePartecipanti = 0;
        newQuestionsInfo.map(q => {
            //Calcolo il totale dei partecipanti
            partecipanti = ((q.risposta) ? parseInt(q.risposta) : 0) + partecipanti;
            //Calcolo il subtotale dovuto alle impostazioni dei partecipanti
            subtotalePartecipanti = ((q.subtotal ? parseFloat(q.subtotal) : 0) + subtotalePartecipanti)
        })

        this.setState({
            sessionChooseOpen: true,
            questions: newQuestionsInfo,
            partecipanti: partecipanti,
            subtotalePartecipanti: subtotalePartecipanti.toFixed(2)
        }, _=>{
            this.calcolaTotalePrenotazione()
        });
    }

    //Reset prezzo delle domande
    resetQuestionsPrice =_=>{
        let questions = [...this.state.questions]
        questions.map(d => {
            d.prezzo = d.prezzoIniziale;
            return d;
        })
        this.setState({questions: questions})
    }



    /*----------- UTILITY PREZZO ---------*/

    //Calcola il totale della prenotazione
    calcolaTotalePrenotazione = _ => {
        var totale = 0
        if(this.state.prenotazioneManuale){
            totale = this.state.subtotalePartecipanti
        }else{
            totale = this.state.sessionChooseData ? (this.state.subtotalePartecipanti * this.state.sessionChooseData.length).toFixed(2) : totale
        }
        this.setState({totalePrenotazione: parseFloat(totale).toFixed(2)})
    }

    //Calcola il numero delle sessioni selezionate con orario e data manuali
    setPriceForManualSessions = _ => {
        if (!this.state.prenotazioneManuale) return 0
        if (!this.state.groupSelected.prezzo) return 0
        //Recupero il numero del giorno della data
        const dayOfDateChose = moment(this.state.data, 'DD-MM-YYYY').day();
        //Recupero le informazioni sulla sessione nel giorno scelto
        const dayChoseSessions = this.state.groupSelected.sessionsInfo.sessioni[dayOfDateChose]
        if(!dayChoseSessions) return 0
        //Setto gli orari scelti in formato secondi per predisporli al confronto
        let orariScelti = this.orariInizioFineInUnix(this.state.orario_inizio, this.state.orario_fine)
        console.log(dayChoseSessions)

        //recupero le informazioni delle domande per le quali modificherò il prezzo
        let questions = [...this.state.questions]
        questions.map((q) => {return q.prezzo = 0})
        dayChoseSessions.map((d)=>{
            //Setto gli orari per il confronto
            let orariSessione = this.orariInizioFineInUnix(d.orario_inizio, d.orario_fine)
            var minutiCombacianti = (Math.min(orariScelti.orario_fine, orariSessione.orario_fine) - Math.max(orariScelti.orario_inizio, orariSessione.orario_inizio))/60
            if(minutiCombacianti >= 0){
                //Calcolo il prezzo al minuto per ogni servizio
                questions.map(q => {
                    //Prezzo totale della sessione per la domanda
                    return q.prezzo = (parseFloat(q.prezzo) + ((parseFloat(q.prezzoIniziale) / (this.convertH2M(d.orario_fine) - this.convertH2M(d.orario_inizio)))*minutiCombacianti)).toFixed(2)
                })
            }
        })
        console.log(questions)
        this.setState({questions: questions})
    }

    //Restituisce un oggetto contenente i due orari inizio e fine in formato unix
    orariInizioFineInUnix = (orario_inizio, orario_fine) => {
        let orariUnix = []
        orariUnix.orario_inizio = moment('1970-01-01 ' + orario_inizio).unix()
        orariUnix.orario_fine = moment(((orario_fine > orario_inizio) ? '1970-01-01 ' : '1970-01-02 ') + orario_fine).unix()
        return orariUnix
    }

    //Converte orario in minuti
    convertH2M = timeInHour => {
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }


    /* ---------- UTILITI UTENTI ----------*/
    canFindAvailability = _ => {
        return !!this.state.partecipanti && !!this.state.groupSelected;
    }

    /*-----------GESTIONE INPUT-----------*/

    handleChangeGruppo = id_gruppo => {
        let groupSelected = this.props.groups.find(object => {
            return object.id_gruppo === id_gruppo;
        });
        //Prendo lo stato iniziale e ci assegno il nuovo gruppo
        let startingState = this.startingState
        startingState.groupSelected = groupSelected
        this.setState({...startingState}, _=>{this.getQuestionsOfGroups()});
    }

    togglePrenotazioneManuale = _ => {
        this.setState({prenotazioneManuale: !this.state.prenotazioneManuale})
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value,
        }, _=>{
            //Se viene modificato il modo in cui devono essere prese le prenotazioni prenoto
            if(target.name === "prenotazioneManuale"){
                if(this.state.prenotazioneManuale){
                    this.setPriceForManualSessions()
                }else {
                    this.resetQuestionsPrice()
                }
            }
        });
    }

    /**
     * @MARK Gestione data e orario blocco
     * */
    handleDataChange = ({value}) => this.setState({data: moment(value).format('DD-MM-YYYY')});
    handleOrarioInizioChange = ({value}) => this.setState({orario_inizio: moment(value).format('HH:mm')});
    handleOrarioFineChange = ({value}) => this.setState({orario_fine: moment(value).format('HH:mm')});


    /**
     * Prenotazione
     * */
    handleSubmit = _ => {
        //Se la prenotazione è avvenuta manualmente setto la sessione con inizio e fine scelti
        let sessionData = {...this.state.sessionChooseData}
        if(this.state.prenotazioneManuale){
            sessionData = [{"orario_inizio": this.state.orario_inizio, "orario_fine": this.state.orario_fine}]
        }
        console.log(sessionData)
        swalLoading('Attendere prego...');
        this.getIdUtente()
            .then(id_utente => {
                ModuloGruppiAPI.Dashboard.book({
                    id_gruppo: this.state.groupSelected.id_gruppo,
                    id_utente: id_utente,
                    data: this.state.data,
                    sessionData: sessionData,
                    //Se la prenotazione è manuale NON devono essere utilizzate le sessioni default
                    useDefaultSessions: !this.state.prenotazioneManuale,
                    note: this.state.note,
                    forceBook: this.state.forceBook,
                    partecipanti: this.state.partecipanti,
                    questions: this.state.questions,
                    totalePrenotazione: this.state.totalePrenotazione,
                    importoScontoGlobale: this.state.importoScontoGlobale,
                    metodoPagamento: this.state.metodoPagamento
                })
                    .then((response) => {
                        swalSuccess(response)
                        this.props.bookConfirmed()
                    })
                    .catch(response => swalError(response));
            }).catch(swalError)
    }

    getIdUtente = _ => {
        return new Promise((resolve, reject) => {
            if (this.state.utente.id_utente) {
                resolve(this.state.utente.id_utente);
            } else {
                DashboardUtentiAPI.createGuest(this.state.utente.nome)
                    .then(id_utente => {
                        resolve(id_utente);
                    }).catch(reject);
            }
        });
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Aggiungi prenotazione"}

                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <Fragment>
                            {/*Scegliere il servizio da visulaizzare*/}
                            <div className={"container my-3 __disable-swipe"}>
                                <StoriesLikeSelector
                                    items={
                                        this.props.groups.map(g => {
                                            return {
                                                value: g.id_gruppo,
                                                label: g.nome,
                                                imageUrl: g.immagine
                                            }
                                        })
                                    }
                                    circleSize={60}
                                    onChange={this.handleChangeGruppo}
                                    defaultValue={this.props.id_group_selected}
                                />
                            </div>

                            <hr className={"my-4"}/>

                            {/*Sceglo se la prenotazione è manuale*/}
                            <div className={"d-flex align-items-center mb-4"}>
                                <ToggleSwitch id={`prenotazione_manuale`} currentValue={this.state.prenotazioneManuale}
                                              onChange={this.togglePrenotazioneManuale}/>
                                <span className={"label"}>Inserisci manualmente data e ora</span>
                            </div>

                            {/*Se la prenotazione è manuale faccio inserire ora e data*/}
                            {
                                this.state.prenotazioneManuale &&
                                <Fragment>
                                    {/* Data prenotazione */}
                                    <div className={"form-group mb-4"}>
                                        <h6 className={"text-muted font-weight-bold pl-3"}>Data</h6>
                                        <div className={"container-fluid"}>
                                            <div className={"row bg-white align-items-center border-top border-bottom"}>
                                                <div className={"col-1 px-0 text-success text-center"}>
                                                    <FaRegularIcon name={"calendar"}/>
                                                </div>
                                                <div className={"col px-0"}>
                                                    <InputDatepicker
                                                        className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                        value={this.state.data}
                                                        onChange={this.handleDataChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Orario inizio & fine */}
                                    <div className={"form-group mb-4"}>
                                        <h6 className={"text-muted font-weight-bold pl-3"}>Orario inizio & fine</h6>
                                        <div className={"container-fluid"}>
                                            <div className={"row bg-white align-items-center border-top border-bottom"}>
                                                <div className={"col-1 px-0 text-success text-center"}>
                                                    <FaRegularIcon name={"calendar"}/>
                                                </div>
                                                <div className={"col px-0"}>
                                                    <InputDatepicker
                                                        className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                        value={this.state.orario_inizio}
                                                        onChange={this.handleOrarioInizioChange}
                                                        format={'hh:mm'}
                                                        config={timepickerConfig}
                                                        startDate={this.moment_currentTime.toDate()}
                                                    />
                                                </div>
                                                <div className={"col px-0"}>
                                                    <InputDatepicker
                                                        className={"form-control border-0 rounded-0 text-center bg-white m-0 shadow-none"}
                                                        value={this.state.orario_fine}
                                                        onChange={this.handleOrarioFineChange}
                                                        format={'hh:mm'}
                                                        config={timepickerConfig}
                                                        startDate={this.moment_currentTime.toDate()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*IGNORA DISPONIBILITA REALTE*/}
                                    <div className={"container mb-4"}>
                                        <div className={"row"}>
                                            <div className={"col-12 form-group"}>
                                                <label>
                                                    <input
                                                        type={"checkbox"}
                                                        name={"forceBook"}
                                                        onChange={this.handleInputChange}
                                                        checked={this.state.forceBook}
                                                    /> Ignora disponibilità reale
                                                </label>
                                                <div className={"text-muted"}>
                                                    (la prenotazione verrà forzata ignorando gli appuntamenti già
                                                    esistenti)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            <hr className={"mb-4"}/>

                            {/*INFORMAZIONI SULLE DOMANDE*/}
                            <div className={"form-group mb-4"}>
                                {
                                    this.state.questions.length > 0 &&
                                    <QuestionsInput
                                        questions={this.state.questions}
                                        percSconto={this.state.groupSelected.perc_sconto}
                                        hasPrice={!!this.state.groupSelected.prezzo}
                                        showSconto={!this.state.prenotazioneManuale}
                                        onChange={this.onChangeQuestionsInfo}
                                    />
                                }
                            </div>

                            {/*PRENOTAZIONE ATTRAVERSO DISPONIBILITA bookizon
                            {
                                !this.state.prenotazioneManuale &&
                                <div className={"container mb-4"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <button className={"btn btn-link p-0 my-3"} disabled={!this.canFindAvailability()}
                                                    onClick={this.toggleSessionChooser}>
                                                <i className={"fas fa-calendar-alt"}/> TROVA DISPONIBILITÀ CON BOOKIZON
                                            </button>
                                        </div>
                                        {
                                            this.state.sessionChooseData &&
                                            <div className={"col-12"}>
                                                <div className={"card shadow-sm p-2"}>
                                            <span>Hai scelto: <b className={"text-secondary"}>{this.state.data}</b>
                                                {this.state.sessionChooseData.map(s =>{
                                                    return (<span> dalle <b>{s.orario_inizio}</b> alle <b>{s.orario_fine}</b></span>)
                                                })}
                                            </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            <hr/>

                            {/*INFO AGGIUNTIVE
                            <div className={"container mb-2"}>
                                <h6 className={"text-muted font-weight-bold"}>Come pagherà il cliente?:</h6>
                                <select className={"form-control"} name="metodoPagamento"
                                        value={this.state.metodoPagamento} onChange={this.handleInputChange}
                                >
                                    <option value="consegna">Contanti</option>
                                    <option value="carta_credito">Carta di credito</option>
                                </select>
                                <div>
                                    {
                                        !this.state.notesVisible &&
                                        <button className={"btn btn-link"} onClick={this.toggleNote}>
                                            <i className={"fa fa-plus"}></i> Aggiungi note interne
                                        </button>
                                    }
                                </div>
                            </div>

                            {
                                this.state.notesVisible &&
                                <div className={"form-group"}>
                                    <label>Note interne</label>
                                    <textarea className={"form-control"} name={"note"} onChange={this.handleInputChange}/>
                                    <button className={"btn btn-link text-danger"} onClick={this.toggleNote}>
                                        <i className={"fa fa-trash"}></i> Rimuovi note
                                    </button>
                                </div>
                            }

                            <hr/>

                            {/*INFORMAZIONI COSTI PRENOTAZIONE
                            <div className={"container mt-3"}>
                                <h6 className={"text-muted font-weight-bold"}>Info & Costi</h6>
                                <span><b>Prezzo totale dei partecipanti:</b> €{this.state.subtotalePartecipanti}</span><br/>
                                {!this.state.prenotazioneManuale && <span><b>Numero delle sessioni prenotate:</b> {this.state.sessionChooseData ? this.state.sessionChooseData.length : 0}</span>}
                                <div className={"row"}>
                                    <div className={"col-lg-6 col-md-6 d-flex align-items-center mt-2"}>
                                        <label>Aggiungi uno sconto (in euro)</label>
                                        <input type={"number"}
                                               className={"form-control ml-2"}
                                               name={'importoScontoGlobale'}
                                               value={this.state.importoScontoGlobale}
                                               onChange={this.handleInputChange}
                                        />
                                    </div>
                                </div>
                                <h4 className={"mt-3"}>Totale: €{this.calcolaTotaleScontato()}</h4>
                            </div>*/}
                        </Fragment>
                    }
                    footer={
                        <PageFooter className={"bg-main-z2 p-3 border-top"}>
                            <button
                                className={"btn btn-lg btn-block btn-primary text-on-bg-primary "+(this.canGoToSecondStep() ? "" : "disabled")+""}
                                onClick={this.state.prenotazioneManuale ? this.userInfoPageToggle : this.toggleSessionChooser}>
                                {this.state.prenotazioneManuale ? "Procedi" : "Scegli orario"} <FaSolidIcon
                                name={"chevron-right"}/>
                            </button>
                        </PageFooter>
                    }
                />

                {
                    this.state.sessionChooserOpen &&
                    <DashboardSessionsChooserPage
                        id_gruppo={this.state.groupSelected.id_gruppo}
                        partecipanti={this.state.partecipanti}
                        onChoose={this.handleSessionChoose}
                        onDismiss={this.toggleSessionChooser}
                        maxSessions={10}
                        hideAvailability={false}
                        maxVisibleDays={365}
                        advanceDays={365}
                        confirmText={"Conferma"}
                    />
                }

                {
                    this.state.userInfoPageOpen &&
                        <DashboardUserInfoPage
                            onDismiss = {this.userInfoPageToggle}
                            onChoose={this.handleUserInfoChoose}
                        />
                }

                {
                    this.state.resumeAndCostsPageOpen &&
                    <DashboardResumeAndCostsPage
                        onDismiss = {this.resumeAndCostsPageToggle}
                        subtotalePartecipanti = {this.state.subtotalePartecipanti}
                        totalePrenotazione = {this.state.totalePrenotazione}
                        sessionChooseData = {
                            this.state.prenotazioneManuale ?
                                [{orario_inizio: this.state.orario_inizio, orario_fine: this.state.orario_fine}]:
                                this.state.sessionChooseData
                        }
                        group = {this.state.groupSelected}
                        data = {this.state.data}
                        utente = {this.state.utente}
                        onChoose={this.handleResumeAndCostsPageConfirm}
                    />
                }
            </Fragment>
        );
    }
}

DashboardAddBookableGroupsBook.propTypes = {
    onDismiss: PropTypes.func,
    groups: PropTypes.array,
    id_group_selected: PropTypes.string || null
}
