import React, {Fragment} from "react";
import Page from "../BaseComponents/Page/Page";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {ServizioCard} from "../ModuloServizi/ListinoServizi/ServizioCard";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";
import PropTypes from "prop-types";
import {ModuloCassaAPI} from "../../api/ModuloCassa/ModuloCassaAPI";
import Pagination from "../BaseComponents/Pagination/Pagination";
import CronologiaUserListView from "../../launcher/CronologiaUser/CronologiaUserListView";
import {EllipsisLoader} from "../GenericUI/EllipsisLoader/EllipsisLoader";
import emptyBox from "../../img/illustrations/empty.svg";
import {getTotaleScontrino} from "../../launcher/dashboard/DashboardView/ModuloCassa/ReceiptsUtils";
import ReceiptPreview from "./ReceiptPreview";


const PAGE_SIZE = 10

export default class YourReceiptPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            totalItems: 0,
            items: []
        }
    }

    componentDidMount() {
        this.fetchItems()
    }

    handlePageChange = pageNumber => this.setState({currentPage: pageNumber}, this.fetchItems);

    fetchItems = _ => {
        this.setState({fetching: true});
        ModuloCassaAPI.User.getUserReceiptsInShop(NegozioOpenStore.infoNegozio.id_negozio, PAGE_SIZE, (this.state.currentPage - 1) * PAGE_SIZE)
            .then(data => {
                this.setState({
                    totalItems: data.total,
                    items: data.items,
                    fetching: false
                });
            })
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"I tuoi scontrini"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >
                    <div className={"container mt-2 text-muted text-center"}>
                        <small>
                            In questa sezione potrai trovare gli scontrini che l'attività ha collegato al tuo profilo
                            utente. Ricorda, non sempre le attività collegano gli scontrini, quindi potresti non vederli
                            tutti.
                        </small>
                    </div>

                    <div className={"container my-5"}>
                        {
                            !this.state.fetching ?
                                <div>
                                    {
                                        this.state.items && this.state.items.length > 0 ?
                                            this.state.items.map((i, key) => {
                                                return (
                                                    <div key={key} className={"my-2"}>
                                                        <ReceiptPreview
                                                            idRicevuta={i.id_ricevuta}
                                                            numeroDocumento={i.numero_documento}
                                                            righe={i.righe}
                                                            pagamenti={i.pagamenti}
                                                            dataCreazione={i.data_creazione}
                                                        />
                                                    </div>

                                                )
                                            })
                                            :
                                            <div className={"text-center my-3"}>
                                                Non ci sono scontrini da visualizzare
                                                <img src={emptyBox} className={"img-fluid w-75 mx-auto mt-3"}
                                                     alt={"Nessuna immagine disponibile"}/>
                                            </div>
                                    }
                                </div>
                                :
                                <EllipsisLoader color={"#cccccc"}/>
                        }

                        <div className={"justify-content-center d-flex mt-3"}>
                            <Pagination
                                activePage={this.state.currentPage}
                                itemsCountPerPage={PAGE_SIZE}
                                totalItemsCount={this.state.totalItems}
                                pageRangeDisplayed={5}
                                itemClass="page-item"
                                linkClass="page-link"
                                onChange={this.handlePageChange}
                            />
                        </div>
                    </div>
                </Page>
            </Fragment>
        );
    }
}


YourReceiptPage.propTypes = {
    onDismiss: PropTypes.func
}