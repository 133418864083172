import styled from "styled-components";
import React from "react";
import defaultProfilePicM from "../../../img/profile-placeholder-m.png";
import defaultProfilePicF from "../../../img/profile-placeholder-f.png";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";

export const SCUserProfilePicMd = styled.div`
    width:40px;
    height:40px;
    background-size:cover;
    background-position:center center;
    background-image:url(${props => props.src});
`;

export class UserSearchResult extends React.Component {

    constructor(props) {
        super(props);
    }

    getNumber = _ => {
        if (!this.props.cellulare) return this.props.cellulare;
        return this.props.cellulare.replace('+39', '');
    }

    render() {
        return (
            <div className={"card shadow-sm border-0"} onClick={this.props.onClick}>
                <div className={"card-body d-flex align-items-center p-2"}>
                    <SCUserProfilePicMd src={this.props.sesso == 0 ? defaultProfilePicF : defaultProfilePicM}
                                        className={"mr-2"}/>
                    <span>
                        {this.props.nome} {this.props.cognome}
                        <br/>
                        <small className={"text-muted"}>
                            {
                                this.props.email &&
                                <span className={"mr-2"}><FaSolidIcon name={"envelope"}/> {this.props.email}</span>
                            }
                            {
                                this.props.cellulare &&
                                <span className={"mr-2"}><FaSolidIcon name={"phone"}/> {this.getNumber()}</span>
                            }<br/>
                            {
                                this.props.citta &&
                                <span className={"mr-2"}><FaSolidIcon name={"map-marker-alt"}/> {this.props.citta}</span>
                            }
                        </small>
                    </span>
                </div>
            </div>
        )
    }

}
