import React from 'react';
import styled from "styled-components";
import {swal} from "../../helpers/SweetAlertWrapper";
import {FaSolidIcon} from "../FontAwesomeIcons";
import MenuCategoryGalleryContainer from "./MenuCategoryGalleryContainer";
import Page from "../BaseComponents/Page/Page";
import isCategoryEmpty from "../../helpers/ProdottiCategorieHelpers";
import {MenuDigitaleDownloadBanner} from "./MenuDigitaleDownloadBanner";
import {BANNER_POSITION_MENU_HOMEPAGE, BannerInAppContainer} from "../BannerInApp/BannerInAppContainer";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";

export default class MenuCategoryGalleryPage extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.category !== nextProps.category ||
            this.props.productList !== nextProps.productList;
    }

    handleSearch = (queryString, sortType) => {
        return this.props.onSearch(queryString, sortType, this.props.category.child);
    }

    //Filtra le categorie figlie se necessario
    getFilteredChilds = _ => {
        if (!this.props.hideEmptyCategory) {
            return this.props.category.child;
        }
        return this.props.category.child.filter(c => !isCategoryEmpty(c, this.props.productList));
    }

    render() {
        const isRootGallery = !this.props.category.id_categoria;
        return (
            <Page
                title={this.props.category.nome}
                leftIcon={!!this.props.onDismiss && <FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={true}
                rightIcon={this.props.rightIcon}
            >
                <div className={"pb-5"}>
                    {
                        !this.props.onDismiss && <MenuDigitaleDownloadBanner/>
                    }
                    {
                        isRootGallery &&
                        <div className={"__disable-swipe"}>
                            <BannerInAppContainer
                                bannerPosition={BANNER_POSITION_MENU_HOMEPAGE}
                                idNegozio={NegozioOpenStore.id_negozio}
                            />
                        </div>
                    }
                    <MenuCategoryGalleryContainer
                        onCategoryChoose={this.props.onCategoryChoose}
                        onCategoryLeafChoose={this.props.onCategoryLeafChoose}
                        categoryList={this.getFilteredChilds()}
                        onSearch={this.handleSearch}
                        onProductDetailOpen={this.props.onProductDetailOpen}
                        searchResultItemGenerator={this.props.searchResultItemGenerator}
                    />
                </div>
            </Page>
        );
    }
}