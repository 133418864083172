import React, {Fragment} from "react";
import styled from "styled-components";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {Draggable} from "react-beautiful-dnd";
import {swal, swalPopOver} from "../../helpers/SweetAlertWrapper";
import AppConfig from "../../config/AppConfig";
import ShareComponent from "../../components/ShareComponent";
import EllipsisMenu from "./EllipsisMenu";
import ShopFeaturesIcon from "../../components/ShopFeaturesIcon";
import ShopOpenOrClose from "../../components/ShopOpenOrClose";
import {AppIconThumbnail} from "../../components/BaseComponents/AppIconThumbnail";

const Item = styled.div.attrs(props => ({
    className: "card my-2 shadow-sm border-0"
}))`
background-color: ${props => props.isDragging ? '#a9afe4' : 'auto'};
box-shadow: ${props => props.isDragging ? '0 .5rem 1rem rgba(0,0,0,.15)' : 'none'};
`;

const ListGroupItemUnselectable = styled.div`
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
`;

const AppNameStyle = {
    marginBottom: "-6px"
};

export default class LauncherAppListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.editMode !== this.props.editMode ||
            nextState.menuOpen !== this.state.menuOpen ||
            nextProps.negozio.accetta_asporto !== this.props.negozio.accetta_asporto ||
            nextProps.negozio.accetta_consegna !== this.props.negozio.accetta_consegna ||
            nextProps.negozio.orari !== this.props.negozio.orari ||
            nextProps.negozio.giorni_chiusura !== this.props.negozio.giorni_chiusura;
    }

    handleOpenApp = _ => {
        if (this.state.menuOpen) return;
        this.props.onAppOpen(this.props.negozio.id_negozio);
    };

    handleDeleteApp = _ => {
        this.closePopover();
        this.props.onAppDelete(this.props.negozio.id_negozio);
    };

    handleAppReorder = _ => {
        this.closePopover();
        this.props.onAppReorder();
    };

    handleAppShare = _ => {
        var qrCodeImage = AppConfig.webServerUrl + "/api/shops/qrcode/" + this.props.negozio.id_negozio;
        swal.fire({
            imageUrl: qrCodeImage,
            html: <ShareComponent codiceNegozio={this.props.negozio.codice} appName={this.props.negozio.nome}/>,
            showConfirmButton: false,
            showCloseButton: true,
            focusCancel: false, focusConfirm: false
        });
    };

    closePopover = _ => {
        swal.safeClose();
    }

    toggleMenu = _ => {
        this.setState({menuOpen: !this.state.menuOpen});
    }


    render() {
        return (
            <Draggable draggableId={this.props.negozio.id_negozio} index={this.props.index}
                       isDragDisabled={!this.props.editMode}>
                {
                    (provided, snapshot) => (
                        <Item
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            isDragging={snapshot.isDragging}
                            className={"p-0"}
                        >
                            <div className={"row m-0 align-items-center"}>
                                <div
                                    className={"col-2 px-0 py-2 justify-content-center d-flex " + (this.props.editMode ? 'jiggle' : '')}>
                                    <AppIconThumbnail size={"48px"} src={this.props.imageUrl}/>
                                </div>
                                <div className={"col px-0 py-2 text-truncate"} onClick={this.handleOpenApp}>
                                    <h5 className="mt-0 text-truncate"
                                        style={AppNameStyle}>{this.props.negozio.nome}</h5>
                                    <small className={"text-muted"}>{this.props.negozio.nome_categoria}</small><br/>
                                    <div>
                                        {
                                            this.props.negozio.features && this.props.negozio.features.map(
                                                (f, i) => <ShopFeaturesIcon key={i} feature={f}/>
                                            )
                                        }
                                        {
                                            this.props.negozio.orari &&
                                            <ShopOpenOrClose
                                                orariNegozio={this.props.negozio.orari}
                                                orarioAttuale={this.props.negozio.orario_giornata_attuale !== undefined ? this.props.negozio.orario_giornata_attuale : ""}
                                                giorniChiusura={this.props.negozio.giorni_chiusura !== undefined ? this.props.negozio.giorni_chiusura : ""}
                                            />
                                        }
                                    </div>
                                </div>
                                <div
                                    className={"col-1 px-0 py-2 justify-content-center d-flex align-items-center text-muted"}
                                    onClick={this.toggleMenu}
                                >
                                    {
                                        this.props.editMode ?
                                            <FaSolidIcon name={"grip-lines"}/>
                                            :
                                            <Fragment>
                                                <FaSolidIcon name={"ellipsis-v"}/>
                                                {
                                                    this.state.menuOpen &&
                                                    <EllipsisMenu
                                                        onReorder={this.handleAppReorder}
                                                        onDelete={this.handleDeleteApp}
                                                        onShare={this.handleAppShare}
                                                        onDismiss={this.toggleMenu}
                                                    />
                                                }
                                            </Fragment>
                                    }
                                </div>
                                <div className={"col-12 pb-2 mt-n2"}>
                                    <div>
                                        {
                                            this.props.negozio.accetta_consegna === false &&
                                            <small className={"text-warning"}>
                                                <FaSolidIcon name={"exclamation-circle"}/> Non accetta più consegna a
                                                domicilio oggi
                                            </small>
                                        }
                                    </div>
                                    <div>
                                        {
                                            this.props.negozio.accetta_asporto === false &&
                                            <small className={"text-warning"}>
                                                <FaSolidIcon name={"exclamation-circle"}/> Non accetta più asporto oggi
                                            </small>
                                        }
                                    </div>
                                </div>

                                {
                                    this.props.negozio.food_free_shipping_value > 0 &&
                                    <div className={"col-12 bg-soft-success py-2"}>
                                        <small className={"font-weight-bold text-success"}>
                                            <FaSolidIcon name={"tags"}/> Consegna gratuita
                                            con {this.props.negozio.food_free_shipping_value}€ di spesa
                                        </small>
                                    </div>
                                }
                            </div>
                        </Item>
                    )
                }
            </Draggable>
        );
    }

}
