import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";



export const NegoziPartnershipAPI = {
    getAllShopPartners: (id_negozio) => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/negozi-partnership/get-all-shop-partners`), {
                id_negozio: id_negozio,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    salvaVisite: (id_negozio_da, id_negozio_a, id_lista = null, tipo_operazione = null) => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/negozi-partnership/save-visit'), {
                id_negozio_a: id_negozio_a,
                id_negozio_da: id_negozio_da,
                id_lista: id_lista,
                tipo_operazione: tipo_operazione,
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    getShopsInListPartenrship: (id_negozio, show_type) =>{
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url('/negozi-partnership/get-shops-in-list'), {
                id_negozio: id_negozio,
                show_type: show_type,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    }
};

export default NegoziPartnershipAPI;