import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "./FontAwesomeIcons";
import styled from "styled-components";
import {ReactComponent as BookizonIcon} from "../launcher/svg/logo-icon.svg";

const BookizonIconWrapper = styled.div`
    height:20px;
    width:20px;
    & svg{
        transform:scale(1);
    }
`;

export const ButtonCloseAllApp = styled.button`
    position:fixed;
    bottom:50px;
    border-radius: 500px;
    background-color: #283068 !important;
    color: white;
    font-size:75%;
    left:-20px;
    padding-left: 30px;
    z-index:1101;
    overflow:hidden;
    background-color: white;
`;

export class ButtonToGoBookizonHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            <ButtonCloseAllApp id={"buttonToCloseAllApp"}
                               className={"btn btn-circle closeAllApp shadow d-none"}
                               onClick={this.props.closeApp}>
                <div className={"d-flex align-items-center"}>
                    <BookizonIconWrapper><BookizonIcon/></BookizonIconWrapper>
                    <div className={"ml-2"}>Torna a Bookizon</div>
                </div>
            </ButtonCloseAllApp>
        )
    }

}


ButtonToGoBookizonHome.propTypes = {
    closeApp: PropTypes.func.isRequired,
}
