import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import {swalError, swalToast} from "../../../helpers/SweetAlertWrapper";
import moment from "moment";
import {EcommerceProductRequestQuoteButton} from "./EcommerceProductRequestQuoteButton";
import {EcommerceProductStockNotifyButton} from "./EcommerceProductStockNotifyButton";
import i18n from "../../../i18n";

export class EcommerceProductAddToCartView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quantita: 1,
            attributi: {}, // => {"attrname"=>[valori disponibili]}
            varianteTemp: {},
        }
        this.props.productData.modelli.map(m => {
            Object.keys(m.variante).map(attrName => {
                //Creo la lista dei possibili valori dell'attributo
                if (!this.state.attributi[attrName]) this.state.attributi[attrName] = [];
                //Se già esiste il valore allora esco
                if (this.state.attributi[attrName].find(value => value === m.variante[attrName])) return;
                //Aggiungo il valore alla lista
                this.state.attributi[attrName].push(m.variante[attrName]);
            })
        });
        this.quantityInputStyle = {
            width:80
        }
    }

    handleAppOpen = _ => {
        /**
         * L'obiettivo è far arrivare l'ID prodotto da aprire all'app, salvo i dati prodotto nello Store globale
         * senza l'invio dell'evento e simulo l'apertura di un dynamic link
         * */
        NegozioOpenStore.shouldOpenProduct = this.props.productData;
        this.props.onAppOpen(this.props.productData.id_negozio);
    }

    handleMinusButton = e => {
        const step = this.getQuantityStep();
        this.setState({
            quantita: +(Math.max(step, this.state.quantita - step).toFixed(step.countDecimals()))
        })
    }

    handlePlusButton = e => {
        const step = this.getQuantityStep();
        this.setState({
            quantita: +((Math.min(this.state.quantita + step, this.props.productData.quantita)).toFixed(step.countDecimals()))
        })
    }

    handleQuantityChange = e => {
        this.setState({
            quantita: e.target.value
        })
    }

    handleQuantityBlur = e => {
        const step = this.getQuantityStep();
        this.setState({
            quantita: +((Math.min(this.state.quantita, this.props.productData.quantita)).toFixed(step.countDecimals()))
        })
    }

    getQuantityStep = _ => {
        switch (this.props.productData.um) {
            case "kg":
                return 0.1;
            default:
                return 1;
        }
    }

    handleOptionChange = (optionData, actionData) => {
        let varianteTemp = {...this.state.varianteTemp}
        if (actionData.action === 'select-option') {
            varianteTemp[actionData.name] = optionData.value;
        }
        if (actionData.action === 'clear') {
            delete varianteTemp[actionData.name];
        }
        this.setState({varianteTemp: varianteTemp}, _ => {
            this.props.onModelloChange(this.getCurrentModello());
        });
    }

    shouldOptionDisable = (attrName, value) => {
        //Devo verificare se esiste un modello che ha tutti gli attributi di this.state.varianteTemp U {[attrName]:value}
        const modelli = this.props.productData.modelli.slice();
        const expectedVariante = {...this.state.varianteTemp, [attrName]: value};
        let found = false;
        for (var i in modelli) {
            const m = modelli[i];
            let _localFound = true;
            const realVariante = m.variante;
            Object.keys(expectedVariante).map(attr => {
                if (realVariante[attr] !== expectedVariante[attr]) {
                    _localFound = false;
                }
            });
            if (_localFound) {
                found = true;
                break;
            }
        }
        return !found;
    }

    getCurrentModello = _ => {
        const modelli = this.props.productData.modelli.slice();

        const expectedVariante = {...this.state.varianteTemp};
        let modello = undefined;

        if (modelli.length && Object.keys(expectedVariante).length === Object.keys(modelli[0].variante).length) {
            for (var i in modelli) {
                const m = modelli[i];
                let attributesMatch = true;
                const realVariante = m.variante;
                Object.keys(expectedVariante).map(attr => {
                    if (realVariante[attr] !== expectedVariante[attr]) {
                        attributesMatch = false;
                    }
                });
                if (attributesMatch) {
                    modello = m;
                    break;
                }
            }
        }
        return modello;
    }

    handleAddToCart = _ => {
        let attributeList = Object.keys(this.state.attributi);
        for (var i in attributeList) {
            if (!this.state.varianteTemp[attributeList[i]]) {
                swalError(i18n.t('ecommerce.errors.select-all-options-to-add-to-cart'));
                return;
            }
        }

        //Recupero il modello scelto
        if (this.props.productData.modelli.length) {
            const modello = this.getCurrentModello();
            if (!modello) {
                swalError(i18n.t('ecommerce.errors.variant-not-found'));
                return;
            }
            if (!this.getCurrentStock()) {
                swalError(i18n.t('ecommerce.errors.not-in-stock'));
                return;
            }
            const productCartData = {
                ...this.props.productData,
                quantita: this.state.quantita,
                variante: modello.variante,
                prezzo: modello.prezzo
            };
            this.props.onAddToCart(productCartData);
        } else {
            const productCartData = {...this.props.productData, quantita: this.state.quantita, variante: {}};
            this.props.onAddToCart(productCartData);
        }
        swalToast(i18n.t('ecommerce.success.product-added-to-cart'), 'center', 'success', 2000);
    }

    getCurrentStock = _ => {

        if (moment().format('YYYY-MM-DD hh:mm:ss') < this.props.productData.data_disponibilita) {
            return 0;
        }
        if (moment().format('YYYY-MM-DD hh:mm:ss') > this.props.productData.available_until_date) {
            return 0;
        }
        if (this.props.productData.preventivo === "1") {
            return undefined;
        } else if (this.props.productData.modelli.length === 0) {
            return parseFloat(this.props.productData.quantita);
        } else {
            const modello = this.getCurrentModello();
            if (modello) {
                return parseFloat(modello.quantita);
            } else {
                return undefined;
            }
        }

        return 0;
    }

    getStockLabel = _ => {
        const stock = this.getCurrentStock();
        if (stock !== undefined) {
            if (stock === 0) {
                return <span className={"text-danger"}>{i18n.t('ecommerce.availability.unavailable')}</span>
            } else {
                if (parseInt(this.props.productData.ignora_magazzino) === 1 || stock >= 15) {
                    return <span className={"text-success"}>{i18n.t('ecommerce.availability.immediate')}</span>
                } else {
                    return <span
                        className={"text-success"}>{i18n.t('ecommerce.availability.in-stock-template', {stock: stock})}</span>
                }
            }
        }
        return '';
    }

    render() {
        return (
            <Fragment>
                <div className={"my-3 bg-main-z2 shadow-sm rounded p-3"}>
                    {
                        Object.keys(this.state.attributi).length ?
                            <label>{i18n.t('ecommerce.options-label')}</label> : ''
                    }
                    {
                        Object.keys(this.state.attributi).map((attrName, i) => {
                            return (
                                <div key={i} className={"form-group"}>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={this.state.attributi[attrName][0]}
                                        isClearable={true}
                                        isSearchable={false}
                                        name={attrName}
                                        placeholder={attrName}
                                        options={this.state.attributi[attrName].map(v => {
                                            return {
                                                value: v,
                                                label: v,
                                                isDisabled: this.shouldOptionDisable(attrName, v)
                                            }
                                        })}
                                        onChange={this.handleOptionChange}
                                    />
                                </div>
                            );
                        })
                    }

                    {
                        !!this.props.onOpenApp &&
                        <button className={"btn btn-block btn-secondary"} onClick={this.handleAppOpen}>
                            <FaSolidIcon name={"tablet"}/> {i18n.t('ecommerce.open-app-btn')}
                        </button>
                    }
                    {
                        !!parseInt(this.props.productData.preventivo) &&
                        <EcommerceProductRequestQuoteButton productData={this.props.productData}/>
                    }
                    {
                        (!parseInt(this.props.productData.preventivo) && !!this.props.onAddToCart) &&
                        <Fragment>
                            <div className={"d-flex align-items-center"}>
                                <button className={"btn btn-link text-primary"} onClick={this.handleMinusButton}>
                                    <FaSolidIcon name={"minus"}/>
                                </button>
                                <input type={"number"} value={this.state.quantita} onChange={this.handleQuantityChange}
                                       onBlur={this.handleQuantityBlur}
                                       className={"form-control"} style={this.quantityInputStyle}/>
                                {
                                    this.props.productData.um === 'pz' ? '' :
                                        <span className={"ml-1"}>{this.props.productData.um}</span>
                                }

                                <button className={"btn btn-link text-primary"} onClick={this.handlePlusButton}>
                                    <FaSolidIcon name={"plus"}/>
                                </button>
                                <div className={"flex-grow-1 ml-3"}>
                                    {
                                        this.getCurrentStock() !== undefined && this.getCurrentStock() === 0 ?
                                            <EcommerceProductStockNotifyButton
                                                productData={this.props.productData}
                                                modello={this.getCurrentModello()}
                                            />
                                            :
                                            <button className={"btn btn-block btn-secondary"}
                                                    onClick={this.handleAddToCart}>
                                                <FaSolidIcon name={"cart-plus"}/> {i18n.t('ecommerce.add-to-cart-btn')}
                                            </button>
                                    }
                                </div>
                            </div>
                        </Fragment>
                    }

                    <div className={"small text-center mt-2"}>
                        {this.getStockLabel()}
                    </div>
                </div>


            </Fragment>
        )
    }

}

EcommerceProductAddToCartView.propTypes = {
    productData: PropTypes.object.isRequired,
    onAppOpen: PropTypes.func,
    onAddToCart: PropTypes.func,
    onModelloChange: PropTypes.func,
}