import {_Books} from "./_Books";
import _Dashboard from "./_Dashboard";
import {_BookingEngine} from "./_BookingEngine";
import {_Notes} from "./_Notes";

export const ModuloServiziAPI = {
    Books: _Books,
    Dashboard: _Dashboard,
    BookingEngine: _BookingEngine,
    Notes: _Notes,
};