import React, {Fragment} from "react";
import styled from "styled-components";
import {DipendenteWelfareGiftCardsProductItem} from "./Products/DipendenteWelfareGiftCardsProductItem";
import {WelfareAPI} from "../../../../api/Welfare/WelfareAPI";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import FuxCursorPaginator from "../../../../components/BaseComponents/FuxCursorPaginator/FuxCursorPaginator";
import {DipendenteWelfareGiftCardsProductPage} from "./Products/DipendenteWelfareGiftCardsProductPage";
import PropTypes from "prop-types";
import Page from "../../../../components/BaseComponents/Page/Page";

const SearchBarContainer = styled.div`
    color: black !important;
`;

export default class DipendenteWelfareGiftCardsCatalogPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            searching: false,
            childCLicked: {},
            productClicked: {}
        }

        this.typingTimer = null;
        this.doneTypingInterval = 300;
    }

    /**
     * @PAGINATOR-PRODUCTS
     */
    handleProductRender = product => <DipendenteWelfareGiftCardsProductItem product={product} onProductClick={this.handleClickOnProduct}/>

    handleProductsPageRequest = cursor => WelfareAPI.GiftCards.getProducts(cursor, null, null, this.state.searchQuery)

    //Pagina vuota del fux
    handleProductEmptyPage = _ => <div className={"text-center"}><span className={'text-muted'}>Non siamo riusciti a identificare la Gift Card <br/> al momento</span></div>

    /**
     * @PRODUCTS
     */
    handleClickOnProduct = product => this.setState({productClicked: product})

    onProductDismiss = _ => this.setState({productClicked: {}})

    /**
     * @SERACH-ENGIGN
     */

    handleSearchQueryChange = e => {

        this.setState({
            searchQuery: e.target.value,
            searching: true
        });

        clearTimeout(this.typingTimer);

        if (e.target.value) {
            this.typingTimer = setTimeout(_ => {
                this.setState({
                    searching: false
                });
            }, this.doneTypingInterval);
        } else {
            this.setState({searching: false})
        }
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Gift cards"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div>
                            <SearchBarContainer className={"container bg-primary pb-3 shadow-sm sticky-top"}>
                                <form onSubmit={this.handleSearchFormSubmit}>
                                    <div className={"input-group"}>
                                        <div className={"position-relative flex-fill"}>
                                            <input
                                                type={"text"}
                                                name={"searchQuery"}
                                                value={this.state.searchQuery}
                                                className={"form-control"}
                                                placeholder={"Cerca gift cards"}
                                                onChange={this.handleSearchQueryChange}
                                            />
                                        </div>
                                        <div className={"input-group-append"}>
                                            <button className={"btn btn-secondary"}><FaSolidIcon name={"search"}/>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {/*Info su quanto può spendere*/}
                                <small className={"text-white mt-2"}>Puoi spendere ancora €{this.props.maxPayable}</small>
                            </SearchBarContainer>


                            <div className={"container mt-2"}>
                                {
                                    !this.state.searching &&
                                    <FuxCursorPaginator
                                        onItemRender={this.handleProductRender}
                                        onPageRequest={this.handleProductsPageRequest}
                                        onEmptyPage={this.handleProductEmptyPage}
                                        classItemsContainer={"row align-items-stretch"}
                                    />
                                }

                            </div>
                        </div>
                    }
                />

                {/*PAGINA DEL PRODOTTO*/}
                {
                    this.state.productClicked.id_prodotto &&
                    <DipendenteWelfareGiftCardsProductPage
                        product={this.state.productClicked}
                        onDismiss={this.onProductDismiss}
                        active={true}
                    />
                }
            </Fragment>
        )
    }
}

DipendenteWelfareGiftCardsCatalogPage.propTypes = {
    onDismiss: PropTypes.func,
    active: PropTypes.bool,
    maxPayable: PropTypes.number
};