import React, {Suspense} from "react";
import LauncherActionHeader from "../LauncherActionHeader";
import LauncherNextBooksList from "../NextBooks/LauncherNextBooksList";
import LauncherAppListContainer from "../AppList/LauncherAppListContainer";
import SearchAppPage from "../SearchAppPage/SearchAppPage";
import styled from "styled-components";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import ModalPortal from "../../components/BaseComponents/ModalPortal";
import {DLA_APP_SEARCH} from "../../const/DeepLinkActionsCostants";
import AuthAPI from "../../api/AuthAPI";
import {LauncherBirthdayAlert} from "../LauncherBirthdayAlert";
import {SearchAppController} from "../SearchAppPage/SearchAppController";
import {AdminDashboardView} from "../dashboard/DashboardView/AdminDashboardView";
import {LauncherLoginView} from "../LauncherLoginView";
import CardForBirthdayGreetings from "./Promotions/Birthday/CardForBirthdayGreetings";
import {UserDisputeButtonView} from "../UserDisputes/UserDisputeButtonView";
import {DevModeView} from "../DevModeView";
import {AppIconThumbnail} from "../../components/BaseComponents/AppIconThumbnail";
import i18n from "../../i18n";
import DipendenteWelfareModal from "../WelfareDipendente/DipendenteWelfareModal";
import areaWelfareLogo from "../../img/welfare/area-welfare-logo.png"
import {DigitalCityBanner} from "../DigitalCity/DigitalCityBanner";

const HomepageWrapper = styled.div`
    position: relative;
    z-index: 1030;
    padding-bottom: 4rem;
`;

export default class HomepageTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchPageOpen: false,
            areaWelfareOpen: false
        };
        this.areaWelfareLogoStyle = {
            width: 150
        }
    }

    toggleSearchPage = _ => {
        if (this.state.searchPageOpen) {
            document.body.classList.remove('noscroll');
        } else {
            document.body.classList.add('noscroll');
        }
        this.setState({searchPageOpen: !this.state.searchPageOpen});
    }

    handleSearchDismiss = _ => {
        this.toggleSearchPage();
        this.setState({appSearchQuery: '', searchResults: [], searching: false});
    }

    handleAdd = codice => {
        window.addAppId(codice);
        this.handleSearchDismiss();
    }

    componentDidMount() {
        FuxEvents.on(DLA_APP_SEARCH, this.handleDeepLinkAppSearch);
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_APP_SEARCH, this.handleDeepLinkAppSearch);
    }

    handleDeepLinkAppSearch = data => {
        console.log("CHIAMATO");
        this.toggleSearchPage();
    }

    toggleAreaWelfare = _ => this.setState({areaWelfareOpen: !this.state.areaWelfareOpen});

    render() {

        return (
            <HomepageWrapper className={this.props.active ? '' : 'd-none'}>
                <LauncherActionHeader
                    onAppAdd={this.props.onAppAddRequest}
                    onQRScan={this.props.onQRScanRequest}
                    onSearch={this.toggleSearchPage}
                    nOfRecensioniInSospeso={this.props.nOfRecensioniInSospeso}
                    onMenuToggle={this.props.onMenuToggle}
                />

                <DigitalCityBanner/>

                {
                    this.props.devMode && <DevModeView/>
                }

                {
                    !!this.props.user &&
                    <React.Fragment>

                        {!!this.props.user.isAdmin && <AdminDashboardView user={this.props.user}/>}

                        {
                            (this.props.user && this.props.user.walletapikey) &&
                            <div className={"bg-white shadow-sm rounded py-2 mx-3 mt-3"} onClick={this.toggleAreaWelfare}>
                                <div className={"d-flex align-items-center justify-content-center"} >
                                    <div className={"mr-2"}>
                                        <img src={areaWelfareLogo} style={this.areaWelfareLogoStyle}/>
                                    </div>
                                </div>
                            </div>
                        }

                        <CardForBirthdayGreetings
                            user={this.props.user}
                        />

                        <LauncherNextBooksList
                            books={this.props.books}
                            hotelBooks={this.props.hotelBooks}
                            seatBooks={this.props.seatBooks}
                            ordini={this.props.ordini}
                            hasApps={this.props.hasApps}
                            onAppOpen={this.props.onAppOpenRequest}
                        />

                        <UserDisputeButtonView/>

                        <LauncherBirthdayAlert {...AuthAPI.currentUser}/>

                        <LauncherAppListContainer
                            apps={this.props.apps}
                            editMode={this.props.editMode}
                            onAppReorderRequest={this.props.onAppReorderRequest}
                            onAppOpenRequest={this.props.onAppOpenRequest}
                            onAppDeleteRequest={this.props.onAppDeleteRequest}
                            onAppAddRequest={this.props.onAppAddRequest}
                            onAppReorder={this.props.onAppReorder}
                        />
                    </React.Fragment>
                }

                {
                    !this.props.user &&
                    <div className={"d-flex align-items-center justify-content-center"}>
                        <LauncherLoginView/>
                    </div>
                }

                <ModalPortal>
                    {
                        this.state.searchPageOpen &&
                        <SearchAppController
                            onDismiss={this.handleSearchDismiss}
                        />
                    }

                    {
                        this.state.areaWelfareOpen &&
                        <DipendenteWelfareModal
                            user={this.props.user}
                            active={true}
                            onDismiss={this.toggleAreaWelfare}/>
                    }
                </ModalPortal>
            </HomepageWrapper>
        )

    }

}
