import React from "react";
import Page from "../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import {PostAdminNotifySection} from "./PostAdminNotifySection";
import {CommentsNotifySection} from "./CommentsNotifySection";
import {SettingsAppItem} from "./SettingsAppItem";

export default class ImpostazioniPage extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.apps !== this.props.apps ||
            nextProps.active !== this.props.active;
    }

    render() {
        return (
            <Page
                title={"Impostazioni"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
                content={
                    <div>
                        {
                            this.props.user.negozio &&
                            <div className={"mb-4 mt-2"}>
                                <small className={"text-muted text-uppercase pl-2"}>
                                    Notifiche post della tua attività
                                </small>
                                <PostAdminNotifySection user={this.props.user}/>
                            </div>
                        }

                        <div className={"mb-4 mt-2"}>
                            <small className={"text-muted text-uppercase pl-2"}>Notifiche commenti</small>
                            <CommentsNotifySection user={this.props.user}/>
                        </div>

                        <small className={"text-muted text-uppercase pl-2"}>Impostazioni app</small>
                        {
                            !!this.props.apps &&
                            <div className={"p-3"}>
                                {
                                    this.props.apps.map((n, i) => <SettingsAppItem key={i} negozio={n}/>)
                                }
                            </div>
                        }
                    </div>
                }
            />
        );
    }
}
