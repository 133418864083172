import React from "react";
import PropTypes from "prop-types";
import {swalError} from "../../../../../../helpers/SweetAlertWrapper";
import {ServicesListViewItem} from "./ServicesListViewItem";
import {AddServiceButton} from "./AddServiceButton/AddServiceButton";

export class ServicesListView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idServizio: null,
            notPrintableSubBooks: [],
            collapsedSubBooks: [],
            showNote: true,
            showPause: false,
            useGrouping: true
        }
        if (this.props.collapse) {
            this.state.collapsedSubBooks = this.props.books.map(b => b.id_prenotazione);
        }
    }

    /** @MARK Collapsing */

    handleCollapseToggle = (id_prenotazione, collapsed) => {
        let collapsedSubBooks = [...this.state.collapsedSubBooks];
        if (collapsed) {
            collapsedSubBooks.push(id_prenotazione);
        } else {
            collapsedSubBooks = collapsedSubBooks.filter(id => id !== id_prenotazione);
        }
        this.setState({collapsedSubBooks: collapsedSubBooks});
    }

    /** @MARK Note */
    handleToggleChange = ({target}) => this.setState({[target.name]: target.checked});

    /** @MARK Delete */
    handleDeleteWrapper = (id_prenotazione, rearrange, rearrange_bundle_price) => {
        this.props.onDelete(id_prenotazione, rearrange, rearrange_bundle_price, !this.state.useGrouping);
    }

    render() {
        //Genero la lista delle prenotazioni da mostrare in base alla impostazione dello state "useGrouping".
        //In caso di useGrouping mostro il "tree" delle prenotazioni classico, altrimenti i "subservices" dei bundle
        //vengono considerati come singolo servizio
        const subBookList = this.state.useGrouping ? this.props.books : this.props.books.reduce((acc, b) => {
            if (b.subservices) {
                return acc.concat(b.subservices);
            }
            acc.push(b);
            return acc;
        }, []).sort((a, b) => a.orario < b.orario ? -1 : (a.orario > b.orario) ? 1 : 0);

        const hasBundles = !!this.props.books.find(b => b.subservices && b.subservices.length);

        return (
            <React.Fragment>
                <div className={"mb-3"}>
                    {
                        (this.props.noteServizi && this.props.noteServizi.length > 0) &&
                        <div className="custom-control custom-switch cursor-pointer mr-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.showNote}
                                onChange={this.handleToggleChange}
                                name={"showNote"}
                                id={"switch-show-note-servizi"}
                            />
                            <label className="custom-control-label"
                                   htmlFor={"switch-show-note-servizi"}>
                                Mostra note
                            </label>
                        </div>
                    }

                    {
                        hasBundles &&
                        <div className="custom-control custom-switch cursor-pointer mr-2">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.useGrouping}
                                onChange={this.handleToggleChange}
                                name={"useGrouping"}
                                id={"switch-use-grouping"}
                            />
                            <label className="custom-control-label"
                                   htmlFor={"switch-use-grouping"}>
                                Raggruppa servizi
                            </label>
                        </div>
                    }

                    <div className="custom-control custom-switch cursor-pointer">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={this.state.showPause}
                            onChange={this.handleToggleChange}
                            name={"showPause"}
                            id={"switch-show-pause-servizi"}
                        />
                        <label className="custom-control-label"
                               htmlFor={"switch-show-pause-servizi"}>
                            Mostra pause non prenotabili
                        </label>
                    </div>

                </div>

                <AddServiceButton
                    optGroupServizi={this.props.optGroupServizi}
                    startTime={this.props.books[0].orario || this.props.books[0].subservices[0].orario}
                    direction={'before'}
                    idPrenotazione={this.props.books[0].id_prenotazione}
                    nomeServizioPrenotazione={this.props.books[0].nome_servizio}
                    onAppendSubBook={this.props.onAppendSubBook}
                    dropdownDirection={'dropdown'}
                />

                {
                    subBookList.map(b => {
                        return (
                            <ServicesListViewItem
                                key={b.id_prenotazione}
                                subBook={b}
                                onCollapseToggle={this.handleCollapseToggle}
                                showCollapseToggle={true}
                                collapsed={!!this.state.collapsedSubBooks.find(id => id === b.id_prenotazione)}
                                noteServizi={this.state.showNote ? this.props.noteServizi : null}
                                showPause={this.state.showPause}
                                showPrice={this.state.useGrouping}
                                optGroupServizi={this.props.optGroupServizi}
                                onAppendSubBook={this.props.onAppendSubBook}
                                onEditServiceDuration={this.props.onEditServiceDuration}
                                onEditTime={this.props.onEditTime}
                                onDelete={this.handleDeleteWrapper}
                            />
                        )
                    })
                }
            </React.Fragment>
        )
            ;
    }

}

ServicesListView.propTypes = {
    books: PropTypes.array,
    idParent: PropTypes.any,
    optGroupServizi: PropTypes.array, //Struttura per optgroup dei servizi divisi in categoria
    onAppendSubBook: PropTypes.func,
    onEditServiceDuration: PropTypes.func,
    onDelete: PropTypes.func,
    onEditTime: PropTypes.func,
    showEditOptions: PropTypes.bool,
    collapse: PropTypes.bool,
    noteServizi: PropTypes.array,
}