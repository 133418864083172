import React from "react";
import PropTypes from "prop-types";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import {FuxRating} from "../../../../components/FuxRating";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {getCompressedImageUrl} from "../../../../helpers/ImageUtility";
import {ProductImageSize} from "../../../../const/ImageSizeCostants";

export class HotelView extends React.Component {
    constructor(props) {
        super(props);
        this.imageStyle = {
            height: '20vh',
            width: '100%',
            backgroundImage: `url('${getCompressedImageUrl(this.props.hotelData.immagini?.[0], ProductImageSize.preview.w, ProductImageSize.preview.h)}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
        }
        this.ratingStarStyle = {
            fontSize: '80%'
        }
        this.textBackdropStyle = {
            position: 'absolute',
            zIndex: 0,
            width: '100%',
            height: 50,
            background: "linear-gradient(180deg, rgba(30,30,30,0.65) 50%, transparent 100%)"
        }
        this.cardStyle = {
            border: 0,
            borderRadius: '.75rem',
            overflow: 'hidden'
        }
    }

    getFullAddress = _ => {
        return `${this.props.hotelData.indirizzo}, ${this.props.hotelData.citta} (${this.props.hotelData.provincia}), ${this.props.hotelData.nazione} `
    }

    render() {
        return (
            <div className={"card mb-3 shadow-sm"} style={this.cardStyle} onClick={this.handleClick}>
                <div style={this.imageStyle} className={"card-img"}/>
                <div style={this.textBackdropStyle}/>
                <div className={"card-img-overlay p-2"}>
                    <h6 className={"card-title text-white mb-0"}>
                        {this.props.hotelData.nome}
                    </h6>
                    <FuxRating
                        className={'text-warning'}
                        style={this.ratingStarStyle}
                        showMissingStars={false}
                        rating={parseInt(this.props.hotelData.stelle)}
                    />
                </div>
                <div className={"card-footer bg-main-z2"}>
                    <FaSolidIcon name={"map-marker-alt"}/> <SafeHtmlContainer html={this.getFullAddress()}/>
                </div>
            </div>
        )
    }

}

HotelView.propTypes = {
    hotelData: PropTypes.object
}