import React, {Fragment} from "react";
import ModalPortal from "../../../../components/BaseComponents/ModalPortal";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {DLA_FIDELITY_CARD} from "../../../../const/DeepLinkActionsCostants";
import DashboardFidelityCardTransactionPage from "./DashboardFidelityCardTransactionPage";


export default class DashboardFidelityCardListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardToken: null
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_FIDELITY_CARD, this.handleFidelityCardScan);
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_FIDELITY_CARD, this.handleFidelityCardScan);
    }

    handleFidelityCardScan = cardToken => {
        this.setState({cardToken: cardToken});
    }

    handleTransactionDismiss = _ => this.setState({cardToken: null});

    render() {
        return (
            <ModalPortal>
                {
                    this.state.cardToken ?
                        <DashboardFidelityCardTransactionPage
                            active={!!this.state.cardToken}
                            cardToken={this.state.cardToken}
                            onDismiss={this.handleTransactionDismiss}
                        />
                        : ''
                }
            </ModalPortal>
        )
    }


}
