import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";


const _Guest = {
    save: function (formData) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-hotel/guests/save`), formData,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    delete: function (id_ospite) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-hotel/guests/delete`), {
                id_ospite: id_ospite,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getRecent: function (limit) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-hotel/guests/get-recent`), {
                limit: limit,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default _Guest;