import React, {Fragment} from "react";
import styled from "styled-components";

const CarouselContainer = styled.div`
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const CircleIndicator = styled.div`
    display:inline-block;
    width:9px; height:9px; border-radius:50%;
    margin:0 3px;
    opacity:0.5;
    &.active{
        opacity:0.99;
    }
`;

const ImageWrapper = styled.div`
    display:flex;
    scroll-snap-align: center;
    width:100%;
    flex: 0 0 100%;
`;

export class FuxCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollLeft: 0,
            currActive: 0
        }
        this.autoscrollInterval = null;
        this.scrollPane = null;
    }

    componentDidMount() {
        if (this.props.autoscroll) {
            this.autoscrollInterval = setInterval(this.goNextTab, this.props.autoscroll);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.autoscrollInterval){
            clearInterval(this.autoscrollInterval);
        }
        if (this.props.autoscroll) {
            this.autoscrollInterval = setInterval(this.goNextTab, this.props.autoscroll);
        }
    }

    handleScroll = e => {
        const dimensioneCard = window.innerWidth - (window.innerWidth - e.target.clientWidth);
        this.setState({
            scrollLeft: e.target.scrollLeft,
            currActive: Math.round(e.target.scrollLeft / dimensioneCard)
        });
    }

    goNextTab = _ => {
        if (!this.scrollPane) return;
        const dimensioneCard = window.innerWidth - (window.innerWidth - this.scrollPane.clientWidth);
        const scrollSize = this.scrollPane.scrollWidth;
        const actualScrollPos = this.scrollPane.scrollLeft;
        const nextScrollPos = scrollSize - actualScrollPos - dimensioneCard > 0 ? actualScrollPos + dimensioneCard : 0;
        this.scrollPane.scroll({
            top: 0,
            left: nextScrollPos,
            behavior: 'smooth'
        });
    }

    getScrollPaneRef = e => this.scrollPane = e;

    render() {
        return (
            <Fragment>
                <CarouselContainer ref={this.getScrollPaneRef} className={"items-container d-flex flex-row flex-nowrap mb-3"}
                                   onScroll={this.handleScroll}>
                    {
                        this.props.items.map((v, i) => <ImageWrapper className={"d-flex align-items-center"}
                                                                     key={i}>{v}</ImageWrapper>)
                    }
                </CarouselContainer>


                {/* Circle indicators */}
                {
                    (this.props.items.length > 1 && !this.props.hideIndicators) &&
                    <div
                        className={"indicators-container justify-content-center d-flex " + (this.props.overlappingIndicators ? ' mt-n5 mb-5' : '')}>
                        {
                            this.props.items.map((v, i) => <CircleIndicator
                                className={i === this.state.currActive ? 'active bg-primary' : 'bg-primary'} key={i}/>)
                        }
                    </div>
                }
            </Fragment>
        )
    }

}
