import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {MenuDigitaleEmailOptinPanel} from "./MenuDigitaleEmailOptinPanel";
import moment from "moment";

const OPTIN_TIMEOUT_S = 20 * 1000;

export class MenuDigitaleEmailOptinListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            panelOpen: false
        }
        this.timeout = null;
    }

    componentDidMount() {
        const lastDate = localStorage.getItem(`menu_digitale_optin_date_${this.props.infoNegozio.id_negozio}`) || '1970-01-01';
        if (lastDate !== moment().format('YYYY-MM-DD')) {
            this.timeout = setTimeout(this.showOptin, OPTIN_TIMEOUT_S);
        }
    }

    componentWillUnmount() {
       if (this.timeout) clearTimeout(this.timeout);
    }

    showOptin = _ => this.setState({panelOpen: true});
    hideOptin = _ => {
        this.setState({panelOpen: false});
        localStorage.setItem(`menu_digitale_optin_date_${this.props.infoNegozio.id_negozio}`, moment().format('YYYY-MM-DD'));
    }

    render() {
        return (
            <Fragment>
                <MenuDigitaleEmailOptinPanel open={this.state.panelOpen} onDismiss={this.hideOptin} infoNegozio={this.props.infoNegozio}/>
            </Fragment>
        )
    }

}

MenuDigitaleEmailOptinListener.propTypes = {
    infoNegozio: PropTypes.object.isRequired
}