import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import FuxCursorPaginator from "../../../../../components/BaseComponents/FuxCursorPaginator/FuxCursorPaginator";
import Page from "../../../../../components/BaseComponents/Page/Page";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {WelfareAPI} from "../../../../../api/Welfare/WelfareAPI";
import {DipendenteWelfareGiftCardsProductItem} from "../Products/DipendenteWelfareGiftCardsProductItem";
import {DipendenteWelfareGiftCardsProductPage} from "../Products/DipendenteWelfareGiftCardsProductPage";
import styled from "styled-components";
import i18n from "../../../../../i18n";
import {ResetInput} from "../../../../../components/BaseComponents/Inputs/ResetInput";
import DipendenteWelfareGiftCardsBrandsItem from "./DipendenteWelfareGiftCardsBrandsItem";

const SearchBarContainer = styled.div`
    color: black !important;
`;

export default class DipendenteWelfareGiftCardsBrandsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            searching: false,
            brandSelected: {},
            childCLicked: {},
            productClicked: {}
        }

        this.typingTimer = null;
        this.doneTypingInterval = 300;
    }

    /**
     * @PAGINATOR-BRANDS
     */
    handleBrandRender = brand => <DipendenteWelfareGiftCardsBrandsItem brand={brand} onBrandClick={this.onBrandCLick}/>

    handleBrandPageRequest = cursor => WelfareAPI.GiftCards.getBrands(cursor)

    handleBrandEmptyPage = _ => ""

    onBrandCLick = brand => this.setState({brandSelected: brand})

    onBrandDismiss = _ => this.setState({brandSelected: {}})

    /**
     * @PAGINATOR-PRODUCTS
     */
    handleProductRender = product => <DipendenteWelfareGiftCardsProductItem product={product} onProductClick={this.handleClickOnProduct}/>

    handleProductsPageRequest = cursor => WelfareAPI.GiftCards.getProducts(cursor, null, this.props.brand.id_brand || null, this.state.searchQuery)

    //Pagina vuota del fux
    handleProductEmptyPage = _ => <div className={"text-center"}><span className={'text-muted'}>Non ci sono prodotti in questo brand <br/> al momento</span></div>

    /**
     * @PRODUCTS
     */
    handleClickOnProduct = product => this.setState({productClicked: product})

    onProductDismiss = _ => this.setState({productClicked: {}})

    /**
     * @SERACH-ENGIGN
     */

    handleSearchQueryChange = e => {

        this.setState({
            searchQuery: e.target.value,
            searching: true
        });

        clearTimeout(this.typingTimer);

        if (e.target.value) {
            this.typingTimer = setTimeout(_ => {
                this.setState({
                    searching: false
                });
            }, this.doneTypingInterval);
        } else {
            this.setState({searching: false})
        }
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={this.props.brand.name || "Gift cards"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div>
                            <SearchBarContainer className={"container bg-primary pb-3 shadow-sm sticky-top"}>
                                <form onSubmit={this.handleSearchFormSubmit}>
                                    <div className={"input-group"}>
                                        <div className={"position-relative flex-fill"}>
                                            <input
                                                type={"text"}
                                                name={"searchQuery"}
                                                value={this.state.searchQuery}
                                                className={"form-control"}
                                                placeholder={"Cerca gift cards" + (this.props.brand.name ? " in "+ this.props.brand.name : "")}
                                                onChange={this.handleSearchQueryChange}
                                            />
                                        </div>
                                        <div className={"input-group-append"}>
                                            <button className={"btn btn-secondary"}><FaSolidIcon name={"search"}/>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {/*Info su quanto può spendere*/}
                                <small className={"text-white mt-2"}>Puoi spendere ancora €{this.props.maxPayable}</small>
                            </SearchBarContainer>


                            <div className={"container mt-2"}>
                                {
                                    !this.state.searchQuery && !this.props.brand.id_brand &&
                                    <div className={"list-group mb-4"}>
                                        <FuxCursorPaginator
                                            onItemRender={this.handleBrandRender}
                                            onPageRequest={this.handleBrandPageRequest}
                                            onEmptyPage={this.handleBrandEmptyPage}
                                        />
                                    </div>
                                }

                                {
                                    !this.state.searching &&
                                    <FuxCursorPaginator
                                        onItemRender={this.handleProductRender}
                                        onPageRequest={this.handleProductsPageRequest}
                                        onEmptyPage={this.handleProductEmptyPage}
                                    />
                                }

                            </div>
                        </div>
                    }
                />

                {/*PAGINA BRAND RICORSIONE*/}
                {
                    this.state.brandSelected.id_brand &&
                    <DipendenteWelfareGiftCardsBrandsPage
                        brand={this.state.brandSelected}
                        onDismiss={this.onBrandDismiss}
                        active={true}
                        maxPayable={this.props.maxPayable}
                    />
                }

                {/*PAGINA DEL PRODOTTO*/}
                {
                    this.state.productClicked.id_prodotto &&
                    <DipendenteWelfareGiftCardsProductPage
                        product={this.state.productClicked}
                        onDismiss={this.onProductDismiss}
                        active={true}
                    />
                }
            </Fragment>
        )
    }
}

DipendenteWelfareGiftCardsBrandsPage.propTypes = {
    brand: PropTypes.object,
    onDismiss: PropTypes.func,
    active: PropTypes.bool,
    maxPayable: PropTypes.number
};