import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {RECURRING_MAX_BOOKS_NUM} from "../RecurringDatesChooserPanel";
import {PluralString} from "../../../../../../helpers/UIUtility";

export class WeeklyOptionsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            weekday: 1,
            amount: 2,
            step: 1
        }
    }

    handleInputChange = ({target}) => this.setState({[target.name]: target.value});

    handleChoose = _ => this.props.onChoose(this.state);

    render() {
        return (
            <React.Fragment>
                <div>
                    <h4 className={"font-weight-bold text-center"}>
                        Prenotazione settimanale
                    </h4>
                    <p className={"text-muted"}>
                        Scegli ogni quante settimane e in che giorno della settimana in cui vuoi effettuare le prossime
                        prenotazioni
                    </p>
                    <div className={"d-flex align-items-center flex-wrap mb-3 justify-content-center"}>
                        Ogni
                        <select className={"form-control w-auto ml-2"} name={'step'} value={this.state.step}
                                onChange={this.handleInputChange}>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                        </select>
                        <span className={"mx-2"}><PluralString n={this.state.step} more={"settimane"}
                                                               single={"settimana"}/>, di</span>
                        <select className={"form-control w-auto"} name={'weekday'} value={this.state.weekday}
                                onChange={this.handleInputChange}>
                            <option value={1}>Lunedì</option>
                            <option value={2}>Martedì</option>
                            <option value={3}>Mercoledì</option>
                            <option value={4}>Giovedì</option>
                            <option value={5}>Venerdì</option>
                            <option value={6}>Sabato</option>
                            <option value={0}>Domenica</option>
                        </select>
                    </div>
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <button className={"btn btn-link text-on-bg-main"} onClick={this.props.onDismiss}>
                            <FaSolidIcon name={"chevron-left"}/> Indietro
                        </button>
                        <button className={"btn btn-primary"} onClick={this.handleChoose}>
                            Conferma <FaSolidIcon name={"chevron-right"}/>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

WeeklyOptionsView.propTypes = {
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func
}
