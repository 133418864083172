import React, {Fragment} from "react";
import IndirizziAPI, {INDIRIZZI_TYPE_INVOICE, INDIRIZZI_TYPE_NORMAL} from "../api/IndirizziAPI";
import {swal, swalError, swalLoading, swalSuccess} from "../helpers/SweetAlertWrapper";
import RadioListCard from "./BaseComponents/RadioListCard";
import {FaSolidIcon} from "./FontAwesomeIcons";
import Page from "./BaseComponents/Page/Page";
import FuxEvents from "../lib/FuxFramework/FuxEvents";
import {ADDRESS_BOOK_CHANGE} from "../const/AppEventsCostants";
import {PageFooter, PageWithFooterContainer} from "./BaseComponents/Page/PageFooter";
import {LocationIcon} from "../img/illustrations/LocationIcon";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";
import i18next from "i18next";
import i18n from "i18next";


export default class AddressBookPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indirizzi: null,
            idIndirizzoScelto: null,
            creaIndirizzoOpen: false,
            flag: this.props.flagSelected || false
        }
    }

    componentDidMount() {
        swalLoading();
        this.fetchIndirizzi();
        FuxEvents.on(ADDRESS_BOOK_CHANGE, this.fetchIndirizzi);
    }

    componentWillUnmount() {
        FuxEvents.off(ADDRESS_BOOK_CHANGE, this.fetchIndirizzi);
    }

    fetchIndirizzi = _ => {
        IndirizziAPI.list(this.props.invoiceData ? INDIRIZZI_TYPE_INVOICE : INDIRIZZI_TYPE_NORMAL)
            .then(indirizzi => {
                this.setState({indirizzi: indirizzi}, _ => {
                    indirizzi.map(i => {
                        if (parseInt(i.predefinito)) {
                            this.handleChange(i.id_indirizzo);
                        }
                    });
                });
                swal.safeClose();
            })
            .catch(swalError);
    }

    handleChange = id => {
        this.setState({idIndirizzoScelto: id});
    }

    toggleCreaIndirizzo = _ => {
        this.setState({creaIndirizzoOpen: !this.state.creaIndirizzoOpen});
    }

    handleChoose = _ => {
        if (!this.state.idIndirizzoScelto) {
            swalError(i18next.t('address-book.errors.selection-mandatory'))
            return;
        }
        this.props.onChoose({
            id_indirizzo: this.state.idIndirizzoScelto,
            data: this.state.indirizzi.find(i => i.id_indirizzo === this.state.idIndirizzoScelto),
            flag: this.state.flag
        })
    }

    handleFlagChange = e => {
        this.setState({
            [e.target.name]: e.target.checked
        });
    }

    handleSetPredefined = id => {
        swalLoading(i18next.t('generic.loading-please-wait'));
        IndirizziAPI.setPredefined(id)
            .then(swalSuccess)
            .then(this.fetchIndirizzi)
            .catch(swalError);
    }

    handleElimina = id => {
        swalLoading(i18next.t('generic.loading-please-wait'));
        IndirizziAPI.delete(id)
            .then(swalSuccess)
            .then(this.fetchIndirizzi)
            .catch(swalError);
    }

    render() {
        const TopIcon = this.props.topIcon || LocationIcon;
        return (
            <Fragment>
                <Page
                    title={this.props.title || i18next.t('address-book.title')}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    footer={
                        <PageFooter className={"bg-main-z2 p-3 border-top"}>
                            {
                                !!this.props.useFlag &&
                                <label className={"form-group"}>
                                    <input type={"checkbox"} name={"flag"} checked={this.state.flag}
                                           onChange={this.handleFlagChange} value={1}/> {this.props.useFlag}
                                </label>
                            }
                            <button className={"btn btn-block btn-primary btn-lg"} onClick={this.handleChoose}>
                                {this.props.continueButtonText || i18next.t('generic.continue-btn')}
                            </button>
                        </PageFooter>
                    }
                >
                    <PageWithFooterContainer>
                        <div className={"mb-4"}>
                            <div className={"mx-auto my-3"} style={{width: "38%"}}>
                                <TopIcon className={"img-fluid"}
                                         color={NegozioOpenStore.infoNegozio ? NegozioOpenStore.infoNegozio.aspetto.primary_color : "#293069"}/>
                            </div>
                        </div>
                        {
                            this.state.indirizzi ?
                                <Fragment>
                                    <div className={"container pt-2"}>
                                        <div className={"mb-2 px-1"}>
                                            <small className={"text-uppercase text-on-bg-main"}>
                                                {this.props.subtitle || i18next.t('address-book.subtitle')}<br/>
                                                <button className={"btn btn-sm btn-link m-0 p-0"}
                                                        onClick={this.props.onCreateNew}
                                                >
                                                    {this.props.createNewText || i18next.t('address-book.add-new-btn')}
                                                </button>
                                            </small>
                                        </div>

                                        <RadioListCard
                                            onChange={this.handleChange}
                                            items={
                                                this.state.indirizzi.map(ind => {
                                                    return {
                                                        text: `${ind.ragione_sociale || ""}${ind.nome} ${ind.cognome}`,
                                                        description:
                                                            <div>
                                                                {
                                                                    this.props.invoiceData ?
                                                                        <span>
                                                                            {ind.piva}<br/>
                                                                            {ind.pec || ""} {ind.codice_sdi || ""}<br/>
                                                                            {ind.indirizzo},{ind.civico}<br/>
                                                                            {ind.citta},{ind.cap}
                                                                        </span>
                                                                        :
                                                                        <span>
                                                                            {ind.cellulare}<br/>
                                                                            {ind.indirizzo},{ind.civico}<br/>
                                                                            {ind.citofono ?
                                                                                <span>Citofono: {ind.citofono}<br/></span> : ''}
                                                                            {ind.interno_scala ?
                                                                                <span>Interno/Scala: {ind.interno_scala}<br/></span> : ''}
                                                                            {ind.citta},{ind.cap}
                                                                        </span>
                                                                }
                                                                <div
                                                                    className={"d-flex align-items-center justify-content-between mt-2"}>
                                                                    {
                                                                        parseInt(ind.predefinito) !== 1 ?
                                                                            <button
                                                                                className={"btn btn-link btn-sm p-0 text-primary"}
                                                                                onClick={_ => this.handleSetPredefined(ind.id_indirizzo)}>
                                                                                {i18next.t('address-book.set-default-btn')}
                                                                            </button>
                                                                            :
                                                                            <div
                                                                                className={"d-inline-block text-info text-primary"}>
                                                                                <FaSolidIcon
                                                                                    name={"certificate"}/> {i18next.t('address-book.default-label')}
                                                                            </div>
                                                                    }

                                                                    <button
                                                                        className={"btn btn-link btn-sm p-0 text-danger"}
                                                                        onClick={_ => this.handleElimina(ind.id_indirizzo)}>
                                                                        {i18next.t('generic.delete-btn')}
                                                                    </button>
                                                                </div>
                                                            </div>,
                                                        value: ind.id_indirizzo
                                                    }
                                                })
                                            }
                                        />
                                    </div>
                                </Fragment>
                                :
                                <FaSolidIcon name={"spin fa-spinner"}/>
                        }
                    </PageWithFooterContainer>
                </Page>
            </Fragment>
        );
    }

}
