import React, {Fragment} from "react";
import PropTypes from "prop-types";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {DLA_PRENOTAZIONE_SERVIZIO} from "../../../const/DeepLinkActionsCostants";
import {ServiceBookResumePage} from "../../NextBooks/ServiceBook/ServiceBookResume/ServiceBookResumePage";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";

export class PrenotazioneServizioNotificationClickListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idPrenotazione: null
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_PRENOTAZIONE_SERVIZIO, this.handleOpenResume)
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_PRENOTAZIONE_SERVIZIO, this.handleOpenResume);
    }

    handleOpenResume = idPrenotazione => this.setState({idPrenotazione: idPrenotazione});

    handleDismissResume = _ => this.setState({idPrenotazione: null});


    render() {
        if (!this.state.idPrenotazione) return '';
        return (
            <ModalPortalHOC>
                <ServiceBookResumePage
                    idPrenotazione={this.state.idPrenotazione}
                    onDismiss={this.handleDismissResume}
                    showOpenApp={true}
                />
            </ModalPortalHOC>
        )
    }

}

PrenotazioneServizioNotificationClickListener.propTypes = {}