import React from "react";
import {shadeColor} from "../../../helpers/UIUtility";

export const EcommerceIllustration = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} id="a03e49aa-1ede-49ab-95c4-f5a45b19bb50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             width="847.6929" height="656" viewBox="0 0 847.6929 656">
            <path
                d="M213.74735,759.28773c26.42251,19.03478,60.66413,19.99056,93.76235,17.65213,4.62173-.32608,9.21077-.70873,13.75728-1.12156.02747-.00713.06353-.00459.09144-.01168.21849-.02079.43743-.04161.6465-.054.9384-.0878,1.87724-.17551,2.80684-.26388l-.2196.419-.68924,1.30024c.2477-.43517.4952-.86129.7429-1.29646.07286-.13062.15452-.26067.22694-.39138,8.57426-14.92076,17.07842-30.38964,19.24764-47.4275,2.24221-17.68452-4.05928-37.70924-19.62367-46.40124a31.40726,31.40726,0,0,0-6.4449-2.69922c-.93084-.28286-1.87429-.51229-2.82844-.71534a33.66445,33.66445,0,0,0-39.14929,43.76182c-13.19581-13.53109-10.73425-35.482-6.18233-53.82747,4.56072-18.34493,10.08187-38.7585.80579-55.236-5.15643-9.17155-14.2243-14.86189-24.40066-17.15421-.31218-.06731-.62393-.13453-.93675-.19277a49.13024,49.13024,0,0,0-35.29738,6.1847c-19.45808,12.26176-29.452,35.51036-32.6956,58.28426C172.14112,696.74457,183.70645,737.64426,213.74735,759.28773Z"
                transform="translate(-176.15355 -122)" fill="#f2f2f2"/>
            <path
                d="M222.2782,700.54062a83.51812,83.51812,0,0,0,5.76411,23.53542,72.51989,72.51989,0,0,0,11.25978,18.39165c9.49656,11.42369,22.05367,20.01238,35.691,25.73513a133.72808,133.72808,0,0,0,32.51657,8.737c4.62173-.32608,9.21077-.70873,13.75728-1.12156.02747-.00713.06353-.00459.09144-.01168.21849-.02079.43743-.04161.6465-.054.9384-.0878,1.87724-.17551,2.80684-.26388l-.2196.419-.68924,1.30024c.2477-.43517.4952-.86129.7429-1.29646.07286-.13062.15452-.26067.22694-.39138a73.92213,73.92213,0,0,1-20.71723-64.6648,74.55555,74.55555,0,0,1,13.8963-31.86316c-.93084-.28286-1.87429-.51229-2.82844-.71534a77.29873,77.29873,0,0,0-7.05111,11.67872,75.74167,75.74167,0,0,0-6.53472,47.11951,77.4376,77.4376,0,0,0,19.61964,38.62482c-.88274-.06221-1.77409-.13409-2.64633-.21369-16.52166-1.39976-33.00966-4.9516-47.995-12.22685a86.44039,86.44039,0,0,1-32.382-26.57c-9.07566-12.51652-13.07481-27.5721-13.7618-42.87405-.72845-16.38207,1.39716-33.05187,5.13581-48.9833a205.59774,205.59774,0,0,1,17.276-47.05958,1.4782,1.4782,0,0,0-.58694-1.95143,1.25725,1.25725,0,0,0-.93675-.19277,1.09454,1.09454,0,0,0-.72256.619c-.94457,1.8435-1.87153,3.68824-2.76327,5.55359a207.93121,207.93121,0,0,0-16.27749,48.82276C222.31254,666.91289,220.55954,683.953,222.2782,700.54062Z"
                transform="translate(-176.15355 -122)" fill="#fff"/>
            <path
                d="M1006.84645,686h-437a17.01917,17.01917,0,0,1-17-17V139a17.01917,17.01917,0,0,1,17-17h437a17.01917,17.01917,0,0,1,17,17V669A17.01917,17.01917,0,0,1,1006.84645,686Zm-437-562a15.017,15.017,0,0,0-15,15V669a15.017,15.017,0,0,0,15,15h437a15.017,15.017,0,0,0,15-15V139a15.017,15.017,0,0,0-15-15Z"
                transform="translate(-176.15355 -122)" fill="#3f3d56"/>
            <rect x="377.6929" y="41.59033" width="469" height="2" fill="#3f3d56"/>
            <circle cx="400.6929" cy="22" r="8" fill="#3f3d56"/>
            <circle cx="425.6929" cy="22" r="8" fill="#3f3d56"/>
            <circle cx="450.6929" cy="22" r="8" fill="#3f3d56"/>
            <path
                d="M939.10629,243h-312a13,13,0,0,1,0-26h312a13,13,0,0,1,0,26Zm-312-24a11,11,0,0,0,0,22h312a11,11,0,0,0,0-22Z"
                transform="translate(-176.15355 -122)" fill="#3f3d56"/>
            <path
                d="M743.84645,426h-123a17.01917,17.01917,0,0,1-17-17V308a17.01917,17.01917,0,0,1,17-17h123a17.01917,17.01917,0,0,1,17,17V409A17.01917,17.01917,0,0,1,743.84645,426Zm-123-133a15.017,15.017,0,0,0-15,15V409a15.017,15.017,0,0,0,15,15h123a15.017,15.017,0,0,0,15-15V308a15.017,15.017,0,0,0-15-15Z"
                transform="translate(-176.15355 -122)" fill="#3f3d56"/>
            <rect x="640.6929" y="174" width="155" height="133" rx="16" fill="#f2f2f2"/>
            <path
                d="M743.84645,614h-123a17.01917,17.01917,0,0,1-17-17V496a17.01917,17.01917,0,0,1,17-17h123a17.01917,17.01917,0,0,1,17,17V597A17.01917,17.01917,0,0,1,743.84645,614Zm-123-133a15.017,15.017,0,0,0-15,15V597a15.017,15.017,0,0,0,15,15h123a15.017,15.017,0,0,0,15-15V496a15.017,15.017,0,0,0-15-15Z"
                transform="translate(-176.15355 -122)" fill="#3f3d56"/>
            <rect x="640.6929" y="362" width="155" height="133" rx="16" fill="#f2f2f2"/>
            <path
                d="M651.7383,401.57275q-.03333,0-.06665-.00146l-13.99024-.51807a1.80775,1.80775,0,0,1-1.74267-1.61279c-.96289-8.59131-4.92554-52.37109,11.675-70.56738a23.74656,23.74656,0,0,1,18.78784-7.89209.78611.78611,0,0,0,.72119-.376l3.38672-5.32227a1.83573,1.83573,0,0,1,1.75268-.83105c2.71534.32813,16.5564,1.877,22.37647.19189a1.8215,1.8215,0,0,1,1.92334.5918l4.44751,5.43555a.77923.77923,0,0,0,.64917.30078c2.262-.04541,10.1936.39258,17.135,8.15674,7.5166,8.40722,15.27392,27.376,9.54345,69.99609a1.83307,1.83307,0,0,1-1.80908,1.58447H715.50954a1.832,1.832,0,0,1-1.80347-1.56054l-3.59936-24.83594a.82209.82209,0,0,0-1.6355.10059l-.24805,11.66064a1.8256,1.8256,0,0,1-1.08276,1.62695c-3.92823,1.72705-24.68286,9.82715-47.62232-.31787a1.83814,1.83814,0,0,1-1.08276-1.59375l-.45093-10.59472a.82218.82218,0,0,0-1.63843-.05713l-2.79638,24.81738A1.8311,1.8311,0,0,1,651.7383,401.57275Z"
                transform="translate(-176.15355 -122)" fill={color}/>
            <path
                d="M709.61225,584.53884h-.01037l-54.82139-.347a1.63729,1.63729,0,0,1-1.61346-1.44377l-5.51455-46.87338a1.26178,1.26178,0,0,0-1.21766-1.12194c-4.35225-.14193-14.70674-1.134-16.85244-7.2632-1.911-5.45858,3.3159-13.50984,15.53534-23.931a1.63687,1.63687,0,0,1,2.63562.78558l6.34341,21.885a1.2799,1.2799,0,0,0,1.213.91792l51.97882.34879a1.26545,1.26545,0,0,0,1.22165-.8906l7.05829-22.45809a1.63391,1.63391,0,0,1,2.55117-.81006c4.81608,3.67844,20.33164,16.30726,17.778,24.38407-1.3375,4.23018-7.37009,6.588-17.9303,7.00843a1.2655,1.2655,0,0,0-1.21384,1.122l-5.5173,47.24168A1.63314,1.63314,0,0,1,709.61225,584.53884Z"
                transform="translate(-176.15355 -122)" fill={color}/>
            <path
                d="M949.84645,387.15336a115.56467,115.56467,0,0,0-47.50512,21.03889,8.81124,8.81124,0,0,0-3.78781-4.49853,13.30152,13.30152,0,0,0-3.84435-1.61527c-1.23571-.315-2.42291.43613-3.51319,1.51836a19.89151,19.89151,0,0,0-3.12555,4.62776c-18.826-12.92217-47.384-21.16812-47.384-21.16812V384.472a14.27018,14.27018,0,0,1,.18574-1.81718c.09691-.69456.21808-1.30837.37153-2.05946,2.42291-12.32453,12.00145-40.48677,12.00145-40.48677h10.83037l20.99852,4.36123,23.62333-1.44566,23.793-4.9508,3.08517-.64611c5.14463,4.03818,10.98382,32.30543,13.27753,44.33919.29073,1.52644.51689,2.78635.68647,3.71513C949.74146,386.53149,949.84645,387.15336,949.84645,387.15336Z"
                transform="translate(-176.15355 -122)" fill="#fff"/>
            <path
                d="M885.22266,511.21886h0s-5.03056,1.25761-12.83229,22.38888-10.56563,48.43369-10.56563,48.43369-4.38711,7.42882,14.33852,7.92606c0,0-1.50624,4.90627,9.0594,4.15313s9.04475-.74582,9.04475-.74582,6.7927-4.27742,9.81249-3.52432,4.90627,1.009,8.17465,3.77289,13.717,1.63785,13.84132-.4972a19.73885,19.73885,0,0,0,0-3.1514s15.97638,1.009,12.95658-10.56563-5.53508-30.57085-5.53508-30.57085-8.17464-37.10764-17.48993-37.61216S885.22266,511.21886,885.22266,511.21886Z"
                transform="translate(-176.15355 -122)" fill="#fff"/>
            <circle cx="192.64032" cy="323.59598" r="30" fill="#2f2e41"/>
            <circle cx="195.16001" cy="329.09205" r="24.56103" fill="#ffb8b8"/>
            <path
                d="M464.85216,525.79729a10.52585,10.52585,0,0,0-1.07217,1.264L414.21769,525.31,408.549,514.67893l-17.16236,6.45812,8.20639,19.79551a8,8,0,0,0,8.26328,4.88857l56.0845-6.15807a10.4971,10.4971,0,1,0,.9114-13.86577Z"
                transform="translate(-176.15355 -122)" fill="#ffb8b8"/>
            <path
                d="M384.78481,530.6957a4.81169,4.81169,0,0,1-2.64261-3.01727l-6.90326-23.16917a13.37737,13.37737,0,0,1,24.20833-11.39155l13.37223,20.23476a4.81688,4.81688,0,0,1-1.9806,7.01985L388.79617,530.666A4.81146,4.81146,0,0,1,384.78481,530.6957Z"
                transform="translate(-176.15355 -122)" fill="#ccc"/>
            <polygon points="257.446 643.927 268.214 638.065 250.729 593.743 234.837 602.394 257.446 643.927"
                     fill="#ffb8b8"/>
            <path
                d="M428.93967,763.90628l21.20539-11.5436.00085-.00046a15.386,15.386,0,0,1,20.87006,6.15794l.23905.43916-34.71954,18.90015Z"
                transform="translate(-176.15355 -122)" fill="#2f2e41"/>
            <polygon points="138.959 643.975 151.218 643.975 157.051 596.687 138.956 596.688 138.959 643.975"
                     fill="#ffb8b8"/>
            <path
                d="M311.98494,761.97275l24.1438-.001h.001A15.38605,15.38605,0,0,1,351.51619,777.358v.5l-39.53052.00147Z"
                transform="translate(-176.15355 -122)" fill="#2f2e41"/>
            <path
                d="M391.29387,556.096l4,48,44.61857,133.8557a4,4,0,0,1-2.86825,5.15613l-13.6751,3.256a4,4,0,0,1-4.49827-2.09058l-57.044-113.15283a2,2,0,0,0-3.73938.47151L332.98366,745.95361a4,4,0,0,1-3.907,3.14237H316.35484a3.99267,3.99267,0,0,1-3.99663-4.05407c1.16578-79.29671-2.68-169.65348,27.93566-183.94593l5-13Z"
                transform="translate(-176.15355 -122)" fill="#2f2e41"/>
            <path
                d="M343.85393,551.50028l-5.15552-31.17774a30.8774,30.8774,0,0,1,9.76856-28.00585,30.36136,30.36136,0,0,1,28.3313-6.92481c.29223.07813.58471.15918.87768.24121a30.78328,30.78328,0,0,1,22.22754,27.3457c.98389,13.54.10352,33.1836-9.67822,49.87012l-.18994.32422Z"
                transform="translate(-176.15355 -122)" fill="#ccc"/>
            <path
                d="M395.74674,438.03822a33.40483,33.40483,0,0,1-19.09069,5.89985,20.4708,20.4708,0,0,0,8.11361,3.338,67.35857,67.35857,0,0,1-27.51395.15459,17.80711,17.80711,0,0,1-5.75978-1.97823,7.28926,7.28926,0,0,1-3.55521-4.75471c-.60365-3.44851,2.08348-6.58157,4.876-8.69307a35.96732,35.96732,0,0,1,30.22446-6.03969c3.37626.87273,6.75852,2.34727,8.9515,5.05866s2.84257,6.8915.75322,9.68353Z"
                transform="translate(-176.15355 -122)" fill="#2f2e41"/>
            <path
                d="M368.39884,415.44782c2.8472-11.4957-7.35716-24.26792-19.19779-24.02878-12.01344.24262-20.85393,11.0986-26.93092,21.46449S310.15359,435,298.99724,439.46288a24.02853,24.02853,0,0,1-32.946-22.89418c-1.49792,12.59326.57734,26.21905,8.69879,35.95948s23.23332,14.13042,34.09532,7.58437c6.20358-3.73862,10.16874-10.23726,13.43777-16.70063s6.17789-13.279,11.13119-18.56353,12.61233-8.79206,19.50567-6.56865c2.73629.88259,5.19108,2.60293,8.01814,3.12633s6.38631-.82017,6.77246-3.66923Z"
                transform="translate(-176.15355 -122)" fill="#2f2e41"/>
            <path
                d="M309.20227,623.89057a10.7427,10.7427,0,0,1,2.74259-16.24271l21.30539-95.497,21.61251,8.87057-27.73845,91.08112a10.80091,10.80091,0,0,1-17.922,11.788Z"
                transform="translate(-176.15355 -122)" fill="#ffb8b8"/>
            <path
                d="M324.80881,525.85045a4.81169,4.81169,0,0,1-.3365-3.99676l8.19276-22.74519a13.37737,13.37737,0,0,1,26.24507,5.19684l-1.2446,24.22215a4.81688,4.81688,0,0,1-5.75991,4.47488L328.05512,528.207A4.81149,4.81149,0,0,1,324.80881,525.85045Z"
                transform="translate(-176.15355 -122)" fill="#ccc"/>
            <path id="b06866c4-d0b9-420f-a9d2-389dd10be0a8" data-name="Path 22"
                  d="M499.36843,515.14047l-.35491-.04879,1.33654-9.72271a5.6804,5.6804,0,0,0-4.85388-6.401l-20.59957-2.83172a5.68038,5.68038,0,0,0-6.401,4.85388l-7.33245,53.34046a5.68038,5.68038,0,0,0,4.85388,6.401l20.59956,2.83172a5.68039,5.68039,0,0,0,6.401-4.85389l5.04454-36.69694.35491.04879Z"
                  transform="translate(-176.15355 -122)" fill="#3f3d56"/>
            <path id="fb337c3c-e64d-4daa-8f83-1de4a3033e68" data-name="Path 23"
                  d="M494.84864,501.82037l-2.56459-.35254a1.92232,1.92232,0,0,1-2.12411,2.38124l-11.25647-1.54737a1.92233,1.92233,0,0,1-1.40262-2.866l-2.39549-.32929a4.04622,4.04622,0,0,0-4.55957,3.4575L463.562,553.36813a4.04623,4.04623,0,0,0,3.45751,4.55956l19.74328,2.714a4.04624,4.04624,0,0,0,4.55957-3.45751h0l6.98382-50.80434a4.04623,4.04623,0,0,0-3.45751-4.55949Z"
                  transform="translate(-176.15355 -122)" fill="#fff"/>
            <circle cx="304.6929" cy="411" r="11" fill={color}/>
            <path
                d="M479.7561,537.19624a1.1408,1.1408,0,0,1-.68643-.228l-.01228-.00922-2.58508-1.97922a1.14849,1.14849,0,1,1,1.397-1.82331l1.67441,1.28392,3.95691-5.16039a1.14844,1.14844,0,0,1,1.61023-.21255l-.0246.0334.02524-.03292a1.14978,1.14978,0,0,1,.21256,1.61024l-4.65427,6.06985A1.149,1.149,0,0,1,479.7561,537.19624Z"
                transform="translate(-176.15355 -122)" fill="#fff"/>
            <path d="M582.22834,778h-381a1,1,0,0,1,0-2h381a1,1,0,1,1,0,2Z" transform="translate(-176.15355 -122)"
                  fill="#3f3d56"/>
        </svg>
    )
}
