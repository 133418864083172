import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../FontAwesomeIcons";

export class InputInlineSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: this.props.defaultQuery,
            searchResults: [],
            searching: false
        }

        this.typingTimer = null;
        this.doneTypingInterval = 300;
    }

    handleQueryChange = ({target}) => {
        this.setState({query: target.value}, _ => {

            clearTimeout(this.typingTimer);
            if (target.value && target.value.length >= 2) {
                this.typingTimer = setTimeout(_ => {
                    this.setState({searchResults: []}, this.handleSearch, this.props.itemChoose({nome: target.value}));
                }, this.doneTypingInterval);
            } else {
                if (!target.value) {
                    this.handleReset();
                } else {
                    this.setState({searching: false});
                }
            }
        })
    }

    handleReset = _ => this.setState({searching: false, searchResults: []})

    handleSearch = _ => {
        this.setState({searching: true})
        this.props.searchFunc(this.state.query)
            .then(results => {
                this.setState({searchResults: results, searching: false})
            })
    }

    handleItemClicked = item => {
        this.setState({query: item.nome, searchResults: []}, _ => {
            this.props.itemChoose(item)
        })
    }

    render() {
        return (
            <div className="dropdown">
                <input
                    type={"text"}
                    placeholder={this.props.searchBarPlaceholder ? this.props.searchBarPlaceholder : ""}
                    className={"form-control " + (this.props.inputClass ? this.props.inputClass : "")}
                    name={"query"}
                    value={this.state.query}
                    onChange={this.handleQueryChange}
                />
                <div className={"w-100 shadow dropdown-menu " + (this.state.searchResults.length > 0 || this.state.searching ? " show " : "")}
                     aria-labelledby="dropdownMenuButton">
                    {
                        this.state.searching ?
                            <a className="dropdown-item text-center">
                                <FaSolidIcon name={"spinner fa-pulse text-muted"}/>
                            </a>
                            : this.state.searchResults.map((item, k) => {
                                return <a className="dropdown-item" key={k} onClick={_ => this.handleItemClicked(item)}>
                                    {
                                        this.props.overwriteSuggestedItem ?
                                            this.props.overwriteSuggestedItem(item)
                                            :
                                            item.nome.length > 35 ? item.nome.slice(0, 35) + "..." : item.nome

                                    }
                                </a>
                            })
                    }
                </div>
            </div>
        )
    }
}

InputInlineSearch.propTypes = {
    searchBarPlaceholder: PropTypes.string || null,
    searchBarClass: PropTypes.string || null,
    searchFunc: PropTypes.func,
    itemChoose: PropTypes.func,
    itemRenderFunction: PropTypes.func,
    defaultQuery: PropTypes.string,
    overwriteSuggestedItem: PropTypes.func
}