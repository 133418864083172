import React, {Fragment} from "react";
import PropTypes from "prop-types";
import SafeHtmlContainer from "../../../../../../helpers/SafeHtmlContainer";
import {ServicesListView} from "../ServiziView/ServicesListView";
import {swal, swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../../../helpers/SweetAlertWrapper";
import FuxHTTP from "../../../../../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../../../../../config/AppConfig";
import {ModuloServiziAPI} from "../../../../../../api/ModuloServizi/ModuloServiziAPI";
import {replacePhoneNumberLinks} from "../../../../../../helpers/StringHelpers";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {NoteInterneView} from "./NoteInterneView";
import NotesHistoryView from "../../NotesHistory/NotesHistoryView";

export class PanoramicaTabView extends React.Component {

    /**
     * Permette di aggiungere un servizio alla fine della prenotazione
     *
     * @param {Number} id_servizio Il servizio da voler aggiungere
     * @param {String} direction "after" o "before"
     * @param {Number} id_prenotazione_relative Prenotazione di riferimento per l'aggiunta del servizo prima o dopo
     * @param {Function} onSuccess Funzione che viene richiamata se l'operazione va a buon fine
     * @param {Boolean} rearrange Specifica se è necessario spostare le prenotazioni successive a quella che si vuole inserire
     * @param {Boolean} force Obbliga il sistema a salvare la prenotazione ignorando eventuali spazi già pieni nel calendario
     */
    handleAppendSubBook = (id_servizio, direction, id_prenotazione_relative, onSuccess, rearrange, force) => {
        swalLoading('Attendere prego...');
        ModuloServiziAPI.Dashboard.addExtraService(id_prenotazione_relative, id_servizio, direction, rearrange ? 1 : 0, force ? 1 : 0)
            .then(message => {
                this.props.onRefetchData();
                swalSuccess(message)
                    .then(_ => {
                        this.fetchData();
                        if (onSuccess) onSuccess();
                    }).catch(m => console.log(m));
            })
            .catch(m => {
                if (force) {
                    swalError(m)
                } else {
                    swalConfirm(m, 'Continua', 'Annulla')
                        .then(_ => {
                            this.handleAppendSubBook(id_servizio, direction, id_prenotazione_relative, onSuccess, rearrange, true);
                        });
                }
            });
    }

    /**
     * Permette di modificare la durata di un singolo sotto-blocco della prenotazione
     *
     * @param {Number} id_prenotazione
     * @param {Number} durata Espressa in ticks (minuti = durata * 5)
     * @param {Function} onSuccess
     * @param {Boolean} force
     */
    handleEditServiceDuration = (id_prenotazione, durata, onSuccess, force) => {
        swalLoading('Attendere prego...');
        ModuloServiziAPI.Dashboard.editServiceDuration(id_prenotazione, durata, force ? 1 : 0)
            .then(message => {
                this.props.onRefetchData();
                swalSuccess(message)
                    .then(_ => {
                        this.props.onRefetchData();
                        if (onSuccess) onSuccess();
                    });
            })
            .catch(({status, message}) => {
                if (status === 'ERROR') {
                    swalError(message);
                    return;
                }
                if (status === 'CONFIRM') {
                    swalConfirm(message, 'Continua', 'Annulla')
                        .then(_ => {
                            this.handleEditServiceDuration(id_prenotazione, durata, onSuccess, true);
                        });
                }
            });
    }

    /**
     * Permette di modificare l'orario di un singolo sotto-blocco della prenotazione
     * @param {Object} originalBook I dati completi del record originale della prenotazione (o sotto-prenotazione)
     * @param {Boolean} fullbook Se modificare l'intera prenotazione o se modificare il singolo sotto-servizio
     * @param {String} newOrario Il nuovo orario
     * @param {Number} forceBook Se forzare la prenotazione
     * @param {Function} onSuccess Callback function on edit success
     */
    handleEditServiceTime = async ({originalBook, fullbook, newOrario, forceBook, onSuccess}) => {

        const handleSuccess = ({message, data}) => {
            swalSuccess(message);
            if (fullbook){
                this.props.onReplaceParentId(data); //Con cambio completo della prenotazione cambia anche l'ID
            }else {
                this.props.onRefetchData(); //Refetch dei nuovi dati per aggiornare l'UI
            }
            this.props.onEditTime();
            if (onSuccess) onSuccess();
        }

        swalLoading('Attendere prego...');
        if (fullbook) {
            //Recupero tutti i servizi della prenotazione e ricostruisco un nuovo book info per lo spostamento COMPLETO
            // di tutto l'appuntamento
            ModuloServiziAPI.Dashboard.getFullBookInfo(this.props.book.id_parent)
                .then(fullBookInfo => {

                    ModuloServiziAPI.Dashboard.editFullBookTime({
                        id_prenotazione: this.props.book.id_parent,
                        id_servizio: fullBookInfo.books
                            .map(b => b.id_servizio_bundle ? b.id_servizio_bundle : b.id_servizio)
                            .filter((v, idx, self) => self.indexOf(v) === idx), //Se il servizio fa parte di un bundle userò solo il riferimento del bundle, ma ciò comporta avere id del bundle duplicati. Con filter elimino i duplicati.
                        data: this.props.book.data,
                        orario: newOrario,
                        forceBook: forceBook,
                        forceDipendente: forceBook,
                    }).then(handleSuccess).catch(swalError);

                }).catch(swalError);
        } else {
            let canBook = true;
            if (!forceBook) {
                await ModuloServiziAPI.Dashboard.checkAvailability(
                    this.props.book.data,
                    newOrario,
                    originalBook.durata,
                    originalBook.id_dipendente,
                    this.props.book.id_sede
                ).catch(_ => {
                    canBook = false;
                    swalError('Sembra che non sia possibile spostare la prenotazione');
                });
            }
            if (!canBook) return;

            ModuloServiziAPI.Dashboard.editTime({
                id_prenotazione: originalBook.id_prenotazione,
                data: this.props.book.data,
                orario: newOrario,
                id_dipendente: originalBook.id_dipendente
            }).then(handleSuccess).catch(swalError);
        }
    }

    /**
     * Permette di eliminare un sotto-blocco della prenotazione. Nel caso in cui cambi l'id_parent viene richiamata
     * la funzione (ricevuta tramite props) per aggiornarlo.
     *
     * @param {Number} id_prenotazione
     * @param {Boolean} rearrange Specifica se bisogna anticipare tutti i blocchi prenotazione successivi
     * @param {Boolean} delete_single_service_only Specifica se si vuole eliminare uno specifico servizio o tutto il bundle
     * @param {Boolean} rearrange_bundle_price Specifica se bisogna ridurre il prezzo del bundle se si sta eliminando un
     * sotto servizio del bundle
     */
    handleSubBookDelete = (id_prenotazione, rearrange, rearrange_bundle_price, delete_single_service_only) => {
        swalLoading('Attendere prego...');
        ModuloServiziAPI.Dashboard.deleteSubService(id_prenotazione, rearrange ? 1 : 0, delete_single_service_only ? 1 : 0, rearrange_bundle_price ? 1 : 0)
            .then(({message, data}) => {
                this.props.onRefetchData();
                swalSuccess(message)
                    .then(_ => {
                        if(data.id_parent != this.props.book.id_parent) this.props.onReplaceParentId(data.id_parent);
                    })
            }).catch(swalError);
    }


    handleFullDelete = _ => {
        const doDelete = keepRecurring => {
            swalLoading('Attendere prego...');
            ModuloServiziAPI.Dashboard.delete(this.props.book.id_parent ? this.props.book.id_parent : this.props.book.id_prenotazione, keepRecurring)
                .then(swalSuccess)
                .then(this.props.onDelete)
                .catch(swalError);
        }

        swalConfirm('Sei sicuro di voler rimuovere questo appuntamento e tutti i servizi ad esso associati?')
            .then(_ => {

                if (parseInt(this.props.book.isRecurringMaster) === 1) {
                    swalConfirm(
                        'Questa prenotazione è la principale di una lista di appuntamenti ricorrenti, ' +
                        'vuoi eliminare anche gli appuntamenti futuri?', 'Si', 'No'
                    )
                        .then(_ => {
                            doDelete(0);
                        })
                        .catch(_ => {
                            doDelete(1);
                        })
                } else {
                    doDelete(1);
                }

            });
    }


    getIndirizzoMapLink = _ => {
        var mapLink = 'https://maps.google.com/maps?q=';
        if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
            mapLink = 'http://maps.apple.com/?q=';
        }
        return `${mapLink}${this.props.book.indirizzo}`;
    }

    handleReschedule = _ => this.props.onReschedule(this.props.book);


    render() {
        const book = this.props.book;
        const ordini = book.ordini && book.ordini.length ? book.ordini : null;

        return (
            <React.Fragment>

                <ServicesListView
                    books={book.books}
                    idParent={book.id_parent}
                    optGroupServizi={this.props.optGroupServizi}
                    onAppendSubBook={this.handleAppendSubBook}
                    onEditServiceDuration={this.handleEditServiceDuration}
                    onDelete={this.handleSubBookDelete}
                    onEditTime={this.handleEditServiceTime}
                    noteServizi={book.user.note_servizi}
                />

                <div className={"mt-5"}>
                    <button className={"btn btn-primary btn-block mb-2"} onClick={this.handleReschedule}>
                        <FaSolidIcon name={"calendar-day"}/> Sposta prenotazione
                    </button>
                    <button className={"btn btn-danger btn-block mb-2"} onClick={this.handleFullDelete}>
                        <FaSolidIcon name={"trash"}/> Elimina prenotazione
                    </button>
                </div>

                <div className={"mt-5"}/>

                {
                    !!ordini &&
                    <Fragment>
                        <div className={"card shadow-sm border-0 mb-3"}>
                            <div className={"card-body"}>
                                <h6 className={"font-weight-bold text-purple"}>Vendite collegate</h6>
                                <p className={"text-muted"}>
                                    Hai associato le seguenti vendite a questa prenotazione, vai nella sezione Pagamento
                                    per
                                    ulteriori dettagli
                                </p>
                                {
                                    ordini.map(o => {
                                        return <div>• Acquisto #{o.id_ordine}, Totale: €{o.totale}</div>
                                    })
                                }
                            </div>
                        </div>

                    </Fragment>
                }

                {
                    !!book.note_utente &&
                    <Fragment>
                        <div className={"card shadow-sm border-0 mb-3"}>
                            <div className={"card-body"}>
                                <h6 className={"font-weight-bold text-purple"}>Note del cliente</h6>
                                <div>
                                    <SafeHtmlContainer html={replacePhoneNumberLinks(book.note_utente)}/>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }

                <NoteInterneView
                    idParent={book.id_parent}
                    note={book.note}
                    onChange={this.props.onRefetchData}
                />

                <NotesHistoryView userId={this.props.book.user.id_utente}/>

                {
                    !!book.indirizzo &&
                    <Fragment>
                        <div className={"card shadow-sm border-0 mb-3"}>
                            <div className={"card-body"}>
                                <h6 className="font-weight-bold text-purple">Indirizzo</h6>
                                <div>
                                    <a href={this.getIndirizzoMapLink()} target={"_blank"}
                                       className={"btn btn-link text-warning mx-3"}>
                                        <FaSolidIcon name={"map-marker-alt"}/> <SafeHtmlContainer
                                        html={book.indirizzo}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }

            </React.Fragment>
        )
    }
}

PanoramicaTabView.propTypes = {
    book: PropTypes.object.isRequired,
    onRefetchData: PropTypes.func.isRequired,
    onReplaceParentId: PropTypes.func.isRequired,
    onReschedule: PropTypes.func.isRequired,
    optGroupServizi: PropTypes.array,
    onDelete: PropTypes.func.isRequired
}

