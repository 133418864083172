import React, {Fragment} from "react";
import OrdineCard from "../NextBooks/Ordini/OrdineCard";
import ServiceBookCard from "../NextBooks/ServiceBook/ServiceBookCard";
import GroupBookCard from "../NextBooks/GroupBooks/GroupBookCard";
import HotelNextBooksItem from "../NextBooks/HotelNextBooks/HotelNextBooksItem";
import PropTypes from "prop-types";
import RecensioniForm from "../Recensioni/RecensioniForm";
import RecensioniCardInformations from "../Recensioni/RecensioniCardInformations";
import {
    RECENSIONI_TIPO_ORDINE, RECENSIONI_TIPO_PRENOTAZIONE_CAMERA,
    RECENSIONI_TIPO_PRENOTAZIONE_GRUPPO, RECENSIONI_TIPO_PRENOTAZIONE_POSTI,
    RECENSIONI_TIPO_PRENOTAZIONE_SERVIZIO
} from "../../const/RecensioniConstants";
import SeatsNextBooksItem from "../NextBooks/SeatBook/SeatsNextBooksItem";

const ITEM_TYPE_PRENOTAZIONE_SERVIZI = 'prenotazione_servizi';
const ITEM_TYPE_ORDINE_FOOD = 'ordine_food';
const ITEM_TYPE_ORDINE_ECOMMERCE = 'ordine_ecommerce';
const ITEM_TYPE_PRENOTAZIONE_GRUPPI = 'prenotazione_gruppi';
const ITEM_TYPE_PRENOTAZIONE_HOTEL = 'prenotazione_hotel';
const ITEM_TYPE_PRENOTAZIONE_POSTI = 'prenotazione_posti';

export default class CronologiaUserListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    showFeedbackInformations = (item, recensioneTipo) => {
        return <RecensioniCardInformations
                    item={item}
                    recensioneTipo = {recensioneTipo}
                />
    }

    render() {
        return (
            <div className={"container-fluid"}>
                {
                    this.props.items.map(item => {

                        switch (item.type) {
                            case ITEM_TYPE_ORDINE_ECOMMERCE:
                            case ITEM_TYPE_ORDINE_FOOD:
                                return <OrdineCard
                                    key={item.id}
                                    className={"mt-4"}
                                    order={item.extended}
                                    showOpenApp={this.props.showOnAppOpen}
                                    onAppOpen={this.props.onAppOpen}
                                    feedbackInformations={this.showFeedbackInformations(item.extended, RECENSIONI_TIPO_ORDINE)}
                                    onCronologiaDismiss={this.props.onCronologiaDismiss}
                                />


                            case ITEM_TYPE_PRENOTAZIONE_SERVIZI:
                                return <ServiceBookCard
                                    key={item.id}
                                    className={"mt-4"}
                                    book={item.extended}
                                    showOpenApp={this.props.showOnAppOpen}
                                    onAppOpen={this.props.onAppOpen}
                                    feedbackInformations={this.showFeedbackInformations(item.extended, RECENSIONI_TIPO_PRENOTAZIONE_SERVIZIO)}
                                />

                            case ITEM_TYPE_PRENOTAZIONE_GRUPPI:
                                this.showFeedbackInformations(RECENSIONI_TIPO_PRENOTAZIONE_GRUPPO)
                                return <GroupBookCard
                                    key={item.id}
                                    className={"mt-4"}
                                    book={item.extended}
                                    showOpenApp={this.props.showOnAppOpen}
                                    onAppOpen={this.props.onAppOpen}
                                    feedbackInformations={this.showFeedbackInformations(item.extended, RECENSIONI_TIPO_PRENOTAZIONE_GRUPPO)}
                                />

                            case ITEM_TYPE_PRENOTAZIONE_HOTEL:
                                return <HotelNextBooksItem
                                    key={item.id}
                                    className={"mt-4"}
                                    book={item.extended}
                                    showOpenApp={this.props.showOnAppOpen}
                                    onAppOpen={this.props.onAppOpen}
                                    feedbackInformations={this.showFeedbackInformations(item.extended, RECENSIONI_TIPO_PRENOTAZIONE_CAMERA)}
                                />

                            case ITEM_TYPE_PRENOTAZIONE_POSTI:
                                return <SeatsNextBooksItem
                                    key={item.id}
                                    className={"mt-4"}
                                    book={item.extended}
                                    showOpenApp={this.props.showOnAppOpen}
                                    onAppOpen={this.props.onAppOpen}
                                    feedbackInformations={this.showFeedbackInformations(item.extended, RECENSIONI_TIPO_PRENOTAZIONE_POSTI)}
                                />
                        }
                    })
                }
            </div>
        )
    }
}

CronologiaUserListView.propTypes = {
    items: PropTypes.array,
    onAppOpen: PropTypes.func,
    onCronologiaDismiss: PropTypes.func,
    showOpenApp: PropTypes.bool,
};
