import React, {Fragment} from "react";
import AuthAPI from "../../api/AuthAPI";
import PropTypes from "prop-types";
import mobileLoginSvg from "../../launcher/svg/mobile-login.svg";
import LoginForm from "../Forms/LoginForm";
import RegisterModal from "../Modals/RegisterModal";
import PasswordRecoveryModal from "../Modals/PasswordRecoveryModal";
import {SwipeablePanelGripBar} from "../BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {SwipeablePanel} from "../BaseComponents/SwipeablePanel/SwipeablePanel";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";
import {PageZLayerSelector} from "../BaseComponents/Page/PageZLayerSelector";
import i18n from "../../i18n";
import {MobileLoginIllustration} from "../../img/illustrations/login/MobileLoginIllustration";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";

export class LoginPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRegisterModalOpen: false,
            isPassRecoveryModalOpen: false,
        }
    }

    componentDidMount() {
        AuthAPI.onStateChanged(this.handleAuthStateChanged);
    }

    componentWillUnmount() {
        AuthAPI.offStateChanged(this.handleAuthStateChanged);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open && this.props.openRegisterModal) {
            this.setState({isRegisterModalOpen: true});
        }
    }

    handleAuthStateChanged = user => {
        if (user && this.props.open) {
            this.props.onDismiss();
        }
    }

    handleRegisterModalOpen = _ => this.setState({isRegisterModalOpen: true});
    handleRegisterModalClose = _ => {
        this.setState({isRegisterModalOpen: false});
        if (this.props.openRegisterModal) {
            this.props.onDismiss();
        }
    }
    togglePasswordRecoveryModal = _ => this.setState({isPassRecoveryModalOpen: !this.state.isPassRecoveryModalOpen});

    render() {
        let negozioAspetto = NegozioOpenStore.infoNegozio ? NegozioOpenStore.infoNegozio.aspetto : null

        return (
            <Fragment>
                {(this.props.open && !this.props.openRegisterModal) &&
                    <div onClick={this.props.onDismiss} style={BackdropStyle}/>}
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={this.props.open && !this.props.openRegisterModal}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    {
                        this.props.open &&
                        <Fragment>
                            <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                            <div className={"container bg-main-z2 d-flex justify-content-center py-4"}>
                                <div className={"w-75 text-center"}>
                                    <div className={"w-100 mb-2"}>
                                        <MobileLoginIllustration
                                            color={negozioAspetto ? negozioAspetto.secondary_color : "#6861ce"}
                                            className={"img-fluid mx-auto"} style={{width:"70%"}}/>
                                    </div>
                                    <span className={"text-muted"}>
                                        {this.props.message}
                                    </span>
                                    <div className={"mt-4"}>
                                        <LoginForm onPasswordRecovery={this.togglePasswordRecoveryModal}/>
                                        {
                                            this.props.showRegisterButton &&
                                            <Fragment>
                                                <small
                                                    className={"text-muted text-uppercase"}>{i18n.t('generic.otherwise-label')}</small>
                                                <button className={"btn btn-secondary btn-block mx-auto mt-3"}
                                                        onClick={this.handleRegisterModalOpen}>
                                                    {i18n.t('login.create-account-btn')}
                                                </button>
                                            </Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                </SwipeablePanel>

                <ModalPortalHOC>
                    {
                        this.props.open &&
                        <Fragment>
                            <PageZLayerSelector zIndex={3001}>
                                <RegisterModal
                                    infoNegozio={NegozioOpenStore.infoNegozio || null}
                                    active={this.state.isRegisterModalOpen}
                                    onDismiss={this.handleRegisterModalClose}
                                />

                                <PasswordRecoveryModal
                                    active={this.state.isPassRecoveryModalOpen}
                                    onDismiss={this.togglePasswordRecoveryModal}
                                />
                            </PageZLayerSelector>
                        </Fragment>
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }
}

LoginPanel.propTypes = {
    open: PropTypes.bool,
    onDismiss: PropTypes.func,
    message: PropTypes.string,
    openRegisterModal: PropTypes.bool,
    showRegisterButton: PropTypes.bool
};

LoginPanel.defaultProps = {
    showRegisterButton: true
}

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 3000, backgroundColor: "rgba(0,0,0,0.7)"
}