import React, {Fragment} from "react";
import PropTypes from "prop-types";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import styled from "styled-components";
import {SwipeablePanel} from "../../BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class DipendenteItemView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    togglePanel = _ => this.setState({open: !this.state.open});

    render() {
        const imageUrl = this.props.infoNegozio.impostazioni.show_dipendenti_image === "1" ? this.props.dipendente.immagine : undefined;
        return (
            <Fragment>
                <div className={"d-inline-block w-100"}>
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <SafeHtmlContainer html={this.props.dipendente.nome}/>
                        {
                            !!imageUrl && <ImagePreview onClick={this.togglePanel} image={imageUrl}/>
                        }
                    </div>
                </div>

                <ModalPortalHOC>
                    {this.state.open && <div style={BackdropStyle}/>}
                    <SwipeablePanel
                        swipeTreshold={5}
                        direction={'top-to-bottom'}
                        active={this.state.open}
                        onDismiss={this.togglePanel}
                        disableSwipe={this.isSwipeDisabled}
                        animationSpeed={150}
                    >
                        <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                        {
                            this.state.open &&
                            <div className={"bg-main-z2 p-3"}>
                                <ImageFull image={imageUrl}/>
                                {
                                    !!this.props.dipendente.descrizione &&
                                    <div className={"text-muted mt-3"}>
                                        <SafeHtmlContainer html={this.props.dipendente.descrizione}/>
                                    </div>
                                }
                            </div>
                        }
                    </SwipeablePanel>
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DipendenteItemView.propTypes = {
    dipendente: PropTypes.object,
    infoNegozio: PropTypes.object,
}

const ImagePreview = styled.div`
    width: 40px;
    height: 40px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.image});
    border-radius: 50%;
`;

const ImageFull = styled.div`
    width: 100%;
    padding-top: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.image});
`;

