import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {ChatListSearchBar} from "../ChatListSearchBar";
import {ChatListViewItem} from "../ChatListViewItem";
import AppConfig from "../../../../config/AppConfig";
import APIConfig from "../../../../api/APIConfig";
import AuthAPI from "../../../../api/AuthAPI";

export class AdminChatListView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchQuery: ''
        }
    }

    handleSearch = query => this.setState({searchQuery: query});

    getFilteredChatList = _ => {
        const query = this.state.searchQuery.toLowerCase();
        return this.props.chatList.filter(c => {
            return !query || `${c.nome} ${c.cognome}`.toLowerCase().indexOf(query) > -1
        })
    }

    getUserProfilePicUrl = id_utente => APIConfig.url(`/utenti/profile-pic/${AuthAPI.currentUser.accessToken}/${id_utente}/400`)

    render() {
        const chatList = this.getFilteredChatList();
        return (
            <Fragment>

                <div className={"mt-3 border-bottom border-top p-2 bg-light"}>
                    <ChatListSearchBar onQueryChange={this.handleSearch}/>
                </div>


                {
                    !chatList.length &&
                    <div className={"text-muted my-3 text-center"}>
                        Non ci sono conversazioni da visualizzare
                    </div>
                }

                <div className={"pb-5"}>
                    {
                        !!chatList.length && <div className={"list-group list-group-flush shadow-sm mb-5"}>
                            {
                                chatList.map(
                                    (c, i) =>
                                        <ChatListViewItem
                                            className={i === 0 ? 'border-top-0' : ''}
                                            key={c.id_room}
                                            onClick={this.props.onChoose}
                                            chatData={c}
                                            label={`${c.nome} ${c.cognome}`}
                                            newMessageNumber={parseInt(c.nuovi_messaggi)}
                                            imageUrl={this.getUserProfilePicUrl(c.id_utente)}
                                        />
                                )
                            }
                        </div>
                    }
                </div>

            </Fragment>
        )
    }

}

AdminChatListView.propTypes = {
    chatList: PropTypes.array,
    onChoose: PropTypes.func,
}