import React, {Fragment} from "react";
import PropTypes from "prop-types";
import SafeHtmlContainer from "../helpers/SafeHtmlContainer";

export class CollapsibleHTMLTextView extends React.Component {

    constructor(props) {
        super(props);
    }

    getPlainTextDescription = _ => {
        let str = this.props.html;
        /* Sostituisco tag chiusura paragrafo con spazio, altrimenti i due paragrafi verranno
        stampati senza spazio fra loro*/
        str = str.replace(/<\/p>/g, ' ');
        str = str.replace(/<br>/g, ' ');
        str = str.replace(/<br\/>/g, ' ');
        /*rimuovo tag html per la preview*/
        str = str.replace(/<[^>]*>/g, '');
        return str;
    }

    //Restituisce il testo troncato "SMART" in base a MAX_PREVIWE_LENGTH
    getTruncatedDescription = _ => {
        let str = this.getPlainTextDescription();
        var i;
        for (i = this.props.limit; str.length >= i; i++) {
            if (str[i] === " " || str.length === i || str[i] === "&") {
                return str.substring(0, i);
            }
        }
    }

    //Mi dice se il testo va in overflow rispetto al massimo consentito
    isTextOverflowing = _ => {
        return this.getPlainTextDescription().length > this.props.limit;
    }

    render() {
        return (
            this.isTextOverflowing() ?
                !this.props.collapsed ?
                    <Fragment>
                        <SafeHtmlContainer html={this.props.html}/>
                        {this.props.showLessElement}
                    </Fragment>
                    :
                    <Fragment>
                        <SafeHtmlContainer html={this.getTruncatedDescription()}/>
                        {this.props.showMoreElement}
                    </Fragment>
                :
                <SafeHtmlContainer html={this.props.html}/>
        )
    }

}


CollapsibleHTMLTextView.propTypes = {
    html: PropTypes.string.isRequired,
    collapsed: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    showMoreElement: PropTypes.element,
    showLessElement: PropTypes.element,
}