import React from "react";
import PropTypes from "prop-types";

export class BundleSubServiceItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addServiceOpen: false
        }
    }

    render() {
        const subservice = this.props.subservice;
        const isPause = !subservice.id_servizio;
        if (isPause && !this.props.showPause) return '';
        const nomeServizio = isPause ? 'Pausa NON prenotabile' : subservice.nome_servizio;

        return (
            <React.Fragment>
                <div className={'pl-3'}>
                    <span className={"mr-2"}>{subservice.orario} - {subservice.orario_fine}</span>
                    <b className={isPause ? 'text-muted' : ''}>{nomeServizio}</b> con {subservice.nome_dipendente}
                    {
                        this.props.noteServizio &&
                        <div className={"text-info"}>
                            <b>Note:</b> {this.props.noteServizio.note_servizio}
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

}

BundleSubServiceItem.propTypes = {
    subservice: PropTypes.object,
    noteServizio: PropTypes.object,
    showPause: PropTypes.bool,
}