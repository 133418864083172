import AppConfig from "../../config/AppConfig";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";

export const _RecensioniCRUD = {

    crea: function (testo, valutazione, tipo_operazione, id_operazione){
        var body = {
            testo: testo,
            valutazione: valutazione,
            tipo_operazione: tipo_operazione,
            id_operazione:id_operazione,
            token: AuthAPI.currentUser.accessToken
        }

        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/recensioniCRUD/crea`), body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getRecensione: function (tipo_operazione, id_operazione){
        var params = {
            tipo_operazione: tipo_operazione,
            id_operazione:id_operazione,
            token: AuthAPI.currentUser.accessToken
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/recensioniCRUD/get-recensione`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
}