import React, {Fragment} from "react";
import styled, {keyframes} from "styled-components";
import {zoomInUp} from "react-animations";

const showAnimation = keyframes`${zoomInUp}`;

const MenuBackdrop = styled.div`
    position:fixed;
    width:90vw;
    height:300vh;
    top:0;
    left:0;
    z-index:1031;
    background:white;
    opacity:0;
`;
const MenuWrapper = styled.div`
    width:auto;
    position:absolute;
    right:10px;
    top:0;
    z-index:1032;
    overflow:hidden;
    animation: .3s ${showAnimation};
    animation-fill-mode: forwards;
`

export default class EllipsisMenu extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = null;
    }

    componentDidMount() {
        setTimeout(_ => document.addEventListener('click', this.handleClickOutside), 100);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleClickOutside = e => {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.props.onDismiss()
        }
    }

    setWrapperRef = node => {
        this.wrapperRef = node;
    }

    render() {

        return (
            <Fragment>
                <MenuBackdrop className={"__androidDismissable__"}/>
                <MenuWrapper ref={this.setWrapperRef} className={"bg-white shadow rounded"}>
                    <div className={"list-group list-group-flush text-center"}>
                        <div className={"list-group-item"} onClick={this.props.onReorder}>
                            Riordina
                        </div>
                        <div className={"list-group-item"} onClick={this.props.onShare}>
                            Condividi
                        </div>
                        <div className={"list-group-item text-danger"} onClick={this.props.onDelete}>
                            Elimina
                        </div>
                    </div>
                </MenuWrapper>
            </Fragment>
        )

    }

}
