import React from "react";
import AppConfig from "../config/AppConfig";
import {PlaceholderBlock} from "./Placeholders";

export function getCompressedImageUrl(src, width, height, quality) {
    if (!src) return src;
    let url;
    if (src.indexOf("firebasestorage.googleapis") !== -1) {
        url = getCompressedFirebaseStorageUrl(src, width, height);
    } else {
        url = `${AppConfig.webServerUrl}/compressjpeg.php?thumbnail=true&width=${width}&height=${height}&img=${src}`;
        if (quality) {
            url += `&quality=${quality}`;
        }
    }
    return url;
}

function getCompressedFirebaseStorageUrl(src, width, height) {
    const url = new URL(src);
    const pathnameParts = url.pathname.split("/");
    const filename = pathnameParts.pop();
    const filenameParts = filename.split(".");
    const ext = filenameParts.pop();
    const filenameWithoutExt = filenameParts.join(".");
    const newFilename = filenameWithoutExt + "_" + width + "x" + height + "." + ext;
    url.pathname = pathnameParts.join("/") + "/" + newFilename;
    return url.toString();
}

export function b64DataToFile(dataurl) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
};

export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
}
