import React, {Fragment} from "react";
import PropTypes from "prop-types";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import i18n from "../../../i18n";

export class EcommerceProductDescriptionView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    canShowDescription = _ => {
        return !!this.props.productData.descrizione ||
            !!this.props.productData.descrizione_tecninca ||
            !!this.props.productData.descrizione_spedizione ||
            !!this.props.productData.marca ||
            !!this.props.productData.caratteristiche?.length;
    }

    render() {
        return (
            <Fragment>
                {
                    this.canShowDescription() &&
                    <div className={"my-3"}>

                        {
                            !!this.props.productData.descrizione &&
                            <div className={"bg-main-z2 shadow-sm p-3 my-3 rounded"}>
                                <div className={"font-weight-bold text-on-bg-main"}>{i18n.t('ecommerce.description-headline')}</div>
                                <hr/>
                                <span className={"text-on-bg-main"}>
                                    <SafeHtmlContainer html={this.props.productData.descrizione}/>
                                </span>
                            </div>
                        }
                        {
                            !!this.props.productData.caratteristiche?.length &&
                            <div className={"bg-main-z2 shadow-sm p-3 my-3 rounded"}>
                                <div className={"font-weight-bold text-on-bg-main"}>{i18n.t('ecommerce.properties-headline')}</div>
                                <hr/>
                                <ul>
                                    {
                                        this.props.productData.caratteristiche.map(c =>
                                            <li><SafeHtmlContainer html={c}/></li>
                                        )
                                    }
                                </ul>
                            </div>
                        }
                        {
                            !!this.props.productData.descrizione_tecnica &&
                            <div className={"bg-main-z2 shadow-sm p-3 my-3 rounded"}>
                                <div className={"font-weight-bold text-on-bg-main"}>{i18n.t('ecommerce.technical-description-headline')}</div>
                                <hr/>
                                <SafeHtmlContainer html={this.props.productData.descrizione_tecnica}/>
                            </div>
                        }
                        {
                            !!this.props.productData.descrizione_spedizione &&
                            <div className={"bg-main-z2 shadow-sm p-3 my-3 rounded"}>
                                <div className={"font-weight-bold text-on-bg-main"}>{i18n.t('ecommerce.shipping-description-headline')}</div>
                                <hr/>
                                <SafeHtmlContainer html={this.props.productData.descrizione_spedizione}/>
                            </div>
                        }

                    </div>
                }
            </Fragment>
        )
    }

}

EcommerceProductDescriptionView.propTypes = {
    productData: PropTypes.object.isRequired
}