import React, {Fragment} from "react";
import {PreloadImage} from "../BaseComponents/ProfilePicThumbnail";
import ImageZoomPanel from "../BaseComponents/ImageZoomPanel";
import PropTypes from "prop-types";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";


export class GalleryGridImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zoomedImage: false,
            src: null,
        }
        this.styleImageStyle = {
            width: '100%',
            paddingTop: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${this.props.srcLD})`,
            backgroundSize: 'cover',
            backgroundProsition: 'center center',
            border: `2px solid ${this.props.borderColor}`,
            position: 'relative'
        }
        PreloadImage(this.props.srcLD)
            .then(image => this.setState({src: image}));
    }

    handleImageZoom = originalUrl => _ => {
        this.setState({zoomedImage: originalUrl});
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.srcHD !== this.props.srcHD ||
            nextProps.srcLD !== this.props.srcLD ||
            nextProps.borderColor !== this.props.borderColor ||
            nextState.src !== this.state.src ||
            nextState.zoomedImage !== this.state.zoomedImage;
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.src ?
                        <div className={"col-4 p-0"}>
                            <div
                                style={this.styleImageStyle}
                                onClick={this.handleImageZoom(this.props.srcHD)}
                            />
                        </div>
                        :
                        <div className={"col-4 ph-item p-0 m-0"}>
                            <div className={"ph-picture m-0"}/>
                        </div>
                }
                {
                    this.state.zoomedImage &&
                    <ModalPortalHOC>
                        <ImageZoomPanel onDismiss={_ => this.setState({zoomedImage: ''})} src={this.state.zoomedImage}/>
                    </ModalPortalHOC>
                }
            </Fragment>
        )
    }
}


GalleryGridImage.propTypes = {
    srcHD: PropTypes.string.isRequired,
    srcLD: PropTypes.string.isRequired,
    borderColor: PropTypes.string,
}