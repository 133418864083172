import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {swal, swalError, swalInput, swalLoading, swalSuccess} from "../helpers/SweetAlertWrapper";
import ProdottiFoodAPI from "../api/ModuloFood/ProdottiFoodAPI";
import i18n from "../i18n";
import NegozioAPI from "../api/NegozioAPI";
import MenuCategoryGalleryPage from "../components/MenuDigitale/MenuCategoryGalleryPage";
import FlagDropdown from "../components/BaseComponents/FlagDropdown/FlagDropdown";
import FoodDeliveryProductCard from "../components/FoodDelivery/FoodDeliveryProductCard";
import {FaSolidIcon} from "../components/FontAwesomeIcons";
import {MenuDigitaleSearchBar} from "../components/MenuDigitale/MenuDigitaleSearchBar";
import Page from "../components/BaseComponents/Page/Page";
import {FoodCartUtils} from "../components/FoodDelivery/Utils/FoodCartUtils";
import FoodShoppingCart from "../components/FoodDelivery/FoodShoppingCart";
import ProdottoDetail from "../components/MenuDigitale/ProdottoDetail";
import ModalPortalHOC from "../components/BaseComponents/ModalPortalHOC";
import ProdottiAggiuntePage from "../components/FoodDelivery/ProdottiAggiuntePage";
import SafeHtmlContainer from "../helpers/SafeHtmlContainer";
import ComandeAPI from "../api/ComandeAPI";
import AuthAPI from "../api/AuthAPI";
import {AdminAPI} from "../api/Admin/AdminAPI";

export class DirectComandeOrderContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sale: null,
            prodotti: null,
            cartProducts: [],
            prodotto_open: null,
            editingProduct: null,
            searchQuery: '',
            orderMenuOpen: false,
            id_tavolo: '',
            id_servizio: '',
            note: '',
            negozio: null,
            lang: i18n.language || 'it',

            categoryTree: [],
            categoryOpen: [],
            categoryLeaf: null,
        }

        this.shoppingCartRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData();
        AuthAPI.currentUser = {accessToken: this.props.accessToken};
    }

    fetchData() {
        swalLoading("Attendere prego...");
        var promiseList = [];

        promiseList.push(ProdottiFoodAPI.list(i18n.language, this.props.idNegozio));
        promiseList[0].then((prodotti) => this.setState({prodotti: prodotti.filter(p => p.abilita_ordine === "1")}));

        promiseList.push(ProdottiFoodAPI.listCategorie(i18n.language, this.props.idNegozio));
        promiseList[1].then((categorie) => this.setState({categoryTree: categorie}));

        promiseList.push(NegozioAPI.getInfo(this.props.idNegozio));
        promiseList[2].then((negozio) => this.setState({negozio: negozio}));

        Promise.all(promiseList).then(swal.close);
    }

    /**
     * @MARK: Category navigation
     * */

    handleCategoriesDismiss = _ => this.setState({id_servizio: ''});

    handleCategoryChoose = categoria => {
        if (!this.state.categoryOpen.find(c => c.id_categoria === categoria.id_categoria)) {
            this.setState({categoryOpen: [...this.state.categoryOpen, categoria]});
        }
    }

    handleCategoryDismiss = categoria => _ => {
        let categories = this.state.categoryOpen.slice();
        categories = categories.filter(c => c.id_categoria !== categoria.id_categoria);
        this.setState({categoryOpen: categories});
    }

    handleCategoryLeafChoose = categoria => this.setState({categoryLeaf: categoria});

    /**
     * Product navigation
     * */
    handleProdottiDismiss = _ => {
        this.setState({categoryLeaf: null, searchQuery: ''});
    }

    openDettaglio = prodotto => _ => {
        this.setState({prodotto_open: prodotto});
    }

    closeDettaglio = _ => {
        this.setState({prodotto_open: null});
    }

    handleProductEdit = product => this.setState({editingProduct: product});

    closeProductEdit = _ => this.setState({editingProduct: null});


    /**
     * SEARCH AND ORDERINGS
     * */

    handleSearch = (queryString, sortType) => {
        this.setState({searchQuery: queryString, orderType: sortType});
    }

    filterProductSearch = p => {
        const query = this.state.searchQuery.toLowerCase();
        if (!query) {
            return true;
        }
        return p.nome.toLowerCase().indexOf(query) > -1;
    }

    filterProductCategory = p => {
        return p.id_categoria === this.state.categoryLeaf.id_categoria;
    }

    sortProducts = (p1, p2) => {
        console.log("SORT CALLED");
        switch (this.state.orderType) {
            case 'alpha_asc':
                return p1.nome < p2.nome ? -1 : 1;
            case 'alpha_desc':
                return p1.nome > p2.nome ? -1 : 1;
            case 'price_asc':
                return parseFloat(p1.prezzo) < parseFloat(p2.prezzo) ? -1 : 1;
            case 'price_desc':
                return parseFloat(p1.prezzo) > parseFloat(p2.prezzo) ? -1 : 1;
            default:
                return 0
        }
    }

    /**
     * @description Effettua una ricerca in tutte le categorie figlie di "id_categoria_parent" in base ai dati di ricerca
     * @return Array | Null */
    handleSearchInCategory = (queryString, sortType, childList) => {
        if (!queryString) {
            return null;
        }

        const idLeaf = this.getLeafChilds(childList);
        let prodotti = this.state.prodotti
            .filter(
                p => idLeaf.find(id_categoria => id_categoria === p.id_categoria)
            )
            .filter(
                p => p.nome.toLowerCase().indexOf(queryString.toLowerCase()) > -1
            )
            .sort((p1, p2) => {
                switch (sortType) {
                    case 'alpha_asc':
                        return p1.nome < p2.nome ? -1 : 1;
                    case 'alpha_desc':
                        return p1.nome > p2.nome ? -1 : 1;
                    case 'price_asc':
                        return parseFloat(p1.prezzo) < parseFloat(p2.prezzo) ? -1 : 1;
                    case 'price_desc':
                        return parseFloat(p1.prezzo) > parseFloat(p2.prezzo) ? -1 : 1;
                    default:
                        return 0
                }
            });
        return prodotti;
    }

    getLeafChilds = childList => {
        let idLeafs = [];
        for (let categoria of childList) {
            if (!categoria.child.length) {
                idLeafs.push(categoria.id_categoria);
            } else {
                idLeafs = [...idLeafs, ...this.getLeafChilds(categoria.child)];
            }
        }
        return idLeafs;
    }

    productCardSearchResultGenerator = (prodotto) =>
        <FoodDeliveryProductCard
            primaryColor={this.state.negozio.aspetto.primaryColor}
            textOnPrimaryColor={this.state.negozio.aspetto.text_on_primary_color}
            secondaryColor={this.state.negozio.aspetto.secondary_color}
            textOnSecondaryColor={this.state.negozio.aspetto.text_on_secondary_color}
            bgMain={this.state.negozio.aspetto.bg_main}
            key={prodotto.id_prodotto}
            {...prodotto}
            onAddToCart={this.handleAddToCart}
            onDetail={this.openDettaglio(prodotto)}
            abilitaUscita={true}
        />

    /**
     * GESTIONE CARRELLO
     * */

    handleAddToCart = productData => {
        try {
            const newCartProducts = FoodCartUtils.addToCart(
                this.state.cartProducts,
                productData,
                id_prodotto => this.state.prodotti.find(pp => pp.id_prodotto === id_prodotto)
            );
            this.setState({cartProducts: newCartProducts});
        } catch (e) {
            swalError(e);
        }
    }

    handleUpdateCart = cartData => {
        const newCartProducts = FoodCartUtils.updateCartProduct(
            this.state.cartProducts,
            cartData.prodotto,
            cartData.quantita,
            cartData.note,
            (id_prodotto, neededQty) => {
                /* I prodotti possono essere con aggiunte, la disponibilità però è unica */
                let prodotto = this.state.prodotti.find(pp => pp.id_prodotto === id_prodotto); //Recupero disponibilità prodotto
                if(!prodotto) return -1
                if (neededQty > prodotto.quantita) {
                    swalError(`Ci sono solamente ${prodotto.quantita} ${prodotto.nome} disponibili`);
                    return false;
                }
                return true;
            }
        )
        this.setState({cartProducts: newCartProducts});
    }

    handleUpdateAggiunte = (id_opzione, aggiunte, rimozioni, attributi, note) => {
        const details = {
            id_opzione: id_opzione,
            aggiunte: aggiunte,
            rimozioni: rimozioni,
            attributi: attributi,
            note: note
        };
        const updatedCartProducts = FoodCartUtils.updateCartProductDetails(
            this.state.cartProducts,
            this.state.editingProduct,
            details
        );
        this.setState({cartProducts: updatedCartProducts, editingProduct: null});
    }

    handleCheckout = _ => {
        this.handlePlaceOrder(this.state);
    }

    /**
     * ORDER MANAGEMENT
     * */
    handleSetNote = _ => {
        swalInput('Note comanda', "Inserisci note della comanda", "textarea",'',true,true,this.state.note)
            .then(({value}) => {
                this.setState({note: value});
            });
    }

    handlePlaceOrder = state => {
        swalLoading();
        ComandeAPI.placeOrder(
            state.cartProducts,
            this.props.idServizio,
            state.note,
        ).then(response => {
            swalSuccess(response.message);
            this.setState({
                categoryOpen: [],
                categoryLeaf: null,
                cartProducts: [],
                note: ''
            });
            this.handleProdottiDismiss();
            this.shoppingCartRef.current.toggleShoppingCart();
        }).catch(swalError);
    }


    render() {
        const tempMainCategory = {
            nome: "Comande",
            child: this.state.categoryTree
        }
        if (!this.state.negozio) {
            return '';
        }
        return (
            <Fragment>
                <MenuCategoryGalleryPage
                    category={tempMainCategory}
                    onCategoryChoose={this.handleCategoryChoose}
                    onCategoryLeafChoose={this.handleCategoryLeafChoose}
                    onSearch={this.handleSearchInCategory}
                    onProductDetailOpen={this.openDettaglio}
                    hideEmptyCategory={true}
                    productList={this.state.prodotti}
                    searchResultItemGenerator={this.productCardSearchResultGenerator}
                />

                {/* Stampo tutte le successive pagine di categorie */}
                {
                    this.state.categoryOpen.map((c, i) =>
                        <MenuCategoryGalleryPage
                            key={i}
                            category={c}
                            onDismiss={this.handleCategoryDismiss(c)}
                            onCategoryChoose={this.handleCategoryChoose}
                            onCategoryLeafChoose={this.handleCategoryLeafChoose}
                            onSearch={this.handleSearchInCategory}
                            onProductDetailOpen={this.openDettaglio}
                            hideEmptyCategory={true}
                            productList={this.state.prodotti}
                            searchResultItemGenerator={this.productCardSearchResultGenerator}
                            rightIcon={<FlagDropdown className={"bg-main-z3 px-2 py-1 rounded"} default={"it"}
                                                     onChange={this.onLanguageChange}/>}
                        />
                    )
                }

                <Page
                    title={"Prodotti"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    leftIconText={"Categorie"}
                    onLeftIconClick={this.handleProdottiDismiss}
                    active={!!this.state.categoryLeaf}

                    content={
                        <div>
                            <div className={"container pt-2"}>
                                <MenuDigitaleSearchBar
                                    onSearch={this.handleSearch}
                                />
                                {
                                    this.state.prodotti === null || !this.state.categoryLeaf ?
                                        <div className={"text-center"}>Caricamento prodotti</div>
                                        :
                                        this.state.prodotti
                                            .filter(this.filterProductCategory)
                                            .filter(this.filterProductSearch)
                                            .sort(this.sortProducts)
                                            .map((prodotto, i) => {
                                                return (
                                                    <FoodDeliveryProductCard
                                                        primaryColor={this.state.negozio.aspetto.primaryColor}
                                                        textOnPrimaryColor={this.state.negozio.aspetto.text_on_primary_color}
                                                        secondaryColor={this.state.negozio.aspetto.secondary_color}
                                                        textOnSecondaryColor={this.state.negozio.aspetto.text_on_secondary_color}
                                                        bgMain={this.state.negozio.aspetto.bg_main}
                                                        key={i}
                                                        {...prodotto}
                                                        onAddToCart={this.handleAddToCart}
                                                        onDetail={this.openDettaglio(prodotto)}
                                                        abilitaUscita={true}
                                                    />
                                                )
                                            })
                                }
                            </div>

                        </div>
                    }
                />
                <FoodShoppingCart
                    ref={this.shoppingCartRef}
                    floatingCartColor={this.state.negozio.aspetto.primary_color}
                    floatingIconVisible={!this.state.orderDetailOpen}
                    cartVisible={true}
                    cartProducts={this.state.cartProducts}
                    onUpdateCart={this.handleUpdateCart}
                    onUpdateAggiunte={this.handleUpdateAggiunte}
                    onContinue={this.handleCheckout}
                    onProductEdit={this.handleProductEdit}
                    onContinueText={"Invia comanda"}
                    additionalFooter={
                        <button className={"btn btn-block btn-secondary"} onClick={this.handleSetNote}>
                            Aggiungi note comanda
                        </button>
                    }
                />

                <ProdottoDetail
                    {...this.state.prodotto_open}
                    active={!!this.state.prodotto_open}
                    onDismiss={this.closeDettaglio}
                />

                <ModalPortalHOC>
                    {
                        !!this.state.editingProduct &&
                        <ProdottiAggiuntePage
                            active={!!this.state.editingProduct}
                            onDismiss={this.closeProductEdit}
                            onChoose={this.handleUpdateAggiunte}
                            title={this.state.editingProduct.nome}
                            subtitle={this.state.editingProduct.ingredienti &&
                            <SafeHtmlContainer html={`Ingredienti: ${this.state.editingProduct.ingredienti}`}/>
                            }
                            opzioni={this.state.editingProduct.listaOpzioni}
                            aggiunte={this.state.editingProduct.listaAggiunte}
                            cottura={this.state.editingProduct.attributi.cottura !== undefined ? 1 : 0}
                            abilitaRimozioni={parseInt(this.state.editingProduct.abilitaRimozioni) === 1}
                            abilitaNote={parseInt(this.state.editingProduct.abilitaNote) === 1}
                            startOpzione={this.state.editingProduct.id_opzione}
                            startAggiunte={this.state.editingProduct.aggiunte}
                            startRimozioni={this.state.editingProduct.rimozioni}
                            startAttributi={this.state.editingProduct.attributi}
                            startNote={this.state.editingProduct.note}
                            continueButtonText={"Salva modifiche"}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DirectComandeOrderContainer.propTypes = {
    idServizio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    idNegozio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    accessToken: PropTypes.string,
}