import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../FontAwesomeIcons";

export function AttachmentsAddButton({attachments, onChange, ...props}) {
    const handleFilesChange = e => {
        if (!e.target.files) return;
        onChange([...attachments, ...e.target.files]);
    }

    const inputStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        translateX: -9999,
        opacity: 0
    }

    return (
        <React.Fragment>
            <label htmlFor={"chat-attachments-btn"}
                   disabled={props.disabled} className={`btn btn-link text-primary cursor-pointer mb-0 ${props.disabled && 'disabled'}`}>
                <FaSolidIcon name={"paperclip"}/>
            </label>
            <input
                id={"chat-attachments-btn"}
                style={inputStyle}
                type={"file"}
                onChange={handleFilesChange}
                multiple={true}
                accept={"image/*,video/*,audio/*,.pdf,.doc,.docx,.xls,.xlsx"}
                disabled={props.disabled}
            />
        </React.Fragment>
    )

}

AttachmentsAddButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    attachments: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
}