import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {ServiziRecommenderItem} from "./ServiziRecommenderItem";

export class ServiziRecommenderPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            referenceData: null,
            cartData: null
        }
        this.scrollableContainerRef = null;
    }

    isSwipeDisabled = _ => {
        if (this.scrollableContainerRef) {
            return this.scrollableContainerRef.scrollTop > 0;
        }
        return false;
    }
    getScrollableContainerRef = node => this.scrollableContainerRef = node;

    handleAdd = id_servizio => this.props.onChange([id_servizio], id_servizio, true);
    handleRemove = id_servizio => this.props.onChange([id_servizio], id_servizio, false);


    render() {

        return (
            <Fragment>
                <div style={BackdropStyle}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"bg-main-z2"}>
                        <div className={"py-4"} ref={this.getScrollableContainerRef}
                             style={ScrollableContainerStyle}>
                            <div className={"container"}>
                                <p className={"text-muted lead text-center"}>
                                    Altri clienti hanno prenotato anche
                                </p>
                                <div className={"form-group"}>
                                    {
                                        this.props.recommendations.map(s =>
                                            <ServiziRecommenderItem
                                                key={s.id_servizio}
                                                servizio={s}
                                                selected={this.props.selectedServicesIds.find(id => id === s.id_servizio)}
                                                showPrice={this.props.showPrice}
                                                onAdd={this.handleAdd}
                                                onRemove={this.handleRemove}
                                            />
                                        )
                                    }
                                </div>
                                <button className={"btn btn-primary btn-block text-on-bg-primary"} onClick={this.props.onDismiss}>
                                    Continua <FaSolidIcon name={"chevron-right"}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </SwipeablePanel>
            </Fragment>
        )
    }

}


ServiziRecommenderPanel.propTypes = {
    onDismiss: PropTypes.func,
    recommendations: PropTypes.array,
    selectedServicesIds: PropTypes.array,
    showPrice: PropTypes.bool,
    onChange: PropTypes.func,
}

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

const ScrollableContainerStyle = {maxHeight: '70vh', overflowY: 'auto'};
