import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import Page from "../../../../components/BaseComponents/Page/Page";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import {ModuloSeatsAPI} from "../../../../api/ModuloSeats/ModuloSeatsAPI";
import {
    SEAT_BOOK_STATUS_CANCELED,
    SEAT_BOOK_STATUS_CONFIRMED,
    SEAT_BOOK_STATUS_PENDING_CONFIRMATION,
    SEAT_BOOK_STATUS_PENDING_PAYMENT,
    SEAT_BOOK_STATUS_REFUSED
} from "../../../../components/ModuloSeats/Constants/BookStatuses";
import moment from "moment";
import {showFeedbackAvailableForSeats} from "../../../Recensioni/RecensioniHelperFunctions";
import RecensioniModal from "../../../Recensioni/RecensioniModal";
import {RECENSIONI_TIPO_PRENOTAZIONE_POSTI} from "../../../../const/RecensioniConstants";
import {MapView} from "./MapView";
import styled from "styled-components";
import {SeatView} from "./DateAccordion/SeatView";
import {CancellationView} from "./DateAccordion/CancellationView";
import {DateAccordion} from "./DateAccordion/DateAccordion";
import {LinkedBooksView} from "./DateAccordion/LinkedBooksView";

export class SeatBookDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookData: null,
            qrCodeZoomed: false,
        }
    }

    componentDidMount() {
        this.fetchBookData();
    }

    fetchBookData = _ => {
        swalLoading('Recupero informazioni sulla prenotazione');
        ModuloSeatsAPI.Client.Books.getFullBookInfo(this.props.bookId)
            .then(bookData => {
                this.setState({bookData: bookData});
                swal.safeClose();
            })
            .catch(message => {
                swalError(message);
                this.props.onDismiss();
            })
    }

    toggleQRCodeZoom = _ => this.setState({qrCodeZoomed: !this.state.qrCodeZoomed})

    getStatusLabel = _ => {
        return {
            [SEAT_BOOK_STATUS_CONFIRMED]: <span className={"badge badge-success"}>Confermato</span>,
            [SEAT_BOOK_STATUS_PENDING_PAYMENT]: <span className={"badge badge-warning"}>In attesa di pagamento</span>,
            [SEAT_BOOK_STATUS_PENDING_CONFIRMATION]: <span
                className={"badge badge-warning"}>In attesa di conferma</span>,
            [SEAT_BOOK_STATUS_CANCELED]: <span className={"badge badge-danger"}>Annullata</span>,
            [SEAT_BOOK_STATUS_REFUSED]: <span className={"badge badge-danger"}>Rifiutata</span>,
        }[this.state.bookData.master.status] || <span className={"badge badge-danger"}>Sconosciuto</span>
    }

    getAllSeats = _ => {
        const firstDayDate = Object.keys(this.state.bookData.dates)[0];
        return this.state.bookData.dates[firstDayDate].map(linkedBook => linkedBook.seat);
    }


    render() {
        if (!this.state.bookData) return '';

        const masterBook = this.state.bookData.master;
        const allBookedDates = Object.keys(this.state.bookData.dates).sort();
        const startDate = allBookedDates[0];
        const endDate = allBookedDates.pop();
        const allBookedSeats = this.getAllSeats();

        return (
            <React.Fragment>
                <Page
                    title={"Riepilogo"}
                    active={true}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                >
                    <div className={"container"}>

                        <div className={"my-4"}>
                            Stato: {this.getStatusLabel()}<br/>
                            Codice prenotazione: #{masterBook.book_id}<br/>
                        </div>

                        <div className={"my-4"}>
                            <div className={"row align-items-center"}>
                                <div className={"col border-right"}>
                                    <div className={"row mb-3"}>
                                        <div
                                            className={"col d-flex flex-column justify-content-center align-items-center"}>
                                            {startDate !== endDate && <span className={"text-muted"}>Dal</span>}
                                            <div className={"bg-primary text-on-bg-primary shadow rounded p-2"}>
                                                {moment(startDate).format('DD')}
                                            </div>
                                            <span>{moment(startDate).format('MMMM')}</span>
                                        </div>
                                        {
                                            startDate !== endDate &&
                                            <div
                                                className={"col d-flex flex-column justify-content-center align-items-center"}>
                                                <span className={"text-muted"}>Al</span>
                                                <div className={"bg-primary text-on-bg-primary shadow rounded p-2"}>
                                                    {moment(endDate).format('DD')}
                                                </div>
                                                <span>{moment(endDate).format('MMMM')}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={"col text-center"}>
                                    <FaSolidIcon name={"clock"} className={"fa-2x text-primary mb-2"}/>
                                    <div className={"text-center"}>
                                        <b>{masterBook.start_time}</b> - <b>{masterBook.end_time}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"my-4"}>
                            <MapView map={this.state.bookData.map}/>
                        </div>

                        {
                            showFeedbackAvailableForSeats(this.state.bookData) &&
                            <div className={"mb-2"}>
                                <RecensioniModal
                                    id_operazione={this.state.bookData.master.book_id}
                                    tipo_operazione={RECENSIONI_TIPO_PRENOTAZIONE_POSTI}
                                />
                            </div>
                        }

                        {/* QR code */}
                        {
                            this.state.bookData.qr_code_url &&
                            <div className={"my-4"}>
                                <div className={"card border-0 shadow-sm text-center p-3"}
                                     onClick={this.toggleQRCodeZoom}>
                                    <QrCodeImage className={this.state.qrCodeZoomed && 'zoomed'}
                                                 src={this.state.bookData.qr_code_url}/>
                                    <b>Clicca per ingrandire il QR Code!</b>
                                </div>
                            </div>
                        }

                        {/* Seats detail */}
                        <div className={"my-4"}>
                            <div className={"card border-0 shadow-sm"}>
                                {
                                    Object.keys(this.state.bookData.dates).length > 1 ?
                                        <DateAccordion bookData={this.state.bookData} onUpdate={this.fetchBookData}/>
                                        :
                                        <LinkedBooksView
                                            linkedBooks={this.state.bookData.dates[startDate]}
                                            disputeList={this.state.bookData.disputes}
                                            onUpdate={this.fetchBookData}
                                            onlinePayments={this.state.bookData.online_payments}
                                        />
                                }
                            </div>
                        </div>

                        {
                            (this.state.bookData.map.cancel_advance_time === 10000000) &&
                            <div className={"my-4"}>
                                <p className={"text-danger"}>
                                    La struttura non consente l'annullamento della prenotazione tramite app. In caso di
                                    necessità, contatta direttamente la struttura per ottenere ulteriori informazioni.
                                </p>
                            </div>
                        }

                    </div>
                </Page>
            </React.Fragment>
        )
    }

}

SeatBookDetailModal.propTypes = {
    bookId: PropTypes.any,
    onDismiss: PropTypes.func,
}

const QrCodeImage = styled.img`
margin:auto;
width: 80px;
height: 80px;
transition: 0.3s;

&.zoomed{
    width: 260px;
    height: 260px;
}
`;
