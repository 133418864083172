import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../config/AppConfig";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";
import AuthAPI from "../AuthAPI";
import APIWelfareConfig from "./APIWelfareConfig";

var WalletAttivitaAPI = {

    getBalance: function (walletApiKey){
        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/wallet/attivita/${walletApiKey}/getBalance`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getTransactions: function (walletApiKey, limit, offset){
        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/wallet/attivita/${walletApiKey}/getTransactions/${limit}/${offset}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }

};

export default WalletAttivitaAPI;