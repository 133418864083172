import React from 'react';

function FaIcon(props) {
    let classNames = "";
    if (props.badgeSide) classNames += ` fa-badge-${props.badgeSide}`;
    if (props.badgeColor) classNames += ` fa-badge-${props.badgeColor}`;

    return (
        <i style={props.style}
           className={`${props.type} fa-${props.name} fa-${props.size}x ${classNames} ${props.className}`}
           data-badge={props.badge} data-badge-small={props.badgeSmall}>{props.children}</i>
    )
}


function FaSolidIcon(props) {
    return (
        <FaIcon type={"fas"} {...props}>{props.children}</FaIcon>
    )
}

function FaRegularIcon(props) {
    return (
        <FaIcon type={"far"} {...props} >{props.children}</FaIcon>
    )
}

function FaBrandIcon(props) {
    return (
        <FaIcon type={"fab"} {...props} >{props.children}</FaIcon>
    )
}


export {FaIcon, FaSolidIcon, FaBrandIcon, FaRegularIcon};
