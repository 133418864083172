import React from "react";
import PropTypes from "prop-types";
import {AttachmentsListView} from "./AttachmentsListView";

export function AttachmentsContainer({attachments, onChange, ...props}) {
    const handleRemove = attachmentIndex => {
        if (attachmentIndex === -1) return onChange([]); //Clear all attachments
        onChange(attachments.filter((a, i) => i !== attachmentIndex));
    }

    const wrapperStyle = {
        maxHeight: 300,
        overflowY: 'auto'
    }

    return (
        <React.Fragment>
            <div className={"py-2"} style={wrapperStyle}>
                <b>File allegati:</b>
                <AttachmentsListView attachments={attachments} onRemove={handleRemove} disabled={props.disabled}/>
            </div>
        </React.Fragment>
    )
}

AttachmentsContainer.propTypes = {
    onChange: PropTypes.func.isRequired,
    attachments: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
}