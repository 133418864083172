import React from "react";
import moment from "moment";
import {string} from "prop-types";
import {PromotionsAPI} from "../../../../api/Promotions/PromotionsAPI";
import PromotionsCarousel from "../PromotionsCarousel";
import PromotionsAttiveOggiPage from "./PromotionsAttiveOggiPage";

export default class PromotionsAttiveOggi extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            promotions:[],
            attiveOggiPageOpen:false
        }
    }

    componentDidMount() {
        PromotionsAPI.PromotionsWithGeolocation.getPromotionsValideInDate(moment().format("YYYY-MM-DD"), 5, 0)
            .then(response =>{
                this.setState({promotions:response.promotions})
            })
    }

    attiveOggiPageToggle=_=>{
        this.setState({attiveOggiPageOpen: !this.state.attiveOggiPageOpen})
    }


    render() {
        return (
            <div>
                <div className={"mt-5 p-3"}>
                    <h4><b>Attive oggi! 🗓</b></h4>
                    <span className={"text-muted"}>Approfitta di queste promozioni adesso, sono attive per te!!</span>
                </div>
                <PromotionsCarousel
                    onVediDiPiuClick={this.attiveOggiPageToggle}
                    promotions={this.state.promotions}
                />
                {
                    this.state.attiveOggiPageOpen &&
                    <PromotionsAttiveOggiPage
                        onDismiss={this.attiveOggiPageToggle}
                    />
                }

            </div>
        );
    }

}