import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {Base64} from "../../../helpers/Base64";
import {SubscribeIcon} from "../../../img/illustrations/SubscribeIcon";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {swal, swalError, swalLoading} from "../../../helpers/SweetAlertWrapper";
import {MenuDigitaleAPI} from "../../../api/MenuDigitale/MenuDigitaleAPI";
import moment from "moment";


const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class MenuDigitaleEmailOptinPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            privacy_policy: false,
        }
        this.formRef = React.createRef();
    }

    handleInputChange = e => this.setState({[e.target.name]: e.target.value});
    handleCheckboxChange = e => this.setState({[e.target.name]: e.target.checked});
    isSwipeDisabled = _ => false;

    handleConfirm = _ => {
        var reEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.state.email || !reEmail.test(this.state.email)){
            swalError('Inserisci una email valida');
            return;
        }

        if (!this.state.privacy_policy){
            swalError(`Accetta l'informativa privacy policy per proseguire`);
            return;
        }

        swalLoading('Attendere prego...');
        localStorage.setItem('menu_digitale_optin_date', moment().format('YYYY-MM-DD')+"@"+this.props.infoNegozio.id_negozio);
        MenuDigitaleAPI.WebMenu.saveEmail(this.props.infoNegozio.id_negozio, this.state.email, {
            email: this.state.email,
            privacy_policy: this.state.privacy_policy
        }, this.formRef.current.innerHTML).finally(_ => {
            swal.safeClose();
            this.props.onDismiss();
        });
    }

    render() {
        return (
            <Fragment>
                {this.props.open && <div style={BackdropStyle} onClick={this.props.onDismiss}/>}
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={this.props.open}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"bg-main-z2 p-3"}>
                        <div className={"w-50 mx-auto d-block my-3"}>
                            <SubscribeIcon className={"img-fluid"} color={this.props.infoNegozio.aspetto.primary_color}/>
                        </div>
                        <h5 className={"font-weight-bold text-primary text-center"}>
                            Inserisci la tua email per proseguire
                        </h5>
                        <div ref={this.formRef}>
                            <div className={"form-group"}>
                                <input type={"email"} name={"email"} className={"form-control"}
                                       onChange={this.handleInputChange}
                                       placeholder={"Inserisci email"}/>
                            </div>
                            <div className="form-group">
                                <label>
                                    <input type={"checkbox"} name={"privacy_policy"} required onChange={this.handleCheckboxChange}/>
                                    &nbsp;Accetto l'informativa privacy e consenso al trattamento dei dati <a
                                    target={"_blank"}
                                    href={`https://proinn.web.app/bookizon/redirect.html?url=${Base64.encode("https://bookizon.it/documents/privacy-policy")}`}
                                    className="iubenda-white no-brand iubenda-embed iub-body-embed"
                                    title="Privacy Policy">Privacy Policy</a>
                                </label>
                            </div>
                            <button className={"btn btn-primary btn-block"}
                                    onClick={this.handleConfirm}
                                    disabled={!this.state.email || !this.state.privacy_policy}>
                                <FaSolidIcon name={"check"}/> Invia e continua
                            </button>
                        </div>
                    </div>
                </SwipeablePanel>
            </Fragment>
        )
    }

}

MenuDigitaleEmailOptinPanel.propTypes = {
    open: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    infoNegozio: PropTypes.object.isRequired
}