import React, {Fragment} from "react";
import PropTypes, {object} from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import DipendenteWelfareGiftCardHistoryProductPage from "./DipendenteWelfareGiftCardHistoryProductPage";
import {ORDINE_COMPLETATO, ORDINE_RIMBORSATO, PIN_STATUS_ATTIVO, PIN_STATUS_NON_ATTIVO} from "../GiftCardsConstants";

export default class DipendenteWelfareGiftCardsHistoryItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    //Stampa lo stato dell'ordine
    getOrderStatus = _ => {
        if (this.props.giftCard.status === ORDINE_RIMBORSATO) {
            return (
                <>
                    <div className={"text-danger"}><FaSolidIcon name={"hourglass"}/></div>
                    <small className={"text-danger"}>Rimborsata</small>
                </>
            )
        }

        switch (this.props.giftCard.pin_status) {
            case PIN_STATUS_NON_ATTIVO:
                return (
                    <>
                        <div className={"text-warning"}><FaSolidIcon name={"hourglass"}/></div>
                        <small className={"text-warning"}>Non <br/> attivata</small>
                    </>
                )
            case PIN_STATUS_ATTIVO:
                return (
                    <>
                        <div className={"text-success"}><FaSolidIcon name={"check"}/></div>
                        <small className={"text-success"}>Attivata</small>
                    </>
                )
        }
    }


    render() {
        return (
            <Fragment>
                <div className={"card card-body shadow-sm border-0 my-2 align-item-center"}
                     onClick={_ => this.props.onGiftCardClick(this.props.giftCard)}>
                    <div className={"d-flex align-item-center justify-content-around"}>
                        <div className={"mr-2"}>
                            <div>#{this.props.giftCard.id_ordine}</div>
                            <div className={"font-weight-bold"}>{this.props.giftCard.name}</div>
                            {
                                this.props.giftCard.pin_status === PIN_STATUS_NON_ATTIVO && this.props.giftCard.status === ORDINE_COMPLETATO &&
                                <div><small className={"text-warning"}>Attiva la gift card per poterla
                                    utilizzare</small></div>
                            }
                            <small className={"text-muted"}>Acquistato il {this.props.giftCard.data_ordine}</small>
                        </div>
                        <div className={"text-center"}>
                            {this.getOrderStatus()}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

DipendenteWelfareGiftCardsHistoryItem.propTypes = {
    giftCard: PropTypes.object,
    onGiftCardClick: PropTypes.func
}