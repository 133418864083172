import React, {Fragment} from "react";
import ProfiloAttivitaContainer from "./ProfiloAttivitaContainer";
import logoGrey from "../../img/logo_total_gray.svg";
import logoWhite from "../../img/logo_total_white.svg";
import logoColor from "../../img/bookizon-logo.svg";
import appStoreLogo from "../../img/store-logo/app-store.svg";
import playStoreLogo from "../../img/store-logo/google-play-badge.png";
import {BookizonAppManager} from "../../index";
import AppConfig from "../../config/AppConfig";

export default class ProfiloAttivitaPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const OS = BookizonAppManager.getMobileOperatingSystem();
        return (
            <div className={"py-4 mx-auto"} style={{maxWidth: 570}}>
                <div className={"container border-bottom"}>
                    {
                        this.props.negozio.aspetto.isBackgroundColorDark ?
                            <img src={logoWhite} height="40" className="mb-4 mx-auto d-block"/>
                            :
                            <img src={logoColor} height="40" className="mb-4 mx-auto d-block"/>
                    }
                    <div className="text-on-bg-main text-center">
                        Vivi l'esperienza completa e scarica <span className={"font-weight-bold"}>Bookizon</span> sul
                        tuo smartphone!
                    </div>
                    <div className={"my-3 text-center"}>
                        {
                            OS !== 'unknown' &&
                            <div className={"row"}>
                                <div className={"col"}>
                                    {OS === 'iOS' && <a href={"https://apps.apple.com/us/app/bookizon/id1506158300?l=it&ls=1"}><img src={appStoreLogo} className="w-100"/></a>}
                                    {OS === 'Android' && <a href="https://play.google.com/store/apps/details?id=com.proinn.bookizon"><img src={playStoreLogo} className="w-100"/></a>}
                                </div>
                                <div className={"col"}>
                                    <span className="text-on-bg-main">Poi usa il codice:</span><br/>
                                    <span className="text-uppercase text-primary">{this.props.negozio.codice}</span>
                                </div>
                            </div>

                        }

                        {OS === 'unknown' &&
                        <img src={`${AppConfig.webServerUrl}/api/shops/qrcode/${this.props.negozio.id_negozio}`}
                             className="mx-auto w-50"/>}
                    </div>
                </div>
                <ProfiloAttivitaContainer
                    negozio={this.props.negozio}
                    primaryColor={this.props.negozio.aspetto.primary_color}
                    backgroundColor={this.props.negozio.aspetto.bg_main_z2}
                    showMenu={!!this.props.negozio.homepage_buttons.find(b => b.type === 'menu_digitale')}
                />
            </div>
        )
    }

}
