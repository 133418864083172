import React from "react";
import PropTypes from "prop-types";
import {CityPropType} from "./PropTypes/CityPropTypes";
import Page from "../../components/BaseComponents/Page/Page";
import AppConfig from "../../config/AppConfig";
import {swal, swalLoading} from "../../helpers/SweetAlertWrapper";
import ModalPortalHOC from "../../components/BaseComponents/ModalPortalHOC";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {NM_GEOLOCATION_PERMISSION, NM_GEOLOCATION_UPDATE} from "../../native/NativeMessageHandler";
import Store from "../../components/Store";

export class DigitalCityPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.iframeStyle = {
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 2000,
            border: 0
        }
        this.iframeRef = null;
    }

    componentDidMount() {
        swalLoading('Attendere prego...');
        window.addEventListener('message', this.handleMessageReceived, false);
        FuxEvents.on(NM_GEOLOCATION_UPDATE, this.handleUpdateGeolocationInfo);
        FuxEvents.on(NM_GEOLOCATION_PERMISSION, this.handleUpdateGeolocationInfo);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessageReceived, false);
        FuxEvents.off(NM_GEOLOCATION_UPDATE, this.handleUpdateGeolocationInfo);
        FuxEvents.off(NM_GEOLOCATION_PERMISSION, this.handleUpdateGeolocationInfo);
    }

    handleIframeLoad = _ => {
        swal.safeClose();
        this.handleUpdateGeolocationInfo();
    }

    handleIframeRef = node => {
        if (node) {
            this.iframeRef = node;
        }
    }

    handleUpdateGeolocationInfo = _ => {
        this.sendIframeMessage({
            action: 'geolocation.permission',
            permission: Store.state.native.hasGeolocationPermission
        });
        this.sendIframeMessage({
            action: 'geolocation.update',
            lat: Store.state.native.lat,
            lon: Store.state.native.lng
        });
    }

    sendIframeMessage = (message) => {
        console.log(message);
        if (this.iframeRef && this.iframeRef.contentWindow) {
            this.iframeRef.contentWindow.postMessage({
                action: 'nativeMessage',
                message: JSON.stringify(message)
            },'*')
        }
    }

    handleMessageReceived = event => {
        if (!event || !event.data || !event.data.action) return;
        if (event.data.action === 'dismiss') this.props.onDismiss();
    }

    render() {
        return (
            <ModalPortalHOC>
                <iframe ref={this.handleIframeRef} style={this.iframeStyle} onLoad={this.handleIframeLoad}
                        src={`${AppConfig.webServerDigitalCity}/${this.props.city.normalised_name}/app?embedded=1`}/>
            </ModalPortalHOC>
        )
    }

}

DigitalCityPage.propTypes = {
    city: CityPropType,
    lat: PropTypes.number,
    lon: PropTypes.number
}
