import React from "react";
import PropTypes from "prop-types";

export class RecordingDurationLabel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recordingDuration: 0
        }
        this.interval = null;
    }

    componentDidMount() {
        this.interval = setInterval(_ => {
            if (!this.props.isPaused) {
                this.setState({recordingDuration: this.state.recordingDuration + 1})
            }
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval)
    }

    render() {
        const durationDate = new Date(this.state.recordingDuration * 1000).toISOString();
        const durationString = this.state.recordingDuration < 3600 ? durationDate.substring(14, 19) : durationDate.substring(11, 16);

        return (
            <React.Fragment>
                {durationString}
            </React.Fragment>
        )
    }

}

RecordingDurationLabel.propTypes = {
    isPaused: PropTypes.bool
}