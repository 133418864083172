import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import Page from "../../../../components/BaseComponents/Page/Page";
import {DisputeListViewItem} from "./DisputeListViewItem";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {BusinessWithUserDisputePanel} from "./BusinessWithUserDisputePanel";
import {UserAdminChatAPI} from "../../../../api/Chat/UserAdminChatAPI";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {ADMIN_DISPUTE_CHAT_READ_EVT} from "../../../../const/AppEventsCostants";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import {DisputesAPI} from "../../../../api/Disputes/DisputesAPI";
import {DISPUTE_STATUS_OPEN} from "../../../../const/DisputesCostants";
import AuthAPI from "../../../../api/AuthAPI";

export class DisputeModeView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disputes: [],
            disputeOpen: null,
        }
    }

    componentDidMount() {
        AuthAPI.currentUser = {accessToken: this.props.accessToken};
        this.fetchDisputes();
        FuxEvents.on(ADMIN_DISPUTE_CHAT_READ_EVT, this.silentUpdate)
    }

    componentWillUnmount() {
        FuxEvents.off(ADMIN_DISPUTE_CHAT_READ_EVT, this.silentUpdate)
    }

    handleDisputeOpen = dispute => {
        this.setState({disputeOpen: dispute});
        //imposto come letti i messaggi della chat aperta
        UserAdminChatAPI.setRead(dispute.id_chat_room)
            .then(_ => FuxEvents.emit(ADMIN_DISPUTE_CHAT_READ_EVT));
    }

    fetchDisputes = silent => {
        if (!silent) swalLoading('Aggiornamento controversie in corso...');
        DisputesAPI.Admin.getDisputes(DISPUTE_STATUS_OPEN)
            .then(disputes => this.setState({disputes: disputes}, _ => swal.safeClose()))
            .catch(swalError);
    }

    handleDisputeClose = silent => {
        this.setState({disputeOpen: null});
    }

    silentUpdate = _ => this.fetchDisputes(true);
    visibleUpdate = _ => this.fetchDisputes(false);

    render() {
        return (
            <Fragment>
                <div className={"container mt-3"}>
                    {
                        this.state.disputes.map((d, i) =>
                            <DisputeListViewItem
                                className={i === 0 ? 'border-top-0' : ''}
                                key={d.id_chat_room}
                                onClick={this.handleDisputeOpen}
                                disputeData={d}
                                newMessageNumber={parseInt(d.nuovi_messaggi)}
                            />
                        )
                    }
                </div>
                <ModalPortalHOC>
                    {
                        this.state.disputeOpen &&
                        <BusinessWithUserDisputePanel
                            disputeData={this.state.disputeOpen}
                            onClose={this.handleDisputeClose}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}