import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'
import AuthAPI from "../../api/AuthAPI";
import {swal, swalError, swalLoading, swalSuccess} from '../../helpers/SweetAlertWrapper';
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import IndirizziAPI from "../../api/IndirizziAPI";
import PlaceAPI from "../../api/PlaceAPI";
import {ADDRESS_BOOK_CHANGE} from "../../const/AppEventsCostants";
import i18next from "i18next";

export default class AddressForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nome: '', cognome: '', cellulare: '',
            indirizzo: '', civico: '',
            interno_scala: '', citofono: '',
            citta: '', cap: '',
            id_indirizzo: props.idIndirizzo, //Se non è settato sarà undefined,
            codice_nazione: 'IT', id_provincia: '', id_citta: '',
            listaCitta: [],
            listaProvince: [],
            listaNazioni: [],
            fatturazione: this.props.invoiceData ? 1 : 0
        };

        this.state = {...this.state, ...this.props.addressData};

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.campiObbligatori = this.props.invoiceData ?
            ["ragione_sociale", "piva", "cellulare", "indirizzo", "civico", "id_citta", "cap"] :
            ["nome", "cognome", "cellulare", "indirizzo", "civico", "id_citta", "cap"];
    }

    componentDidMount() {
        PlaceAPI.getProvince(this.state.codice_nazione)
            .then(province => {
                this.setState({listaProvince: province});
            });
        if (this.state.id_provincia){
            PlaceAPI.getCitta(this.state.id_provincia)
                .then(citta => this.setState({listaCitta: citta}));
        }
    }

    validateForm() {

        for (var i in this.campiObbligatori) {
            if (!this.state[this.campiObbligatori[i]]) {
                swal.fire({
                    icon: 'error',
                    title: "Oops!",
                    text: i18next.t('generic.errors.fill-all-mandatory-fields')
                });
                return false;
            }
        }

        return true;

    }

    handleSubmit(event) {
        event.preventDefault();
        swalLoading();
        if (this.validateForm()) {
            IndirizziAPI.save(this.state)
                .then(swalSuccess)
                .then(_ => FuxEvents.emit(ADDRESS_BOOK_CHANGE))
                .then(this.props.onSuccess)
                .catch(swalError)
        }
    }

    handleDeleteAddress = _ => {
        swalLoading();
        IndirizziAPI.delete(this.state.id_indirizzo)
            .then(swalSuccess)
            .then(_ => FuxEvents.emit(ADDRESS_BOOK_CHANGE))
            .then(this.props.onSuccess)
            .catch(swalError)
    }

    handleFetchCitta = id_provincia => {
        PlaceAPI.getCitta(id_provincia)
            .then(citta => this.setState({listaCitta: citta, id_citta: citta[0].id_citta}));
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        if (name === 'codice_nazione') {
            PlaceAPI.getProvince(value)
                .then(province => this.setState({listaProvince: province}));
        }
        if (name === 'id_provincia') {
            this.handleFetchCitta(value);
        }
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {
                    this.props.invoiceData &&
                    <Fragment>
                        <div className="form-group">
                            <label>{i18next.t('address-book.add-form.business-name')} *</label>
                            <input type="text" name="ragione_sociale" className="form-control"
                                   value={this.state.ragione_sociale} onChange={this.handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>{i18next.t('address-book.add-form.vat-number')} *</label>
                            <input type="text" name="piva" className="form-control"
                                   value={this.state.piva} onChange={this.handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>{i18next.t('address-book.add-form.invoice-delivery-code')}</label>
                            <input type="text" name="codice_sdi" className="form-control"
                                   value={this.state.codice_sdi} onChange={this.handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>PEC</label>
                            <input type="email" name="pec" className="form-control"
                                   value={this.state.pec} onChange={this.handleInputChange}/>
                        </div>
                    </Fragment>
                }
                {
                    !this.props.invoiceData &&
                    <Fragment>
                        <div className="form-group">
                            <label>{i18next.t('address-book.add-form.first_name')} *</label>
                            <input type="text" name="nome" className="form-control"
                                   value={this.state.nome} onChange={this.handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>{i18next.t('address-book.add-form.last_name')} *</label>
                            <input type="text" name="cognome" className="form-control"
                                   value={this.state.cognome} onChange={this.handleInputChange}/>
                        </div>
                    </Fragment>
                }
                <div className="form-group">
                    <label>{i18next.t('address-book.add-form.phone')} *</label>
                    <input type="tel" name="cellulare" className="form-control"
                           value={this.state.cellulare} onChange={this.handleInputChange}/>
                </div>
                <div className="form-group">
                    <label>{i18next.t('address-book.add-form.address')} *</label>
                    <input type="text" name="indirizzo" className="form-control"
                           value={this.state.indirizzo} onChange={this.handleInputChange}/>
                </div>
                <div className="form-group">
                    <label>{i18next.t('address-book.add-form.number')} *</label>
                    <input type="text" name="civico" className="form-control"
                           value={this.state.civico} onChange={this.handleInputChange}/>
                </div>
                {
                    !this.props.invoiceData &&
                    <Fragment>
                        <div className="form-group">
                            <label>{i18next.t('address-book.add-form.interior')}</label>
                            <input type="text" name="interno_scala" className="form-control"
                                   value={this.state.interno_scala} onChange={this.handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>{i18next.t('address-book.add-form.intercom')}</label>
                            <input type="text" name="citofono" className="form-control"
                                   value={this.state.citofono} onChange={this.handleInputChange}/>
                        </div>
                    </Fragment>
                }
                <div className="form-group">
                    <label>{i18next.t('address-book.add-form.country')} *</label>
                    <select name="codice_nazione" className="form-control" value={this.state.codice_nazione}
                            onChange={this.handleInputChange}
                    >
                        <option value={'IT'}>Italia</option>
                        <option value={'NL'}>Netherlands</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>{i18next.t('address-book.add-form.state')} *</label>
                    <select name="id_provincia" className="form-control" value={this.state.id_provincia}
                            onChange={this.handleInputChange}
                            disabled={!this.state.codice_nazione}
                    >
                        {
                            this.state.codice_nazione ?
                                this.state.listaProvince.map(p => {
                                    return <option value={p.id_provincia} key={p.id_provincia}>{p.nome}</option>
                                }) : ''
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>{i18next.t('address-book.add-form.city')} *</label>
                    <select name="id_citta" className="form-control" value={this.state.id_citta}
                            onChange={this.handleInputChange}
                            disabled={!this.state.id_provincia}
                    >
                        {
                            this.state.id_provincia ?
                                this.state.listaCitta.map(c => {
                                    return <option value={c.id_citta} key={c.id_citta}>{c.nome}</option>
                                }) : ''
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>{i18next.t('address-book.add-form.postal_code')} *</label>
                    <input type="text" name="cap" className="form-control"
                           value={this.state.cap} onChange={this.handleInputChange}/>
                </div>
                <button className="btn btn-primary btn-block shadow my-3" type="submit">{i18next.t('address-book.add-form.save-btn')}</button>
                {
                    !!this.state.id_indirizzo &&
                        <button className={"btn btn-link btn-block text-danger my-3"} type={"button"} onClick={this.handleDeleteAddress}>
                            {i18next.t('address-book.add-form.delete-btn')}
                        </button>
                }
            </form>
        )
    }

}
