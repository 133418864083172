import React from "react";
import moment from "moment";
import DipendenteWelfareDetailsRichiestaRimborso from "./DipendenteWelfareDetailsRichiestaRimborso";
import DipendenteWelfareDetailsTransaction from "../TransazioniWallet/DipendenteWelfareDetailsTransaction";


export default class DipendenteWelfareSingleRichiestaRimborso extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            richiestaDetailsOpen: false
        }
    }


    handleColorStatusRichiesta = status => {
        switch (status) {
            case "in_attesa":
                return <span className={"text-muted"}><i className="fas fa-clock"></i><br/>In attesa</span>;
            case "rifiutato":
                return <span className={"text-danger"}><i className="fas fa-times"></i><br/>Rifiutato</span>;
            case "accettato":
                return <span className={"text-warning"}><i className="fas fa-thumbs-up"></i><br/>Accettato</span>;
            case "rimborsato":
                return <span className={"text-success"}><i className="fas fa-check-circle"></i><br/>Rimborsato</span>;
        }
    }


    toggleRichiestaDetails = _ => {
        this.setState({richiestaDetailsOpen: !this.state.richiestaDetailsOpen});
    }


    render() {

        var momentDate = moment(this.props.richiestaRimborso.data_creazione);
        var m = momentDate.calendar(null, {
            sameDay: '[Oggi alle] HH:mm',
            nextDay: '[Domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[Ieri alle] HH:mm',
            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });

        return (
            <div>
                <ul className={"list-group"} onClick={this.toggleRichiestaDetails}>
                    {this.props.richiestaRimborso.id_rimborso &&
                    <li className={"list-group-item list-group-item-action"}>
                        <div className={"row align-items-center"}>
                            <div className={"col-3 text-center"}>
                                {this.handleColorStatusRichiesta(this.props.richiestaRimborso.status)}
                            </div>
                            <div className={"col-6"}>
                                <b>{m[0].toUpperCase() + m.substr(1)}</b><br/>
                                <span>{this.props.richiestaRimborso.descrizione}</span><br/>
                                <span
                                    className={"text-muted"}>{this.props.richiestaRimborso.denominazione_attivita}</span>
                            </div>
                            <div className={"col-3 text-right"}>
                                <span><b>€ {this.props.richiestaRimborso.importo}</b></span>
                            </div>
                        </div>
                    </li>
                    }
                </ul>

                <DipendenteWelfareDetailsRichiestaRimborso
                    active = {this.state.richiestaDetailsOpen}
                    onDismiss = {this.toggleRichiestaDetails}
                    user={this.props.user}
                    dipendente={this.props.dipendente}
                    richiestaRimborso = {this.props.richiestaRimborso}
                />

            </div>
        );
    }
}