import React, {Fragment} from "react";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {FidelityCardAPI} from "../../../../api/FidelityCard/FidelityCardAPI";
import {swal, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import PropTypes from "prop-types";
import {FidelityCardPremiItem} from "./FidelityCardPremiItem";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {FIDELITY_CARD_PRIZE_REDEEM_EVT} from "../../../../const/AppEventsCostants";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {FidelityCardPremiRiscattatiPage} from "./FidelityCardPremiRiscattatiPage";

export class FidelityCardPremiPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            premi: null,
            premiUtenteOpen: false
        }
    }

    componentDidMount() {
        this.fetchProdotti();
        FuxEvents.on(FIDELITY_CARD_PRIZE_REDEEM_EVT, this.fetchProdotti);
    }

    componentWillUnmount() {
        FuxEvents.off(FIDELITY_CARD_PRIZE_REDEEM_EVT, this.fetchProdotti);
    }

    fetchProdotti = _ => {
        swalLoading('Recupero premi in corso...')
        FidelityCardAPI.User.getPrizeList(this.props.idNegozio)
            .then(premi => this.setState({premi: premi}, _ => swal.safeClose()));
    }

    togglePremiUtente = _ => this.setState({premiUtenteOpen: !this.state.premiUtenteOpen})

    render() {
        if (!this.state.premi) return '';

        return (
            <Fragment>
                <Page
                    title={"Premi"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    rightIconText={`${this.props.punti} punti`}
                >
                    <div className={"container-fluid pt-3"}>
                        <button className={"btn btn-block btn-info mb-4"} onClick={this.togglePremiUtente}>
                            <FaSolidIcon name={"gifts"}/> I miei premi
                        </button>
                        {
                            this.state.premi.map(p => <FidelityCardPremiItem key={p.id_premio} premioData={p}/>)
                        }
                    </div>
                </Page>
                <ModalPortalHOC>
                    {
                        this.state.premiUtenteOpen &&
                        <FidelityCardPremiRiscattatiPage
                            idNegozio={this.props.idNegozio}
                            onDismiss={this.togglePremiUtente}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        );
    }
}

FidelityCardPremiPage.propTypes = {
    idNegozio: PropTypes.string,
    onDismiss: PropTypes.func,
    punti: PropTypes.number
}