import React, {Fragment} from "react";
import Page from "../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import {FidelityCardAPI} from "../../../api/FidelityCard/FidelityCardAPI";
import {swal, swalLoading} from "../../../helpers/SweetAlertWrapper";


import FidelityCardModal from "./FidelityCardModal";
import FidelityCardItem from "./FidelityCardItem";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {FIDELITY_CARD_PRIZE_REDEEM_EVT} from "../../../const/AppEventsCostants";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";

export default class FidelityCardsModal extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            cards: [],
            fidelityCardOpenData: null
        }
    }

    componentDidMount() {
        FuxEvents.on(FIDELITY_CARD_PRIZE_REDEEM_EVT, this.fetchData);
        this.fetchData();
    }

    componentWillUnmount() {
        FuxEvents.off(FIDELITY_CARD_PRIZE_REDEEM_EVT, this.fetchData);
    }

    fetchData = (waitMessage, silent) => {
        if (!silent) swalLoading(waitMessage || 'Recupero carte...');
        FidelityCardAPI.User.list()
            .then(cards => this.setState({
                cards: cards,
                //Aggiorno l'eventuale carta aperta
                fidelityCardOpenData: this.state.fidelityCardOpenData ? cards.find(c => c.id_negozio === this.state.fidelityCardOpenData.id_negozio) : null
            }))
            .then(swal.safeClose);
    }

    handleFidelityCardClick = data => {
        this.setState({fidelityCardOpenData: data});
    }

    handleFidelityCardDismiss = _ => {
        this.setState({fidelityCardOpenData: null});
        this.fetchData('Aggiorno i dati...', true);
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Fidelity Card"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <div className={"container"}>
                            <div className={"row mt-3"}>
                                {
                                    this.state.cards.map((c, i) =>
                                        <div className={"col-6"} key={c.id_negozio}>
                                            <FidelityCardItem
                                                onClick={this.handleFidelityCardClick}
                                                color={c.colors.text_on_primary_color}
                                                darkerColor={c.colors.primary_color_active}
                                                lighterColor={c.colors.primary_color_disabled}
                                                cardData={c}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                />
                <ModalPortalHOC>
                    {
                        !!this.state.fidelityCardOpenData &&
                        <FidelityCardModal
                            onDismiss={this.handleFidelityCardDismiss}
                            cardData={this.state.fidelityCardOpenData}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        );
    }
}


