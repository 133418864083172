import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";


export const _Operations = {
    importGuestOperations: function (operations){
        let body = {
            token: AuthAPI.currentUser.accessToken,
            operations: operations
        }
        return FuxHTTP.post(
            APIConfig.url(`/user/operations/import-guest-operations`), body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};
