import React from "react";
import PropTypes from "prop-types";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {PRODOTTI_PREFERITI_CHANGE} from "../../../const/AppEventsCostants";
import {ModuloEcommerceAPI} from "../../../api/ModuloEcommerce/ModuloEcommerceAPI";


/**
 * Questo componente ha il compito di aggiornare lo state di una parte della UI basandosi sul fatto che un prodotto
 * (indicato tramite props) sia o meno all'interno dei preferiti dell'utente. Il componente riceve messaggi tramite
 * FuxEvents per aggiornare il proprio stato in base alle interazioni di altre istanze dello stesso componente
 * (e che si riferiscono allo stesso prodotto)
 * */

export class EcommerceProductFavouriteStatusHOC extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isPreferito: (typeof this.props.initialStatus === "boolean" && this.props.initialStatus) || (!!parseInt(this.props.initialStatus))
        }
    }

    componentDidMount() {
        FuxEvents.on(PRODOTTI_PREFERITI_CHANGE, this.handleExternalPreferitoUpdate);
    }

    componentWillUnmount() {
        FuxEvents.off(PRODOTTI_PREFERITI_CHANGE, this.handleExternalPreferitoUpdate);
    }

    handleExternalPreferitoUpdate = ({id_prodotto, status}) => {
        if (id_prodotto === this.props.idProdotto && status !== this.state.isPreferito) {
            this.setState({isPreferito: status});
        }
    }

    /*
    * La funzione si occupa di INVERTIRE lo stato del componente e, una volta fatto, invia al
    * server la richiesta corretta per invertire lo stato. Se la richiesta non va a buon fine
    * viene ripristinato lo stato originale
    * */
    handlePreferitoToggle = e => {
        e.stopPropagation();
        let isPreferito = this.state.isPreferito;
        this.setState({
            isPreferito: !isPreferito
        }, _ => {
            var promise = isPreferito ?
                ModuloEcommerceAPI.Prodotti.rimuoviPreferito(this.props.idProdotto) :
                ModuloEcommerceAPI.Prodotti.aggiungiPreferito(this.props.idProdotto);

            promise
                .then(_ => {
                    FuxEvents.emit(PRODOTTI_PREFERITI_CHANGE, {
                        id_prodotto: this.props.idProdotto,
                        status: !isPreferito,
                        productData: {id_prodotto:this.props.idProdotto, isPreferito: !isPreferito ? 1 : 0}
                    });
                })
                .catch(_ => {
                    this.setState({isPreferito: isPreferito});
                });
        });
    }

    render() {
        return this.props.render(this.state.isPreferito, this.handlePreferitoToggle);
    }

}


EcommerceProductFavouriteStatusHOC.propTypes = {
    idProdotto: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    initialStatus: PropTypes.bool,
    render: PropTypes.func.isRequired
}
