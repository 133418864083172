import React from "react";
import RangeSlider from "react-bootstrap-range-slider";


export default class DistanceChooserBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            distance: this.props.default || 20
        }
    }

    handleMaxDistanceChange = e => {
        this.setState({distance: e.target.value});
    }

    render() {
        return (
            <div className={"row align-items-center"}>
                <div className={"col __disable-swipe "}>
                    <RangeSlider
                        value={this.state.distance}
                        min={1}
                        max={200}
                        onChange={this.handleMaxDistanceChange}
                        onAfterChange={this.handleMaxDistanceChange}
                        tooltip={'on'}
                        tooltipPlacement={'top'}
                        tooltipLabel={km => `${km}Km`}
                    />
                </div>
                <div className={"col-3"}>
                    <button className={"btn btn-link font-weight-bold"} onClick={_ => this.props.onChoose(this.state.distance)}>
                        Fatto
                    </button>
                </div>
            </div>
        )
    }


}
