import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import {swal, swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import EcommerceAPI from "../../../api/EcommerceAPI";
import i18n from "../../../i18n";

export class EcommerceProductRequestQuoteButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    //Gestione preventivo
    handleRequestQuote = _ => {
        let placeholder = NegozioOpenStore.infoNegozio.impostazioni.preventivi_placeholder_note || i18n.t('ecommerce.quotes.request-placeholder');
        swal.fire({
            input: 'textarea',
            allowOutsideClick: false,
            html: i18n.t('ecommerce.quotes.request-title'),
            inputPlaceholder: placeholder,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: i18n.t('generic.send-btn'),
            cancelButtonText: i18n.t('generic.cancel-btn'),
            reverseButtons: true,
            preConfirm: note => {
                if (!note) {
                    swal.showValidationMessage(i18n.t('ecommerce.errors.enter-notes'));
                }
                return note || false;
            }
        })
            .then(result => {
                if (result.value) {
                    swalLoading(i18n.t('generic.loading-please-wait'));
                    EcommerceAPI.requestQuote(this.props.productData.id_prodotto, result.value)
                        .then(swalSuccess)
                        .catch(swalError)
                }
            });
    }

    render() {
        return (
            <Fragment>
                <button className={"btn btn-block btn-secondary"}
                        onClick={this.handleRequestQuote}>
                    <FaSolidIcon name={"file-invoice-dollar"}/> {i18n.t('ecommerce.quotes.request-btn')}
                </button>
            </Fragment>
        )
    }

}

EcommerceProductRequestQuoteButton.propTypes = {
    productData: PropTypes.object.isRequired
}