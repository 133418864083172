import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";


export const _Settings = {
    notificheSettings: function (settings){
        console.log(settings)
        let params = {
            token: AuthAPI.currentUser.accessToken,
            settings: settings
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/user/settings/save`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};