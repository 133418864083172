import styled from "styled-components";
import React, {Fragment} from "react";
import {FeedPostCardActionBar} from "./FeedPostCardActionBar";
import FeedPostBaseInfo from "./FeedPostBaseInfo";
import {FeedPostPage} from "./FeedPostPage";
import FeedCommentItem from "./Comments/FeedCommentItem";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import PostAPI from "../../../api/PostAPI";
import AuthAPI from "../../../api/AuthAPI";

const animationDurationMs = 350;

const PostCard = styled.div`
    position:relative;
    overflow: hidden; 
    transition: all ${animationDurationMs / 2}ms linear;
    border-radius:0px!important;
`;

export default class FeedPostCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            totaleCommenti: 0,
            bestCommentData:[],
            commentsPageOpen: false
        }
        this.cardRef = null

    }

    toggleCommentSection = _ => this.setState({commentsPageOpen: !this.state.commentsPageOpen})

    loadBestComment = _ => {
        const viewportHeight = document.documentElement.clientHeight;
        const boundingClientRect = this.cardRef.getBoundingClientRect();
        //recupero i commenti se la viewport è scesa e se l'utente è loggato
        if (boundingClientRect.top - viewportHeight < 200 && AuthAPI.currentUser) {
            PostAPI.getBestCommentUnderThePost(this.props.id_post)
                .then(data => {
                    this.setState({bestCommentData: data.commento, totaleCommenti: data.totaleCommenti})
                })
        }
        return false;
    }

    getCardRef = el => {
        if (el) {
            this.cardRef = el;
            this.intersectionObserver = new IntersectionObserver(
                this.intersectionCallback, {
                    root: null, threshold: 0
                }
            );
            this.intersectionObserver.observe(el);
        }
    }

    intersectionCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.loadBestComment();
            }
        });
    };


    render() {

        return (
            <Fragment>
                <div style={this.props.style} ref={this.getCardRef}>
                    <PostCard key={`${this.props.id}_${this.props.tipo}`}
                              className={"card my-3 shadow-sm border-0"}
                    >
                        {/*BASE DEL POST*/}
                        <FeedPostBaseInfo {...this.props}/>

                        {/*BARRA LIKE/COMMENTI/CONDIVISIONE*/}
                        <FeedPostCardActionBar
                            {...this.props}
                            onAppAdd={this.props.onAppAdd}
                            onAppOpen={this.props.onAppOpen}
                            onCommentButtonClick={this.toggleCommentSection}
                        />

                        {/*STAMPA IL PRIMO COMMENTO SE ESISTE E SE C'é un utente loggato*/}
                        {
                            this.state.bestCommentData.id_commento &&
                            <div className={"py-3"} onClick={this.toggleCommentSection}>
                                <div className={"p-2"}>
                                    <FeedCommentItem
                                        idUtente={this.state.bestCommentData.id_utente}
                                        nomeUtente={this.state.bestCommentData.nome_utente}
                                        immagineUtente={this.state.bestCommentData.immagine_utente || null}
                                        commento={this.state.bestCommentData.commento}
                                        dataCommento={this.state.bestCommentData.data_creazione}
                                        disableReplay={true}
                                    />
                                </div>
                                {
                                    this.state.totaleCommenti > 1 && <small className={"text-muted p-2"}>Vedi altri {this.state.totaleCommenti} commenti</small>
                                }
                            </div>
                        }
                    </PostCard>
                </div>

                <ModalPortalHOC>
                    {
                        this.state.commentsPageOpen &&
                        <FeedPostPage
                            {...this.props}
                            onDismiss={this.toggleCommentSection}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }
}


