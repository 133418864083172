import React from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";
import {SwipeablePanelBackdrop} from "../../BaseComponents/SwipeablePanel/SwipeablePanelBackdrop";
import {FileListView} from "./FileList/FileListView";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {PluralString} from "../../../helpers/UIUtility";
import AuthAPI from "../../../api/AuthAPI";
import FuxHTTP from "../../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../../../api/APIConfig";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";

export class UploadPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
        this.panelContentRef = React.createRef();
        this.containerStyle = {
            height: '80vh',
            overflow: 'auto'
        }
    }

    isSwipeDisabled = _ => {
        if (this.panelContentRef.current) {
            return this.panelContentRef.current.scrollTop > 0;
        }
        return false;
    }

    handleFileInputChange = e => {
        if (!e.target.files) return;
        this.handleFilesChange([...this.state.files, ...e.target.files]);
    }

    handleFilesChange = files => this.setState({files: files});

    handleClearAllFiles = _ => this.handleFilesChange([]);

    handleSubmit = _ => {
        swalConfirm(`Sei sicuro di voler caricare ${this.state.files.length} file?`)
            .then(_ => {
                const formData = new FormData();
                formData.append("token", AuthAPI.getUserAccessToken());
                for (let k in this.props.uploadParams) formData.append(k, this.props.uploadParams[k]);

                this.state.files.map(a => formData.append("file[]", a));

                swalLoading('Caricamento in corso...')
                FuxHTTP.post(
                    this.props.uploadUrl, formData,
                    FuxHTTP.RESOLVE_DATA,
                    FuxHTTP.REJECT_MESSAGE
                ).then(response => {
                    const prom = parseInt(response.failed) <= 0 ? swalSuccess("Caricamento completato correttamente!") : swalError(response.errorMessage);
                    prom.then(_ => {
                        this.handleClearAllFiles();
                        this.props.onFileUpdateRequest();
                        this.props.onDismiss();
                    });
                }).catch(swalError);
            });
    }

    render() {
        return (
            <React.Fragment>
                <ModalPortalHOC>
                    {this.props.open && <SwipeablePanelBackdrop onClick={this.props.onDismiss}/>}
                    <SwipeablePanel
                        swipeTreshold={5}
                        direction={'top-to-bottom'}
                        active={this.props.open}
                        onDismiss={this.props.onDismiss}
                        disableSwipe={this.isSwipeDisabled}
                        animationSpeed={150}
                    >
                        <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                        <div className={"bg-main-z2 p-3"} style={this.containerStyle}>
                            <div className={"d-flex flex-column align-items-center"}>
                                <FaSolidIcon name={"cloud-arrow-up"} size={4} className={"text-primary mb-3"}/>
                                <p className={"lead text-muted text-center"}>
                                    Seleziona documenti o immagini dal tuo dispositivo per poterli caricare
                                </p>
                                {
                                    this.state.files.length > 0 &&
                                    <button className={"btn btn-primary mb-3"} onClick={this.handleSubmit}>
                                        Carica {this.state.files.length} file <PluralString n={this.state.files.length}
                                                                                            single={"selezionato"}
                                                                                            more={"selezionati"}/>
                                    </button>
                                }
                                <label className={"btn btn-link"} htmlFor={"__FileManagerFilePicker"}>
                                    <FaSolidIcon name={"plus"}/> Clicca per scegliere uno o più file
                                </label>
                                <input
                                    type={"file"}
                                    id={"__FileManagerFilePicker"}
                                    multiple={true}
                                    accept={"image/*,video/*,audio/*,.pdf,.doc,.docx,.xls,.xlsx"}
                                    className={"opacity-0"}
                                    onChange={this.handleFileInputChange}
                                />
                            </div>
                            {
                                this.state.files.length > 0 &&
                                <div>
                                    <b>File selezionati</b>
                                    <FileListView onChange={this.handleFilesChange} files={this.state.files}/>
                                    <div className={"text-center"}>
                                        <button className={"btn btn-link btn-sm text-muted"} type={"button"}
                                                onClick={this.handleClearAllFiles}>
                                            <FaSolidIcon name={"trash"}/> Rimuovi tutti
                                        </button>
                                    </div>
                                </div>

                            }
                        </div>
                    </SwipeablePanel>
                </ModalPortalHOC>
            </React.Fragment>
        )
    }

}

UploadPanel.propTypes = {
    open: PropTypes.bool,
    onDismiss: PropTypes.func,
    uploadUrl: PropTypes.string,
    uploadParams: PropTypes.object,
    onFileUpdateRequest: PropTypes.func,
}
