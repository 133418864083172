import React, {Fragment} from "react";
import {FaSolidIcon} from "../FontAwesomeIcons";
import PropTypes from "prop-types";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";
import EditEmailPage from "./EditEmailPage";
import EditPasswordPage from "./EditPasswordPage";

export default class CredentialInfoView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editEmailPageOpen: false,
            editPasswordPageOpen: false
        }
    }

    toggleEditEmailPage = _ => {
        this.setState({editEmailPageOpen: !this.state.editEmailPageOpen});
    }

    toggleEditPasswordPage = _ =>{
        this.setState({editPasswordPageOpen: !this.state.editPasswordPageOpen})
    }

    render() {
        return(
            <Fragment>
                <div className={"form-group text-on-bg-main"}>
                    <small className={""}>
                        <FaSolidIcon name={"user-circle"}/> Account
                    </small>
                    <div className={"card px-2 bg-main-z2"}>
                        <div className={"container"}>
                            <div className={"d-flex py-2 border-bottom align-items-center"}>
                                <div className={"text-muted w-25"}>
                                    Email
                                </div>
                                <div className={"flex-fill text-left"}>
                                    {this.props.user.email}
                                </div>
                                <div className={"px-0 flex-fill text-right"}>
                                    <button className={"btn btn-link btn-sm"} onClick={this.toggleEditEmailPage}>
                                        <FaSolidIcon name={"edit"}/>
                                    </button>
                                </div>
                            </div>
                            <div className={"d-flex py-2 border-bottom align-items-center"}>
                                <div className={"text-muted w-25"}>
                                    Cellulare
                                </div>
                                <div className={"flex-fill text-left"}>
                                    {this.props.user.cellulare}
                                </div>
                            </div>
                            <div className={"d-flex py-2 align-items-center"}>
                                <div className={"text-muted w-25"}>
                                    Password
                                </div>
                                <div className={"px-0 flex-fill text-right"}>
                                    <button className={"btn btn-link btn-sm"} onClick={this.toggleEditPasswordPage}>
                                        <FaSolidIcon name={"edit"}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalPortalHOC>
                    {
                        this.state.editEmailPageOpen &&
                        <EditEmailPage
                            user = {this.props.user}
                            onDismiss = {this.toggleEditEmailPage}
                        />
                    }

                    {this.state.editPasswordPageOpen &&
                        <EditPasswordPage
                            user={this.props.user}
                            onDismiss={this.toggleEditPasswordPage}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

CredentialInfoView.propTypes = {
    user: PropTypes.any
}