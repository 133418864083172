import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../FontAwesomeIcons";
import moment from "moment";
import ModalPortalHOC from "../../../BaseComponents/ModalPortalHOC";
import ImageZoomPanel from "../../../BaseComponents/ImageZoomPanel";

export function ImageMessage({isMine, isLast, messageData, getMediaContentUrlAPI}) {
    const sendTime = moment(messageData.data_creazione).format('HH:mm');
    const mediaUrl = getMediaContentUrlAPI(messageData.id_messaggio);

    const imageStyle = {
        backgroundImage: `url("${mediaUrl}")`,
        backgroundSize: 'cover',
        width: 250,
        height: 250,
        borderRadius: 15
    }

    const [isFullscreenMode, setFullscreenMode] = React.useState(false);

    const toggleFullscreenMode = _ => setFullscreenMode(!isFullscreenMode);

    return (
        <React.Fragment>
            <div className={"d-flex " + (isMine ? 'justify-content-end mine' : 'yours')}>
                <div className={"message " + (isLast ? 'last' : '')}>
                    <div style={imageStyle} className={"mb-2 cursor-pointer"} onClick={toggleFullscreenMode}/>
                    <div className={"metadata"}>
                        {sendTime}&nbsp;
                        {
                            isMine &&
                            <FaSolidIcon name={messageData.letto === '1' ? 'check-double' : 'check'}/>
                        }
                    </div>
                </div>
            </div>
            <ModalPortalHOC>
                {
                    isFullscreenMode &&
                    <ImageZoomPanel onDismiss={toggleFullscreenMode} src={mediaUrl}/>
                }
            </ModalPortalHOC>

        </React.Fragment>
    )
}

ImageMessage.propTypes = {
    isMine: PropTypes.bool,
    isLast: PropTypes.bool,
    messageData: PropTypes.object.isRequired,
    getMediaContentUrlAPI: PropTypes.func.isRequired,
}