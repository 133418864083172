import AppConfig from "../config/AppConfig";
import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./AuthAPI";
import APIConfig from "./APIConfig";


var BookingAPI = {
    getRichData: function (id_negozio, getAllServices) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/bookingData/${id_negozio}`), {
                token: AuthAPI.getUserAccessToken(),
                getAllServices: getAllServices ? 1 : 0
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getServices: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            AppConfig.controllerUrl,
            {action: "getServices", id_negozio: id_negozio},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listaSedi: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            AppConfig.controllerUrl,
            {action: "listaSedi", id_negozio: id_negozio},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listaDipendenti: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/dipendenti/${id_negozio}/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listaCategorie: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            AppConfig.controllerUrl,
            {action: "listaCategorie", id_negozio: id_negozio},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getAvailableDates: function (from, direction, id_sede, id_dipendente, id_servizio, repeat) {
        var params = {
            from: from,
            direction: direction,
            id_sede: id_sede,
            id_dipendente: id_dipendente,
            id_servizio: id_servizio,
            repeat: repeat,
            token: AuthAPI.currentUser.accessToken
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/get-available-dates'),
            params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    book: function (options) {
        var params = {
            id_sede: options.id_sede,
            data: options.data,
            orario: options.orario,
            id_servizio: options.id_servizio,
            id_dipendente: options.id_dipendente,
            dipendente_chiunque: options.dipendente_chiunque || 0,
            note_utente: options.note_utente,
            repeat: options.repeat || 1,
            token: AuthAPI.currentUser.accessToken,
            id_utente: options.id_utente, //Impostato solo per prenotazione admin
            note: options.note, //Impostato solo per prenotazione admin
            coupon: options.coupon,
            wallets: options.wallets || null,
            packages: options.packages || null,
            payFullAmount: options.payFullAmount
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/book'),
            params,
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    changeBook: function (options) {
        var params = {
            action: "book",
            id_prenotazione: options.id_prenotazione,
            id_sede: options.id_sede,
            data: options.data,
            orario: options.orario,
            //id_servizio: options.id_servizio,
            id_dipendente: options.id_dipendente,
            token: AuthAPI.currentUser.accessToken
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/changeBook'),
            params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    remove: function (id_prenotazione) {

        var params = {
            id_prenotazione: id_prenotazione,
        };

        if (AuthAPI && AuthAPI.currentUser && AuthAPI.currentUser.accessToken)
            params.token = AuthAPI.currentUser.accessToken;

        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/deleteBook'),
            params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );

    },
    getNextBooks: function () {
        var endPoint = APIConfig.url('/nextBooks');
        endPoint += `/${AuthAPI.currentUser.accessToken}`;

        return FuxHTTP.apiGetRequestWithPromise(
            endPoint, {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getPassedBooks: function () {
        var endPoint = APIConfig.url('/passedBooks');
        endPoint += `/${AuthAPI.currentUser.accessToken}`;

        return FuxHTTP.apiGetRequestWithPromise(
            endPoint, {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getShareData: function (id_prenotazione) {
        return FuxHTTP.apiGetRequestWithPromise(
            AppConfig.controllerUrl,
            {
                action: "getPrenotazioneShareData",
                token: AuthAPI.currentUser.accessToken,
                id_prenotazione: id_prenotazione
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};


export default BookingAPI;
