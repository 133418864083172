import React from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import moment from "moment";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import RegisterForm from "../Forms/RegisterForm";

export default class RegisterModal extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Page
                onLeftIconClick={this.props.onDismiss}
                floatingNavbar={true}
                navbarBgColor={" bg-main "}
                active={this.props.active}
                content={
                    <div className={"container text-on-bg-main pt-2"}>
                        <RegisterForm
                            onDismiss={this.props.onDismiss}
                            infoNegozio={this.props.infoNegozio}
                        />
                    </div>
                }
            />
        );
    }

}


RegisterModal.propTypes = {
    onDismiss: PropTypes.func,
    active: PropTypes.bool,
    infoNegozio: PropTypes.object|| null
};
