import React from "react";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {DLA_SEAT_BOOK_QR} from "../../../../const/DeepLinkActionsCostants";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {SeatBookQRInfoPanel} from "./SeatBookQRInfoPanel";
import {ModuloSeatsAPI} from "../../../../api/ModuloSeats/ModuloSeatsAPI";
import {swalError} from "../../../../helpers/SweetAlertWrapper";

export class SeatBookQRScanListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookId: null
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_SEAT_BOOK_QR, this.handleOpenPanel)
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_SEAT_BOOK_QR, this.handleOpenPanel);
    }

    handleOpenPanel = bookIdWithPassword => {
        const [bookId, password] = bookIdWithPassword.split("_");
        ModuloSeatsAPI.Admin.Books.checkPassword(bookId, password)
            .then(_ => {
                this.setState({bookId: bookId});
            }).catch(_ => swalError("Il QR code non è valido oppure è scaduto"))
    }

    handleDismissResume = _ => this.setState({bookId: null});


    render() {
        if (!this.state.bookId) return '';
        return (
            <ModalPortalHOC>
                <SeatBookQRInfoPanel
                    bookId={this.state.bookId}
                    onDismiss={this.handleDismissResume}
                />
            </ModalPortalHOC>
        )
    }

}

SeatBookQRScanListener.propTypes = {

}