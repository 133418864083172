import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import '../../FoodDelivery/ribbon.css';
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {FaRegularIcon, FaSolidIcon} from "../../FontAwesomeIcons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {getCompressedImageUrl} from "../../../helpers/ImageUtility";
import ImageZoomPanel from "../../BaseComponents/ImageZoomPanel";
import EcommerceAPI from "../../../api/EcommerceAPI";
import {FuxCarousel} from "../../BaseComponents/FuxCarousel/FuxCarousel";
import {VietatoMinoriIcon} from "../../MenuDigitale/MenuDigitaleCardProdotto";
import {ProductImageSize} from "../../../const/ImageSizeCostants";
import {
    EcommerceProductSuggestionView,
    PRODUCT_SUGGEST_SAME_BRAND,
    PRODUCT_SUGGEST_SAME_CATEGORY
} from "./EcommerceProductSuggestionView";
import {EcommerceProductFavouriteStatusHOC} from "./EcommerceProductFavouriteStatusHOC";
import {applyPercentage} from "../../../helpers/NumberUtility";
import AuthAPI from "../../../api/AuthAPI";
import {EcommerceShareButton} from "./EcommerceShareButton";
import {AppIconThumbnail} from "../../BaseComponents/AppIconThumbnail";
import AppConfig from "../../../config/AppConfig";
import {EcommerceProductViewProfiloAttivita} from "./EcommerceProductViewProfiloAttivita";
import {EcommerceProductDescriptionView} from "./EcommerceProductDescriptionView";
import {EcommerceImageGalleryButton} from "./EcommerceImageGalleryButton";
import {EcommerceProductAddToCartView} from "./EcommerceProductAddToCartView";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";
import {
    ECOMMERCE_PRODUCT_TYPE_PACCHETTO,
    ECOMMERCE_PRODUCT_TYPE_PACCHETTO_SMART
} from "../../../const/ModuloEcommerceCostants";
import {EcommerceProductDirectBuyView} from "./EcommerceProductDirectBuyView";
import {YoutubeEmbed} from "../../VideoEmbed/YoutubeEmbed/YoutubeEmbed";
import {VimeoEmbed} from "../../VideoEmbed/VimeoEmbed/VimeoEmbed";
import i18n from "../../../i18n";
import {Trans} from "react-i18next";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import {formatCurrency} from "../../../helpers/CurrencyHelpers";
import {max} from "moment";

const EmbedVideoStyle = {width: '100vw', height: '100vw'};

export default class EcommerceProductView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zoomedImage: '',
            expectedShipping: null,
            profiloAttivitaOpen: false,
            modelloScelto: null
        }
    }

    componentDidMount() {
        if (this.props.showExpectedShipping) {
            EcommerceAPI.getExpectedShippingPrice(this.props.productData.id_prodotto)
                .then(expectedShipping => this.setState({expectedShipping: expectedShipping}));
        }
    }

    handleModelloChange = m => this.setState({modelloScelto: m});


    getCurrentDiscountPercentage = _ => this.props.productData.perc_sconto ? parseFloat(this.props.productData.perc_sconto) : 0.0;

    getCurrentPrice = withoutDiscount => {
        const discount = this.getCurrentDiscountPercentage();
        const um = this.props.productData.um === 'pz' ? '' : `/${this.props.productData.um}`;

        if (parseInt(this.props.productData.preventivo)) {
            return `${this.props.productData.priceRange}${um}`;
        } else if (this.props.productData.modelli.length === 0) {
            const prezzoScontato = applyPercentage(parseFloat(this.props.productData.prezzo), withoutDiscount ? 0 : -discount).toFixed(2);
            return <span>
                €{prezzoScontato} {um}
                {
                    (!withoutDiscount && discount > 0) &&
                    <span className={"ml-2 small text-muted text-line-through"}>
                        €{this.props.productData.prezzo}
                    </span>
                }
            </span>
        } else {
            const modelloScelto = this.state.modelloScelto;
            if (modelloScelto) {
                const prezzoScontato = applyPercentage(parseFloat(modelloScelto.prezzo), withoutDiscount ? 0 : -discount).toFixed(2);
                return <span>
                    €{prezzoScontato} {um}
                    {
                        (!withoutDiscount && discount > 0) &&
                        <span className={"ml-2 small text-muted text-line-through"}>
                            €{modelloScelto.prezzo}
                        </span>
                    }
                </span>
            } else {
                if (withoutDiscount || !discount) return `${this.props.productData.priceRange}${um}`;
                //Apply discount to min and max prices for each model
                const prices = this.props.productData.modelli.map(m => parseFloat(m.prezzo));
                const minPrice = applyPercentage(Math.min(...prices), -discount).toFixed(2);
                const maxPrice = applyPercentage(Math.max(...prices), -discount).toFixed(2);
                const discountedRange = minPrice < maxPrice ?
                    `${formatCurrency(minPrice, 'EUR', 'it-IT')} - ${formatCurrency(maxPrice, 'EUR', 'it-IT')}${um}` :
                    `${formatCurrency(maxPrice, 'EUR', 'it-IT')}${um}`;
                return <div>
                    €{discountedRange}
                    {
                        (!withoutDiscount && discount > 0) &&
                        <span className={"ml-2 small text-muted text-line-through"}>
                            {this.props.productData.priceRange}{um}
                        </span>
                    }
                </div>
            }
        }
    }

    getDiscountLabel = _ => {
        const discount = this.getCurrentDiscountPercentage();
        if (discount) {
            return <div className={'alert alert-success'}>
                <strong>{i18n.t('ecommerce.discount-template', {perc: discount.toFixed(0)})}</strong>
                {
                    !!this.props.productData.modelli.length &&
                    <span>
                        <br/>{i18n.t('ecommerce.select-variants-option-advice')}
                    </span>
                }
            </div>
        }
        return '';
    }

    getDiscountNoticeLabel = _ => {
        if (this.getCurrentDiscountPercentage() && (this.props.productData.modelli.length === 0 || this.state.modelloScelto)) {
            return <span className={'small mr-2'}>
                <Trans i18nKey={"ecommerce.price-discounted-notice"}>
                    invece di <span className={"text-primary text-line-through"}>{this.getCurrentPrice(true)}</span>
                </Trans>
            </span>
        }
        return '';
    }

    renderFavourite = (isPreferito, onToggle) => {
        return (
            <div
                className={"text-white shadow-sm d-flex align-items-center justify-content-center"}
                style={FavouriteButtonStyle} onClick={onToggle}>
                {isPreferito ? <FaSolidIcon name={"heart"}/> : <FaRegularIcon name={"heart"}/>}
            </div>
        )
    }

    toggleProfiloAttivita = _ => this.setState({profiloAttivitaOpen: !this.state.profiloAttivitaOpen});

    handleImageZoom = originalUrl => _ => {
        this.setState({zoomedImage: originalUrl});
    }
    toggleZoomedImage = _ => this.setState({zoomedImage: ''})

    render() {
        const negozioIconUrl = `${AppConfig.webServerUrl}/api/negozio/${this.props.productData.id_negozio}/logoIcon/100`
        const isPacchetto = this.props.productData.ecommerce_type === ECOMMERCE_PRODUCT_TYPE_PACCHETTO;
        const carouselItems = [this.props.productData.immagine, ...this.props.productData.immagini]
            .map((src, i) =>
                <ProductImage
                    className={`border-bottom border-top ${i === 0 && !this.props.productData.video_url ? "" : "__disable-swipe"}`}
                    src={getCompressedImageUrl(src, ProductImageSize.preview.w, ProductImageSize.preview.h)}
                    onClick={this.handleImageZoom(src)}
                />
            );

        if (this.props.productData.video_url) {
            if (this.props.productData.video_url.indexOf('youtu') > -1) {
                carouselItems.splice(
                    0, 0,
                    <div className={`border-bottom border-top w-100`}>
                        <YoutubeEmbed url={this.props.productData.video_url} style={EmbedVideoStyle}/>
                    </div>
                )
            } else if (this.props.productData.video_url.indexOf('vimeo') > -1) {
                carouselItems.splice(
                    0, 0,
                    <div className={`border-bottom border-top w-100`}>
                        <VimeoEmbed url={this.props.productData.video_url} style={EmbedVideoStyle}/>
                    </div>
                )
            }

        }

        return (
            <Fragment>
                {parseInt(this.props.productData.vietato_minori) === 1 && <VietatoMinoriIcon/>}
                <div className={"container my-3"}>
                    <h6 className={"font-weight-bold text-on-bg-main"}>{this.props.productData.nome}</h6>
                </div>

                {/* Banner attività */}
                {
                    !!this.props.productData.nome_negozio &&
                    <div className={"container py-3 mt-n3"} onClick={this.toggleProfiloAttivita}>
                        <div className={"d-flex align-items-center justify-content-between text-muted"}>
                            <div className={"d-flex align-items-center"}>
                                <AppIconThumbnail size={"35px"} src={negozioIconUrl} className={"mr-2"}/>
                                <h6 className={"mb-0"}>
                                    <small>{i18n.t('ecommerce.sold-by-label')}<br/></small>
                                    <SafeHtmlContainer html={this.props.productData.nome_negozio}/>
                                </h6>
                            </div>
                            <FaSolidIcon name={"chevron-right"}/>
                        </div>
                    </div>
                }

                <div className={"position-relative"}>
                    <FuxCarousel
                        className={"__disable-swipe"}
                        items={carouselItems}
                        hideIndicators={true}
                    />
                    <EcommerceShareButton
                        productData={this.props.productData}
                        buttonClassName={"text-white shadow-sm d-flex align-items-center justify-content-center"}
                        buttonStyle={ShareButtonStyle}
                    >
                        <FaSolidIcon name={"share"}/>
                    </EcommerceShareButton>
                    {
                        AuthAPI.currentUser &&
                        <EcommerceProductFavouriteStatusHOC
                            initialStatus={this.props.productData.isPreferito}
                            idProdotto={this.props.productData.id_prodotto}
                            render={this.renderFavourite}
                        />
                    }
                    <EcommerceImageGalleryButton productData={this.props.productData}/>
                </div>

                <div className={"container"}>{this.getDiscountLabel()}</div>

                <div className={"mw-100 overflow-hidden container"}>
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <div className={"h5 mb-0"}>
                            <small
                                className={"text-on-bg-main"}>{i18n.t('ecommerce.price-label')}:</small>&nbsp;<b
                            className={"text-primary mr-2"}>{this.getCurrentPrice()}</b>
                        </div>
                        {
                            !!this.props.productData.marca &&
                            <div
                                className={"bg-main-z2 shadow-sm pl-2 py-2 pr-5 mr-n5 rounded-pill d-flex align-items-center"}>
                                <div style={BrandIconContainerStyle}
                                     className={"bg-primary text-on-bg-primary rounded-circle mr-2 d-flex align-items-center justify-content-center"}>
                                    <FaSolidIcon name={"fingerprint"}/>
                                </div>
                                <span className={"text-primary"}>
                                        <SafeHtmlContainer html={this.props.productData.marca}/>
                                    </span>
                            </div>
                        }
                    </div>
                </div>


                <div className={"container my-3"}>

                    <div className={"h5 d-flex align-items-center flex-wrap"}>
                        {this.getDiscountNoticeLabel()}
                        <span className={"small text-muted"}>
                                <small>
                                    ({i18n.t(NegozioOpenStore.infoNegozio?.impostazioni.ordini_prezzi_iva_esclusa == 1 ? 'ecommerce.tax-excluded-label' : 'ecommerce.tax-included-label')})
                                </small>
                            </span>
                    </div>

                    {
                        (!parseInt(this.props.productData.preventivo) && !!parseInt(this.props.productData.abilita_spedizione) && this.state.expectedShipping !== null) &&
                        <div className={"text-muted small mb-2"}>
                            {
                                this.state.expectedShipping > 0 ?
                                    <span>
                                            {i18n.t('ecommerce.expected-shipping-template', {shipping: this.state.expectedShipping.toFixed(2)})}
                                        </span>
                                    :
                                    <span className={"text-primary"}>
                                            {i18n.t('ecommerce.free-shipping-label')}
                                        </span>
                            }
                        </div>
                    }

                </div>

                <div className={"container"}>
                    {
                        (this.props.productData.abilita_spedizione === "1" && this.props.productData.abilita_ritiro === "0") &&
                        <span className={"badge badge-warning"}>{i18n.t('ecommerce.only-delivery-advice')}</span>
                    }
                    {
                        (this.props.productData.abilita_ritiro === "1" && this.props.productData.abilita_spedizione === "0") &&
                        <span className={"badge badge-info"}>{i18n.t('ecommerce.only-takeaway-advice')}</span>
                    }

                    {
                        {
                            [ECOMMERCE_PRODUCT_TYPE_PACCHETTO_SMART]:
                                <EcommerceProductDirectBuyView
                                    productData={this.props.productData}
                                    onAppOpen={this.props.onOpenApp}
                                />,
                            [ECOMMERCE_PRODUCT_TYPE_PACCHETTO]:
                                <EcommerceProductDirectBuyView
                                    productData={this.props.productData}
                                    onAppOpen={this.props.onOpenApp}
                                />,
                            'default':
                                <EcommerceProductAddToCartView
                                    productData={this.props.productData}
                                    onModelloChange={this.handleModelloChange}
                                    onAddToCart={this.props.onAddToCart}
                                    onAppOpen={this.props.onOpenApp}
                                />
                        }[this.props.productData.ecommerce_type || 'default']
                    }

                    <EcommerceProductDescriptionView productData={this.props.productData}/>

                    {
                        this.props.showSameCategoryProducts &&
                        <EcommerceProductSuggestionView
                            suggestionType={PRODUCT_SUGGEST_SAME_CATEGORY}
                            onAddToCart={this.props.onAddToCart}
                            productData={this.props.productData}
                            heading={i18n.t('ecommerce.suggested-products-headline')}
                            showOwnerIcon={!!this.props.productData.nome_negozio}
                            showFavouriteIcon={true}
                        />
                    }

                    {
                        (this.props.showSameBrandProducts && !!this.props.productData.marca) &&
                        <EcommerceProductSuggestionView
                            suggestionType={PRODUCT_SUGGEST_SAME_BRAND}
                            onAddToCart={this.props.onAddToCart}
                            productData={this.props.productData}
                            heading={i18n.t('ecommerce.same-category-products-headline', {brand: this.props.productData.marca})}
                            showOwnerIcon={!!this.props.productData.nome_negozio}
                            showFavouriteIcon={true}
                        />
                    }

                </div>

                <ModalPortalHOC>
                    {
                        this.state.zoomedImage &&
                        <ImageZoomPanel onDismiss={this.toggleZoomedImage} src={this.state.zoomedImage}/>
                    }
                    {
                        this.state.profiloAttivitaOpen &&
                        <EcommerceProductViewProfiloAttivita
                            idNegozio={this.props.productData.id_negozio}
                            onDismiss={this.toggleProfiloAttivita}
                        />
                    }
                </ModalPortalHOC>

            </Fragment>
        )
    }

}

EcommerceProductView.propTypes = {
    productData: PropTypes.object,
    onAddToCart: PropTypes.func,
    onProductOpen: PropTypes.func,
    onOpenApp: PropTypes.func,
    showExpectedShipping: PropTypes.bool,
    showSameBrandProducts: PropTypes.bool,
    showSameCategoryProducts: PropTypes.bool,
}

const ShareButtonStyle = {
    position: 'absolute',
    top: 25,
    right: 20,
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.5)'
};

const FavouriteButtonStyle = {
    position: 'absolute',
    top: 80,
    right: 20,
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.5)'
};

const BrandIconContainerStyle = {
    width: 32, height: 32
}

const ProductImage = styled.div`
    width: 100%;
    padding-top: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("${props => props.src}");
`;
