import React from "react";
import PropTypes from "prop-types";
import AppConfig from "../config/AppConfig";
import {AppIconThumbnail} from "../components/BaseComponents/AppIconThumbnail";
import {EllipsisLoader} from "../components/GenericUI/EllipsisLoader/EllipsisLoader";


export class BookizonAppLoaderSplashscreen extends React.Component {
    constructor(props) {
        super(props);
        this.containerStyle = {
            height: '100vh',
            backgroundColor: 'rgba(248,249,250,0.8)',
            position: 'fixed',
            top:0, left:0, right:0, bottom:0, zIndex:9000
        }
    }

    render() {
        return (
            <div style={this.containerStyle} className={"d-flex align-items-center justify-content-center"}>
                <div>
                    <AppIconThumbnail
                        className={"shadow"}
                        src={`${AppConfig.webServerUrl}/api/negozio/${this.props.id_negozio}/logoIcon/100`}
                        size={'75px'}
                    />
                    <div>
                        <EllipsisLoader/>
                    </div>
                </div>
            </div>
        )
    }

}


BookizonAppLoaderSplashscreen.propTypes = {
    id_negozio: PropTypes.any
}
