import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../FontAwesomeIcons";
import styled from "styled-components";
import ProfiloAttivitaContainer from "./ProfiloAttivitaContainer";
import {SwipeablePanel} from "../BaseComponents/SwipeablePanel/SwipeablePanel";
import i18n from "../../i18n";

const InfoBackdrop = styled.div`
        position:fixed;
        top:0; left:0; height:100vh; width:100vh;
        z-index:2000; background-color:rgba(0,0,0,0.7);
`;

const InfoWrapper = styled.div`
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
    min-height:80vh;
    max-height:80vh;
    width:100%;
    scrollbar-width: none;
    overflow-y:auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
        display:none;
    }
`;

export default class ProfiloAttivitaPanel extends React.Component {

    constructor(props) {
        super(props);
        this.ref = null;
        this.hiding = false;

        this.hasRemovedScrollToBody = false;
        this.lastDocumentScrollPos = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.expanded && this.props.expanded) {
            this.handleRemoveBodyScroll();
        }
        if (prevProps.expanded && !this.props.expanded) {
            this.handleRestoreBodyScroll();
        }
    }

    handleRemoveBodyScroll = _ => {
        this.hasRemovedScrollToBody = document.body.className.indexOf("noscroll") === -1;
        if (this.hasRemovedScrollToBody) {
            this.lastDocumentScrollPos = document.documentElement.scrollTop;
            document.documentElement.scrollTop = 0;
            //console.log("Page add noscroll", this.props.title);
        }
        document.body.classList.add('noscroll');
    }

    handleRestoreBodyScroll = _ => {
        //Rimuovo il "noscroll" solo se sono stato io ad aggiungerlo
        if (this.hasRemovedScrollToBody) {
            document.body.classList.remove('noscroll');
            if (this.lastDocumentScrollPos !== null) {
                document.documentElement.scrollTop = this.lastDocumentScrollPos;
                this.lastDocumentScrollPos = null;
            }
            //console.log("Page remove noscroll", this.props.title);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.expanded !== this.props.expanded ||
            nextProps.negozio !== this.props.negozio;
    }


    getWrapperRef = node => {
        this.ref = node;
    }


    render() {

        return (
            <Fragment>
                {this.props.expanded && <InfoBackdrop className={"__androidDismissable__"} onClick={this.props.onToggle}/>}

                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={!!this.props.expanded}
                    onDismiss={this.props.onToggle}
                    disableSwipe={_ => !this.ref || this.ref.scrollTop > 0}
                    animationSpeed={150}
                >
                    <InfoWrapper
                        ref={this.getWrapperRef}
                        className={"bg-main-z2"}
                    >
                        <div className={"text-center text-on-bg-main small"}>
                            <FaSolidIcon name={"grip-lines"}/><br/>
                            <b>{i18n.t('business-info.title')}</b>
                        </div>
                        {
                            !!this.props.expanded && <ProfiloAttivitaContainer
                                isAppAdded={this.props.isAppAdded}
                                negozio={this.props.negozio}
                                primaryColor={this.props.primaryColor}
                                backgroundColor={this.props.backgroundColor}
                                hideButtonsGroup={this.props.hideButtonsGroup}
                            />
                        }
                    </InfoWrapper>
                </SwipeablePanel>
            </Fragment>
        )

    }

}

ProfiloAttivitaContainer.propTypes = {
    isAppAdded: PropTypes.bool,
    onToggle: PropTypes.func,
    negozio: PropTypes.object,
    useNavbar: PropTypes.bool,
    hideButtonsGroup: PropTypes.bool,
};

ProfiloAttivitaContainer.defaultProps = {
    showAdd: false,
    showOpen: false,
    onToggle: _ => {
    },
    negozio: {},
    useNavbar: true
};



