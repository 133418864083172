import styled from "styled-components";
import AppConfig from "../../../config/AppConfig";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";
import PublicationInformation from "../PublicationInformation";
import React, {Fragment} from "react";
import moment from "moment";
import {getCompressedImageUrl} from "../../../helpers/ImageUtility";
import ProfiloAttivitaContainer from "../../../components/ProfiloAttivita/ProfiloAttivitaPanel";
import {swal, swalError, swalLoading} from "../../../helpers/SweetAlertWrapper";
import NegozioAPI from "../../../api/NegozioAPI";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {APP_FAVOURITE_ADDED} from "../../../const/AppEventsCostants";
import {FeedPostCardActionBar} from "./FeedPostCardActionBar";
import {replaceHashtag} from "../../../helpers/StringHelpers";
import ImageZoomPanel from "../../../components/BaseComponents/ImageZoomPanel";
import {PostImageSize} from "../../../const/ImageSizeCostants";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import {LazyImage} from "../../../helpers/ImageUtility/LazyImage";

const MAX_CAPTION_PREVIEW_LENGTH = 120;
const MAX_POST_IMAGE_PREVIEW_HEIGHT = window.innerWidth ? window.innerWidth / 4 * 5 : 500;


const PostCardHeader = styled.div`
    border-bottom: 0px;
    background-color: white;
`;

const TypeAndTimePost = styled.div`
    font-size: 13px;
    margin-top: -3px;
`;

const PostImageStyle = {
    position: 'absolute',
    zIndex: 1,
    top: "50%",
    transform: 'translateY(-50%)'
};

const BlurImageWrapperStyle = {
    position: 'relative',
    overflow: 'hidden',
    maxHeight: MAX_POST_IMAGE_PREVIEW_HEIGHT
};

const BlurImageSpinnerStyle = {
    opacity: 0.5,
    position: 'absolute',
    top: "50%", left: "50%",
    width: 80, height: 80,
    transform: 'translateX(-50%) translateY(-50%)'
};

const TapToExpandBtnStyle = {
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    position: 'absolute',
    zIndex: 1,
    bottom: 10,
    right: 10,
    borderRadius: 500
}


export default class FeedPostBaseInfo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            OpenPublicationInformation: false,
            profiloNegozioOpen: null,
            fullDescription: false,
            imageZoom: false,
            imageLoaded: false,
            isImageOverflowing: false
        }
        this.previewImageRef = React.createRef();
    }

    getCalendarTimestamp = datetime => {
        return moment(datetime).calendar(null, {
            sameDay: '[oggi alle] HH:mm',
            nextDay: '[domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[ieri alle] HH:mm',
            lastWeek: '[lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });
    }

    getTypePostIcon = tipo => {
        switch (tipo.toLowerCase()) {
            case 'offerta':
                return <span><FaSolidIcon name={"tags"}/> Offerta - </span>
            case 'evento':
                return <span><FaSolidIcon name={"calendar-alt"}/> Evento - </span>
            default:
                return <span/>
        }
    }

    getEventDurationLabel = _ => {
        if (this.props.data_inizio && this.props.data_fine) {
            const mi = moment(this.props.data_inizio, 'YYYY-MM-DD').format('dddd DD MMMM');
            const mf = moment(this.props.data_fine, 'YYYY-MM-DD').format('dddd DD MMMM');
            if (mi === mf) {
                return mi;
            } else {
                return <span>Da <b>{mi}</b> a <b>{mf}</b></span>;
            }
        }
        return '';
    }

    getPlainTextDescription = _ => {
        let str = this.props.testo.toString();
        /* Sostituisco tag chiusura paragrafo con spazio, altrimenti i due paragrafi verranno
        stampati senza spazio fra loro*/
        str = str.replace(/<\/p>/g, ' ');
        str = str.replace(/<br>/g, ' ');
        str = str.replace(/<br\/>/g, ' ');
        /*rimuovo tag html per la preview*/
        str = str.replace(/<[^>]*>/g, '');
        return str;
    }

    //Restituisce il testo troncato "SMART" in base a MAX_PREVIWE_LENGTH
    getTruncatedDescription = _ => {
        let str = this.getPlainTextDescription();
        var i;
        for (i = MAX_CAPTION_PREVIEW_LENGTH; str.length >= i; i++) {
            if (str[i] === " " || str.length === i || str[i] === "&") {
                return replaceHashtag(str.substring(0, i), '<span class="text-facebook">{{hashtag}}</span>', true);
            }
        }
    }

    //Mi dice se il testo va in overflow rispetto al massimo consentito
    isTextOverflowing = _ => {
        return this.getPlainTextDescription().length > MAX_CAPTION_PREVIEW_LENGTH;
    }

    toggleFullDescription = _ => this.setState({fullDescription: !this.state.fullDescription});
    toggleImageZoom = _ => this.setState({imageZoom: !this.state.imageZoom});

    handleOpenPublicationInformation = _ => {
        this.setState({OpenPublicationInformation: !this.state.OpenPublicationInformation})
    }

    handleProfiloNegozioOpen = _ => {
        swalLoading("Attendere prego...");
        NegozioAPI.getInfo(this.props.id_negozio)
            .then(negozio => {
                swal.safeClose();
                FuxEvents.on(APP_FAVOURITE_ADDED, this.updateWasAdded);
                this.setState({profiloNegozioOpen: negozio})
                if (this.props.onProfiloAttivitaOpen) this.props.onProfiloAttivitaOpen();
            })
            .catch(swalError);
    }

    handleProfiloNegozioDismiss = _ => {
        FuxEvents.off(APP_FAVOURITE_ADDED, this.updateWasAdded);
        this.setState({profiloNegozioOpen: null});
        if (this.props.onProfiloAttivitaClose) this.props.onProfiloAttivitaClose();
    }

    updateWasAdded = idNegozio => {
        if (this.state.profiloNegozioOpen) {
            if (this.state.profiloNegozioOpen.id_negozio === idNegozio) {
                this.setState({
                    profiloNegozioOpen: {...this.state.profiloNegozioOpen, wasAdded: true}
                });
            }
        }
    }

    handleImageLoaded = _ => {
        this.setState({isImageOverflowing: this.checkImageOverflowing()})
        //Dopo 300ms nascondo l'immagine blur
        setTimeout(_ => this.setState({imageLoaded: true}), 1000);
    }

    checkImageOverflowing = _ => {
        if (this.previewImageRef.current) {
            return this.previewImageRef.current.offsetHeight > MAX_POST_IMAGE_PREVIEW_HEIGHT;
        }
        return false;
    }


    render() {

        const imageUrl = this.props.immagine ? (this.props.immagine.indexOf('http') !== -1 ? this.props.immagine : `${AppConfig.webServerUrl}/assets/posts/${this.props.id_post}/${this.props.immagine}`) : '';

        return (
            <div style={this.props.style}>
                <div>
                    {
                        imageUrl &&
                        <Fragment>
                            {/* Blurred image */}
                            <div style={BlurImageWrapperStyle}>
                                <LazyImage
                                    className={"card-img-top border-0 rounded-0 img-fluid"}
                                    style={PostImageStyle}
                                    onClick={this.toggleImageZoom}
                                    src={getCompressedImageUrl(imageUrl, PostImageSize.preview.w, PostImageSize.preview.h)}
                                    loadOnView={true}
                                    scrollTop={this.props.scrollTop}
                                    keepFallbackVisible={true}
                                    onLoad={this.handleImageLoaded}
                                    fallback={
                                        React.forwardRef((props, ref) => (
                                            <div ref={ref} {...props}/>
                                        ))
                                    }
                                />

                                <img
                                    ref={this.previewImageRef}
                                    alt={"post image preview"}
                                    className={"card-img-top border-0 rounded-0 img-fluid " + (this.state.imageLoaded ? "opacity-0" : "img-blur")}
                                    src={getCompressedImageUrl(imageUrl, PostImageSize.blur.w, PostImageSize.blur.h)}
                                />

                                <div className={"d-flex align-items-center justify-content-center"}
                                     style={BlurImageSpinnerStyle}>
                                    <FaSolidIcon name={"circle-notch"} size={3} className={"fa-spin"}/>
                                </div>

                                {
                                    this.state.isImageOverflowing &&
                                    <div className={"small py-1 px-2 shadow"} style={TapToExpandBtnStyle}
                                         onClick={this.toggleImageZoom}>
                                        <FaSolidIcon name={"expand"}/> Premi per espandere
                                    </div>
                                }

                            </div>
                        </Fragment>
                    }


                    <PostCardHeader className={"card-header"}>
                        <div className={"mb-3"}>
                            <h5 className={"card-title font-weight-bold mb-0"}>
                                <SafeHtmlContainer html={this.props.titolo}/>
                            </h5>
                            <div className={"small text-muted"}>
                                {this.getEventDurationLabel()}
                            </div>
                        </div>
                        <div className={"d-flex"}>
                            <div className={"mr-3"}>
                                <AppIconThumbnail
                                    size={'45px'}
                                    onClick={this.handleProfiloNegozioOpen}
                                    src={`${AppConfig.webServerUrl}/api/negozio/${this.props.id_negozio}/logoIcon/100`}
                                />
                            </div>
                            <div>
                                <b onClick={this.handleProfiloNegozioOpen}>
                                    <SafeHtmlContainer html={this.props.nome_negozio}/>
                                </b>
                                <TypeAndTimePost className={"text-muted"}>
                                    {this.getTypePostIcon(this.props.tipo)} Pubblicato {this.getCalendarTimestamp(this.props.data_pubblicazione)}
                                </TypeAndTimePost>
                            </div>
                        </div>
                    </PostCardHeader>

                    {
                        (this.props.testo || this.props.link) &&
                        <div className={"card-body border-top bg-white"}>
                            <div className={"card-text"} onClick={this.toggleFullDescription}>
                                {
                                    this.isTextOverflowing() ?
                                        this.state.fullDescription ?
                                            <Fragment>
                                                <SafeHtmlContainer
                                                    html={replaceHashtag(this.props.testo, '<span class="text-facebook">{{hashtag}}</span>', true)}/>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <SafeHtmlContainer html={this.getTruncatedDescription()}/>
                                                <span className={"text-muted"}>... Leggi altro</span>
                                            </Fragment>
                                        :
                                        <SafeHtmlContainer html={this.props.testo}/>
                                }
                            </div>
                            {
                                this.props.link &&
                                <a className={"btn btn-primary text-on-bg-primary float-right btn-sm mt-2"}
                                   href={this.props.link}>
                                    <FaSolidIcon name={"external-link-alt"}/> Vai al sito
                                </a>
                            }
                        </div>
                    }
                </div>


                <ModalPortal>
                    <PublicationInformation
                        active={this.state.OpenPublicationInformation}
                        onClose={this.handleOpenPublicationInformation}
                        infoPost={this.props}
                    />

                    {
                        !!this.state.profiloNegozioOpen &&
                        <ProfiloAttivitaContainer
                            negozio={this.state.profiloNegozioOpen}
                            primaryColor={"#293069"}
                            backgroundColor={"#ffffff"}
                            onAppAdd={this.props.onAppAdd}
                            onAppOpen={this.props.onAppOpen}
                            isAppAdded={!!this.state.profiloNegozioOpen.wasAdded}
                            expanded={true}
                            useNavbar={false}
                            onToggle={this.handleProfiloNegozioDismiss}
                        />

                    }
                    {
                        this.state.imageZoom &&
                        <ImageZoomPanel onDismiss={this.toggleImageZoom}
                                        src={getCompressedImageUrl(imageUrl, PostImageSize.full.w, PostImageSize.full.h)}/>
                    }
                </ModalPortal>

            </div>
        )
    }
}
