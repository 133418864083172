import React from "react";
import PropTypes from "prop-types";
import "../../../css/ribbon.css";
import i18n from "../../../i18n";

const ImageStyle = {
    height: 60
};

export class MacroCategoryItem extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClick = _ => {
        if (this.props.categoria.disabled) return;
        this.props.onClick(this.props.categoria);
    }

    render() {
        const labelStyle = {color: this.props.categoria.color};
        const Image = this.props.categoria.image;
        return (
            <div className={"col-6 py-2 " + (this.props.i % 2 === 0 ? "pr-2" : "pl-2")}
                 onClick={this.handleClick}>
                <div className={"bg-white shadow-sm position-relative overflow-hidden py-3 h-100 rounded"}>
                    {
                        this.props.categoria.disabled &&
                        <div className={"corner-ribbon top-right blue small"}>
                            {i18n.t('generic.coming-soon')}
                        </div>
                    }
                    <div>
                        <Image style={ImageStyle} className={"img-fluid mx-auto d-block"}
                               color={this.props.categoria.color}/>
                    </div>
                    <br/>
                    <div className={"text-center small font-weight-bold"}
                         style={labelStyle}>{this.props.categoria.nome}</div>
                </div>
            </div>
        )
    }
}


MacroCategoryItem.propTypes = {
    categoria: PropTypes.object.isRequired,
    i: PropTypes.number.isRequired,
    onClick: PropTypes.func
}
