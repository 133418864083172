import React from "react";
import NotificationSystem from "react-notification-system";

/**
 * Questo file agisce da wrapper per consentire l'aggiunta di una notifica di sistema tramite chiamata a funzione.
 * E' necessario inserire il FuxNotificationSystemProvider all'interno dell'albero dei componenti più in cima possibile.
 * Per aggiungere una notifica basta richiamare FuxNotificationSystem.fire(data), dove "data" è un object le cui chiavi
 * sono recuperabili a questo link https://github.com/igorprado/react-notification-system
 * */


export const FuxNotificationSystem = {
    ref: React.createRef(),
    fire: function (data) {
        if (FuxNotificationSystem.ref) {
            const notification = FuxNotificationSystem.ref.current;
            notification.addNotification(data);
        }
    }
}

export default class FuxNotificationSystemProvider extends React.Component {

    constructor(props) {
        super(props);
    }
    render(){
        return(
            <NotificationSystem ref={FuxNotificationSystem.ref} />
        )
    }

}
