import axios from 'axios';
import '../../polyfill/Array.js';
import {getCurrentLanguage} from "../../langUtils";

const FuxHTTP = {
    RESOLVE_DATA: 1,
    RESOLVE_MESSAGE: 2,
    RESOLVE_RESPONSE: 3,
    REJECT_DATA: 4,
    REJECT_MESSAGE: 5,
    REJECT_RESPONSE: 6,
    STATUS_SUCCESS: 'OK',
    apiRequestWithPromise: function (method, url, params, resolveMode, rejectMode, axiosParams) {

        axios.defaults.headers.common['Accept-Language'] = `${getCurrentLanguage('it')}`;

        method = method.toUpperCase();
        let http = null;
        switch (method) {
            case 'GET':
                http = axios.get;
                break;
            case 'POST':
                http = axios.post;
                break;
            default:
                http = axios.get;
                break;
        }
        return new Promise(function (resolve, reject) {
                if (method === 'GET' && params) {
                    const urlObj = new URL(url);
                    for (let key in params) {
                        if (params.hasOwnProperty(key)) {
                            if (params[key] !== null) {
                                if (Array.isArray(params[key])) {
                                    for (let i in params[key]) {
                                        if (params[key].hasOwnProperty(i)) {
                                            urlObj.searchParams.append(key, params[key][i])
                                        }
                                    }
                                } else {
                                    urlObj.searchParams.append(key, params[key]);
                                }
                            }
                        }
                    }
                    params = null;
                    url = urlObj.href;
                }
                http(url, method === 'POST' ? params : axiosParams, method === 'POST' ? axiosParams : undefined)
                    .then(function (response) {
                        const jsonResponse = response.data;
                        if (jsonResponse.status === FuxHTTP.STATUS_SUCCESS) {
                            switch (resolveMode) {
                                case FuxHTTP.RESOLVE_DATA:
                                    resolve(jsonResponse.data);
                                    break;
                                case FuxHTTP.RESOLVE_MESSAGE:
                                    resolve(jsonResponse.message);
                                    break;
                                case FuxHTTP.RESOLVE_RESPONSE:
                                    resolve(jsonResponse);
                                    break;
                            }
                        } else {
                            switch (rejectMode) {
                                case FuxHTTP.REJECT_DATA:
                                    reject(jsonResponse.data);
                                    break;
                                case FuxHTTP.REJECT_MESSAGE:
                                    reject(jsonResponse.message);
                                    break;
                                case FuxHTTP.REJECT_RESPONSE:
                                    reject(jsonResponse);
                                    break;
                            }
                        }
                    })
                    .catch(function (data) {
                        reject(null);
                    });
            }
        );
    },
    get: function (url, params, resolveMode, rejectMode, axiosParams) {
        return FuxHTTP.apiRequestWithPromise('GET', url, params, resolveMode, rejectMode, axiosParams);
    },
    post: function (url, params, resolveMode, rejectMode, axiosParams) {
        return FuxHTTP.apiRequestWithPromise('POST', url, params, resolveMode, rejectMode, axiosParams);
    },
    getJson: function (url, params) {
        return new Promise((resolve, reject) => {
            fetch(`${url}?` + new URLSearchParams(params || {}))
                .then(r => r.json())
                .then(json => {
                    resolve(json);
                })
                .catch(reject)
        });
    },
    apiGetRequestWithPromise: (url, params, resolveMode, rejectMode, axiosParams) => FuxHTTP.get(url, params, resolveMode, rejectMode, axiosParams),
    apiPostRequestWithPromise: (url, params, resolveMode, rejectMode, axiosParams) => FuxHTTP.post(url, params, resolveMode, rejectMode, axiosParams),
};

export default FuxHTTP;
