import React, {Fragment} from "react";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import Page from "../../../components/BaseComponents/Page/Page";
import Pagination from "../../../components/BaseComponents/Pagination/Pagination";
import RimborsiDipendenteAPI from "../../../api/Welfare/RimborsiDipendenteAPI";
import DipendenteWelfareSingleTransaction from "../TransazioniWallet/DipendenteWelfareSingleTransaction";
import DipendenteWelfareSingleRichiestaRimborso from "./DipendenteWelfareSingleRichiestaRimborso";

export default class DipendenteWelfareStoricoRichiestaRimborso extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            richiesteLoaded: false,
            richieste: [],
            totalRichieste: 0,
            pageSize: 10,
            currentPage: 1
        }
    }

    fetchData = (limit, offset) => {
        RimborsiDipendenteAPI.getRichiesteRimborso(this.props.user.walletapikey, limit, offset)
            .then((data) => {
                console.log(data);
                this.setState({richieste: data.richiesteRimborso, totalRichieste: parseInt(data.total), richiesteLoaded: true});
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.active && this.props.active) {
            this.setState({filterOpen: 0});
            this.fetchData(this.state.pageSize,
                (this.state.currentPage - 1) * this.state.pageSize);
        }
    }

    handlePageChange = page => {
        this.setState({currentPage: page});
        this.fetchData(this.state.pageSize,
            (page - 1) * this.state.pageSize);
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Storico richieste rimborso"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div>
                            {
                                this.state.richiesteLoaded ?

                                <div>
                                    {
                                        this.state.richieste.length ?
                                            <div>
                                                {
                                                    this.state.richieste.map((item, i) => {
                                                        return <div key={i} className={"list-group"}>
                                                            {item.id_rimborso &&
                                                            <DipendenteWelfareSingleRichiestaRimborso
                                                                active={this.props.active}
                                                                user={this.props.user}
                                                                dipendente={this.props.dipendente}
                                                                richiestaRimborso={item}
                                                            />
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            :
                                            <div className={"container"}>
                                                <div className={"mt-3 text-center text-on-bg-main"}>
                                                    Non ci sono richieste di rimborso
                                                </div>
                                            </div>
                                    }

                                </div>

                                :

                                <div className={"container text-center lead mt-4"}>
                                    <i className={"fas fa-spinner fa-spin"}/>
                                </div>
                            }

                            <div className={"justify-content-center d-flex pt-3"}>
                                {
                                    this.state.richieste.length ?
                                        <Pagination
                                            activePage={this.state.currentPage}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalRichieste}
                                            pageRangeDisplayed={5}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            onChange={this.handlePageChange}
                                        /> :
                                        ''

                                }
                            </div>
                        </div>
                    }
                />
            </Fragment>

        );
    }
}