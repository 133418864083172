import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../BaseComponents/SwipeablePanel/SwipeablePanel";
import AppModalPortal from "../../BaseComponents/AppModalPortal";
import ModalPortal from "../../BaseComponents/ModalPortal";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {SwipeablePanelGripBar} from "../../BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import shareIcon from "../../../img/illustrations/shareIcon.svg";
import FuxUtility from "../../../lib/FuxFramework/FuxUtility";
import AppConfig from "../../../config/AppConfig";
import {FuxNotificationSystem} from "../../GenericUI/FuxNotificationSystem/FuxNotificationSystem";
import {BookizonAppManager} from "../../../index";
import i18n from "i18next";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class EcommerceShareButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    toggleMenu = _ => this.setState({menuOpen: !this.state.menuOpen});

    getShareLink = _ => {
        return `${AppConfig.webServerUrl}/dl/@product:${this.props.productData.id_prodotto}`;
    }

    handleCopyLink = _ => {
        FuxUtility.copyToClipboard(this.getShareLink())
            .then(_ => {
                FuxNotificationSystem.fire({
                    title: "Link copiato negli appunti",
                    //message: ``,
                    position: 'tc', //Top center
                    level: 'success',
                    autoDismiss: 2
                });
            })
    }

    handleShare = _ => {
        const message = `Guarda cosa ho trovato su Bookizon! Clicca questo link: ${this.getShareLink()}`;
        BookizonAppManager.shareData(message);
    }

    isSwipeDisabled = _ => false;

    render() {
        const Portal = document.getElementById('app-modal-root') ? AppModalPortal : ModalPortal;
        return (
            <Fragment>
                <div onClick={this.toggleMenu} style={this.props.buttonStyle} className={this.props.buttonClassName}>
                    {this.props.children}
                </div>
                <Portal>
                    {this.state.menuOpen && <div style={BackdropStyle}/>}
                    <SwipeablePanel
                        swipeTreshold={5}
                        direction={'top-to-bottom'}
                        active={this.state.menuOpen}
                        onDismiss={this.toggleMenu}
                        disableSwipe={this.isSwipeDisabled}
                        animationSpeed={150}
                    >
                        <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                        <div className={"bg-main-z2 py-5"}>
                            <img src={shareIcon} alt={"share icon"} className={"d-block w-50 mx-auto my-4"}/>
                            <div className={"list-group list-group-flush"}>
                                <div className={"list-group-item d-flex align-items-center"} onClick={this.handleCopyLink}>
                                    <FaSolidIcon name={"copy"} className={"text-info mr-2"}/> {i18n.t('generic.copy-link-btn')}
                                </div>
                                <div className={"list-group-item d-flex align-items-center border-bottom"} onClick={this.handleShare}>
                                    <FaSolidIcon name={"share"} className={"text-warning mr-2"}/> {i18n.t('generic.share-on-other-apps-btn')}
                                </div>
                            </div>
                        </div>
                    </SwipeablePanel>
                </Portal>
            </Fragment>
        )
    }

}


EcommerceShareButton.propTypes = {
    productData: PropTypes.object,
    buttonClassName: PropTypes.string,
    buttonStyle: PropTypes.object
}
