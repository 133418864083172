import Store from "../components/Store";
import FuxEvents from "../lib/FuxFramework/FuxEvents";


export const NM_GEOLOCATION_UPDATE = 'geolocation.update';
export const NM_GEOLOCATION_PERMISSION = 'geolocation.permission';
export const NM_IN_APP_BROWSER_DISMISS = 'inappbrowser.dismiss';
export const NM_EVALUATE_JS = 'eval';
export const NM_FILEPICKER_RESULT = 'filepicker.result';
export const NM_FILEPICKER_RESULT_END = 'filepicker.result-end';
export const NM_FILEPICKER_DISMISS = 'filepicker.dismiss';
export const NM_NOTIFICATION_FOREGROUND = 'notification.foreground';
export const NM_FIREBASE_RETRIEVE_FCM_TOKEN = 'firebase.retrieve_fcm_token_response';

//ANDROID ONLY
export const NM_ANDROID_ON_RESUME = 'android.onResume';

//iOS ONLY
export const NM_IOS_ENTER_FOREGROUND = 'ios.enterForeground';

export const NativeMessageHandler = function (_message) {
    let message = {};
    if (typeof _message === 'string') {
        message = JSON.parse(_message);
    } else {
        message = {..._message};
    }
    console.log(`NATIVE MESSAGE: ${JSON.stringify(message)}`);
    switch (message.action) {
        case NM_GEOLOCATION_UPDATE:
            Store.setState({
                native: {
                    ...Store.state.native,
                    lat: message.lat,
                    lng: message.lon
                }
            });
            //console.log("POSIZIONE");
            //console.log(`LAT: ${message.lat} / LON: ${message.lon}`);
            FuxEvents.emit(NM_GEOLOCATION_UPDATE, message);
            break;
        case NM_GEOLOCATION_PERMISSION:
            Store.setState({
                native: {
                    ...Store.state.native,
                    hasGeolocationPermission: message.permission,
                }
            });
            FuxEvents.emit(NM_GEOLOCATION_PERMISSION, message)
            //console.log(`Geolocation permission update ${message.permission ? 'SI' : 'NO'}`);
            break;
        case NM_EVALUATE_JS:
            if (message.code) {
                eval(message.code);
            }
            break;
        case NM_NOTIFICATION_FOREGROUND:
            FuxEvents.emit(NM_NOTIFICATION_FOREGROUND, message);
            break;
        case NM_FILEPICKER_RESULT:
            FuxEvents.emit(NM_FILEPICKER_RESULT, message);
            break;
        case NM_FILEPICKER_RESULT_END:
            FuxEvents.emit(NM_FILEPICKER_RESULT_END, message);
            break;
        case NM_FILEPICKER_DISMISS:
            FuxEvents.emit(NM_FILEPICKER_DISMISS);
            break;
        default:
            FuxEvents.emit(message.action, message);
            console.log(`NATIVE MESSAGE DEFAULT: ${JSON.stringify(message)}`);
            break;
    }
};
