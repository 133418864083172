import React,{Fragment} from "react";
import styled, {keyframes} from "styled-components";
import {slideInUp} from "react-animations";

const slideInUpAnimation = keyframes`${slideInUp}`;

const DetailPopoverContainer = styled.div`
    position:fixed;
    width:100vw; 
    height:85vh;
    bottom:0px; left:0px; z-index:3000;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    overflow:auto;
    visibility:hidden;
    
    &.active{
        visibility:visible;
        animation: .5s ${slideInUpAnimation};
        animation-fill-mode: forwards;
    }
`;

const DetailPopoverBackdrop = styled.div`
    &.active{
        position:fixed;
        top:0; left:0; height:100vh; width:100vh;
        z-index:3000; background-color:rgba(0,0,0,0.7);
    }
`;

const DetailPopoverImage = styled.div`
    width:100%;
    height:40vh;
    background-size:cover;
    background-position:center center;
    background-image:url(${props => props.src});
`;

export default class DetailPopover extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <DetailPopoverBackdrop onClick={this.props.onDismiss} className={this.props.active ? 'active __androidDismissable__' : ''}/>
                <DetailPopoverContainer className={"bg-main-z3 shadow-lg text-on-bg-main " + (this.props.active  ? 'active' : '')}>
                    {this.props.image && <DetailPopoverImage className={"img-card-top"} src={this.props.image}/>}
                    {this.props.active ? this.props.children : ''}
                </DetailPopoverContainer>
            </Fragment>
        )
    }

}
