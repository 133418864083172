import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";
import AppConfig from "../config/AppConfig";


const ExpoAPI = {
    lista: function (withInvisible) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/list`), {invisible: withInvisible ? 1 : 0},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listaNegozi: function (id_gruppo, seed, page, pageSize) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/negozi-list`), {
                id_gruppo:id_gruppo,
                seed: seed,
                page: page,
                limit: pageSize,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE
        );
    },
    searchProdotti: function (id_gruppo, query, offset, limit, orderType, cancelToken) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/search-prodotti`), {
                id_gruppo: id_gruppo,
                q: query,
                offset: offset,
                limit: limit,
                orderType: orderType,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE,
            {cancelToken: cancelToken}
        );
    },
    prodottiRandom: function (id_gruppo, seed, offset, limit) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/prodotti-random`), {
                id_gruppo: id_gruppo,
                seed: seed,
                offset: offset,
                limit: limit,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    itemProdotti: function (id_gruppo, id_item, seed, offset, limit) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/item-prodotti`), {
                id_gruppo: id_gruppo,
                id_item: id_item,
                seed: seed,
                offset: offset,
                limit: limit,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    itemNegozi: function (id_gruppo, id_item, query, offset, limit, modules) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/item-negozi`), {
                id_gruppo: id_gruppo,
                id_item: id_item,
                query: query,
                offset: offset,
                limit: limit,
                modules: modules,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    productsGallery: function (id_negozio, limit) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/product-gallery-negozi`), {
                id_negozio: id_negozio,
                limit: limit,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getItemModules: function (id_item) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/get-item-modules`), {
                id_item: id_item,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    gallery: function (id_gruppo, limit, offset) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/gruppi-negozi/gallery`), {
                id_gruppo: id_gruppo,
                limit: limit,
                offset: offset
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

}

export default ExpoAPI
