import {_Settings} from "./_Settings";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const AdminAPI = {
    Settings: _Settings,
    getPermissions: function () {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/admin/get-permissions`), {token: AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};