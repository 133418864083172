import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import styled from "styled-components";
import defaultProfilePicF from "../../../../img/profile-placeholder-f.png";
import defaultProfilePicM from "../../../../img/profile-placeholder-m.png";
import APIConfig from "../../../../api/APIConfig";
import AuthAPI from "../../../../api/AuthAPI";
import PostAPI from "../../../../api/PostAPI";
import {swalError} from "../../../../helpers/SweetAlertWrapper";
import FeedCommentItem from "./FeedCommentItem";
import moment from "moment";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {
    ANSWER_IS_LOADED,
    ANSWERING_A_COMMENT,
    CTA_GROUP_BOOK_SUCCESS,
    NEED_TO_REPLY_COMMENT
} from "../../../../const/AppEventsCostants";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import ReactDOM from "react-dom";


const ProfilePicThumbnail = styled.div`
    width:40px;
    height:40px;
    background-image: url('${props => props.src}');
    background-size:cover;
    background-position:center center;
    position:relative;
    overflow:hidden;
    border-radius:50%;
`;

const WrapperStyle = {
    height: 'calc(100vh - 95px)',
    width: "100%",
    paddingBottom: 65
}


export class FeedPostComments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profilePicPreview: null,
            commentsList: [],
            totalComments: 0,
            loadingData: false,
            limit: 10,
            offset: 0,
            comment: "",
            newCommentInPublishing: "",

            //Risposte commenti
            idCommentToReplay: null,
            userToReplay: null
        }
        this.loadingStyle = {
            transform: 'scale(0.6)'
        }
    }

    componentDidMount() {
        this.fetchData()
        FuxEvents.on(NEED_TO_REPLY_COMMENT, this.toggleReplayAComment);
    }

    componentWillUnmount() {
        FuxEvents.off(NEED_TO_REPLY_COMMENT, this.toggleReplayAComment);
    }


    fetchData = (postNewComment = false) => {
        this.setState({loadingData: true})
        //Se sto inserendo un nuovo commento, verrà postato come primo, quindi resetto l'offset
        var offset = (postNewComment) ? 0 : this.state.offset
        PostAPI.getPostComments(this.props.id_post, this.state.limit, offset)
            .then(data => {
                var comments = (postNewComment) ? data.comments : this.state.commentsList.concat(data.comments)
                this.setState({
                    commentsList: comments,
                    totalComments: data.total_comments,
                    offset: offset,
                    loadingData: false, newCommentInPublishing: "", idCommentToReplay: null, userToReplay: null
                })
            })
    }


    getProfilePicUrl = _ => {
        if (!AuthAPI.currentUser?.immagine) {
            return null;
        }
        return APIConfig.url(`/utenti/profile-pic/${AuthAPI.currentUser.accessToken}/400`)
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ?
            target.checked :
            target.type === 'file' ?
                target.files
                :
                target.value;
        console.log(value);
        const name = target.name;
        if (name === 'user') {
            value = value.toLowerCase();
        }
        this.setState({
            [name]: value,
        });
    }

    addNewComment = _ => {
        if (!this.state.comment) return;
        var comment = this.state.comment;
        if (this.state.idCommentToReplay) {
            FuxEvents.emit(ANSWERING_A_COMMENT, {
                "id_commento_risposto": this.state.idCommentToReplay,
                "commento": comment
            })
        }
        this.setState({newCommentInPublishing: comment, comment: ""}, _ => {
            PostAPI.addNewComment(this.props.id_post, this.state.newCommentInPublishing, this.state.idCommentToReplay)
                .then(response => {
                    //Se si tratta di una nuova risposta emetto il fux event altrimenti riscarico i commenti presenti subito sotto il post
                    if (this.state.idCommentToReplay) {
                        FuxEvents.emit(ANSWER_IS_LOADED, {
                            "id_commento_risposto": this.state.idCommentToReplay,
                            "commento": comment,
                            "id_commento": response.data.id_commento
                        })
                    } else {
                        this.fetchData(true)
                    }
                })
                .catch(message => swalError(message))
        })
    }

    toggleReplayAComment = (dataCommentToReplay = null) => {
        if (!this.state.idCommentToReplay) {
            return this.setState({
                idCommentToReplay: dataCommentToReplay.id_commento,
                userToReplay: dataCommentToReplay.nome_utente
            })
        }
        return this.setState({idCommentToReplay: null, userToReplay: null, newCommentInPublishing: null})
    }

    handleOtherComments = _ => {
        this.setState({offset: this.state.offset + 10}, _ => {
            this.fetchData()
        })
    }

    render() {
        return (
            <Fragment>
                <div className={"bg-white pb-5"}>
                    {
                        this.state.newCommentInPublishing && !this.state.idCommentToReplay &&
                        <div className={"p-2"}>
                            <FeedCommentItem
                                idUtente={AuthAPI.currentUser.id_utente}
                                nomeUtente={AuthAPI.currentUser.nome + " " + AuthAPI.currentUser.cognome}
                                immagineUtente={AuthAPI.currentUser.immagine || null}
                                commento={this.state.newCommentInPublishing}
                                dataCommento={moment()}
                                publishing={true}
                            />
                        </div>
                    }

                    {/*Caricamentp*/}
                    {this.state.loadingData && <div style={this.loadingStyle}><EllipsisLoader/></div>}

                    {
                        this.state.commentsList.length ? this.state.commentsList.map((c, i) => {
                            return (
                                <div key={i} className={"p-2"}>
                                    <FeedCommentItem
                                        idUtente={c.id_utente}
                                        idCommento={c.id_commento}
                                        nomeUtente={c.nome_utente}
                                        immagineUtente={c.immagine_utente || null}
                                        commento={c.commento}
                                        dataCommento={c.data_creazione}
                                        nRisposte={c.n_risposte}
                                        likeNumber={c.n_like}
                                        userLiked={c.user_liked == "0" ? false : true}
                                    />
                                </div>
                            )
                        }) : ''
                    }

                    {
                        this.state.totalComments > this.state.offset + this.state.limit &&
                        <small className={"p-2 text-muted mb-5 "} onClick={this.handleOtherComments}>
                            Visualizza altri commenti...
                        </small>
                    }
                </div>

                <FeedPostPageCommentInputPortal postId={this.props.id_post}>
                    <div className={"position-fixed fixed-bottom border-top"}>
                        {
                            this.state.idCommentToReplay &&
                            <div className={"bg-white p-2 d-flex align-items-center justify-content-between"}>
                                <small className={"text-muted"}>In risposta a <b>{this.state.userToReplay}</b></small>
                                <span className={"text-muted"} onClick={this.toggleReplayAComment}>&times;</span>
                            </div>
                        }
                        <div
                            className={"d-flex bg-white py-2 px-3 shadow-sm align-items-center justify-content-center"}>
                            <div>
                                <ProfilePicThumbnail
                                    src={
                                        this.state.profilePicPreview || this.getProfilePicUrl() ||
                                        (AuthAPI.currentUser.sesso === "0" ? defaultProfilePicF : defaultProfilePicM)
                                    }
                                />
                            </div>
                            <input type={"text"} name={"comment"} className={"form-control mx-2 rounded-pill"}
                                   value={this.state.comment}
                                   onChange={this.handleInputChange} placeholder={"Scrivi qualcosa..."}/>
                            <button className={"btn btn-link btn-lg p-0 m-2 rounded-circle"}
                                    onClick={_ => this.addNewComment()}>
                                <FaSolidIcon name={"paper-plane"}/></button>
                        </div>
                    </div>
                </FeedPostPageCommentInputPortal>
            </Fragment>
        )
    }
}

FeedPostComments.propTypes = {
    idNegozio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onAppOpen: PropTypes.func
}

/**
 * Questo HOC restituisce un portal per poter spostare l'input per i commenti come figlio diretto della page per
 * evitare "glitch" della UI
 *
 * @param {RefObject} el L'elemento che farà da parent nel DOM ai child del Portal
 *
 * @return Function Il componente portal
 * */
export function FeedPostPageCommentInputPortal({postId, children}) {
    return ReactDOM.createPortal(
        children,
        document.getElementById(`input-bar-post-${postId}`) || document.createElement('div')
    )
}
