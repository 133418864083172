import React from "react";
import PropTypes from "prop-types";

export function FileImagePreview({file}) {
    const url = URL.createObjectURL(file);
    const imageStyle = {
        backgroundImage: `url("${url}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: 40,
        height: 40,
        borderRadius: "0.25rem"
    }
    return (
        <React.Fragment>
            <div style={imageStyle}/>
        </React.Fragment>
    )
}

FileImagePreview.propTypes = {
    file: PropTypes.instanceOf(File)
}
