import React, {Component} from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.css";
import styled from "styled-components";
/*
Toggle Switch Component
Note: id is required for ToggleSwitch component to function. Name, currentValue, defaultChecked, Small and onChange are optional.
Usage: <ToggleSwitch id="id" onChange={function (e) { console.log("Checkbox Current State: " + e.target.checked); }} />
*/

const ToggleSwitchInner = styled.span`
    &:before{
        color:${props => props.colors[0]};
        background-color:${props => props.bgColors[0]};
    } 
    
    &:after{
        color:${props => props.colors[1]};
        background-color:${props => props.bgColors[1]};
    }
`

class ToggleSwitch extends Component {
    state = {
        checked: this.props.defaultChecked
    };
    onChange = e => {
        this.setState({
            checked: e.target.checked
        });
        if (typeof this.props.onChange === "function") this.props.onChange(e);
    };

    render() {
        return (
            <div
                className={"toggle-switch" + (this.props.Small ? " small-switch" : "") + (this.props.Big ? " big-switch" : "")}
            >
                <input
                    type="checkbox"
                    name={this.props.Name}
                    className="toggle-switch-checkbox"
                    id={this.props.id}
                    checked={this.props.currentValue}
                    defaultChecked={this.props.defaultChecked}
                    onChange={this.onChange}
                    disabled={this.props.disabled}
                />
                {this.props.id ? (
                    <label className="toggle-switch-label" htmlFor={this.props.id}>
            <ToggleSwitchInner
                className={
                    this.props.disabled
                        ? "toggle-switch-inner toggle-switch-disabled"
                        : "toggle-switch-inner"
                }
                colors={this.props.Colors}
                bgColors={this.props.BgColors}
                data-yes={this.props.Text[0]}
                data-no={this.props.Text[1]}
            />
                        <span
                            className={
                                this.props.disabled
                                    ? "toggle-switch-switch toggle-switch-disabled"
                                    : "toggle-switch-switch"
                            }
                        />
                    </label>
                ) : null}
            </div>
        );
    }

    // Set text for rendering.
    static defaultProps = {
        Text: ["Si", "No"],
        Colors: ["#fff", "#fff"],
        BgColors: ["#293069", "#ccc"]
    };
}

ToggleSwitch.propTypes = {
    id: PropTypes.string.isRequired,
    Text: PropTypes.arrayOf(PropTypes.string).isRequired,
    Colors: PropTypes.arrayOf(PropTypes.string),
    BgColors: PropTypes.arrayOf(PropTypes.string),
    Name: PropTypes.string,
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool,
    Small: PropTypes.bool,
    Big: PropTypes.bool,
    currentValue: PropTypes.bool,
    disabled: PropTypes.bool
};

export default ToggleSwitch;
