import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const _Admin = {

    getCardData: function (cardToken) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/fidelity-card/admin/getCardData`),
            {
                token : AuthAPI.currentUser.accessToken,
                cardToken : cardToken
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    transaction: function (cardToken, amount) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/fidelity-card/admin/transaction`),
            {
                token: AuthAPI.currentUser.accessToken,
                cardToken: cardToken,
                amount: amount
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    }
}