import React from "react";
import Page from "./BaseComponents/Page/Page";
import AuthAPI from "../api/AuthAPI";
import {swalError, swalLoading, swalSuccess} from "../helpers/SweetAlertWrapper";
import {FaSolidIcon} from "./FontAwesomeIcons";
import confirmOtpImage from "./../img/otp-confirm.svg";
import PropTypes from "prop-types";
import i18next from "i18next";
import {OTP_PROVIDER_SMS, OTP_PROVIDER_WHATSAPP, OTP_PROVIDERS} from "./Forms/RegisterFormSteps/OtpProviderChoose";

const COUNTER_SECONDS = 60

export default class ValidateOTPPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            code: '',
            timerActive: true,
            counter: COUNTER_SECONDS
        }
    }

    componentDidMount() {
        this.timer()
    }

    handleChange = e => {
        const name = e.target.name;
        this.setState({[name]: e.target.value});
    }

    handleClickOnResendOtp = provider => {
        this.setState({timerActive: true, counter: COUNTER_SECONDS}, _ => {
            this.timer()
            this.props.resendOTP(provider)
        })
    }

    timer = _ => {
        if (this.state.counter > 0) {
            this.setState({counter: this.state.counter - 1}, _ => {
                setTimeout(() => {
                    this.timer()
                }, 1000)
            })
            return
        }
        this.setState({timerActive: false})
    }


    render() {
        return (
            <Page
                title={"Conferma account"}
                active={true}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                leftIconText={i18next.t("generic.back-btn")}
                onLeftIconClick={this.props.onDismiss}
            >
                <div className={"container mt-4"}>
                    <img src={confirmOtpImage} className={"w-50 mx-auto mb-3 d-block"}/>
                    <p>
                        {this.props.message}
                    </p>
                    <div className={"form-group"}>
                        <input type={"number"} name={"code"} onChange={this.handleChange}
                               className={"form-control text-center"} required={true}/>
                    </div>
                    <div className={"form-group"}>
                        <button type={"button"} className={"btn btn-primary btn-block"}
                                onClick={_ => this.props.onValidate(this.state.code)}>{i18next.t("generic.confirm-btn")}</button>
                    </div>
                    <div className={"form-group text-center"}>
                        {
                            this.state.timerActive ?
                                <span>
                                    {i18next.t("validate-otp.wait-timer-text")} {this.state.counter} sec
                                </span>
                                :
                                <div className={"py-3"}>
                                    {
                                        OTP_PROVIDERS.map(provider => {
                                            return <OtpResendBtn provider={provider}
                                                                 onClick={this.handleClickOnResendOtp}/>
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </Page>
        );
    }

}

ValidateOTPPage.propTypes = {
    onDismiss: PropTypes.func,
    onValidate: PropTypes.func,
    resendOTP: PropTypes.func,
    message: PropTypes.string
}


function OtpResendBtn({provider, onClick}) {
    const style = {
        cursor: 'pointer',
    }

    const iconClass = {
        [OTP_PROVIDER_SMS]: 'fas fa-comment-sms text-blue mr-2',
        [OTP_PROVIDER_WHATSAPP]: 'fab fa-whatsapp text-success mr-2'
    }[provider];

    const iconStyle = {
        fontSize: '1.5rem'
    }

    const handleClick = _ => onClick(provider);

    return <div className={"bg-main-z3 shadow-sm p-3 mb-3 rounded"} style={style} onClick={handleClick}>
        <div className={"d-flex align-items-center justify-content-between"}>
            <div className={"d-flex align-items-center"}>
                <i className={iconClass} style={iconStyle}/> {i18next.t(`validate-otp.send-new-code-btn-${provider}`)}
            </div>
            <FaSolidIcon name={"chevron-right"} className={"text-primary"}/>
        </div>
    </div>

}
