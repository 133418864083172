import React, {Fragment} from 'react';
import {swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import {RecensioniAPI} from "../../api/Recensioni/RecensioniAPI";
import {Rating} from "react-simple-star-rating";
import {FullscreenTextarea} from "../../components/Forms/FullscreenInputs";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import PropTypes from "prop-types";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {NEW_FEEDBACK_WRITTEN} from "../../const/AppEventsCostants";

export default class RecensioniForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            valutazione: 0,
            testo: "",
        }
    }

    handleRating = (rate) => {
        this.setState({valutazione: rate})
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.state.valutazione < 1){
            swalError("La valutazione è obbligatoria")
            return;
        }
        swalLoading("Stiamo verificando i dati inseriti");
        RecensioniAPI.RecensioniCRUD.crea(
            this.state.testo, this.state.valutazione,
            this.props.tipo_operazione, this.props.id_operazione)
            .then(message => {
                swalSuccess(message);
                //this.props.reloadInformation();
                FuxEvents.emit(NEW_FEEDBACK_WRITTEN)
            })
            .catch(swalError)
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    render(){
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group text-align-center mt-2 mb-2">
                    <span>Valuta e recensisci</span><br/>
                    <small className={"text-muted"}>Condividi la tua esperienza per aiutare gli altri</small>
                    {/*link alla documentazione del plugin https://www.npmjs.com/package/react-simple-star-rating*/}
                    <div className={"__disable-swipe"}>
                        <Rating onClick={this.handleRating} ratingValue={this.state.valutazione} size={35} transition={true}/>
                    </div>
                    <div className="form-group">
                        <FullscreenTextarea
                            name={"testo"}
                            className={"form-control border-top border-bottom rounded-0"}
                            value={this.state.testo}
                            onChange={this.handleInputChange}
                            placeholder={"Scrivi cosa ne pensi (opzionale)"}
                        />
                    </div>
                    <div className={"form-group"}>
                        <button className={"btn btn-sm btn btn-primary btn-block shadow-sm"} type="submit">Invia</button>
                    </div>
                </div>
            </form>
        );
    }
}


RecensioniForm.propTypes = {
    id_operazione: PropTypes.string,
    tipo_operazione: PropTypes.string,
    reloadInformation: PropTypes.func
};
