import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";

var GeoFeedAPI = {
    getStartPage: function (lat, lng, maxDistance, limit) {
        let params = {limit: limit, lat: lat, lng: lng, maxDistance: maxDistance};
        if (AuthAPI.currentUser){
            params.token = AuthAPI.currentUser.accessToken;
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/geo-feed/start`),
            params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getPageWithCursor: function (cursor, lat, lng, maxDistance, limit) {
        let params = {cursor: cursor, limit: limit, lat: lat, lng: lng, maxDistance: maxDistance};
        if (AuthAPI.currentUser){
            params.token = AuthAPI.currentUser.accessToken;
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/geo-feed/cursor`),
            params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default GeoFeedAPI;
