import React from "react";
import {FaSolidIcon} from "../FontAwesomeIcons";
import styled from 'styled-components';
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import {applyPercentage} from "../../helpers/NumberUtility";
import {ProductInfoCheckoutView} from "./ProductInfoCheckoutView";

const Modifier = styled.div`
    display:inline;
    & + &:before{
        content:", ";
        display:inline;
    }
`;


export default class ProdottiCartListItem extends React.Component {

    constructor(props) {
        super(props);
    }

    handleValueChange = e => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleIncreaseQnt = _ => {
        const qnt = this.props.quantita + 1;
        this.setState({quantita: this.props.quantita + 1})
        this.props.onUpdate({prodotto: this.props, quantita: qnt});
    }

    handleDecreaseQnt = _ => {
        const qnt = Math.max(0, this.props.quantita - 1);
        this.setState({quantita: qnt});
        this.props.onUpdate({prodotto: this.props, quantita: qnt});
    }

    handleDelete = _ => {
        this.props.onUpdate({prodotto: this.props, quantita: -1});
    }


    canEditProduct = _ => {
        try {
            return !!(
                parseInt(this.props.abilitaNote) ||
                parseInt(this.props.abilitaRimozioni) ||
                parseInt(this.props.attributi.length) ||
                this.props.listaAggiunte.length ||
                this.props.listaOpzioni.length
            )
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    render() {
        return (
            <div>
                <div className={"list-group-item bg-main-z2 px-0"}>
                    <div className={"container-fluid"}>
                        <ProductInfoCheckoutView
                            nome={this.props.nome}
                            quantita={this.props.quantita}
                            categoria_prodotto={this.props.categoria_prodotto}
                            prezzo={this.props.prezzo}
                            perc_sconto={this.props.perc_sconto}
                            listaAggiunte={this.props.listaAggiunte}
                            listaOpzioni={this.props.listaOpzioni}
                            aggiunte={this.props.aggiunte}
                            rimozioni={this.props.rimozioni}
                            attributi={this.props.attributi}
                            id_opzione={this.props.id_opzione}
                        />
                        <div className={"row align-items-center"}>
                            {
                                this.props.note ?
                                    <div className={"col-12"}>
                                        <small>
                                            <FaSolidIcon name={"file-alt"}/> {
                                            this.props.note.split('\n').map(
                                                line => <span>{line}<br/></span>
                                            )
                                        }
                                        </small>
                                    </div> : ''
                            }

                            {
                                /* Barra quantità */
                                <div className={"col-12 mt-2"}>
                                    <div className={"row"}>
                                        <div className={"col-2 p-1"}>
                                            <button className={"btn btn-secondary btn-block btn-sm"}
                                                    onClick={this.handleDecreaseQnt}>
                                                <FaSolidIcon name={"minus"}/>
                                            </button>
                                        </div>
                                        <div className={"col-3 p-1"}>
                                            <input type={"number"} readOnly={true}
                                                   className={"form-control form-control-sm text-center bg-main-z2"}
                                                   value={this.props.quantita}
                                                   name={"quantita"}
                                                   onChange={this.handleValueChange}/>
                                        </div>
                                        <div className={"col-2 p-1"}>
                                            <button className={"btn btn-secondary btn-block btn-sm"}
                                                    onClick={this.handleIncreaseQnt}>
                                                <FaSolidIcon name={"plus"}/>
                                            </button>
                                        </div>
                                        <div className={"col-2 p-1"}>
                                            <button className={"btn btn-danger btn-block btn-sm"}
                                                    onClick={this.handleDelete}>
                                                <FaSolidIcon name={"trash"}/>
                                            </button>
                                        </div>
                                        {
                                            this.canEditProduct() && <div className={"col-2 p-1"}>
                                                <button className={"btn btn-primary btn-block btn-sm"}
                                                        onClick={_ => this.props.onEdit(this.props)}>
                                                    <FaSolidIcon name={"edit"}/>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
