import FuxHTTP from "../../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../../APIConfig";
import AuthAPI from "../../AuthAPI";

export const _Books = {
    getBooks: (map_id, date) => {
        return FuxHTTP.get(APIConfig.url("/modulo-seats/admin/books/get-books"), {
            map_id: map_id,
            date: date,
            token: AuthAPI.getUserAccessToken()
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE)
    },
    checkPassword: (book_id, password) => {
        return FuxHTTP.post(APIConfig.url("/modulo-seats/admin/books/check-password"), {
            book_id: book_id,
            password: password,
            token: AuthAPI.getUserAccessToken()
        }, FuxHTTP.RESOLVE_RESPONSE, FuxHTTP.REJECT_MESSAGE)
    },
    getBookInfo: (book_id, date = null) => {
        return FuxHTTP.get(APIConfig.url("/modulo-seats/admin/books/get-book-info"), {
            book_id: book_id,
            date: date,
            token: AuthAPI.getUserAccessToken()
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE)
    },
    setQRScanDate: (book_id) => {
        return FuxHTTP.post(APIConfig.url("/modulo-seats/admin/books/set-qr-scan-date"), {
            book_id: book_id,
            token: AuthAPI.getUserAccessToken()
        }, FuxHTTP.RESOLVE_RESPONSE, FuxHTTP.REJECT_MESSAGE)
    },
    setEntranceDate: (book_id) => {
        return FuxHTTP.post(APIConfig.url("/modulo-seats/admin/books/set-entrance-date"), {
            book_id: book_id,
            token: AuthAPI.getUserAccessToken()
        }, FuxHTTP.RESOLVE_RESPONSE, FuxHTTP.REJECT_MESSAGE)
    }
}