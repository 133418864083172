import React from "react";
import "../../dashboard.css";
import moment from "moment";
import {swal, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import sortMethodWithDirectionMultiColumn from '../../../../helpers/ObjectMultiColumnSort'
import OrdineAdminActionCard from "../Ordini/OrdineAdminActionCard";
import OrdiniDashboardAPI from "../../../../api/OrdiniDashboardAPI";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import styled from "styled-components";

import it from 'date-fns/locale/it';

registerLocale('it', it)

const ORDINE_STATUS_ATTESA = 'ordine.in_attesa';
const ORDINE_STATUS_CONFERMATO = 'ordine.confermato';
const ORDINE_STATUS_RIFIUTATO_UTENTE = 'ordine.rifiutato_utente';
const ORDINE_STATUS_RIFIUTATO_ESERCENTE = 'ordine.rifiutato_esercente';
const ORDINE_STATUS_PROPOSTA_ESERCENTE = 'ordine.proposta_esercente';
const ORDINE_STATUS_ANNULLATO_UTENTE = 'ordine.annullato_utente';
const ORDINE_STATUS_COMPLETO = 'ordine.completo';
const ORDINE_STATUS_IN_ARRIVO = 'ordine.in_arrivo'; //Solo se tipo = consegna
const ORDINE_STATUS_PRONTO = 'ordine.pronto'; //Solo se tipo = asporto

const monthMap = {
    '1': 'Gen',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'Mag',
    '6': 'Giu',
    '7': 'Lug',
    '8': 'Ago',
    '9': 'Set',
    '10': 'Ott',
    '11': 'Nov',
    '12': 'Dic',
};

const dateConfig = {
    'date': {
        format: 'DD',
        caption: 'Giorno',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Mese',
        step: 1,
    },
    'year': {
        format: 'YYYY',
        caption: 'Anno',
        step: 1,
    },
};

const CalendarWrapper = styled.div`
    & .react-datepicker-wrapper{
        width:100%!important;
    }
`;


export default class DashboardOrdiniFood extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            activeSection: ORDINE_STATUS_ATTESA,
            ordini: [],
            data: moment().format('DD-MM-YYYY'),
            datepickerTime: new Date(),
            datepickerOpen: false,
            ordinamento: 'data_creazione',
            sortColumns: {
                "isCompleto": "asc",
                "isRifiutatoEsercente": "asc",
                "isRifiutatoUtente": "asc",
                "data_creazione": "asc",
            },
            nextOrders: {},
        }
        this.datepicker = null;
        this.baseSortColumns = {
            "isCompleto": "asc",
            "isRifiutatoEsercente": "asc",
            "isRifiutatoUtente": "asc",
        }
    }

    componentDidMount() {
        this.fetchData(moment(this.state.datepickerTime).format('YYYY-MM-DD'))
        this.fetchNextOrders();
    }

    fetchData = data => {
        swalLoading();
        OrdiniDashboardAPI.lista(data).then(ordini => {
            swal.safeClose();
            var activeSection = ORDINE_STATUS_ATTESA
            this.checkFirstNonEmptySection = true;

            if (this.getInPreparazione(ordini).length) {
                activeSection = ORDINE_STATUS_CONFERMATO
            } else if (this.getCompletati(ordini).length) {
                activeSection = ORDINE_STATUS_COMPLETO;
            } else if (this.getRifiutati(ordini).length) {
                activeSection = ORDINE_STATUS_RIFIUTATO_ESERCENTE;
            }
            this.setState({ordini: this.sortData(ordini, this.state.sortColumns), activeSection: activeSection});
        });
    }

    fetchNextOrders = _ => {
        OrdiniDashboardAPI.getNextOrdersNumber().then(dateNumberPair => {
            this.setState({nextOrders: dateNumberPair});
        });
    }

    toggleSection = section => _ => {
        this.setState({activeSection: section});
    }

    handleDatepickerSelect = date => {
        this.setState({datepickerTime: date, data: moment(date).format('DD-MM-YYYY')});
        this.fetchData(moment(date).format('YYYY-MM-DD'));
    }

    handleSelectChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let state = {[name]: value, sortColumns: {...this.baseSortColumns}};
        state.sortColumns[value] = 'asc';
        state.ordini = this.sortData(this.state.ordini, state.sortColumns);
        this.setState(state);
    }

    sortData = (ordini, sortColumns) => {
        let sortOptions = [];
        for (let columnName in sortColumns) {
            sortOptions.push({column: columnName, direction: sortColumns[columnName]});
        }
        const sortMethod = sortMethodWithDirectionMultiColumn(sortOptions);
        ordini = ordini.sort(sortMethod);
        return ordini;
    }

    handleOrderStateChange = (id_ordine, state) => {
        let ordini = this.state.ordini.slice();
        for (let i in ordini) {
            if (ordini[i].id_ordine === id_ordine) {
                ordini[i] = {...ordini[i], ...state}
            }
        }
        ordini = this.sortData(ordini, this.state.sortColumns);
        this.setState({ordini: ordini});
    }

    getInAttesa = ordini => ordini.filter(o => o.status === ORDINE_STATUS_ATTESA || o.status === ORDINE_STATUS_PROPOSTA_ESERCENTE)
    getInPreparazione = ordini => ordini.filter(o => o.status === ORDINE_STATUS_CONFERMATO || o.status === ORDINE_STATUS_IN_ARRIVO || o.status === ORDINE_STATUS_PRONTO)
    getCompletati = ordini => ordini.filter(o => o.status === ORDINE_STATUS_COMPLETO)
    getRifiutati = ordini => ordini.filter(o => o.status === ORDINE_STATUS_RIFIUTATO_ESERCENTE || o.status === ORDINE_STATUS_RIFIUTATO_UTENTE || o.status === ORDINE_STATUS_ANNULLATO_UTENTE)

    getFirstNextOrderDate = format => moment(Object.keys(this.state.nextOrders)[0]).format(format)


    render() {
        return (
            <div className={"container mt-3 pb-3"} id={"DashboardOrdini"}>
                <div className={"row my-3"}>
                    <CalendarWrapper className={"col"}>
                        <DatePicker
                            className={"w-100"}
                            dateFormat={"dd-MM-yyyy"}
                            selected={this.state.datepickerTime}
                            onChange={this.handleDatepickerSelect}
                            customInput={<CalendarButton/>}
                            dayClassName={date => {
                                var dateStr = moment(date).format('YYYY-MM-DD');
                                if (this.state.nextOrders[dateStr]) {
                                    return 'text-danger';
                                }
                                return '';
                            }}
                            locale={"it"}
                        />
                    </CalendarWrapper>
                    <div className={"col"}>
                        <div className={"input-group input-group-sm"}>
                            <div className={"input-group-prepend"}>
                                <span className={"input-group-text"}>
                                    <FaSolidIcon name={"sort"}/>
                                </span>
                            </div>
                            <select name="ordinamento" className="form-control d-inline-block"
                                    value={this.state.ordinamento} onChange={this.handleSelectChange}>
                                <option value="data_creazione">Orario di arrivo</option>
                                <option value="orario">Orario di consegna</option>
                            </select>
                        </div>
                    </div>
                </div>
                {
                    Object.keys(this.state.nextOrders).length ?
                        <small className={"my-3 text-center d-block"}>
                            Hai <span className={"text-danger"}>
                        {Object.keys(this.state.nextOrders).reduce((acc, day) => acc + parseInt(this.state.nextOrders[day]), 0)}
                    </span> ordini futuri, il primo è il <span className={"text-danger"}
                                                               onClick={_ => this.handleDatepickerSelect((new Date(this.getFirstNextOrderDate('YYYY-MM-DD'))))}>
                            {this.getFirstNextOrderDate('DD-MM-YYYY')}
                        </span>
                        </small> : ''
                }
                <div className={"my-3"}>
                    <div className={"btn-group d-flex"}>
                        <button type="button"
                                onClick={this.toggleSection(ORDINE_STATUS_ATTESA)}
                                className={"btn btn-sm " + (this.state.activeSection === ORDINE_STATUS_ATTESA ? 'btn-primary' : 'btn-outline-primary')}>
                            In attesa
                        </button>
                        <button type="button"
                                onClick={this.toggleSection(ORDINE_STATUS_CONFERMATO)}
                                className={"btn btn-sm " + (this.state.activeSection === ORDINE_STATUS_CONFERMATO ? 'btn-primary' : 'btn-outline-primary')}>
                            In preparazione
                        </button>
                        <button type="button"
                                onClick={this.toggleSection(ORDINE_STATUS_COMPLETO)}
                                className={"btn btn-sm " + (this.state.activeSection === ORDINE_STATUS_COMPLETO ? 'btn-primary' : 'btn-outline-primary')}>
                            Completati
                        </button>
                        <button type="button"
                                onClick={this.toggleSection(ORDINE_STATUS_RIFIUTATO_ESERCENTE)}
                                className={"btn btn-sm " + (this.state.activeSection === ORDINE_STATUS_RIFIUTATO_ESERCENTE ? 'btn-primary' : 'btn-outline-primary')}>
                            Rifiutati
                        </button>
                    </div>
                </div>
                <div className={"container"}>
                    <div className={this.state.activeSection === ORDINE_STATUS_ATTESA ? '' : 'd-none'}>
                        <small className={"text-info"}>
                            <FaSolidIcon name={"info-circle"}/> Ricordati di <b>confermare</b> gli ordini che decidi di accettare, dopo 10 min di
                            mancata conferma gli utenti <span className="text-danger">potrebbero annullare</span> il loro
                            ordine
                        </small>
                        {
                            this.getInAttesa(this.state.ordini)
                                .map((o, i) => {
                                    return <OrdineAdminActionCard
                                        key={o.id_ordine}
                                        className={"my-2"}
                                        {...o}
                                        onStateChange={this.handleOrderStateChange}
                                    />
                                })
                        }
                        {
                            !this.getInAttesa(this.state.ordini).length
                            && <div className={"text-center mt-5 text-muted"}>
                                Non ci sono<br/>ordini in attesa
                            </div>
                        }
                    </div>
                    <div className={this.state.activeSection === ORDINE_STATUS_CONFERMATO ? '' : 'd-none'}>
                        {
                            this.getInPreparazione(this.state.ordini)
                                .map((o, i) => {
                                    return <OrdineAdminActionCard
                                        key={o.id_ordine}
                                        className={"my-2"}
                                        {...o}
                                        onStateChange={this.handleOrderStateChange}
                                    />
                                })
                        }
                        {
                            !this.getInPreparazione(this.state.ordini).length
                            && <div className={"text-center mt-5 text-muted"}>
                                Non ci sono<br/>ordini in preparazione
                            </div>
                        }
                    </div>
                    <div className={this.state.activeSection === ORDINE_STATUS_COMPLETO ? '' : 'd-none'}>
                        {
                            this.getCompletati(this.state.ordini)
                                .map((o, i) => {
                                    return <OrdineAdminActionCard
                                        key={o.id_ordine}
                                        className={"my-2"}
                                        {...o}
                                        onStateChange={this.handleOrderStateChange}
                                    />
                                })
                        }
                        {
                            !this.getCompletati(this.state.ordini).length
                            && <div className={"text-center mt-5 text-muted"}>
                                Non ci sono<br/>ordini completati
                            </div>
                        }
                    </div>
                    <div
                        className={this.state.activeSection === ORDINE_STATUS_RIFIUTATO_ESERCENTE ? '' : 'd-none'}>
                        {
                            this.getRifiutati(this.state.ordini)
                                .map((o, i) => {
                                    return <OrdineAdminActionCard
                                        key={o.id_ordine}
                                        className={"my-2"}
                                        {...o}
                                        onStateChange={this.handleOrderStateChange}
                                    />
                                })
                        }
                        {
                            !this.getRifiutati(this.state.ordini).length
                            && <div className={"text-center mt-5 text-muted"}>
                                Non ci sono<br/>ordini rifiutati
                            </div>
                        }
                    </div>
                </div>
            </div>

        );
    }


}


const CalendarButton = ({value, onClick}) => <button className={"btn btn-primary btn-block btn-sm"} onClick={onClick}>
    <FaSolidIcon name={"calendar-alt"}/> {value}
</button>
