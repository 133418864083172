import React, {Fragment} from "react";
import moment from "moment";
import AppConfig from "../../../config/AppConfig";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {
    HOTEL_BOOK_STATUS_CONFIRMED,
    HOTEL_BOOK_STATUS_WAIT_PAYMENT,
    HOTEL_PAYMENT_METHOD_OFFLINE,
    HOTEL_PAYMENT_METHOD_STRIPE
} from "../../../const/ModuloHotelCostants";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import {HotelBookDetailModal} from "./DetailModal/HotelBookDetailModal";
import {BOOKIZON_PAY_METHODS} from "../../../const/PaymentMethodoCostants";


export default class HotelNextBooksItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            detailsOpen: false
        };

    }

    toggleDetailPage = _ => this.setState({detailsOpen: !this.state.detailsOpen});

    getStatusLabel = _ => {
        if (this.props.book.status === HOTEL_BOOK_STATUS_CONFIRMED) {
            if (BOOKIZON_PAY_METHODS.indexOf(this.props.book.metodo_pagamento) !== -1) {
                return <span className={"badge badge-success"}>Confermato</span>
            } else if (this.props.book.metodo_pagamento === HOTEL_PAYMENT_METHOD_OFFLINE) {
                if (this.props.book.riferimento_pagamento) {
                    return <span className={"badge badge-success"}>Confermato, pagato in struttura</span>
                } else {
                    return <span className={"badge badge-info"}>Confermato, pagamento in struttura</span>
                }
            }
        } else if (this.props.book.status === HOTEL_BOOK_STATUS_WAIT_PAYMENT) {
            return <span className={"badge badge-warning"}>In attesa di pagamento</span>
        }
    }

    render() {
        const momentCheckin = moment(this.props.book.data_checkin);
        const momentCheckout = moment(this.props.book.data_checkout);
        const imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${this.props.book.id_negozio}/logoIcon/100`;

        return (
            <Fragment>
                <div className={"card bg-main-z2 shadow-sm " + this.props.className}>
                    <div className={"card-body"}>
                        <div onClick={this.toggleDetailPage}>
                            <div className={"d-flex align-items-center"}>
                                <AppIconThumbnail size={"25px"} src={imageFullUrl} className={"mr-1"}/>
                                <b><SafeHtmlContainer html={this.props.book.nome_camera}/>, <SafeHtmlContainer
                                    html={this.props.book.nome_hotel}/></b>
                            </div>
                            {
                                //Se vengono passate come props stampa le informazioni sulla recensione
                                this.props.feedbackInformations
                            }
                            <div className={"small text-muted"}>
                                Dal {momentCheckin.format('DD-MM-YYYY')} al {momentCheckout.format('DD-MM-YYYY')}
                            </div>
                            {this.getStatusLabel()}
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col"}>
                                <button className={"btn btn-soft-secondary btn-block btn-sm"}
                                        onClick={this.toggleDetailPage}>
                                    Dettagli
                                </button>
                            </div>
                            {
                                this.props.showOpenApp &&
                                <div className={"col"}>
                                    <button className={"btn btn-soft-primary btn-block btn-sm"}
                                            onClick={_ => this.props.onAppOpen(this.props.book.id_negozio)}>
                                        Apri l'app
                                    </button>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <ModalPortalHOC>
                    {
                        this.state.detailsOpen &&
                        <HotelBookDetailModal
                            onDismiss={this.toggleDetailPage}
                            idPrenotazione={this.props.book.id_prenotazione}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        );
    }

}
