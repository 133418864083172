import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {RECURRING_MAX_BOOKS_NUM} from "./RecurringDatesChooserPanel";
import {InputDatepicker} from "../../../../../components/BaseComponents/InputDatepicker";
import moment from "moment";

export class RecurringCyclesChooserView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            amount: 2,
            startDate: this.props.startDate
        }
        this.inputStyle = {
            width: 120
        }
    }

    handleInputChange = ({target}) => this.setState({[target.name]: target.value});
    handleDateChange = ({target, value}) => this.setState({[target.name]: value});

    handleChoose = _ => this.props.onChoose(this.state);

    render() {
        return (
            <React.Fragment>
                <div>
                    <h4 className={"font-weight-bold text-center"}>
                        Quante prenotazioni
                    </h4>
                    <p className={"text-muted"}>
                        Scegli quante prenotazioni vuoi effettuare a partire da una certa data
                    </p>
                    <div className={"d-flex align-items-center flex-wrap mb-3 justify-content-center"}>
                        Effettua
                        <select className={"form-control w-auto mx-2"} name={'amount'} value={this.state.amount}
                                onChange={this.handleInputChange}>
                            {[...Array(RECURRING_MAX_BOOKS_NUM)].map((_, i) => <option key={i + 1}
                                                                                       value={i + 1}>{i + 1}</option>)}
                        </select>
                        prenotazioni
                    </div>
                    <div className={"d-flex align-items-center flex-wrap mb-3 justify-content-center"}>
                        A partire dal
                        <InputDatepicker
                            className={"form-control bg-main-z2 text-center ml-2"}
                            style={this.inputStyle}
                            name={"startDate"}
                            type={"text"}
                            value={moment(this.state.startDate).format('DD-MM-YYYY')}
                            onChange={this.handleDateChange}
                        />
                    </div>
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <button className={"btn btn-link text-on-bg-main"} onClick={this.props.onDismiss}>
                            <FaSolidIcon name={"chevron-left"}/> Indietro
                        </button>
                        <button className={"btn btn-primary"} onClick={this.handleChoose}>
                            Conferma <FaSolidIcon name={"chevron-right"}/>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

RecurringCyclesChooserView.propTypes = {
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func,
    startDate: PropTypes.instanceOf(Date),
}
