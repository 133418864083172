import React from "react";
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById("modal-root");

export default class ModalPortal extends React.Component {

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        if (modalRoot.contains(this.el)) {
            modalRoot.removeChild(this.el);
        }
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        )
    }

}
