import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import {FeedPostView} from "./FeedPostView";
import ReactDOM from "react-dom";

export class FeedPostPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Commenti"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >
                    <FeedPostView
                        {...this.props}
                    />
                    <div id={`input-bar-post-${this.props.id}`}/>
                </Page>
            </Fragment>
        )
    }
}

FeedPostPage.propTypes = {
    idNegozio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onDismiss: PropTypes.func,
    onAppOpen: PropTypes.func,
}