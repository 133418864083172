import {MODULE_GRUPPI} from "../../../../../const/ModuliConstants";
import {SMART_PACKAGES_MODULES_TYPE} from "../../Constants";

export const ModuleGruppiSelectItemsUtils = {
    getItems: function (item, smartPackage, payableBy) {
        const moduleSettings = smartPackage.settings[SMART_PACKAGES_MODULES_TYPE[MODULE_GRUPPI]] || false
        const multipleSessions = parseInt(moduleSettings.multiple_points_session) || false
        const multipleParticipants = parseInt(moduleSettings.multiple_points_participants) || false
        /*
         * Recupero la struttura che contiene partecipanti e sessioni as Es.
         * [type: "participant", price: 8, credits: 1]
         */
        const baseItemsStructure = this.getBaseItemsStructure(item)
        //Per una questione di comodità suddivido le due tipologie
        const sessions = baseItemsStructure.filter(baseItem => baseItem.type === this.baseItemsStructureTypes.session)
        const participants = baseItemsStructure.filter(baseItem => baseItem.type === this.baseItemsStructureTypes.participant)

        //Calcolo il subtotale per entrambe le tipologie
        const sessionsNumber = sessions.length; //Le sessioni non hanno in prezzo per cui sono solo un moltiplicatore
        const participantsSubtotal = participants.reduce((partialSum, p) => partialSum + p.price, 0);

        let maxCredits = payableBy.max_payments_num
        let itemToPay = [{
            ...item,
            id_wallet: smartPackage.id_wallet,
            id_payable: payableBy.id_payable,
            prezzo: 0,
            sessions: 0,
            crediti: 0
        }]

        //CASO BASE: con un credito pago sia i partecipanti che le sessioni
        if(!multipleSessions && !multipleParticipants){
            console.log("CASO BASE")
            itemToPay[0]["prezzo"] += participantsSubtotal * sessionsNumber
            itemToPay[0]["sessions"] += sessionsNumber
            itemToPay[0]["crediti"] += 1
            maxCredits -= 1
        }

        //CASO SESSIONI MULTIPLE: per ogni sessione è utilizzato un credito, ma i partecipanti non vengono considerati
        if(multipleSessions && !multipleParticipants){
            console.log("CASO SESSIONI MULTIPLE")
            for(let s = 0; s < sessionsNumber; s++){
                if(maxCredits){
                    itemToPay[0]["prezzo"] += participantsSubtotal
                    itemToPay[0]["sessions"] += 1
                    itemToPay[0]["crediti"] += 1
                    maxCredits -= 1
                }
            }
        }

        //CASO PARTECIPANTI MULTIPLI: per ogni partecipante viene utilizzato un credito
        if(!multipleSessions && multipleParticipants){
            console.log("CASO PARTECIPANTI MULTIPLE")
            for (let p = 0; p < participants.length; p++){
                if(maxCredits){
                    itemToPay[0]["prezzo"] += participants[p].price * sessionsNumber
                    itemToPay[0]["sessions"] += sessionsNumber
                    itemToPay[0]["crediti"] += 1
                    maxCredits -= 1
                }
            }
        }

        //CASO PARTECIPANTI E SESSIONI MULTIPLE: per ogni sessione e per ogni partecipante viene utilizzato un credito
        if(multipleSessions && multipleParticipants){
            console.log("CASO TUTTO MULTIPLO")
            for(let s = 0; s < sessionsNumber; s++){
                for (let p = 0; p < participants.length; p++){
                    if(maxCredits){
                        itemToPay[0]["prezzo"] += participants[p].price
                        itemToPay[0]["sessions"] += 1
                        itemToPay[0]["crediti"] += 1
                        maxCredits -= 1
                    }
                }
            }
        }

        return itemToPay
    },


    getBaseItemsStructure: function (item) {
        let baseItemsStructure = []
        //Inserisco i partecipanti
        item.participants.map((p) => {
            for (let i = p.risposta - 1; i >= 0; i--) {
                const price = parseFloat(p.prezzo)
                const discount = p.sconto ? parseFloat(p.sconto) : 0
                baseItemsStructure.push({
                    type: this.baseItemsStructureTypes.participant,
                    price: p.prezzo !== "" ? price - price * discount / 100 : 0,
                    credits: 1
                })
                console.log(baseItemsStructure)
            }
        })
        //Inserisco le sessioni
        for (let i = item.sessions - 1; i >= 0; i--) {
            baseItemsStructure.push({type: this.baseItemsStructureTypes.session, price: 0, credits: 1})
        }
        return baseItemsStructure
    },

    baseItemsStructureTypes: {
        participant: "participant",
        session: "session",
    }
}