import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import React from "react";

/**
 * @description: Mi filtra un array di sedi in base a dipendente scelto e servizio */
export default function filtraSedi(listaSedi, listaServizi, listaDipendenti, bookInfo) {
    var newListaSedi = listaSedi.slice();
    //Faccio rimanere solo le sedi in cui lavorano dipendenti che svolgono quel servizio
    if (bookInfo.id_servizio) {
        var dipendenti = listaDipendenti.filter((d) => {
            return d.servizi.includes(bookInfo.id_servizio);
        });
        newListaSedi = newListaSedi.filter((s) => {
            //Cerco tra i dipendenti disponibili se ce n'è uno nella mia sede corrente (s)
            var trovato = false;
            for (var i in dipendenti) {
                if (dipendenti[i].id_sede === s.id_sede) {
                    trovato = true;
                    break;
                }
            }
            return trovato;
        });
    }

    if (bookInfo.id_dipendente) {
        var dip = null;
        for (var i in listaDipendenti) {
            if (listaDipendenti[i].id_dipendente === bookInfo.id_dipendente) {
                dip = listaDipendenti[i];
                break;
            }
        }
        if (dip) {
            newListaSedi = newListaSedi.filter((s) => {
                return s.id_sede === dip.id_sede;
            });
        }
    }

    return newListaSedi;
}

/**
 * Aggiunge la chiave "itemPickerData" ad ogni elmento della lista
 * */
export function extendSediListWithItemPickerData(listaSedi) {
    return listaSedi.map(sede => {
        return {
            ...sede,
            itemPickerData: {
                text: <SafeHtmlContainer html={sede.nome}/>,
                value: sede.id_sede
            }
        };
    })
}