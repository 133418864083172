import React from "react";

function shadeColor(color, percent) {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export const CronologiaVuota = props => {
    const lightColor = shadeColor(props.color, +80);
    return (

        <svg xmlns="http://www.w3.org/2000/svg" className={props.className} id="f52ea72b-4bf4-4ca0-b256-883445bd7321" data-name="Layer 1"
             width="649.3779"
             height="727.77798" viewBox="0 0 649.3779 727.77798">
            <path
                d="M648.20033,259.05824h-3.99878V149.51291A63.40187,63.40187,0,0,0,580.79976,86.111H348.713a63.40184,63.40184,0,0,0-63.402,63.4017V750.48713A63.40181,63.40181,0,0,0,348.71284,813.889H580.79945a63.40185,63.40185,0,0,0,63.402-63.40167V337.0345h3.99884Z"
                transform="translate(-285.31105 -86.11101)" fill="#e6e6e6"/>
            <path
                d="M583.3578,102.606h-30.295a22.49485,22.49485,0,0,1-20.82715,30.99053H399.2762a22.49484,22.49484,0,0,1-20.82715-30.99061H350.15346a47.34781,47.34781,0,0,0-47.34784,47.34774V750.04628a47.34781,47.34781,0,0,0,47.34777,47.34784H583.3578a47.34781,47.34781,0,0,0,47.34784-47.34778h0V149.95371A47.34777,47.34777,0,0,0,583.3578,102.606Z"
                transform="translate(-285.31105 -86.11101)" fill="#fff"/>
            <path id="f3818c68-126c-4685-b4e0-2450731ccc2a" data-name="a2804879-ded6-4045-b20f-1f1dde9b938b"
                  d="M611.46248,573.92982h-279.619a5.184,5.184,0,0,1-5.178-5.178v-69.361a5.184,5.184,0,0,1,5.178-5.178h279.619a5.184,5.184,0,0,1,5.178,5.178v69.362A5.184,5.184,0,0,1,611.46248,573.92982Zm-279.619-77.646a3.11,3.11,0,0,0-3.107,3.107v69.362a3.11,3.11,0,0,0,3.107,3.107h279.619a3.11,3.11,0,0,0,3.107-3.107v-69.362a3.11,3.11,0,0,0-3.107-3.107Z"
                  transform="translate(-285.31105 -86.11101)" fill="#e6e6e6"/>
            <circle id="abdb74b7-e321-430b-89c2-b563f66442fc" data-name="b9ad11c9-d8a0-4df6-8741-900b9ec46a35"
                    cx="82.77841"
                    cy="447.95985" r="21.74799" fill="#e6e6e6"/>
            <path id="addd02b1-b85b-481a-baea-1b0ba5ed9f4a" data-name="bd261eec-7ae0-49b0-bf26-57ff03972605"
                  d="M418.31746,519.57286a3.625,3.625,0,0,0,0,7.249h170.878a3.625,3.625,0,0,0,.13989-7.24866l-.02087-.00033q-.05943-.001-.119,0Z"
                  transform="translate(-285.31105 -86.11101)" fill="#e6e6e6"/>
            <path id="a42dc2a4-5fb2-4ea8-b2b3-ce81bc256782" data-name="e80b4447-8c34-435b-ba6c-5300a190ab24"
                  d="M418.31746,541.32087a3.625,3.625,0,0,0,0,7.249h170.878a3.625,3.625,0,0,0,.119-7.249q-.05943-.00092-.119,0Z"
                  transform="translate(-285.31105 -86.11101)" fill="#e6e6e6"/>
            <path id="b84687c4-e3b4-4975-8361-bf73c33c9ee5" data-name="e55fcb7d-3a3b-45d8-b167-72fb2263dd92"
                  d="M611.46248,689.91981h-279.619a5.184,5.184,0,0,1-5.178-5.178v-69.361a5.184,5.184,0,0,1,5.178-5.178h279.619a5.184,5.184,0,0,1,5.178,5.178v69.362A5.184,5.184,0,0,1,611.46248,689.91981Zm-279.619-77.646a3.11,3.11,0,0,0-3.107,3.107v69.362a3.11,3.11,0,0,0,3.107,3.107h279.619a3.11,3.11,0,0,0,3.107-3.107v-69.362a3.11,3.11,0,0,0-3.107-3.107Z"
                  transform="translate(-285.31105 -86.11101)" fill="#e6e6e6"/>
            <circle id="fe3713e7-4e14-41f8-af1d-48b338e5371c" data-name="a50d232f-7710-43e4-8fa9-6ef0443fc454"
                    cx="82.77841"
                    cy="563.94987" r="21.74799" fill="#e6e6e6"/>
            <path id="e34cf46a-1d6c-4c41-b5e1-331fa5bf8d4c" data-name="e49b4965-a9e9-4371-9134-194e44e65c31"
                  d="M418.31746,635.56288a3.625,3.625,0,0,0,0,7.249h170.878a3.625,3.625,0,0,0,.119-7.249q-.05943-.001-.119,0Z"
                  transform="translate(-285.31105 -86.11101)" fill="#e6e6e6"/>
            <path id="e105039f-b7a6-49c8-8f81-87505f1b0ae5" data-name="abc4586a-ac92-4255-aae6-84f53baad571"
                  d="M418.31746,657.31086a3.625,3.625,0,0,0,0,7.249h170.878a3.625,3.625,0,0,0,.119-7.249q-.05943-.00092-.119,0Z"
                  transform="translate(-285.31105 -86.11101)" fill="#e6e6e6"/>
            <path id="ad9187ec-89e0-4b9d-a4fb-dc654c09bafe" data-name="a9e593af-a319-4e97-9065-f0c2c04624d5"
                  d="M465.76845,429.99485a98.343,98.343,0,0,1-98.384-98.30194v-.0820\6c0-.206,0-.423.012-.629.3-53.879,44.432-97.756,98.372-97.756a98.384,98.384,0,0,1,.0224,196.768h-.0224Zm0-194.7a96.519,96.519,0,0,0-96.3,95.749c-.011.22-.011.4-.011.564a96.325,96.325,0,1,0,96.337-96.313h-.026Z"
                  transform="translate(-285.31105 -86.11101)" fill="#e6e6e6"/>
            <circle cx="315.11393" cy="422.84174" r="40" fill="#fff"/>
            <path
                d="M586.53265,526.94884c-.06861,0-.13721-.00049-.20606-.00195h-21.5a10.39761,10.39761,0,0,1-.0083-20.79248h21.51807c.10547-.00195.22021-.00195.334,0a10.39771,10.39771,0,0,1-.13769,20.79443Z"
                transform="translate(-285.31105 -86.11101)" fill={props.color}/>
            <rect x="325.3453" y="725.34374" width="324.03261" height="2.24072" fill="#3f3d56"/>
            <path
                d="M630.6207,621.842a6.56111,6.56111,0,0,1-.62973-1.259l-5.16339-13.82657a6.50779,6.50779,0,0,1,3.81532-8.36324l115.24228-43.03187a6.50621,6.50621,0,0,1,8.363,3.81491l5.16283,13.82751a6.49951,6.49951,0,0,1-3.81558,8.36283l-115.2412,43.03174a6.5067,6.5067,0,0,1-7.73351-2.55634Z"
                transform="translate(-285.31105 -86.11101)" fill={props.color}/>
            <path
                d="M743.51834,551.78826l-46.86323,18.68723a6,6,0,0,0-4.093,7.1996l5.43852,21.77515a6,6,0,0,0,8.37642,3.97481l46.99241-22.1188a6.0109,6.0109,0,0,0,3.52446-7.717l-5.64883-18.27558A6.01072,6.01072,0,0,0,743.51834,551.78826Z"
                transform="translate(-285.31105 -86.11101)" fill="#2f2e41"/>
            <path
                d="M829.28044,687.45a6.50671,6.50671,0,0,1-6.89226-4.34039l-40.114-116.289a6.49951,6.49951,0,0,1,4.02482-8.26416l13.95295-4.81352a6.50408,6.50408,0,0,1,8.26373,4.02536l40.11465,116.2899a6.50779,6.50779,0,0,1-4.02531,8.26422l-13.95257,4.8125A6.56115,6.56115,0,0,1,829.28044,687.45Z"
                transform="translate(-285.31105 -86.11101)" fill={props.color}/>
            <path
                d="M811.5058,555.77814l15.39577,48.04524a6,6,0,0,1-2.95088,7.73816l-20.26024,9.657a6,6,0,0,1-8.39566-3.934l-12.83035-50.328a6.0109,6.0109,0,0,1,3.71178-7.62871l17.694-7.26835A6.01071,6.01071,0,0,1,811.5058,555.77814Z"
                transform="translate(-285.31105 -86.11101)" fill="#2f2e41"/>
            <path
                d="M764.89068,812.11413H750.13141a6.50753,6.50753,0,0,1-6.5-6.5V682.59948a6.50753,6.50753,0,0,1,6.5-6.5h14.75927a6.50753,6.50753,0,0,1,6.5,6.5V805.61413A6.50753,6.50753,0,0,1,764.89068,812.11413Z"
                transform="translate(-285.31105 -86.11101)" fill="#2f2e41"/>
            <path
                d="M794.08307,812.11413H779.3233a6.50753,6.50753,0,0,1-6.5-6.5V682.59948a6.50753,6.50753,0,0,1,6.5-6.5h14.75977a6.50753,6.50753,0,0,1,6.5,6.5V805.61413A6.50753,6.50753,0,0,1,794.08307,812.11413Z"
                transform="translate(-285.31105 -86.11101)" fill="#2f2e41"/>
            <path
                d="M806.2467,697.06158H739.1842a7.00787,7.00787,0,0,1-7-7V581.11725a40.53125,40.53125,0,0,1,81.0625,0V690.06158A7.00786,7.00786,0,0,1,806.2467,697.06158Z"
                transform="translate(-285.31105 -86.11101)" fill="#ccc"/>
            <path d="M816.34118,723.711h-34V531.55621l.6316.17236a45.38181,45.38181,0,0,1,33.3684,43.6875Z"
                  transform="translate(-285.31105 -86.11101)" fill="#2f2e41"/>
            <path d="M760.32629,723.711h-34V575.41607a45.38226,45.38226,0,0,1,33.36841-43.6875l.63159-.17236Z"
                  transform="translate(-285.31105 -86.11101)" fill="#2f2e41"/>
            <circle cx="486.89704" cy="378.42061" r="53.51916" fill={props.color}/>
            <path d="M832.71545,452.21s3-83-36-56c0,0-22.5-75.5-77.5,37.5l-16,16s72-10,139,21Z"
                  transform="translate(-285.31105 -86.11101)" fill="#2f2e41"/>
            <path
                d="M773.02436,491.03636c-3.30591-.09179-7.42029-.20654-10.59-2.522a8.13272,8.13272,0,0,1-3.20008-6.07275,5.47091,5.47091,0,0,1,1.86035-4.49317c1.65552-1.39892,4.073-1.727,6.67823-.96142l-2.69922-19.72559,1.98144-.27148,3.17322,23.18994-1.65466-.75928c-1.91834-.87988-4.55164-1.32764-6.188.05518a3.51513,3.51513,0,0,0-1.15271,2.8955,6.14685,6.14685,0,0,0,2.38123,4.52783c2.46667,1.80176,5.74621,2.03418,9.46582,2.13819Z"
                transform="translate(-285.31105 -86.11101)" fill="#2f2e41"/>
            <rect x="459.19183" y="372.85162" width="10.77161" height="2" fill="#2f2e41"/>
            <rect x="493.19183" y="372.85162" width="10.77161" height="2" fill="#2f2e41"/>
            <path
                d="M652.88209,626.94393a6.00014,6.00014,0,0,1-7.96794-2.91753l-34.511-74.38406a6,6,0,0,1,10.88547-5.0504l34.511,74.38406A6.00012,6.00012,0,0,1,652.88209,626.94393Z"
                transform="translate(-285.31105 -86.11101)" fill="#3f3d56"/>
            <path
                d="M617.425,546.90538a4,4,0,0,1-4-4V491.95275h-50a4,4,0,0,1,0-8h54a4,4,0,0,1,4,4v54.95263A4,4,0,0,1,617.425,546.90538Z"
                transform="translate(-285.31105 -86.11101)" fill={props.color}/>
            <path
                d="M618.371,552.55988a48,48,0,1,1,23.34031-63.74348A48.05437,48.05437,0,0,1,618.371,552.55988Zm-35.35277-76.19831a36,36,0,1,0,47.80761,17.50523A36.04072,36.04072,0,0,0,583.01827,476.36157Z"
                transform="translate(-285.31105 -86.11101)" fill="#3f3d56"/>
        </svg>
    )
}