import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import StaticRating from "./StaticRating";
import {
    RECENSIONI_TIPO_ORDINE, RECENSIONI_TIPO_PRENOTAZIONE_CAMERA,
    RECENSIONI_TIPO_PRENOTAZIONE_GRUPPO,
    RECENSIONI_TIPO_PRENOTAZIONE_SERVIZIO
} from "../../const/RecensioniConstants";
import moment from "moment";
import {ECOMMERCE_ORDER_TYPE_DEFAULT} from "../../const/ModuloEcommerceCostants";
import {
    showFeedbackAvailableForGroups,
    showFeedbackAvailableForOrders, showFeedbackAvailableForRooms,
    showFeedbackAvailableForService
} from "./RecensioniHelperFunctions";


export default class RecensioniCardInformations extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    showFeedbackAvailable = _ =>{
        switch (this.props.recensioneTipo) {
            case RECENSIONI_TIPO_ORDINE:
                return showFeedbackAvailableForOrders(this.props.item)
            case RECENSIONI_TIPO_PRENOTAZIONE_SERVIZIO:
                return showFeedbackAvailableForService(this.props.item)
            case RECENSIONI_TIPO_PRENOTAZIONE_GRUPPO:
                return showFeedbackAvailableForGroups(this.props.item)
            case RECENSIONI_TIPO_PRENOTAZIONE_CAMERA:
                return showFeedbackAvailableForRooms(this.props.item)
        }
        return false
    }


    render() {
        return(
            this.props.item.id_recensione ?
                <div className={"mt-2 mb-2 align-items-center"}>
                    <small>Valutato: </small><StaticRating actualRating={parseInt(this.props.item.valutazione)} maxRating={5}/>
                </div>
                :
                this.showFeedbackAvailable() &&
                <div className={"d-flex align-items-center mt-2 mb-2 text-success"}>
                    <div><FaSolidIcon name={"pencil-alt"}/></div>
                    <div className={"ml-1"}>Lascia una recensione</div>
                </div>
        )
    }
}
