import React from "react";
import Store from "../../../components/Store";
import UsersShopsAPI from "../../../api/UsersShopsAPI";
import ToggleSwitch from "../../../components/BaseComponents/ToggleSwitch/ToggleSwitch";

/**
 * @description: Componente che permette di salvare automaticamente un valore di impostazione booleano in una associazione negozio-utente
 * @prop id_negozio
 * @prop key: Nome della colonna da modificare
 */
export class ShopUserSettingsSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.negozio.userSettings[this.props.settingName] === "1"
        }
    }

    onToggle = () => {
        const oldState = this.state.checked;
        const newState = !this.state.checked;
        let data = {};
        data[this.props.settingName] = newState ? 1 : 0;
        UsersShopsAPI.save(this.props.negozio.id_negozio, data)
            //.then(_ => this.setCurrentLiveState(newState)) //Se va tutto bene allora aggiorno anche i valori globali dell'app
            .catch(_ => {
                this.setState({checked: oldState});
            }) //Se qualcosa va storto allora resetto anche lo stato interno del componente

        this.setState({checked: newState});
    };

    render() {
        return (
            <div className={"row align-items-center"}>
                <div className={"col-8"}>
                    {this.props.title}
                </div>
                <div className={"col-4 text-right"}>
                    <ToggleSwitch id={`${this.props.negozio.id_negozio}_${this.props.settingName}`}
                                  currentValue={this.state.checked} onChange={this.onToggle}/>
                </div>
            </div>
        );
    }
}

