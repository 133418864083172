import {BookizonAppManager, getActionDataByActionLink} from "../index";
import React from "react";
import SafeHtmlContainer from "./SafeHtmlContainer";
import AppConfig from "../config/AppConfig";

function removeAccents(string) {
    const accents = 'ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ';
    const accentsOut = "AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGgg";
    return string
        .split("")
        .map((letter, index) => {
            const accentIndex = accents.indexOf(letter);
            return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
        })
        .join("");
}

const stripHtml = html => {
    html = html.replace('</br>', ' ').replace('<br>', ' ').replace('</p>', '</p> ');
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

const truncateHtmlString = (html, length) => {
    html = html.toString();
    /* Sostituisco tag chiusura paragrafo con spazio, altrimenti i due paragrafi verranno
    stampati senza spazio fra loro*/
    html = html.replace('</p>', ' ');
    html = html.replace('<br>', ' ');
    html = html.replace('</br>', ' ');

    /*rimuovo tag html per la preview*/
    html = html.replace(/<[^>]*>/g, '');
    if (html.length > length) {
        var i;
        for (i = length; html.length >= i; i++) {
            if (html[i] === " " || html.length === i || html[i] === "&") {
                return html.substring(0, i) + '...';
            }
        }
    } else {
        return html;
    }
}

function replaceHashtag(text, strFormat, isHTML) {
    if (isHTML) {
        let colorOccurences = text.match(/#[0-9a-f]{6}|#[0-9a-f]{3}/gi);
        for (var i in colorOccurences) {
            const colorWithoutHashtag = colorOccurences[i].substr(1);
            text = text.replace(colorOccurences[i], '{{HASH}}' + colorWithoutHashtag);
        }
    }
    strFormat = strFormat.replace(/{{hashtag}}/g, '#$1') || '<a href="#">#$1</a>';
    var repl = text.replace(/#(\w+)/g, strFormat).replace(/{{HASH}}/g, '#');
    return repl;
}

function replacePhoneNumberLinks(text) {
    var regex = /\+?(?:[0-9]?){6,14}[0-9]/g;
    return text.replace(regex, "<a href=\"tel:$&\">$&</a>");
}

function replaceLinksWithAnchors(text) {

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = text.match(urlRegex);
    if (urls) {
        return text.split(urlRegex).map((strPart, i) => {
            const onClick = _ => {
                const url = new URL(strPart);
                const isDeepLink = url.origin === AppConfig.webServerUrl && url.pathname.substr(0,3) === '/dl';
                if (isDeepLink){
                    const lastSegment = strPart.split("/").pop();
                    window.addAppId(lastSegment);
                }else {
                    BookizonAppManager.openInAppBrowser(strPart);
                }
            }
            if (urls.indexOf(strPart) > -1) { //Url
                return <a href="#" key={i} onClick={onClick}>{strPart}</a>
            } else { //Stringa normale
                return <SafeHtmlContainer key={i} html={strPart}/>;
            }
        });
    }
    return <SafeHtmlContainer html={text}/>;
}

export {removeAccents, stripHtml, replaceHashtag, replacePhoneNumberLinks, replaceLinksWithAnchors, truncateHtmlString};

