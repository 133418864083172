import React, {Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {swal, swalConfirm, swalError, swalInput, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import BookingAPI from "../../../../api/BookingAPI";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {UPDATE_USER_BOOKS, USER_DISPUTE_REQUEST_EVT} from "../../../../const/AppEventsCostants";
import DateChooserPage from "../../../../components/Booking/DateChooserPage";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {BOOKIZON_PAY_METHODS} from "../../../../const/PaymentMethodoCostants";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {ModuloServiziAPI} from "../../../../api/ModuloServizi/ModuloServiziAPI";
import {UserWithBusinessDisputePanel} from "../../../UserDisputes/UserWithBusinessDisputePanel";
import {DIPENDENTE_ANYONE_OPTION} from "../../../../const/ModuloServiziCostants";
import {arrayShuffle} from "../../../../helpers/ArrayUtility";
import NegozioAPI from "../../../../api/NegozioAPI";
import {NegozioOpenStore} from "../../../../stores/NegozioOpenStore";

export class ServiceBookResumeEditView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dateChooserOpen: false,
            disputeOpen: false
        }
    }


    /**
     * @MARK Cancellazione classica
     * */

    handleDeleteBook = _ => {
        swalConfirm("Sei sicuro di voler annullare questo appuntamento?", "Procedi", "Annulla")
            .then(_ => {
                swalLoading();
                BookingAPI.remove(this.props.bookData.id_prenotazione)
                    .then(swalSuccess)
                    .then(this.props.onDelete)
                    .then(_ => FuxEvents.emit(UPDATE_USER_BOOKS))
                    .catch(swalError);
            })
    }

    /**
     * @MARK Cambio data e ora
     * */

    toggleDateChooser = _ => {
        this.setState({dateChooserOpen: !this.state.dateChooserOpen});
    }

    getBookInfoForTimeChange = _ => {
        return {
            data: this.props.bookData.data,
            id_sede: this.props.bookData.id_sede,
            id_dipendente: this.props.bookData.dipendente_chiunque == "1" ? null : this.props.bookData.id_dipendente,
            id_negozio: this.props.bookData.id_negozio,
            id_servizio: this.props.bookData.books
                .map(b => b.id_servizio_bundle ? b.id_servizio_bundle : b.id_servizio) //Prendo i bundle o i servizi singoli
                .filter((id_servizio, idx, self) => idx === self.indexOf(id_servizio)) //Rimuovo eventuali duplicati dei bundle
        }
    }

    handleChangeBookChoose = (dateTime) => {
        swalLoading('Attendere prego...');
        const bookInfo = {
            id_prenotazione: this.props.bookData.id_prenotazione,
            id_sede: this.props.bookData.id_sede,
            data: dateTime.data,
            orario: dateTime.orario,
            dipendenti: arrayShuffle(dateTime.dipendenti),
            //id_servizio: this.props.bookData.id_servizio, //Non viene usato sul server
            id_dipendente: this.props.bookData.dipendente_chiunque == "1" ? DIPENDENTE_ANYONE_OPTION : this.props.bookData.id_dipendente,
        };
        this.doChangeBook(bookInfo, 0);
    }

    doChangeBook = (bookInfo, attempt) => {
        if (!bookInfo.id_dipendente || bookInfo.id_dipendente === DIPENDENTE_ANYONE_OPTION) {
            bookInfo.id_dipendente = bookInfo.dipendenti[attempt]; //Setto il dipendente al PRIMO disponibile
            bookInfo.dipendente_chiunque = 1
        }
        BookingAPI.changeBook(bookInfo)
            .then(swalSuccess)
            .then(this.toggleDateChooser) //Richiude la finestra di scelta della data
            .then(this.props.onChange)
            .then(_ => FuxEvents.emit(UPDATE_USER_BOOKS))
            .catch((message) => {
                if (bookInfo.dipendenti[attempt + 1] !== undefined) {
                    bookInfo.id_dipendente = bookInfo.dipendenti[attempt + 1]; //Cambio il dipendente con il prossimo disponibile
                    this.doChangeBook(bookInfo, attempt + 1);
                } else {
                    swalError(message);
                }
            });
    }

    /**
     * @MARK Dispute e rimborsi
     * */

    handleRefund = _ => {
        swalInput(
            "Motiva la tua richiesta",
            "Scrivi il motivo per cui stai annullando la prenotazione e richiedendo il rimborso",
            "textarea", null, false, true,
        ).then(({value}) => {
            if (!value) return;
            swalLoading('Attendere prego...');
            ModuloServiziAPI.Books.requestRefund(this.props.bookData.id_prenotazione, value)
                .then(m => {
                    swalSuccess(m)
                        .then(_ => {
                            this.props.onUpdate();
                            FuxEvents.emit(USER_DISPUTE_REQUEST_EVT);
                        });
                }).catch(swalError)
        })
    }

    toggleDisputePanel = _ => this.setState({disputeOpen: !this.state.disputeOpen});


    render() {
        const now = moment().format('YYYY-MM-DD HH:mm:ss')
        console.log(this.props.bookData.datetime_inizio, now)
        if (this.props.bookData.datetime_inizio < now) return '';

        if (this.props.bookData.dispute) {
            return (
                <Fragment>
                    <div className={"alert alert-danger mt-3"}>
                        <strong>
                            Cancellazione richiesta
                        </strong>
                        <p>
                            Hai inviato una richiesta all'attività per la cancellazione della prenotazione e il rimborso
                            dell'importo già pagato
                        </p>
                        <div className={"text-center"}>
                            <button className={"btn btn-link"} onClick={this.toggleDisputePanel}>
                                Vedi richiesta
                            </button>
                        </div>
                    </div>
                    <ModalPortalHOC>
                        {
                            this.state.disputeOpen &&
                            <UserWithBusinessDisputePanel
                                disputeData={this.props.bookData.dispute}
                                onClose={this.toggleDisputePanel}
                            />
                        }
                    </ModalPortalHOC>
                </Fragment>
            )
        }


        const needRefundOnCancel = this.props.bookData.pagamenti.find(p => !!p.riferimento_pagamento && BOOKIZON_PAY_METHODS.indexOf(p.metodo_pagamento) !== -1);
        return (
            <Fragment>

                <div className={"row"}>
                    <hr/>
                    <div className={"col-12"}>
                        {
                            needRefundOnCancel ?
                                <button className={"btn btn-block btn-danger mt-3"} onClick={this.handleRefund}>
                                    <FaSolidIcon name={"trash"}/> Annulla e richiedi rimborso
                                </button>
                                :
                                <button className={"btn btn-block btn-danger mt-3"} onClick={this.handleDeleteBook}>
                                    <FaSolidIcon name={"trash"}/> Annulla prenotazione
                                </button>
                        }
                    </div>
                    <div className={"col-12"}>
                        <button className={"btn btn-block btn-primary mt-3"} onClick={this.toggleDateChooser}>
                            <FaSolidIcon name={"calendar-day"}/> Modifica data e ora
                        </button>
                    </div>
                </div>

                {
                    (this.state.dateChooserOpen && this.props.infoNegozio) &&
                    <ModalPortalHOC>
                        <DateChooserPage
                            bookInfo={this.getBookInfoForTimeChange()}
                            onChoose={this.handleChangeBookChoose}
                            onDismiss={this.toggleDateChooser}
                            active={true}
                            confirmText={"Conferma"}
                            maxVisibleDays={parseInt(this.props.infoNegozio?.impostazioni?.servizi_max_visible_days || 0)}
                        />
                    </ModalPortalHOC>
                }

            </Fragment>
        )
    }

}

ServiceBookResumeEditView.propTypes = {
    bookData: PropTypes.object,
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    onChange: PropTypes.func,
}
