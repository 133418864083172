import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";

export const _Prodotti = {
    get: function (id_prodotto, lang) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/get-data/${id_prodotto}`), {
                token: AuthAPI.getUserAccessToken(),
                lang: lang
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listCategorie: function (type, lang, id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/categorie/${id_negozio || NegozioOpenStore.id_negozio}/${type}/list`), {lang: lang},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    search: function(query, id_categorie, offset, limit, orderType, onlyAvailable = false, lang){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/${NegozioOpenStore.id_negozio}/search`), {
                q: query,
                id_categorie: id_categorie,
                offset: offset,
                limit: limit,
                orderType: orderType,
                onlyAvailable: onlyAvailable,
                token: AuthAPI.getUserAccessToken(),
                lang: lang,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listEvidenza: function (id_negozio, withUser, lang) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/${id_negozio || NegozioOpenStore.id_negozio}/evidenza`), {
                token: withUser ? AuthAPI.getUserAccessToken() : undefined,
                lang: lang
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    listaPreferiti: function (id_gruppo, id_negozio = null, lang) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/lista-preferiti/${AuthAPI.currentUser.accessToken}${id_gruppo ? `/${id_gruppo}` : ''}`),
            {
                id_negozio: id_negozio,
                lang: lang
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    aggiungiPreferito: function (id_prodotto) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/aggiungi-preferito`), {
                token: AuthAPI.currentUser.accessToken,
                id_prodotto: id_prodotto
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    rimuoviPreferito: function (id_prodotto) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/rimuovi-preferito`), {
                token: AuthAPI.currentUser.accessToken,
                id_prodotto: id_prodotto
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getSameCategoryProducts: function (id_prodotto, limit, lang) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/get-same-category`), {
                token: AuthAPI.getUserAccessToken(),
                id_prodotto: id_prodotto,
                limit: limit,
                lang: lang
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getSameBrandProducts: function (id_prodotto, limit, lang) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-ecommerce/prodotti/get-same-brand`), {
                token: AuthAPI.getUserAccessToken(),
                id_prodotto: id_prodotto,
                limit: limit,
                lang
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
}