import {CHAT_TAB_MOUNTED, FOOD_SHARED_CART_LISTENER_MOUNTED} from "./AppEventsCostants";
import FuxEvents from "../lib/FuxFramework/FuxEvents";

export const DLA_FIDELITY_CARD = 'DL_fidelitycard';
export const DLA_WELFARE_CAPTURE_TRANSACTION = 'DL_welfarecapturetransaction';
export const DLA_APP_SEARCH = 'DL_appsearch';
export const DLA_APP_OPEN = 'DL_appopen';
export const DLA_VIEW_POST = 'DL_view-post';
export const DLA_INTERACTIVE_MAP = 'DL_interactive-map';
export const DLA_VIEW_PRODUCT = 'DL_product';
export const DLA_SHARED_FOOD_CART = 'DL_foodcart';
export const DLA_ADMIN_CHAT_MESSAGE = 'DL_admin-chat-message';
export const DLA_USER_CHAT_MESSAGE = 'DL_user-chat-message';
export const DLA_OPEN_NOTIFICATION_CENTER = 'DL_notification-center';
export const DLA_EXPO = 'DL_expo';
export const DLA_PACCHETTO_VIRTUAL_CARD = 'DL_pacchetto-virtual-card';
export const DLA_PRENOTAZIONE_SERVIZIO = 'DL_prenotazione-servizio';
export const DLA_PRENOTAZIONE_GRUPPO = 'DL_prenotazione-gruppo';
export const DLA_PRENOTAZIONE_GRUPPO_QR = 'DL_prenotazione-gruppo-qr';
export const DLA_SEAT_BOOK = 'DL_seat-book';
export const DLA_SEAT_BOOK_QR = 'DL_seat-book-qr';
export const DLA_STAFF_IDENTITY = 'DL_staff-identity';
export const DLA_ORDINE_FOOD = 'DL_ordine-food';
export const DLA_ORDINE_ECOMMERCE = 'DL_ordine-ecommerce';
export const DLA_NEW_DISPUTE = 'DL_new-dispute';
export const DLA_APP_PROMO = 'DL_app-open-promo';

export const DLA_APP_CTA_PRENOTA_SERVIZI = "DL_app-cta-prenota";
export const DLA_APP_CTA_PRENOTA_GRUPPO = "DL_app-cta-prenota_gruppo";
export const DLA_APP_CTA_PRENOTA_HOTEL = "DL_app-cta-prenota_hotel";
export const DLA_APP_CTA_MENU = "DL_app-cta-menu_digitale";
export const DLA_APP_CTA_ORDINE_PRODOTTI = "DL_app-cta-ordini_prodotti";

export const DLA_PAYMENT_LINK = 'DL_pay'
export const NEED_AUTH_ACTIONS = [
    DLA_APP_SEARCH,
    DLA_APP_OPEN,
    DLA_FIDELITY_CARD,
    DLA_PACCHETTO_VIRTUAL_CARD,
    DLA_SHARED_FOOD_CART,
    DLA_ADMIN_CHAT_MESSAGE,
    DLA_USER_CHAT_MESSAGE,
    DLA_EXPO,
    DLA_PRENOTAZIONE_SERVIZIO,
    DLA_PRENOTAZIONE_GRUPPO,
    DLA_ORDINE_FOOD,
    DLA_ORDINE_ECOMMERCE,
    DLA_OPEN_NOTIFICATION_CENTER,
    DLA_NEW_DISPUTE,
    DLA_APP_PROMO,
    DLA_STAFF_IDENTITY,
    DLA_VIEW_POST
];

/**
 * Questo oggetto contiene una mappatura tra un DLA (chiave) che viene triggerato da una sorgente X e che prevede
 * l'apertura dell'app e successivamente l'apertura di una sezione specifica nell'app.
 *
 * La DLA che genera l'evento deve avere come argomento l'ID del negozio
 *
 * Es. Una notifica di una promozione causa l'apertura dell'app e l'apertura della pagina delle promozioni.
 * */
export const APP_OPEN_DLA_HOMEPAGE_ACTION_MAP = {
    [DLA_APP_PROMO]: 'promozioni',
    [DLA_APP_CTA_PRENOTA_SERVIZI]: "prenota",
    [DLA_APP_CTA_PRENOTA_GRUPPO]: "prenota_gruppo",
    [DLA_APP_CTA_PRENOTA_HOTEL]: "prenota_hotel",
    [DLA_APP_CTA_MENU]: "menu_digitale",
    [DLA_APP_CTA_ORDINE_PRODOTTI]: "ordini_prodotti",
}

/**
 * Lista delle DLA che vengono immediatamente trasmesse nel sistema all'arrivo della notifica (e non al click) quando
 * l'app è in foreground
 * */
export const IMMEDIATE_FIRING_FOREGROUND_NOTIFICATION_ACTIONS = {
    [DLA_ADMIN_CHAT_MESSAGE]: true,
    [DLA_USER_CHAT_MESSAGE]: true
};

/**
 * Alcune azioni tramite deep link (Deep Link Action - DLA) necessitano di condizioni particolari nell'app per essere
 * eseguite. Ad esempio necessitano che un componente venga montato, o che certe azioni asincrone nell'app vengano eseguite prima.
 * Nell'oggetto qui sotto viene associato l'azione (costante DLA) con una funzione che restituisce una Promise.
 * Quest'ultima si risolve non appena il dispatcher degli eventi DLA può eseguire l'azione.
 *
 * @Notes: Per eseguire DLA solo quando si ha un utente loggato, non è consigliato inserire una funzione in questo
 * oggetto; sarebbe opportuno inserirlo nell'array NEED_AUTH_ACTIONS che incarica il dispatcher dei DLA di eseguire
 * l'evento solo dopo il login utente.
 * */
export const DLA_DISPATCH_PERMISSION_PROMISES = {
    [DLA_SHARED_FOOD_CART]: _ => {
        return __checkAppEventExecutedOrWaitForIt(FOOD_SHARED_CART_LISTENER_MOUNTED);
    },
    [DLA_ADMIN_CHAT_MESSAGE]: _ => {
        return __checkAppEventExecutedOrWaitForIt(CHAT_TAB_MOUNTED);
    },
    [DLA_USER_CHAT_MESSAGE]: _ => {
        return __checkAppEventExecutedOrWaitForIt(CHAT_TAB_MOUNTED);
    },
}


const __checkAppEventExecutedOrWaitForIt = eventName => {
    return new Promise(resolve => {
        const eventFiresNumber = FuxEvents.getFiresNumber(eventName);
        if (eventFiresNumber) {
            resolve()
        } else {
            FuxEvents.waitForEvent(eventName).then(_ => resolve());
        }
    });
}

//Restituisce una promise che mi dice se posso effettuare il dispatching di una DLA
export const waitDispatchDLAPermission = (eventName) => {
    const promise = DLA_DISPATCH_PERMISSION_PROMISES[eventName] ?? null;
    if (promise) {
        return promise();
    } else {
        return new Promise(r => r());
    }
}
