import React, {Fragment} from "react";
import AppConfig from "../config/AppConfig";
import styled from "styled-components";
import i18n from "../i18n";
import {getCurrentLanguage} from "../langUtils";

const OrariWrapper = styled.div`
    font-size:85%;
`

function daysForLocale(localeName = 'es-MX', weekday = 'long') {
    const format = new Intl.DateTimeFormat(localeName, { weekday }).format;
    return [...Array(7).keys()]
        .map((day) => format(new Date(Date.UTC(2021, 5, day))));
}


export default class OrariHumanWeekView extends React.PureComponent{

    constructor(props) {
        super(props);
        this.weekDay = null;
        const weekDays = daysForLocale(getCurrentLanguage());
        this.dayMap = {
            0: weekDays[6], 1: weekDays[0], 2: weekDays[1], 3: weekDays[2], 4: weekDays[3], 5: weekDays[4], 6: weekDays[5]
        }
    }

    getOrarioRange = o => {
        if (!o.array_pausa) {
            return <div className={"col text-right"}>{i18n.t('business-info.work-time.closed')}</div>;
        }
        if (o.array_pausa.filter(v => v).length) {
            return <Fragment>
                <div className={"col text-right px-1"}>{o.orario_inizio}-{o.array_pausa[0]}</div>
                <div className={"col text-right pl-1"}>{o.array_pausa[1]}-{o.orario_fine}</div>
            </Fragment>;
        } else {
            return <Fragment>
                <div className={"col text-right pl-1"}>{o.orario_inizio}-{o.orario_fine}</div>
            </Fragment>;
        }
    }

    render(){
        this.weekDay = String((new Date()).getDay());
        return (
            this.props.orari && <Fragment>
                <div className={"row text-center"}>
                    <div className={"col-3"}>
                        <hr className={"bg-primary"}/>
                    </div>
                    <div className={"col-6"}><b className={"text-primary"}>{i18n.t('business-info.work-time.working-hours-headline')}</b></div>
                    <div className={"col-3"}>
                        <hr className={"bg-primary"}/>
                    </div>
                </div>
                {
                    this.props.orari .map(o =>
                        <OrariWrapper key={o.id_giorno}>
                            <div
                                className={"row text-on-bg-main"}
                                key={o.id_giorno}>
                                <div className={"col-4 pr-1"}>
                                    {this.dayMap[o.id_giorno]}
                                </div>
                                {this.getOrarioRange(o)}
                            </div>
                        </OrariWrapper>
                    )
                }
            </Fragment>
        )
    }
}
