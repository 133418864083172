import AppConfig from "../config/AppConfig";
import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";

var NotificheAPI = {
    getLatest: function (limit, id_negozio) {
        limit = limit ? limit : 10;
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/notifiche/${AuthAPI.currentUser.accessToken}/${limit}`), {id_negozio: id_negozio || null},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    setAllRead: function (id_negozio) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/notifiche/setRead/${AuthAPI.currentUser.accessToken}`), {id_negozio: id_negozio},
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.RESOLVE_RESPONSE
        );
    },
};

export default NotificheAPI;