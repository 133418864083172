import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import AppConfig from "../config/AppConfig";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";

var EcommerceAPI = {
    getCorrieri: function (prodotti, id_indirizzo_spedizione) {
        let params = {
            id_negozio: NegozioOpenStore.id_negozio,
            token: AuthAPI.currentUser.accessToken,
            prodotti: prodotti,
            id_indirizzo_spedizione: id_indirizzo_spedizione
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/getCorrieri`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    placeOrder: function (tipo_ordine,prodotti, id_indirizzo_spedizione, id_indirizzo_fatturazione, metodo_pagamento, note_utente, id_corriere, data, orario, codice_coupon, wallets, formHTML) {
        let params = {
            id_negozio: NegozioOpenStore.id_negozio,
            token: AuthAPI.currentUser.accessToken,
            tipo_ordine:tipo_ordine,
            prodotti: prodotti,
            id_indirizzo_spedizione: id_indirizzo_spedizione,
            id_indirizzo_fatturazione: id_indirizzo_fatturazione,
            metodo_pagamento: metodo_pagamento,
            note_utente: note_utente,
            id_corriere: id_corriere,
            data: data,
            orario: orario,
            codice_coupon: codice_coupon,
            wallets: wallets,
            formHTML: formHTML
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/placeOrder`), params,
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    verifyPaypalPayment: function (id_ordine, id_ordine_paypal) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            id_ordine: id_ordine,
            id_ordine_paypal: id_ordine_paypal
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/verifyPaypalPayment`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    requestQuote: function (id_prodotto, note_utente) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            id_prodotto: id_prodotto,
            note_utente: note_utente,
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/request-quote`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getFasceOrarieConsegna: function (id_negozio, date) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            id_negozio: id_negozio,
            date: date,
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/getFasceOrarie`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getFasceOrarieRitiro: function (id_negozio, date) {
        let params = {
            token: AuthAPI.getUserAccessToken(),
            id_negozio: id_negozio,
            date: date,
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/getFasceOrarieRitiro`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getExpectedShippingPrice: function (id_prodotto) {
        let params = {
            token: AuthAPI.getUserAccessToken(),
            id_prodotto:id_prodotto
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/expected-shipping-price`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    subscriptionUserProductAvailability: function (id_prodotto, id_variante) {
        let params = {
            token: AuthAPI.getUserAccessToken(),
            id_prodotto:id_prodotto,
            id_variante: id_variante
        };
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/ecommerce/subscription-user-product-availability`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    }

};

export default EcommerceAPI;
