export const APP_HOMEPAGE_ACTION = 'homepageEvent';
export const PRODOTTI_PREFERITI_CHANGE = 'prodottiPreferitiChange';
export const GRUPPI_NEGOZI_DO_CLEAR_SEARCH = 'gruppiNegoziDoClearSearch';
export const PROFILO_NEGOZIO_MODAL_CHANGE = 'profiloNegozioModalChange';
export const AUTHENTICATED_USER_DATA_CHANGE = 'authUserDataChange';
export const ADDRESS_BOOK_CHANGE = 'addressBookChange';
export const APP_FAVOURITE_ADDED = 'appAddedToFavourites';
export const APP_NOT_FAVOURITE_OPENED = 'appNotFavouriteOpened';
export const APP_FAVOURITE_REMOVED = 'appRemovedFromFavourites';
export const POST_LIKE_CHANGE = 'postLikeChange';

export const UPDATE_USER_ORDINI = 'updateUserOrdini';
export const UPDATE_USER_BOOKS = 'updateGlobalUserBooks';
export const UPDATE_USER_HOTEL_BOOKS = 'updateUserHotelBooks';
export const UPDATE_USER_SEAT_BOOKS = 'updateUserSeatBooks';
export const LINKED_APPS_CHANGE = 'linkedAppsChange';
export const UPDATE_USER_NOTIFICATIONS = 'updateUserNotifications';

export const ADD_APP_REQUEST = 'addAppRequest';
export const OPEN_APP_REQUEST = 'openAppRequest';
export const CLOSE_APP_REQUEST = 'closeAppRequest';
export const APP_MOUNTED = 'appMounted';


export const OPEN_PRODUCT_BY_ID = 'openProductById';

export const FOOD_SHARED_CART_LISTENER_MOUNTED = 'foodSharedCartListenerMounted';
export const FOOD_CART_RELOAD_FROM_MEMORY = 'foodCartReloadFromMemory';

//USER CTA SUCCESS
export const CTA_SERVICE_BOOK_SUCCESS = 'cta_prenotazione_servizi';
export const CTA_GROUP_BOOK_SUCCESS = 'cta_prenotazione_gruppi';
export const CTA_ORDER_SUCCESS = 'cta_ordine';
export const CTA_HOTEL_BOOK_SUCCESS = 'cta_prenotazione_hotel';


//MARKETPLACE
export const MARKETPLACE_SHIPPING_ADDRESS_SELECTED = 'MARKETPLACE_SHIPPING_ADDRESS_SELECTED';
export const MARKETPLACE_BILLING_ADDRESS_SELECTED = 'MARKETPLACE_BILLING_ADDRESS_SELECTED';


//MODULO HOTEL
export const HOTEL_GUEST_SAVED_EVT = "hotelGuestSaved";


//MODULO E-COMMERCE
export const ECOMMERCE_ADD_PRODUCT_CART = "ecommerceAddProductCart"
export const ECOMMERCE_OPEN_CART = "ecommerceOpenCart"
export const ECOMMERCE_CART_RELOAD_FROM_MEMORY = 'ecommerceCartReloadFromMemory';


//FIDELITY CARD
export const FIDELITY_CARD_PRIZE_REDEEM_EVT = "fidelity_riscatto_premio"


//CHAT
export const USER_CHAT_READ_EVT = 'userChatReatEvt';
export const ADMIN_CHAT_READ_EVT = 'adminChatReatEvt';
export const CHAT_UPDATE_MESSAGES = 'chatUpdateMessages';
export const CHAT_TAB_MOUNTED = 'chatTabMounted';

//DISPUTES
export const USER_DISPUTE_REQUEST_EVT = 'userDisputeRequestEvt';
export const USER_DISPUTE_CHAT_READ_EVT = 'userChatReatEvt';
export const ADMIN_DISPUTE_CHAT_READ_EVT = 'adminChatReatEvt';
export const DISPUTE_CHAT_ACCEPT_REFUND = 'acceptRefundEvt';
export const DISPUTE_CHAT_REJECT_REFUND = 'rejectRefundEvt';


//RECENSIONI
export const NEW_FEEDBACK_WRITTEN = 'newFeedbackWritten';


//BANNER PARTNERSHIP
export const SHOW_BANNER_PARTNERSHIP = 'showBannerPartnership';
export const USERS_CONVERSION_PARTNERHIP = 'usersConversionPartnership';

//PROMOZIONI
export const OPEN_PROMOTIONS_TAB = 'openPromotionsTab';
export const PROMOTION_CLICKED = 'promotionClicked';

//COMMENTI
export const NEED_TO_REPLY_COMMENT = 'needToReplyComment'
export const ANSWERING_A_COMMENT = 'answeringAComment'
export const ANSWER_IS_LOADED = 'answerIsLoaded'

//GIFT CARDS WELFARE
export const DISMISS_GIFT_CARDS_SECTION = 'dismissGiftCardsSection'
