import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {PluralString} from "../../../../helpers/UIUtility";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {DisputeListPage} from "./DisputeListPage";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import {DisputesAPI} from "../../../../api/Disputes/DisputesAPI";
import {DISPUTE_STATUS_OPEN} from "../../../../const/DisputesCostants";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {DLA_NEW_DISPUTE} from "../../../../const/DeepLinkActionsCostants";

export class DisputeButtonView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disputes: this.props.disputes || [],
            pageOpen: false,
        }
    }

    componentDidMount() {
        //Le info generali delle dispute sono passate tramite props, ma i dati delle chat li recupero tramite API specifiche
        this.fetchDisputes();
        FuxEvents.on(DLA_NEW_DISPUTE, this.fetchDisputes);
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_NEW_DISPUTE, this.fetchDisputes);
    }

    togglePage = _ => this.setState({pageOpen: !this.state.pageOpen});

    fetchDisputes = silent => {
        if (!silent) swalLoading('Aggiornamento controversie in corso...');
        DisputesAPI.Admin.getDisputes(DISPUTE_STATUS_OPEN)
            .then(disputes => this.setState({disputes: disputes}, _ => swal.safeClose()))
            //.catch(swalError);
    }

    render() {
        if (!this.state.disputes || !this.state.disputes.length) return '';

        return (
            <Fragment>
                <div className={"border-top my-2"}/>
                <div className={"d-flex align-items-center justify-content-center"} onClick={this.togglePage}>
                    <FaSolidIcon name={"exclamation-triangle"} className={"text-danger"}/>&nbsp;
                    Hai&nbsp;{this.props.disputes.length}&nbsp;
                    <PluralString n={this.props.disputes.length} more={"richieste"} single={"richiesta"}/>
                    &nbsp;di rimborso
                </div>
                <ModalPortalHOC>
                    {
                        this.state.pageOpen &&
                        <DisputeListPage
                            onDismiss={this.togglePage}
                            onUpdate={this.fetchDisputes}
                            disputes={this.state.disputes}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DisputeButtonView.propTypes = {
    disputes: PropTypes.array.isRequired
}