import React, {Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import AppConfig from "../../../config/AppConfig";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import {SeatBookDetailModal} from "./DetailModal/SeatBookDetailModal";
import {
    SEAT_BOOK_STATUS_CANCELED,
    SEAT_BOOK_STATUS_CONFIRMED,
    SEAT_BOOK_STATUS_PENDING_CONFIRMATION,
    SEAT_BOOK_STATUS_PENDING_PAYMENT,
    SEAT_BOOK_STATUS_REFUSED
} from "../../../components/ModuloSeats/Constants/BookStatuses";
import {SeatsBookType} from "../../../components/ModuloSeats/PropTypes/BookType";
import {LinkedBookType} from "../../../components/ModuloSeats/PropTypes/LinkedBookType";
import {SeatsMapType} from "../../../components/ModuloSeats/PropTypes/MapType";
import {PluralString} from "../../../helpers/UIUtility";


export default class SeatsNextBooksItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            detailsOpen: false
        };

    }

    toggleDetailPage = _ => this.setState({detailsOpen: !this.state.detailsOpen});

    getStatusLabel = _ => {
        return {
            [SEAT_BOOK_STATUS_CONFIRMED]: <span className={"badge badge-success"}>Confermato</span>,
            [SEAT_BOOK_STATUS_PENDING_PAYMENT]: <span className={"badge badge-warning"}>In attesa di pagamento</span>,
            [SEAT_BOOK_STATUS_PENDING_CONFIRMATION]: <span
                className={"badge badge-warning"}>In attesa di conferma</span>,
            [SEAT_BOOK_STATUS_CANCELED]: <span className={"badge badge-danger"}>Annullata</span>,
            [SEAT_BOOK_STATUS_REFUSED]: <span className={"badge badge-danger"}>Rifiutata</span>,
        }[this.props.book.master.status] || <span className={"badge badge-danger"}>Sconosciuto</span>
    }

    getAllSeats = _ => {
        const firstDayDate = Object.keys(this.props.book.dates)[0];
        return this.props.book.dates[firstDayDate].map(linkedBook => linkedBook.seat);
    }


    render() {

        const allBookedDates = Object.keys(this.props.book.dates).sort();
        const startDate = allBookedDates[0];
        const endDate = allBookedDates.pop();
        const allBookedSeats = this.getAllSeats();

        const imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${this.props.book.map.id_negozio}/logoIcon/100`;

        return (
            <Fragment>
                <div className={"card bg-main-z2 shadow-sm " + this.props.className}>
                    <div className={"card-body"}>
                        <div onClick={this.toggleDetailPage}>
                            <div className={"d-flex align-items-center"}>
                                <AppIconThumbnail size={"25px"} src={imageFullUrl} className={"mr-1"}/>
                                <b><SafeHtmlContainer html={this.props.book.map.name}/></b>&nbsp; - &nbsp;
                                <PluralString
                                    n={allBookedSeats.length}
                                    more={"Posti"} single={"Posto"}
                                />&nbsp;
                                <SafeHtmlContainer html={allBookedSeats.map(s => s.internal_code).join(', ')}/>

                            </div>
                            {
                                //Se vengono passate come props stampa le informazioni sulla recensione
                                this.props.feedbackInformations
                            }
                            {
                                startDate === endDate ?
                                    <div className={"small text-muted"}>
                                        Il {moment(startDate).format('DD-MM-YYYY')}
                                    </div>
                                    :
                                    <div className={"small text-muted"}>
                                        Dal {moment(startDate).format('DD-MM-YYYY')} al {moment(endDate).format('DD-MM-YYYY')}
                                    </div>
                            }
                            {this.getStatusLabel()}
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col"}>
                                <button className={"btn btn-soft-secondary btn-block btn-sm"}
                                        onClick={this.toggleDetailPage}>
                                    Dettagli
                                </button>
                            </div>
                            {
                                this.props.showOpenApp &&
                                <div className={"col"}>
                                    <button className={"btn btn-soft-primary btn-block btn-sm"}
                                            onClick={_ => this.props.onAppOpen(this.props.book.map.id_negozio)}>
                                        Apri l'app
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <ModalPortalHOC>
                    {
                        this.state.detailsOpen &&
                        <SeatBookDetailModal
                            onDismiss={this.toggleDetailPage}
                            bookId={this.props.book.master.book_id}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        );
    }

}


SeatsNextBooksItem.propTypes = {
    book: PropTypes.shape({
        map: SeatsMapType,
        master: SeatsBookType,
        qr_code_url: PropTypes.string,
        dates: PropTypes.objectOf(LinkedBookType), //each key is a date in Y-m-d format
    })
}