import React from "react";
import PropTypes from "prop-types";
import {SeatsBookType} from "../../../../../components/ModuloSeats/PropTypes/BookType";
import {SectorSeatType} from "../../../../../components/ModuloSeats/PropTypes/SectorSeatType";
import {MapSectorType} from "../../../../../components/ModuloSeats/PropTypes/MapSectorType";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../../helpers/SweetAlertWrapper";
import {ModuloSeatsAPI} from "../../../../../api/ModuloSeats/ModuloSeatsAPI";
import moment from "moment";

export class SeatBookItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            overridedEntranceDate: null
        }
    }

    handleSetEntranceDate = _ => {
        swalConfirm("Sei sicuro di voler registrare l'ingresso per questa prenotazione?", "Procedi", "Annulla")
            .then(_ => {
                swalLoading("Attendere prego...");
                ModuloSeatsAPI.Admin.Books.setEntranceDate(this.props.book.book_id)
                    .then(r => {
                        this.setState({overridedEntranceDate: r.data});
                        swalSuccess(r.message);
                    }).catch(swalError);
            });
    }

    render() {
        const {seat, sector} = this.props;
        const badgeStyle = {backgroundColor: sector.seat_color};
        const entranceDate = this.props.book.entrance_date || this.state.overridedEntranceDate;
        return (
            <React.Fragment>
                <b>Posto {seat.internal_code}</b> <span className={"badge text-white"}
                                                        style={badgeStyle}>{sector.name}</span>
                {
                    !!this.props.tickets && <div className={"mb-1"}>
                        {
                            this.props.tickets.map(t => {
                                return <div key={t.ticket_type_id} className={"pl-3"}>{t.quantity}x {t.name}</div>
                            })
                        }
                    </div>
                }
                {
                    !!this.props.accessories && <div>
                        {
                            this.props.accessories.map(a => {
                                return <div key={a.accessory_id} className={"pl-3"}>{a.quantity}x {a.name}</div>
                            })
                        }
                    </div>
                }
                {
                    entranceDate ?
                        <div className={"text-success"}>
                            Ingresso registrato {moment(entranceDate).format('ddd DD MMM [alle] HH:mm')}
                        </div>
                        :
                        <button className={"btn btn-outline-primary mt-2 btn-sm"} onClick={this.handleSetEntranceDate}>
                            <FaSolidIcon name={"sign-in-alt"}/> Registra ingresso
                        </button>
                }
            </React.Fragment>
        )
    }

}


SeatBookItem.propTypes = {
    book: SeatsBookType.isRequired,
    seat: SectorSeatType.isRequired,
    sector: MapSectorType.isRequired,
    accessories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        accessory_id: PropTypes.any,
        quantity: PropTypes.number,
        unit_price: PropTypes.number
    })),
    tickets: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        ticket_type_id: PropTypes.any,
        quantity: PropTypes.number,
        unit_price: PropTypes.number
    })),
}