import React from "react";
import PropTypes from "prop-types";
import {SeatView} from "./SeatView";
import {LinkedBookType} from "../../../../../components/ModuloSeats/PropTypes/LinkedBookType";

export function LinkedBooksView({linkedBooks, onlinePayments, disputeList, onUpdate}) {
    return (
        <React.Fragment>
            {
                linkedBooks.map(lb => {
                    const paidAmount = onlinePayments[lb.seat.seat_id] ? parseFloat(onlinePayments[lb.seat.seat_id]) : 0;
                    const dispute = (disputeList || []).find(d => d.id_operazione === lb.book.book_id);
                    return <div className={"list-group-item"} key={lb.seat.seat_id}>
                        <SeatView
                            linkedBook={lb}
                            paidAmount={paidAmount}
                            onUpdate={onUpdate}
                            dispute={dispute}
                        />
                    </div>
                })
            }
        </React.Fragment>
    )
}

LinkedBooksView.propTypes = {
    linkedBooks: PropTypes.arrayOf(LinkedBookType),
    onlinePayments: PropTypes.object,
    onUpdate: PropTypes.func,
    disputeList: PropTypes.array,
}