import React from "react";
import InfoIndirizzo from "./InfoIndirizzo";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";

export class ProfiloUtenteIndirizzoItem extends React.Component{
    constructor(props) {
        super(props);
    }

    getMapEmbedLink = indirizzo => {
        var queryString = encodeURIComponent(`${indirizzo.indirizzo} ${indirizzo.civico},${indirizzo.citta},${indirizzo.cap},${indirizzo.civico}`);
        var imageDimension = `150x150`;
        return `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAsRTtIb0cMy_bpR5RbavHoNKvIkGPlHpg&size=${imageDimension}&center=${queryString}&zoom=17`;
    }

    render(){
        return (
            <div className={"row py-2 border-bottom align-items-center"} onClick={this.props.onClick}>
                <div className={"col"}>
                    <InfoIndirizzo {...this.props.address}/>
                </div>
                <div
                    className={"col-4 px-0 align-items-center justify-content-between"}>
                    <img src={this.getMapEmbedLink(this.props.address)}
                         className={"border rounded mr-2"} width={"80%"}/>
                    <FaSolidIcon name={"chevron-right"}/>
                </div>
            </div>
        )
    }

}
