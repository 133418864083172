import React, {Fragment} from "react";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {PageFooter} from "../../../../../components/BaseComponents/Page/PageFooter";
import PropTypes from "prop-types";
import defaultProfilePicF from "../../../../../img/profile-placeholder-f.png";
import defaultProfilePicM from "../../../../../img/profile-placeholder-m.png";
import {SCUserProfilePicMd} from "../../../SearchUserPanel/UserSearchResult";


export default class DashboardResumeAndCostsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            importoScontoGlobale: 0
        }
    }

    //Calcola il totale applicando lo sconto
    calcolaTotaleScontato = _ => {
        var importo = this.props.totalePrenotazione - this.state.importoScontoGlobale
        return ((importo < 0) ? 0 : importo)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value,
        });
    }

    getNumber = _ => {
        if (!this.props.utente.cellulare) return this.props.utente.cellulare;
        return this.props.utente.cellulare.replace('+39', '');
    }

    handleConfirm =_=>{
        this.props.onChoose(this.state.importoScontoGlobale)
    }


    render() {
        return (
            <Page
                title={"Riepilogo"}
                
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={true}
                content={
                    <div className={"container mt-3"}>
                        {/*Resume group info*/}
                        <span className={"p-2"}>Prenotazione per:</span>
                        <h3 className={"text-muted font-weight-bold mb-2 p-2"}>{this.props.group.nome}</h3>

                        {/*Resume info cliente*/}
                        <h6 className={"text-muted font-weight-bold mb-2 p-2"}>Cliente</h6>

                        <div className={"card my-1 shadow rounded-0"}>
                            <div className={"card-body p-2"}>
                                {
                                    this.props.utente.temporaneo ?
                                        <span>{this.props.utente.nome}</span> :
                                        <div className={"d-flex align-items-center"}>
                                            <SCUserProfilePicMd
                                                src={this.props.utente.sesso == 0 ? defaultProfilePicF : defaultProfilePicM}
                                                className={"mr-2"}/>
                                            <span>
                                                {this.props.utente.nome} {this.props.utente.cognome}
                                                <br/>
                                                <small className={"text-muted"}>
                                                    {
                                                        this.props.utente.email &&
                                                        <span className={"mr-2"}><FaSolidIcon name={"envelope"}/> {this.props.utente.email}</span>
                                                    }
                                                    {
                                                        this.props.utente.cellulare &&
                                                        <span className={"mr-2"}><FaSolidIcon
                                                            name={"phone"}/> {this.getNumber()}</span>
                                                    }<br/>
                                                    {
                                                        this.props.utente.citta &&
                                                        <span className={"mr-2"}><FaSolidIcon
                                                            name={"map-marker-alt"}/> {this.props.utente.citta}</span>
                                                    }
                                                </small>
                                            </span>
                                        </div>
                                }
                            </div>
                        </div>

                        <hr/>
                        {/*Resume info data e ora*/}
                        <h6 className={"text-muted font-weight-bold mb-2"}>Data & ora</h6>
                        <div className={"card shadow-sm mb-3 p-2"}>
                                <span>Hai scelto: <b className={"text-secondary"}>{this.props.data}</b>
                                    {this.props.sessionChooseData.map(s => {
                                        return (
                                            <div> dalle <b>{s.orario_inizio}</b> alle <b>{s.orario_fine}</b></div>)
                                    })}
                                </span>
                        </div>

                        <hr/>
                        {/*Resume info costi*/}
                        <h6 className={"text-muted font-weight-bold"}>Info & Costi</h6>
                        <span><b>Prezzo totale dei partecipanti:</b> €{this.props.subtotalePartecipanti}</span><br/>
                        <span><b>Numero delle sessioni prenotate:</b> {this.props.sessionChooseData.length}</span>
                        <div className={"row"}>
                            <div className={"col-lg-6 col-md-6 d-flex align-items-center mt-2"}>
                                <label>Aggiungi uno sconto (in euro)</label>
                                <input type={"number"}
                                       className={"form-control ml-2"}
                                       name={'importoScontoGlobale'}
                                       value={this.state.importoScontoGlobale}
                                       onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                        <h4 className={"mt-3"}>Totale: €{this.calcolaTotaleScontato()}</h4>
                    </div>
                }
                footer={
                    <PageFooter className={"bg-main-z2 p-3 border-top"}>
                        <button
                            className={"btn btn-lg btn-block btn-primary text-on-bg-primary"}
                            onClick={this.handleConfirm}>
                            Blocca prenotazione
                        </button>
                    </PageFooter>
                }
            />

        );
    }

}

DashboardResumeAndCostsPage.propTypes = {
    onDismiss: PropTypes.func,
    subtotalePartecipanti: PropTypes.string || PropTypes.number,
    totalePrenotazione: PropTypes.string || PropTypes.number,
    sessionChooseData: PropTypes.array,
    data: PropTypes.string,
    group: PropTypes.object,
    onChoose: PropTypes.func
}