import React, {Fragment} from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import Page from "../../components/BaseComponents/Page/Page";
import {swal, swalError, swalLoading} from "../../helpers/SweetAlertWrapper";
import WalletDipendentiAPI from "../../api/Welfare/WalletDipendenteAPI";
import DipendenteWelfareBalanceContent from "./DipendenteWelfareBalanceContent";
import DipendenteWelfareTransactions from "./TransazioniWallet/DipendenteWelfareTransactions";
import ModalPortal from "../../components/BaseComponents/ModalPortal";
import DipendenteWelfareRichiesteRimborsoModal from "./RichiesteRimborso/DipendenteWelfareRichiesteRimborsoModal";
import DipendenteWelfarePagaInNegozioModal from "./DipendenteWelfarePagaInNegozioModal";
import {error} from "autoprefixer/lib/utils";
import {SearchAppController} from "../SearchAppPage/SearchAppController";
import SearchAppPage from "../SearchAppPage/SearchAppPage";
import {EcommerceIllustration} from "../../img/illustrations/search-page/EcommerceIllustration";
import {DipendenteWelfareGiftCardsModal} from "./GiftCards/DipendenteWelfareGiftCardsModal";

const defaultSearchFilters = {isWelfare: true}

export default class DipendenteWelfareModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dipendente: null,
            richiesteRimborsiOpen: false,
            cercaNegozioPageOpen: false,
            giftCardsPageOpen: false,
            //Utilizzato per pagina di ricerca negozio
            categoriaWelfare: {
                nome: "Welfare Shops",
                disabled: false,
                image: EcommerceIllustration,
                color: "#293069",
            }
        }
    }

    fetchData = () => {
        swalLoading();
        WalletDipendentiAPI.getDipendenteInfo(this.props.user.walletapikey)
            .then((data) => {
                this.setState({dipendente: data});
                swal.safeClose();
            })
            .catch((message) => {
                swalError(message)
                this.props.onDismiss()
            })
    }

    componentDidMount() {
        this.fetchData();
    }


    toggleRimborsiPage = _ => this.setState({richiesteRimborsiOpen: !this.state.richiesteRimborsiOpen});
    toggleCercaNegozioPage = _ => this.setState({cercaNegozioPageOpen: !this.state.cercaNegozioPageOpen});
    toggleGiftCardsPage = _ => this.setState({giftCardsPageOpen: !this.state.giftCardsPageOpen});

    getPermission = name =>{
        if(!this.state.dipendente.permissions) return false
        return this.state.dipendente.permissions.find(p => p.permission_name === name && p.permission_type === 'rw')
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Il tuo welfare"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div>
                            {
                                this.state.dipendente &&
                                <div>
                                    <div style={{
                                        height: "50vh",
                                        backgroundColor: "#293069",
                                        color: "white",
                                        paddingTop: "10%"
                                    }}>
                                        <div className={"container text-center align-middle"}>
                                            <div className={"row"}>
                                                <div className={"col-12 mb-2"}>
                                                    <DipendenteWelfareBalanceContent
                                                        active={this.props.active}
                                                        user={this.props.user}
                                                        dipendente={this.state.dipendente}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"d-flex mt-5 mb-5 justify-content-around"}>
                                                <div className={this.getPermission('gift_cards') ? "" : "d-none"}>
                                                    <button type={"button"}
                                                            className={"btn btn-circle text-primary bg-white"}
                                                            onClick={this.toggleGiftCardsPage}>
                                                        <i className="fas fa-gifts"/>
                                                    </button>
                                                    <br/>
                                                    Gift cards
                                                </div>
                                                <div className={this.getPermission('shops') ? "" : "d-none"}>
                                                    <button type={"button"} className={"btn text-primary bg-white"}
                                                            onClick={this.toggleCercaNegozioPage}>
                                                        <i className={"fas fa-store"}/>
                                                    </button>
                                                    <br/>
                                                    Agenzie viaggi
                                                </div>
                                                <div className={this.getPermission('refunds') ? "" : "d-none"}>
                                                    <button type={"button"} className={"btn text-primary bg-white"}
                                                            onClick={this.toggleRimborsiPage}>
                                                        <i className="fas fa-money-check-alt"/>
                                                    </button>
                                                    <br/>
                                                    Rimborsi
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <DipendenteWelfareTransactions
                                        active={this.props.active}
                                        user={this.props.user}
                                        dipendente={this.state.dipendente}
                                    />
                                </div>
                            }
                        </div>
                    }
                />

                {
                    this.state.richiesteRimborsiOpen &&
                    <DipendenteWelfareRichiesteRimborsoModal
                        active={true}
                        user={this.props.user}
                        dipendente={this.state.dipendente}
                        onDismiss={this.toggleRimborsiPage}
                    />
                }

                {/*Gift cards page*/}
                {
                    this.state.giftCardsPageOpen &&
                    <DipendenteWelfareGiftCardsModal
                        user={this.props.user}
                        dipendente={this.state.dipendente}
                        onDismiss={this.toggleGiftCardsPage}
                    />
                }


                {/*Entra in modalità ricerca*/}
                {
                    this.state.cercaNegozioPageOpen &&
                    <SearchAppPage
                        active={true}
                        onDismiss={this.toggleCercaNegozioPage}
                        categoriaNegozio={this.state.categoriaWelfare}
                        initialSearchQuery={""}
                        searchFilters={defaultSearchFilters}
                        disableGeolocationSearching={true}
                    />
                }
            </Fragment>
        );
    }
}