import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import i18n from "../../../../i18n";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import DashboardPublishModal from "../DashboardPublish/DashboardPublishModal";

export class DashboardPubblicaPostButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageOpen: false
        }
    }

    handlePageOpen = _ => this.setState({pageOpen: true});
    handlePageClose = _ => this.setState({pageOpen: false});

    render() {
        return (
            <Fragment>
                <div className={"d-flex align-items-center justify-content-between p-3 border-top text-primary"} onClick={this.handlePageOpen}>
                    <span>
                        <FaSolidIcon name={"newspaper"}/>&nbsp;
                        {i18n.t("launcher.side-menu.pubblica")}
                    </span>
                </div>
                <ModalPortalHOC>
                    {/* Pubblica */}
                    {
                        this.state.pageOpen &&
                        <DashboardPublishModal
                            active={true}
                            onDismiss={this.handlePageClose}
                            idNegozio={this.props.userNegozio.id_negozio}
                            socialAccounts={this.props.userNegozio.socialAccounts || {}}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DashboardPubblicaPostButton.propTypes = {
    userNegozio: PropTypes.object
}