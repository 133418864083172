import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {EllipsisLoader} from "../../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {ModuloServiziAPI} from "../../../../../api/ModuloServizi/ModuloServiziAPI";
import {TabViewItem} from "../../../../../components/BaseComponents/TabView";
import {ClienteTabView} from "./ClienteTabView";
import {PanoramicaTabView} from "./PanoramicaTab/PanoramicaTabView";
import {FilesView} from "./FilesTab/FilesView";

const TAB_PANORAMICA = 'panoramica';
const TAB_CLIENTE = 'cliente';
const TAB_PAGAMENTO = 'pagamento';
const TAB_FILES = 'files';

export class BookResumePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            tab: TAB_PANORAMICA
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.idPrenotazione !== this.props.idPrenotazione){
            this.setState({data: null}, this.fetchData);
        }
    }

    fetchData = _ => {
        ModuloServiziAPI.Dashboard.getFullBookInfo(this.props.idPrenotazione)
            .then(data => this.setState({data: data}));
        this.props.onRefetchData();
    }

    /**
     * @MARK TABS
     * */

    handleTabChange = tab => _ => this.setState({tab: tab});


    render() {
        const book = this.state.data;

        return (
            <Fragment>
                <Page
                    title={`Prenotazione #${this.props.idPrenotazione}`}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >
                    {
                        !this.state.data &&
                        <div className={"h-100 d-flex align-items-center justify-content-center"}>
                            <EllipsisLoader/>
                        </div>
                    }
                    {
                        !!this.state.data &&
                        <Fragment>
                            <div className={"d-flex bg-white sticky-top shadow-sm"}>
                                <TabViewItem
                                    onClick={this.handleTabChange(TAB_PANORAMICA)}
                                    className={"py-2 text-muted d-flex align-items-center justify-content-center flex-fill text-uppercase " + (this.state.tab === TAB_PANORAMICA ? ' active' : '')}
                                    activeColor={"#293069"}
                                >
                                    Panoramica
                                </TabViewItem>
                                <TabViewItem
                                    onClick={this.handleTabChange(TAB_CLIENTE)}
                                    className={"py-2 text-muted d-flex align-items-center justify-content-center flex-fill text-uppercase " + (this.state.tab === TAB_CLIENTE ? ' active' : '')}
                                    activeColor={"#293069"}
                                >
                                    Cliente
                                </TabViewItem>
                                {/*<TabViewItem
                                    onClick={this.handleTabChange(TAB_PAGAMENTO)}
                                    className={"py-2 text-muted d-flex align-items-center justify-content-center flex-fill text-uppercase " + (this.state.tab === TAB_PAGAMENTO ? ' active' : '')}
                                    activeColor={"#293069"}
                                >
                                    Pagamento
                                </TabViewItem>*/}
                                <TabViewItem
                                    onClick={this.handleTabChange(TAB_FILES)}
                                    className={"py-2 text-muted d-flex align-items-center justify-content-center flex-fill text-uppercase " + (this.state.tab === TAB_FILES ? ' active' : '')}
                                    activeColor={"#293069"}
                                >
                                    <FaSolidIcon name={"file-lines"}/>
                                </TabViewItem>
                            </div>

                            <div className={this.state.tab === TAB_PANORAMICA ? '' : 'd-none'}>
                                <div className={"container py-4"}>
                                    <PanoramicaTabView
                                        book={book}
                                        optGroupServizi={this.props.optGroupServizi}
                                        onRefetchData={this.fetchData}
                                        onReplaceParentId={this.props.onReplaceParentId}
                                        onReschedule={this.props.onReschedule}
                                        onDelete={this.props.onDelete}
                                        onEditTime={this.props.onEditTime}
                                    />
                                </div>
                            </div>

                            <div className={this.state.tab === TAB_CLIENTE ? '' : 'd-none'}>
                                <div className={"container py-4"}>
                                    <ClienteTabView user={book.user} subBooks={book.books}/>
                                </div>
                            </div>

                            <div className={this.state.tab === TAB_FILES ? '' : 'd-none'}>
                                <div className={"container py-4"}>
                                    <FilesView idParent={this.props.idPrenotazione}/>
                                </div>
                            </div>

                        </Fragment>
                    }
                </Page>
            </Fragment>
        )
    }

}

BookResumePage.propTypes = {
    idPrenotazione: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    optGroupServizi: PropTypes.array.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onReplaceParentId: PropTypes.func.isRequired,
    onReschedule: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEditTime: PropTypes.func.isRequired,
    onRefetchData: PropTypes.func.isRequired
}
