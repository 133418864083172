import React, {Fragment} from "react";
import PropTypes from "prop-types";
import SafeHtmlContainer from "../../../../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";

export class ClienteTabView extends React.Component {


    //Restituisce la lista delle note dei servizi che sono presenti nella prenotazione
    getNoteServizi = _ => {
        const flatServiceList = this.props.subBooks.reduce((flat, b) => {
            const c = {...b};
            delete c.subservices;
            flat.push(c);
            if (b.subservices) {
                flat = flat.concat(b.subservices);
            }
            return flat;
        }, []);

        return this.props.user.note_servizi.map(({id_servizio, note_servizio}) => {
            //Verifico se il servizio di cui ho le note sia prenotato
            const book = flatServiceList.find(s => s.id_servizio == id_servizio);
            if (!book) return null;
            return {
                id_servizio: id_servizio,
                nome_servizio: book.nome_servizio,
                note_servizio: note_servizio
            }
        }).filter(v => !!v);
    }

    render() {
        const user = this.props.user;
        const note = this.getNoteServizi();
        return (
            <React.Fragment>
                <div className={"card shadow-sm mb-3 border-0"}>
                    <div className={"card-body"}>
                        <h6 className="font-weight-bold text-purple">Cliente</h6>
                        <div>{user.nome} {user.cognome}</div>
                        <div>
                            <b>Cellulare:</b>
                            {
                                user.cellulare ?
                                    <a href={`tel:${user.cellulare}`}>
                                        {user.cellulare}
                                    </a>
                                    : "Nessun numero"
                            }
                        </div>
                        <div>
                            <b>Email:</b>
                            {
                                user.email ?
                                    <a href={`mailto:${user.email}`}>
                                        {user.email}
                                    </a>
                                    : "Nessuna email"
                            }
                        </div>
                        <div className={"mt-1"}>
                            {
                                user.etichette.map(e => <span key={e}
                                                              className={"badge badge-primary mb-2 mr-1"}>{e}</span>)
                            }
                        </div>
                    </div>
                </div>


                {
                    note.length > 0 &&
                    <div className={"card shadow-sm mb-3 border-0"}>
                        <div className={"card-body"}>
                            <h6 className="font-weight-bold text-purple">Note sui servizi</h6>
                            {
                                note.map(n => <div key={n.id_servizio}>
                                    <b>{n.nome_servizio}</b>
                                    <p><SafeHtmlContainer html={n.note_servizio}/></p>
                                </div>)
                            }
                        </div>
                    </div>
                }

                {
                    !!user.note &&
                    <div className={"card shadow-sm mb-3 border-0"}>
                        <div className={"card-body"}>
                            <h6 className="font-weight-bold text-purple">Note interne sul cliente</h6>
                            <div className={"notes-wrapper"}>
                                <SafeHtmlContainer html={user.note}/>
                            </div>
                        </div>
                    </div>

                }
            </React.Fragment>
        )
    }
}

ClienteTabView.propTypes = {
    user: PropTypes.object.isRequired,
    subBooks: PropTypes.array.isRequired
}

