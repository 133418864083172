import React, {Suspense} from "react";
import {EllipsisLoader} from "../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import PropTypes from "prop-types";
import ExpoAPI from "../../../api/ExpoAPI";
import {swal, swalError, swalLoading} from "../../../helpers/SweetAlertWrapper";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {DLA_EXPO} from "../../../const/DeepLinkActionsCostants";
import {ExpoThemeWrapper} from "../../Expo/ExpoThemeWrapper";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";


const LazyExpoHomePage = React.lazy(() => import("../../Expo/ExpoHomePage.js"));

export class ExpoDirectOpenListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gruppoOpen: null
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_EXPO, this.fetchDatiGruppo);
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_EXPO, this.fetchDatiGruppo);
    }

    fetchDatiGruppo = id_gruppo => {
        swalLoading('Recupero informazioni...');
        ExpoAPI.lista(true)
            .then(lista => {
                let gruppo = lista.find(g => g.id_gruppo === id_gruppo);
                if (!gruppo) {
                    swalError("Sembra che questo QR code non sia associato ad una iniziativa EXPO");
                } else {
                    swal.safeClose();
                    this.setState({gruppoOpen: gruppo});
                }
            }).catch(swalError);
    }

    handleGruppoDismiss = _ => this.setState({gruppoOpen: null});

    render() {
        if (!this.state.gruppoOpen) return '';

        return (
            <Suspense fallback={<div className={"vh-100 align-items-center d-flex justify-content-center"}>
                <EllipsisLoader/></div>}>
                <ModalPortal>
                    <ExpoThemeWrapper color={this.state.gruppoOpen ? this.state.gruppoOpen.primary_color : ''}>
                        <LazyExpoHomePage
                            active={true}
                            gruppo={this.state.gruppoOpen}
                            onDismiss={this.handleGruppoDismiss}
                            onAppOpen={this.props.onAppOpen}
                            onAppAdd={this.props.onAppAdd}
                        />
                    </ExpoThemeWrapper>
                </ModalPortal>
            </Suspense>
        )
    }

}

ExpoDirectOpenListener.propTypes = {
    onAppOpen: PropTypes.func,
    onAppAdd: PropTypes.func,
}