import React from "react";
import {FaSolidIcon} from "../../../../FontAwesomeIcons";
import {getFontAwesomeIconFromMIME} from "../../../../ChatPanel/ChatRoom/Attachments/Attachments.helpers";
import {FileImagePreview} from "./FileImagePreview";
import PropTypes from "prop-types";

export function FileListViewItemPreview({file}) {
    const [mimeGenericType, mimeSpecificType] = file.type.split("/");

    if (mimeGenericType === 'image') {
        return <FileImagePreview file={file}/>
    }

    return (
        <React.Fragment>
            <FaSolidIcon name={getFontAwesomeIconFromMIME(file.type)}/>
        </React.Fragment>
    )
}

FileListViewItemPreview.propTypes = {
    file: PropTypes.instanceOf(File)
}
