import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "../AuthAPI";
import APIConfig from "../APIConfig";


const _Dashboard_Fullcalendar = {
    getResources: function (id_sede) {
        return FuxHTTP.getJson(
            APIConfig.url(`/dashboard/modulo-servizi/fullcalendar/get-resources`),
            {
                id_sede: id_sede,
                token: AuthAPI.getUserAccessToken()
            }
        );
    },
    getEvents: function (id_sede, start, end) {
        return FuxHTTP.getJson(
            APIConfig.url(`/dashboard/modulo-servizi/fullcalendar/get-events`),
            {
                id_sede: id_sede,
                start: start,
                end: end,
                token: AuthAPI.getUserAccessToken()
            }
        );
    },
    getBackgroundEvents: function (id_sede, start, end) {
        return FuxHTTP.getJson(
            APIConfig.url(`/dashboard/modulo-servizi/fullcalendar/get-background-events`),
            {
                id_sede: id_sede,
                start: start,
                end: end,
                token: AuthAPI.getUserAccessToken()
            }
        );
    },
    getTimeInterval: function (id_sede, dateYmd) {
        return FuxHTTP.getJson(
            APIConfig.url(`/dashboard/modulo-servizi/fullcalendar/get-time-interval`),
            {
                id_sede: id_sede,
                date: dateYmd,
                token: AuthAPI.getUserAccessToken()
            }
        );
    },
};


export default _Dashboard_Fullcalendar;
