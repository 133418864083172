import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {EllipsisLoader} from "../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import {CronologiaVuota} from "../../img/illustrations/CronologiaVuota";
import CronologiaUserAPI from "../../api/CronologiaUserAPI";
import {swalError} from "../../helpers/SweetAlertWrapper";
import ModalPortalHOC from "../../components/BaseComponents/ModalPortalHOC";
import {CronologiaUserFiltersPanel} from "./CronologiaUserFiltersPanel";
import Page from "../../components/BaseComponents/Page/Page";
import {PluralString} from "../../helpers/UIUtility";
import moment from "moment";
import CronologiaUserListView from "./CronologiaUserListView";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {NEW_FEEDBACK_WRITTEN} from "../../const/AppEventsCostants";
import {fi} from "date-fns/locale";

const PAGE_SIZE = 10;

const CRONOLOGIA_ACTIVITY_MAP = {
    prenotazioni: "Prenotazioni",
    ordini_food: "Ordinazioni food",
    ordini_ecommerce: "Ordini ecommerce",
    hotel: "Prenotazioni Hotel e B&B",
}

export const CRONOLOGIA_MIN_DATE_ALWAYS = 'da sempre';
export const CRONOLOGIA_MIN_DATE_LAST_WEEK = `nell'ultima settimana`;
export const CRONOLOGIA_MIN_DATE_LAST_MONTH = `nell'ultimo mese`;
export const CRONOLOGIA_MIN_DATE_LAST_3MONTH = 'negli ultimi 3 mesi';

const minDateTypeToDate = type => {
    switch (type) {
        case CRONOLOGIA_MIN_DATE_ALWAYS:
            return '1970-01-01 00:00:00';
        case CRONOLOGIA_MIN_DATE_LAST_WEEK:
            return moment().subtract(7, 'days').format('YYYY-MM-DD hh:mm:ss');
        case CRONOLOGIA_MIN_DATE_LAST_MONTH:
            return moment().subtract(30, 'days').format('YYYY-MM-DD hh:mm:ss');
        case CRONOLOGIA_MIN_DATE_LAST_3MONTH:
            return moment().subtract(90, 'days').format('YYYY-MM-DD hh:mm:ss');
    }
}


export class CronologiaUserPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ordini_food: true,
            ordini_ecommerce: true,
            prenotazioni: true,
            hotel: true,
            future_experiences: !!this.props.idNegozio,
            items: [],
            totalItems: 0,
            fetching: false,
            minDateType: CRONOLOGIA_MIN_DATE_ALWAYS,
            currentPage: 1,
            filtersVisible: false,
        }
    }

    componentDidMount() {
        this.fetchItems();
        FuxEvents.on(NEW_FEEDBACK_WRITTEN, this.fetchItems);
    }

    componentWillUnmount() {
        FuxEvents.off(NEW_FEEDBACK_WRITTEN, this.fetchItems);
    }

    toggleFilterPanelVisibility = _ => this.setState({filtersVisible: !this.state.filtersVisible});

    handleFiltersChange = ({ordini_food, ordini_ecommerce, prenotazioni, hotel, minDateType, future_experiences}) => {
        this.setState({
            ordini_food, ordini_ecommerce, prenotazioni, hotel, minDateType, future_experiences
        }, _ => {
            this.toggleFilterPanelVisibility();
            this.fetchItems();
        })
    }

    handlePageChange = pageNumber => this.setState({currentPage: pageNumber}, this.fetchItems);

    fetchItems = _ => {
        this.setState({fetching: true});
        CronologiaUserAPI.getItems({
            ordini_food: this.state.ordini_food ? 1 : 0,
            ordini_ecommerce: this.state.ordini_ecommerce ? 1 : 0,
            prenotazioni: this.state.prenotazioni ? 1 : 0,
            hotel: this.state.hotel ? 1 : 0,
            min_date: minDateTypeToDate(this.state.minDateType),
            max_date: this.state.future_experiences ? null : moment(new Date()).format('YYYY-MM-DD'),
            id_negozio: this.props.idNegozio
        }, PAGE_SIZE, (this.state.currentPage - 1) * PAGE_SIZE)
            .then(data => {
                this.setState({
                    totalItems: data.total,
                    items: data.items,
                    fetching: false
                });
            })
            .catch(swalError);
    }

    getFilterLabel = _ => {
        const firstActiveFilterName = Object.keys(CRONOLOGIA_ACTIVITY_MAP).find(type => this.state[type]);
        console.log("QUI", firstActiveFilterName)
        const numOthers = Object.keys(CRONOLOGIA_ACTIVITY_MAP).filter(type => type !== firstActiveFilterName && this.state[type]).length;
        if (!firstActiveFilterName && !numOthers) {
            return `Nessuna attività selezionato ${this.state.minDateType}`;
        }

        if (firstActiveFilterName && numOthers + 1 === Object.keys(CRONOLOGIA_ACTIVITY_MAP).length) {
            return `Tutte le tue attività ${this.state.minDateType} ${this.state.future_experiences ? "" : " fino ad oggi"}`
        }

        return <Fragment>
            {CRONOLOGIA_ACTIVITY_MAP[firstActiveFilterName]} + <PluralString n={numOthers}
                                                                             more={"altre"}
                                                                             single={"altra"}/> {numOthers} {this.state.minDateType} {this.state.future_experiences ? "" : "fino ad oggi"}
        </Fragment>
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Cronologia"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >
                    <div
                        className={"bg-main-z2 text-on-bg-main shadow-sm sticky-top p-2 d-flex align-items-center justify-content-between"}>
                        <small className={"text-muted"}>{this.getFilterLabel()}</small>
                        <button className={"btn btn-sm btn-link text-primary"}
                                onClick={this.toggleFilterPanelVisibility}>
                            Cambia filtri
                        </button>
                    </div>

                    {this.props.nOfRecensioniInSospeso > 0 &&
                        <div className={"container bg-soft-success text-center pt-2 pb-2"}>
                            <span>Ci sono ancora {this.props.nOfRecensioniInSospeso} esperienze recenti da recensire! </span>
                        </div>
                    }

                    {
                        this.state.fetching &&
                        <div className={"mt-5"}>
                            <EllipsisLoader/>
                        </div>
                    }

                    {
                        (!this.state.fetching && !this.state.items.length) &&
                        <div className={"container"}>
                            <div className={"mt-5 text-center"}>
                                <CronologiaVuota className={"img-fluid w-75 mx-auto"} color={"#293069"}/>
                            </div>
                            <div className={"mt-3 text-center text-muted"}>
                                Non abbiamo trovato nessuna attività di questo tipo effettuata da te
                            </div>
                        </div>
                    }

                    {
                        !this.state.fetching &&
                        <CronologiaUserListView
                            items={this.state.items}
                            onAppOpen={this.props.onAppOpen}
                            showOnAppOpen={!this.props.idNegozio}
                            onCronologiaDismiss={this.props.onDismiss}
                        />
                    }

                    {
                        (!this.state.fetching && !!this.state.items.length && this.state.totalItems > PAGE_SIZE) &&
                        <div className={"justify-content-center d-flex mt-3"}>
                            <Pagination
                                activePage={this.state.currentPage}
                                itemsCountPerPage={PAGE_SIZE}
                                totalItemsCount={this.state.totalItems}
                                pageRangeDisplayed={5}
                                itemClass="page-item"
                                linkClass="page-link"
                                onChange={this.handlePageChange}
                            />
                        </div>
                    }
                </Page>
                <ModalPortalHOC>
                    <CronologiaUserFiltersPanel
                        onDismiss={this.toggleFilterPanelVisibility}
                        onChange={this.handleFiltersChange}
                        open={this.state.filtersVisible}
                        filters={{
                            ordini_food: this.state.ordini_food,
                            ordini_ecommerce: this.state.ordini_ecommerce,
                            prenotazioni: this.state.prenotazioni,
                            hotel: this.state.hotel,
                            future_experiences: this.state.future_experiences,
                            minDateType: this.state.minDateType,
                        }}
                    />
                </ModalPortalHOC>
            </Fragment>
        )
    }
}

CronologiaUserPage.propTypes = {
    idNegozio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onDismiss: PropTypes.func,
    onAppOpen: PropTypes.func,
}