import React,{Fragment} from 'react'
import ReactDOM from 'react-dom'
import AuthAPI from "../../api/AuthAPI";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import {FullscreenInput, FullscreenSelect} from "./FullscreenInputs";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {AUTHENTICATED_USER_DATA_CHANGE} from "../../const/AppEventsCostants";

export default class UserProfileForm extends React.Component {
    constructor(props) {
        super(props);
        var user = {...props.userData};
        delete user.token;
        delete user.accessToken;
        this.state = {...user};
        const dateParts = (user.data_nascita || "0000-00-00").split('-');
        this.state.giorno_nascita = dateParts[2];
        this.state.mese_nascita = dateParts[1];
        this.state.anno_nascita = dateParts[0];

        this.giorniMese = Array.from(Array(31).keys());
        this.mesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
        this.currentYear = parseInt((new Date()).getFullYear());
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        return new Promise((resolve,reject)=>{
            swalLoading();
            var data = {...this.state};
            data.data_nascita = `${data.anno_nascita}-${data.mese_nascita}-${data.giorno_nascita}`;
            AuthAPI.setUserData(data)
                .then(function (message) {
                    swalSuccess(message);
                    FuxEvents.emit(AUTHENTICATED_USER_DATA_CHANGE);
                    resolve();
                })
                .catch(function (message) {
                    swalError(message);
                    reject();
                });
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group container-fluid">
                        <small className={"text-uppercase"}>Nome</small>
                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                            <div className={"col-1 px-0 text-primary text-center"}>
                                <FaSolidIcon name={"id-card"}/>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenInput
                                    className={"form-control border-0 rounded-0"}
                                    type={"text"}
                                    name={"nome"}
                                    value={this.state.nome}
                                    placeholder="Il tuo nome"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group container-fluid">
                        <small className={"text-uppercase"}>Cognome</small>
                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                            <div className={"col-1 px-0 text-primary text-center"}>
                                <FaRegularIcon name={"id-card"}/>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenInput
                                    className={"form-control border-0 rounded-0"}
                                    type={"text"}
                                    name={"cognome"}
                                    value={this.state.cognome}
                                    placeholder="Il tuo cognome"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group container-fluid">
                        <small className={"text-uppercase"}>Data di nascita</small>
                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                            <div className={"col-1 px-0 text-primary text-center"}>
                                <FaSolidIcon name={"birthday-cake"}/>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenSelect
                                    className={"form-control border-0 rounded-0"}
                                    name={"giorno_nascita"}
                                    value={this.state.giorno_nascita}
                                    onChange={this.handleInputChange}
                                >
                                    <option value={""}>GG</option>
                                    {
                                        this.giorniMese.map(i => {
                                            return <option key={i}
                                                           value={("0" + (i + 1)).substr(-2, 2)}>{("0" + (i + 1)).substr(-2, 2)}</option>
                                        })
                                    }
                                </FullscreenSelect>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenSelect
                                    className={"form-control border-0 rounded-0"}
                                    name={"mese_nascita"}
                                    value={this.state.mese_nascita}
                                    onChange={this.handleInputChange}
                                >
                                    <option value={""}>MM</option>
                                    {
                                        this.mesi.map((m, i) => {
                                            return <option key={i} value={("0" + (i + 1)).substr(-2, 2)}>{m}</option>
                                        })
                                    }
                                </FullscreenSelect>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenSelect
                                    className={"form-control border-0 rounded-0"}
                                    name={"anno_nascita"}
                                    value={this.state.anno_nascita}
                                    onChange={this.handleInputChange}
                                >
                                    <option value={""}>AAAA</option>
                                    {
                                        Array.from(Array(100).keys()).map(i => {
                                            return <option key={i}
                                                           value={this.currentYear - i}>{this.currentYear - i}</option>
                                        })
                                    }
                                </FullscreenSelect>
                            </div>
                        </div>
                    </div>
                    <div className="form-group container-fluid">
                        <small className={"text-uppercase"}>Cellulare</small>
                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                            <div className={"col-1 px-0 text-primary text-center"}>
                                <FaSolidIcon name={"mobile-alt"}/>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenInput
                                    className={"form-control border-0 rounded-0"}
                                    type={"tel"}
                                    name={"cellulare"}
                                    value={this.state.cellulare}
                                    disabled={this.state.isCellulareValid}
                                    placeholder="(+39) 333 444 555 6"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group container-fluid">
                        <small className={"text-uppercase"}>Email</small>
                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                            <div className={"col-1 px-0 text-primary text-center"}>
                                <FaSolidIcon name={"envelope"}/>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenInput
                                    className={"form-control border-0 rounded-0"}
                                    type={"email"}
                                    name={"email"}
                                    value={this.state.email}
                                    disabled={true}
                                    placeholder="La tua email"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group container-fluid">
                        <small className={"text-uppercase"}>Sesso</small>
                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                            <div className={"col-1 px-0 text-primary text-center"}>
                                <FaSolidIcon name={"venus-mars"}/>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenSelect
                                    className={"form-control border-0 rounded-0"}
                                    name={"sesso"}
                                    value={this.state.sesso}
                                    onChange={this.handleInputChange}
                                >
                                    <option value="-1">Non specificato</option>
                                    <option value="1">Maschio</option>
                                    <option value="0">Femmina</option>
                                </FullscreenSelect>
                            </div>
                        </div>
                    </div>
                    <div className="form-group container-fluid">
                        <small className={"text-uppercase"}>Telefono</small>
                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                            <div className={"col-1 px-0 text-primary text-center"}>
                                <FaSolidIcon name={"phone"}/>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenInput
                                    className={"form-control border-0 rounded-0"}
                                    type={"tel"}
                                    name={"telefono"}
                                    value={this.state.telefono}
                                    placeholder="(+39) 080 444 555 6"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"form-group container"}>
                        <button className="btn btn-primary btn-block shadow" type="submit">Salva modifiche</button>
                    </div>
                </form>
            </Fragment>
        )
    }

}
