import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {InputTimepicker} from "../../../../components/BaseComponents/InputTimepicker";
import moment from "moment";
import {SwipeablePanelGripBar} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {SwipeablePanel} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class EditStartTimePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fullbook: false,
            orario: this.props.orario,
            forceBook: false
        }
    }

    handleCheckboxChange = e => this.setState({[e.target.name]: e.target.checked});

    handleTimeChange = ({target, value}) => this.setState({orario: moment(value).format('HH:mm')});

    handleChoose = _ => this.props.onChoose({
        fullbook: this.state.fullbook,
        newOrario: this.state.orario,
        forceBook: this.state.forceBook
    });


    render() {
        return (
            <Fragment>
                <div onClick={this.props.onDismiss} style={BackdropStyle} className={"__androidDismissable__"}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.props.onDismiss}
                    disableSwipe={_ => false}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"container pt-3 bg-main-z2 py-5"}>
                        <h4 className={"font-weight-bold"}>Modifica orario</h4>
                        <p className={"text-muted"}>
                            Scegli il nuovo orario in cui avrà inizio questo servizio
                        </p>
                        <div className={"form-group"}>
                            <label>Nuovo orario</label>
                            <InputTimepicker
                                style={TimePickerStyle}
                                className={"border rounded shadow-sm bg-light text-dark mx-2 px-2"}
                                value={this.state.orario}
                                onChange={this.handleTimeChange}
                                name={"orario"}
                                startDate={moment(`${this.props.orario}`, 'HH:mm').toDate()}
                            />
                        </div>
                        <div className={"form-group"}>
                            <label>
                                <input type={"checkbox"} name={"forceBook"} value={1} checked={this.state.forceBook}
                                       onChange={this.handleCheckboxChange}/>
                                &nbsp;Ignora disponibilità
                            </label>
                        </div>
                        <div className={"form-group"}>
                            <label>
                                <input type={"checkbox"} name={"fullbook"} value={1} checked={this.state.fullbook}
                                       onChange={this.handleCheckboxChange}/>
                                &nbsp;Sposta l'intera prenotazione
                            </label>
                        </div>
                        <button className={"btn btn-block btn-primary"} onClick={this.handleChoose}>
                            <FaSolidIcon name={"clock"}/> Salva nuovo orario
                        </button>
                    </div>
                </SwipeablePanel>
            </Fragment>
        )
    }

}

EditStartTimePanel.propTypes = {
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func,
    orario: PropTypes.string
}


const TimePickerStyle = {
    width: 65
}