import moment from "moment";
import React from "react";

import AppConfig from "../../../config/AppConfig";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {ServiceBookResumePage} from "./ServiceBookResume/ServiceBookResumePage";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";


export default class ServiceBookCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            detailsOpen: false
        };

        this.styleOpacity = {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(30, 30, 30, 0.7)',
            fromNegozio: 0
        }
    }


    handleClick() {
        if (this.state.detailsOpen) {
            if (!this.props.isFullScreen) {
                //Se mi trovo nella view fullscreen degli appuntamenti non devo riabilitare lo scroll del body
                document.body.classList.remove('noscroll');
            }
        } else {
            document.body.classList.add('noscroll');
        }
        this.setState({detailsOpen: !this.state.detailsOpen});
    }

    getColorByDay(weekDay) {
        const colors = ["#ef5350", "#673ab7", "#2196f3", "#009688", "#4caf50", "#ffc107", "#ff5722"];
        return colors[weekDay];
    }

    getOtherServiceAmountLabel() {
        const num = this.props.book.otherServices.length;
        if (!num) return '';
        if (num === 1) {
            return ' (+1 altro)'
        } else {
            return ` (+${num} altri)`
        }
    }

    render() {
        var momentDate = moment(this.props.book.datetime_inizio);
        var m = momentDate.calendar(null, {
            sameDay: '[Oggi alle] HH:mm',
            nextDay: '[Domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[Ieri alle] HH:mm',
            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });

        var imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${this.props.book.id_negozio}/logoIcon/100`;

        return (
            <div>
                <div className={"card bg-main-z2 shadow-sm " + this.props.className}
                     style={{"borderLeft": `4px solid ${this.getColorByDay(momentDate.day())}`}}>
                    <div className={"card-body"}>
                        <div onClick={this.handleClick.bind(this)}>
                            <div className={"d-flex align-items-center"}>
                                <AppIconThumbnail size={"25px"} src={imageFullUrl} className={"mr-1"}/>
                                <small><SafeHtmlContainer html={this.props.book.nome_negozio}/></small>
                            </div>
                            {
                                //Se vengono passate come props stampa le informazioni sulla recensione
                                this.props.feedbackInformations
                            }
                            <b>
                                {this.props.book.nome_servizio || ''}
                                {this.props.book.nome_gruppo || ''}
                                {this.props.book.hasMultiple === "1" ? this.getOtherServiceAmountLabel() : ''}
                            </b>
                            <div className={"text-muted"}>{m[0].toUpperCase() + m.substr(1)}</div>
                        </div>
                        <div className={"row mt-2"}>
                            <div className={"col"}>
                                <button className={"btn btn-soft-secondary btn-block btn-sm"}
                                        onClick={this.handleClick.bind(this)}>
                                    Dettagli
                                </button>
                            </div>
                            {
                                this.props.showOpenApp &&
                                <div className={"col"}>
                                    <button className={"btn btn-soft-primary btn-block btn-sm"}
                                            onClick={_ => this.props.onAppOpen(this.props.book.id_negozio)}>
                                        Apri l'app
                                    </button>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <ModalPortalHOC>
                    {
                        !!this.state.detailsOpen &&
                        <ServiceBookResumePage
                            idPrenotazione={this.props.book.id_prenotazione}
                            onDismiss={this.handleClick.bind(this)}
                            showOpenApp={true}
                        />
                    }
                </ModalPortalHOC>
            </div>
        );
    }

}
