import React from "react";
import PropTypes from "prop-types";
import "./skeleton.css";

export function FuxPaginatorItemSkeleton(props) {
    const style = {
        width: props.width,
        height: props.height
    }
    return <div className={"fux-paginator-skeleton-placeholder"} style={style}/>
}

FuxPaginatorItemSkeleton.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
