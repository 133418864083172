import Page from "../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SuperHeroIcon} from "../../../img/illustrations/SuperHeroIcon";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import {MarketplaceCartShippingBar} from "../../Tabs/MarketplaceTab/Cart/MarketplaceCartShippingBar";
import {EcommerceShippingAddressBar} from "../../../components/Ecommerce/Checkout/EcommerceShippingAddressBar";
import {ECOMMERCE_ORDER_TYPE_DEFAULT} from "../../../const/ModuloEcommerceCostants";
import {PageFooter} from "../../../components/BaseComponents/Page/PageFooter";
import {swal, swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import {WelfareAPI} from "../../../api/Welfare/WelfareAPI";
import {DipendenteWelfareGiftCardsHistoryPage} from "./History/DipendenteWelfareGiftCardsHistoryPage";
import DipendenteWelfareGiftCardHistoryProductPage from "./History/DipendenteWelfareGiftCardHistoryProductPage";

export default class DipendenteWelfareGiftCardsOrderResumePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: null,
            openOrderPage: false,
            idOrdine: null
        }
    }

    handleShippingChange = address => this.setState({address: address});

    toggleHistoryPage = _ => this.setState({openOrderPage: !this.state.openOrderPage})

    handlePlaceOrder = _ => {
        if (!this.state.address) {
            return swalError("Inserisci un indirizzo, altrimenti la gift card non potrà essere acquistata correttamente")
        }
        swalLoading("Stiamo generando la gift card...")
        WelfareAPI.GiftCards.placeOrder(this.props.variant.id_variante, this.state.address)
            .then(response => {
                this.setState({
                    idOrdine: response.data.id_ordine
                }, _ => {
                    swalSuccess(response.message).then(this.toggleHistoryPage)
                })
            })
            .catch(msg => swalError(msg))
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Conferma il tuo acquisto"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div>
                            <div className={"mb-4"}>
                                <div className={"mx-auto w-25 my-3"}>
                                    <SuperHeroIcon className={"img-fluid"} color={"#293069"}/>
                                </div>
                                <h4 className={"text-center text-primary p-2"}>
                                    Hai quasi finito! <br/> Se necessario compila i dati mancanti!
                                </h4>

                                {/*INDIRIZZO*/}
                                <EcommerceShippingAddressBar
                                    onChange={this.handleShippingChange}
                                    placeholder={"Inserisci il tuo indirizzo"}
                                />

                                {/*RESUME*/}
                                <div className={"container mt-3"}>
                                    <b>Riepilogo:</b>
                                    <div className={"card card-body shadow-sm border-0"}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div><b>{this.props.product.name}</b></div>
                                            <div>€{parseFloat(this.props.variant.taglio).toFixed(2)}</div>
                                        </div>
                                        <hr/>
                                        <div className={"d-flex justify-content-between"}>
                                            <div><h4 className={"font-weight-bold"}>Totale:</h4></div>
                                            <div>€{parseFloat(this.props.variant.taglio).toFixed(2)}</div>
                                        </div>
                                        <div className={"text-muted"}><small>L'intero importo verrò pagato con il tuo
                                            credito welfare</small></div>
                                    </div>
                                </div>
                            </div>

                            <PageFooter className={"bg-white p-3 border-top shadow-sm"}>
                                <small className={"text-muted"}>
                                    Ricorda che puoi spendere <b>un massimo di €1000</b> in Gift Card, <b>il limite è esteso €2000</b> se hai figli a carico
                                </small>
                                <button className={"btn btn-primary btn-lg btn-block mt-2"} onClick={this.handlePlaceOrder}>
                                    Acquista
                                </button>
                            </PageFooter>
                        </div>
                    }
                />

                {/*Se l'ordine va a buon fine apre la history degli ordini*/}
                {
                    this.state.openOrderPage && this.state.idOrdine &&
                    <DipendenteWelfareGiftCardHistoryProductPage
                        idOrdine={this.state.idOrdine}
                        onDismiss={this.toggleHistoryPage}
                    />
                }
            </Fragment>
        )
    }
}

DipendenteWelfareGiftCardsOrderResumePage.propTypes = {
    onDismiss: PropTypes.func,
    active: PropTypes.bool,
    product: PropTypes.object,
    variant: PropTypes.object
}