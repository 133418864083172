import {SectorSeatType} from "./SectorSeatType";
import {SeatsBookType} from "./BookType";

import PropTypes from "prop-types";
import {MapSectorType} from "./MapSectorType";

export const LinkedBookType = PropTypes.shape({
    book: SeatsBookType,
    seat: SectorSeatType,
    sector: MapSectorType,
    accessories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        accessory_id: PropTypes.any,
        quantity: PropTypes.number,
        unit_price: PropTypes.number
    })),
    tickets: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        ticket_type_id: PropTypes.any,
        quantity: PropTypes.number,
        unit_price: PropTypes.number
    })),
    receipt: PropTypes.shape({
        receipt: PropTypes.object,
        rows: PropTypes.arrayOf(PropTypes.object),
        payments: PropTypes.arrayOf(PropTypes.object)
    })
});