import React, {Fragment, memo} from "react";
import {swal, swalError} from "../../helpers/SweetAlertWrapper";
import {FaSolidIcon} from "../FontAwesomeIcons";
import styled from "styled-components";
import "./FlyToCart.css";
import DOMPurify from "dompurify";
import "./ribbon.css";
import ProdottiAggiuntePage from "./ProdottiAggiuntePage";
import moment from "moment";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import {stripHtml} from "../../helpers/StringHelpers";
import AllergeniProdottiList from "../MenuDigitale/AllergeniProdottiList";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import {VietatoMinoriIcon} from "../MenuDigitale/MenuDigitaleCardProdotto";
import AppModalPortal from "../BaseComponents/AppModalPortal";
import ModalPortal from "../BaseComponents/ModalPortal";
import {parse} from "@fortawesome/fontawesome-svg-core";
import AppConfig from "../../config/AppConfig";
import {ProductInfoIcon} from "../../img/illustrations/ProductInfoIcon";
import {applyPercentage} from "../../helpers/NumberUtility";

const DescriptionPreviewContainer = styled.div`
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Badge = styled.span`
    & + span{
        margin-left:2px;
    }
`;

export default class FoodDeliveryProductCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quantita: 1,
            aggiuntePopoverOpen: false
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.quantita !== nextState.quantita ||
            this.state.aggiuntePopoverOpen !== nextState.aggiuntePopoverOpen;
    }

    handleImageClick = e => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onDetail();
    }

    handleValueChange = e => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleIncreaseQnt = _ => {
        this.setState({quantita: this.state.quantita + 1})
    }

    handleDecreaseQnt = _ => {
        this.setState({quantita: Math.max(0, this.state.quantita - 1)})
    }

    addProduct = _ => {
        if (!this.state.aggiuntePopoverOpen) { //Se sto aprendo il popover per aggiungere
            if (this.props.quantita < this.state.quantita) {
                swalError(`Ci sono solamente ${this.props.quantita} ${this.props.nome} disponibili`);
                return;
            }
        }

        //Verifico che posso eseguire almeno un'azione sul prodotto
        if (
            parseInt(this.props.abilita_note) ||
            parseInt(this.props.abilita_rimozioni) ||
            parseInt(this.props.richiesta_cottura) ||
            this.props.aggiunte.length ||
            this.props.opzioni.length
        ) {
            this.setState({aggiuntePopoverOpen: !this.state.aggiuntePopoverOpen});
        } else {
            this.handleAggiunteChoose('', [], [], [], '');
        }
    }

    handleAggiunteChoose = (id_opzione, aggiunte, rimozioni, attributi, note, uscita) => {
        const productData = {
            id_prodotto: this.props.id_prodotto,
            nome: this.props.nome,
            categoria_prodotto: this.props.categoriaProdotto,
            ingredienti: this.props.ingredienti,
            quantita: this.state.quantita,
            prezzo: this.props.prezzo,
            id_opzione: id_opzione,
            abilitaRimozioni: parseInt(this.props.abilita_rimozioni) === 1,
            abilitaNote: parseInt(this.props.abilita_note) === 1,
            aggiunte: aggiunte,
            rimozioni: rimozioni,
            attributi: attributi,
            note: note,
            perc_sconto: this.props.perc_sconto,
            uscita: uscita,
        };
        this.props.onAddToCart(productData);
        this.setState({quantita: 0, aggiuntePopoverOpen: false});
    }

    getProductDescription = _ => {
        if (this.props.ingredienti) {
            return <div><span className={"font-weight-bold"}>Ingredienti:</span> {this.props.ingredienti}</div>;
        } else if (this.props.descrizione) {
            return (
                <DescriptionPreviewContainer>
                    <SafeHtmlContainer html={stripHtml(this.props.descrizione)}/>
                </DescriptionPreviewContainer>
            );
        } else {
            return '';
        }
    }

    getCurrentDiscountPercentage = _ => this.props.perc_sconto ? parseFloat(this.props.perc_sconto) : 0.0;

    getDiscountLabel = _ => {
        const discount = this.getCurrentDiscountPercentage();
        if (discount) {
            return <span className={'badge badge-success'}>Sconto del {discount}%</span>
        }
        return '';
    }

    getDiscountNoticeLabel = _ => {
        if (this.getCurrentDiscountPercentage()) {
            return <span className={'small'}>
                invece di <span className={"text-primary text-line-through"}>{this.getCurrentPrice(true)}</span>
            </span>
        }
        return '';
    }

    getCurrentPrice = withoutDiscount => {
        const prezzo = applyPercentage(parseFloat(this.props.prezzo), withoutDiscount ? 0 : -this.getCurrentDiscountPercentage());
        return prezzo > 0 ? `€${prezzo.toFixed(2)}` : '';
    }


    render() {
        const Portal = document.getElementById('app-modal-root') ? AppModalPortal : ModalPortal;
        this.grayscale = parseInt(this.props.disponibilita) === 0 || parseInt(this.props.quantita) <= 0;
        return (
            <div>
                <div className={"card bg-main-z2 my-2 overflow-hidden text-on-bg-main"}>
                    <div className={"card-body"}>
                        {
                            parseInt(this.props.disponibilita) === 0 ?
                                <div className={"corner-ribbon top-right grey shadow"}>Non disponibile</div>
                                :
                                (parseInt(this.props.quantita) <= 0 ?
                                    <div className={"corner-ribbon top-right grey shadow"}>Esaurito</div> : '')
                        }
                        {parseInt(this.props.vietato_minori) === 1 && <VietatoMinoriIcon/>}
                        <div className={"row align-items-center"}>
                            <div className={'col-8'}>
                                <b>{this.props.nome}</b>
                                <br/>
                                <div>{this.getDiscountLabel()}</div>
                                <b className={"text-primary"} style={{verticalAlign: 'middle'}}>
                                    {this.getCurrentPrice()} {this.getDiscountNoticeLabel()}
                                </b>
                                &nbsp;
                                {parseInt(this.props.minimo_ordinabile) > 0 ? <span
                                    className={"badge badge-primary"}>{`Min. ${this.props.minimo_ordinabile}`}</span> : ''}&nbsp;
                                {parseInt(this.props.massimo_ordinabile) > 0 ? <span
                                    className={"badge badge-primary"}>{`Max. ${this.props.massimo_ordinabile}`}</span> : ''}&nbsp;
                            </div>

                            {/* Immagine prodotto */}
                            {
                                this.props.immagine && <div className={"col p-0 text-center"}>
                                    <ProductImageSC
                                        onClick={this.handleImageClick}
                                        className={"mx-auto shadow " + (this.grayscale ? 'greyscale' : '')}
                                        image={getCompressedImageUrl(this.props.immagine, 300, 300)}
                                        icon={"\\f06e"}
                                        backgroundColor={this.props.primaryColor}
                                        color={this.props.textOnPrimaryColor}
                                    />
                                </div>
                            }

                            {/* Immagine di default del sistema */}
                            {
                                !this.props.immagine && <div className={"col p-0 text-center"}>
                                    <ProductImageSC
                                        className={"mx-auto"}
                                        onClick={this.props.onDetail}
                                        backgroundColor={this.props.secondaryColor}
                                        color={this.props.textOnSecondaryColor}
                                        icon={"\\f06e"}
                                        small={true}
                                    >
                                        <ProductInfoIcon
                                            color={this.props.bgMain}
                                            bgColor={this.props.secondaryColor}
                                        />
                                    </ProductImageSC>
                                </div>
                            }

                            {
                                /* Barra quantità */

                                parseInt(this.props.quantita) > 0 && parseInt(this.props.disponibilita) === 1 ?
                                    <div className={"col-12 mt-2"}>
                                        <div className={"row"}>
                                            <div className={"col-2 p-1"}>
                                                <button className={"btn btn-secondary btn-block btn-sm"}
                                                        onClick={this.handleDecreaseQnt}>
                                                    <FaSolidIcon name={"minus"}/>
                                                </button>
                                            </div>
                                            <div className={"col-3 p-1"}>
                                                <input type={"number"}
                                                       className={"form-control form-control-sm text-center"}
                                                       value={this.state.quantita}
                                                       name={"quantita"}
                                                       onChange={this.handleValueChange}/>
                                            </div>
                                            <div className={"col-2 p-1"}>
                                                <button className={"btn btn-secondary btn-block btn-sm"}
                                                        onClick={this.handleIncreaseQnt}>
                                                    <FaSolidIcon name={"plus"}/>
                                                </button>
                                            </div>
                                            <div className={"col-5 p-1"}>
                                                <button className={"btn btn-primary btn-block btn-sm"}
                                                        onClick={this.addProduct}
                                                        disabled={!this.state.quantita}
                                                >
                                                    <FaSolidIcon name={"shopping-cart"}/> Aggiungi
                                                </button>
                                            </div>
                                        </div>
                                    </div> : ''
                            }

                            { /* Descrizione prodotto */
                                !this.props.ingredienti && !stripHtml(this.props.descrizione) && (!this.props.badges || !this.props.badges.length) ? '' :
                                    <div className={"border-top mt-3 pt-3 w-100"}>
                                        <div className={"col-12"}>
                                            {
                                                this.props.badges ?
                                                    this.props.badges.map((b, i) => {
                                                        return (
                                                            <Badge key={i} className={"badge badge-primary mb-2"}>
                                                                {b}
                                                            </Badge>
                                                        );
                                                    })
                                                    : ''
                                            }
                                        </div>
                                        <div className={"col-12"}>
                                            <small>{this.getProductDescription()}</small>
                                        </div>
                                    </div>
                            }

                            {
                                !!this.props.allergeni && !!this.props.allergeni.length &&
                                <div className={"container"}>
                                    <div className={"d-flex align-items-center"}>
                                        <small className={"font-weight-bold small mr-2"}>Allergeni presenti:</small>
                                        <AllergeniProdottiList
                                            allergeni={this.props.allergeni}
                                            icon={true}
                                            text={false}
                                            detailPage={true}
                                            size={30}
                                        />
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                <Portal>
                    {
                        this.state.aggiuntePopoverOpen && <ProdottiAggiuntePage
                            active={this.state.aggiuntePopoverOpen}
                            onDismiss={this.addProduct}
                            onChoose={this.handleAggiunteChoose}
                            title={this.props.nome}
                            subtitle={!!this.props.ingredienti &&
                            <SafeHtmlContainer html={`Ingredienti: ${this.props.ingredienti}`}/>}
                            aggiunte={this.props.aggiunte}
                            opzioni={this.props.opzioni}
                            cottura={this.props.richiesta_cottura}
                            abilitaRimozioni={parseInt(this.props.abilita_rimozioni) === 1}
                            abilitaNote={parseInt(this.props.abilita_note) === 1}
                            abilitaUscita={this.props.abilitaUscita}
                            continueButtonText={"Aggiungi al carrello"}
                            resetOnContinue={true}
                        />
                    }
                </Portal>
            </div>
        );
    }

}


const ProductImageSC = styled.div`
    width:${props => props.small ? '70px' : '90px'};
    height:${props => props.small ? '70px' : '90px'};
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    background-image:url(${props => props.image});
    border-radius:.50rem;
    position:relative;

    &.greyscale{
        filter:grayscale(1);
    }
    
    &:after{
        position:absolute;
        bottom:-5px;
        right:-5px;
        text-align:center;
        padding:2px 4px;
        font-family: "Font Awesome 5 Free";
        background-color: ${props => props.backgroundColor};
        color: ${props => props.color};
        content: "${props => props.icon}";
        z-index:1;
        border-top-left-radius:.25rem;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
`;
