import React, {Fragment} from "react";
import styled from "styled-components";
import ComandeProductCartItem from "./ComandeProductCartItem";
import {CartIcon} from "../../../../img/illustrations/CartIcon";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {FoodCartProductPriceReduce} from "../../../../components/FoodDelivery/Utils/FoodCartProductPriceReduce";


const ShoppingCartIcon = styled.div`
    position:fixed;
    width:64px;
    height:64px;
    bottom:20px;
    left:20px;
    z-index:2000;
    font-size:1.2em;
`;

const ShoppingCartBackdrop = styled.div`
    position:fixed; top:0; left:0; width:100%; height: 100vh; z-index: 2000; background-color: rgba(0,0,0,0.6);
`;

const ShoppingCartContainer = styled.div`
    position:fixed; top: 1rem; left:1rem; right:1rem; bottom: 100px; z-index:2000; overflow-x:hidden; overflow-y:auto;
    padding-bottom:103px!important; /* serve per escludere il footer dallo scroll */
    
    &:after{
        content: ' ';
        background-color:white;
        width:20px;
        height:20px;
        position:fixed; bottom:94px; left:42px;
        z-index:2000;
        transform:rotate(45deg);
        -webkit-transform:rotate(45deg);
    }
`;

const ShoppingCartFooter = styled.div`
    position:fixed; bottom:100px; left:20px; right:20px; z-index:2001;
`;

export default class ComandeShoppingCart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shoppingCartOpen: false
        }
    }

    toggleShoppingCart = _ => {
        this.setState({shoppingCartOpen: !this.state.shoppingCartOpen});
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.shoppingCartOpen && this.props.cartVisible ?
                        [
                            <ShoppingCartBackdrop className={"__androidDismissable__"} key={"cart-backdrop"}
                                                  onClick={this.toggleShoppingCart}/>,
                            <ShoppingCartContainer key={"cart-container"}
                                                   className={"bg-main-z2 rounded shadow p-3 text-on-bg-main"}>
                                <h3>Il mio carrello</h3>
                                {
                                    !this.props.cartProducts.length &&
                                    <div>
                                        <div className={"mx-auto mt-5 mb-3"} style={{width: "40%"}}>
                                            <CartIcon className={"img-fluid"} color={this.props.floatingCartColor}/>
                                        </div>
                                        <h5 className={"text-center text-primary"}>
                                            Il tuo carrello aspetta solo di essere riempito, non deluderlo!
                                        </h5>
                                    </div>
                                }
                                <div className={"list-group list-group-flush"}>
                                    {
                                        this.props.cartProducts.map((p, i) => {
                                            return <ComandeProductCartItem
                                                key={i}
                                                {...p}
                                                onUpdate={this.props.onUpdateCart}
                                                onEdit={this.props.onProductEdit}
                                            />
                                        })
                                    }
                                </div>
                                <ShoppingCartFooter className={"p-3 bg-white border-top"}>
                                    <button className={"btn btn-primary btn-block"}
                                            disabled={!this.props.cartProducts.length}
                                            onClick={this.props.onContinue}>
                                        {this.props.onContinueText || "Continua"}&nbsp;
                                        (€{this.props.cartProducts.reduce(FoodCartProductPriceReduce, 0).toFixed(2)})
                                    </button>
                                    {this.props.additionalFooter}
                                </ShoppingCartFooter>
                            </ShoppingCartContainer>
                        ]
                        : ''
                }
                {
                    this.props.floatingIconVisible &&
                    <ShoppingCartIcon
                        onClick={this.toggleShoppingCart}
                        className={"shadow bg-secondary text-on-bg-secondary rounded-circle d-flex align-items-center justify-content-center"}>
                        <FaSolidIcon name={"shopping-basket"}
                                     badge={this.props.cartProducts.reduce((acc, p) => {
                                         return acc + p.quantita;
                                     }, 0)}
                                     badgeColor={"red"}/>
                    </ShoppingCartIcon>
                }
            </Fragment>
        );
    }
}


