import React, {Fragment} from "react";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import Page from "../../../components/BaseComponents/Page/Page";
import {swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import IndirizziAPI from "../../../api/IndirizziAPI";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {ADDRESS_BOOK_CHANGE} from "../../../const/AppEventsCostants";
import RimborsiDipendenteAPI from "../../../api/Welfare/RimborsiDipendenteAPI";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";
import moment from "moment";
import WalletDipendentiAPI from "../../../api/Welfare/WalletDipendenteAPI";

export default class DipendenteWelfareDetailsTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            note_personali: this.props.transazione.note_personali ?? ''
        }

        this.state = {...this.state};

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(event) {
        event.preventDefault();
        swalLoading();
        WalletDipendentiAPI.salvaNotePersonali(this.props.user.walletapikey, this.props.transazione.id_transazione, this.state.note_personali)
            .then((message) => {
                swalSuccess(message);
                this.props.onDismiss();
            })
            .catch(swalError)

    }


    handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }


    handleIconInOrOut =_=>{
        if(this.props.transazione.id_wallet_from === this.props.dipendente.id_wallet){
            return <h1 className={'text-danger text-lg'}><i className={'fas fa-arrow-circle-up'}></i></h1>;
        }else{
            return <h1 className={'text-success text-lg'}><i className={'fas fa-arrow-circle-down'}></i></h1>;
        }
    }


    handldeSubtitle =_=>{
        if(this.props.transazione.id_wallet_from === this.props.dipendente.id_wallet){
            return <h6 className={'text-danger text-lg'}>Transazione in uscita</h6>
        }else{
            return <h6 className={'text-success text-lg'}>Transazione in entrata</h6>
        }
    }


    handleImportoColor =_=>{
        if(this.props.transazione.id_wallet_from === this.props.dipendente.id_wallet){
            return <h4 className={'text-danger text-lg'}>- €{this.props.transazione.importo}</h4>
        }else{
            return <h4 className={'text-success text-lg'}>+ €{this.props.transazione.importo}</h4>
        }
    }

    render() {

        var momentDate = moment(this.props.transazione.data_creazione);
        var m = momentDate.calendar(null, {
            sameDay: '[Oggi alle] HH:mm',
            nextDay: '[Domani alle] HH:mm',
            nextWeek: 'dddd DD [alle] HH:mm',
            lastDay: '[Ieri alle] HH:mm',
            lastWeek: '[Lo scorso] dddd [alle] HH:mm',
            sameElse: 'dddd D MMM [alle] HH:mm'
        });

        return (
            <ModalPortal>
                <Page
                    title={"Dettagli Transazione"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div className={"container pt-4"}>
                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    {this.handleIconInOrOut()}
                                    {this.handldeSubtitle()}
                                    {this.handleImportoColor()}
                                </div>

                                <div className={"col-12 text-center"}>
                                    <div className={"text-muted"}>{m[0].toUpperCase() + m.substr(1)}</div>
                                </div>
                            </div>

                            <div className={"card mt-4"}>
                                <div className={"card-body"}>
                                    <b>{this.props.transazione.denominazione}</b>
                                    <br/>
                                    <span className={"text-muted"}>{this.props.transazione.descrizione}</span>

                                    <form onSubmit={this.handleSubmit} className={"from-group mt-4"}>
                                        <label>Note personali</label>
                                        <textarea className={"form-control"} name="note_personali"
                                                  placeholder={"Inserisci una descrizione (opzionale)"}
                                                  value={this.state.note_personali}
                                                  onChange={this.handleInputChange}/>
                                        <div className={"col-12 mt-2 text-right"}>
                                            <button className="btn btn-primary" type="submit">Salva</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    }
                />
            </ModalPortal>
        );
    }
}