import React from "react";
import ReactDOM from 'react-dom';

export const isAppModalPortalEnabled = _ => !!document.getElementById("app-modal-root");

export default class AppModalPortal extends React.Component {

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.modalRoot = document.getElementById("app-modal-root");
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        if (this.modalRoot && this.modalRoot.contains(this.el)) {
            this.modalRoot.removeChild(this.el);
        }
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        )
    }

}
