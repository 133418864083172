import FuxHTTP from "../../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../../APIConfig";

export const _Booking = {
    /**
     * Return a price detail object for the given booking intent
     *
     * @param {Number} map_id
     * @param {String} start_date
     * @param {String} end_date
     * @param {String} start_time
     * @param {String} end_time
     * @param {Number[]} seats An array of seats IDs
     * @param {Object<Object>} accessories each 1st-level key represent a seat_id, each 2nd-level keys represent an
     * accessory_id and it's value is the desired quantity
     * @param {Object<Object> | null} tickets each 1st-level key represent a seat_id, each 2nd-level keys represent a
     * ticket_type_id and it's value is the desired quantity
     *
     * */
    getPrices: (map_id, start_date, end_date, start_time, end_time, seats, accessories, tickets) => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/booking/get-prices`), {
            intent_b64: btoa(JSON.stringify({
                map_id: map_id,
                start_date: start_date,
                end_date: end_date,
                start_time: start_time,
                end_time: end_time,
                seats: seats,
                accessories: accessories,
                tickets: tickets
            }))
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    },
    /**
     * Return a price detail object for the given booking intent
     *
     * @param {Number} map_id
     * @param {String} start_date
     * @param {String} end_date
     * @param {String} start_time
     * @param {String} end_time
     * @param {Number[]} seats An array of seats IDs
     * @param {Object<Object>} accessories each 1st-level key represent a seat_id, each 2nd-level keys represent an
     * accessory_id and it's value is the desired quantity
     * @param {Object<Object> | null} tickets each 1st-level key represent a seat_id, each 2nd-level keys represent a
     * ticket_type_id and it's value is the desired quantity
     * @param {String} token The user access token
     * @param {Boolean} force_advance_payment wether the user want to pay in advance the whole reservation
     * @param {String} coupon The coupon code
     * @param {String} notes User reservation notes
     *
     * */
    book: (map_id, start_date, end_date, start_time, end_time, seats, accessories, tickets, token, force_advance_payment, coupon, notes) => {
        return FuxHTTP.post(APIConfig.url(`/modulo-seats/booking/book`), {
            map_id: map_id,
            start_date: start_date,
            end_date: end_date,
            start_time: start_time,
            end_time: end_time,
            seats: seats,
            accessories: accessories,
            tickets: tickets,
            token: token,
            force_advance_payment: force_advance_payment ? 1 : 0,
            coupon: coupon,
            notes: notes
        }, FuxHTTP.RESOLVE_RESPONSE, FuxHTTP.REJECT_MESSAGE);
    },
    cancelBookWithPaymentId: function (payment_reference, token) {
        return FuxHTTP.post(APIConfig.url(`/modulo-seats/booking/cancel-book-with-payment-id`), {
            token: token,
            payment_reference: payment_reference
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_RESPONSE);
    }
}
