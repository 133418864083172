import React from "react";
import styled from "styled-components";
import './style.css';
import {shadeColor} from "../../../helpers/UIUtility";
import PropTypes from "prop-types";


const Wrapper = styled.div`
        & div:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.6s infinite;
            background: ${props => shadeColor(props.color, 100)};
        }
        & div:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.6s infinite;
            background: ${props => shadeColor(props.color, 50)};
        }
        & div:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.6s infinite;
            background: ${props => props.color};
        }
        & div:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.6s infinite;
            background: ${props => props.color};
        }
    `;

export function EllipsisLoader(props) {
    return <Wrapper className="lds-ellipsis" color={props.color || '#293069'}>
        <div/>
        <div/>
        <div/>
        <div/>
    </Wrapper>
}

EllipsisLoader.propTypes = {
    color: PropTypes.string,
}
