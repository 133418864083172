import React, {Fragment} from "react";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import NegoziPartnershipAPI from "../../../api/NegoziPartnership/NegoziPartnershipAPI";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import {PROMOTION_CLICKED, OPEN_APP_REQUEST} from "../../../const/AppEventsCostants";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import AppConfig from "../../../config/AppConfig";
import StaticRating from "../../Recensioni/StaticRating";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import styled from "styled-components";
import moment from "moment";
import {array, func, string} from "prop-types";
import PatternForPromotion from "./PatternForPromotion";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";

export default class PromotionsItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            previewOpen: false,
            infoNegozio: null
        }
        this.containerOfPromotion = {
            borderRadius: '10px',
            minWidth: '55vw',

            backgroundColor: 'white'
        }
        this.appNameContainer = {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '75%',
            right: '0'
        }
        this.nomeCategoria = {
            display: 'block',
            marginTop: '-2px',
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    }

    convertDayStringInArray = string => {
        return JSON.parse("[" + string + "]");
    }

    printPromotionValidity = _ => {
        var stringToPrint = ""
        if (this.props.tipo) {
            if (this.props.tipo === "weekly") {
                var arrayOfDays = this.convertDayStringInArray(this.props.weekday);
                if (arrayOfDays.length === 7) stringToPrint = "Tutti i giorni";
                if (arrayOfDays.length > 4 && arrayOfDays.length < 7) {
                    stringToPrint = "Tutti i giorni tranne "
                    for (var i = 0; i < 7; i++) {
                        if (!arrayOfDays.includes(i)) {
                            stringToPrint += moment().day(i).format("dddd").capitalize() + " "
                        }
                    }
                }
                if (arrayOfDays.length <= 4) {
                    for (var i = 0; i < 7; i++) {
                        if (arrayOfDays.includes(i)) {
                            stringToPrint += moment().day(i).format("dddd").capitalize() + " "
                        }
                    }
                }
            }

            if (this.props.tipo === "daterange") {
                stringToPrint = "Dal " + moment(this.props.data_inizio).format("DD MMM") +
                    " al " + moment(this.props.data_fine).format("DD MMM")
            }
        } else {
            stringToPrint = "Da " + this.props.gg_prima + " giorni prima " + this.props.gg_dopo + " giorni dopo"
        }

        return stringToPrint
    }

    handleOnPromotionClick = _ => {
        FuxEvents.emit(PROMOTION_CLICKED, this.props);
    }

    handleStopPropagation = e => {
        e.stopPropagation()
    }

    render() {
        return (
            <Fragment>
                <div style={this.containerOfPromotion} className={"mx-3 shadow-sm"}
                     onClick={_ => this.handleOnPromotionClick()}>
                    <ScontoContainer
                        id_promozione={this.props.id_promozione}
                        sconto={this.props.percentuale}
                        aspetto={this.props.aspetto}
                        immagine={this.props.immagine}
                        mostra_percentuale={this.props.mostra_percentuale}
                    />
                    <ScontoLabel
                        sconto={this.props.percentuale}
                        mostra_percentuale={this.props.mostra_percentuale}
                    />
                    <div className={"d-flex p-3 align-items-center"}>
                        <AppIconThumbnail
                            className={"mr-2 bg-white shadow-sm"}
                            size={"35px"}
                            src={`${AppConfig.webServerUrl}/api/negozio/${this.props.id_negozio}/logoIcon/100`}
                            circle={true}
                        />
                        <div style={this.appNameContainer}>
                            <span className={"text-truncate"}>{this.props.nome_negozio}</span><br/>
                            <small style={this.nomeCategoria}>{this.props.categoria_negozio}&nbsp;</small>
                        </div>
                    </div>
                    <div className={"px-3 pt-2 pb-3"} style={{lineHeight: "normal"}}>
                        <small>{this.props.gg_prima ? "Per il tuo compleanno!" : "Valida"}</small><br/>
                        <small className={"text-muted"}>{this.printPromotionValidity()}</small>
                    </div>
                    {
                        this.props.condizioni &&
                        <div className={"px-3 pt-2 pb-3"} style={{lineHeight: "normal"}}>
                            <small>Condizioni d'uso</small><br/>
                            <small className={"text-muted"}><SafeHtmlContainer html={this.props.condizioni}/></small>
                        </div>
                    }
                </div>
                {/*}
                <CardOfApp className={"card my-2 " + (this.props.id_lista ? "ml-2" : "")}
                           onClick={_ =>this.handleOnAppClick(this.props.id_negozio)}
                >
                    <div className={"card-body text-center shadow-sm px-1 py-2"}>
                        <AppIconThumbnail
                            className={"my-2 bg-white shadow"}
                            size={"60px"}
                            src={`${AppConfig.webServerUrl}/api/negozio/${this.props.id_negozio}/logoIcon/100`}
                            circle={true}
                        />
                        <AppNameContainer>
                            <span className={"text-truncate w-100"}>{this.props.nome}</span><br/>
                            <small>{this.props.categoria}&nbsp;</small>
                        </AppNameContainer>
                */}
                {/* Rating */}
                {/*}
                        <div className={"container mt-0 py-2"}>
                            {
                                (!!this.props.valutazione) ?
                                    <div className={"d-flex align-items-center justify-content-center"}>
                                        {
                                            !!this.props.valutazione &&
                                            <Fragment>
                                                <small>
                                                    <StaticRating actualRating={parseInt(this.props.valutazione)} maxRating={5}/>
                                                </small>
                                            </Fragment>
                                        }

                                    </div>
                                    :
                                    <div className={"d-flex align-items-center justify-content-center"}>
                                        <small style={{visibility: "hidden"}}>
                                            <StaticRating actualRating={parseInt("5")} maxRating={5}/>
                                        </small>
                                    </div>
                            }
                        </div>

                        <div className={"my-1"}>
                            <div className={"row justify-content-center"}>

                                {
                                    this.props.sedi[0].telefono &&
                                    <div className={"col-3 text-center px-1"}>
                                        <a href={`tel:${this.props.sedi[0].telefono}`} onClick={this.handleStopPropagation}>
                                            <PulsanteCircle
                                                className={"d-flex align-items-center justify-content-center mx-auto text-primary"}>
                                                <FaSolidIcon name={"phone"}/>
                                            </PulsanteCircle>
                                        </a>
                                    </div>
                                }

                                {
                                    this.props.sedi[0].email &&
                                    <div className={"col-3 text-center px-1"}>
                                        <a href={`mailto:${this.props.sedi[0].email}`} onClick={this.handleStopPropagation}>
                                            <PulsanteCircle
                                                className={"d-flex align-items-center justify-content-center mx-auto text-primary"}>
                                                <FaSolidIcon name={"envelope"}/>
                                            </PulsanteCircle>
                                        </a>
                                    </div>
                                }

                                {
                                    this.props.sedi[0] &&
                                    <div className={"col-3 text-center px-1"}>
                                        <a href={this.getMapLink(this.props.sedi[0])} onClick={this.handleStopPropagation}>
                                            <PulsanteCircle
                                                className={"d-flex align-items-center justify-content-center mx-auto text-primary"}>
                                                <FaSolidIcon name={"map-marker-alt"}/>
                                            </PulsanteCircle>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </CardOfApp>
                */}
            </Fragment>
        )
    }

}


export const PatternContainer = styled.div`
    border-radius: 10px 10px 0px 0px;
    position: relative;
    width: 100%;
    height: 25vh;
    line-height: 25vh;
    border: 0;
    text-align: center;
    background-color: ${props => (props.immagine ? 'transparent' : props.primary_color)};
    background-image: ${props => (props.immagine ? `url(${props.immagine})` : 'none')};
    background-size: cover;
    background-position: center;
    color: ${props => props.text_on_primary_color};
    padding-top: auto;
`;

function ScontoContainer(props) {

    function promotionNumber() {
        var lastDigit = props.id_promozione % 10;
        if (lastDigit > 0 && lastDigit < 6) return lastDigit
        lastDigit = lastDigit == 0 ? 10 : lastDigit
        return Math.trunc(lastDigit / 2)
    }

    return (
        <PatternContainer
            primary_color={props.aspetto.primary_color}
            text_on_primary_color={props.aspetto.text_on_primary_color}
            immagine={props.immagine} // Pass the image prop here
        >
            {!props.immagine && <PatternForPromotion pattern={promotionNumber()}/>}

            {
                props.mostra_percentuale > 0 ? <div>
                    {
                        props.sconto < 16 &&
                        <span style={{fontSize: '30px', position: 'relative'}} className={"mt-n3"}>
                            <b>-{props.sconto}%</b>
                        </span>
                    }
                    {
                        props.sconto > 15 && props.sconto < 50 &&
                        <span style={{fontSize: '40px', position: 'relative'}} className={"mt-n3"}>
                            <b>-{props.sconto}%</b>
                        </span>
                    }
                    {
                        props.sconto > 49 &&
                        <span style={{fontSize: '40px', position: 'relative'}} className={"mt-n3"}>
                            <b>-{props.sconto}%</b>
                        </span>
                    }
                </div> : ""
            }
        </PatternContainer>
    )
}

export const LabelScontoContainer = styled.div`
    width: 90%;
    background-color: white;
    text-align: center;
    border-radius: 20px;
    margin: auto;
    position: relative
`;

function ScontoLabel(props) {

    let msg = ""
    if(props.sconto < 16) msg = "Bello sconto! 🎉"
    if(props.sconto > 15 && props.sconto < 50)msg = "Super offerta! 🤩"
    if(props.sconto > 49) msg = "Da non perdere! 😱"

    if(props.mostra_percentuale < 1) msg = msg + " (-"+props.sconto+"%)"

    return (
        <LabelScontoContainer className={"p-1 shadow-sm mt-n3"}>
            <span>{msg}</span>
        </LabelScontoContainer>
    )
}