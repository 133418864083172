import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {InputTimepicker} from "../../../../components/BaseComponents/InputTimepicker";
import moment from "moment";
import {UserAdminChatAPI} from "../../../../api/Chat/UserAdminChatAPI";

export class ChatSettingsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chatEnabled: this.props.chatSettings.chat_abilitata || false,
            orarioAssenzaEnabled: this.props.chatSettings.chat_orario_assenza_abilitato || false,
            orarioAssenzaInizio: this.props.chatSettings.chat_orario_assenza_inizio || '21:00',
            orarioAssenzaFine: this.props.chatSettings.chat_orario_assenza_fine || '07:00',
            messaggioAssenza: this.props.chatSettings.chat_messaggio_assenza || '',
            messaggioAssenzaEnabled: this.props.chatSettings.chat_messaggio_assenza_abilitato || false,
            saveFailed: false
        }
        this.saveTimeout = null;
    }

    handleCheckboxChange = ({target}) => {
        this.setState({[target.name]: target.checked}, this.enqueueSaveRequest);
    }

    handleTimeChange = ({target, value}) => {
        this.setState({
            [target.name]: moment(value).format('HH:mm')
        }, this.enqueueSaveRequest);
    }

    handleInputChange = ({target}) => {
        this.setState({[target.name]: target.value}, this.enqueueSaveRequest);
        target.style.height = 'auto';
        target.style.height = Math.max(target.scrollHeight, 52) + 'px';
    }

    saveSettings = _ => {
        this.setState({saveFailed: false});
        const settings = {
            chat_abilitata: this.state.chatEnabled ? 1 : 0,
            chat_orario_assenza_abilitato: this.state.orarioAssenzaEnabled ? 1 : 0,
            chat_orario_assenza_inizio: this.state.orarioAssenzaInizio,
            chat_orario_assenza_fine: this.state.orarioAssenzaFine,
            chat_messaggio_assenza: this.state.messaggioAssenza,
            chat_messaggio_assenza_abilitato: this.state.orarioAssenzaEnabled && this.state.messaggioAssenzaEnabled ? 1 : 0
        }
        UserAdminChatAPI.saveSettings(settings)
            .catch(_ => {
                this.setState({saveFailed: true});
            });
    }

    enqueueSaveRequest = _ => {
        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
        }
        this.saveTimeout = setTimeout(this.saveSettings, 1000);
    }

    getTextareaMessaggioRef = node => {
        if (node) {
            node.style.height = Math.max(52, node.scrollHeight) + 'px';
        }
    }

    render() {
        return (
            <Fragment>
                {/** @MARK Abilitazione chat */}
                <div className={"form-group mt-3"}>
                    <div className={"bg-white border-top border-bottom px-3 py-2"}>
                        <div className="custom-control custom-switch">
                            <input type={"checkbox"} className={"custom-control-input"} id={"chatEnabledSwitch"}
                                   checked={this.state.chatEnabled}
                                   name={"chatEnabled"}
                                   onChange={this.handleCheckboxChange}
                            />
                            <label className="custom-control-label" htmlFor="chatEnabledSwitch">
                                Abilita chat in app
                            </label>
                        </div>
                    </div>
                    <div className={"small text-muted px-3"}>
                        Abilitando questa opzione i tuoi clienti potranno scriverti usando il pulsante "Chatta con noi"
                        che si attiverà nella tua app di Bookizon
                    </div>
                </div>

                {/** @MARK Orario di assenza */}
                <div className={"form-group mt-3"}>
                    <div className={"bg-white border-top border-bottom px-3 py-2"}>
                        <div className="custom-control custom-switch">
                            <input
                                type={"checkbox"}
                                className={"custom-control-input"}
                                id={"chatOrarioAssenzaSwitch"}
                                checked={this.state.orarioAssenzaEnabled}
                                name={"orarioAssenzaEnabled"}
                                onChange={this.handleCheckboxChange}
                            />
                            <label className="custom-control-label" htmlFor="chatOrarioAssenzaSwitch">
                                Orario di assenza
                            </label>
                        </div>
                        {
                            this.state.orarioAssenzaEnabled &&
                            <div>
                                Dalle
                                <InputTimepicker
                                    style={TimePickerStyle}
                                    className={"border rounded shadow-sm bg-light text-dark mx-2 px-2"}
                                    value={this.state.orarioAssenzaInizio}
                                    onChange={this.handleTimeChange}
                                    name={"orarioAssenzaInizio"}
                                    startDate={moment(`${this.props.chatSettings.chat_abilitata_orario_inizio || '21:00'}`, 'HH:mm').toDate()}
                                />
                                alle
                                <InputTimepicker
                                    style={TimePickerStyle}
                                    className={"border rounded shadow-sm bg-light text-dark mx-2 px-2"}
                                    value={this.state.orarioAssenzaFine}
                                    onChange={this.handleTimeChange}
                                    name={"orarioAssenzaFine"}
                                    startDate={moment(`${this.props.chatSettings.chat_abilitata_orario_fine || '07:00'}`, 'HH:mm').toDate()}
                                />
                            </div>
                        }
                    </div>
                    <div className={"small text-muted px-3"}>
                        Non riceverai notifiche dei messaggi nella fascia oraria selezionata
                    </div>
                </div>


                {/** @MARK Messaggio di assenza */}
                {
                    this.state.orarioAssenzaEnabled &&
                    <div className={"form-group mt-3"}>
                        <div className={"bg-white border-top border-bottom px-3 py-2"}>
                            <div className="custom-control custom-switch">
                                <input
                                    type={"checkbox"}
                                    className={"custom-control-input"}
                                    id={"messaggioAssenzaSwitch"}
                                    checked={this.state.messaggioAssenzaEnabled}
                                    name={"messaggioAssenzaEnabled"}
                                    onChange={this.handleCheckboxChange}
                                />
                                <label className="custom-control-label" htmlFor="messaggioAssenzaSwitch">
                                    Risposta automatica quando assente
                                </label>
                            </div>
                        </div>
                        <div className={"small text-muted px-3"}>
                            Invia una risposta automatica a tutti i tuoi clienti quanto ti scrivono
                            dalle {this.state.orarioAssenzaInizio} alle {this.state.orarioAssenzaFine}
                        </div>
                    </div>
                }

                {
                    this.state.messaggioAssenzaEnabled &&
                    <div className={"form-group"}>
                        <div className={"bg-white border-top border-bottom px-3 py-2"}>
                            <textarea
                                ref={this.getTextareaMessaggioRef}
                                name={"messaggioAssenza"}
                                className={"w-100 border-0 rounded-0"}
                                onChange={this.handleInputChange}
                                value={this.state.messaggioAssenza}
                                placeholder={"Inserisci qui il tuo messaggio da inviare ai tuoi clienti quando sei assente"}
                            />
                        </div>
                    </div>
                }

                {
                    this.state.saveFailed &&
                    <div className={"small text-center mt-5"}>
                        Salvataggio fallito<br/>
                        <button className={"btn btn-link btn-sm text-danger"} onClick={this.saveSettings}>
                            Riprova
                        </button>
                    </div>
                }
            </Fragment>
        )
    }

}

ChatSettingsView.propTypes = {
    chatSettings: PropTypes.shape({
        chat_abilitata: PropTypes.bool,
        chat_orario_assenza_abilitato: PropTypes.bool,
        chat_abilitata_orario_inizio: PropTypes.string,
        chat_abilitata_orario_fine: PropTypes.string,
        chat_messaggio_assenza: PropTypes.string,
        chat_messaggio_assenza_abilitato: PropTypes.bool
    }).isRequired
}


const TimePickerStyle = {
    width: 65
}