import React from "react";
import PropTypes from "prop-types";

export const PluralString = props => <span>
    {
        parseFloat(props.n) > 1 ?
            props.more
            :
            props.zero && parseFloat(props.n) === 0 ?
                props.zero
                :
                props.single
    }
</span>

export const pluralString = (n, more, single, zero) => {
    return parseFloat(n) > 1 ?
        more
        :
        zero && parseFloat(n) === 0 ?
            zero
            :
            single
}

PluralString.propTypes = {
    n: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    more: PropTypes.string.isRequired,
    single: PropTypes.string.isRequired,
    zero: PropTypes.string
}


export function shadeColor(color, percent) {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const logShadeColor = (colorHex, percent) => {
    let components = hexToRgb(colorHex);
    let color = `rgb(${components.r},${components.g},${components.b})`;
    return RGB_Log_Shade(color, percent / 100);
}

export const RGB_Log_Shade = (c, p) => {
    var i = parseInt, r = Math.round, [a, b, c, d] = c.split(","), P = p < 0, t = P ? 0 : p * 255 ** 2,
        P = P ? 1 + p : 1 - p;
    return "rgb" + (d ? "a(" : "(") + r((P * i(a[3] == "a" ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5) + "," + r((P * i(b) ** 2 + t) ** 0.5) + "," + r((P * i(c) ** 2 + t) ** 0.5) + (d ? "," + d : ")");
}
