import React, {Fragment} from "react";
import styled from "styled-components";
import AppConfig from "../../../../config/AppConfig";
import OrdiniAPI from "../../../../api/OrdiniAPI";
import {swalConfirm, swalError, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import OrdineAdminInfoPage from "./InfoPage/OrdineAdminInfoPage";
import moment from "moment";
import ModalPortal from "../../../../components/BaseComponents/ModalPortal";
import {FOOD_ORDER_TYPE_ASPORTO, FOOD_ORDER_TYPE_CONSEGNA} from "../../../../const/ModuloFoodCostants";
import {ECOMMERCE_ORDER_TYPE_RITIRO} from "../../../../const/ModuloEcommerceCostants";

const OrderAppThumbnail = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:url(${props => props.imageUrl});
    display:inline-block;
`;

const ORDINE_STATUS_ATTESA = 'ordine.in_attesa';
const ORDINE_STATUS_CONFERMATO = 'ordine.confermato';
const ORDINE_STATUS_RIFIUTATO_UTENTE = 'ordine.rifiutato_utente';
const ORDINE_STATUS_RIFIUTATO_ESERCENTE = 'ordine.rifiutato_esercente';
const ORDINE_STATUS_PROPOSTA_ESERCENTE = 'ordine.proposta_esercente';
const ORDINE_STATUS_ANNULLATO_UTENTE = 'ordine.annullato_utente';
const ORDINE_STATUS_COMPLETO = 'ordine.completo';
const ORDINE_STATUS_IN_ARRIVO = 'ordine.in_arrivo'; //Solo se tipo = consegna
const ORDINE_STATUS_PRONTO = 'ordine.pronto'; //Solo se tipo = asporto

export default class OrdineAdminActionCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoPageOpen: false
        }
    }

    getStatusLabel = _ => {
        switch (this.props.status) {
            case ORDINE_STATUS_ATTESA:
                return <span className={"badge badge-warning"}>In attesa di TUA conferma</span>;
            case ORDINE_STATUS_COMPLETO:
                return <span className={"badge badge-success"}>Completato</span>;
            case ORDINE_STATUS_CONFERMATO:
                return <span className={"badge badge-primary"}>Confermato, in preparazione</span>;
            case ORDINE_STATUS_IN_ARRIVO:
                return this.isPreventivo() ? <span className={"badge badge-info"}>In attesa di TUO preventivo</span> :
                    <span className={"badge badge-info"}>In arrivo</span>
            case ORDINE_STATUS_PRONTO:
                return <span className={"badge badge-info"}>Pronto per il ritiro</span>;
            case ORDINE_STATUS_PROPOSTA_ESERCENTE:
                return <span className={"badge badge-warning"}>In attesa utente</span>;
            case ORDINE_STATUS_RIFIUTATO_ESERCENTE:
                return <span className={"badge badge-danger"}>Rifiutato</span>;
            case ORDINE_STATUS_RIFIUTATO_UTENTE:
                return <span className={"badge badge-danger"}>Rifiutato da te</span>;
            case ORDINE_STATUS_ANNULLATO_UTENTE:
                return <span className={"badge badge-danger"}>Annullato utente</span>;

        }
    }

    getOrderTypeText = _ => {
        switch (this.props.tipo_ordine) {
            case 'asporto':
                return 'Ritiro';
            case 'consegna':
                return 'Consegna';
        }
    }

    getOrderTypeLabel = _ => {
        switch (this.props.tipo_ordine) {
            case FOOD_ORDER_TYPE_CONSEGNA:
                return <span className={"badge badge-success"}>Consegna a domicilio</span>;
            case FOOD_ORDER_TYPE_ASPORTO:
                return <span className={"badge badge-info"}>Asporto</span>;
        }
    }

    toggleInfoPage = _ => {
        this.setState({infoPageOpen: !this.state.infoPageOpen});
    }

    isPreventivo = _ => this.props.id_ordine && this.props.preventivo === '1';

    getTotaleOrdine = _ => {
        if (!this.props.id_coupon) {
            return parseFloat(this.props.totaleOrdine).toFixed(2)
        } else {
            let totaleProdotti = parseFloat(this.props.totaleProdotti);
            let costoSpedizione = parseFloat(this.props.prezzo_spedizione);
            let valoreSconto = parseFloat(this.props.importo_sconto_globale);

            return (totaleProdotti + costoSpedizione - valoreSconto).toFixed(2);
        }
    }

    render() {
        return (
            <div>
                <div className={"card shadow " + this.props.className} onClick={this.toggleInfoPage}>
                    <div className={"card-body"}>
                        <b>#{this.props.id_ordine} - {this.props.nome_utente}</b><br/>
                        <div>{this.getOrderTypeLabel()}</div>
                        {!this.isPreventivo() && <Fragment>Consegna: {this.props.orario}<br/></Fragment>}
                        <small className={"text-muted"}>
                            {this.isPreventivo() ? 'Preventivo richiesto ' : 'Ordinato '}
                            {
                                moment(this.props.data_creazione).calendar(null, {
                                    sameDay: '[Oggi alle] HH:mm',
                                    nextDay: '[Domani alle] HH:mm',
                                    nextWeek: 'dddd DD [alle] HH:mm',
                                    lastDay: '[Ieri alle] HH:mm',
                                    lastWeek: '[Lo scorso] dddd [alle] HH:mm',
                                    sameElse: 'dddd D MMM [alle] HH:mm'
                                })
                            }
                        </small><br/>
                        {this.getStatusLabel()}
                        {
                            this.props.status === ORDINE_STATUS_PROPOSTA_ESERCENTE ?
                                <div className={"alert alert-info mt-2 mb-0 p-1"}>
                                    <small>
                                        Hai proposto un nuovo orario di {this.getOrderTypeText()},
                                        le <b>{this.props.orario_proposto}</b> invece delle <b>{this.props.orario}</b>.
                                    </small>
                                </div> : ''
                        }
                        {
                            !this.isPreventivo() &&
                            <div>
                                <div><b>Totale</b> €{this.getTotaleOrdine()}</div>
                                {
                                    !!this.props.id_indirizzo_fatturazione &&
                                    <span className={"ml-2 badge badge-warning"}>Con fattura</span>
                                }
                                {
                                    this.props.tipo_ordine === ECOMMERCE_ORDER_TYPE_RITIRO &&
                                    <span className={"ml-2 badge badge-success"}>Ritiro in negozio</span>
                                }
                            </div>
                        }
                    </div>
                </div>
                <ModalPortal>
                    {
                        this.state.infoPageOpen ?
                            <OrdineAdminInfoPage
                                id_ordine={this.props.id_ordine}
                                onDismiss={this.toggleInfoPage}
                                onStateChange={this.props.onStateChange}
                            /> : ''
                    }
                </ModalPortal>
            </div>
        );
    }

}
