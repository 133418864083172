/* globals AndroidInterface, webkit */
import React from "react";
import ModalPortalHOC from "../../../../../components/BaseComponents/ModalPortalHOC";
import "./ModuloCassaSideMenu.css"
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import ReceiptsHistoryPage from "../ReceiptsHistoryPage";
import {ModuloCassaAPI} from "../../../../../api/ModuloCassa/ModuloCassaAPI";
import {EllipsisLoader} from "../../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import StatisticsPage from "../StatisticsPage";


export default class ModuloCassaSideMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            statisticsPageOpen: false,
            receiptsHistoryPage: false,
            statisticalInfo: [],
            fetching: false,
        };
    }

    componentDidMount() {
        this.setState({fetching: true})
        ModuloCassaAPI.Dashboard.getStatisticalInformation()
            .then(data =>{
                this.setState({statisticalInfo: data, fetching: false})
            })
    }

    toggleStatisticsPage =_=>this.setState({statisticsPageOpen: !this.state.statisticsPageOpen})

    toggleReceiptsHistoryPage =_=> this.setState({receiptsHistoryPage: !this.state.receiptsHistoryPage})

    render() {
        return (
            <div>
                <div className={"side-menu-backdrop " + (this.props.active ? 'active __androidDismissable__' : '')} onClick={this.props.onMenuToggle}>&nbsp;</div>
                <div className={"side-menu bg-main " + (this.props.active ? 'active shadow' : '')}>
                    <div className={"bg-primary h-25 pt-5 py-2 mb-4"}>
                        {
                            this.state.fetching ?
                                <EllipsisLoader color={"#cccccc"}/>
                                :
                                <div className={"w-100 text-center"}>
                                    <small>Totale scontrini giornalieri</small>
                                    <h3 className={"text-white"}>€{this.state.statisticalInfo.totale ? this.state.statisticalInfo.totale : 0.00}</h3>
                                    {
                                        this.state.statisticalInfo.creati && this.state.statisticalInfo.creati > 0 &&
                                        <span>{this.state.statisticalInfo.creati} scontrini creati</span>
                                    }
                                </div>
                        }
                    </div>
                    <small className={"ml-2 text-muted mt-3"}>Opzioni modulo cassa</small>
                    <ul className={"list-group"}>
                        <li className={"list-group-item list-group-item-action bg-main-z2 text-on-bg-main"}
                            onClick={this.toggleStatisticsPage}>
                            <FaSolidIcon name={"chart-simple"}/> Statistiche
                        </li>
                        <li className={"list-group-item list-group-item-action bg-main-z2 text-on-bg-main"}
                            onClick={this.toggleReceiptsHistoryPage}>
                            <FaSolidIcon name={"clock"}/> Storico
                        </li>
                    </ul>
                </div>

                <ModalPortalHOC>
                    {
                        this.state.statisticsPageOpen &&
                        <StatisticsPage
                            onDismiss={this.toggleStatisticsPage}
                            userNegozio={this.props.userNegozio}
                        />
                    }

                    {
                        this.state.receiptsHistoryPage &&
                        <ReceiptsHistoryPage
                            onDismiss={this.toggleReceiptsHistoryPage}
                            userNegozio={this.props.userNegozio}
                        />
                    }
                </ModalPortalHOC>
            </div>
        );
    }

}

