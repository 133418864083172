import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {formatCurrency} from "../../helpers/CurrencyHelpers";
import {TYPE_WALLET_WELFARE} from "../CheckoutDiscount/Wallets/WalletsConstants";

export default class WalletView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getWalletTitle = _ => {
        switch (this.props.wallet.type) {
            case  TYPE_WALLET_WELFARE:
                return "Il tuo welfare"
            default:
                if(this.props.wallet.name) return this.props.wallet.name.toUpperCase()
                return this.props.wallet.type.replaceAll("_", " ").toUpperCase()
        }
    }

    handleClick = _ => {
        this.props.handleOnClick(this.props.wallet)
    }

    render() {
        let w = this.props.wallet

        return (
            <div className={"card bg-" + w.color + " shadow-sm border-0 text-white text-center"} onClick={this.handleClick}>
                <span>{this.getWalletTitle()}</span>
                <div className={"card-body bubble-shadow text-center"}>
                    <h4 className={"mb-0"}>{formatCurrency(w.saldo_disponibile, "EUR", "it-IT")}</h4>
                    <div className={"small mt-0"}>su {formatCurrency(w.inbound_balance, "EUR", "it-IT")}</div>
                </div>
                <div className={"card-footer py-1 border-0 text-center"}>
                    <div className={"small"} style={{opacity: "0.8"}}>Creato
                        il {moment(w.data_creazione).format("DD-MM-YYYY")}</div>
                </div>
            </div>
        )
    }
}

WalletView.propTypes = {
    wallet: PropTypes.object,
    handleOnClick: PropTypes.func
}
