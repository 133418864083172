import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";


export const _Geolocation = {
    save: function (lat, lng){
        let params = {
            token: AuthAPI.currentUser.accessToken,
            lat: lat,
            lng: lng
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/user/geolocation/save`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};