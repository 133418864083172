import {ALIQUOTE_IVA} from "./ModuloCassaContants";

//Calcola il subtotale di un singolo item
export function getSubtotalOfItem (item, withScontoDocumento = true){
    const i = item
    const subtotal = parseFloat(i.quantita || "0") * parseFloat(i.importo_unitario || "0")
    return subtotal - parseFloat(i.sconto || "0") - (withScontoDocumento ? i.sconto_documento : 0)
}

//Recupera il totale degli items inseriti nello scontrino
export function getTotaleItems (items, withScontoDocumento = true) {
    if (items && !items.length) return 0
    return items.reduce((subtotal, item) => {
        return subtotal + getSubtotalOfItem(item, withScontoDocumento)
    }, 0)
}

//Calcola l'iva di un singolo item
export function getIvaForItem(item) {
    //Se c'é una lettera nel codice aliquota non c'é iva
    if (/[a-zA-Z]/.test(item.codice_aliquota)) return 0;
    let subtotalOfItem = getSubtotalOfItem(item);
    return subtotalOfItem - (subtotalOfItem / (1 +(parseFloat(item.codice_aliquota || "0") / 100)))
}

//Calcola il totale dell'iva di tutti gli items
export function getTotaleIva (items) {
    if (items && !items.length) return 0
    return items.reduce((subtotalIva, item) => {
        return subtotalIva + getIvaForItem(item)
    }, 0)
}

//Calcola approssimazione data dallo sconto a pagare
export function getScontoAPagare (metodiPagamento, items) {
    let isContanti = false
    //approssimazione è possibile solo se c'é almeno un pagamento in contanti
    for (let i = 0; i < metodiPagamento.length; i++) {
        if (metodiPagamento[i].tipo === 1) {
            isContanti = true
            break
        }
    }
    if (!isContanti) return 0
    //Al totale degli item sottraggo il totale approssimato, in questo modo riconosco lo sconto a pagare
    return (roundNearest005(getTotaleItems(items)) - getTotaleItems(items)).toFixed(2)
}

//Approssima al numero divisibile per 0.05 più vicino
export function roundNearest005 (num) {
    return (Math.round(num / 0.05) * 0.05).toFixed(2);
}

//Il totale effettivo dello scontrino, comprende tutti i possibili parametri
export function getTotaleScontrino (metodiPagamento, items){
    console.log(metodiPagamento, items)
    return parseFloat(getTotaleItems(items)) + parseFloat(getScontoAPagare(metodiPagamento, items))
}

export function getDescrizioneIva(codice){
    let aliquota = ALIQUOTE_IVA.find(al => al.codice === codice)
    if(!aliquota) return ""
    return aliquota.descrizione
}