import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {getCompressedImageUrl} from "../../../../../helpers/ImageUtility";
import {ProductImageSize} from "../../../../../const/ImageSizeCostants";
import giftcardPlaceholder from "../../../../../img/giftcard-placeholder.png"

const NAME_LENGTH = 40

export class DipendenteWelfareGiftCardsProductItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.productImageStyle = {
            width: '100%',
            height: '120px',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundImage: `url(${this.props.product.downloaded_image_url ? getCompressedImageUrl(this.props.product.downloaded_image_url, ProductImageSize.thumbnail.w, ProductImageSize.thumbnail.h) : giftcardPlaceholder})`,
        }
    }

    render() {
        return (
            <div className={"col-6 my-2 px-1"}>
                <div className={"card bg-main-z2 overflow-hidden border-0 shadow-sm p-1 h-100"}
                     onClick={_ => this.props.onProductClick(this.props.product)}
                >
                    <div style={this.productImageStyle}/>
                    <div className={"mt-1 p-1 text-center"}>
                        <b>{
                            this.props.product.name.length > NAME_LENGTH ?
                                this.props.product.name.substring(0, NAME_LENGTH) + "..." :
                                this.props.product.name
                        }</b><br/>
                        <small className={"text-muted"}>{this.props.product.brand}</small>
                    </div>
                </div>
            </div>
        )
    }
}

DipendenteWelfareGiftCardsProductItem.propTypes = {
    product: PropTypes.object,
    onProductClick: PropTypes.func
};