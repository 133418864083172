import React from "react";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {DLA_PACCHETTO_VIRTUAL_CARD} from "../../../const/DeepLinkActionsCostants";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import {PacchettoAdminDetailPage} from "../../../components/Pacchetti/PacchettoAdminDetailPage/PacchettoAdminDetailPage";
import PropTypes from "prop-types";

export class PacchettoAdminQRScanListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageOpen: false,
            authorizationToken: null,
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_PACCHETTO_VIRTUAL_CARD, this.handleEvent)
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_PACCHETTO_VIRTUAL_CARD, this.handleEvent);
    }

    handleEvent = authorizationToken => {
        this.setState({
            authorizationToken:authorizationToken,
            pageOpen: true
        })
    }

    handlePageToggle = _ => this.setState({pageOpen: !this.state.pageOpen});

    render() {
        return (
            <ModalPortalHOC>
                {
                    this.state.pageOpen &&
                    <PacchettoAdminDetailPage
                        authorizationToken={this.state.authorizationToken}
                        onDismiss={this.handlePageToggle}
                        color={this.props.color}
                    />
                }
            </ModalPortalHOC>
        )
    }

}


PacchettoAdminQRScanListener.propTypes = {
    idNegozio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
}