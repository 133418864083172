import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {PlaceholderBlock} from "../../helpers/Placeholders";
import {BookizonDexieDB} from "../../database/DatabaseFacade";
import {imageUrlToBase64Data} from "../../helpers/ImageUtility/ImageUrlToBase64Data";
import AppConfig from "../../config/AppConfig";
import {UserDataStore} from "../../stores/UserDataStore";

const canUseIndexedDB = !!window.indexedDB;

export class AppIconThumbnail extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            src: null
        }
    }

    componentDidMount() {
        if (this.props.src) {
            AppIconCacher.getImageData(this.props.src)
                .then(imageData => {
                    this.setState({src: imageData});
                })
                .catch(_ => {
                    AppIconCacher.addImageData(this.props.src)
                        .then(src => this.setState({src: src}));
                })
        }
    }

    render() {
        return (
            !!this.state.src ?
                <AppIconThumbnailSC {...this.props} src={this.state.src}/>
                :
                <LoadingAppIconThumbnailSC {...this.props}/>
        )
    }

}

AppIconThumbnail.propTypes = {
    url: PropTypes.string,
    size: PropTypes.string,
    circle: PropTypes.bool
}

export const AppIconThumbnailSC = styled.div`
    display: inline-block;
    width: ${props => props.size};
    height: ${props => props.size};
    border-radius: ${props => props.circle ? '50%' : '4px'};
    border: 1px solid #cdcdcd;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color:#fff;
    background-image:url(${props => props.src});
`;

const LoadingAppIconThumbnailSC = styled(PlaceholderBlock)`
    display: inline-block;
    width: ${props => props.size};
    height: ${props => props.size};
    border-radius: ${props => props.circle ? '50%' : '4px'};
    border: 1px solid #cdcdcd;
`;


const AppIconCacher = {
    cachedIcons: {},
    addImageData: url => {
        return new Promise((resolve, reject) => {
            imageUrlToBase64Data(url)
                .then(imageData => {
                    if (canUseIndexedDB) {
                        BookizonDexieDB.appIcons.put({url: url, imageData: imageData, timestamp: Date.now()})
                    } else {
                        AppIconCacher.cachedIcons[url] = {imageData: imageData, timestamp: Date.now()};
                    }
                    resolve(imageData);
                })
                .catch(error => console.warn(error));
        });
    },
    getImageData: url => {
        return new Promise((resolve, reject) => {
            if (canUseIndexedDB) {
                BookizonDexieDB.appIcons.get(url)
                    .then(iconData => {
                        if (!iconData || AppIconCacher.shouldInvalidate(url, iconData.timestamp)) {
                            reject(null);
                        } else {
                            resolve(iconData.imageData);
                        }
                    })
                    .catch(_ => reject(null));
            } else {
                if (AppIconCacher.cachedIcons[url]) {
                    resolve(AppIconCacher.cachedIcons[url].imageData)
                } else {
                    reject(null);
                }
            }
        });

    },
    /**
     * Mi indica se un determinato URL cachato deve essere invalidato e deve essere rifatta la richiesta
     *
     * @param url
     * @param timestamp Unix timestamp del momento del salvataggio del dato nella cache
     *
     * @return boolean
     * */
    shouldInvalidate: (url, timestamp) => {
        if (canUseIndexedDB) {
            const regExp = new RegExp(`${AppConfig.webServerUrl.replace('.', '\\.')}/api/negozio/([0-9]+)/logoIcon`)
            const match = url.match(regExp);
            if (match && match[1]) {
                for (let i in UserDataStore.apps) {
                    if (UserDataStore.apps.hasOwnProperty(i)) {
                        if (UserDataStore.apps[i].id_negozio === match[1]) {
                            const negozioTimestamp = (new Date(UserDataStore.apps[i].data_modifica.replace(/-/g, "/") || '1970/01/01 00:00:00')).getTime();
                            return negozioTimestamp > timestamp;
                        }
                    }
                }
            }
        }
        return false;
    }
}
