import React, {Fragment} from "react";
import styled from "styled-components";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {swal, swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../../helpers/SweetAlertWrapper";
import DOMPurify from "dompurify";
import AppConfig from "../../../../../config/AppConfig";
import moment from "moment";
import OrdiniDashboardAPI from "../../../../../api/OrdiniDashboardAPI";
import DatePicker from "react-mobile-datepicker";
import SafeHtmlContainer from "../../../../../helpers/SafeHtmlContainer";
import {getFoodDeliveryRejectReasonByOptionList} from "../OrdineRejectReasonUtility";
import {ECOMMERCE_ORDER_TYPE_DEFAULT, ECOMMERCE_ORDER_TYPE_RITIRO} from "../../../../../const/ModuloEcommerceCostants";
import {OrdineAdminInfoProduct} from "./OrdineAdminInfoProduct"


const OrderAppThumbnail = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #cdcdcd;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:url(${props => props.imageUrl});
    display:block;
`;

const ORDINE_STATUS_ATTESA = 'ordine.in_attesa';
const ORDINE_STATUS_CONFERMATO = 'ordine.confermato';
const ORDINE_STATUS_RIFIUTATO_UTENTE = 'ordine.rifiutato_utente';
const ORDINE_STATUS_RIFIUTATO_ESERCENTE = 'ordine.rifiutato_esercente';
const ORDINE_STATUS_PROPOSTA_ESERCENTE = 'ordine.proposta_esercente';
const ORDINE_STATUS_ANNULLATO_UTENTE = 'ordine.annullato_utente';
const ORDINE_STATUS_COMPLETO = 'ordine.completo';
const ORDINE_STATUS_IN_ARRIVO = 'ordine.in_arrivo'; //Solo se tipo = consegna
const ORDINE_STATUS_PRONTO = 'ordine.pronto'; //Solo se tipo = asporto

const ORDER_TYPE_ASPORTO = 'asporto';
const ORDER_TYPE_CONSEGNA = 'consegna';
const ORDER_TYPE_ECOMMERCE = 'ecommerce';

const dateConfig = {
    'hour': {
        format: 'hh',
        caption: 'Ora',
        step: 1,
    },
    'minute': {
        format: 'mm',
        caption: 'Min',
        step: 15,
    },
};

function isEcommerce(tipo_ordine) {
    return tipo_ordine === ECOMMERCE_ORDER_TYPE_DEFAULT || tipo_ordine === ECOMMERCE_ORDER_TYPE_RITIRO;
}


function IndirizzoHTML(props) {
    return (
        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.html)}}/>
    )
}

export default class OrdineAdminInfoPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ordine: null,
            datepickerOpen: false,
        }
    }

    componentDidMount() {
        this.fetchOrderData();
    }

    fetchOrderData = silent => {
        if (!silent) swalLoading();
        OrdiniDashboardAPI.getData(this.props.id_ordine)
            .then(ordine => {
                this.setState({ordine: ordine});
                swal.safeClose();
            });
    }

    toggleDatePicker = _ => {
        this.setState({datepickerOpen: !this.state.datepickerOpen});
    }

    handleDatepickerSelect = date => {
        this.setState({datepickerOpen: false});
        const time = moment(date).format('HH:mm');
        swalConfirm(`Sei sicuro di voler proporre all'utente le ${time} come nuovo orario?`, 'Conferma', 'Annulla')
            .then(_ => {
                swalLoading();
                OrdiniDashboardAPI.setTime(this.props.id_ordine, time)
                    .then(swalSuccess)
                    .then(_ => this.props.onStateChange(this.props.id_ordine, {
                        status: ORDINE_STATUS_PROPOSTA_ESERCENTE,
                        orario_proposto: time
                    }))
                    .then(_ => this.setState({
                        ordine: {
                            ...this.state.ordine,
                            status: ORDINE_STATUS_PROPOSTA_ESERCENTE,
                            orario_proposto: time
                        }
                    }))
                    .catch(swalError)
            });
    }

    getOrderTypeLabel = _ => {
        switch (this.state.ordine.tipo_ordine) {
            case 'asporto':
                return 'ritiro';
            case 'consegna':
                return 'consegna';
        }
    }


    getStatusLabel = _ => {
        switch (this.state.ordine.status) {
            case ORDINE_STATUS_ATTESA:
                return <span className={"badge badge-warning"}>In attesa di TUA conferma</span>;
            case ORDINE_STATUS_COMPLETO:
                return <span className={"badge badge-success"}>Completato</span>;
            case ORDINE_STATUS_CONFERMATO:
                return <span className={"badge badge-primary"}>Confermato, in preparazione</span>;
            case ORDINE_STATUS_IN_ARRIVO:
                return this.isPreventivo() ? <span className={"badge badge-info"}>In attesa di TUO preventivo</span> :
                    <span className={"badge badge-info"}>In arrivo</span>
            case ORDINE_STATUS_PRONTO:
                return <span className={"badge badge-info"}>Pronto per il ritiro</span>;
            case ORDINE_STATUS_PROPOSTA_ESERCENTE:
                return <span className={"badge badge-warning"}>In attesa utente</span>;
            case ORDINE_STATUS_RIFIUTATO_ESERCENTE:
                return <span className={"badge badge-danger"}>Rifiutato</span>;
            case ORDINE_STATUS_RIFIUTATO_UTENTE:
                return <span className={"badge badge-danger"}>Rifiutato da te</span>;
            case ORDINE_STATUS_ANNULLATO_UTENTE:
                return <span className={"badge badge-danger"}>Annullato utente</span>;
        }
    }

    handleConfirmOrder = _ => {
        swalConfirm('Sei sicuro di voler CONFERMARE questo ordine?', 'Conferma', 'Annulla')
            .then(_ => {
                swalLoading();
                OrdiniDashboardAPI.setStatus(this.props.id_ordine, ORDINE_STATUS_CONFERMATO)
                    .then(swalSuccess)
                    .then(_ => this.props.onStateChange(this.props.id_ordine, {status: ORDINE_STATUS_CONFERMATO}))
                    .then(_ => this.setState({ordine: {...this.state.ordine, status: ORDINE_STATUS_CONFERMATO}}))
                    .catch(swalError)
            })
    }

    handleRejectOrder = _ => {
        swalConfirm('Sei sicuro di voler RIFIUTARE questo ordine?', 'Conferma', 'Annulla')
            .then(async _ => {
                let rejectReason = '';
                if (this.props.tipo_ordine !== ORDER_TYPE_ECOMMERCE) {
                    rejectReason = await getFoodDeliveryRejectReasonByOptionList();
                }
                swalLoading();
                OrdiniDashboardAPI.setStatus(this.props.id_ordine, ORDINE_STATUS_RIFIUTATO_ESERCENTE, rejectReason)
                    .then(swalSuccess)
                    .then(_ => this.props.onStateChange(this.props.id_ordine, {status: ORDINE_STATUS_RIFIUTATO_ESERCENTE}))
                    .then(_ => this.setState({
                        ordine: {
                            ...this.state.ordine,
                            status: ORDINE_STATUS_RIFIUTATO_ESERCENTE
                        }
                    }))
                    .catch(swalError);
            });
    }

    handleSetOnTheWayOrder = _ => {
        swalConfirm('Sei sicuro di voler impostare l\'ordine come "In Arrivo"?', 'Conferma', 'Annulla')
            .then(_ => {
                swalLoading();
                OrdiniDashboardAPI.setStatus(this.props.id_ordine, ORDINE_STATUS_IN_ARRIVO)
                    .then(swalSuccess)
                    .then(_ => this.props.onStateChange(this.props.id_ordine, {status: ORDINE_STATUS_IN_ARRIVO}))
                    .then(_ => this.setState({ordine: {...this.state.ordine, status: ORDINE_STATUS_IN_ARRIVO}}))
                    .catch(swalError)
            });
    }

    handleSetReadyOrder = _ => {
        swalConfirm('Sei sicuro di voler impostare l\'ordine come "Pronto"?', 'Conferma', 'Annulla')
            .then(_ => {
                swalLoading();
                OrdiniDashboardAPI.setStatus(this.props.id_ordine, ORDINE_STATUS_PRONTO)
                    .then(swalSuccess)
                    .then(_ => this.props.onStateChange(this.props.id_ordine, {status: ORDINE_STATUS_PRONTO}))
                    .then(_ => this.setState({ordine: {...this.state.ordine, status: ORDINE_STATUS_PRONTO}}))
                    .catch(swalError)
            });
    }

    handleSetTakenOrder = _ => {
        swalConfirm('Sei sicuro di voler impostare l\'ordine come "Ritirato"?', 'Conferma', 'Annulla')
            .then(_ => {
                swalLoading();
                OrdiniDashboardAPI.setStatus(this.props.id_ordine, ORDINE_STATUS_COMPLETO)
                    .then(swalSuccess)
                    .then(_ => this.props.onStateChange(this.props.id_ordine, {status: ORDINE_STATUS_COMPLETO}))
                    .then(_ => this.setState({ordine: {...this.state.ordine, status: ORDINE_STATUS_COMPLETO}}))
                    .catch(swalError)
            });
    }

    handleCompleteOrder = _ => {
        swalConfirm('Sei sicuro di voler impostare l\'ordine come "Completo"?', 'Conferma', 'Annulla')
            .then(_ => {
                swalLoading();
                OrdiniDashboardAPI.setStatus(this.props.id_ordine, ORDINE_STATUS_COMPLETO)
                    .then(swalSuccess)
                    .then(_ => this.props.onStateChange(this.props.id_ordine, {status: ORDINE_STATUS_COMPLETO}))
                    .then(_ => this.setState({ordine: {...this.state.ordine, status: ORDINE_STATUS_COMPLETO}}))
                    .catch(swalError)
            });
    }

    handleEditStatus = (id_prodotto, aggiunte_hash, newStatus) => {
        swalLoading();
        OrdiniDashboardAPI.setProductStatus(this.props.id_ordine, id_prodotto, aggiunte_hash, newStatus)
            .then(m => {
                swalSuccess(m);
                this.fetchOrderData(true);
            })
            .catch(swalError)
    }

    isPreventivo = _ => this.state.ordine.id_ordine && this.state.ordine.preventivo === '1';

    getAddressMapImageUrl = indirizzo => {
        var queryString = encodeURIComponent(`${indirizzo.indirizzo} ${indirizzo.civico},${indirizzo.citta},${indirizzo.cap},${indirizzo.civico}`);
        var imageDimension = `150x150`;
        return `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAsRTtIb0cMy_bpR5RbavHoNKvIkGPlHpg&size=${imageDimension}&center=${queryString}&zoom=17`;
    }

    getGPSLink(indirizzo) {
        var mapLink = 'https://maps.google.com/maps?q=';
        if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
            mapLink = 'http://maps.apple.com/?q=';
        }
        var queryString = (`${indirizzo.indirizzo} ${indirizzo.civico},${indirizzo.nome_citta},${indirizzo.nome_provincia}`);
        return `${mapLink}${queryString}`;
    }

    render() {
        if (!this.state.ordine) return '';

        const discount = parseFloat(this.state.ordine.importo_sconto_globale);
        const subtotal = parseFloat(this.state.ordine.totaleProdotti) + parseFloat(this.state.ordine.prezzo_spedizione);
        const total = subtotal - discount;
        const coupon = this.state.ordine.dati_coupon;
        return (
            <Page
                title={`Ordine #${this.props.id_ordine}`}
                active={true}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                onLeftIconClick={this.props.onDismiss}
            >
                {
                    this.state.ordine ?
                        <div className={"container pt-2"}>
                            <div className={"text-center"}>
                                <OrderAppThumbnail
                                    className={"mx-auto"}
                                    imageUrl={`${AppConfig.webServerUrl}/api/negozio/${this.state.ordine.id_negozio}/logoIcon/100`}/>
                                <h4 className={"text-center"}>{this.state.ordine.nome_utente}</h4>
                                <div className={"text-center"}>
                                    <a className={"btn btn-outline-primary"}
                                       href={`tel:${this.state.ordine.numero_utente}`}><FaSolidIcon
                                        name={"phone"}/> Contatta cliente</a>
                                </div>
                            </div>

                            {
                                (this.state.ordine.tipo_ordine === ORDER_TYPE_CONSEGNA || this.state.ordine.tipo_ordine === ORDER_TYPE_ASPORTO) &&
                                <p className={"text-center"}>
                                    {this.getOrderTypeLabel()} alle {this.state.ordine.orario}
                                </p>
                            }

                            {
                                (this.state.ordine.tipo_ordine === ORDER_TYPE_ECOMMERCE && this.state.ordine.orario) &&
                                <p className={"text-center"}>
                                    Consegna &nbsp;
                                    {
                                        moment(this.state.ordine.data_creazione).calendar(null, {
                                            sameDay: '[Oggi]',
                                            nextDay: '[Domani]',
                                            nextWeek: 'dddd DD',
                                            lastDay: '[Ieri]',
                                            lastWeek: '[Lo scorso] dddd',
                                            sameElse: 'dddd D MMM'
                                        })
                                    }&nbsp;
                                    dalle {this.state.ordine.orario}
                                </p>
                            }


                            <p className={"text-muted text-center"}>
                                {this.isPreventivo() ? 'Preventivo richiesto ' : 'Ordine effettuato '}
                                {
                                    moment(this.state.ordine.data_creazione).calendar(null, {
                                        sameDay: '[Oggi alle] HH:mm',
                                        nextDay: '[Domani alle] HH:mm',
                                        nextWeek: 'dddd DD [alle] HH:mm',
                                        lastDay: '[Ieri alle] HH:mm',
                                        lastWeek: '[Lo scorso] dddd [alle] HH:mm',
                                        sameElse: 'dddd D MMM [alle] HH:mm'
                                    })
                                }
                            </p>
                            <p>
                                {this.getStatusLabel()}
                                {
                                    this.state.ordine.tipo_ordine === ECOMMERCE_ORDER_TYPE_RITIRO &&
                                    <span className={"ml-2 badge badge-success"}>Ritiro in negozio</span>
                                }
                            </p>
                            {
                                this.state.ordine.status === ORDINE_STATUS_PROPOSTA_ESERCENTE ?
                                    <div className={"alert alert-info"}>
                                    <span>
                                        Hai proposto un nuovo orario di {this.getOrderTypeLabel()},
                                        le <b>{this.state.ordine.orario_proposto}</b> invece delle <b>{this.state.ordine.orario}</b>.
                                    </span>
                                    </div> : ''
                            }
                            {
                                this.state.ordine.status === ORDINE_STATUS_ATTESA ?
                                    <div className={"btn-group"}>
                                        <button className='btn btn-sm btn-primary' onClick={this.handleConfirmOrder}>
                                            Conferma ordine
                                        </button>
                                        <button className='btn btn-sm btn-danger' onClick={this.handleRejectOrder}>
                                            Rifiuta ordine
                                        </button>
                                        <button className='btn btn-sm btn-secondary' onClick={this.toggleDatePicker}>
                                            Suggerisci orario
                                        </button>
                                    </div> : ''
                            }

                            {
                                this.state.ordine.status === ORDINE_STATUS_CONFERMATO ?
                                    this.state.ordine.tipo_ordine === ORDER_TYPE_ASPORTO ?
                                        <div className={"btn-group"}>
                                            <button className='btn btn-sm btn-primary'
                                                    onClick={this.handleSetReadyOrder}>
                                                Segna pronto
                                            </button>
                                            <button className='btn btn-sm btn-danger' onClick={this.handleRejectOrder}>
                                                Rifiuta ordine
                                            </button>
                                            <button className='btn btn-sm btn-secondary'
                                                    onClick={this.handleSetTakenOrder}>
                                                Segna ritirato
                                            </button>
                                        </div> :
                                        <div className={"btn-group"}>
                                            <button className='btn btn-sm btn-primary'
                                                    onClick={this.handleSetOnTheWayOrder}>
                                                Segna in arrivo
                                            </button>
                                            <button className='btn btn-sm btn-danger' onClick={this.handleRejectOrder}>
                                                Rifiuta ordine
                                            </button>
                                        </div>
                                    : ''
                            }

                            {
                                this.state.ordine.status === ORDINE_STATUS_IN_ARRIVO || this.state.ordine.status === ORDINE_STATUS_PRONTO ?
                                    <div className={"btn-group"}>
                                        <button className='btn btn-sm btn-primary' onClick={this.handleCompleteOrder}>
                                            Segna completo
                                        </button>
                                    </div> : ''
                            }


                            <hr/>
                            <b>Prodotti:</b><br/>
                            {
                                this.state.ordine.prodotti.map((p, i) => {
                                    return <OrdineAdminInfoProduct
                                        key={i}
                                        product={p}
                                        showStatus={isEcommerce(this.state.ordine.tipo_ordine)}
                                        onEditStatus={this.handleEditStatus}
                                    />
                                })
                            }
                            <hr/>
                            <b>Note cliente:</b> <SafeHtmlContainer html={this.state.ordine.note_utente || 'Nessuna'}/>
                            <hr/>

                            {
                                this.state.ordine.tipo_ordine === 'ecommerce' && !!this.state.ordine.dati_corriere &&
                                <div className={"card my-3 bg-soft-primary"}>
                                    <div className={"card-body"}>
                                        <b>Metodo di consegna scelto</b><br/>
                                        {this.state.ordine.dati_corriere.nome}
                                    </div>
                                </div>
                            }

                            {
                                !this.isPreventivo() &&
                                <Fragment>
                                    {
                                        this.state.ordine.tipo_ordine === ORDER_TYPE_CONSEGNA &&
                                        <div className={"card my-3"}>
                                            <div className={"card-body"}>
                                                <div className={"row align-items-center"}>
                                                    <div className={"col"}>
                                                        <b>Indirizzo consegna:</b>
                                                        <IndirizzoHTML
                                                            html={this.state.ordine.indirizzo_spedizione}/>
                                                    </div>
                                                    {
                                                        !!this.state.ordine.indirizzo_spedizione_data &&
                                                        <div className={"col-4"}>
                                                            <a href={this.getGPSLink(this.state.ordine.indirizzo_spedizione_data)}
                                                               target={'_blank'}>
                                                                <img
                                                                    src={this.getAddressMapImageUrl(this.state.ordine.indirizzo_spedizione_data)}
                                                                    className={"img-fluid border"}
                                                                    alt={"Mappa indirizzo"}/>
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.ordine.tipo_ordine === ORDER_TYPE_ECOMMERCE && (
                                            !!this.state.ordine.id_indirizzo_spedizione ||
                                            !!this.state.ordine.id_indirizzo_fatturazione
                                        ) &&
                                        <div className={"card my-3"}>
                                            <div className={"card-body"}>
                                                {
                                                    !!this.state.ordine.id_indirizzo_spedizione &&
                                                    <div className={"row align-items-center"}>
                                                        <div className={"col"}>
                                                            <b>Indirizzo consegna:</b>
                                                            <IndirizzoHTML
                                                                html={this.state.ordine.indirizzo_spedizione}/>
                                                        </div>
                                                        {
                                                            !!this.state.ordine.indirizzo_spedizione_data &&
                                                            <div className={"col-4"}>
                                                                <a href={this.getGPSLink(this.state.ordine.indirizzo_spedizione_data)}
                                                                   target={'_blank'}>
                                                                    <img
                                                                        src={this.getAddressMapImageUrl(this.state.ordine.indirizzo_spedizione_data)}
                                                                        className={"img-fluid border"}
                                                                        alt={"Mappa indirizzo"}/>
                                                                </a>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    !!this.state.ordine.id_indirizzo_fatturazione &&
                                                    <div className={"my-3"}>
                                                        <b>Indirizzo fatturazione:</b>
                                                        <IndirizzoHTML
                                                            html={this.state.ordine.indirizzo_fatturazione}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            }

                            {
                                !this.isPreventivo() &&
                                <div className={"card my-3"}>
                                    <div className={"card-body"}>

                                        {/* Costi di spedizione */}
                                        {
                                            this.state.ordine.tipo_ordine === 'consegna' &&
                                            <div className={"row"}>
                                                <div className={"col-8"}>
                                                    <h6>Consegna</h6>
                                                </div>
                                                <div className={"col-4 text-right"}>
                                                    <h6>€{this.state.ordine.prezzo_spedizione > 0 ? parseFloat(this.state.ordine.prezzo_spedizione).toFixed(2) : 0}</h6>
                                                </div>
                                            </div>
                                        }

                                        {/* Totale dei prodotti */}
                                        <div className={"row"}>
                                            <div className={"col-8"}>
                                                <h6>Totale prodotti</h6>
                                            </div>
                                            <div className={"col-4 text-right"}>
                                                <h6>€{parseFloat(this.state.ordine.totaleProdotti).toFixed(2)}</h6>
                                            </div>
                                        </div>

                                        {/* Mostro subtotale se c'è uno sconto e se si tratta di consegna a domicilio */}
                                        {
                                            (this.state.ordine.tipo_ordine === 'consegna' && !!this.state.ordine.dati_coupon) &&
                                            <div className={"row"}>
                                                <div className={"col-8"}>
                                                    <h6>Subtotale</h6>
                                                </div>
                                                <div className={"col-4 text-right"}>
                                                    <h6>€{subtotal.toFixed(2)}</h6>
                                                </div>
                                            </div>
                                        }

                                        {/* Mostro lo sconto con il coupon se possibile */}
                                        {
                                            !!this.state.ordine.dati_coupon &&
                                            <div className={"row"}>
                                                <div className={"col-8"}>
                                                    <h6>Sconto</h6>
                                                </div>
                                                <div className={"col-4 text-right"}>
                                                    <h6 className={"text-danger"}>€{discount.toFixed(2)}</h6>
                                                </div>
                                                {
                                                    coupon &&
                                                    <div className={"col-12"}>
                                                        {
                                                            coupon.id_promozione ?
                                                                <div className={"small"}>
                                                                    Con &nbsp;
                                                                    <span
                                                                        className={"badge badge-success text-uppercase"}>
                                promozione del {coupon.valore_sconto}%
                                </span>
                                                                </div>
                                                                :
                                                                <div className={"small"}>
                                                                    Con coupon&nbsp;
                                                                    <span
                                                                        className={"badge badge-success text-uppercase"}>
                            {coupon.codice}
                                </span>&nbsp;
                                                                    {
                                                                        coupon.tipo_sconto === 'euro' ?
                                                                            <span>di €{coupon.valore_sconto}</span>
                                                                            :
                                                                            <span>del {coupon.valore_sconto}%</span>
                                                                    }
                                                                </div>
                                                        }

                                                    </div>
                                                }
                                            </div>
                                        }

                                        {/* Mostro il totale dell'ordine */}
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <hr/>
                                            </div>
                                            <div className={"col-8"}>
                                                <h5>Totale</h5>
                                            </div>
                                            <div className={"col-4 text-right"}>
                                                <h5>€{total.toFixed(2)}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div> :
                        <div className={"text-center mt-3"}>
                            Recupero dati in corso...
                        </div>
                }
                {
                    this.state.ordine ?
                        <DatePicker
                            ref={this.datepicker}
                            dateConfig={dateConfig}
                            headerFormat={'DD-MM-YYYY'}
                            showHeader={true}
                            confirmText={"Conferma"}
                            cancelText={"Annulla"}
                            value={moment(`${this.state.ordine.data} ${this.state.ordine.orario}`).toDate()}
                            isOpen={this.state.datepickerOpen}
                            onSelect={this.handleDatepickerSelect}
                            onCancel={this.toggleDatePicker}
                        /> : ''
                }

            </Page>
        );
    }

}
