import React, {Fragment} from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaSolidIcon} from "../FontAwesomeIcons";
import {swalError} from "../../helpers/SweetAlertWrapper";
import CheckboxListGroup from "./CheckboxListGroup";
import CheckboxListCard from "./CheckboxListCard";
import {PageFooter, PageWithFooterContainer} from "./Page/PageFooter";
import DiscountBoxToWarnUser from "../DiscountBoxToWarnUser";

export default class MultipleItemChooserPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onDismiss: props.onDismiss,
            onChoose: props.onChoose,
            selected: props.selected
        };

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleChoose = this.handleChoose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleDismiss() {
        this.setState({selected: undefined});
        this.props.onDismiss();
    }

    handleChoose() {
        if (this.props.required) {
            if (this.state.selected !== undefined && this.state.selected.length) {
                this.state.onChoose(this.state.selected);
            } else {
                swalError("Devi scegliere un'opzione per poter proseguire");
            }
            return;
        }
        this.state.onChoose(this.state.selected);
    }

    handleChange(newSelectedList, changedValue, changedValueState) {
        this.setState({selected: newSelectedList});
        if (this.props.onChange) {
            this.props.onChange(newSelectedList, changedValue, changedValueState);
        }
    }

    render() {
        const ListComponent = {
            'list-group': CheckboxListGroup,
            'card': CheckboxListCard
        }[this.props.itemsType] || Fragment;
        return (
            <Page
                title={this.props.title}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.handleDismiss}
                active={this.props.active}
                content={
                    <PageWithFooterContainer>
                        {
                            this.props.active &&
                            <div className={"container pt-2"}>
                                {/*Stampo box per far capire la presenza di uno sconto esterno*/}
                                {this.props.promotionDiscount && <DiscountBoxToWarnUser discount={this.props.promotionDiscount}/>}

                                {
                                    !!this.props.subtitle &&
                                    <div className={"mb-2 px-1"}>
                                        <small
                                            className={"text-on-bg-main"}>{this.props.subtitle}</small>
                                    </div>
                                }
                                <ListComponent
                                    items={this.props.items}
                                    onChange={this.handleChange}
                                    showOrder={this.props.showOrder}
                                    selected={this.props.selected}
                                />
                            </div>
                        }
                    </PageWithFooterContainer>
                }
                footer={
                    <PageFooter className={"bg-main-z2 p-3 border-top"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <button className={"btn btn-lg btn-block btn-primary text-on-bg-primary"} onClick={this.handleChoose}>
                                Continua <FaSolidIcon name={"chevron-right"}/>
                            </button>
                            <div className={"ml-3"}>
                                {this.props.cartIcon}
                            </div>
                        </div>
                    </PageFooter>
                }
            />
        );
    }


}

MultipleItemChooserPage.defaultProps = {
    itemsType: 'list-group'
};


MultipleItemChooserPage.propTypes = {
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func,
    active: PropTypes.bool,
    itemsType: PropTypes.string
};
