import React, {Fragment} from "react";
import PropTypes from "prop-types";
import anidrideSoforosa from "../../img/allergeni/anidride_solforosa.svg";
import arachidi from "../../img/allergeni/arachidi.svg";
import cereali from "../../img/allergeni/cereali.svg";
import crostacei from "../../img/allergeni/crostacei.svg";
import fruttaGuscio from "../../img/allergeni/frutta_guscio.svg";
import latte from "../../img/allergeni/latte.svg";
import lupini from "../../img/allergeni/lupini.svg";
import molluschi from "../../img/allergeni/molluschi.svg";
import pesce from "../../img/allergeni/pesce.svg";
import sedano from "../../img/allergeni/sedano.svg";
import semiSesamo from "../../img/allergeni/semi_sesamo.svg";
import senape from "../../img/allergeni/senape.svg";
import soia from "../../img/allergeni/soia.svg";
import uova from "../../img/allergeni/uova.svg";
import fruttiBosco from "../../img/allergeni/frutti_bosco.svg";
import AppModalPortal from "../BaseComponents/AppModalPortal";
import i18next from "i18next";
import AllergeniPage from "./AllergeniPage";
import ModalPortal from "../BaseComponents/ModalPortal";

export default class AllergeniProdottiList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false
        }
    }

    getAllergeneData = allergene => {
        const ALLERGENI_LIST = {
            "frutta_guscio": {
                name: i18next.t("menu.allergeni.ingredienti.frutta-guscio-derivati.name"),
                image: fruttaGuscio,
                description: i18next.t("menu.allergeni.ingredienti.frutta-guscio-derivati.description")
            },
            "sedano": {
                name: i18next.t("menu.allergeni.ingredienti.sedano-derivati.name"),
                image: sedano,
                description: i18next.t("menu.allergeni.ingredienti.sedano-derivati.description")
            },
            "senape": {
                name: i18next.t("menu.allergeni.ingredienti.senape-derivati.name"),
                image: senape,
                description: i18next.t("menu.allergeni.ingredienti.senape-derivati.description")
            },
            "semi_sesamo": {
                name: i18next.t("menu.allergeni.ingredienti.semi-sesamo-derivati.name"),
                image: semiSesamo,
                description: i18next.t("menu.allergeni.ingredienti.semi-sesamo-derivati.description")
            },
            "anidride_solforosa": {
                name: i18next.t("menu.allergeni.ingredienti.anidride-solforosa-solfitti.name"),
                image: anidrideSoforosa,
                description: i18next.t("menu.allergeni.ingredienti.anidride-solforosa-solfitti.description")
            },
            "lupino": {
                name: i18next.t("menu.allergeni.ingredienti.lupino-derivati.name"),
                image: lupini,
                description: i18next.t("menu.allergeni.ingredienti.lupino-derivati.description")
            },
            "molluschi": {
                name: i18next.t("menu.allergeni.ingredienti.molluschi-derivati.name"),
                image: molluschi,
                description: i18next.t("menu.allergeni.ingredienti.molluschi-derivati.description")
            },
            "cereali": {
                name: i18next.t("menu.allergeni.ingredienti.cereali.name"),
                image: cereali,
                description: i18next.t("menu.allergeni.ingredienti.cereali.description")
            },
            "crostacei": {
                name: i18next.t("menu.allergeni.ingredienti.crostacei.name"),
                image: crostacei,
                description: i18next.t("menu.allergeni.ingredienti.crostacei.description")
            },
            "uova": {
                name: i18next.t("menu.allergeni.ingredienti.uova-derivati.name"),
                image: uova,
                description: i18next.t("menu.allergeni.ingredienti.uova-derivati.description")
            },
            "pesce": {
                name: i18next.t("menu.allergeni.ingredienti.pesce-derivati.name"),
                image: pesce,
                description: i18next.t("menu.allergeni.ingredienti.pesce-derivati.description")
            },
            "arachidi": {
                name: i18next.t("menu.allergeni.ingredienti.arachidi-derivati.name"),
                image: arachidi,
                description: i18next.t("menu.allergeni.ingredienti.arachidi-derivati.description")
            },
            "soia": {
                name: i18next.t("menu.allergeni.ingredienti.soia-derivati.name"),
                image: soia,
                description: i18next.t("menu.allergeni.ingredienti.soia-derivati.description")
            },
            "latte": {
                name: i18next.t("menu.allergeni.ingredienti.latte-derivati.name"),
                image: latte,
                description: i18next.t("menu.allergeni.ingredienti.latte-derivati.description")
            },
            "frutti_bosco": {
                name: i18next.t("menu.allergeni.ingredienti.frutti-bosco.name"),
                image: fruttiBosco,
                description: i18next.t("menu.allergeni.ingredienti.frutti-bosco.description")
            }
        }
        return ALLERGENI_LIST[allergene];
    }

    toggleDetail = _ => this.setState({detailOpen:!this.state.detailOpen});

    render() {
        const Portal = document.getElementById("app-modal-root") ? AppModalPortal : ModalPortal;
        return (
            <Fragment>
                {
                    this.props.allergeni.map(a => {
                        return (
                            <span key={a} className={this.props.className} onClick={this.toggleDetail}>
                                {
                                    this.props.icon &&
                                    <img
                                        src={this.getAllergeneData(a).image}
                                        height={this.props.size}
                                        alt={this.getAllergeneData(a).name}
                                    />
                                }
                                &nbsp;
                                {this.props.text && this.getAllergeneData(a).name}
                                {this.props.description && <p>{this.getAllergeneData(a).description}</p>}
                            </span>
                        )
                    })
                }
                {
                    this.props.detailPage &&
                    <Portal>
                        {
                            this.state.detailOpen && <AllergeniPage
                                active={this.state.detailOpen}
                                onDismiss={this.toggleDetail}
                                allergeni={this.props.allergeni}
                                description={true}
                            />
                        }
                    </Portal>
                }
            </Fragment>
        )
    }

}

AllergeniProdottiList.propTypes = {
    allergeni: PropTypes.array,
    icon: PropTypes.bool,
    text: PropTypes.bool,
    detailPage: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.number
}

AllergeniProdottiList.defaultProps = {
    allergeni: [],
    icon: true,
    text: true,
    detailPage: false,
    className: "",
    size: 25
}
