import AppConfig from "../../config/AppConfig";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";


export const _WaitingList = {
    join: function (id_gruppo, data, orario_inizio, orario_fine, domande) {
        let body = {
            token: AuthAPI.currentUser.accessToken,
            id_gruppo: id_gruppo,
            data: data,
            orario_inizio: orario_inizio,
            orario_fine: orario_fine,
            domande: domande
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/waiting-list/join`), body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    pagination: function (cursor) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            limit: cursor,
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/waiting-list/pagination`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    }
};