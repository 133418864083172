import React from "react";
import {Fragment} from "react";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {CRONOLOGIA_MIN_DATE_ALWAYS} from "../../../CronologiaUser/CronologiaUserPage";
import {PromotionsAPI} from "../../../../api/Promotions/PromotionsAPI";
import PromotionsItem from "../PromotionsItem";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import Pagination from "../../../../components/BaseComponents/Pagination/Pagination";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {
    PROMOTIONS_SORT_INDICIZZAZIONE_ASC,
    PROMOTIONS_SORT_INDICIZZAZIONE_DESC,
    PROMOTIONS_SORT_PERCENTUALE_ASC,
    PROMOTIONS_SORT_PERCENTUALE_DESC,
    PROMOTIONS_SORT_POSIZIONE_ASC,
    PROMOTIONS_SORT_POSIZIONE_DESC
} from "../PromotionsContants";
import PromotionsOrderPanel from "../PromotionsOrderPanel";
import NativeGeolocationProvider from "../../../../providers/NativeGeolocationProvider";
import moment from "moment";


const PAGE_SIZE = 10;
const MAX_DISTANCE = 70 //km

export default class PromotionsGeohashPage extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            items: [],
            totalItems: 0,
            fetching: false,
            currentPage: 1,
            sortType: PROMOTIONS_SORT_INDICIZZAZIONE_DESC,
            orderPanelVisible: false,

            lat: null,
            lng: null,
            maxDistance: MAX_DISTANCE, //km
        }
    }

    componentDidMount() {
        this.fetchItems()
    }

    //Gestisce l'apertura del pannello per ordinare le promozioni
    toggleOrderPanelVisibility = _ => this.setState({orderPanelVisible: !this.state.orderPanelVisible});


    //Recupera le informazioni
    fetchItems =_=>{
        this.setState({fetching: true})
        PromotionsAPI.PromotionsWithGeolocation.getPromotionsInGeoHash(
            PAGE_SIZE,
            (this.state.currentPage - 1) * PAGE_SIZE,
            this.state.sortType,
            this.state.lat,
            this.state.lng,
            this.state.maxDistance)
            .then(response => {
                this.setState({items: response.promotions, fetching: false, totalItems:response.total})
            })
    }

    //Gestisce il numero di pagina aperto se la paginazione è visibile
    handlePageChange = pageNumber => this.setState({currentPage: pageNumber}, this.fetchItems);

    //Cambia il tipo di ordinamento in base a quello selezionato
    handleOrderChange = (new_sort_type, lat, lng) => {
        console.log(lat, lng)
        if(new_sort_type === PROMOTIONS_SORT_POSIZIONE_ASC){
            this.setState({lat: lat, lng:lng})
        }
        this.setState({sortType: new_sort_type}, _ => {
            this.toggleOrderPanelVisibility();
            this.fetchItems();
        })
    }

    //Stampa le etichette sul tipo di ordinamento
    getLabelOrder =_=>{
        if(this.state.sortType === PROMOTIONS_SORT_INDICIZZAZIONE_DESC){
            return "Ordinato per Consigliati"
        }
        return "Ordinato per " + this.state.sortType.replace("_desc", "").replace("_asc", "").capitalize()
    }

    render() {
        return(
            <Fragment>
                <Page
                    title={"Vicine a te"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                    onLeftIconClick={this.props.onDismiss}
                    active={true}>

                    <div
                        className={"bg-main-z2 text-on-bg-main shadow-sm sticky-top p-2 d-flex align-items-center justify-content-between"}>
                        <small className={"text-muted"}>{this.getLabelOrder()}</small>
                        <button className={"btn btn-sm btn-link text-primary"}
                                onClick={this.toggleOrderPanelVisibility}>
                            Cambia ordinamento
                        </button>
                    </div>

                    <div className={"p-3 mt-3"}>
                        <h4><b>Vicino a te! 📍</b></h4>
                        <span className={"text-muted"}>Scopri le migliori offerte nei dintorni!</span>
                    </div>

                    {
                        this.state.fetching &&
                        <div className={"mt-5 w-100 text-center"}>
                            <EllipsisLoader/>
                        </div>
                    }

                    {
                        this.state.items.length > 0 && this.state.items.map((items, i) =>
                            <div className={"mt-4"}>
                                <PromotionsItem
                                    key={i}
                                    {...items}
                                />
                            </div>
                        )
                    }

                    {
                        (!this.state.fetching && !!this.state.items.length && this.state.totalItems > PAGE_SIZE) &&
                        <div className={"justify-content-center d-flex mt-3"}>
                            <Pagination
                                activePage={this.state.currentPage}
                                itemsCountPerPage={PAGE_SIZE}
                                totalItemsCount={this.state.totalItems}
                                pageRangeDisplayed={5}
                                itemClass="page-item"
                                linkClass="page-link"
                                onChange={this.handlePageChange}
                            />
                        </div>
                    }
                </Page>

                <ModalPortalHOC>
                    <PromotionsOrderPanel
                        onDismiss={this.toggleOrderPanelVisibility}
                        onChange={this.handleOrderChange}
                        sortType={this.state.sortType}
                        open={this.state.orderPanelVisible}
                    />
                </ModalPortalHOC>
            </Fragment>
        );
    }

}
