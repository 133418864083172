import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../BaseComponents/SwipeablePanel/SwipeablePanel";
import AppModalPortal from "../../BaseComponents/AppModalPortal";
import ModalPortal from "../../BaseComponents/ModalPortal";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {SwipeablePanelGripBar} from "../../BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import shareIcon from "../../../img/illustrations/shareIcon.svg";
import FuxUtility from "../../../lib/FuxFramework/FuxUtility";
import AppConfig from "../../../config/AppConfig";
import {FuxNotificationSystem} from "../../GenericUI/FuxNotificationSystem/FuxNotificationSystem";
import {BookizonAppManager} from "../../../index";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";
import {GalleryGridImage} from "../../GalleryGrid/GalleryGridImage";
import {getCompressedImageUrl} from "../../../helpers/ImageUtility";
import {GalleryImageSize} from "../../../const/ImageSizeCostants";

const ButtonStyle = {
    position: 'absolute',
    bottom: 25,
    right: 20,
    width: 40,
    borderRadius: 300,
    backgroundColor: 'rgba(0,0,0,0.5)'
};

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

const GalleryContainerStyle = {
    height:'80vh',
    overflow:'auto'
}


export class EcommerceImageGalleryButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            galleryOpen: false
        }
        this.galleryContainerRef = React.createRef();
    }

    toggleGallery = _ => this.setState({galleryOpen: !this.state.galleryOpen});

    isSwipeDisabled = _ => {
        if (this.galleryContainerRef.current){
            return this.galleryContainerRef.current.scrollTop > 0;
        }
        return false;
    };

    render() {
        return (
            <Fragment>
                <div onClick={this.toggleGallery} style={ButtonStyle}
                     className={"text-white shadow-sm d-flex flex-column align-items-center justify-content-center py-2"}>
                    <FaSolidIcon name={"images"}/>
                    {[this.props.productData.immagine, ...this.props.productData.immagini].length}
                </div>
                <ModalPortalHOC>
                    {this.state.galleryOpen && <div style={BackdropStyle}/>}
                    <SwipeablePanel
                        swipeTreshold={5}
                        direction={'top-to-bottom'}
                        active={this.state.galleryOpen}
                        onDismiss={this.toggleGallery}
                        disableSwipe={this.isSwipeDisabled}
                        animationSpeed={150}
                    >
                        <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                        <div className={"bg-white container-fluid py-3"} ref={this.galleryContainerRef} style={GalleryContainerStyle}>
                            <div className={"row"}>
                                {
                                    [this.props.productData.immagine, ...this.props.productData.immagini]
                                        .map((img) =>
                                            <GalleryGridImage
                                                key={img}
                                                srcHD={getCompressedImageUrl(img, GalleryImageSize.full.w, GalleryImageSize.full.h)}
                                                srcLD={getCompressedImageUrl(img, GalleryImageSize.preview.w, GalleryImageSize.preview.h)}
                                                borderColor={"#fff"}
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </SwipeablePanel>
                </ModalPortalHOC>
            </Fragment>
        )
    }

}


EcommerceImageGalleryButton.propTypes = {
    productData: PropTypes.object,
    buttonClassName: PropTypes.string,
    buttonStyle: PropTypes.object
}
