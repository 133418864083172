import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../BaseComponents/Page/Page";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {PacchettiAPI} from "../../../api/Pacchetti/PacchettiAPI";
import {EllipsisLoader} from "../../GenericUI/EllipsisLoader/EllipsisLoader";
import {PacchettiListItem} from "../PacchettiListPage/PacchettiListItem";
import {PacchettoListaMovimenti} from "./PacchettoListaMovimenti";

export class PacchettoDetailPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"I miei pacchetti"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    
                    onLeftIconClick={this.props.onDismiss}
                    rightIcon={<FaSolidIcon name={"sync"}/>}
                    rightIconText={"Aggiorna"}
                    onRightIconClick={this.props.onRefresh}
                    active={true}
                >
                    <div className={"container"}>
                        <PacchettiListItem
                            pacchetto={this.props.pacchetto}
                            color={this.props.color}
                            enableClick={false}
                        />
                        <div className={"card my-3 shadow-sm border-0 rounded-xl overflow-hidden"}>
                            <p className={"lead text-muted pt-4 px-4 mb-0 pb-0"}>
                                Fai scansionare al gestore dell'attività questo QR code per poterti accreditare o
                                addebitare dei crediti sulla card virtuale di questo pacchetto
                            </p>
                            <img className={"img-fluid"} src={this.props.pacchetto.qrcode_url}
                                 alt={"QR code pacchetto"}/>
                        </div>
                        <h4 className={"font-weight-bold"}>
                            Ultimi movimenti
                        </h4>
                        <PacchettoListaMovimenti idPacchetto={this.props.pacchetto.id_pacchetto}
                                                 qrCodeUrl={this.props.pacchetto.qrcode_url}/>
                    </div>
                </Page>
            </Fragment>
        )
    }

}

PacchettoDetailPage.propTypes = {
    onDismiss: PropTypes.func,
    onRefresh: PropTypes.func,
    pacchetto: PropTypes.object,
    color: PropTypes.string
}