import React from "react";
import moment from "moment";
import {string} from "prop-types";
import {PromotionsAPI} from "../../../../api/Promotions/PromotionsAPI";
import PromotionsCarousel from "../PromotionsCarousel";
import PromotionsAttiveOggiPage from "../AttiveOggi/PromotionsAttiveOggiPage";
import PromotionsAppCollegatePage from "./PromotionsAppCollegatePage";

export default class PromotionsAppCollegate extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            promotions: [],
            appCollegatePageOpen:false
        }
    }

    componentDidMount() {
        PromotionsAPI.PromotionsNoGeolocation.getPromotionsAppCollegate(5, 0)
            .then(response => {
                this.setState({promotions: response.promotions})
            })
    }

    appCollegatePageToggle=_=>{
        this.setState({appCollegatePageOpen: !this.state.appCollegatePageOpen})
    }

    render() {
        return (
            <div>
                <div className={"mt-5 p-3"}>
                    <h4><b>Dalle tue app! 🥰</b></h4>
                    <span className={"text-muted"}>Ti aiutiamo a tenere d'occhio le promozioni delle tue app preferite!</span>
                </div>
                <PromotionsCarousel
                    onVediDiPiuClick={this.appCollegatePageToggle}
                    promotions={this.state.promotions}
                />
                {
                    this.state.appCollegatePageOpen &&
                    <PromotionsAppCollegatePage
                        onDismiss={this.appCollegatePageToggle}
                    />
                }
            </div>
        );
    }

}
