import AppConfig from "../../config/AppConfig";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";


export const _Dashboard = {
    conferma: function (id_prenotazione) {
        let params = {
            id_prenotazione: id_prenotazione,
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/confirm-book`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    rifiuta: function (id_prenotazione) {
        let params = {
            id_prenotazione: id_prenotazione,
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/refuse-book`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getBookableGroups: function () {
        let params = {
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/get-groups`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getDateListBooksCount: function (startDate, endDate, id_gruppo) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/${AuthAPI.currentUser.accessToken}/${id_gruppo}/date-list-books-count`),
            {
                start: startDate,
                end: endDate
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getDateBooksUnavailable: function (startDate, endDate, id_gruppo) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/${AuthAPI.currentUser.accessToken}/${id_gruppo}/date-books-unavailable`),
            {
                start: startDate,
                end: endDate
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    setGroupUnavailable: function (id_gruppo, data_inizio_indisponibilita, data_fine_indisponibilita, orario_inizio_indisponibilita = null, orario_fine_indisponibilita = null) {
        let params = {
            id_gruppo: id_gruppo,
            data_inizio_indisponibilita: data_inizio_indisponibilita,
            data_fine_indisponibilita: data_fine_indisponibilita,
            orario_inizio_indisponibilita: orario_inizio_indisponibilita,
            orario_fine_indisponibilita: orario_fine_indisponibilita,
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/dateUnavailable/salva`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getBookInfo: function (id_prenotazione, qrscan) {
        let params = {
            id_prenotazione: id_prenotazione,
            qrscan: qrscan ? 1 : 0,
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/get-book-info`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    registerEntryByAdmin: function (id_prenotazione) {
        let params = {
            id_prenotazione: id_prenotazione,
            token: AuthAPI.currentUser.accessToken
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/registra-entrata`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    book: function (options) {
        var body = {
            id_gruppo: options.id_gruppo,
            id_utente: options.id_utente,
            data: options.data,
            sessioni: options.sessionData,
            useDefaultSessions: options.useDefaultSessions,
            note: options.note,
            forceBook: options.forceBook,
            partecipanti: options.partecipanti,
            questions: options.questions,
            totalePrenotazione: options.totalePrenotazione,
            importoScontoGlobale: options.importoScontoGlobale,
            metodoPagamento: options.metodoPagamento,
            token: AuthAPI.currentUser.accessToken
        };

        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/book`),
            body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },

    delete: function (id_prenotazione, withRefuse = null) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-gruppi/dashboard/delete`),
            {
                id_prenotazione: id_prenotazione,
                withRefuse: withRefuse,
                token: AuthAPI.currentUser.accessToken
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    }
};