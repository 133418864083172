import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SeatsExtendedBookType} from "../../../../../components/ModuloSeats/PropTypes/ExtendedBookType";
import moment from "moment";
import styled from "styled-components";
import APIConfig from "../../../../../api/APIConfig";
import AuthAPI from "../../../../../api/AuthAPI";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";

export class BookDetailView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.badgeStyle = {
            backgroundColor: this.props.book.sector.seat_color
        }
    }

    getUserProfilePic = _ => {
        return APIConfig.url(`/utenti/profile-pic/${AuthAPI.getUserAccessToken()}/${this.props.book.user.id_utente}/300`)
    }

    handleSetEntranceDate = e => this.props.onSetEntranceDate(this.props.book.book_id);

    render() {
        return (
            <Fragment>
                <div className={"card shadow-sm border-0 mb-3"}>
                    <div className={"card-body"}>
                        {/* HEADING */}
                        <div className={"text-center"}>
                            <div className={"d-flex align-items-center justify-content-center mb-3"}>
                                <ProfilePicThumbnail
                                    className={"mr-2 shadow-sm"}
                                    src={this.getUserProfilePic()}
                                />
                                <span className={"lead font-weight-bold"}>
                                    {this.props.book.user.nome} {this.props.book.user.cognome}
                                </span>
                            </div>
                        </div>

                        {/* ORARI */}
                        <div className={"text-capitalize text-center"}>
                            {moment(this.props.book.date).format("ddd DD MMMM")}
                        </div>
                        <div className={"text-center"}>
                            <b>Posto {this.props.book.seat.internal_code}</b> <span className={"badge text-white"}
                                                                                    style={this.badgeStyle}>{this.props.book.sector.name}</span>
                        </div>
                        <div className={"text-center"}>
                            Dalle <b>{this.props.book.start_time}</b> alle <b>{this.props.book.end_time}</b>
                        </div>
                        <div className={"text-center text-muted small"}>
                            Codice pren. #{this.props.book.book_id}
                        </div>
                    </div>
                </div>

                {
                    !!this.props.book.tickets && <div className={"card border-0 shadow-sm mb-3"}>
                        <div className={"card-body"}>
                            <b>Biglietti</b>
                            {
                                this.props.book.tickets.map(t => {
                                    return <div key={t.ticket_type_id} className={"pl-3"}>{t.quantity}x {t.name}</div>
                                })
                            }
                        </div>
                    </div>
                }
                {
                    !!this.props.book.accessories && <div className={"card border-0 shadow-sm mb-3"}>
                        <div className={"card-body"}>
                            <b>Accessori extra</b>
                            {
                                this.props.book.accessories.map(a => {
                                    return <div key={a.accessory_id} className={"pl-3"}>{a.quantity}x {a.name}</div>
                                })
                            }
                        </div>
                    </div>
                }
                {
                    this.props.book.entrance_date ?
                        <div className={"text-success text-center"}>
                            Ingresso registrato
                            il {moment(this.props.book.entrance_date).format('ddd DD MMM [alle] HH:mm')}
                        </div>
                        :
                        <div className={"text-center"}>
                            {
                                !!this.props.onSetEntranceDate &&
                                <button className={"btn btn-outline-primary mt-2"}
                                        onClick={this.handleSetEntranceDate}>
                                    <FaSolidIcon name={"sign-in-alt"}/> Registra ingresso
                                </button>
                            }
                        </div>
                }
            </Fragment>
        )
    }

}

BookDetailView.propTypes = {
    book: SeatsExtendedBookType,
    onSetEntranceDate: PropTypes.func
}

const ProfilePicThumbnail = styled.div`
    display:block;
    width:45px;
    height:45px;
    background-image: url('${props => props.src}');
    background-size:cover;
    background-position:center center;
    position:relative;
    overflow:hidden;
    border-radius:50%;
`;
