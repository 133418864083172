import React from "react";
import {FilePropType} from "./PropTypes/FilePropType";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {
    getFontAwesomeIconFromMIME
} from "../ChatPanel/ChatRoom/Attachments/Attachments.helpers";

export function UploadedFilePreview({file}) {
    const [mimeGenericType, mimeSpecificType] = file.mime_type.split("/");

    if (mimeGenericType === 'image') {
        const imageStyle = {
            backgroundImage: `url("${file.url}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: 40,
            height: 40,
            borderRadius: "0.25rem"
        }
        return (
            <React.Fragment>
                <div style={imageStyle} className={"mr-2"}/>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <FaSolidIcon name={getFontAwesomeIconFromMIME(file.mime_type)} className={"mr-2"}/>
        </React.Fragment>
    )
}

UploadedFilePreview.propTypes = {
    file: FilePropType
}
