import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import {BookizonAppManager} from "../../../index";
import AppConfig from "../../../config/AppConfig";
import {ID_CATEGORIA_BELLEZZA_BENESSERE} from "../../../const/CategorieNegoziConstants";

export function DashboardBanner(props) {
    const today = moment().format('YYYY-MM-DD');
    const id_categoria = parseInt(props.negozio.id_categoria);

    if (id_categoria === ID_CATEGORIA_BELLEZZA_BENESSERE) {

        //Bookizon LIVE
        if ('2025-03-17' <= today && today <= '2025-03-24') {
            const handleClick = _ => BookizonAppManager.openInAppBrowser('https://bookizon.it/web/n/bookizon-support/ODA2/modulo-gruppi/booking-engine');
            return (
                <React.Fragment>
                    <div className={"bg-white shadow-sm rounded mb-3 overflow-hidden"}
                         onClick={handleClick}>
                        <img
                            src={`${AppConfig.webServerUrl}/app/Packages/AdminDashboard/Assets/app-banner-bookizon-live.png?v=2`}
                            className={"img-fluid"}/>
                    </div>
                </React.Fragment>
            )
        }


        //Bookizon lifestyle beauty
        if (today < '2025-09-15') {
            const wrapperStyle = {
                backgroundColor: `rgb(147, 25, 87)`,
                background: `linear-gradient(45deg, rgba(147,25,87,1) 0%, rgba(254,175,45,1) 100%)`
            }
            const handleClick = _ => BookizonAppManager.openInAppBrowser('https://bkzn.it/10gbj');
            return (
                <React.Fragment>
                    <div className={"shadow-sm rounded mb-3 overflow-hidden"} style={wrapperStyle}
                         onClick={handleClick}>
                        <img
                            src={`${AppConfig.webServerUrl}/app/Packages/AdminDashboard/Assets/banner-lifestyle-beauty-app-2.png`}
                            className={"img-fluid"}/>
                    </div>
                </React.Fragment>
            )
        }
    }

    return '';
}

DashboardBanner.propTypes = {
    negozio: PropTypes.object
}
