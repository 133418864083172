import React from "react";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {DLA_ORDINE_ECOMMERCE, DLA_ORDINE_FOOD, DLA_PRENOTAZIONE_GRUPPO} from "../../../const/DeepLinkActionsCostants";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import {GroupBookResumePage} from "../../NextBooks/GroupBooks/GroupBookResume/GroupBookResumePage";
import OrdineResumePage from "../../NextBooks/Ordini/OrdineResumePage";

export class OrdineNotificationClickListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idOrdine: null
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_ORDINE_ECOMMERCE, this.handleOpenResume)
        FuxEvents.on(DLA_ORDINE_FOOD, this.handleOpenResume)
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_ORDINE_ECOMMERCE, this.handleOpenResume)
        FuxEvents.off(DLA_ORDINE_FOOD, this.handleOpenResume)
    }

    handleOpenResume = idOrdine => this.setState({idOrdine: idOrdine});

    handleDismissResume = _ => this.setState({idOrdine: null});


    render() {
        if (!this.state.idOrdine) return '';
        return (
            <ModalPortalHOC>
                <OrdineResumePage
                    id_ordine={this.state.idOrdine}
                    onDismiss={this.handleDismissResume}
                />
            </ModalPortalHOC>
        )
    }

}

OrdineNotificationClickListener.propTypes = {}