import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";


import translationIt from "./locales/it/translation.json";
import translationEn from "./locales/en/translation.json";
import translationFr from "./locales/fr/translation.json";
import translationDe from "./locales/de/translation.json";
import translationEs from "./locales/es/translation.json";
import translationNl from "./locales/nl/translation.json";
import translationHr from "./locales/hr/translation.json";



i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: true,
        lng: localStorage.i18nextLng,
        fallbackLng: "en", // use en if detected lng is not available
        keySeparator: ".", // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        supportedLngs: ['en', 'it', 'fr', 'de', 'es', 'hr', 'nl'],
        nonExplicitSupportedLngs: true,
        resources: {
            en: {translations: translationEn},
            it: {translations: translationIt},
            fr: {translations: translationFr},
            de: {translations: translationDe},
            es: {translations: translationEs},
            hr: {translations: translationHr},
            nl: {translations: translationNl},
        },
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations"
    });



export default i18n;
