import AuthAPI from "../AuthAPI";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";

export const _Admin = {
    movimenta: function (authorizationToken, punti) {
        const body = {
            authorization_token: authorizationToken,
            punti: punti,
            token: AuthAPI.currentUser.accessToken
        }

        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/pacchetti/admin/movimenta`), body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getInfo: function (id_pacchetto) {
        const params = {
            id_pacchetto: id_pacchetto,
            token: AuthAPI.currentUser.accessToken
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/pacchetti/admin/get-info`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
}