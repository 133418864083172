import React from "react";
import {shadeColor} from "../../helpers/UIUtility";

export const Decorator5 = props => {
    const color = shadeColor(props.color, 0);
    return (
        <svg className={props.className} style={props.style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.18524 35.32339">
            <title>decorator-5</title>
            <g id="Livello_2" data-name="Livello 2">
                <g id="Livello_1-2" data-name="Livello 1">
                    <path fill={color}
                          d="M17.61155,35.32339a17.6679,17.6679,0,0,1-7.79231-33.5,17.56208,17.56208,0,0,1,23.559,8.05384,17.69742,17.69742,0,0,1-8.00513,23.62308l-.00513.00256A17.49963,17.49963,0,0,1,17.61155,35.32339Zm6.01539-5.35385h0ZM17.58078,7.88236A9.63663,9.63663,0,0,0,13.309,8.88492,9.81218,9.81218,0,0,0,8.87565,21.9798a9.69662,9.69662,0,0,0,13.00513,4.459h0a9.8067,9.8067,0,0,0,4.42308-13.09744,9.71815,9.71815,0,0,0-8.72308-5.459Z"/>
                </g>
            </g>
        </svg>
    )
}
