export const FuxSharedState = function (initialState) {
    let state = {...initialState};
    let callbacks = [];

    return {
        subscribe: cb => {
            callbacks.push(cb);
        },
        unsubscribe: cb => {
            callbacks = callbacks.filter(c => c !== cb);
        },
        setState: newState => {
            state = {...state, ...newState};
            for (let i = 0; i < callbacks.length; i++) {
                callbacks[i](state);
            }
        },
        getState: _ => state
    }
}