const hasProducts = (c, productList) => {
    let hasProducts = false;
    for(var i in productList){
        if (productList[i].id_categoria === c.id_categoria){
            hasProducts = true;
            break;
        }
    }
    return hasProducts;
}

const hasOnlyEmptyChild = (c, productList) => {
    var hasOnlyEmpty = true;
    for(var i in c.child){
        if (!isCategoryEmpty(c.child[i],productList)){
            hasOnlyEmpty = false;
            break;
        }
    }
    return hasOnlyEmpty;
}

const isCategoryEmpty = (c, productList) => {
    return (c.child.length === 0 || hasOnlyEmptyChild(c,productList)) && !hasProducts(c, productList);
}

export default isCategoryEmpty;
