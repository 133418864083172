import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../../../components/FontAwesomeIcons";
import {OutsideClickListener} from "../../../../../../../helpers/OutsideClickListener";
import {AddServiceCard} from "./AddServiceCard";
import './AddServiceButton.css';

export class AddServiceButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            idServizio: null
        }
    }

    handleExpand = _ => this.setState({expanded: true});
    handleCollapse = _ => this.setState({expanded: false});


    render() {
        return (
            <React.Fragment>
                {
                    !this.state.expanded &&
                    <div className={"sub-book-add-btn py-1 px-2 my-2"} onClick={this.handleExpand}>
                        <FaSolidIcon name={"plus"}/> Aggiungi servizio
                    </div>
                }
                {
                    this.state.expanded &&
                    <div className={"sub-book-form-wrapper my-2"}>
                        <div className={"sub-book-form-container w-100"}>
                            <OutsideClickListener onOutSideClick={this.handleCollapse}>
                                <div className={"bounce-in"}>
                                    <AddServiceCard
                                        onDismiss={this.handleCollapse}
                                        rearrange={true}
                                        {...this.props}
                                    />
                                </div>
                            </OutsideClickListener>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }

}

AddServiceButton.propTypes = {
    idPrenotazione: PropTypes.any, //La prenotazione da usare come orario di inizio per il nuovo servizio aggiunto
    nomeServizioPrenotazione: PropTypes.string,
    direction: PropTypes.oneOf(['after', 'before']), //La posizione in cui aggiungere il servizio scelto relativamente alla prenotazione indicata
    startTime: PropTypes.string, //L'orario di inizio del nuovo servizio in formato HH:mm
    optGroupServizi: PropTypes.array, //Struttura per optgroup dei servizi divisi in categoria
    onAppendSubBook: PropTypes.func,
    dropdownDirection: PropTypes.string
}
