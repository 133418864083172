import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import React from "react";
import {DipendenteItemView} from "../DipendentePicker/DipendenteItemView";

/**
 * @description: Filtra i servizi in base alla sede o il dipendente scelto */

export default function filtraDipendenti(listaDipendenti, listaServizi, listaSedi, bookInfo) {
    var newListaDipendenti = listaDipendenti.slice();

    if (bookInfo.id_servizio) {
        bookInfo.id_servizio.map(id_serv => {
            newListaDipendenti = newListaDipendenti.filter((d) => {
                return d.servizi.includes(id_serv);
            });
        })
    }

    if (bookInfo.id_sede) {
        newListaDipendenti = newListaDipendenti.filter((d) => {
            return d.id_sede === bookInfo.id_sede;
        });
    }

    return newListaDipendenti;
}


/**
 * Aggiunge la chiave "itemPickerData" ad ogni elmento della lista
 * */
export function extendDipendentiListWithItemPickerData(listaDipendenti, infoNegozio) {
    const list = listaDipendenti.map(dipendente => {
        return {
            ...dipendente,
            itemPickerData: {
                text: <DipendenteItemView dipendente={dipendente} infoNegozio={infoNegozio}/>,
                value: dipendente.id_dipendente,
                image: undefined
            }
        };
    });
    return list;
}
