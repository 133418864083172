import React from "react";
import PropTypes from "prop-types";
import AppConfig from "../../../config/AppConfig";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {getCompressedImageUrl} from "../../../helpers/ImageUtility";
import {CategoryImageSize} from "../../../const/ImageSizeCostants";
import {LazyImage} from "../../../helpers/ImageUtility/LazyImage";
import {PlaceholderBlock} from "../../../helpers/Placeholders";


export class CategoriaQuickPickerCard extends React.Component {

    constructor(props) {
        super(props);

        this.imageDivStyle = {
            background: `url('${this.getImageUrl()}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            width: "100%",
            paddingTop: "37.5%"
        }
        this.nameContainerStyle = {
            width: "90%",
            left: "5%",
            bottom: 0,
            transform: "translateY(+50%)",
            position: "absolute",
        }
    }

    getImageUrl = _ => getCompressedImageUrl(this.props.itemData.immagine, CategoryImageSize.banner.w, CategoryImageSize.banner.h);

    handleClick = _ => this.props.onChoose(this.props.itemData.id_categoria);

    render() {
        if (this.props.itemData.immagine) {
            return (
                <div className={"position-relative bg-main-z3 shadow-sm mb-5"} onClick={this.handleClick}>
                    <LazyImage
                        src={this.getImageUrl()}
                        fallback={CategoriaPlaceholderHOC}
                        showLoader={true}
                        loaderSize={3}
                    >
                        <div style={this.imageDivStyle}/>
                    </LazyImage>
                    <div
                        className={"bg-main-z3 p-3 shadow-sm text-center d-flex align-items-center justify-content-between font-weight-bold text-on-bg-main"}
                        style={this.nameContainerStyle}>
                        {<SafeHtmlContainer html={this.props.itemData.nome}/>}
                        <FaSolidIcon name={"chevron-right"}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    onClick={this.handleClick}
                    className={"bg-main-z3 shadow-sm mb-3 p-3 d-flex align-items-center justify-content-between text-on-bg-main"}>
                    {<SafeHtmlContainer html={this.props.itemData.nome}/>}
                    <FaSolidIcon name={"chevron-right"}/>
                </div>
            )
        }
    }

}


CategoriaQuickPickerCard.propTypes = {
    itemData: PropTypes.object,
    onChoose: PropTypes.func,
}

const CategoriaPlaceholderHOC = props => {
    const style = {
        width: "100%",
        paddingTop: "37.5%"
    }
    return <PlaceholderBlock style={style}/>
}
