import React from "react";
import PropTypes from "prop-types";
import {LinkedBookType} from "../../../../../components/ModuloSeats/PropTypes/LinkedBookType";
import {formatCurrency} from "../../../../../helpers/CurrencyHelpers";
import {CancellationView} from "./CancellationView";
import {SEAT_BOOK_STATUS_CONFIRMED} from "../../../../../components/ModuloSeats/Constants/BookStatuses";
import ReceiptPreview from "../../../../../components/ModuloCassa/ReceiptPreview";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";

export class SeatView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.badgeStyle = {
            backgroundColor: this.props.linkedBook.sector.seat_color,
            isReceiptVisible: false
        }
    }

    toggleReceiptVisibility = _ => this.setState({isReceiptVisible: !this.state.isReceiptVisible});

    render() {
        const {seat, sector, book} = this.props.linkedBook;
        const tickets = this.props.linkedBook.tickets;
        const accessories = this.props.linkedBook.accessories;
        const receiptInfo = this.props.linkedBook.receipt;
        return (
            <React.Fragment>
                <div className={"d-flex align-items-center"}>
                    Posto <b>{seat.internal_code}</b>
                    <span className={"badge text-white mx-2"} style={this.badgeStyle}>
                        {sector.name}
                    </span>
                    {formatCurrency(book.price, 'EUR', 'it-IT')}
                </div>
                <div className={"small text-muted"}>
                    Codice prenotazione #{book.book_id}
                </div>
                {
                    (tickets && tickets.length > 0) &&
                    <div className={"mt-2"}>
                        {
                            tickets.map(t => {
                                return <div key={t.ticket_type_id} className={"pl-2"}>
                                    {t.quantity}x {t.name} {formatCurrency(t.unit_price * t.quantity, 'EUR', 'it-IT')}
                                </div>
                            })
                        }
                    </div>
                }
                {
                    (accessories && accessories.length > 0) &&
                    <div className={"mt-2"}>
                        {
                            accessories.map(a => {
                                return <div key={a.accessory_id} className={"pl-2"}>
                                    {a.quantity}x {a.name} {formatCurrency(a.unit_price * a.quantity, 'EUR', 'it-IT')}
                                </div>
                            })
                        }
                    </div>
                }
                <div className={"mt-2"}>
                    {
                        book.global_discount_amount > 0 &&
                        <React.Fragment>
                            Subtotale: {formatCurrency(book.global_price, 'EUR', 'it-IT')}<br/>
                            Sconto: {formatCurrency(book.global_discount_amount, 'EUR', 'it-IT')}<br/>
                        </React.Fragment>
                    }
                    Totale: {formatCurrency(book.global_price - book.global_discount_amount, 'EUR', 'it-IT')}<br/>
                    {
                        this.props.paidAmount > 0 &&
                        <React.Fragment>
                            Importo già pagato: {formatCurrency(this.props.paidAmount, 'EUR', 'it-IT')}<br/>
                            {
                                book.global_price - book.global_discount_amount > this.props.paidAmount &&
                                <b>
                                    Da pagare in
                                    struttura: {formatCurrency(book.global_price - book.global_discount_amount - this.props.paidAmount, 'EUR', 'it-IT')}<br/>
                                </b>
                            }
                        </React.Fragment>
                    }
                </div>
                <CancellationView
                    linkedBook={this.props.linkedBook}
                    onUpdate={this.props.onUpdate}
                    hasPayments={this.props.paidAmount > 0}
                    dispute={this.props.dispute}
                />
                {
                    !!receiptInfo &&
                    <div className={"mt-2"}>
                        <button className={"btn btn-link btn-sm text-muted"} onClick={this.toggleReceiptVisibility}>
                            {
                                this.state.isReceiptVisible ?
                                    <span>Nascondi info scontrino <FaSolidIcon name={"chevron-up"}/></span>
                                    :
                                    <span>Mostra info scontrino <FaSolidIcon name={"chevron-down"}/></span>
                            }
                        </button>
                        {
                            this.state.isReceiptVisible &&
                            <ReceiptPreview
                                idRicevuta={receiptInfo.receipt.id_ricevuta}
                                numeroDocumento={receiptInfo.receipt.numero_documento}
                                righe={receiptInfo.rows}
                                pagamenti={receiptInfo.payments}
                                dataCreazione={receiptInfo.receipt.data_creazione}
                                showRows={false}
                            />
                        }
                    </div>
                }
            </React.Fragment>
        )
    }

}

SeatView.propTypes = {
    linkedBook: LinkedBookType,
    paidAmount: PropTypes.number,
    onUpdate: PropTypes.func,
    dispute: PropTypes.object,
}