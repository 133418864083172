import React from "react";
import LauncherAppListItem from "./LauncherAppListItem";
import {Droppable} from "react-beautiful-dnd";
import styled from "styled-components";
import AppConfig from "../../config/AppConfig";

const ListGroup = styled.div.attrs(props => ({
    className:''
}))`

& > div:first-of-type {
    border-top-left-radius:.5rem;
    border-top-right-radius:.5rem;
}

& > div:last-of-type {
    border-bottom-left-radius:.5rem;
    border-bottom-right-radius:.5rem;
}
`;


class InnerList extends React.Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.apps !== this.props.apps ||
            nextProps.searchQuery !== this.props.searchQuery ||
            nextProps.editMode !== this.props.editMode;
    }


    render() {
        return (
            this.props.apps.map((negozio, i) => {
                let imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${negozio.id_negozio}/logoIcon/100`;
                if (!this.props.searchQuery || negozio.nome.toLowerCase().indexOf(this.props.searchQuery.toLowerCase()) > -1) {
                    return (
                        <LauncherAppListItem
                            key={negozio.id_negozio}
                            index={i}
                            imageUrl={imageFullUrl}
                            negozio={negozio}
                            onAppOpen={this.props.onAppOpen}
                            onAppDelete={this.props.onAppDelete}
                            onAppReorder={this.props.onAppReorder}
                            editMode={this.props.editMode}
                        />
                    );
                }
            })
        )
    }
}

export default class LauncherAppList extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.apps !== this.props.apps ||
            nextProps.searchQuery !== this.props.searchQuery ||
            nextProps.editMode !== this.props.editMode;
    }

    render() {
        return (
            <Droppable droppableId={"LauncherAppListDroppable"}>
                {
                    provided1 => (
                        <ListGroup ref={provided1.innerRef} {...provided1.droppableProps}>
                            <InnerList {...this.props}/>
                            {provided1.placeholder}
                        </ListGroup>
                    )
                }

            </Droppable>
        );
    }

}
