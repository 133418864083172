/* globals AndroidInterface, webkit */
import React from "react";
import {FaSolidIcon} from "./FontAwesomeIcons";
import {BookizonAppManager} from "../index";

export default class ShareComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            copied: false
        }
    }

    getShareLink() {
        return `https://bookizon.it/dl/${this.props.codiceNegozio}`;
    }

    handleShare = _ => {
        const shareLink = this.getShareLink();
        const shareMessage = `Aggiungi anche tu l'app "${this.props.appName}" su Bookizon! Clicca questo link: ${shareLink}`;
        BookizonAppManager.shareData(shareMessage);
    };

    handleCopy = _ => {
        var textField = document.createElement('textarea');
        textField.innerText = this.getShareLink();
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({copied: true});
        setTimeout(_ => this.setState({copied: false}), 2000);
    };

    render() {
        return (
            <div className={"text-center"}>
                <p className={"lead"}>Scannerizza QR code</p>
                <small>oppure invia questo link ai tuoi amici</small>
                <div className={"input-group mt-3"}>
                    <input type={"text"} value={this.getShareLink()} readOnly={true} className={"form-control"}
                           disabled={true}/>
                    {
                        window.webkit !== undefined || window.AndroidInterface !== undefined || window.AndroidBridgeSendMessage !== undefined ?
                            < div className={"input-group-append"}>
                                <button className={"btn btn-primary"} onClick={this.handleShare}><FaSolidIcon
                                    name={"share"}/></button>
                            </div>
                            :
                            <div className={"input-group-append"}>
                                <button className={"btn btn-primary"} onClick={this.handleCopy}><FaSolidIcon
                                    name={"copy"}/> Copia
                                </button>
                            </div>

                    }
                </div>
                {this.state.copied ? <small className={"text-success"}>Link copiato!</small> : ''}
            </div>
        );
    }

}
