import React from "react";
import PropTypes from "prop-types";
import {StoriesLikeSelectorItem} from "./StoriesLikeSelectorItem";

export class StoriesLikeSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedValue: null
        }
        if (this.props.defaultSelectFirst){
            if (this.props.items.length){
                this.state.selectedValue = this.props.items[0].value;
            }
        }
        if (this.props.defaultValue){
            this.state.selectedValue = this.props.defaultValue;
        }
    }

    handleSelect = value => {
        this.setState({selectedValue: value});
        if (this.props.onChange){
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <div className={"d-flex align-items-center flex-nowrap overflow-auto"}>
                {
                    this.props.items.map(item =>
                        <StoriesLikeSelectorItem
                            key={item.value}
                            imageUrl={item.imageUrl}
                            value={item.value}
                            label={item.label}
                            onSelect={this.handleSelect}
                            size={this.props.circleSize}
                            selected={this.state.selectedValue === item.value}
                        />
                    )
                }
            </div>
        )
    }

}

StoriesLikeSelector.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        imageUrl: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })).isRequired,
    defaultSelectFirst: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    circleSize: PropTypes.number
}

StoriesLikeSelector.defaultProps = {
    defaultSelectFirst: true,
    circleSize: 48
}
