import React from "react";
import PropTypes from "prop-types";
import {
    HOTEL_TARIFFE_POLICY_LABEL_MAP,
    HOTEL_TARIFFE_POLICY_NOT_REFUNDABLE,
    HOTEL_TARIFFE_POLICY_REFUNDABLE,
    HOTEL_TARIFFE_POLICY_REFUNDABLE_1W,
    HOTEL_TARIFFE_POLICY_REFUNDABLE_2W,
    HOTEL_TARIFFE_POLICY_REFUNDABLE_3W,
    HOTEL_TARIFFE_POLICY_REFUNDABLE_4W
} from "../../../../const/ModuloHotelCostants";
import {swalError, swalInput, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {USER_DISPUTE_REQUEST_EVT} from "../../../../const/AppEventsCostants";
import {ModuloHotelAPI} from "../../../../api/ModuloHotel/ModuloHotelAPI";

export class CancellationView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleRefund = _ => {
        swalInput(
            "Motiva la tua richiesta",
            "Scrivi il motivo per cui stai annullando la prenotazione e richiedendo il rimborso",
            "textarea", null, false, true
        ).then(({value}) => {
            if (!value) return;
            swalLoading('Attendere prego...');
            ModuloHotelAPI.Books.requestRefund(this.props.bookData.id_prenotazione, value)
                .then(m => {
                    swalSuccess(m)
                        .then(_ => {
                            this.props.onUpdate();
                            FuxEvents.emit(USER_DISPUTE_REQUEST_EVT);
                        });
                }).catch(swalError)
        })
    }

    render() {
        const book = this.props.bookData;

        switch (book.politica_pagamento) {
            case HOTEL_TARIFFE_POLICY_REFUNDABLE:
            case HOTEL_TARIFFE_POLICY_REFUNDABLE_1W:
            case HOTEL_TARIFFE_POLICY_REFUNDABLE_2W:
            case HOTEL_TARIFFE_POLICY_REFUNDABLE_3W:
            case HOTEL_TARIFFE_POLICY_REFUNDABLE_4W:
                return (
                    <div>
                        <p className={"text-muted"}>
                            Questa prenotazione è {HOTEL_TARIFFE_POLICY_LABEL_MAP[book.politica_pagamento].toLowerCase()}
                        </p>
                        <button className={"btn btn-danger"} onClick={this.handleRefund}>
                            Annulla e richiedi rimborso
                        </button>
                    </div>
                )
            case HOTEL_TARIFFE_POLICY_NOT_REFUNDABLE:
                return (
                    <div>
                        <p className={"text-danger"}>
                            La tariffa scelta in fase di prenotazione non consente la cancellazione gratuita
                        </p>
                        <button className={"btn btn-danger"} disabled={true}>
                            Annulla e richiedi rimborso
                        </button>
                    </div>
                )
        }
        return '';
    }

}

CancellationView.propTypes = {
    bookData: PropTypes.object.isRequired,
    onUpdate: PropTypes.func
}