import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FuxCarousel} from "../../../../components/BaseComponents/FuxCarousel/FuxCarousel";
import {getCompressedImageUrl} from "../../../../helpers/ImageUtility";
import {ProductImageSize} from "../../../../const/ImageSizeCostants";
import styled from "styled-components";
import ImageZoomPanel from "../../../../components/BaseComponents/ImageZoomPanel";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import "../../../../css/ribbon.css"
import {CollapsibleHTMLTextView} from "../../../../hoc/CollapsibleHTMLTextView";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {swalConfirm, swalError, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import {FidelityCardAPI} from "../../../../api/FidelityCard/FidelityCardAPI";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {FIDELITY_CARD_PRIZE_REDEEM_EVT} from "../../../../const/AppEventsCostants";
import moment from "moment";

export class FidelityCardPremiRiscattatiItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zoomedImage: '',
            fullDescription: false
        }
    }

    getImmagini = _ => {
        let immagini = [];
        if (this.props.premioData.immagine) immagini.push(this.props.premioData.immagine);
        immagini = [...immagini, ...this.props.premioData.immagini]
        return immagini.map(url => getCompressedImageUrl(url, ProductImageSize.preview.w, ProductImageSize.preview.h));
    }

    render() {
        const premio = this.props.premioData;
        const badge = premio.stato_transazione === 'confermata' ?
            <span className={"badge badge-success"}>Ritirato</span> :
            <span className={"badge badge-warning"}>In attesa di ritiro</span>;
        const data = moment(premio.data_creazione).format("ddd DD MMM YYYY");
        return (
            <Fragment>
                <div className={"list-group-item"}>
                    <div className={"d-flex align-items-center"}>
                        <ProductImage
                            src={getCompressedImageUrl(premio.immagine, ProductImageSize.preview.w, ProductImageSize.preview.h)}
                        />
                        <div className={"ml-3"}>
                            <h5>{premio.titolo}</h5>
                            <div className={"text-muted small"}>{data} {premio.punti} punti</div>
                            <div>{badge}</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

FidelityCardPremiRiscattatiItem.propTypes = {
    premioData: PropTypes.object
}


const ProductImage = styled.div`
    width:80px;
    height:80px;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:contain;
    background-image:url("${props => props.src}");
`;
