import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {EllipsisLoader} from "../components/GenericUI/EllipsisLoader/EllipsisLoader";
import React from "react";

const swal = withReactContent(Swal);

/**
 * @description:
 * Può essere usato in alternativa al classico swal.close().
 * Prevede una rimozione forzata e "manuale" agendo sugli elementi del DOM.
 * Infatti può capitare che venga lanciata una exception quando vengono richiamati in poco tempo un swal.fire e swal.close
 * l'uno ha bisogno di elementi che gestisce l'altro e di creano eccezioni per operazioni sul DOM.
 * Questa funzione intercetta l'errore con un try...catch e avvia l'operazione di "chiusura forzata".
 * Il setTimeout è usato per evitare la generazione di una eccezione quando swal tenta di usare gli elementi del DOM per
 * mostrare il popup, ma quest'ultimi sono stati rimossi dalla chiusura forzata. Il setTimeout permette di ritardare
 * il processo facendo in modo che swal possa eseguire le sue istruzioni, chiudendo comunque dopo 100ms il popup.
 * */
swal.safeClose = _ => {
    if (swal.isVisible()){
        try{
            return swal.close();
        }catch(e){
            //In caso di errore potrebbe essere bloccato il body dalle classi di swal
            const classesToRemove = ["swal2-shown", "swal2-height-auto", "swal2-iosfix"];
            classesToRemove.map(c => document.body.classList.remove(c));
            setTimeout(_ => {
                const container = document.querySelector('.swal2-container');
                if (container){
                    container.parentNode.removeChild(container);
                }
            },100);
        }

    }
    return false;
}

function swalLoading(messaggio,allowOutsideClick) {
    var s = swal.fire({
        html: <EllipsisLoader/>,
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: allowOutsideClick !== undefined ? allowOutsideClick : false,
        customClass: {
            container:'bg-light-transparent', //Backdrop
            popup:'bg-transparent'
        }
    });
    return s;
}

function swalSuccess(message) {
    return swal.fire({
        icon: 'success',
        html: message,
        showConfirmButton: true,
        showCancelButton: false
    });
}

function swalInfo(message) {
    return swal.fire({
        icon: 'info',
        html: message,
        showConfirmButton: true,
        showCancelButton: false
    });
}

function swalError(message) {
    console.error("SWAL",message);
    return swal.fire({
        icon: 'error',
        html: message,
        showConfirmButton: true,
        showCancelButton: false
    });
}

function swalInput(message, placeholder, type, icon, allowOutsideClick, showCancelButton, inputValue) {
    return swal.fire({
        icon: icon ? icon : 'question',
        input: type ? type : 'input',
        inputPlaceholder: placeholder,
        inputValue: inputValue || '',
        html: message,
        showConfirmButton: true,
        showCancelButton: showCancelButton || false,
        cancelButtonText: 'Annulla',
        confirmButtonText: 'Conferma',
        allowOutsideClick: allowOutsideClick !== undefined ? allowOutsideClick : false
    });
}

function swalConfirm(message, confirmText, cancelText) {
    return new Promise(function (resolve, reject) {
        swal.fire({
            icon: 'question',
            html: message,
            showConfirmButton: true, showCancelButton: true, reverseButtons: true,
            confirmButtonText: confirmText ? confirmText : 'Procedi',
            cancelButtonText: cancelText ? cancelText : 'Annulla'
        })
            .then(function (r) {
                if (r.value) {
                    resolve();
                }
                reject();
            })
            .catch(reject);
    });
}

function swalPopOver(title, content, closeButton) {
    return swal.fire({
        icon: false,
        title: title,
        html: content,
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: closeButton
    });
}

function swalToast(text, position, icon, timer) {
    return swal.fire({
        toast: true,
        position: position,
        text: text,
        icon: icon,
        timer: timer || 1500,
        showConfirmButton:false,
        width:"90vw"
    })
}

function swalSelect(message, options, confirmText, cancelText, title, defaultValue) {
    return new Promise(function (resolve, reject) {
        swal.fire({
            title: title || '',
            icon: 'question',
            input: 'select',
            inputOptions: options,
            inputValue: defaultValue || '',
            text: message,
            showConfirmButton: true, showCancelButton: true, reverseButtons: true,
            confirmButtonText: confirmText ? confirmText : 'Procedi',
            cancelButtonText: cancelText ? cancelText : 'Annulla'
        })
            .then(function (r) {
                if (r.value) {
                    resolve(r.value);
                }
                reject();
            })
            .catch(reject);
    });
}

export {swal, swalSuccess, swalError, swalLoading, swalConfirm, swalPopOver, swalInput, swalToast, swalInfo, swalSelect};

