import React from "react";
import {AppIconThumbnail} from "../../components/BaseComponents/AppIconThumbnail";
import {PlaceholderBlock} from "../../helpers/Placeholders";


export default class NegozioSearchResultPlaceholder extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"card p-1 mb-2 rounded-0"} style={{visibility:this.props.visibility ? 'visible' : 'hidden'}}>
                <div className={"container-fluid"}>
                    <div className={"row align-items-center"}>
                        <div className={"col-2 px-0 py-2 justify-content-center d-flex"}>
                            <AppIconThumbnail
                                size={"50px"}
                                src={null}
                            />
                        </div>
                        <div className={"col text-truncate"}>
                            {/* Nome */}
                            <h6 className="mt-0 text-truncate font-weight-bold"
                                style={{marginBottom: "-6px"}}>
                                <PlaceholderBlock>&nbsp;</PlaceholderBlock>
                            </h6>
                            {/* Indirizzo */}
                            <small className={"text-muted"}>
                                <PlaceholderBlock>&nbsp;</PlaceholderBlock>
                            </small>
                            {/* Features */}
                            <PlaceholderBlock>&nbsp;</PlaceholderBlock>
                        </div>

                        {/* Pulsante */}
                        <div className={"col-3 text-right"}>
                            <PlaceholderBlock>&nbsp;</PlaceholderBlock>
                        </div>

                        {/* Info sulle consegne/asporto */}
                        <div className={"col-12"}>
                            <PlaceholderBlock>&nbsp;</PlaceholderBlock>
                        </div>

                        {/* Rating */}
                        <div className={"col-12 p-1 border-top"}>
                            <PlaceholderBlock>&nbsp;</PlaceholderBlock>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
