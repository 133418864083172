import React from 'react'
import AuthAPI from "../../api/AuthAPI";
import {swal, swalError, swalLoading, swalSuccess} from '../../helpers/SweetAlertWrapper';
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import ValidateOTPPage from "../ValidateOTPPage";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {Base64} from "../../helpers/Base64";
import PersonalUserInformation from "./RegisterFormSteps/PersonalUserInformation";
import ContactsInformation from "./RegisterFormSteps/ContactsInformation";
import PasswordAndTermsAndConditions from "./RegisterFormSteps/PasswordAndTermsAndConditions";
import "./RegisterFormSteps/RegisterForm.css"
import i18next from "i18next";
import OtpProviderChoose from "./RegisterFormSteps/OtpProviderChoose";
import PropTypes from "prop-types";

const FORM_STEPS = ["personalUserInformation", "contactsInformation", "passwordAndTermsAndConditions", "otpProvider"];
const STEP_CLASSES = {
    'in_from_left': 'step-in-from-left',
    'in_from_right': 'step-in-from-right',
    'out_to_left': 'step-out-to-left',
    'out_to_right': 'step-out-to-right',
}

export default class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            cognome: '',
            email: '',
            password: '',
            password2: '',
            cellulare: '',
            conferma_cellulare: '',
            termini_condizioni: false,
            privacy_policy: false,
            giorno_nascita: '',
            mese_nascita: '',
            anno_nascita: '',
            sesso: '-1',
            otp_provider: 'sms',
            errors: {},
            stepFormToShow: "personalUserInformation",
            stepFormShowed: new Array(FORM_STEPS[0]),
            stepsClassNames: new Array(FORM_STEPS.length).fill('')
        };
        this.goBackButtonStyle = {
            fontSize: "20px"
        }
        this.formRef = null;
        this.state.stepsClassNames[0] = STEP_CLASSES.in_from_right
    }


    handleSubmit = _ => {
        swalLoading();
        var extraData = {...this.state};
        extraData.data_nascita = `${extraData.anno_nascita || '0000'}-${extraData.mese_nascita || '00'}-${extraData.giorno_nascita || '00'}`;
        extraData.formHTML = Base64.encode(this.formRef.innerHTML);

        if (!this.formRef) {
            swalError(i18next.t('register.errors.generic'));
            return;
        }

        AuthAPI.registerWithEmailAndPassword(this.state.email, this.state.password, extraData)
            .then((response) => {
                swal.fire({icon: "success", title: i18next.t('generic.fantastic-title'), text: response.message});
                this.setState({
                    isValidateOtpOpen: true,
                    id_utente: response.data
                });
            })
            .catch(function (e) {
                swal.fire({icon: "error", title: "Oops!", text: e});
            });
    }

    handleCloseValidateOTP = _ => this.setState({isValidateOtpOpen: false});

    handleValidateOTP = otp => {
        AuthAPI.confirmAccountWithOTP(this.state.id_utente, otp)
            .then(token => {
                swal.fire({
                    icon: 'success',
                    html: i18next.t('register.messages.confirm'),
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: i18next.t('generic.proceed-btn')
                }).then(_ => {
                    AuthAPI.saveAccessToken(token);
                    AuthAPI.loginWithAccessToken();
                    this.setState({isValidateOtpOpen: false});
                    FuxEvents.emit('homepageEvent', 'registerModal');
                });
            })
            .catch(swalError);
    }

    handleResendOTP = provider => {
        swalLoading();
        this.setState({otp_provider: provider || 'sms'}, _ => {
            AuthAPI.resendOTP(this.state.id_utente, this.state.otp_provider)
                .then(swalSuccess)
                .catch(swalError);
        });
    }

    handleNextRegistrationStep = data => {
        this.setState(data, _ => {
            //Cambio schermata
            let indexOfActualForm = FORM_STEPS.indexOf(this.state.stepFormToShow)
            if (indexOfActualForm + 1 === FORM_STEPS.length) {
                this.handleSubmit()
                return
            }
            const newStepsClassNames = [this.state.stepsClassNames];
            newStepsClassNames[indexOfActualForm] = STEP_CLASSES.out_to_left;
            newStepsClassNames[indexOfActualForm + 1] = STEP_CLASSES.in_from_right

            let stepFormShowed = this.state.stepFormShowed.slice()
            if (!stepFormShowed.includes(FORM_STEPS[indexOfActualForm + 1])) {
                stepFormShowed.push(FORM_STEPS[indexOfActualForm + 1])
            }

            this.setState({
                stepFormShowed: stepFormShowed,
                stepFormToShow: FORM_STEPS[indexOfActualForm + 1],
                stepsClassNames: newStepsClassNames
            });
        })
    }

    handleOnLeftIconClick = _ => {
        let indexOfActualForm = FORM_STEPS.indexOf(this.state.stepFormToShow)
        if (indexOfActualForm === 0) {
            this.props.onDismiss()
        }
        const newStepsClassNames = [this.state.stepsClassNames];
        newStepsClassNames[indexOfActualForm] = STEP_CLASSES.out_to_right;
        newStepsClassNames[indexOfActualForm - 1] = STEP_CLASSES.in_from_left
        this.setState({stepFormToShow: FORM_STEPS[indexOfActualForm - 1], stepsClassNames: newStepsClassNames});
    }


    getFormRef = node => this.formRef = node;

    render() {
        return (
            <div>
                <div className={"w-100 position-fixed pt-4"} style={{zIndex: '20'}}>
                    <button type={"button"} className={"btn btn-link text-primary __androidDismissable__"}
                            onClick={_ => {
                                this.handleOnLeftIconClick()
                            }} style={this.goBackButtonStyle}>
                        <FaSolidIcon name={"chevron-left"}/>
                    </button>
                </div>

                <form autoComplete={"off"} onSubmit={this.handleSubmit} ref={this.getFormRef}>
                    <input autoComplete="on" style={{display: 'none'}}
                           id="fake-hidden-input-to-stop-google-address-lookup"/>
                    <div className={'position-relative w-100'}
                         style={{height: 'calc(100vh - 100px)', overflowX: 'hidden'}}>

                        {
                            this.state.stepFormShowed.includes('personalUserInformation') &&
                            <div
                                className={`registerStepContainer ${this.state.stepsClassNames[FORM_STEPS.indexOf('personalUserInformation')]}`}>
                                <PersonalUserInformation
                                    confirmValue={this.handleNextRegistrationStep}
                                    infoNegozio={this.props.infoNegozio}
                                />
                            </div>
                        }

                        {
                            this.state.stepFormShowed.includes('contactsInformation') &&
                            <div
                                className={`registerStepContainer ${this.state.stepsClassNames[FORM_STEPS.indexOf('contactsInformation')]}`}>
                                <ContactsInformation
                                    userName={this.state.nome}
                                    infoNegozio={this.props.infoNegozio}
                                    confirmValue={this.handleNextRegistrationStep}
                                />
                            </div>
                        }

                        {
                            this.state.stepFormShowed.includes('passwordAndTermsAndConditions') &&
                            <div
                                className={`registerStepContainer ${this.state.stepsClassNames[FORM_STEPS.indexOf('passwordAndTermsAndConditions')]}`}>
                                <PasswordAndTermsAndConditions
                                    infoNegozio={this.props.infoNegozio}
                                    confirmValue={this.handleNextRegistrationStep}
                                />
                            </div>
                        }

                        {
                            this.state.stepFormShowed.includes('otpProvider') &&
                            <div
                                className={`registerStepContainer ${this.state.stepsClassNames[FORM_STEPS.indexOf('otpProvider')]}`}>
                                <OtpProviderChoose
                                    infoNegozio={this.props.infoNegozio}
                                    confirmValue={this.handleNextRegistrationStep}
                                />
                            </div>
                        }
                    </div>
                    {
                        this.state.isValidateOtpOpen ?
                            <ValidateOTPPage
                                onValidate={this.handleValidateOTP}
                                resendOTP={this.handleResendOTP}
                                onDismiss={this.handleCloseValidateOTP}
                                message={
                                    <span>{i18next.t(`register.confirm-account-otp-headline-${this.state.otp_provider}`)}{this.state.cellulare}</span>
                                }
                            /> : ''
                    }
                </form>
            </div>
        )
    }
}

RegisterForm.propType = {
    onDismiss: PropTypes.func,
    infoNegozio: PropTypes.object || null
}
