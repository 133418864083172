import React from "react";
import PropTypes from "prop-types";
import "./VimeoEmbed.css";

export const VimeoEmbed = (props) => (
    <iframe
        width="100%"
        height="100%"
        style={props.style}
        src={`https://player.vimeo.com/video/${props.embedId || vimeo_parser(props.url)}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded video"
    />
)
;

//Riconosce l'id da URL del tipo https://vimeo.com/670775679
function vimeo_parser(url) {
    return url.split("/").pop();
}

VimeoEmbed.propTypes = {
    embedId: PropTypes.string,
    url: PropTypes.string,
    style: PropTypes.object
};
