import React, {Fragment} from "react";
import {FaRegularIcon, FaSolidIcon} from "../../components/FontAwesomeIcons";
import Page from "../../components/BaseComponents/Page/Page";
import {swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import PagamentiDipendenteAPI from "../../api/Welfare/PagamentiDipendenteAPI";

export default class AttivitaWelfareTransactionConfirmPage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            paymentData: null,
            descrizione: ''
        }
    }

    componentDidMount() {
        PagamentiDipendenteAPI.getPaymentData(this.props.negozio.walletapikey, this.props.authCode)
            .then(paymentData => {
                this.setState({
                    paymentData: paymentData,
                    descrizione: "Da "+paymentData.dipendente_nome +" a "+this.props.negozio.nome});
            })
            .catch(swalError)
    }

    handlePaymentConfirmed = _ => {
        swalLoading();
        PagamentiDipendenteAPI.autorizzaTransazione(this.props.negozio.walletapikey, this.props.authCode, this.state.descrizione)
            .then((message) => {
                swalSuccess(message);
                this.props.onDismiss();
            })
            .catch(swalError);
    }


    render() {
        return (
            <Page
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
                content={
                    <div className={"container pt-4"}>
                        <h3 className={"mb-3"}>Conferma Pagamento</h3>
                        {
                            this.state.paymentData ?

                                <div className={"card shadow"}>
                                    <div className={"card-body"}>
                                        <div className={"row align-items-center"}>
                                            <div className={"col-2"}>
                                                <h4 className={'text-success text-lg'}>
                                                    <i className={"fas fa-arrow-circle-down"}></i>
                                                </h4>
                                            </div>
                                            <div className={"col-6"}>
                                                <b>{this.state.paymentData.dipendente_nome} {this.state.paymentData.dipendente_cognome}</b><br/>
                                                <span>{this.state.descrizione}</span><br/>
                                                <span className={"text-muted"}>{this.state.paymentData.wallet_descrizione}</span>
                                            </div>
                                            <div className={"col-4"}>
                                                <h6 className={'text-success'}>+ €{this.state.paymentData.importo}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={"container text-center lead mt-4"}>
                                    <i className={"fas fa-spinner fa-spin"}/>
                                </div>
                        }

                        <div className={"text-muted mt-2"}>
                            Una volta confermato il pagamento, avrà luogo la vera e propria transazione
                            verso il tuo wallet. Prima di confermare assicurati che tutti i dati siano corretti.
                        </div>

                        <hr/>

                        <div className={"row"}>
                            <div className={"col-6"}>
                                <h4><b>Importo:</b></h4>
                            </div>
                            <div className={"col-6 text-right"}>
                                {
                                    this.state.paymentData ?
                                        <h4><b>€{this.state.paymentData.importo}</b></h4>
                                        :
                                        <div className={"container text-center lead mt-4"}>
                                            <i className={"fas fa-spinner fa-spin"}/>
                                        </div>
                                }
                            </div>
                        </div>

                        {
                            this.state.paymentData &&
                            <button type={"button"} className={"btn btn-primary btn-lg btn-block mt-4"}
                                    onClick={this.handlePaymentConfirmed}>Conferma Pagamento
                            </button>
                        }


                    </div>
                }
            />
        )
    }

}
