import React from "react";
import PropTypes from "prop-types";
import {DirectPostContainer} from "./DirectPostContainer";
import Page from "../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../components/FontAwesomeIcons";

export class DirectPostPage extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <Page
                title={''}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={true}
            >
                <DirectPostContainer
                    id_post={this.props.id_post}
                    useDownloadBanner={false}
                    useMetaTags={false}
                    useActionBar={true}
                    onAppOpen={this.props.onAppOpen}
                    onAppAdd={this.props.onAppAdd}
                />
            </Page>
        )
    }

}

DirectPostContainer.propTypes = {
    id_post: PropTypes.any,
    onDismiss: PropTypes.func,
    onAppOpen: PropTypes.func,
    onAppAdd: PropTypes.func
}
