import styled from "styled-components";
import DOMPurify from "dompurify";
import React from "react";

const HTMLContainer = styled.span`
& p{
    margin-top:0px;
    margin-bottom:0px;
}
`;

const SafeHtmlContainer = props => <HTMLContainer className={props.className} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.html)}}/>;

export default SafeHtmlContainer;
