import React, {Fragment} from "react";
import moment from "moment";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import AppConfig from "../../../config/AppConfig";
import {BookizonAppManager} from "../../../index";
import APIConfig from "../../../api/APIConfig";
import AuthAPI from "../../../api/AuthAPI";

export default class DashboardUpaidInvoices extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"container-fluid"}>
                {
                    this.props.invoices.map(f => {
                        const invoiceUrl = `/admin/billing/invoices/info?invoice_id=${f.invoice_id}`;
                        return <div className={"border-top py-3 mt-3"} key={f.invoice_id}>
                            <div>
                                <b className={"text-danger"}>
                                    Fattura {f.number}
                                </b> del {moment(f.date).format('DD-MM-YYYY')}
                            </div>
                            <div>
                                <b>Totale: </b>€{parseFloat(f.total).toFixed(2)}
                            </div>

                            <button
                                className={"btn btn-danger btn-round btn-sm"}
                                onClick={_ => BookizonAppManager.openInAppBrowser(APIConfig.url(`/dashboard/autologin/${AuthAPI.currentUser.accessToken}?redirect=${btoa(invoiceUrl)}`))}
                            >
                                <FaSolidIcon name="external-link-alt"/> Paga fattura
                            </button>
                        </div>
                    })
                }
            </div>
        )
    }

}
