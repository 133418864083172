import React, {Fragment} from "react";
import AppConfig from "../../../../config/AppConfig";
import Page from "../../../../components/BaseComponents/Page/Page";
import styled from "styled-components";
import NavBarTop from "../../../../components/NavBarTop";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import FullCalendar from '@fullcalendar/react'
import timelinePlugin from '@fullcalendar/timeline'
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import itLocale from '@fullcalendar/core/locales/it';
import "../../dashboard.css";
import AuthAPI from "../../../../api/AuthAPI";
import {swal, swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import moment from "moment";
import "./CalendarioGruppi.css";
import GruppiAPI from "../../../../api/GruppiAPI";
import {StoriesLikeSelector} from "../../../../components/GenericUI/StoriesLikeSelector/StoriesLikeSelector";
import {getCompressedImageUrl} from "../../../../helpers/ImageUtility";
import HorizontalDatePicker from "../../../../components/HorizontalDatePicker/HorizontalDatePicker";
import DashboardDateBooksUnavailable from "./DashboardDateBooksUnavailable";
import {ModuloGruppiAPI} from "../../../../api/ModuloGruppi/ModuloGruppiAPI";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {DashboardGruppiBookDetails} from "./DashboardGruppiBookDetails";
import DashboardAddBookableGroupsBook from "./DashboardAddBook/DashboardAddBookableGroupsBook";

const CalendarWrapper = styled.div`
    background-color:#EBEBEB;
    
    & .selectedDate > div:last-of-type{
      background-color: #293069;
      border-radius: 50%;
      color:white;
      width:28px; height: 28px;
      text-align:center;
      line-height: 28px;
    }
    
    & .withBadge::before {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #293069;
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }
    
    & .today::after {
        content: 'OGGI';
        position: absolute;
        top: -8px;
        left: 10%;
        background-color: #e39813;
        width: 80%;
        /* height: 3px; */
        border-radius: 500px;
        color: white;
        font-size: 50%;
    }
`;


const DAYS_BEFORE_TODAY = 100;
const DAYS_AFTER_TODAY = 100;


const PRENOTAZIONE_GRUPPO_STATUS_ATTESA = 'prenotazione.in_attesa';
const PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA = 'prenotazione.confermata';
const PRENOTAZIONE_GRUPPO_STATUS_RIFIUTATA_ESERCENTE = 'prenotazione.rifiutata_esercente';

export default class CalendarioGruppi extends React.Component {

    calendarRef = React.createRef();

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            scrollTime: moment().format('HH:mm:ss'),
            events: [],
            fetchingEvents: false,
            businessHours: {},
            groups: [],
            groupSelected: '',
            dateBooksUnavailable: [],
            fetchingDateBooksUnavailable: false,
            selectedDate: new Date(),
            datesBookCount: null,
            dateIntervalStart: moment().subtract(DAYS_BEFORE_TODAY, 'days').format('YYYY-MM-DD'),
            dateIntervalEnd: moment().add(DAYS_AFTER_TODAY, 'days').format('YYYY-MM-DD'),

            //Utilizzati per pagina presa appuntamenti
            addBookPageOpen:false,

            //Utilizzati per la detailsPage
            bookDetailsOpen: false,
            idBookToOpen: null
        }

        this.todayMoment = moment();
    }

    componentDidMount() {
        this.fetchGroups();
    }

    //--- FETCHING dei Dati ---//
    fetchGroups = _ =>{
        ModuloGruppiAPI.Dashboard.getBookableGroups()
            .then((groups) => {
                this.setState({groups: groups, groupSelected: (groups.length > 0 ? groups[0].id_gruppo : '')})
                this.fetchDatesBooksCount()
            });
    }

    fetchEvents = (fetchInfo, successCallback, failureCallback) => {
        this.setState({fetchingEvents: true});

        var url = new URL(`${AppConfig.webServerUrl}/api/dashboard-gruppi/events/${AuthAPI.currentUser.accessToken}/${this.state.groupSelected}`);
        var params = {start: fetchInfo.startStr, end: fetchInfo.endStr}

        url.search = new URLSearchParams(params).toString();

        fetch(url)
            .then(response => response.json())
            .then(data => {
                successCallback(data);
                this.setState({
                    events: data,
                    fetchingEvents: false
                });
            })
            .catch(_ => {
                failureCallback();
            });
    }

    fetchDatesBooksCount = _ => {
        ModuloGruppiAPI.Dashboard.getDateListBooksCount(this.state.dateIntervalStart, this.state.dateIntervalEnd, this.state.groupSelected)
            .then(datesInfo => {
                this.setState({
                    datesBookCount: datesInfo
                });
            });
    }


    handleChangeGruppo = id_gruppo => {
        this.setState({groupSelected: id_gruppo},
            _ => {
            this.calendarRef.current.getApi().refetchEvents();
            this.fetchDatesBooksCount();
        });
    }

    handleDateChange = date => this.setState({selectedDate: date}, _=>{this.calendarRef.current.getApi().gotoDate(this.state.selectedDate)});

    getCalendarDateClass = dateYmd => {
        let baseClass = this.todayMoment.format('YYYY-MM-DD') === dateYmd ? 'today' : '';
        if (this.state.datesBookCount) {
            if (this.state.datesBookCount[dateYmd]) {
                baseClass += ' withBadge';
            }
        }
        return baseClass;
    }

    printTitlePrenotazioniInAttesa = _ => {
        for (var i = 0; i < this.state.events.length; i++){
            if(this.state.events[i].book.status === PRENOTAZIONE_GRUPPO_STATUS_ATTESA){
                return <div className={"container"}><h6>Prenotazioni in attesa:</h6></div>
            }
        }
    }

    handleCalendarUpdate = _ => this.calendarRef.current.getApi().refetchEvents();


    //--- Gestione calendar ---//

    handleEventClick = data => {
        if(data.jsEvent.target.classList.contains("eventiInAttesaEvent")){
            return "";
        }
        var bookInfo = data.event.extendedProps.book;
        this.setState({idBookToOpen: bookInfo.id_prenotazione}, _=>{
            this.toggleBookDetails();
        });
    }


    handleEventRender = info => {
        var bookInfo = info.event.extendedProps.book;
        console.log( info.el.innerHTML)
        if(bookInfo.data_prima_scansione_qr || bookInfo.data_entrata){
            //Setto content
            var content = info.el.querySelector(".fc-title-wrap");
            content.setAttribute("style", "position: relative; padding-left: 40px");
            //Setto div che conterrà l'icona
            var qr = document.createElement("div")
            qr.setAttribute("style", "height: 100%; width: 40px; position: absolute; left:0; text-align:center;")
            qr.innerHTML = "<i class='fas fa-check' style='font-size: 20px;'></i>"
            info.el.prepend(qr)
        }
        if(bookInfo.is_guest === "1"){
            info.el.innerHTML ="<div class='d-flex align-items-center flex-row'>" + info.el.innerHTML + "<i class='fas fa-mask'></i></div>"
        }
        if (bookInfo.status === PRENOTAZIONE_GRUPPO_STATUS_ATTESA) {

            info.el.innerHTML = "<div class='superimposed-event-in-attesa w-100'>" + info.el.innerHTML + "<br>" +
                "<button class='btn btn-sm btn-success mt-2 shadow-sm eventiInAttesaEvent' id='confermaPrenotazione' data-pk='" + bookInfo.id_prenotazione + "'>Conferma</button> " +
                "<button class='btn btn-sm btn-danger mt-2 shadow-sm eventiInAttesaEvent' id='rifiutaPrenotazione' data-pk='" + bookInfo.id_prenotazione + "'>Rifiuta</button> " + "</div>";

            info.el.querySelector("#confermaPrenotazione").addEventListener("click", this.handleConferma(bookInfo.id_prenotazione))
            info.el.querySelector("#rifiutaPrenotazione").addEventListener("click", this.handleRifiuta(bookInfo.id_prenotazione))

        }
        if (bookInfo.status === PRENOTAZIONE_GRUPPO_STATUS_RIFIUTATA_ESERCENTE) {
            info.el.innerHTML = "<div><div class='bg-danger w-auto p-1'>Rifiutata da te</div>" + info.el.innerHTML + "</div>";
        }

    }

    changeCalendarScrollTime = time => {
        time = time + ":00";
        this.calendarRef.current.getApi().scrollToTime(time)
    }


    //---- CONFERMA E RIFIUTA PRENOTAZIONI ----//
    handleConferma = id => _ => {
        swalConfirm("Sei sicuro di voler confermare questa prenotazione?")
            .then(_ => {
                swalLoading();
                ModuloGruppiAPI.Dashboard.conferma(id)
                    .then(message => {
                        swalSuccess(message)
                            .then(_=>this.handleCalendarUpdate())
                    })
                    .catch(swalError);
            });
    }

    handleRifiuta = id => _ => {
        swalConfirm("Sei sicuro di voler rifiutare questa prenotazione?")
            .then(_ => {
                swalLoading();
                ModuloGruppiAPI.Dashboard.rifiuta(id)
                    .then(message => {
                        swalSuccess(message).then(_=>this.handleCalendarUpdate())
                    })
                    .catch(swalError);
            });
    }

    bookFromAdminConfirmed =_=>{
        this.toggleAddBookPage()
        this.calendarRef.current.getApi().refetchEvents();
    }

    toggleBookDetails =_=>{this.setState({bookDetailsOpen: !this.state.bookDetailsOpen})}

    toggleAddBookPage =_=>{this.setState({addBookPageOpen: !this.state.addBookPageOpen})}

    render() {
        return (
            <Fragment>
                <Page
                    title={'Calendario'}
                    
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    rightIcon={<FaSolidIcon name={"plus"}/>}
                    onRightIconClick={this.toggleAddBookPage}
                    active={true}
                >
                    {
                        this.state.groups[0] ?
                        <div>
                            {/*Scegliere il servizio da visulaizzare*/}
                            <div className={"container my-3 __disable-swipe"}>
                                <StoriesLikeSelector
                                    items={
                                        this.state.groups.map(g => {
                                            return {
                                                value: g.id_gruppo,
                                                label: g.nome,
                                                imageUrl: g.immagine
                                            }
                                        })
                                    }
                                    circleSize={60}
                                    onChange={this.handleChangeGruppo}
                                    defaultValue={this.state.groupSelected}
                                />
                            </div>

                            {/*Mostra il servizio scelto*/}
                            <div className={"container my-3"}>
                                <h6>
                                    <span className={"text-muted"}>Hai scelto: </span>
                                    <span className={"text-primary"}>{this.state.groups.find(g => g.id_gruppo === this.state.groupSelected)?.nome}</span>
                                </h6>
                            </div>

                            {/*Calendario delle date*/}
                            <CalendarWrapper className={"__disable-swipe py-2"}>
                                <div className={"container"}>
                                    <HorizontalDatePicker
                                        getSelectedDay={this.handleDateChange}
                                        color={"#293069"}
                                        selectedClass={"selectedDate"}
                                        getDayClass={this.getCalendarDateClass}
                                        initialDaysBeforeToday={DAYS_BEFORE_TODAY}
                                        initialDaysAfterToday={DAYS_AFTER_TODAY}
                                    />
                                </div>
                            </CalendarWrapper>

                            {/*Print non prenotabile*/}
                            <DashboardDateBooksUnavailable
                                IdgruppoSelected = {this.state.groupSelected}
                                selectedDate = {this.state.selectedDate}
                                dateIntervalStart = {this.state.dateIntervalStart}
                                dateIntervalEnd = {this.state.dateIntervalEnd}
                            />

                            {/*Calendario giornata selezionata*/}
                            <div className={"container mt-3 pb-3 __disable-swipe"}>
                                <FullCalendar
                                    locale={itLocale}
                                    className={"bg-white"}
                                    defaultView="timeline"
                                    plugins={[timelinePlugin, interactionPlugin]}
                                    ref={this.calendarRef}
                                    header={{
                                        left: "title",
                                        right: ""
                                    }}
                                    height={"auto"}
                                    slotWidth={200}
                                    eventClick={this.handleEventClick}
                                    eventRender={this.handleEventRender}

                                    minTime={'00:00:00'}
                                    maxTime={'23:59:59'}
                                    scrollTime={this.state.scrollTime}

                                    allDaySlot={false}
                                    slotLabelFormat={{
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        omitZeroMinute: false
                                    }}

                                    slotDuration={`00:${('0' + this.props.impostazioni.slot_interval_gruppi).slice(-2)}:00`}
                                    slotLabelInterval={`00:${('0' + this.props.impostazioni.slot_interval_gruppi).slice(-2)}:00`}

                                    nowIndicator={true}
                                    events={this.fetchEvents}
                                />
                            </div>

                            {
                                !this.state.fetchingEvents &&
                                this.state.events.length === 0 &&
                                <div className={"container mt-3 pb-3 text-center"}>
                                    <span className={"text-muted"}>Non ci sono prenotazioni in questo giorno</span>
                                </div>
                            }

                            {/*Stampa titolo prenotazioni in attesa, se effettivamente ce ne sono*/}
                            {this.printTitlePrenotazioniInAttesa()}

                            {
                                !this.state.fetchingEvents &&
                                this.state.events.length > 0 &&
                                <div className={"container mt-3 pb-3"}>
                                    {
                                        this.state.events.map((e, i) => {
                                            if (e.book.status === PRENOTAZIONE_GRUPPO_STATUS_ATTESA) {
                                                var dataConvertita = moment(e.book.data).format('ddd DD MMMM')
                                                var color = e.className.split(" ").pop()

                                                return (
                                                    <div key={i} className={"bg-white mb-1 p-2 rounded shadow-sm d-flex align-items-center justify-content-between"}
                                                         style={{borderLeft: "4px solid " + color + ""}}>
                                                        <div>
                                                            <div className={"badge badge-warning"}>In attesa</div>
                                                            <div>{e.book.nome_gruppo}-{e.book.nome_utente}</div>
                                                            <div className={"small text-muted"}>{dataConvertita} dalle {e.book.orario_inizio} alle {e.book.orario_fine}</div>
                                                        </div>
                                                        <div>
                                                            <button className={"btn btn-sm btn-link"} onClick={_ => this.changeCalendarScrollTime(e.book.orario_inizio)}>
                                                                <FaSolidIcon name={"clock"}/> Vai quì
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            }
                        </div>
                            :
                        <div className={"p-2 text-muted text-center"}>"Non sono stati identificati servizi del tipo gruppi, per iniziare ad utilizzare la tua dashboard creane almeno uno"</div>
                    }
                </Page>

                {
                    this.state.addBookPageOpen && this.state.groups.length > 0 &&
                    <DashboardAddBookableGroupsBook
                        onDismiss = {this.toggleAddBookPage}
                        groups = {this.state.groups}
                        id_group_selected={this.state.groupSelected}
                        bookConfirmed = {this.bookFromAdminConfirmed}
                    />
                }

                <ModalPortalHOC>
                    {
                        this.state.bookDetailsOpen &&
                        <DashboardGruppiBookDetails
                            onDismiss={this.toggleBookDetails}
                            onUpdate={this.handleCalendarUpdate}
                            id_prenotazione={this.state.idBookToOpen}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        );
    }


}
