import React from "react";
import styled from "styled-components";
import {Fragment} from "react";
import {SwipeablePanel} from "../../../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {ALIQUOTE_IVA, METODI_PAGAMENTO} from "../../ModuloCassaContants";
import {getScontoAPagare, getTotaleItems, getTotaleIva, getTotaleScontrino} from "../../ReceiptsUtils";
import PropTypes from "prop-types";
import {
    PAYMENT_METHOD_ASSEGNO,
    PAYMENT_METHOD_BANCOMAT, PAYMENT_METHOD_BONIFICO,
    PAYMENT_METHOD_CARTA,
    PAYMENT_METHOD_CONTANTI, PAYMENT_METHOD_PAYPAL
} from "../../../../../../const/PaymentMethodoCostants";


const InfoBackdrop = styled.div`
        position:fixed;
        top:0; left:0; height:100vh; width:100vh;
        z-index:2000; background-color:rgba(0,0,0,0.7);
`;

const InfoWrapper = styled.div`
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
    min-height:80vh;
    max-height:80vh;
    width:100%;
    scrollbar-width: none;
    overflow-y:auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
        display:none;
    }
`;

export default class KeypadInterfaceConfirmPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.ref = null;
        this.hiding = false;

        this.hasRemovedScrollToBody = false;
        this.lastDocumentScrollPos = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.expanded && this.props.expanded) {
            this.handleRemoveBodyScroll();
        }
        if (prevProps.expanded && !this.props.expanded) {
            this.handleRestoreBodyScroll();
        }
    }

    /**
     * @FUNZIONI-PANEL
     */

    handleRemoveBodyScroll = _ => {
        this.hasRemovedScrollToBody = document.body.className.indexOf("noscroll") === -1;
        if (this.hasRemovedScrollToBody) {
            this.lastDocumentScrollPos = document.documentElement.scrollTop;
            document.documentElement.scrollTop = 0;
            //console.log("Page add noscroll", this.props.title);
        }
        document.body.classList.add('noscroll');
    }

    handleRestoreBodyScroll = _ => {
        //Rimuovo il "noscroll" solo se sono stato io ad aggiungerlo
        if (this.hasRemovedScrollToBody) {
            document.body.classList.remove('noscroll');
            if (this.lastDocumentScrollPos !== null) {
                document.documentElement.scrollTop = this.lastDocumentScrollPos;
                this.lastDocumentScrollPos = null;
            }
            //console.log("Page remove noscroll", this.props.title);
        }
    }

    getWrapperRef = node => {
        this.ref = node;
    }


    handleSubmit = _ =>{
        this.props.onToggle()
        this.props.submitReceipt()
    }

    render() {
        return (
            <Fragment>
                {this.props.expanded && <InfoBackdrop className={"__androidDismissable__"} onClick={this.props.onToggle}/>}

                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={!!this.props.expanded}
                    onDismiss={this.props.onToggle}
                    disableSwipe={_ => !this.ref || this.ref.scrollTop > 0}
                    animationSpeed={150}
                >
                    <InfoWrapper
                        ref={this.getWrapperRef}
                        className={"bg-main-z2"}
                    >
                        <div className={"p-3"}>

                            <h4>Conferma lo scontrino</h4>

                            {
                                this.props.metodi_pagamento.map((mp, i) => {
                                    return <div key={i} className={"mb-2 row"}>
                                        <div className={"col-6"}>
                                            <select className={"form-control"}
                                                    name={"metodo_pagamento"} data-idx={i}
                                                    onChange={this.props.changeMetodoPagamento}
                                                    value={mp.metodo_pagamento}>
                                                <option value={PAYMENT_METHOD_CONTANTI}>Contanti</option>
                                                <option value={PAYMENT_METHOD_CARTA}>Carta di credito</option>
                                                <option value={PAYMENT_METHOD_BANCOMAT}>Bancomat</option>
                                                <option value={PAYMENT_METHOD_BONIFICO}>Bonifico</option>
                                                <option value={PAYMENT_METHOD_ASSEGNO}>Assegno</option>
                                                <option value={PAYMENT_METHOD_PAYPAL}>Pay Pal</option>
                                            </select>
                                        </div>
                                        <div className={"col-4"}>
                                            <input
                                                type={"number"}
                                                name={"importo"}
                                                placeholder={"importo"}
                                                value={mp.importo}
                                                className={"form-control mr-2"}
                                                step={"0.01"}
                                                data-idx={i}
                                                onChange={this.props.changeMetodoPagamento}
                                            />
                                        </div>
                                        <div className={"col-1"}>
                                            <button
                                                className={"btn btn-link text-danger " + (i ? "" : "disabled")}
                                                onClick={_ => this.props.deleteMetodoPagamento(i)}>
                                                <FaSolidIcon name={"trash"}/>
                                            </button>
                                        </div>
                                    </div>
                                })
                            }

                            {
                                this.props.metodi_pagamento.length < 2 &&
                                <div className={"w-100 text-center"}>
                                    <button type={"button"} className={"btn btn-link"}
                                            onClick={_ => this.props.addMetodoPagamento()}>
                                        + Aggiungi metodo
                                    </button>
                                </div>
                            }

                            {/*Riepilogo scontrino*/}
                            <br/>
                            <span className={"mt-2"}>Riepilogo</span>

                            <div className={"mb-3"}>
                                <div className={"input-group"}>
                                    <div className={"input-group-prepend"}>
                                                        <span className={"input-group-text align-items-center"}>
                                                            <i className="fas fa-euro-sign"/> <small
                                                            className={"ml-1"}>(sconto)</small>
                                                        </span>
                                    </div>
                                    <input
                                        type={"number"}
                                        name={"sconto_documento"}
                                        placeholder={"sconto sul totale"}
                                        value={this.props.sconto_documento}
                                        className={"form-control"}
                                        step={"0.01"}
                                        onChange={this.props.changeScontoDocumento}
                                    />
                                </div>
                                {this.props.errorsScontoDocumento()}
                            </div>

                            <div className={"d-flex justify-content-between my-1"}>
                                <span>Totale imponibile</span>
                                <span>€{(getTotaleItems(this.props.items) - getTotaleIva(this.props.items)).toFixed(2)}</span>
                            </div>
                            <hr className={"m-0"}/>
                            <div className={"d-flex justify-content-between my-1"}>
                                <span>IVA versata</span>
                                <span>€{getTotaleIva(this.props.items).toFixed(2)}</span>
                            </div>
                            <hr className={"m-0"}/>
                            <div className={"d-flex justify-content-between my-1"}>
                                <span>Subtotale prodotti</span>
                                <span>€{getTotaleItems(this.props.items, false).toFixed(2)}</span>
                            </div>
                            {
                                this.props.sconto_documento &&
                                <div>
                                    <hr className={"m-0"}/>
                                    <div className={"d-flex justify-content-between my-1"}>
                                        <span>Sconto</span>
                                        <span>-€{parseFloat(this.props.sconto_documento || "0").toFixed(2)}</span>
                                    </div>
                                </div>
                            }
                            {
                                getScontoAPagare(this.props.metodi_pagamento, this.props.items) !== 0 &&
                                <div>
                                    <hr className={"m-0"}/>
                                    <div className={"d-flex justify-content-between my-1"}>
                                        <span>Sconto a pagare</span>
                                        <span>€{getScontoAPagare(this.props.metodi_pagamento, this.props.items)}</span>
                                    </div>
                                </div>
                            }

                            <hr className={"m-0"}/>
                            <div className={"d-flex justify-content-between my-2"}>
                                <h5>Totale scontrino</h5>
                                <h5>€{(getTotaleScontrino(this.props.metodi_pagamento, this.props.items)).toFixed(2)}</h5>
                            </div>

                            <div className={"w-100 mt-3"}>
                                <button className={"btn btn-block btn-primary"} onClick={this.handleSubmit}>Salva e trasmetti</button>
                            </div>
                        </div>
                    </InfoWrapper>
                </SwipeablePanel>
            </Fragment>
        )

    }
}

KeypadInterfaceConfirmPanel.propTypes = {
    useNavbar: PropTypes.bool,
    expanded: PropTypes.bool,
    onToggle: PropTypes.func,
    //Items
    items: PropTypes.object,
    //Metodi pagamento
    metodi_pagamento: PropTypes.object,
    changeMetodoPagamento: PropTypes.func,
    addMetodoPagamento: PropTypes.func,
    deleteMetodoPagamento: PropTypes.func,
    //Sconti
    errorsScontoDocumento: PropTypes.func,
    sconto_documento: PropTypes.number,
    changeScontoDocumento: PropTypes.func,
    //Submit
    submitReceipt: PropTypes.func
}