import {SMART_PACKAGES_MODULES_TYPE} from "../Constants";
import {MODULE_GRUPPI} from "../../../../const/ModuliConstants";
import {ModuleGruppiSelectItemsUtils} from "./Modules/ModuleGruppiSelectItemsUtils";

export const SelectItemsUtils = {
    getItems: function(item, smartPackage, payableBy, module) {
        switch (module){
            case MODULE_GRUPPI:
                return ModuleGruppiSelectItemsUtils.getItems(item, smartPackage, payableBy);
            default:
                return [{...item, id_wallet: smartPackage.id_wallet, id_payable: payableBy.id_payable, crediti: 1}]
        }
    },

    removeItems: function(item, smartPackage, selectedItems, module){
        let cleanedItems = selectedItems
        //Switch predisposto ma nessun modulo implementato necessita implementazione specifica
        switch (module){
            default:
                cleanedItems = cleanedItems.filter((selItem) => selItem.id_item !== item.id_item)
                return cleanedItems
        }
    },

    getDiscountFromPackage: function(selectedItems, smartPackage, module){
        switch (module){
            default: return selectedItems.reduce((partialSum, i) => partialSum + i.prezzo, 0)
        }
    }
}