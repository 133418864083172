import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import SafeHtmlContainer from "../../../../../helpers/SafeHtmlContainer";
import styled from "styled-components";
import {swalSelect} from "../../../../../helpers/SweetAlertWrapper";

const Modifier = styled.div`
    display:inline;
    & + &:before{
        content:", ";
        display:inline;
    }
`;


export function OrdineAdminInfoProduct(props) {
    const p = props.product;
    const STATO_PRODOTTO_CONFERMATO = 'confermato';
    const STATO_PRODOTTO_IN_LAVORAZIONE = 'in_lavorazione';
    const STATO_PRODOTTO_SPEDITO = 'spedito';
    const STATO_PRODOTTO_COMPLETATO = 'completato';

    const getProductStatusLabel = _ => {
        switch (p.stato_prodotto) {
            case STATO_PRODOTTO_COMPLETATO:
                return <span className={"badge badge-success"}>Completato</span>;
            case STATO_PRODOTTO_CONFERMATO:
                return <span className={"badge badge-success"}>Confermato</span>;
            case STATO_PRODOTTO_SPEDITO:
                return <span className={"badge badge-info"}>Spedito</span>;
            case STATO_PRODOTTO_IN_LAVORAZIONE:
                return <span className={"badge badge-info"}>In lavorazione</span>;
        }
    }

    const handleEditStatus = _ => {
        swalSelect("Seleziona il nuovo stato", {
            [STATO_PRODOTTO_CONFERMATO]: 'Confermato',
            [STATO_PRODOTTO_IN_LAVORAZIONE]: 'In lavorazione',
            [STATO_PRODOTTO_SPEDITO]: 'Spedito',
            [STATO_PRODOTTO_COMPLETATO]: 'Completato'
        }, "Procedi", "Annulla", '', p.stato_prodotto)
            .then(newStatus => {
                props.onEditStatus(p.id_prodotto, p.aggiunte_hash, newStatus);
            })
    }

    return (
        <React.Fragment>
            <div className={"card my-2"}>
                <div className={"card-body"}>
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <div>
                            {
                                (props.showStatus && !!p.stato_prodotto) && <div>
                                    {getProductStatusLabel()}
                                </div>
                            }
                            <b>{p.quantita}x</b> {p.nome_prodotto}<br/>
                            <div className={"text-muted small"}>
                                {p.categoria_prodotto}
                            </div>
                        </div>
                        <button className={"btn btn-link btn-sm"} onClick={handleEditStatus}>
                            Modifica stato
                        </button>
                    </div>
                    {
                        p.aggiunte.map((a, i) => {
                            return (
                                <Modifier key={i}>
                                    {a.type === 1 && <FaSolidIcon name={"plus-circle"}
                                                                  className={"text-success"}/>}
                                    {a.type === 0 && <FaSolidIcon name={"minus-circle"}
                                                                  className={"text-danger"}/>}
                                    {a.type === 2 && <FaSolidIcon name={"info-circle"}
                                                                  className={"text-info"}/>}
                                    {a.text}
                                </Modifier>
                            )
                        })
                    }
                    {
                        !!p.note &&
                        <Fragment>
                            <b>Note:</b> <SafeHtmlContainer html={p.note || 'Nessuna'}/>
                        </Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

OrdineAdminInfoProduct.propTypes = {
    product: PropTypes.object,
    onEditStatus: PropTypes.func,
    showStatus: PropTypes.bool,
}