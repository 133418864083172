import React from "react";

const GripStyle = {
    height:5,
    width:50,
    background: "#aaa",
    borderRadius: 500
}

export const SwipeablePanelGripBar = props => {
    return (
        <div className={props.className + " p-3"}>
            <div style={GripStyle} className={"mx-auto"}/>
        </div>
    )
}
