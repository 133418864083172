import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {OspiteView} from "./OspiteView";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import ModalPortalHOC from "../../../../../components/BaseComponents/ModalPortalHOC";
import {GuestCRUDModal} from "./GuestModal/GuestCRUDModal";

export class OspitiListView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ospiteEditData: null
        }
    }

    handleOspiteEdit = ospiteData => {
        this.setState({ospiteEditData: ospiteData});
    }

    handleOspiteEditClose = _ => this.setState({ospiteEditData: null})

    render() {
        return (
            <Fragment>
                <h5 className={"text-muted"}>Ospiti già inseriti</h5>
                {
                    !this.props.ospiti.length &&
                    <p className={"text-muted"}>
                        Non hai ancora inserito un ospite per questa prenotazione.
                    </p>
                }
                {
                    this.props.ospiti.map(o => {
                        return <OspiteView key={o.id_ospite} ospiteData={o} onClick={this.handleOspiteEdit}/>
                    })
                }
                <ModalPortalHOC>
                    {
                        !!this.state.ospiteEditData &&
                        <GuestCRUDModal
                            idPrenotazione={this.props.idPrenotazione}
                            ospiteData={this.state.ospiteEditData}
                            onDismiss={this.handleOspiteEditClose}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

OspitiListView.propTypes = {
    ospiti: PropTypes.array.isRequired,
    maxOspiti: PropTypes.number.isRequired,
    idPrenotazione: PropTypes.string.isRequired
}