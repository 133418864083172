import React from "react";
import PropTypes from "prop-types";
import AppModalPortal from "../../BaseComponents/AppModalPortal";
import ModalPortal from "../../BaseComponents/ModalPortal";
import ProfiloAttivitaPanel from "../../ProfiloAttivita/ProfiloAttivitaPanel";
import NegozioAPI from "../../../api/NegozioAPI";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {ADD_APP_REQUEST, APP_FAVOURITE_ADDED, OPEN_APP_REQUEST} from "../../../const/AppEventsCostants";
import {swal, swalLoading} from "../../../helpers/SweetAlertWrapper";

export class EcommerceProductViewProfiloAttivita extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoNegozio: null
        }
    }

    componentDidMount() {
        swalLoading('Recupero informazioni attività...')
        NegozioAPI.getInfo(this.props.idNegozio)
            .then(infoNegozio => {
                this.setState({infoNegozio: infoNegozio});
                swal.safeClose();
            });
        FuxEvents.on(APP_FAVOURITE_ADDED, this.handleExternalAppAdded);
    }

    componentWillUnmount() {
        FuxEvents.off(APP_FAVOURITE_ADDED, this.handleExternalAppAdded);
    }

    handleExternalAppAdded = id_negozio => {
        if (this.state.infoNegozio && this.state.infoNegozio.id_negozio === id_negozio) {
            const infoNegozio = {...this.state.infoNegozio, wasAdded: true};
            this.setState({infoNegozio: infoNegozio});
        }
    }

    handleAppOpen = _ => {
        FuxEvents.emit(OPEN_APP_REQUEST, this.props.idNegozio);
        this.props.onDismiss(); //Chiudo il pannello
    }

    handleAppAdd = _ => {
        FuxEvents.emit(ADD_APP_REQUEST, this.state.infoNegozio.codice);
    }

    render() {
        if (!this.state.infoNegozio) return '';

        const Portal = document.getElementById('app-modal-root') ? AppModalPortal : ModalPortal;

        return (
            <Portal>
                <ProfiloAttivitaPanel
                    negozio={this.state.infoNegozio}
                    primaryColor={"#293069"}
                    backgroundColor={"#ffffff"}
                    onAppAdd={this.handleAppAdd}
                    onAppOpen={this.handleAppOpen}
                    isAppAdded={!!this.state.infoNegozio.wasAdded}
                    expanded={true}
                    useNavbar={false}
                    onToggle={this.props.onDismiss}
                />
            </Portal>
        )

    }

}


EcommerceProductViewProfiloAttivita.propTypes = {
    idNegozio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onDismiss: PropTypes.func
}
