import React from "react";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {getCompressedImageUrl} from "../../../../helpers/ImageUtility";
import {ProductImageSize} from "../../../../const/ImageSizeCostants";
import {SeatsMapType} from "../../../../components/ModuloSeats/PropTypes/MapType";

export class MapView extends React.Component {
    constructor(props) {
        super(props);
        this.imageStyle = {
            width: '100%',
            paddingTop: '56.25%',
            backgroundImage: `url('${getCompressedImageUrl(this.props.map.cover_image, ProductImageSize.preview.w, ProductImageSize.preview.h)}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
        }
        this.ratingStarStyle = {
            fontSize: '80%'
        }
        this.textBackdropStyle = {
            position: 'absolute',
            zIndex: 0,
            width: '100%',
            height: 50,
            background: "linear-gradient(180deg, rgba(30,30,30,0.65) 50%, transparent 100%)"
        }
        this.cardStyle = {
            border: 0,
            borderRadius: '.75rem',
            overflow: 'hidden'
        }
    }

    getFullAddress = _ => {
        return `${this.props.map.address}, ${this.props.map.city} ${this.props.map.postal_code} (${this.props.map.state}), ${this.props.map.country} `
    }

    getMapsLink() {
        var mapLink = 'https://maps.google.com/maps?q=';
        if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
            mapLink = 'http://maps.apple.com/?q=';
        }
        return `${mapLink}${this.getFullAddress()}`;
    }

    render() {
        return (
            <div className={"card mb-3 shadow-sm"} style={this.cardStyle} onClick={this.handleClick}>
                <div style={this.imageStyle} className={"card-img embed-responsive-16by9"}/>
                <div style={this.textBackdropStyle}/>
                <div className={"card-img-overlay p-2"}>
                    <h6 className={"card-title text-white mb-0"}>
                        {this.props.map.name}
                    </h6>
                </div>
                <div className={"card-footer bg-main-z2 position-relative"}>
                    <FaSolidIcon name={"map-marker-alt"}/> <SafeHtmlContainer html={this.getFullAddress()}/><br/>
                    <div className={"mt-2 text-right"}>
                        <a href={this.getMapsLink()}
                           target={'_blank'}
                           className={"btn btn-primary btn-sm"}>
                            <FaSolidIcon name={"map-marker-alt"}/> Portami qui
                        </a>
                    </div>
                </div>
            </div>
        )
    }

}

MapView.propTypes = {
    map: SeatsMapType
}