import React from "react";
import PropTypes from "prop-types";
import {UserBookInfoType} from "../../../../../components/ModuloSeats/PropTypes/UserBookInfoType";
import {SeatView} from "./SeatView";
import {DateAccordionItem} from "./DateAccordionItem";

export function DateAccordion({bookData, onUpdate}) {
    return (
        <React.Fragment>
            <div className={"list-group list-group-flush"}>
                {
                    Object.keys(bookData.dates).map(dateYmd => {
                        return <div className={"list-group-item"} key={dateYmd}>
                            <DateAccordionItem
                                date={dateYmd}
                                linkedBooks={bookData.dates[dateYmd]}
                                onlinePayments={bookData.online_payments}
                                onUpdate={onUpdate}
                                disputeList={bookData.disputes}
                            />
                        </div>
                    })
                }
            </div>
        </React.Fragment>
    )
}

DateAccordion.propTypes = {
    bookData: UserBookInfoType,
    onUpdate: PropTypes.func
}