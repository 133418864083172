import React, {Fragment} from "react";
import IndirizziAPI, {INDIRIZZI_TYPE_NORMAL} from "../../../../api/IndirizziAPI";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import AddressBook from "../../../../components/AddressBook";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {MARKETPLACE_SHIPPING_ADDRESS_SELECTED} from "../../../../const/AppEventsCostants";
import ModalPortal from "../../../../components/BaseComponents/ModalPortal";
import AddAddressModal from "../../../../components/Modals/AddAddressModal";
import {PageZLayerSelector} from "../../../../components/BaseComponents/Page/PageZLayerSelector";
import i18next from "i18next";


export class MarketplaceCartShippingBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addressList: null,
            selected: null,
            addressChooserOpen: false,
            createAddressOpen: false
        }
    }

    componentDidMount() {
        IndirizziAPI.list(INDIRIZZI_TYPE_NORMAL)
            .then(list => {
                const predefined = list.find(i => parseInt(i.predefinito) === 1);
                this.setState({
                    addressList: list,
                    selected: predefined ? predefined.id_indirizzo : null
                });
                if (predefined) {
                    this.handleShippingAddressChoose({id_indirizzo: predefined.id_indirizzo, data: predefined});
                }
            });
    }

    getSelected = _ => {
        return this.state.addressList.find(i => i.id_indirizzo === this.state.selected);
    }

    toggleAddressCreate = _ => this.setState({createAddressOpen: !this.state.createAddressOpen});

    toggleAddressChooser = _ => this.setState({addressChooserOpen: !this.state.addressChooserOpen})

    handleShippingAddressChoose = ({id_indirizzo, data}) => {
        const exists = !!this.state.addressList.find(i => i.id_indirizzo === id_indirizzo);
        const addressList = exists ? [...this.state.addressList] : [...this.state.addressList, data];
        this.setState({
            addressList: addressList,
            selected: id_indirizzo,
            addressChooserOpen: false
        });
        FuxEvents.emit(MARKETPLACE_SHIPPING_ADDRESS_SELECTED, data);
    }

    render() {

        if (!this.state.addressList) {
            return (
                <div className={"bg-white p-3 shadow-sm text-center"}>
                    <EllipsisLoader/>
                </div>
            )
        }

        const selected = this.getSelected();
        return (
            <Fragment>
                <div className={"bg-white p-3 shadow-sm"}>
                    {
                        !!selected &&
                        <div className={"d-flex align-items-center justify-content-between"}
                             onClick={this.toggleAddressChooser}>
                            <div className={"text-truncate mr-1"}>
                                <FaSolidIcon name={"map-marker-alt"}/> {i18next.t('marketplace.cart.shipping-to')}
                                <b>{selected.nome} {selected.cognome}</b> {selected.indirizzo}
                            </div>
                            <FaSolidIcon name={"chevron-right"}/>
                        </div>
                    }
                    {
                        !selected &&
                        <div className={"text-center"}>
                            <button className={"btn btn-link text-primary"} onClick={this.toggleAddressChooser}>
                                <FaSolidIcon name={"map-marker-alt"}/> {i18next.t('marketplace.cart.select-shipping-address-btn')}
                            </button>
                        </div>
                    }
                </div>

                <ModalPortal>
                    <PageZLayerSelector zIndex={2001}>
                        {
                            this.state.addressChooserOpen && <AddressBook
                                active={true}
                                title={i18next.t('marketplace.cart.shipping-title')}
                                subtitle={i18next.t('marketplace.cart.select-shipping-address-btn')}
                                onChoose={this.handleShippingAddressChoose}
                                onDismiss={this.toggleAddressChooser}
                                useFlag={false}
                                onCreateNew={this.toggleAddressCreate}
                            />
                        }
                        {
                            this.state.createAddressOpen &&
                            <AddAddressModal
                                invoiceData={false}
                                onDismiss={this.toggleAddressCreate}
                            />
                        }
                    </PageZLayerSelector>
                </ModalPortal>
            </Fragment>
        )
    }

}
