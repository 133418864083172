import React, {Fragment} from 'react';
import {FaSolidIcon} from "../FontAwesomeIcons";
import {FullscreenInput} from "../Forms/FullscreenInputs";
import CouponAPI from "../../api/CouponAPI";
import PropTypes from "prop-types";
import WalletsList from "./Wallets/WalletsList";
import i18n from "i18next";
import {COUPON_TYPE_GRUPPI, COUPON_TYPE_SERVIZI, MAPPING_MODULE_COUPON_TYPE} from "../../const/CouponConstants";
import {Trans} from "react-i18next";
import {WalletItemType} from "./Types/WalletItemType";
import SmartPackageList from "./SmartPackage/SmartPackageList";
import {MODULE_BY_CATEGORY_MAP} from "../../const/ModuliConstants";
import {SelectItemsUtils} from "./SmartPackage/SelectItemsUtils/SelectItemsUtils";


export default class CheckoutDiscount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codice_coupon: this.props.externalCode || '',
            dati_coupon: [],
            error_coupon: '',
            applying: false,
            discount_complete: []
        }
    }

    componentDidMount() {
        if (this.props.externalCode) this.setState({applying: true}, this.handleVerifyCodice)
    }

    handleVerifyCodice = _ => {
        CouponAPI.getValiditaCouponPerUtente(this.state.codice_coupon, this.props.type, this.props.discountableAmount, this.props.usageDate)
            .then((data) => {
                this.setState({dati_coupon: data.datiCoupon, error_coupon: '', applying: false}, _ => {
                    let discount = this.state.discount_complete
                    //Se lo sconto è utilizzabile sull'importo passato allora setto i dati, altrimenti l'errore
                    discount.coupon = this.canUseCouponBasedOnDiscountableAmount() ? this.state.dati_coupon : new Error(i18n.t('checkout-discount.errors.amount-not-enough'));
                    this.setState({discount_complete: discount}, this.props.discountValidityHandler(this.state.discount_complete))
                });
            })
            .catch((message) => {
                this.setState({dati_coupon: [], error_coupon: message, applying: false}, _ => {
                    let discount = this.state.discount_complete
                    //Se lo sconto è utilizzabile sull'importo passato allora setto i dati, altrimenti l'errore
                    discount.coupon = new Error(this.state.error_coupon)
                    this.setState({discount_complete: discount}, this.props.discountValidityHandler(this.state.discount_complete))
                })
            })
    }

    //Verifico che il coupon sia utilizzabile in base all'importo da scontare
    canUseCouponBasedOnDiscountableAmount = _ => {
        if (this.props.discountableAmount < this.state.dati_coupon.min_spesa) {
            return false
        }
        //Se lo sconto è euro il valore dello sconto non può essere maggiore dell'importo speso
        return !(this.state.dati_coupon.tipo_sconto === "euro" && (this.props.discountableAmount - this.state.dati_coupon.valore_sconto) < 0);

    }


    handleClearDatiCoupon = _ => {
        this.setState({dati_coupon: []});
    }

    //Restituice le informazioni di validità o no, del coupon che viene inserito
    printInfoAboutCouponInsert = _ => {
        //Caso in cui non ho alcuna informazione
        if (!this.state.dati_coupon.id_coupon && this.state.error_coupon === '') {
            return ''
        }
        //Caso in cui ho ricevuto messaggio di errore
        if (this.state.error_coupon !== '') {
            return <span className={'small text-danger'}>{this.state.error_coupon}</span>;
        }
        //Caso in cui arriva risposta OK, ma i dati sono vuoti
        if (!this.state.dati_coupon.id_coupon) {
            return <span className={'small text-danger'}>{i18n.t('checkout-discount.errors.coupon-not-found')}</span>
        }
        //Controllo che il minimo della spesa sia stato raggiunto
        if (this.props.discountableAmount < this.state.dati_coupon.min_spesa) {
            return <span
                className={'small text-warning'}>{i18n.t('checkout-discount.errors.amount-not-enough-template', {amount: this.state.dati_coupon.min_spesa})}</span>;
        }
        //Gestione validità coupon
        if (this.state.dati_coupon.tipo_sconto === "euro") {
            if ((this.props.discountableAmount - this.state.dati_coupon.valore_sconto) >= 0) {
                return <span
                    className={'small text-success'}>{i18n.t('checkout-discount.status.fixed-discount-template', {amount: this.state.dati_coupon.valore_sconto})}</span>;
            } else {
                return <span
                    className={'small text-warning'}>{i18n.t('checkout-discount.errors.discount-bigger-than-spending')}</span>;
            }
        }
        return <span
            className={'small text-success'}>{i18n.t('checkout-discount.status.perc-discount-template', {perc: this.state.dati_coupon.valore_sconto})}</span>;
    }


    handleInputChange = ({target}) => {
        clearTimeout(this.typingTimer);
        this.setState({
            applying: true,
            [target.name]: target.value,
        }, _ => {
            //Se il valore è realmente settato applico il codice, altrimenti no
            if (target.value) {
                this.typingTimer = setTimeout(_ => {
                    this.handleVerifyCodice()
                }, 1000);
            } else {
                let discount = this.state.discount_complete
                //Se il coupon è settato vuoto lo imposto a null
                discount.coupon = null
                this.setState({
                    applying: null,
                    discount_complete: discount
                }, this.props.discountValidityHandler(this.state.discount_complete))
            }
        });
    }


    /**
     * @Wallets
     */
    discountFromWalletsHandler = wallets_list => {
        let discount = this.state.discount_complete
        discount.wallets = {}
        discount.wallets.wallets_list = wallets_list

        //Calcolo il totale degli importi utilizzati dai wallet
        discount.wallets.importo_usato_totale = wallets_list.reduce((partialSum, w) => partialSum + w.importo_usato, 0)
        if (discount.wallets.importo_usato_totale > this.props.amountPayableWithWallet) {
            discount.wallets = new Error(i18n.t('checkout-discount.wallets.errors.amount-bigger-than', {amount: this.props.amountPayableWithWallet.toFixed(2)}))
        }

        this.setState({discount_complete: discount}, this.props.discountValidityHandler(this.state.discount_complete))
    }

    /**
     * @Packages
     */
    discountFromPackageHandler = (selectedItems, smartPackage) => {
        let discount = this.state.discount_complete
        discount.packages = {}
        discount.packages.selected_items = selectedItems

        //Calcolo il totale degli importi utilizzati dai pacchetti
        //NB viene considerato il prezzo intero dell item che CONTENTE sconti ESCLUSI eventuali l'acconto
        discount.packages.importo_usato_totale =
            SelectItemsUtils.getDiscountFromPackage(selectedItems, smartPackage, MAPPING_MODULE_COUPON_TYPE[this.props.type])

        if (discount.packages.importo_usato_totale > 0) {
            discount.wallets = {}
        }

        console.log(discount, "QUI LO SCONTO")

        this.setState({discount_complete: discount}, _=>{
            this.props.discountValidityHandler(this.state.discount_complete)
        })
    }


    render() {
        return (
            <Fragment>
                <div className={"form-group"}>

                    {
                        (
                            MAPPING_MODULE_COUPON_TYPE[this.props.type] === MAPPING_MODULE_COUPON_TYPE[COUPON_TYPE_SERVIZI] ||
                            MAPPING_MODULE_COUPON_TYPE[this.props.type] === MAPPING_MODULE_COUPON_TYPE[COUPON_TYPE_GRUPPI]
                        ) &&
                        <SmartPackageList
                            module={MAPPING_MODULE_COUPON_TYPE[this.props.type]}
                            items={this.props.items}
                            operationDate={this.props.operationDate}
                            discountFromPackage={this.discountFromPackageHandler}
                        />
                    }

                    {
                        this.props.externalCode ?
                            <div className={"card border-0 shadow-sm"}>
                                {
                                    this.state.dati_coupon.id_coupon && this.canUseCouponBasedOnDiscountableAmount() ?
                                        <div className={"p-3 bg-soft-success"}>
                                            <span>
                                                <Trans
                                                    i18nKey={"checkout-discount.status.perc-discount-applied-template"}
                                                    values={{discount: this.state.dati_coupon.valore_sconto}}>
                                                    Il tuo <b>sconto</b> del <b>{this.state.dati_coupon.valore_sconto}%</b> è stato applicato!
                                                </Trans> &#x1F389;
                                            </span>
                                        </div>
                                        :
                                        <div className={"p-3"}>
                                            {this.printInfoAboutCouponInsert()}
                                        </div>
                                }
                            </div>
                            :
                            <div className={"container"}>
                                <div className={"card card-body p-0 shadow-sm border-0"}>
                                    <div className={"row align-items-center bg-white"}>
                                        <div className={"col-1 px-0 text-primary text-center"}>
                                            <FaSolidIcon name={"money-check-alt"}/>
                                        </div>
                                        <div className={"col px-0"}>
                                            <FullscreenInput
                                                name={"codice_coupon"}
                                                value={this.state.codice_coupon}
                                                onKeyUp={this.handleClearDatiCoupon}
                                                className={"form-control border-0 rounded-0"}
                                                placeholder={i18n.t('checkout-discount.input-placeholder')}
                                                onChange={this.handleInputChange}
                                                disabled={this.props.disableCoupon}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.applying ?
                                        <span className={'text-warning small'}>
                                            {i18n.t('checkout-discount.status.applying')}
                                        </span>
                                        :
                                        this.state.codice_coupon && this.printInfoAboutCouponInsert()
                                }
                            </div>
                    }
                </div>


                {/*Wallet a credito*/}
                {
                    (this.props.amountPayableWithWallet && this.props.amountPayableWithWallet > 0 &&
                        //Se ci sono pacchetti i wallet non vengono utilizzati
                        !(this.state.discount_complete.packages && this.state.discount_complete.packages.importo_usato_totale)) ?
                        <WalletsList
                            amountPayableWithWallet={this.props.amountPayableWithWallet}
                            discountFromWallets={this.discountFromWalletsHandler}
                            module={MAPPING_MODULE_COUPON_TYPE[this.props.type]}
                            items={this.props.items}
                        />
                        : ''
                }
            </Fragment>
        );
    }
}


CheckoutDiscount.propTypes = {
    type: PropTypes.string,
    discountValidityHandler: PropTypes.func, //Se il coupon è valido restituisce i dati, altrimenti array vuoto
    discountableAmount: PropTypes.number, //Importo scontabile
    amountPayableWithWallet: PropTypes.number, //Pagabile dal wallet (se passato come 0 o null, allora il wallets non verrà mostrato)
    externalCode: PropTypes.string || null,
    disableCoupon: PropTypes.bool,
    usageDate: PropTypes.string,
    operationDate: PropTypes.string, //Data per la quale si vuole fare una prenotazione/ordine
    items: PropTypes.arrayOf(WalletItemType)
};
