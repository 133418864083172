import React, {Fragment} from "react";
import PropTypes from "prop-types";
import "./ChatRoomMessage.css";
import moment from "moment";
import {AudioMessage} from "./AudioMessage/AudioMessage";
import {GenericMediaMessage} from "./GenericMediaMessage";
import {ImageMessage} from "./ImageMessage";
import {TextMessage} from "./TextMessage";
import {VideoMessage} from "./VideoMessage";

export class ChatRoomMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    isMine = _ => parseInt(this.props.messageData.id_mittente) === parseInt(this.props.idSelf);

    getMessageComponent = ({type, metadata}) => {

        if (type === 'media'){
            const [mimeGenericType, mimeSpecificType] = metadata.mime_type.split('/');
            switch (mimeGenericType) {
                case 'image':
                    return ImageMessage;
                case 'audio':
                    return AudioMessage;
                case 'video':
                    return VideoMessage;
                default:
                    return GenericMediaMessage;
            }
        }

        switch (type) {
            case 'text':
            case 'auto':
                return TextMessage;
            default:
                return React.Fragment;
        }
    }


    render() {
        const sendDate = moment(this.props.messageData.data_creazione).format('ddd D MMM');
        const isMine = this.isMine();
        const Message = this.getMessageComponent(this.props.messageData);
        return (
            <Fragment>
                {
                    this.props.showOwnDate &&
                    <div className={"d-flex justify-content-center my-3"}>
                        <div className={"d-inline-block bg-white small px-2 py-1 text-capitalize rounded shadow-sm"}>
                            {sendDate}
                        </div>
                    </div>
                }

                <Message
                    isMine={isMine}
                    isLast={this.props.isLast}
                    messageData={this.props.messageData}
                    getMediaContentUrlAPI={this.props.getMediaContentUrlAPI}
                />
            </Fragment>
        )
    }

}

ChatRoomMessage.propTypes = {
    messageData: PropTypes.object.isRequired,
    idSelf: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isLast: PropTypes.bool.isRequired,
    showOwnDate: PropTypes.bool,
    getMediaContentUrlAPI: PropTypes.func.isRequired
}

