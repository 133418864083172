import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {HotelView} from "./HotelView";
import {CameraView} from "./CameraView";
import {HotelBookingResumeIcon} from "../../../../img/illustrations/HotelBookingResumeIcon";
import moment from "moment";
import {PluralString} from "../../../../helpers/UIUtility";
import {ServizioView} from "./ServizioView";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {OnlineCheckinView} from "./OnlineCheckinView";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {HOTEL_GUEST_SAVED_EVT} from "../../../../const/AppEventsCostants";
import RecensioniModal from "../../../Recensioni/RecensioniModal";
import {RECENSIONI_TIPO_PRENOTAZIONE_CAMERA} from "../../../../const/RecensioniConstants";
import {showFeedbackAvailableForRooms} from "../../../Recensioni/RecensioniHelperFunctions";
import {CancellationView} from "./CancellationView";
import {ModuloHotelAPI} from "../../../../api/ModuloHotel/ModuloHotelAPI";

export class HotelBookDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookData: null
        }
    }

    componentDidMount() {
        this.fetchBookData();
        FuxEvents.on(HOTEL_GUEST_SAVED_EVT, this.fetchBookData);
    }

    componentWillUnmount() {
        FuxEvents.off(HOTEL_GUEST_SAVED_EVT, this.fetchBookData);
    }

    fetchBookData = _ => {
        swalLoading('Recupero informazioni sulla prenotazione');
        ModuloHotelAPI.Books.getBookData(this.props.idPrenotazione)
            .then(bookData => {
                this.setState({bookData: bookData});
                swal.safeClose();
            })
            .catch(message => {
                swalError(message);
                this.props.onDismiss();
            })
    }


    render() {
        if (!this.state.bookData) return '';
        return (
            <Page
                title={"Riepilogo"}
                active={true}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
            >
                <div className={"container-fluid mt-3"}>
                    <div className={"w-50 mb-2 d-blockk mx-auto mb-2 d-none"}>
                        <HotelBookingResumeIcon
                            color={this.props.infoNegozio?.aspetto?.primary_color || '#293069'}
                            className={"img-fluid"}
                        />
                    </div>
                    <h4 className={"font-weight-bold text-primary text-center"}>
                        Riepilogo della tua prenotazione
                    </h4>

                    {
                        showFeedbackAvailableForRooms(this.state.bookData) &&
                        <div className={"mb-2"}>
                            <RecensioniModal
                                id_operazione={this.state.bookData.id_prenotazione}
                                tipo_operazione={RECENSIONI_TIPO_PRENOTAZIONE_CAMERA}
                            />
                        </div>
                    }

                    <div className={"my-4"}>
                        <OnlineCheckinView bookData={this.state.bookData} infoNegozio={this.props.infoNegozio}/>
                    </div>

                    <div className={"my-4"}>
                        <h5 className={"text-muted"}>Dove?</h5>
                        <HotelView hotelData={this.state.bookData.hotel}/>
                    </div>

                    <div className={"my-4"}>
                        <div className={"row align-items-center"}>
                            <div className={"col border-right"}>
                                <div className={"row mb-3"}>
                                    <div
                                        className={"col d-flex flex-column justify-content-center align-items-center"}>
                                        <span className={"text-muted"}>Dal</span>
                                        <div className={"bg-primary text-on-bg-primary shadow rounded p-2"}>
                                            {moment(this.state.bookData.data_checkin).format('DD')}
                                        </div>
                                        <span>{moment(this.state.bookData.data_checkin).format('MMMM')}</span>
                                    </div>
                                    <div
                                        className={"col d-flex flex-column justify-content-center align-items-center"}>
                                        <span className={"text-muted"}>Al</span>
                                        <div className={"bg-primary text-on-bg-primary shadow rounded p-2"}>
                                            {moment(this.state.bookData.data_checkout).format('DD')}
                                        </div>
                                        <span>{moment(this.state.bookData.data_checkout).format('MMMM')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"col text-center"}>
                                <FaSolidIcon name={"users"} className={"fa-2x text-primary mb-2"}/>
                                <div className={"text-center"}>
                                    Per {this.state.bookData.nadulti} <PluralString
                                    n={this.state.bookData.nadulti} more={"adulti"} single={"adulto"}/>
                                    {
                                        !!this.state.bookData.nbambini &&
                                        <Fragment>
                                            &nbsp;e {this.state.bookData.nbambini} <PluralString
                                            n={this.state.bookData.nbambini} more={"bambini"}
                                            single={"bambino"}/>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"my-4"}>
                        <h5 className={"text-muted"}>La tua camera</h5>
                        <CameraView
                            bookData={this.state.bookData}
                        />
                    </div>

                    {
                        !!this.state.bookData.servizi.length &&
                        <div className={"my-4"}>
                            <h5 className={"text-muted"}>Servizi aggiuntivi</h5>
                            {
                                this.state.bookData.servizi.map(s =>
                                    <ServizioView
                                        key={s.id_servizio}
                                        servizioData={s}
                                    />
                                )
                            }
                        </div>
                    }

                    <CancellationView bookData={this.state.bookData} onUpdate={this.fetchBookData}/>

                </div>
            </Page>
        )
    }

}

HotelBookDetailModal.propTypes = {
    onDismiss: PropTypes.func,
    idPrenotazione: PropTypes.string,
    infoNegozio: PropTypes.object,
}