import React, {Fragment} from "react";
import {UserAPI} from "../../../api/User/UserAPI";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import AuthAPI from "../../../api/AuthAPI";
import APIWelfareConfig from "../../../api/Welfare/APIWelfareConfig";
import WalletDipendentiAPI from "../../../api/Welfare/WalletDipendenteAPI";
import {TYPE_WALLET_WELFARE} from "./WalletsConstants";
import WalletsListView from "./WalletsListView";
import PropTypes from "prop-types";
import {WalletsAPI} from "../../../api/Wallets/WalletsAPI";
import {data} from "autoprefixer";
import {da} from "date-fns/locale";
import {WalletItemType} from "../Types/WalletItemType";


export default class WalletsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            walletsList: [],
            walletsErrors: [],
        }
    }

    componentDidMount() {
       this.fetchWalletsInfo()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.amountPayableWithWallet !== this.props.amountPayableWithWallet){
            this.setState({walletsList: [], walletsErrors: []}, _=>{
                this.fetchWalletsInfo()
            })
        }
    }

    fetchWalletsInfo = _ =>{
        let promises = [
            this.getUsableWallets()
        ]
        //Controllo se devo recuperare le info del welfare
        if(this.welfareWalletCondition()){
            promises.push(this.welfareGetData())
        }

        Promise.all(promises)
            .then((data) => {
                let wallets = this.state.walletsList
                console.log(data)
                for(var i=0; data.length > i; i++){
                    //Se si tratta di un array di wallets li gestisco in maniera diversa poiché è dell'attività
                    if(data[i].wallets){
                        let attivitaWallets = data[i].wallets
                        for(let w=0; attivitaWallets.length > w; w++){
                            wallets.push({
                                id_wallet: attivitaWallets[w].id_wallet,
                                type:attivitaWallets[w].type,
                                saldo: parseFloat(attivitaWallets[w].saldo_disponibile),
                                max_payable: parseFloat(attivitaWallets[w].max_payable),
                                bonded_spending_amount: parseInt(attivitaWallets[w].bonded_spending_amount),
                                importo_usato: '',
                                name: attivitaWallets[w].name,
                                opened: true
                            })
                        }
                    }else {
                        //Se non è un array allora è il welfare
                        wallets.push({
                            id_wallet: data[i][1].id_wallet,
                            type: TYPE_WALLET_WELFARE,
                            saldo: parseFloat(data[i][0].saldoDisponibile),
                            max_payable: parseFloat(data[i][0].saldoDisponibile),
                            bonded_spending_amount: 0,
                            importo_usato: '',
                            name: '',
                            opened: true
                        })
                    }
                }

                this.setState({walletsList: wallets}, _=>{
                    this.defaultMaxImport()
                })
            })
            .catch((msg) =>{
                let errors = this.state.walletsErrors
                errors.push({type: TYPE_WALLET_WELFARE, text: msg})
                this.setState({walletErrors: errors})
            })

    }

    /**
     * @Welfare
     */

    welfareWalletCondition =_=>{
        //Se non è modulo shop posso già ritornare falso
        if(!this.props.module || this.props.module !== 'modulo_shop') return false
        //Verifico che l'attività abbia il welfare
        return (!!NegozioOpenStore.infoNegozio.walletapikey && !!AuthAPI.currentUser.walletapikey);
    }

    welfareGetData =_=>{
        let welfarePromises = [
            WalletDipendentiAPI.getBalance(AuthAPI.currentUser.walletapikey),
            WalletsAPI.User.getWalletWelfare()
        ]
        return Promise.all(welfarePromises)
    }


    /**
     * @Wallets-in-bookizon
     */
    getUsableWallets = _ =>{
        return WalletsAPI.User.getUsableWallets(NegozioOpenStore.infoNegozio.id_negozio, this.props.module || null, this.props.items || null)
    }


    /**
     * @FOR-ALL-WALLETS
     */


    /**
     * Dati l'id del wallet del wallet e il nuovo importo usato, lo modifica all'interno dell oggetto
     * @param event
     */
    handleChangeImporto = (event) =>{

        const target = event.target;
        let value = target.value;
        const id_wallet = target.name;

        const regExp = /^\d*(\.\d{0,2})?$/g;
        if (!value.match(regExp)) {
            value = parseFloat(value).toFixed(2);
        }

        this.setNewWalletList(id_wallet, value)
    }

    defaultMaxImport = _ =>{
        for (const wallet of this.state.walletsList) {
            this.setMaxImportOfWallet(wallet.id_wallet, wallet.max_payable)
        }
    }


    /**
     * Setta il massimo importo in un wallet
     * @param id_wallet
     * @param maxPayable
     */
    setMaxImportOfWallet = (id_wallet, maxPayable) => {
        //Calcolo il totale nei wallet eccetto quello di cui richiedo il max
        let totInWallet = this.state.walletsList.reduce((subtot, i) => {
            if (i.id_wallet === id_wallet) return subtot
            return subtot + i.importo_usato || 0
        }, 0)

        this.setNewWalletList(id_wallet, Math.min(maxPayable, this.props.amountPayableWithWallet - totInWallet))
    }


    /**
     * Quando gli importi vengono modificati bisogna sempre modificare la voce importo_usato
     * e comunicare la nuova wallet list al genitore
     * @param id_wallet
     * @param importo
     */
    setNewWalletList = (id_wallet, importo) =>{
        let newWalletsList = this.state.walletsList
        for(var i = 0; i < this.state.walletsList.length; i++){
            if(this.state.walletsList[i].id_wallet === id_wallet){
                newWalletsList[i].importo_usato = importo ? parseFloat(importo) : ""
            }
        }

        this.setState({walletsList: newWalletsList}, this.props.discountFromWallets(this.state.walletsList))
    }


    /**
     * Si occupa del toggle del wallet e mostrare l'importo
     * @param i
     */
    toggleWalletOpen = i => {
        let walletsList= this.state.walletsList
        walletsList[i].opened = !walletsList[i].opened
        this.setState({walletsList: [...walletsList]})
    }


    render() {

        let maxImportoUsabile = this.props.amountPayableWithWallet - this.state.walletsList.reduce((partialSum, w) => partialSum + (w.importo_usato || 0), 0)

        return (
            <Fragment>
                <div className={"my-4"}>
                    <div className={"card bg-main-z3 text-on-bg-main border-0 shadow-sm my-1 mx-3 list-group"}>
                        {
                            this.state.walletsList.length > 0 && this.state.walletsList.map((wallet, i) => {
                                return <WalletsListView
                                    key={i}
                                    index={i}
                                    wallet={wallet}
                                    onSetMaxImport={this.setMaxImportOfWallet}
                                    onImportoChange={this.handleChangeImporto}
                                    onToggleButtonClick={this.toggleWalletOpen}
                                />
                            })
                        }
                    </div>
                </div>

                {
                    maxImportoUsabile < 0 &&
                    <div className={"w-100 px-3"}>
                        <b className={"text-warning mt-1"}>
                            Il massimo importo scalabile dai wallet è €{this.props.amountPayableWithWallet}
                        </b>
                    </div>
                }
            </Fragment>
        );
    }
}


WalletsList.propTypes = {
    discountFromWallets: PropTypes.func, //Come parametro della funzione verrà passata la lista dei portafogli e gli importi
    amountPayableWithWallet: PropTypes.number,
    module: PropTypes.string, //Opzionale
    items: PropTypes.arrayOf(WalletItemType)
}