import React, {Fragment} from "react";
import contacts from "../../../launcher/svg/register-form/contacts.svg"
import {PhoneNumberInput} from "../../BaseComponents/Inputs/PhoneNumberInput";
import {swalError} from "../../../helpers/SweetAlertWrapper";
import i18next from "i18next";
import {ContactIllustration} from "../../../img/illustrations/login/ContactIllustration";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";

export default class ContactsInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            cellulare: '',
            conferma_cellulare: '',
            errors: {}
        }
        this.formRef = null;
        this.campiObbligatori = ["cellulare", "email"];
    }

    handleInputChange = ({target}) => this.setState({[target.name]: target.value});
    handlePhoneNumberChange = phone => this.setState({cellulare: phone});
    handlePhoneNumberConfirmChange = phone => this.setState({conferma_cellulare: phone});
    handlePasteDisabled = e => e.preventDefault();

    validateFormAndProceed = values => {
        const errors = {};

        for (var i in this.campiObbligatori) {
            if (!values[this.campiObbligatori[i]]) {
                errors[this.campiObbligatori[i]] = i18next.t('generic.errors.mandatory-field-label');
            }
        }

        if (values.cellulare !== values.conferma_cellulare) {
            errors.cellullare = i18next.t('register.errors.phone-not-matching');
            errors.conferma_cellulare = i18next.t('register.errors.phone-not-matching');
        }


        var reEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!reEmail.test(values.email)) {
            errors.email = i18next.t('generic.errors.invalid-format');
        }

        if (Object.keys(errors).length) {
            this.setState({
                errors: errors
            });
            swalError(i18next.t('register.errors.missing-fields'));
            return;
        }

        this.props.confirmValue({
            "email": this.state.email,
            "cellulare": this.state.cellulare,
            "conferma_cellulare": this.state.conferma_cellulare
        })
    }

    render() {
        let negozioAspetto = NegozioOpenStore.infoNegozio ? NegozioOpenStore.infoNegozio.aspetto : null

        return (
            <Fragment>

                <div className={"text-center px-3 pb-2"}>
                    <div className={"w-100"}>
                        <ContactIllustration className={"img-fluid my-5"} color={negozioAspetto ? negozioAspetto.secondary_color : "#6861ce"}/>
                    </div>
                    <h2 className={"text-primary font-weight-bold"}>{i18next.t('register.heading2')}</h2>
                    <p className={"text-muted"}>{this.props.userName}, {i18next.t('register.subheading2')}</p>
                </div>

                <div className="form-group px-4 py-1">
                    <span className={"text-muted"}>{i18next.t('register.phone-label')}</span>
                    <PhoneNumberInput value={this.state.cellulare} onChange={this.handlePhoneNumberChange} border={"none"}/>
                    <ErrorMessage errors={this.state.errors} name="cellulare"/>
                </div>

                <div className="form-group px-4 py-1">
                    <span className={"text-muted"}>{i18next.t('register.confirm-phone-label')}</span>
                    <PhoneNumberInput value={this.state.conferma_cellulare} onChange={this.handlePhoneNumberConfirmChange} border={"none"}/>
                    <ErrorMessage errors={this.state.errors} name="conferma_cellulare"/>
                </div>

                <div className="form-group px-4 py-1">
                    <span className={"text-muted"}>Email</span>
                    <input autoComplete={"new-password"} type="email" name="email" className="form-control border-0"
                           placeholder="La tua email" onPaste={this.handlePasteDisabled} value={this.state.email}
                           onChange={this.handleInputChange}/>
                    <ErrorMessage errors={this.state.errors} name="email"/>
                </div>

                <div className={"px-4 py-3"}>
                    <button type={"button"} className={"btn btn-primary btn-block"}
                            onClick={_=>this.validateFormAndProceed(this.state)}>{i18next.t('generic.proceed-btn')}
                    </button>
                </div>
            </Fragment>
        )
    }
}

function ErrorMessage(props) {
    return (!!props.errors[props.name] && <span className={'text-danger'}>{props.errors[props.name]}</span>)
}