import React from "react";
import Page from "../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import {FidelityCardAPI} from "../../../api/FidelityCard/FidelityCardAPI";
import {swal, swalLoading} from "../../../helpers/SweetAlertWrapper";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import PropTypes from "prop-types";

export class FidelityCardRegolamentoPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regolamento: null,
        }
    }

    componentDidMount() {
        swalLoading('Recupero regolamento in corso...')
        FidelityCardAPI.User.getRegulation(this.props.idNegozio)
            .then(regolamento => this.setState({regolamento: regolamento}, _ => swal.safeClose()));
    }


    render() {
        if (!this.state.regolamento) return '';

        return (
            <Page
                title={"Regolamento"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={true}
            >
                <div className={"container-fluid pt-3"}>
                    <SafeHtmlContainer html={this.state.regolamento}/>
                </div>
            </Page>
        );
    }
}

FidelityCardRegolamentoPage.propTypes = {
    idNegozio: PropTypes.string,
    onDismiss: PropTypes.func
}