import React from "react";
import {FaSolidIcon} from "../FontAwesomeIcons";
import Page from "../BaseComponents/Page/Page";
import AllergeniProdottiList from "./AllergeniProdottiList";


export default class AllergeniPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page
                title={"Allergeni"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
            >
                <div className={"container py-3"}>
                    <AllergeniProdottiList
                        allergeni={this.props.allergeni}
                        className={"d-block"}
                        icon={true}
                        text={true}
                        description={true}
                        size={40}
                        detailPage={false}
                    />
                </div>
            </Page>
        )
    }

}
