import React from "react";
import AppModalPortal from "./AppModalPortal";
import ModalPortal from "./ModalPortal";

export default class ModalPortalHOC extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const Portal = document.getElementById('app-modal-root') ? AppModalPortal : ModalPortal;
        return <Portal>{this.props.children}</Portal>
    }

}
