import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";

var BannerInAppAPI = {
    forHomepage: function(id_negozio){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/banner-in-app/homepage/${id_negozio}`), {token:AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    forEcommerceHomepage: function(id_negozio){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/banner-in-app/ecommerce-homepage/${id_negozio}`), {token:AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    forMenuHomepage: function(id_negozio){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/banner-in-app/menu-homepage/${id_negozio}`), {token:AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    forHotelBookingIntent: function(id_negozio){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/banner-in-app/hotel-booking-intent/${id_negozio}`), {token:AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    forProductCategory: function(id_categoria){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/banner-in-app/product-category/${id_categoria}`), {token:AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};

export default BannerInAppAPI;
