import React from "react";
import moment from "moment";
import {string} from "prop-types";
import {PromotionsAPI} from "../../../../api/Promotions/PromotionsAPI";
import PromotionsCarousel from "../PromotionsCarousel";
import PromotionsGeohashPage from "./PromotionsGeohashPage";
import NativeGeolocationProvider from "../../../../providers/NativeGeolocationProvider";
import {PROMOTIONS_SORT_INDICIZZAZIONE_DESC, PROMOTIONS_SORT_POSIZIONE_DESC} from "../PromotionsContants";


const POSITION_RECOVERY_TIMEOUT = 2000;
const MAX_DISTANCE = 70 //km

export default class PromotionsGeohash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promotions: [],
            geoHashPageOpen: false,

            lat: null,
            lng: null,
            hasGeolocationPermission:false,
            positionRecoveryTimedout: false,
        }
    }

    componentDidMount() {
        this.checkGeolocationPerimission()
    }


    fetchData=_=>{
        PromotionsAPI.PromotionsWithGeolocation.getPromotionsInGeoHash(
            5, 0,
            null,
            this.state.lat,
            this.state.lng,
            MAX_DISTANCE
        )
            .then(response =>{
                this.setState({promotions: response.promotions})
            })
    }


    geoHashPageToggle =_=>{
        this.setState({geoHashPageOpen: !this.state.geoHashPageOpen})
    }


    //Verifico se ci sono i permessi di geolocalizzazione
    checkGeolocationPerimission =_=>{
        NativeGeolocationProvider.getPermission()
            .then(permission => {
                this.setState({
                    hasGeolocationPermission: permission,
                });
                if (permission) {
                    setTimeout(this.handlePositionRecoveryTimedout, POSITION_RECOVERY_TIMEOUT);
                    this.waitForPosition();
                } else {
                    this.fetchData()
                    NativeGeolocationProvider.askPermission();
                    NativeGeolocationProvider.waitForPermissionGrant().then(_ => {
                        setTimeout(this.handlePositionRecoveryTimedout, POSITION_RECOVERY_TIMEOUT);
                        this.waitForPosition();
                    });
                }
            });
    }


    //Se dopo la richiesta della posizione sono passati X secondi e non ho ancora una posizione, allora imposto il flag a true
    handlePositionRecoveryTimedout = _ => {
        this.setState({
            positionRecoveryTimedout: this.state.hasGeolocationPermission && (!this.state.lat || !this.state.lng)
        });
    }


    //Recupera le informazioni sulla geo localizzazione
    waitForPosition = _ => {
        NativeGeolocationProvider.waitForPosition('SEARCH')
            .then(location => {
                if (location) {
                    this.setState({
                        lat: location.lat,
                        lng: location.lng
                    }, _=>{
                        this.fetchData()
                    })
                }
            });
    }


    render() {
        return (
            <div>
                <div className={"mt-5 p-3"}>
                    <h4><b>Vicino a te! 📍</b></h4>
                    <span className={"text-muted"}>Scopri le migliori offerte nei dintorni!</span>
                </div>
                <PromotionsCarousel
                    onVediDiPiuClick={this.geoHashPageToggle}
                    promotions={this.state.promotions}
                />
                {
                    this.state.geoHashPageOpen &&
                        <PromotionsGeohashPage
                            onDismiss={this.geoHashPageToggle}
                        />
                }
            </div>
        );
    }

}
