import AppConfig from '../config/AppConfig'
import FuxUtility from "../lib/FuxFramework/FuxUtility";
import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import FuxEvents from "../lib/FuxFramework/FuxEvents";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";
import {BookizonDexieDB} from "../database/DatabaseFacade";

export const AuthAPI = {
    currentUser: null,
    id_negozio: 0,
    hasAlreadyStarted: 0,
    lastStartFrom: 'NULL',
    lastFcmTokenSent: null,
    registerWithEmailAndPassword: function (email, password, extraData) {
        var data = {
            email: email,
            password: password,
            id_negozio: AppConfig.isBookizonWebView ? null : NegozioOpenStore.id_negozio
        };
        data = FuxUtility.mergeObjects(data, extraData);
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/register`,
            data,
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    confirmAccountWithOTP: function (id_utente, otp) {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/confirm-with-otp`, {id_utente: id_utente, otp: otp},
            FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE
        )
    },
    confirmPasswordWithOTP: function (id_utente, password, otp) {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/confirm-password-with-otp`, {
                id_utente: id_utente,
                password: password,
                otp: otp
            },
            FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE
        )
    },
    validateOTP: function (id_utente, otp) {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/validateOTP`, {id_utente: id_utente, otp: otp},
            FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE
        )
    },
    resendOTP: function (id_utente, otp_provider = 'sms') {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/resendOTP`, {id_utente: id_utente, otp_provider: otp_provider},
            FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE
        )
    },
    sendOTPWithCredentials: function (user, otp_provider = 'sms') {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/send-otp-with-credentials`, {
                user: user,
                otp_provider: otp_provider,
                id_negozio: AppConfig.isBookizonWebView ? null : NegozioOpenStore.id_negozio
            },
            FuxHTTP.RESOLVE_RESPONSE, FuxHTTP.REJECT_MESSAGE
        )
    },
    invalidateAccessToken: function (invalidateToken) {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/invalidate-token`, {
                token: AuthAPI.getUserAccessToken(),
                invalidate_token: invalidateToken
            },
            FuxHTTP.RESOLVE_RESPONSE, FuxHTTP.REJECT_MESSAGE
        )
    },
    requestAccountCancellation: function () {
        return FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/request-account-cancellation`, {
                token: AuthAPI.getUserAccessToken(),
            },
            FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE
        )
    },
    loginWithCredentials: function (user, password) {
        return new Promise((resolve, reject) => {
            FuxHTTP.apiPostRequestWithPromise(
                `${AppConfig.webServerUrl}/auth/login-with-credentials`, {
                    user: user,
                    password: password,
                    id_negozio: AppConfig.isBookizonWebView ? null : NegozioOpenStore.id_negozio
                },
                FuxHTTP.RESOLVE_RESPONSE, FuxHTTP.REJECT_RESPONSE
            ).then(function (jsonResponse) {
                const prevUser = AuthAPI.currentUser ? {...AuthAPI.currentUser} : null;
                AuthAPI.currentUser = jsonResponse.data;
                AuthAPI.setAccessToken(AuthAPI.currentUser.accessToken, !AppConfig.isBookizonWebView && NegozioOpenStore.id_negozio ? NegozioOpenStore.id_negozio : null)
                AuthAPI._onStateChanged_Fire(prevUser);
                resolve(AuthAPI.currentUser);
            }).catch(reject);
        });
    },
    loginWithAccessToken: function () {
        return new Promise((resolve, reject) => {
            /**
             * @description: L'access token deve permettere in maniera univoca l'accesso ad un utente.
             * Se siamo all'interno di un app privata allora l'access token usato sarà nel local storage alla chiave "{id_negozio}_accessToken"
             * altrimenti sarà nella chiave "global_accessToken". La priorità viene dato a quello della app privata.
             * */

            const isPrivateAppLogin = !!NegozioOpenStore.id_negozio && !AppConfig.isBookizonWebView;

            AuthAPI.getAccessToken(isPrivateAppLogin ? NegozioOpenStore.id_negozio : null)
                .then(accessToken => {
                    if (accessToken && accessToken.length < 32) {
                        accessToken = null;
                    }
                    if (!accessToken) {
                        AuthAPI._onStateChanged_Fire(AuthAPI.currentUser);
                        resolve(false);
                        return false;
                    }
                    if (accessToken) {
                        var data = {token: accessToken};
                        var promise = FuxHTTP.apiPostRequestWithPromise(
                            `${AppConfig.webServerUrl}/auth/login-with-access-token`,
                            data,
                            FuxHTTP.RESOLVE_RESPONSE,
                            FuxHTTP.REJECT_MESSAGE
                        );
                        promise.then(function (jsonResponse) {
                            const prevUser = AuthAPI.currentUser ? {...AuthAPI.currentUser} : null;
                            AuthAPI.currentUser = jsonResponse.data.userData;
                            AuthAPI.setAccessToken(AuthAPI.currentUser.accessToken, isPrivateAppLogin ? NegozioOpenStore.id_negozio : null);
                            AuthAPI.invalidateAccessToken(accessToken);
                            AuthAPI._onStateChanged_Fire(prevUser);
                            resolve(AuthAPI.currentUser);
                        });
                        promise.catch((message) => {
                            const prevUser = AuthAPI.currentUser ? {...AuthAPI.currentUser} : null;
                            AuthAPI.currentUser = null;
                            AuthAPI._onStateChanged_Fire(prevUser);
                            resolve(false);
                        })
                    }
                });
        });
    },
    saveAccessToken: function (token) {
        AuthAPI.setAccessToken(token, !AppConfig.isBookizonWebView && NegozioOpenStore.id_negozio ? NegozioOpenStore.id_negozio : null)
    },
    setAccessToken: function (token, id_negozio) {
        console.log("[AUTH API] Set new token to", token);
        BookizonDexieDB.accessToken.put({id_negozio: id_negozio ? id_negozio : "global", token: token});
        const key = `${id_negozio ? id_negozio : "global"}_accessToken`;
        localStorage[key] = token;
    },
    getAccessToken: function (id_negozio) {
        return new Promise((resolve) => {
            BookizonDexieDB.accessToken.get(id_negozio ? id_negozio : "global")
                .then(accessTokenData => {
                    console.log("[AUTH API] Token recuperato da index DB", accessTokenData.token || null);
                    resolve(accessTokenData.token || null);
                })
                .catch(_ => {
                    const key = `${id_negozio ? id_negozio : "global"}_accessToken`;
                    console.log("[AUTH API] Fallback token finder");
                    resolve(localStorage[key] || null);
                });
        });
    },
    refresh: function () {
        AuthAPI.loginWithAccessToken();
    },
    logout: function () {
        const prevUser = AuthAPI.currentUser ? {...AuthAPI.currentUser} : null;
        AuthAPI.invalidateAccessToken(AuthAPI.getUserAccessToken());
        AuthAPI.currentUser = null;
        var accessTokenKey = !AppConfig.isBookizonWebView && NegozioOpenStore.id_negozio ? NegozioOpenStore.id_negozio + "_accessToken" : "global_accessToken";
        localStorage[accessTokenKey] = undefined;
        AuthAPI.saveAccessToken(undefined)
        AuthAPI._onStateChanged_Fire(prevUser);
    },
    _onStateChangedEventName: '_AUTH_API_STATE_CHANGED_',
    _wrappedCb: [],
    _originalCb: [],
    //Funzione per richiamare tutte le callback registrate all'evento
    _onStateChanged_Fire: function (prevUser) {
        FuxEvents.emit(AuthAPI._onStateChangedEventName, {currentUser: AuthAPI.currentUser, prevUser: prevUser});
    },
    //Funzione per registrazione all'evento
    onStateChanged: function (cb) {
        if (FuxUtility.isFunction(cb) && cb) {
            const wrappedCb = WrappedCallback(cb);
            AuthAPI._originalCb.push(cb);
            AuthAPI._wrappedCb.push(wrappedCb);
            FuxEvents.on(AuthAPI._onStateChangedEventName, wrappedCb);
            //Se è già loggato al momento dell'iscrizione all'evento allora richiamo la funzione
            if (AuthAPI.currentUser) {
                wrappedCb({currentUser: AuthAPI.currentUser, prevUser: AuthAPI.currentUser});
            }
        }
    },
    offStateChanged: function (cb) {
        const idx = AuthAPI._originalCb.findIndex(f => f === cb);
        if (idx) {
            FuxEvents.off(AuthAPI._onStateChangedEventName, AuthAPI._wrappedCb[idx]);
        }
    },
    start: function (onlyOnce, startedFrom) {
        AuthAPI.lastStartFrom = startedFrom;
        console.log("AUTHAPI START FROM", startedFrom);
        return new Promise((resolve, reject) => {
            if (!onlyOnce || AuthAPI.hasAlreadyStarted === 0) {
                AuthAPI.hasAlreadyStarted++;
                console.log("AUTH STARTED");
                AuthAPI.loginWithAccessToken().then(function () {
                    console.log("Autentication token succeed");
                    resolve(true);
                }).catch(function (e) {
                    console.log("Autentication token failed: " + e);
                    reject(e);
                });
            } else {
                console.log("ONLY ONCE", onlyOnce, "started", AuthAPI.hasAlreadyStarted, AuthAPI.lastStartFrom);
                resolve(false);
            }
        })
    },
    reset: function () {
        console.log("AUTH RESET");
        FuxEvents.remove(AuthAPI._onStateChangedEventName);
        AuthAPI.currentUser = null;
        AuthAPI.id_negozio = null;
    },
    setUserData: function (userData) {
        var p = FuxHTTP.apiPostRequestWithPromise(
            `${AppConfig.webServerUrl}/auth/set-user-data/${AuthAPI.currentUser.accessToken}`, userData,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
        p.then(_ => AuthAPI.currentUser = FuxUtility.mergeObjects(AuthAPI.currentUser, userData));
        return p;
    },
    setFCMToken: function (fcm_token, platform, bundleVersion, force) {
        const data = {
            fcm_token: fcm_token
        };
        if (platform) data.platform = platform;
        if (bundleVersion) data.bundle_version = bundleVersion;

        AuthAPI.onStateChanged(function (user) {
            if (user) {
                if (AuthAPI.lastFcmTokenSent !== fcm_token || force) {
                    AuthAPI.setUserData(data)
                        .then(response => {
                            console.log(JSON.stringify(response));
                            AuthAPI.lastFcmTokenSent = fcm_token;
                        })
                }
            }
        });
        return fcm_token;
    },
    waitForLoggedIn: function () {
        return new Promise(resolve => {
            if (AuthAPI.currentUser) {
                resolve();
                return true;
            }
            const listener = _ => {
                FuxEvents.off(AuthAPI._onStateChangedEventName, listener);
                resolve();
            }
            FuxEvents.on(AuthAPI._onStateChangedEventName, listener);
        });
    },
    getUserAccessToken: function () {
        if (AuthAPI.currentUser) {
            return AuthAPI.currentUser.accessToken;
        }
        return null;
    }
};


//Funzione usata come wrapper per eseguire la funzione originale attraverso la libreria FuxEvents, ma supportando più parametri
const WrappedCallback = (cb) => ({currentUser, prevUser}) => {
    cb(currentUser, prevUser);
}

export default AuthAPI;
