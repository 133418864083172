import React from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import Page from "../../components/BaseComponents/Page/Page";
import {swal, swalLoading} from "../../helpers/SweetAlertWrapper";
import CronologiaUserAPI from "../../api/CronologiaUserAPI";
import WalletDipendentiAPI from "../../api/Welfare/WalletDipendenteAPI";


const SaldoContabileStyle = {
    opacity: "0.5"
};



export default class DipendenteWelfareBalanceContent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            balanceContabile: null,
            balanceDisponibile: null
        }
    }

    fetchData = () => {
        WalletDipendentiAPI.getBalance(this.props.user.walletapikey)
            .then((data) => {
                this.setState({balanceContabile: data.saldoContabile, balanceDisponibile: data.saldoDisponibile});
            })
    }

    componentDidMount() {
        if (this.props.active) {
            this.fetchData();
        }
    }

    render() {
        return (
            <div className={"container"}>
                {
                    this.state.balanceContabile !== null && this.state.balanceDisponibile !== null
                    ?
                        <div>
                            <span>Saldo disponibile</span>
                            <h1 className={"font-weight-bold"}>€ {this.state.balanceDisponibile}</h1>
                            <span className={"text-sx"} style={SaldoContabileStyle}>Saldo contabile</span>
                            <h4 style={SaldoContabileStyle}>€ {this.state.balanceContabile}</h4>
                        </div>
                        :
                    <div className={"container text-center lead mt-4"}>
                        <i className={"fas fa-spinner fa-spin"}/>
                    </div>
                }
            </div>
        );
    }
}