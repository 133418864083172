import React, {Fragment} from "react";
import AppConfig from "../../../config/AppConfig";
import {AppIconThumbnail} from "../../../components/BaseComponents/AppIconThumbnail";
import {ShopUserSettingsSwitch} from "./ShopUserSettingsSwitch";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";

export class SettingsAppItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCommercial: false,
            showReminder: false,
        }
    }

    toggleCommercial = _ => this.setState({showCommercial: !this.state.showCommercial});
    toggleReminder = _ => this.setState({showReminder: !this.state.showReminder});

    render() {
        var imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${this.props.negozio.id_negozio}/logoIcon/100`;
        return (
            <div className={"card p-2 shadow-sm border-0 mb-3"} key={this.props.negozio.id_negozio}
                 style={this.props.style}>
                <div className={"d-flex align-items-center mb-2"}>
                    <AppIconThumbnail src={imageFullUrl} size={"32px"}/>
                    <h5 className="my-0 ml-2">{this.props.negozio.nome}</h5>
                </div>
                <div className={"mb-3"}>
                    <div className={"d-flex align-items-center"} onClick={this.toggleCommercial}>
                        <div className={"mr-2"}>
                            <b>Ricevi comunicazioni commerciali</b>
                            <div className={"small text-muted"}>
                                Scegli dove ricevere news, offerte ed eventi che questa attività ha da offrire
                            </div>
                        </div>
                        {this.state.showCommercial ?
                            <FaSolidIcon name={"chevron-up"}/> :
                            <FaSolidIcon name={"chevron-down"}/>}
                    </div>
                    {
                        this.state.showCommercial &&
                        <Fragment>
                            <ShopUserSettingsSwitch negozio={this.props.negozio} settingName={"notifiche"}
                                                    title={"Notifiche"}/>
                            <ShopUserSettingsSwitch negozio={this.props.negozio} settingName={"sms"}
                                                    title={"SMS"}/>
                            <ShopUserSettingsSwitch negozio={this.props.negozio} settingName={"email"}
                                                    title={"Email"}/>
                            <ShopUserSettingsSwitch negozio={this.props.negozio} settingName={"whatsapp"}
                                                    title={"Whatsapp"}/>
                        </Fragment>
                    }

                </div>
                <div>
                    <div className={"d-flex align-items-center"} onClick={this.toggleReminder}>
                        <div className={"mr-2"}>
                            <b>Ricevi conferme e promemoria</b>
                            <div className={"small text-muted"}>
                                Scegli dove ricevere conferme/promemoria di prenotazioni e ordini fatti presso
                                l'attività
                            </div>
                        </div>
                        {this.state.showReminder ?
                            <FaSolidIcon name={"chevron-up"}/> :
                            <FaSolidIcon name={"chevron-down"}/>}
                    </div>
                    {
                        this.state.showReminder &&
                        <Fragment>
                            <ShopUserSettingsSwitch negozio={this.props.negozio} settingName={"notifiche_reminder"}
                                                    title={"Notifiche"}/>
                            <ShopUserSettingsSwitch negozio={this.props.negozio} settingName={"sms_reminder"}
                                                    title={"SMS"}/>
                            <ShopUserSettingsSwitch negozio={this.props.negozio} settingName={"email_reminder"}
                                                    title={"Email"}/>
                            <ShopUserSettingsSwitch negozio={this.props.negozio} settingName={"whatsapp_reminder"}
                                                    title={"Whatsapp"}/>
                            <div className={"small text-muted"}>
                                <b>ATTENZIONE:</b> anche se selezioni alcune di queste opzioni potresti non ricevere gli
                                avvisi
                                in
                                base alle scelte dell'attività
                            </div>
                        </Fragment>
                    }
                </div>
                {/*<ShopUserSettingsSwitch negozio={negozio} settingName={"calendario"} title={"Sincronizza calendario"}/>*/}
            </div>
        )
    }

}
