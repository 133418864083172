import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {swalError, swalInput, swalLoading, swalSuccess} from "../../../../../../helpers/SweetAlertWrapper";
import {ModuloServiziAPI} from "../../../../../../api/ModuloServizi/ModuloServiziAPI";
import SafeHtmlContainer from "../../../../../../helpers/SafeHtmlContainer";
import {replacePhoneNumberLinks} from "../../../../../../helpers/StringHelpers";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";

export class NoteInterneView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleChange = _ => {
        swalInput('Modifica note interne', 'Note interne', 'textarea', null, true, true, this.props.note)
            .then(result => {
                if (result.value || result.value === '') {
                    swalLoading('Attendere prego...');
                    ModuloServiziAPI.Dashboard.setNote(this.props.idParent, result.value)
                        .then(message => {
                            swalSuccess(message);
                            this.props.onChange();
                        })
                        .catch(swalError);
                }
            });
    }


    render() {
        return (
            <Fragment>
                <div className={"card shadow-sm border-0 mb-3"}>
                    <div className={"card-body"}>
                        <h6 className="font-weight-bold text-purple">Note interne</h6>
                        <div>
                            <SafeHtmlContainer
                                html={replacePhoneNumberLinks(this.props.note || 'Nessuna nota interna')}/>
                        </div>
                        <button className={"btn btn-sm btn-link px-0"} onClick={this.handleChange}>
                            <FaSolidIcon name={"edit"}/> Modifica
                        </button>
                    </div>
                </div>
            </Fragment>
        )
    }

}

NoteInterneView.propTypes = {
    idParent: PropTypes.any.isRequired,
    note: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}