export const monthMap = {
    '1': 'Gen',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'Mag',
    '6': 'Giu',
    '7': 'Lug',
    '8': 'Ago',
    '9': 'Set',
    '10': 'Ott',
    '11': 'Nov',
    '12': 'Dic',
};

export const dateConfig = {
    'date': {
        format: 'DD',
        caption: 'Giorno',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Mese',
        step: 1,
    },
    'year': {
        format: 'YYYY',
        caption: 'Anno',
        step: 1,
    }
};

export const timepickerConfig = {
    'hour': {
        format: 'hh',
        caption: 'Ora',
        step: 1,
    },
    'minute': {
        format: 'mm',
        caption: 'Min',
        step: 5,
    },
}
