import React from "react";
import PropTypes from "prop-types";
import {SeatsMapType} from "../../../../../components/ModuloSeats/PropTypes/MapType";
import {SeatsBookType} from "../../../../../components/ModuloSeats/PropTypes/BookType";
import {LinkedBookType} from "../../../../../components/ModuloSeats/PropTypes/LinkedBookType";
import {UserBookInfoType} from "../../../../../components/ModuloSeats/PropTypes/UserBookInfoType";
import {PluralString} from "../../../../../helpers/UIUtility";
import {swalError, swalInput, swalLoading, swalSelect, swalSuccess} from "../../../../../helpers/SweetAlertWrapper";
import {ModuloHotelAPI} from "../../../../../api/ModuloHotel/ModuloHotelAPI";
import FuxEvents from "../../../../../lib/FuxFramework/FuxEvents";
import {UPDATE_USER_SEAT_BOOKS, USER_DISPUTE_REQUEST_EVT} from "../../../../../const/AppEventsCostants";
import {ModuloSeatsAPI} from "../../../../../api/ModuloSeats/ModuloSeatsAPI";
import moment from "moment";
import {DISPUTE_STATUS_OPEN} from "../../../../../const/DisputesCostants";
import {DisputesListView} from "../DisputesListView";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {SEAT_BOOK_STATUS_CONFIRMED} from "../../../../../components/ModuloSeats/Constants/BookStatuses";
import {SEAT_BOOK_STATUS_CANCELED} from "../../../../../components/ModuloSeats/BookingEngine/Constants/BookStatus";

export class CancellationView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleRefund = _ => {
        swalInput(
            "Motiva la tua richiesta",
            "Scrivi il motivo per cui stai annullando la prenotazione e richiedendo il rimborso",
            "textarea", null, false, true
        ).then(({value}) => {
            if (!value) return;
            swalLoading('Attendere prego...');
            ModuloSeatsAPI.Client.Books.cancelBook(this.props.linkedBook.book.book_id, value)
                .then(m => {
                    swalSuccess(m)
                        .then(_ => {
                            this.props.onUpdate();
                            FuxEvents.emit(USER_DISPUTE_REQUEST_EVT);
                        });
                }).catch(swalError);
        })
    }

    render() {
        if (this.props.linkedBook.book.status === SEAT_BOOK_STATUS_CANCELED) {
            return <div className={"my-2 text-danger"}>
                Prenotazione cancellata
            </div>
        }
        return (
            <React.Fragment>
                {
                    !this.props.dispute &&
                    <button className={"btn btn-link text-danger"} onClick={this.handleRefund}>
                        {this.props.hasPayments ? "Cancella e richiedi rimborso" : "Cancella prenotazione"}
                    </button>
                }
                {
                    this.props.dispute &&
                    <div className={"my-2"}>
                        <FaSolidIcon name={"exclamation-triangle"} className={"text-danger"}/> Richiesta di rimborso
                        inviata, riceverai maggiori informazioni tramite l'app e via email
                    </div>
                }
            </React.Fragment>
        )
    }

}

CancellationView.propTypes = {
    linkedBook: LinkedBookType,
    hasPayments: PropTypes.func,
    onUpdate: PropTypes.func,
    dispute: PropTypes.object
}