import React from "react";
import PropTypes from "prop-types";
import {getDescrizioneIva, getSubtotalOfItem} from "../ReceiptsUtils";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import KeypadInterface from "./KeypadInterface/KeypadInterface";

export default class ReceiptItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let item = this.props.item
        return (
            <div className={"list-group-item px-2 p-1"} key={this.props.key}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className={"w-75"}>
                        <small> {item.descrizione} (€{parseFloat(item.importo_unitario).toFixed(2)} x {item.quantita})</small> <br/>
                        <small className="text-muted receipt-text-small">IVA {getDescrizioneIva(item.codice_aliquota)}</small>
                    </div>
                    <div className={"align-items-center"}>
                        €{getSubtotalOfItem(item, false).toFixed(2)}
                        <span className={"text-danger ml-2"}
                               onClick={_ => this.props.deleteItem(this.props.key)}>
                            <FaSolidIcon name={"trash"}/>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}


ReceiptItem.propTypes = {
    item: PropTypes.object,
    key: PropTypes.number,
    deleteItem: PropTypes.func
}