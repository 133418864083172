import moment from "moment";
import {ECOMMERCE_ORDER_TYPE_DEFAULT} from "../../const/ModuloEcommerceCostants";

/* == ORDINI == */

const ORDINE_STATUS_ATTESA = 'ordine.in_attesa';
const ORDINE_STATUS_CONFERMATO = 'ordine.confermato';
const ORDINE_STATUS_RIFIUTATO_UTENTE = 'ordine.rifiutato_utente';
const ORDINE_STATUS_RIFIUTATO_ESERCENTE = 'ordine.rifiutato_esercente';
const ORDINE_STATUS_PROPOSTA_ESERCENTE = 'ordine.proposta_esercente';
const ORDINE_STATUS_ANNULLATO_UTENTE = 'ordine.annullato_utente';
const ORDINE_STATUS_COMPLETO = 'ordine.completo';
const ORDINE_STATUS_IN_ARRIVO = 'ordine.in_arrivo'; //Solo se tipo = consegna
const ORDINE_STATUS_PRONTO = 'ordine.pronto'; //Solo se tipo = asporto


//Verifica se per un dato ordine è possibile inserire una recensione
function showFeedbackAvailableForOrders (infoOrder) {
    var now = new Date();
    var nowMinus15days = moment(now).subtract(15, "days").format("YYYY-MM-DD HH:mm")
    if(infoOrder.tipo_ordine === ECOMMERCE_ORDER_TYPE_DEFAULT){
        //Se ordine è completo e sono passati + 15 giorni dalla creazione
        return infoOrder.status === ORDINE_STATUS_COMPLETO &&
            nowMinus15days >= infoOrder.data_creazione.substring(0, infoOrder.data_creazione.length - 2);
    }
    if(
        infoOrder.status === ORDINE_STATUS_ATTESA || infoOrder.status === ORDINE_STATUS_ANNULLATO_UTENTE ||
        infoOrder.status === ORDINE_STATUS_RIFIUTATO_ESERCENTE || infoOrder.status === ORDINE_STATUS_PROPOSTA_ESERCENTE ||
        infoOrder.status === ORDINE_STATUS_RIFIUTATO_UTENTE
    ){
        return false;
    }
    var nowMinus4hours = moment(new Date()).subtract(4, "hours").format("YYYY-MM-DD HH:mm")
    var dataFineOrdine =infoOrder.data + " " +((infoOrder.orario_proposto) ? infoOrder.orario_proposto : infoOrder.orario);

    //Se sono passate più di 4 ore dalla fine dell'ordine
    return nowMinus4hours >= dataFineOrdine
}



/* == PRENOTAZIONI SERVIZI == */

function showFeedbackAvailableForService (infoService){
    var now = new Date();
    var nowMinus4hours = moment(now).subtract(4, "hours").format("YYYY-MM-DD HH:mm");
    var dataStart = moment(infoService["datetime_inizio"]).format("YYYY-MM-DD HH:mm")
    return nowMinus4hours > dataStart
}



/* == PRENOTAZIONI GRUPPI ==*/

const PRENOTAZIONE_GRUPPO_STATUS_ATTESA = 'prenotazione.in_attesa';
const PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA = 'prenotazione.confermata';
const PRENOTAZIONE_GRUPPO_STATUS_RIFIUTATA_ESERCENTE = 'prenotazione.rifiutata_esercente';


function showFeedbackAvailableForGroups (infoGroup){
    if(infoGroup.status === PRENOTAZIONE_GRUPPO_STATUS_ATTESA ||
        infoGroup.status === PRENOTAZIONE_GRUPPO_STATUS_RIFIUTATA_ESERCENTE
    ){
        return false;
    }
    var now = new Date();
    var nowMinus4hours = moment(now).subtract(4, "hours").format("YYYY-MM-DD HH:mm");
    var dataStart = moment(infoGroup.datetime_inizio).format("YYYY-MM-DD HH:mm")
    return nowMinus4hours > dataStart
}


/* == PRENOTAZIONI CAMERE ==*/
function showFeedbackAvailableForRooms (infoRoomBook){
    return  moment(new Date()).format("YYYY-MM-DD") > moment(infoRoomBook.data_checkout).format("YYYY-MM-DD")
}


/* == PRENOTAZIONI POSTI ==*/
function showFeedbackAvailableForSeats (fullBookInfo){
    const allBookedDates = Object.keys(fullBookInfo.dates).sort();
    return moment(new Date()).format("YYYY-MM-DD") > moment(allBookedDates.pop()).format("YYYY-MM-DD")
}



export {showFeedbackAvailableForOrders, showFeedbackAvailableForService, showFeedbackAvailableForGroups, showFeedbackAvailableForRooms, showFeedbackAvailableForSeats}