import React, {Fragment} from "react";
import styled from "styled-components";
import OrdiniAPI from "../../../api/OrdiniAPI";
import Page from "../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import {swalConfirm, swalError, swalInput, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import DOMPurify from "dompurify";
import AppConfig from "../../../config/AppConfig";
import moment from "moment";
import {BookizonAppManager} from "../../../index";
import {ECOMMERCE_ORDER_TYPE_RITIRO} from "../../../const/ModuloEcommerceCostants";
import {FOOD_ORDER_TYPE_ASPORTO, FOOD_ORDER_TYPE_CONSEGNA} from "../../../const/ModuloFoodCostants";
import ReorderController from "../../CronologiaUser/Reorder/ReorderController";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {UPDATE_USER_ORDINI, USER_DISPUTE_REQUEST_EVT} from "../../../const/AppEventsCostants";
import {addMinutes} from "date-fns";
import RecensioniModal from "../../Recensioni/RecensioniModal";
import {RECENSIONI_TIPO_ORDINE} from "../../../const/RecensioniConstants";
import {showFeedbackAvailableForOrders} from "../../Recensioni/RecensioniHelperFunctions";
import {formatCurrency} from "../../../helpers/CurrencyHelpers";
import {ModuloFoodAPI} from "../../../api/ModuloFood/ModuloFoodAPI";
import AuthAPI from "../../../api/AuthAPI";


const Modifier = styled.div`
    display:inline;
    & + &:before{
        content:", ";
        display:inline;
    }
`;

const OrderAppThumbnail = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #cdcdcd;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:url(${props => props.imageUrl});
    display:block;
`;

const ORDINE_STATUS_ATTESA = 'ordine.in_attesa';
const ORDINE_STATUS_CONFERMATO = 'ordine.confermato';
const ORDINE_STATUS_RIFIUTATO_UTENTE = 'ordine.rifiutato_utente';
const ORDINE_STATUS_RIFIUTATO_ESERCENTE = 'ordine.rifiutato_esercente';
const ORDINE_STATUS_PROPOSTA_ESERCENTE = 'ordine.proposta_esercente';
const ORDINE_STATUS_ANNULLATO_UTENTE = 'ordine.annullato_utente';
const ORDINE_STATUS_COMPLETO = 'ordine.completo';
const ORDINE_STATUS_IN_ARRIVO = 'ordine.in_arrivo'; //Solo se tipo = consegna
const ORDINE_STATUS_PRONTO = 'ordine.pronto'; //Solo se tipo = asporto

const STATO_PRODOTTO_CONFERMATO = 'confermato';
const STATO_PRODOTTO_IN_LAVORAZIONE = 'in_lavorazione';
const STATO_PRODOTTO_SPEDITO = 'spedito';
const STATO_PRODOTTO_COMPLETATO = 'completato';


function IndirizzoHTML(props) {
    return (
        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.html)}}/>
    )
}

export default class OrdineResumePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ordine: null,
            reorderControllerOpen: false
        }
    }

    componentDidMount() {
        OrdiniAPI.getData(this.props.id_ordine)
            .then(ordine => {
                this.setState({ordine: ordine});
            }).catch(swalError);
    }

    getStatusLabel = _ => {
        switch (this.state.ordine.status) {
            case ORDINE_STATUS_ATTESA:
                return <span className={"badge badge-warning"}>In attesa di conferma</span>;
            case ORDINE_STATUS_COMPLETO:
                return <span className={"badge badge-success"}>Completato</span>;
            case ORDINE_STATUS_CONFERMATO:
                return <span className={"badge badge-primary"}>Confermato, in preparazione</span>;
            case ORDINE_STATUS_IN_ARRIVO:
                return this.isPreventivo() ? <span className={"badge badge-info"}>Inviato, in attesa</span> :
                    <span className={"badge badge-info"}>In arrivo</span>;
            case ORDINE_STATUS_PRONTO:
                return <span className={"badge badge-info"}>Pronto per il ritiro</span>;
            case ORDINE_STATUS_PROPOSTA_ESERCENTE:
                return <span className={"badge badge-warning"}>In attesa di TUA conferma</span>;
            case ORDINE_STATUS_RIFIUTATO_ESERCENTE:
                return <span className={"badge badge-danger"}>Rifiutato</span>;
            case ORDINE_STATUS_RIFIUTATO_UTENTE:
                return <span className={"badge badge-danger"}>Rifiutato da te</span>;
            case ORDINE_STATUS_ANNULLATO_UTENTE:
                return <span className={"badge badge-danger"}>Annullato da te</span>;
        }
    }


    getProductStatusLabel = status => {
        switch (status) {
            case STATO_PRODOTTO_COMPLETATO:
                return <span className={"badge badge-success"}>Completato</span>;
            case STATO_PRODOTTO_CONFERMATO:
                return <span className={"badge badge-success"}>Confermato</span>;
            case STATO_PRODOTTO_SPEDITO:
                return <span className={"badge badge-info"}>Spedito</span>;
            case STATO_PRODOTTO_IN_LAVORAZIONE:
                return <span className={"badge badge-info"}>In lavorazione</span>;
        }
    }

    isPreventivo = _ => this.state.ordine && this.state.ordine.preventivo === '1';

    getPageTitle = _ => {
        if (!this.state.ordine) {
            return `#${this.props.id_ordine}`
        }
        return `${this.isPreventivo() ? 'Preventivo' : 'Ordine'} #${this.props.id_ordine}`;
    }

    //Restituisce il costo della spedizione sommato a quello dei prodotti
    getSubtotal = _ => {
        return parseFloat(this.state.ordine.totaleProdotti) + parseFloat(this.state.ordine.prezzo_spedizione);
    }

    //Restituisce il valore in euro del coupon applicato all'ordine
    getCouponRealValue = _ => {
        if (!this.state.ordine.dati_coupon) return 0;

        if (this.state.ordine.dati_coupon.tipo_sconto === 'euro') {
            return parseFloat(this.state.ordine.dati_coupon.valore_sconto);
        } else {
            let factorSconto = (parseFloat(this.state.ordine.dati_coupon.valore_sconto) / 100);
            return parseFloat(this.state.ordine.totaleProdotti) * factorSconto;
        }
    }

    //Restituisce il totale REALE dell'ordine ovverto totale prodotti + costi consegna - eventuale sconto
    getFinalTotal = _ => {
        return this.getSubtotal() - this.getCouponRealValue();
    }


    handleReorderToggle = _ => {
        this.setState({reorderControllerOpen: !this.state.reorderControllerOpen});
    };

    handleReorderDismiss = _ => {
        this.setState({reorderControllerOpen: false}, _ => {
            if (this.props.onCronologiaDismiss) this.props.onCronologiaDismiss();
            if (this.props.onDismiss) this.props.onDismiss();
        });
    }



    getAddressMapImageUrl = indirizzo => {
        var queryString = encodeURIComponent(`${indirizzo.indirizzo} ${indirizzo.civico},${indirizzo.citta},${indirizzo.cap},${indirizzo.civico}`);
        var imageDimension = `150x150`;
        return `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAsRTtIb0cMy_bpR5RbavHoNKvIkGPlHpg&size=${imageDimension}&center=${queryString}&zoom=17`;
    }

    getGPSLink(indirizzo) {
        var mapLink = 'https://maps.google.com/maps?q=';
        if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
            mapLink = 'http://maps.apple.com/?q=';
        }
        var queryString = (`${indirizzo.indirizzo} ${indirizzo.civico},${indirizzo.nome_citta},${indirizzo.nome_provincia}`);
        return `${mapLink}${queryString}`;
    }


    handleCancelOrder = _ => {
        swalConfirm('Sei sicuro di voler annullare questo ordine? Il tuo ordine verrà cancellato definitivamente.')
            .then(_ => {
                swalLoading();
                OrdiniAPI.cancelOrderByUser(this.props.id_ordine)
                    .then(swalSuccess)
                    .then(_ => FuxEvents.emit(UPDATE_USER_ORDINI))
                    .then(this.props.onDismiss)
                    .catch(swalError);
            });
    }

    handleRefund = _ => {
        swalInput(
            "Motiva la tua richiesta",
            "Scrivi il motivo per cui stai annullando l'ordine e richiedendo il rimborso",
            "textarea", null, false, true
        ).then(({value}) => {
            if (!value) return;
            swalLoading('Attendere prego...');
            ModuloFoodAPI.Orders.requestRefund(this.props.id_ordine, value)
                .then(m => {
                    swalSuccess(m)
                        .then(_ => {
                            FuxEvents.emit(USER_DISPUTE_REQUEST_EVT);
                        });
                }).catch(swalError)
        })
    }

    canAskRefund = _ => {
        if (!this.state.ordine.pagamenti) return false
        if (!this.state.ordine.pagamenti.length) return false

        //Se becca paypal non può fare il rimborso
        if (this.state.ordine.pagamenti.map(pagamento => pagamento.metodo_pagamento).includes('paypal')) return false
    }


    //Verifica se sono passati almeno 10 minuti da quando l'ordine è stato creato
    orderCanBeCancelled = _ => {
        var now = new Date();
        var dataCreazione = this.state.ordine.data_creazione;
        dataCreazione = new Date(dataCreazione.replace(' ', 'T'));
        return (addMinutes(dataCreazione, 10) <= now)
    }

    stampaMetodoPagamento = metodo_pagamento => {
        if (metodo_pagamento === "stripe") {
            return "Carta di credito";
        }
        return metodo_pagamento.charAt(0).toUpperCase() + metodo_pagamento.slice(1).replaceAll("_", " ");
    }


    render() {

        return (
            <Fragment>
                <Page
                    title={this.getPageTitle()}
                    active={true}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                    onLeftIconClick={this.props.onDismiss}
                >
                    {
                        this.state.ordine ?
                            <div className={"container pt-2"}>
                                <div className={"text-center"}>
                                    <OrderAppThumbnail
                                        className={"mx-auto"}
                                        imageUrl={`${AppConfig.webServerUrl}/api/negozio/${this.state.ordine.id_negozio}/logoIcon/100`}/>
                                    <h4 className={"text-center"}>{this.state.ordine.nome_negozio}</h4>
                                </div>

                                {
                                    this.state.ordine.orario &&
                                    <p className={"text-center"}>

                                        {
                                            (this.state.ordine.tipo_ordine === ECOMMERCE_ORDER_TYPE_RITIRO || this.state.ordine.tipo_ordine === FOOD_ORDER_TYPE_ASPORTO) ?
                                                <span className={"badge badge-info mr-1"}>Ritiro in negozio</span>
                                                :
                                                <span className={"badge badge-primary mr-1"}>Consegna</span>
                                        }

                                        {
                                            this.state.ordine.orario.search("-") !== -1 ?
                                                <Fragment>
                                                    {
                                                        moment(`${this.state.ordine.data}`).calendar(null, {
                                                            sameDay: '[oggi]',
                                                            nextDay: '[domani]',
                                                            nextWeek: 'dddd DD',
                                                            lastDay: '[ieri]',
                                                            lastWeek: '[lo scorso] dddd',
                                                            sameElse: 'dddd D MMM'
                                                        })
                                                    }
                                                    &nbsp;alle&nbsp;
                                                    {this.state.ordine.orario}
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    {
                                                        moment(`${this.state.ordine.data} ${this.state.ordine.orario}`).calendar(null, {
                                                            sameDay: '[oggi alle] HH:mm',
                                                            nextDay: '[domani alle] HH:mm',
                                                            nextWeek: 'dddd DD [alle] HH:mm',
                                                            lastDay: '[ieri alle] HH:mm',
                                                            lastWeek: '[lo scorso] dddd [alle] HH:mm',
                                                            sameElse: 'dddd D MMM [alle] HH:mm'
                                                        })
                                                    }
                                                </Fragment>
                                        }
                                    </p>
                                }
                                <p className={"text-muted text-center"}>
                                    {
                                        this.isPreventivo() ? 'Preventivo richiesto ' : 'Ordine effettuato '
                                    }
                                    {
                                        moment(this.state.ordine.data_creazione).calendar(null, {
                                            sameDay: '[oggi alle] HH:mm',
                                            nextDay: '[domani alle] HH:mm',
                                            nextWeek: 'dddd DD [alle] HH:mm',
                                            lastDay: '[ieri alle] HH:mm',
                                            lastWeek: '[lo scorso] dddd [alle] HH:mm',
                                            sameElse: 'dddd D MMM [alle] HH:mm'
                                        })
                                    }
                                </p>
                                <p>
                                    Stato: {this.getStatusLabel()}
                                </p>

                                {
                                    showFeedbackAvailableForOrders(this.state.ordine) &&
                                    <div className={"mb-2"}>
                                        <RecensioniModal
                                            id_operazione={this.state.ordine.id_ordine}
                                            tipo_operazione={RECENSIONI_TIPO_ORDINE}
                                        />
                                    </div>
                                }

                                {
                                    this.state.ordine.codice_tracking ?
                                        <p>
                                            <b>Codice tracciabilità:</b> {this.state.ordine.codice_tracking}
                                        </p> : ''
                                }
                                {
                                    this.state.ordine.link_tracking ?
                                        <p>
                                            <b>Link tracciabilità:</b>
                                            <button className={"btn btn-link"}
                                                    onClick={_ => BookizonAppManager.openInAppBrowser(this.state.ordine.link_tracking)}>
                                                Clicca qui
                                            </button>
                                        </p> : ''
                                }

                                {/*Indirizzo spedizione*/}

                                {
                                    !this.isPreventivo() &&
                                    <Fragment>
                                        {
                                            this.state.ordine.tipo_ordine === 'consegna' &&
                                            <div>
                                                <b>Indirizzo consegna:</b>
                                                <div className={"card shadow-sm border-0 my-3"}>
                                                    <div className={"card-body"}>
                                                        <div className={"row align-items-center"}>
                                                            <div className={"col"}>
                                                                <IndirizzoHTML
                                                                    html={this.state.ordine.indirizzo_spedizione}/>
                                                            </div>
                                                            {
                                                                !!this.state.ordine.indirizzo_spedizione_data &&
                                                                <div className={"col-4"}>
                                                                    <a href={this.getGPSLink(this.state.ordine.indirizzo_spedizione_data)}
                                                                       target={'_blank'}>
                                                                        <img
                                                                            src={this.getAddressMapImageUrl(this.state.ordine.indirizzo_spedizione_data)}
                                                                            className={"img-fluid border"}
                                                                            alt={"Mappa indirizzo"}/>
                                                                    </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.ordine.tipo_ordine === 'ecommerce' &&
                                            <div>
                                                <b>Indirizzo consegna:</b>
                                                <div className={"card shadow-sm border-0 my-3"}>
                                                    <div className={"card-body"}>
                                                        {
                                                            !!this.state.ordine.id_indirizzo_spedizione &&
                                                            <div className={"row align-items-center"}>
                                                                <div className={"col"}>
                                                                    <IndirizzoHTML
                                                                        html={this.state.ordine.indirizzo_spedizione}/>
                                                                </div>
                                                                {
                                                                    !!this.state.ordine.indirizzo_spedizione_data &&
                                                                    <div className={"col-4"}>
                                                                        <a href={this.getGPSLink(this.state.ordine.indirizzo_spedizione_data)}
                                                                           target={'_blank'}>
                                                                            <img
                                                                                src={this.getAddressMapImageUrl(this.state.ordine.indirizzo_spedizione_data)}
                                                                                className={"img-fluid border"}
                                                                                alt={"Mappa indirizzo"}/>
                                                                        </a>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            !!this.state.ordine.id_indirizzo_fatturazione &&
                                                            <div className={"my-3"}>
                                                                <b>Indirizzo fatturazione:</b>
                                                                <IndirizzoHTML
                                                                    html={this.state.ordine.indirizzo_fatturazione}/>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </Fragment>
                                }

                                <b>Prodotti:</b><br/>
                                {
                                    this.state.ordine.prodotti.map((p, i) => {
                                        return (
                                            <div key={i} className={"card shadow-sm border-0 my-2"}>
                                                <div className={"card-body"}>
                                                    {
                                                        !!p.stato_prodotto &&
                                                        <div>
                                                            {this.getProductStatusLabel(p.stato_prodotto)}
                                                        </div>
                                                    }
                                                    <b>{
                                                        p.um === 'pz' ? `${p.quantita}x` : `${p.quantita}${p.um}`
                                                    }</b> {p.nome_prodotto}<br/>
                                                    <div className={"small text-muted"}><SafeHtmlContainer
                                                        html={p.categoria_prodotto}/></div>
                                                    {
                                                        p.aggiunte.map((a, i) => {
                                                            return (
                                                                <Modifier key={i}>
                                                                    {a.type === 1 && <FaSolidIcon name={"plus-circle"}
                                                                                                  className={"text-success"}/>}
                                                                    {a.type === 0 && <FaSolidIcon name={"minus-circle"}
                                                                                                  className={"text-danger"}/>}
                                                                    {a.type === 2 && <FaSolidIcon name={"info-circle"}
                                                                                                  className={"text-info"}/>}
                                                                    {a.text}
                                                                </Modifier>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        p.variante && Object.keys(p.variante).map((attr, i) =>
                                                            <Modifier key={i}>{attr}: {p.variante[attr]}</Modifier>
                                                        )
                                                    }
                                                    {
                                                        p.note &&
                                                        <small><FaSolidIcon name={"file"}/> {p.note}</small>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <hr/>
                                <b>Note:</b> {this.state.ordine.note_utente || 'Nessuna'}

                                {/*Informazioni sul coupon - promozioni*/}
                                {
                                    this.state.ordine.dati_coupon &&
                                    <div className={"mt-3"}>
                                        {
                                            this.state.ordine.dati_coupon.id_promozione ?
                                                <div className={"p-3 bg-soft-success shadow-sm"}>
                                                    <span>
                                                        Hai utilizzato una <b>promozione</b> per questo ordine! &#x1F389;
                                                        <br/>
                                                        Il tuo prezzo viene <b>scontato del {this.state.ordine.dati_coupon.valore_sconto}%</b> <br/>
                                                        {
                                                            this.state.ordine.dati_coupon.min_spesa > 0 &&
                                                            <span>
                                                               Su una spesa minima di €{this.state.ordine.dati_coupon.min_spesa}
                                                            </span>
                                                        }
                                                    </span>
                                                </div>
                                                :
                                                <div>
                                                    <b>Info Coupon Inserito</b>
                                                    <div className={"card shadow-sm border-0 p-3"}>
                                                        <span><b>Codice:</b> {this.state.ordine.dati_coupon.codice}</span>
                                                        <span><b>Sconto:</b> {
                                                            this.state.ordine.dati_coupon.tipo_sconto === 'percentuale' ?
                                                                <span>-{this.state.ordine.dati_coupon.valore_sconto}%</span> :
                                                                <span>-€{this.state.ordine.dati_coupon.valore_sconto}</span>
                                                        }
                                                        </span>
                                                        {
                                                            this.state.ordine.dati_coupon.min_spesa > 0 &&
                                                            <span>
                                                                <b>Spesa minima:</b> €{this.state.ordine.dati_coupon.min_spesa}
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                }

                                {/*STAMPO METODI PAGAMENTO*/}
                                {
                                    this.state.ordine.pagamenti && this.state.ordine.pagamenti.length > 0 &&
                                    <div className={"my-3"}>
                                        <b>Metodi pagamento:</b>
                                        <div className={"card shadow-sm border-0 my-2"}>
                                            <div className={"card-body"}>
                                                {
                                                    this.state.ordine.pagamenti.map((p, i) => {
                                                        return (
                                                            <div key={i} className={"row"}>
                                                                <div className={"col-8"}>
                                                                    <h6>{this.stampaMetodoPagamento(p.metodo_pagamento)}</h6>
                                                                </div>
                                                                <div className={"col-4 text-right"}>
                                                                    <h6>€{formatCurrency(p.importo, 'EUR', 'it-IT')}</h6>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    !this.isPreventivo() &&
                                    <div className={"card shadow-sm border-0 my-3"}>
                                        <div className={"card-body"}>

                                            {/* Costi di spedizione */}
                                            {
                                                this.state.ordine.tipo_ordine === 'consegna' &&
                                                <div className={"row"}>
                                                    <div className={"col-8"}>
                                                        <h6>Consegna</h6>
                                                    </div>
                                                    <div className={"col-4 text-right"}>
                                                        <h6>€{parseFloat(this.state.ordine.prezzo_spedizione).toFixed(2)}</h6>
                                                    </div>
                                                </div>
                                            }

                                            {/* Totale dei prodotti */}
                                            <div className={"row"}>
                                                <div className={"col-8"}>
                                                    <h6>Totale prodotti</h6>
                                                </div>
                                                <div className={"col-4 text-right"}>
                                                    <h6>€{parseFloat(this.state.ordine.totaleProdotti).toFixed(2)}</h6>
                                                </div>
                                            </div>

                                            {/* Mostro subtotale se c'è uno sconto e se si tratta di consegna a domicilio */}
                                            {
                                                (this.state.ordine.tipo_ordine === 'consegna' && !!this.state.ordine.dati_coupon) &&
                                                <div className={"row"}>
                                                    <div className={"col-8"}>
                                                        <h6>Subtotale</h6>
                                                    </div>
                                                    <div className={"col-4 text-right"}>
                                                        <h6>€{this.getSubtotal().toFixed(2)}</h6>
                                                    </div>
                                                </div>
                                            }

                                            {/* Mostro lo sconto con il coupon se possibile */}
                                            {
                                                !!this.state.ordine.dati_coupon &&
                                                <div className={"row"}>
                                                    <div className={"col-8"}>
                                                        <h6>
                                                            Sconto
                                                            {
                                                                this.state.ordine.dati_coupon.tipo_sconto !== 'euro' &&
                                                                <span>&nbsp;{this.state.ordine.dati_coupon.valore_sconto}%</span>
                                                            }
                                                        </h6>
                                                    </div>
                                                    <div className={"col-4 text-right"}>
                                                        <h6 className={"text-success"}>€{this.getCouponRealValue().toFixed(2)}</h6>
                                                    </div>
                                                </div>
                                            }

                                            {/* Mostro il totale dell'ordine */}
                                            <div className={"row"}>
                                                <div className={"col-12"}>
                                                    <hr/>
                                                </div>
                                                <div className={"col-8"}>
                                                    <h5>Totale</h5>
                                                </div>
                                                <div className={"col-4 text-right"}>
                                                    <h5>€{this.getFinalTotal().toFixed(2)}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*Ordina di nuovo*/}
                                {
                                    this.state.ordine.status === ORDINE_STATUS_COMPLETO &&
                                    <div className={"row mt-2"}>
                                        <div className={"col"}>
                                            <button className={"btn btn-warning btn-block"}
                                                    onClick={this.handleReorderToggle}>
                                                <i className="fas fa-retweet"></i> Ordina di nuovo
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/*Annulla ordine*/}
                                {
                                    this.state.ordine.status === ORDINE_STATUS_ATTESA && (
                                        this.state.ordine.tipo_ordine === FOOD_ORDER_TYPE_ASPORTO ||
                                        this.state.ordine.tipo_ordine === FOOD_ORDER_TYPE_CONSEGNA
                                    ) && this.orderCanBeCancelled() &&
                                    <div className={"row mt-2"}>
                                        <div className={"col text-center"}>
                                            <small className={"text-info"}>
                                                <FaSolidIcon name={"info-circle"}/> Sono passati più di 10 min da quando
                                                hai inviato il tuo ordine. L'attività non lo ha
                                                ancora confermato, <b>contatta l'esercente</b> oppure <b>annulla il tuo
                                                ordine</b>
                                            </small>
                                            {
                                                this.canAskRefund() ?
                                                    <button className={"btn btn-danger btn-block mt-2"}
                                                            onClick={this.handleRefund}>
                                                        <i className="fas fa-trash"></i> Annulla e richiedi rimborso
                                                    </button> :
                                                    <button className={"btn btn-danger btn-block mt-2"}
                                                            onClick={this.handleCancelOrder}>
                                                        <i className="fas fa-trash"></i> Annulla ordine
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                }

                                {/*Allegati*/}
                                {
                                    this.state.ordine.files && this.state.ordine.files.length > 0 &&
                                    <div className={"my-3"}>
                                        <b>Allegati:</b>
                                        <div className={"card shadow-sm border-0 my-2"}>
                                            <div className={"card-body"}>
                                                {
                                                    this.state.ordine.files.map((file, i) => {
                                                        return (
                                                            <div key={i} className={"row"}>
                                                                <div className={"col-12"}>
                                                                    <h6>
                                                                        <a href={`${AppConfig.webServerUrl}/api/ordini/view-public-file?id_file=${file.id_file}&token=${AuthAPI.getUserAccessToken()}`}
                                                                           target={"_blank"}><i className={"fas fa-external-link"}/>
                                                                            {file.name}
                                                                        </a>
                                                                    </h6>
                                                                    {i < (this.state.ordine.files.length - 1) && <hr/>}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div> :
                            <div className={"text-center mt-3"}>
                                Recupero dati in corso...
                            </div>
                    }
                </Page>

                {
                    this.state.reorderControllerOpen &&

                    <ReorderController
                        order={this.state.ordine}
                        onDismiss={this.handleReorderDismiss}
                        onSuccess={this.props.onDismiss}
                        id_negozio={this.state.ordine.id_negozio}
                        fromNegozio={this.props.fromNegozio}
                    />
                }
            </Fragment>
        );
    }

}
