import React from 'react'
import AuthAPI from "../../api/AuthAPI";
import {swalError, swalLoading, swalSuccess} from '../../helpers/SweetAlertWrapper';
import ValidateOTPPage from "../ValidateOTPPage";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {FaSolidIcon} from "../FontAwesomeIcons";
import styled from "styled-components";
import {PhoneNumberInput} from "../BaseComponents/Inputs/PhoneNumberInput";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";
import {PageZLayerSelector} from "../BaseComponents/Page/PageZLayerSelector";
import i18next from "i18next";
import {OTP_PROVIDER_SMS, OTP_PROVIDER_WHATSAPP, OTP_PROVIDERS} from "./RegisterFormSteps/OtpProviderChoose";

export default class PasswordRecoveryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
            password2: '',
            otp_provider: 'sms',
            passwordVisible: false,
            isValidateOtpOpen: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    togglePassword = _ => this.setState({passwordVisible: !this.state.passwordVisible});

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.password !== this.state.password2) {
            swalError(i18next.t('password-recovery.errors.password-not-matching'));
            return;
        }

        swalLoading();
        AuthAPI.sendOTPWithCredentials(this.state.user, this.state.otp_provider)
            .then(response => {
                swalSuccess(response.message);
                this.setState({
                    id_utente: response.data.id_utente,
                    cellulare: response.data.cellulare,
                    isValidateOtpOpen: true
                });
            }).catch(swalError);
    }

    handleValidateOTP = otp => {
        AuthAPI.confirmPasswordWithOTP(this.state.id_utente, this.state.password, otp)
            .then(swalSuccess)
            .then(_ => this.setState({id_utente: 0, cellulare: 0, isValidateOtpOpen: false}))
            .then(this.props.PasswordRecoveryModalDismiss())
            .then(_ => FuxEvents.emit('homepageEvent', 'passwordRecoveryModal'))
            .catch(swalError);
    }

    handleResendOTP = provider => {
        swalLoading();
        this.setState({otp_provider: provider}, _ => {
            AuthAPI.resendOTP(this.state.id_utente, this.state.otp_provider)
                .then(swalSuccess)
                .catch(swalError);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'user') value = value.toLowerCase();
        this.setState({
            [name]: value,
        });
    }

    handlePhoneNumberChange = phone => this.setState({user: phone});

    handleOtpProviderChange = p => this.setState({otp_provider: p});

    render() {
        return (
            <React.Fragment>
                <div>
                    <form onSubmit={this.handleSubmit} className={"text-left"}>

                        <div className={"form-group text-muted text-center"}>
                            {i18next.t('password-recovery.headline')}
                        </div>
                        <div className={"form-group mb-2"}>
                            <PhoneNumberInput value={this.state.user} onChange={this.handlePhoneNumberChange}/>
                        </div>
                        <div className={"form-group mb-2"}>
                            <div className={"input-group"}>
                                <input type={this.state.passwordVisible ? 'text' : "password"}
                                       className={"form-control"}
                                       name="password" value={this.state.password}
                                       onChange={this.handleInputChange}
                                       placeholder={i18next.t("password-recovery.password-placeholder")}/>
                                <div className={"input-group-append"} onClick={this.togglePassword}>
                                <span className={"input-group-text"}>
                                    {this.state.passwordVisible ? <FaSolidIcon name={"eye-slash"}/> :
                                        <FaSolidIcon name={"eye"}/>}
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"input-group"}>
                                <input type={this.state.passwordVisible ? 'text' : "password"}
                                       className={"form-control"}
                                       name="password2" value={this.state.password2}
                                       onChange={this.handleInputChange}
                                       placeholder={i18next.t("password-recovery.password-confirm-placeholder")}/>
                                <div className={"input-group-append"} onClick={this.togglePassword}>
                                <span className={"input-group-text"}>
                                    {this.state.passwordVisible ? <FaSolidIcon name={"eye-slash"}/> :
                                        <FaSolidIcon name={"eye"}/>}
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <label>Scegli dove ricevere il codice di conferma</label>
                            <div className={"d-flex flex-wrap"}>
                                {
                                    OTP_PROVIDERS.map(p => <OtpItem provider={p}
                                                                    selected={this.state.otp_provider === p}
                                                                    onChange={this.handleOtpProviderChange}/>)
                                }
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <button className={"btn btn-soft-primary btn-block"}>
                                {i18next.t('password-recovery.submit-btn')}
                            </button>
                        </div>
                    </form>
                </div>
                <ModalPortalHOC>
                    <PageZLayerSelector zIndex={3001}>
                        {
                            this.state.isValidateOtpOpen ?
                                <ValidateOTPPage
                                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                                    onDismiss={_ => this.setState({isValidateOtpOpen: false})}
                                    resendOTP={this.handleResendOTP}
                                    onValidate={this.handleValidateOTP}
                                    message={
                                        <span>{i18next.t('password-recovery.confirm-password-otp-headline')} {this.state.cellulare}</span>
                                    }
                                /> : ''
                        }
                    </PageZLayerSelector>
                </ModalPortalHOC>
            </React.Fragment>
        )
    }
}


function OtpItem({provider, selected, onChange}) {
    const style = {
        cursor: 'pointer',
        border: selected ? '2px solid #293069' : '2px solid transparent'
    }

    const iconClass = {
        [OTP_PROVIDER_SMS]: 'fas fa-comment-sms text-blue mr-2',
        [OTP_PROVIDER_WHATSAPP]: 'fab fa-whatsapp text-success mr-2'
    }[provider];

    const handleClick = _ => onChange(provider);

    return <div className={"bg-main-z3 shadow-sm p-2 mb-2 mr-2 rounded"} style={style} onClick={handleClick}>
        <div className={"d-flex align-items-center justify-content-between"}>
            <div className={"d-flex align-items-center"}>
                <i className={iconClass}/> {i18next.t(`register.receive-${provider}`)}
            </div>
        </div>
    </div>

}