import React from "react";

function shadeColor(color, percent) {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export const PromotionsOrder = props => {
    const lightColor = shadeColor(props.color, +80);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={props.className} data-name="Layer 1" width="649.3779"
             height="727.77798" viewBox="0 0 649.3779 727.77798">
            <path
                d="M489.51239,184.95881a12.33393,12.33393,0,0,1-7.26937,15.6973,12.11776,12.11776,0,0,1-2.06454.56907l-7.0011,42.86807-16.42309-15.16382,9.16618-38.38348a12.378,12.378,0,0,1,10.84307-13.582,12.052,12.052,0,0,1,12.7488,7.9948Z"
                transform="translate(-177.9814 -176.88497)" fill="#ffb6b6"/>
            <path d="M368.31011,282.093s12.74635-16.8413,59.00006-16.38l26.25371-9.53871,6-55,21,5-3,68-45,26-59,12Z"
                  transform="translate(-177.9814 -176.88497)" fill="#e6e7e8"/>
            <polygon points="119.255 519.834 131.372 524.997 157.055 480.719 139.172 473.099 119.255 519.834"
                     fill="#ffb6b6"/>
            <polygon points="219.383 531.3 232.553 531.299 238.819 480.497 219.38 480.498 219.383 531.3"
                     fill="#ffb6b6"/>
            <path
                d="M396.90059,721.92434l40.50158-.00152v-.51221a15.76521,15.76521,0,0,0-15.76434-15.7641h-.001l-7.39813-5.61261-13.80328,5.61347-3.53553.00012Z"
                transform="translate(-177.9814 -176.88497)" fill="#2f2e41"/>
            <path
                d="M289.86929,705.51428l37.25941,15.87813.20082-.47119a15.76522,15.76522,0,0,0-8.3215-20.6827l-.0009-.00038-4.60525-8.06384-14.899-.24786-3.2525-1.38606Z"
                transform="translate(-177.9814 -176.88497)" fill="#2f2e41"/>
            <path
                d="M410.28389,407.52157l20.01672,121.8841-5.44751,18.16284,7.14826,12.86359L421.504,673.40231l4.26111,9.38123L413.85957,690.126h-24.226l-1.28571-102.27282-5.98425-15.01587v-8.27558l-12.18219-62.36358-2.243,71.33618-3.54358,15.03937,2.97656,10.70813-3.88928,7.50195L330.151,678.3544H302.98567l2.101-16.18549h0c-4.86706-2.46417,7.30734-9.66618,8.4772-14.99461l-4-9,4-17,3.66763-33.21843-3.86785-70.11856,1.82-94.42334L346.962,399.11349Z"
                transform="translate(-177.9814 -176.88497)" fill="#2f2e41"/>
            <circle id="b6492e21-528b-49f6-adbd-cfab639933c4" data-name="ab6171fa-7d69-4734-b81c-8dff60f9761b"
                    cx="183.5563" cy="42.67018" r="27.45257" fill="#ffb6b6"/>
            <path
                d="M315.07368,427.70741a.52606.52606,0,0,1-.14405-.01954c-.50683-.1416-.90722-.25293.92188-16.06884l1.97412-12.50147L315.89838,388.079l-3.2417-3.2417,5.21-5.20947-3.19288-23.62891-8.67919-45.84326a36.57225,36.57225,0,0,1,20.08447-39.73633L343.166,254.57069l31.44238-.23535,10.05957,13.07714L411.2812,276.453l-4.4126,91.29346,3.09521,7.22217-.26513.23974c-.02051.01856-2.08252,1.8833-3.00879,2.90869-.44873.63721.39306,5.396,1.43261,9.43653l1.333,9.97412-3.35937,2.40088,4.36767,5.15918,1.26465,9.48388-.71338-.17724c-.80371-.2002-80.4873-19.583-95.39111,12.896A.60141.60141,0,0,1,315.07368,427.70741Z"
                transform="translate(-177.9814 -176.88497)" fill="#e6e7e8"/>
            <path d="M354.51479,245.6635q-.17592-.38733-.35087-.77733c.045.00144.08945.00791.1344.00908Z"
                  transform="translate(-177.9814 -176.88497)" fill="#2f2e41"/>
            <path
                d="M333.32169,193.351a7.6409,7.6409,0,0,1,4.78651-2.06946c1.77516.05721,3.57535,1.66053,3.20756,3.39817a28.211,28.211,0,0,1,33.95017-12.73041c4.41211,1.55663,8.73888,4.67081,9.75239,9.23835a8.32041,8.32041,0,0,0,1.04813,3.41026,3.893,3.893,0,0,0,4.14333,1.04969l.04356-.01284a1.29709,1.29709,0,0,1,1.56729,1.831l-1.24839,2.32827a10.00412,10.00412,0,0,0,4.76622-.10137,1.296,1.296,0,0,1,1.37619,2.01677,22.58933,22.58933,0,0,1-18.01175,9.2583c-4.988-.03041-10.02736-1.7495-14.88181-.60245a12.9269,12.9269,0,0,0-8.69453,18.14673c-1.49131-1.63118-4.37416-1.245-5.89966.35435a8.09147,8.09147,0,0,0-1.76664,6.19126,28.72624,28.72624,0,0,0,2.94916,9.64245,28.82633,28.82633,0,0,1-17.08773-51.3491Z"
                transform="translate(-177.9814 -176.88497)" fill="#2f2e41"/>
            <path
                d="M496.38077,705.96426l-7.71-12.39-1.54,7.08007c-.27,1.24-.54,2.5-.79,3.75-2.18994-1.87011-4.52-3.60009-6.7998-5.26q-10.5-7.62012-20.99024-15.26l2.18994,12.69995c1.3501,7.82006,2.76026,15.80005,6.1001,22.95.37012.81006.77,1.61011,1.2002,2.39013h32.54a10.488,10.488,0,0,0,.54-2.24011.77469.77469,0,0,0,.00976-.15C501.641,714.68423,498.96085,710.11429,496.38077,705.96426Z"
                transform="translate(-177.9814 -176.88497)" fill="#f0f0f0"/>
            <path
                d="M229.1511,705.96426l7.71-12.39,1.54,7.08007c.27,1.24.54,2.5.79,3.75,2.18994-1.87011,4.52-3.60009,6.79981-5.26q10.5-7.62012,20.99023-15.26l-2.18994,12.69995c-1.3501,7.82006-2.76025,15.80005-6.1001,22.95-.37012.81006-.77,1.61011-1.20019,2.39013h-32.54a10.48736,10.48736,0,0,1-.54-2.24011.77391.77391,0,0,1-.00977-.15C223.89085,714.68423,226.571,710.11429,229.1511,705.96426Z"
                transform="translate(-177.9814 -176.88497)" fill="#f0f0f0"/>
            <path d="M570.92209,723.115h-391.75a1.19069,1.19069,0,1,1,0-2.38137h391.75a1.19068,1.19068,0,0,1,0,2.38137Z"
                  transform="translate(-177.9814 -176.88497)" fill="#cacaca"/>
            <path
                d="M735.03531,215.9H616.21243a4.62947,4.62947,0,0,1,0-9.25893H735.03531C741.00554,206.55771,741.06526,215.98406,735.03531,215.9Z"
                transform="translate(-177.9814 -176.88497)" fill="#e4e4e4"/>
            <path
                d="M895.10307,240.1166H616.21243a4.62947,4.62947,0,0,1,0-9.25893H895.10307C901.0733,230.77434,901.133,240.20069,895.10307,240.1166Z"
                transform="translate(-177.9814 -176.88497)" fill="#e4e4e4"/>
            <circle cx="814.27801" cy="46.49385" r="29.75918" fill="#6c63ff"/>
            <path
                d="M988.19964,238.8671a3.30935,3.30935,0,0,1-2.648-1.32439l-8.11876-10.8252a3.31022,3.31022,0,1,1,5.29648-3.97209L988.041,229.827l13.64209-20.46285a3.31036,3.31036,0,1,1,5.50877,3.67252L990.9543,237.39292a3.31173,3.31173,0,0,1-2.66252,1.4731C988.26107,238.86656,988.23036,238.8671,988.19964,238.8671Z"
                transform="translate(-177.9814 -176.88497)" fill="#fff"/>
            <path
                d="M556.61072,195.47559h-75.368a4.44637,4.44637,0,0,0-4.44134,4.44135v46.92377a4.4464,4.4464,0,0,0,4.44134,4.44134h75.368a4.44638,4.44638,0,0,0,4.44141-4.44134V199.91694A4.44633,4.44633,0,0,0,556.61072,195.47559Zm1.45752,33.70594A18.25791,18.25791,0,0,1,540.11781,247.368c-7.64747.09955,4.23126.13837-4.99213.13837-21.09088,0-43.08283-.20154-51.28827-.33222a4.1376,4.1376,0,0,1-4.05225-4.12073v-43.133a1.46245,1.46245,0,0,1,1.45722-1.46082h75.36529a1.46228,1.46228,0,0,1,1.46057,1.45691Z"
                transform="translate(-177.9814 -176.88497)" fill="#cacaca"/>
            <path
                d="M546.66835,225.42411H489.24583a2.468,2.468,0,0,1-2.32-1.636L478.05736,198.907a2.463,2.463,0,0,1,2.31649-3.28988l77.013-.13668h.00343a2.463,2.463,0,0,1,2.26374,3.43311l-.13428-.05748.13428.05748L548.9321,223.93138A2.46,2.46,0,0,1,546.66835,225.42411Z"
                transform="translate(-177.9814 -176.88497)" fill="#cacaca"/>
            <circle cx="340.90205" cy="48.53914" r="6.13579" fill="#3f3d56"/>
            <path
                d="M735.03531,347.91722H616.21243a4.62947,4.62947,0,0,1,0-9.25893H735.03531C741.00554,338.575,741.06526,348.00131,735.03531,347.91722Z"
                transform="translate(-177.9814 -176.88497)" fill="#e4e4e4"/>
            <path
                d="M895.10307,372.13385H616.21243a4.62946,4.62946,0,0,1,0-9.25893H895.10307C901.0733,362.79159,901.133,372.21793,895.10307,372.13385Z"
                transform="translate(-177.9814 -176.88497)" fill="#e4e4e4"/>
            <circle cx="814.27801" cy="178.5111" r="29.75918" fill="#6c63ff"/>
            <path
                d="M988.19964,370.88434a3.30932,3.30932,0,0,1-2.648-1.32439l-8.11876-10.82519a3.31022,3.31022,0,1,1,5.29648-3.97209l5.31157,7.08155,13.64209-20.46285a3.31036,3.31036,0,1,1,5.50877,3.67251L990.9543,369.41016a3.31172,3.31172,0,0,1-2.66252,1.47311C988.26107,370.8838,988.23036,370.88434,988.19964,370.88434Z"
                transform="translate(-177.9814 -176.88497)" fill="#fff"/>
            <path
                d="M556.61072,327.49281h-75.368a4.44639,4.44639,0,0,0-4.44134,4.44134V378.858a4.44643,4.44643,0,0,0,4.44134,4.44134h75.368a4.44641,4.44641,0,0,0,4.44141-4.44134V331.93415A4.44636,4.44636,0,0,0,556.61072,327.49281Zm1.45752,33.706a18.25786,18.25786,0,0,1-17.95043,18.1864c-7.64747.09955,4.23126.13837-4.99213.13837-21.09088,0-43.08283-.20154-51.28827-.33222a4.1375,4.1375,0,0,1-4.05225-4.12066v-43.133a1.46239,1.46239,0,0,1,1.45722-1.46081h75.36529a1.46227,1.46227,0,0,1,1.46057,1.4569Z"
                transform="translate(-177.9814 -176.88497)" fill="#cacaca"/>
            <path
                d="M546.66835,357.44135H489.24583a2.46794,2.46794,0,0,1-2.32-1.63595l-8.86851-24.88113a2.463,2.463,0,0,1,2.31649-3.28989l77.013-.13667h.00343a2.463,2.463,0,0,1,2.26374,3.43311l-.13428-.05749.13428.05749L548.9321,355.94863A2.46,2.46,0,0,1,546.66835,357.44135Z"
                transform="translate(-177.9814 -176.88497)" fill="#cacaca"/>
            <circle cx="340.90205" cy="180.55639" r="6.13579" fill="#3f3d56"/>
            <path
                d="M735.03531,479.93447H616.21243a4.62947,4.62947,0,0,1,0-9.25893H735.03531C741.00554,470.59221,741.06526,480.01855,735.03531,479.93447Z"
                transform="translate(-177.9814 -176.88497)" fill="#e4e4e4"/>
            <path
                d="M895.10307,504.15109H616.21243a4.62946,4.62946,0,0,1,0-9.25892H895.10307C901.0733,494.80883,901.133,504.23518,895.10307,504.15109Z"
                transform="translate(-177.9814 -176.88497)" fill="#e4e4e4"/>
            <circle cx="814.27801" cy="310.52835" r="29.75918" fill="#6c63ff"/>
            <path
                d="M988.19964,502.90159a3.30935,3.30935,0,0,1-2.648-1.32439L977.43291,490.752a3.31022,3.31022,0,1,1,5.29648-3.9721l5.31157,7.08156,13.64209-20.46285a3.31036,3.31036,0,1,1,5.50877,3.67251L990.9543,501.42741a3.31171,3.31171,0,0,1-2.66252,1.4731C988.26107,502.90105,988.23036,502.90159,988.19964,502.90159Z"
                transform="translate(-177.9814 -176.88497)" fill="#fff"/>
            <path
                d="M556.61072,459.51008h-75.368a4.44636,4.44636,0,0,0-4.44134,4.44134v46.92377a4.44637,4.44637,0,0,0,4.44134,4.44135h75.368a4.44635,4.44635,0,0,0,4.44141-4.44135V463.95142A4.44636,4.44636,0,0,0,556.61072,459.51008Zm1.45752,33.70593a18.25791,18.25791,0,0,1-17.95043,18.18646c-7.64747.09955,4.23126.13837-4.99213.13837-21.09088,0-43.08283-.20154-51.28827-.33221a4.13756,4.13756,0,0,1-4.05225-4.12073v-43.133a1.46244,1.46244,0,0,1,1.45722-1.46081h75.36529a1.46233,1.46233,0,0,1,1.46057,1.457Z"
                transform="translate(-177.9814 -176.88497)" fill="#cacaca"/>
            <path
                d="M546.66835,489.4586H489.24583a2.46794,2.46794,0,0,1-2.32-1.636l-8.86851-24.88113a2.463,2.463,0,0,1,2.31649-3.28988l77.013-.13668h.00343a2.463,2.463,0,0,1,2.26374,3.43312l-.13428-.05749.13428.05749L548.9321,487.96588A2.46,2.46,0,0,1,546.66835,489.4586Z"
                transform="translate(-177.9814 -176.88497)" fill="#cacaca"/>
            <circle cx="340.90205" cy="312.57363" r="6.13579" fill="#3f3d56"/>
            <path
                d="M393.54534,430.37757a12.33393,12.33393,0,0,1-12.51763-11.93976,12.1177,12.1177,0,0,1,.12655-2.1378l-38.32559-20.441,19.647-10.66087,33.38254,21.04558a12.378,12.378,0,0,1,9.3636,14.64124,12.052,12.052,0,0,1-11.67642,9.49263Z"
                transform="translate(-177.9814 -176.88497)" fill="#ffb6b6"/>
            <path
                d="M376.54829,415.68641l-64.04688-25.002L302.31928,339.3578l3.78125-29.51123a30.2009,30.2009,0,0,1,34.62354-26l.21484.03369.12207.18017c.48877.72217,11.78613,18.17871-3.417,61.49121l.56152,27.52588,50.36475,23.51026Z"
                transform="translate(-177.9814 -176.88497)" fill="#e6e7e8"/>
        </svg>
    )
}