import React from "react";
import PropTypes from "prop-types";
import {formatCurrency} from "../../../../helpers/CurrencyHelpers";
import {BOOKIZON_PAY_METHODS, PAYMENT_METHOD_PAYPAL} from "../../../../const/PaymentMethodoCostants";


export function ServiceBookResumePaymentsView({pagamenti}) {

    const getPaymentMethodName = (paymentMethod) => {
        if (BOOKIZON_PAY_METHODS.indexOf(paymentMethod) > -1) return 'Bookizon pay';
        return {
            [PAYMENT_METHOD_PAYPAL]: 'PayPal',
        }[paymentMethod] || 'metodo di pagamento sconosciuto';
    }

    return pagamenti.map(p => <div>
        Hai già pagato&nbsp;
        <b>{formatCurrency(p.importo, 'EUR', 'it-IT')}</b>&nbsp;tramite {getPaymentMethodName(p.metodo_pagamento)}
    </div>);
}


ServiceBookResumePaymentsView.propTypes = {
    pagamenti: PropTypes.array
}
