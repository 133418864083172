import React from "react";

export const RelaxationIcon = props => {
    const color = props.color;
    return (

        <svg id="5d5c8886-ac11-4c34-803b-6feca69a11c2" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" width="655.21" height="773.33" viewBox="0 0 655.21 773.33"
             className={props.className} style={props.style}
        >
            <defs>
                <linearGradient id="33e7054d-00bd-45f9-a6f1-f82c47d3320a" x1="632.2" y1="425.85" x2="632.2" y2="110.31"
                                gradientTransform="matrix(0.88, -0.47, 0.47, 0.88, -319.42, 252.77)"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="gray" stopOpacity="0.25"/>
                    <stop offset="0.54" stopColor="gray" stopOpacity="0.12"/>
                    <stop offset="1" stopColor="gray" stopOpacity="0.1"/>
                </linearGradient>
                <linearGradient id="b6cfe92d-c41f-4e64-8195-7c698e819d96" x1="664.16" y1="377.34" x2="664.16" y2="60.09"
                                gradientTransform="matrix(0.88, -0.47, 0.47, 0.88, -47.02, 316.11)"
                                xlinkHref="#33e7054d-00bd-45f9-a6f1-f82c47d3320a"/>
                <linearGradient id="b5361056-ea40-4102-ac4c-52b758be124c" x1="493.33" y1="829.29" x2="493.33"
                                y2="337.18" gradientTransform="translate(-426.25 96.93) rotate(-18.16)"
                                xlinkHref="#33e7054d-00bd-45f9-a6f1-f82c47d3320a"/>
            </defs>
            <title>relaxation_1</title>
            <polygon points="346.31 377.42 327.56 375.67 381.28 0 401.45 2.17 346.31 377.42"
                     fill="url(#33e7054d-00bd-45f9-a6f1-f82c47d3320a)"/>
            <rect x="471.48" y="274.82" width="323.98" height="8.36" transform="translate(-9.61 800.24) rotate(-81.39)"
                  fill="#263238"/>
            <line x1="383.36" y1="56.78" x2="390.38" y2="6.99" fill="none" stroke="#263238" strokeMiterlimit="10"
                  strokeWidth="8"/>
            <path d="M916.65,224.85C620.35-27.9,367,163.34,367,163.34Z" transform="translate(-272.39 -63.33)"
                  fill="url(#b6cfe92d-c41f-4e64-8195-7c698e819d96)"/>
            <path d="M899,217.77c-275.88-235.17-511.93-57-511.93-57Z" transform="translate(-272.39 -63.33)"
                  fill={color}/>
            <polygon
                points="19.03 305.73 34.15 351.83 49.99 400.13 65.11 446.23 80.95 494.53 96.07 540.63 111.92 588.93 127.04 635.03 142.88 683.33 158 729.43 172.4 773.34 429.53 689 415.13 645.09 400.01 598.99 384.17 550.69 369.05 504.59 353.2 456.29 338.08 410.19 322.24 361.9 307.12 315.79 291.28 267.5 276.16 221.4 19.03 305.73"
                fill="url(#b5361056-ea40-4102-ac4c-52b758be124c)"/>
            <rect x="367.78" y="321.33" width="259.09" height="475.75"
                  transform="translate(-421.9 119.51) rotate(-18.16)" fill={color}/>
            <rect x="300.95" y="332.01" width="259.09" height="46.9" transform="translate(-361.73 88.54) rotate(-18.16)"
                  fill="#fff" opacity="0.5"/>
            <rect x="330.88" y="423.27" width="259.09" height="46.9"
                  transform="translate(-388.68 102.41) rotate(-18.16)" fill="#fff" opacity="0.5"/>
            <rect x="360.82" y="514.52" width="259.09" height="46.9"
                  transform="translate(-415.64 116.29) rotate(-18.16)" fill="#fff" opacity="0.5"/>
            <rect x="390.75" y="605.78" width="259.09" height="46.9"
                  transform="translate(-442.59 130.16) rotate(-18.16)" fill="#fff" opacity="0.5"/>
            <rect x="420.68" y="697.04" width="259.09" height="46.9"
                  transform="translate(-469.54 144.03) rotate(-18.16)" fill="#fff" opacity="0.5"/>
            <path d="M494.84,487.07s48.52-63,12.22-80.51-74.13-7.42-74.13-7.42l3.83,11.67s92.48,9.63,34.31,61.61Z"
                  transform="translate(-272.39 -63.33)" fill="#ffe0d6"/>
            <path d="M418.15,512.22s-76.42-22-57.53-57.63S416,404.71,416,404.71l3.83,11.67s-68.81,62.53,8.85,70Z"
                  transform="translate(-272.39 -63.33)" fill="#ffe0d6"/>
            <path
                d="M363.81,453.55c15.58-29.35,43.09-44.21,52.25-48.52l-.1-.32s-36.44,14.3-55.33,49.88,57.53,57.63,57.53,57.63l.73-1.8C406,506.26,347,485.29,363.81,453.55Z"
                transform="translate(-272.39 -63.33)" opacity="0.2"/>
            <path
                d="M503.88,407.61c-29.94-14.42-60.91-10.1-70.84-8.15l-.1-.32s37.84-10.06,74.13,7.42-12.22,80.51-12.22,80.51l-1.66-1C501.13,475,536.26,423.2,503.88,407.61Z"
                transform="translate(-272.39 -63.33)" opacity="0.2"/>
            <circle cx="430.71" cy="421.03" r="60.31" transform="translate(-382.16 91.87) rotate(-18.16)"
                    fill="#ff7185"/>
            <path
                d="M488.14,596.12h0C466.54,603.2,450,612.55,443,591l-23-70a41.44,41.44,0,0,1,26.38-52.14h0a41.44,41.44,0,0,1,52.14,26.38l23,70C528.57,586.79,509.73,589,488.14,596.12Z"
                transform="translate(-272.39 -63.33)" fill="#ff8976"/>
            <g opacity="0.2">
                <path d="M491.32,595.07h0l-1.59.53c5.33-1.7,10.45-3.15,15-4.7C500.55,592.23,496,593.54,491.32,595.07Z"
                      transform="translate(-272.39 -63.33)"/>
                <path
                    d="M446.14,589.91l-23-70A41.44,41.44,0,0,1,448,468.3c-.54.15-1.07.31-1.61.48h0A41.44,41.44,0,0,0,420,520.92l23,70c5.55,16.91,16.87,14.84,31.9,9.75C461.42,605,451.29,605.61,446.14,589.91Z"
                    transform="translate(-272.39 -63.33)"/>
            </g>
            <path
                d="M523.4,571s49.72,42.92,50,80.1a138.3,138.3,0,0,0,1.17,18.19l5.45,53-22.63,6.25-8.79-66.42a22.39,22.39,0,0,0-4.13-10.28L525.2,625.44a16.64,16.64,0,0,0-18.62-6h0a16.64,16.64,0,0,0-9.73,23.18l19.48,39.44,18,83.43L512,772.81s-18.76-64.37-34.51-94.46-32.09-81.74-32.09-81.74Z"
                transform="translate(-272.39 -63.33)" fill="#5caade"/>
            <rect x="437.44" y="452.03" width="17.87" height="33.5" rx="8" ry="8"
                  transform="translate(-396.26 99.13) rotate(-18.16)" fill="#ffe0d6"/>
            <path d="M434.79,462.15s8.79,3.26,16.76-5.44" transform="translate(-272.39 -63.33)" opacity="0.2"/>
            <circle cx="430.71" cy="421.03" r="41.32" transform="translate(-382.16 91.87) rotate(-18.16)"
                    fill="#ffe0d6"/>
            <path d="M392,392.59s3.73,32.86,73.47-1.77l-25.68-21-25.09,2.35Z" transform="translate(-272.39 -63.33)"
                  fill="#ff7185"/>
            <path
                d="M448.65,436.3c.77,2.34-3.49,7-9.93,9.13s-12.64.87-13.41-1.48,4.18-4.9,10.63-7S447.88,434,448.65,436.3Z"
                transform="translate(-272.39 -63.33)" fill="#fff"/>
            <rect x="428.33" y="426.28" width="8.93" height="2.23" rx="1" ry="1"
                  transform="translate(-384.04 92.84) rotate(-18.16)" opacity="0.1"/>
            <circle cx="409.19" cy="416.33" r="2.23" transform="translate(-381.77 84.93) rotate(-18.16)"
                    fill="#383f4d"/>
            <circle cx="445.27" cy="404.5" r="2.23" transform="translate(-376.28 95.58) rotate(-18.16)" fill="#383f4d"/>
            <path
                d="M396,415.47l3.07,9.37a4,4,0,0,0,5,2.56l15.74-5.16a4,4,0,0,0,2.56-5l-3.07-9.37a4,4,0,0,0-5-2.56l-15.74,5.16A4,4,0,0,0,396,415.47Zm21.24,5.92-11.5,3.77a4,4,0,0,1-5-2.56l-2.06-6.29a4,4,0,0,1,2.56-5l11.5-3.77a4,4,0,0,1,5,2.56l2.06,6.29A4,4,0,0,1,417.22,421.39Z"
                transform="translate(-272.39 -63.33)" fill="#e75f7e"/>
            <rect x="399.14" y="408.51" width="20.1" height="15.63" rx="3.58" ry="3.58"
                  transform="translate(-381.77 84.93) rotate(-18.16)" fill="#e75f7e" opacity="0.2"/>
            <path d="M420.28,413.14l-1-.58c.06-.09,6.77-9.54,15.59-5.08l-.5,1C426.5,404.48,420.49,412.8,420.28,413.14Z"
                  transform="translate(-272.39 -63.33)" fill="#e75f7e"/>
            <ellipse cx="469.97" cy="408.15" rx="3.35" ry="7.82" transform="translate(-305.98 -21.1) rotate(-4.97)"
                     fill="#ffe0d6"/>
            <ellipse cx="391.45" cy="433.9" rx="3.35" ry="7.82" transform="translate(-440.99 203.67) rotate(-31.35)"
                     fill="#ffe0d6"/>
            <path d="M393.7,391.06,392,392.59s3.73,32.86,73.47-1.77l-2.57-2.1C408.11,414.93,396.19,397.77,393.7,391.06Z"
                  transform="translate(-272.39 -63.33)" fill="#fff" opacity="0.2"/>
            <path
                d="M432.06,403.64l3.07,9.37a4,4,0,0,0,5,2.56l15.74-5.16a4,4,0,0,0,2.56-5L455.4,396a4,4,0,0,0-5-2.56l-15.74,5.16A4,4,0,0,0,432.06,403.64Zm21.24,5.92-11.5,3.77a4,4,0,0,1-5-2.56l-2.06-6.29a4,4,0,0,1,2.56-5l11.5-3.77a4,4,0,0,1,5,2.56l2.06,6.29A4,4,0,0,1,453.29,409.56Z"
                transform="translate(-272.39 -63.33)" fill="#e75f7e"/>
            <rect x="435.22" y="396.68" width="20.1" height="15.63" rx="3.58" ry="3.58"
                  transform="translate(-376.28 95.58) rotate(-18.16)" fill="#e75f7e" opacity="0.2"/>
            <g opacity="0.2">
                <path
                    d="M481.75,677c-15.75-30.09-32.09-81.74-32.09-81.74l-4.24,1.39s16.34,51.64,32.09,81.74S512,772.81,512,772.81l4.24-1.39S497.49,707.05,481.75,677Z"
                    transform="translate(-272.39 -63.33)"/>
            </g>
            <path d="M512,772.81,519,794l33.21-2.67s4.63-7.39-3.48-10.61-14.41-15.26-14.41-15.26Z"
                  transform="translate(-272.39 -63.33)" fill="snow"/>
            <path d="M556.75,722.88l-2.13,19.59,31.47,10.94s7.21-4.9,1.09-11.11-7-19.77-7-19.77Z"
                  transform="translate(-272.39 -63.33)" fill="snow"/>
            <path d="M514.13,772.12l-2.12.7L519,794l33.21-2.67s.13-.2.3-.55l-31.39,2.52Z"
                  transform="translate(-272.39 -63.33)" opacity="0.2"/>
            <ellipse cx="600" cy="470.63" rx="93.09" ry="328.02" transform="translate(-173.97 981.68) rotate(-86.98)"
                     opacity="0.1"/>
        </svg>
    )
}
