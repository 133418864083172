import AuthAPI from "../AuthAPI";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AppConfig from "../../config/AppConfig";

export const _GiftCards = {
    /**
     * @param cursor
     * @param id_parent
     */
    getCategories: function (cursor, id_parent=null){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            cursor: cursor,
            id_parent: id_parent
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/get-categories`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    /**
     */
    getBrands: function (cursor){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            cursor: cursor
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/get-brands`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    /**
     * @param cursor
     * @param id_categoria
     * @param id_brand
     * @param query
     */
    getProducts: function (cursor, id_categoria=null, id_brand = null, query = null){
        console.log(cursor)
        let params = {
            token: AuthAPI.getUserAccessToken(),
            cursor: cursor,
            id_categoria: id_categoria,
            id_brand: id_brand,
            query: query
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/get-products`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    /**
     * @param id_prodotto
     */
    getProductVariants: function (id_prodotto){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            id_prodotto: id_prodotto
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/get-product-variants`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    /**
     * @param id_variante
     * @param id_utente
     * @param indirizzo
     * @returns {Promise}
     */
    placeOrder: function (id_variante, indirizzo){
        let body = {
            token: AuthAPI.getUserAccessToken(),
            id_variante: id_variante,
            indirizzo: indirizzo
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/place-order`), body,
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        )
    },

    /**
     * @param id_ordine
     * @returns {Promise}
     */
    refundOrder: function (id_ordine){
        let body = {
            token: AuthAPI.getUserAccessToken(),
            id_ordine: id_ordine
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/refund-order`), body,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },

    /**
     * @param cursor
     * @returns {Promise}
     */
    getOrders: function (cursor){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            cursor: cursor
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/get-orders`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    /**
     * @param cursor
     * @returns {Promise}
     */
    getOrder: function (id_ordine){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            id_ordine: id_ordine
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/get-order`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    /**
     *
     * @param id_ordine
     * @param item_external_id
     * @param serial_number
     * @returns {Promise}
     */
    pinActivationPage: function (id_ordine, item_external_id, serial_number){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            id_ordine: id_ordine,
            item_external_id:item_external_id,
            serial_number: serial_number
        }
        return FuxHTTP.apiGetRequestWithPromise(
            AppConfig.webServerUrl+`/epipoli/pin-activation`, params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    },
    /**
     *
     * @param id_ordine
     * @param item_external_id
     * @param serial_number
     * @returns {Promise}
     */
    getActivatedCardInfo: function (id_ordine){
        let params = {
            token: AuthAPI.getUserAccessToken(),
            id_ordine: id_ordine
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/welfare/gift-cards/get-activated-card-info`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        )
    }
}