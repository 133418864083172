import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../FontAwesomeIcons";
import {FilesListViewItem} from "./Items/FilesListViewItem";


export function FileListView({files, onChange, ...props}) {
    const handleRemove = fileIdx => {
        if (fileIdx === -1) return onChange([]); //Clear all files
        onChange(files.filter((a, i) => i !== fileIdx));
    }

    const wrapperStyle = {
        maxHeight: 300,
        overflowY: 'auto'
    }

    return (
        <React.Fragment>
            <div className={"py-2"} style={wrapperStyle}>
                <div className={"list-group"}>
                    {
                        files.map((a, i) => {
                            return <div className={"list-group-item p-2"}>
                                <FilesListViewItem key={i} file={a} onRemove={handleRemove} index={i} disabled={props.disabled}/>
                            </div>
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

FileListView.propTypes = {
    onChange: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
}
