import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";


export const _Admin = {
    getDisputes: function (status) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            status: status,
        }
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/disputes/admin/get-disputes`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    acceptRefund: function (id_messaggio) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            id_messaggio: id_messaggio,
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/disputes/admin/accept-refund`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    rejectRefund: function (id_messaggio) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            id_messaggio: id_messaggio,
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/disputes/admin/reject-refund`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    sendRefundProposal: function (id_operazione, tipo_operazione, amount) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            id_operazione: id_operazione,
            tipo_operazione: tipo_operazione,
            amount: amount,
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/disputes/admin/send-refund-proposal`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};