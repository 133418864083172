import React from "react";
import PropTypes from "prop-types";
import {CalendarRecurringIcon} from "../../../../../img/illustrations/CalendarRecurringIcon";
import {NegozioOpenStore} from "../../../../../stores/NegozioOpenStore";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {RECURRING_TYPE_MONTHLY, RECURRING_TYPE_WEEKLY} from "./RecurringDatesChooserPanel";

const ImageWrapperStyle = {
    maxWidth: 200
}

/**
 * This component allow to choose the recurring type: monthly or weekly
 * */
export function RecurringTypeChooserView(props) {

    const handleChoose = t => _ => props.onChoose(t);

    return (
        <React.Fragment>

            <div style={ImageWrapperStyle} className={"mx-auto mb-3"}>
                <CalendarRecurringIcon
                    className={"img-fluid"}
                    color={NegozioOpenStore.infoNegozio ? NegozioOpenStore.infoNegozio.aspetto.primary_color : "#293069"}
                />
            </div>
            <h4 className={"font-weight-bold text-center"}>
                Prenotazione periodica
            </h4>
            <p className={"text-muted"}>
                Scegli con quale periodicità vuoi prenotare questo appuntamento, se ogni settimana o ogni
                mese
            </p>

            <button
                className={"btn btn-block btn-lg btn-light d-flex align-items-center justify-content-between"}
                onClick={handleChoose(RECURRING_TYPE_WEEKLY)}>
                            <span>
                                <FaSolidIcon name={"calendar-week"}/> Settimanale
                            </span>
                <FaSolidIcon name={"chevron-right"}/>
            </button>

            <button
                className={"btn btn-block btn-lg btn-light d-flex align-items-center justify-content-between"}
                onClick={handleChoose(RECURRING_TYPE_MONTHLY)}>
                            <span>
                                <FaSolidIcon name={"calendar-day"}/> Mensile
                            </span>
                <FaSolidIcon name={"chevron-right"}/>
            </button>

            <div className={"d-flex align-items-center justify-content-between mt-4"}>
                <button className={"btn btn-link text-on-bg-main"} onClick={props.onDismiss}>
                    Annulla
                </button>
            </div>
        </React.Fragment>
    )
}

RecurringTypeChooserView.propTypes = {
    onChoose: PropTypes.func,
    onDismiss: PropTypes.func
}
