import React, {Fragment} from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import AppConfig from "../../config/AppConfig";
import ProdottiFoodAPI from "../../api/ModuloFood/ProdottiFoodAPI";
import MenuDigitaleCardProdotto from "./MenuDigitaleCardProdotto";
import ProdottoDetail from "./ProdottoDetail";
import FlagDropdown from "../BaseComponents/FlagDropdown/FlagDropdown";
import i18n from "../../i18n";
import MenuCategoryGalleryPage from "./MenuCategoryGalleryPage";
import {MenuDigitaleSearchBar, SORT_ALPHA_ASC, SORT_PRICE_ASC, SORT_PRICE_DESC} from "./MenuDigitaleSearchBar";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";


export default class MenuDigitaleModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categorie: null,
            prodotti: null,
            prodotto_open: null,
            categoryNavigation: [],
            categoria: null,
            searchQuery: '',
            orderType: '',
            lang: i18n.language || 'it',

            categoryTree: [],
            categoryOpen: [],
            categoryLeaf: null,
        };
    }

    fetchNewData() {
        ProdottiFoodAPI.list(this.state.lang)
            .then((prodotti) => {
                this.setState({prodotti: prodotti.filter(p => p.abilita_menu === "1")});
            });
        ProdottiFoodAPI.listCategorie(this.state.lang)
            .then((categorie) => {
                this.setState({categoryTree: categorie});
            });
    }

    onLanguageChange = lang => {
        this.setState({lang: lang}, _ => {
            this.fetchNewData();
        });
    }

    componentDidMount() {
        if (this.props.active) {
            this.fetchNewData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.active && !prevProps.active) {
            this.fetchNewData();
        }
    }

    handleCategoryChoose = categoria => {
        if (!this.state.categoryOpen.find(c => c.id_categoria === categoria.id_categoria)) {
            this.setState({categoryOpen: [...this.state.categoryOpen, categoria]});
        }
    }

    handleCategoryDismiss = categoria => _ => {
        let categories = this.state.categoryOpen.slice();
        categories = categories.filter(c => c.id_categoria !== categoria.id_categoria);
        this.setState({categoryOpen: categories});
    }

    handleCategoryLeafChoose = categoria => this.setState({categoryLeaf: categoria});

    handleProdottiDismiss = _ => {
        this.setState({categoryLeaf: null, searchQuery: ''});
    }

    openDettaglio = prodotto => {
        this.setState({prodotto_open: prodotto});
    }

    closeDettaglio = _ => {
        this.setState({prodotto_open: null});
    }


    /* Search and Ordering management */
    handleSearch = (queryString, sortType) => {
        this.setState({searchQuery: queryString, orderType: sortType});
    }

    filterProductSearch = p => {
        const query = this.state.searchQuery.toLowerCase();
        if (!query) {
            return true;
        }
        return p.nome.toLowerCase().indexOf(query) !== -1;
    }

    filterProductCategory = p => {
        return p.id_categoria === this.state.categoryLeaf.id_categoria;
    }

    sortProducts = (p1, p2) => {
        switch (this.state.orderType) {
            case 'alpha_asc':
                return p1.nome < p2.nome ? -1 : 1;
            case 'alpha_desc':
                return p1.nome > p2.nome ? -1 : 1;
            case 'price_asc':
                return parseFloat(p1.prezzo) < parseFloat(p2.prezzo) ? -1 : 1;
            case 'price_desc':
                return parseFloat(p1.prezzo) > parseFloat(p2.prezzo) ? -1 : 1;
            default:
                return 0
        }
    }

    /**
     * @description Effettua una ricerca in tutte le categorie figlie di "id_categoria_parent" in base ai dati di ricerca
     * @return Array | Null */
    handleSearchInCategory = (queryString, sortType, childList) => {
        if (!queryString) {
            return null;
        }
        const idLeaf = this.getLeafChilds(childList);
        let prodotti = this.state.prodotti
            .filter(
                p => idLeaf.find(id_categoria => id_categoria === p.id_categoria)
            )
            .filter(p => {
                return p.nome.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
            })
            .sort((p1, p2) => {
                switch (sortType) {
                    case 'alpha_asc':
                        return p1.nome < p2.nome ? -1 : 1;
                    case 'alpha_desc':
                        return p1.nome > p2.nome ? -1 : 1;
                    case 'price_asc':
                        return parseFloat(p1.prezzo) < parseFloat(p2.prezzo) ? -1 : 1;
                    case 'price_desc':
                        return parseFloat(p1.prezzo) > parseFloat(p2.prezzo) ? -1 : 1;
                    default:
                        return 0
                }
            });
        return prodotti;
    }

    getLeafChilds = childList => {
        let idLeafs = [];
        for (let categoria of childList) {
            if (!categoria.child.length) {
                idLeafs.push(categoria.id_categoria);
            } else {
                idLeafs = [...idLeafs, ...this.getLeafChilds(categoria.child)];
            }
        }
        return idLeafs;
    }

    productCardSearchResultGenerator = prodotto =>
        <MenuDigitaleCardProdotto
            key={prodotto.id_prodotto}
            {...prodotto}
            onDetail={this.openDettaglio.bind(this, prodotto)}
        />


    render() {
        /* La pagina principale del menù è una categoria creata in modo fittizio */
        const tempMainCategory = {
            nome: NegozioOpenStore.infoNegozio.homepage_buttons.find(b => b.type === 'menu_digitale')?.text || "Menu digitale",
            child: this.state.categoryTree
        }

        return (
            <div>

                {
                    this.props.active && <MenuCategoryGalleryPage
                        category={tempMainCategory}
                        onDismiss={this.props.onDismiss}
                        onCategoryChoose={this.handleCategoryChoose}
                        onCategoryLeafChoose={this.handleCategoryLeafChoose}
                        onSearch={this.handleSearchInCategory}
                        onProductDetailOpen={this.openDettaglio}
                        hideEmptyCategory={true}
                        productList={this.state.prodotti}
                        searchResultItemGenerator={this.productCardSearchResultGenerator}
                        rightIcon={<FlagDropdown className={"bg-main-z3 px-2 py-1 rounded"} default={"it"}
                                                 onChange={this.onLanguageChange}/>}
                    />
                }

                {/* Stampo tutte le successive pagine di categorie */}
                {
                    this.state.categoryOpen.map((c, i) =>
                        <MenuCategoryGalleryPage
                            key={i}
                            category={c}
                            onDismiss={this.handleCategoryDismiss(c)}
                            onCategoryChoose={this.handleCategoryChoose}
                            onCategoryLeafChoose={this.handleCategoryLeafChoose}
                            onSearch={this.handleSearchInCategory}
                            onProductDetailOpen={this.openDettaglio}
                            hideEmptyCategory={true}
                            productList={this.state.prodotti}
                            searchResultItemGenerator={this.productCardSearchResultGenerator}
                            rightIcon={<FlagDropdown className={"bg-main-z3 px-2 py-1 rounded"} default={"it"}
                                                     onChange={this.onLanguageChange}/>}
                        />
                    )
                }

                <Page
                    title={"Menu digitale"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    leftIconText={"Categorie"}
                    onLeftIconClick={this.handleProdottiDismiss}
                    active={!!this.state.categoryLeaf}
                    content={
                        <div className={"container pt-2 pb-5 mb-3"}>
                            <MenuDigitaleSearchBar
                                onSearch={this.handleSearch}
                            />
                            <div className={"my-2 px-1"}>
                                <small
                                    className={"text-uppercase text-on-bg-main"}>{this.state.categoryLeaf ? this.state.categoryLeaf.nome : ''}</small>
                            </div>
                            {
                                this.state.prodotti === null || !this.state.categoryLeaf ?
                                    <div className={"text-center"}>Caricamento prodotti</div>
                                    :
                                    this.state.prodotti
                                        .filter(this.filterProductCategory)
                                        .filter(this.filterProductSearch)
                                        .sort(this.sortProducts)
                                        .map((prodotto, i) => {
                                            return (

                                                <MenuDigitaleCardProdotto
                                                    key={i}
                                                    {...prodotto}
                                                    onDetail={this.openDettaglio.bind(this, prodotto)}
                                                />

                                            )
                                        })
                            }
                        </div>
                    }
                />
                <ProdottoDetail
                    {...this.state.prodotto_open}
                    active={!!this.state.prodotto_open}
                    onDismiss={this.closeDettaglio}
                />
            </div>
        );
    }
}

MenuDigitaleModal.propTypes = {
    onDismiss: PropTypes.func,
};
