import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const _User = {
    getUserReceiptsInShop: function (id_negozio, limit, offset){
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/modulo-cassa/user/get-receipts-in-shop`), {
                token: AuthAPI.getUserAccessToken(),
                id_negozio: id_negozio,
                limit: limit,
                offset: offset
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    }
}