import React from 'react';
import PropTypes from 'prop-types'

import FuxCursorPaginator from "../../../../../components/BaseComponents/FuxCursorPaginator/FuxCursorPaginator";
import {ModuloServiziAPI} from "../../../../../api/ModuloServizi/ModuloServiziAPI";
import moment from "moment";
import SafeHtmlContainer from "../../../../../helpers/SafeHtmlContainer";
import {
    FuxPaginatorItemSkeleton
} from "../../../../../components/BaseComponents/FuxCursorPaginator/FuxPaginatorItemSkeleton";

export default class NotesHistoryView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasHistoryNotes: false,
        }

        this.TYPE_BOOK = 'book';
        this.TYPE_PIPELINE = 'pipeline';
    }

    handleFetch = cursor => {
        const p = ModuloServiziAPI.Notes.pagination(this.props.userId, cursor);
        p.then(pageData => {
            if (pageData.total && parseInt(pageData.total) > 0) this.setState({hasHistoryNotes: true});
        })
        return p;
    }

    handleEmptyPage = _ => <p className={"text-center lead text-muted"}>Nessun risultato</p>;
    handleItemRender = row => {
        return (
            <div className={"list-group-item d-block"}>
                <div>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <small className={"text-muted"}>{moment(row.created_at).format('DD/MM/YYYY HH:mm')}</small>
                        <span className={"badge"}>
                        {row.type === this.TYPE_BOOK && <React.Fragment>Da: "Prenotazione"</React.Fragment>}
                            {row.type === this.TYPE_PIPELINE && <React.Fragment>Da: "Note pipeline"</React.Fragment>}
                    </span>
                    </div>
                    <div className={"small"}>
                        <SafeHtmlContainer html={row.note}/>
                    </div>
                </div>
            </div>
        )
    }

    handleSkeletonRender = _ => {
        return <div className={"list-group-item d-block"}>
            <FuxPaginatorItemSkeleton height={35}/>
        </div>
    }

    render() {
        return (
            <div className={`card shadow-sm mb-3 border-0 ${this.state.hasHistoryNotes ? '' : 'd-none'}`}>
                <div className={"card-body"}>
                    <h6 className="font-weight-bold text-purple">Note vecchi appuntamenti</h6>
                    <div className={"list-group"}>
                        <FuxCursorPaginator
                            onItemRender={this.handleItemRender}
                            onPageRequest={this.handleFetch}
                            onEmptyPage={this.handleEmptyPage}
                            onItemSkeletonRender={this.handleSkeletonRender}
                            skeletonItemsNum={3}
                        />
                    </div>
                </div>
            </div>

        )
    }
}

NotesHistoryView.propTypes = {
    userId: PropTypes.string,
}
