import FuxHTTP from "../../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../../APIConfig";

export const _Map = {
    getMaps: (id_negozio) => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/maps/get-maps`), {
            id_negozio: id_negozio,
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    },
    getSlots: (map_id, start_date, end_date) => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/maps/get-slots`), {
            map_id: map_id,
            start_date: start_date,
            end_date: end_date,
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    },
    getSectors: map_id => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/maps/get-sectors`), {
            map_id: map_id,
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    },
    getTickets: map_id => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/maps/get-tickets`), {
            map_id: map_id,
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    },
    getAccessories: map_id => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/maps/get-accessories`), {
            map_id: map_id,
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    },
    getClosingDays: map_id => {
        return FuxHTTP.get(APIConfig.url(`/modulo-seats/maps/get-closing-days`), {
            map_id: map_id,
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
    }
}