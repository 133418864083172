import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";


export class LightCalendarEvent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            noteInterne: this.props.event.isFreeTimeEvent ? '' : this.props.event.book.note,
            detailOpen: false
        }
    }

    handleOpenResume = _ => this.props.onOpenResume(this.props.event.book.id_parent ? this.props.event.book.id_parent : this.props.event.book.id_prenotazione)

    render() {

        //Evento di tipo "free-time"
        if (this.props.event.isFreeTimeEvent) {
            return (
                <EventHeader className={"shadow-sm p-2 my-3 " + this.props.event.className}>
                    <div onClick={_ => this.props.onAddBookWithTime(this.props.event.start)}>
                        <div className={"small"}>
                            {this.props.event.title} (dalle {this.props.event.start} alle {this.props.event.end})
                        </div>
                        <div className={"small text-muted"}>Clicca per aggiungere appuntamento</div>
                    </div>
                </EventHeader>
            );
        }

        //Evento di tipo prenotazione
        return (
            <Fragment>
                <EventHeader className={"shadow-sm p-2 my-2 " + this.props.event.className}>
                    <div onClick={this.handleOpenResume}>
                        <h6 className={"font-weight-bold mb-0"}>{this.props.event.book.nome_utente}</h6>
                        <div className={"small"}>{this.props.event.book.nome_servizio}</div>
                        <div className={"small text-muted"}>
                            {this.props.event.book.orario_inizio} - {this.props.event.book.orario_fine}
                        </div>
                    </div>
                </EventHeader>
            </Fragment>
        );
    }

}

LightCalendarEvent.propTypes = {
    event: PropTypes.object,
    onOpenResume: PropTypes.func,
    onAddBookWithTime: PropTypes.func
}


const EventHeader = styled.div`
border-left:5px solid;
background:white!important;

&.free-time
{
    margin-left:-15px;
    margin-right:-15px;
    background-color:#EBEBEB!important;
    border-left:0!important;
    border-top:1px solid #b8b8b8!important;
    border-bottom:1px solid #b8b8b8!important;
}
`;
