import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../BaseComponents/Page/Page";
import {FaSolidIcon} from "../../FontAwesomeIcons";
import {PacchettiAPI} from "../../../api/Pacchetti/PacchettiAPI";
import {PacchettoCardBackground} from "../PacchettiListPage/PacchettiListItem";
import SafeHtmlContainer from "../../../helpers/SafeHtmlContainer";
import moment from "moment";
import {PlaceholderBlock} from "../../../helpers/Placeholders";
import {swal, swalConfirm, swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import {Base64} from "../../../helpers/Base64";

export class PacchettoAdminDetailPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pacchetto: null,
            multiplier: -1,
            punti: 1
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = _ => {
        try {
            const tokenData = JSON.parse(Base64.decode(this.props.authorizationToken));
            PacchettiAPI.Admin.getInfo(tokenData.id_pacchetto)
                .then(pacchetto => {
                    this.setState({pacchetto: pacchetto, fetching: false});
                });
        } catch (e) {
            swalError('Qualcosa è andato storto. Impossibile recuperare dati del pacchetto. Riprova più tardi.');
            this.props.onDismiss();
        }
    }

    handleInputChange = ({target}) => {
        console.log(target, target.value);
        this.setState({[target.name]: target.value});
    }

    handleSubmit = _ => {
        const action = parseInt(this.state.multiplier) === 1 ? 'accreditare' : 'addebitare';
        swalConfirm(`Sei sicuro di voler ${action} ${this.state.punti} crediti su questo pacchetto?`)
            .then(_ => {
                swalLoading();
                PacchettiAPI.Admin.movimenta(this.props.authorizationToken, this.state.punti * parseInt(this.state.multiplier))
                    .then(message => {
                        swalSuccess(message);
                        this.props.onDismiss();
                    });
            })
    }

    render() {
        const pacchetto = this.state.pacchetto;
        const humanReadableDate = pacchetto ? moment(pacchetto.data_creazione).format('ddd D MMM YYYY') : '';

        return (
            <Fragment>
                <Page
                    title={"Info pacchetto"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >
                    <div className={"container"}>

                        {
                            !this.state.pacchetto &&
                            <PlaceholderBlock className={"rounded-xl shadow-sm mt-3"} height={200}/>
                        }

                        {
                            !!this.state.pacchetto &&
                            <Fragment>
                                <div
                                    className={"card shadow-sm border-0 my-3 rounded-xl overflow-hidden text-on-bg-primary"}>
                                    <PacchettoCardBackground color={this.props.color || '#293069'}>
                                        <h5 className={"font-weight-bold"}>
                                            <SafeHtmlContainer html={pacchetto.nome}/>
                                        </h5>
                                        <div className={"text-center my-3"}>
                                            <small className={"text-uppercase"}>saldo</small>
                                            <h3 className={"font-weight-bold text-center"}>{pacchetto.punti_rimanenti}/{pacchetto.punti}</h3>
                                        </div>
                                        <div className={"d-flex align-items-center justify-content-between"}>
                                            <div className={"small"}>
                                                Proprietario<br/>
                                                {pacchetto.nome_utente}
                                            </div>
                                            <div className={"small text-right"}>
                                                Data di acquisto<br/>
                                                {humanReadableDate}
                                            </div>
                                        </div>
                                        <div className={"small text-uppercase"}>
                                            Cod. #{pacchetto.id_pacchetto}
                                        </div>
                                    </PacchettoCardBackground>
                                </div>

                                <h4 className={"font-weight-bold"}>
                                    Aggiungi operazione
                                </h4>
                                <div className={"form-group"}>
                                    <label className={"mr-3"}>
                                        <input type={"radio"} value={-1} name={"multiplier"}
                                               checked={this.state.multiplier == -1}
                                               onChange={this.handleInputChange}/> Addebita
                                    </label>
                                    <label>
                                        <input type={"radio"} value={1} name={"multiplier"}
                                               checked={this.state.multiplier == 1}
                                               onChange={this.handleInputChange}/> Accredita
                                    </label>
                                    <input
                                        type={"number"}
                                        name={"punti"}
                                        value={this.state.punti}
                                        className={"form-control w-auto"}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className={"form-group"}>
                                    <button className={"btn btn-primary"} onClick={this.handleSubmit}>
                                        <FaSolidIcon name={"check"}/> Procedi
                                    </button>
                                </div>
                            </Fragment>
                        }

                    </div>
                </Page>
            </Fragment>
        )
    }

}

PacchettoAdminDetailPage.propTypes = {
    onDismiss: PropTypes.func,
    authorizationToken: PropTypes.string.isRequired,
    color: PropTypes.string
}