import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../BaseComponents/Page/Page";
import {FaSolidIcon} from "../FontAwesomeIcons";
import {FullscreenInput} from "../Forms/FullscreenInputs";
import {swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import AuthAPI from "../../api/AuthAPI";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {AUTHENTICATED_USER_DATA_CHANGE} from "../../const/AppEventsCostants";
import {ModuloGruppiAPI} from "../../api/ModuloGruppi/ModuloGruppiAPI";
import {UserAPI} from "../../api/User/UserAPI";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";
import ValidateOTPPage from "../ValidateOTPPage";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";

export default class EditPasswordPage extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            currentPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            validateOtpPageOpen: false
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.state.newPassword.length < 5){
            swalError("La nuova password deve contenere almeno 5 caratteri")
            return;
        }
        if(this.state.newPasswordRepeat !== this.state.newPassword){
            swalError("Verifica la nuova password inserita, assicurati che coincidano!")
            return;
        }
        swalLoading("Stiamo verificando i dati inseriti");
        UserAPI.Credentials.changePassword(this.state.currentPassword, this.state.newPassword)
            .then(message => {
                swalSuccess(message);
                this.props.onDismiss();
            })
            .catch(swalError)
    }


    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    passwordCoincidono = _ => {
        if(this.state.newPassword.length < 1 || this.state.newPasswordRepeat.length < 1){
            return ''
        }
        if(this.state.newPasswordRepeat === this.state.newPassword){
            return <span className={"text-success"}>Le due password coincidono</span>
        }
        return <span className={"text-danger"}>Le due password non coincidono</span>
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Modifica email"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >

                    <form onSubmit={this.handleSubmit}>

                        <div className="form-group container-fluid mt-3">
                            <small className={"text-uppercase"}>Modifica la tua password</small>
                            <div className={"row align-items-center bg-white border-bottom border-top"}>
                                <div className={"col-1 px-0 text-primary text-center"}>
                                    <FaSolidIcon name={"lock"}/>
                                </div>
                                <div className={"col px-0"}>
                                    <FullscreenInput
                                        className={"form-control border-0 rounded-0"}
                                        type={"password"}
                                        name={"currentPassword"}
                                        value={this.state.currentPassword}
                                        placeholder="La tua password attuale"
                                        onChange={this.handleInputChange}
                                        required = {true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group container-fluid mt-3">
                            <small className={"text-uppercase"}>Inserisci la nuova password</small>
                            <div className={"row align-items-center bg-white border-bottom border-top"}>
                                <div className={"col-1 px-0 text-primary text-center"}>
                                    <FaSolidIcon name={"lock"}/>
                                </div>
                                <div className={"col px-0"}>
                                    <FullscreenInput
                                        className={"form-control border-0 rounded-0"}
                                        type={"password"}
                                        name={"newPassword"}
                                        value={this.state.newPassword}
                                        placeholder="Nuova password"
                                        onChange={this.handleInputChange}
                                        required = {true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group container-fluid mt-3">
                            <div className={"row align-items-center bg-white border-bottom border-top"}>
                                <div className={"col-1 px-0 text-primary text-center"}>
                                    <FaSolidIcon name={"lock"}/>
                                </div>
                                <div className={"col px-0"}>
                                    <FullscreenInput
                                        className={"form-control border-0 rounded-0"}
                                        type={"password"}
                                        name={"newPasswordRepeat"}
                                        value={this.state.newPasswordRepeat}
                                        placeholder="Ripeti nuova password"
                                        onChange={this.handleInputChange}
                                        required = {true}
                                    />
                                </div>
                            </div>
                            {this.passwordCoincidono()}
                        </div>


                        <div className={"form-group container mt-4"}>
                            <button className={"btn btn-primary btn-block shadow-sm"} type="submit">Cambia password</button>
                        </div>
                    </form>
                </Page>

            </Fragment>
        )
    }

}


EditPasswordPage.propTypes = {
    user: PropTypes.any,
    onDismiss: PropTypes.func
}