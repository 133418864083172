import React, {Fragment} from "react";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import Page from "../../../components/BaseComponents/Page/Page";
import {RequestARefoundIcon} from "../../../img/illustrations/RequestARefoundIcon";
import {AllRequestsRefoundIcon} from "../../../img/illustrations/AllRequestsRefoundIcon";
import DipendenteWelfareNuovaRichiestaRimborso from "./DipendenteWelfareNuovaRichiestaRimborso";
import ModalPortal from "../../../components/BaseComponents/ModalPortal";
import DipendenteWelfareStoricoRichiestaRimborso from "./DipendenteWelfareStoricoRichiestaRimborso";

export default class DipendenteWelfareRichiesteRimborsoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nuovoRimborsoPageOpen: false,
            storicoRimborsiPageOpen: false,
        }
    }

    toggleNuovoRimborsoPage = _ => this.setState({nuovoRimborsoPageOpen: !this.state.nuovoRimborsoPageOpen});
    toggleStoricoRimborsiPage = _ => this.setState({storicoRimborsiPageOpen: !this.state.storicoRimborsiPageOpen});


    render() {
        return (
            <Fragment>
                <Page
                    title={"Richieste rimborsi"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <div className={"container pt-4"}>
                            <div className={"text-center mb-4"}>
                                <h3 className={"text-primary "}>
                                    Scegli una operazione da fare sui rimborsi
                                </h3>
                                <span>
                                    Le tue richieste di rimborso, eccetto quelle rifiutate, verranno sottratte da
                                    tuo <b>Saldo disponibile</b>
                                </span>
                            </div>
                            <div className={"row"}>
                                <div className={"col-12 mb-2"}>
                                    <div className={"card"} onClick={this.toggleNuovoRimborsoPage}>
                                        <div className={"card-body"}>
                                            <div className={"d-flex align-items-center justify-content-left"}>
                                                <RequestARefoundIcon className={"img-fluid w-50 mx-auto"}
                                                                     color={"#293069"}/>
                                                <div className={"ml-2"}>
                                                    <h5>Esegui una nuova richiesta di rimborso</h5>
                                                    <p>Presenta una ricevuta alla tua azineda, la quale provvederà a
                                                        rimborsarti </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <div className={"card"} onClick={this.toggleStoricoRimborsiPage}>
                                        <div className={"card-body"}>
                                            <div className={"d-flex align-items-center justify-content-left"}>
                                                <AllRequestsRefoundIcon className={"img-fluid w-25 mx-auto"}
                                                                        color={"#293069"}/>
                                                <div className={"ml-2"}>
                                                    <h5>Storico richieste di rimborso</h5>
                                                    <p>Guarda tutte le tue richieste di rimborso</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />

                <DipendenteWelfareNuovaRichiestaRimborso
                    active={this.state.nuovoRimborsoPageOpen}
                    user={this.props.user}
                    dipendente={this.props.dipendente}
                    onDismiss={this.toggleNuovoRimborsoPage}
                />

                <DipendenteWelfareStoricoRichiestaRimborso
                    active={this.state.storicoRimborsiPageOpen}
                    user={this.props.user}
                    dipendente={this.props.dipendente}
                    onDismiss={this.toggleStoricoRimborsiPage}
                />
            </Fragment>
        );
    }
}