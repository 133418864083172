import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";


export const UserAdminChatAPI = {
    saveSettings: ({
                       chat_abilitata,
                       chat_orario_assenza_abilitato,
                       chat_orario_assenza_inizio,
                       chat_orario_assenza_fine,
                       chat_messaggio_assenza,
                       chat_messaggio_assenza_abilitato
                   }) => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/admin-chat/save-settings`), {
                token: AuthAPI.currentUser.accessToken,
                chat_abilitata: chat_abilitata,
                chat_orario_assenza_abilitato: chat_orario_assenza_abilitato,
                chat_orario_assenza_inizio: chat_orario_assenza_inizio,
                chat_orario_assenza_fine: chat_orario_assenza_fine,
                chat_messaggio_assenza: chat_messaggio_assenza,
                chat_messaggio_assenza_abilitato: chat_messaggio_assenza_abilitato
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    chatList: _ => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/admin-chat/chat-list`), {
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    setRead: id_room => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/admin-chat/set-read`), {
                token: AuthAPI.currentUser.accessToken,
                id_room: id_room,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getChatRoom: id_utente => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/admin-chat/get-chat-room`), {
                token: AuthAPI.currentUser.accessToken,
                id_utente: id_utente,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getMessages: (id_room, limit, cursor) => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/admin-chat/get-messages`), {
                token: AuthAPI.currentUser.accessToken,
                id_room: id_room,
                limit: limit,
                cursor: cursor
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getMediaContentUrl: (id_messaggio) => {
        return APIConfig.url(`/admin-chat/get-media-content?id_messaggio=${id_messaggio}&token=${AuthAPI.currentUser.accessToken}`)
    },
    sendTextMessage: (id_room, text, attachments) => {
        const formData = new FormData();
        formData.append("token", AuthAPI.currentUser.accessToken);
        formData.append("id_room", id_room);
        formData.append("text", text);
        if (attachments) {
            attachments.map(a => {
                formData.append("attachments[]", a);
            })
        }

        return FuxHTTP.post(
            APIConfig.url(`/admin-chat/send-text-message`), formData,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    sendMessageNotification: (id_room, id_messaggio, otp) => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/admin-chat/send-message-notification`), {
                token: AuthAPI.currentUser.accessToken,
                id_room: id_room,
                id_messaggio: id_messaggio,
                otp: otp,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};
