import React, {Fragment} from "react";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import Page from "../../components/BaseComponents/Page/Page";
import NegozioAPI from "../../api/NegozioAPI";
import axios from "axios";
import NegozioSearchResult from "./NegozioSearchResult";
import "../css/LauncherAppList.css";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {BookizonAppManager} from "../../index";
import SottocategorieNegoziAPI from "../../api/SottocategorieNegoziAPI";
import SearchPageCarouselSottocategorie from "./SearchPageCarouselSottocategorie";
import styled, {keyframes} from "styled-components";
import NegozioSearchResultPlaceholder from "./NegozioSearchResultPlaceholder";
import mappaTelefono from "../svg/mappa-telefono.svg";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {pulse} from "react-animations";
import ModalPortal from "../../components/BaseComponents/ModalPortal";
import ProfiloAttivitaContainer from "../../components/ProfiloAttivita/ProfiloAttivitaPanel";
import {FullscreenInput} from "../../components/Forms/FullscreenInputs";
import DistanceChooserBar from "./DistanceChooserBar";
import makeSearchImage from "../svg/make-search.svg";
import NativeGeolocationProvider from "../../providers/NativeGeolocationProvider";
import {LINKED_APPS_CHANGE} from "../../const/AppEventsCostants";
import {SearchPageHeader} from "./SearchPage/SearchPageHeader";
import LogoTotalWhite from "../../img/logo-icon-total-white.svg";
import {ResetInput} from "../../components/BaseComponents/Inputs/ResetInput";
import alienTaken from "../svg/taken-alien.svg";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import {Trans} from "react-i18next";

const pulseAnimation = keyframes`${pulse}`;

const CancelToken = axios.CancelToken;

const SEARCH_TAB_ADDRESS = 'indirizzo';
const SEARCH_TAB_NEAREST = 'vicine';

const SEARCH_TYPE_GEO = 'geo';
const SEARCH_TYPE_FULL = 'full';

const SEARCH_PAGE_SIZE = 10;

const POSITION_RECOVERY_TIMEOUT = 2000;

export default class SearchAppPage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            appSearchQuery: this.props.initialSearchQuery || '',

            searchResults: [],
            totalSearchResults: 0,
            currentPage: 1,

            sottocategorie: [],
            sottocategoriaSelected: null,

            maxDistance: this.props.initialSearchQuery !== null ? 15000 : 20, //km
            selectDistanceOpen: false,

            searchType: SEARCH_TYPE_FULL,
            hasGeolocationPermission: false,
            positionRecoveryTimedout: false,
            lat: null,
            lng: null,

            activeTab: SEARCH_TAB_ADDRESS,

            hasDoneEverywhereSearch: false,
            hasDoneNearestSearch: false,
            canTrySameSearchOnEverywhere: false, //Mi dice se posso effettuare la ricerca su everywhere al click del tab
            canTrySameSearchOnNearest: false, //Mi dice se posso effettuare la ricerca su nearest al click del tab,
            lastVisibileSearchResultsNumber: 0,
            hasDoneSearch: false,
            profiloNegozioOpen: null
        };

        this.typingTimer = null;
        this.doneTypingInterval = 300;
        this.cancelTokenSource = undefined;
        this.lastAutoSearchTimestamp = (new Date()).getTime();
        this.inputRef = null;

        /** @MARK Styles */
        this.accentColorStyle = {
            color: this.props.categoriaNegozio.color
        }
    }

    componentDidMount() {
        FuxEvents.on(LINKED_APPS_CHANGE, this.updateSearchResult)
        SottocategorieNegoziAPI.listaPresenti().then(s => {
            s = s.filter(sc => sc.id_categoria == this.props.categoriaNegozio.id_categoria);
            this.setState({sottocategorie: s})
        });

        //Allo startup del componente verifico se ho già i permessi. Se li ho rimango SUBITO in attesa della posizione
        NativeGeolocationProvider.getPermission()
            .then(permission => {
                if (this.props.disableGeolocationSearching) {
                    permission = false
                }
                this.setState({
                    hasGeolocationPermission: permission,
                    searchType: permission && !this.props.disableGeolocationSearching ? SEARCH_TYPE_GEO : SEARCH_TYPE_FULL
                });
                if (permission) {
                    setTimeout(this.handlePositionRecoveryTimedout, POSITION_RECOVERY_TIMEOUT);
                    this.waitForPosition();
                } else {
                    if (this.props.initialSearchQuery) {
                        this.handleSearch();
                    } else if (this.props.searchFilters) {
                        this.handleSearch();
                    } else if (!this.props.disableGeolocationSearching) {
                        NativeGeolocationProvider.askPermission();
                        NativeGeolocationProvider.waitForPermissionGrant().then(_ => {
                            setTimeout(this.handlePositionRecoveryTimedout, POSITION_RECOVERY_TIMEOUT);
                            this.setState({hasGeolocationPermission: true, searchType: SEARCH_TYPE_GEO});
                            this.waitForPosition();
                        });
                    }
                }
            });
    }

    componentWillUnmount() {
        FuxEvents.off(LINKED_APPS_CHANGE, this.updateSearchResult);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.active) {
            //Eseguo una ricerca se ci sono i dati della geolocalizzazione
            if (prevState.lat === null && prevState.lng === null && this.state.lat && this.state.lng && this.state.hasDoneEverywhereSearch) {
                this.handleSearch();
            }
        }
    }

    handleProfiloNegozioOpen = negozio => this.setState({profiloNegozioOpen: negozio});
    handleProfiloNegozioClose = _ => this.setState({profiloNegozioOpen: null});

    /**
     * GEOLOCATION MANAGEMENT
     * */
    waitForPosition = _ => {
        NativeGeolocationProvider.waitForPosition('SEARCH')
            .then(location => {
                if (location) {
                    this.setState({
                        lat: location.lat,
                        lng: location.lng
                    }, _ => {
                        if (this.state.searchType === SEARCH_TYPE_GEO) {
                            this.handleSearch();
                        } else {
                            /*
                            * Se:
                            * - non sono già in modalità geolocalizzata
                            * - la pagina di ricerca non è stata aperta
                            * - non ho effettuato ricerche (o è stata resettata)
                            * (quindi è come se l'app è stata appena aperta)
                            * Imposto il tipo di ricerca a geolocalizzata
                            * */
                            if (!this.props.active && !this.state.hasDoneSearch) {
                                this.setState({searchType: SEARCH_TYPE_GEO});
                            }
                        }
                    });
                }
            });
    }


    //Se dopo la richiesta della posizione sono passati X secondi e non ho ancora una posizione, allora imposto il flag a true
    handlePositionRecoveryTimedout = _ => {
        this.setState({
            positionRecoveryTimedout: this.state.hasGeolocationPermission && (!this.state.lat || !this.state.lng)
        });
    }

    /**
     * FILTERS MANAGEMENT
     * */

    handleSottocategoriaSelectedChange = id => {
        this.setState({
            sottocategoriaSelected: this.state.sottocategoriaSelected !== id ? id : null,
            //Resetto tutta la ricerca per farla iniziare da capo
            searchResults: [], currentPage: 1,
            appSearchQuery: '',
            hasDoneSearch: this.state.sottocategoriaSelected !== id ? this.state.hasDoneSearch : false
        }, _ => {
            if (this.state.sottocategoriaSelected) {
                this.handleSearch();
            }
        });
    }

    handleMaxDistanceChange = distance => {
        const hasDoneSearch = this.state.hasDoneSearch;
        this.setState({
            maxDistance: distance, selectDistanceOpen: false,
            searchResults: [],
            currentPage: 1,
        }, _ => {
            if (hasDoneSearch) {
                this.handleSearch();
            }
        });
    }

    toggleDistanceChooserVisibility = _ => this.setState({selectDistanceOpen: !this.state.selectDistanceOpen});

    /**
     * MIXED UTILITY
     * */

    handleFeedScroll = e => {
        //Verifico che sia passato l'intervallo minimo tra due ricerche "automatiche"
        const currentTs = (new Date()).getTime();
        const minIntervalBetweenSearch = 1000; //2 secondi
        if (currentTs - this.lastAutoSearchTimestamp < minIntervalBetweenSearch) {
            return;
        }

        if (!this.props.active) return; //Verifico che la pagina di ricerca si visibile

        if (this.state.searching || this.state.profiloNegozioOpen) return; //Verifico che non stia già eseguendo la ricerca

        const scrollTop = e.target.scrollTop;
        if (scrollTop <= this.lastScrollTop) { //Se vado verso sopra allora non triggero la ricerca (uso un errore di 10px)
            this.lastScrollTop = scrollTop;
            return;
        }
        this.lastScrollTop = scrollTop;

        const height = e.target.scrollHeight;
        const thresholdOffset = 200;
        if (scrollTop + window.innerHeight + thresholdOffset >= height) {
            if (this.state.searchResults.length) {
                this.lastAutoSearchTimestamp = (new Date()).getTime();
                this.setState({
                    searching: true,
                    currentPage: this.state.currentPage + 1
                }, this.handleSearch);
            }
        }
    }

    setSearchType = (type, refreshSearch) => {
        const hasDoneSearch = this.state.hasDoneSearch;
        this.setState({
            searchType: type,
            searchResults: [],
            currentPage: 1,
        }, _ => {
            if (hasDoneSearch || refreshSearch) {
                if (this.state.searchType === SEARCH_TYPE_GEO) {
                    if (this.state.lat && this.state.lng) {
                        this.handleSearch();
                    }
                } else {
                    this.handleSearch();
                }
            }
        });
    }

    getNoResultsMessage = _ => {
        if (this.state.searchType === SEARCH_TYPE_FULL) {

            const additionalMessage = <div className={"text-muted"}>
                {i18n.t('search-page.errors.no-result-advice')}
            </div>

            //Categoria + query
            if (!!this.state.sottocategoriaSelected && !!this.state.appSearchQuery) {
                const sottocategoria = this.state.sottocategorie.find(s => s.id_sottocategoria === this.state.sottocategoriaSelected);
                return <span style={this.accentColorStyle}>
                    <h5>
                        <Trans i18nKey={"search-page.errors.full-search.no-result-query&category"}
                               values={{query: this.state.appSearchQuery, category: sottocategoria.nome}}>
                            Non abbiamo trovato nessun risultato per <b>{this.state.appSearchQuery}</b> in <b>{sottocategoria.nome}</b>
                        </Trans>
                    </h5>
                    {additionalMessage}
                </span>
            }
            //Solo categoria
            if (!!this.state.sottocategoriaSelected) {
                const sottocategoria = this.state.sottocategorie.find(s => s.id_sottocategoria === this.state.sottocategoriaSelected);
                return <span style={this.accentColorStyle}>
                    <h5>
                        <Trans i18nKey={"search-page.errors.full-search.no-result-category"}
                               values={{category: sottocategoria.nome}}>
                            Non abbiamo trovato nessun risultato in <b>{sottocategoria.nome}</b>
                        </Trans>
                    </h5>
                    {additionalMessage}
                </span>;
            }
            //Solo query
            if (!!this.state.appSearchQuery) {
                return <span style={this.accentColorStyle}>
                    <h5>
                        <Trans i18nKey={"search-page.errors.full-search.no-result-query"}
                               values={{query: this.state.appSearchQuery}}>
                            Non abbiamo trovato nessun risultato per <b>{this.state.appSearchQuery}</b>
                        </Trans>
                    </h5>
                    {additionalMessage}
                </span>;
            }
        } else if (this.state.searchType === SEARCH_TYPE_GEO) {

            const additionalMessage = this.state.maxDistance === 15000 ?
                <div className={"text-muted d-flex justify-content-center align-items-center"}>
                    <Trans i18nKey={"search-page.errors.change-search-advice"}>
                        Prova a <button className={"btn btn-link p-0 ml-1"}
                                        onClick={this.handleResetAndNewSearch}>cambiare
                        la tua ricerca</button>
                    </Trans>

                </div>
                :
                <div className={"mt-3"}>
                    <AccentedButton className={"btn btn-primary"} accentColor={"white"}
                                    bgColor={this.props.categoriaNegozio.color}
                                    onClick={this.toggleDistanceChooserVisibility}>
                        {i18n.t('search-page.geolocation.change-distance-btn')}
                    </AccentedButton>
                    &nbsp;
                    <AccentedOutlineButton accentColor={this.props.categoriaNegozio.color}
                                           className={"btn btn-outline-primary"}
                                           onClick={_ => this.handleMaxDistanceChange(15000)}>
                        {i18n.t('search-page.geolocation.search-everywhere-btn')}
                    </AccentedOutlineButton>
                </div>;

            const distanceMessage = this.state.maxDistance === 15000 ? 'in Bookizon' : i18n.t('search-page.geolocation.in-radius-of-template', {radius: this.state.maxDistance})

            //Categoria + query
            if (!!this.state.sottocategoriaSelected && !!this.state.appSearchQuery) {
                const sottocategoria = this.state.sottocategorie.find(s => s.id_sottocategoria === this.state.sottocategoriaSelected);
                return <span style={this.accentColorStyle}>
                    <h6>
                        <Trans i18nKey={"search-page.errors.geo-search.no-result-query&category"}
                               values={{query: this.state.appSearchQuery, category: sottocategoria.nome}}>
                            Non abbiamo trovato nessun risultato per <b>{this.state.appSearchQuery}</b> in <b>{sottocategoria.nome}</b>
                        </Trans>
                        {distanceMessage}
                    </h6>
                    {additionalMessage}
                </span>
            }
            //Solo categoria
            if (!!this.state.sottocategoriaSelected) {
                const sottocategoria = this.state.sottocategorie.find(s => s.id_sottocategoria === this.state.sottocategoriaSelected);
                return <span style={this.accentColorStyle}>
                    <h6>
                        <Trans i18nKey={"search-page.errors.geo-search.no-result-category"}
                               values={{category: sottocategoria.nome}}>
                            Non abbiamo trovato nessun risultato in <b>{sottocategoria.nome}</b>
                        </Trans>
                        {distanceMessage}
                    </h6>
                    {additionalMessage}
                </span>
            }
            //Solo query
            if (!!this.state.appSearchQuery) {
                return <span style={this.accentColorStyle}>
                    <h6>
                        <Trans i18nKey={"search-page.errors.geo-search.no-result-query"}
                               values={{query: this.state.appSearchQuery}}>
                            Non abbiamo trovato nessun risultato per <b>{this.state.appSearchQuery}</b>
                        </Trans>
                        {distanceMessage}
                    </h6>
                    {additionalMessage}
                </span>
            }

            return <span style={this.accentColorStyle}>
                {i18n.t('search-page.errors.no-results')} :(
                {additionalMessage}
            </span>
        }
        return <span style={this.accentColorStyle}>
            {i18n.t('search-page.errors.no-results')} :(
        </span>
    }

    /**
     * SEARCH MANAGEMENT
     * */

    handleQueryChange = ({target}) => {
        this.setState({[target.name]: target.value});
    }

    handleSearchInputKeyUp = ({target}) => {
        clearTimeout(this.typingTimer);

        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('cancellato');
            this.cancelTokenSource = undefined;
        }
        if (target.value && target.value.length >= 3) {
            this.typingTimer = setTimeout(_ => {
                this.setState({
                    searchResults: [],
                    currentPage: 1,
                    hasDoneSearch: false
                }, this.handleSearch);
            }, this.doneTypingInterval);
        } else {
            if (!target.value) {
                this.handleReset();
            } else {
                this.setState({searching: false});
            }
        }
    }

    updateSearchResult = _ => {
        if (!this.state.appSearchQuery.length) {
            this.setState({searching: false, searchResultsEverywhere: [], searchResultsNearest: []});
        } else {
            this.handleSearch();
        }
    }

    /**
     * @description Esegue la ricerca tramite i parametri impostati nello state
     * @param hasSameParameters mi dice se ci sono stati cambiamenti nei parametri, così da abilitare
     * la ricerca automatica nell'altra tab
     * */
    handleSearch = hasSameParameters => {
        console.log("SEARCHING");
        this.cancelTokenSource = CancelToken.source();

        //Effettuo una ricerca con geolocalizzazione
        if (this.state.searchType === SEARCH_TYPE_GEO) {
            if (!this.state.lat || !this.state.lng) {
                return;
            }
            this.setState({searching: true});
            NegozioAPI.geoSearch(
                this.state.appSearchQuery,
                this.props.categoriaNegozio.id_categoria,
                this.state.sottocategoriaSelected ? [this.state.sottocategoriaSelected] : undefined,
                this.state.lat,
                this.state.lng,
                this.state.maxDistance,
                SEARCH_PAGE_SIZE,
                (this.state.currentPage - 1) * SEARCH_PAGE_SIZE,
                this.cancelTokenSource.token,
                this.props.searchFilters || null,
            )
                .then(({negozi, total}) => {
                    const newSearchResults = [...this.state.searchResults, ...negozi];
                    this.setState({
                        searching: false,
                        searchResults: newSearchResults,
                        lastVisibileSearchResultsNumber: parseInt(newSearchResults.length),
                        totalSearchResults: total,
                        hasDoneSearch: true
                    });
                });
        } else { //Ricerca classica senza geolocalizzazione
            this.setState({searching: true});
            NegozioAPI.search(
                this.state.appSearchQuery,
                SEARCH_PAGE_SIZE,
                (this.state.currentPage - 1) * SEARCH_PAGE_SIZE,
                this.props.categoriaNegozio.id_categoria,
                this.state.sottocategoriaSelected,
                this.cancelTokenSource.token,
                this.props.searchFilters || null,
            )
                .then(({negozi, total}) => {
                    const newSearchResults = [...this.state.searchResults, ...negozi];
                    this.setState({
                        searching: false,
                        searchResults: newSearchResults,
                        lastVisibileSearchResultsNumber: parseInt(newSearchResults.length),
                        totalSearchResults: total,
                        hasDoneSearch: true
                    });
                });
        }
    }

    handleReset = _ => {
        this.setState({
            appSearchQuery: '',
            searching: false,
            searchResults: [],
        }, _ => {
            if (this.state.sottocategoriaSelected) {
                if (this.state.searchType === SEARCH_TYPE_GEO) {
                    if (this.state.lat && this.state.lng) {
                        this.handleSearch();
                    }
                } else {
                    this.handleSearch();
                }
            }
        });
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('cancellato');
        }
    }

    handleResetAndNewSearch = _ => {
        this.setState({
            appSearchQuery: '',
            searching: false,
            searchResults: []
        });
        if (this.inputRef) {
            this.inputRef.focus();
        }
    }

    handleInputRef = input => {
        if (this.state.searchPageOpen && input) {
            input.focus();
        }
        this.inputRef = input;
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={""}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    leftIconText={i18n.t('generic.close-btn')}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    rightIcon={<img src={LogoTotalWhite} height={22} alt={"Bookizon logo"}/>}
                    floatingNavbar={true}
                    onScroll={this.handleFeedScroll}
                    navbarBgColor={this.props.categoriaNegozio.color}
                    navbarTextColor={"#ffffff"}
                >

                    <SearchPageHeader categoria={this.props.categoriaNegozio}/>

                    {
                        !!this.state.sottocategorie.length &&
                        <Fragment>
                            <div className={"container-fluid font-weight-bold"}>
                                {i18n.t('search-page.filter-category-headline')}
                            </div>

                            {
                                this.props.active && <SearchPageCarouselSottocategorie
                                    sottocategorie={this.state.sottocategorie}
                                    selected={this.state.sottocategoriaSelected}
                                    onChange={this.handleSottocategoriaSelectedChange}
                                    accentColor={this.props.categoriaNegozio.color}
                                />
                            }
                        </Fragment>
                    }

                    {
                        !!this.state.sottocategoriaSelected &&
                        <div className={"small my-2 text-center"}>
                            {i18n.t('search-page.searching-in-category-label')}:&nbsp;
                            <b style={this.accentColorStyle}>
                                {
                                    this.state.sottocategorie
                                        .find(s => s.id_sottocategoria === this.state.sottocategoriaSelected).nome
                                }
                            </b>
                        </div>
                    }

                    <div className={"container-fluid clearfix mt-3 sticky-top"}>
                        <div className={"row align-items-center bg-white border-bottom border-top"}>
                            <div className={"col-1 px-0 text-primary text-center"}>
                                <FaSolidIcon name={"search"} style={this.accentColorStyle}/>
                            </div>
                            <div className={"col px-0"}>
                                <FullscreenInput
                                    className={"form-control border-0 rounded-0"}
                                    type={"text"}
                                    name={"appSearchQuery"}
                                    value={this.state.appSearchQuery}
                                    placeholder={i18n.t('search-page.input-placeholder2')}
                                    onChange={this.handleQueryChange}
                                    onKeyUp={this.handleSearchInputKeyUp}
                                    ref={this.handleInputRef} /* Auto focus */
                                />
                                <ResetInput onClick={this.handleReset}>
                                    &times;
                                </ResetInput>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.searchType === SEARCH_TYPE_FULL && !this.props.disableGeolocationSearching &&
                        <button className={"btn btn-link btn-sm pl-3"}
                                onClick={_ => this.setSearchType(SEARCH_TYPE_GEO)}>
                            <FaSolidIcon name={"map-marker-alt"}/> {i18n.t('search-page.geolocation.use-geolocation-btn')}
                        </button>
                    }

                    {
                        (this.state.searchType === SEARCH_TYPE_GEO && this.state.maxDistance !== 15000) &&
                        <div className={"container text-center"}>
                            <button className={"btn btn-link"} onClick={this.toggleDistanceChooserVisibility}>
                                <FaSolidIcon name={"map-marker-alt"}/> {i18n.t('search-page.geolocation.radius-template', {radius: this.state.maxDistance})}
                            </button>
                        </div>
                    }

                    {
                        this.state.selectDistanceOpen && <div className={"container py-4"}>
                            <DistanceChooserBar default={this.state.maxDistance}
                                                onChoose={this.handleMaxDistanceChange}/>
                        </div>
                    }

                    {
                        (this.state.searchType === SEARCH_TYPE_GEO && !this.state.hasGeolocationPermission && !this.state.searching) &&
                        <div className={"container"}>
                            <p className={"lead"}>
                                {i18n.t('search-page.geolocation.instruction-label')}
                            </p>
                            <button className={"btn btn-soft-primary border-primary"}
                                    onClick={BookizonAppManager.openGeolocationSettings}>
                                <FaSolidIcon name={"location-arrow"}/> {i18n.t('search-page.geolocation.goto-settings-btn')}
                            </button>
                        </div>
                    }

                    {/* Se ho consenso posizione ma non ho la posizione */}
                    {
                        (this.state.searchType === SEARCH_TYPE_GEO && this.state.hasGeolocationPermission && (!this.state.lat || !this.state.lng)) &&
                        <div className={"container text-center mt-5"}>
                            <SearchingLocationImage src={mappaTelefono} className={"w-75"}/>
                            <h5 className={"text-center mt-3 text-primary"}>
                                {i18n.t('search-page.geolocation.retrieving-location-label')}
                            </h5>
                            {
                                this.state.positionRecoveryTimedout &&
                                <button className={"btn btn-link btn-sm"}
                                        onClick={_ => this.setSearchType(SEARCH_TYPE_FULL, true)}>
                                    {i18n.t('search-page.geolocation.dont-use-geolocation-btn')}
                                </button>
                            }
                        </div>
                    }

                    {/* Risultati di ricerca */}
                    <div className={"mt-2"}>
                        {
                            this.state.searchResults.map((n, i) => {
                                return <NegozioSearchResult
                                    {...n}
                                    key={i}
                                    onClick={_ => this.handleProfiloNegozioOpen(n)}
                                    accentColor={this.props.categoriaNegozio.color}
                                />
                            })
                        }
                    </div>

                    {/* Ho la posizione e devo ancora fare una ricerca */}
                    {
                        (!this.state.searching && !this.state.searchResults.length && !this.state.hasDoneSearch && (
                            this.state.searchType === SEARCH_TYPE_FULL || this.state.lat && this.state.lng
                        )) &&
                        <div className={"container mt-4"}>
                            <img src={makeSearchImage}
                                 className={"w-25 d-block mx-auto mb-2"}
                                 alt={"Fai la tua ricerca"}
                                 title={"Fai la tua ricerca"}
                            />
                            <h6 className={"text-center"} style={this.accentColorStyle}>
                                {i18n.t('search-page.description')}
                            </h6>
                        </div>
                    }

                    {/* Ho fatto una ricerca e non ho risultati */}
                    {
                        (
                            !this.state.searching && !this.state.searchResults.length && this.state.hasDoneSearch &&
                            (this.state.searchType === SEARCH_TYPE_FULL || (this.state.lat && this.state.lng))
                        ) &&
                        <div className={"container text-center mt-4"}>
                            <div className={"w-25 text-center mx-auto"}>
                                <img src={alienTaken} alt={"Alien taken"} className={"img-fluid"}/>
                            </div>
                            {this.getNoResultsMessage()}
                        </div>
                    }


                    {/* Vengono mostrati solo quando si sta cercando, ma occupano comunque spazione nella view*/}
                    {
                        this.state.searching &&
                        [...new Array(Math.max(1, this.state.lastVisibileSearchResultsNumber))]
                            .map((v, i) => <NegozioSearchResultPlaceholder key={i} visibility={true}/>)
                    }

                </Page>

                <ModalPortal>
                    <PreviewAppDiv>
                        <ProfiloAttivitaContainer
                            negozio={this.state.profiloNegozioOpen}
                            primaryColor={"#293069"}
                            backgroundColor={"#ffffff"}
                            isAppAdded={this.state.profiloNegozioOpen && !!this.state.profiloNegozioOpen.wasAdded}
                            expanded={this.state.profiloNegozioOpen && !!this.state.profiloNegozioOpen}
                            useNavbar={false}
                            onToggle={this.handleProfiloNegozioClose}
                        />
                    </PreviewAppDiv>
                </ModalPortal>
            </Fragment>
        )
    }

}

export const SearchingLocationImage = styled.img`
    animation: 1s ${pulseAnimation};
    animation-fill-mode: revert;
    animation-iteration-count: infinite;
`;

const PreviewAppDiv = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2001;
`;

const AccentedButton = styled.div`
    color: ${props => props.accentColor} !important;
    background-color: ${props => props.bgColor} !important;
    border-color: ${props => props.bgColor} !important;
`;

const AccentedOutlineButton = styled.div`
    color: ${props => props.accentColor} !important;
    background-color: white !important;
    border-color: ${props => props.accentColor} !important;
`;


SearchAppPage.propTypes = {
    active: PropTypes.bool,
    onDismiss: PropTypes.func,
    categoriaNegozio: PropTypes.object,
    initialSearchQuery: PropTypes.string,
    searchFilters: PropTypes.object || null,
    disableGeolocationSearching: PropTypes.bool || null //Default attiva
}