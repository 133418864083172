import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {ModuloGruppiAPI} from "../../../../api/ModuloGruppi/ModuloGruppiAPI";
import {swal, swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";
import AppConfig from "../../../../config/AppConfig";
import moment from "moment";
import {AppIconThumbnail} from "../../../../components/BaseComponents/AppIconThumbnail";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {OPEN_APP_REQUEST} from "../../../../const/AppEventsCostants";
import {ServiceBookResumeEditView} from "../../ServiceBook/ServiceBookResume/ServiceBookResumeEditView";
import {GroupBookResumeEditView} from "./GroupBookResumeEditView";
import RecensioniModal from "../../../Recensioni/RecensioniModal";
import {TIPO_ORDINE, RECENSIONI_TIPO_PRENOTAZIONE_GRUPPO} from "../../../../const/RecensioniConstants";
import {ECOMMERCE_ORDER_TYPE_DEFAULT} from "../../../../const/ModuloEcommerceCostants";
import {showFeedbackAvailableForGroups} from "../../../Recensioni/RecensioniHelperFunctions";
import styled, {keyframes} from "styled-components";
import {formatCurrency, formatNumber} from "../../../../helpers/CurrencyHelpers";
import {TYPE_WALLET_WELFARE} from "../../../../components/CheckoutDiscount/Wallets/WalletsConstants";
import {PAYMENT_METHOD_NAME_GRUPPI} from "../../../../const/PaymentMethodoCostants";


const PRENOTAZIONE_GRUPPO_STATUS_ATTESA = 'prenotazione.in_attesa';
const PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA = 'prenotazione.confermata';
const PRENOTAZIONE_GRUPPO_STATUS_RIFIUTATA_ESERCENTE = 'prenotazione.rifiutata_esercente';

const QrCodeContainer = styled.div`
    margin: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    background-image:url(${props => props.src});
    transition: 0.3s;
    
    &.enlargedQr {
        width: 260px;
        height: 260px;
    }
    
`;


export class GroupBookResumePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookData: null,
            usePrezzoServizioInQuestion: false,
            qrCodeActive: false
        }
    }

    componentDidMount() {
        this.fetchInfoPrenotazione();
    }


    getStatusLabel = _ => {
        switch (this.state.bookData.status) {
            case PRENOTAZIONE_GRUPPO_STATUS_ATTESA:
                return <span className={"badge badge-warning"}>In attesa di conferma</span>;
            case PRENOTAZIONE_GRUPPO_STATUS_CONFERMATA:
                return <span className={"badge badge-success"}>Confermato</span>;
            case PRENOTAZIONE_GRUPPO_STATUS_RIFIUTATA_ESERCENTE:
                return <span className={"badge badge-danger"}>Rifiutato</span>;
        }
    }


    fetchInfoPrenotazione = _ => {
        swalLoading('Attendere prego...');
        ModuloGruppiAPI.Books.getBookData(this.props.idPrenotazione)
            .then(data => {
                swal.safeClose();
                this.setState({bookData: data}, _ => {
                    //Se non ci sono prezzi settati nelle domande utilizzerò il prezzo del servizio
                    if (this.state.bookData.domande) {
                        const prezziNonZero = this.state.bookData.domande.map(d => d.prezzo).filter(p => p > 0)
                        if (!prezziNonZero.length) {
                            this.setState({usePrezzoServizioInQuestion: true})
                        }
                    }
                });
            })
            .catch(swalError);
    }

    getMapLink() {
        var mapLink = 'https://maps.google.com/maps?q=';
        if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
            mapLink = 'http://maps.apple.com/?q=';
        }
        return `${mapLink}${this.state.bookData.indirizzo_sede},${this.state.bookData.nome_negozio}`;
    }

    handleOpenApp = _ => FuxEvents.emit(OPEN_APP_REQUEST, this.state.bookData.id_negozio);


    getSubtotaleDeiPartecipanti = (domandaInfo = null) => {
        if (this.state.usePrezzoServizioInQuestion) {
            //Calcolo prezzo base
            var basePrice = (this.state.bookData.prezzo / this.state.bookData.partecipanti).toFixed(2)
            return (basePrice * domandaInfo.persone * this.state.bookData.books.length).toFixed(2)
        }
        if (domandaInfo.prezzo) {
            var prezzo = parseFloat(domandaInfo.prezzo).toFixed(2)
            if (this.state.bookData.perc_sconto > 0) {
                prezzo = this.calcolaSconto(prezzo, this.state.bookData.perc_sconto)
            }
            return (prezzo * domandaInfo.persone * this.state.bookData.books.length).toFixed(2)
        }
        return 0.00
    }


    getSubtotale = _ => {
        //Calcolo totale accessori
        let accessories = 0;
        if (this.state.bookData.accessories) {
            accessories = this.state.bookData.accessories.reduce((totale, acc) => {
                return totale + (acc.prezzo * acc.numero_prenotati)
            }, 0)
        }

        return (this.state.bookData.prezzo * this.state.bookData.books.length + accessories).toFixed(2)
    }

    getCouponRealValue = _ => {
        if (!this.state.bookData.coupon.id_coupon) return 0;

        if (this.state.bookData.coupon.tipo_sconto === 'euro') {
            return parseFloat(this.state.bookData.coupon.valore_sconto);
        } else {
            let factorSconto = (parseFloat(this.state.bookData.coupon.valore_sconto) / 100).toFixed(2);
            return ((this.getSubtotale()) * factorSconto).toFixed(2);
        }
    }

    getTotaleWithCoupon = _ => {
        var tot = (this.getSubtotale() - this.getCouponRealValue()).toFixed(2)
        return tot < 0 ? 0 : tot
    }

    //Calcola lo sconto applicato ad un prezzo
    calcolaSconto = (price, perc_sconto) => {
        var prezzo = parseFloat(price).toFixed(2)
        let sconto = parseFloat(perc_sconto).toFixed(2) / 100
        return prezzo - prezzo * sconto
    }

    toggleClassQrCode = _ => {
        this.setState({qrCodeActive: !this.state.qrCodeActive})
    }


    render() {
        if (!this.state.bookData) return null;

        const logoNegozioUrl = `${AppConfig.webServerUrl}/api/negozio/${this.state.bookData.id_negozio}/logoIcon/100`
        const momentDate = moment(this.state.bookData.datetime_inizio);
        const monthDay = momentDate.format('DD');
        const monthName = momentDate.format('MMMM');
        const dayFullName = momentDate.format('dddd');
        const hideEndTime = parseInt(this.state.bookData.hide_session_end_time) === 1;
        return (
            <Page
                title={"Dettagli"}

                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                onLeftIconClick={this.props.onDismiss}
                active={true}
            >

                <div className={"container pt-3"}>
                    {/** @MARK Heading */}
                    <div className={"text-center"}>
                        <AppIconThumbnail src={logoNegozioUrl} size={"50px"} className={"rounded-circle"}/>
                        <h4 className={"text-center text-on-bg-main"}>{this.state.bookData.nome_negozio}</h4>
                    </div>

                    <div className={"justify-content-center d-flex mt-2 mb-2"}>
                        <a href={"tel:" + this.state.bookData.telefono_sede}
                           className={"btn btn-sm btn-outline-primary"}>
                            <FaSolidIcon name={"phone"}/> Chiama ora
                        </a>
                        &nbsp;
                        <a href={this.getMapLink()} className={"btn btn-sm btn-outline-primary"}>
                            <FaSolidIcon name={"map-marker-alt"}/> Portami qui
                        </a>
                    </div>
                    <hr/>

                    <p>
                        Stato: {this.getStatusLabel()}<br/>
                        Codice prenotazione: #{this.state.bookData.id_prenotazione}<br/>
                    </p>

                    {/*Qr code*/}
                    {
                        this.state.bookData.qrcode_url &&
                        <div className={"card border-0 shadow-sm text-center p-3"} onClick={this.toggleClassQrCode}>
                            <QrCodeContainer className={this.state.qrCodeActive && 'enlargedQr'}
                                             src={this.state.bookData.qrcode_url}/>
                            <b>Clicca per ingrandire il QR Code!</b>
                        </div>
                    }

                    {/** @MARK Elenco delle fasce orarie prenotate */}
                    <div className={"mt-4"}>
                        <div className={"text-center text-on-bg-main"}>
                            Hai prenotato <b>{this.state.bookData.nome_gruppo}</b> per il giorno <b
                            className={"text-capitalize"}>{dayFullName} {monthDay} {monthName}</b>&nbsp;
                            {
                                this.state.bookData.books.length === 1 ?
                                    <Fragment>
                                        {
                                            hideEndTime ?
                                                <Fragment>
                                                    alle <b>{this.state.bookData.books[0].orario_inizio}</b>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    dalle <b>{this.state.bookData.books[0].orario_inizio}</b> alle <b>{this.state.bookData.books[0].orario_fine}</b>
                                                </Fragment>
                                        }
                                    </Fragment>
                                    :
                                    <Fragment>
                                        nei seguenti orari:
                                        <div>
                                            {
                                                this.state.bookData.books.map(b =>
                                                    <div>
                                                        {
                                                            hideEndTime ?
                                                                <Fragment>
                                                                    Alle <b>{b.orario_inizio}</b>
                                                                </Fragment>
                                                                :
                                                                <Fragment>
                                                                    Dalle <b>{b.orario_inizio}</b> alle <b>{b.orario_fine}</b>
                                                                </Fragment>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Fragment>
                            }
                        </div>
                    </div>

                    {
                        showFeedbackAvailableForGroups(this.state.bookData) &&
                        <div className={"mt-2 mb-2"}>
                            <RecensioniModal
                                id_operazione={this.state.bookData.id_prenotazione}
                                tipo_operazione={RECENSIONI_TIPO_PRENOTAZIONE_GRUPPO}
                            />
                        </div>
                    }

                    {/*Informazioni pagamento*/}
                    {
                        this.state.bookData.metodo_pagamento &&
                        <div className={"my-2"}><b>Metodo di pagamento:</b>
                            <div className={"card shadow-sm border-0 p-3"}>
                                {
                                    this.state.bookData.payment_report.payments_type.length ?
                                        this.state.bookData.payment_report.payments_type.map((p, k) => {
                                            return (
                                                <div key={k} className={"d-flex justify-content-between"}>
                                                    <span>{PAYMENT_METHOD_NAME_GRUPPI[p.type] || p.type.replaceAll("_", " ").capitalize()}</span>
                                                    <b>{formatCurrency(p.importo, 'EUR', 'it-IT')}</b>
                                                </div>
                                            )
                                        })
                                        :
                                        this.state.bookData.metodo_pagamento === 'stripe' ? 'Carta di credito'
                                            : this.state.bookData.metodo_pagamento === 'consegna' ? 'Contanti' : 'Carta di credito'
                                }
                            </div>
                        </div>
                    }

                    {/*Informazioni sul coupon - promozione*/}
                    {
                        this.state.bookData.coupon &&
                        <div className={"mt-3"}>
                            {
                                this.state.bookData.coupon.id_promozione ?
                                    <div className={"p-3 bg-soft-success shadow-sm"}>
                                        <span>
                                            Hai utilizzato una <b>promozione</b> per questa prenotazione! &#x1F389;
                                            <br/>
                                            Il tuo prezzo viene <b>scontato del {this.state.bookData.coupon.valore_sconto}%</b> <br/>
                                            {
                                                this.state.bookData.coupon.min_spesa > 0 &&
                                                <span>
                                                   Su una spesa minima di €{this.state.bookData.coupon.min_spesa}
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    :
                                    <div>
                                        <b>Info Coupon Inserito</b>
                                        <div className={"card shadow-sm border-0 p-3"}>
                                            <span><b>Codice:</b> {this.state.bookData.coupon.codice}</span>
                                            <span><b>Sconto:</b> {
                                                this.state.bookData.coupon.tipo_sconto === 'percentuale' ?
                                                    <span>-{this.state.bookData.coupon.valore_sconto}%</span> :
                                                    <span>-€{this.state.bookData.coupon.valore_sconto}</span>
                                            }
                                            </span>
                                            {
                                                this.state.bookData.coupon.min_spesa > 0 &&
                                                <span>
                                                    <b>Spesa minima:</b> €{this.state.bookData.coupon.min_spesa}
                                                </span>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    }


                    {/* Partecipanti */}
                    <div className={"mt-3"}>
                        <b>Partecipanti:</b>
                        <div className={"card shadow-sm border-0 p-3"}>
                            {
                                //Se non ci sono domande stampo il numero dei partecipanti
                                this.state.bookData.domande && this.state.bookData.domande.length > 0
                                    ?
                                    this.state.bookData.domande.map((d, i) => {
                                        return <div className={"row align-items-center"} key={i}>
                                            <div className={"col-8"}>{d.testo} ({d.persone})</div>
                                            <div className={"col-4"}><b>€{this.getSubtotaleDeiPartecipanti(d)}</b></div>
                                        </div>
                                    })
                                    :
                                    <div className={"row align-items-center"}>
                                        <div className={"col-8"}>Prenotazione
                                            per {this.state.bookData.partecipanti}</div>
                                        {/*Se non ci sono domande il subtotale è uguale al subtotale*/}
                                        <div className={"col-4"}><b>€{this.getSubtotale()}</b></div>
                                    </div>

                            }
                        </div>
                    </div>

                    {/* Accessori */}
                    {
                        this.state.bookData.accessories && this.state.bookData.accessories.length > 0 &&
                        <div className={"mt-3"}>
                            <b>Accessori:</b>
                            <div className={"card shadow-sm border-0 p-3"}>
                                {
                                    this.state.bookData.accessories.map((a, i) => {
                                        return <div className={"row align-items-center"} key={i}>
                                            <div className={"col-8"}>{a.nome_accessorio} ({a.numero_prenotati})</div>
                                            <div className={"col-4"}>
                                                <b>€{(a.prezzo * a.numero_prenotati).toFixed(2)}</b></div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }

                    {/*Getsione e stampa del totale*/}
                    {
                        (!!this.state.bookData.coupon) ?

                            <div className={"card border-0 p-3 shadow-sm my-2"}>
                                <div className={"row align-items-center"}>
                                    <div className={"col-8"}>Sub Totale:</div>
                                    <div className={"col-4"}><b>€{this.getSubtotale()}</b></div>
                                </div>

                                <div className={"row align-items-center"}>
                                    <div className={"col-8"}>Sconto:</div>
                                    <div className={"col-4"}><b>€{this.getCouponRealValue()}</b></div>
                                </div>

                                <hr/>

                                <div className={"row align-items-center"}>
                                    <div className={"col-8"}>Totale:</div>
                                    <div className={"col-4"}><b>€{this.getTotaleWithCoupon()}</b></div>
                                </div>
                            </div>

                            :

                            <div className={"card border-0 p-3 shadow-sm my-2"}>
                                <div className={"row align-items-center"}>
                                    <div className={"col-8"}>Totale:</div>
                                    {/*Se non c'é coupon il totale coincide con il subtotale*/}
                                    <div className={"col-4"}><b>€{this.getSubtotale()}</b></div>
                                </div>
                            </div>
                    }


                    {/** @MARK Note utente */}
                    {
                        !!this.state.bookData.note_utente &&
                        <div className={"mt-3"}>
                            <span className={"text-muted"}>Le tue note</span>
                            <p>
                                <SafeHtmlContainer html={this.state.bookData.note_utente}/>
                            </p>
                        </div>
                    }

                    {/** @MARK Cancellazione */}
                    <GroupBookResumeEditView
                        bookData={this.state.bookData}
                        onDelete={this.props.onDismiss}
                        onUpdate={this.fetchInfoPrenotazione}
                    />

                    {
                        this.props.showOpenApp &&
                        <button className={"btn btn-soft-primary btn-block mt-3"} onClick={this.handleOpenApp}>
                            Apri l'app <FaSolidIcon name={"chevron-right"}/>
                        </button>
                    }

                </div>
            </Page>
        )
    }

}

GroupBookResumePage.propTypes = {
    idPrenotazione: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDismiss: PropTypes.func,
    showOpenApp: PropTypes.bool
}
