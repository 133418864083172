import React from "react";
import PropTypes from "prop-types";
import "./YoutubeEmbed.css";

export const YoutubeEmbed = (props) => (
    <iframe
        width="100%"
        height="100%"
        style={props.style}
        src={`https://www.youtube.com/embed/${props.embedId || youtube_parser(props.url)}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
    />
);


function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string,
    url: PropTypes.string,
    style: PropTypes.object
};
