import React, {Fragment} from "react";
import {PluralString} from "../../helpers/UIUtility";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import ModalPortalHOC from "../../components/BaseComponents/ModalPortalHOC";
import {UserDisputeListPage} from "./UserDisputeListPage";
import {swal, swalError, swalLoading} from "../../helpers/SweetAlertWrapper";
import {DisputesAPI} from "../../api/Disputes/DisputesAPI";
import {DISPUTE_STATUS_OPEN} from "../../const/DisputesCostants";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {USER_DISPUTE_REQUEST_EVT} from "../../const/AppEventsCostants";
import {DLA_NEW_DISPUTE} from "../../const/DeepLinkActionsCostants";

export class UserDisputeButtonView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disputes: [],
            pageOpen: false,
        }
    }

    componentDidMount() {
        //Le info generali delle dispute sono passate tramite props, ma i dati delle chat li recupero tramite API specifiche
        this.fetchDisputes();
        FuxEvents.on(USER_DISPUTE_REQUEST_EVT, this.fetchDisputes);
        FuxEvents.on(DLA_NEW_DISPUTE, this.fetchDisputes);
    }

    componentWillUnmount() {
        FuxEvents.off(USER_DISPUTE_REQUEST_EVT, this.fetchDisputes);
        FuxEvents.off(DLA_NEW_DISPUTE, this.fetchDisputes);
    }

    togglePage = _ => this.setState({pageOpen: !this.state.pageOpen});

    fetchDisputes = silent => {
        console.log(silent);
        if (!silent) swalLoading('Aggiornamento controversie in corso...');
        DisputesAPI.User.getDisputes(DISPUTE_STATUS_OPEN)
            .then(disputes => this.setState({disputes: disputes}, _ => swal.safeClose()))
            .catch(swalError);
    }

    render() {
        if (!this.state.disputes || !this.state.disputes.length) return '';

        return (
            <Fragment>
                <div className={"container my-3"}>
                    <div className={"d-flex align-items-center justify-content-center bg-white shadow-sm rounded p-2"}
                         onClick={this.togglePage}>
                        <FaSolidIcon name={"exclamation-triangle"} className={"text-danger"}/>&nbsp;
                        Hai&nbsp;{this.state.disputes.length}&nbsp;
                        <PluralString n={this.state.disputes.length} more={"richieste"} single={"richiesta"}/>
                        &nbsp;di rimborso
                    </div>
                </div>
                <ModalPortalHOC>
                    {
                        this.state.pageOpen &&
                        <UserDisputeListPage
                            onDismiss={this.togglePage}
                            onUpdate={this.fetchDisputes}
                            disputes={this.state.disputes}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }
}