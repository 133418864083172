/**@MARK: Imports  */
import './wdyr'; // <--- first import
import React from 'react'
import ReactDOM from 'react-dom'
import AuthAPI from "./api/AuthAPI";
import FuxCookies from "./lib/FuxFramework/FuxCookies";
import AppConfig from "./config/AppConfig";
import {swal, swalConfirm} from "./helpers/SweetAlertWrapper";
import FuxEvents from "./lib/FuxFramework/FuxEvents";
import DashboardUserAdminAPI from "./api/DashboardUserAdminAPI";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {
    NativeMessageHandler,
    NM_FIREBASE_RETRIEVE_FCM_TOKEN,
    NM_IN_APP_BROWSER_DISMISS
} from "./native/NativeMessageHandler";
import {
    APP_OPEN_DLA_HOMEPAGE_ACTION_MAP,
    DLA_ADMIN_CHAT_MESSAGE,
    DLA_APP_OPEN,
    DLA_PAYMENT_LINK,
    DLA_USER_CHAT_MESSAGE,
    NEED_AUTH_ACTIONS,
    waitDispatchDLAPermission
} from "./const/DeepLinkActionsCostants";
import './css/style.css';
/* globals AndroidInterface, webkit, AndroidBridgeSendMessage */
/**@MARK: Polyfills  */
import 'promise-polyfill/src/polyfill';
import 'url-polyfill';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/features/array/some'
import 'es6-object-assign/auto';
import 'fetch-polyfill/fetch';
import 'intersection-observer';
import smoothscroll from 'smoothscroll-polyfill';
import {
    ADD_APP_REQUEST,
    APP_HOMEPAGE_ACTION,
    APP_MOUNTED,
    OPEN_APP_REQUEST,
    UPDATE_USER_BOOKS,
    UPDATE_USER_NOTIFICATIONS,
    UPDATE_USER_ORDINI
} from "./const/AppEventsCostants";
import {BookizonRouter} from "./router/BookizonRouter";
import {NegozioOpenStore} from "./stores/NegozioOpenStore";
import moment from "moment";
import localization from "moment/locale/it";
import FuxNotificationSystemProvider from "./components/GenericUI/FuxNotificationSystem/FuxNotificationSystem";
import NegozioAPI from "./api/NegozioAPI";
import {Base64} from "./helpers/Base64";

moment.updateLocale('it', localization);

FuxCookies.setCookie('id_negozio', NegozioOpenStore.id_negozio);

const fetchStyles = (id_negozio, templateUrl) =>
    fetch(
        AppConfig.webServerUrl + "/bootstrap-override.css.php?id_negozio=" + id_negozio + (templateUrl ? '&templateUrl=' + templateUrl : '')
    ).then(
        response => response.text()
    ).catch(e => {
        console.log(e);
    });

let OS__geolocationPermissionGrant = false;
let OS__geolocationPermissionAsked = false;


export var BookizonAppManager = {
    start: function () {
        var currentUrl = new URL(window.location.href);

        //Setto la modalità di esecuzione
        if (currentUrl.searchParams.get('fromWebview')) {
            AppConfig.isBookizonWebView = true;
        }

        //Verifico se ci sono proprietà di templating
        var template = currentUrl.searchParams.get('template');
        if (template) {
            try {
                AppConfig.isTemplateView = true;
                AppConfig.templateData = JSON.parse(atob(template));
            } catch (e) {
                console.log(e);
            }
        }

        var templateUrl = currentUrl.searchParams.get('templateUrl');
        if (templateUrl) {
            AppConfig.templateUrl = templateUrl;
        }


        //Imposto l'id negozio
        var id_negozio = currentUrl.searchParams.get('id_negozio');
        if (id_negozio) {
            NegozioOpenStore.id_negozio = id_negozio;
        }

        //Auto-setto la modalità di esecuzione come launcher se non vi sono altri dati che indicano il contrario
        if (!NegozioOpenStore.id_negozio && !AppConfig.isTemplateView) {
            AppConfig.isBookizonWebView = true;
        }

        //Modalità di autologin
        var accessToken = currentUrl.searchParams.get('autologinToken');
        if (accessToken) {
            localStorage[(NegozioOpenStore.id_negozio ? NegozioOpenStore.id_negozio : "global") + "_accessToken"] = accessToken;
        }

        //Controllo se deve essere aperto solo il menu digitale
        if (currentUrl.searchParams.get('onlyMenuDigitale')) {
            AppConfig.menuDigitale = true;
        }


        //Controllo se deve essere aperto solo il menu digitale
        if (currentUrl.searchParams.get('profiloAttivita')) {
            AppConfig.profiloAttivita = true;
        }

        //Controllo se viene passata la versione dell'interfaccia JS
        var jsv = currentUrl.searchParams.get('jsv');
        if (jsv) {
            AppConfig.jsInterfaceVersion = parseInt(jsv);
        }
    },
    setNativePrimaryColor: function (color) {
        const message = {action: 'setPrimaryColor', color: color};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.setPrimaryColor) {
            AndroidInterface.setPrimaryColor(color);
        }
    },
    __CURRENT_TOP_AREA_COLOR: null,
    __TOP_AREA_COLOR_CHECKPOINT: null,
    setNativeTopUnsafeAreaColor: function (color, ref) {
        //console.log("SET TOP COLOR ", color, ref);
        if (!color) {
            console.log('INVALID COLOR ' + color);
            return;
        }
        BookizonAppManager.__CURRENT_TOP_AREA_COLOR = color;

        if (AppConfig.jsInterfaceVersion < 2) {
            return BookizonAppManager.setNativePrimaryColor(color);
        }

        const message = {action: 'setTopUnsafeAreaColor', color: color};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.setTopUnsafeAreaColor) {
            AndroidInterface.setTopUnsafeAreaColor(color);
        }

        var c = color.substring(1);      // strip #
        var rgb = parseInt(c, 16);   // convert rrggbb to decimal
        var r = (rgb >> 16) & 0xff;  // extract red
        var g = (rgb >> 8) & 0xff;  // extract green
        var b = (rgb >> 0) & 0xff;  // extract blue
        const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        BookizonAppManager.setNativeStatusBarStyle(brightness > 128 ? 'dark' : 'light');
    },
    getNativeTopUnsafeAreaColor: _ => BookizonAppManager.__CURRENT_TOP_AREA_COLOR,
    topUnsafeAreaCheckpoint: function () {
        BookizonAppManager.__TOP_AREA_COLOR_CHECKPOINT = BookizonAppManager.__CURRENT_TOP_AREA_COLOR;
    },
    restoreTopUnsafeAreaCheckpoint: function () {
        BookizonAppManager.setNativeTopUnsafeAreaColor(BookizonAppManager.__TOP_AREA_COLOR_CHECKPOINT, "restore");
    },
    __CURRENT_BOTTOM_AREA_COLOR: '#ffffff',
    __BOTTOM_AREA_COLOR_CHECKPOINT: null,
    setNativeBottomUnsafeAreaColor: function (color, from) {
        if (AppConfig.jsInterfaceVersion < 2) {
            return 0;
        }
        const message = {action: 'setBottomUnsafeAreaColor', color: color};
        BookizonAppManager.__CURRENT_BOTTOM_AREA_COLOR = color;
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.setBottomUnsafeAreaColor) {
            AndroidInterface.setBottomUnsafeAreaColor(color);
        }
    },
    getNativeBottomUnsafeAreaColor: _ => BookizonAppManager.__CURRENT_BOTTOM_AREA_COLOR,
    bottomUnsafeAreaCheckpoint: function () {
        BookizonAppManager.__BOTTOM_AREA_COLOR_CHECKPOINT = BookizonAppManager.__CURRENT_BOTTOM_AREA_COLOR;
    },
    restoreBottomUnsafeAreaCheckpoint: function () {
        BookizonAppManager.setNativeBottomUnsafeAreaColor(BookizonAppManager.__BOTTOM_AREA_COLOR_CHECKPOINT, "restore");
    },
    __STATUS_BAR_STYLE: 'dark',
    setNativeStatusBarStyle: function (style, from) {
        console.log("SET STATUS BAR STYLE", style);
        if (AppConfig.jsInterfaceVersion < 2) {
            return 0;
        }
        const message = {action: 'setStatusBarStyle', style: style};
        BookizonAppManager.__STATUS_BAR_STYLE = style;
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.setStatusBarStyle) {
            AndroidInterface.setStatusBarStyle(style);
        }
    },
    getNativeStatusBarStyle: _ => BookizonAppManager.__STATUS_BAR_STYLE,
    askGeolocationPermission: function () {
        console.log("BROWSER LOCATION PERMISSION");
        if (AppConfig.jsInterfaceVersion < 2) {
            return 0;
        }
        const message = {action: 'askGeolocationPermission'};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.askGeolocationPermission) {
            AndroidInterface.askGeolocationPermission();
        } else {
            if (OS__geolocationPermissionAsked) {
                setTimeout(
                    _ => window.nativeMessageHandler({action: 'geolocation.update', lat: 40.70142, lon: 17.33304}),
                    1000
                );
                return;
            }
            OS__geolocationPermissionAsked = true;
            swalConfirm('[SIMULATORE PERMESSI LOCALIZZAZIONE]', 'Autorizza', 'Rifiuta')
                .then(_ => {
                    OS__geolocationPermissionGrant = true;
                    window.nativeMessageHandler({action: 'geolocation.permission', permission: true});
                    setTimeout(
                        _ => window.nativeMessageHandler({
                            action: 'geolocation.update',
                            lat: 40.70142,
                            lon: 17.33304
                        }),
                        1000
                    );
                })
                .catch(_ => {
                    OS__geolocationPermissionGrant = false;
                    window.nativeMessageHandler({action: 'geolocation.permission', permission: false});
                });
        }
    },
    getGeolocationPermission: function () {
        if (AppConfig.jsInterfaceVersion < 2) {
            window.nativeMessageHandler({action: 'geolocation.permission', permission: OS__geolocationPermissionGrant});
        }
        const message = {action: 'getGeolocationPermission'};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.getGeolocationPermission) {
            AndroidInterface.getGeolocationPermission();
        } else {
            window.nativeMessageHandler({action: 'geolocation.permission', permission: OS__geolocationPermissionGrant});
        }
    },
    openGeolocationSettings: function () {
        if (AppConfig.jsInterfaceVersion < 2) {
            return 0;
        }
        const message = {action: 'openGeolocationSettings'};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.openGeolocationSettings) {
            AndroidInterface.openGeolocationSettings();
        }
    },
    openInAppBrowser: function (url) {
        if (AppConfig.jsInterfaceVersion < 2) {
            return 0;
        }
        const message = {action: 'openInAppBrowser', url: url};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            console.log("AndroidBridgeSendMessage")
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.openInAppBrowser) {
            console.log("AndroidInterface")
            AndroidInterface.openInAppBrowser(url);
        } else {
            console.log("ELSE POPUP")
            let popup = window.open(url, "Popup browser", 'height=736,width=414');
            if (window.focus) {
                popup.focus()
            }
            let popupTick = setInterval(function () {
                if (popup.closed) {
                    clearInterval(popupTick);
                    FuxEvents.emit(NM_IN_APP_BROWSER_DISMISS);
                }
            }, 500);
        }
    },
    getMobileOperatingSystem: _ => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    },
    superBackButtonPressed: function () {
        if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage({action: 'superBackButtonPressed'});
        } else if (window.AndroidInterface && AndroidInterface.superBackButtonPressed) {
            AndroidInterface.superBackButtonPressed();
        }
    },
    shareData: function (data) {
        const message = {action: 'shareData', data: data};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.openInAppBrowser) {
            AndroidInterface.shareData(data);
        } else {
            if (navigator.share) {
                navigator.share({
                    text: data
                });
            }
        }
    },
    scanQRCode: function () {
        const message = {action: 'scanQRCode'};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        } else if (window.AndroidInterface && AndroidInterface.openInAppBrowser) {
            AndroidInterface.scanQRCode();
        }
    },
    androidImageChooserDialog: function () {
        const message = {action: 'imageChooserDialog'};
        if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        }
    },
    sendMessage: function (message) {
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        }
    },
    invalidateFcmToken: function () {
        const message = {action: 'invalidateFcmToken'};
        if (window.webkit !== undefined) {
            window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
        } else if (window.AndroidBridgeSendMessage !== undefined) {
            window.AndroidBridgeSendMessage(message);
        }
    },
    retrieveFcmToken: function () {
        return new Promise(resolve => {
            const handleTokenRetrieved = data => {
                FuxEvents.off(NM_FIREBASE_RETRIEVE_FCM_TOKEN, handleTokenRetrieved);
                resolve(data.fcm_token);
            }
            FuxEvents.on(NM_FIREBASE_RETRIEVE_FCM_TOKEN, handleTokenRetrieved);

            const message = {action: 'retrieveFcmToken'};
            if (window.webkit !== undefined) {
                window.webkit.messageHandlers.javascriptMessageHandler.postMessage(message);
            } else if (window.AndroidBridgeSendMessage !== undefined) {
                window.AndroidBridgeSendMessage(message);
            }
        });
    },
};

class AppLocaleWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'it'
        }
    }

    componentDidMount() {
        FuxEvents.on('changeLang', lang => {
            this.setState({lang: lang});
            i18n.changeLanguage(lang);
        });
    }

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <FuxNotificationSystemProvider/>
                <BookizonRouter/>
            </I18nextProvider>
        )
    }
}


//initReactFastclick();
smoothscroll.polyfill();
BookizonAppManager.start();
ReactDOM.render(
    <AppLocaleWrapper/>,
    document.getElementById('root')
);
FuxEvents.emit('renderApp');
BookizonAppManager.setNativeTopUnsafeAreaColor("#ffffff");

/**
 * @JavaScriptInterface
 * @param linkedApps String (base64(JSON string))
 * */
window.setAppIds = function (linkedApps) {
    try {
        linkedApps = JSON.parse(atob(linkedApps));
        AppConfig.linkedApps = linkedApps;
        FuxEvents.emit('renderApp');
    } catch (e) {
        swal.fire({title: e.message, text: linkedApps});
    }
};

FuxEvents.on(DLA_PAYMENT_LINK, (id_b64) => {
    BookizonAppManager.openInAppBrowser(`https://proinn.web.app/bookizon/redirect.html?url=`+Base64.encode(`${AppConfig.webServerUrl}/dl/@pay:${id_b64}`));
});

export const getActionDataByActionLink = actionLinkSegment => {
    if (actionLinkSegment.indexOf('@') === 0) {
        let duePuntiPos = actionLinkSegment.indexOf(':');
        if (duePuntiPos > 0) {
            let actionName = actionLinkSegment.substr(1, duePuntiPos - 1);
            let param = actionLinkSegment.substr(duePuntiPos + 1, actionLinkSegment.length - duePuntiPos);
            return {action: actionName, param: param};
        }
    }
    return null;
}

/**
 * @JavaScriptInterface
 * @description Esegue un'azione in seguito all'apertura dell'app tramite link o click interno di link "azione"
 * @param codice String
 * */
let __LASTQR_CODE = null;
window.addAppId = function (codice) {
    if (!codice) {
        return;
    }
    const actionData = getActionDataByActionLink(codice);
    try {
        console.log(`ACTION DATA:${JSON.stringify(actionData)}`);
    } catch (e) {
    }

    if (actionData) {
        const actionEventName = `DL_${actionData.action}`;

        const dispatchEvent = _ => {
            console.log("DISPATCHING EVENT", actionEventName, actionData.param);
            FuxEvents.emit(actionEventName, actionData.param);
            AuthAPI.offStateChanged(dispatchEvent);
        }

        //Verifico se l'azione apre l'app + una sua schermata all'interno una volta aperta
        if (APP_OPEN_DLA_HOMEPAGE_ACTION_MAP[actionEventName] !== undefined) {
            FuxEvents.emit(DLA_APP_OPEN, actionData.param); //Richiedo l'apertura di un'app specifica
            FuxEvents.waitForEvent(APP_MOUNTED) //Attendo l'apertura dell'app
                .then(_ => {
                    FuxEvents.emit(APP_HOMEPAGE_ACTION, APP_OPEN_DLA_HOMEPAGE_ACTION_MAP[actionEventName]);
                });

            return;
        }

        const promises = [];

        //Verifico se l'azione necessità di login
        if (NEED_AUTH_ACTIONS.indexOf(actionEventName) !== -1) {
            promises.push(AuthAPI.waitForLoggedIn());
        }

        promises.push(waitDispatchDLAPermission(actionEventName));

        Promise.all(promises).then(dispatchEvent)

    } else {
        __LASTQR_CODE = codice;
        console.log(__LASTQR_CODE);
        if (AuthAPI.currentUser) {
            AuthAPI.onStateChanged(addAppByCodice);
        } else {
            NegozioAPI.getInfoByCode(codice)
                .then(info => FuxEvents.emit(OPEN_APP_REQUEST, info.id_negozio))
        }
    }
};


/**
 * @description Restituisce l'action indicata dal segmento terminale di un deep link del tipo https://bookizon.it/dl/@{azione}:{parametro} */


const addAppByCodice = user => {
    console.log("addAppByCodice", __LASTQR_CODE, user);
    if (!user) return;
    let codice = __LASTQR_CODE;
    __LASTQR_CODE = null;
    if (codice === '' || codice === '0') { //Per l'apertura dell'app da un link senza aggiunta
        return;
    }
    FuxEvents.emit(ADD_APP_REQUEST, codice);
    AuthAPI.offStateChanged(addAppByCodice);
}

window.nativeBackButtonPressed = _ => {
    const elems = document.getElementsByClassName("__androidDismissable__");
    let visible = null;
    for (let i = 0; i < elems.length; i++) {
        if (window.isVisible(elems[i])) {
            visible = elems[i];
        }
    }
    console.log(`BACK BUTTON PRESSED VISIBLE ${visible ? 1 : 0}`);
    if (visible) {
        console.log("SIMULATING CLICK");
        visible.click();
    } else {
        BookizonAppManager.superBackButtonPressed();
    }
};

window.isVisible = (elem) => {
    if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.');
    const style = getComputedStyle(elem);
    if (style.display === 'none') return false;
    if (style.visibility !== 'visible') return false;
    if (style.opacity < 0.1) return false;
    if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height +
        elem.getBoundingClientRect().width === 0) {
        return false;
    }

    const elemCenter = {
        x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
        y: elem.getBoundingClientRect().top + elem.offsetHeight / 2
    };
    const elemTopLeft = {
        x: elem.getBoundingClientRect().left + 1,
        y: elem.getBoundingClientRect().top + 1
    };
    const elemBottomRight = {
        x: elem.getBoundingClientRect().left + elem.offsetWidth - 1,
        y: elem.getBoundingClientRect().top + elem.offsetHeight - 1
    };

    if (elemCenter.x < 0) return false;
    if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false;
    if (elemCenter.y < 0) return false;
    if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false;

    const pointsToCheck = [elemCenter, elemTopLeft, elemBottomRight];
    for (var i = 0; i < pointsToCheck.length; i++) {
        const point = pointsToCheck[i];
        var pointContainer = document.elementFromPoint(point.x, point.y);
        if (pointContainer) {
            do {
                if (pointContainer === elem) return true;
            } while (pointContainer = pointContainer.parentNode);
        }
    }

    return false;
}

var fcmMemorized = false;
window.setFCMToken = function (fcm_token) {
    if (!fcmMemorized) {
        const url = new URL(window.location.href);
        let platform = url.searchParams?.get('platform');
        if (!platform) {
            if (window.AndroidBridgeSendMessage !== undefined) {
                platform = 'android';
            } else if (window.webkit !== undefined) {
                platform = 'ios';
            }
        }
        const bundleVersion = url.searchParams?.get('bundleVersion');
        AuthAPI.setFCMToken(fcm_token, platform, bundleVersion);
        fcmMemorized = true;
    }
};

//Fallback per app native singole
window.AuthAPI = {
    setFCMToken: function (token) {
        window.setFCMToken(token);
    }
}

window.fetchPushNotificationList = function (skipNextBooks, skipOrdini) {
    if (!AuthAPI.currentUser) {
        return;
    }
    FuxEvents.emit(UPDATE_USER_NOTIFICATIONS);
    FuxEvents.emit(DLA_USER_CHAT_MESSAGE);
    FuxEvents.emit(DLA_ADMIN_CHAT_MESSAGE);
    if (AuthAPI.currentUser.isAdmin) {
        DashboardUserAdminAPI.getData()
            .then(data => {
                AuthAPI.currentUser.negozio = data;
            });
    }
    if (!skipNextBooks) {
        FuxEvents.emit(UPDATE_USER_BOOKS);
    }
    if (!skipOrdini) {
        FuxEvents.emit(UPDATE_USER_ORDINI);
    }
};

window.iosPaymentGatewayDismiss = function () {
    FuxEvents.emit('NativePaymentPageClosed', 'IOS');
}


window.nativeMessageHandler = NativeMessageHandler;

window.addEventListener('GeckoMessage', function (m) {
    console.log(`WIN EVENT LISTENER: ${JSON.stringify(m)}`);
});

//Disabilito il pinch to zoom
document.documentElement.addEventListener('gesturestart', function (event) {
    event.preventDefault();
}, false);


if (window.location.href.indexOf("dev") !== -1 || window.location.href.indexOf("localhost") !== -1) {
    //document.getElementById("dev-label").classList.remove("d-none");
    //document.getElementById("dev-label").innerHTML = `DEV:${process.env.REACT_APP_VERSION}`;
}


/*PROTOTYPES*/
Number.prototype.countDecimals = function () {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0;
}

localStorage.__last_lat = null;
localStorage.__last_lng = null;
