import React, {Fragment} from "react";
import Page from "../BaseComponents/Page/Page";
import {FaSolidIcon} from "../FontAwesomeIcons";
import i18n from "../../i18n";
import PropTypes from "prop-types";
import YourReceiptPage from "../ModuloCassa/YourReceiptPage";
import {WalletsAPI} from "../../api/Wallets/WalletsAPI";
import {EllipsisLoader} from "../GenericUI/EllipsisLoader/EllipsisLoader";
import WalletsStyle from "./WalletsStyle.css"
import WalletView from "./WalletView";
import SingleWalletPage from "./SingleWallet/SingleWalletPage";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";

export default class WalletsListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWalletsWithZeroCredit: false,
            walletToOpenInfo: false
        }
    }

    showWalletsWithZeroCreditToggle = e => this.setState({showWalletsWithZeroCredit: !this.state.showWalletsWithZeroCredit});

    //Permette di aprire e chiudere la pagina di un singolo wallet
    //Se il parametro è a null la pagina verrà chiusa se aperta
    openWalletPage = (walletInfo) => this.setState({walletToOpenInfo: walletInfo});
    closeWalletPage = () => this.setState({walletToOpenInfo: false});

    render() {

        const positiveWallets = this.props.wallets.filter(w => w.saldo_disponibile > 0)
        const zeroWallets = this.props.wallets.filter(w => w.saldo_disponibile <= 0)

        return (
            <Fragment>
                {/*SUDDIVISIONE NEGOZI*/}

                <div>
                    {
                        this.props.showShopName &&
                        <div className={"text-muted mt-4"}>{this.props.wallets[0]?.nome_negozio || ''}</div>
                    }

                    <div className={"row"}>
                        {/*WALLET SALDO POSITIVO*/}
                        {
                            positiveWallets.map(w => {
                                return <div className={"col-6 py-2 px-2"} key={w.id_wallet}>
                                    <WalletView wallet={w} handleOnClick={this.openWalletPage}/>
                                </div>
                            })
                        }
                    </div>

                    {
                        (zeroWallets && zeroWallets.length > 0) &&
                        <div className={"text-muted small"} onClick={this.showWalletsWithZeroCreditToggle}>
                            Mostra con saldo a 0 euro {
                            this.state.showWalletsWithZeroCredit
                                ? <FaSolidIcon name={"caret-down"}/> : <FaSolidIcon name={"caret-right"}/>
                        }
                        </div>
                    }
                    <div className={"row"}>
                        {/*WALLET SALDO A ZERO*/}
                        {
                            this.state.showWalletsWithZeroCredit &&
                            zeroWallets.map(w => {
                                return <div className={"col-6 py-2 px-2"} key={w.id_wallet}>
                                    <WalletView wallet={w}/>
                                </div>

                            })
                        }
                    </div>
                </div>

                <ModalPortalHOC>
                    {
                        this.state.walletToOpenInfo &&
                        <SingleWalletPage
                            wallet={this.state.walletToOpenInfo}
                            onDismiss={this.closeWalletPage}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }
}


WalletsListView.propTypes = {
    wallets: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
    showShopName: PropTypes.bool
}
