import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";

export default class DipendenteWelfareGiftCardsBrandsItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <div className={"list-group-item align-items-center d-flex justify-content-between"}
                 onClick={_ => this.props.onBrandClick(this.props.brand)}>
                <div><b>{this.props.brand.name}</b></div>
                <div><FaSolidIcon name={"chevron-right"}/></div>
            </div>
        )
    }
}

DipendenteWelfareGiftCardsBrandsItem.propTypes = {
    brand: PropTypes.object,
    onBrandClick: PropTypes.func
};