import {DIPENDENTE_ANYONE_OPTION} from "../../../const/ModuloServiziCostants";

/**
 * @description: Filtro le categorie in base al dipendente scelto.
 *  categorieWhitelist = [{id_categoria: 12}, {id_categoria: 13}, ...]
 *  serviziWhitelist = [{id_categoria: 12}, {id_categoria: 13}, ...]
 * */
export const filterCategoryTree = ({categoryTree, listaDipendenti, listaServizi, bookInfo}, categorieWhitelist = [], serviziWhitelist = []) => {
    const leafCategoriesToRemove = [];
    const categoryList = flattedCategoryTree(categoryTree, "child");

    //Rimuovo tutte le categorie in cui non ci sono servizi svolti dal dipendente scelto
    if (bookInfo.id_dipendente && bookInfo.id_dipendente !== DIPENDENTE_ANYONE_OPTION) {

        const dipendente = listaDipendenti.find(d => d.id_dipendente === bookInfo.id_dipendente);
        const serviziDipendente = listaServizi.filter(s => dipendente.servizi.includes(s.id_servizio));

        //Segno da eliminare tutte le categorie (foglia il cui ID non è assegnato a nemmeno 1 servizio del dipendente
        categoryList.map(c => {
            if ((!c.child || !c.child.length) && !serviziDipendente.find(s => s.categoria === c.id_categoria)
            ) {
                leafCategoriesToRemove.push(c.id_categoria);
            }
        });
    }

    //Segno da eliminare tutte le categorie non in white list
    let allCategoriesInWhitelist = []
    //Può capitare che venga presa sia la categoria x per intero, che solo un singolo servizio di questa categoria, per questo vengono analizzati
    //sia le categorie che i servizi
    categorieWhitelist.map(cwl => allCategoriesInWhitelist.push(cwl.id_categoria))
    serviziWhitelist.map(swl => allCategoriesInWhitelist.push(swl.id_categoria))

    //Se white list è vuota nob la considero
    if(allCategoriesInWhitelist.length){
        categoryList.map(c => (!c.child || !c.child.length) && !allCategoriesInWhitelist.includes(c.id_categoria) && leafCategoriesToRemove.push(c.id_categoria))
    }

    //Individuo tutte le categorie foglie da eliminare
    categoryList.map(c => {
        if ((!c.child || !c.child.length) && !c.numServizi) leafCategoriesToRemove.push(c.id_categoria)
    });

    const newCategoryTree =  pruneTreeLeafsRecursive(
        {id_categoria: '', child: categoryTree},
        leafCategoriesToRemove,
        "id_categoria", "child"
    );

    return newCategoryTree && newCategoryTree.child && newCategoryTree.child.length ? newCategoryTree.child : [];

}


/**
 * @param treeRoot {
 *     "{itemIdKey}": <id>
 *     "{childKey}": <treeRoot[]>
 * }
 * @param leafsToRemove Elenco degli ID da eliminare
 * @param itemIdKey Chiave di ogni nodo che lo identifica univocamente
 * @param childKey Chiave di ogni nodo attraverso cui recuperare i figli
 * */
const pruneTreeLeafsRecursive = (treeRoot, leafsToRemove, itemIdKey, childKey) => {
    let root = JSON.parse(JSON.stringify(treeRoot));

    //Se sono una foglia e non sono tra quelle da rimuovere allora restituisco me stesso
    if (!root[childKey] || !root[childKey].length) {
        if (!leafsToRemove.includes(root[itemIdKey])) {
            return root;
        }
    }

    //Se ho dei figli allora recupero i loro sottoalberi filtrati
    let newChildList = root[childKey].filter(c => !leafsToRemove.includes(c[itemIdKey]));
    if (!newChildList.length) return null;
    newChildList = newChildList.map(c => {
        return pruneTreeLeafsRecursive(c, leafsToRemove, itemIdKey, childKey);
    })
        .filter(v => v); //Rimuovo i figli nulli

    if (!newChildList.length){
        return null;
    }

    root[childKey] = newChildList;
    return root;
}


const flattedCategoryTree = (tree, childKey) => {
    let list = [];
    tree.map(c => {
        const listItem = {...c};
        list.push(listItem);
        if (c[childKey] && c[childKey].length) {
            const subList = flattedCategoryTree(c[childKey], childKey);
            list = list.concat(subList);
        }
    });
    return list;
}