import React, {Fragment, Suspense} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import Page from "../../../components/BaseComponents/Page/Page";
import {SwalLoadingSplashscreen} from "../../../components/GenericUI/SwalLoadingSplashscreen/SwalLoadingSplashscreen";
import {EllipsisLoader} from "../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import expoSVG from "../../svg/EXPO.svg";
import bullhornSVG from "../../svg/bullhorn.svg";
import {BookizonAppManager} from "../../../index";

const LazyGruppiNegoziListView = React.lazy(() => import("../../Expo/ExpoBannerListView/ExpoBannerListView"));

export class ExpoGalleryPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Page
                title={""}
                active={true}
                onLeftIconClick={this.props.onDismiss}
                
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                navbarBgColor={'#ffffff'}
                navbarTextColor={"#293069"}
            >
                <div className={"text-center bg-white shadow-sm mb-4 pb-3"}>
                    <img src={expoSVG} height={50} alt={"Bookizon EXPO logo"}/>
                    <div className={"text-center font-weight-bold text-primary mt-2"}>
                        Fiere, iniziative & associazioni
                    </div>
                </div>
                <div className={"container-fluid text-muted small mb-2"}>Iniziative in corso</div>
                <div className={"container"}>
                    <Suspense fallback={<div className={"vh-100 align-items-center d-flex justify-content-center"}>
                        <EllipsisLoader/></div>}>
                        <LazyGruppiNegoziListView
                            onAppOpen={this.props.onAppOpenRequest}
                            onAppAdd={this.props.onAppAddRequest}
                        />
                    </Suspense>
                    <div className={"text-center mt-5"}>
                        <div className={"p-2"}>
                            <img src={bullhornSVG} height={100} alt={"Megafono con persone"}/>
                            <div className={"text-center text-primary mt-2"}>
                                <h4>Hai una iniziativa o un evento da promuovere nell'area EXPO di Bookizon?</h4>
                                <button className={"btn btn-soft-primary"}
                                        onClick={_ => BookizonAppManager.openInAppBrowser('https://bookizon.it/home/info-area-expo/')}>
                                    Contattaci subito!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }

}

ExpoGalleryPage.propTypes =
    {
        onDismiss: PropTypes.func,
    }