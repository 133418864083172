import React from "react";
import PropTypes from "prop-types";
import {getFontAwesomeIconFromMIME} from "../Attachments.helpers";
import {FaSolidIcon} from "../../../../FontAwesomeIcons";
import {AttachmentsImagePreview} from "./AttachmentsImagePreview";

export function AttachmentsPreview({attachment}) {

    const [mimeGenericType, mimeSpecificType] = attachment.type.split("/");

    if (mimeGenericType === 'image') {
        return <AttachmentsImagePreview attachment={attachment}/>
    }

    return (
        <React.Fragment>
            <FaSolidIcon name={getFontAwesomeIconFromMIME(attachment.type)}/>
        </React.Fragment>
    )
}

AttachmentsPreview.propTypes = {}