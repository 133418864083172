import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaRegularIcon, FaSolidIcon} from "../../FontAwesomeIcons";
import {SuperHeroIcon} from "../../../img/illustrations/SuperHeroIcon";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";
import Page from "../../BaseComponents/Page/Page";
import {EcommerceShippingAddressBar} from "./EcommerceShippingAddressBar";
import {EcommerceBillingAddressBar} from "./EcommerceBillingAddressBar";
import {FullscreenInput, FullscreenSelect, FullscreenTextarea} from "../../Forms/FullscreenInputs";
import {PAYMENT_METHOD_CONSEGNA, PAYMENT_METHOD_NAME, PAYMENT_METHOD_BONIFICO, PAYMENT_METHOD_BONIFICO_30GG, PAYMENT_METHOD_BONIFICO_3060GG, PAYMENT_METHOD_BONIFICO_306090GG} from "../../../const/PaymentMethodoCostants";
import {ECOMMERCE_ORDER_TYPE_DEFAULT, ECOMMERCE_ORDER_TYPE_RITIRO} from "../../../const/ModuloEcommerceCostants";
import EcommerceAPI from "../../../api/EcommerceAPI";
import {swal, swalError, swalLoading} from "../../../helpers/SweetAlertWrapper";
import moment from "moment";
import DatePicker from "react-mobile-datepicker";
import CouponAPI from "../../../api/CouponAPI";
import {PageFooter} from "../../BaseComponents/Page/PageFooter";
import {applyPercentage} from "../../../helpers/NumberUtility";
import styled from "styled-components";
import CheckoutDiscount from "../../CheckoutDiscount/CheckoutDiscount";
import {COUPON_TYPE_ECOMMERCE} from "../../../const/CouponConstants";
import i18n from "i18next";
import {FoodCartProductPriceReduce} from "../../FoodDelivery/Utils/FoodCartProductPriceReduce";
import {formatCurrency} from "../../../helpers/CurrencyHelpers";


const monthMap = {
    '1': 'Gen',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'Mag',
    '6': 'Giu',
    '7': 'Lug',
    '8': 'Ago',
    '9': 'Set',
    '10': 'Ott',
    '11': 'Nov',
    '12': 'Dic',
};

const dateConfig = {
    'date': {
        format: 'DD',
        caption: 'Giorno',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Mese',
        step: 1,
    },
    'year': {
        format: 'YYYY',
        caption: 'Anno',
        step: 1,
    }
};

export class EcommerceCheckoutDetailPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shipping: null,
            billing: null,
            id_corriere: null,
            note: '',
            metodo_pagamento: '',
            datepickerDate: new Date(),
            datepickerOpen: false,
            orario: '',
            listaCorrieri: [],
            dati_coupon: null,
            dati_wallets: null,
            times: []
        }

        this.campiObbligatori = {
            metodo_pagamento: "Metodo di pagamento",
            id_corriere: "Metodo di spedizione",
            //termini_e_condizioni: "Termini e condizioni"
        };

        //Setto gli items da passare nel componente del wallet
        this.itemsForDiscount = this.props.products.map(p =>{
            const priceTotal = (parseFloat(p.prezzo) * parseFloat(p.quantita));
            const discount = p.perc_sconto ? parseFloat(p.perc_sconto) : 0.0;
            return {id_item: p.id_prodotto, totale: applyPercentage(priceTotal, -discount)}
        })
    }

    componentDidMount() {
        const corriere = this.state.listaCorrieri.find(c => c.id_corriere === this.state.id_corriere);
        if (this.props.orderType === ECOMMERCE_ORDER_TYPE_RITIRO || (corriere && corriere.richiedi_datetime)) {
            this.handleDatepickerSelect(this.state.datepickerDate);
        }
    }

    handleShippingChange = shipping => this.setState({shipping: shipping}, _ => {
        if (this.props.orderType === ECOMMERCE_ORDER_TYPE_DEFAULT) this.fetchCorrieri();
    });
    handleBillingChange = billing => this.setState({billing: billing});

    handleInputChange = ({target}) => {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value,
        }, _ => {
            if (target.name === "id_corriere") { //Se il corriere è cambiato
                const corriere = this.state.listaCorrieri.find(c => c.id_corriere === this.state.id_corriere);
                if (corriere && corriere.richiedi_datetime) {
                    this.handleDatepickerSelect(this.state.datepickerDate); //Triggero il recupero degli orari per la data attuale
                }
            }
        });
    }

    fetchCorrieri = _ => {
        EcommerceAPI.getCorrieri(this.props.products, this.state.shipping.id_indirizzo)
            .then(corrieri => {
                swal.safeClose();
                this.setState({listaCorrieri: corrieri});
            })
            .catch(message => {
                swalError(message);
            });
    }


    toggleDatePicker = _ => {
        this.setState({datepickerOpen: !this.state.datepickerOpen});
    }

    handleDatepickerSelect = date => {
        swalLoading(i18n.t('ecommerce.cart.checkout.messages.loading-available-times'));

        let apiFunction = this.props.orderType === ECOMMERCE_ORDER_TYPE_RITIRO ?
            EcommerceAPI.getFasceOrarieRitiro :
            EcommerceAPI.getFasceOrarieConsegna;

        apiFunction(NegozioOpenStore.infoNegozio.id_negozio, moment(date).format('YYYY-MM-DD'))
            .then(times => {
                swal.safeClose();
                this.setState({
                    datepickerDate: date,
                    datepickerOpen: false,
                    times: times,
                    orario: ''
                });
            })
            .catch(swalError)
    }

    //Gestisce il coupon che viene confermato
    discountValidityHandler = resultData => {
        console.log(resultData)
        this.setState({dati_coupon: resultData.coupon, dati_wallets: resultData.wallets})
    }

    //Restituisce il valore in euro del coupon applicato all'ordine
    getCouponRealValue = totaleProdotti => {
        if (!this.state.dati_coupon) return 0;
        if (!this.state.dati_coupon.tipo_sconto) return 0;

        if (this.state.dati_coupon.tipo_sconto === 'euro') {
            return parseFloat(this.state.dati_coupon.valore_sconto);
        } else {
            let factorSconto = (parseFloat(this.state.dati_coupon.valore_sconto) / 100);
            return parseFloat(totaleProdotti) * factorSconto;
        }
    }

    validateForm = _ => {
        for (var key in this.campiObbligatori) {
            if (key === "id_corriere" && (this.props.orderType === ECOMMERCE_ORDER_TYPE_RITIRO || !this.props.getShipping)) continue;
            if (!this.state[key]) {
                swal.fire({
                    icon: 'error',
                    title: "Oops!",
                    text: i18n.t('ecommerce.cart.checkout.errors.mandatory-field-template',{field: this.campiObbligatori[key]})
                });
                return false;
            }
        }

        if (this.state.dati_coupon instanceof Error) {
            swal.fire({
                icon: 'error',
                title: i18n.t('ecommerce.cart.checkout.errors.coupon'),
                text: this.state.dati_coupon.message
            });
            return false;
        }

        if (this.state.dati_wallets instanceof Error) {
            swal.fire({
                icon: 'error',
                title: i18n.t('ecommerce.cart.checkout.errors.coupon'),
                text: this.state.dati_wallets.message
            });
            return false;
        }

        const corriere = this.state.listaCorrieri.find(c => c.id_corriere === this.state.id_corriere);
        if (corriere && corriere.richiedi_datetime && (!this.state.datepickerDate || !this.state.orario)) {
            swalError(i18n.t('ecommerce.cart.checkout.errors.date-time-mandatory'));
            return false;
        }

        return true;
    }

    handleSubmit = _ => {
        if (this.validateForm()) {
            const corriereScelto = this.state.listaCorrieri ? this.state.listaCorrieri.find(c => c.id_corriere === this.state.id_corriere) : null;
            const shouldSelectDateTime = this.props.orderType === ECOMMERCE_ORDER_TYPE_RITIRO || (corriereScelto && !!corriereScelto.richiedi_datetime)

            this.props.onConfirm({
                shipping: this.state.shipping,
                billing: this.state.billing,
                corriere: corriereScelto,
                metodo_pagamento: this.state.metodo_pagamento,
                data: shouldSelectDateTime ? moment(this.state.datepickerDate).format('YYYY-MM-DD') : null,
                orario: shouldSelectDateTime ? this.state.orario : null,
                coupon: this.state.dati_coupon ? this.state.dati_coupon.codice : null,
                wallets: this.state.dati_wallets ? this.state.dati_wallets.wallets_list : null,
                note: this.state.note,
                formHTML: this.formRef ? this.formRef.innerHTML : ''
            });
        }
    }


    render() {
        const corriereScelto = this.state.listaCorrieri ? this.state.listaCorrieri.find(c => c.id_corriere === this.state.id_corriere) : null;
        const shouldSelectDateTime = this.props.orderType === ECOMMERCE_ORDER_TYPE_RITIRO || (corriereScelto && !!corriereScelto.richiedi_datetime)
        const selectedDate = moment(this.state.datepickerDate).format('DD-MM-YYYY');

        const totaleProdotti = this.props.products.reduce((acc, p) => {
            const priceTotal = (parseFloat(p.prezzo) * parseFloat(p.quantita));
            const discount = p.perc_sconto ? parseFloat(p.perc_sconto) : 0.0;
            return acc + applyPercentage(priceTotal, -discount);
        }, 0);
        const subtotal = totaleProdotti + (corriereScelto ? parseFloat(corriereScelto.costo_finale) : 0);
        const couponValue = this.getCouponRealValue(totaleProdotti);
        const total = subtotal - couponValue;

        return (
            <Fragment>
                <Page
                    title={i18n.t('ecommerce.cart.checkout.title')}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    footer={
                        <PageFooter className={"bg-main-z2 p-3 border-top"}>
                            <button className="btn btn-primary btn-block shadow" type="button"
                                    onClick={this.handleSubmit}>
                                {
                                    this.state.metodo_pagamento === PAYMENT_METHOD_CONSEGNA ?
                                        i18n.t('ecommerce.cart.checkout.complete-order-btn') :
                                        i18n.t('ecommerce.cart.checkout.goto-payment-btn')
                                }
                            </button>
                        </PageFooter>
                    }
                >
                    <Fragment>
                        <div className={"mb-4"}>
                            <div className={"mx-auto w-25 my-3"}>
                                <SuperHeroIcon className={"img-fluid"}
                                               color={NegozioOpenStore.infoNegozio.aspetto.primary_color}/>
                            </div>
                            <h4 className={"text-center text-primary"}>
                                {i18n.t('ecommerce.cart.checkout.headline')}
                            </h4>
                        </div>

                        <div className={"form-group"}>
                            {
                                this.props.getShipping &&
                                <EcommerceShippingAddressBar onChange={this.handleShippingChange}/>
                            }
                        </div>

                        <div className={"form-group"}>
                            {
                                this.props.getBilling &&
                                <EcommerceBillingAddressBar onChange={this.handleBillingChange}/>
                            }
                        </div>

                        {/** @MARK: Note aggiuntive */}
                        <div className="form-group">
                            <div className={"container"}>
                                <small className={"text-muted text-uppercase"}>{i18n.t('ecommerce.cart.checkout.notes-label')}</small>
                                <div className={"card shadow-sm border-0"}>
                                    <div className={"card-body p-0"}>
                                        <FullscreenTextarea
                                            className={"form-control px-3 border-0"}
                                            name={"note"} value={this.state.note}
                                            onChange={this.handleInputChange}
                                            placeholder={NegozioOpenStore.infoNegozio.impostazioni.ordini_placeholder_note || i18n.t('ecommerce.cart.checkout.notes-placeholder')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/** @MARK: Metodo pagamento */}
                        <div className="form-group">
                            <div className={"container clearfix"}>
                                <div className={"card shadow-sm border-0"}>
                                    <div className={"card-body py-0"}>
                                        <div className={"row align-items-center bg-white"}>
                                            <div className={"col-1 px-0 text-primary text-center"}>
                                                <FaRegularIcon name={"credit-card"}/>
                                            </div>
                                            <div className={"col px-0"}>
                                                <FullscreenSelect
                                                    name={"metodo_pagamento"}
                                                    className={"form-control border-0 rounded-0"}
                                                    value={this.state.metodo_pagamento}
                                                    onChange={this.handleInputChange}
                                                >
                                                    <option value={''}>{i18n.t('generic.none-selected')}</option>
                                                    {
                                                        this.props.paymentMethods.map((m, i) =>
                                                            <option key={i} value={m}>{i18n.t(`ecommerce.cart.checkout.payment-methods.${m}`)}</option>
                                                        )
                                                    }
                                                </FullscreenSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <small className={"text-muted"}>{i18n.t('ecommerce.cart.checkout.choose-payment-method-label')}</small>
                            </div>
                        </div>

                        {/** @MARK: Corriere */}
                        {
                            this.props.orderType === ECOMMERCE_ORDER_TYPE_DEFAULT && this.props.getShipping &&
                            <Fragment>
                                {
                                    !this.state.shipping &&
                                    <div className={"form-group text-muted px-3"}>
                                        {i18n.t('ecommerce.cart.checkout.choose-shipping-address-advice')}
                                    </div>
                                }

                                {
                                    !!this.state.shipping &&
                                    <div className="form-group">
                                        <div className={"container clearfix"}>
                                            <div className={"card shadow-sm border-0"}>
                                                <div className={"card-body py-0"}>
                                                    <div className={"row align-items-center bg-white"}>
                                                        <div className={"col-1 px-0 text-primary text-center"}>
                                                            <FaSolidIcon name={"truck"}/>
                                                        </div>
                                                        <div className={"col px-0"}>
                                                            <FullscreenSelect
                                                                name={"id_corriere"}
                                                                className={"form-control border-0 rounded-0"}
                                                                value={this.state.id_corriere}
                                                                onChange={this.handleInputChange}
                                                            >
                                                                <option value={''}>{i18n.t('generic.none-selected')}</option>
                                                                {
                                                                    this.state.listaCorrieri.map(c => <option
                                                                        value={c.id_corriere}
                                                                        key={c.id_corriere}>{c.nome}</option>)
                                                                }
                                                            </FullscreenSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <small className={"text-muted"}>
                                                {i18n.t('ecommerce.cart.checkout.choose-shipping-method-label')}
                                            </small>
                                            {
                                                !!this.state.listaCorrieri && this.state.listaCorrieri.length === 0 &&
                                                <div className={"text-danger"}>
                                                    {i18n.t('ecommerce.cart.checkout.errors.no-shipping-methods')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        }

                        {/** @MARK: Data e Orario (se corriere scelto lo richiede) */}
                        {
                            shouldSelectDateTime &&
                            <div className={"form-group"}>
                                <div className={"container"}>
                                    <div className={"card shadow-sm border-0"}>
                                        <div className={"card-body py-0"}>
                                            <div className={"row align-items-center bg-white"}>
                                                <div className={"col-1 px-0 text-success text-center"}>
                                                    <FaRegularIcon name={"clock"}/>
                                                </div>
                                                <div className={"col px-0"}>
                                                    <FullscreenInput
                                                        className={"form-control border-0 rounded-0 text-center"}
                                                        type={"text"}
                                                        readOnly={true}
                                                        value={selectedDate}
                                                        onChange={this.handleInputChange}
                                                        onClick={this.toggleDatePicker}
                                                        onFocus={this.toggleDatePicker}
                                                    />
                                                </div>
                                                <div className={"col px-0"}>
                                                    <FullscreenSelect name={"orario"} value={this.state.orario}
                                                                      onChange={this.handleInputChange}
                                                                      className={"form-control border-0 rounded-0 text-center"}
                                                                      disabled={!this.state.datepickerDate && !this.state.id_corriere}>
                                                        {
                                                            this.state.times.length ?
                                                                <option value={''}>{i18n.t('ecommerce.cart.checkout.choose-time')}</option> :
                                                                <option value={''}>{i18n.t('ecommerce.cart.checkout.no-times')}</option>
                                                        }
                                                        {
                                                            this.state.times.map((t, i) => {
                                                                return <option key={i}
                                                                               value={JSON.stringify(t)}>{`${t.orario_inizio} - ${t.orario_fine}`}</option>
                                                            })
                                                        }
                                                    </FullscreenSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <small className={"text-muted"}>{i18n.t('ecommerce.cart.checkout.choose-date-time-label')}</small>
                                </div>
                            </div>
                        }

                        {/** @MARK: Coupon & Wallet */}
                        <CheckoutDiscount
                            type={COUPON_TYPE_ECOMMERCE}
                            discountValidityHandler={this.discountValidityHandler}
                            discountableAmount={totaleProdotti}
                            amountPayableWithWallet={total}
                            externalCode={this.props.externalCoupon || ''}
                            items={this.itemsForDiscount}
                        />

                        {/** @MARK: Dettaglio prezzi */}
                        <div className={"container"}>
                            <div className={"form-group"}>
                                <div className={"card border-0 shadow-sm"}>
                                    <div className={"card-body"}>

                                        {/*Costi di consegna*/}
                                        {
                                            this.props.orderType === ECOMMERCE_ORDER_TYPE_DEFAULT && this.props.getShipping &&
                                            <div className={"row"}>
                                                <div
                                                    className={"col-12 d-flex align-items-center justify-content-between"}>
                                                    <span>{i18n.t('ecommerce.cart.checkout.receipt.shipping')}</span>
                                                    {
                                                        this.state.id_corriere && corriereScelto ?
                                                            parseFloat(corriereScelto.costo_finale) > 0 ?
                                                                <span>{formatCurrency(corriereScelto.costo_finale, "EUR", "it-IT")}</span>
                                                                :
                                                                <span className={"text-success"}>{i18n.t('ecommerce.cart.checkout.receipt.free-shipping')}</span>
                                                            :
                                                            <small>{i18n.t('ecommerce.cart.checkout.receipt.choose-shipping-method')}</small>
                                                    }
                                                </div>
                                            </div>
                                        }


                                        {/*Totale dei prodotti*/}
                                        <div className={"row"}>
                                            <div className={"col-8"}>
                                                <span>{i18n.t('ecommerce.cart.checkout.receipt.products-total')}</span>
                                            </div>
                                            <div className={"col-4 text-right"}>
                                                <span>{formatCurrency(totaleProdotti, "EUR", "it-IT")}</span>
                                            </div>
                                        </div>


                                        {/* Mostro subtotale se c'è uno sconto e se sono in modalità di consegna con corriere */}
                                        {
                                            (this.state.dati_coupon || this.state.dati_wallets) && !!corriereScelto &&
                                            <div className={"row"}>
                                                <div className={"col-8"}>
                                                    <h6>{i18n.t('ecommerce.cart.checkout.receipt.subtotal')}</h6>
                                                </div>
                                                <div className={"col-4 text-right"}>
                                                    <h6>{formatCurrency(subtotal, "EUR", "it-IT")}</h6>
                                                </div>
                                            </div>
                                        }

                                        {/* Mostro lo sconto con il coupon se possibile */}
                                        {
                                            this.state.dati_coupon && this.state.dati_coupon.tipo_sconto &&
                                            <div className={"row"}>
                                                <div className={"col-8"}>
                                                    <h6>
                                                        {i18n.t('ecommerce.cart.checkout.receipt.coupon-discount')}
                                                        {
                                                            this.state.dati_coupon.tipo_sconto !== 'euro' &&
                                                            <span>&nbsp;{this.state.dati_coupon.valore_sconto}%</span>
                                                        }
                                                    </h6>
                                                </div>
                                                <div className={"col-4 text-right"}>
                                                    <h6 className={"text-success"}>{formatCurrency(couponValue, "EUR", "it-IT")}</h6>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.state.dati_wallets && this.state.dati_wallets.importo_usato_totale > 0 &&
                                            <div className={"row"}>
                                                <div className={"col-8"}>
                                                    <h6>
                                                        {i18n.t('ecommerce.cart.checkout.receipt.from-wallets')}
                                                    </h6>
                                                </div>
                                                <div className={"col-4 text-right"}>
                                                    <h6 className={"text-success"}>{formatCurrency(this.state.dati_wallets.importo_usato_totale, "EUR", "it-IT")}</h6>
                                                </div>
                                            </div>
                                        }

                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <hr/>
                                            </div>
                                        </div>

                                        {/*Stampa del totale*/}
                                        {
                                            <div className={"row"}>
                                                <div className={"col-8 mt-3"}>
                                                    <h5>{i18n.t('ecommerce.cart.checkout.receipt.total')}</h5>
                                                </div>
                                                <div className={"col-4 mt-3 text-right"}>
                                                    <h5>{formatCurrency(total, "EUR", "it-IT")}</h5>
                                                    {
                                                        NegozioOpenStore.infoNegozio.impostazioni.ordini_prezzi_iva_esclusa == 1 &&
                                                        <div className={"small text-muted"}>
                                                            {i18n.t('ecommerce.tax-excluded-label')}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Fragment>
                </Page>

                <DatePicker
                    ref={this.datepicker}
                    dateConfig={dateConfig}
                    headerFormat={'DD-MM-YYYY'}
                    showHeader={true}
                    confirmText={i18n.t('generic.confirm-btn')}
                    cancelText={i18n.t('generic.cancel-btn')}
                    value={this.state.datepickerDate}
                    isOpen={this.state.datepickerOpen}
                    min={moment(new Date()).hours(0).minutes(0).seconds(0).toDate()}
                    onSelect={this.handleDatepickerSelect}
                    onCancel={this.toggleDatePicker}
                />
            </Fragment>
        )
    }

}

EcommerceCheckoutDetailPage.propTypes = {
    onDismiss: PropTypes.func,
    onConfirm: PropTypes.func,
    getShipping: PropTypes.bool,
    getBilling: PropTypes.bool,
    orderType: PropTypes.string,
    paymentMethods: PropTypes.array,
    products: PropTypes.array,
    externalCoupon: PropTypes.string
}