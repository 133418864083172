export const PostImageSize = {
    blur: {w:25, h:25},
    preview: {w: 768, h: 768},
    full: {w: 1080, h: 1080},
};

export const AppIconImageSize = {
    cartThumbnail: {w:25,h:25},
    productThumbnail: {w:35,h:35},
    extraSmall: {w:75,h:75},
    small: {w:100,h:100},
    thumbnail: {w:150,h:150}
};

export const ProductImageSize = {
    thumbnail: {w: 300, h: 300},
    preview: {w: 768, h: 768},
    full: {w: 1080, h: 1080},
};

export const CategoryImageSize = {
    thumbnail: {w: 300, h: 300},
    banner: {w: 768, h: 768}
};

export const SubcategoryImageSize = {
    thumbnail: {w: 150, h: 150},
};

export const BannerInAppImageSize = {
    full: {w: 1080, h: 1080},
    blur: {w: 25, h: 25}
}


export const GalleryImageSize = {
    full: {w: 1080, h: 1080},
    preview: {w: 150, h: 150}
}
