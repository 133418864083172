import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";


export const UserChatAPI = {
    chatList: _ => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/user-chat/chat-list`), {
                token: AuthAPI.currentUser.accessToken,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    setRead: id_room => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/user-chat/set-read`), {
                token: AuthAPI.currentUser.accessToken,
                id_room: id_room,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getChatRoom: id_negozio => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/user-chat/get-chat-room`), {
                token: AuthAPI.currentUser.accessToken,
                id_negozio: id_negozio,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getMessages: (id_room, limit, cursor) => {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/user-chat/get-messages`), {
                token: AuthAPI.currentUser.accessToken,
                id_room: id_room,
                limit: limit,
                cursor: cursor
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getMediaContentUrl: (id_messaggio) => {
        return APIConfig.url(`/user-chat/get-media-content?id_messaggio=${id_messaggio}&token=${AuthAPI.currentUser.accessToken}`)
    },
    sendTextMessage: (id_room, text, attachments) => {
        const formData = new FormData();
        formData.append("token", AuthAPI.currentUser.accessToken);
        formData.append("id_room", id_room);
        formData.append("text", text);
        if (attachments) {
            attachments.map(a => {
                formData.append("attachments[]", a);
            })
        }

        return FuxHTTP.post(
            APIConfig.url(`/user-chat/send-text-message`), formData,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    sendMessageNotification: (id_room, id_messaggio, otp) => {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/user-chat/send-message-notification`), {
                token: AuthAPI.currentUser.accessToken,
                id_room: id_room,
                id_messaggio: id_messaggio,
                otp: otp,
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};
