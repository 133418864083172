import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";


const _BookingEngine = {
    findHotels: function ({
                              data_checkin,
                              data_checkout,
                              nadulti,
                              bambini,
                              lat,
                              lon,
                              id_negozio,
                              id_hotel,
                              limit,
                              offset
                          }) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-hotel/booking-engine/find-hotels`), {
                data_checkin: data_checkin,
                data_checkout: data_checkout,
                nadulti: nadulti,
                bambini: bambini,
                lat: lat,
                lon: lon,
                id_negozio: id_negozio,
                id_hotel: id_hotel,
                limit: limit,
                offset: offset,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getHotelRoom: function ({
                                data_checkin,
                                data_checkout,
                                nadulti,
                                bambini,
                                lat,
                                lon,
                                id_negozio,
                                id_hotel,
                                limit,
                                offset
                            }) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-hotel/booking-engine/get-hotel-room`), {
                data_checkin: data_checkin,
                data_checkout: data_checkout,
                nadulti: nadulti,
                bambini: bambini,
                lat: lat,
                lon: lon,
                id_negozio: id_negozio,
                id_hotel: id_hotel,
                limit: limit,
                offset: offset,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getRoomRates: function ({data_checkin, data_checkout, nadulti, bambini, id_camera}) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-hotel/booking-engine/get-room-rates`), {
                data_checkin: data_checkin,
                data_checkout: data_checkout,
                nadulti: nadulti,
                bambini: bambini,
                id_camera: id_camera,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getServices: function ({data_checkin, data_checkout, nadulti, bambini, id_camera}) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-hotel/booking-engine/get-services`), {
                data_checkin: data_checkin,
                data_checkout: data_checkout,
                nadulti: nadulti,
                bambini: bambini,
                id_camera: id_camera,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    book: function ({data_checkin, data_checkout, nadulti, bambini, id_camera, id_tariffa, servizi, coupon, wallets}) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-hotel/booking-engine/book`), {
                data_checkin: data_checkin,
                data_checkout: data_checkout,
                nadulti: nadulti,
                bambini: bambini,
                id_camera: id_camera,
                id_tariffa: id_tariffa,
                servizi: servizi,
                token: AuthAPI.getUserAccessToken(),
                coupon:coupon,
                wallets: wallets
            },
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default _BookingEngine;