import React from "react";
import WalletDipendentiAPI from "../../../api/Welfare/WalletDipendenteAPI";
import Pagination from "../../../components/BaseComponents/Pagination/Pagination";
import DipendenteWelfareSingleTransaction from "./DipendenteWelfareSingleTransaction";

export default class DipendenteWelfareTransactions extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            transactionsLoaded: false,
            transactions: [],
            totalTransactions: 0,
            pageSize: 10,
            currentPage: 1,
        }
    }

    fetchData = (limit, offset) => {
        WalletDipendentiAPI.getTransactions(this.props.user.walletapikey, limit, offset)
            .then((data) => {
                console.log(data);
                //L'ordine delle transazioni viene invertiro poiché arrivano in ordine decrsecente per data
                //e nel map abbiamo la necessità di stamparle in ordine opposto
                this.setState({transactions: data.transactions, totalTransactions: parseInt(data.total), transactionsLoaded: true});
            })
    }

    componentDidMount() {
        if (this.props.active) {
            this.fetchData(this.state.pageSize,
                (this.state.currentPage - 1) * this.state.pageSize);
        }
    }

    handlePageChange = page => {
        this.setState({currentPage: page});
        this.fetchData(this.state.pageSize,
            (page - 1) * this.state.pageSize);
    }

    render() {
        return (
            <div>
                {
                    this.state.transactions.length > 0 &&
                    <div>
                        {
                            this.state.transactions.map((item, i) => {
                                return <div key={i} className={"list-group"}>
                                    {item.id_transazione &&
                                        <DipendenteWelfareSingleTransaction
                                            active={this.props.active}
                                            user={this.props.user}
                                            dipendente={this.props.dipendente}
                                            transazione={item}
                                        />
                                    }
                                </div>
                            })
                        }
                    </div>
                }
                {
                    this.state.transactionsLoaded ?

                        <div className={"justify-content-center d-flex pt-3"}>
                            {
                                this.state.transactions.length ?
                                    <Pagination
                                        activePage={this.state.currentPage}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalTransactions}
                                        pageRangeDisplayed={5}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        onChange={this.handlePageChange}
                                    /> :
                                    <div className={"container"}>
                                        <div className={"mt-3 text-center text-on-bg-main"}>
                                            Non ci sono transazioni
                                        </div>
                                    </div>

                            }
                        </div>
                        :
                        <div className={"container text-center lead mt-4"}>
                            <i className={"fas fa-spinner fa-spin"}/>
                        </div>
                }

            </div>
        );
    }
}