import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";

export const _PromotionsBirthday = {

    getPromotionsCompleanno: function (limit, offset, sortType=null, lat=null, lng=null, maxDistance=null, id_negozio=null){
        var params = {
            token: AuthAPI.currentUser ? AuthAPI.currentUser.accessToken : null,
            limit: limit,
            offset: offset,
            sort_type: sortType,

            lat:lat,
            lng:lng,
            maxDistance: maxDistance,
            id_negozio: id_negozio
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/promotions/birthday`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }

}