import React from "react";
import styled from "styled-components";
import {FaSolidIcon} from "../FontAwesomeIcons";
import DropdownMenu from "../BaseComponents/DropdownMenu";


const SearchInputWrapper = styled.div`
    position:relative;
    
    & > input{
        padding-left:30px;
    }
    
    & i{
        position:absolute;
        left:8px;
        top:50%;
        transform: translateY(-50%);
        bottom:0px;
        display:block;
        color:#717a80;
    }
    
    & span{
        display: none;
        position: absolute;
        top:50%;
        right:8px;
        border-radius: 50%;
        padding:2px;
        background:#ddd;
        color:white;
        width: 18px;
        height: 18px;
        line-height: 13px;
        text-align: center;
        transform: translateY(-50%);
    }

    & input:not([value=""]) + span{
        display: block;
    }  
`;

export const SORT_ALPHA_ASC = 'alpha_asc';
export const SORT_ALPHA_DESC = 'alpha_desc';
export const SORT_PRICE_ASC = 'price_asc';
export const SORT_PRICE_DESC = 'price_desc';

const TYPING_TIMEOUT_MS = 300;

export class MenuDigitaleSearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderMenuOpen: false,
            orderType: '',
            searchQuery: '',
        }
        this.typingTimeout = null;
    }

    toggleOrderMenu = _ => {
        this.setState({orderMenuOpen: !this.state.orderMenuOpen});
    }

    handleInputChange = e => {
        const searchQuery = e.target.value;
        this.setState({searchQuery: searchQuery});

        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        this.typingTimeout = setTimeout(_ => {
            this.props.onSearch(searchQuery, this.state.orderType);
        }, TYPING_TIMEOUT_MS);
    }

    handleSearchReset = _ => {
        this.setState({searchQuery: ''});
        this.props.onSearch('', this.state.orderType);
    }

    setSortType = type => _ => {
        this.setState({orderMenuOpen: false, orderType: type});
        this.props.onSearch(this.state.searchQuery, type);
    }

    //Rendering utilities
    getOrderTypeString = str => {
        switch (str || this.state.orderType) {
            case 'alpha_asc':
                return <span><FaSolidIcon name={"sort-alpha-down"}/> Nome</span>
            case 'alpha_desc':
                return <span><FaSolidIcon name={"sort-alpha-down-alt"}/> Nome</span>
            case 'price_asc':
                return <span><FaSolidIcon name={"sort-numeric-down"}/> Più economico</span>
            case 'price_desc':
                return <span><FaSolidIcon name={"sort-numeric-down-alt"}/> Più costoso</span>
            default:
                return <span><FaSolidIcon name={"sort"}/> Ordina</span>
        }
    }

    render() {
        return (
            <div className={"row"}>
                <div className={"col-7 pr-1"}>
                    <SearchInputWrapper>
                        <input
                            type={"text"}
                            className={"form-control form-control-sm"}
                            value={this.state.searchQuery}
                            name={"searchQuery"}
                            onChange={this.handleInputChange}
                            placeholder={"Ricerca per nome..."}
                        />
                        <span onClick={this.handleSearchReset}>&times;</span>
                        <FaSolidIcon name={"search"}/>
                    </SearchInputWrapper>
                </div>
                <div className={"col pl-1"}>
                    <button className={"btn btn-primary btn-block btn-sm"}
                            onClick={this.toggleOrderMenu}>
                        {this.getOrderTypeString()}
                    </button>
                    {
                        this.state.orderMenuOpen &&
                        <DropdownMenu onDismiss={this.toggleOrderMenu}>
                            <div className={"list-group-item"}
                                 onClick={this.setSortType(SORT_ALPHA_ASC)}>
                                <small>
                                    {this.getOrderTypeString(SORT_ALPHA_ASC)}
                                </small>
                            </div>
                            <div className={"list-group-item"}
                                 onClick={this.setSortType(SORT_PRICE_ASC)}>
                                <small>
                                    {this.getOrderTypeString(SORT_PRICE_ASC)}
                                </small>
                            </div>
                            <div className={"list-group-item"}
                                 onClick={this.setSortType(SORT_PRICE_DESC)}>
                                <small>
                                    {this.getOrderTypeString(SORT_PRICE_DESC)}
                                </small>
                            </div>
                        </DropdownMenu>
                    }
                </div>
            </div>
        )
    }

}
