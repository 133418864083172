import FuxHTTP from "../../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../../APIConfig";
import AuthAPI from "../../AuthAPI";

export const _Maps = {
    getList: _ => {
        return FuxHTTP.get(APIConfig.url("/modulo-seats/admin/maps/get-list"), {
            token: AuthAPI.getUserAccessToken()
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE)
    },
    getSectors: map_id => {
        return FuxHTTP.get(APIConfig.url("/modulo-seats/admin/maps/get-sectors"), {
            token: AuthAPI.getUserAccessToken(),
            map_id: map_id
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE)
    }
}