import React from "react";
import {UserAuthContext} from "../launcher/BookizonLauncher";
import ModalPortalHOC from "../components/BaseComponents/ModalPortalHOC";
import {LoginPanel} from "../components/Login/LoginPanel";
import PropTypes from "prop-types";
import AuthAPI from "../api/AuthAPI";
import i18next from "i18next";

export class NeedAuthCallToActionHOC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginPanelOpen: false
        }
    }

    handleTogglePanel = _ => this.setState({loginPanelOpen: !this.state.loginPanelOpen});

    render() {
        return (
            <React.Fragment>
                <UserAuthContext.Consumer>
                    {
                        user => (
                            <NeedAuthTester className={this.props.className} user={user}
                                            onInvalidClick={this.handleTogglePanel}>
                                {this.props.children}
                            </NeedAuthTester>
                        )
                    }
                </UserAuthContext.Consumer>
                <ModalPortalHOC>
                    <LoginPanel
                        open={this.state.loginPanelOpen}
                        openRegisterModal={this.props.openRegisterModal}
                        onDismiss={this.handleTogglePanel}
                        message={i18next.t("login.headline")}
                        showRegisterButton={this.props.showRegisterButton}
                    />
                </ModalPortalHOC>
            </React.Fragment>
        )
    }
}

NeedAuthCallToActionHOC.propTypes = {
    className: PropTypes.string,
    openRegisterModal: PropTypes.bool,
    showRegisterButton: PropTypes.bool
}

NeedAuthCallToActionHOC.defaultProps = {
    showRegisterButton: true
}

function NeedAuthTester(props) {

    const handleClickCapture = e => {
        if (!props.user && !AuthAPI.currentUser) {
            e.preventDefault();
            e.stopPropagation();
            props.onInvalidClick();
        }
    }

    return (
        <div className={props.className} onClickCapture={handleClickCapture}>
            {props.children}
        </div>
    )

}