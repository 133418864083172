import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {BookDetailView} from "./BookDetailView";
import {EllipsisLoader} from "../../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {ModuloSeatsAPI} from "../../../../../api/ModuloSeats/ModuloSeatsAPI";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {SeatCalendarContext} from "../CalendarContext";
import moment from "moment";

export class BookDetailPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            extendedBookData: null
        }
    }

    componentDidMount() {
        ModuloSeatsAPI.Admin.Books.getBookInfo(this.props.bookId, moment(this.context.date).format('YYYY-MM-DD'))
            .then(data => {
                this.setState({extendedBookData: data});
            });
    }

    handleSetEntranceDate = book_id => {
        this.props.onSetEntranceDate(book_id, newEntranceDate => {
            this.setState({
                extendedBookData: {...this.state.extendedBookData, entrance_date: newEntranceDate}
            })
        })
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Dettagli prenotazione"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                >
                    <div className={"container py-3"}>
                        {
                            this.state.extendedBookData ?
                                <BookDetailView book={this.state.extendedBookData}
                                                onSetEntranceDate={this.props.onSetEntranceDate ? this.handleSetEntranceDate : null}/>
                                :
                                <div className={"text-center"}>
                                    <EllipsisLoader/>
                                </div>
                        }
                    </div>
                </Page>
            </Fragment>
        )
    }

}

BookDetailPage.contextType = SeatCalendarContext;

BookDetailPage.propTypes = {
    bookId: PropTypes.any,
    onDismiss: PropTypes.func,
    onSetEntranceDate: PropTypes.func
}