import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const ConsentSolutionAPI = {
    Changes: {
        getChanges: ids => {
            return FuxHTTP.get(APIConfig.url('/consent-solution/changes/get-changes'), {
                ids: ids.join(','),
                token: AuthAPI.getUserAccessToken()
            }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE);
        },
        submit: (formHtml, change_ids) => {
            return FuxHTTP.post(APIConfig.url('/consent-solution/changes/submit'), {
                formHtml: formHtml,
                change_ids: change_ids,
                token: AuthAPI.getUserAccessToken()
            }, FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE);
        }
    },
}
