import React from "react";
import PropTypes from "prop-types";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../config/AppConfig";
import NativeGeolocationProvider from "../../providers/NativeGeolocationProvider";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import {DigitalCityPage} from "./DigitalCityPage";


export class DigitalCityBanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nearestCities: null,
            openedCity: null,
            lat: null,
            lon: null
        }
        this.bannerStyle = {
            backgroundColor: "#7c9dfd"
        }
    }

    componentDidMount() {
        setTimeout(_ => {
            NativeGeolocationProvider.getPermission()
                .then(permission => {
                    if (permission) {
                        this.waitForPosition();
                    } else {
                        NativeGeolocationProvider.askPermission();
                        NativeGeolocationProvider.waitForPermissionGrant().then(_ => this.waitForPosition());
                    }
                });
        }, 2000);

    }

    waitForPosition = _ => {
        NativeGeolocationProvider.waitForPosition('DigitalCityBanner')
            .then(location => {
                if (location) {
                    this.fetchCities(location.lat, location.lng);
                    this.setState({lat: location.lat, lon: location.lng});
                }
            });
    }

    fetchCities = (lat, lon) => {
        FuxHTTP.get(`${AppConfig.webServerDigitalCity}/api/get-nearest-cities`, {
            lat: lat,
            lon: lon
        }, FuxHTTP.RESOLVE_DATA, FuxHTTP.REJECT_MESSAGE)
            .then(cities => {
                this.setState({nearestCities: cities});
            })
    }

    handleOpenCity = city => _ => this.setState({openedCity: city});
    handleCloseCity = _ => this.setState({openedCity: null});

    render() {
        if (!this.state.nearestCities) return '';
        const city = this.state.nearestCities[0];
        return (
            <React.Fragment>
                <div className={"p-3 d-none"}>
                    <div className={"d-flex align-items-center justify-content-between p-3 rounded shadow-sm w-100"}
                         style={this.bannerStyle} onClick={this.handleOpenCity(city)}>
                        <div className={"d-flex align-items-center"}>
                            <img src={city.logo_url} height={50}/>
                            <h5 className={"mb-0 ms-2 text-white"}>
                                {city.name}
                                <div className={"small text-start"}>
                                    CITTA DIG<b className={"text-success"}><b>I</b></b>T<b
                                    className={"text-danger"}>A</b>LE
                                </div>
                            </h5>
                        </div>
                        <FaSolidIcon name={"chevron-right"} className={"text-white"}/>
                    </div>
                </div>
                {
                    this.state.openedCity !== null &&
                    <DigitalCityPage city={this.state.openedCity} onDismiss={this.handleCloseCity} lat={this.state.lat} lon={this.state.lon} />
                }
            </React.Fragment>
        )
    }

}

DigitalCityBanner.propTypes = {}
