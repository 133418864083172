import React, {Fragment} from "react";
import Page from "../../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import {PageFooter} from "../../../../../components/BaseComponents/Page/PageFooter";
import {FullscreenInput, FullscreenSelect} from "../../../../../components/Forms/FullscreenInputs";
import {UserSearchResult} from "../../../SearchUserPanel/UserSearchResult";
import SearchUserPanel from "../../../SearchUserPanel/SearchUserPanel";
import PropTypes from "prop-types";
import DashboardAddBookableGroupsBook from "./DashboardAddBookableGroupsBook";
import {swalError} from "../../../../../helpers/SweetAlertWrapper";


export default class DashboardUserInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            metodoPagamento: 'contanti',
            note: '',
            searchClientePageOpen: false,
            cliente: null,
            temporaneo: false,
            nome_cliente: ''
        }
    }


    handleInputChange = event => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value,
        });
    }

    toggleNote = _ => this.setState({notesVisible: !this.state.notesVisible, note: null});

    /**
     * @MARK Gestione clienti
     * */

    handleResetCliente = _ => {
        this.setState({temporaneo: false, nome_cliente: '', cliente: null})
    }

    toggleSearchCliente = _ => {
        this.setState({searchClienteOpen: !this.state.searchClienteOpen});
    }

    handleClienteChoose = cliente => {
        this.setState({cliente: cliente, searchClienteOpen: false});
    }

    handleUseTemporaneo = searchString => {
        searchString = typeof searchString === 'string' ? searchString : '';
        this.setState({cliente: null, nome_cliente: searchString, temporaneo: true, searchClienteOpen: false});
    }


    /**
     * @MARK conferma le scelte prese in questa sezione e manda le info al componente padre
     */

    handleConfirm = _ => {
        let cliente = this.state.cliente
        //Controllo se è temporaneo
        if (this.state.temporaneo) {
            if (!this.state.nome_cliente)
                return swalError("Inserisci il nome del cliente al quale collegare la prenotazione")
            cliente = {id_utente: null, nome: this.state.nome_cliente, temporaneo: this.state.temporaneo}
        }

        if (!cliente)
            return swalError("Inserisci informazioni del cliente al quale collegare la prenotazione")

        this.props.onChoose({
            cliente: cliente,
            metodoPagamento: this.state.metodoPagamento,
            note: this.state.note
        });
    }


    render() {
        return (
            <Fragment>
                <Page
                    title={"Info cliente"}

                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={true}
                    content={
                        <div className={"my-4"}>
                            {/* Scelta del CLIENTE */}
                            <div className={"form-group mb-4"}>
                                <h6 className={"text-muted font-weight-bold pl-3"}>Cliente</h6>
                                {
                                    this.state.temporaneo ?
                                        <div className={"container-fluid clearfix"}>
                                            <div
                                                className={"row bg-white align-items-center border-top border-bottom"}>
                                                <div className={"col-1 px-0 text-primary text-center"}>
                                                    <FaSolidIcon name={"user"}/>
                                                </div>
                                                <div className={"col px-0"}>
                                                    <FullscreenInput
                                                        type={"text"}
                                                        name={"nome_cliente"}
                                                        placeholder={"Nome cliente"}
                                                        className={"form-control border-0 rounded-0"}
                                                        value={this.state.nome_cliente}
                                                        onChange={this.handleInputChange}
                                                        readonly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"text-center"}>
                                                <button className={"btn btn-link btn-sm text-danger"}
                                                        type={"button"}
                                                        onClick={this.handleResetCliente}
                                                >
                                                    <FaSolidIcon name={"times"}/> Scegli un altro cliente
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        this.state.cliente ?
                                            <div className={"container-fluid clearfix"}>
                                                <UserSearchResult {...this.state.cliente}/>
                                                <div className={"text-center"}>
                                                    <button className={"btn btn-link btn-sm text-danger"}
                                                            type={"button"}
                                                            onClick={this.handleResetCliente}
                                                    >
                                                        <FaSolidIcon name={"times"}/> Scegli un altro cliente
                                                    </button>
                                                </div>
                                            </div>
                                            :

                                            <div className={"container-fluid"}>
                                                <div
                                                    className={"row bg-white align-items-center border-top border-bottom"}>
                                                    <div className={"col-1 px-0 text-center text-danger"}>
                                                        <FaSolidIcon name={"ellipsis-h"}/>
                                                    </div>
                                                    <div className={"col px-0"}>
                                                        <button
                                                            className={"btn btn-link btn-block text-muted text-left"}
                                                            type={"button"}
                                                            onClick={this.toggleSearchCliente}
                                                        >
                                                            Associa cliente
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className={"text-right"}>
                                                    <button className={"btn btn-link btn-sm"} type={"button"}
                                                            onClick={this.handleUseTemporaneo}>
                                                        <FaSolidIcon name={"pencil-alt"}/> Scrivi manualmente
                                                    </button>
                                                </div>
                                            </div>
                                }
                            </div>


                            {/*Pagamento*/}
                            <h6 className={"text-muted font-weight-bold mt-3 ml-2"}>Come pagherà il cliente?</h6>
                            <div className={"container-fluid"}>
                                <div
                                    className={"row bg-white align-items-center border-top border-bottom"}>
                                    <div className={"col-1 px-0 text-center text-success"}>
                                        <FaSolidIcon name={"coins"}/>
                                    </div>
                                    <div className={"col px-0"}>
                                        <FullscreenSelect
                                            name={"metodoPagamento"}
                                            className={"form-control border-0 rounded-0"}
                                            value={this.state.nome_cliente}
                                            onChange={this.handleInputChange}
                                            readonly={true}>

                                            <option value="consegna">Contanti</option>
                                            <option value="carta_credito">Carta di credito</option>
                                        </FullscreenSelect>
                                    </div>
                                </div>
                            </div>

                            {/*Note*/}
                            <div className={"mt-3"}>
                                {
                                    !this.state.notesVisible &&
                                    <button className={"btn btn-link"} onClick={this.toggleNote}>
                                        <i className={"fa fa-plus"}></i> Aggiungi note interne
                                    </button>
                                }
                                {
                                    this.state.notesVisible &&
                                    <div className={"form-group"}>
                                        <label>Note interne</label>
                                        <textarea className={"form-control"} name={"note"}
                                                  onChange={this.handleInputChange}/>
                                        <button className={"btn btn-link text-danger"} onClick={this.toggleNote}>
                                            <i className={"fa fa-trash"}></i> Rimuovi note
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    footer={
                        <PageFooter className={"bg-main-z2 p-3 border-top"}>
                            <button
                                className={"btn btn-lg btn-block btn-primary text-on-bg-primary"}
                                onClick={this.handleConfirm}>
                                Conferma <FaSolidIcon name={"chevron-right"}/>
                            </button>
                        </PageFooter>
                    }
                />

                {
                    this.state.searchClienteOpen &&
                    <SearchUserPanel
                        onDismiss={this.toggleSearchCliente}
                        onResultClick={this.handleClienteChoose}
                        onDefaultClick={this.handleUseTemporaneo}
                    />
                }
            </Fragment>
        )
    }
}

DashboardUserInfoPage.propTypes = {
    onDismiss: PropTypes.func,
    onChoose: PropTypes.func
}
