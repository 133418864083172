import React from "react";
import PropTypes from "prop-types";
import Page from "../../../../components/BaseComponents/Page/Page";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import AppConfig from "../../../../config/AppConfig";
import FuxUtility from "../../../../lib/FuxFramework/FuxUtility";
import {FuxNotificationSystem} from "../../../../components/GenericUI/FuxNotificationSystem/FuxNotificationSystem";

export default class DashboardInvitaUnAmico extends React.Component {
    constructor(props) {
        super(props);
    }

    generateShopUrlReferral = urlComplete => {
        return AppConfig.webServerUrl + urlComplete + "/NG_" + this.props.id_negozio.toString(16).toUpperCase()
    }

    copyToClipboard = url => {
        FuxUtility.copyToClipboard(url)
            .then(_ => {
                FuxNotificationSystem.fire({
                    title: "Link copiato negli appunti",
                    //message: ``,
                    position: 'tc', //Top center
                    level: 'success',
                    autoDismiss: 2
                });
            })
    }

    render() {
        return (
            <Page
                active={this.props.active}
                title={"Invita un amico"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
            >
                <div className={"container"}>
                    <div className={"card mt-3 border-0 shadow-sm"}>
                        <div className={"card-body"}>
                            <h5>
                                Presentaci un amico! <span
                                className="text-danger"><b>€20 euro</b> in buono sconto</span> su
                                Bookizon per te!
                            </h5>

                            <span className="text-muted">Invita un tuo amico esercente ad entrare a far parte di Bookzion! &#127881;
                                <br/>
                                    Otterrai €20 di buono sconto per te &#127873; <br/>
                                    Per ogni esercente invitato, il buono sconto sarà cumulativo! &#129297;
                                </span>
                        </div>
                    </div>
                    <div className={"card mt-3 border-0 shadow-sm"}>
                        <div className={"card-body"}>
                            <h5 className="font-weight-bold mb-2">Link referral per sito</h5>
                            <div className="input-group">
                                <input id="for-website" type="text" className="form-control" readOnly
                                       value={this.generateShopUrlReferral("/startup")}/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button"
                                            onClick={_ => this.copyToClipboard(this.generateShopUrlReferral("/startup"))}>
                                        Copia
                                    </button>
                                </div>
                            </div>
                            <small>
                                Chiunque navigherà questo link e si registrerà successivamente (anche a distanza di
                                giorni) verrà associato sotto il tuo account.
                            </small>
                        </div>
                    </div>
                    <div className={"card mt-3 border-0 shadow-sm"}>
                        <div className={"card-body"}>
                            <h5 className="font-weight-bold mb-2">Link referral per registrazione diretta</h5>
                            <div className="input-group">
                                <input type="text" className="form-control" readOnly
                                       value={this.generateShopUrlReferral("/ref")}/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button"
                                            onClick={_ => this.copyToClipboard(this.generateShopUrlReferral("/ref"))}>
                                        Copia
                                    </button>
                                </div>
                            </div>
                            <small>
                                Chiunque navigherà questo link e si registrerà (anche a distanza di giorni) verrà
                                associato sotto il tuo account.
                            </small>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }

}

DashboardInvitaUnAmico.propTypes = {
    id_negozio: PropTypes.number,
    onDismiss: PropTypes.func,
    active: PropTypes.bool
}