import React, {Fragment} from "react";
import ReactDOM from "react-dom";
import {swal, swalError} from "../../helpers/SweetAlertWrapper";
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import styled, {keyframes} from "styled-components";
import "./FlyToCart.css";
import DOMPurify from "dompurify";
import {slideInUp} from "react-animations";
import CheckboxListGroup from "../BaseComponents/CheckboxListGroup";
import RadioListGroup from "../BaseComponents/RadioListGroup";
import {PageFooter, PageWithFooterContainer} from "../BaseComponents/Page/PageFooter";
import Page from "../BaseComponents/Page/Page";
import {FullscreenSelect} from "../Forms/FullscreenInputs";
import {
    COMANDE_PRIMA_USCITA,
    COMANDE_QUARTA_USCITA,
    COMANDE_SECONDA_USCITA, COMANDE_TERZA_USCITA,
    COMANDE_UNICA_USCITA
} from "../../const/ModuloComandeConstants";

const CardAccordion = styled.div`
    & > .card:first-of-type{
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    & > .card:last-of-type{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
`;

const TabItem = styled.div`
    text-align:center;
    border-bottom:3px solid transparent;
`;

export default class ProdottiAggiuntePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            prezzoAggiunte: 0,
            prezzoOpzione: 0,
            rimozioni: this.props.startRimozioni || [''],
            id_opzione: this.props.startOpzione || '',
            aggiunte: this.props.startAggiunte || [],
            attributi: this.props.startAttributi || {},
            selectedTab: 'aggiunte',
            note: this.props.startNote || '',
            uscita: this.props.startUscita || COMANDE_UNICA_USCITA
        };

        if (this.props.aggiunte.length) {
            this.state.selectedTab = 'aggiunte';
        } else if (this.props.abilitaRimozioni) {
            this.state.selectedTab = 'rimozioni';
        }

        //Calcolo il prezzo opzioni nel caso in cui mi vengono passate tramie props
        if (this.props.startOpzione) {
            const opzione = this.props.opzioni.find(_a => _a.id_opzione === this.props.startOpzione);
            if (opzione) {
                this.state.prezzoOpzione += parseFloat(opzione.prezzo);
            }
        }
        //Calcolo il prezzo aggiunte nel caso in cui mi vengono passate tramie props
        if (this.props.startAggiunte) {
            this.props.startAggiunte.map(id_aggiunta => {
                const aggiunta = this.props.aggiunte.find(_a => _a.id_aggiunta === id_aggiunta);
                if (aggiunta) {
                    this.state.prezzoAggiunte += parseFloat(aggiunta.prezzo);
                }
            })
        }


        this.state.tipoCottura = this.state.attributi.cottura || 'cottura normale';

        if (this.props.cottura == 1 && !this.state.attributi.cottura) {

            this.state.attributi.cottura = 'cottura normale';
        }
    }

    handleAggiunteChange = aggiunte => {
        let prezzoAggiunte = 0;
        aggiunte.map(id_aggiunta => {
            const aggiunta = this.props.aggiunte.find(_a => _a.id_aggiunta === id_aggiunta);
            if (aggiunta) {
                prezzoAggiunte += parseFloat(aggiunta.prezzo);
            }
        })

        this.setState({aggiunte: aggiunte, prezzoAggiunte: prezzoAggiunte});
    }

    handleOpzioneChange = id_opzione => {
        let prezzoOpzione = 0;
        const opzione = this.props.opzioni.find(_a => _a.id_opzione === id_opzione);
        if (opzione) {
            prezzoOpzione = parseFloat(opzione.prezzo);
        }

        this.setState({id_opzione: id_opzione, prezzoOpzione: prezzoOpzione});
    }

    handleRimozioneChange = index => e => {
        let rimozioni = this.state.rimozioni.slice();
        rimozioni[index] = e.target.value
        this.setState({rimozioni: rimozioni});
    }

    handleAddRimozioni = _ => {
        let rimozioni = this.state.rimozioni.slice();
        rimozioni.push("");
        this.setState({rimozioni: rimozioni});
    }

    handleRemoveRimozione = index => _ => {
        let rimozioni = this.state.rimozioni.filter((r, i) => i !== index);
        this.setState({rimozioni: rimozioni});
    }

    handleChoose = _ => {
        const rimozioniTrim = this.state.rimozioni.filter(r => r.trim());

        if (this.props.opzioni.length && !this.state.id_opzione) {
            swalError("Devi scegliere almeno una opzione");
            return;
        }

        this.props.onChoose(this.state.id_opzione, this.state.aggiunte, rimozioniTrim, this.state.attributi, this.state.note, this.state.uscita);
        if (this.props.resetOnContinue) {
            this.setState({id_opzione: '', aggiunte: [], rimozioni: [], prezzoAggiunte: 0, attributi: [], note: '', uscita: COMANDE_UNICA_USCITA});
        }
    }

    handleChangeCottura = event => {
        this.setState({
            tipoCottura: event.target.value,
            attributi: {...this.state.attributi, cottura: event.target.value}
        });
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    getPrezzoTotaleAggiunteLabel = _ => !!this.state.prezzoAggiunte &&
        <span className={"ml-2"}>(+ €{this.state.prezzoAggiunte.toFixed(2)})</span>

    getPrezzoAggiuntaLabel = prezzo => {
        if (parseFloat(prezzo) > 0) {
            return <span className={"text-primary float-right"}>+ €{parseFloat(prezzo).toFixed(2)}</span>;
        } else if (parseInt(prezzo) === -1) {
            return ''
        } else {
            return <span className={"text-success float-right"}>Gratis</span>
        }
    }

    getPrezzoOpzioneLabel = prezzo => {
        if (parseFloat(prezzo) > 0) {
            return <span className={"text-primary float-right"}>+ €{parseFloat(prezzo).toFixed(2)}</span>;
        } else {
            return '';
        }
    }

    getAggiunteListGroupItems = _ => {
        return this.props.aggiunte.map(a => ({
            text: <span>{a.nome} {this.getPrezzoAggiuntaLabel(a.prezzo)}</span>,
            value: a.id_aggiunta,
            isHeader: parseInt(a.prezzo) === -1
        }))
    }

    render() {
        return (
            <Page
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
                footer={
                    <PageFooter className={"bg-main-z2 p-3 border-top"}>
                        {
                            this.props.abilitaUscita &&
                            <select className={"form-control form-control-sm mb-2"} name={"uscita"} onChange={this.handleInputChange}>
                                <option value={COMANDE_UNICA_USCITA}>Unica uscita</option>
                                <option value={COMANDE_PRIMA_USCITA}>Prima uscita</option>
                                <option value={COMANDE_SECONDA_USCITA}>Seconda uscita</option>
                                <option value={COMANDE_TERZA_USCITA}>Terza uscita</option>
                                <option value={COMANDE_QUARTA_USCITA}>Quarta uscita</option>
                            </select>
                        }
                        <button className={"btn btn-lg btn-primary btn-block"} onClick={this.handleChoose}>
                            <FaSolidIcon name={"shopping-cart"}/> {this.props.continueButtonText}
                        </button>
                    </PageFooter>
                }
            >
                <PageWithFooterContainer>
                    <div className={"py-3 sticky-top bg-main border-bottom border-primary"}>
                        {
                            !!this.props.title &&
                            <div className={"font-weight-bold text-center"}>{this.props.title}</div>
                        }
                        {
                            !!this.props.subtitle && <div className={"small text-center"}>{this.props.subtitle}</div>
                        }
                    </div>
                    <CardAccordion>
                        {/* Collapse opzioni */}
                        {
                            !!this.props.opzioni.length &&
                            <div className={"my-3"}>
                                <small className={"text-uppercase pl-3"}>Seleziona un'opzione obbligatoria</small>
                                <RadioListGroup
                                    items={
                                        this.props.opzioni.map(a => ({
                                            text: <span>{a.nome} {this.getPrezzoOpzioneLabel(a.prezzo)}</span>,
                                            value: a.id_opzione,
                                        }))
                                    }
                                    selected={this.state.id_opzione}
                                    onChange={this.handleOpzioneChange}
                                />
                            </div>
                        }

                        {/*Scelta cottura*/}
                        {
                            !!parseInt(this.props.cottura) &&
                            <div className={"container my-3"}>
                                <small className={"text-uppercase"}>Scegli il tipo di cottura</small>
                                <div className={"row bg-white align-items-center border-bottom border-top"}>
                                    <div className={"col-1 px-0 text-warning text-center"}>
                                        <FaSolidIcon name={"fire"} className={"text-danger"}/>
                                    </div>
                                    <div className={"col px-0"}>
                                        <FullscreenSelect
                                            name={"tipoCottura"}
                                            className={"form-control border-0 border-top border-bottom rounded-0"}
                                            value={this.state.tipoCottura} onChange={this.handleChangeCottura}>
                                            <option value={'ben cotto'}>Ben cotto</option>
                                            <option value={'cottura normale'}>Cottura normale</option>
                                            <option value={'poco cotto'}>Poco cotto</option>
                                        </FullscreenSelect>
                                    </div>
                                </div>
                            </div>
                        }

                        {/*Note aggiuntive*/}
                        {
                            !!this.props.abilitaNote && <textarea
                                className={"form-control border-top border-bottom border-left-0 border-right-0 rounded-0 my-3"}
                                name={"note"}
                                onChange={this.handleInputChange}
                                value={this.state.note}
                                placeholder={"Note aggiuntive"}
                            />
                        }

                        {/* Tabs aggiunte e rimozioni */}
                        <div className={"container"}>
                            <div className={"row bg-white border-top border-bottom"}>
                                {
                                    !!this.props.aggiunte.length &&
                                    <TabItem
                                        onClick={_ => this.setState({selectedTab: 'aggiunte'})}
                                        className={"col p-2 " + (this.state.selectedTab === 'aggiunte' ? 'active text-primary border-primary' : 'text-muted')}>
                                        Aggiunte {this.getPrezzoTotaleAggiunteLabel()}
                                    </TabItem>
                                }
                                {
                                    !!this.props.abilitaRimozioni &&
                                    <TabItem
                                        onClick={_ => this.setState({selectedTab: 'rimozioni'})}
                                        className={"col p-2 " + (this.state.selectedTab === 'rimozioni' ? 'active text-primary border-primary' : 'text-muted')}>
                                        Rimozioni
                                    </TabItem>
                                }
                            </div>
                        </div>

                        <div className={"my-3"}>
                            <div
                                className={this.state.selectedTab === 'aggiunte' && !!this.props.aggiunte.length ? '' : 'd-none'}>
                                <small className={"text-uppercase pl-3"}>Seleziona uno o più ingredienti</small>
                                {
                                    <CheckboxListGroup
                                        items={this.getAggiunteListGroupItems()}
                                        selected={this.state.aggiunte} //Invio le aggiunte già presenti, se ci sono
                                        onChange={this.handleAggiunteChange}
                                    />
                                }
                            </div>

                            <div
                                className={this.state.selectedTab === 'rimozioni' && !!this.props.abilitaRimozioni ? '' : 'd-none'}>
                                <small className={"text-uppercase pl-3"}>Rimuovi uno o più ingredienti</small>
                                {
                                    this.state.rimozioni.map((r, i) => {
                                        return (
                                            <div className={"input-group my-2"} key={i}>
                                                <input className={"form-control"} name={"rimozione" + i}
                                                       value={r}
                                                       onChange={this.handleRimozioneChange(i)}
                                                       placeholder={"Scrivi nome ingrediente"}
                                                />
                                                <div className={"input-group-append"}>
                                                    <button className={"btn btn-danger"}
                                                            onClick={this.handleRemoveRimozione(i)}>
                                                        <FaSolidIcon name={"trash"}/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <button className={"btn btn-link text-danger btn-block"}
                                        onClick={this.handleAddRimozioni}>
                                    <FaSolidIcon name={"plus"}/> Aggiungi ingrediente da rimuovere
                                </button>
                            </div>
                        </div>
                    </CardAccordion>
                </PageWithFooterContainer>
            </Page>
        )
    }

}


class RimozioneRow extends React.Component {

    constructor(props) {
        super(props);
    }

}
