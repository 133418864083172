export const HOTEL_BOOK_STATUS_CONFIRMED = 'confirmed';
export const HOTEL_BOOK_STATUS_WAIT_PAYMENT = 'wait_payment';
export const HOTEL_PAYMENT_METHOD_STRIPE = "stripe";
export const HOTEL_PAYMENT_METHOD_OFFLINE = "offline"; //Pagamento in struttura

export const HOTEL_MAX_ETA_CULLE = 6;


/** @MARK: Tariffe */
export const HOTEL_TARIFFE_POLICY_REFUNDABLE = 'rimborsabile';
export const HOTEL_TARIFFE_POLICY_REFUNDABLE_1W = 'rimborsabile_1s';
export const HOTEL_TARIFFE_POLICY_REFUNDABLE_2W = 'rimborsabile_2s';
export const HOTEL_TARIFFE_POLICY_REFUNDABLE_3W = 'rimborsabile_3s';
export const HOTEL_TARIFFE_POLICY_REFUNDABLE_4W = 'rimborsabile_4s';
export const HOTEL_TARIFFE_POLICY_NOT_REFUNDABLE = 'non_rimborsabile';

export const HOTEL_TARIFFE_POLICY_REFUNDABLE_LABEL = 'Sempre rimborsabile';
export const HOTEL_TARIFFE_POLICY_REFUNDABLE_1W_LABEL = 'Rimborsabile a 1 settimana dal check-in';
export const HOTEL_TARIFFE_POLICY_REFUNDABLE_2W_LABEL = 'Rimborsabile a 2 settimane dal check-in';
export const HOTEL_TARIFFE_POLICY_REFUNDABLE_3W_LABEL = 'Rimborsabile a 3 settimane dal check-in';
export const HOTEL_TARIFFE_POLICY_REFUNDABLE_4W_LABEL = 'Rimborsabile a 4 settimane dal check-in';
export const HOTEL_TARIFFE_POLICY_NOT_REFUNDABLE_LABEL = 'Non rimborsabile';

export const HOTEL_TARIFFE_POLICY_LABEL_MAP = {
    [HOTEL_TARIFFE_POLICY_REFUNDABLE]: HOTEL_TARIFFE_POLICY_REFUNDABLE_LABEL,
    [HOTEL_TARIFFE_POLICY_REFUNDABLE_1W]: HOTEL_TARIFFE_POLICY_REFUNDABLE_1W_LABEL,
    [HOTEL_TARIFFE_POLICY_REFUNDABLE_2W]: HOTEL_TARIFFE_POLICY_REFUNDABLE_2W_LABEL,
    [HOTEL_TARIFFE_POLICY_REFUNDABLE_3W]: HOTEL_TARIFFE_POLICY_REFUNDABLE_3W_LABEL,
    [HOTEL_TARIFFE_POLICY_REFUNDABLE_4W]: HOTEL_TARIFFE_POLICY_REFUNDABLE_4W_LABEL,
    [HOTEL_TARIFFE_POLICY_NOT_REFUNDABLE]: HOTEL_TARIFFE_POLICY_NOT_REFUNDABLE_LABEL
};

export const HOTEL_TARIFFE_POLICY_COLOR_MAP = {
    [HOTEL_TARIFFE_POLICY_REFUNDABLE]: 'success',
    [HOTEL_TARIFFE_POLICY_REFUNDABLE_1W]: 'warning',
    [HOTEL_TARIFFE_POLICY_REFUNDABLE_2W]: 'warning',
    [HOTEL_TARIFFE_POLICY_REFUNDABLE_3W]: 'warning',
    [HOTEL_TARIFFE_POLICY_REFUNDABLE_4W]: 'warning',
    [HOTEL_TARIFFE_POLICY_NOT_REFUNDABLE]: 'danger'
};
