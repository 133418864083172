import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {PluralString} from "../../helpers/UIUtility";
import moment from "moment";
import AppConfig from "../../config/AppConfig";
import {AppIconThumbnail} from "../../components/BaseComponents/AppIconThumbnail";

export function WaitingListItem(props) {

    moment.locale('it');

    const item = props.item
    const data = moment(item.data)

    function getNegozioIconLink (id){
        return ;
    }

    return <li className={"list-group-item shadow-sm border-bottom"}>
        <div className={"d-flex small align-items-center mb-1"}>
            <AppIconThumbnail src={`${AppConfig.webServerUrl}/api/negozio/${item.id_negozio}/logoIcon/100`} size={"25px"}/>&nbsp;
            {item.nome_negozio}
        </div>
        <div><b>{item.nome}</b></div>
        <div className={"text-muted"}>
            <Fragment>
                <div>
                    {data.format('dddd DD MMMM YYYY')} per&nbsp;
                    <PluralString n={item.partecipanti} more={item.partecipanti + " partecipanti"} single={"un partecipante"}/>
                </div>
            </Fragment>
        </div>
    </li>
}

WaitingListItem.propTypes = {
    item: PropTypes.object,
}