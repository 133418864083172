import React from "react";
import styled from "styled-components";
import {Fragment} from "react";
import {SwipeablePanel} from "../../../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {ALIQUOTE_IVA} from "../../ModuloCassaContants";
import {swalError} from "../../../../../../helpers/SweetAlertWrapper";
import {ModuloCassaAPI} from "../../../../../../api/ModuloCassa/ModuloCassaAPI";
import {InputInlineSearch} from "../../../../../../components/BaseComponents/InputInlineSearch/InputInlineSearch";
import suggestedItem from "./../suggestedItem";
import PropTypes from "prop-types";


const InfoBackdrop = styled.div`
        position:fixed;
        top:0; left:0; height:100vh; width:100vh;
        z-index:2000; background-color:rgba(0,0,0,0.7);
`;

const InfoWrapper = styled.div`
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
    min-height:80vh;
    max-height:80vh;
    width:100%;
    scrollbar-width: none;
    overflow-y:auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
        display:none;
    }
`;

export default class AggiungiItemPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
        }
        this.ref = null;
        this.hiding = false;

        this.hasRemovedScrollToBody = false;
        this.lastDocumentScrollPos = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.expanded && this.props.expanded) {
            this.handleRemoveBodyScroll();
        }
        if (prevProps.expanded && !this.props.expanded) {
            this.handleRestoreBodyScroll();
        }
    }

    /**
     * @FUNZIONI-PANEL
     */

    handleRemoveBodyScroll = _ => {
        this.hasRemovedScrollToBody = document.body.className.indexOf("noscroll") === -1;
        if (this.hasRemovedScrollToBody) {
            this.lastDocumentScrollPos = document.documentElement.scrollTop;
            document.documentElement.scrollTop = 0;
            //console.log("Page add noscroll", this.props.title);
        }
        document.body.classList.add('noscroll');
    }

    handleRestoreBodyScroll = _ => {
        //Rimuovo il "noscroll" solo se sono stato io ad aggiungerlo
        if (this.hasRemovedScrollToBody) {
            document.body.classList.remove('noscroll');
            if (this.lastDocumentScrollPos !== null) {
                document.documentElement.scrollTop = this.lastDocumentScrollPos;
                this.lastDocumentScrollPos = null;
            }
            //console.log("Page remove noscroll", this.props.title);
        }
    }

    getWrapperRef = node => {
        this.ref = node;
    }


    /**
     * @FUNZIONI-CASSA
     */

    //Utils
    getSubtotalOfItem = _ => {
        let i = this.state.item
        let subtot = parseFloat(i.quantita || "0") * parseFloat(i.importo_unitario || "0")
        return (subtot - parseFloat(i.sconto || "0")).toFixed(2)
    }

    //Input
    handleInputItemChange = ({target}) => this.setState({item: {...this.state.item, [target.name]: target.value}})

    handlePlusButton = name =>{
        this.setState({item: {...this.state.item, [name]: this.state.item[name]+1}})
    }

    handleMinusButton = name =>{
        if(this.state.item[name] > 0){
            this.setState({item: {...this.state.item, [name]: this.state.item[name]-1}})
        }
    }

    applyPredefinedDiscount = value =>{
        this.setState({item: {...this.state.item, sconto: (this.getSubtotalOfItem()/100*value).toFixed(2)}})
    }

    //Conferma
    handleAddElement = _ =>{
        if(this.getSubtotalOfItem() < 0) return swalError("Non puoi proseguire con un importo negativo")
        if(!this.state.item.descrizione) return swalError("Non puoi proseguire senza descrizione del prodotto")
        this.props.onAddItem(this.state.item)
        this.props.onToggle()
    }

    /**
     *@SEARCH-MANAGEMENT
     * */

    handleSearch = query =>{
        return ModuloCassaAPI.Dashboard.ricercaProdotto(query, 8)
    }

    handleItemChoose = item => {
        this.setState({item: {...this.state.item,
                importo_unitario: item.prezzo ? parseFloat(item.prezzo) : 1,
                descrizione: item.nome}
        })
    }

    render() {
        return (
            <Fragment>
                {this.props.expanded &&
                <InfoBackdrop className={"__androidDismissable__"} onClick={this.props.onToggle}/>}

                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={!!this.props.expanded}
                    onDismiss={this.props.onToggle}
                    disableSwipe={_ => !this.ref || this.ref.scrollTop > 0}
                    animationSpeed={150}
                >
                    <InfoWrapper
                        ref={this.getWrapperRef}
                        className={"bg-main-z2"}
                    >
                        <div className={"text-center text-on-bg-main small"}>
                            <FaSolidIcon name={"grip-lines"}/><br/>
                            <b>Aggiungi riga</b>
                        </div>

                        {
                            this.state.item.index &&
                            <input
                                type={"hidden"}
                                name={"index"}
                                value={this.state.item.index}
                                className={"d-none"}
                            />
                        }

                        {/* Descrizione */}
                        <div className={"form-group mt-3 px-3 py-1"}>
                            Descrizione del prodotto
                            <InputInlineSearch
                                searchBarPlaceholder={"Es. Mascherina"}
                                searchBarClass={(this.state.item.descrizione !== "" ? "" : "is-invalid")}
                                searchFunc={this.handleSearch}
                                itemChoose={this.handleItemChoose}
                                defaultQuery={this.state.item.descrizione}
                                overwriteSuggestedItem={suggestedItem}
                            />
                        </div>

                        <div className={"form-group px-3 py-1"}>
                            <div className={"row"}>
                                {/* Quantita */}
                                <div className={"col-6"}>
                                    Quantità
                                    <div className={"d-flex text-center"}>
                                        <button type={"button"} className={"btn btn-danger mr-1"} onClick={_=>this.handleMinusButton('quantita')}>
                                            <FaSolidIcon name={"minus"}/>
                                        </button>
                                        <input
                                            type={"number"}
                                            name={"quantita"}
                                            placeholder={"Quantità"}
                                            value={this.state.item.quantita}
                                            className={"form-control radius-0 mr-1 " + (this.state.item.quantita > 0 ? "" : "is-invalid")}
                                            step={"1"}
                                            onChange={this.handleInputItemChange}
                                        />
                                        <button type={"button"} className={"btn btn-danger btn-primary"} onClick={_=>this.handlePlusButton('quantita')}>
                                            <FaSolidIcon name={"plus"}/>
                                        </button>
                                    </div>
                                </div>

                                {/* Importo */}
                                <div className={"col-6"}>
                                    Importo unitario
                                    <div className={"d-flex"}>
                                        <button type={"button"} className={"btn btn-danger mr-1"} onClick={_=>this.handleMinusButton('importo_unitario')}>
                                            <FaSolidIcon name={"minus"}/>
                                        </button>
                                        <input
                                            type={"number"}
                                            name={"importo_unitario"}
                                            placeholder={"importo unitario"}
                                            value={this.state.item.importo_unitario}
                                            className={"form-control mr-1"}
                                            step={"0.01"}
                                            onChange={this.handleInputItemChange}
                                        />
                                        <button type={"button"} className={"btn btn-danger btn-primary"} onClick={_=>this.handlePlusButton('importo_unitario')}>
                                            <FaSolidIcon name={"plus"}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Sconto */}
                        <div className={"form-group px-3 py-1"}>
                            Inserisci uno sconto
                            <input
                                type={"number"}
                                name={"sconto"}
                                placeholder={"Es. 14"}
                                value={this.state.item.sconto}
                                className={"form-control"}
                                step={"0.01"}
                                onChange={this.handleInputItemChange}
                            />
                            <div className={"d-flex justify-content-between mt-2"}>
                                <button className={"btn btn-outline-primary btn-sm"} type={"button"} onClick={_=>this.applyPredefinedDiscount(10)}>10%</button>
                                <button className={"btn btn-outline-primary btn-sm"} type={"button"} onClick={_=>this.applyPredefinedDiscount(15)}>15%</button>
                                <button className={"btn btn-outline-primary btn-sm"} type={"button"} onClick={_=>this.applyPredefinedDiscount(20)}>20%</button>
                                <button className={"btn btn-outline-primary btn-sm"} type={"button"} onClick={_=>this.applyPredefinedDiscount(25)}>25%</button>
                                <button className={"btn btn-outline-primary btn-sm"} type={"button"} onClick={_=>this.applyPredefinedDiscount(30)}>30%</button>
                                <button className={"btn btn-outline-primary btn-sm"} type={"button"} onClick={_=>this.applyPredefinedDiscount(50)}>50%</button>
                            </div>
                        </div>

                        {/* Iva */}
                        <div className={"form-group px-3 py-1"}>
                            <label>Aliquota IVA</label>
                            <select className={"form-control"}
                                    name={"codice_aliquota"}
                                    onChange={this.handleInputItemChange}
                                    value={this.state.item.codice_aliquota}>
                                {
                                    ALIQUOTE_IVA.map((a, k) => {
                                        return <option value={a.codice} key={k}>{a.descrizione}</option>
                                    })
                                }

                            </select>
                        </div>

                        {/* Subtot. */}
                        <div className={"px-3 py-1"}>
                            <label>Subtotale</label><br/>
                            <h4>€{this.getSubtotalOfItem()}</h4>
                            {
                                this.getSubtotalOfItem() < 0 &&
                                    <span className={"text-warning"}>L'importo non può essere negativo</span>
                            }
                        </div>

                        <div className={"w-100 p-3"}>
                            <button className={"btn btn-block btn-primary"} onClick={_=>this.handleAddElement()}>+ Aggiungi</button>
                        </div>

                    </InfoWrapper>
                </SwipeablePanel>
            </Fragment>
        )

    }
}


AggiungiItemPanel.propTypes = {
    useNavbar: PropTypes.bool,
    expanded: PropTypes.bool,
    onToggle: PropTypes.func,
    item: PropTypes.object,
    onAddItem: PropTypes.func
}