import React from "react";
import PropTypes from "prop-types";
import AppConfig from "../../../config/AppConfig";
import AuthAPI from "../../../api/AuthAPI";
import {SUBSCRIPTION_STATUS_SUSPENDEND, SUBSCRIPTION_STATUS_TERMINATED} from "../../../const/SubscriptionConstants";
import moment from "moment";

export class SubscriptionMessageView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getAutologinUrl = _ => {
        return btoa(`${AppConfig.webServerUrl}/api/dashboard/autologin/${AuthAPI.getUserAccessToken()}`);
    }

    render() {

        if (this.props.userNegozio.subscription) {
            const subscriptionDueDate = moment(this.props.userNegozio.subscription.due_date);
            const terminationDate = subscriptionDueDate.add(30, 'days').format('dddd DD MMMM YYYY');
            if (this.props.userNegozio.subscription.status === SUBSCRIPTION_STATUS_SUSPENDEND) {
                return <div className={"container-fluid text-muted mt-3 text-center small"}>
                    <b className={"text-danger"}>ATTENZIONE</b><br/>
                    Il tuo abbonamento è stato temporaneamente <b>sospeso</b> a causa del mancato pagamento della
                    fattura. I
                    tuoi clienti possono continuare ad effettuare ordini e prenotazioni sulla tua app. Paga la fattura
                    per
                    poter interagire con loro.<br/>
                    <b className={"text-warning"}>
                        Se non rinnovi il tuo abbonamento entro {terminationDate} la tua app verrà rimossa da Bookizon e
                        perderai l'offerta con cui ti sei abbonato
                    </b>
                </div>
            }

            if (this.props.userNegozio.subscription.status === SUBSCRIPTION_STATUS_TERMINATED) {
                return <div className={"container-fluid text-muted mt-3 text-center small"}>
                    <b className={"text-danger"}>ATTENZIONE</b><br/>
                    Sembra che il tuo abbonamento sia scaduto, usa il pulsante qui sotto per sottoscrivere un nuovo
                    abbonamento per non lasciarti sfuggire gli ordini e prenotazioni dei tuoi clienti.

                    <a href={`https://proinn.web.app/bookizon/redirect.html?url=${this.getAutologinUrl()}`}
                       target={'_blank'}
                       className={"btn btn-success btn-sm text-white mt-2"}>
                        Attiva abbonamento
                    </a>
                </div>
            }
        }

        //Significa che sono scaduti i giorni di prova
        return <div className={"container-fluid text-muted mt-3 text-center small"}>
            <b className={"text-danger"}>ATTENZIONE</b><br/>
            Sembra che tu non abbia un abbonamento attivo al momento e i tuoi giorni di prova gratuiti sono terminati.
            Usa il pulsante qui sotto per sottoscrivere un abbonamento per non lasciarti sfuggire gli ordini e
            prenotazioni dei tuoi clienti.

            <a href={`https://proinn.web.app/bookizon/redirect.html?url=${this.getAutologinUrl()}`}
               target={'_blank'}
               className={"btn btn-success btn-sm text-white mt-2"}>
                Attiva abbonamento
            </a>
        </div>
    }

}

SubscriptionMessageView.propTypes = {
    userNegozio: PropTypes.object.isRequired
}
