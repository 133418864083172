import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import AttivitaWelfareModal from "../../../WelfareAttivita/AttivitaWelfareModal";

export class DashboardAttivitaWelfareButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageOpen: false
        }
    }

    handlePageOpen = _ => this.setState({pageOpen: true});
    handlePageClose = _ => this.setState({pageOpen: false});

    render() {
        return (
            <Fragment>
                <div className={"d-flex align-items-center justify-content-between p-3 border-top text-danger"} onClick={this.handlePageOpen}>
                    <span>
                        <FaSolidIcon name={"money-bill"}/>&nbsp;
                        Welfare attività
                    </span>
                </div>
                <ModalPortalHOC>
                    {/* Welfare attività */}
                    {
                        this.state.pageOpen &&
                        <AttivitaWelfareModal
                            negozio={this.props.userNegozio}
                            onDismiss={this.handlePageClose}
                            active = {true}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

DashboardAttivitaWelfareButton.propTypes = {
    userNegozio: PropTypes.object
}