import {applyPercentage} from "../../../helpers/NumberUtility";

export const FoodCartProductPriceReduce = (acc, prodotto) => {
    let discount = prodotto.perc_sconto ? parseFloat(prodotto.perc_sconto) : 0.0;
    let prezzoBase = applyPercentage(parseFloat(prodotto.prezzo),-discount);
    let prezzoAggiunte = 0;
    if (prodotto.aggiunte) {
        prodotto.aggiunte.map(id_aggiunta => {
            const datiAggiunta = prodotto.listaAggiunte.find(a => a.id_aggiunta === id_aggiunta);
            prezzoAggiunte += parseFloat(datiAggiunta.prezzo);
        });
    }
    let prezzoOpzione = 0;
    if (prodotto.id_opzione) {
        const datiOpzione = prodotto.listaOpzioni.find(o => o.id_opzione === prodotto.id_opzione);
        console.log(parseFloat(datiOpzione.prezzo));
        prezzoOpzione += parseFloat(datiOpzione.prezzo);
    }
    return (prezzoBase + prezzoAggiunte + prezzoOpzione) * prodotto.quantita + acc;
}
