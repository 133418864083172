import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import Page from "../../../components/BaseComponents/Page/Page";
import {BeautyIllustration} from "../../../img/illustrations/search-page/BeautyIllustration";
import {EcommerceIllustration} from "../../../img/illustrations/search-page/EcommerceIllustration";
import {ProfessionistiIllustration} from "../../../img/illustrations/search-page/ProfessionistiIllustration";
import {RistorazioneIllustration} from "../../../img/illustrations/search-page/RistorazioneIllustration";
import {SportIllustration} from "../../../img/illustrations/search-page/SportIllustration";
import {ViaggiIllustration} from "../../../img/illustrations/search-page/ViaggiIllustration";
import LogoTotalWhite from "../../../img/logo-icon-total-white.svg";
import {MacroCategoryItem} from "./MacroCategoryItem";
import {ResetInput} from "../../../components/BaseComponents/Inputs/ResetInput";
import expoSVG from "../../svg/EXPO.svg";
import i18n from "i18next";

const categorieNegozi = [
    {
        id_categoria: 20,
        nome: i18n.t('search-page.categories.beauty.name'),
        disabled: false,
        image: BeautyIllustration,
        color: "#d5b849"
    },
    {
        id_categoria: 23,
        nome: i18n.t('search-page.categories.food.name'),
        disabled: false,
        image: RistorazioneIllustration,
        color: "#f4b942"
    },
    {
        id_categoria: 24,
        nome: i18n.t('search-page.categories.professionals.name'),
        disabled: false,
        image: ProfessionistiIllustration,
        color: "#8a8a8a"
    },
    {
        id_categoria: 26,
        nome: i18n.t('search-page.categories.shops.name'),
        disabled: false,
        image: EcommerceIllustration,
        color: "#fb3a3a"
    },
    {
        id_categoria: 22,
        nome: i18n.t('search-page.categories.sport.name'),
        disabled: false,
        image: SportIllustration,
        color: "#85d25f"
    },
    {
        id_categoria: 25,
        nome: i18n.t('search-page.categories.tourism.name'),
        disabled: false,
        image: ViaggiIllustration,
        color: "#3fa0da"
    },
];

const HeadingStyle = {zIndex: 1, position: "relative"};
const SearchButtonStyle = {width: 38, height: 38, textAlign: 'center'};

export class MacroCategoryChooserPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            directSearchQuery: ''
        }
    }

    handleFullBookizonSearch = e => {
        e.preventDefault();
        this.props.onDirectSearch(this.state.directSearchQuery, {
            id_categoria: null,
            nome: i18n.t('search-page.categories.generic.name'),
            disabled: false,
            image: _ => '',
            color: "#293069"
        });
        this.setState({directSearchQuery: ''}); //Pulisco l'input
    }

    handleInputChange = e => this.setState({[e.target.name]: e.target.value});
    handleReset = _ => this.setState({directSearchQuery: ''});

    render() {
        return (
            <Page
                title={""}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                leftIconText={i18n.t('generic.back-btn')}
                onLeftIconClick={this.props.onDismiss}
                rightIcon={<img src={LogoTotalWhite} height={22} alt={"Bookizon logo"}/>}
                active={true}
            >
                <h5 className={"font-weight-bold text-primary text-center bg-primary text-white pt-2"}
                    style={HeadingStyle}>
                    {i18n.t('search-page.headline')}
                </h5>
                <div className={"overflow-hidden mw-100 w-100 mt-n3"}>
                    <div className={"mx-n2"}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 1440 160" style={{enableBackground: 'new 0 0 1440 160'}} xmlSpace="preserve">
                            <path fill={"#293069"}
                                  d="M0,160l80-10.7c80-10.3,240-32.3,400-42.6C640,96,800,96,960,85.3C1120,75,1280,53,1360,42.7l80-10.7V0h-80c-80,0-240,0-400,0S640,0,480,0S160,0,80,0H0V160z"/>
                        </svg>
                    </div>
                </div>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12 py-2"}>
                            <form onSubmit={this.handleFullBookizonSearch}>
                                <div className={"d-flex"}>
                                    <div
                                        className={"position-relative flex-fill rounded-pill bg-white shadow-sm overflow-hidden mr-2"}>
                                        <input
                                            type={"text"}
                                            name={"directSearchQuery"}
                                            className={"form-control border-0"}
                                            value={this.state.directSearchQuery}
                                            onChange={this.handleInputChange}
                                            placeholder={i18n.t('search-page.input-placeholder')}
                                        />
                                        <ResetInput onClick={this.handleReset}>
                                            &times;
                                        </ResetInput>
                                    </div>
                                    <button
                                        className={"btn btn-primary rounded-circle d-flex align-items-center justify-content-center p-0"}
                                        disabled={!this.state.directSearchQuery} style={SearchButtonStyle}>
                                        <FaSolidIcon name={"search"}/>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className={"col-12 py-2"}>
                            <div className={"small text-muted text-center"}>
                                {i18n.t('search-page.choose-category-label')}
                            </div>
                        </div>
                        {
                            categorieNegozi.map((c, i) => <MacroCategoryItem key={i} i={i} categoria={c}
                                                                             onClick={this.props.onChoose}/>)
                        }
                    </div>
                </div>
            </Page>
        )
    }

}

MacroCategoryChooserPage.propTypes = {
    onDismiss: PropTypes.func.isRequired,
    onChoose: PropTypes.func.isRequired,
    onDirectSearch: PropTypes.func.isRequired,
    onExpoOpen: PropTypes.func.isRequired
}
