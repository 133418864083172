import React, {Fragment} from "react";
import PropTypes from "prop-types";

export class ChatListSearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query: ''
        }
    }

    handleChange = e => {
        this.setState({query: e.target.value});
        this.props.onQueryChange(e.target.value);
    }

    handleReset = _ => {
        this.setState({query: ''});
        this.props.onQueryChange('');
    }

    render() {
        return (
            <div className={"input-group input-group-sm"}>
                <input
                    className={"form-control"}
                    onChange={this.handleChange}
                    value={this.state.query}
                    placeholder={"Cerca una conversazione..."}
                />
                {
                    !!this.state.query &&
                    <div className={"input-group-append"} onClick={this.handleReset}>
                        <span className={"input-group-text bg-white"}>
                            <i className={"fas fa-times-circle"}/>
                        </span>
                    </div>
                }
            </div>

        )
    }

}

ChatListSearchBar.propTypes = {
    onQueryChange: PropTypes.func.isRequired
}