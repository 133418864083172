import React from "react";
import styled from "styled-components";

export const PageFooter = styled.div`
    position:fixed; z-index:2001; bottom:0px; left:0px; right:0px;
`;

export const PageWithFooterContainer = styled.div`

    
`;
