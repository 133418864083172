import React, {Fragment} from "react";
import {FaRegularIcon, FaSolidIcon} from "../../components/FontAwesomeIcons";
import ServiceBookCard from "./ServiceBook/ServiceBookCard";
import OrdineCard from "./Ordini/OrdineCard";
import HotelNextBooksItem from "./HotelNextBooks/HotelNextBooksItem";
import GroupBookCard from "./GroupBooks/GroupBookCard";
import {ECOMMERCE_ORDER_TYPE_DEFAULT, ECOMMERCE_ORDER_TYPE_RITIRO} from "../../const/ModuloEcommerceCostants";
import {FOOD_ORDER_TYPE_ASPORTO, FOOD_ORDER_TYPE_CONSEGNA} from "../../const/ModuloFoodCostants";
import moment from "moment";
import {FuxCarousel} from "../../components/BaseComponents/FuxCarousel/FuxCarousel";
import {pluralString, PluralString} from "../../helpers/UIUtility";
import ModalPortalHOC from "../../components/BaseComponents/ModalPortalHOC";
import {WaitingEcommerceOrdersPage} from "./Ordini/WaitingEcommerceOrdersPage";
import i18n from "../../i18n";
import SeatsNextBooksItem from "./SeatBook/SeatsNextBooksItem";

export default class LauncherNextBooksList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ordiniEcommercePageOpen: false
        }
    }

    toggleEcommerceOrdiniPage = _ => this.setState({ordiniEcommercePageOpen: !this.state.ordiniEcommercePageOpen});

    getEcommerceOrders = _ => {
        return this.props.ordini.filter(o => {
            return o.tipo_ordine === ECOMMERCE_ORDER_TYPE_DEFAULT || o.tipo_ordine === ECOMMERCE_ORDER_TYPE_RITIRO
        });
    }

    getFoodOrders = _ => {
        return this.props.ordini.filter(o => {
            return o.tipo_ordine === FOOD_ORDER_TYPE_ASPORTO || o.tipo_ordine === FOOD_ORDER_TYPE_CONSEGNA
        });
    }

    getSortedItems = _ => {
        let items = [...this.props.books, ...this.getFoodOrders(), ...this.props.hotelBooks, ...this.props.seatBooks];
        const nowTimestamp = moment().unix();

        const getStartTime = item => {
            if (item.id_ordine){
                return `${item.data} ${item.orario}:00`
            }else if(item.master && item.master.seat_id){
                return `${item.master.date} ${item.master.start_time}:00`
            }else{
                return item.datetime_inizio;
            }
        }

        items.sort((a, b) => {
            const aDiff = moment(getStartTime(a)).unix() - nowTimestamp;
            const bDiff = moment(getStartTime(b)).unix() - nowTimestamp;
            if (aDiff === bDiff) return 0;
            return aDiff > bDiff ? 1 : -1;
        });
        return items;
    }

    getTotalItems = _ => this.props.books.length + this.getFoodOrders().length + this.props.hotelBooks.length + this.props.seatBooks.length;

    render() {
        if (!this.props.books || !this.props.hotelBooks || !this.props.ordini || !this.props.seatBooks) return; //Devono essere tutti e 3 settati (anche se vuoti)

        const ecommerceOrders = this.getEcommerceOrders();
        return (

            <Fragment>
                {
                    this.getTotalItems() > 0 &&
                    <div className={"pt-3"}>
                        <div className={"px-3 mb-2"}>
                            <small className={"text-muted"}>
                                <FaRegularIcon name={"calendar-alt"}/> {i18n.t('launcher.homepage.appuntamenti-in-corso')}
                            </small>
                        </div>
                        <FuxCarousel
                            className={"__disable-swipe"}
                            items={
                                this.getSortedItems().map((item, i) => {
                                    return <div key={`o_${item.id_ordine}_p_${item.id_prenotazione}`}
                                                className={'w-100 px-3'}>

                                        {/* Ordini ecommerce/food */}
                                        {
                                            item.id_ordine &&
                                            <OrdineCard
                                                className={"h-100"}
                                                order={item}
                                                showOpenApp={true}
                                                onAppOpen={this.props.onAppOpen}
                                            />
                                        }

                                        {/* Prenotazioni gruppi */}
                                        {
                                            (!!item.id_prenotazione && !!item.id_gruppo) &&
                                            <GroupBookCard
                                                className={"h-100"}
                                                book={item}
                                                showOpenApp={true}
                                                onAppOpen={this.props.onAppOpen}
                                            />
                                        }

                                        {/* Prenotazioni servizi */}
                                        {
                                            (!!item.id_prenotazione && item.id_servizio !== undefined) &&
                                            <ServiceBookCard
                                                className={"h-100"}
                                                book={item}
                                                showOpenApp={true}
                                                onAppOpen={this.props.onAppOpen}
                                            />
                                        }

                                        {/* Prenotazioni hotel */}
                                        {
                                            (!!item.id_prenotazione && !!item.id_camera) &&
                                            <HotelNextBooksItem
                                                className={"h-100"}
                                                book={item}
                                                showOpenApp={true}
                                                onAppOpen={this.props.onAppOpen}
                                            />
                                        }

                                        {/* Prenotazioni seats */}
                                        {
                                            (!!item.master && !!item.master.seat_id) &&
                                            <SeatsNextBooksItem
                                                className={"h-100"}
                                                book={item}
                                                showOpenApp={true}
                                                onAppOpen={this.props.onAppOpen}
                                            />
                                        }
                                    </div>
                                })
                            }
                        />
                    </div>
                }
                {
                    ecommerceOrders.length > 0 &&
                    <div className={"text-center mt-3"}>
                        <button className={"btn btn-outline-primary btn-sm"} onClick={this.toggleEcommerceOrdiniPage}>
                            <FaSolidIcon
                                name={"shopping-bag"}/> {i18n.t("launcher.homepage.ordini-in-corso-template", {text: `${ecommerceOrders.length} ${pluralString(ecommerceOrders.length, "ordini", "ordine")}`})}
                        </button>
                    </div>
                }

                <ModalPortalHOC>
                    {
                        this.state.ordiniEcommercePageOpen &&
                        <WaitingEcommerceOrdersPage
                            ordini={this.getEcommerceOrders()}
                            onAppOpen={this.props.onAppOpen}
                            onDismiss={this.toggleEcommerceOrdiniPage}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        );
    }

}
