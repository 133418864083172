import React, {Fragment} from "react";
import styled from "styled-components";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {BookizonAppManager} from "../../../../index";
import AppConfig from "../../../../config/AppConfig";
import AuthAPI from "../../../../api/AuthAPI";

const Modifier = styled.div`
    display:inline;
    & + &:before{
        content:", ";
        display:inline;
    }
`;

const COMANDE_PRODUCT_STATUS_ATTESA = 'prodotto.in_attesa';
const COMANDE_PRODUCT_STATUS_CONSEGNATO = 'prodotto.consegnato';

export default class ComandaCard extends React.Component {

    constructor(props) {
        super(props);
    }

    handlePrint = _ => {
        const comandaPrintRoute = btoa(`/admin/comande/print/${this.props.id_comanda}`);
        const autologinUrl = `${AppConfig.webServerUrl}/api/dashboard/autologin/${AuthAPI.getUserAccessToken()}?redirect=${comandaPrintRoute}`;
        BookizonAppManager.openInAppBrowser(autologinUrl);
    }

    render() {
        return (
            <div className={"card my-3 border-0 shadow-sm"}>
                <div className={"card-body p-3"}>
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <div>
                            <h5 className={"text-purple mb-0"}>Comanda #{this.props.id_comanda}</h5>
                            <div
                                className={"text-muted small"}> {this.props.nome_admin} {this.props.cognome_admin}</div>
                        </div>
                        <div>
                            <button className={"btn btn-link text-purple"} onClick={this.handlePrint}>
                                <FaSolidIcon name={"print"}/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={"list-group list-group-flush px-n3"}>
                    {
                        this.props.prodotti.map((p, i) =>
                            <div className={"list-group-item px-3"} key={i}>
                                {
                                    (p.status === COMANDE_PRODUCT_STATUS_ATTESA && !this.props.isReadOnly) &&
                                    <Fragment>
                                        <button className={"btn btn-link text-danger"}
                                                onClick={_ => this.props.onEliminaProdotto(p)}>
                                            <FaSolidIcon name={"trash"}/>
                                        </button>
                                        <button className={"btn btn-link text-success"}
                                                onClick={_ => this.props.onConsegnaProdotto(p)}>
                                            <FaSolidIcon name={"check"}/>
                                        </button>
                                    </Fragment>
                                }
                                <span
                                    className={p.status === COMANDE_PRODUCT_STATUS_CONSEGNATO ? 'text-success' : ''}>
                                        {p.quantita}x{p.nome_prodotto} €{parseFloat(p.prezzo_unitario).toFixed(2)}
                                    </span>
                                <div>
                                    {
                                        p.aggiunte.map((a, i) => {
                                            return (
                                                <Modifier key={i}>
                                                    {a.type === 1 &&
                                                    <FaSolidIcon name={"plus-circle"} className={"text-success"}/>}
                                                    {a.type === 0 &&
                                                    <FaSolidIcon name={"minus-circle"} className={"text-danger"}/>}
                                                    {a.type === 2 &&
                                                    <FaSolidIcon name={"info-circle"} className={"text-info"}/>}
                                                    {a.text}
                                                </Modifier>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

}
