import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import DashboardUtentiAPI from "../../../api/DashboardUtentiAPI";
import {swal, swalError, swalLoading} from "../../../helpers/SweetAlertWrapper";
import {CheckboxSwitch} from "../../../components/Bootstrap4/CustomControl/CheckboxSwitch";

export default class CreateCRMUserView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nome: this.props.defaultName || '',
            cognome: '',
            email: '',
            cellulare: '',
            smsReminder: 1,
            whatsappReminder: 1,
            emailReminder: 1,
            smsSetting: 1,
            whatsappSetting: 1,
            emailSetting: 1,
        };

        this.typingTimer = null;
        this.doneTypingInterval = 300;
        this.cancelTokenSource = undefined;
    }

    handleChange = ({target}) => {
        this.setState({[target.name]: target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value});
    }

    handleCreateUser = _ => {
        swalLoading('Attendere prego...');
        DashboardUtentiAPI.createCRM({...this.state})
            .then(userData => {
                swal.safeClose();
                this.props.onUserCreated(userData);
            })
            .catch(swalError);
    }

    render() {
        return (
            <Fragment>
                <h5 className={"font-weight-bold text-center"}>Crea scheda cliente</h5>
                <div className={"mb-3"}>
                    <label>Nome (opzionale)</label>
                    <input type={"text"} name={"nome"} value={this.state.nome} className={"form-control"}
                           onChange={this.handleChange}/>
                </div>
                <div className={"mb-3"}>
                    <label>Cognome (opzionale)</label>
                    <input type={"text"} name={"cognome"} value={this.state.cognome} className={"form-control"}
                           onChange={this.handleChange}/>
                </div>
                <div className={"mb-3"}>
                    <label>Cellulare (opzionale)</label>
                    <input type={"tel"} name={"cellulare"} value={this.state.cellulare} className={"form-control"}
                           onChange={this.handleChange}/>
                </div>
                <div className={"mb-3"}>
                    <label>Email (opzionale)</label>
                    <input type={"email"} name={"email"} value={this.state.email} className={"form-control"}
                           onChange={this.handleChange}/>
                </div>

                <div className={"mb-3"}>
                    <label>Preferenze promemoria</label>
                    <CheckboxSwitch
                        label={"Whatsapp"}
                        name={"whatsappReminder"}
                        checked={this.state.whatsappReminder}
                        onChange={this.handleChange}
                    />

                    <CheckboxSwitch
                        label={"Email"}
                        name={"emailReminder"}
                        checked={this.state.emailReminder}
                        onChange={this.handleChange}
                    />
                    <CheckboxSwitch
                        label={"SMS"}
                        name={"smsReminder"}
                        checked={this.state.smsReminder}
                        onChange={this.handleChange}
                    />

                </div>
                <div className={"mb-3"}>
                    <label>Preferenze di marketing</label>
                    <CheckboxSwitch
                        label={"Whatsapp"}
                        name={"whatsappSetting"}
                        checked={this.state.whatsappSetting}
                        onChange={this.handleChange}
                    />

                    <CheckboxSwitch
                        label={"Email"}
                        name={"emailSetting"}
                        checked={this.state.emailSetting}
                        onChange={this.handleChange}
                    />

                    <CheckboxSwitch
                        label={"SMS"}
                        name={"smsSetting"}
                        checked={this.state.smsSetting}
                        onChange={this.handleChange}
                    />

                </div>

                <button className={"btn btn-block btn-primary mb-3"} onClick={this.handleCreateUser}>
                    <FaSolidIcon name={"save"}/> Salva scheda cliente
                </button>
                <button className={"btn btn-block btn-soft-primary mb-3"} onClick={this.props.onDismiss}>
                    <FaSolidIcon name={"chevron-left"}/> Annulla
                </button>
            </Fragment>
        )
    }
}


CreateCRMUserView.propTypes = {
    onDismiss: PropTypes.func,
    onUserCreated: PropTypes.func,
    defaultName: PropTypes.string,
}

