import React from 'react';
import Page from "../BaseComponents/Page/Page";
import PropTypes from 'prop-types'
import {FaSolidIcon} from "../FontAwesomeIcons";
import styled from "styled-components";
import DiscountBoxToWarnUser from "../DiscountBoxToWarnUser";

export default class ListGroupPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page
                title={this.props.title}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                active={this.props.active}
                content={
                    <div className={"container pt-2"}>
                        {/*Stampo box per far capire la presenza di uno sconto esterno*/}
                        {this.props.promotionDiscount && <DiscountBoxToWarnUser discount={this.props.promotionDiscount}/>}

                        {
                            this.props.subtitle ?
                                <div className={"mb-2 px-1"}>
                                    <small className={"text-on-bg-main"}>{this.props.subtitle}</small>
                                </div>
                                : ''
                        }
                        <div className={"list-group"}>
                            {
                                this.props.items.map((item,i) => <ListGroupItem {...item} key={i} onClick={this.props.onChoose}/> )
                            }
                        </div>
                    </div>
                }
            />
        );
    }

}

ListGroupPage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    active: PropTypes.bool,
    onChoose: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.elementType]),
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.elementType]),
        image: PropTypes.string
    })),
    onDismiss: PropTypes.func
};


class ListGroupItem extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div className={"list-group-item bg-main-z2 text-on-bg-main px-0"} onClick={_ => this.props.onClick(this.props.value)}>
                <div className="container-fluid">
                    <div className={"row align-items-center"}>
                        {
                            this.props.image &&
                            <div className={"col"}>
                                <ListGroupItemImage onClick={this.handleImageClick}
                                                    className={"mx-auto"}
                                                    image={this.props.image}/>
                            </div>
                        }
                        <div className={this.props.image ? "col-8" : "col-10"}>
                            <span className={"text-on-bg-main"}>{this.props.text}</span>
                            <small
                                className={"d-block text-on-bg-main"}>{this.props.description}</small>
                        </div>
                        <div className={"col-2"}>
                            <FaSolidIcon name={"chevron-right"}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const ListGroupItemImage = styled.div`
    width:40px;
    height:40px;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    background-image:url(${props => props.image});
    border-radius:50%;
`;
