import Page from "../../../../../components/BaseComponents/Page/Page";
import {FaBrandIcon, FaSolidIcon} from "../../../../../components/FontAwesomeIcons";
import React, {Fragment} from "react";
import ModalPortal from "../../../../../components/BaseComponents/ModalPortal";
import {ALIQUOTE_IVA, ME, METODI_PAGAMENTO} from "../ModuloCassaContants";
import {
    getDescrizioneIva,
    getScontoAPagare,
    getSubtotalOfItem,
    getTotaleItems,
    getTotaleIva,
    getTotaleScontrino
} from "./../ReceiptsUtils"
import {swal, swalError, swalInput, swalLoading, swalSuccess} from "../../../../../helpers/SweetAlertWrapper";
import {ModuloCassaAPI} from "../../../../../api/ModuloCassa/ModuloCassaAPI";
import PropTypes, {string} from "prop-types";
import ListItemsInterface from "./ListItemsInterface/ListItemsInterface";
import styled from "styled-components";
import KeypadInterface from "./KeypadInterface/KeypadInterface";
import ReceiptsHistoryPage from "../ReceiptsHistoryPage";
import ModuloCassaSideMenu from "../SideMenu/ModuloCassaSideMenu";
import {PAYMENT_METHOD_CONTANTI} from "../../../../../const/PaymentMethodoCostants";
import APIConfig from "../../../../../api/APIConfig";
import ShortLinkAPI from "../../../../../api/ShortLink/ShortLinkAPI";
import {BookizonAppManager} from "../../../../../index";
import {SwipeablePanel} from "../../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";

const VIEW_TYPE_ITEMS = 'items';
const VIEW_TYPE_KEYPAD = 'keypad';
const DEFAULT_VIEW_TYPE_LOCAL_STORAGE_KEY = 'dashboard_cassa_default_view_type';

const ViewTypeWrapper = styled.div`
    & .btn.btn-sm:focus {
        box-shadow: none !important;
    }
`;
const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export default class CreateReceiptsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            metodi_pagamento: [{metodo_pagamento: PAYMENT_METHOD_CONTANTI, importo: ""}],
            scontoDocumento: "",
            viewType: VIEW_TYPE_ITEMS,
            feedbackPanelOpen: false,
            lastSendReceipt: {},
            //Cronologia
            menuOpen: false
        }
        this.baseItem = {
            descrizione: "",
            importo_unitario: 1,
            quantita: 1,
            sconto: "",
            codice_aliquota: ALIQUOTE_IVA[3].codice,
            sconto_documento: ""
        };
        this.base_metodi_pagamento = {
            metodo_pagamento: PAYMENT_METHOD_CONTANTI,
            importo: ""
        }

        const savedViewType = localStorage.getItem(DEFAULT_VIEW_TYPE_LOCAL_STORAGE_KEY);
        if (savedViewType) this.state.viewType = savedViewType;
    }


    /**
     * @TOGGLE
     */
    toggleMenu() {
        this.setState({menuOpen: !this.state.menuOpen})
    }


    /**
     * @GESTIONE-VISUALIZZAZIONE
     */
    handleViewTypeChange = t => _ => this.setState({viewType: t}, _ => localStorage.setItem(DEFAULT_VIEW_TYPE_LOCAL_STORAGE_KEY, t));


    /**
     * @GESTIONE-ITEMS
     */
    handleAddItem = newItem => {
        console.log(newItem)
        let items = [...this.state.items]
        //Se ho setteto l'index vuol dire che si tratta di una modifica di Item
        if (newItem.index) {
            items[newItem.index] = newItem
        } else {
            items.push(newItem)
        }
        this.setState({items: items}, _ => {
            this.adaptAutomaticallyImportoMetodoPagamento()
        })
    }

    //Modifica lo sconto documento presente nell'item, andando a ripartire lo sconto globale in base all'incidenza di esso sul totale
    changeScontoDocumentoFotItems = _ => {
        //se non c'é sconto documento posso anche ignorarlo
        if (!this.state.scontoDocumento) {
            return
        }
        let newItems = []
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i]
            //Calcolo quanto l'importo incide sul totale degli items (singoloItem/totaleItems*100)
            let incidenzaSulTotale = (getSubtotalOfItem(item, false)
                / getTotaleItems(this.state.items, false) * 100).toFixed(2)
            //calcolo lo sconto documento in base alla percentuale di incidenza
            item.sconto_documento = this.state.scontoDocumento * incidenzaSulTotale / 100
            newItems.push(item)
        }
        this.setState({items: newItems})
    }

    handleDeleteItem = index => {
        const items = [...this.state.items];
        items.splice(index, 1);
        this.setState({items: items}, _ => {
            this.adaptAutomaticallyImportoMetodoPagamento()
        })
    }

    itemsErrors = _ => {
        //Se non esiste nessun metodo di pagamento
        if (!this.state.items.length) return true
        //Ciclo sugli item per capire se i dati sono corretti
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].descrizione === "") return true
            if (this.state.items[i].quantita < 1) return true
        }
        return false
    }

    /**
     * @GESTIONE-METODI-PAGAMENTO
     */
    handleAddMetodoPagamento = _ => {
        const metodiPagamento = this.state.metodi_pagamento
        metodiPagamento.push(this.base_metodi_pagamento)
        this.setState({metodi_pagamento: metodiPagamento})
    }

    handleDeleteMetodoPagamento = index => {
        const metodiPagamento = [...this.state.metodi_pagamento];
        metodiPagamento.splice(index, 1);
        this.setState({metodi_pagamento: metodiPagamento});
    }

    handleMetodoPagamentoChange = ({target}) => {
        const metodiPagamento = [...this.state.metodi_pagamento];
        const idx = target.getAttribute('data-idx');
        metodiPagamento[idx] = {...this.state.metodi_pagamento[idx], [target.name]: target.value};
        this.setState({metodi_pagamento: metodiPagamento});
    }

    metodiPagamentoErrors = _ => {
        //Se non esiste nessun metodo di pagamento
        if (!this.state.metodi_pagamento.length) return <span className={"text-danger"}>Ci deve essere almeno un metodo di pagamento</span>
        //Calcolo il totale inserito nei metodi di pagamento
        let totaleMetodiPagamento = this.state.metodi_pagamento.reduce((totale, metodi) => {
            return totale + parseFloat(metodi.importo)
        }, 0)

        if (totaleMetodiPagamento < getTotaleScontrino(this.state.metodi_pagamento, this.state.items)) {
            return <span
                className={"text-warning"}>Attenzione, i metodi di pagamento non coprono l'intero importo</span>
        }
        if (totaleMetodiPagamento > getTotaleScontrino(this.state.metodi_pagamento, this.state.items)) {
            return <span className={"text-warning"}>Attenzione, il totale dei metodi di pagamento <b>non può superare l'importo dello scontrino</b></span>
        }
        return false
    }

    //In caso di creazione scontrino inserisco l'importo totale nel primo metodo di pagamento, in questo modo verrà agevolato l'inserimento
    adaptAutomaticallyImportoMetodoPagamento = _ => {
        const metodiPagamento = [...this.state.metodi_pagamento];
        let totaleScontrino = getTotaleScontrino(metodiPagamento, this.state.items)
        let importoFirstMethod = parseFloat(metodiPagamento[0].importo || "0")

        //Calcolo il totale inserito nei metodi di pagamento
        let totaleMetodiPagamento = metodiPagamento.reduce((totale, metodi) => {
            return totale + parseFloat(metodi.importo)
        }, 0) || 0

        //Dal totale dello scontrino rimuovo il totale dei metodi di pagamento escluso il primo, in questo modo posso conoscere esattamente l'importo da assegnare al primo metodo
        const newImporto = totaleScontrino - (totaleMetodiPagamento - importoFirstMethod)
        metodiPagamento[0].importo = newImporto < 0 ? 0 : newImporto
        this.setState({metodi_pagamento: metodiPagamento});
    }

    /**
     *@GESTIONE-IMPORTO-SCONTO-GLOBALE
     * */

    handleImportoScontoGlobaleOnChange = ({target}) => {
        //Quando viene cancellato lo sconto deve tornare a 0
        target.value = !target.value ? 0 : target.value
        this.setState({scontoDocumento: target.value}, _ => {
            this.changeScontoDocumentoFotItems()
        })
    }

    getErrorImportoScontoGlobale = _ => {
        if (this.state.scontoDocumento > getTotaleItems(this.state.items, false)) {
            return <span
                className={"text-warning"}>Attenzione lo sconto non può superare il subtotale dei prodotti</span>
        }
    }

    /* ----- INVIO INFORMAZIONI ----- */
    handleSubmit = _ => {
        if (this.itemsErrors()) {
            swalError("Ricontrolla i dati dei tuoi servizi/prodotti poiché ci sono degli errori")
            return 0;
        }
        if (this.metodiPagamentoErrors()) {
            swalError("Ricontrolla i metodi di pagamento prima di proseguire")
            return 0;
        }
        swalLoading("Attendi mentre salviamo le informazioni")
        ModuloCassaAPI.Dashboard.salvaScontrino(this.state.items, this.state.metodi_pagamento, this.state.scontoDocumento, this.state.id_ricevuta, null)
            .then(ricevuta => {
                if (ricevuta.status === 'inviata_ae') {
                    swal.close()
                    this.setState({lastSendReceipt: ricevuta}, _=>{this.feedbackPanelToggle()})
                } else {
                    swalSuccess("Lo scontrino è stato salvato, verrà inviato appena possibile")
                }
                this.setState({
                    items: [],
                    metodi_pagamento: [{metodo_pagamento: PAYMENT_METHOD_CONTANTI, importo: ""}],
                })

            })
            .catch(msg => {
                swalError(msg)
            })
    }

    /**
     * @SHARE-RECEIPT
     */

    feedbackPanelToggle = _ => this.setState({feedbackPanelOpen: !this.state.feedbackPanelOpen})

    handleShareReceipt = _ => {
        let url = APIConfig.url('/modulo-cassa/scontrini/get-scontrino-data/from-external-link?id_ricevuta=' + this.state.lastSendReceipt.id_ricevuta + '&password=' + this.state.lastSendReceipt.password)
        swalLoading("Attendi, stiamo generando lo scontrino")
        ShortLinkAPI.generate(url)
            .then(data => {
                let msg = "Ciao, a questo link troverai il tuo scontrino: " + data.short_link
                BookizonAppManager.shareData(msg)
                swal.close()
                this.feedbackPanelToggle()
            })
            .catch(swalError)
    }

    handleWhatsappShareReceipt = _ => {
        swalInput("Inserisci il numero di cellulare al quale inviare il messaggio", "", "text", "", "", true, "+39")
            .then(({value}) =>{
                if(!value) swalError("Inserisci un numero valido")

                swalLoading("Stiamo provando ad inviare il tuo messaggio")
                ModuloCassaAPI.Dashboard.sendWhatsapp(this.state.lastSendReceipt.id_ricevuta, value)
                    .then(msg => swalSuccess(msg).then(_=>this.feedbackPanelToggle()))
                    .catch(swalError)
            })
    }


    render() {
        const isModuleWhatsAppActive = this.props.userNegozio.modulesSubscription.some(module => module.module === "modulo_whatsapp" && module.status === "active");

        return (
            <ModalPortal>
                <Page
                    title={<ViewTypeWrapper className={"btn-group border border-white rounded"}>
                        <button
                            className={`btn btn-sm py-0 btn-${this.state.viewType === VIEW_TYPE_ITEMS ? 'light' : 'primary'}`}
                            onClick={this.handleViewTypeChange(VIEW_TYPE_ITEMS)}>
                            <FaSolidIcon name={"list"}/>
                        </button>
                        <button
                            className={`btn btn-sm py-0 btn-${this.state.viewType === VIEW_TYPE_KEYPAD ? 'light' : 'primary'}`}
                            onClick={this.handleViewTypeChange(VIEW_TYPE_KEYPAD)}>
                            <FaSolidIcon name={"calculator"}/>
                        </button>
                    </ViewTypeWrapper>}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}

                    onLeftIconClick={this.props.onDismiss}
                    rightIcon={<FaSolidIcon name={"bars"}/>}
                    onRightIconClick={this.toggleMenu.bind(this)}
                    active={true}
                    content={
                        <div>
                            {/*INTERFACCIA A LINEA DI ITEMS*/}
                            {
                                this.state.viewType === VIEW_TYPE_ITEMS &&
                                <ListItemsInterface
                                    sconto_documento={this.state.scontoDocumento}
                                    errorsScontoDocumento={this.getErrorImportoScontoGlobale}
                                    changeScontoDocumento={this.handleImportoScontoGlobaleOnChange}
                                    //Items
                                    items={this.state.items}
                                    addItem={this.handleAddItem}
                                    deleteItem={this.handleDeleteItem}
                                    //Metodi pagamento
                                    metodi_pagamento={this.state.metodi_pagamento}
                                    addMetodoPagamento={this.handleAddMetodoPagamento}
                                    deleteMetodoPagamento={this.handleDeleteMetodoPagamento}
                                    changeMetodoPagamento={this.handleMetodoPagamentoChange}
                                    errorsMetodiPagamento={this.metodiPagamentoErrors}
                                    //Submit
                                    submitReceipt={this.handleSubmit}
                                />
                            }

                            {/*INTERFACCIA A KEYPAD*/}
                            {
                                this.state.viewType === VIEW_TYPE_KEYPAD &&
                                <KeypadInterface
                                    sconto_documento={this.state.scontoDocumento}
                                    errorsScontoDocumento={this.getErrorImportoScontoGlobale}
                                    changeScontoDocumento={this.handleImportoScontoGlobaleOnChange}
                                    //Items
                                    items={this.state.items}
                                    addItem={this.handleAddItem}
                                    deleteItem={this.handleDeleteItem}
                                    //Metodi pagamento
                                    metodi_pagamento={this.state.metodi_pagamento}
                                    addMetodoPagamento={this.handleAddMetodoPagamento}
                                    deleteMetodoPagamento={this.handleDeleteMetodoPagamento}
                                    changeMetodoPagamento={this.handleMetodoPagamentoChange}
                                    errorsMetodiPagamento={this.metodiPagamentoErrors}
                                    submitReceipt={this.handleSubmit}
                                />
                            }

                            {
                                this.state.menuOpen &&
                                <ModuloCassaSideMenu
                                    active={this.state.menuOpen}
                                    onMenuToggle={this.toggleMenu.bind(this)}
                                    userNegozio={this.props.userNegozio}
                                />
                            }
                        </div>
                    }
                />

                {
                    this.state.feedbackPanelOpen &&
                    <Fragment>
                        <div style={BackdropStyle} className={"__androidDismissable__"}
                             onClick={this.feedbackPanelToggle}/>
                        <SwipeablePanel
                            swipeTreshold={5}
                            direction={'top-to-bottom'}
                            active={!!this.state.feedbackPanelOpen}
                            onDismiss={this.feedbackPanelToggle}
                            animationSpeed={150}>
                            <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                            <div className={"bg-white p-3 text-center border"}>
                                <div className={"w-100 text-success my-3"}>
                                    <FaSolidIcon name={"circle-check"} size={4}/>
                                </div>
                                Scontrino inviato con successo
                                <div className={"d-flex mt-5 align-items-center justify-content-around"}>
                                    {
                                        isModuleWhatsAppActive &&
                                        <button className={"btn btn-success p-2 w-50 mr-1"}
                                                onClick={this.handleWhatsappShareReceipt}>Invia whatsapp <FaBrandIcon name={"whatsapp"}/>
                                        </button>
                                    }
                                    <button className={"btn btn-primary p-2 "+ (isModuleWhatsAppActive ? " w-50 ml-1 " : " w-100 ")}
                                            onClick={this.handleShareReceipt}>Condividi <FaSolidIcon name={"share"}/>
                                    </button>
                                </div>
                            </div>
                        </SwipeablePanel>
                    </Fragment>
                }
            </ModalPortal>
        )
    }
}

CreateReceiptsPage.propTypes = {
    onDismiss: PropTypes.func,
    userNegozio: PropTypes.object
}
