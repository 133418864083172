import React from "react";
import FuxEvents from "../../../lib/FuxFramework/FuxEvents";
import {DLA_PRENOTAZIONE_GRUPPO} from "../../../const/DeepLinkActionsCostants";
import ModalPortalHOC from "../../../components/BaseComponents/ModalPortalHOC";
import {GroupBookResumePage} from "../../NextBooks/GroupBooks/GroupBookResume/GroupBookResumePage";

export class PrenotazioneGruppoNotificationClickListener extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idPrenotazione: null
        }
    }

    componentDidMount() {
        FuxEvents.on(DLA_PRENOTAZIONE_GRUPPO, this.handleOpenResume)
    }

    componentWillUnmount() {
        FuxEvents.off(DLA_PRENOTAZIONE_GRUPPO, this.handleOpenResume);
    }

    handleOpenResume = idPrenotazione => this.setState({idPrenotazione: idPrenotazione});

    handleDismissResume = _ => this.setState({idPrenotazione: null});


    render() {
        if (!this.state.idPrenotazione) return '';
        return (
            <ModalPortalHOC>
                <GroupBookResumePage
                    idPrenotazione={this.state.idPrenotazione}
                    onDismiss={this.handleDismissResume}
                    showOpenApp={true}
                />
            </ModalPortalHOC>
        )
    }

}

PrenotazioneGruppoNotificationClickListener.propTypes = {}