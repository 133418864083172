import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {SwipeablePanelGripBar} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {formatCurrency} from "../../../../helpers/CurrencyHelpers";
import {swalConfirm} from "../../../../helpers/SweetAlertWrapper";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 3000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class DisputeRefundProposalPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            amount: this.props.amount
        }
    }

    handleInputChange = ({target}) => this.setState({[target.name]: parseFloat(target.value)});

    handleConfirm = _ => {
        if (this.state.amount <= this.props.amount && this.state.amount > 0) {
            swalConfirm(`Sei sicuro di voler proporre al cliente un rimborso di ${formatCurrency(this.state.amount, 'EUR', 'it-IT')}?`)
                .then(_ => {
                    this.props.onConfirm(this.state.amount);
                })
        }
    }

    render() {

        const isAmountValid = this.state.amount <= this.props.amount && this.state.amount > 0;

        return (
            <Fragment>
                <div style={BackdropStyle} className={"__androidDismissable__"} onClick={this.props.onClose}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.props.onClose}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main rounded-top"}/>
                    <div className={"container-fluid bg-main py-4"}>
                        <h4 className={"font-weight-bold"}>
                            Proponi rimborso parziale
                        </h4>
                        <p className={"small text-muted"}>
                            Proponi la cifra che sei disposto a rimborsare al cliente. Lui potrà accettare o rifiutare
                            la tua offerta. In caso di accettazione la prenotazione verrà annullata e il rimborso
                            emesso automaticamente.
                        </p>
                        <div className={"form-group"}>
                            <div className={"input-group"}>
                                <div className={"input-group-prepend"}>
                                <span className={"input-group-text"}>
                                    <FaSolidIcon name={"euro-sign"}/>
                                </span>
                                </div>
                                <input
                                    type={"number"}
                                    className={`form-control ${isAmountValid ? '' : 'is-invalid'}`}
                                    value={this.state.amount}
                                    name={"amount"}
                                    placeholder={"Importo rimborso"}
                                    onChange={this.handleInputChange}
                                />
                                {
                                    !isAmountValid &&
                                    <div className="invalid-feedback">
                                        L'importo deve essere maggiore di 0€ e non può essere superiore al totale della prenotazione,
                                        ovvero {formatCurrency(this.props.amount, "EUR", "it-IT")}
                                    </div>
                                }
                            </div>
                        </div>
                        <button className={"btn btn-primary btn-block"} disabled={!isAmountValid}
                                onClick={this.handleConfirm}>
                            <FaSolidIcon name={"check"}/> Conferma e proponi
                        </button>
                    </div>
                </SwipeablePanel>
            </Fragment>
        )
    }

}

DisputeRefundProposalPanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    amount: PropTypes.number,
    onConfirm: PropTypes.func,
}