import styled from "styled-components";

export const FeedContainer = styled.div`
    max-height:calc(100vh - 41px - 56px);
    overflow:auto;
    padding-top:0em;
    padding-bottom: 4rem;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const GetMoreLoadingWrapper = styled.div`
    text-align:center;
    padding-bottom:60px;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    z-index:1029;
`;
