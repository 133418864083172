import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {SwipeablePanel} from "../../../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import AuthAPI from "../../../../api/AuthAPI";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {ChatRoomView} from "../../../../components/ChatPanel/ChatRoom/ChatRoomView";
import {UserAdminChatAPI} from "../../../../api/Chat/UserAdminChatAPI";
import APIConfig from "../../../../api/APIConfig";
import {DLA_ADMIN_CHAT_MESSAGE} from "../../../../const/DeepLinkActionsCostants";
import {DashboardGruppiBookDetails} from "../ModuloGruppi/DashboardGruppiBookDetails";
import {
    TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI, TIPO_OPERAZIONE_PRENOTAZIONE_POSTI,
    TIPO_OPERAZIONE_PRENOTAZIONE_SERVIZI
} from "../../../../const/OperazioniConstants";
import {PageZLayerSelector} from "../../../../components/BaseComponents/Page/PageZLayerSelector";
import {DisputeRefundProposalPanel} from "./DisputeRefundProposalPanel";
import {DisputesAPI} from "../../../../api/Disputes/DisputesAPI";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {
    ADMIN_DISPUTE_CHAT_READ_EVT, CHAT_UPDATE_MESSAGES,
    DISPUTE_CHAT_ACCEPT_REFUND,
    DISPUTE_CHAT_REJECT_REFUND,
    UPDATE_USER_BOOKS
} from "../../../../const/AppEventsCostants";
import {formatCurrency} from "../../../../helpers/CurrencyHelpers";
import {BookDetailPage} from "../ModuloSeats/BookDetailPage/BookDetailPage";

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}

export class BusinessWithUserDisputePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
            proposalPanelOpen: false,
        }
    }

    componentDidMount() {
        FuxEvents.on(DISPUTE_CHAT_ACCEPT_REFUND, this.handleProposalAccept);
        FuxEvents.on(DISPUTE_CHAT_REJECT_REFUND, this.handleProposalReject);
    }

    componentWillUnmount() {
        FuxEvents.off(DISPUTE_CHAT_ACCEPT_REFUND, this.handleProposalAccept);
        FuxEvents.off(DISPUTE_CHAT_REJECT_REFUND, this.handleProposalReject);
    }


    /**
     * @MARK: Panel utility
     * */

    isSwipeDisabled = _ => false;

    handleClose = _ => {
        this.setState({roomData: null});
        this.props.onClose();
    }


    /**
     * @MARK: Operation details
     * */

    toggleDetail = _ => this.setState({detailOpen: !this.state.detailOpen});

    getDetailPage = _ => {
        if (this.props.disputeData.tipo_operazione === TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI) {
            return (
                <DashboardGruppiBookDetails
                    onDismiss={this.toggleDetail}
                    id_prenotazione={this.props.disputeData.id_operazione}
                />
            )
        } else if (this.props.disputeData.tipo_operazione === TIPO_OPERAZIONE_PRENOTAZIONE_POSTI) {
            return <BookDetailPage onDismiss={this.toggleDetail} bookId={this.props.disputeData.id_operazione}/>
        }
        return '';
    }


    /**
     * @MARK: Refund proposals
     * */

    toggleProposalPanel = _ => this.setState({proposalPanelOpen: !this.state.proposalPanelOpen});

    handleProposalConfirm = amount => {
        swalLoading('Attendere prego...');
        DisputesAPI.Admin.sendRefundProposal(
            this.props.disputeData.id_operazione,
            this.props.disputeData.tipo_operazione,
            amount
        ).then(({id_messaggio, otp}) => {
            this.toggleProposalPanel();
            UserAdminChatAPI.sendMessageNotification(this.props.disputeData.id_chat_room, id_messaggio, otp); //Notifica del messaggio
            FuxEvents.emit(DLA_ADMIN_CHAT_MESSAGE, this.props.disputeData.id_chat_room); //Aggiornamento messaggio in chat
            swalSuccess('Proposta di rimborso invata correttamente!')
        }).catch(swalError)
    }

    handleProposalAccept = ({id_messaggio, metadata}) => {
        swalConfirm(`Sei sicuro di voler accettare questa proposta di rimborso di ${formatCurrency(metadata.amount, 'EUR', 'it-IT')}?`)
            .then(_ => {
                swalLoading('Attendere prego...');
                DisputesAPI.Admin.acceptRefund(id_messaggio)
                    .then(m => {
                        FuxEvents.emit(UPDATE_USER_BOOKS);
                        swalSuccess(m).then(_ => {
                            FuxEvents.emit(ADMIN_DISPUTE_CHAT_READ_EVT, false); //Aggiornamento elenco chat
                            this.props.onClose();
                        });
                    }).catch(swalError);
            });
    }

    handleProposalReject = ({id_messaggio, metadata}) => {
        swalConfirm(`Sei sicuro di voler rifiutare questa proposta di rimborso di ${formatCurrency(metadata.amount, 'EUR', 'it-IT')}?`)
            .then(_ => {
                swalLoading('Attendere prego...');
                DisputesAPI.Admin.rejectRefund(id_messaggio)
                    .then(m => {
                        FuxEvents.emit(ADMIN_DISPUTE_CHAT_READ_EVT, false); //Aggiornamento elenco chat
                        FuxEvents.emit(CHAT_UPDATE_MESSAGES, this.props.disputeData.id_chat_room); //Aggiornamento messaggi in chat
                        swalSuccess(m);
                    }).catch(swalError);
            });
    }

    render() {
        const dispute = this.props.disputeData;
        const imageStyle = {
            backgroundImage: `url("${APIConfig.url(`/utenti/profile-pic/${AuthAPI.currentUser.accessToken}/${dispute.id_utente}/400`)}")`,
            width: 32,
            height: 32,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }
        return (
            <Fragment>
                <div style={BackdropStyle}/>
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={true}
                    onDismiss={this.handleClose}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <div className={"container-fluid rounded-top overflow-hidden bg-white py-2"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <div className={"d-flex align-items-center w-75"}>
                                <div className={"mr-2 rounded-circle border"} style={imageStyle}/>
                                <div className={"text-truncate w-75"}>
                                    <SafeHtmlContainer html={`${dispute.nome} ${dispute.cognome}`}/>
                                </div>
                            </div>
                            <div className={"d-flex align-items-center text-right"}>
                                <button className={"btn btn-link text-primary"} onClick={this.handleClose}>
                                    <FaSolidIcon name={"times"}/>
                                </button>
                            </div>
                        </div>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <button className={"btn btn-link btn-sm"} onClick={this.toggleDetail}>
                                Vedi dettagli
                            </button>
                            <button className={"btn btn-link btn-sm text-magenta"} onClick={this.toggleProposalPanel}>
                                Proponi cifra rimborso
                            </button>
                        </div>
                    </div>
                    <div className={"__disable-swipe"}>
                        <ChatRoomView
                            idRoom={this.props.disputeData.id_room}
                            idSelf={dispute.id_utente2}
                            getMessageAPI={UserAdminChatAPI.getMessages}
                            getMediaContentUrlAPI={UserAdminChatAPI.getMediaContentUrl}
                            sendMessageAPI={UserAdminChatAPI.sendTextMessage}
                            setReadAPI={UserAdminChatAPI.setRead}
                            sendNotificationAPI={UserAdminChatAPI.sendMessageNotification}
                            fetchMessageEventName={DLA_ADMIN_CHAT_MESSAGE}
                        />
                    </div>
                </SwipeablePanel>
                <ModalPortalHOC>
                    <PageZLayerSelector zIndex={3000}>
                        {
                            this.state.detailOpen && this.getDetailPage()
                        }
                    </PageZLayerSelector>
                    {
                        this.state.proposalPanelOpen &&
                        <DisputeRefundProposalPanel
                            onClose={this.toggleProposalPanel}
                            amount={dispute.amount}
                            onConfirm={this.handleProposalConfirm}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

BusinessWithUserDisputePanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    disputeData: PropTypes.object,
}