import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {AppIconThumbnail} from "../BaseComponents/AppIconThumbnail";
import AppConfig from "../../config/AppConfig";
import ShopOpenOrClose from "../ShopOpenOrClose";
import {FaSolidIcon} from "../FontAwesomeIcons";
import OrariHumanWeekView from "../OrariHumanWeekView";
import ShopFeaturesIcon from "../ShopFeaturesIcon";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import ProfiloGridGallery from "./ProfiloGridGallery";
import styled from "styled-components";
import menuDigitaleIcon from "../../img/menu-digitale.svg";
import {FuxRating} from "../FuxRating";
import {UserAuthContext} from "../../launcher/BookizonLauncher";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {ADD_APP_REQUEST, OPEN_APP_REQUEST} from "../../const/AppEventsCostants";
import i18n from "../../i18n";

const RatingWrapper = styled.div`
    background-color: rgba(40, 167, 69, 0.1);
`;

const MapWrapper = styled.div`
    position:relative;
`;

const MapAnchor = styled.a`
    display:block;
    width:100%;
    height:150px;
    position:absolute;
    top:0px;
`;

const PulsanteCircle = styled.div`
    width: 48px;
    height: 48px;
    border:1px solid;
    border-color:${props => props.color};
    color:${props => props.color};
    border-radius:50%;
`;

const TabItem = styled.div`
    font-size:0.9em;
    color:${props => props.active ? `${props.activeColor}!important` : 'inherit'};
    border-bottom: 3px solid ${props => props.active ? props.activeColor : 'transparent'} !important;
`;

const AnimatedArrow = styled.span`
    transition: all .2s linear;
    transform:rotate(${props => props.rotate || 0}deg);
`;

const OrariAnimatedWrapper = styled.div`
    overflow:hidden;
    transition: all .2s linear;
    max-height: ${props => props.expanded ? "500px" : "0px"};
`;

const AppDetailsContainer = styled.div`
    max-width: calc(100% - 100px);
`;

export default class ProfiloAttivitaContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'informazioni',
            orariExpanded: false,
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState.activeTab !== this.state.activeTab ||
            nextState.orariExpanded !== this.state.orariExpanded ||
            nextProps.negozio !== this.props.negozio ||
            nextProps.showMenu !== this.props.showMenu;
    }

    getMapLink(sede) {
        var mapLink = `https://maps.google.com/maps?q=${!!sede.lat ? `${sede.lat},${sede.lng},` : ''}`;
        if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
            mapLink = 'http://maps.apple.com/?q=';
        }
        var queryString = (`${sede.indirizzo},${sede.citta},${sede.nome}`);
        return `${mapLink}${queryString}`;
    }

    getMapEmbedLink = sede => {
        var queryString = encodeURIComponent(`${!!this.props.negozio.lat ? `${this.props.negozio.lat},${this.props.negozio.lng},` : ''}${sede.indirizzo},${sede.citta},${this.props.negozio.nome}`);
        var imageDimension = `${window.innerWidth}x150`;
        return `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAsRTtIb0cMy_bpR5RbavHoNKvIkGPlHpg&size=${imageDimension}&center=${queryString}&zoom=20`;
    }

    handleToggleTab = tabId => _ => {
        this.setState({activeTab: tabId});
        this.lastRealHeight = "90vh";
    }

    handleToggleOrari = _ => this.setState({orariExpanded: !this.state.orariExpanded});

    render() {
        let imageFullUrl = `${AppConfig.webServerUrl}/api/negozio/${this.props.negozio.id_negozio}/logoIcon/100`
        return (
            <UserAuthContext.Consumer>
                {
                    user => (
                        <Fragment>
                            {/* Logo e slogan */}
                            <div className={"mb-4 pt-3"}>
                                <div className={"d-flex align-items-center mb-2 px-3 text-on-bg-main"}>
                                    <AppIconThumbnail size={"70px"} src={imageFullUrl}/>
                                    <AppDetailsContainer className={"ml-2 flex-shrink-1"}>
                                        <h5 className="my-0 text-truncate" style={{marginBottom: "-6px"}}>
                                            {this.props.negozio.nome}
                                        </h5>
                                        {
                                            !!this.props.negozio.nome_categoria && <small className={"text-muted"}>
                                                {this.props.negozio.nome_categoria}<br/>
                                            </small>
                                        }

                                        <div className={"d-flex align-items-center"}
                                             onClick={this.handleToggleOrari}>
                                            {
                                                this.props.negozio.orari &&
                                                <ShopOpenOrClose
                                                    orariNegozio={this.props.negozio.orari}
                                                    orarioAttuale={this.props.negozio.orario_giornata_attuale !== undefined ? this.props.negozio.orario_giornata_attuale : ""}
                                                    giorniChiusura={this.props.negozio.giorni_chiusura !== undefined ? this.props.negozio.giorni_chiusura : ""}
                                                />
                                            }
                                            <AnimatedArrow className={"small text-muted ml-1"}
                                                           rotate={this.state.orariExpanded ? 90 : 0}>
                                                <FaSolidIcon name={"chevron-right"}/>
                                            </AnimatedArrow>
                                        </div>
                                    </AppDetailsContainer>
                                </div>
                                <OrariAnimatedWrapper className={"bg-main shadow-sm"}
                                                      expanded={this.state.orariExpanded}>
                                    <div className={"p-3"}>
                                        <OrariHumanWeekView orari={this.props.negozio.orari}/>
                                    </div>
                                </OrariAnimatedWrapper>
                            </div>

                            {/* Pulsanti circle */}
                            <div className={"container my-4"}>
                                <div className={"row justify-content-center"}>

                                    {
                                        this.props.negozio.sedi[0].telefono &&
                                        <div className={"col-3 text-center px-1"}>
                                            <a href={`tel:${this.props.negozio.sedi[0].telefono}`}
                                               className={"text-on-bg-main"}>
                                                <PulsanteCircle
                                                    className={"d-flex align-items-center justify-content-center mx-auto"}
                                                    color={this.props.primaryColor}>
                                                    <FaSolidIcon name={"phone"}/>
                                                </PulsanteCircle>
                                                <small className={"text-uppercase text-primary"}>
                                                    {i18n.t('business-info.call-btn')}
                                                </small>
                                            </a>
                                        </div>
                                    }

                                    {
                                        this.props.negozio.sedi[0].email &&
                                        <div className={"col-3 text-center px-1"}>
                                            <a href={`mailto:${this.props.negozio.sedi[0].email}`}>
                                                <PulsanteCircle
                                                    className={"d-flex align-items-center justify-content-center mx-auto"}
                                                    color={this.props.primaryColor}>
                                                    <FaSolidIcon name={"envelope"}/>
                                                </PulsanteCircle>
                                                <small className={"text-uppercase text-primary"}>
                                                    {i18n.t('business-info.email-btn')}
                                                </small>
                                            </a>
                                        </div>
                                    }

                                    {
                                        this.props.negozio.sedi[0] &&
                                        <div className={"col-3 text-center px-1"}>
                                            <a href={this.getMapLink(this.props.negozio.sedi[0])}>
                                                <PulsanteCircle
                                                    className={"d-flex align-items-center justify-content-center mx-auto"}
                                                    color={this.props.primaryColor}>
                                                    <FaSolidIcon name={"map-marker-alt"}/>
                                                </PulsanteCircle>
                                                <small className={"text-uppercase text-primary"}>
                                                    {i18n.t('business-info.take-me-here-btn')}
                                                </small>
                                            </a>
                                        </div>
                                    }


                                    {
                                        this.props.showMenu &&
                                        <div className={"col-3 text-center px-1"}>
                                            <div
                                                onClick={_ => window.location.href = `${AppConfig.webServerUrl}/app?id_negozio=${this.props.negozio.id_negozio}&onlyMenuDigitale=true`}>
                                                <PulsanteCircle
                                                    className={"d-flex align-items-center justify-content-center mx-auto"}
                                                    color={this.props.primaryColor}>
                                                    <img src={menuDigitaleIcon} height={16}/>
                                                </PulsanteCircle>
                                                <small className={"text-uppercase text-primary"}>
                                                    {i18n.t('business-info.digital-menu-btn')}
                                                </small>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>


                            {/* Pulsanti aggiungi/apri */}
                            {
                                !this.props.hideButtonsGroup &&
                                <div className={"container my-3"}>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            {
                                                this.props.isAppAdded ?
                                                    <button className={"btn btn-block btn-outline-dark"}
                                                            disabled={true}>
                                                        <FaSolidIcon name={"check"}/> {i18n.t('business-info.added-btn')}
                                                    </button>
                                                    :
                                                    <button className={"btn btn-block btn-outline-primary"}
                                                            onClick={_ => FuxEvents.emit(ADD_APP_REQUEST, this.props.negozio.codice)}
                                                            disabled={!user}
                                                    >
                                                        <FaSolidIcon name={"plus"}/> {i18n.t('business-info.add-btn')}
                                                    </button>
                                            }
                                        </div>
                                        <div className={"col"}>
                                            <button className={"btn btn-block btn-primary"}
                                                    onClick={_ => FuxEvents.emit(OPEN_APP_REQUEST, this.props.negozio.id_negozio)}
                                            >
                                                <FaSolidIcon name={"tablet"}/> {i18n.t('business-info.enter-app-btn')}
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            }


                            {/* Tabs */}
                            <div className={"container mb-3"}>
                                <div className={"row"}>
                                    <TabItem
                                        active={this.state.activeTab === 'informazioni'}
                                        onClick={this.handleToggleTab('informazioni')}
                                        className={"col py-2 text-center text-muted text-uppercase"}
                                        activeColor={this.props.primaryColor}
                                    >
                                        {i18n.t('business-info.info-tab')}
                                    </TabItem>

                                    <TabItem
                                        active={this.state.activeTab === 'foto'}
                                        onClick={this.handleToggleTab('foto')}
                                        className={"col py-2 text-center text-muted text-uppercase"}
                                        activeColor={this.props.primaryColor}
                                    >
                                        {i18n.t('business-info.photos-tab')}
                                    </TabItem>
                                </div>
                            </div>

                            <div className={this.state.activeTab === 'informazioni' ? '' : 'd-none'}>
                                {/* Rating */}
                                {
                                    (!!this.props.negozio.valutazione || !!this.props.negozio.fasciaPrezzo) &&
                                    <RatingWrapper className={"container my-3 py-3"}>
                                        <div className={"d-flex align-items-center justify-content-center"}>
                                            {
                                                !!this.props.negozio.valutazione &&
                                                <Fragment>
                                                    <FuxRating rating={this.props.negozio.valutazione}/>
                                                    <span className={"text-success mr-2"}>
                                                        ({this.props.negozio.nvalutazioni})
                                                    </span>
                                                </Fragment>
                                            }
                                            {
                                                !!this.props.negozio.fasciaPrezzo &&
                                                <Fragment>
                                                    <div>
                                                        {i18n.t('business-info.spending-range-label')}:
                                                        <span className={"text-success"}>
                                                            {this.props.negozio.fasciaPrezzo}
                                                        </span>
                                                    </div>
                                                </Fragment>
                                            }
                                        </div>
                                    </RatingWrapper>
                                }

                                <div className={"container"}>
                                    {/* Features */}
                                    {
                                        !!this.props.negozio.features.length &&
                                        <div className={"mb-3"}>
                                            <h5 className={"text-muted"}>{i18n.t('business-info.features-headline')}</h5>
                                            {
                                                this.props.negozio.features.map(
                                                    (f, i) => <ShopFeaturesIcon
                                                        columnAlign={true}
                                                        size={"md"}
                                                        key={i}
                                                        feature={f}
                                                        withText={true}
                                                    />
                                                )
                                            }
                                        </div>
                                    }

                                    {
                                        !!this.props.negozio.descrizione &&
                                        <div className={"mb-3"}>
                                            <h5 className={"text-muted"}>{i18n.t('business-info.description-label')}</h5>
                                            <span className={"text-muted"}><SafeHtmlContainer
                                                html={this.props.negozio.descrizione}/></span>
                                        </div>
                                    }

                                    {
                                        !!this.props.negozio.sedi[0] &&
                                        <div className={"mb-3"}>
                                            <h5 className={"text-muted"}>{i18n.t('business-info.where-we-are-label')}</h5>
                                            <MapWrapper className={"row"}>
                                                <img
                                                    src={this.getMapEmbedLink(this.props.negozio.sedi[0])}
                                                    className={"img-fluid"}
                                                    height={150}
                                                    alt={"Mappa"}
                                                />
                                                <MapAnchor href={this.getMapLink(this.props.negozio.sedi[0])}/>
                                            </MapWrapper>
                                            <div className={"text-center text-on-bg-main mb-0"}>
                                        <span>
                                        <FaSolidIcon className={"mr-2 text-primary"} name={"map"}/>
                                            {this.props.negozio.sedi[0].indirizzo}, {this.props.negozio.sedi[0].citta}
                                        </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={this.state.activeTab === 'foto' ? '' : 'd-none'}>
                                <div className={"container"}>
                                    <ProfiloGridGallery
                                        igProfile={this.props.negozio.social.instagram}
                                        id_negozio={this.props.negozio.id_negozio}
                                        borderColor={this.props.backgroundColor}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    )
                }

            </UserAuthContext.Consumer>
        )
    }

}


ProfiloAttivitaContainer.propTypes = {
    showMenu: PropTypes.bool,
    isAppAdded: PropTypes.bool,
    onAppAdd: PropTypes.func,
    hideButtonsGroup: PropTypes.bool,
    negozio: PropTypes.object,
    primaryColor: PropTypes.string,
    backgroundColor: PropTypes.string,
};

ProfiloAttivitaContainer.defaultProps = {
    isAppAdded: false,
    onAppAdd: _ => {
    },
    negozio: {},
};
