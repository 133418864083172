import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../APIConfig";
import AuthAPI from "../AuthAPI";

export const _Orders = {
    requestRefund: function (id_ordine, reason){
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/modulo-food/orders/request-refund`), {
                token: AuthAPI.getUserAccessToken(),
                id_ordine: id_ordine,
                reason: reason
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    }
}