import React, {Fragment} from "react";
import AppConfig from "../../../../config/AppConfig";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import styled from "styled-components";
import Page from "../../../../components/BaseComponents/Page/Page";
import FuxHTTP from "../../../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../../../../api/APIConfig";
import {swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import AuthAPI from "../../../../api/AuthAPI";
import {AppIconThumbnail} from "../../../../components/BaseComponents/AppIconThumbnail";
import Select from "react-select";
import loadImage from "blueimp-load-image";
import {InputMaxCharCounterHOC} from "../../../../hoc/InputMaxCharCounterHOC";
import DatePicker from "react-datepicker";
import moment from "moment";


const publishType = {post: 'Post', offerta: 'Offerta', evento: 'Evento'};

const FullscreenTextarea = styled.textarea`
    width:100%;
    height:40vh!important;
    margin:0px;
    padding:10px 5px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
`;

const FullscreenInput = styled.input`
    width:100%;
    margin:0px;
    padding:10px 5px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
`;

const FileSelectInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    
    & + label{
        cursor:pointer;
    }
    
    & + label * {
        pointer-events: none;
    }
`

const ImagePreviewWrapper = styled.div`
    position: relative;
    & .img-fluid-exif{
        width: 100%;
        height:auto;
    }
`;

const OverImageBtn = styled.div`
    display:inline-block;
    position: absolute;
    border-radius:500px;
    color:white;
    background-color:rgba(0,0,0,0.7);
    font-size:.85em;
    &.top{ top:10px; }
    &.left{ left:10px; }
    &.right{ right:10px; }  
    
`;

const TitleInputWithCharCounter = InputMaxCharCounterHOC(FullscreenInput);

export default class DashboardPublishModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            titolo: '', testo: '', link: '', immagine: null, tipo: 'evento',
            usaLink: false, imagePreview: null, facebook_page: [],
            data_inizio: new Date(), data_fine: new Date()
        }
    }

    handleInputChange = async (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ?
            target.checked :
            target.type === 'file' ?
                target.files
                :
                target.value;

        if (target.type === 'file') {
            if (!value) { //Immagine non selezionata dal filechooser
                return;
            }
            let blob = await fetch(URL.createObjectURL(value[0])).then(r => r.blob());
            this.setState({[target.name]: blob});
            loadImage(
                value[0],
                (img) => {
                    this.setState({imagePreview: img}, function () {
                        document.getElementById("publish-modal-preview").innerHTML = "";
                        document.getElementById("publish-modal-preview").appendChild(img);
                    });
                },
                {
                    orientation: true,
                    maxWidth: window.innerWidth,
                    imageSmoothingQuality: 'high'
                } // Options
            )
            target.value = null; //Elimino il file selezionato nella memoria dell'input file
            return;
        }

        const name = target.name;
        if (name === 'user') {
            value = value.toLowerCase();
        }
        this.setState({
            [name]: value,
        });
    }

    handleOptionChange = (optionData, actionData) => {
        this.setState({[actionData.name]: optionData.value});
    }

    handleDataInizioChoose = date => this.setState({data_inizio: date});
    handleDataFineChoose = date => this.setState({data_fine: date});

    handleSocialAccountChange = event => {
        let accounts = [...this.state[event.target.name]];
        if (event.target.checked) {
            accounts.push(event.target.value);
        } else {
            accounts = accounts.filter(id => id !== event.target.value);
        }
        this.setState({
            [event.target.name]: accounts
        });
    }

    toggleUseLink = _ => {
        this.setState({useLink: !this.state.useLink});
    }

    handleRemoveImage = _ => {
        this.setState({imagePreview: null, immagine: null});
    }

    handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('token', AuthAPI.currentUser.accessToken);
        formData.append('titolo', this.state.titolo);
        formData.append('testo', this.state.testo);
        formData.append('link', this.state.link);
        formData.append('tipo', this.state.tipo);
        formData.append('data_inizio', moment(this.state.data_inizio).format('YYYY-MM-DD'));
        formData.append('data_fine', moment(this.state.data_fine).format('YYYY-MM-DD'));
        if (this.state.immagine) {
            formData.append('immagine', this.state.immagine);
        }

        this.state.facebook_page.map(pageId => formData.append('facebook_page[]', pageId));


        swalLoading('Attendere prego...', false);
        FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url('/dashboard-publish/post'), formData,
            FuxHTTP.RESOLVE_MESSAGE, FuxHTTP.REJECT_MESSAGE
        )
            .then(swalSuccess)
            .then(this.props.onDismiss)
            .then(_ => this.setState({titolo: '', testo: '', link: '', immagine: null, facebook_page: []}))
            .catch(swalError)
    }

    getNegozioImageUrl = _ => `${AppConfig.webServerUrl}/api/negozio/${this.props.idNegozio}/logoIcon/100`

    hasSocialAccounts = _ => {
        let socialAccounts = Object.keys(this.props.socialAccounts);
        if (!socialAccounts.length) {
            return false;
        }
        return !!socialAccounts.filter(socialName => !!this.props.socialAccounts[socialName].length).length;
    }

    render() {
        const today = new Date();
        const dataFineMinDate = moment(this.state.data_inizio).add(1, 'day').toDate();
        const dataInizioYmd = moment(this.state.data_inizio).format('YYYY-MM-DD');
        const dataFineYmd = moment(this.state.data_fine).format('YYYY-MM-DD');
        return (
            <Page
                active={this.props.active}
                title={"Nuovo post"}
                leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                
                onLeftIconClick={this.props.onDismiss}
                rightIcon={<FaSolidIcon name={"check"}/>}
                rightIconText={"Pubblica"}
                onRightIconClick={this.handleSubmit}
            >
                <div className={"container pt-2 px-0"}>
                    <form onSubmit={this.handleSubmit}>
                        <div className={"form-group"}>
                            <div className={"d-flex align-items-center px-2 justify-content-center"}>
                                <AppIconThumbnail size={"30px"} src={this.getNegozioImageUrl()}/>
                                <span className={"ml-2 mr-2 text-muted"}>Pubblica come</span>
                                <Select
                                    className="basic-single w-50"
                                    classNamePrefix="select"
                                    defaultValue={{value: 'post', label: 'Post'}}
                                    isClearable={false}
                                    isSearchable={false}
                                    name={'tipo'}
                                    placeholder={"Scegli"}
                                    options={Object.keys(publishType).map(type => {
                                        return {
                                            value: type,
                                            label: publishType[type],
                                            isDisabled: false
                                        }
                                    })}
                                    onChange={this.handleOptionChange}
                                />
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <TitleInputWithCharCounter
                                type={"text"}
                                name={"titolo"}
                                placeholder={"Inserisci un titolo"}
                                className={"form-control border-right-0 border-left-0 border-top border-bottom rounded-0"}
                                value={this.state.titolo}
                                onChange={this.handleInputChange}
                                maxLength={65}
                                labelFormat={'Caratteri usati {ratio}'}
                                labelClassList={{
                                    0: 'text-success ml-2',
                                    25: 'text-success ml-2',
                                    50: 'text-warning ml-2',
                                    75: 'text-danger ml-2'
                                }}
                            />
                        </div>
                        {
                            this.state.tipo === 'evento' &&
                            <div className={"form-group"}>
                                <div className={"px-3 border-top border-bottom bg-white"}>
                                    <div className={"row pl-3 py-2 align-items-center"}>
                                        <div className={"col-1 px-0"}>
                                            <FaSolidIcon name={"calendar-alt"} className={"text-info"}/>
                                        </div>
                                        <div className={"col pl-0"}>
                                            <div className={"d-flex align-items-center justify-content-between"}>
                                                <div className={'pl-0 pr-1 d-flex'}>
                                                    Dal&nbsp;
                                                    <CalendarWrapper>
                                                        <DatePicker
                                                            className={"w-100"}
                                                            dateFormat={"dd-MM-yyyy"}
                                                            selected={this.state.data_inizio}
                                                            onChange={this.handleDataInizioChoose}
                                                            customInput={<DatePickerInput
                                                                defaultText={"Inizio evento"}/>}
                                                            selectsStart={true}
                                                            minDate={today}
                                                            startDate={this.state.data_inizio}
                                                            endData={this.state.data_fine}
                                                            popperPlacement={"bottom-start"}
                                                            locale={"it"}
                                                            dayClassName={date => {
                                                                const dateYmd = moment(date).format('YYYY-MM-DD');
                                                                if (dateYmd === dataInizioYmd || dateYmd === dataFineYmd) {
                                                                    return 'react-datepicker__day--selected';
                                                                }
                                                                if (dateYmd > dataInizioYmd && dateYmd < dataFineYmd) {
                                                                    return 'react-datepicker__day--in-selecting-range';
                                                                }
                                                                return '';
                                                            }}
                                                        />
                                                    </CalendarWrapper>
                                                </div>
                                                <div className={'pl-1 pr-0 d-flex'}>
                                                    Al&nbsp;
                                                    <CalendarWrapper>
                                                        <DatePicker
                                                            className={"w-100"}
                                                            dateFormat={"dd-MM-yyyy"}
                                                            selected={this.state.data_fine}
                                                            selectsEnd={true}
                                                            minDate={dataFineMinDate}
                                                            startDate={this.state.data_inizio}
                                                            endData={this.state.data_fine}
                                                            onChange={this.handleDataFineChoose}
                                                            customInput={<DatePickerInput defaultText={"Fine evento"}/>}
                                                            dayClassName={date => {
                                                                const dateYmd = moment(date).format('YYYY-MM-DD');
                                                                if (dateYmd === dataInizioYmd || dateYmd === dataFineYmd) {
                                                                    return 'react-datepicker__day--selected';
                                                                }
                                                                if (dateYmd > dataInizioYmd && dateYmd < dataFineYmd) {
                                                                    return 'react-datepicker__day--in-selecting-range';
                                                                }
                                                                return '';
                                                            }}
                                                            popperPlacement={"bottom-end"}
                                                            locale={"it"}
                                                        />
                                                    </CalendarWrapper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"small px-3 text-muted mt-2"}>
                                    Inserisci la stessa data se l'evento dura solo 1 giorno
                                </div>
                            </div>
                        }
                        <div className={"form-group"}>
                            <FullscreenTextarea
                                name={"testo"}
                                className={"form-control border-right-0 border-left-0 border-top border-bottom rounded-0"}
                                value={this.state.testo}
                                onChange={this.handleInputChange}
                                placeholder={"Scrivi qui il tuo post..."}
                            />
                        </div>
                        <ImagePreviewWrapper className={this.state.imagePreview ? "form-group" : "d-none"}>
                            <OverImageBtn className={"top left py-1 px-2 text-center"}>
                                <FaSolidIcon name={"eye"}/> Anteprima
                            </OverImageBtn>
                            <OverImageBtn className={"top right py-1 px-2 text-center"}
                                          onClick={this.handleRemoveImage}>
                                <FaSolidIcon name={"times"}/> Rimuovi
                            </OverImageBtn>

                            <div id={"publish-modal-preview"}/>
                        </ImagePreviewWrapper>

                        <div className={"form-group bg-white border-top border-bottom"}>
                            <div className={"list-group list-group-flush"}>
                                <div className={"list-group-item"} onClick={this.toggleUseLink}>
                                    <label className={"m-0"}>
                                        {
                                            this.state.useLink ?
                                                <Fragment>
                                                    <FaSolidIcon name={"times"} className={"text-danger"}/>&nbsp;
                                                    Rimuovi link
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <FaSolidIcon name={"link"} className={"text-info"}/>&nbsp;
                                                    Link esterno
                                                </Fragment>
                                        }

                                    </label>
                                </div>
                                <div className={"list-group-item py-0 " + (!this.state.useLink && 'd-none')}>
                                    <div className={"row pl-3 align-items-center"}>
                                        <div className={"col-1 px-0"}>
                                            <FaSolidIcon name={"link"} className={"text-info"}/>
                                        </div>
                                        <div className={"col pl-0"}>
                                            <FullscreenInput
                                                placeholder={"https://miosito.it"}
                                                type={"text"}
                                                name={"link"}
                                                className={"form-control border-0 rounded-0"}
                                                value={this.state.link}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"list-group-item"}>
                                    <FileSelectInput
                                        id={"fileSelector"}
                                        type={"file"}
                                        name={"immagine"}
                                        accept="image/*"
                                        className={"form-control"}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor={"fileSelector"} className={"m-0"}>
                                        <FaSolidIcon name={"image"} className={"text-success"}/> Scegli immagine
                                    </label>
                                </div>
                                <div className={"list-group-item"}>
                                    <FileSelectInput
                                        id={"cameraPicker"}
                                        type={"file"}
                                        name={"immagine"}
                                        accept={"image/*"}
                                        capture={"camera"}
                                        className={"form-control"}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor={"cameraPicker"} className={"m-0"}>
                                        <FaSolidIcon name={"camera"} className={"text-success"}/> Fotocamera
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className={"form-group"}>
                            <div className={"container"}>
                                {
                                    this.hasSocialAccounts() &&
                                    <div className={"font-weight-bold mb-1"}>Pubblica anche su:</div>
                                }
                                {
                                    !!this.props.socialAccounts &&
                                    Object.keys(this.props.socialAccounts).map(socialName => {
                                        return this.props.socialAccounts[socialName].map(accountData => {
                                            return (
                                                <SocialAccountWrapper className={"mr-2"}
                                                                      key={accountData[`${socialName}_page_id`]}>
                                                    <input
                                                        type="checkbox"
                                                        name={`${socialName}_page`}
                                                        value={accountData[`${socialName}_page_id`]}
                                                        onChange={this.handleSocialAccountChange}
                                                        checked={!!this.state[`${socialName}_page`].find(id => id === accountData[`${socialName}_page_id`])}
                                                    />
                                                    <SocialAccount
                                                        className={"facebook d-flex align-items-center text-truncate"}>
                                                        <SocialAccountImage
                                                            src={accountData[`${socialName}_page_image_url`]}
                                                            className={"mr-2"}/>
                                                        <SocialAccountName>
                                                            {accountData[`${socialName}_page_name`]}
                                                        </SocialAccountName>
                                                    </SocialAccount>
                                                </SocialAccountWrapper>
                                            )
                                        });
                                    })
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </Page>
        )
    }

}


const SocialAccountWrapper = styled.label`
    display: inline-block;
    cursor: pointer;
    & input {
        position: absolute;
        left: -99999px;
    }
`;

const SocialAccount = styled.div`
    position: relative;
    display: inline-block;
    border-radius: 500px;
    border: 2px solid #ddd;
    padding: .25rem;
    padding-right: .50rem;
    filter: grayscale(100%);
    
    input:checked + & {
        border: 2px solid #293069;
        filter: none;
    }
    
    &.facebook:before{ 
        content: '\\f39e';
        position: absolute;
        top: .15rem;
        left: 21px;
        background-color: rgb(24, 119, 242);
        color: white;
        font-family: "Font Awesome 5 Brands";
        border-radius: 50%;
        height: 15px;
        width: 15px;
        text-align: center;
        line-height: 15px;
        font-size: 0.5em;
    }
`;

const SocialAccountName = styled.small`
    display: block;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
`


const SocialAccountImage = styled.div`
    background-image:url('${props => props.src}');
    background-position:center center;
    background-size:cover;
    border-radius:50%;
    width:25px;
    height:25px;
`;


const DatePickerInput = ({value, onClick, defaultText}) => <div
    className={"font-weight-bold text-center " + (!value ? 'text-muted' : '')} onClick={onClick}>
    {value ? value : defaultText}
</div>

const CalendarWrapper = styled.div`
    & .react-datepicker-wrapper{
        width:100%!important;
    }
`;