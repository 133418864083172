import React, {Fragment} from "react";
import festivities from "../../../launcher/svg/register-form/festivities.svg"
import {swalError} from "../../../helpers/SweetAlertWrapper";
import i18n from "i18next";
import i18next from "i18next";
import {FestivitiesIllustration} from "../../../img/illustrations/login/FestivitiesIllustration";
import {NegozioOpenStore} from "../../../stores/NegozioOpenStore";

export default class PersonalUserInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            cognome: '',
            giorno_nascita: '',
            mese_nascita: '',
            anno_nascita: '',
            sesso: '',
            errors: {}
        }
        this.campiObbligatori = ["nome", "cognome"];
        this.giorniMese = Array.from(Array(31).keys());
        this.mesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
        this.currentYear = parseInt((new Date()).getFullYear());
        this.formRef = null;
    }


    validateFormAndProceed = values => {
        const errors = {};

        console.log(values)
        for (var i in this.campiObbligatori) {
            if (!values[this.campiObbligatori[i]]) {
                errors[this.campiObbligatori[i]] = i18next.t('generic.errors.mandatory-field-label');
            }
        }

        if (!values.giorno_nascita || !values.mese_nascita || !values.anno_nascita) {
            //errors.giorno_nascita = "Devi impostare una data di nascita";
        }

        if (Object.keys(errors).length) {
            this.setState({
                errors: errors
            });
            swalError(i18next.t('register.errors.missing-fields'));
            return;
        }

        this.props.confirmValue({
            "nome": this.state.nome,
            "cognome": this.state.cognome,
            "giorno_nascita": this.state.giorno_nascita,
            "mese_nascita": this.state.mese_nascita,
            "anno_nascita": this.state.anno_nascita,
            "sesso": this.state.sesso
        })
    }

    handleInputChange = ({target}) => this.setState({[target.name]: target.value});

    render() {
        let negozioAspetto = NegozioOpenStore.infoNegozio ? NegozioOpenStore.infoNegozio.aspetto : null

        return (
            <Fragment>

                <div className={"text-center px-3 pb-2"}>
                    <FestivitiesIllustration className={"img-fluid my-5"} color={negozioAspetto ? negozioAspetto.secondary_color : "#6861ce"}/>
                    <h2 className={"text-primary font-weight-bold"}>{i18next.t("register.heading")}</h2>
                    <p className={"text-muted"}>{i18next.t("register.subheading")}</p>
                </div>


                <div className="form-group px-4 py-1">
                    <input autoComplete={"new-password"} type="text" name="nome" className="form-control border-0"
                           placeholder={i18next.t('register.first-name-placeholder')} value={this.state.nome}
                           onChange={this.handleInputChange}
                    />
                    <ErrorMessage errors={this.state.errors} name="nome"/>
                </div>
                <div className="form-group px-4 py-1">
                    <input autoComplete={"new-password"} type="text" name="cognome" className="form-control border-0"
                           placeholder={i18next.t('register.last-name-placeholder')} value={this.state.cognome}
                           onChange={this.handleInputChange}
                    />
                    <ErrorMessage errors={this.state.errors} name="cognome"/>
                </div>
                <div className="form-group px-4 py-1">
                    <div className={"row"}>
                        <div className={"col-3 pr-1"}>
                            <select autoComplete={"new-password"} className={"form-control border-0"}
                                    name={"giorno_nascita"}
                                    value={this.state.giorno_nascita} onChange={this.handleInputChange}>
                                <option value={""}>{i18next.t('generic.datepicker.day-select-placeholder')}</option>
                                {
                                    this.giorniMese.map(i => {
                                        return <option key={i}
                                                       value={("0" + (i + 1)).substr(-2, 2)}>{("0" + (i + 1)).substr(-2, 2)}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className={"col-6 px-1"}>
                            <select autoComplete={"new-password"} className={"form-control border-0"}
                                    name={"mese_nascita"}
                                    value={this.state.mese_nascita} onChange={this.handleInputChange}>
                                <option value={""}>{i18next.t('generic.datepicker.month-select-placeholder')}</option>
                                {
                                    this.mesi.map((m, i) => {
                                        return <option key={i}
                                                       value={("0" + (i + 1)).substr(-2, 2)}>{m}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className={"col-3 pl-1"}>
                            <select autoComplete={"new-password"} className={"form-control border-0"}
                                    name={"anno_nascita"}
                                    value={this.state.anno_nascita} onChange={this.handleInputChange}>
                                <option value={""}>{i18next.t('generic.datepicker.year-select-placeholder')}</option>
                                {
                                    Array.from(Array(100).keys()).map(i => {
                                        return <option key={i}
                                                       value={this.currentYear - i}>{this.currentYear - i}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <small className={"text-muted"}>{i18next.t('register.birthday-label')}</small>
                    <ErrorMessage errors={this.state.errors} name="giorno_nascita"/>
                </div>
                <div className="form-group px-4 py-1">
                    <select
                        className={"form-control border-0"} name={"sesso"}
                        value={this.state.sesso} onChange={this.handleInputChange}
                    >
                        <option value="-1">{i18next.t('register.sex.undefined')}</option>
                        <option value="1">{i18next.t('register.sex.male')}</option>
                        <option value="0">{i18next.t('register.sex.female')}</option>
                    </select>
                </div>

                <div className={"px-4 sticky bottom-0 py-3"}>
                    <button type={"button"} className={"btn btn-primary btn-block"}
                            onClick={_ => this.validateFormAndProceed(this.state)}>{i18next.t('generic.proceed-btn')}
                    </button>
                </div>
            </Fragment>
        )
    }
}

function ErrorMessage(props) {
    return (!!props.errors[props.name] && <span className={'text-danger'}>{props.errors[props.name]}</span>)
}