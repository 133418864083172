import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import '../FoodDelivery/ribbon.css';
import {FaRegularIcon, FaSolidIcon} from "../FontAwesomeIcons";
import {EcommerceProductFavouriteStatusHOC} from "./ProductView/EcommerceProductFavouriteStatusHOC";
import {AppIconThumbnail} from "../BaseComponents/AppIconThumbnail";
import {AppIconImageSize} from "../../const/ImageSizeCostants";
import AppConfig from "../../config/AppConfig";
import {applyPercentage} from "../../helpers/NumberUtility";
import {NeedAuthCallToActionHOC} from "../../hoc/NeedAuthCallToActionHOC";
import ModalPortalHOC from "../BaseComponents/ModalPortalHOC";
import EcommerceProductPage from "./EcommerceProductPage";

const ProductName = styled.div`
    font-size:0.70em;
`;

const FavouriteIconWrapper = styled.div`
    position: absolute;
    top:5px;
    right:5px;
    padding:.25rem;
    z-index: 1;
    font-size:1rem;
    border-radius:50%;
    height:35px; width:35px;
    background-color:rgba(0,0,0,0.5);
`;

const OwnerIconWrapper = styled.div`
    position: absolute;
    top:45px;
    right:5px;
    z-index: 1;
`;

export default class EcommerceProductCardGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productOpen: false
        }
        this.imageStyle = {
            width: "100%",
            paddingTop: "100%",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundImage: `url(${this.props.immagine})`
        }
    }

    getCurrentDiscountPercentage = _ => this.props.product.perc_sconto ? parseFloat(this.props.product.perc_sconto) : 0.0;

    getDiscountLabel = _ => {
        const discount = this.getCurrentDiscountPercentage();
        if (discount) {
            return <span className={'badge badge-success'}>Sconto {discount.toFixed(0)}%</span>
        }
        return '';
    }

    handleProductOpen = _ => {
        this.props.onProductOpen(this.props.product);
    }

    formatEuro = number => parseFloat(number).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'});

    getPrice = withDiscount => {
        let prezzoBase;
        if (parseInt(this.props.product.preventivo) === 1) {
            prezzoBase = parseFloat(this.props.product.prezzo_range_min);
        } else if (this.props.product.modelli.length) {
            prezzoBase = this.props.product.modelli.map(m => parseFloat(m.prezzo)).sort((a, b) => a - b).reverse().pop();
        } else {
            prezzoBase = parseFloat(this.props.product.prezzo);
        }

        const discount = this.getCurrentDiscountPercentage();
        if (discount && withDiscount) {
            prezzoBase = applyPercentage(prezzoBase, -discount);
        }
        return prezzoBase;
    }

    getDiscountNoticeLabel = _ => {
        if (this.getCurrentDiscountPercentage()) {
            return <span className={'small'}>
                <span className={"text-muted text-line-through"}>{this.formatEuro(this.getPrice(false))}</span>
            </span>
        }
        return '';
    }

    handleToggleProductOpen = _ => this.setState({productOpen: !this.state.productOpen});

    render() {
        return (
            <React.Fragment>
                <div className={this.props.fullHeight ? "w-100 h-100" : "w-100"}>
                    <div className={"card bg-main-z2 my-2 overflow-hidden h-100"}>
                        <div
                            onClick={this.handleProductOpen}
                            className={'card-img-top'}
                            style={this.imageStyle}
                        />
                        {
                            this.props.showOwnerIcon &&
                            <OwnerIconWrapper>
                                <AppIconThumbnail
                                    src={`${AppConfig.webServerUrl}/api/negozio/${this.props.product.id_negozio}/logoIcon/100`}
                                    size={`${AppIconImageSize.productThumbnail.w}px`}
                                    circle={true}
                                />
                            </OwnerIconWrapper>
                        }
                        {
                            this.props.showFavouriteIcon &&
                            <NeedAuthCallToActionHOC>
                                <EcommerceProductFavouriteStatusHOC
                                    idProdotto={this.props.product.id_prodotto}
                                    initialStatus={this.props.product.isPreferito}
                                    render={(isPreferito, onToggle) => {
                                        return (
                                            <FavouriteIconWrapper
                                                onClick={onToggle}
                                                className={"text-white d-flex align-items-center justify-content-center"}
                                            >
                                                {isPreferito ? <FaSolidIcon name={"heart"}/> :
                                                    <FaRegularIcon name={"heart"}/>}
                                            </FavouriteIconWrapper>
                                        )
                                    }}
                                />
                            </NeedAuthCallToActionHOC>
                        }
                        <div className={"card-body p-2 border-top"} onClick={this.handleProductOpen}>
                            <div style={{maxWidth: "100%"}}>
                                <ProductName className={"text-truncate text-on-bg-main"}>{this.props.product.nome}</ProductName>
                                <div className={"row"}>
                                    <div className={"col"}>
                                    <span className={"text-primary font-weight-bold"}>
                                        {this.formatEuro(this.getPrice(true))}
                                    </span> {this.getDiscountNoticeLabel()}
                                    </div>
                                    {
                                        this.props.showOpenButton &&
                                        <div className={"col text-right"}>
                                            <button className={"btn btn-sm btn-outline-dark"}>
                                                Scopri
                                            </button>
                                        </div>
                                    }
                                    <div className={"col-12"}>
                                        {this.getDiscountLabel()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalPortalHOC>
                    {
                        this.state.productOpen &&
                        <EcommerceProductPage
                            productData={this.props.product}
                            onAddToCart={this.props.onAddToCart}
                            onDismiss={this.handleToggleProductOpen}
                            showSameCategoryProducts={true}
                            showSameBrandProducts={true}
                            showExpectedShipping={true}
                        />
                    }
                </ModalPortalHOC>
            </React.Fragment>
        )
    }

}

EcommerceProductCardGrid.propTypes = {
    onAddToCart: PropTypes.func,
    fullHeight: PropTypes.bool
}

EcommerceProductCardGrid.defaultProps = {
    fullHeight: true,
}
