import PropTypes from "prop-types";

export const SectorSeatType = PropTypes.shape({
    seat_id: PropTypes.any,
    sector_id: PropTypes.any,
    internal_code: PropTypes.string,
    position_x_ratio: PropTypes.number,
    position_y_ratio: PropTypes.number,
    width_ratio: PropTypes.number,
    height_ratio: PropTypes.number,
    enable_from_date: PropTypes.string,
    enable_until_date: PropTypes.string,
    hidden: PropTypes.bool,
});