import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../FontAwesomeIcons";
import moment from "moment";
import {BookizonAppManager} from "../../../../index";

export function GenericMediaMessage({isMine, isLast, messageData, getMediaContentUrlAPI}) {
    const sendTime = moment(messageData.data_creazione).format('HH:mm');
    const mediaUrl = getMediaContentUrlAPI(messageData.id_messaggio);

    const openMedia = _ => BookizonAppManager.openInAppBrowser(mediaUrl);

    return (
        <React.Fragment>
            <div className={"d-flex " + (isMine ? 'justify-content-end mine' : 'yours')}>
                <div className={"message " + (isLast ? 'last' : '')}>
                    <a href={"javascript:void(0)"} onClick={openMedia}>
                        <FaSolidIcon name={"file"}/> {messageData.metadata.filename}
                    </a>
                    <div className={"metadata"}>
                        {sendTime}&nbsp;
                        {
                            isMine &&
                            <FaSolidIcon name={messageData.letto === '1' ? 'check-double' : 'check'}/>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

GenericMediaMessage.propTypes = {
    isMine: PropTypes.bool,
    isLast: PropTypes.bool,
    messageData: PropTypes.object.isRequired,
    getMediaContentUrlAPI: PropTypes.func.isRequired,
}