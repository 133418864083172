import React from "react";
import PropTypes from "prop-types";
import {AppIconThumbnail} from "../../../../components/BaseComponents/AppIconThumbnail";
import AppConfig from "../../../../config/AppConfig";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import {DashboardAPI} from "../../../../api/Admin/DashboardAPI";
import {swalError, swalLoading} from "../../../../helpers/SweetAlertWrapper";

export function DashboardAdminSwitchListViewItem({shop}) {
    const negozioIconUrl = `${AppConfig.webServerUrl}/api/negozio/${shop.id_negozio}/logoIcon/100`;
    const wrapperStyle = {
        maxWidth: 80
    }

    const handleSwitch = _ => {
        swalLoading();
        DashboardAPI.switchAdmin(shop.id_negozio).then(_ => {
            window.location.reload();
        }).catch(swalError);
    }

    return (
        <React.Fragment>
            <div className={"d-flex flex-column align-items-center mr-3"} style={wrapperStyle} onClick={handleSwitch}>
                <AppIconThumbnail size={"35px"} src={negozioIconUrl} className={"mb-2"}/>
                <SafeHtmlContainer className={"d-block w-100 small text-muted text-truncate"} html={shop.nome}/>
            </div>
        </React.Fragment>
    )
}

DashboardAdminSwitchListViewItem.propTypes = {
    shop: PropTypes.shape({
        id_negozio: PropTypes.any,
        nome: PropTypes.string
    })
}