import AppConfig from "../config/AppConfig";
import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";

var NovitaUsersAPI = {
    getNews: function () {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/appBreakingNews/allNews/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getUserNews: function () {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/appBreakingNews/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    setSeenNews: function () {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/appBreakingNews/newsSeen/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_RESPONSE,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    countNewsVisible: function () {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/appBreakingNews/countVisible/${AuthAPI.currentUser.accessToken}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }
};

export default NovitaUsersAPI;
