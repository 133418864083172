import React from "react";
import PropTypes from "prop-types";
import {InputDatepicker} from "../../../../../../components/BaseComponents/InputDatepicker";
import moment from "moment";
import {FaSolidIcon} from "../../../../../../components/FontAwesomeIcons";
import {swalError, swalLoading, swalSuccess} from "../../../../../../helpers/SweetAlertWrapper";
import {InputMobileImagePicker} from "../../../../../../hoc/InputMobileImagePicker";
import AuthAPI from "../../../../../../api/AuthAPI";
import {ModuloHotelAPI} from "../../../../../../api/ModuloHotel/ModuloHotelAPI";

export class OspiteCrudForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {...this.props.ospite},
        }
    }

    handleChange = e => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value;
        this.setState({formData: formData});
    }

    handleDateChange = e => {
        const formData = {...this.state.formData};
        formData[e.target.name] = moment(e.value).format('DD-MM-YYYY');
        this.setState({formData: formData});
    }

    handleImageFronteDocumentoChange = blob => {
        const formData = {...this.state.formData};
        formData.immagine_fronte_documento = blob;
        this.setState({formData: formData});
    }

    handleImageRetroDocumentoChange = blob => {
        const formData = {...this.state.formData};
        formData.immagine_retro_documento = blob;
        this.setState({formData: formData});
    }

    handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        for (let key in this.state.formData) {
            const value = this.state.formData[key] || '';
            if (value) formData.append(key, value); //In caso immagini non modificate verrà inviato l'URL attuale, ma tanto non risulta come $_FILES in php
        }
        formData.append('id_prenotazione', this.props.idPrenotazione);
        formData.append('token', AuthAPI.getUserAccessToken());
        swalLoading('Salvataggio in corso...');
        ModuloHotelAPI.Guest.save(formData).then(message => {
            swalSuccess(message).then(this.props.onSuccess);
        }).catch(swalError);
    }

    render() {
        return (
            <form className="form-modifica-camera my-3" onSubmit={this.handleSubmit}>
                <div className="container-fluid">
                    <h4 className="text-secondary font-weight-bold mt-3">Dati anagrafici</h4>
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label>Nome:</label>
                            <input
                                className={"form-control"} name="nome" type={"text"}
                                value={this.state.formData.nome} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Cognome:</label>
                            <input
                                className={"form-control"} name="cognome" type={"text"}
                                value={this.state.formData.cognome} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Sesso:</label>
                            <select className={"form-control"} name="sesso"
                                    value={this.state.formData.sesso} onChange={this.handleChange}
                            >
                                <option value="-1">Non specificato</option>
                                <option value="1">Maschio</option>
                                <option value="0">Femmina</option>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label>Codice fiscale:</label>
                            <input
                                className={"form-control"} name="codice_fiscale" type={"text"}
                                value={this.state.formData.codice_fiscale} onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label>Luogo Nascita:</label>
                            <input
                                className={"form-control"} name="luogo_nascita" type={"text"}
                                value={this.state.formData.luogo_nascita} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Prov. Nascita:</label>
                            <input
                                className={"form-control"} name="provincia_nascita" type={"text"}
                                value={this.state.formData.provincia_nascita} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Nazione Nascita:</label>
                            <input
                                className={"form-control"} name="nazione_nascita" type={"text"}
                                value={this.state.formData.nazione_nascita} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Data Nascita:</label>
                            <InputDatepicker
                                className={"form-control bg-main-z2"} name="data_nascita" type={"text"}
                                value={this.state.formData.data_nascita} onChange={this.handleDateChange}
                            />
                        </div>
                    </div>

                    <h4 className="text-secondary font-weight-bold mt-3">Contatti</h4>
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label>Telefono:</label>
                            <input
                                className={"form-control"} name="telefono" type={"text"}
                                value={this.state.formData.telefono} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Cellulare:</label>
                            <input
                                className={"form-control"} name="cellulare" type={"text"}
                                value={this.state.formData.cellulare} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Email:</label>
                            <input className={"form-control"} name="email" type={"text"}
                                   value={this.state.formData.email} onChange={this.handleChange}
                            />
                        </div>
                    </div>

                    <h4 className="text-secondary font-weight-bold mt-3">Residenza</h4>
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label>Nazione:</label>
                            <input className={"form-control"} name="nazione" type={"text"}
                                   value={this.state.formData.nazione} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Città:</label>
                            <input className={"form-control"} name="citta" type={"text"}
                                   value={this.state.formData.citta} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Cap:</label>
                            <input className={"form-control"} name="cap" type={"text"}
                                   value={this.state.formData.cap} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Indirizzo:</label>
                            <input className={"form-control"} name="indirizzo" type={"text"}
                                   value={this.state.formData.indirizzo} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Provincia:</label>
                            <input className={"form-control"} name="provincia" type={"text"}
                                   value={this.state.formData.provincia} onChange={this.handleChange}
                            />
                        </div>
                    </div>

                    <h4 className="text-secondary font-weight-bold mt-3">Documenti</h4>
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label>Tipo documento:</label>
                            <select className={"form-control"} name="tipo_documento"
                                    value={this.state.formData.tipo_documento} onChange={this.handleChange}
                            >
                                <option value="">Nessuno</option>
                                <option value="carta_identita">Carta di identità</option>
                                <option value="certificato_identità">Certificato d'identità</option>
                                <option value="carta_identita_elettronica">Carta di identità elettronica</option>
                                <option value="passaporto_diplomatico">Passaporto diplomatico</option>
                                <option value="passaporto_ordinario">Passaporto ordinario</option>
                                <option value="passaporto_servizio">Passaporto di servizio</option>
                                <option value="patente">Patente di guida</option>
                                <option value="patente_nautica">Patente nautica</option>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label>Numero:</label>
                            <input className={"form-control"} name="numero_documento" type={"text"}
                                   value={this.state.formData.numero_documento} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Rilasciato da:</label>
                            <select className={"form-control"} name="ente_rilascio_documento"
                                    value={this.state.formData.ente_rilascio_documento} onChange={this.handleChange}
                            >
                                <option value="">Nessuno</option>
                                <option value="comune">Comune</option>
                                <option value="prefetto">Prefetto</option>
                                <option value="questura">Questura</option>
                                <option value="ente">Ente</option>
                                <option value="motorizzazione">Motorizzazione</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label>Data rilascio:</label>
                            <InputDatepicker className={"form-control bg-main-z2"} name="data_rilascio_documento"
                                             type={"text"} readOnly
                                             value={this.state.formData.data_rilascio_documento}
                                             onChange={this.handleDateChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Comune rilascio:</label>
                            <input className={"form-control"} name="comune_rilascio_documento" type={"text"}
                                   value={this.state.formData.comune_rilascio_documento} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Nazione rilascio:</label>
                            <input className={"form-control"} name="nazione_rilascio_documento" type={"text"}
                                   value={this.state.formData.nazione_rilascio_documento} onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Data scadenza:</label>
                            <InputDatepicker className={"form-control bg-main-z2"} name="data_scadenza_documento"
                                             type={"text"} readOnly
                                             value={this.state.formData.data_scadenza_documento}
                                             onChange={this.handleDateChange}
                            />
                        </div>
                        <div className={"form-group col-md-2"}>
                            <label>Fronte documento</label>
                            <InputMobileImagePicker
                                onChange={this.handleImageFronteDocumentoChange}
                                previewClassName={"img-fluid mt-2"}
                                previewInitialSrc={this.props.ospite?.immagine_fronte_documento}
                            />
                        </div>
                        <div className={"form-group col-md-2"}>
                            <label>Retro documento</label>
                            <InputMobileImagePicker
                                onChange={this.handleImageRetroDocumentoChange}
                                previewClassName={"img-fluid mt-2"}
                                previewInitialSrc={this.props.ospite?.immagine_retro_documento}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary btn-block">
                        <FaSolidIcon name={"save"}/> Salva ospite
                    </button>
                </div>
            </form>
        )
    }

}

OspiteCrudForm.propTypes = {
    idPrenotazione: PropTypes.string,
    ospite: PropTypes.object,
    onSuccess: PropTypes.func
}