import {_Credentials} from "./_Credentials";
import {_Geolocation} from "./_Geolocation";
import {_Settings} from "./_Settings";
import {_Operations} from "./_Operations";

export const UserAPI = {
    Credentials: _Credentials,
    Geolocation: _Geolocation,
    Settings: _Settings,
    Operations: _Operations
};
