import {Base64} from "../../../helpers/Base64";


/**
 * Verifica se due prodotti sono uguali considerando anche le aggiunte, rimozioni e opzioni
 * @param p1
 * @param p2
 *
 * @return boolean
 * */
const areProductEquals = (p1, p2) => {
    if (p1.id_prodotto !== p2.id_prodotto) {
        return false;
    }

    if (p1.id_opzione !== p2.id_opzione) {
        return false;
    }

    if ((p1.aggiunte && !p2.aggiunte) || (!p1.aggiunte && p2.aggiunte)) { //XOR
        return false;
    }

    if (p1.aggiunte && p2.aggiunte && p1.aggiunte.length !== p2.aggiunte.length) {
        return false;
    }

    if ((p1.rimozioni && !p2.rimozioni) || (!p1.rimozioni && p2.rimozioni)) { //XOR
        return false;
    }

    if (p1.rimozioni && p2.rimozioni && p1.rimozioni.length !== p2.rimozioni.length) {
        return false;
    }

    if ((p1.attributi && !p2.attributi) || (!p1.attributi && p2.attributi)) {
        return false;
    }

    if (p1.attributi && p2.attributi && Object.keys(p1.attributi).length !== Object.keys(p2.attributi).length) {
        return false;
    }

    for (var i in p1.aggiunte) {
        if (!p2.aggiunte.find(id_aggiunta2 => id_aggiunta2 === p1.aggiunte[i])) {
            return false;
        }
    }

    for (var i in p1.rimozioni) {
        if (p2.rimozioni.find(rimozione => rimozione === p1.rimozioni[i]) === undefined) {
            return false;
        }
    }

    for (var i in p1.attributi) {
        if (!Object.keys(p2.attributi).find(nomeAttributo => p2.attributi[nomeAttributo] === p1.attributi[nomeAttributo])) {
            return false;
        }
    }

    return true;
}

/**
 * Salva i dati di un carrello di un negozio per la lettura offline
 * @param id_negozio
 * @param cartProducts
 *
 * @return void
 * */
const saveOfflineCartProducts = (id_negozio, cartProducts) => {
    localStorage.setItem(`${id_negozio}_food_cart`, Base64.encode(JSON.stringify(cartProducts)));
}

/**
 * Recupera i dati di un carrello di un negozio per la lettura offline
 * @param id_negozio
 *
 * @return array
 * */
const getOfflineCartProducts = (id_negozio) => {
    try {
        const cart = JSON.parse(atob(localStorage.getItem(`${id_negozio}_food_cart`)));
        return cart || [];
    } catch (e) {
        console.warn("IMPOSSIBILE RECUPERARE CARRELLO", e);
    }
    return [];
}


/**
 * Aggiorna i dettagli di un prodotto (aggiunte, opzioni, rimozioni ecc...) nel carrello passato, restituendo il
 * carrello con i dati modificati
 *
 * @param {Object[]} cartProducts
 * @param {Object} editingProductData
 * @param {Object} details {
 *      @param {(string|number)} details.id_opzione
 *      @param {Object[]} details.aggiunte
 *      @param {String[]} details.rimozioni
 *      @param {Object[]} details.attributi
 *      @param {string} details.note
 * }
 *
 * @return {Object[]}
 * */
const updateAggiunte = (cartProducts, editingProductData, details) => {
    const products = cartProducts.slice();
    products.map((p, i) => {
        if (FoodCartUtils.checkProductEquals(p, editingProductData)) {
            products[i].id_opzione = details.id_opzione;
            products[i].aggiunte = details.aggiunte;
            products[i].rimozioni = details.rimozioni;
            products[i].attributi = details.attributi;
            products[i].note = details.note
        }
    });
    return products;
}


/**
 * Aggiorna un prodotto del carrello impostando nuova quantità e note, restituendo il carrello aggiornato
 * @param {Object[]} cartProducts
 * @param {Object} productRefData
 * @param {number} qty
 * @param {string} note
 * @param {function} checkQtyCb (id_prodotto, neededQty) Funzione che restituisce true o false se vi è disponibili la
 * quantità desiderata di un certo prodotto
 *
 * @return {Object[]}
 * */
const updateCartProduct = (cartProducts, productRefData, qty, note, checkQtyCb) => {
    let products = cartProducts.slice();
    products.map((p, i) => {
        if (FoodCartUtils.checkProductEquals(p, productRefData)) {
            let quantityVerified = qty
            //Verifico che ci sia disponibilità
            if (checkQtyCb){
                const checkQty = checkQtyCb(productRefData.id_prodotto, qty);
                if(!checkQty) return;
                //Se la callback restituisce -1 il prodotto è da eliminare
                if(checkQty < 0) quantityVerified = -1
            }
            //Se il prodotto non esiste più viene eliminato
            products[i].quantita = quantityVerified;
            products[i].note = note;
        }
    });
    products = products.filter(p => p.quantita > 0);//-1 significa che il prodotto è da eliminare, 0 che non lo vuole più
    return products;
}


/**
 * Aggiunge un determinato prodotto al carrello, aumentando la quantità se già presente, e restituendo il nuovo
 * carrello aggiornato
 * @param {Object[]} cartProducts
 * @param {Object} productData
 * @param {function} getProductDataCb (id_prodotto) Funzione che restituisce i dati del prodotto indicato
 *
 * @return {Object[]}
 * @throws Error messaggio di errore da poter far visualizzare all'utente finale
 * */
const addToCart = (cartProducts, productData, getProductDataCb) => {
    let trovato = false;
    let products = cartProducts.slice();
    for(let i in products){
        const p = products[i];
        if (products.hasOwnProperty(i)){
            if (FoodCartUtils.checkProductEquals(p,productData)) {
                trovato = true;

                //Verifico che ci sia disponibilità
                const prodotto = getProductDataCb(productData.id_prodotto);
                if (prodotto) {
                    const quantitaInCarrello = cartProducts.filter(pc => pc.id_prodotto === productData.id_prodotto).reduce((acc, pp) => pp.quantita + acc, 0);
                    if (prodotto.quantita < quantitaInCarrello + productData.quantita) {
                        throw new Error(`Ci sono solamente ${prodotto.quantita} ${prodotto.nome} disponibili`)
                    }
                }

                products[i].quantita += productData.quantita;
                if (productData.note) {
                    products[i].note += `\n${productData.note}`;
                }

            }
        }
    }

    if (!trovato) {
        //Verifico che ci sia disponibilità
        const prodotto = getProductDataCb(productData.id_prodotto);
        if (prodotto) {
            const quantitaInCarrello = cartProducts.filter(pc => pc.id_prodotto === productData.id_prodotto).reduce((acc, pp) => pp.quantita + acc, 0);
            if (prodotto.quantita < quantitaInCarrello + productData.quantita) {
                throw new Error(`Ci sono solamente ${prodotto.quantita} ${prodotto.nome} disponibili`)
            }
            productData.listaAggiunte = prodotto.aggiunte; //Nei dati del prodotto in carrello passo anche la lista di tutte le aggiunte
            productData.listaOpzioni = prodotto.opzioni; //Nei dati del prodotto in carrello passo anche la lista di tutte le opzioni
            products.push(productData);
        }
    }

    return products;
}



/**
 * Effettua l'unione di due carrelli, per quanto riguarda le liste di aggiunge e di opzioni disponibili vengono
 * mantenute in memoria */
const mergeCarts = (cartProducts1, cartProducts2) => {
    const resultCart = cartProducts1.slice();
    cartProducts2.map(p2 => {
        let trovato = false;
        for(let i in resultCart){
            if (resultCart.hasOwnProperty(i)) {
                if (FoodCartUtils.checkProductEquals(resultCart[i], p2)) {
                    trovato = true;

                    resultCart[i].quantita += p2.quantita;
                    if (p2.note) {
                        resultCart[i].note += `\n${p2.note}`;
                    }

                    break;
                }
            }
        }

        //Il prodotto va aggiunto così com'è
        if (!trovato){
            resultCart.push(p2);
        }
    });

    return resultCart;
}



export const FoodCartUtils = {
    checkProductEquals: areProductEquals,
    saveOfflineCartProducts: saveOfflineCartProducts,
    getOfflineCartProducts: getOfflineCartProducts,
    updateCartProductDetails: updateAggiunte,
    updateCartProduct: updateCartProduct,
    addToCart: addToCart,
    mergeCarts: mergeCarts
};
