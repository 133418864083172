import styled from "styled-components";
import {shadeColor} from "../../helpers/UIUtility";

export const ExpoThemeWrapper = styled.div`
    & .text-primary{
        color:${props => props.color}!important;
    }
    
    & .border-primary{
        border-color:${props => props.color}!important;
    }
    
    & .bg-primary{
        background-color:${props => props.color}!important;
    }
    
    & .page-navbar{
        background-color:${props => props.color}!important;
    }
    
    & .page-item.active .page-link {
        color: #fff !important;
        background-color: ${props => props.color} !important;
        border-color: ${props => props.color} !important;
    }
    
    & .page-link {
        color: ${props => props.color} !important;
    }
    
    & .btn-soft-primary{
        color: ${props => props.color} !important;
        background-color: ${props => shadeColor(props.color, 215)} !important;
        border-color: ${props => props.color} !important;
    }
    
    & .btn-primary{
        color: #fff !important;
        background-color: ${props => props.color} !important;
        border-color: ${props => props.color} !important;
    }
`;
