import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
    TIPO_OPERAZIONE_ORDINE_ECOMMERCE,
    TIPO_OPERAZIONE_ORDINE_FOOD,
    TIPO_OPERAZIONE_PRENOTAZIONE_CAMERA,
    TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI,
    TIPO_OPERAZIONE_PRENOTAZIONE_SERVIZI
} from "../../const/OperazioniConstants";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";

const MOMENT_CALENDAR_SETTINGS = {
    sameDay: '[oggi alle] HH:mm',
    nextDay: '[domani alle] HH:mm',
    nextWeek: 'dddd DD [alle] HH:mm',
    lastDay: '[ieri alle] HH:mm',
    lastWeek: '[lo scorso] dddd [alle] HH:mm',
    sameElse: 'dddd D MMM [alle] HH:mm'
}


export class UserDisputeListViewItem extends React.Component {

    constructor(props) {
        super(props);
    }

    handleClick = _ => {
        this.props.onClick(this.props.disputeData);
    }

    getOperationName = opType => {
        switch (opType) {
            case TIPO_OPERAZIONE_PRENOTAZIONE_SERVIZI:
            case TIPO_OPERAZIONE_PRENOTAZIONE_GRUPPI:
            case TIPO_OPERAZIONE_PRENOTAZIONE_CAMERA:
                return 'Prenotazione';
            case TIPO_OPERAZIONE_ORDINE_ECOMMERCE:
            case TIPO_OPERAZIONE_ORDINE_FOOD:
                return 'Ordine';
        }
    }

    render() {
        return (
            <div className={"list-group-item d-flex align-items-center " + this.props.className}
                 onClick={this.handleClick}>
                <div className={"w-75"}>
                    <div>
                        <div className={"text-truncate font-weight-bold"}>
                            <SafeHtmlContainer html={`${this.props.disputeData.nome}`}/>

                        </div>
                    </div>
                    <div className={"small text-muted"}>
                        {this.getOperationName(this.props.disputeData.tipo_operazione)} #{this.props.disputeData.id_operazione}
                    </div>
                    <div className={"small text-muted"}>
                        Aperta {moment(this.props.disputeData.data_creazione).calendar(null, MOMENT_CALENDAR_SETTINGS)}
                    </div>
                </div>
                <div className={"flex-fill"}>
                    <div className={"d-flex align-items-center justify-content-end"}>
                        {
                            this.props.newMessageNumber > 0 &&
                            <span className={'badge badge-pill badge-primary'}>
                                {this.props.newMessageNumber}
                            </span>
                        }
                        <FaSolidIcon name={"chevron-right"} className={"ml-2"}/>
                    </div>
                </div>
            </div>
        )
    }

}

UserDisputeListViewItem.propTypes = {
    disputeData: PropTypes.object,
    label: PropTypes.string,
    newMessageNumber: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
}