import React from 'react';

export default class DiscountBoxToWarnUser extends React.Component{
    constructor(props) {
        super(props);
    }


    render() {
        return <div className={"bg-soft-primary text-center shadow-sm p-2 my-2"}>
            Il tuo <b>sconto</b> del <b>{this.props.discount}%</b> verrà applicato al momento del pagamento &#x1F389;
        </div>
    }
}