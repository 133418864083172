import FuxHTTP from "../../../lib/FuxFramework/FuxHTTP";
import APIConfig from "../../APIConfig";
import AuthAPI from "../../AuthAPI";

export const _Books = {
    getNextBooks: function () {
        return FuxHTTP.get(
            APIConfig.url(`/modulo-seats/books/get-next-books`), {
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    getFullBookInfo: function (master_book_id) {
        return FuxHTTP.get(
            APIConfig.url(`/modulo-seats/books/get-full-book-info`), {
                book_id: master_book_id,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_RESPONSE
        );
    },
    cancelBook: function (book_id, reason) {
        return FuxHTTP.post(
            APIConfig.url(`/modulo-seats/books/cancel-book`), {
                book_id: book_id,
                reason: reason,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
}