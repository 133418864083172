import React, {Fragment} from "react";
import PropTypes from "prop-types";
import AuthAPI from "../../../../api/AuthAPI";
import PostAPI from "../../../../api/PostAPI";
import FeedCommentItem from "./FeedCommentItem";
import moment from "moment";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {ANSWER_IS_LOADED, ANSWERING_A_COMMENT} from "../../../../const/AppEventsCostants";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";


const limit = 10
const offset = 0

export class FeedCommentsFetchingAnswers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            answersList: [],
            totalAnswers: 0,
            loadingData: false,
            limit: limit,
            offset: offset,

            newAnsweringInPublishing : ""
        }
        this.loadingStyle = {
            transform: 'scale(0.6)'
        }
    }

    componentDidMount() {
        this.fetchAnswers()
        FuxEvents.on(ANSWERING_A_COMMENT, this.handleAnsweringAComment)
        FuxEvents.on(ANSWER_IS_LOADED, this.handleAnswerIsLoaded)
    }

    componentWillUnmount() {
        FuxEvents.off(ANSWERING_A_COMMENT, this.handleAnsweringAComment)
        FuxEvents.off(ANSWER_IS_LOADED, this.handleAnswerIsLoaded)
    }

    fetchAnswers = _ => {
        this.setState({loadingData: true})
        PostAPI.getCommentAnswers(this.props.idContext, this.state.limit, this.state.offset)
            .then(data => {
                //Se sto ricaricando i commenti con limit e offset pari ai valori di partenza pulisco la answersList
                //è stato fatto perché quando aggiungo un nuovo commento limit e offset vengono resettati ed avrò bisogno di resettare tutto
                let answersList = ((this.state.limit === limit && this.state.offset === offset) ? data.answers.reverse() : data.answers.reverse().concat(this.state.answersList))
                this.setState({answersList: answersList, totalAnswers: data.total_answers, loadingData: false})
            })
    }

    handleAnsweringAComment = commentData =>{
        if(commentData.id_commento_risposto !== this.props.idContext){
            return 0;
        }
        console.log(commentData.commento)
        this.setState({newAnsweringInPublishing: commentData.commento})
    }

    handleAnswerIsLoaded = commentData =>{
        if(commentData.id_commento_risposto !== this.props.idContext){
            return 0;
        }
        //Refeccio i dati e resetto limit e offset
        this.setState({newAnsweringInPublishing: "", limit:limit, offset:offset}, _=>{
            this.fetchAnswers()
        });

    }

    handleOtherAnswers =_=>{
        this.setState({offset: this.state.offset + 10}, _=>{
            this.fetchAnswers()
        })
    }


    render() {
        return (
            <div>
                {
                    this.state.totalAnswers > this.state.offset + this.state.limit &&
                    <small className={"p-2 text-muted mb-5 "} onClick={this.handleOtherAnswers}>
                        Carica precedenti...
                    </small>
                }

                {/*Mostra il caricamento mentre i dati vengono scaricati*/}
                {this.state.loadingData && <div style={this.loadingStyle}><EllipsisLoader/></div>}

                {
                    !this.state.loadingData && this.state.answersList.length > 0 && this.state.answersList.map((c, i) => {
                        return (
                            <div key={i} className={"mt-2"}>
                                <FeedCommentItem
                                    idUtente={c.id_utente}
                                    idCommento={c.id_commento}
                                    nomeUtente={c.nome_utente}
                                    immagineUtente={c.immagine_utente || null}
                                    commento={c.commento}
                                    dataCommento={c.data_creazione}
                                    disableReplay={true}
                                    likeNumber={c.n_like}
                                    userLiked={c.user_liked == "0" ? false : true}
                                />
                            </div>
                        )
                    })
                }

                {
                    this.state.newAnsweringInPublishing &&
                    <FeedCommentItem
                        idUtente={AuthAPI.currentUser.id_utente}
                        nomeUtente={AuthAPI.currentUser.nome+ " "+AuthAPI.currentUser.cognome}
                        immagineUtente={AuthAPI.currentUser.immagine || null}
                        commento={this.state.newAnsweringInPublishing}
                        dataCommento={moment()}
                        publishing={true}
                    />
                }
            </div>
        )
    }
}

FeedCommentsFetchingAnswers.propTypes = {
    idContext: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //Se nullo si riferisce al post
}