import FuxEvents from "../lib/FuxFramework/FuxEvents";

var Store = {
    _storeChangeEvent: '_STORE_CHANGE_',
    state: {
        nextBooks:[],
        nextGlobalBooks:[],
        bookToEdit:null, //L'oggetto che rappresenta i dati di una prenotazione che si vuole modificare,
        apps:null, //L'elenco della app attualmente collegato,
        pushNotification:[], //L'elenco delle notifiche dell'utente loggato
        ordini: [],
        hotelBooks: [],
        shouldOpenAppId: null,
        native:{
            lat: null,
            lng: null,
            hasGeolocationPermission: false
        },
        shouldOpenProduct: null,
    },
    setState:(newState) => {
        Store.state = Object.assign(Store.state, newState);
        FuxEvents.emit(Store._storeChangeEvent, Store.state);
    },
    onChange: (cb) => {
        FuxEvents.on(Store._storeChangeEvent, cb);
    },
    off: (cb) => {
        FuxEvents.off(Store._storeChangeEvent, cb);
    },
    one: cb => {
        let _cb = _ => {
            console.log('cia');
            cb();
            Store.off(_cb);
        }
        Store.onChange(_cb);
    }
};

export default Store;
