const {
    ID_CATEGORIA_BELLEZZA_BENESSERE, ID_CATEGORIA_RISTORAZIONE, ID_CATEGORIA_SPORT_DIVERTIMENTO,
    ID_CATEGORIA_PROFESSIONISTI, ID_CATEGORIA_ECOMMERCE, ID_CATEGORIA_TURISMO
} = require("./CategorieNegoziConstants");

export const MODULE_SERVIZI = "modulo_servizi";
export const MODULE_SHOP = "modulo_shop";
export const MODULE_SHOP_LITE = "modulo_shop_lite";
export const MODULE_GRUPPI = "modulo_gruppi";
export const MODULE_FOOD = "modulo_food";
export const MODULE_COMANDE = "modulo_comande";
export const MODULE_HOTEL = "modulo_hotel";
export const MODULE_CONTABILITA = "modulo_contabilita";
export const MODULE_TRICOLOGIA = "modulo_tricologia";
export const MODULE_BODYCARE = "modulo_bodycare";
export const MODULE_CASSA = "modulo_cassa";
export const MODULE_SEATS = "modulo_seats";
export const MODULE_BOOKIZON_PLUS = "bookizon_plus";

export const MODULE_BY_CATEGORY_MAP = {
        [ID_CATEGORIA_RISTORAZIONE]: {
            [MODULE_SHOP]: {
                "name": "E-commerce",
                "shortName": "E-commerce",
                "description": "Metti in vendita i tuoi prodotti sull'app in modo semplice e veloce. Gestisci metodi di spedizione e ricevi pagamenti online o offline.",
            },
            [MODULE_FOOD]: {
                "name": "Food Delivery/Take away",
                "shortName": "Food delivery",
                "description": "Permetti ai tuoi clienti di acquistare i tuoi piatti quando lo desiderano, prenotando una consegna o ritiro in sede all'orario che preferiscono.",
            },
            [MODULE_GRUPPI]: {
                "name": "Sale & tavoli",
                "shortName": "Sale",
                "description": "Permetti ai tuoi clienti di prenotare un tavolo in una sala con un solo click",
            },
            [MODULE_COMANDE]: {
                "name": "Comande",
                "shortName": "Comande",
                "description": "Gestisci le comande in sala direttamente tramite l'app Bookizon, cucina e sala sempre di pari passo!",
            },
            [MODULE_CASSA]: {
                "name": "Cassa virtuale",
                "shortName": "Cassa",
                "image": "cassa.jpg",
                "description": "Emetti scontrini telematici in un click, senza un registratore fisico ma potendo stampare la copia cartacea, collegato direttamente all'AE e senza necessità della chiusura fiscale.",
            },
            [MODULE_BOOKIZON_PLUS]: {
                "name": "Bookizon Plus",
                "shortName": "Plus",
                "image": "bookizon-plus.png",
                "description": "Assistenza personalizzata e prioritaria, azioni marketing con intelligenza artificiale, template mail e social media, report settimanali su whatsapp, analisi utili periodica con consulente dedicato e badge verificato in app",
            }
        },
        [ID_CATEGORIA_BELLEZZA_BENESSERE]: {
            [MODULE_SHOP]: {
                "name": "E-commerce",
                "shortName": "E-commerce",
                "description": "Metti in vendita i tuoi prodotti sull'app in modo semplice e veloce. Gestisci metodi di spedizione e ricevi pagamenti online o offline.",
            },
            [MODULE_SERVIZI]: {
                "name": "Prenotazione servizi",
                "shortName": "Servizi",
                "description": "Gestione del calendario, appuntamenti, servizi e collaboratori",
            },
            [MODULE_GRUPPI]: {
                "name": "Corsi & gruppi",
                "shortName": "Corsi",
                "description": "Prenotazione di corsi e sessioni in cui possono partecipare più persone contemporaneamente",
            },
            [MODULE_CONTABILITA]: {
                "name": "Contabilità",
                "shortName": "Contabilità",
                "image": "contabilita.jpg",
                "description": "Gestisci gestisci ogni movimentazione di denaro nella tua attività, pagamenti ricorrenti, scadenzario, la tua prima nota digitale e tanto altro!",
            },
            [MODULE_TRICOLOGIA]: {
                "name": "Tricologia",
                "shortName": "Tricologia",
                "image": "tricologia.jpg",
                "description": "Crea una diagnosi per i tuoi clienti, organizza le terapie in modo rapido e veloce e crea i promemoria per le applicazioni dei prodotti in automatico.",
            },
            [MODULE_BODYCARE]: {
                "name": "Body care",
                "shortName": "Body care",
                "image": "bodycare.jpg",
                "description": "Crea una diagnosi per i tuoi clienti, organizza le terapie in modo rapido e veloce e crea i promemoria per le applicazioni dei prodotti in automatico.",
            },
            [MODULE_CASSA]: {
                "name": "Cassa virtuale",
                "shortName": "Cassa",
                "image": "cassa.jpg",
                "description": "Emetti scontrini telematici in un click, senza un registratore fisico ma potendo stampare la copia cartacea, collegato direttamente all'AE e senza necessità della chiusura fiscale.",
            },
            [MODULE_BOOKIZON_PLUS]: {
                "name": "Bookizon Plus",
                "shortName": "Plus",
                "image": "bookizon-plus.png",
                "description": "Assistenza personalizzata e prioritaria, azioni marketing con intelligenza artificiale, template mail e social media, report settimanali su whatsapp, analisi utili periodica con consulente dedicato e badge verificato in app",
            }
        },
        [ID_CATEGORIA_SPORT_DIVERTIMENTO]:
            {
                [MODULE_SHOP]:
                    {
                        "name":
                            "E-commerce",
                        "shortName":
                            "E-commerce",
                        "description":
                            "Metti in vendita i tuoi prodotti sull'app in modo semplice e veloce. Gestisci metodi di spedizione e ricevi pagamenti online o offline.",
                    }
                ,
                [MODULE_SERVIZI]:
                    {
                        "name":
                            "Prenotazione servizi",
                        "shortName":
                            "Servizi",
                        "description":
                            "Gestione del calendario, appuntamenti, servizi e collaboratori",
                    }
                ,
                [MODULE_GRUPPI]:
                    {
                        "name":
                            "Corsi & gruppi",
                        "shortName":
                            "Gruppi & corsi",
                        "description":
                            "Prenotazione di corsi e sessioni in cui possono partecipare più persone contemporaneamente",
                    },
                [MODULE_SEATS]: {
                    "name": "Prenotazioni posti",
                    "shortName": "Posti",
                    "description": "Permetti la prenotazione di posti specifici su una piantina da te caricata, con prezzi, fasce orarie, biglietti personalizzati, accessori e pagamenti anticipati!",
                },
                [MODULE_CASSA]: {
                    "name": "Cassa virtuale",
                    "shortName": "Cassa",
                    "image": "cassa.jpg",
                    "description": "Emetti scontrini telematici in un click, senza un registratore fisico ma potendo stampare la copia cartacea, collegato direttamente all'AE e senza necessità della chiusura fiscale.",
                },
                [MODULE_BOOKIZON_PLUS]: {
                    "name": "Bookizon Plus",
                    "shortName": "Plus",
                    "image": "bookizon-plus.png",
                    "description": "Assistenza personalizzata e prioritaria, azioni marketing con intelligenza artificiale, template mail e social media, report settimanali su whatsapp, analisi utili periodica con consulente dedicato e badge verificato in app",
                }
            }
        ,
        [ID_CATEGORIA_PROFESSIONISTI]:
            {
                [MODULE_SHOP]:
                    {
                        "name":
                            "E-commerce",
                        "shortName":
                            "E-commerce",
                        "description":
                            "Metti in vendita i tuoi prodotti sull'app in modo semplice e veloce. Gestisci metodi di spedizione e ricevi pagamenti online o offline.",
                    }
                ,
                [MODULE_SERVIZI]:
                    {
                        "name":
                            "Prenotazione servizi",
                        "shortName":
                            "Servizi",
                        "description":
                            "Gestione del calendario, appuntamenti, servizi e collaboratori",
                    },
                [MODULE_CASSA]: {
                    "name": "Cassa virtuale",
                    "shortName": "Cassa",
                    "image": "cassa.jpg",
                    "description": "Emetti scontrini telematici in un click, senza un registratore fisico ma potendo stampare la copia cartacea, collegato direttamente all'AE e senza necessità della chiusura fiscale.",
                },
                [MODULE_BOOKIZON_PLUS]: {
                    "name": "Bookizon Plus",
                    "shortName": "Plus",
                    "image": "bookizon-plus.png",
                    "description": "Assistenza personalizzata e prioritaria, azioni marketing con intelligenza artificiale, template mail e social media, report settimanali su whatsapp, analisi utili periodica con consulente dedicato e badge verificato in app",
                }
            }
        ,
        [ID_CATEGORIA_ECOMMERCE]:
            {
                [MODULE_SHOP]:
                    {
                        "name":
                            "E-commerce",
                        "shortName":
                            "E-commerce",
                        "description":
                            "Metti in vendita i tuoi prodotti sull'app in modo semplice e veloce. Gestisci metodi di spedizione e ricevi pagamenti online o offline.",
                    }
                ,
                [MODULE_SERVIZI]:
                    {
                        "name":
                            "Prenotazione servizi",
                        "shortName":
                            "Servizi",
                        "description":
                            "Gestione del calendario, appuntamenti, servizi e collaboratori",
                    }
                ,
                [MODULE_GRUPPI]:
                    {
                        "name":
                            "Corsi & gruppi",
                        "shortName":
                            "Corsi",
                        "description":
                            "Prenotazione di corsi e sessioni in cui possono partecipare più persone contemporaneamente",
                    },
                [MODULE_CASSA]: {
                    "name": "Cassa virtuale",
                    "shortName": "Cassa",
                    "image": "cassa.jpg",
                    "description": "Emetti scontrini telematici in un click, senza un registratore fisico ma potendo stampare la copia cartacea, collegato direttamente all'AE e senza necessità della chiusura fiscale.",
                },
                [MODULE_BOOKIZON_PLUS]: {
                    "name": "Bookizon Plus",
                    "shortName": "Plus",
                    "image": "bookizon-plus.png",
                    "description": "Assistenza personalizzata e prioritaria, azioni marketing con intelligenza artificiale, template mail e social media, report settimanali su whatsapp, analisi utili periodica con consulente dedicato e badge verificato in app",
                }
            }
        ,
        [ID_CATEGORIA_TURISMO]:
            {
                [MODULE_HOTEL]:
                    {
                        "name":
                            "Hotel e B&B",
                        "shortName":
                            "Hotel e B&B",
                        "description":
                            "Ricevi prenotazioni nelle tue strutture, vendi servizi aggiuntivi, gestisci il checkin virtuale e tanto altro...",
                    }
                ,
                [MODULE_SHOP]:
                    {
                        "name":
                            "E-commerce",
                        "shortName":
                            "E-commerce",
                        "description":
                            "Metti in vendita i tuoi prodotti sull'app in modo semplice e veloce. Gestisci metodi di spedizione e ricevi pagamenti online o offline.",
                    }
                ,
                [MODULE_FOOD]:
                    {
                        "name":
                            "Food Delivery/Take away",
                        "shortName":
                            "Food & Beverage",
                        "description":
                            "Permetti ai tuoi clienti di acquistare i tuoi piatti quando lo desiderano, prenotando una consegna o ritiro in sede all'orario che preferiscono.",
                    }
                ,
                [MODULE_COMANDE]:
                    {
                        "name":
                            "Comande",
                        "shortName":
                            "Comande",
                        "description":
                            "Gestisci le comande in sala direttamente tramite l'app Bookizon, cucina e sala sempre di pari passo!",
                    }
                ,
                [MODULE_GRUPPI]:
                    {
                        "name":
                            "sale & tavoli",
                        "shortName":
                            "Sale",
                        "description":
                            "Permetti ai tuoi clienti di prenotare un tavolo in una sala con un solo click",
                    },
                [MODULE_SEATS]: {
                    "name": "Prenotazioni posti",
                    "shortName": "Posti",
                    "description": "Permetti la prenotazione di posti specifici su una piantina da te caricata, con prezzi, fasce orarie, biglietti personalizzati, accessori e pagamenti anticipati!",
                },
                [MODULE_CASSA]: {
                    "name": "Cassa virtuale",
                    "shortName": "Cassa",
                    "image": "cassa.jpg",
                    "description": "Emetti scontrini telematici in un click, senza un registratore fisico ma potendo stampare la copia cartacea, collegato direttamente all'AE e senza necessità della chiusura fiscale.",
                },
                [MODULE_BOOKIZON_PLUS]: {
                    "name": "Bookizon Plus",
                    "shortName": "Plus",
                    "image": "bookizon-plus.png",
                    "description": "Assistenza personalizzata e prioritaria, azioni marketing con intelligenza artificiale, template mail e social media, report settimanali su whatsapp, analisi utili periodica con consulente dedicato e badge verificato in app",
                }
            }
    }
;

