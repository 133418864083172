import React, {Fragment} from "react";
import {PromotionsAPI} from "../../../../api/Promotions/PromotionsAPI";
import moment from "moment";
import ReactConfetti from "react-confetti";
import PromotionsCarousel from "../PromotionsCarousel";
import PromotionsBirthdayPage from "./PromotionsBirthdayPage";
import {BirthdayCakeIllustration} from "../../../../img/illustrations/BirthdayCakeIllustration";
import {PromotionsOrder} from "../../../../img/illustrations/PromotionsOrder";
import FuxEvents from "../../../../lib/FuxFramework/FuxEvents";
import {OPEN_PROMOTIONS_TAB} from "../../../../const/AppEventsCostants";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import coriandoliBackground from "./coriandoliBackground.png";
import styled, { keyframes } from 'styled-components'


const imgConfettiAnimation = keyframes`
 0% {}
 30% {opacity: 0.3}
 40% {opacity: 0.6;}
 100% {opacity: 0.8}
`

const ContainerForImgConfetti = styled.div`
    position:absolute;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-image: url('${coriandoliBackground}');
    z-index:1;
    opacity: 0;
    animation-name: ${imgConfettiAnimation};
    animation-delay: 4s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  
`;


export default class CardForBirthdayGreetings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            runConfetti: false,
            promotions: [],
            birthdayPageOpen: false,
            isInBirthdayRange:false,

        }

        this.cardStyle = {
            position: "relative",
            overflow: "hidden"
        }

        this.textStyle = {
            position: "relative",
            zIndex: 200
        }
    }


    componentDidMount() {
        if(this.checkIfIsBirthdayRange()){
            this.setState({isInBirthdayRange: true}, _=>{
                this.runConfetti()
            })
        }
    }

    //Verifica se siamo nel range dei -5 +5 giorni dal compleanno
    checkIfIsBirthdayRange =_=>{
        if(!this.props.user) return 0
        var todayNumber = this.getDayInYear(moment());
        var birthdayNumber = this.getDayInYear(moment(this.props.user.data_nascita));
        var giorniDifferenza = todayNumber - birthdayNumber;

        if(giorniDifferenza < 0 && giorniDifferenza >-6){
            return true
        }
        if (giorniDifferenza > 0 && giorniDifferenza < 6){
            return true;
        }
        return giorniDifferenza === 0;
    }


    leapYear = year =>{
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

    runConfetti =_=>{
        this.setState({runConfetti:true})
        setTimeout(() => {
            this.setState({runConfetti:false})
        }, 5000);
    }

    getDayInYear = dateTypeMoment =>{
        var standardDate = dateTypeMoment.set('year', 1970);
        var start = moment("01-01-1970", 'DD-MM-YYYY')
        return  standardDate.diff(start, 'days') + 1;
    }

    //Stampa l'etichetta del compleanno
    printDayBeforeBirthday = _ =>{
        if(!this.props.user) return 0
        var todayNumber = this.getDayInYear(moment());
        //var birthdayNumber = this.getDayInYear(moment(this.props.user.data_nascita));
        var birthdayNumber = this.getDayInYear(moment(this.props.user.data_nascita));
        var giorniDifferenza = todayNumber - birthdayNumber;

        if(giorniDifferenza < 0){
            giorniDifferenza = (giorniDifferenza > 300) ? 365 - giorniDifferenza : giorniDifferenza
            return "Mancano "+Math.abs(giorniDifferenza)+" giorni al tuo compleanno 🥳";
        }
        if (giorniDifferenza > 0){
            giorniDifferenza = (giorniDifferenza < -300) ? 365 + giorniDifferenza : giorniDifferenza
            return "Sono passati " +giorniDifferenza+" giorni dal tuo compleanno 🥳";
        }
        return "🎉 Buon compleanno 🎉";
    }

    birthdayPageOpenToggle =_=>{
        this.setState({birthdayPageOpen: !this.state.birthdayPageOpen})
    }


    handleOpenPromotionsTab=_=>{
        FuxEvents.emit(OPEN_PROMOTIONS_TAB, "promotions")
    }

    render() {
        return (
            <Fragment>
                {this.state.isInBirthdayRange &&
                    <div className={"container mt-3"}>
                        <ReactConfetti
                            numberOfPieces={200}
                            opacity={0.8}
                            gravity={0.1}
                            recycle={this.state.runConfetti}
                        />
                        <div className={"bg-white shadow-sm rounded py-2 text-center"} style={this.cardStyle}>

                            <ContainerForImgConfetti className={"opacizer"}/>

                            <div style={this.textStyle} className={"p-2"}>
                                <div className={"w-100"}>
                                    <BirthdayCakeIllustration className={"img-fluid mx-auto"} style={{width:"35%"}} color={"#293069"}/>
                                </div>
                                <h2><b>Ciao {this.props.user.nome}</b></h2>
                                <h4>{this.printDayBeforeBirthday()}</h4>
                                {/*<button className={"btn btn-link text-blue pb-3"} onClick={this.handleOpenPromotionsTab}>Scopri le offerte a te dedicate! <FaSolidIcon name={"arrow-circle-right"}/></button>*/}
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}
