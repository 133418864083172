import AppConfig from "../../config/AppConfig";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";

export const _RecensioniInfoForLauncher = {

    getRecensioniInSospeso: function (){
        var params = {
            token: AuthAPI.currentUser.accessToken
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/recensioni-info-for-launcher/numero-recensioni-in-sospeso`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

}