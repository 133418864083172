import FuxHTTP from "../lib/FuxFramework/FuxHTTP";
import APIConfig from "./APIConfig";
import AuthAPI from "./AuthAPI";
import {NegozioOpenStore} from "../stores/NegozioOpenStore";

var NegozioAPI = {
    getInfo: function (id_negozio) {
        id_negozio = id_negozio ? id_negozio : NegozioOpenStore.id_negozio;
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/negozio/${id_negozio}`), {token: AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getRichInfo: function (id_negozio) {
        id_negozio = id_negozio ? id_negozio : NegozioOpenStore.id_negozio;
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/negozio/richData/${id_negozio}`), {token: AuthAPI.getUserAccessToken()},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    getInfoByCode: function (codice) {
        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/negozio/withCode/${codice}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },
    search: function (queryString, limit, offset, id_categoria, id_sottocategoria, cancelToken, searchFilters=null) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/negozio/search`), {
                query: queryString,
                limit: limit,
                offset: offset,
                id_categoria: id_categoria,
                id_sottocategoria: id_sottocategoria,
                searchFilters: searchFilters,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE,
            {cancelToken: cancelToken}
        );
    },
    geoSearch: function (queryString, id_categoria, id_sottocategoria, lat, lon, maxDistance, limit, offset, cancelToken, searchFilters=null,) {
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/negozio/geo-search`), {
                query: queryString,
                id_sottocategoria: id_sottocategoria,
                id_categoria: id_categoria,
                lat: lat,
                lon: lon,
                maxDistance: maxDistance,
                limit: limit,
                offset: offset,
                searchFilters: searchFilters,
                token: AuthAPI.getUserAccessToken()
            },
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE,
            {cancelToken: cancelToken}
        );
    }
};

export default NegozioAPI;
