import React from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import FeedPostCard from "./FeedPostCard";
import {FeedContainer, GetMoreLoadingWrapper} from "./FeedStyledComponents";
import GeoFeedAPI from "../../../api/GeoFeedAPI";
import {FeedPostCardHOC} from "./FeedPostCardHOC";
import cityLocation from "../../svg/city-location.svg";
import {BookizonAppManager} from "../../../index";
import directions from "../../../img/illustrations/directions.svg"


export default class FeedGeolocatedContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: false,
            hasFullscreenPost: false,
            hasProfiloAttivita: false,
            cursorBefore: null,
            cursorAfter: null,
            pageSize: 20,
            maxDistance: 50,
            lastScrollTop: 0
        }
        this.lastScrollTop = 0;
        this.feedContainerRef = null;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState.items !== this.state.items ||
            nextState.loading !== this.state.loading ||
            nextState.lastScrollTop !== this.state.lastScrollTop;
    }

    componentDidMount() {
        GeoFeedAPI.getStartPage(this.props.lat, this.props.lng, this.state.maxDistance, this.state.pageSize)
            .then(result => {
                this.setState({
                    items: result.data,
                    cursorBefore: result.cursors.before,
                    cursorAfter: result.cursors.after
                });
            });
    }

    handleContainerRef = node => {
        this.feedContainerRef = node;
        this.props.onScrollPaneRef(node);
    }

    handleFeedScroll = e => {
        if (this.feedContainerRef && e.target !== this.feedContainerRef) return;
        if (this.state.loading || this.state.hasFullscreenPost || this.state.hasProfiloAttivita) return;
        const scrollTop = e.target.scrollTop;
        this.setState({lastScrollTop: scrollTop});

        if (scrollTop <= this.lastScrollTop) { //Se vado verso sopra allora non triggero il getMore
            this.lastScrollTop = scrollTop;
            return;
        }
        this.lastScrollTop = scrollTop;

        const height = e.target.scrollHeight;
        const thresholdOffset = 200;
        if (scrollTop + window.innerHeight + thresholdOffset >= height) {
            return new Promise(this.onGetMore);
        }
    }

    onGetMore = (resolve, reject) => {
        if (!this.state.cursorAfter) {
            return;
        }
        this.setState({loading: true});
        GeoFeedAPI.getPageWithCursor(this.state.cursorAfter, this.props.lat, this.props.lng, this.state.maxDistance, this.state.pageSize)
            .then(result => {
                this.setState({
                    items: [...this.state.items, ...result.data],
                    cursorAfter: result.cursors.after, //Aggiorno solo il cursore della prossima pagina (che è l'unico cambiato)
                    loading: false
                });
                resolve();
            });
    }

    onRefresh = (resolve, reject) => {
        console.log("REFRESH GEO");
        if (!this.state.cursorBefore) {
            resolve();
            return;
        }
        this.setState({loading: true});
        GeoFeedAPI.getPageWithCursor(this.state.cursorBefore, this.props.lat, this.props.lng, this.state.maxDistance, this.state.pageSize)
            .then(result => {
                this.setState({
                    items: [...result.data, ...this.state.items],
                    cursorBefore: result.cursors.before, //Aggiorno solo il cursore della prossima pagina (che è l'unico cambiato)
                    loading: false
                });
                resolve();
            }).catch(reject);
    }

    handlePostFullscreenOpen = _ => this.setState({hasFullscreenPost: true});
    handlePostFullscreenClose = _ => this.setState({hasFullscreenPost: false});

    handleProfiloAttivitaOpen = _ => this.setState({hasProfiloAttivita: true});
    handleProfiloAttivitaClose = _ => this.setState({hasProfiloAttivita: false});

    render() {
        return (
            <FeedContainer ref={this.handleContainerRef} onScroll={this.handleFeedScroll}>
                {
                    this.state.items.length > 0 ?
                        <div>
                        {
                            this.state.items.map((item, i) =>
                                <FeedPostCardHOC scrollTop={this.state.lastScrollTop} key={item.id_post}>
                                    <FeedPostCard
                                        {...item}
                                        onAppOpen={this.props.onAppOpen}
                                        onAppAdd={this.props.onAppAdd}
                                        onFullscreenOpen={this.handlePostFullscreenOpen}
                                        onFullscreenClose={this.handlePostFullscreenClose}
                                        onProfiloAttivitaOpen={this.handleProfiloAttivitaOpen}
                                        onProfiloAttivitaClose={this.handleProfiloAttivitaClose}
                                        scrollTop={this.state.lastScrollTop}
                                    />
                                </FeedPostCardHOC>
                            )
                        }
                        </div>

                    :
                        <div className={"container text-center mt-5"}>
                            <h5 className={"text-center mt-3 text-muted"}>
                                Al momento non c'é nessun post nelle vicinanze :(
                            </h5>
                            <img src={directions} className={"img-fluid w-50 mt-2"} alt={"Nessuna immagine disponibile"}/>
                            <h6 className={"text-center mt-3 text-muted mb-4"}>
                                Bookizon è in grado di recuperare i post degli esercenti più vicini a te, ma
                                al momento non è stato trovato niente da mostrarti
                            </h6>
                        </div>

                }

                {
                    this.state.loading &&
                    <GetMoreLoadingWrapper>
                        <FaSolidIcon name={"spinner fa-pulse text-muted"}/>
                    </GetMoreLoadingWrapper>
                }
            </FeedContainer>
        )
    }

}

FeedGeolocatedContainer.propTypes = {
    lat: PropTypes.number,
    lng: PropTypes.number
}
