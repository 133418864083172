import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const PageZLayerSelector = styled.div`
  & .page {
    z-index: ${props => props.zIndex};
  }
`;
PageZLayerSelector.displayName = 'PageZLayerSelector';

PageZLayerSelector.propTypes = {
    zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}
