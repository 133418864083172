import React, {Fragment} from "react";
import PropTypes from "prop-types";
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../FontAwesomeIcons";
import styled from "styled-components";
import {applyPercentage} from "../../helpers/NumberUtility";

const Modifier = styled.div`
    display:inline;
    & + &:before{
        content:", ";
        display:inline;
    }
`;


export class ProductInfoCheckoutView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getPrezzo = _ => {
        let prezzoBase = applyPercentage(parseFloat(this.props.prezzo), -this.getCurrentDiscountPercentage());
        let prezzoAggiunte = 0;
        if (this.props.aggiunte) {
            this.props.aggiunte.map(id_aggiunta => {
                const datiAggiunta = this.props.listaAggiunte.find(a => a.id_aggiunta === id_aggiunta);
                prezzoAggiunte += parseFloat(datiAggiunta.prezzo);
            });
        }
        let prezzoOpzione = 0;
        if (this.props.id_opzione) {
            const datiOpzione = this.props.listaOpzioni.find(o => o.id_opzione === this.props.id_opzione);
            console.log(parseFloat(datiOpzione.prezzo));
            prezzoOpzione += parseFloat(datiOpzione.prezzo);
        }
        return (prezzoBase + prezzoAggiunte + prezzoOpzione) * this.props.quantita;
    }

    getCurrentDiscountPercentage = _ => this.props.perc_sconto ? parseFloat(this.props.perc_sconto) : 0.0;

    getDiscountLabel = _ => {
        const discount = this.getCurrentDiscountPercentage();
        if (discount){
            return <span className={'badge badge-success'}>Sconto del {discount}%</span>
        }
        return '';
    }

    render() {
        return (
            <Fragment>
                <div className={"row align-items-center"}>
                    <div className={'col-12'}>
                        <b><SafeHtmlContainer html={this.props.nome}/></b> x {this.props.quantita}
                        <span className={"badge badge-primary ml-2"}>
                                    {`€${this.getPrezzo().toFixed(2)}`}
                                </span>
                        <div className={"small text-muted"}><SafeHtmlContainer html={this.props.categoria_prodotto}/>
                        </div>
                        {this.getDiscountLabel()}
                    </div>
                    <div className={"col-12"}>
                        {
                            this.props.id_opzione &&
                            <Modifier>
                                <small>
                                    <FaSolidIcon name={"info-circle"}
                                                 className={"text-info"}/> {this.props.listaOpzioni.find(o => o.id_opzione === this.props.id_opzione).nome}
                                </small>
                            </Modifier>
                        }
                        {
                            this.props.attributi.cottura &&
                            <Modifier>
                                <small>
                                    <FaSolidIcon name={"info-circle"}
                                                 className={"text-info"}/> {this.props.attributi.cottura}
                                </small>
                            </Modifier>
                        }
                        {
                            !!this.props.aggiunte &&
                            this.props.aggiunte.map(id_aggiunta => {
                                const datiAggiunta = this.props.listaAggiunte.find(a => a.id_aggiunta === id_aggiunta);
                                return <Modifier key={id_aggiunta} className={"text-muted"}>
                                    <small>
                                        <FaSolidIcon name={"plus-circle text-success"}/>
                                        {datiAggiunta.nome}
                                    </small>
                                </Modifier>
                            })
                        }
                        {
                            !!this.props.rimozioni &&
                            this.props.rimozioni.map((rimozione, i) => {
                                return <Modifier key={i} className={"text-muted"}>
                                    <small>
                                        <FaSolidIcon name={"minus-circle text-danger"}/> {rimozione}
                                    </small>
                                </Modifier>
                            })
                        }
                    </div>
                </div>
            </Fragment>
        )
    }

}

ProductInfoCheckoutView.propTypes = {
    nome: PropTypes.string.isRequired,
    quantita: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    categoria_prodotto: PropTypes.string.isRequired,
    prezzo: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    perc_sconto: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    listaAggiunte: PropTypes.array.isRequired,
    listaOpzioni: PropTypes.array.isRequired,
    aggiunte: PropTypes.array.isRequired,
    rimozioni: PropTypes.array.isRequired,
    attributi: PropTypes.array.isRequired,
    id_opzione: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
}