import React from "react";
import moment from "moment";
import FuxEvents from "../lib/FuxFramework/FuxEvents";

export const LauncherBirthdayAlert = (props) => {

    var date = moment(props.data_nascita);
    var check = date.isValid();

    return (
        !check && <div className={'container mt-3'}>
            <div className={'alert alert-warning'}>
                Imposta una data di nascita per poter ordinare senza
                limitazioni di età su Bookizon.
                <div className={"text-right"}>
                    <button className={"btn btn-sm btn-warning"} onClick={_ => FuxEvents.emit('OPEN_PROFILE_PAGE')}>
                        Vai al profilo
                    </button>
                </div>
            </div>
        </div>
    );
}
