import React, {Fragment} from "react";
import {FaSolidIcon} from "../../../components/FontAwesomeIcons";
import Page from "../../../components/BaseComponents/Page/Page";
import {swalError, swalLoading, swalSuccess} from "../../../helpers/SweetAlertWrapper";
import RimborsiDipendenteAPI from "../../../api/Welfare/RimborsiDipendenteAPI";

export default class DipendenteWelfareNuovaRichiestaRimborso extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categorie_rimborso: [],
            info_categoria_scelta: false,
            metadati_input: false,
            denominazione_attivita: '',
            importo: '',
            descrizione: '',
            immagini: []
        }

        this.state = {...this.state};

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        console.log("COMPONENTE MONTATO")
        RimborsiDipendenteAPI.getCategorieRimborsi()
            .then(data => {
                this.setState({
                    categorie_rimborso: data,
                    info_categoria_scelta: data[0],
                    metadati_input: data[0].metadati,
                    immagini: ['']
                })
            })
    }

    handleInputChange = async (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        if (name === 'id_categoria') {
            //Trovo l'indice della categoria scelta e lo assegno a info categoria scelta
            var i = this.state.categorie_rimborso.findIndex((categoria) => categoria.id_categoria === value);
            this.setState({
                info_categoria_scelta: this.state.categorie_rimborso[i],
                metadati_input: this.state.categorie_rimborso[i].metadati
            })
        }

        if (name.includes('immagini')) {
            const file = target.files[0];
            const index = target.name.substring(9);

            let immagini = this.state.immagini
            immagini[index] = (await fetch(URL.createObjectURL(file)).then(r => r.blob()))

            this.setState({immagini: immagini});
            console.log(this.state.immagini);

            return;
        }

        if (name === "importo") {
            const regExp = /^\d*(\.\d{0,2})?$/g;
            if (!value.match(regExp)) {
                value = parseFloat(value).toFixed("2");
            }
        }

        this.setState({
            [name]: value,
        });
    }

    handleInputMetadatiChange = event => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        let metadati = this.state.metadati_input;

        var i = metadati.findIndex((metadati) => metadati.label === name);
        metadati[i].value = value

        console.log(metadati)
        this.setState({metadati_input: metadati})
    }


    handleSubmit(event) {
        event.preventDefault();
        swalLoading();
        const formData = new FormData();
        formData.append('denominazione_attivita', this.state.denominazione_attivita);
        formData.append('importo', this.state.importo);
        formData.append('descrizione', this.state.descrizione);
        formData.append('id_categoria', this.state.info_categoria_scelta.id_categoria)
        formData.append('metadati', btoa(JSON.stringify(this.state.metadati_input)))
        this.state.immagini.map((item, i) => {
            formData.append('immagini[]', item);
        });

        RimborsiDipendenteAPI.inviaRichiestaRimborso(this.props.user.walletapikey, formData)
            .then((message) => {
                swalSuccess(message);
                this.setState({
                    denominazione_attivita: '',
                    importo: '',
                    descrizione: '',
                    immagine: ''
                });
                this.props.onDismiss();
            })
            .catch(swalError)
    }

    capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    addNewFIle =_=>{
        let immagini = this.state.immagini
        immagini.push('')
        this.setState({immagini: immagini})
    }

    removeFile = index =>{
        let immagini = this.state.immagini
        immagini.splice(index, 1)
        console.log(index)
        this.setState({immagini: immagini})
    }

    getRefundPermission = id_categoria_rimborso =>{
        return this.props.dipendente.permissions.find(p => p.permission_name === 'dipendenti_rimborsi_categorie_'+id_categoria_rimborso && p.permission_type === 'rw')
    }

    render() {
        return (
            <Fragment>
                <Page
                    title={"Richiedi rimborso"}
                    leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                    onLeftIconClick={this.props.onDismiss}
                    active={this.props.active}
                    content={
                        <form onSubmit={this.handleSubmit}>
                            <div className={"container pt-4 form-group"}>
                                <h5>Inserisci i dati per ottenere il tuo rimborso</h5>
                                <div className={"row"}>
                                    {
                                        this.state.categorie_rimborso.length > 0 &&
                                        <div className={"col-12 mt-4"}>
                                            <label>Categoria rimborso</label>
                                            <select className={"form-control"} name={"id_categoria"}
                                                    onChange={this.handleInputChange}
                                                    value={this.state.info_categoria_scelta.id_categoria}>
                                                {
                                                    this.state.categorie_rimborso.map((c, i) => {
                                                        if(!this.getRefundPermission(c.id_categoria)) return ""
                                                        return <option key={i} value={c.id_categoria}>{c.nome}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                </div>

                                {
                                    this.state.info_categoria_scelta.id_categoria === "1" &&
                                    <div className={"row"}><div className={"col-12 text-danger"}>NB. Solo per persone con figli minori a carico</div></div>
                                }

                                {/* CAMPI OPZIONALI IN BASE A SCELTA CATEGORIA*/}
                                {
                                    this.state.info_categoria_scelta &&
                                    <div className={"row"}>
                                        {
                                            this.state.info_categoria_scelta.scelta_importo === "1" &&
                                            <div
                                                className={"col-12 mt-4"}>
                                                <label>Importo speso in euro</label>
                                                <input className={"form-control"} name="importo" type="number"
                                                       placeholder={"Inserisci importo"} value={this.state.importo}
                                                       onChange={this.handleInputChange}/>
                                                <small className={(this.state.importo >= 0.01 || this.state.importo === '' ? "d-none" : "")}>Importo
                                                    inserito non valido</small>
                                            </div>
                                        }

                                        {
                                            this.state.metadati_input.length > 0 && this.state.metadati_input.map((m, i) => {
                                                return (
                                                    <div key={i} className={"col-12 mt-4"}>
                                                        <label>{this.capitalizeFirstLetter(m.label.replace("_", " "))}</label>
                                                        {
                                                            m.tipo === "select" ?
                                                                <select className={"form-control"} name={m.label} value={m.value || ""} onChange={this.handleInputMetadatiChange} required>
                                                                    <option value={""}>Scegli una opzione</option>
                                                                    {
                                                                        m.possibili_valori.map((v, i) => {
                                                                            return <option key={i} value={v}>{v}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                :
                                                                <input className={"form-control"} type={m.tipo} name={m.label}
                                                                       value={m.value || ""} onChange={this.handleInputMetadatiChange}/>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {/*DEFAULT PER TUTTE LE CATEGORIE*/}
                                <div className={"row"}>
                                    <div className={"col-12 mt-4"}>
                                        <label>Descrizione</label>
                                        <textarea className={"form-control"} name="descrizione"
                                                  placeholder={"Inserisci una descrizione (opzionale)"}
                                                  value={this.state.descrizione}
                                                  onChange={this.handleInputChange}/>
                                    </div>
                                    <div className={"col-12 mt-4"}>
                                        <label>Allega Immagine</label><br/>
                                        <small>Inserisci una foto ben visibile della tua <b>ricevuta fiscale</b>, in
                                            modo da rendere veritiera la tua richiesta di rimborso</small>
                                        {
                                            this.state.immagini.map((img, i) =>{
                                                return <div className={"d-flex justify-content-between mt-2"} key={i}>
                                                    <input className={"form-control w-75"} name={"immagini_"+i} value={img.value} type={"file"} onChange={this.handleInputChange} required/>
                                                    {
                                                        i !== 0 &&
                                                        <button type={"button"} className={"btn btn-danger btn-sm"} onClick={_=>this.removeFile(i)}>Rimuovi</button>
                                                    }
                                                </div>

                                            })
                                        }
                                        <button type={"button"} className={"btn btn-link btn-sm"} onClick={_=>this.addNewFIle()}>+ Aggiungi file</button>
                                    </div>
                                    <div className={"col-12 mt-4 text-center"}>
                                        <button className="btn btn-primary" type="submit">Invia richiesta</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                />
            </Fragment>
        );
    }
}