import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {RelaxationIcon} from "../../../../img/illustrations/RelaxationIcon";
import {ClockIcon} from "../../../../img/illustrations/ClockIcon";
import ModalPortalHOC from "../../../../components/BaseComponents/ModalPortalHOC";
import {OnlineCheckinModal} from "./OnlineCheckin/OnlineCheckinModal";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {OspiteModel} from "./OnlineCheckin/OspiteView";

export class OnlineCheckinView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onlineCheckinModalOpen: false
        }
    }

    shouldAddGuest = _ => {
        if (this.props.bookData.nbambini + this.props.bookData.nadulti > this.props.bookData.ospiti.length) {
            return true;
        }
        return false
    }

    shouldCompleteGuestData = _ => {
        const optionalFields = ['immagine_retro_documento', 'immagine_fronte_documento']
        for (let i in this.props.bookData.ospiti) {
            const ospite = this.props.bookData.ospiti[i];
            for (let key in OspiteModel) {
                if (ospite.hasOwnProperty(key) && !ospite[key] && optionalFields.indexOf(key) === -1) {
                    return true;
                }
            }
        }
        return false;
    }

    shouldInsertDocumentsPicture = _ => {
        for (let i in this.props.bookData.ospiti) {
            const ospite = this.props.bookData.ospiti[i];
            if (ospite.immagine_retro_documento && ospite.immagine_fronte_documento) {
                return false;
            }
        }
        return true;
    }

    toggleOnlineCheckinModal = _ => this.setState({onlineCheckinModalOpen: !this.state.onlineCheckinModalOpen})

    render() {

        return (
            <Fragment>
                {
                    this.shouldAddGuest() || this.shouldCompleteGuestData() ?
                        <div className={"card bg-main-z2 shadow-sm border-0"}>
                            <div className={"card-body"}>
                                <div className={"w-50 mx-auto mb-2"}>
                                    <ClockIcon className={"img-fluid"} color={'#28a745'}/>
                                </div>
                                <h5 className={"font-weight-bold text-success"}>
                                    Risparmia tempo. Fai il check-in online!
                                </h5>
                                <div className={"small text-muted"}>
                                    Inserisci i dati di tutti gli ospiti per velocizzare il check-in non appena
                                    arriverai
                                    alla struttura.
                                </div>
                                <div className={"d-flex align-items-center justify-content-between mt-2"}>
                                    <div className={"small text-muted"}>
                                        Ospiti inseriti {this.props.bookData.ospiti.length} su&nbsp;
                                        {this.props.bookData.nbambini + this.props.bookData.nadulti}
                                        {
                                            this.shouldCompleteGuestData() &&
                                            <div className={"text-danger"}>
                                                <FaSolidIcon name={"exclamation-triangle"}/> Alcuni dati mancanti
                                            </div>
                                        }
                                    </div>
                                    <button className={"btn btn-link"} onClick={this.toggleOnlineCheckinModal}>
                                        Inserisci dati
                                    </button>
                                </div>
                                {
                                    (this.shouldInsertDocumentsPicture() && this.props.bookData.ospiti.length > 0) &&
                                    <div className={"small text-danger"}>
                                        <FaSolidIcon name={"exclamation-triangle"}/> Inserisci le foto del documento di
                                        almeno 1 ospite maggiorenne
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className={"card bg-main-z2 shadow-sm border-0"}>
                            <div className={"card-body"}>
                                <div className={"mx-auto mb-2"} style={{maxWidth: "35%"}}>
                                    <RelaxationIcon className={"img-fluid"} color={'#ffcd2a'}/>
                                </div>
                                <h5 className={"font-weight-bold text-success"}>
                                    Fantastico! Tutto pronto per il check-in.
                                </h5>
                                <div className={"small text-muted"}>
                                    Hai compilato tutti i dati degli ospiti per il checkin online! Ora rilassati e
                                    preparti per il tuo soggiorno in struttura!
                                </div>
                                <div className={"text-right"}>
                                    <button className={"btn btn-link"} onClick={this.toggleOnlineCheckinModal}>
                                        Modifica gli ospiti
                                    </button>
                                </div>
                            </div>
                        </div>
                }
                <ModalPortalHOC>
                    {
                        this.state.onlineCheckinModalOpen &&
                        <OnlineCheckinModal
                            onDismiss={this.toggleOnlineCheckinModal}
                            ospiti={this.props.bookData.ospiti}
                            idPrenotazione={this.props.bookData.id_prenotazione}
                            maxOspiti={this.props.bookData.nadulti + this.props.bookData.nbambini}
                        />
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }

}

OnlineCheckinView.propTypes = {
    bookData: PropTypes.shape({
        data_checkin: PropTypes.string,
        data_checkout: PropTypes.string,
        nadulti: PropTypes.number,
        nbambini: PropTypes.number,
        ospiti: PropTypes.array
    }).isRequired,
    infoNegozio: PropTypes.object
}