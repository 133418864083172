import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {TYPE_WALLET_MOD_ECOMMERCE, TYPE_WALLET_NEGOZIO, TYPE_WALLET_WELFARE} from "./WalletsConstants";
import {FaSolidIcon} from "../../FontAwesomeIcons";


export default class WalletsListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.btnMaxImportoStyle = {
            borderRadius: "0 !important",
        }
    }

    //Dato il tipo del wallet restituisce il suo nome
    getWalletTitle = type => {
        switch (type) {
            case  TYPE_WALLET_WELFARE:
                return "Il tuo welfare"
            default:
                if(this.props.wallet.name) return this.props.wallet.name.toUpperCase()
                return type.replaceAll("_", " ").toUpperCase()
        }
    }

    render() {

        let w = this.props.wallet

        return (

            <div className={"list-group-item border-0 pb-0"} key={this.props.index}>

                <div className={"d-flex align-items-center justify-content-between"}
                     onClick={_ => this.props.onToggleButtonClick(this.props.index)}>
                    <div>
                        <small className={"text-muted"}>Paga con</small>
                        <h6 className={"font-weigh-bold text-primary mr-2 my-0"}> {this.getWalletTitle(w.type)}
                            <FaSolidIcon name={"info-circle"}/></h6>
                        <small className={"text-muted"}>Saldo disponibile €{w.saldo.toFixed(2)}</small>
                    </div>
                    {w.opened ? <FaSolidIcon name={"caret-down"}/> : <FaSolidIcon name={"caret-right"}/>}
                </div>

                <div
                    className={"row mt-2 mb-0 py-0 " + (w.opened ? "" : "d-none")}>
                    <div className={"col-9 my-0 py-0"}>
                        <input
                            name={w.id_wallet}
                            value={w.importo_usato}
                            type={"number"}
                            placeholder={"Inserisci importo"}
                            onChange={this.props.onImportoChange}
                            className={"form-control mb-0"}
                        />
                    </div>
                    <div className={"col"}>
                        <button className={"btn btn-outline-primary"} type={"button"}
                                onClick={_ => this.props.onSetMaxImport(w.id_wallet, w.max_payable.toFixed(2))}>Max
                        </button>
                    </div>
                </div>

                {
                    w.importo_usato > w.max_payable &&
                    <small className={"text-warning ml-2"}>
                        Con questo portafogli puoi pagare al massimo
                        €{w.max_payable.toFixed(2)}
                    </small>
                }

                <div className={"mb-2"}/>
            </div>

        );
    }
}

WalletsListView.propTypes = {
    index: PropTypes.number,
    wallet: PropTypes.object,
    onSetMaxImport: PropTypes.func,
    onImportoChange: PropTypes.func,
    onToggleButtonClick: PropTypes.func
}