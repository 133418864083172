import AppConfig from "../../config/AppConfig";
import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";


export const _Credentials = {

    changeEmail: function (newEmail, password){
        let params = {
            token: AuthAPI.currentUser.accessToken,
            password: password,
            newEmail: newEmail
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/user/credentials/change-email`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    validateOTPForChangeEmail: function (newEmail, otp) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            newEmail: newEmail,
            otp: otp
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/user/credentials/change-email/validateOTP`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },

    resendOTPForChangeEmail: function (newEmail) {
        let params = {
            token: AuthAPI.currentUser.accessToken,
            newEmail: newEmail
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/user/credentials/change-email/resendOTP`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        )
    },

    changePassword: function (currentPassword, newPassword){
        let params = {
            token: AuthAPI.currentUser.accessToken,
            currentPassword: currentPassword,
            newPassword: newPassword
        }
        return FuxHTTP.apiPostRequestWithPromise(
            APIConfig.url(`/user/credentials/change-password`), params,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },
};