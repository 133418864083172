import React, {Fragment} from "react";
import {FaRegularIcon, FaSolidIcon} from "../components/FontAwesomeIcons";
import styled from "styled-components";
import {ReactComponent as BookizonIcon} from './svg/logo-icon.svg';
import i18n from "../i18n";
import AuthAPI from "../api/AuthAPI";
import {ChatTabSharedState} from "./Tabs/ChatTab/ChatTab";

const LauncherNavbarNav = styled.div`
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
    overflow:hidden;
    box-shadow: 0 -0.125rem .5rem rgba(0,0,0,.15)!important;
`;

const NavbarItem = styled.div`
    color: #a9bcc9;
    
    &.active{
        color:#293069;
    }
    
    &.disabled{
        color:#dadada;
    }
    
    i{
        font-size:1.1em;
    }
    
    & small{
        display:block;
        font-size:0.7em;
    }
`;

const BookizonIconWrapper = styled.span`
    & svg{
        height:35px;
        vertical-align:text-bottom;
    }
    
    & .cls-2{
        fill: #a9bcc9;
    }
    
    .active & .cls-2{
        fill: #293069;
    }
`;

export default class LauncherNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.editModeStyle = {
            filter: "brightness(0.5)"
        }
        this.state = {
            totalUnreadChatMessages: 0
        }
        this._setState = this.setState.bind(this);
    }

    componentDidMount() {
        ChatTabSharedState.subscribe(this._setState);
    }

    componentWillUnmount() {
        ChatTabSharedState.unsubscribe(this._setState);
    }

    canUseChat = _ => {
        const enabledEmails = ['mfusillo98@gmail.com', 'sasaamoruso@gmail.com', 'donato.filomena@gmail.com'];
        if (AuthAPI.currentUser) {
            return !!enabledEmails.find(e => e === AuthAPI.currentUser.email);
        }
        return false;
    }

    isTabDisabled = tabName => {
        return !this.props.user && (['homepage', 'feed','marketplace','promotions'].indexOf(tabName) === -1)
    }

    handleTabChange = tabName => _ => {
        if(!this.isTabDisabled(tabName)) this.props.onTabChange(tabName);
    }

    render() {
        return (
            <LauncherNavbarNav className={"bg-white fixed-bottom"}>
                <div id={"launcherNavbar"} className={"row mx-0 align-items-center"}>
                    {
                        !this.props.editMode ?
                            <Fragment>
                                <NavbarItem
                                    className={`col px-0 py-2 text-center ${this.isTabDisabled('chat') ? 'disabled' : ''} ${this.props.activeTab === 'chat' ? 'active' : ''}`}
                                    onClick={this.handleTabChange('chat')}>
                                    <span
                                        data-badge={this.state.totalUnreadChatMessages > 0 ? this.state.totalUnreadChatMessages : undefined}
                                        className={"fa-badge-red"}
                                    >
                                        &#x1F4AC;
                                    </span>
                                    <small>Chat</small>
                                </NavbarItem>

                                <NavbarItem
                                    className={`col px-0 py-2 text-center ${this.isTabDisabled('notifiche') ? 'disabled' : ''} ${this.props.activeTab === 'notifiche' ? 'active' : ''}`}
                                    onClick={this.handleTabChange('notifiche')}>
                                    <span
                                        data-badge={this.props.unreadNotifications > 0 ? this.props.unreadNotifications : undefined}
                                        className={"fa-badge-red"}
                                    >
                                        &#x1F514;
                                    </span>
                                    <small>{i18n.t("launcher.navbar.notifiche")}</small>
                                </NavbarItem>

                                <NavbarItem
                                    className={`col px-0 py-2 text-center  ${this.isTabDisabled('homepage') ? 'disabled' : ''} ${this.props.activeTab === 'homepage' ? 'active' : ''}`}
                                    onClick={this.handleTabChange('homepage')}>
                                    <BookizonIconWrapper>
                                        <BookizonIcon/>
                                    </BookizonIconWrapper>
                                </NavbarItem>

                                <NavbarItem
                                    className={`col px-0 py-2 text-center  ${this.isTabDisabled('feed') ? 'disabled' : ''} ${this.props.activeTab === 'feed' ? 'active' : ''}`}
                                    onClick={this.handleTabChange('feed')}>
                                    &#x1F4E2;
                                    <small>{i18n.t("launcher.navbar.feed")}</small>
                                </NavbarItem>

                                {/*TAB PROMOZIONI NASCOSTA*/}
                                {/*<NavbarItem
                                    className={`col px-0 py-2 text-center  ${this.isTabDisabled('promotions') ? 'disabled' : ''} ${this.props.activeTab === 'promotions' ? 'active' : ''}`}
                                    onClick={this.handleTabChange('promotions')}>
                                    &#x1F381;
                                    <small>{i18n.t("launcher.navbar.promotions")}</small>
                                </NavbarItem>*/}

                                {<NavbarItem
                                    className={`col px-0 py-2 text-center  ${this.isTabDisabled('marketplace') ? 'disabled' : ''} ${this.props.activeTab === 'marketplace' ? 'active' : ''}`}
                                    onClick={this.handleTabChange('marketplace')}>
                                    <FaSolidIcon>
                                        &#x1F6D2;
                                    </FaSolidIcon>
                                    <small>{i18n.t("launcher.navbar.marketplace")}</small>
                                </NavbarItem>}


                            </Fragment>
                            :
                            <NavbarItem className={"col px-0 py-3 text-center text-success"}
                                        onClick={this.props.onEditClick}>
                                <FaSolidIcon name={"check"}/> {i18n.t("launcher.navbar.salva-modifiche")}
                            </NavbarItem>
                    }
                </div>
            </LauncherNavbarNav>
        )
    }

}
