import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaRegularIcon, FaSolidIcon} from "../../FontAwesomeIcons";
import ModalPortalHOC from "../../BaseComponents/ModalPortalHOC";
import {SwipeablePanel} from "../../BaseComponents/SwipeablePanel/SwipeablePanel";
import styled from "styled-components";
import {SwipeablePanelGripBar} from "../../BaseComponents/SwipeablePanel/SwipeablePanelGripBar";

export class ServiziRecommenderItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false
        }
    }

    toggleDetail = _ => this.setState({detailOpen: !this.state.detailOpen})

    handleRemove = _ => this.props.onRemove(this.props.servizio.id_servizio);

    handleAdd = _ => this.props.onAdd(this.props.servizio.id_servizio);

    render() {
        const servizio = this.props.servizio;

        const prezzoBase = parseFloat(servizio.prezzo);
        const percSconto = parseFloat(servizio.perc_sconto)
        const prezzoScontato = prezzoBase - (prezzoBase * percSconto / 100);

        return (
            <Fragment>
                <div className={"list-group-item bg-main-z3 text-on-bg-main"}>
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <div>
                            {servizio.nome}
                            <div>
                                <button className={"btn btn-sm btn-link px-0"} onClick={this.toggleDetail}>
                                    Vedi dettagli
                                </button>
                            </div>
                            <div>
                                {
                                    this.props.showPrice &&
                                    <Fragment>
                            <span className={"badge badge-primary text-on-bg-primary mr-1"}>
                                 {`€${prezzoScontato.toFixed(2)}`}
                            </span>
                                        {
                                            !!percSconto &&
                                            <Fragment>
                                                <small className={"mr-1 text-muted text-line-through"}>
                                                    {`€${prezzoBase.toFixed(2)}`}
                                                </small>
                                                <span className={"badge badge-success mr-1"}>
                                        Sconto {percSconto}%
                                    </span>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                            </div>
                        </div>
                        {
                            this.props.selected ?
                                <button className={"btn btn-link btn-sm text-primary float-right text-nowrap"}
                                        onClick={this.handleRemove}>
                                    <FaSolidIcon name={"check-square"}/> Rimuovi
                                </button>
                                :
                                <button className={"btn btn-link btn-sm text-muted float-right text-nowrap"}
                                        onClick={this.handleAdd}>
                                    <FaRegularIcon name={"check-square"}/> Aggiungi
                                </button>
                        }
                    </div>
                </div>
                <ModalPortalHOC>
                    {this.state.detailOpen &&
                    <div style={BackdropStyle} className={"__androidDismissable__"} onClick={this.toggleDetail}/>}
                    {
                        this.state.detailOpen &&
                        <SwipeablePanel
                            swipeTreshold={5}
                            direction={'top-to-bottom'}
                            active={true}
                            onDismiss={this.toggleDetail}
                            disableSwipe={_ => false}
                            animationSpeed={150}
                        >
                            <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                            <div className={"bg-main-z2"}>
                                <DetailImage src={servizio.itemPickerData.image}/>
                                <div className={"container mt-3 pb-4"}>
                                    <h5 className={"font-weight-bold"}>{servizio.itemPickerData.text}</h5>
                                    <div>{servizio.itemPickerData.subtitle}</div>
                                    {servizio.itemPickerData.description}
                                </div>
                            </div>
                        </SwipeablePanel>
                    }
                </ModalPortalHOC>
            </Fragment>
        )
    }
}


ServiziRecommenderItem.propTypes = {
    servizio: PropTypes.object,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    selected: PropTypes.bool,
    showPrice: PropTypes.bool
}

const BackdropStyle = {
    position: "fixed",
    top: 0, left: 0, height: "100vh", width: "100vw",
    zIndex: 2000, backgroundColor: "rgba(0,0,0,0.7)"
}


const DetailImage = styled.div`
    width:100%;
    height:40vh;
    background-size:cover;
    background-position:center center;
    background-image:url(${props => props.src});
`;