import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AppConfig from "../../config/AppConfig";
import {NegozioOpenStore} from "../../stores/NegozioOpenStore";
import AuthAPI from "../AuthAPI";
import APIWelfareConfig from "./APIWelfareConfig";
import APIConfig from "../APIConfig";

var RimborsiDipendenteAPI = {


    inviaRichiestaRimborso: function(walletApiKey, formData){

        formData.append("walletapikey",walletApiKey);

        return FuxHTTP.apiPostRequestWithPromise(
            APIWelfareConfig.url(`/welfare/dipendenti/richieste-rimborso/invia`), formData,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },


    modificaRichiestaRimborso: function(walletApiKey, formData){

        formData.append("walletapikey",walletApiKey);

        return FuxHTTP.apiPostRequestWithPromise(
            APIWelfareConfig.url(`/welfare/dipendenti/richieste-rimborso/modifica`), formData,
            FuxHTTP.RESOLVE_MESSAGE,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getCategorieRimborsi: function (){
        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/welfare/dipendente/richieste-rimborso/categorie`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getRichiesteRimborso: function (walletApiKey, limit, offset){
        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/welfare/dipendente/${walletApiKey}/richieste-rimborso/${limit}/${offset}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getImmaginiRichiesta: function (walletApiKey, id_rimborso){

        return FuxHTTP.apiGetRequestWithPromise(
            APIWelfareConfig.url(`/welfare/dipendente/${walletApiKey}/immagini/richieste-rimborso/${id_rimborso}`), {},
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }

};

export default RimborsiDipendenteAPI;