import FuxHTTP from "../../lib/FuxFramework/FuxHTTP";
import AuthAPI from "./../AuthAPI";
import APIConfig from "./../APIConfig";

export const _PromotionsWithGeolocation = {

    getPromotionsValideInDate: function (date, limit, offset, sortType=null, lat=null, lng=null, maxDistance=null){
        var params = {
            token: AuthAPI.currentUser.accessToken,
            date: date,
            limit: limit,
            offset: offset,
            sort_type: sortType,

            lat: lat,
            lng: lng,
            maxDistance: maxDistance
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/promotions/with-geolocation/valide-in-date`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    },

    getPromotionsInGeoHash: function (limit, offset, sortType=null, lat=null, lng=null, maxDistance=null){
        var params = {
            token: AuthAPI.currentUser.accessToken,
            limit: limit,
            offset: offset,
            sort_type: sortType,

            lat: lat,
            lng: lng,
            maxDistance: maxDistance
        }

        return FuxHTTP.apiGetRequestWithPromise(
            APIConfig.url(`/promotions/with-geolocation/with-geoash`), params,
            FuxHTTP.RESOLVE_DATA,
            FuxHTTP.REJECT_MESSAGE
        );
    }

}
