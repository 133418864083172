import React, {Fragment} from "react";
import {SwipeablePanel} from "../../components/BaseComponents/SwipeablePanel/SwipeablePanel";
import {SwipeablePanelGripBar} from "../../components/BaseComponents/SwipeablePanel/SwipeablePanelGripBar";
import {FaSolidIcon} from "../../components/FontAwesomeIcons";
import PropTypes from "prop-types";
import {SwipeablePanelBackdrop} from "../../components/BaseComponents/SwipeablePanel/SwipeablePanelBackdrop";
import makeSearchImage from "../svg/make-search.svg";
import {RedeemOperationItem} from "./RedeemOperationItem";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../helpers/SweetAlertWrapper";
import {UserAPI} from "../../api/User/UserAPI";
import FuxEvents from "../../lib/FuxFramework/FuxEvents";
import {
    UPDATE_USER_BOOKS,
    UPDATE_USER_HOTEL_BOOKS,
    UPDATE_USER_ORDINI,
    UPDATE_USER_SEAT_BOOKS
} from "../../const/AppEventsCostants";

export default class LauncherOperationsRedeemController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: null,
            importedOperations: [],
        }
        this.scrollableContainerRef = null;
        this.scrollableContainerStyle = {
            maxHeight: "70vh",
            overflowY: "auto"
        };
    }

    componentDidMount() {
        this.checkOpening();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user?.redeemable_operations !== prevProps.user?.redeemable_operations && this.state.isOpen == null) {
            this.checkOpening();
        }
    }

    checkOpening = _ => {
        if (Object.keys(this.props.user?.redeemable_operations || {}).length > 0) this.setState({isOpen: true});
    }

    handleDismiss = _ => this.setState({isOpen: false});

    isSwipeDisabled = _ => {
        if (this.scrollableContainerRef) {
            return this.scrollableContainerRef.scrollTop > 0;
        }
        return false;
    }

    handleScrollableContainerRef = node => {
        if (node) this.scrollableContainerRef = node;
    }

    handleImportAdd = (id_operazione, tipo_operazione) => {
        const ops = [...this.state.importedOperations];
        ops.push({
            id_operazione: id_operazione,
            tipo_operazione: tipo_operazione
        });
        this.setState({importedOperations: ops});
    }

    handleImportRemove = (id_operazione, tipo_operazione) => {
        this.setState({importedOperations: this.state.importedOperations.filter(iop => iop.id_operazione !== id_operazione || iop.tipo_operazione !== tipo_operazione)});
    }

    handleConfirm = _ => {
        swalConfirm('Vuoi davvero importare le prenotazioni e ordini scelti nel tuo account?')
            .then(_ => {
                swalLoading('Attendere prego...');
                UserAPI.Operations.importGuestOperations(this.state.importedOperations)
                    .then(m => {
                        this.props.onSuccess();
                        swalSuccess(m);
                        this.handleDismiss();
                    })
                    .catch(swalError);
            })
    }

    render() {

        const redeemable_operations = this.props.user?.redeemable_operations || {};

        return (
            <Fragment>
                {
                    this.state.isOpen &&
                    <SwipeablePanelBackdrop className={"__androidDismissable__"} onClick={this.handleDismiss}/>
                }
                <SwipeablePanel
                    swipeTreshold={5}
                    direction={'top-to-bottom'}
                    active={this.state.isOpen}
                    onDismiss={this.handleDismiss}
                    disableSwipe={this.isSwipeDisabled}
                    animationSpeed={150}
                >
                    <SwipeablePanelGripBar className={"bg-main-z2 rounded-top"}/>
                    <div className={"bg-main-z2 p-4"}>
                        <div ref={this.handleScrollableContainerRef} style={this.scrollableContainerStyle}>
                            <div className={"text-center mx-auto mb-3"}>
                                <img src={makeSearchImage} className={"w-50"}/>
                            </div>
                            <h5 className={"font-weight-bold text-center text-primary mb-3"}>
                                Abbiamo trovato delle prenotazioni che potrebbero essere tue!
                            </h5>
                            <div className={"list-group"}>
                                {
                                    Object.keys(redeemable_operations).map(operationType => {
                                        return redeemable_operations[operationType].map((op, i) => {
                                            const isImported = this.state.importedOperations.find(iop => iop.id_operazione === op.id_operazione && iop.tipo_operazione === operationType);
                                            return <div
                                                className={`list-group-item ${isImported && 'bg-soft-success'} px-2`}
                                                key={`${operationType}-${i}`}>
                                                <RedeemOperationItem
                                                    operation={op}
                                                    operationType={operationType}
                                                    isImported={isImported}
                                                    onImportAdd={this.handleImportAdd}
                                                    onImportRemove={this.handleImportRemove}
                                                />
                                            </div>
                                        })
                                    })
                                }
                            </div>
                        </div>
                        <div className={"d-flex align-items-center justify-content-between mt-3"}>
                            <button className={"btn btn-link text-muted"} onClick={this.handleDismiss}>
                                <FaSolidIcon name={"times"}/> Chiudi
                            </button>
                            <button className={"btn btn-primary "} onClick={this.handleConfirm}
                                    disabled={this.state.importedOperations.length === 0}>
                                <FaSolidIcon name={"check"}/> Conferma
                            </button>
                        </div>
                    </div>
                </SwipeablePanel>
            </Fragment>
        )
    }

}


LauncherOperationsRedeemController.propTypes = {
    user: PropTypes.shape({
        id_utente: PropTypes.any,
        redeemable_operations: PropTypes.objectOf(PropTypes.array)
    }),
    onSuccess: PropTypes.func.isRequired,
}
