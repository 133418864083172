import React, {Fragment} from 'react';
import styled from 'styled-components';
import NovitaUsersAPI from "../../api/NovitaUsersAPI"
import SafeHtmlContainer from "../../helpers/SafeHtmlContainer";
import AppConfig from "../../config/AppConfig";
import {getCompressedImageUrl} from "../../helpers/ImageUtility";
import {FuxCarousel} from "../../components/BaseComponents/FuxCarousel/FuxCarousel";
import BodyCardNews from "./BodyCardNews";
import {NewsCarousel} from "./NewsCarousel";

const ContenitoreNews = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1532;
`;

const CardNews = styled.div`
    position: relative;
    height: 100vh;
    width: 100vw;
    z-index: 1532;
    background-image: url(${props => props.backgroundImg});
    background-color: ${props => props.backgroundColor}; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const FooterCardNews = styled.div`
    text-align: center;
    height: 10vh;
    position: absolute;
    width: 100vw;
    padding-top: 5%;
    bottom: 0;
    background-color: transparent;
`

const ContainerNewsCarousel = styled.div`
    & .indicators-container{
        width:100vw;
        margin-top: 10%;
        text-align: center;
        position: fixed;
        z-index: 1550;
        background-color: transparent;
    }
    
    
`


export default class PopUpNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
        }
        this.myRef = null;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.news !== nextState.news;
    }

    componentDidMount() {
        if (this.props.fromSideMenu) {
            NovitaUsersAPI.getNews()
                .then(novita => this.setState({news: novita}));
        } else {
            NovitaUsersAPI.getUserNews()
                .then(novita => this.setState({news: novita}));
        }
    }


    handleAutoScrollCard = _ => {
        if(this.myRef){
            this.myRef.querySelector(".items-container").scrollLeft += window.innerWidth;
        }
    }


    /*Richiamato all'onClick del "ContainerFuxCarousel", altrimenti il click si
    propaga fino al backdrop che chiude le news*/
    stopPropagation = function (e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    closePopUpNews = _ => {
        console.log("Chiudi news")
        document.body.classList.remove("noscroll");
        if (this.props.fromSideMenu) {
            this.setState({news: []});
            this.props.onDismiss();
        } else {
            NovitaUsersAPI.setSeenNews().then(response => {
                console.log(response)
            });
            this.setState({news: []})
        }
    }


    getRef = (node) => {
        this.myRef = node;
    }

    render() {
        if (this.state.news.length){
            document.body.classList.add("noscroll");
        }
        return (
            <div ref={this.getRef}>
                <div className={(this.state.news.length > 0 ? '' : 'd-none')}>
                    <ContenitoreNews>
                        <ContainerNewsCarousel onClick={this.stopPropagation}>
                            <NewsCarousel
                                items={
                                    this.state.news
                                        .map((row, i) =>
                                            <CardNews
                                                backgroundColor={row.colore_sfondo}
                                                backgroundImg={`${AppConfig.webServerUrl}/assets/AppBreakingNews/background/${row.id_novita}/${row.immagine_background}`}
                                                >
                                                {/*imposto il riempimento del body della card*/}
                                                <BodyCardNews news={row}/>
                                                <FooterCardNews className={"container"}>
                                                    <div className={"row align-items-center"}>
                                                        <div className={"col-4"}>
                                                        </div>
                                                        <div className={"col-4"}>
                                                            {i === this.state.news.length - 1
                                                                ?
                                                                <button
                                                                    onClick={this.closePopUpNews}
                                                                    className={"btn btn-primary"}>
                                                                    Chiudi
                                                                </button>
                                                                :
                                                                <button
                                                                    onClick={this.handleAutoScrollCard}
                                                                    className={"btn btn-primary"}>
                                                                    Avanti
                                                                </button>
                                                            }
                                                        </div>
                                                        <div className={"col-4"}>
                                                            <button
                                                                onClick={this.closePopUpNews}
                                                                className={"btn btn-link align-items-centern text-muted"}>
                                                                Salta
                                                            </button>
                                                        </div>
                                                    </div>
                                                </FooterCardNews>
                                            </CardNews>
                                        )
                                }
                            />
                        </ContainerNewsCarousel>
                    </ContenitoreNews>
                </div>

            </div>
        );
    }

}
