import React from "react";
import styled from 'styled-components';
import {applyPercentage} from "../../../../helpers/NumberUtility";
import SafeHtmlContainer from "../../../../helpers/SafeHtmlContainer";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import {
    COMANDE_PRIMA_USCITA, COMANDE_QUARTA_USCITA,
    COMANDE_SECONDA_USCITA, COMANDE_TERZA_USCITA,
    COMANDE_UNICA_USCITA
} from "../../../../const/ModuloComandeConstants";


const Modifier = styled.div`
    display:inline;
    & + &:before{
        content:", ";
        display:inline;
    }
`;


export default class ComandeProductCartItem extends React.Component {

    constructor(props) {
        super(props);
    }

    handleValueChange = e => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleIncreaseQnt = _ => {
        const qnt = this.props.quantita + 1;
        this.setState({quantita: this.props.quantita + 1})
        this.props.onUpdate({prodotto: this.props, quantita: qnt});
    }

    handleDecreaseQnt = _ => {
        const qnt = Math.max(0, this.props.quantita - 1);
        this.setState({quantita: qnt});
        this.props.onUpdate({prodotto: this.props, quantita: qnt});
    }

    handleDelete = _ => {
        this.props.onUpdate({prodotto: this.props, quantita: -1});
    }

    handleUscitaChange = e => {
        this.props.onUpdate({prodotto: this.props, quantita: this.props.quantita, uscita: e.target.value});
    }

    getCurrentDiscountPercentage = _ => this.props.perc_sconto ? parseFloat(this.props.perc_sconto) : 0.0;

    getDiscountLabel = _ => {
        const discount = this.getCurrentDiscountPercentage();
        if (discount) {
            return <span className={'badge badge-success'}>Sconto del {discount}%</span>
        }
        return '';
    }

    getPrezzo = _ => {
        let prezzoBase = applyPercentage(parseFloat(this.props.prezzo), -this.getCurrentDiscountPercentage());
        let prezzoAggiunte = 0;
        if (this.props.aggiunte) {
            this.props.aggiunte.map(id_aggiunta => {
                const datiAggiunta = this.props.listaAggiunte.find(a => a.id_aggiunta === id_aggiunta);
                prezzoAggiunte += parseFloat(datiAggiunta.prezzo);
            });
        }
        let prezzoOpzione = 0;
        if (this.props.id_opzione) {
            const datiOpzione = this.props.listaOpzioni.find(o => o.id_opzione === this.props.id_opzione);
            console.log(parseFloat(datiOpzione.prezzo));
            prezzoOpzione += parseFloat(datiOpzione.prezzo);
        }
        return (prezzoBase + prezzoAggiunte + prezzoOpzione) * this.props.quantita;
    }

    canEditProduct = _ => {
        try {
            return !!(
                parseInt(this.props.abilitaNote) ||
                parseInt(this.props.abilitaRimozioni) ||
                parseInt(this.props.attributi.length) ||
                this.props.listaAggiunte.length ||
                this.props.listaOpzioni.length
            )
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    render() {
        return (
            <div>
                <div className={"list-group-item bg-main-z2 px-0"}>
                    <div className={"container-fluid"}>
                        <div className={"row align-items-center"}>
                            <div className={'col-12 px-1'}>
                                <b><SafeHtmlContainer html={this.props.nome}/></b> x {this.props.quantita}
                                <span className={"badge badge-primary ml-2"}>
                                    {`€${this.getPrezzo().toFixed(2)}`}
                                </span>
                                <div className={"small text-muted"}><SafeHtmlContainer
                                    html={this.props.categoria_prodotto}/></div>
                                {this.getDiscountLabel()}
                            </div>
                            <div className={"col-12"}>
                                {
                                    this.props.id_opzione &&
                                    <Modifier>
                                        <small>
                                            <FaSolidIcon name={"info-circle"}
                                                         className={"text-info"}/> {this.props.listaOpzioni.find(o => o.id_opzione === this.props.id_opzione).nome}
                                        </small>
                                    </Modifier>
                                }
                                {
                                    this.props.attributi.cottura &&
                                    <Modifier>
                                        <small>
                                            <FaSolidIcon name={"info-circle"}
                                                         className={"text-info"}/> {this.props.attributi.cottura}
                                        </small>
                                    </Modifier>
                                }
                                {
                                    !!this.props.aggiunte &&
                                    this.props.aggiunte.map(id_aggiunta => {
                                        const datiAggiunta = this.props.listaAggiunte.find(a => a.id_aggiunta === id_aggiunta);
                                        return <Modifier key={id_aggiunta} className={"text-muted"}>
                                            <small>
                                                <FaSolidIcon name={"plus-circle text-success"}/>
                                                {datiAggiunta.nome}
                                            </small>
                                        </Modifier>
                                    })
                                }
                                {
                                    !!this.props.rimozioni &&
                                    this.props.rimozioni.map((rimozione, i) => {
                                        return <Modifier key={i} className={"text-muted"}>
                                            <small>
                                                <FaSolidIcon name={"minus-circle text-danger"}/> {rimozione}
                                            </small>
                                        </Modifier>
                                    })
                                }
                            </div>
                            {
                                this.props.note ?
                                    <div className={"col-12"}>
                                        <small>
                                            <FaSolidIcon name={"file-alt"}/> {
                                            this.props.note.split('\n').map(
                                                line => <span>{line}<br/></span>
                                            )
                                        }
                                        </small>
                                    </div> : ''
                            }

                            {
                                /* Barra quantità */
                                <div className={"col-12 mt-2"}>
                                    <div className={"d-flex align-items-center"}>
                                        <button className={"btn btn-secondary btn-sm mr-2"}
                                                onClick={this.handleDecreaseQnt}>
                                            <FaSolidIcon name={"minus"}/>
                                        </button>
                                        <div className={"px-2"}>
                                            {this.props.quantita}
                                        </div>
                                        <button className={"btn btn-secondary btn-sm mx-2"}
                                                onClick={this.handleIncreaseQnt}>
                                            <FaSolidIcon name={"plus"}/>
                                        </button>

                                        <button className={"btn btn-danger btn-sm mr-2"}
                                                onClick={this.handleDelete}>
                                            <FaSolidIcon name={"trash"}/>
                                        </button>

                                        {
                                            this.canEditProduct() &&
                                            <button className={"btn btn-primary btn-sm mr-2"}
                                                    onClick={_ => this.props.onEdit(this.props)}>
                                                <FaSolidIcon name={"edit"}/>
                                            </button>
                                        }
                                        <select className={"form-control"} value={this.props.uscita} name={"uscita"} onChange={this.handleUscitaChange}>
                                            <option value={COMANDE_UNICA_USCITA}>Unica U.</option>
                                            <option value={COMANDE_PRIMA_USCITA}>Prima U.</option>
                                            <option value={COMANDE_SECONDA_USCITA}>Seconda U.</option>
                                            <option value={COMANDE_TERZA_USCITA}>Terza U.</option>
                                            <option value={COMANDE_QUARTA_USCITA}>Quarta U.</option>
                                        </select>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
