import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {PacchettiAPI} from "../../../api/Pacchetti/PacchettiAPI";
import {PacchettoListaMovimentiItem} from "./PacchettoListaMovimentiItem";
import {PlaceholderBlock} from "../../../helpers/Placeholders";
import Pagination from "../../BaseComponents/Pagination/Pagination";

const PAGE_SIZE = 10;

export class PacchettoListaMovimenti extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            movimenti: [],
            total: 0,
            fetching: true,
            currentPage: 1,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.qrCodeUrl !== this.props.qrCodeUrl) {
            this.setState({fetching: true, currentPage: 1}, this.fetchData);
        }
    }

    fetchData = _ => {
        PacchettiAPI.User.getMovimenti(this.props.idPacchetto, PAGE_SIZE, (this.state.currentPage - 1) * PAGE_SIZE)
            .then(({rows, total}) => {
                this.setState({
                    fetching: false,
                    movimenti: rows,
                    total: total
                })
            });
    }

    handlePageChange = page => this.setState({currentPage: page, fetching: true}, this.fetchData)

    render() {
        return (
            <Fragment>
                {
                    this.state.fetching &&
                    <Fragment>
                        {/* Stampo almeno 1 placeholder oppure tanti quanti ne stavano prima del fetch dei dati */}
                        {
                            [...new Array(Math.max(1, this.state.movimenti.length))]
                                .map(_ => <PlaceholderBlock className={"shadow-sm rounded-xl mb-2"} height={56}/>)
                        }
                    </Fragment>
                }
                {
                    !this.state.fetching &&
                    this.state.movimenti.map(m => <PacchettoListaMovimentiItem key={m.id_movimento} movimento={m}/>)
                }
                {
                    (!this.state.fetching && !this.state.movimenti.length) &&
                    <p className={"text-muted"}>
                        Non ci sono ancora dei movimenti per questo pacchetto
                    </p>
                }
                {
                    (!this.state.fetching && !!this.state.movimenti.length && this.state.total > PAGE_SIZE) &&
                    <div className={"justify-content-center d-flex mt-3"}>
                        <Pagination
                            activePage={this.state.currentPage}
                            itemsCountPerPage={PAGE_SIZE}
                            totalItemsCount={this.state.total}
                            pageRangeDisplayed={5}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange}
                        />
                    </div>
                }
            </Fragment>
        )
    }

}

PacchettoListaMovimenti.propTypes = {
    idPacchetto: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    qrCodeUrl: PropTypes.string
}