import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {FaSolidIcon} from "../../../../components/FontAwesomeIcons";
import Page from "../../../../components/BaseComponents/Page/Page";
import moment from "moment";
import {swalConfirm, swalError, swalLoading, swalSuccess} from "../../../../helpers/SweetAlertWrapper";
import {SeatsMapType} from "../../../../components/ModuloSeats/PropTypes/MapType";
import {StoriesLikeSelector} from "../../../../components/GenericUI/StoriesLikeSelector/StoriesLikeSelector";
import {getCompressedImageUrl} from "../../../../helpers/ImageUtility";
import {CalendarDayPicker} from "../ModuloServizi/CalendarDayPicker";
import {ModuloSeatsAPI} from "../../../../api/ModuloSeats/ModuloSeatsAPI";
import {EllipsisLoader} from "../../../../components/GenericUI/EllipsisLoader/EllipsisLoader";
import {SectorBookList} from "./SectorBookList";
import {CheckboxSwitch} from "../../../../components/Bootstrap4/CustomControl/CheckboxSwitch";
import {SeatCalendarContext} from "./CalendarContext";
import {
    SEAT_BOOK_STATUS_CONFIRMED, SEAT_BOOK_STATUS_PENDING_CONFIRMATION,
    SEAT_BOOK_STATUS_PENDING_PAYMENT
} from "../../../../components/ModuloSeats/Constants/BookStatuses";

const DAYS_BEFORE_TODAY = 100;
const DAYS_AFTER_TODAY = 100;

const DEFAULT_HIDE_BOOK_WITH_ENTRANCE_DATE_STORAGE_KEY = 'dashboard_seats_hide_books_with_entrance_date';
const DEFAULT_HIDE_EMPTY_SECTORS_STORAGE_KEY = 'dashboard_seats_hide_empty_sectors';

export class CalendarioSeats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            selectedMapId: this.props.maps[0]?.map_id || null,
            sectors: {}, //each key is map ID each value is a sector lists
            books: null,
            datesBookCount: {},
            loading: true,
            hideBooksWithEntranceDate: false,
            hideEmptySectors: true,
            searchQuery: ''
        }

        const savedhideBooksWithEntranceDate = localStorage.getItem(DEFAULT_HIDE_BOOK_WITH_ENTRANCE_DATE_STORAGE_KEY);
        if (savedhideBooksWithEntranceDate) this.state.hideBooksWithEntranceDate = savedhideBooksWithEntranceDate === '1';

        const savedhideEmptySectors = localStorage.getItem(DEFAULT_HIDE_EMPTY_SECTORS_STORAGE_KEY);
        if (savedhideEmptySectors) this.state.hideEmptySectors = savedhideEmptySectors === '1';

        this.todayMoment = moment();
    }

    componentDidMount() {
        this.fetchSectors(this.state.selectedMapId);
        this.fetchBooks();
    }

    handleInputChange = e => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({[name]: value}, _ => {
            if (name === 'hideBooksWithEntranceDate') localStorage.setItem(DEFAULT_HIDE_BOOK_WITH_ENTRANCE_DATE_STORAGE_KEY, value ? "1" : "0")
            if (name === 'hideEmptySectors') localStorage.setItem(DEFAULT_HIDE_EMPTY_SECTORS_STORAGE_KEY, value ? "1" : "0")
        })
    }

    handleDateChange = date => this.setState({selectedDate: date}, this.fetchBooks);
    handleMapIdChange = map_id => {
        this.setState({selectedMapId: map_id}, this.fetchBooks);
        if (!this.state.sectors[map_id]) this.fetchSectors(map_id);
    }

    fetchSectors = map_id => {
        ModuloSeatsAPI.Admin.Maps.getSectors(map_id)
            .then(sectors => {
                this.setState({sectors: {...this.state.sectors, [map_id]: sectors}})
            }).catch(swalError);
    }

    fetchBooks = _ => {
        this.setState({
            books: null,
        }, _ => {
            ModuloSeatsAPI.Admin.Books.getBooks(this.state.selectedMapId, moment(this.state.selectedDate).format('YYYY-MM-DD'))
                .then(books => {
                    this.setState({books: books.filter(b => b.status === SEAT_BOOK_STATUS_CONFIRMED || b.status === SEAT_BOOK_STATUS_PENDING_PAYMENT || b.status === SEAT_BOOK_STATUS_PENDING_CONFIRMATION)});
                })
        });
    }

    handleSetEntranceDate = (book_id, successCallback) => {
        swalConfirm("Sei sicuro di voler registrare l'ingresso per questa prenotazione?", "Procedi", "Annulla")
            .then(_ => {
                swalLoading("Attendere prego...");
                ModuloSeatsAPI.Admin.Books.setEntranceDate(book_id)
                    .then(r => {
                        //Updating entrance date in the correct book stored in state
                        const newBooks = this.state.books.map(b => {
                            return {...b, entrance_date: b.book_id === book_id ? r.data : b.entrance_date};
                        });
                        this.setState({books: newBooks});
                        swalSuccess(r.message);
                        successCallback(r.data);
                    }).catch(swalError);
            });
    }

    render() {
        return (
            <Fragment>
                <SeatCalendarContext.Provider value={this.state.selectedDate}>
                    <Page
                        title={"Prenotazione"}
                        leftIcon={<FaSolidIcon name={"chevron-left"}/>}
                        onLeftIconClick={this.props.onDismiss}
                        active={true}
                    >
                        {/** @MARK Map chooser */}
                        <div className={"container my-3 __disable-swipe"}>
                            <StoriesLikeSelector
                                items={
                                    this.props.maps.map(m => {
                                        return {
                                            value: m.map_id,
                                            label: m.name,
                                            imageUrl: getCompressedImageUrl(`${m.cover_image}`, 150, 150)
                                        }
                                    })
                                }
                                circleSize={60}
                                onChange={this.handleMapIdChange}
                                defaultValue={this.state.selectedMapId}
                            />
                        </div>

                        {/** @MARK Horizontal calendar */}
                        <CalendarDayPicker
                            datesBookCount={this.state.datesBookCount}
                            daysBeforeToday={DAYS_BEFORE_TODAY}
                            daysAfterToday={DAYS_AFTER_TODAY}
                            onDateChange={this.handleDateChange}
                        />

                        {
                            (!this.state.books || !this.state.sectors[this.state.selectedMapId]) &&
                            <EllipsisLoader/>
                        }

                        {
                            (!!this.state.books && !!this.state.sectors[this.state.selectedMapId]) &&
                            <div className={"container py-3"}>
                                <input
                                    type={"search"}
                                    name={"searchQuery"}
                                    value={this.state.searchQuery}
                                    onChange={this.handleInputChange}
                                    className={"form-control mb-3"}
                                    placeholder={"Ricerca per nome cliente"}
                                />
                                <CheckboxSwitch
                                    label={"Mostra solo prenotazioni senza ingresso"}
                                    name={"hideBooksWithEntranceDate"}
                                    checked={this.state.hideBooksWithEntranceDate}
                                    onChange={this.handleInputChange}
                                />
                                <CheckboxSwitch
                                    label={"Nascondi settori senza prenotazioni"}
                                    name={"hideEmptySectors"}
                                    checked={this.state.hideEmptySectors}
                                    onChange={this.handleInputChange}
                                />
                                {
                                    this.state.sectors[this.state.selectedMapId].map(s => {
                                        return <SectorBookList
                                            key={s.sector_id}
                                            sector={s}
                                            books={this.state.books.filter(b => b.sector_id === s.sector_id)}
                                            onSetEntranceDate={this.handleSetEntranceDate}
                                            hideBooksWithEntranceDate={this.state.hideBooksWithEntranceDate}
                                            hideEmptySectors={this.state.hideEmptySectors}
                                            searchQuery={this.state.searchQuery}
                                        />
                                    })
                                }
                            </div>
                        }

                    </Page>
                </SeatCalendarContext.Provider>
            </Fragment>
        );
    }

}

CalendarioSeats.propTypes = {
    maps: PropTypes.arrayOf(SeatsMapType),
    onDismiss: PropTypes.func,
}

