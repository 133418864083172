import React from "react";
import styled from "styled-components";
import logoIconWhite from "../img/logo-icon-total-white.svg";
import {FaSolidIcon} from "../components/FontAwesomeIcons";

const Splashscreen = styled.div`
    height:100vh;
    width:100%;
    position: absolute;
    top:0; left:0;
    background-color: #293069;
    z-index:999;
`;

const SplashscreenText = styled.div`
    position:absolute;
    bottom:1vh;
`;

export default class BookizonSplashScreen extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Splashscreen className={"d-flex align-items-center justify-content-center"}>
                <img src={logoIconWhite} width={window.innerWidth / 3} height={window.innerWidth / 3}/>
                <SplashscreenText className={"text-white text-center"}>
                    Made in Puglia<br/>
                    with <span className={"text-danger"}><FaSolidIcon name={"heart"}/></span>
                </SplashscreenText>
            </Splashscreen>
        )
    }

}
